import {Component, OnDestroy} from '@angular/core';
import {Personal} from '@generated/defs/Personal';
import {Start as GetAdvisor} from '@generated/store/integrations/getAdvisor/states/actions';
import {getGetAdvisorStateSelector} from '@generated/store/integrations/getAdvisor/states/reducers';
import {LoginService, NotificationService, YBugService} from '@lib/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {AdvisorDetailService} from '@shared/advisor-detail/advisor-detail.service';
import {selectFamilyHead} from '@shared/analysis/operators';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {merge, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {PageTitleService} from 'src/app/services/page-title.service';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';
import {getCurrentStepperConfig} from 'src/store/selectors/stepper.selectors';
import {getWideContainer} from 'src/store/selectors/ui.selectors';
import {loadedOk, replenish} from 'src/store/store-helpers';

@UntilDestroy()
@Component({
  templateUrl: './secured-layout.component.html',
  styleUrls: ['./secured-layout.component.scss'],
})
export class SecuredLayoutComponent implements OnDestroy {
  stepperProgressConfig$ = this.store.select(getCurrentStepperConfig);

  clientName$ = merge(
    of(''),
    this.store.pipe(
      selectFamilyHead(),
      map(member => `${member.firstName} ${member.lastName}`),
    ),
  );

  title$ = this.pageTitleService.getTitle();

  familyUuid$ = this.store.pipe(select(getFamilyUuid));

  advisor$ = replenish(getGetAdvisorStateSelector, this.store, GetAdvisor);
  advisorDetail$ = this.advisorDetailService.getAdvisorDetail();

  personalInfo: Personal;

  wideContainer$ = this.store.pipe(select(getWideContainer));

  constructor(
    private store: Store<State>,
    private advisorDetailService: AdvisorDetailService,
    private pageTitleService: PageTitleService,
    public modalService: ModalService,
    public notificationService: NotificationService,
    loginService: LoginService,
    ybugService: YBugService,
  ) {
    loginService.accessToken.pipe(take(1)).subscribe(() => {
      ybugService.configure(loginService.jwtToken);
    });

    this.advisor$
      .pipe(untilDestroyed(this), filter(loadedOk))
      .subscribe(user => (this.personalInfo = user.data.personalInfo));
  }

  ngOnDestroy() {}
}
