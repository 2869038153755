import {createSelector} from '@ngrx/store';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {
  ObjectiveSource,
  objectiveSourceAssets,
  ObjectiveType,
} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectImmovableProperties,
  selectMovableProperties,
} from '@shared/analysis/store';
import produce from 'immer';
import {flatten, sortBy} from 'lodash';
import {ObjectiveItem} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {selectAdditionalContractsObjective} from 'src/app/modules/financial-plan/store/additional-contracts-objective.selectors';
import {selectFamilyObjectivesOld} from 'src/app/modules/financial-plan/store/family-objective-old.selectors';
import {selectFamilyObjectives} from 'src/app/modules/financial-plan/store/family-objective.selectors';
import {selectFinancialIndependenceObjectives} from 'src/app/modules/financial-plan/store/financial-independence-objective.selectors';
import {FinancialPlanData} from 'src/app/modules/financial-plan/store/financial-plan.models';
import {selectFinancialPlanData} from 'src/app/modules/financial-plan/store/financial-plan.selectors';
import {selectFinancialResourcesObjectives} from 'src/app/modules/financial-plan/store/financial-resources-objective.selectors';
import {
  selectVehicleObjectiveAsset,
  selectFamilyObjectiveAsset,
  selectPropertyObjectiveAsset,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  FinancialPlanObjectives,
  FinancialResourcesObjective,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {selectPensionObjectives} from 'src/app/modules/financial-plan/store/pension-objective.selectors';
import {selectPropertyObjectives} from 'src/app/modules/financial-plan/store/property-objective.selectors';
import {selectVehicleObjectives} from 'src/app/modules/financial-plan/store/vehicle-objective.selectors';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export const selectFinancialPlanObjectives = ({showNewDashboard}: {showNewDashboard: boolean}) =>
  createSelector(
    selectFamilyObjectivesOld,
    selectFamilyObjectives,
    selectPropertyObjectives(),
    selectVehicleObjectives(),
    selectPensionObjectives,
    selectFinancialResourcesObjectives(ObjectiveType.FinancialResources),
    selectFinancialIndependenceObjectives,
    selectFinancialResourcesObjectives(ObjectiveType.Housing),
    selectFinancialResourcesObjectives(ObjectiveType.Reserve),
    selectAdditionalContractsObjective,
    (
      familyObjectivesOld,
      familyObjectives,
      propertyObjectives,
      vehicleObjectives,
      pensionObjectives,
      financialResourcesObjectives,
      independenceObjectives,
      housingObjectives,
      reserveObjectives,
      additionalContractsObjectives,
    ): FinancialPlanObjectives => ({
      [ObjectiveType.Family]: showNewDashboard ? familyObjectives : familyObjectivesOld,
      [ObjectiveType.Property]: propertyObjectives,
      [ObjectiveType.Vehicle]: vehicleObjectives,
      [ObjectiveType.Reserve]: reorderReserveObjectives(reserveObjectives),
      [ObjectiveType.Housing]: housingObjectives,
      [ObjectiveType.Pension]: pensionObjectives,
      [ObjectiveType.Independence]: independenceObjectives,
      [ObjectiveType.FinancialResources]: financialResourcesObjectives,
      [ObjectiveType.AdditionalContracts]: additionalContractsObjectives,
    }),
  );

export function areObjectivesEmpty(objectives: FinancialPlanObjectives): boolean {
  return flatten(Object.values(objectives)).length === 0;
}

export type FinancialPlanObjectivesMap = Partial<Record<ObjectiveType, ObjectiveItem[]>>;

export const selectInitialObjectiveItems = (type: ObjectiveType) =>
  createSelector(selectCurrentAssets, (assets): ObjectiveItem[] => {
    const assetsOfInterest = objectiveSourceAssets[type];
    return assets
      .reduce((acc, asset: Asset) => {
        if (assetsOfInterest.includes(asset.type)) acc.push(asset);
        return acc;
      }, [] as Asset[])
      .map((asset): ObjectiveItem => {
        return {
          type,
          objectiveAssetUuid: asset.assetUuid,
          source: ObjectiveSource.FinancialAnalysis,
          data: {
            current: {
              rating: 0,
            },
            proposed: {
              rating: 0,
            },
            introText: null,
          },
        };
      });
  });

export const selectInitialFamilyObjectiveItems = createSelector(
  selectFamilyObjectiveAsset,
  getFamilyMembers,
  (propertyAndFamilyAsset, members): ObjectiveItem[] => {
    if (!propertyAndFamilyAsset) return [];
    return members.map(member => ({
      type: ObjectiveType.Family,
      source: ObjectiveSource.FinancialAnalysis,
      objectiveAssetUuid: propertyAndFamilyAsset.assetUuid,
      familyMemberUuid: member.sugarUuid,
      data: {
        current: {
          rating: 0,
        },
        proposed: {
          rating: 0,
        },
        introText: null,
      },
    }));
  },
);

export const selectInitialPropertyObjectiveItems = createSelector(
  selectPropertyObjectiveAsset,
  selectImmovableProperties,
  (propertyAndFamilyAsset, properties): ObjectiveItem[] => {
    if (!propertyAndFamilyAsset) return [];
    return properties.map(property => ({
      type: ObjectiveType.Property,
      source: ObjectiveSource.FinancialAnalysis,
      objectiveAssetUuid: propertyAndFamilyAsset.assetUuid,
      propertyAssetUuid: property.assetUuid,
      data: {
        current: {
          rating: 0,
        },
        proposed: {
          rating: 0,
        },
        introText: null,
      },
    }));
  },
);

export const selectInitialVehicleObjectiveItems = createSelector(
  selectVehicleObjectiveAsset,
  selectMovableProperties,
  (objectiveAsset, properties): ObjectiveItem[] => {
    if (!objectiveAsset) return [];
    return properties.map(property => ({
      type: ObjectiveType.Vehicle,
      source: ObjectiveSource.FinancialAnalysis,
      objectiveAssetUuid: objectiveAsset.assetUuid,
      propertyAssetUuid: property.assetUuid,
      data: {
        current: {
          rating: 0,
        },
        proposed: {
          rating: 0,
        },
        introText: null,
      },
    }));
  },
);

export const selectInitialAdditionalObjectiveItem = createSelector(
  selectCurrentAssets,
  (_assets): ObjectiveItem[] => {
    return [
      {
        type: ObjectiveType.AdditionalContracts,
        objectiveAssetUuid: null,
        source: ObjectiveSource.FinancialAnalysis,
        data: {
          current: {
            rating: 0,
          },
          proposed: {
            rating: 0,
          },
          introText: null,
        },
      },
    ];
  },
);

export const selectInitialObjectivesMap = createSelector(
  selectInitialFamilyObjectiveItems,
  selectInitialPropertyObjectiveItems,
  selectInitialVehicleObjectiveItems,
  selectInitialObjectiveItems(ObjectiveType.Reserve),
  selectInitialObjectiveItems(ObjectiveType.Housing),
  selectInitialObjectiveItems(ObjectiveType.Pension),
  selectInitialObjectiveItems(ObjectiveType.Independence),
  selectInitialObjectiveItems(ObjectiveType.FinancialResources),
  selectInitialAdditionalObjectiveItem,
  (
    family,
    property,
    car,
    reserve,
    housing,
    pension,
    independence,
    financialObjective,
    additionalObjective,
  ): FinancialPlanObjectivesMap => {
    return {
      [ObjectiveType.Family]: family,
      [ObjectiveType.Property]: property,
      [ObjectiveType.Vehicle]: car,
      [ObjectiveType.Reserve]: reserve,
      [ObjectiveType.Housing]: housing,
      [ObjectiveType.Pension]: pension,
      [ObjectiveType.Independence]: independence,
      [ObjectiveType.FinancialResources]: financialObjective,
      [ObjectiveType.AdditionalContracts]: additionalObjective,
    };
  },
);

export const selectMergedFinancialPlan = createSelector(
  selectFinancialPlanData,
  selectInitialObjectivesMap,
  (financialPlanFromBE, objectivesMap): FinancialPlanData => {
    return mergeFinancialPlan(financialPlanFromBE, objectivesMap);
  },
);

function mergeFinancialPlan(
  financialPlanFromBE: FinancialPlanData,
  initialObjectivesMap: FinancialPlanObjectivesMap,
): FinancialPlanData {
  const initialObjectives = flatten<ObjectiveItem>(Object.values(initialObjectivesMap));
  const objectives = mergeObjectives(financialPlanFromBE, initialObjectives);

  return {...financialPlanFromBE, objectives};
}

function mergeObjectives(
  financialPlanFromBE: FinancialPlanData,
  initialObjectives: ObjectiveItem[],
): ObjectiveItem[] {
  return initialObjectives.map(initialObjective => {
    const objectiveFromBE = financialPlanFromBE.objectives.find(
      o =>
        o.objectiveAssetUuid === initialObjective.objectiveAssetUuid &&
        o.type === initialObjective.type &&
        o.source === initialObjective.source,
    );

    if (!objectiveFromBE) return initialObjective;

    return produce(initialObjective, objective => {
      objective.data.current.rating = objectiveFromBE.data.current.rating;
      objective.data.proposed.rating = objectiveFromBE.data.proposed.rating;
      objective.data.introText = objectiveFromBE.data.introText;
    });
  });
}

function reorderReserveObjectives(
  reserveObjectives: FinancialResourcesObjective[],
): FinancialResourcesObjective[] {
  return sortBy(reserveObjectives, o => {
    switch (o.objectiveAsset.type) {
      case AssetType.ShortTermReserve:
        return 'a';
      case AssetType.LongTermReserve:
        return 'b';
      default:
        return 'c' + o.objectiveAsset.type;
    }
  });
}
