import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {LifeinsuranceService} from '@generated/controllers/Lifeinsurance';
import {Choice, Form, LifeInsuranceData, Person, Risk} from '@generated/model';
import {Start as GetPriorityQuestionnaire} from '@generated/store/lifeinsurance/getPriorityQuestionnaire/states/actions';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {isEqual, sortBy} from 'lodash';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  pairwise,
  skip,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {InsuranceType} from 'src/app/modules/life-insurance-old/6-summary/summary.models';
import {requiredRisksInPriorityQuestionnaire} from 'src/app/modules/life-insurance-old/criteria.helpers';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {
  AdditionalAnalysisData,
  LifeInsuranceFormGroup,
  LifeInsurancePerson,
  LifeInsuranceRisk,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  LifeInsuranceForBE,
  selectAdditionalAnalysisData,
  selectDisabledReserves,
  selectLifeInsuranceForBE,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {State} from 'src/store';
import {Provision, RiskId} from 'src/store/models/risk.models';

type Form2 = Form & {group: LifeInsuranceFormGroup; insuranceType: InsuranceType};
type Risk2 = Risk & {provision: Provision}; // with provision as enum

@UntilDestroy()
@Injectable()
export class LifeInsuranceDataService implements OnDestroy {
  loaded$ = new BehaviorSubject<boolean>(false);

  private cancel$ = new Subject<void>();
  private currentRisks: LifeInsuranceRisk[];

  constructor(
    private store: Store<State>,
    private lifeInsuranceService: LifeinsuranceService,
    private analysisDataService: AnalysisDataService,
    private http: HttpClient,
  ) {
    this.updateReservesOrPension();
  }

  ngOnDestroy() {}

  loadDataAndSaveChanges(familyId: string) {
    this.stop();
    this.loadData(familyId);
    this.listenToChanges(familyId);
    this.loadAdditionalAnalysisDataAndSaveChanges(familyId);
  }

  loadAdditionalAnalysisDataAndSaveChanges(familyId: string) {
    this.loadAdditionalAnalysisData(familyId);
    this.listenToAdditionalAnalysisDataChanges(familyId);
  }

  stop() {
    this.cancel$.next();
  }

  getFile(dmsUuid: string): Observable<Blob> {
    return this.http.get(`/api/integrations/get-file/${dmsUuid}/`, {
      responseType: 'blob',
    });
  }

  loadData(familyId: string) {
    combineLatest([
      this.lifeInsuranceService.getLifeInsuranceData({family_uuid: familyId}),
      this.analysisDataService.getLifeInsurancePersonsOld$(),
    ])
      .pipe(take(1))
      .subscribe(([data, enrichedPersons]) => {
        if (data.persons.length > 0) {
          this.currentRisks = data.risks as LifeInsuranceRisk[];
          const mergedPersons = this.mergePersons(data.persons, enrichedPersons);
          this.store.dispatch(
            LifeInsuranceActions.loadData({
              data: {
                persons: mergedPersons,
                forms: this.processIncomingForms(data.forms),
                risks: data.risks as Risk2[],
                choice: this.processIncomingChoice(data.choice),
                files: data.files,
                definitions: data.definitions,
              },
            }),
          );

          // this is workaround for pairwise at life-insurance.effects.ts - setFinancialAnalysisChangeFlag
          this.setPersons(mergedPersons);
        } else {
          this.analysisDataService.reloadFinancialAnalysis();
        }
        this.loaded$.next(true);
      });
  }

  loadPriorityQuestionnaire(familyId: string, sugarUuids: string[]) {
    this.store.dispatch(
      new GetPriorityQuestionnaire({
        family_uuid: familyId,
        data: {
          clients: sugarUuids.map(sugarUuid => ({sugarUuid})),
        },
      }),
    );
  }

  setPersons(persons: LifeInsurancePerson[]) {
    this.store.dispatch(LifeInsuranceActions.setPersons({persons}));
  }

  private mergePersons(
    persons: Person[],
    enrichedPersons: LifeInsurancePerson[],
  ): LifeInsurancePerson[] {
    const merge = (
      source: (Person | LifeInsurancePerson)[],
      target: (Person | LifeInsurancePerson)[],
    ): LifeInsurancePerson[] => {
      return source.map(person => {
        const enrichedPerson = (target as LifeInsurancePerson[]).find(ep => ep.id === person.id);
        if (!enrichedPerson) return person as LifeInsurancePerson;
        return {
          ...enrichedPerson,
          ...(person as LifeInsurancePerson),
          individualSelection: enrichedPerson.individualSelection,
          createReserves:
            person.createReserves === undefined
              ? enrichedPerson.createReserves
              : person.createReserves,
          employeeContribution:
            person.employeeContribution === undefined
              ? enrichedPerson.employeeContribution
              : person.employeeContribution,
          pensionEnsurement:
            person.pensionEnsurement === undefined
              ? enrichedPerson.pensionEnsurement
              : person.pensionEnsurement,
          pensionEnsurementType:
            person.pensionEnsurementType === undefined
              ? enrichedPerson.pensionEnsurementType
              : person.pensionEnsurementType,
          taxRelief: person.taxRelief === undefined ? enrichedPerson.taxRelief : person.taxRelief,
          useOtherIncome: enrichedPerson.useOtherIncome,
          otherIncomeAssetUuids: enrichedPerson.otherIncomeAssetUuids,
          useSixMonthReserve: enrichedPerson.useSixMonthReserve,
          sixMonthReserve: enrichedPerson.sixMonthReserve,
          usePaymentProtectionInsurance: enrichedPerson.usePaymentProtectionInsurance,
        };
      });
    };

    return merge(enrichedPersons, persons);
  }

  private processIncomingForms(forms: Form[]) {
    return forms.map(form => ({...form})) as Form2[];
  }

  private processIncomingChoice(choice: Choice): Choice {
    if (!choice) choice = {} as Choice;
    return {
      amount: choice.amount || null,
      securingDependentPersons: choice.securingDependentPersons || false,
      evenUpIncome: choice.evenUpIncome || false,
      rebuildExpenses: choice.rebuildExpenses || false,
      medicalCostsCoverage: choice.medicalCostsCoverage || false,
      utilitiesPurchase: choice.utilitiesPurchase || false,
      liabilitiesCoverage: choice.liabilitiesCoverage || false,
      higherStandard: choice.higherStandard || false,
      other: choice.other || false,
      otherText: choice.otherText || '',
    };
  }

  private listenToChanges(familyId: string) {
    this.store
      .pipe(
        select(selectLifeInsuranceForBE),
        skip(2), // skip once subscribed to store and skip once data from BE are written to store
        debounceTime(1000),
        distinctUntilChanged<LifeInsuranceForBE>(isEqual),
        takeUntil(this.cancel$),
        switchMap(data => this.sendData(data, familyId)),
      )
      .subscribe();
  }

  private loadAdditionalAnalysisData(familyId: string) {
    this.lifeInsuranceService
      .getAdditionalFinancialAnalysis({family_uuid: familyId})
      .pipe(take(1))
      .subscribe(additionalData => {
        this.store.dispatch(
          LifeInsuranceActions.setAdditionalAnalysisData({
            debtsTogether: additionalData.debtsTogether,
          }),
        );
      });
  }

  private listenToAdditionalAnalysisDataChanges(familyId: string) {
    this.store
      .pipe(
        select(selectAdditionalAnalysisData),
        skip(2), // skip once subscribed to store and skip once data from BE are written to store
        debounceTime(1000),
        distinctUntilChanged<AdditionalAnalysisData>(isEqual),
        takeUntil(this.cancel$),
        switchMap(
          data =>
            this.lifeInsuranceService
              .createUpdateAdditionalFinancialAnalysis({
                family_uuid: familyId,
                data: {
                  debtsTogether: data.debtsTogether,
                },
              })
              .pipe(
                catchError(error => {
                  console.error('Failed to save tangible assets:', error);
                  return of();
                }),
              ) as Observable<LifeInsuranceData>,
        ),
      )
      .subscribe();
  }

  private resetScoredProducts(
    newPersons: LifeInsurancePerson[],
    newRisks: LifeInsuranceRisk[],
    currentRisks: LifeInsuranceRisk[],
  ): {personId: string; reset: boolean}[] {
    return newPersons.reduce((acc, person) => {
      if (person.child) return acc;

      const newIds = this.transformRisks(newRisks, person.id);
      const currentIds = this.transformRisks(currentRisks, person.id);
      acc.push({
        personId: person.id,
        reset: !isEqual(currentIds, newIds),
      });
      return acc;
    }, []);
  }

  private transformRisks(risks: LifeInsuranceRisk[], personId: string) {
    return sortBy(risks, ['riskId'])
      .filter(r => r.myChoice && personId === r.personId && r.provision !== Provision.Custom)
      .map(r => r.riskId)
      .filter(rId => !requiredRisksInPriorityQuestionnaire.includes(rId as RiskId));
  }

  private updateReservesOrPension() {
    this.store
      .pipe(
        select(selectDisabledReserves),
        filter(state => state !== undefined),
        pairwise(),
        untilDestroyed(this),
      )
      .subscribe(([oldState, newState]) => {
        Object.entries(newState).forEach(([sugarUuid, newDisabled]) => {
          const oldDisabled = oldState[sugarUuid];
          if (!oldDisabled && newDisabled) {
            this.store.dispatch(
              LifeInsuranceActions.updateReservesOrPension({
                personId: sugarUuid,
                changes: {
                  createReserves: false,
                  taxRelief: false,
                  employeeContribution: false,
                },
              }),
            );
          }
        });
      });
  }

  private sendData(data: LifeInsuranceForBE, familyId: string): Observable<LifeInsuranceData> {
    return this.lifeInsuranceService
      .createUpdateLifeInsuranceData({
        family_uuid: familyId,
        data: {
          persons: data.persons,
          forms: data.forms,
          risks: data.risks,
          choice: data.choice,
          files: data.files,
          resetScoredProducts: this.resetScoredProducts(
            data.persons,
            data.risks,
            this.currentRisks,
          ),
          definitions: data.definitions,
        },
      })
      .pipe(
        tap(_ => (this.currentRisks = data.risks)),
        catchError(error => {
          console.error('Failed to save life insurance:', error);
          return of();
        }),
      ) as Observable<LifeInsuranceData>;
  }
}
