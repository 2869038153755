export enum ChartColors {
  incomeLightColor = '#DAE8EB',
  incomeColor = '#BFD8DD',
  incomeDarkColor = '#A3C8CE',

  freeAssetLightColor = '#BEDBD4',
  freeAssetColor = '#60AEA1',
  investmentColor = '#016677',

  insuranceColor = '#FEDC98',

  expenseColor = '#EC7978',
  expenseDarkColor = '#CA5654',

  dispensableExpenseColor = '#78858E',
  indispensableExpenseColor = '#ABB4BB',
}
