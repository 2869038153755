import {Component} from '@angular/core';
import {DialogService} from '@shared/services/modal.service';

@Component({
  selector: 'kpt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  title = '';
  yesButton = 'Ano';
  noButton = 'Ne';

  constructor(public modalService: DialogService) {}

  confirm(value: boolean) {
    this.modalService.close(value);
  }
}
