import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'kpt-radio-group-formly',
  templateUrl: './radio-group-formly.component.html',
})
export class RadioGroupFormlyComponent extends FieldType {
  noop() {}
}
