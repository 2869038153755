import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'kpt-slider-input-range-formly',
  templateUrl: './slider-input-range-formly.component.html',
  styleUrls: ['./slider-input-range-formly.component.scss'],
})
export class SliderInputRangeFormlyComponent extends FieldType implements OnInit, OnDestroy {
  helperForm = new UntypedFormGroup({
    slider: new UntypedFormControl(),
    input: new UntypedFormControl(),
  });
  sliderValue = 100;
  percentValue = 0;
  initValue: number;

  ngOnInit() {
    this.initValue = this.to.modelValue;
    this.percentValue = this.formControl.value * 100;

    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(_ => {
      this.initValue = _;
      this.onSliderChange(this.sliderValue);
    });

    this.helperForm.get('slider').patchValue(this.percentValue, {emitEvent: false});
    this.onSliderChange(this.percentValue);

    this.helperForm
      .get('slider')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        this.onSliderChange(value);
      });

    if (this.to.connectedField) {
      this.form
        .get(this.to.connectedField)
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe(val => {
          this.initValue = val;
          this.onSliderChange(this.sliderValue);
        });
    }
  }

  ngOnDestroy() {}

  onSliderChange(evt: number) {
    this.sliderValue = evt;
    this.helperForm
      .get('input')
      .patchValue(Math.floor((this.sliderValue / 100) * this.initValue), {emitEvent: false});
    this.formControl.patchValue(this.sliderValue / 100, {emitEvent: false});
  }
}
