import {CommonModule, CurrencyPipe} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CurrencyCZKPipe, PipesModule} from '@lib/pipes';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonComponent} from '@shared/lib/common.component';
import {
  AccordionModule,
  AttachmentComponent,
  BubbleLabelComponent,
  MenuListComponent,
  SummaryListComponent,
} from '@shared/lib/components';
import {CollapseModule} from '@shared/lib/directives';
import {AvatarModule} from '@shared/ui/avatar/avatar.module';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {DialogService} from '../services/modal.service';
import {ThinSpacePipe} from './pipes';

const declarations = [
  AttachmentComponent,
  BubbleLabelComponent,
  CommonComponent,
  SummaryListComponent,
  MenuListComponent,
  ThinSpacePipe,
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    FlexboxModule,
    AccordionModule,
    CollapseModule,
    AvatarModule,
    PipesModule,
    NgbModalModule,
    RouterModule,
    AngularSvgIconModule,
  ],
  exports: [
    ...declarations,
    CurrencyCZKPipe,
    AccordionModule,
    CollapseModule,
    AvatarModule,
    NgbModalModule,
  ],
  providers: [CurrencyPipe, CurrencyCZKPipe, ThinSpacePipe, DialogService],
})
export class CommonBaseModule {
  static withProviders(): ModuleWithProviders<CommonBaseModule> {
    return {
      ngModule: CommonBaseModule,
      providers: [DialogService],
    };
  }
}
