import {DecimalPipe} from '@angular/common';
import {Component, Input, Optional, SkipSelf} from '@angular/core';
import {NgControl} from '@angular/forms';
import {ErrorMessages} from '@shared/ui/forms/form.constants';
import {createStringFromTemplate} from '@shared/utils/create-string-from-template.fnc';
import {isNil, isNumber, isString} from 'lodash';

@Component({
  selector: 'kpt-form-field-error',
  templateUrl: 'field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
})
export class FieldErrorComponent {
  @Input()
  customErrors: {[key: string]: string} | string;

  @Input()
  get showErrorsOn() {
    if (typeof this._showErrorsOn === 'boolean') return this._showErrorsOn;

    if (this._showErrorsOn === 'touched') return this.controlDirective.touched;

    return false;
  }
  set showErrorsOn(v: boolean | 'touched') {
    this._showErrorsOn = v;
  }

  private _showErrorsOn: boolean | 'touched';

  constructor(
    private decimalPipe: DecimalPipe,
    @SkipSelf() @Optional() public controlDirective: NgControl,
  ) {}

  getErrorMessage() {
    if (
      isNil(this.controlDirective) ||
      (isNil(this.controlDirective.errors) && isNil(this.customErrors))
    )
      return;

    if (isString(this.customErrors)) return this.customErrors;

    const errors = {
      ...this.controlDirective.errors,
      ...this.customErrors,
    };

    const errorType = Object.keys(errors)[0] as keyof typeof ErrorMessages;
    const stringFromTemplate = createStringFromTemplate(
      ErrorMessages[errorType],
      this.formatErrorObjectValues((errors as any)[errorType]),
    );

    return stringFromTemplate;
  }

  private formatErrorObjectValues(obj: any) {
    Object.keys(obj).forEach(key => {
      if (isNumber(obj[key])) {
        obj[key] = this.decimalPipe.transform(obj[key], '1.0-2');
      }
    });
    return obj;
  }
}
