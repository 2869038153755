import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {PipesModule} from '@lib/pipes';
import {AvatarModule} from '@shared/ui/avatar/avatar.module';
import {SelectModule} from '@shared/ui/forms/select/select.module';
import {HeaderComponent} from '@shared/ui/header/header.component';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    AvatarModule,
    CommonModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    SelectModule,
  ],
})
export class HeaderModule {}
