import {createFeatureSelector, createSelector} from '@ngrx/store';
import {InvestmentSurveyState} from '@shared/investment-survey-old/store/investment-survey.state';

export const storeFeatureName = 'investmentSurvey';

export const selectInvestmentSurveyState =
  createFeatureSelector<InvestmentSurveyState>(storeFeatureName);

export const selectAllSurveyAnswers = createSelector(
  selectInvestmentSurveyState,
  state => state.surveyAnswers,
);
