import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginService, NotificationService, versionFile} from '@lib/services';
import {BackendValidationService} from '@shared/backend-validation/backend-validation.service';
import {
  connectionRefused,
  errorLocalizations,
  genericErrorLocalization,
  genericValidationLocalization,
  MessageLocalization,
  warningLocalizations,
} from '@shared/models/message-localization.models';
import {find, get} from 'lodash';
import {Observable, throwError} from 'rxjs';

import {catchError, concatMap, map, take} from 'rxjs/operators';

import {environment} from 'src/environments/environment';

const messagesToSkip: string[] = [];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private notificationService: NotificationService,
    private backendValidationService: BackendValidationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // auth call is not intercepted
    if (req.url.match(`^${environment.tokenUrl}`)) return next.handle(req);
    if (req.url.match(versionFile)) return next.handle(req);

    // set base url for api calls
    // set oauth header
    return this.loginService.accessToken.pipe(
      map(token =>
        req.clone({
          url: environment.apiBaseUrl + req.url,
          headers: req.headers.set('Authorization', `Bearer ${token}`).set('Accept-Language', 'cs'),
        }),
      ),
      take(1), // request observables must be finite
      concatMap(authorizedRequest => next.handle(authorizedRequest)),
      // final error message
      catchError((e: HttpErrorResponse) => {
        // invalid code, refresh token
        if (this.isErrorOfType(e, 400, 'invalid_grant')) {
          this.loginService.logout();
        } else if (this.isErrorOfType(e, 0)) {
          this.notificationService.dispatchError(connectionRefused);
        } else if (this.isErrorOfType(e, 401)) {
          this.loginService.logout();
        } else if (this.isErrorOfType(e, 403)) {
          // skip 403 errors
        } else if (this.isErrorOfType(e, 404)) {
          // skip possible valid 404
        } else if (this.isErrorOfType(e, 504)) {
          this.notificationService.dispatchTimeout();
        } else if (this.isEzaznamError(e)) {
          this.notificationService.dispatchWarning(e.error.error);
        } else if (e.status < 500) {
          if (this.backendValidationService.isActive()) {
            // do nothing, error will be handled by validation service
          } else {
            const details = this.getErrorDetails(e);
            this.notificationService.dispatchWarning(
              this.getLocalizedMessage(e, warningLocalizations, genericValidationLocalization) +
                details,
            );
          }
        } else if (!messagesToSkip.includes(e.error.detail)) {
          this.notificationService.dispatchError(
            this.getLocalizedMessage(e, errorLocalizations, genericErrorLocalization),
          );
        }

        return throwError(e);
      }),
    );
  }

  private isErrorOfType(e: HttpErrorResponse, status: number, authError?: string) {
    if (e.status !== status) return false;
    return !authError || get(e, 'error.error') === authError;
  }

  private getErrorDetails(e: HttpErrorResponse): string {
    try {
      const errors = this.backendValidationService.getErrors(e);
      return errors.length > 0 ? ` ( ${errors[0].path} - ${errors[0].messages[0]} )` : '';
    } catch {
      return '';
    }
  }

  private getLocalizedMessage(
    e: HttpErrorResponse,
    localizations: MessageLocalization[],
    genericLocalization: string,
  ): string {
    const message = e.error.detail ? e.error.detail : e.error.error;
    const messageLocalization = find(localizations, {message}) as MessageLocalization;
    return messageLocalization ? messageLocalization.localizedMessage : genericLocalization;
  }

  private isEzaznamError(e: HttpErrorResponse): boolean {
    return (
      !!e.error.error &&
      e.status === 400 &&
      e.url.includes(`${environment.apiBaseUrl}/api/integrations/ezaznam/`)
    );
  }
}
