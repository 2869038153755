import {Injectable} from '@angular/core';
import {CreditProducts} from '@shared/analysis/forms/credit-products.definitions';
import {FinancialOutlook} from '@shared/analysis/forms/financial-outlook.definitions';
import {InsuranceProducts} from '@shared/analysis/forms/insurance-products.definitions';
import {InvestmentProducts} from '@shared/analysis/forms/investment-products.definitions';
import {ObjectivesDefinitions} from '@shared/analysis/forms/objectives.definitions';
import {OtherAssets} from '@shared/analysis/forms/other-assets.definitions';
import {flatMap} from 'lodash';
import {relatedSumAssetTypes, sumAssetTypes} from '../asset.utils';
import {AssetType} from '../models/asset';
import {FASection, FASectionType, FinancesSection, PropertiesSection} from '../models/section';
import {CommonExpenses} from './common-expenses.definitions';
import {AssetDefinition} from './definitions.models';
import {MainIncomes} from './main-incomes.definitions';
import {OtherIncomes} from './other-incomes.definitions';
import {Properties} from './properties.definitions';

export interface SectionDefinition<T extends string = FASection> {
  key: T;
  definitions: AssetDefinition[];
  type?: FASectionType;
  sumAssetType?: AssetType;
  relatedSumAssetType?: AssetType;
}

@Injectable()
export class AssetDefinitions {
  constructor(
    private insuranceProducts: InsuranceProducts,
    private investmentProducts: InvestmentProducts,
    private creditProducts: CreditProducts,
    private objectives: ObjectivesDefinitions,
    private commonExpenses: CommonExpenses,
    private mainIncomes: MainIncomes,
    private otherIncomes: OtherIncomes,
    private financialOutlook: FinancialOutlook,
    private properties: Properties,
    private otherAssets: OtherAssets,
  ) {}

  getFinancesDefinitions(): SectionDefinition<FinancesSection>[] {
    return [
      {
        key: 'mainIncomes',
        definitions: this.mainIncomes.getAll(),
        type: 'positive',
      },
      {
        key: 'otherIncomes',
        definitions: this.otherIncomes.getAll(),
        type: 'positive',
      },
      {
        key: 'expenses',
        definitions: this.commonExpenses.getAll(),
        sumAssetType: sumAssetTypes.expenses,
        type: 'negative',
      },
      {
        key: 'insuranceProducts',
        definitions: this.insuranceProducts.getAll(),
        sumAssetType: sumAssetTypes.insuranceProducts,
        type: 'negative',
      },
      {
        key: 'investmentProducts',
        definitions: this.investmentProducts.getAllForFinances(),
        sumAssetType: sumAssetTypes.investmentProducts,
        type: 'negative',
      },
      {
        key: 'creditProducts',
        definitions: this.creditProducts.getAll(),
        sumAssetType: sumAssetTypes.creditProducts,
        type: 'negative',
      },
    ];
  }

  getPropertiesDefinitions(): SectionDefinition<PropertiesSection>[] {
    return [
      {
        key: 'immovableProperties',
        definitions: this.properties.getImmovableProperties(),
        sumAssetType: sumAssetTypes.immovableProperties,
        type: 'positive',
      },
      {
        key: 'movableProperties',
        definitions: this.properties.getMovableProperties(),
        sumAssetType: sumAssetTypes.movableProperties,
        type: 'positive',
      },
      {
        key: 'insuranceProperties',
        definitions: this.insuranceProducts.getAllForProperties(),
        sumAssetType: sumAssetTypes.insuranceProperties,
        relatedSumAssetType: relatedSumAssetTypes.insuranceProperties,
        type: 'positive',
      },
      {
        key: 'investmentProperties',
        definitions: this.investmentProducts.getAllForProperties(),
        sumAssetType: sumAssetTypes.investmentProperties,
        relatedSumAssetType: relatedSumAssetTypes.investmentProperties,
        type: 'positive',
      },
      {
        key: 'financialProperties',
        definitions: this.properties.getFinancialProperties(),
        type: 'positive',
      },
      {
        key: 'creditProperties',
        definitions: this.creditProducts.getAll(),
        sumAssetType: sumAssetTypes.creditProperties,
        relatedSumAssetType: relatedSumAssetTypes.creditProperties,
        type: 'negative',
      },
    ];
  }

  getAll(): AssetDefinition[] {
    return [
      ...this.commonExpenses.getAll(),
      ...this.mainIncomes.getAll(),
      ...this.otherIncomes.getAll(),

      ...this.objectives.getAll(),
      ...this.insuranceProducts.getAll(),
      ...this.investmentProducts.getAllForProperties(),
      ...this.creditProducts.getAll(),
      ...this.properties.getAll(),
      ...this.financialOutlook.getAll(),
      ...this.otherAssets.getAll(),
    ];
  }

  getAllByType(): {[key in AssetType]: AssetDefinition} {
    return this.getAll().reduce((acc, assetDefinition) => {
      acc[assetDefinition.type] = assetDefinition;
      return acc;
    }, {} as {[key in AssetType]: AssetDefinition});
  }

  getSumAssetTypes(assetType: AssetType, includeRelated = false): AssetType[] {
    return flatMap(
      [
        ...Object.values(this.getFinancesDefinitions()),
        ...Object.values(this.getPropertiesDefinitions()),
      ].filter(s => s.definitions.find(d => d.type === assetType)),
      s => [s.sumAssetType, ...(includeRelated ? [s.relatedSumAssetType] : [])],
    );
  }
}
