import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from '@lib/components';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';
import {RadioGroupComponent} from '@shared/ui/forms/radio-group/radio-group.component';

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [CommonModule, ReactiveFormsModule, FieldErrorModule, TooltipModule, FormsModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}
