export const ErrorMessages = {
  required: 'Bez vyplnění tohoto pole to dál nepůjde.',
  email:
    'Můžu tady mít pouze písmena bez diakritiky, číslice, tečku (.) a nesmí chybět zavináč (@).',
  customEmail:
    'Můžu tady mít pouze písmena bez diakritiky, číslice, tečku (.) a nesmí chybět zavináč (@).',
  pattern: 'Musím zadat správný formát.',
  minlength: 'Tady nemůžu mít méně jak {requiredLength} znaků.',
  maxlength: 'Sem se mi nevejde více jak {requiredLength} znaků.',
  bsDate: 'Nemůžu zadat nelogické minulé, současné nebo budoucí datum.',
  notBefore: 'Konečné datum musí následovat po počátečním datu.',
  futureDate: 'Musím zadat datum v budoucnu.',
  birthNumber: 'Musím mít vyplněné platné rodné číslo (bez lomítka) občana ČR nebo SK.',
  birthDate: 'Nemůžu zadat datum starší než 01.01.1900.',
  phoneNumber: 'Musím mít vyplněnou předvolbu pro ČR +420 nebo pro SK +421 a k tomu 9 číslic.',
  min: 'Tady můžu zadat minimálně {min}.',
  max: 'Tady můžu zadat maximálně {max}.',
  number: 'Tady můžu mít pouze číslo.',
  oneOf: 'Ještě mi chybí vyplněné alespoň jedno z polí: {missingFields}.',
  decimal: 'Můžu tady mít pouze číslice a čárku (,) nebo tečku (.) pro desetinné místo.',
  postalCode: 'Sem můžu zadat 5 číslic.',
  sexMatchesBirthNumber: 'Pohlaví neodpovídá rodnému číslu.',
  birthDateMatchesBirthNumber: 'Datum narození neodpovídá rodnému číslu.',
  documentNumber: 'Můžu tady mít pouze písmena bez diakritiky a číslice.',
  backendValidation: '{message}',
};
