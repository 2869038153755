import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {InvestmentSurveyUtilsService} from '@shared/investment-survey-old/investment-survey-utils.service';
import {
  hasInvestmentPortfolioInconsistency,
  hasInvestmentPurposeInconsistency,
} from '@shared/investment-survey-old/investment-survey.utils';
import {Answers, emptyAnswers} from '@shared/investment-survey-old/models/answer';
import {QuestionsOrigin} from '@shared/investment-survey-old/models/question';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {
  bareLossesValue,
  horizons,
  InvestmentPreferences,
  investmentPreferencesI18n,
  InvestmentStrategy,
  investmentStrategyI18n,
  InvestmentSurveyCategory,
  InvestmentSurveyResults,
  InvestmentSurveyRiskProfile,
  PreferredPortfolio,
  preferredPortfolioI18n,
  riskProfileI18n,
} from 'src/app/modules/investment-old/investment-survey/investment-survey.models';
import {pointsI18n} from '../summary.utils';

@Injectable()
export class InvestmentSummaryService {
  constructor(
    private questions: Questions,
    private investmentSurveyUtilsService: InvestmentSurveyUtilsService,
  ) {}

  evaluate(
    investmentQuestionnaire: SurveyAnswers,
    checkAnswersConsistency = false,
  ): InvestmentSurveyResults {
    const surveyData = {...emptyAnswers(), ...investmentQuestionnaire?.answers};
    const knowledgeExperiencePoints = this.questions.getPoints(surveyData, [
      'knowledgeInv',
      'financialMarketNews',
      'education',
      'experience',
      'transactions',
    ]);
    const riskProfilePoints = checkAnswersConsistency
      ? this.getAppropriateRiskPoints(surveyData)
      : this.questions.getPoints(surveyData, ['risk', 'graph', 'lossOfInvestment']);
    const riskProfile = this.getRiskProfile(riskProfilePoints);
    const horizon = surveyData.investmentDurationInv.answer
      ? (surveyData.investmentDurationInv.answer as string)
      : 'less-than-year';

    return {
      category: surveyData.investmentClientEligibility.answer as InvestmentSurveyCategory,
      bareLosses: bareLossesValue[surveyData.lossOfInvestment.answer as string],
      horizon,
      knowledgeExperiencePoints,
      investmentPreferences: surveyData.risk.answer as InvestmentPreferences,
      preferredPortfolio: surveyData.graph.answer as PreferredPortfolio,
      riskProfile,
      riskProfilePoints,
      eligibility: this.getEligibility(surveyData),
      investmentStrategy: this.getStrategy(knowledgeExperiencePoints, riskProfile, horizon),
      inconsistentPortfolioAnswers:
        checkAnswersConsistency && hasInvestmentPortfolioInconsistency(surveyData),
      inconsistentInvestmentPurposeAnswers:
        checkAnswersConsistency && hasInvestmentPurposeInconsistency(surveyData),
    };
  }

  isFinished(surveyAnswers: SurveyAnswers[], sugarUuid: string): boolean {
    return this.investmentSurveyUtilsService.answersValid(
      surveyAnswers,
      sugarUuid,
      this.questions.allQuestionsCategories(QuestionsOrigin.INVESTMENT_ALL),
    );
  }

  prepareResultForm(form: UntypedFormGroup, results: InvestmentSurveyResults) {
    const chosenHorizonI18n = horizons.find(horizon => horizon.key === results.horizon).label;
    form.get('knowledgeExperiencePoints').setValue(results.knowledgeExperiencePoints);
    form.get('horizon').setValue(chosenHorizonI18n);
    form
      .get('investmentPreferences')
      .setValue(investmentPreferencesI18n[results.investmentPreferences]);
    form.get('preferredPortfolio').setValue(preferredPortfolioI18n[results.preferredPortfolio]);
    form.get('bareLosses').setValue(results.bareLosses ? 'Ano' : 'Ne');
    form.get('riskProfile').setValue(`${results.riskProfilePoints} ${pointsI18n(
      results.riskProfilePoints,
    )} -
     ${riskProfileI18n[results.riskProfile]}`);
    form.get('strategy').setValue(investmentStrategyI18n[results.investmentStrategy]);
    form.get('eligibility').setValue(results.eligibility);
    form.get('inconsistentPortfolioAnswers').setValue(results.inconsistentPortfolioAnswers);
    form
      .get('inconsistentInvestmentPurposeAnswers')
      .setValue(results.inconsistentInvestmentPurposeAnswers);
  }

  private getAppropriateRiskPoints(answers: Answers): number {
    let riskPoints = this.questions.getPoints(answers, ['risk']);
    let graphPoints = this.questions.getPoints(answers, ['graph']);
    const lossOfInvestmentPoints = this.questions.getPoints(answers, ['lossOfInvestment']);
    if (answers.risk.answer === 'almost-none-risk' && answers.graph.answer !== 'A') {
      graphPoints = 1;
    } else if (
      answers.risk.answer === 'low-risk' &&
      !['A', 'B'].includes(answers.graph.answer as string)
    ) {
      graphPoints = 2;
    } else if (
      ['partial-risk', 'max-risk'].includes(answers.risk.answer as string) &&
      !['C', 'D'].includes(answers.graph.answer as string)
    ) {
      riskPoints = 2;
    }
    return riskPoints + graphPoints + lossOfInvestmentPoints;
  }

  private getRiskProfile(points: number): InvestmentSurveyRiskProfile {
    if (points <= 3) return 'a';
    else if (points <= 8) return 'b';
    else if (points <= 11) return 'c';
    return 'd';
  }

  private getEligibility(answers: Answers): boolean {
    const firstAnswerPoints = this.questions.getPoints(answers, ['knowledgeInv']);
    if (firstAnswerPoints < 0) return false;
    if (answers.risk.answer === 'no-risk') return false;
    if (answers.lossOfInvestment.answer === 'everything') return false;
    return answers.investmentDurationInv.answer !== 'less-than-year';
  }

  private getStrategy(
    knowledgeExperiencePoints: number,
    riskProfile: InvestmentSurveyRiskProfile,
    horizon: string,
  ): InvestmentStrategy {
    const longTermHorizon = ['min-ten-years', 'min-fifteen-years'].includes(horizon);
    if (knowledgeExperiencePoints < 0) return 'ineligibile';
    if (knowledgeExperiencePoints >= 0 && knowledgeExperiencePoints <= 3) return 'conservative';
    if (knowledgeExperiencePoints >= 4 && knowledgeExperiencePoints <= 19) {
      if (riskProfile === 'a') return 'conservative';
      if (riskProfile === 'd') return 'dynamic';
      if (riskProfile === 'c' && knowledgeExperiencePoints >= 15 && longTermHorizon) {
        return 'dynamic';
      }
      // riskProfile === 'b' || riskProfile === 'c'
      return 'balanced';
    }

    // knowledgeExperiencePoints >= 20
    if (riskProfile === 'a') {
      return longTermHorizon ? 'balanced' : 'conservative';
    }
    if (riskProfile === 'b') {
      return longTermHorizon ? 'dynamic' : 'balanced';
    }
    if (riskProfile === 'c') return 'dynamic';
    // riskProfile === 'd'
    return 'speculative';
  }
}
