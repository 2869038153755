export interface ITableColumnViewConfig {
  showIn?: string[];
  headingClass?: string[];
  cellClass?: string[];
  content?: (row: any) => string;
  contentTemplateName?: string;
}

export interface ITableColumnConfig {
  label?: string;
  views: ITableColumnViewConfig[];
  mobileViews?: ITableColumnViewConfig[];
}
