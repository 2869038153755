import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {AssetType} from '@shared/analysis/models/asset';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {DialogService} from '@shared/services/modal.service';
import {
  AddAssetModalComponent,
  AddAssetResult,
} from 'src/app/modules/financial-plan/objectives/components/add-asset-modal/add-asset-modal.component';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';

@Component({
  selector: 'kpt-add-asset-button',
  templateUrl: './add-asset-button.component.html',
  styleUrls: ['./add-asset-button.component.scss'],
})
export class AddAssetButtonComponent {
  @Input() situation: Situation = Situation.Current;

  @Input() objectiveType: ObjectiveType;

  @Input() relatedObjectiveUuid: string;

  @Input() relatedPropertyUuid: string;

  @Input() stakeholderUuid: string;

  @Input() assetType: AssetType;

  @Input() variant: 'link' | 'button' | 'icon' = 'link';

  @Input() text: string;

  @Input() bothSituations = false;

  Situation = Situation;

  newAssetType: AssetType;

  @ViewChild('createElem', {read: ElementRef, static: false})
  private createElem: ElementRef<HTMLAnchorElement>;

  constructor(
    private dialogService: DialogService,
    private assetsHandlerService: AssetsHandlerService,
    private cd: ChangeDetectorRef,
  ) {}

  addAsset() {
    this.dialogService
      .load(
        AddAssetModalComponent,
        {
          objectiveType: this.objectiveType,
          relatedObjectiveUuid: this.relatedObjectiveUuid,
          relatedPropertyUuid: this.relatedPropertyUuid,
          stakeholderUuid: this.stakeholderUuid,
          assetType: this.assetType,
          situation: this.situation,
          bothSituations: this.bothSituations,
        },
        {},
      )
      .then(
        (result: AddAssetResult) => {
          if (result.type === 'new') {
            this.newAssetType = result.assetType;
            this.situation = result.situation;
            this.cd.detectChanges();
            setTimeout(() => {
              this.createElem.nativeElement.click();
            });
          } else {
            if (this.situation === Situation.Current) {
              this.assetsHandlerService.addObjectiveRelation(
                result.assetUuid,
                this.relatedObjectiveUuid,
              );
              if (this.relatedPropertyUuid) {
                this.assetsHandlerService.addPropertyRelation(
                  result.assetUuid,
                  this.relatedPropertyUuid,
                );
              }
              if (this.stakeholderUuid) {
                this.assetsHandlerService.addStakeholderRelation(
                  result.assetUuid,
                  this.stakeholderUuid,
                );
              }
            } else if (this.situation === Situation.AdvisorProposed) {
              this.assetsHandlerService.proposeCurrentAsset(result.assetUuid);
            }
          }
        },
        () => {},
      );
  }
}
