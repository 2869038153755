<div class="form-group radio-group--switch" [id]="id" #field>
  <div *ngFor="let option of optionsList" class="custom-control custom-radio custom-radio--switch">
    <input
      type="radio"
      (blur)="blur.emit($event)"
      class="custom-control-input form-control"
      [class.is-warning]="warning"
      [class.form-control--not-empty]="_value === option.key"
      (change)="changeAction(option.key)"
      [checked]="_value === option.key"
      [readonly]="readonly"
      [value]="option.key"
      [disabled]="disabled"
      [required]="required"
      [ngClass]="customFieldClass"
    />
    <label class="custom-control-label" (click)="labelClick(option.key)">
      {{ option.label }}
    </label>
  </div>
</div>
