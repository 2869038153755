import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ToObservable} from '@shared/decorators/to-observable';
import {Observable} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'kpt-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() tabTitle: string;
  @Input() tabDescription: string;
  @ToObservable()
  @Input()
  active: boolean;
  active$: Observable<boolean>;
  @Input() disabled = false;
  @Input() name?: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.active$.pipe(untilDestroyed(this)).subscribe(_ => this.cd.detectChanges());
  }

  ngOnDestroy() {}
}
