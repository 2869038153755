import {Asset} from '@shared/analysis/models/asset';
import {ofType, unionize, UnionOf} from 'unionize';

export const FinancialAnalysisActions = unionize(
  {
    FA_SetData: ofType<{assets: Asset[]}>(),
    FA_AddAsset: ofType<Asset>(),
    FA_UpdateAsset: ofType<Asset>(),
    FA_SaveAsset: ofType<Asset>(),
    FA_RemoveAsset: ofType<{assetUuid: string}>(),
    FA_SetCurrentlyEditedAsset: ofType<Asset>(),
    FA_AddObjectiveRelation: ofType<{assetUuid: string; relatedObjectiveUuid: string}>(),
    FA_AddPropertyRelation: ofType<{assetUuid: string; relatedPropertyUuid: string}>(),
    FA_AddStakeholderRelation: ofType<{assetUuid: string; stakeholderUuid: string}>(),
    FA_RemoveObjectiveRelation: ofType<{assetUuid: string}>(),
    FA_RemovePropertyRelation: ofType<{assetUuid: string}>(),
    FA_RemoveProposedUnchangedAssets: {},
    FA_RemoveProposedAssets: {},
    FA_ProposeCurrentAsset: ofType<{assetUuid: string}>(),
    FA_ClearProposalStateBeforeUpdate: ofType<{
      originalAssetUuid: string;
      clientProposal: boolean;
    }>(),
    FA_TerminateAsset: ofType<{assetUuid: string}>(),
    FA_AcceptNewAsset: ofType<{assetUuid: string}>(),
    FA_RejectNewAsset: ofType<{assetUuid: string}>(),
    FA_AcceptUnchangedAsset: ofType<{assetUuid: string}>(),
    FA_TerminateUnchangedAsset: ofType<{assetUuid: string}>(),
    FA_AcceptUpdatedAsset: ofType<{assetUuid: string}>(),
    FA_TerminateUpdatedAsset: ofType<{assetUuid: string}>(),
    FA_RejectUpdatedAsset: ofType<{assetUuid: string}>(),
    FA_AcceptTerminatedAsset: ofType<{assetUuid: string}>(),
    FA_RejectTerminatedAsset: ofType<{assetUuid: string}>(),
    FA_ExcludeAssetFromAnalysis: ofType<{assetUuid: string}>(),
    FA_IncludeAssetInAnalysis: ofType<{assetUuid: string}>(),
  },
  {tag: 'type', value: 'payload'},
);

export type FinancialAnalysisAction = UnionOf<typeof FinancialAnalysisActions>;
