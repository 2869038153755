import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {FormModule} from '@shared/ui';
import {LabelFormlyComponent} from './label-formly.component';

@NgModule({
  declarations: [LabelFormlyComponent],
  imports: [CommonModule, FormModule, BackendValidationModule],
  exports: [LabelFormlyComponent],
})
export class LabelFormlyModule {}
