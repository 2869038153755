import {ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {mapValues} from 'lodash';
import {AssetValidators} from './forms/definitions.models';
import {AssetType} from './models/asset';
import {assetValidators} from './validations';

export const formValidators = (key: string, comparator?: number) => {
  const mapValidatorFn: Record<string, ValidatorFn | ValidationErrors> = {
    required: Validators.required,
    min: Validators.min(comparator),
    max: Validators.max(comparator),
    minLength: Validators.minLength(comparator),
    maxLength: Validators.maxLength(comparator),
  };

  return mapValidatorFn[key];
};

export const getAssetValidators = (assetType: AssetType): AssetValidators => {
  return mapValues(assetValidators[assetType], value => {
    return value.map(validatorDef => formValidators(validatorDef.name, validatorDef.comparator));
  });
};
