import {Component, EventEmitter, Input, Output} from '@angular/core';

import {IEventSituationButtonConfig} from './models/event-situation.deprecated.model';

@Component({
  selector: 'kpt-event-situation-deprecated',
  templateUrl: './event-situation.deprecated.component.html',
  styleUrls: ['./event-situation.component.scss'],
})
export class EventSituationDeprecatedComponent {
  @Input() config: IEventSituationButtonConfig[];
  @Input() dataTransferData: string;
  @Input() buttonOffset = true;
  @Output() eventAction: EventEmitter<IEventSituationButtonConfig> =
    new EventEmitter<IEventSituationButtonConfig>();
  @Output() dragActive: EventEmitter<boolean> = new EventEmitter<boolean>();
}
