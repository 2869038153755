import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'kpt-slider-range-formly',
  templateUrl: './slider-range-formly.component.html',
  styleUrls: ['./slider-range-formly.component.scss'],
})
export class SliderRangeFormlyComponent extends FieldType implements OnInit, OnDestroy {
  sliderParentForm: UntypedFormGroup;
  endFormControl: UntypedFormControl;
  startEndFormControl: UntypedFormControl;

  ngOnInit() {
    this.to.withEnd ? this.setUpWithEnd() : this.setUpWithoutEnd();
  }

  ngOnDestroy(): void {}

  private setUpWithEnd(): void {
    this.endFormControl = new UntypedFormControl();
    this.form.addControl('end', this.endFormControl);
    this.startEndFormControl = new UntypedFormControl();

    const startValue: string = this.convertInput(this.formControl.value);
    const endValue: string = !!this.to.endValue
      ? this.convertInput(this.to.endValue)
      : this.field.defaultValue;

    this.startEndFormControl.setValue([startValue, endValue]);

    this.sliderParentForm = new UntypedFormGroup({
      slider: this.startEndFormControl,
    });

    this.startEndFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(values => {
      this.formControl.patchValue(this.convertOutput(values[0]), {emitEvent: false});
      this.endFormControl.patchValue(this.convertOutput(values[1]), {emitEvent: false});
    });
  }

  private setUpWithoutEnd(): void {
    this.sliderParentForm = new UntypedFormGroup({
      slider: this.formControl,
    });
  }

  private convertInput(value: any) {
    return this.to.convertInput ? this.to.convertInput(value) : value;
  }

  private convertOutput(value: number) {
    return this.to.convertOutput ? this.to.convertOutput(value) : value;
  }
}
