import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition, RetirementInputMode} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {RetirementAsset} from '@shared/analysis/models/objectives';
import {selectFamilyHead} from '@shared/analysis/operators';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class RetirementAssetType extends BaseAssetType {
  constructor(private store: Store<State>) {
    super();
  }

  async create(opts: {stakeholderUuid?: string}): Promise<RetirementAsset> {
    const familyHead = await this.store.pipe(selectFamilyHead(), take(1)).toPromise();
    const retirementDate = this.getRetirementDate(familyHead.age);

    return {
      type: AssetType.Retirement,
      assetUuid: this.createId(),
      value: 0,
      name: null,
      description: null,
      familyMemberUuid: opts.stakeholderUuid ?? familyHead.sugarUuid,
      start: retirementDate,
      monthlyRent: 0,
      retirementInputMode: RetirementInputMode.Value,
      pensionDuration: 20,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.Retirement],
      type: AssetType.Retirement,
    };
  }

  private getRetirementDate(currentAge: number): string {
    return moment()
      .startOf('year')
      .add(65 - currentAge, 'years')
      .format('YYYY-MM-DD');
  }
}
