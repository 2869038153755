import {Pipe, PipeTransform} from '@angular/core';
import {RiskUnit} from '@shared/analysis/models/risks';

@Pipe({
  standalone: true,
  name: 'riskUnitLabel',
})
export class RiskUnitLabelPipe implements PipeTransform {
  transform(unit: RiskUnit): string {
    return unit === 'million' ? 'mil. Kč' : 'Kč';
  }
}
