<kpt-button
  *ngIf="variant === 'button'"
  icon="icon--plus"
  type="secondary"
  (action)="addAsset()"
  [label]="text"
>
</kpt-button>

<a href *ngIf="variant === 'link'" class="link" (click)="addAsset(); $event.preventDefault()">
  {{ text }}
</a>

<kpt-button *ngIf="variant === 'icon'" icon="icon--edit" type="simple" (action)="addAsset()">
</kpt-button>

<kpt-asset-link
  class="d-none"
  [assetType]="newAssetType"
  [queryParams]="{
    relatedObjectiveUuid: relatedObjectiveUuid,
    relatedPropertyUuid: relatedPropertyUuid,
    stakeholderUuid: stakeholderUuid,
    proposal: situation === Situation.AdvisorProposed || situation === Situation.ClientProposed,
    advisorProposalState:
      situation === Situation.AdvisorProposed || situation === Situation.ClientProposed
        ? 'new'
        : null
  }"
  [situation]="situation"
>
  <ng-template let-queryParams="queryParams" let-routerLink="routerLink">
    <a #createElem [routerLink]="routerLink" [queryParams]="queryParams"> </a>
  </ng-template>
</kpt-asset-link>
