import {Asset, AssetType, RegularPaymentType} from '@shared/analysis/models/asset';
import {MainIncomesAsset} from '@shared/analysis/models/main-incomes';
import {Frequency, UnitFunction} from '@shared/analysis/models/unit';
import {sum} from 'lodash';
import * as moment from 'moment';
import {Moment, MomentInput, unitOfTime} from 'moment';

type DurationConstructor = unitOfTime.DurationConstructor;

export function getEnd(fn: UnitFunction, date: moment.Moment) {
  let end: moment.Moment;

  if (fn.end) {
    if (typeof fn.end === 'number') end = dateAdd(fn.start, fn.end, fn.frequency);
    else end = moment(fn.end);

    if (date <= end) end = date;
  } else end = date;

  return end;
}

export function getEndDate(
  start: string,
  end: string | number,
  paymentType: RegularPaymentType,
): string {
  return typeof end === 'number'
    ? dateAdd(start, end, regularPaymentTypeToFrequency(paymentType)).format('YYYY-MM-DD')
    : end;
}

export function getPeriodsCount(
  start: moment.Moment,
  end: moment.Moment,
  frequency: Frequency,
): number {
  if (start.isSame(end)) return 0;
  if (!frequency) return 1;
  return dateDiff(end, start, frequency);
}

export function dateDiff(inputA: MomentInput, inputB: MomentInput, unit: Frequency): number {
  switch (unit) {
    case Frequency.Quarter:
      return Math.floor(moment(inputA).diff(inputB, 'month') / 3);
    case Frequency.HalfYear:
      return Math.floor(moment(inputA).diff(inputB, 'month') / 6);
    default:
      return moment(inputA).diff(inputB, unit);
  }
}

export function dateAdd(input: MomentInput, amount: number, unit: Frequency): Moment {
  let unitParam: DurationConstructor;

  switch (unit) {
    case Frequency.Quarter:
      amount *= 3;
      unitParam = Frequency.Month;
      break;
    case Frequency.HalfYear:
      amount *= 6;
      unitParam = Frequency.Month;
      break;
    default:
      unitParam = unit;
  }

  return moment(input).add(amount, unitParam);
}

export const frequencyRate: Record<Frequency, number> = {
  [Frequency.Day]: 1 / 360,
  [Frequency.Week]: 7 / 360, // or 1 / 52?
  [Frequency.Month]: 1 / 12,
  [Frequency.Quarter]: 3 / 12,
  [Frequency.HalfYear]: 6 / 12,
  [Frequency.Year]: 1,
};

export function regularPaymentTypeToFrequency(paymentType: RegularPaymentType): Frequency {
  switch (paymentType) {
    case RegularPaymentType.Month:
      return Frequency.Month;
    case RegularPaymentType.Quarter:
      return Frequency.Quarter;
    case RegularPaymentType.HalfYear:
      return Frequency.HalfYear;
    case RegularPaymentType.Year:
      return Frequency.Year;
  }
}

export function getCurrentIncome(familyMemberId: string, date: string, assets: Asset[]): number {
  const incomeAssetTypes: AssetType[] = [AssetType.Employment, AssetType.Contractor];

  return sum(
    assets
      .filter(asset => incomeAssetTypes.includes(asset.type))
      .filter((asset: MainIncomesAsset) => asset.familyMemberUuid === familyMemberId)
      .filter((asset: MainIncomesAsset) => dateWithinStartEnd(date, asset.start, asset.end))
      .map((asset: MainIncomesAsset) => asset.value),
  );
}

export function dateWithinStartEnd(
  date: string,
  start: string,
  end: string | number | undefined,
  frequency: Frequency = Frequency.Month,
): boolean {
  if (end) {
    if (typeof end === 'number') end = dateAdd(start, end, frequency).format('YYYY-MM-DD');
    return start <= date && date <= end;
  } else {
    return start <= date;
  }
}
