<div>
  <kpt-button
    *ngIf="!productId && group === LifeInsuranceFormGroup.Recommended"
    type="primary"
    icon="icon--plus"
    label="Vybrat produkt"
    class="select-product"
    (action)="selectProduct()"
  >
  </kpt-button>

  <div *ngIf="productId" class="d-flex align-items-center">
    <div class="names">
      <div class="partner" [title]="partnerName">{{ partnerName }}</div>
      <div class="product" [title]="productName">{{ productCode }}</div>
    </div>
    <a
      *ngIf="group === LifeInsuranceFormGroup.Recommended"
      class="icon--interactive"
      (click)="selectProduct()"
    >
      <span class="icon icon--edit"></span>
    </a>
  </div>
</div>
