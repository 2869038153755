import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {AssetType} from 'src/shared/analysis/models/asset';

@Component({
  selector: 'kpt-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  @Input() situation: Situation;

  @Input() buttonLink: string;

  @Input() queryParams: Record<string, string> = {};

  @Input() objectiveType: ObjectiveType;

  @Input() relatedObjectiveUuid: string;

  @Input() relatedPropertyUuid: string;

  @Input() stakeholderUuid: string;

  @Input() assetType: AssetType;

  @Input() showAddAssetButton = true;
}
