import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

export interface ButtonGroupItem {
  label: string;
  value: string;
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  imports: [CommonModule],
})
export class ButtonGroupComponent {
  @Input() items: ButtonGroupItem[];
  @Input() selectedValue: string;

  @Output() selectedValueChange = new EventEmitter<string>();

  selectItem(item: ButtonGroupItem) {
    this.selectedValueChange.emit(item.value);
  }
}
