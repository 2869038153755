import {Component, ElementRef, forwardRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {CommonFieldComponent} from '../common-field/common-field.component';

@Component({
  selector: 'kpt-switch',
  templateUrl: './switch.component.html',
  providers: [
    ...getProvidersFor(SwitchComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => SwitchComponent)},
  ],
})
export class SwitchComponent extends CommonFieldComponent {
  @ViewChild('field', {static: true})
  field: ElementRef;

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  checked = false;

  @Input()
  labelPosition: 'before' | 'after' = 'after';

  /**
   * You can provide your own custom values to use instead of true/false.
   */
  @Input()
  optionsMap: {true: string; false: string};

  writeValue(val: boolean | string) {
    if (this.optionsMap) {
      this.checked = this.optionsMap.true === val?.toString();
    } else {
      this.checked = Boolean(val);
    }
  }

  onCheckedChange(e: MatSlideToggleChange) {
    let newValue: boolean | string;
    if (this.optionsMap) {
      newValue = this.optionsMap[e.checked ? 'true' : 'false'];
    } else {
      newValue = e.checked;
    }

    this.onChange(newValue);
    this.valueChange.emit(newValue);
    this.onTouched(null);
  }
}
