<div class="edit-selected" *ngIf="formControl.value">
  <span (click)="edit(formControl.value); preventDefault($event)">Upravit</span>
</div>
<div>
  <kpt-select
    [formControl]="formControl"
    [label]="to.label"
    [options]="options$ | async"
    [required]="to.required"
    [showErrorsOn]="true"
    [value]="to.value"
    [clearable]="to.clearable"
    (selected)="to.change ? to.change(field, $event) : noop()"
    [ngOptionTmpRef]="ngOptionTmp"
    [ngFooterTmpRef]="ngFooterTmp"
  >
  </kpt-select>
</div>

<ng-template #ngOptionTmp let-item="item">
  <div class="d-flex justify-content-between item-wrapper">
    <div>{{ item.label }}</div>
    <div class="inline-edit-asset" (click)="edit(item.key); preventDefault($event)">
      <kpt-button type="secondary" size="sm" customClass="btn--icon p-0" label="Upravit">
      </kpt-button>
    </div>
  </div>
</ng-template>

<ng-template #ngFooterTmp>
  <div class="add-asset ng-option">
    <kpt-button
      icon="icon--plus"
      type="secondary"
      size="sm"
      (action)="add(); preventDefault($event)"
      label="Přidat položku"
    >
    </kpt-button>
  </div>
</ng-template>
