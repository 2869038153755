import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Advisor} from '@generated/defs/Advisor';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {ClientPageConfig} from 'src/app/modules/client/client-page.config';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {getConsultantMenuItems} from 'src/app/modules/consultant/consultant-page.config';
import {MenuItem} from 'src/shared/lib';

interface MenuItemExtended extends MenuItem {
  appendClientsUrl?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'kpt-advisor-section',
  templateUrl: 'advisor-section.component.html',
  styleUrls: ['advisor-section.component.scss'],
})
export class AdvisorSectionComponent {
  @Input()
  advisor$: Observable<Advisor>;

  @Input()
  hasClientSelected: boolean;

  @Output()
  navigate = new EventEmitter<MenuItemExtended>();

  itemsPrimary: ModuleCard[] = [];
  itemsSecondary: ModuleCard[] = [];
  itemsOthers: ModuleCard[] = [];
  itemsConsultant: MenuItem[] = getConsultantMenuItems().filter(item => !item.disabled);

  constructor(private config: ClientPageConfig) {
    this.config
      .getCardBoard()
      .pipe(untilDestroyed(this))
      .subscribe(cards => {
        this.itemsPrimary =
          cards.complexPlan?.cards.filter(card => !card.disabled && card.showInAdvisorMenu) ?? [];
        this.itemsSecondary = cards.lifeSituation.cards.filter(
          card => !card.disabled && card.showInAdvisorMenu,
        );
        this.itemsOthers = cards.otherTools.cards.filter(
          card => !card.disabled && card.showInAdvisorMenu,
        );
      });
  }

  getMenuItems(): MenuItemExtended[][] {
    return [
      this.itemsPrimary,
      this.itemsSecondary,
      this.itemsOthers,
      this.itemsConsultant,
      [
        {
          target: '/logout',
          label: 'Odhlásit',
        },
      ],
    ];
  }

  navigateTo(item: MenuItemExtended) {
    this.navigate.emit(item);
  }
}
