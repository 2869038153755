import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {ITooltipDirection} from '@lib/components';
import {formatCZK} from '@lib/utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {debounceTime} from 'rxjs/operators';
import {
  InsuranceGroupVM,
  PersonalInsuranceProposalVM,
  SumRowVM,
} from 'src/app/modules/life-insurance-old/5-proposals/proposals.selectors';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {LifeInsuranceFormGroup} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {State} from 'src/store';

enum RowError {
  noError = 0,
  lessThenExpenses,
  greaterThenIncome,
  lessThenIncome,
  greaterThenExpenses,
}

@UntilDestroy()
@Component({
  selector: 'kpt-sum-column',
  templateUrl: './sum-column.component.html',
  styleUrls: ['./sum-column.component.scss'],
})
export class SumColumnComponent implements OnInit, OnChanges, OnDestroy {
  @Input() proposal: PersonalInsuranceProposalVM;
  @Input() group: LifeInsuranceFormGroup;
  ITooltipDirection = ITooltipDirection;

  headings = {
    [LifeInsuranceFormGroup.Current]: 'Současná ochrana',
    [LifeInsuranceFormGroup.Selected]: 'Moje volba',
    [LifeInsuranceFormGroup.Recommended]: 'Doporučení poradce',
    [LifeInsuranceFormGroup.Final]: 'Řešení zvolené klientem',
  };
  helps = {
    [LifeInsuranceFormGroup.Current]:
      'Pojistná ochrana, kterou mám, na základě existujících smluv, nyní sjednanou.',
    [LifeInsuranceFormGroup.Selected]:
      'Pojistná ochrana, kterou jsem si zvolil/a v kapitole Moje volba',
    [LifeInsuranceFormGroup.Recommended]:
      'Pojistná ochrana, kterou mi doporučuje poradce jako ideální ' +
      '(šitou na míru mému aktuálnímu stavu).',
  };
  risksForm: UntypedFormArray;

  constructor(private store: Store<State>, private fb: UntypedFormBuilder) {}

  get insuranceGroup(): InsuranceGroupVM {
    return this.proposal[this.group];
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal.firstChange) {
      this.createForm();
    } else {
      this.updateForm();
    }
  }

  ngOnDestroy() {}

  trackByRiskId(_: number, item: SumRowVM) {
    return item.riskId;
  }

  getRowError(rowIndex: number): RowError {
    if (this.group !== 'recommended') return RowError.noError;

    const value = this.insuranceGroup.sumRows[rowIndex].value;
    const minValue = this.proposal.riskLimits[rowIndex].min;
    const maxValue = this.proposal.riskLimits[rowIndex].max;
    const required = this.proposal.riskLimits[rowIndex].required;

    if (minValue <= maxValue) {
      if (required) {
        if (minValue && value < minValue) return RowError.lessThenExpenses;
        if (maxValue && value > maxValue) return RowError.greaterThenIncome;
      } else {
        if (value && minValue && value < minValue) return RowError.lessThenExpenses;
        if (value && maxValue && value > maxValue) return RowError.greaterThenIncome;
      }
    } else {
      if (required) {
        if (minValue && value < maxValue) return RowError.lessThenIncome;
        if (maxValue && value > minValue) return RowError.greaterThenExpenses;
      } else {
        if (value && minValue && value < maxValue) return RowError.lessThenIncome;
        if (value && maxValue && value > minValue) return RowError.greaterThenExpenses;
      }
    }

    return RowError.noError;
  }

  getRowErrorMsg(rowIndex: number): string {
    switch (this.getRowError(rowIndex)) {
      case RowError.lessThenExpenses: {
        const minValue = this.proposal.riskLimits[rowIndex].min;
        return (
          `KAPITOL doporučuje minimální částku na zajištění výdajů ${formatCZK(minValue)}, ` +
          `proto není možné zadat nižší.`
        );
      }
      case RowError.greaterThenIncome: {
        const maxValue = this.proposal.riskLimits[rowIndex].max;
        return `KAPITOL doporučuje optimální částku na zajištění příjmů ${formatCZK(maxValue)}.`;
      }
      case RowError.lessThenIncome: {
        const minValue = this.proposal.riskLimits[rowIndex].max;
        return (
          `KAPITOL doporučuje minimální částku na zajištění příjmů ${formatCZK(minValue)}, ` +
          `proto není možné zadat nižší.`
        );
      }
      case RowError.greaterThenExpenses: {
        const maxValue = this.proposal.riskLimits[rowIndex].min;
        return `KAPITOL doporučuje optimální částku na zajištění výdajů ${formatCZK(maxValue)}.`;
      }
      default:
        return '';
    }
  }

  private createForm() {
    this.risksForm = this.fb.array(
      this.insuranceGroup.sumRows.map(sumRow => {
        const fromGroup = this.fb.group({
          value: sumRow.value,
          age: sumRow.age,
        });
        if (sumRow.editable) {
          fromGroup.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((row: SumRowVM) => {
              this.store.dispatch(
                LifeInsuranceActions.updateCustomRisk({
                  customRiskDefId: sumRow.riskId,
                  value: row.value,
                  age: row.age,
                }),
              );
            });
        }
        return fromGroup;
      }),
    );
  }

  private updateForm() {
    if (this.insuranceGroup.sumRows.length !== this.risksForm.length) {
      this.createForm();
    } else {
      this.risksForm.setValue(
        this.insuranceGroup.sumRows.map(sumRow => ({value: sumRow.value, age: sumRow.age})),
      );
    }
  }
}
