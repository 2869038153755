import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {PortalClient} from '@generated/model';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {
  CreateClientActionTypes,
  CreateClientError,
  CreateClientStart,
  CreateClientSuccess,
} from '../actions/create-client.actions';

@Injectable()
export class CreateClientEffects {
  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateClientActionTypes.CreateClientStart),
      map((action: CreateClientStart) => action.payload),
      switchMap((data: any) =>
        this.integrationsService.createClient({data}).pipe(
          map((client: PortalClient) => new CreateClientSuccess(client)),
          catchError((error: HttpErrorResponse) => of(new CreateClientError(error))),
          takeUntil(this.actions$.pipe(ofType(CreateClientActionTypes.CreateClientCancel))),
        ),
      ),
    ),
  );

  constructor(private integrationsService: IntegrationsService, private actions$: Actions) {}
}
