import {FormlyFieldConfig} from '@ngx-formly/core';
import {isNil} from 'lodash';
import {sumValue} from './forms.helpers';

export function inputModeChange(field: FormlyFieldConfig, event: boolean) {
  if (event) sumValue(field);
}

export function monthValueChange(field: FormlyFieldConfig, value: number) {
  const yearValue = !isNil(value) && !isNaN(value) ? Math.round(value * 12) : null;
  field.form.patchValue({yearValue});
}

export function yearValueChange(field: FormlyFieldConfig, value: number) {
  const monthValue = !isNil(value) && !isNaN(value) ? Math.round(value / 12) : null;
  field.form.patchValue({value: monthValue});
}
