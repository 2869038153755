import {Component, HostListener, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Start as GetAdvisor} from '@generated/store/integrations/getAdvisor/states/actions';
import {getGetAdvisorStateSelector} from '@generated/store/integrations/getAdvisor/states/reducers';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {DialogService} from '@shared/services/modal.service';
import {FeatureFlagsModalComponent} from '@shared/ui/sidebar/feature-flags-modal/feature-flags-modal.component';
import {MenuTabs, SidebarService} from '@shared/ui/sidebar/sidebar.service';
import {map} from 'rxjs/operators';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';
import {getFamilyMembersState} from 'src/store/selectors/family-member.selectors';
import {replenish} from 'src/store/store-helpers';

@UntilDestroy()
@Component({
  selector: 'kpt-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy {
  user$ = this.store.pipe(
    select(getFamilyMembersState),
    map(state => state.familyMembers.filter(m => m.familyHead)[0]),
  );

  advisor$ = replenish(getGetAdvisorStateSelector, this.store, GetAdvisor).pipe(
    map(advisorState => advisorState.data),
  );

  show$ = this.sidebarService.menuOpened$;
  activeTab$ = this.sidebarService.activeTab$;

  familyUuid: string;

  constructor(
    private store: Store<State>,
    private router: Router,
    private sidebarService: SidebarService,
    public featureFlagsService: FeatureFlagsService,
    private dialogService: DialogService,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      const isClientPage = this.router.url.split('/').filter(Boolean)[1] === 'client';
      this.setActiveTab(isClientPage ? 'client' : 'advisor');
    });

    this.store
      .pipe(
        select(getFamilyMembersState),
        map(state => state.familyUuid),
        untilDestroyed(this),
      )
      .subscribe(familyUuid => (this.familyUuid = familyUuid));
  }

  ngOnDestroy() {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent) {
    this.close();
  }

  close() {
    this.sidebarService.close();
  }

  setActiveTab(activeTab: MenuTabs) {
    this.sidebarService.setActiveTab(activeTab);
  }

  openFeatureFlagsModal() {
    this.dialogService.load(
      FeatureFlagsModalComponent,
      {},
      {
        size: 'md',
      },
    );
  }
}
