import {createSelector} from '@ngrx/store';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {selectFinancialIndependenceObjectives} from 'src/app/modules/financial-plan/store/financial-independence-objective.selectors';
import {selectFinancialResourcesObjectives} from 'src/app/modules/financial-plan/store/financial-resources-objective.selectors';

export const selectInvestmentObjectives = (opts?: {filterSelectedAssets: boolean}) =>
  createSelector(
    selectFinancialResourcesObjectives(ObjectiveType.FinancialResources),
    selectFinancialIndependenceObjectives,
    selectFinancialResourcesObjectives(ObjectiveType.Housing),
    selectFinancialResourcesObjectives(ObjectiveType.Reserve),
    (resources, independence, housing, reserve) => {
      let objectives = [...resources, ...independence, ...housing, ...reserve];
      if (opts?.filterSelectedAssets) {
        objectives = objectives.filter(objective => objective.objectiveAsset.selected);
      }
      return objectives;
    },
  );
