import {LifeInsuranceFile} from '@generated/defs/LifeInsuranceFile';
import {Choice, SixMonthReserve, Summary, Termination} from '@generated/model';
import {createAction, props, union} from '@ngrx/store';
import {InsuranceType} from 'src/app/modules/life-insurance-old/6-summary/summary.models';
import {
  LifeInsuranceCurrentInsurance,
  LifeInsuranceFormGroup,
  LifeInsurancePerson,
  LifeInsuranceRiskUniqueIdent,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {LifeInsuranceForBE} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {Provision, RiskId} from 'src/store/models/risk.models';

export const loadData = createAction(
  '[Life Insurance] Load Data',
  props<{data: LifeInsuranceForBE}>(),
);

export const loadSummary = createAction(
  '[Life Insurance] Load Summaries',
  props<{summaries: Summary[]}>(),
);
export const updatePersons = createAction(
  '[Life Insurance] Update Persons',
  props<{persons: LifeInsurancePerson[]; debtsTogether: boolean; debts?: number}>(),
);

export const setPersons = createAction(
  '[Life Insurance] Set Persons',
  props<{persons: LifeInsurancePerson[]}>(),
);

export const resetInput = createAction(
  '[Life Insurance] Reset Input',
  props<{persons: LifeInsurancePerson[]}>(),
);

export const selectRisk = createAction(
  '[Life Insurance] Select Risk',
  props<{personId: string; provision: Provision; riskId: string}>(),
);

export const selectRisks = createAction(
  '[Life Insurance] Select Risks',
  props<{
    membersRisks: Record<RiskId, {personId: string; provision: Provision; riskId: string}[]>;
  }>(),
);

export const updateIndividualRisks = createAction(
  '[Life Insurance] Update Individual Risks',
  props<{zeroRisks: LifeInsuranceRiskUniqueIdent[]}>(),
);

export const setFinancialAnalysisChangeFlag = createAction(
  '[Life Insurance] Set financial analysis change flag',
  props<{changed: boolean}>(),
);

export const selectProvision = createAction(
  '[Life Insurance] Select Provision',
  props<{personId: string; provision: Provision}>(),
);

export const changeIndividualSelection = createAction(
  '[Life Insurance] Change Individual Selection',
  props<{personId: string; selected: boolean}>(),
);

export const updateReservesOrPension = createAction(
  '[Life Insurance] Update Reserves Or Pension',
  props<{personId: string; changes: Partial<LifeInsurancePerson>}>(),
);

export const updateAge = createAction(
  '[Life Insurance] Update Age',
  props<{personId: string; riskId: string; age: number}>(),
);

export const updateChoice = createAction(
  '[Life Insurance] Update Choice',
  props<{choice: Choice}>(),
);

export const addCustomRisk = createAction(
  '[Life Insurance] Add Custom Risk',
  props<{personId: string; myChoice: boolean}>(),
);

export const deleteCustomRisk = createAction(
  '[Life Insurance] Delete Custom Risk',
  props<{customRiskDefId: string}>(),
);

export const updateCustomRisk = createAction(
  '[Life Insurance] Update Custom Risk',
  props<{customRiskDefId: string; label?: string; value?: number; age?: number}>(),
);

export const toggleInsuranceGroup = createAction(
  '[Life Insurance] Toggle Group',
  props<{personId: string; group: LifeInsuranceFormGroup}>(),
);

export const updateCurrentInsurance = createAction(
  '[Life Insurance] Update Current Insurance',
  props<{personId: string; currentInsurance: LifeInsuranceCurrentInsurance}>(),
);

export const addCurrentGroupForm = createAction(
  '[Life Insurance] Add Current Group Form',
  props<{personId: string}>(),
);

export const addRecommendedGroupForm = createAction(
  '[Life Insurance] Add Recommended Group Form',
  props<{personId: string; stakeholderId: string}>(),
);

export const addAlternativeInsuranceGroupForm = createAction(
  '[Life Insurance] Add Alternative Group Form',
  props<{personId: string; alternativeToId: number; stakeholderId: string}>(),
);

export const deleteInsuranceGroupForm = createAction(
  '[Life Insurance] Delete Group Form',
  props<{personId: string; group: LifeInsuranceFormGroup; formId: number}>(),
);

export const cancelInsuranceGroupForm = createAction(
  '[Life Insurance] Cancel Group Form',
  props<{personId: string; group: LifeInsuranceFormGroup; formId: number}>(),
);

export const restoreInsuranceGroupForm = createAction(
  '[Life Insurance] Restore Group Form',
  props<{personId: string; group: LifeInsuranceFormGroup; formId: number}>(),
);

export const updateFormName = createAction(
  '[Life Insurance] Update Form Name',
  props<{personId: string; group: LifeInsuranceFormGroup; formId: number; name: string}>(),
);

export const updateFormPrice = createAction(
  '[Life Insurance] Update Form Price',
  props<{personId: string; group: LifeInsuranceFormGroup; formId: number; price: number}>(),
);

export const updateFormProduct = createAction(
  '[Life Insurance] Update Form Product',
  props<{formId: number; productId: number; insuranceType: InsuranceType}>(),
);

export const updateStakeholder = createAction(
  '[Life Insurance] Update Stakeholder',
  props<{formId: number; stakeholderId: string}>(),
);

export const updateContract = createAction(
  '[Life Insurance] Update Contract',
  props<{formId: number; contractId: number}>(),
);

export const addFormFile = createAction(
  '[Life Insurance] Add Form File',
  props<{
    personId: string;
    group: LifeInsuranceFormGroup;
    formId: number;
    file: LifeInsuranceFile;
  }>(),
);

export const updateFormFileName = createAction(
  '[Life Insurance] Update Form File Name',
  props<{dmsUuid: string; name: string}>(),
);

export const removeFormFiles = createAction(
  '[Life Insurance] Remove Form Files',
  props<{dmsUuids: string[]}>(),
);

export const updateRiskForm = createAction(
  '[Life Insurance] Update Risk Form',
  props<{
    personId: string;
    group: LifeInsuranceFormGroup;
    formId: number;
    riskId: string;
    value: number;
    age: number;
  }>(),
);

export const calculateInsurance = createAction('[Life Insurance] Calculate Insurance');

export const updateSummaries = createAction(
  '[Life Insurance] Update Summaries',
  props<{summaries: Summary[]; familyUuid: string}>(),
);

export const initializeSummariesProgress = createAction(
  '[Life Insurance] Initialize Summary Participant IDs',
  props<{unfinishedParticipantIds: string[]; selectedParticipantId: string}>(),
);

export const pickParticipantForSummaryForm = createAction(
  '[Life Insurance] Pick Participant For Summary Form',
  props<{selectedParticipantId: string}>(),
);

export const finishParticipantSummaryForm = createAction(
  '[Life Insurance] Finish Participant Summary Form',
  props<{finishedParticipantId: string}>(),
);

export const addGeneralFile = createAction(
  '[Life Insurance] Add General File',
  props<{personId: string; file: LifeInsuranceFile}>(),
);

export const updateGeneralFileName = createAction(
  '[Life Insurance] Update General File Name',
  props<{dmsUuid: string; name: string}>(),
);

export const removeGeneralFile = createAction(
  '[Life Insurance] Remove General File',
  props<{dmsUuid: string}>(),
);

export const setAdditionalAnalysisData = createAction(
  '[Life Insurance] Set additional analysis data',
  props<{debtsTogether: boolean}>(),
);

export const terminateAppointment = createAction(
  '[Life Insurance] Terminate appointment',
  props<{termination: Termination; familyUuid: string}>(),
);

export const useOtherIncome = createAction(
  '[Life Insurance] Use Other Income',
  props<{personId: string; use: boolean}>(),
);

export const updateOtherIncomeUuids = createAction(
  '[Life Insurance] Update Other Income Uuids',
  props<{personId: string; assetUuids: string[]}>(),
);

export const usePaymentProtectionInsurance = createAction(
  '[Life Insurance] Use Payment Protection Insurance',
  props<{personId: string; use: boolean}>(),
);

export const useSixMonthReserve = createAction(
  '[Life Insurance] Use Six Month Reserve',
  props<{personId: string; use: boolean}>(),
);

export const updateSixMonthReserve = createAction(
  '[Life Insurance] Update Six Month Reserve',
  props<{personId: string; sixMonthReserve: SixMonthReserve}>(),
);

const allActions = union({
  loadData,
  loadSummary,
  updatePersons,
  setPersons,
  resetInput,
  selectRisk,
  selectRisks,
  updateIndividualRisks,
  selectProvision,
  changeIndividualSelection,
  updateReservesOrPension,
  updateAge,
  updateChoice,
  addCustomRisk,
  deleteCustomRisk,
  updateCustomRisk,
  toggleInsuranceGroup,
  updateCurrentInsurance,
  addCurrentGroupForm,
  addRecommendedGroupForm,
  addAlternativeInsuranceGroupForm,
  deleteInsuranceGroupForm,
  cancelInsuranceGroupForm,
  restoreInsuranceGroupForm,
  updateFormName,
  updateFormPrice,
  updateFormProduct,
  updateStakeholder,
  updateContract,
  updateRiskForm,
  calculateInsurance,
  updateSummaries,
  initializeSummariesProgress,
  pickParticipantForSummaryForm,
  finishParticipantSummaryForm,
  addFormFile,
  updateFormFileName,
  removeFormFiles,
  setFinancialAnalysisChangeFlag,
  addGeneralFile,
  updateGeneralFileName,
  removeGeneralFile,
  setAdditionalAnalysisData,
  terminateAppointment,
  useOtherIncome,
  updateOtherIncomeUuids,
  usePaymentProtectionInsurance,
  useSixMonthReserve,
  updateSixMonthReserve,
});

export type LifeInsuranceActionsUnion = typeof allActions;
