import {AfterContentInit, Component} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';
import {trackByIndex} from '@shared/lib';
import {Observable} from 'rxjs';
import {TabFormlyComponent} from '../tab-formly/tab-formly.component';
import {TabsFormlyService} from './tabs-formly.service';

@Component({
  selector: 'kpt-tabs-formly',
  templateUrl: './tabs-formly.component.html',
  styleUrls: ['./tabs-formly.component.scss'],
  providers: [TabsFormlyService],
})
export class TabsFormlyComponent extends FieldWrapper implements AfterContentInit {
  tabs$: Observable<TabFormlyComponent[]>;

  trackByIndex = trackByIndex;

  constructor(private tabsService: TabsFormlyService) {
    super();
  }

  ngAfterContentInit() {
    this.tabsService.initialActiveIndex = this.to.activeTabIndex;
    this.tabsService.initialActiveId = this.to.activeTabId;
    this.tabs$ = this.tabsService.getTabs$();
  }

  setActiveTab(tab: TabFormlyComponent) {
    this.tabsService.setActiveTab(tab);
  }
}
