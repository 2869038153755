<kpt-radio-group
  [formControl]="formControl"
  [required]="to.required"
  [readonly]="to.readonly"
  [labelTemplate]="to.labelTemplate"
  [disabledField]="to.disabled"
  [itemClass]="'d-inline-block mr-6'"
  (change)="to.change ? to.change(field, $event) : noop()"
  [options]="to.options"
  [label]="to.label"
>
</kpt-radio-group>
