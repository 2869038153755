<kpt-modal
  *ngIf="modalData$ | async as modalData"
  size="md"
  [isModalOpen]="modalData.open"
  (close)="closeModal()"
>
  <!-- investment -->
  <div class="guard-content" *ngIf="modalData.module === 'investment'">
    <kpt-alert *ngFor="let message of modalData.messages?.error" type="danger">
      {{ message }}
    </kpt-alert>

    <kpt-alert *ngFor="let message of modalData.messages?.warning" type="warning">
      {{ message }}
    </kpt-alert>

    <div class="guard-buttons">
      <kpt-button (action)="closeModal()" label="Zavřít" type="primary"> </kpt-button>

      <kpt-button
        *ngIf="modalData.restrictType === 'loose'"
        (action)="proceedAnyway()"
        label="Přesto pokračovat"
        [hasOutline]="true"
        type="primary"
      >
      </kpt-button>
    </div>
  </div>

  <!-- life insurance -->
  <div class="guard-content" *ngIf="modalData.module === 'life-insurance'">
    <kpt-alert type="warning">
      Rozdíl mezi příjmy a výdaji není vhodný pro investiční životní pojištění.
    </kpt-alert>

    <div class="guard-buttons">
      <kpt-button type="primary" (action)="closeModal()" label="Zavřít"> </kpt-button>

      <kpt-button
        [hasOutline]="true"
        type="primary"
        (action)="proceedAnyway()"
        label="Přesto pokračovat"
      >
      </kpt-button>
    </div>
  </div>
</kpt-modal>
