/* tslint:disable:max-line-length max-classes-per-file */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {GetRisksParams} from '../../../../controllers/Analysis';
import * as __model from '../../../../model';

export enum Actions {
  START = '[Analysis getRisks] Start',
  SUCCESS = '[Analysis getRisks] Success',
  ERROR = '[Analysis getRisks] Error',
}

export class Start implements Action {
  readonly type = Actions.START;
  constructor(public payload: GetRisksParams) {}
}

export class Success implements Action {
  readonly type = Actions.SUCCESS;
  constructor(public payload: __model.RiskAdditionalData) {}
}

export class Error implements Action {
  readonly type = Actions.ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export type GetRisksAction = Start | Success | Error;
