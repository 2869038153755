import {Component} from '@angular/core';
import {Asset} from '@shared/analysis/models/asset';
import {Subject} from 'rxjs';
import {AssetDefinition} from 'src/shared/analysis/forms/definitions.models';
import {FormlyFormHandlerService} from 'src/shared/ui/formly/formly-form-handler.service';

export enum AssetModalSize {
  Small = 'modal-sm',
  Medium = 'modal-md',
  Large = 'modal-lg',
}

@Component({
  selector: 'kpt-asset-detail-modal-wrapper',
  templateUrl: './asset-detail-modal-wrapper.component.html',
  styleUrls: ['./asset-detail-modal-wrapper.component.scss'],
  providers: [FormlyFormHandlerService],
})
export class AssetDetailModalWrapperComponent {
  assetDefinition: AssetDefinition = {} as AssetDefinition;
  assetUuid: any = null;
  showDeleteButton = false;
  defaults = {};
  clone = false;
  result$ = new Subject<Asset>();

  windowClass = '';
}
