import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {MessageType} from '@lib/models';
import {NotificationService} from '@lib/services/notification.service';
import {interval, Observable, Subject} from 'rxjs';
import {filter, skip, switchMap, takeUntil, tap, throttleTime, timeInterval} from 'rxjs/operators';

export const versionFile = '/assets/version.json';
interface Version {
  c: string;
}

const POLL_RATE = 1000 * 60;
const ROUTE_CHANGE_RATE = 1000 * 30;
const MESSAGE = 'Právě vyšla nová verze aplikace. Aktualizujte, prosím, tuto stránku.';

@Injectable()
export class VersionCheckerService {
  private initialBuildId = '';
  private complete$ = new Subject<void>();
  private notificationVisible = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  init() {
    this.router.events
      .pipe(
        skip(1),
        filter(event => event instanceof NavigationStart),
        filter(_ => !this.notificationVisible),
        throttleTime(ROUTE_CHANGE_RATE),
        switchMap(_ => this.getBuildId()),
        tap(_ => {
          this.complete$.next();
          this.complete$.complete();
          this.complete$ = new Subject<void>();
          this.startPolling(false);
        }),
        filter(newBuildInfo => this.isNewVersion(newBuildInfo)),
      )
      .subscribe(_ => this.showNotification());

    this.startPolling();
  }

  private startPolling(initial = true) {
    if (initial) this.getInitialBuildId();

    interval(POLL_RATE)
      .pipe(
        takeUntil(this.complete$),
        timeInterval(),
        filter(_ => !this.notificationVisible),
        switchMap(_ => this.getBuildId()),
        filter(newBuildInfo => this.isNewVersion(newBuildInfo)),
      )
      .subscribe(_ => this.showNotification());
  }

  private showNotification() {
    this.notificationService.dispatchMessage(MESSAGE, -1, MessageType.NEW_VERSION);
    this.notificationVisible = true;
  }

  private getInitialBuildId() {
    this.getBuildId().subscribe((r: Version) => (this.initialBuildId = r.c));
  }

  private isNewVersion(newBuildInfo: Version) {
    return newBuildInfo.c !== this.initialBuildId;
  }

  private getBuildId(): Observable<any> {
    return this.http.get(versionFile + '?v=' + new Date().getTime());
  }
}
