import {Injectable} from '@angular/core';
import {Answers} from '@shared/investment-survey-old/models/answer';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {pointsI18n} from '../summary.utils';
import {
  PensionInvestmentSurveyResult,
  riskProfileI18n,
  investmentStrategyI18n,
  RiskProfile,
  knowledgeExperience,
  knowledgeExperienceI18n,
  horizonsI18n,
  KnowledgeExperience,
  Horizon,
} from './pension-summary.models';

@Injectable()
export class PensionSummaryService {
  constructor(private questions: Questions) {}

  evaluate(survey: SurveyAnswers, analysisResult = false): PensionInvestmentSurveyResult {
    const surveyData = survey.answers;
    const knowledgeExperiencePoints = this.questions.getPoints(surveyData, [
      analysisResult ? 'knowledgeInv' : 'knowledgeSps',
      'financialMarketNews',
      'education',
      'experience',
      'transactions',
    ]);
    const knowledgeExperienceKey = this.getKnowledgeExperienceKey(
      surveyData,
      knowledgeExperiencePoints,
      analysisResult,
    );
    const riskProfilePoints = this.questions.getPoints(surveyData, [
      'risk',
      'graph',
      'lossOfInvestment',
      'investmentDurationSps',
    ]);
    const riskProfile = this.getRiskProfile(riskProfilePoints);
    return {
      knowledgeExperience: `${knowledgeExperiencePoints} ${pointsI18n(knowledgeExperiencePoints)} -
      ${knowledgeExperienceI18n[knowledgeExperienceKey]}`,
      riskProfile: `${riskProfilePoints} ${pointsI18n(riskProfilePoints)} - ${
        riskProfileI18n[riskProfile]
      }`,
      horizon: horizonsI18n[surveyData.investmentDurationSps.answer as Horizon],
      strategy: this.getStrategy(knowledgeExperienceKey, riskProfile),
    };
  }

  private getRiskProfile(points: number): RiskProfile {
    if (points <= 5) return 'conservative';
    else if (points <= 13) return 'balanced';
    return 'dynamic';
  }

  private getKnowledgeExperienceKey(
    answers: Answers,
    points: number,
    analysisResult: boolean,
  ): KnowledgeExperience {
    if (answers.risk.answer === 'no-risk' || answers.graph.answer === 'A') return 'a';
    if (answers.lossOfInvestment.answer === 'everything') return 'b';
    return knowledgeExperience(analysisResult).find(a => a.range(points)).key;
  }

  private getStrategy(
    knowledgeExperienceKey: KnowledgeExperience,
    riskProfile: RiskProfile,
  ): string {
    if (knowledgeExperienceKey === 'a') return investmentStrategyI18n['guaranteed-fund'];
    if (knowledgeExperienceKey === 'b') return investmentStrategyI18n.conservative;
    if (knowledgeExperienceKey === 'c') {
      if (riskProfile === 'conservative') return investmentStrategyI18n.conservative;
      return investmentStrategyI18n.balanced;
    }
    if (riskProfile === 'dynamic') return investmentStrategyI18n.dynamic;
    return investmentStrategyI18n.balanced;
  }
}
