import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {ButtonModule} from '@shared/ui';
import {CheckboxModule} from '../../forms/checkbox/checkbox.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {RepeatableFormlyComponent} from './repeatable-formly.component';

@NgModule({
  declarations: [RepeatableFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    FormlyModule,
    CommonFieldStatusesModule,
    ButtonModule,
    BackendValidationModule,
  ],
  exports: [RepeatableFormlyComponent],
})
export class RepeatableFormlyModule {}
