import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CardBoardActionComponent} from './card-board-action.component';

@NgModule({
  declarations: [CardBoardActionComponent],
  imports: [CommonModule],
  exports: [CardBoardActionComponent],
})
export class CardBoardActionModule {}
