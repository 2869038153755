export enum ClientPage {
  OldFinancialAnalysis = 'old-financial-analysis',
  FinancialAnalysis = 'financial-analysis',
  SimpleFinancialAnalysis = 'simple-financial-analysis',
  FinancialPlan = 'financial-plan',
  Gold = 'gold',
  Housing = 'housing',
  LifeInsuranceOld = 'life-insurance',
  ConsumerLoan = 'consumer-loan',
  PensionOld = 'pension',
  InvestmentOld = 'investment',
  VehicleInsurance = 'vehicle-insurance',
  PropertyInsurance = 'property-insurance',
  // TODO: rename to investment once we get rid of investment-old
  Investment = 'investment2',
  // TODO: rename to life-insurance once we get rid of life-insurance-old
  LifeInsurance = 'life-insurance2',
  // TODO: rename to pension once we get rid of pension-old
  Pension = 'pension2',
}
