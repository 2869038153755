import {PathLocationStrategy} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, ParamMap, RouterStateSnapshot} from '@angular/router';
import {AdvisorDivision} from '@lib/models';
import * as jwtDecode from 'jwt-decode';
import {get, isEqual} from 'lodash';
import {BehaviorSubject, EMPTY, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {setErrorLoggerAdvisor} from './error-handler.helpers';
import {GtmService} from './gtm.service';

export interface AuthSettings {
  loginUrl: string;
  logoutUrl: string;
  tokenUrl: string;
  clientId: string;
  tokenUserIdField: string;
  production: boolean;
  clientSecret?: string;
  resourceAccess?: string;
  removeOriginFromRedirectUrl?: boolean;
}

interface TokenBase {
  access_token: string;
  refresh_token: string;
  session_state: string;
  token_type: string;
}

interface TokenResponse extends TokenBase {
  expires_in: number;
  refresh_expires_in: number;
}

interface Token extends TokenBase {
  expires: string;
  refresh_expires: string;
}

interface TokenRequestBase {
  client_id: string;
  redirect_uri: string;
  client_secret?: string;
}

export interface AdvisorBasicInformation {
  id: string;
  name: string;
  email: string;
  username: string;
  phone: string;
}

@Injectable({providedIn: 'root'})
export class LoginService implements CanActivate {
  /**
   * Returns token if it's stored and valid
   * Otherwise it requests a new one
   */
  get accessToken(): Observable<string> {
    const token = JSON.parse(this.window.localStorage.getItem('accessToken')) as Token;

    // access token valid
    if (token && this.beforeExpiry(token.expires)) {
      return of(token.access_token);
    }

    // refresh token valid
    if (token && this.beforeExpiry(token.refresh_expires)) {
      return this.requestToken(token.refresh_token, 'refresh_token');
    }

    // no code to get the token and
    // not on the login page - angular router not activated yet, so we need direct check
    if (!this.window.location.pathname.startsWith(`${this.baseHref}login`)) {
      this.login();
    }

    return EMPTY;
  }

  /**
   * Returns new token
   */
  get refreshedAccessToken(): Observable<string> {
    const token = JSON.parse(this.window.localStorage.getItem('accessToken')) as Token;
    // refresh token valid
    if (token) {
      return this.requestToken(token.refresh_token, 'refresh_token');
    }

    // no code to get the token and
    // not on the login page - angular router not activated yet, so we need direct check
    if (!this.window.location.pathname.startsWith(`${this.baseHref}login`)) {
      this.login();
    }

    return EMPTY;
  }

  get userBasicInformation$() {
    return this.userBasicInformationSubject.asObservable();
  }

  get jwtToken() {
    return JSON.parse(this.window.localStorage.getItem('jwtToken'));
  }

  get advisorDivision(): AdvisorDivision {
    return this.window.localStorage.getItem('advisorDivision') as AdvisorDivision;
  }

  private logoutUrl: string;
  private callback = 'login/callback';
  private tokenObject: TokenRequestBase;
  private userBasicInformationSubject: Subject<AdvisorBasicInformation>;
  private usernameSubject = new Subject<string>();
  private tokenRequest$: Observable<string>;
  private tokenRequestObject = new BehaviorSubject({});
  private distinctTokenRequest = new BehaviorSubject({});
  private tokenReinited = new Subject();
  private baseHref: string;
  private tokenQueryAccessPaths = [
    /\/secured\/modelations\/\d*\/report/,
    /\/secured\/client\/\S*\/financial-plan\/print/,
    /\/secured\/client\/\S*\/summary/,
    /\/secured\/client\/\S*\/financial-plan\/balance-sheet/,
  ];
  private window: Window;
  private useDivisions = this.settings.resourceAccess === 'newMax';

  constructor(
    private http: HttpClient,
    private gtmService: GtmService,
    @Inject('windowObject') window: any,
    pathLocationStrategy: PathLocationStrategy,
    @Inject('authSettings') private readonly settings: AuthSettings,
  ) {
    this.baseHref = pathLocationStrategy.getBaseHref();
    // needs to be absolute
    this.window = window as Window;
    this.callback = `${this.window.location.protocol}//${this.window.location.host}${this.baseHref}${this.callback}`;

    this.userBasicInformationSubject = new BehaviorSubject<AdvisorBasicInformation>(
      this.getUserBasicInformation(),
    );

    const logoutParams = new HttpParams()
      .set('client_id', settings.clientId)
      .set('post_logout_redirect_uri', this.callback);

    const separator = this.settings.logoutUrl.includes('?') ? '&' : '?';
    this.logoutUrl = this.settings.logoutUrl + separator + logoutParams;

    this.tokenObject = {
      client_id: settings.clientId,
      redirect_uri: this.callback,
    };
    if (settings.clientSecret) this.tokenObject.client_secret = settings.clientSecret;

    this.tokenRequestObject
      .pipe(
        distinctUntilChanged(isEqual),
        tap(() => this.initTokenRequest()),
      )
      .subscribe(this.distinctTokenRequest);

    this.initTokenRequest();
  }

  /**
   * Requests the token based on either
   * - code received within callback url after login, or
   * - refresh token
   *
   * It no code and refresh token is available, redirect to login page
   *
   * @param code
   */
  requestToken(
    code: string,
    type: 'authorization_code' | 'refresh_token',
    origin = this.callback,
  ): Observable<string> {
    const override: Record<string, string> = {};
    override.grant_type = type;

    if (type === 'authorization_code') override.code = code;
    else override.refresh_token = code;

    this.tokenObject.redirect_uri = origin;
    this.tokenRequestObject.next({
      ...this.tokenObject,
      ...override,
    });

    return this.tokenRequest$;
  }

  login() {
    this.window.location.href = this.getLoginUrl();
  }

  logout() {
    this.gtmService.setUser();
    this.removeToken();
    if (this.useDivisions) this.window.localStorage.removeItem('advisorDivision');
    this.window.location.href = this.logoutUrl;
  }

  getUserId(): string {
    const idByDivision = () =>
      this.advisorDivision === AdvisorDivision.GOLD ? this.jwtToken.idKG : this.jwtToken.idKFP;
    return (
      this.jwtToken &&
      ((this.useDivisions && idByDivision()) || this.jwtToken[this.settings.tokenUserIdField])
    );
  }

  isLogged() {
    const token = JSON.parse(this.window.localStorage.getItem('accessToken')) as Token;
    return (
      token &&
      Boolean(this.getUserId()) &&
      (this.beforeExpiry(token.expires) || this.beforeExpiry(token.refresh_expires))
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.canAccessWithQueryToken(state.url) && route.queryParamMap.has('access_token')) {
      const accessToken = route.queryParamMap.get('access_token');
      const jwtToken = jwtDecode(accessToken) as any;
      const tokenData: TokenResponse = {
        access_token: accessToken,
        expires_in: jwtToken.exp - jwtToken.iat,
        refresh_token: null,
        refresh_expires_in: null,
        token_type: 'bearer',
        session_state: null,
      };
      this.storeToken(tokenData);
      if (this.useDivisions) this.checkAdvisorDivision(route.queryParamMap);
      return true;
    } else if (this.isLogged()) {
      if (this.useDivisions) this.checkAdvisorDivision(route.queryParamMap);
      return true;
    }

    this.login();
  }

  switchAdvisorDivision(type: AdvisorDivision) {
    this.window.location.href = `${this.window.location.protocol}//${this.window.location.host}/secured/consultant?division=${type}`;
  }

  isUserInFinancialDivision(): boolean {
    return this.useDivisions
      ? this.advisorDivision === AdvisorDivision.KF
      : get(this.jwtToken, 'kf') === true;
  }

  isUserInGoldDivision(): boolean {
    return this.useDivisions
      ? this.advisorDivision === AdvisorDivision.GOLD
      : !this.isUserInFinancialDivision();
  }

  hasUserFinancialDivisionId(): boolean {
    return Boolean(get(this.jwtToken, 'idKFP'));
  }

  hasUserGoldDivisionId(): boolean {
    return Boolean(get(this.jwtToken, 'idKG'));
  }

  getKfBpmRoles(): string[] {
    return get(this.jwtToken, 'resource_access.KF-BPM.roles', []);
  }

  hasUserTesterRole(): boolean {
    return get(this.jwtToken, 'resource_access.newMax.roles', []).includes('tester');
  }

  isUserInInvestmentClub(): boolean {
    return get(this.jwtToken, 'resource_access.newMax.roles', []).includes('investicni_klub');
  }

  isUserInMortgageClub(): boolean {
    return get(this.jwtToken, 'resource_access.newMax.roles', []).includes('hypotecni_klub');
  }

  hasUserComparatorAdminRole(): boolean {
    return get(this.jwtToken, 'resource_access.comparator.roles', []).includes('admin');
  }

  hasUserComparatorUploadRole(): boolean {
    return (
      get(this.jwtToken, 'resource_access.comparator.roles', []).includes('upload') ||
      get(this.jwtToken, 'resource_access.comparator.roles', []).includes('asistentka')
    );
  }

  getCompanyContext(): string {
    return get(this.jwtToken, 'company');
  }

  getUserBasicInformation(): AdvisorBasicInformation | null {
    if (!this.jwtToken) return null;

    return {
      id: this.jwtToken.sub,
      name: this.jwtToken.name,
      email: this.jwtToken.email,
      username: this.jwtToken.preferred_username,
      phone: this.jwtToken.phone,
    };
  }

  // redirect_url on SSO needs to be preserved exactly in order to get access token for the code
  // since
  // - it's dynamic (stores original URL), and
  // - SSO adds 3 params to the callback uri
  // we need to reconstruct original redirect_url they need to be removed
  // once other params are added by the SSO, SSO call will probably return error on redirect_uri mismatch
  extractRedirectUrls(url: string) {
    const paramsToRemove = ['session_state', 'code', 'iss'];
    const redirectUrl = new URL(url);
    paramsToRemove.forEach(param => {
      redirectUrl.searchParams.delete(param);
    });
    const originUrl = redirectUrl.searchParams.get('origin');
    if (this.settings.removeOriginFromRedirectUrl) redirectUrl.searchParams.delete('origin');

    return {originUrl, redirectUrl: redirectUrl.href};
  }

  private canAccessWithQueryToken(url: string) {
    return this.tokenQueryAccessPaths.some(path => path.test(url));
  }

  private storeToken(data: TokenResponse) {
    const jwtToken = jwtDecode(data.access_token) as any;
    let jwtRefreshToken: any;
    if (data.refresh_token) jwtRefreshToken = jwtDecode(data.refresh_token);

    if (
      this.settings.resourceAccess &&
      !get(jwtToken, `resource_access.${this.settings.resourceAccess}`)
    ) {
      this.logout();
      return;
    }

    const token: Token = {
      access_token: data.access_token,
      expires: new Date(1000 * jwtToken.exp).toISOString(),
      refresh_token: data.refresh_token,
      refresh_expires: jwtRefreshToken ? new Date(1000 * jwtRefreshToken.exp).toISOString() : null,
      token_type: data.token_type,
      session_state: data.session_state,
    };

    this.gtmService.setUser(jwtToken.sub);
    this.window.localStorage.setItem('accessToken', JSON.stringify(token));
    this.window.localStorage.setItem('jwtToken', JSON.stringify(jwtToken));
    setErrorLoggerAdvisor(this.getUserBasicInformation());
    this.userBasicInformationSubject.next(this.getUserBasicInformation());
  }

  private removeToken() {
    this.usernameSubject.next();
    this.window.localStorage.removeItem('accessToken');
    this.window.localStorage.removeItem('jwtToken');
  }

  private initTokenRequest() {
    this.tokenReinited.next();
    this.tokenRequest$ = this.distinctTokenRequest.pipe(
      takeUntil(this.tokenReinited),
      switchMap(fromObject => this.http.post(this.settings.tokenUrl, new HttpParams({fromObject}))),
      tap((d: TokenResponse) => this.storeToken(d)),
      map((data: TokenResponse) => data.access_token),
      shareReplay(1),
    );
  }

  private getLoginUrl() {
    const redirectUri = new URL(this.callback);
    const currentUrl = new URL(window.location.href);

    // pass current url to be redirected back after login
    if (!currentUrl.pathname.startsWith('/login')) {
      redirectUri.searchParams.append('origin', window.location.href);
    }

    const params = new HttpParams()
      .set('client_id', this.settings.clientId)
      .set('response_type', 'code')
      .set('redirect_uri', redirectUri.href);

    const separator = this.settings.loginUrl.includes('?') ? '&' : '?';
    return this.settings.loginUrl + separator + params;
  }

  private beforeExpiry(expiry: string | null) {
    // milliseconds reserve
    return new Date(expiry).getTime() - Date.now() > 30_000;
  }

  private storeAdvisorDivision(division: AdvisorDivision) {
    this.window.localStorage.setItem('advisorDivision', division);
  }

  private checkAdvisorDivision(paramMap: ParamMap) {
    if (paramMap.has('division')) {
      const division = AdvisorDivision[paramMap.get('division') as AdvisorDivision];
      this.storeAdvisorDivision(division ?? AdvisorDivision.KF);
    }
    this.checkAdvisorDivisionCorrectness();
  }

  private checkAdvisorDivisionCorrectness() {
    if (!this.advisorDivision || this.advisorDivision === AdvisorDivision.KF) {
      if (!this.hasUserFinancialDivisionId() && this.hasUserGoldDivisionId())
        this.storeAdvisorDivision(AdvisorDivision.GOLD);
      else this.storeAdvisorDivision(AdvisorDivision.KF);
    } else if (this.advisorDivision === AdvisorDivision.GOLD && !this.hasUserGoldDivisionId()) {
      this.storeAdvisorDivision(AdvisorDivision.KF);
    }
  }
}
