import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {selectFamilyHead} from '@shared/analysis/operators';
import * as moment from 'moment';
import {filter, map, tap} from 'rxjs/operators';
import {State} from 'src/store';

export function convertDateToAge(date: string, currentAge: number): number {
  return date ? moment(date).year() - moment().year() + currentAge : null;
}

export function convertAgeToDate(value: number, currentAge: number): string {
  return moment()
    .startOf('year')
    .add(value - currentAge, 'years')
    .format('YYYY-MM-DD');
}

@UntilDestroy()
@Component({
  selector: 'kpt-year-input-formly',
  templateUrl: './year-input-formly.component.html',
  styleUrls: ['./year-input-formly.component.scss'],
})
export class YearInputFormlyComponent extends FieldType implements OnInit, OnDestroy {
  inputForm: UntypedFormControl;

  disabledValueChange = false;

  private age: number;

  constructor(private store: Store<State>) {
    super();
    this.store
      .pipe(
        selectFamilyHead(),
        map(familyMember => familyMember.age),
        untilDestroyed(this),
      )
      .subscribe(age => (this.age = age));
  }

  ngOnInit() {
    this.inputForm = new UntypedFormControl(this.convertInput(this.formControl.value));

    this.formControl.valueChanges
      .pipe(
        tap(() => (this.disabledValueChange = true)),
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.inputForm.patchValue(res);
        this.disabledValueChange = false;
      });

    this.inputForm.valueChanges
      .pipe(
        filter(() => !this.disabledValueChange),
        untilDestroyed(this),
      )
      .subscribe(evt => this.formControl.patchValue(this.convertOutput(evt), {emitEvent: false}));
  }

  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.change) this.to.change(field, $event);
  }

  ngOnDestroy() {}

  private convertInput(value: string): number {
    return convertDateToAge(value, this.age);
  }

  private convertOutput(value: number): string {
    return convertAgeToDate(value, this.age);
  }
}
