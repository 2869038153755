import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {FormModule} from '@shared/ui';
import * as moment from 'moment';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-objective-age-input',
  templateUrl: './objective-age-input.component.html',
  imports: [CommonModule, FormsModule, FormModule],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ObjectiveAgeInputComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() label = 'Chci mít zajištění ve věku';
  @Input() personId: string;

  @Input() start: string;
  @Output() startChange = new EventEmitter<string>();

  private familyMembers: FamilyMember[];

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.store
      .pipe(selectFamilyMembers(), untilDestroyed(this))
      .subscribe(familyMembers => (this.familyMembers = familyMembers));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.personId) {
      if (changes.personId.previousValue) {
        // recompute start date to keep the age same
        const oldAge = this.getFamilyMember(changes.personId.previousValue)?.age;
        const newAge = this.getFamilyMember(changes.personId.currentValue)?.age;
        if (oldAge && newAge) {
          const start = moment(this.start)
            .add(oldAge - newAge, 'years')
            .format('YYYY-MM-DD');
          // timeout needed to prevent ExpressionChangedAfterItHasBeenCheckedError
          setTimeout(() => {
            this.startChange.emit(start);
          });
        }
      }
    }
  }

  getAge(): number {
    const familyMember = this.getFamilyMember(this.personId);
    return this.start ? moment(this.start).year() - moment().year() + familyMember.age : null;
  }

  setAge(value: number) {
    const familyMember = this.getFamilyMember(this.personId);
    const start = moment()
      .startOf('year')
      .add(value - familyMember.age, 'years')
      .format('YYYY-MM-DD');
    this.startChange.emit(start);
  }

  private getFamilyMember(personId: string): FamilyMember {
    return (
      this.familyMembers.find(member => member.sugarUuid === personId) ??
      this.familyMembers.find(member => member.familyHead)
    );
  }
}
