import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IStepperProgressItem} from '@shared/ui';
import {getRouterState} from 'src/app/app.routing';
import {
  containsPlaceholders,
  replacePlaceholders,
} from 'src/shared/ui/stepper-progress/stepper-progress.utils';
import {StepperState} from '../reducers/stepper.reducer';
import {getFamilyUuid} from './family-member.selectors';

export const getSteppersState = createFeatureSelector<StepperState>('steppers');

export const getCurrentStepperConfig = createSelector(
  getSteppersState,
  getRouterState,
  getFamilyUuid,
  (state, router, familyId): IStepperProgressItem[] =>
    state.current
      ? state[state.current].map(s => {
          const url = replacePlaceholders(s.url, {...router.state.params, familyId});
          return {...s, url, disabled: containsPlaceholders(url)};
        })
      : null,
);
