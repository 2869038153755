<div class="tabs">
  <ul
    #tabList
    class="nav nav-tabs"
    [class.wrap]="wrap"
    [class.allCaps]="allCaps"
    [ngStyle]="{'margin-left': shift + 'rem'}"
    role="tablist"
  >
    <ng-container *ngFor="let tab of tabs; let i = index">
      <li #tabItem class="nav-item">
        <a
          (click)="selectTab(tab)"
          class="nav-link"
          [class.alternative-color]="alternativeColors"
          [class.active]="tab.active"
        >
          {{ tab.tabTitle }}
        </a>
      </li>
    </ng-container>
  </ul>
  <div class="tab-content">
    <ng-content></ng-content>
  </div>
</div>
