export const baseColors = {
  green50: '#e5f1ee',
  green100: '#bedbd4',
  green150: '#8DC5BB',
  green200: '#60aea1',
  green300: '#006677',
  green400: '#1c606e',
  lightBlue: '#e8eff2',
  white: '#ffffff',
  skyGray: '#152933',
  blue: '#2a4b5c',
  brightBlue: '#3f8efc',
  promoTomato: '#d00036',
  promoCheese: '#fedc98',
  promoSalmon: '#ec7978',
  promoAvocado: '#00965e',
  promoChampagne: '#ffc95e',
  gray: '#b2b8bf',
  gray050: '#f6f6f6',
  btnOutlineBgHover: 'rgba(190, 219, 212, 0.3)',
};
