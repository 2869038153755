export const pensions: {[key: number]: number} = {
  10_000: 9_270,
  15_000: 11_999,
  20_000: 12_779,
  25_000: 13_559,
  30_000: 14_339,
  35_000: 15_119,
  40_000: 15_899,
  45_000: 16_679,
  50_000: 17_459,
  55_000: 18_239,
  60_000: 19_019,
  65_000: 19_799,
  70_000: 20_579,
  75_000: 21_359,
  80_000: 22_139,
  85_000: 22_919,
  90_000: 23_699,
  95_000: 24_479,
  100_000: 25_259,
};

export function getPensionByIncome(income: number) {
  const roundedIncome = Math.floor(income / 5_000) * 5_000;
  if (roundedIncome >= 10_000 && roundedIncome <= 100_000) return pensions[roundedIncome];
  if (roundedIncome < 10_000) return pensions[10_000];
  if (roundedIncome > 100_000) return pensions[100_000];
}
