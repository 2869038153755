// eslint-disable-next-line max-classes-per-file
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {CanActivate, CanDeactivate} from '@angular/router';
import {AssetDetailPageComponent} from './asset-detail.component';

@Injectable()
export class CanActivateAssetDetail implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  canActivate(): boolean {
    const scrollY = window.scrollY;
    this.document.body.style.top = `-${scrollY}px`;
    this.document.body.style.width = '100%';
    this.document.body.style.position = 'fixed';

    const css = `bs-datepicker-container {
      top: ${scrollY}px !important;
    }`;

    const head = this.document.head || this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('style');

    style.type = 'text/css';
    style.id = 'asset-modal-styles';
    style.appendChild(this.document.createTextNode(css));
    head.appendChild(style);
    return true;
  }
}

@Injectable()
export class CanDeactivateAssetDetail implements CanDeactivate<AssetDetailPageComponent> {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  canDeactivate(): boolean {
    const scrollY = document.body.style.top;
    setTimeout(() => {
      this.document.body.style.position = '';
      this.document.body.style.top = '';
      this.document.body.style.width = '';
      this.document.getElementById('asset-modal-styles').remove();

      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    });

    return true;
  }
}
