import * as fromRouter from '@ngrx/router-store';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {ProductsEffects} from 'src/store/effects/products.effects';
import {RouterStateUrl} from '../app/app.routing';
import {ConsumerCreditEffects} from './effects/consumer-credit.effects';
import {CreateClientEffects} from './effects/create-client.effects';
import {FamilyMembersEffects} from './effects/family-members.effects';
import {FindClientsEffects} from './effects/find-clients.effects';
import {GetClientEffects} from './effects/get-client.effects';
import {HousingEffects} from './effects/housing.effects';
import {StepperEffects} from './effects/stepper.effects';
import * as fromConsumer from './reducers/consumer.reducer';
import * as fromCreateClient from './reducers/create-client.reducer';
import * as fromFamilyMembers from './reducers/family-members.reducer';
import * as fromFindClients from './reducers/find-clients.reducer';
import * as fromGetClient from './reducers/get-client.reducer';
import * as fromHousing from './reducers/housing.reducer';
import * as fromProducts from './reducers/products.reducer';
import * as fromSteppers from './reducers/stepper.reducer';
import * as fromUI from './reducers/ui.reducer';

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  steppers: fromSteppers.StepperState;
  housing: fromHousing.State;
  consumer: fromConsumer.State;
  familyMembers: fromFamilyMembers.FamilyMemberState;
  createClient: fromCreateClient.CreateClientsState;
  findClients: fromFindClients.FindClientsState;
  getClient: fromGetClient.GetClientState;
  ui: fromUI.UIState;
  products: fromProducts.ProductsState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  steppers: fromSteppers.reducer,
  housing: fromHousing.reducer,
  consumer: fromConsumer.reducer,
  familyMembers: fromFamilyMembers.reducer,
  createClient: fromCreateClient.reducer,
  findClients: fromFindClients.reducer,
  getClient: fromGetClient.reducer,
  ui: fromUI.reducer,
  products: fromProducts.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

export const effects: any[] = [
  ConsumerCreditEffects,
  HousingEffects,
  StepperEffects,
  FamilyMembersEffects,
  FindClientsEffects,
  GetClientEffects,
  CreateClientEffects,
  ProductsEffects,
];
