/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormArrayExtended} from '../../../common/formArrayExtended';
import {AnalysisService} from '../../../controllers/Analysis';

@Injectable()
export class CreateUpdateRisksFormService {
  form: UntypedFormGroup;
  constructor(
    private analysisService: AnalysisService,
  ) {
    this.form = new UntypedFormGroup({
      data: new UntypedFormGroup({
        membersRisks: new FormArrayExtended(() => (
          new UntypedFormGroup({
            risksNotes: new FormArrayExtended(() => (
              new UntypedFormGroup({
                riskId: new UntypedFormControl(undefined, [Validators.minLength(1), Validators.required]),
                note: new UntypedFormControl(undefined, []),
              }, [Validators.required])), [], [Validators.required]),
            sugarUuid: new UntypedFormControl(undefined, [Validators.required]),
            riskProfession: new UntypedFormControl(undefined, []),
            riskProfessionNote: new UntypedFormControl(undefined, []),
          }, [Validators.required])), [], [Validators.required]),
        currentState: new UntypedFormControl(undefined, []),
        note: new UntypedFormControl(undefined, []),
      }, [Validators.required]),
      family_uuid: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  submit(raw = false) {
    const data = raw ?
      this.form.getRawValue() :
      this.form.value;
    return this.analysisService.createUpdateRisks(data);
  }
}
