<div class="datepicker-container">
  <div class="form-group">
    <input
      [id]="id"
      bsDatepicker
      #datePicker="bsDatepicker"
      type="text"
      class="form-control"
      (input)="valueChange.emit($event.target.value)"
      (blur)="onTouched($event.target.value); blur.emit($event)"
      (focus)="onInputFocus()"
      [class.form-control--not-empty]="!!value"
      [placeholder]="placeholder || label || ''"
      [bsConfig]="config"
      [ngClass]="customFieldClass"
      [disabled]="disabled"
      [minDate]="minDateObj"
      [maxDate]="maxDateObj"
      [(bsValue)]="value"
    />

    <!-- "material-like" placeholder -->
    <label class="control-label" (click)="labelClick()">
      <ng-template [ngTemplateOutlet]="labelTemplate || defaultLabelTemplateRef"> </ng-template>
    </label>

    <!-- append e.g <kpt-help> as a TemplateRef -->
    <div class="input-group-append" *ngIf="appendTemplate">
      <ng-template [ngTemplateOutlet]="appendTemplate"></ng-template>
    </div>

    <!-- tiny text below the input -->
    <div *ngIf="!!subtext" class="input-subtext" [class.input-subtext--disabled]="!!disabled">
      {{ subtext }}
    </div>

    <!-- warning text below the input -->
    <div *ngIf="!!warningText" class="input-warning-text">
      {{ warningText }}
    </div>

    <!-- show only when kpt-invalid class is active -->
    <kpt-form-field-error
      [showErrorsOn]="showErrorsOn"
      [customErrors]="customErrors"
    ></kpt-form-field-error>

    <!-- maybe move to cmp -->
    <ng-template #defaultLabelTemplateRef>
      <span [innerHTML]="htmlLabel || label"></span>
    </ng-template>

    <ng-template #defaultPlainLabelTemplateRef>
      {{ label }}
    </ng-template>
  </div>
</div>
