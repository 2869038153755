import {Params} from '@angular/router';
import {IStepperProgressItem} from 'src/shared/ui';
import {ofType, unionize, UnionOf} from 'unionize';
import {Stepper} from '../reducers/stepper.reducer';

export const StepperActions = unionize(
  {
    ST_UpdateConfig: ofType<{stepper: Stepper; config: IStepperProgressItem[]}>(),
    ST_UpdateCurrent: ofType<{url: string}>(),
    ST_MarkStepDone: ofType<{url: string; params: Params}>(),
  },
  {tag: 'type', value: 'payload'},
);

export type StepperAction = UnionOf<typeof StepperActions>;
