import {DOCUMENT} from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  NgModuleRef,
  Renderer2,
  RendererFactory2,
  ViewContainerRef,
} from '@angular/core';
import {Asset} from '@shared/analysis/models/asset';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {AssetDetailModalWrapperComponent} from './modal-wrapper/asset-detail-modal-wrapper.component';

@Injectable()
export class AssetDetailCreator {
  instance: AssetDetailModalWrapperComponent;
  componentRef: ComponentRef<AssetDetailModalWrapperComponent>;
  renderer2: Renderer2;
  cancel$ = new Subject();

  private rootEl: ViewContainerRef;
  private hasOpenModal$ = new BehaviorSubject<boolean>(false);

  get hasOpenModal(): Observable<boolean> {
    return this.hasOpenModal$.asObservable();
  }

  constructor(
    private rendererFactory: RendererFactory2,
    private cfr: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  insert(
    ngModuleRef: NgModuleRef<any>,
    assetInfo: Record<string, any>,
    windowClass: string,
    showDeleteButton: boolean,
    defaults: any,
    clone?: boolean,
  ): Observable<Asset> {
    this.renderer2.addClass(this.document.body, 'modal-open');

    this.rootEl = this.appRef.components[0].instance.assetDetailRef;
    const cfr = ngModuleRef ? ngModuleRef.componentFactoryResolver : this.cfr;
    const componentFactory = cfr.resolveComponentFactory(AssetDetailModalWrapperComponent);
    this.componentRef = this.rootEl.createComponent(componentFactory);

    this.instance = this.componentRef.instance as AssetDetailModalWrapperComponent;

    this.instance.assetDefinition = assetInfo.assetDefinition;
    this.instance.assetUuid = assetInfo.assetUuid;
    this.instance.windowClass = windowClass;
    this.instance.showDeleteButton = showDeleteButton;
    this.instance.defaults = defaults;
    this.instance.clone = clone;

    this.hasOpenModal$.next(true);

    return this.instance.result$.pipe(take(1), takeUntil(this.cancel$));
  }

  destroy() {
    this.cancel$.next();
    this.cancel$.complete();
    this.renderer2.removeClass(this.document.body, 'modal-open');
    this.componentRef.destroy();
    this.rootEl.clear();
    this.hasOpenModal$.next(false);
  }
}
