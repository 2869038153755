<kpt-input
  type="text"
  [label]="label"
  [formControl]="controlForm"
  [appendTemplate]="append"
  [autofocus]="true"
  (keyup)="keyup($event)"
>
  <ng-template #append>
    <span class="icon icon--check" (click)="confirm.emit()"></span>
    <span class="icon icon--close ml-4" (click)="cancel.emit()"></span>
  </ng-template>
</kpt-input>
