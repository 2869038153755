<table>
  <thead>
    <tr class="top-bar">
      <th class="align-left">Název produktu</th>
      <ng-container *ngFor="let category of categories">
        <th [colSpan]="category.colspan">{{ category.name }}</th>
      </ng-container>
      <th></th>
    </tr>
    <tr class="help-bar hidden-print">
      <th class="align-left">
        <kpt-sort [direction]="sortByName" (changeDirection)="onSortByName($event)"> </kpt-sort>
      </th>
      <th *ngFor="let criterion of criteria">
        <kpt-help class="px-1" [text]="criterion.name"></kpt-help>
      </th>
      <th>
        <kpt-sort [direction]="sortByScore" (changeDirection)="onSortByScore($event)"> </kpt-sort>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let product of productList">
      <td class="align-left">{{ product.name }}</td>
      <ng-container *ngFor="let categoryResults of product.results">
        <ng-container *ngFor="let result of categoryResults.results; last as lastCategory">
          <td
            class="product-result-item"
            [class.mark-gray]="categoryResults.markGray"
            [class.last-category]="lastCategory"
          >
            <span class="icon" [ngClass]="[result ? 'icon--check' : 'icon--close']"></span>
          </td>
        </ng-container>
      </ng-container>
      <td class="score">{{ product.score }}</td>
    </tr>
  </tbody>
</table>
