import {ChooseFundResponse, LossWhereToInvestEnum, WhereToInvest} from '@generated/model';
import {inRange} from 'lodash';

export const getSsriRange = (loss: LossWhereToInvestEnum): [number, number] => {
  switch (loss) {
    case 'no':
      return [1, 2];
    case 'small':
      return [3, 5];
    case 'big':
      return [6, 7];
  }
};

export const matchingProductFilter =
  ({horizon, loss}: WhereToInvest) =>
  (product: ChooseFundResponse) =>
    horizon &&
    loss &&
    product.minLength <= horizon &&
    inRange(product.ssri, getSsriRange(loss)[0], getSsriRange(loss)[1] + 1);

export const modelPortfolioDisclaimer =
  '<i>Upozornění: Služba vzorového portfolia není hlavní investiční službou' +
  ' investičního poradenství dle § 4 odst. 2 písm. e) zákona o podnikání na kapitálovém trhu (ZPKT). Služba vzorového' +
  ' portfolia je doplňkovou investiční službou investičního výzkumu a finanční analýzy nebo jiné formy' +
  ' obecných doporučení týkajících se obchodování s investičními nástroji, dle § 4 odst. 3 písm. d ZPKT.</i>';
