import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  flags = {
    showNewDashboard: this.getFromLocalStorage('showNewDashboard') ?? environment.showNewDashboard,
  };

  get showFeatureFlagsConfig(): boolean {
    return environment.showFeatureFlagsConfig;
  }

  get showNewDashboard(): boolean {
    return this.flags.showNewDashboard;
  }

  save() {
    this.setToLocalStorage('showNewDashboard', this.flags.showNewDashboard);
  }

  private getFromLocalStorage(flagName: string): boolean | null {
    const flag = localStorage.getItem(`featureFlag.${flagName}`);
    if (flag == null) return null;
    return flag === 'true';
  }

  private setToLocalStorage(flagName: string, value: boolean) {
    localStorage.setItem(`featureFlag.${flagName}`, value.toString());
  }
}
