import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {TabFormlyComponent} from '../tab-formly/tab-formly.component';

@Injectable()
export class TabsFormlyService {
  tabs$ = new BehaviorSubject<TabFormlyComponent[]>([]);
  initialActiveIndex: number = null;
  initialActiveId: string = null;
  private tabs: TabFormlyComponent[] = [];

  addTab(tab: TabFormlyComponent) {
    if (
      this.initialActiveId
        ? tab.id === this.initialActiveId
        : this.tabs.length === this.initialActiveIndex
    ) {
      this.tabs.push(tab);
      this.setActiveTab(tab);
    } else {
      this.tabs.push(tab);
    }

    this.tabs$.next(this.tabs);
  }

  getTabs$() {
    return this.tabs$.asObservable().pipe(delay(0));
  }

  removeTab(tab: TabFormlyComponent) {
    this.tabs = this.tabs.filter(t => t !== tab);
    this.tabs$.next(this.tabs);
  }

  setActiveTab(tab: TabFormlyComponent) {
    this.tabs.forEach(t => (t.active = t === tab));
    this.tabs$.next(this.tabs);
  }
}
