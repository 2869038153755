import {DatePipe} from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Personal} from '@generated/defs/Personal';
import {AdvisorDivision} from '@lib/models';
import {LoginService} from '@lib/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AdvisorDetail} from '@shared/advisor-detail/models/advisor-detail';
import {TypedOption} from '@shared/ui';
import {SidebarService} from '@shared/ui/sidebar/sidebar.service';
import {isEqual} from 'lodash';
import {distinctUntilChanged} from 'rxjs/operators';
import {build} from 'src/environments/version';

@UntilDestroy()
@Component({
  selector: 'kpt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isHeaderSticked: boolean;
  appInfo: string;

  divisionOptions: TypedOption<AdvisorDivision>[];
  divisionFormControl: UntypedFormControl;

  @Input() familyId: string;
  @Input() isMenuOpen: boolean;
  @Input() pageName: string;
  @Input() clientName: string;
  @Input() showClose = true;
  @Input() noBackground: boolean;
  @Input() hasStepper = false;
  @Input() advisorPersonalInfo: Personal;
  @Input() advisorDetail: AdvisorDetail;
  @Output() toggleMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private sidebarService: SidebarService,
    private loginService: LoginService,
  ) {
    this.appInfo = [
      `commit: ${build.commit}`,
      `build time: ${this.datePipe.transform(build.buildTime, 'medium')}`,
      `environment: ${build.env}`,
    ].join('\n');
  }

  ngOnInit() {
    this.prepareDivisionForm();
  }

  ngOnDestroy() {}

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isHeaderSticked = !!(
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 5
    );
  }

  logoClick() {
    this.close();
  }

  menu() {
    this.sidebarService.toggle();
  }

  close() {
    if (this.router.url.includes(`secured/client/${this.familyId}/`))
      return this.router.navigate([`secured/client/${this.familyId}`]);
    return this.router.navigate(['secured/consultant']);
  }

  navigateToAboutConsultantPage() {
    this.router.navigate([`secured/about-consultant`]);
  }

  private prepareDivisionForm() {
    this.prepareDivisionOptions();
    this.divisionFormControl = new UntypedFormControl(this.loginService.advisorDivision);

    this.divisionFormControl.valueChanges
      .pipe(distinctUntilChanged<AdvisorDivision>(isEqual), untilDestroyed(this))
      .subscribe(division => {
        this.loginService.switchAdvisorDivision(division);
      });
  }

  private prepareDivisionOptions() {
    const options: TypedOption<AdvisorDivision>[] = [];
    if (this.loginService.hasUserFinancialDivisionId())
      options.push({key: AdvisorDivision.KF, label: 'PORADCE'});
    if (this.loginService.hasUserGoldDivisionId())
      options.push({key: AdvisorDivision.GOLD, label: 'GOLD'});
    this.divisionOptions = options;
  }
}
