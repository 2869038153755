import {Injectable} from '@angular/core';
import {Product} from '@generated/defs/Product';
import {select, Store} from '@ngrx/store';
import {assetTypesToCoreCategoriesTable} from '@shared/analysis/core-sync.data';
import {AssetType} from '@shared/analysis/models/asset';
import {isEqual} from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {State} from 'src/store';
import {ProductActions} from 'src/store/actions/products.actions';
import {ProductSet} from 'src/store/models/products.models';
import {getProducts} from 'src/store/selectors/products.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private store: Store<State>) {}

  getProducts(set: ProductSet): Observable<Product[]> {
    return this.store.pipe(
      select(getProducts),
      map(productsState => {
        if (!productsState[set]) {
          this.store.dispatch(ProductActions.loadProducts({productSet: set}));
          return null;
        } else if (productsState[set].loading) {
          return null;
        } else {
          return productsState[set].products;
        }
      }),
      filter(products => Boolean(products)),
      distinctUntilChanged(isEqual),
    );
  }

  filterByAssetType(
    products: Product[],
    selectedProductCode: string,
    assetType: AssetType,
  ): Product[] {
    const categories = assetTypesToCoreCategoriesTable[assetType];

    return products.filter(product => {
      // selected option
      if (product.code === selectedProductCode) return true;

      // obsolete product
      if (!product.valid) return false;

      return categories.some(
        ([cat1, cat2, cat3]) =>
          categoriesAreSame(product.category1.code, cat1) &&
          categoriesAreSame(product.category2.code, cat2) &&
          categoriesAreSame(product.category3.code, cat3),
      );
    });

    function categoriesAreSame(a: string, b: string) {
      return !a || !b || a === b;
    }
  }
}
