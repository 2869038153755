import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PensionState} from 'src/app/modules/pension/pension/store/pension.state';

export const pensionFeatureName = 'pension';

export const getPensionState = createFeatureSelector<PensionState>(pensionFeatureName);

export const getPensionPersons = createSelector(getPensionState, state => state.persons);

export const getPensionSelectedPersonId = createSelector(
  getPensionState,
  state => state.selectedPersonId,
);

export const getPensionSelectedPerson = createSelector(getPensionState, state =>
  state.persons.find(person => person.sugarUuid === state.selectedPersonId),
);

export const getMyPensionGraphType = createSelector(getPensionState, state => state.graphType);

export const getPensionStateContribution = createSelector(getPensionSelectedPerson, person => {
  if (!person) return null;
  const contribution = person.contribution;
  if (contribution < 300) return 0;
  if (contribution > 1000) return 230;
  return 90 + (contribution - 300) * 0.2;
});

export const getPensionTaxSavings = createSelector(getPensionSelectedPerson, person => {
  if (!person) return null;
  const contribution = person.contribution;
  if (contribution < 1000) return 0;
  if (contribution > 3000) return 3600 / 12;
  return ((contribution - 1000) * 12 * 0.15) / 12;
});

export const getPensionDepositAppreciation = createSelector(
  getPensionSelectedPerson,
  getPensionStateContribution,
  (person, stateContribution) => {
    if (!person) return null;
    if (person.contribution === 0) return 0;
    return stateContribution / person.contribution;
  },
);

export const getPensionTotalRetirementSavings = createSelector(
  getPensionSelectedPerson,
  getPensionStateContribution,
  getPensionTaxSavings,
  (person, stateContribution) => {
    if (!person) return null;
    const monthsTillRetirement = (65 - person.age) * 12;
    return (
      (person.contribution + person.employersContribution + stateContribution) *
      monthsTillRetirement
    );
  },
);

export const getPensionTotalTaxSavings = createSelector(
  getPensionSelectedPerson,
  getPensionTaxSavings,
  (person, taxSavings) => {
    if (!person) return null;
    const monthsTillRetirement = (65 - person.age) * 12;
    return taxSavings * monthsTillRetirement;
  },
);
