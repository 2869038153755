import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {Orientaion} from './row/row.component';

export type BorderGroupItemPlacing = 'standalone' | 'first' | 'middle' | 'last';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[placing]',
})
export class BorderGroupItemDirective {
  private _container: Orientaion = 'row';
  private _placing: BorderGroupItemPlacing = 'standalone';

  get className() {
    return `${this._container}-item-${this._placing}`;
  }

  @Input()
  set placing(newValue: BorderGroupItemPlacing) {
    this.removeClass();
    this._placing = newValue;
    this.addClass();
  }

  get placing() {
    return this._placing;
  }

  @Input()
  set container(newValue: Orientaion) {
    this.removeClass();
    this._container = newValue;
    this.addClass();
  }

  get container() {
    return this._container;
  }

  constructor(public renderer: Renderer2, public hostElement: ElementRef) {
    this.placing = 'standalone';
  }

  private addClass() {
    if (!this.className) return;
    this.renderer.addClass(this.hostElement.nativeElement, this.className);
  }

  private removeClass() {
    if (!this.className) return;
    this.renderer.removeClass(this.hostElement.nativeElement, this.className);
  }
}
