import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {EventSituationButtonComponent} from './event-situation-button.component';
import {EventSituationButtonDeprecatedComponent} from './event-situation-button.deprecated.component';

@NgModule({
  declarations: [EventSituationButtonComponent, EventSituationButtonDeprecatedComponent],
  imports: [CommonModule, RouterModule],
  exports: [EventSituationButtonComponent, EventSituationButtonDeprecatedComponent],
})
export class EventSituationButtonModule {}
