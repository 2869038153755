/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormArrayExtended} from '../../../common/formArrayExtended';
import {LifeinsuranceService} from '../../../controllers/Lifeinsurance';

@Injectable()
export class GetPriorityQuestionnaireFormService {
  form: UntypedFormGroup;
  constructor(
    private lifeinsuranceService: LifeinsuranceService,
  ) {
    this.form = new UntypedFormGroup({
      data: new UntypedFormGroup({
        clients: new FormArrayExtended(() => (
          new UntypedFormGroup({
            sugarUuid: new UntypedFormControl(undefined, [Validators.required]),
          }, [Validators.required])), [], [Validators.required]),
      }, [Validators.required]),
      family_uuid: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  submit(raw = false) {
    const data = raw ?
      this.form.getRawValue() :
      this.form.value;
    return this.lifeinsuranceService.getPriorityQuestionnaire(data);
  }
}
