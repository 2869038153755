import {Component, Input, OnInit} from '@angular/core';
import {CriteriaResults} from '@generated/defs/CriteriaResults';
import {PriorityQuestionnaireSerializerAnswer} from '@generated/defs/PriorityQuestionnaireSerializerAnswer';
import {Directions} from '@shared/ui/sort';
import {head} from 'lodash';

export interface ProductListVM {
  name: string;
  results: {markGray: boolean; results: number[]}[];
  score: number;
}

export interface CategoriesVM {
  name: string;
  colspan: number;
}

export interface CriteriaVM {
  id: string;
  name: string;
  category: string;
}

@Component({
  selector: 'kpt-priorities-results',
  templateUrl: './priorities-results.component.html',
  styleUrls: ['./priorities-results.component.scss'],
})
export class PrioritiesResultsComponent implements OnInit {
  @Input() prioritiesData: PriorityQuestionnaireSerializerAnswer;
  categories: CategoriesVM[] = [];
  criteria: CriteriaVM[] = [];
  productList: ProductListVM[] = [];
  sortByName = Directions.NONE;
  sortByScore = Directions.DESC;

  constructor() {}

  ngOnInit() {
    const {categories, scoredProducts} = this.prioritiesData;
    const uiCategories: {[key: string]: CategoriesVM} = {};

    for (const category of categories) {
      const {id, name, criteria} = category;
      uiCategories[id] = {name, colspan: criteria.length};
      for (const criteriaItem of criteria) {
        this.criteria.push({id: criteriaItem.id, name: criteriaItem.name, category: id});
      }
    }

    for (const scoredProduct of scoredProducts) {
      const {productName, productVersions} = scoredProduct;
      const filteredProducts = productVersions.filter(p => p.versionState === 'Aktuální');
      const criteriaCategory =
        filteredProducts.length > 0 ? head(filteredProducts).criteriaCategory : [];

      const product = {
        name: productName,
        results: [],
        score: 0,
      } as ProductListVM;

      if (criteriaCategory.length === 0) continue;

      let allResults: CriteriaResults[] = [];
      for (const criteriaCategoryItem of criteriaCategory) {
        allResults = [...allResults, ...criteriaCategoryItem.criteria];
      }

      const resultsCategories: Record<
        string,
        {category: string; results: number[]; score: number}
      > = {};
      for (const criterion of this.criteria) {
        const result = allResults.find(r => r.criterion === criterion.id);
        if (!resultsCategories[criterion.category]) {
          resultsCategories[criterion.category] = {
            category: criterion.category,
            score: result.questionnaireCriterionScore,
            results: [result.questionnaireCriterionScore],
          };
        } else {
          resultsCategories[criterion.category].score += result.questionnaireCriterionScore;
          resultsCategories[criterion.category].results.push(result.questionnaireCriterionScore);
        }
        product.score += result.questionnaireCriterionScore;
      }

      product.results = Object.values(resultsCategories).map(r => {
        return {
          markGray: uiCategories[r.category].colspan / 2 >= r.score,
          results: r.results,
        };
      });

      this.productList.push(product);
    }
    this.categories = Object.values(uiCategories);

    this.onSortByScore(this.sortByScore);
  }

  onSortByName(direction: Directions) {
    this.sortByName = direction;
    this.sortByScore = Directions.NONE;
    const sortedProductList = [...this.productList].sort(
      (a, b) => a.name.localeCompare(b.name, 'cs') * this.sortByName,
    );
    this.productList = sortedProductList;
  }

  onSortByScore(direction: Directions) {
    this.sortByScore = direction;
    this.sortByName = Directions.NONE;
    const sortedProductList = [...this.productList].sort((a, b) =>
      a.score > b.score ? this.sortByScore : this.sortByScore * -1,
    );
    this.productList = sortedProductList;
  }
}
