<ng-container *ngIf="asset">
  <h3 class="section-header">Rizikový profil</h3>

  <ng-template #investmentPreferenceLabel>
    <div class="question-label">{{ INVESTMENT_QUESTION_PREFERENCE_LABEL }}</div>
  </ng-template>
  <kpt-radio-group
    [label]="INVESTMENT_QUESTION_PREFERENCE_LABEL"
    [labelTemplate]="investmentPreferenceLabel"
    [options]="INVESTMENT_QUESTION_PREFERENCE_ITEMS"
    name="investmentPreference"
    [(ngModel)]="asset.investmentPreference"
    required
  ></kpt-radio-group>

  <ng-template #investmentPortfolioLabel>
    <!-- label template does not support label higher than 1 row of text, show nothing instead
    and provide custom label
     -->
  </ng-template>
  <div class="custom-label">
    <div class="question-label">{{ INVESTMENT_QUESTION_PORTFOLIO_LABEL }}</div>
    <img [src]="getPortfolioImageUrl()" />
  </div>
  <kpt-radio-group
    [labelTemplate]="investmentPortfolioLabel"
    [options]="INVESTMENT_QUESTION_PORTFOLIO_ITEMS"
    name="investmentPortfolio"
    [(ngModel)]="asset.investmentPortfolio"
    required
  ></kpt-radio-group>

  <ng-template #investmentLossValueLabel>
    <div class="question-label">{{ INVESTMENT_QUESTION_LOSS_VALUE_LABEL }}</div>
  </ng-template>
  <kpt-radio-group
    [label]="INVESTMENT_QUESTION_LOSS_VALUE_LABEL"
    [labelTemplate]="investmentLossValueLabel"
    [options]="INVESTMENT_QUESTION_LOSS_VALUE_ITEMS"
    name="investmentLossValue"
    [(ngModel)]="asset.investmentLossValue"
    required
  ></kpt-radio-group>
</ng-container>
