import {Subject} from 'rxjs';

export enum ReasonType {
  REFUSE_TO_FILL_SURVEY = 'refuseToFillSurvey',
  OTHER_REASON = 'otherReason',
}

export interface TerminationReason {
  reason: string;
  otherReasonText: string;
}

export interface CancelMeetingData {
  sendERecord: (reason: string) => void;
  eRecordSent$: Subject<boolean>;
  redirect: () => void;
}
