import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {GuardService} from '../../client/services/guard.service';

@Injectable()
export class CanLoadLifeInsurance implements CanActivate {
  constructor(
    private analysisDataService: AnalysisDataService,
    private router: Router,
    private guardService: GuardService,
  ) {}

  // https://github.com/angular/angular/issues/28306
  // has to be canActivate
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const familyId = route.paramMap.get('familyId');
    this.guardService.setTargetUrl(state.url);

    return this.analysisDataService.isLifeInsuranceDataAvailable$(familyId).pipe(
      map((result: boolean) => {
        if (
          // force queryParameter allows user enter the module regardless the other conditions
          // after redirecting, this parameter is removed right away
          route.queryParams.force === 'true'
        ) {
          return true;
        }

        if (!result) {
          // show guard modal
          this.guardService.setModalData({
            module: 'life-insurance',
            open: true,
            restrictType: 'full',
          });

          return this.router.createUrlTree(['/secured/client/', familyId], {
            queryParams: {
              tab: 'subjects',
            },
          });
        }

        return true;
      }),
    );
  }
}
