<div class="phase">
  <div class="phase__item bg--green-200">
    <span class="phase__item-label">1. fáze</span>
    <span class="h3 d-flex text-color--white mb-0">Zjišťujeme</span>
    Vaše potřeby, možnosti a plány do budoucna
  </div>
  <div class="phase__arrow-wrapper">
    <div class="phase__arrow">
      <span class="icon icon--arrow-link phase__arrow-icon"></span>
    </div>
  </div>
  <div class="phase__item bg--green-300">
    <span class="phase__item-label">2. fáze</span>
    <span class="h3 d-flex text-color--white mb-0">Předložíme</span>
    Vám návrhy osobního finančního plánu
  </div>
  <div class="phase__arrow-wrapper">
    <div class="phase__arrow">
      <span class="icon icon--arrow-link phase__arrow-icon"></span>
    </div>
  </div>
  <div class="phase__item bg--blue">
    <span class="phase__item-label">3. fáze</span>
    <span class="h3 d-flex text-color--white mb-0">Nastavíme</span>
    produkty a služby, předáme Vám finanční plán
  </div>
</div>
