import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FieldErrorComponent} from '@shared/ui/forms/field-error/field-error.component';

@NgModule({
  declarations: [FieldErrorComponent],
  imports: [CommonModule],
  exports: [FieldErrorComponent],
  providers: [DecimalPipe],
})
export class FieldErrorModule {}
