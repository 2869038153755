<div class="p-column header-column">
  <div class="p-row header">
    <h3>{{ proposal.person.name }}</h3>
  </div>
  <ng-container
    *ngFor="let riskDef of proposal.riskDefs; trackBy: trackByRiskId; let index = index"
  >
    <div class="p-row" *ngIf="!riskDef.editable" [innerHTML]="riskDef.label"></div>
    <div class="p-row custom-risk" *ngIf="riskDef.editable">
      <kpt-input [formControl]="risksForm.at(index)" customFormGroupClass="input-no-stepper">
      </kpt-input>
      <a class="icon--interactive" (click)="deleteCustomRisk(riskDef.id)">
        <span class="icon icon--bin"></span>
      </a>
    </div>
  </ng-container>
  <div class="p-row footer">Cena</div>
  <div class="mt-3 add-other-risk">
    <kpt-button
      type="primary"
      icon="icon--plus"
      label="Přidat další riziko"
      (action)="addCustomRisk(proposal.person.id)"
    ></kpt-button>
  </div>
</div>
