import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {isOwnContract} from '@shared/analysis/asset.utils';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {FormModule} from '@shared/ui';
import {FormlyFormHandlerService} from '@shared/ui/formly/formly-form-handler.service';
import {FormlyKappkaModule} from '@shared/ui/formly/formly.module';
import {cloneDeep, isEqual} from 'lodash';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, skip} from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-asset-form-formly',
  templateUrl: './asset-form-formly.component.html',
  imports: [CommonModule, ReactiveFormsModule, FormlyKappkaModule, FormModule],
  providers: [FormlyFormHandlerService],
})
export class AssetFormFormlyComponent<T extends Asset> implements OnInit, OnChanges {
  @Input() value: T;
  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Input() disabled = false;

  value$ = new BehaviorSubject<T>(null);

  /**
   * When we receive a read-only `value`, we turn it into a writable model which is passed to formly
   */
  model: T;

  AssetType = AssetType;

  form = new FormGroup({});
  assetDef$ = new BehaviorSubject<AssetDefinition>(null);
  fields$: Observable<FormlyFieldConfig[]>;

  constructor(
    private assetTypeService: AssetTypeService,
    private formlyFormHandler: FormlyFormHandlerService,
  ) {}

  ngOnInit() {
    this.value$.pipe(untilDestroyed(this)).subscribe(value => {
      this.model = cloneDeep(value);
    });

    const formChange$ = this.form.valueChanges.pipe(
      map(() => cloneDeep(this.model)),
      distinctUntilChanged(isEqual),
      skip(1), // skip initial value
      untilDestroyed(this),
    );
    formChange$.subscribe(model => {
      if (!model?.type) {
        throw new Error('AssetFormComponent: refusing to emit valueChange with no asset type set.');
      }
      this.valueChange.next(model);
    });

    this.value$.pipe(distinctUntilChanged(isEqual), untilDestroyed(this)).subscribe(value => {
      const assetDef = this.assetTypeService.getAssetDefinition(value.type);
      if (value?.type !== this.assetDef$.value?.type) {
        this.assetDef$.next(assetDef);
      }
    });
    this.fields$ = combineLatest([this.assetDef$, this.value$]).pipe(
      map(([assetDef, value]) => {
        const readOnlyFields = isOwnContract(value)
          ? ['partnerId', 'productId', 'contractNumber']
          : [];

        const fields = this.setFields(assetDef, readOnlyFields);
        return fields;
      }, untilDestroyed(this)),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (!isEqual(changes.value.currentValue, this.value$.value)) {
        this.value$.next(changes.value.currentValue);
      }
    }

    if (changes.disabled) {
      setTimeout(() => {
        changes.disabled.currentValue ? this.form.disable() : this.form.enable();
      });
    }
  }

  setSubmitted() {
    this.formlyFormHandler.markAsTouched(this.form);
    this.formlyFormHandler.setSubmitted();
  }

  isValid(): boolean {
    return this.form.valid;
  }

  getValue(): T {
    const value = {
      ...this.form.getRawValue(),
      type: this.model.type,
    };
    if (!value?.type) {
      throw new Error(`'AssetFormComponent: no asset type set on value!`);
    }
    return value as T;
  }

  patchValue(changes: Partial<T>) {
    const newValue = {...this.model, ...changes};
    this.value$.next(newValue);
  }

  private setFields(assetDef: AssetDefinition, readOnlyFields: string[]): FormlyFieldConfig[] {
    return assetDef.fields.map(f => this.setField(assetDef, f, readOnlyFields));
  }

  private setField(
    assetDef: AssetDefinition,
    field: FormlyFieldConfig,
    readOnlyFields: string[],
  ): FormlyFieldConfig {
    // process groups and arrays
    if (!field.key) {
      if (field.fieldGroup)
        field.fieldGroup = field.fieldGroup.map(f => this.setField(assetDef, f, readOnlyFields));
      if (field.fieldArray)
        field.fieldArray = this.setField(assetDef, field.fieldArray, readOnlyFields);
      return field;
    }

    const key = String(field.key);

    // set validators
    const validation = assetDef?.validators?.[key];
    if (validation) field.validators = {validation};

    // disable fields editable only in core
    if (field.templateOptions) field.templateOptions.disabled = readOnlyFields.includes(key);

    return field;
  }
}
