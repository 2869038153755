/* eslint-disable max-len */
import {getGetRisksStateSelector} from '@generated/store/analysis/getRisks/states/reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {ChartColors} from '@shared/analysis/components/chart/chart-colors';
import {Chart, Column} from '@shared/analysis/components/chart/chart.component';
import {isLiveOrDraftStatus, isTerminatedSecondaryStatus} from '@shared/analysis/core-sync.data';
import {calculateCurrentEquity, calculateMonthlySum} from '@shared/analysis/evaluation';
import {FieldLabel} from '@shared/analysis/field-label';
import {
  AdvisorProposalState,
  allInvestmentAssets,
  Asset,
  assetNames,
  AssetType,
  basicInvestmentAssets,
  ClientProposalState,
  commonExpenseAssets,
  creditAssets,
  financialProductAssets,
  financialPropertyAssets,
  immovablePropertyAssets,
  incomeAssets,
  insuranceAssets,
  mainIncomesAssets,
  movablePropertyAssets,
  NameAsset,
  objectivesAssets,
  otherIncomesAssets,
  otherInvestmentAssets,
  pensionInvestmentAssets,
  RelatedObjectiveAsset,
  requirementsAssets,
} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {
  applyIndispensability,
  calculateCommonExpensesSum,
  calculateCreditProductsSum,
  calculateInsuranceProductsSum,
  calculateInvestmentProductsSum,
  replaceContractorAssetType,
} from '@shared/analysis/store/financial-analysis.helpers';
import {FinancialAnalysisState} from '@shared/analysis/store/financial-analysis.state';
import {IOption} from '@shared/ui';
import {compact, isNil, reject} from 'lodash';
import {
  AssetPageOverview,
  PropertyOverview,
} from 'src/app/modules/financial-analysis/shared/models/overview.model';
import {notLoadingNorError} from 'src/store/store-helpers';
import {
  filterAssets,
  findAsset,
  isCommonFinancialProductAsset,
  isForeignContract,
  sumAssetTypes,
} from '../asset.utils';
import {ChartSizes} from '../components/chart/chart-sizes';
import {FinancesSection, PropertiesSection} from '../models/section';
/* eslint-enable max-len */

export const storeFeatureName = 'financialAnalysis';

export const selectFinancialAnalysisState =
  createFeatureSelector<FinancialAnalysisState>(storeFeatureName);

export const selectAssets = createSelector(selectFinancialAnalysisState, state => state.assets);

export const selectAssetsForBE = createSelector(
  selectFinancialAnalysisState,
  state => state.assets,
);

export const selectAssetsIncludedInAnalysis = createSelector(
  selectFinancialAnalysisState,
  state => {
    return reject(state.assets, isAssetExcludedFromAnalysis);
  },
);

export const selectAssetsExcludedFromAnalysis = createSelector(
  selectFinancialAnalysisState,
  state => {
    return state.assets.filter(isAssetExcludedFromAnalysis);
  },
);

export const selectAllAssetsForEditing = createSelector(selectFinancialAnalysisState, state => {
  if (state.currentlyEditedAsset) {
    return [
      ...reject(state.assets, asset => asset.assetUuid === state.currentlyEditedAsset.assetUuid),
      state.currentlyEditedAsset,
    ];
  }
  return state.assets;
});

export const selectAllAssets = createSelector(
  selectFinancialAnalysisState,
  selectAssetsIncludedInAnalysis,
  (state, assets) => {
    if (state.currentlyEditedAsset) {
      return [
        ...reject(assets, asset => asset.assetUuid === state.currentlyEditedAsset.assetUuid),
        state.currentlyEditedAsset,
      ];
    }
    return assets;
  },
);

export const selectCurrentAssets = createSelector(selectAllAssets, assets =>
  assets.filter(asset => asset.proposal !== true),
);

export const selectProposedAssets = createSelector(selectAllAssets, assets =>
  assets.filter(asset => asset.proposal === true),
);

const getAdvisorProposedAssets = (currentAssets: Asset[], proposedAssets: Asset[]) => {
  const unchangedAndTerminatedAssets = currentAssets.filter(
    asset =>
      asset.advisorProposalState === AdvisorProposalState.Unchanged ||
      asset.advisorProposalState === AdvisorProposalState.Terminated,
  );

  const newAndUpdatedAssets = proposedAssets.filter(
    asset =>
      asset.advisorProposalState === AdvisorProposalState.New ||
      asset.advisorProposalState === AdvisorProposalState.Updated,
  );

  return [...newAndUpdatedAssets, ...unchangedAndTerminatedAssets];
};

const getClientProposedAssets = (currentAssets: Asset[], proposedAssets: Asset[]) => {
  const unchangedAndTerminatedAssets = currentAssets.filter(
    asset =>
      asset.clientProposalState === ClientProposalState.Unchanged ||
      asset.clientProposalState === ClientProposalState.ClientTerminated ||
      asset.clientProposalState === ClientProposalState.AdvisorTerminated,
  );

  const updatedAssets = proposedAssets.filter(
    asset =>
      asset.clientProposalState === ClientProposalState.AdvisorUpdated ||
      asset.clientProposalState === ClientProposalState.ClientUpdated,
  );

  const newAssets = proposedAssets.filter(
    asset =>
      asset.clientProposalState === ClientProposalState.NewClientApproved ||
      asset.clientProposalState === ClientProposalState.NewClientUpdated ||
      asset.clientProposalState === ClientProposalState.NewClientRejected,
  );

  return [...unchangedAndTerminatedAssets, ...updatedAssets, ...newAssets];
};

export const selectObjectiveAdvisorProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  (currentAssets: Asset[], proposedAssets: Asset[]) => {
    const hasRelatedObjectiveUuid = (asset: Asset) =>
      (asset as RelatedObjectiveAsset).relatedObjectiveUuid;
    const objectiveCurrentAssets = currentAssets.filter(hasRelatedObjectiveUuid);
    const objectiveProposedAssets = proposedAssets.filter(hasRelatedObjectiveUuid);
    return getAdvisorProposedAssets(objectiveCurrentAssets, objectiveProposedAssets);
  },
);

export const selectObjectiveClientProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  (currentAssets, proposedAssets) => {
    const hasRelatedObjectiveUuid = (asset: Asset) =>
      (asset as RelatedObjectiveAsset).relatedObjectiveUuid;
    const objectiveCurrentAssets = currentAssets.filter(hasRelatedObjectiveUuid);
    const objectiveProposedAssets = proposedAssets.filter(hasRelatedObjectiveUuid);
    return getClientProposedAssets(objectiveCurrentAssets, objectiveProposedAssets);
  },
);

export const selectAdditionalAdvisorProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  getAdvisorProposedAssets,
);

export const selectAdditionalClientProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  getClientProposedAssets,
);

export const selectCommonExpensesSum = createSelector(
  selectCurrentAssets,
  calculateCommonExpensesSum,
);

export const selectCommonIndispensableExpensesSum = createSelector(
  selectCurrentAssets,
  assets =>
    -calculateMonthlySum(
      applyIndispensability(
        filterAssets(assets, [...commonExpenseAssets, AssetType.CommonExpensesSum]).map(
          replaceContractorAssetType,
        ),
      ),
    ),
);

export const selectDispensableExpensesSum = createSelector(
  selectCommonExpensesSum,
  selectCommonIndispensableExpensesSum,
  (commonExpensesSum, commonIndispensableExpensesSum) =>
    commonExpensesSum - commonIndispensableExpensesSum,
);

export const selectAssetsByType = (assetTypes: AssetType[]) =>
  createSelector(selectCurrentAssets, assets => filterAssets(assets, assetTypes));

export const selectIncomesAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, incomeAssets),
);

export const selectInsuranceProductsSum = createSelector(
  selectCurrentAssets,
  calculateInsuranceProductsSum,
);

export const selectInvestmentProductsSum = createSelector(
  selectCurrentAssets,
  calculateInvestmentProductsSum,
);

export const selectCreditProductsSum = createSelector(
  selectCurrentAssets,
  calculateCreditProductsSum,
);

export const selectObjectivesAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, objectivesAssets),
);

export const selectRequirementsAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, requirementsAssets),
);

export const selectCurrentAccountAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, [AssetType.CurrentAccount]),
);

export const selectFinancialProductsSum = createSelector(
  selectInsuranceProductsSum,
  selectInvestmentProductsSum,
  selectCreditProductsSum,
  (insuranceProductsSum, investmentProductsSum, creditProductsSum) =>
    insuranceProductsSum + investmentProductsSum + creditProductsSum,
);

export const selectExpensesSum = createSelector(
  selectCommonExpensesSum,
  selectFinancialProductsSum,
  (commonExpensesSum, financialProductsSum) => commonExpensesSum + financialProductsSum,
);

export const selectIndispensableExpensesSum = createSelector(
  selectCommonIndispensableExpensesSum,
  selectFinancialProductsSum,
  (commonIndispensableExpensesSum, financialProductsSum) =>
    commonIndispensableExpensesSum + financialProductsSum,
);

export const selectMainIncomesSum = createSelector(selectCurrentAssets, assets =>
  calculateMonthlySum(filterAssets(assets, mainIncomesAssets)),
);

export const selectMainIncomes = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, mainIncomesAssets),
);

export const selectOtherIncomesSum = createSelector(selectCurrentAssets, assets =>
  calculateMonthlySum(filterAssets(assets, otherIncomesAssets)),
);

export const selectIncomesSum = createSelector(
  selectMainIncomesSum,
  selectOtherIncomesSum,
  (mainIncome, otherIncome) => mainIncome + otherIncome,
);

export const selectMonthlyBalance = createSelector(
  selectIncomesSum,
  selectExpensesSum,
  (incomes, expenses) => incomes - expenses,
);

export const selectImmovablePropertiesSum = createSelector(selectCurrentAssets, assets =>
  calculateCurrentEquity(
    filterAssets(assets, [...immovablePropertyAssets, AssetType.ImmovablePropertiesSum]),
  ),
);

export const selectMovableImmovableProperties = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, [...movablePropertyAssets, ...immovablePropertyAssets]),
);

export const selectMovableProperties = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, movablePropertyAssets),
);

export const selectImmovableProperties = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, immovablePropertyAssets),
);

export const selectMovablePropertiesSum = createSelector(selectCurrentAssets, assets =>
  calculateCurrentEquity(
    filterAssets(assets, [...movablePropertyAssets, AssetType.MovablePropertiesSum]),
  ),
);

export const selectInsurancePropertiesSum = createSelector(selectCurrentAssets, assets =>
  calculateCurrentEquity(
    filterAssets(assets, [
      AssetType.InvestmentLifeInsurance,
      AssetType.CapitalLifeInsurance,
      AssetType.InsurancePropertiesSum,
    ]),
  ),
);

export const selectInvestmentPropertiesSum = createSelector(selectCurrentAssets, assets =>
  calculateCurrentEquity(
    filterAssets(assets, [
      ...basicInvestmentAssets,
      ...pensionInvestmentAssets,
      ...otherInvestmentAssets,
      AssetType.InvestmentPropertiesSum,
    ]),
  ),
);

export const selectCreditPropertiesSum = createSelector(
  selectCurrentAssets,
  assets =>
    -calculateCurrentEquity(filterAssets(assets, [...creditAssets, AssetType.CreditPropertiesSum])),
);

export const selectFinancialProductAssets = createSelector(
  selectCurrentAssets,
  assets => filterAssets(assets, financialProductAssets) as CommonFinancialProductAsset[],
);

export const selectFinancialProductAssetsWithExcluded = createSelector(selectAssets, assets => {
  const currentAssetsWithExcluded = assets.filter(asset => asset.proposal !== true);
  return filterAssets(
    currentAssetsWithExcluded,
    financialProductAssets,
  ) as CommonFinancialProductAsset[];
});

export const selectCommonBadDebtsAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, [AssetType.ConsumerLoan, AssetType.CreditCard, AssetType.Overdraft]),
);

export const selectSavingAccountAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, [AssetType.SavingsAccount]),
);

export const selectFinancialPropertiesSum = createSelector(selectCurrentAssets, assets =>
  calculateCurrentEquity(filterAssets(assets, financialPropertyAssets)),
);

export const selectPropertiesSum = createSelector(
  selectImmovablePropertiesSum,
  selectMovablePropertiesSum,
  selectInsurancePropertiesSum,
  selectInvestmentPropertiesSum,
  selectFinancialPropertiesSum,
  (
    immovablePropertiesSum,
    movablePropertiesSum,
    insurancePropertiesSum,
    investmentPropertiesSum,
    financialPropertiesSum,
  ) =>
    immovablePropertiesSum +
    movablePropertiesSum +
    insurancePropertiesSum +
    investmentPropertiesSum +
    financialPropertiesSum,
);

export const selectFinanceOverview = createSelector(
  selectExpensesSum,
  selectFinancialProductsSum,
  selectCommonIndispensableExpensesSum,
  selectIncomesSum,
  selectMonthlyBalance,
  (
    expenses,
    financialProductsSum,
    indispensableExpenses,
    incomes,
    monthlyBalance,
  ): AssetPageOverview[] => {
    return [
      {
        label: 'Celkové výdaje',
        value: expenses,
      },
      {
        label: 'Výdaje na finanční produkty',
        value: financialProductsSum,
      },
      {
        label: 'Běžné nezbytné výdaje',
        value: indispensableExpenses,
      },
      {
        label: 'Celkové příjmy',
        value: incomes,
      },
      {
        label: 'Měsíční zůstatek',
        value: monthlyBalance,
      },
    ];
  },
);

export const selectFinancialChart = createSelector(
  selectMainIncomesSum,
  selectOtherIncomesSum,
  selectExpensesSum,
  selectDispensableExpensesSum,
  selectMonthlyBalance,
  (mainIncome, otherIncome, expenses, dispensableExpenses, monthlyBalance): Chart => {
    const leftColumn: Column = {
      label: 'Celkové příjmy',
      sections: [
        {
          value: Math.max(expenses - mainIncome - otherIncome, 0),
          empty: true,
        },
        {
          label: 'Hlavní příjmy',
          value: mainIncome,
          color: ChartColors.incomeLightColor,
          alwaysShow: true,
        },
        {
          label: 'Další měsíční příjmy',
          value: otherIncome,
          color: ChartColors.incomeLightColor,
        },
      ],
    };
    const rightColumn: Column = {
      label: 'Výdaje a zůstatek',
      sections: [
        {
          label: 'Měsíční zůstatek',
          value: monthlyBalance,
          color: monthlyBalance >= 0 ? ChartColors.freeAssetColor : ChartColors.expenseColor,
        },
        {
          label: 'Zbytné výdaje',
          value: dispensableExpenses,
          color: ChartColors.dispensableExpenseColor,
        },
        {
          label: 'Nezbytné výdaje',
          value: expenses - dispensableExpenses,
          color: ChartColors.indispensableExpenseColor,
          alwaysShow: true,
        },
      ],
    };
    const size = ChartSizes.Small;
    return {
      leftColumn,
      rightColumn,
      size,
    };
  },
);

export const selectPropertiesOverview = createSelector(
  selectPropertiesSum,
  selectCreditPropertiesSum,
  (propertiesSum, creditPropertiesSum): AssetPageOverview[] => [
    {
      label: 'Majetek',
      value: propertiesSum,
    },
    {
      label: 'Závazky',
      value: creditPropertiesSum,
    },
    {
      label: 'Majetek po odečtení závazků',
      value: propertiesSum - creditPropertiesSum,
    },
  ],
);

export const selectPropertiesChart = createSelector(
  selectPropertiesSum,
  selectImmovablePropertiesSum,
  selectMovablePropertiesSum,
  selectInsurancePropertiesSum,
  selectInvestmentPropertiesSum,
  selectFinancialPropertiesSum,
  selectCreditPropertiesSum,
  (
    propertiesSum,
    immovablePropertiesSum,
    movablePropertiesSum,
    insurancePropertiesSum,
    investmentPropertiesSum,
    financialPropertiesSum,
    creditPropertiesSum,
  ): Chart => {
    const propertiesWithoutCreditSum = propertiesSum - creditPropertiesSum;
    const investmentSectionSum =
      investmentPropertiesSum + insurancePropertiesSum + financialPropertiesSum;
    const leftColumn: Column = {
      label: 'Celkový majetek',
      sections: [
        {
          value: Math.max(-propertiesWithoutCreditSum, 0),
          empty: true,
        },
        {
          label: 'Movitý majetek',
          value: movablePropertiesSum,
          color:
            investmentSectionSum > 0 ? ChartColors.incomeDarkColor : ChartColors.investmentColor,
          showPercent: true,
        },
        {
          label: 'Spoření a investice',
          value: investmentSectionSum,
          color: ChartColors.incomeColor,
          showPercent: true,
        },
        {
          label: 'Nemovitý majetek',
          value: immovablePropertiesSum,
          color: ChartColors.incomeLightColor,
          showPercent: true,
          alwaysShow: true,
        },
      ],
    };
    const rightColumn: Column = {
      label: 'Bilance majetku',
      sections: [
        {
          label: 'Celkový majetek po odečtení závazků',
          value: propertiesWithoutCreditSum,
          color:
            propertiesWithoutCreditSum >= 0
              ? ChartColors.freeAssetColor
              : ChartColors.expenseDarkColor,
          alwaysShow: true,
        },
        {
          label: 'Aktuální výše závazků',
          value: creditPropertiesSum,
          color: ChartColors.expenseColor,
        },
      ],
    };
    const size = ChartSizes.Small;
    return {
      leftColumn,
      rightColumn,
      size,
    };
  },
);

export const selectPropertyOverview = createSelector(
  selectPropertiesSum,
  selectCreditPropertiesSum,
  (propertiesSum, creditPropertiesSum): PropertyOverview => ({
    totalProperties: propertiesSum,
    creditProperties: creditPropertiesSum,
    netProperties: propertiesSum - creditPropertiesSum,
  }),
);

export const selectFinances = createSelector(
  selectCurrentAssets,
  selectAssetsExcludedFromAnalysis,
  selectCommonExpensesSum,
  selectInsuranceProductsSum,
  selectInvestmentProductsSum,
  selectCreditProductsSum,
  selectMainIncomesSum,
  selectOtherIncomesSum,
  (
    assets,
    excludedAssetsFromAnalysis,
    expensesSum,
    insuranceProductsSum,
    investmentProductsSum,
    creditProductsSum,
    mainIncomesSum,
    otherIncomesSum,
  ): Record<FinancesSection, AssetPageStateValue> => {
    const onlyExpensesAssets = filterAssets(assets, commonExpenseAssets);
    const onlyInsuranceProducts = filterAssets(assets, insuranceAssets);
    const onlyInvestmentProducts = filterAssets(assets, [
      ...basicInvestmentAssets,
      ...pensionInvestmentAssets,
    ]);
    const onlyCreditProducts = filterAssets(assets, creditAssets);
    const onlyMainIncomesAssets = filterAssets(assets, mainIncomesAssets);
    const onlyOtherIncomes = filterAssets(assets, otherIncomesAssets);
    return {
      mainIncomes: {
        assets: onlyMainIncomesAssets,
        title: FieldLabel.MAIN_FAMILY_INCOMES,
        sumEditable: false,
        showInput: false,
        sum: mainIncomesSum,
      },
      otherIncomes: {
        assets: onlyOtherIncomes,
        title: FieldLabel.OTHER_INCOMES,
        sumEditable: false,
        showInput: false,
        sum: otherIncomesSum,
      },
      expenses: {
        assets: onlyExpensesAssets,
        title: FieldLabel.COMMON_MONTHLY_EXPENSES,
        sumEditable: onlyExpensesAssets.length === 0,
        showInput: true,
        sum: expensesSum,
        sumAsset: findAsset(assets, sumAssetTypes.expenses),
      },
      insuranceProducts: {
        assets: onlyInsuranceProducts,
        excludedAssetsFromAnalysis: filterAssets(excludedAssetsFromAnalysis, insuranceAssets),
        title: FieldLabel.INSURANCE_PRODUCT_MONTHLY_EXPENSES,
        sumEditable: onlyInsuranceProducts.length === 0,
        showInput: true,
        sum: insuranceProductsSum,
        sumAsset: findAsset(assets, sumAssetTypes.insuranceProducts),
      },
      investmentProducts: {
        assets: onlyInvestmentProducts,
        excludedAssetsFromAnalysis: filterAssets(excludedAssetsFromAnalysis, [
          ...basicInvestmentAssets,
          ...pensionInvestmentAssets,
        ]),
        title: FieldLabel.INVESTMENT_PRODUCT_MONTHLY_EXPENSES,
        sumEditable: onlyInvestmentProducts.length === 0,
        showInput: true,
        sum: investmentProductsSum,
        sumAsset: findAsset(assets, sumAssetTypes.investmentProducts),
      },
      creditProducts: {
        assets: onlyCreditProducts,
        excludedAssetsFromAnalysis: filterAssets(excludedAssetsFromAnalysis, creditAssets),
        title: FieldLabel.CREDIT_PRODUCT_MONTHLY_EXPENSES,
        sumEditable: onlyCreditProducts.length === 0,
        showInput: true,
        sum: creditProductsSum,
        sumAsset: findAsset(assets, sumAssetTypes.creditProducts),
      },
    };
  },
);

export const selectContracts = createSelector(
  selectCurrentAssets,
  (assets): Partial<Record<FinancesSection, Asset[]>> => {
    const onlyInsuranceProducts = filterAssets(assets, insuranceAssets);
    const onlyInvestmentProducts = filterAssets(assets, allInvestmentAssets);
    const onlyCreditProducts = filterAssets(assets, creditAssets);
    return {
      insuranceProducts: onlyInsuranceProducts,
      investmentProducts: onlyInvestmentProducts,
      creditProducts: onlyCreditProducts,
    };
  },
);

export const selectProperties = createSelector(
  selectCurrentAssets,
  selectImmovablePropertiesSum,
  selectMovablePropertiesSum,
  selectInsurancePropertiesSum,
  selectInvestmentPropertiesSum,
  selectCreditPropertiesSum,
  selectFinancialPropertiesSum,
  (
    assets,
    immovablePropertiesSum,
    movablePropertiesSum,
    insurancePropertiesSum,
    investmentPropertiesSum,
    creditPropertiesSum,
    financialPropertiesSum,
  ): Record<PropertiesSection, AssetPageStateValue> => {
    const onlyImmovablePropertiesAssets = filterAssets(assets, immovablePropertyAssets);
    const onlyMovablePropertiesAssets = filterAssets(assets, movablePropertyAssets);
    const onlyInsurancePropertiesAssets = filterAssets(assets, [
      AssetType.InvestmentLifeInsurance,
      AssetType.CapitalLifeInsurance,
    ]);
    const onlyInvestmentPropertiesAssets = filterAssets(assets, allInvestmentAssets);
    const onlyCreditPropertiesAssets = filterAssets(assets, creditAssets);
    const onlyFinancialPropertiesAssets = filterAssets(assets, financialPropertyAssets);

    return {
      immovableProperties: {
        assets: onlyImmovablePropertiesAssets,
        title: 'Nemovitý majetek',
        sumEditable: onlyImmovablePropertiesAssets.length === 0,
        showInput: true,
        sum: immovablePropertiesSum,
        sumAsset: findAsset(assets, sumAssetTypes.immovableProperties),
      },
      movableProperties: {
        assets: onlyMovablePropertiesAssets,
        title: 'Movitý majetek',
        sumEditable: onlyMovablePropertiesAssets.length === 0,
        showInput: true,
        sum: movablePropertiesSum,
        sumAsset: findAsset(assets, sumAssetTypes.movableProperties),
      },
      insuranceProperties: {
        assets: onlyInsurancePropertiesAssets,
        title: 'Pojistné produkty',
        sumEditable: false,
        showInput: false,
        sum: insurancePropertiesSum,
        sumAsset: findAsset(assets, AssetType.InsurancePropertiesSum),
      },
      investmentProperties: {
        assets: onlyInvestmentPropertiesAssets,
        title: 'Spoření a investice',
        // sumEditable: onlyInvestmentPropertiesAssets.length === 0,
        sumEditable: false,
        showInput: false,
        sum: investmentPropertiesSum,
        sumAsset: findAsset(assets, sumAssetTypes.investmentProperties),
      },
      creditProperties: {
        assets: onlyCreditPropertiesAssets,
        title: 'Úvěry',
        sumEditable: onlyCreditPropertiesAssets.length === 0,
        showInput: true,
        sum: creditPropertiesSum,
        sumAsset: findAsset(assets, sumAssetTypes.creditProperties),
      },
      financialProperties: {
        assets: onlyFinancialPropertiesAssets,
        title: 'Běžný účet a hotovost',
        // sumEditable: onlyFinancialPropertiesAssets.length === 0,
        sumEditable: false,
        showInput: false,
        sum: financialPropertiesSum,
      },
    };
  },
);

export const selectAsset = (assetUuid: string) =>
  createSelector(selectCurrentAssets, assets =>
    assets.find(asset => asset.assetUuid === assetUuid),
  );

export const selectAssetFromAll = (assetUuid: string) =>
  createSelector(selectAllAssetsForEditing, assets =>
    assets.find(asset => asset.assetUuid === assetUuid),
  );

export const selectAssetByType = (assetType: AssetType) =>
  createSelector(selectCurrentAssets, assets => assets.find(asset => asset.type === assetType));

export const selectAssetsAsOptions = (types: AssetType[] = null) =>
  createSelector(selectCurrentAssets, assets => {
    return assets
      .filter(asset => isNil(types) || types.includes(asset.type))
      .map(asset => {
        return {
          key: asset.assetUuid,
          label:
            assetNames[asset.type] +
            ((asset as NameAsset).name ? ' – ' + (asset as NameAsset).name : ''),
        } as IOption;
      });
  });

export const selectRisksAdditionalData = createSelector(
  getGetRisksStateSelector,
  risksAdditionalData => {
    if (notLoadingNorError(risksAdditionalData) && risksAdditionalData.data !== null) {
      return risksAdditionalData.data;
    }
    return null;
  },
);

export function getAssetName(asset: Asset): string {
  return compact([assetNames[asset.type], (asset as NameAsset).name]).join(' \u2013 ');
}

function isAssetExcludedFromAnalysis(asset: Asset) {
  if (asset.excludedFromAnalysis) return true;
  if (!isCommonFinancialProductAsset(asset)) return false;
  if (isTerminatedSecondaryStatus(asset.coreSecondaryStatus)) return true;
  if (isForeignContract(asset)) return false;
  return !isLiveOrDraftStatus(asset.coreStatus);
}
