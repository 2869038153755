import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {IAlertType} from './models/type.model';

@Component({
  selector: 'kpt-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent {
  @Input() type: IAlertType;
  @Input() isDismissible = false;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.type = Object.values(IAlertType).includes(this.type) ? this.type : IAlertType.INFO;
  }
}
