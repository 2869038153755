import {RiskId} from 'src/store/models/risk.models';

export const requiredRisksInPriorityQuestionnaire = [
  RiskId.DeathDebtRepayment,
  RiskId.DeathThriceAnnualIncome,
];

// used in priority questionnaire for filtering categories based on user's selected risks in LifeInsurance
export const priorityQuestionnaireCategoryRiskMap: Record<string, string> = {
  [RiskId.Invalidity]: 'recGQNFe6rGHvGITD',
  [RiskId.DeathDebtRepayment]: 'rectDpMYIzhSOaJEp',
  [RiskId.DeathThriceAnnualIncome]: 'rectDpMYIzhSOaJEp',
  [RiskId.SeriousDisease]: 'recN0mxJXEDBhp1xv',
  [RiskId.Incapacity]: 'recSGOCEm5GeO6Gws',
  [RiskId.PermanentDisability]: 'recCmZ9Ra7awj2vUU',
};

export const priorityQuestionnaireExcludeCriteriaMale: string[] = ['recoWC8oFDDzK419X'];

export const requiredCategories = ['rectDpMYIzhSOaJEp'];
