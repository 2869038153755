import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {DirectivesModule} from '@shared/directives';
import {TooltipComponent} from './tooltip.component';

@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule, DirectivesModule, MatIconModule],
  exports: [TooltipComponent],
})
export class TooltipModule {}
