import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RisksSelectionModule} from '@shared/analysis/components/risks-selection/risks-selection.module';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {ButtonModule, FormModule, MenuItemModule, SelectModule, TabsModule} from '@shared/ui';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {RisksLifeInsuranceFormlyComponent} from './risks-life-insurance-formly.component';

@NgModule({
  declarations: [RisksLifeInsuranceFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    ButtonModule,
    MenuItemModule,
    FormModule,
    RisksSelectionModule,
    TabsModule,
  ],
  exports: [RisksLifeInsuranceFormlyComponent],
})
export class RisksLifeInsuranceFormlyModule {}
