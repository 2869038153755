export interface IAccordionItem {
  type?: string;
  label: string;
  data?: any;
  value?: any;
  saving?: any;
  currency?: string;
  isActive: boolean;
  isDisabled?: boolean;
  stateColor?: string;
}
