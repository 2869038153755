import {AssetType} from '@shared/analysis/models/asset';

export interface EventSituationButtonConfig {
  label: string;
  labelFormatted?: string;
  type: AssetType;
  icon?: string;
  iconPosition?: 'left' | 'right';
  tooltip?: string;
  buttonType?: string;
  isActive?: boolean;
  isDraggable?: boolean;
  isUsed?: boolean;
  isDragged?: boolean;
  onlyOne?: boolean;
}
