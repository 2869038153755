/* eslint-disable max-classes-per-file */
import {ConsumerCreditDemand} from '@generated/defs/ConsumerCreditDemand';
import {Action} from '@ngrx/store';
import {ConsumerCreditOutput} from '../models/consumer-credit.models';

export enum ConsumerActionTypes {
  UpdateInput = '[Consumer] Update Input',
  UpdateInputOutput = '[Consumer] Update Input/Output',
}

export class UpdateConsumerInput implements Action {
  readonly type = ConsumerActionTypes.UpdateInput;

  constructor(public payload: Partial<ConsumerCreditDemand>) {}
}

export class UpdateConsumerInputOutput implements Action {
  readonly type = ConsumerActionTypes.UpdateInputOutput;

  constructor(
    public payload: {input: Partial<ConsumerCreditDemand>; output: Partial<ConsumerCreditOutput>},
  ) {}
}

export type ConsumerActions = UpdateConsumerInput | UpdateConsumerInputOutput;
