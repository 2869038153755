<ng-container *ngIf="asset">
  <ng-container *ngIf="mode === 'analysis'">
    <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

    <kpt-row>
      <kpt-objective-age-input
        grow
        label="Chci mít zajištění ve věku"
        name="start"
        [(start)]="this.asset.start"
      ></kpt-objective-age-input>
      <kpt-input grow label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>
    </kpt-row>
  </ng-container>

  <kpt-button-group
    *ngIf="mode !== 'summary'"
    class="person-buttons"
    [items]="personButtons$ | async"
    [selectedValue]="selectedPersonId$ | async"
    (selectedValueChange)="selectedPersonId$.next($event)"
  ></kpt-button-group>

  <span *ngIf="!disabled">Vyberte rizika, která chcete zajistit:</span>
  <ng-container *ngIf="selectedPerson$ | async as selectedPerson">
    <ng-container *ngIf="personData$ | async as personData">
      <div>
        <table class="person-risk-table">
          <thead>
            <tr>
              <th></th>
              <ng-container *ngIf="!selectedPerson.child">
                <th>Zajištění příjmů</th>
                <th>Zajištění výdajů</th>
              </ng-container>
              <ng-container *ngIf="selectedPerson.child">
                <th>Standardní zajištění</th>
                <th>Minimální zajištění</th>
              </ng-container>
              <th>Limit</th>
              <th>Věk</th>
            </tr>
          </thead>
          <ng-container *ngFor="let row of rows$ | async; trackBy: trackByPersonAndRisk">
            <tr>
              <td>
                {{ row.leftRisk?.riskDef.label ?? row.rightRisk?.riskDef.label }}
              </td>
              <td>
                <div class="checkbox-cell">
                  <kpt-checkbox
                    [label]="'&nbsp;'"
                    class="risk-checkbox"
                    [disabledField]="!row.leftRisk"
                    [name]="row.riskKey + '-left'"
                    [ngModel]="row.leftRisk?.data.active"
                    (ngModelChange)="setActive(row.leftRisk, $event)"
                  >
                  </kpt-checkbox>
                  <div
                    *ngIf="mode === 'plan' && row.leftRisk?.warning"
                    class="icon icon--warning icon--large"
                    [tooltip]="row.leftRisk?.warning"
                  ></div>
                </div>
              </td>
              <td>
                <div class="checkbox-cell">
                  <span class="icon-wrapper"></span>
                  <kpt-checkbox
                    [label]="'&nbsp;'"
                    class="risk-checkbox"
                    [disabledField]="!row.rightRisk"
                    [name]="row.riskKey + '-right'"
                    [ngModel]="row.rightRisk?.data.active"
                    (ngModelChange)="setActive(row.rightRisk, $event)"
                  >
                  </kpt-checkbox>
                  <span class="icon-wrapper">
                    <span
                      *ngIf="mode === 'plan' && row.rightRisk?.warning"
                      class="icon icon--warning icon--large"
                      [tooltip]="row.rightRisk?.warning"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <kpt-input
                  *ngIf="row.leftRisk?.data.active || row.rightRisk?.data.active"
                  type="number"
                  appendText="Kč"
                  [name]="row.riskKey + '-limit'"
                  [ngModel]="row.limit"
                  (ngModelChange)="setLimit(row, $event)"
                ></kpt-input>
                <div
                  *ngIf="mode === 'plan' && limitLowerThanRecommended(row)"
                  class="recommended link"
                  (click)="setRecommendedLimit(row)"
                >
                  Doporučeno
                  {{
                    (row.leftRisk?.data.active
                      ? row.leftRisk.calculatedValue
                      : row.rightRisk?.calculatedValue
                    ) | currencyCZK
                  }}
                </div>
              </td>
              <td>
                <kpt-input
                  *ngIf="row.leftRisk?.data.active || row.rightRisk?.data.active"
                  type="number"
                  appendText="let"
                  [name]="row.riskKey + '-age'"
                  [ngModel]="row.age"
                  (ngModelChange)="setAge(row, $event)"
                ></kpt-input>
              </td>
            </tr>
          </ng-container>

          <tr *ngIf="!selectedPerson.child" class="profession-row">
            <td>Máte rizikové povolání?</td>
            <td colspan="2">
              <div class="radio-group-cell">
                <kpt-radio-group
                  [options]="[
                    {label: 'Ano', key: true},
                    {label: 'Ne', key: false}
                  ]"
                  itemClass="d-inline-block mr-9"
                  name="riskProfession"
                  [(ngModel)]="personData.riskProfession"
                ></kpt-radio-group>
              </div>
            </td>
            <td colspan="2">
              <kpt-input
                name="riskProfessionNote"
                [(ngModel)]="personData.riskProfessionNote"
              ></kpt-input>
            </td>
          </tr>
        </table>
      </div>

      <kpt-accordion-container
        customClass="p-0 d-flex align-items-center"
        *ngIf="!selectedPerson.child"
      >
        <kpt-reserves-accordion
          [personId]="selectedPerson.id"
          [personData]="personData"
          [mode]="mode"
        >
        </kpt-reserves-accordion>

        <kpt-pension-ensurement-accordion
          [person]="selectedPerson"
          [personData]="personData"
          [readonly]="disabled"
        >
        </kpt-pension-ensurement-accordion>
      </kpt-accordion-container>
    </ng-container>
  </ng-container>

  <kpt-input
    label="Poznámka (např. jiná rizika...)"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>

  <kpt-investment-risk-profile
    *ngIf="wantsInvestment$ | async"
    [asset]="asset"
  ></kpt-investment-risk-profile>
</ng-container>
