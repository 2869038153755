import {createAction, props, union} from '@ngrx/store';
import {PensionGraphType, PensionPerson} from './pension.models';

export const initializePersons = createAction(
  '[Pension] Initialize Persons',
  props<{persons: PensionPerson[]; familyHeadId: string}>(),
);

export const updatePerson = createAction(
  '[Pension] Update Person',
  props<{person: PensionPerson}>(),
);

export const selectPerson = createAction('[Pension] Select Person', props<{personId: string}>());

export const toggleMyPensionGraph = createAction(
  '[Pension] Toggle My Pension Graph',
  props<{graphType: PensionGraphType}>(),
);

export const PensionActions = {
  initializePersons,
  updatePerson,
  selectPerson,
  toggleMyPensionGraph,
};

const allActions = union(PensionActions);

export type PensionActionsUnion = typeof allActions;
