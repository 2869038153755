import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@lib/pipes';
import {FormlyModule} from '@ngx-formly/core';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {ButtonModule, ModalModule, SelectModule} from '@shared/ui';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AssetsDefinitionsModule} from 'src/shared/analysis/forms/definitions.module';
import {AssetDetailModalComponent} from './asset-detail-modal.component';
import {PartnerLogoComponent} from './partner-logo/partner-logo.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    SelectModule,
    FormlyModule,
    ReactiveFormsModule,
    ButtonModule,
    AssetsDefinitionsModule,
    BackendValidationModule,
    PipesModule,
    AngularSvgIconModule.forRoot(),
    AssetFormComponent,
  ],
  exports: [AssetDetailModalComponent],
  declarations: [AssetDetailModalComponent, PartnerLogoComponent],
})
export class AssetDetailModalModule {}
