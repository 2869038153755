import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CommonBaseModule} from '@shared/lib';
import {ModalContainerModule} from '@shared/lib/components/modal/modal-container.module';
import {FamilyService} from '@shared/services/family/family.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CommonBaseModule, ModalContainerModule],
  providers: [FamilyService],
})
export class FamilyModule {}
