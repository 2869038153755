import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CoreContractsState} from '@shared/analysis/store/core-contracts.state';

export const storeFeatureName = 'coreContracts';

export const selectCoreContractsState = createFeatureSelector<CoreContractsState>(storeFeatureName);

export const selectCoreContracts = createSelector(
  selectCoreContractsState,
  state => state.coreContracts,
);

export const selectCoreContractByUuid = (sugarUuid: string) =>
  createSelector(selectCoreContracts, contracts => contracts.find(({id}) => id === sugarUuid));
