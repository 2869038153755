import {Component, Input} from '@angular/core';

@Component({
  selector: 'kpt-bubbles',
  templateUrl: './bubbles.component.html',
  styleUrls: ['./bubbles.component.scss'],
})
export class BubblesComponent {
  @Input() incomes: number;
  @Input() costs: number;
}
