export interface MenuItemImage {
  src: string;
  alt: string;
  title: string;
}

export interface MenuItem {
  label: string;
  target?: string;
  icon?: string;
  image?: MenuItemImage;
  items?: MenuItem[];
  addDivider?: boolean;
  disabled?: boolean;
  toggledAtInit?: boolean;
}
