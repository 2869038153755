import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'kpt-modal-wrapper',
  templateUrl: `./modal-wrapper.component.html`,
  styleUrls: ['./modal-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalWrapperComponent {
  @Input() heading: string;
  @Input() headingTemplate: TemplateRef<any>;
  @Input() topLeftTemplate: TemplateRef<any>;
  @Input() hideClose = false;
  @Output() close = new EventEmitter<void>();

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(_: KeyboardEvent) {
    this.close.emit();
  }
}
