import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {
  INVESTMENT_QUESTION_LOSS_VALUE_ITEMS,
  INVESTMENT_QUESTION_LOSS_VALUE_LABEL,
  INVESTMENT_QUESTION_PORTFOLIO_ITEMS,
  INVESTMENT_QUESTION_PORTFOLIO_LABEL,
  INVESTMENT_QUESTION_PREFERENCE_ITEMS,
  INVESTMENT_QUESTION_PREFERENCE_LABEL,
  ValueLabelPair,
} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile-portal';
import {AssetType} from '@shared/analysis/models/asset';
import {FamilyProvisionAsset, InvestmentObjectiveAsset} from '@shared/analysis/models/objectives';
import {FormModule, IOption} from '@shared/ui';
import * as moment from 'moment';

@Component({
  standalone: true,
  selector: 'kpt-investment-risk-profile',
  templateUrl: './investment-risk-profile.component.html',
  styleUrls: ['./investment-risk-profile.component.scss'],
  imports: [CommonModule, FormModule, FormsModule],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InvestmentRiskProfileComponent {
  @Input() asset: InvestmentObjectiveAsset | FamilyProvisionAsset;

  INVESTMENT_QUESTION_PREFERENCE_LABEL = INVESTMENT_QUESTION_PREFERENCE_LABEL;
  INVESTMENT_QUESTION_PREFERENCE_ITEMS = toOptions(INVESTMENT_QUESTION_PREFERENCE_ITEMS);
  INVESTMENT_QUESTION_PORTFOLIO_LABEL = INVESTMENT_QUESTION_PORTFOLIO_LABEL;
  INVESTMENT_QUESTION_PORTFOLIO_ITEMS = toOptions(INVESTMENT_QUESTION_PORTFOLIO_ITEMS);
  INVESTMENT_QUESTION_LOSS_VALUE_LABEL = INVESTMENT_QUESTION_LOSS_VALUE_LABEL;
  INVESTMENT_QUESTION_LOSS_VALUE_ITEMS = toOptions(INVESTMENT_QUESTION_LOSS_VALUE_ITEMS);

  getPortfolioImageUrl(): string {
    if (this.asset.type === AssetType.FamilyProvision) {
      return 'assets/images/investment-risk-profile/life_insurance.png';
    }

    const horizon = this.asset.start ? moment(this.asset.start).year() - moment().year() : 0;
    if (horizon <= 3) {
      return 'assets/images/investment-risk-profile/investment_01_03.png';
    } else if (horizon <= 5) {
      return 'assets/images/investment-risk-profile/investment_03_05.png';
    } else if (horizon <= 10) {
      return 'assets/images/investment-risk-profile/investment_05_10.png';
    } else {
      return 'assets/images/investment-risk-profile/investment_10_plus.png';
    }
  }
}

function toOptions(items: ValueLabelPair[]): IOption[] {
  return items.map(item => ({key: item.value, label: item.label}));
}
