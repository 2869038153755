<kpt-modal-wrapper heading="Přidat člena rodiny" (close)="dismiss()">
  <div #searchForm class="px-10">
    <p class="font-weight-medium">Pro přidání člena rodiny začněte nejdříve jeho vyhledáním.</p>
    <kpt-client-search-create-form
      [duplicateClientCheck]="clientSearchService.duplicateClientCheck"
      [clientFound]="clientSearchService.clientFound"
      [foundByBirthNumber]="clientSearchService.foundByBirthNumber"
      [enableCreation]="clientSearchService.enableCreation"
      (change)="clientSearchService.resetSearchState()"
      (search)="clientSearchService.searchClients($event)"
      (create)="createClient($event)"
    >
    </kpt-client-search-create-form>
  </div>
  <div #results>
    <kpt-client-search
      class="d-block pt-5"
      *ngIf="clientSearchService.showResultsWrapper"
      [foundClientsState]="findClients$ | async"
      [showSearchResults]="
        clientSearchService.showSearchResults && clientSearchService.duplicateClientCheck
      "
      [birthNumberToAssign]="clientSearchService.searchedBirthNumber"
      [showClientSelection]="false"
      (addToFamilyAction)="addClientToFamily($event)"
      (reassignAction)="reassign($event)"
    >
    </kpt-client-search>
    <div class="d-flex justify-content-center">
      <kpt-switch
        *ngIf="clientSearchService.clientFound"
        (valueChange)="clientSearchService.enableClientCreation($event, searchForm)"
        label="Požadovaný klient nebyl nalezen"
      >
      </kpt-switch>
    </div>
  </div>
</kpt-modal-wrapper>
