import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {FinancialAnalysisActions} from '@shared/analysis/store';
import {DialogService} from '@shared/services/modal.service';
import {BusinessCaseService} from 'src/app/modules/financial-plan/business-case.service';
import {State} from 'src/store';

@Component({
  selector: 'kpt-new-plan-modal',
  templateUrl: './new-plan-modal.component.html',
  styleUrls: ['./new-plan-modal.component.scss'],
})
export class NewPlanModalComponent {
  sending = false;

  constructor(
    private dialogService: DialogService,
    private businessCaseService: BusinessCaseService,
    private store: Store<State>,
  ) {}

  async resetFp() {
    try {
      this.sending = true;
      await this.businessCaseService.newFPDraft();
      this.store.dispatch(FinancialAnalysisActions.FA_RemoveProposedAssets());
    } finally {
      this.sending = false;
    }
    this.dialogService.close(void 0);
  }

  dismiss() {
    this.dialogService.close(void 0);
  }
}
