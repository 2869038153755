import {PrintSettings} from '@generated/model';
import {FinancialPlanData} from './financial-plan.models';

export interface FinancialPlanState {
  financialPlanData: FinancialPlanData;
  printSettings: PrintSettings;
}

export const initialFinancialPlanState: FinancialPlanState = {
  financialPlanData: {
    financial: {
      evaluation: {
        rating: 0,
        sentences: [],
      },
      recommendations: {
        rating: 0,
        sentences: [],
      },
    },
    properties: {
      evaluation: {
        rating: 0,
        sentences: [],
      },
      recommendations: {
        rating: 0,
        sentences: [],
      },
    },
    objectives: [],
    additionalContracts: {
      advisorSolution: null,
    },
  },
  printSettings: {
    hiddenSections: [],
  },
};
