import {HttpErrorResponse} from '@angular/common/http';
import {PortalClient} from '@generated/defs/PortalClient';
import produce from 'immer';
import {GetClientActions, GetClientActionTypes} from '../actions/get-client.actions';

export interface GetClientState {
  client: PortalClient;
  loading: boolean;
  error: HttpErrorResponse;
}

export const initialState: GetClientState = {
  client: null,
  loading: false,
  error: null,
};

const getClientReducer = produce((state: GetClientState, action: GetClientActions) => {
  switch (action.type) {
    case GetClientActionTypes.GetClientStart:
      state.loading = true;
      return;

    case GetClientActionTypes.GetClientFinish:
      state.loading = false;
      state.error = action.payload.error;
      state.client = action.payload.client;
      return;
  }
});

export function reducer(state = initialState, action: GetClientActions): GetClientState {
  return getClientReducer(state, action);
}
