import {IOption} from '@shared/ui';

export const education: IOption[] = [
  {
    label: 'Ne',
    key: 'no',
  },
  {
    label:
      'Ano, mám vysokoškolské vzdělání se zaměřením mj. na finanční trhy a investiční nástroje.',
    key: 'yes',
  },
  {
    label:
      'Ano, mám složenou odbornou zkoušku nebo absolvovaný odborný kurz se zaměřením na obchodování' +
      ' s investičními nástroji (makléřská zkouška, odborné vzdělání pro registraci investičního' +
      ' zprostředkovatele/vázaného zástupce apod.).',
    key: 'course',
  },
];

export const categoriesOptions: IOption[] = [
  {
    key: 'non-professional',
    label: 'Neprofesionální zákazník',
  },
  {
    key: 'professional',
    label: 'Profesionální zákazník',
  },
  {
    key: 'counter-side',
    label: 'Způsobilá protistrana',
  },
];

export const horizons: IOption[] = [
  {
    label: 'Méně než rok',
    key: 'less-than-year',
  },
  {
    label: 'Minimálně 1 rok',
    key: 'min-one-year',
  },
  {
    label: 'Minimálně 3 roky',
    key: 'min-three-years',
  },
  {
    label: 'Minimálně 5 let',
    key: 'min-five-years',
  },
  {
    label: 'Minimálně 10 let',
    key: 'min-ten-years',
  },
  {
    label: 'Minimálně 15 let',
    key: 'min-fifteen-years',
  },
];

export const investmentPreferencesI18n: {[answer: string]: string} = {
  ['no-risk']: 'Garance bez ztrát',
  ['almost-none-risk']: 'Ochrana',
  ['low-risk']: 'Rovnováha',
  ['partial-risk']: 'Růst',
  ['max-risk']: 'Spekulace',
};

export const investmentPreferencesValue: {[answer: string]: string} = {
  ['no-risk']: 'a',
  ['almost-none-risk']: 'b',
  ['low-risk']: 'c',
  ['partial-risk']: 'd',
  ['max-risk']: 'e',
};

export const bareLossesValue: {[answer: string]: boolean} = {
  ['everything']: false,
  ['plans-threat']: true,
  ['acceptable-loss']: true,
  ['nothing']: true,
};

export const riskProfileI18n: {[answer: string]: string} = {
  a: 'Konzervativní profil (SRI max 2)',
  b: 'Vyvážený profil (SRI max 4)',
  c: 'Dynamický profil (SRI max 6)',
  d: 'Spekulativní profil (SRI max 7)',
};

export const knowledgeExperienceI18n = [
  {
    label: 'Klient neakceptující ztrátu',
    range: (points: number) => points < 0,
  },
  {
    label: 'Základní investor',
    range: (points: number) => points >= 0 && points <= 3,
  },
  {
    label: 'Informovaný investor',
    range: (points: number) => points >= 4 && points <= 19,
  },
  {
    label: 'Pokročilý investor',
    range: (points: number) => points >= 20,
  },
];

export const investmentStrategyI18n: {[answer: string]: string} = {
  ['ineligibile']: 'Nelze sjednat investici',
  ['conservative']: 'Konzervativní strategie',
  ['balanced']: 'Vyvážená strategie',
  ['dynamic']: 'Dynamická strategie',
  ['speculative']: 'Spekulativní strategie',
};

export const preferredPortfolioI18n: {[answer: string]: string} = {
  ['A']: 'Konzervativní',
  ['B']: 'Vyvážené',
  ['C']: 'Růstové',
  ['D']: 'Spekulativní',
};

export type InvestmentSurveyCategory = 'non-professional' | 'professional' | 'counter-side';
export type InvestmentPreferences =
  | 'no-risk'
  | 'low-risk'
  | 'almost-none-risk'
  | 'partial-risk'
  | 'max-risk';
export type PreferredPortfolio = 'A' | 'B' | 'C' | 'D';
export type InvestmentSurveyRiskProfile = 'a' | 'b' | 'c' | 'd';
export type InvestmentStrategy =
  | 'ineligibile'
  | 'conservative'
  | 'balanced'
  | 'dynamic'
  | 'speculative';

export interface InvestmentSurveyResults {
  category: InvestmentSurveyCategory;
  knowledgeExperiencePoints: number;
  horizon: string;
  investmentPreferences: InvestmentPreferences;
  preferredPortfolio: PreferredPortfolio;
  bareLosses: boolean;
  riskProfile: InvestmentSurveyRiskProfile;
  riskProfilePoints: number;
  eligibility: boolean;
  investmentStrategy: InvestmentStrategy;
  inconsistentPortfolioAnswers: boolean;
  inconsistentInvestmentPurposeAnswers: boolean;
}
