import {max, nth, reject, without} from 'lodash';
import {updateRiskForm} from 'src/app/modules/life-insurance-old/store/life-insurance.actions';
import {
  ExpandedForm,
  LifeInsuranceForm,
  LifeInsuranceFormGroup,
  LifeInsurancePerson,
  LifeInsuranceRisk,
  LifeInsuranceRiskUniqueIdent,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {LifeInsuranceState} from 'src/app/modules/life-insurance-old/store/life-insurance.state';
import {Provision, RiskId} from 'src/store/models/risk.models';
import {
  PersonalInsuranceSelectionVM,
  RiskVM,
} from '../1-insurance-protection/insurance-protection.selectors';
import {RiskSelection} from '../1-insurance-protection/risk-list/risk-list.component';

export const getDefaultAge = (person: LifeInsurancePerson) => (person.child ? 26 : 65);

const getDefaultAgeFromState = (state: LifeInsuranceState, personId: string) =>
  getDefaultAge(state.persons.find(p => p.id === personId));

export function newRisk(
  personId: string,
  riskId: RiskId | string,
  provision: Provision,
  state: LifeInsuranceState,
): LifeInsuranceRisk {
  return {
    formId: getSelectedGroupFormId(personId, state),
    personId,
    riskId,
    provision,
    value: 0,
    age: getDefaultAgeFromState(state, personId),
    myChoice: true,
  };
}

function getSelectedGroupFormId(personId: string, state: LifeInsuranceState): number {
  const selectedForm = state.forms.find(
    form => form.personId === personId && form.group === LifeInsuranceFormGroup.Selected,
  );

  if (selectedForm) return selectedForm.id;

  const newForm = newGroupForm(
    personId,
    LifeInsuranceFormGroup.Selected,
    null,
    false,
    false,
    state,
  );
  state.forms.push(newForm);

  return newForm.id;
}

export function defaultExpandedForm(): ExpandedForm {
  return {
    current: false,
    selected: false,
    recommended: false,
    final: false,
  };
}

export function newGroupForm(
  personId: string,
  group: LifeInsuranceFormGroup,
  linkId: number,
  isNew: boolean,
  withProduct: boolean,
  state: LifeInsuranceState,
): LifeInsuranceForm {
  const orders = state.forms
    .filter(form => form.personId === personId && form.group === group)
    .map(form => form.order);
  const newOrder = nextId(orders);
  const ids = state.forms.map(form => form.id);
  const newId = nextId(ids);
  return {
    id: newId,
    personId,
    group,
    linkId,
    isNew,
    cancelled: false,
    price: 0,
    order: newOrder,
    name: '',
    alternativeSetId: null,
    withProduct,
    productId: null,
    stakeholderId: null,
    contractId: null,
  };
}

export function nextLinkId(state: LifeInsuranceState): number {
  const linkIds = state.forms.map(form => form.linkId);
  return nextId(linkIds);
}

export function nextAlternativeSetId(state: LifeInsuranceState): number {
  const alternativeSetIds = state.forms.map(form => form.alternativeSetId);
  return nextId(alternativeSetIds);
}

export function nextContractId(state: LifeInsuranceState): number {
  const contractIds = state.forms.map(form => form.contractId);
  return nextId(contractIds);
}

export function getOrderForAlternativeForm(
  personId: string,
  group: LifeInsuranceFormGroup,
  alternativeSetId: number,
  state: LifeInsuranceState,
): number {
  const lastButOneRecommendedFormInSet = nth(
    state.forms
      .filter(
        f =>
          f.personId === personId && f.group === group && f.alternativeSetId === alternativeSetId,
      )
      .sort((a, b) => a.order - b.order),
    -2,
  );

  state.forms
    .filter(
      f =>
        f.personId === personId &&
        f.group === group &&
        f.order > lastButOneRecommendedFormInSet.order,
    )
    .forEach(f => (f.order += 1));

  return lastButOneRecommendedFormInSet.order + 1;
}

export function updateLinkedRiskForm(
  sourceForm: LifeInsuranceForm,
  linkedGroup: LifeInsuranceFormGroup,
  action: ReturnType<typeof updateRiskForm>,
  previousValue: number,
  previousAge: number,
  defaultAge: number,
  state: LifeInsuranceState,
) {
  const linkedForm = state.forms.find(
    f => f.linkId === sourceForm.linkId && f.group === linkedGroup,
  );
  const linkedRiskFn = (r: LifeInsuranceRisk) =>
    r.formId === linkedForm.id && r.riskId === action.riskId;
  const linkedRisk = state.risks.find(linkedRiskFn);

  if (linkedRisk) {
    if (linkedRisk.value === previousValue) {
      linkedRisk.value = action.value;
    }
    if (linkedRisk.age === previousAge) {
      linkedRisk.age = action.age;
    }
    if (linkedRisk.value === null && linkedRisk.age === defaultAge) {
      state.risks = reject(state.risks, linkedRiskFn);
    }
  } else {
    if (action.value !== null || action.age !== null) {
      state.risks.push({
        personId: action.personId,
        formId: linkedForm.id,
        riskId: action.riskId,
        value: action.value,
        age: action.age,
      });
    }
  }
}

export function findPerson(personId: string, state: LifeInsuranceState): LifeInsurancePerson {
  return state.persons.find(p => p.id === personId);
}

export function findUniqueRisk(
  risk: LifeInsuranceRisk,
  zeroRisks: LifeInsuranceRiskUniqueIdent[],
): boolean {
  return (
    zeroRisks.find(
      r =>
        r.personId === risk.personId && r.riskId === risk.riskId && r.provision === risk.provision,
    ) !== undefined
  );
}

export function getUniqueRisksData(
  personSelection: PersonalInsuranceSelectionVM,
  risks: RiskVM[],
): LifeInsuranceRiskUniqueIdent[] {
  return risks
    .filter(risk => risk.calculatedValue === 0)
    .map(risk => getUniqueRiskData(personSelection.person.id, risk));
}

function getUniqueRiskData(personId: string, risk: RiskVM): LifeInsuranceRiskUniqueIdent {
  return {
    personId,
    riskId: risk.riskDef.id,
    provision: risk.riskDef.provision,
  };
}

export function selectRisk(selectedRisk: RiskSelection, state: LifeInsuranceState) {
  const risk = state.risks.find(
    r => r.personId === selectedRisk.personId && r.riskId === selectedRisk.riskId && r.myChoice,
  );

  if (risk) {
    // if risk is already in the store, either remove it or switch its provision
    if (risk.provision !== selectedRisk.provision) risk.provision = selectedRisk.provision;
    else state.risks = without(state.risks, risk);
  } else {
    state.risks.push(
      newRisk(selectedRisk.personId, selectedRisk.riskId as RiskId, selectedRisk.provision, state),
    );
  }
}

export function updateIndividualSelection(
  personId: string,
  selected: boolean,
  state: LifeInsuranceState,
) {
  const member = findPerson(personId, state);
  if (member.individualSelection === selected) return;
  member.individualSelection = selected;

  const customRiskDefIds = state.customRiskDefinitions
    .filter(def => def.personId === personId)
    .map(def => def.id);

  state.risks = reject(
    state.risks,
    r => r.personId === personId && r.myChoice && !customRiskDefIds.includes(r.riskId),
  );
}

export function nextId(array: number[]): number {
  return max([0, ...array]) + 1;
}
