import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {col, column, row} from '@lib/utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyAttributeEvent} from '@ngx-formly/core/lib/components/formly.field.config';
import {isForeignContract} from '@shared/analysis/asset.utils';
import {FieldLabel} from '@shared/analysis/field-label';
import {InterestRate} from '@shared/analysis/models/interest-rate';
import {validObjectiveRelations, validPropertyRelations} from '@shared/analysis/models/relations';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {selectAssetsAsOptions} from '@shared/analysis/store/financial-analysis.selectors';
import {
  creditPackageKey,
  creditProductInsuranceRisks,
  paymentProtectionInsuranceKey,
} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.models';
import {get} from 'lodash';
import {SliderOptions} from 'src/shared/ui';
import {FormlyTypes, FormlyWrappers} from 'src/shared/ui/formly/formly.enums';
import {
  createNamedGroup,
  getBasicField,
  getRelatedAssetSelectField,
  getSelectField,
} from 'src/shared/ui/formly/formly.utils';
import {State} from 'src/store';
import {AssetType, BaseAsset} from '../models/asset';
import {
  frequencyNames,
  getOptionsFromNames,
  propertyLoanPurposeNames,
  propertyOwnershipTypeNames,
} from '../models/assets.enums';
import {InputMode} from '../models/common-expenses';
import {StakeholderAsset} from '../models/financial-products';
import {
  CommonCreditAssetFields,
  CommonFinancialProductAssetFields,
  CommonFinancialProductTabsKeys,
  CommonFinancialPropertyAssetFields,
  CommonIncomeAssetFields,
  CommonInvestmentAssetFields,
  LifeInsuranceAssetFields,
  PropertyLoanAssetFields,
} from '../models/financial-products.helpers';
import {inputModeChange} from './common-expenses.helpers';
import {AssetFieldConfig} from './definitions.models';
import {getBaseAssetFields, sliderPercentTranslate, sumValue} from './forms.helpers';

interface DateAssetTemplateOptions {
  start: {
    [key: string]: any;
  };
  end?: {
    [key: string]: any;
  };
}

// todo - unify names and methods vs properties
@UntilDestroy()
@Injectable()
export class AbstractAssetsDefinitions {
  baseAssetFields = this.getBaseAssetFields({label: FieldLabel.NOTES});
  commonFinancialProduct: AssetFieldConfig<CommonFinancialProductAssetFields>;
  otherRisks = this.getOtherRisksField();

  private commonFinancialProductGroups: Record<CommonFinancialProductTabsKeys, FormlyFieldConfig>;
  private familyMembers: FamilyMember[];

  constructor(private store: Store<State>) {
    this.commonFinancialProduct = this.getCommonFinancialProductFields();
    this.store.pipe(selectFamilyMembers(), untilDestroyed(this)).subscribe(members => {
      this.familyMembers = members;
    });
    const proposalFields = [
      this.commonFinancialProduct.proposal,
      this.commonFinancialProduct.advisorProposalState,
      this.commonFinancialProduct.clientProposalState,
      this.commonFinancialProduct.originalAssetUuid,
      this.commonFinancialProduct.excludedFromAnalysis,
      this.commonFinancialProduct.foreignContract,
      this.commonFinancialProduct.sugarUuid,
      this.commonFinancialProduct.coreStatus,
      this.commonFinancialProduct.coreSecondaryStatus,
    ];
    this.commonFinancialProductGroups = {
      contractName: createNamedGroup(
        null,
        row([
          col(this.commonFinancialProduct.name),
          col(this.commonFinancialProduct.contractNumber),
        ]),
        ...proposalFields,
      ),

      proposalFields: createNamedGroup(null, ...proposalFields),

      presentValue: createNamedGroup(
        null,
        row([
          col(this.commonInvestment().presentValue),
          col(this.commonInvestment().presentValueDate),
        ]),
      ),

      period: createNamedGroup(
        'Období',
        row([col(this.commonFinancialProduct.start), col(this.commonFinancialProduct.end)]),
      ),

      payment: createNamedGroup(
        'Platba',
        row([
          col(this.commonFinancialProduct.value), // todo: some assets will have `hidden` value
          col(this.commonFinancialProduct.frequency),
        ]),
      ),

      attachments: createNamedGroup(
        'Poznámky',
        row([col(this.commonFinancialProduct.goal), col(this.commonFinancialProduct.note)]),
        this.commonFinancialProduct.attachments,
      ),

      paymentInfo: createNamedGroup(
        'Platební údaje',
        row([
          col(this.commonFinancialProduct.bankAccountNumber),
          col(this.commonFinancialProduct.variableSymbol),
        ]),
        row([
          col(this.commonFinancialProduct.paymentStart),
          col(this.commonFinancialProduct.paymentEnd),
        ]),
        row([
          col(this.commonFinancialProduct.paymentDay),
          col(this.commonFinancialProduct.paymentNote),
        ]),
      ),
    };
  }

  // implements CommonFinancialProductAsset
  getCommonFinancialProductFields = (
    templateOptions: Record<string, any> = {},
  ): AssetFieldConfig<CommonFinancialProductAssetFields> => {
    return {
      assetUuid: this.baseAssetFields.assetUuid,
      proposal: this.baseAssetFields.proposal,
      advisorProposalState: this.baseAssetFields.advisorProposalState,
      clientProposalState: this.baseAssetFields.clientProposalState,
      originalAssetUuid: this.baseAssetFields.originalAssetUuid,
      excludedFromAnalysis: this.baseAssetFields.excludedFromAnalysis,
      foreignContract: {
        key: 'foreignContract',
      },
      sugarUuid: {
        key: 'sugarUuid',
      },
      coreStatus: {
        key: 'coreStatus',
      },
      coreSecondaryStatus: {
        key: 'coreSecondaryStatus',
      },

      name: getBasicField(FormlyTypes.Text, 'name', 'Název smlouvy', {autofocus: true}),
      contractNumber: getBasicField(FormlyTypes.Text, 'contractNumber', FieldLabel.CONTRACT_NUMBER),

      value: this.getValueAssetField(
        {},
        {label: FieldLabel.CONTRACT_PAYMENT, ...templateOptions.value},
      ),
      frequency: getSelectField(
        'frequency',
        FieldLabel.FREQUENCY,
        getOptionsFromNames(frequencyNames),
        {},
      ),

      stakeholderUuid: getBasicField(
        FormlyTypes.FamilyMemberSelect,
        'stakeholderUuid',
        FieldLabel.STAKEHOLDER,
      ),

      start: getBasicField(FormlyTypes.Datepicker, 'start', FieldLabel.CONTRACT_START),
      end: getBasicField(FormlyTypes.Datepicker, 'end', FieldLabel.CONTRACT_END),

      goal: getBasicField(FormlyTypes.Textarea, 'goal', FieldLabel.GOAL, {rows: 3}),
      note: this.baseAssetFields.note,

      attachments: {
        key: 'attachments',
        type: FormlyTypes.Repeatable,
        className: 'd-none', // attachments not working, temporary disabled
        templateOptions: {
          addButtonLabel: 'Přidat další soubor',
        },
        fieldArray: {
          fieldGroup: [
            getBasicField(FormlyTypes.Textarea, 'dmsUuid', 'DmsUuid'), // fileupload
            getBasicField(FormlyTypes.Textarea, 'fileName', 'Jméno souboru'),
          ],
        },
      },
      bankAccountNumber: getBasicField(
        FormlyTypes.Text,
        'bankAccountNumber',
        FieldLabel.BANK_ACCOUNT_NUMBER,
        {},
        {},
        null,
        [Validators.maxLength(20)],
      ),
      variableSymbol: getBasicField(
        FormlyTypes.Text,
        'variableSymbol',
        FieldLabel.VARIABLE_SYMBOL,
        {},
        {},
        null,
        [Validators.maxLength(20)],
      ),
      paymentStart: getBasicField(
        FormlyTypes.Datepicker,
        'paymentStart',
        FieldLabel.CONTRACT_PAYMENT_START,
      ),
      paymentEnd: getBasicField(
        FormlyTypes.Datepicker,
        'paymentEnd',
        FieldLabel.CONTRACT_PAYMENT_END,
      ),
      paymentDay: getBasicField(FormlyTypes.Number, 'paymentDay', FieldLabel.CONTRACT_PAYMENT_DAY),
      paymentNote: getBasicField(FormlyTypes.Text, 'paymentNote', FieldLabel.CONTRACT_PAYMENT_NOTE),
    };
  };

  // implements BaseAsset
  getBaseAssetFields(noteTemplateOptions: {[key: string]: any} = {}): {
    [key in keyof BaseAsset]?: FormlyFieldConfig;
  } {
    return getBaseAssetFields(noteTemplateOptions);
  }

  // implements StakeholderAsset
  // TODO check usage
  stakeholderAsset(): AssetFieldConfig<StakeholderAsset> {
    return {
      stakeholderUuid: this.getStakeholderField(),
    };
  }

  getStakeholderField() {
    return getBasicField(FormlyTypes.FamilyMemberSelect, 'stakeholderUuid', FieldLabel.STAKEHOLDER);
  }

  getProductGroup(assetType: AssetType): FormlyFieldConfig {
    return createNamedGroup(
      'Produkt',
      row(
        [
          col(
            getBasicField(FormlyTypes.PartnerSelect, 'partnerId', FieldLabel.CONTRACT_PARTNER, {
              required: true,
              assetType,
            }),
          ),
          col(
            getBasicField(FormlyTypes.ProductSelect, 'productId', FieldLabel.CONTRACT_PRODUCT, {
              required: true,
              assetType,
            }),
          ),
        ],
        null,
        {
          hideExpression: model => model.proposal !== true && isForeignContract(model),
        },
      ),
    );
  }

  // implements RelatedObjectiveAsset
  getRelatedObjectiveField(assetType: AssetType): FormlyFieldConfig {
    const options = this.store.pipe(
      select(selectAssetsAsOptions(validObjectiveRelations[assetType])),
    );
    return getRelatedAssetSelectField(
      'relatedObjectiveUuid',
      FieldLabel.RELATED_OBJECTIVE,
      options,
      {clearable: true},
      {className: 'related-asset d-block', wrappers: [FormlyWrappers.ImportantValue]},
    );
  }

  // implements RelatedPropertyAsset
  getRelatedPropertyField(assetType: AssetType): FormlyFieldConfig {
    const options = this.store.pipe(
      select(selectAssetsAsOptions(validPropertyRelations[assetType])),
    );
    return getRelatedAssetSelectField(
      'relatedPropertyUuid',
      FieldLabel.RELATED_PROPERTY,
      options,
      {clearable: true},
      {className: 'related-asset d-block'},
    );
  }

  // implements RelatedAsset and StakeholderAsset
  getRelatedAndStakeholderField(assetType: AssetType): FormlyFieldConfig {
    return row([col(this.getRelatedObjectiveField(assetType)), col(this.getStakeholderField())]);
  }

  // implements CommonExpenseAsset
  getCommonExpenseFields(
    inputMode = true,
    valueChange?: FormlyAttributeEvent,
  ): FormlyFieldConfig[] {
    const baseAsset = this.getBaseAssetFields();
    const templateOptions = {
      autofocus: true,
      label: FieldLabel.MONTHLY_PAYMENT,
      change: valueChange,
    };
    const expressionProperties = inputMode
      ? {
          'templateOptions.disabled': 'model.inputMode === "detail"',
        }
      : {};

    return [
      baseAsset.assetUuid,
      baseAsset.proposal,
      baseAsset.advisorProposalState,
      baseAsset.clientProposalState,
      baseAsset.originalAssetUuid,
      row([
        col(this.getValueAssetField({}, templateOptions, expressionProperties)),
        col(this.getDateAssetFields()),
      ]),
      {
        key: 'indispensablePart',
        type: FormlyTypes.SliderInputRange,
        expressionProperties: {
          'templateOptions.modelValue': (model, _) => {
            return model.value;
          },
        },
        templateOptions: {
          connectedField: 'value',
          label: FieldLabel.INDISPENSABLE_PART,
          appendText: 'Kč',
          sliderOption: {
            floor: 0,
            step: 1,
            ceil: 100,
            translate: sliderPercentTranslate,
          } as SliderOptions,
        },
      },
    ];
  }

  // implements DateAsset
  getDateAssetFields(
    end = false,
    templateOptions: DateAssetTemplateOptions = {start: {}, end: {}},
  ): FormlyFieldConfig[] {
    const fields = [
      {
        key: 'start',
        type: FormlyTypes.Datepicker,
        templateOptions: {
          label: FieldLabel.EXPENSE_START,
          ...templateOptions.start,
        },
      },
      {
        key: 'end',
        type: FormlyTypes.Datepicker,
        templateOptions: {
          label: FieldLabel.EXPENSE_END,
          ...templateOptions.end,
        },
      },
    ];

    return end ? fields : [fields[0]];
  }

  // implements InputModeAsset
  getInputModeField(detailedFields: FormlyFieldConfig[]): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'inputMode',
          type: FormlyTypes.Switch,
          templateOptions: {
            label: 'Detailní rozpad',
            change: inputModeChange,
            optionsMap: {
              false: InputMode.Simple,
              true: InputMode.Detail,
            },
          },
          className: 'col-sm-12',
        },
        ...detailedFields.map(field => {
          return {
            ...field,
            templateOptions: {
              ...field.templateOptions,
              change: sumValue,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.inputMode === "simple"',
            },
            className: 'col-sm-6',
          };
        }),
      ],
    };
  }

  // implements ValueAsset
  getValueAssetField(
    fieldOptions: FormlyFieldConfig = {},
    templateOptionsExtra: {[key: string]: any} = {},
    expressionProperties: {[key: string]: any} = {},
  ): FormlyFieldConfig {
    return {
      key: 'value',
      type: FormlyTypes.Number,
      wrappers: [
        FormlyWrappers.ImportantValue,
        FormlyWrappers.CoreValue,
        FormlyWrappers.AssetValue,
      ],
      ...fieldOptions,
      expressionProperties,
      templateOptions: {
        min: 0,
        ...templateOptionsExtra,
      },
    };
  }

  // implements CommonIncomeAsset
  getCommonIncomeField(
    fields: FormlyFieldConfig[] = [],
    valueTemplateOptions: {[key: string]: any} = {},
  ): FormlyFieldConfig[] {
    const fieldOptions: FormlyFieldConfig = {};

    return [
      this.baseAssetFields.assetUuid,
      getBasicField(FormlyTypes.FamilyMemberSelect, 'familyMemberUuid', 'Člen rodiny'),
      ...fields,
      row([
        col(
          this.getValueAssetField(fieldOptions, {
            autofocus: true,
            label: FieldLabel.MONTHLY_PAYMENT,
            ...valueTemplateOptions,
          }),
        ),
        col(this.getDateAssetFields(false, {start: {label: FieldLabel.INCOME_START}})[0]),
      ]),
      this.getBaseAssetFields().note,
    ];
  }

  // implements CommonIncomeAsset
  commonIncomeAssets(): AssetFieldConfig<CommonIncomeAssetFields> {
    const dateAssets = this.getDateAssetFields(true, {
      start: {label: FieldLabel.INCOME_START},
      end: {label: FieldLabel.INCOME_END},
    });

    return {
      assetUuid: this.baseAssetFields.assetUuid,
      familyMemberUuid: getBasicField(
        FormlyTypes.FamilyMemberSelect,
        'familyMemberUuid',
        'Člen rodiny',
      ),
      start: dateAssets[0],
      end: dateAssets[1],
    };
  }

  getCommonFinancialProductGroup(
    groupName: CommonFinancialProductTabsKeys,
    extraFields: FormlyFieldConfig[] = [],
  ): FormlyFieldConfig {
    if (extraFields.length) {
      return createNamedGroup(
        this.commonFinancialProductGroups[groupName].templateOptions.label,
        ...this.commonFinancialProductGroups[groupName].fieldGroup,
        ...extraFields,
      );
    }
    return this.commonFinancialProductGroups[groupName];
  }

  // implements LifeInsuranceAsset
  lifeInsuranceAsset(): AssetFieldConfig<
    Omit<LifeInsuranceAssetFields, 'partnerId' | 'productId'>
  > {
    return {
      ...this.commonFinancialProduct,
      ...this.stakeholderAsset(),

      insuredPersons: this.insuredPersonTabsField(),

      relatedObjectiveUuid: null,
      futureValue: getBasicField(FormlyTypes.Currency, 'futureValue', FieldLabel.FUTURE_VALUE),
      futureValueDate: getBasicField(
        FormlyTypes.Datepicker,
        'futureValueDate',
        FieldLabel.FUTURE_VALUE_DATE,
      ),
    };
  }

  insuredPersonUuidsField(horizontal = false): FormlyFieldConfig {
    return getBasicField(
      FormlyTypes.FamilyMemberCheckbox,
      'insuredPersonUuids',
      FieldLabel.INSURED_PERSONS,
      {horizontal},
    );
  }

  insuredPersonTabsField(): FormlyFieldConfig {
    return {
      key: 'insuredPersons',
      type: FormlyTypes.AssetLifeInsuranceRisks,
      fieldArray: {
        fieldGroup: [
          createNamedGroup(
            'Pojistné částky',
            {key: 'insuredPersonUuid'},
            row([...this.insuredPersonRiskFields()]),
            this.otherRisks,
          ),
        ],
      },
    };
  }

  // implements CommonCreditAsset
  commonCreditAsset(): AssetFieldConfig<CommonCreditAssetFields> {
    return {
      ...this.commonFinancialProduct,
      outstandingValue: getBasicField(
        FormlyTypes.Currency,
        'outstandingValue',
        FieldLabel.OUTSTANDING_VALUE,
      ),
      outstandingValueDate: getBasicField(
        FormlyTypes.Datepicker,
        'outstandingValueDate',
        FieldLabel.OUTSTANDING_VALUE_DATE,
      ),
      yearlyRate: getBasicField(FormlyTypes.Percentage, 'yearlyRate', FieldLabel.YEARLY_RATE, {
        min: 0,
        precision: 2,
      }),
    };
  }

  // implements PropertyLoanAsset
  propertyLoanAsset(
    originalValueLabel: string = FieldLabel.ORIGINAL_MORTGAGE_VALUE,
  ): AssetFieldConfig<PropertyLoanAssetFields> {
    return {
      ...this.commonCreditAsset(),
      purpose: getSelectField(
        'purpose',
        FieldLabel.CONTRACT_PURPOSE,
        getOptionsFromNames(propertyLoanPurposeNames),
      ),

      fixationInYears: getBasicField(
        FormlyTypes.Number,
        'fixationInYears',
        FieldLabel.FIXATION_IN_YEARS,
      ),
      nearestFixationDate: getBasicField(
        FormlyTypes.Datepicker,
        'nearestFixationDate',
        FieldLabel.NEAREST_FIXATION_DATE,
      ),

      originalBank: getBasicField(FormlyTypes.Text, 'originalBank', FieldLabel.ORIGINAL_BANK),
      originalValue: getBasicField(FormlyTypes.Currency, 'originalValue', originalValueLabel),
      originalLtv: getBasicField(FormlyTypes.Number, 'originalLtv', FieldLabel.ORIGINAL_LTV),

      propertyEstimatedValue: getBasicField(
        FormlyTypes.Currency,
        'propertyEstimatedValue',
        FieldLabel.PROPERTY_ESTIMATED_VALUE,
      ),

      hasRealEstateInsurance: getBasicField(
        FormlyTypes.Checkbox,
        'hasRealEstateInsurance',
        FieldLabel.HAS_REAL_ESTATE_INSURANCE,
      ),
      realEstateInsurance: getBasicField(
        FormlyTypes.Text,
        'realEstateInsurance',
        FieldLabel.REAL_ESTATE_INSURANCE,
      ),

      debtorUuids: getBasicField(
        FormlyTypes.FamilyMemberCheckbox,
        'debtorUuids',
        FieldLabel.DEBTORS,
        {onlyAdult: true, horizontal: true},
      ),
      otherDebtors: getBasicField(FormlyTypes.Textarea, 'otherDebtors', FieldLabel.OTHER_DEBTORS),
      insuredRisksByDebtors: {
        key: 'insuredRisksByDebtors',
        type: FormlyTypes.InsuranceOfCreditProduct,
        fieldArray: {
          fieldGroup: [
            col([
              createNamedGroup(
                'Pojištění rizik',
                {key: 'debtorUuid'},
                ...this.creditProductInsuranceRiskFields(),
              ),
            ]),
          ],
        },
      },
    };
  }

  commonInvestment(): AssetFieldConfig<CommonInvestmentAssetFields> {
    return {
      yearlyRate: getBasicField(FormlyTypes.Percentage, 'yearlyRate', FieldLabel.YEARLY_RATE, {
        precision: 2,
      }),
      presentValue: getBasicField(FormlyTypes.Currency, 'presentValue', FieldLabel.PRESENT_VALUE),
      presentValueDate: getBasicField(
        FormlyTypes.Datepicker,
        'presentValueDate',
        FieldLabel.PRESENT_VALUE_DATE,
      ),
      targetAmount: getBasicField(FormlyTypes.Currency, 'targetAmount', FieldLabel.TARGET_AMOUNT),
    };
  }

  yearlyRate(interestRate: InterestRate, templateOptions: any = {}) {
    return {
      ...getBasicField(FormlyTypes.Percentage, 'yearlyRate', FieldLabel.YEARLY_RATE, {
        ...templateOptions,
        min: interestRate.min,
        max: interestRate.max,
        precision: 2,
      }),
    };
  }

  // implements CommonPropertyAsset
  commonPropertyAssetFields(opts?: {
    extraFields?: FormlyFieldConfig[];
    autofocus?: boolean;
  }): FormlyFieldConfig[] {
    opts = {
      extraFields: [],
      autofocus: true,
      ...opts,
    };
    const dateAsset = this.getDateAssetFields(false, {start: {label: FieldLabel.TANGIBLE_START}});

    const baseAsset = this.getBaseAssetFields();

    return [
      baseAsset.assetUuid,
      getBasicField(FormlyTypes.Text, 'name', FieldLabel.NAME, {autofocus: opts.autofocus}),
      row([
        {...this.getValueAssetField({}, {label: FieldLabel.TANGIBLE_VALUE}), className: 'col-sm-6'},
        {...dateAsset[0], className: 'col-sm-6'},
      ]),
      ...opts.extraFields,
      baseAsset.note,
    ];
  }

  // implements CommonImmovablePropertyAsset
  commonImmovablePropertiesFields(extraFields: FormlyFieldConfig[] = []): FormlyFieldConfig[] {
    return this.commonPropertyAssetFields({
      extraFields: [
        row([
          col(
            getSelectField(
              'ownershipType',
              FieldLabel.OWNERSHIP_TYPE,
              getOptionsFromNames(propertyOwnershipTypeNames),
              {},
            ),
          ),
          col(
            getBasicField(FormlyTypes.Number, 'totalArea', FieldLabel.TOTAL_AREA, {
              appendText: 'm&#xB2;',
            }),
          ),
        ]),
        ...extraFields,
      ],
    });
  }

  // implements ExtendedImmovablePropertyAsset
  extendedImmovablePropertiesFields(): FormlyFieldConfig[] {
    return this.commonImmovablePropertiesFields([
      row([
        col(
          getBasicField(
            FormlyTypes.Number,
            'immovablePropertyArea',
            FieldLabel.IMMOVABLE_PROPERTY_AREA,
            {appendText: 'm&#xB2;'},
          ),
        ),
        col(
          getBasicField(FormlyTypes.Number, 'householdArea', FieldLabel.HOUSEHOLD_AREA, {
            appendText: 'm&#xB2;',
          }),
        ),
      ]),
    ]);
  }

  // implements CommonFinancialPropertyAsset
  commonFinancialPropertyAsset(): AssetFieldConfig<CommonFinancialPropertyAssetFields> {
    const dateAsset = this.getDateAssetFields(false, {
      start: {label: FieldLabel.FINANCIAL_PROPERTY_START},
    });

    const baseAsset = this.getBaseAssetFields();

    return {
      assetUuid: baseAsset.assetUuid,
      name: getBasicField(FormlyTypes.Text, 'name', FieldLabel.NAME, {autofocus: true}),
      value: this.getValueAssetField({}, {label: FieldLabel.FINANCIAL_PROPERTY_VALUE}),
      start: dateAsset[0],
      institutionName: getBasicField(
        FormlyTypes.Text,
        'institutionName',
        FieldLabel.INSTITUTION_NAME,
      ),
      note: baseAsset.note,
    };
  }

  getOtherRisksField(): FormlyFieldConfig {
    return {
      key: 'otherRisks',
      type: FormlyTypes.Repeatable,
      fieldArray: {
        fieldGroup: [
          row([
            col(getBasicField(FormlyTypes.Text, 'label', 'Riziko')),
            col(getBasicField(FormlyTypes.Currency, 'value', 'Částka')),
          ]),
        ],
      },
      templateOptions: {
        addButtonLabel: 'Přidat riziko',
      },
    };
  }

  private insuredPersonRiskFields(): FormlyFieldConfig[] {
    const createField = (key: string, label: string, childRisk?: boolean) =>
      column(
        [
          {
            ...getBasicField(FormlyTypes.Currency, key, label),
            wrappers: [FormlyWrappers.InsuranceValue],
          },
        ],
        [6, 6],
        null,
        {
          hideExpression: model =>
            (!childRisk && this.isChild(model.insuredPersonUuid)) ||
            (childRisk && !this.isChild(model.insuredPersonUuid)),
        },
      );
    return [
      createField('incapacity', FieldLabel.INCAPACITY),
      createField('dailyCompensation', FieldLabel.DAILY_COMPENSATION),
      createField('physicalDamage', FieldLabel.PHYSICAL_DAMAGE),
      createField('hospitalization', FieldLabel.HOSPITALIZATION),
      createField('seriousDisease', FieldLabel.SERIOUS_DISEASE),
      createField('permanentDisability', FieldLabel.PERMANENT_DISABILITY),
      createField('invalidity', FieldLabel.INVALIDITY),
      createField('invalidityDebtRepayment', FieldLabel.INVALIDITY_DEBT_REPAYMENT),
      createField('deathDebtRepayment', FieldLabel.DEATH_DEBT_REPAYMENT),
      createField('deathThriceAnnualIncome', FieldLabel.DEATH_THRICE_ANNUAL_INCOME),
      createField('deathChildrenEnsurement', FieldLabel.DEATH_CHILDREN_ENSUREMENT),
      createField('childDailyCompensation', FieldLabel.CHILD_DAILY_COMPENSATION, true),
      createField('childPhysicalDamage', FieldLabel.CHILD_PHYSICAL_DAMAGE, true),
      createField('childHospitalization', FieldLabel.CHILD_HOSPITALIZATION, true),
      createField('childSeriousDisease', FieldLabel.CHILD_SERIOUS_DISEASE, true),
      createField('childPermanentDisability', FieldLabel.CHILD_PERMANENT_DISABILITY, true),
      createField('childSurvival', FieldLabel.CHILD_SURVIVAL, true),
    ];
  }

  private isChild(memberUuid: string): boolean {
    return this.familyMembers.find(m => m.sugarUuid === memberUuid).type === 'CHILD';
  }

  private creditProductInsuranceRiskFields(): FormlyFieldConfig[] {
    return creditProductInsuranceRisks.map(risk => {
      let hideExpression: (model: any) => boolean = null;
      if (risk.key !== paymentProtectionInsuranceKey && risk.key !== creditPackageKey) {
        const keyToCheck =
          risk.type === 'protection' ? paymentProtectionInsuranceKey : creditPackageKey;
        hideExpression = model => !get(model, keyToCheck);
      }
      return getBasicField(
        FormlyTypes.Checkbox,
        risk.key,
        risk.label,
        {},
        {},
        hideExpression,
        [],
        [],
        {className: risk.class},
      );
    });
  }
}
