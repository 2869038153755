import {FamilyMember} from '@generated/model';
import * as Sentry from '@sentry/browser';
import {head} from 'lodash';
import {environment} from 'src/environments/environment';

export function setErrorLoggerUserContext(clientData: FamilyMember[], familyId: string) {
  if (!environment.sentryDataSourceName || clientData.length === 0) return;

  const familyHead = clientData.find(familyMember => familyMember.familyHead);
  Sentry.configureScope(scope => {
    scope.setTag('familyId', familyId);
    if (familyHead) {
      scope.setExtra('familyInformation', {
        id: familyHead.sugarUuid,
        name: familyHead.firstName + ' ' + familyHead.lastName,
      });
    } else {
      console.error('Cannot set familyInformation context due to missing familyHead', {
        id: head(clientData).sugarUuid,
        name: head(clientData).firstName + ' ' + head(clientData).lastName,
        familyHead: head(clientData).familyHead,
      });
    }
  });
}
