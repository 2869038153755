import {createFeatureSelector, createSelector} from '@ngrx/store';
import {BusinessCaseStoreState} from '@shared/business-case/store/business-case.state';
import {
  BusinessCaseMode,
  BusinessCaseStatus,
} from 'src/app/modules/financial-plan/objectives/objectives.models';

export const storeFeatureName = 'businessCase';

export const selectBusinessCaseStoreState =
  createFeatureSelector<BusinessCaseStoreState>(storeFeatureName);

export const selectCurrentBusinessCase = createSelector(selectBusinessCaseStoreState, state =>
  state.businessCases.find(b => b.situationId === state.situation),
);

export const selectRawBusinessCaseStatus = createSelector(
  selectCurrentBusinessCase,
  businessCase => businessCase?.status as BusinessCaseStatus,
);

export const selectBusinessCaseStatus = createSelector(
  selectRawBusinessCaseStatus,
  status => status || BusinessCaseStatus.FinancialAnalysisPreparation,
);

export const selectBusinessCaseLoading = createSelector(
  selectBusinessCaseStoreState,
  state => state.loading,
);

export const selectBusinessCaseSituation = createSelector(
  selectBusinessCaseStoreState,
  state => state.situation,
);

export const selectBusinessCaseTimestamp = createSelector(
  selectCurrentBusinessCase,
  businessCase => businessCase?.timestamp,
);

export const selectBusinessCaseMode = createSelector(
  selectBusinessCaseStatus,
  (businessCaseStatus): BusinessCaseMode => {
    if (
      [
        BusinessCaseStatus.FinancialAnalysisPreparation,
        BusinessCaseStatus.FinancialPlanPreparation,
        BusinessCaseStatus.FinancialPlanTermination,
      ].includes(businessCaseStatus)
    ) {
      return BusinessCaseMode.Design;
    }

    return BusinessCaseMode.Presentation;
  },
);

export const selectBusinessCases = createSelector(
  selectBusinessCaseStoreState,
  state => state.businessCases,
);

export const selectFinalizedBusinessCases = createSelector(
  selectBusinessCaseStoreState,
  state => state.finalizedBusinessCases,
);
