export interface InvestmentGoldPerson {
  id: string;
  familyHead: boolean;
  birthDate: string;
  name: string;
}

// Used in Investments
export function computeMaxOneTimeInvestment(savings: number) {
  return savings * 2;
}

// Used in Investments
export function computeMaxMonthlyInvestment(monthlyBalance: number) {
  return monthlyBalance * 2;
}

// Used in Gold
export function computeOptimalInvestment(savings: number) {
  return Math.round(savings * 0.15);
}
