import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {
  AdvisorProposalState,
  Asset,
  assetNames,
  RelatedObjectiveAsset,
} from '@shared/analysis/models/asset';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {InsuredPerson, LifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {CommonInvestmentAsset} from '@shared/analysis/models/investment-products';
import {ObjectiveAsset} from '@shared/analysis/models/objectives';
import {getObjectiveProductAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {difference, flatten, keyBy, mapValues, uniq} from 'lodash';
import {
  getCategory,
  getMonthlyValue,
  getSkipSum,
  introTexts,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  selectFamilyObjectiveAsset,
  setFulfillmentAndRating,
  sortRowByName,
  sumRows,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  AssetRisk,
  FamilyObjective,
  InputRisk,
  InsuranceRisk,
  Row,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {calculateRisk} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {
  LifeInsurancePerson,
  LifeInsuranceRisk,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectEnabledRisksOfMyChoice,
  selectOrderedPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {allRiskIds, getRiskDefinition, risksNames} from 'src/app/services/risk-definitions';
import {camelCasedRiskIds, RiskId, RiskIdCamelCased} from 'src/store/models/risk.models';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

/**
 * @deprecated
 */
export interface PersonInsuranceRisk {
  person: LifeInsurancePerson;
  risks: InsuranceRisk[];
}

/**
 * @deprecated
 */
export const selectPersonsInsuranceRisks = createSelector(
  selectOrderedPersons,
  selectEnabledRisksOfMyChoice,
  selectCurrentAssets,
  (
    persons: LifeInsurancePerson[],
    risks: LifeInsuranceRisk[],
    assets: Asset[],
  ): PersonInsuranceRisk[] =>
    persons.map(person => ({
      person,
      risks: getPersonInsuranceRisks(person, risks, persons, assets),
    })),
);

/**
 * @deprecated
 */
export const selectFamilyObjectivesOld = createSelector(
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectFamilyObjectiveAsset,
  getFamilyMembers,
  selectPersonsInsuranceRisks,
  (
    currentAssets,
    advisorProposedAssets,
    clientProposedAssets,
    objectiveAsset,
    familyMembers,
    personsInsuranceRisks,
  ): FamilyObjective[] => {
    if (!objectiveAsset) return [];

    return familyMembers
      .map((familyMember): FamilyObjective => {
        const currentTable = getFamilyProvisionTable(
          Situation.Current,
          currentAssets,
          familyMember,
          objectiveAsset,
        );
        const advisorProposedTable = getFamilyProvisionTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          familyMember,
          objectiveAsset,
        );
        const clientProposedTable = getFamilyProvisionTable(
          Situation.ClientProposed,
          clientProposedAssets,
          familyMember,
          objectiveAsset,
        );

        const personInsuranceRisks = personsInsuranceRisks.find(
          p => p.person.id === familyMember.sugarUuid,
        );
        const inputRisks: InputRisk[] = personInsuranceRisks?.risks.map(r => ({
          key: r.key,
          label: r.heading,
        }));

        const additionalRisksFromAdvisor = getAdditionalRisksFromAdvisor(
          personInsuranceRisks,
          advisorProposedTable,
          familyMember,
        );

        const currentRisksNotInInput = getAdditionalRisksNotInInput(
          personInsuranceRisks,
          currentTable,
          familyMember,
        );

        const insuranceRisks: InsuranceRisk[] =
          personInsuranceRisks &&
          getInsuranceRisks(
            currentTable,
            advisorProposedTable,
            clientProposedTable,
            personInsuranceRisks,
          );

        setFulfillmentAndRating(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          insuranceRisks,
        );

        currentTable.rating = null;
        advisorProposedTable.rating = null;
        clientProposedTable.rating = null;

        return {
          type: ObjectiveType.Family,
          objectiveAsset,
          objectiveValue: null,
          familyMember,
          introText: introTexts[ObjectiveType.Family],
          name: familyMember.firstName,
          input: inputRisks ?? [],
          additionalRisksFromAdvisor,
          currentRisksNotInInput,
          chart: insuranceRisks ?? [],
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
        };
      })
      .filter(objective => {
        const isFamilyHead = objective.familyMember.familyHead;
        const hasInputRisks = objective.input.length > 0;
        const hasContract =
          objective.table.current.rows.length > 0 ||
          objective.table.advisorProposed.rows.length > 0;

        return isFamilyHead || hasInputRisks || hasContract;
      });
  },
);

function getPersonInsuranceRisks(
  person: LifeInsurancePerson,
  risks: LifeInsuranceRisk[],
  persons: LifeInsurancePerson[],
  assets: Asset[],
): InsuranceRisk[] {
  return risks
    .filter(risk => risk.personId === person.id)
    .map((risk): InsuranceRisk => {
      const riskDef = getRiskDefinition(risk.riskId, risk.provision);
      if (!riskDef) return null;
      const computedValue = calculateRisk(riskDef, risk.provision, person, persons, assets);
      return {
        key: riskDef.id,
        heading: riskDef.label,
        currentValue: NaN,
        advisorProposedValue: NaN,
        clientProposedValue: NaN,
        computedValue,
      };
    })
    .filter(Boolean);
}

/**
 * @deprecated
 */
export function getFamilyProvisionTable(
  situation: Situation,
  assets: Asset[],
  familyMember: FamilyMember,
  objectiveAsset: ObjectiveAsset,
): Table {
  const isStakeholder = (asset: Asset, member: FamilyMember) =>
    (asset as StakeholderAsset).stakeholderUuid === member.sugarUuid ||
    (!(asset as StakeholderAsset).stakeholderUuid && member.familyHead);

  const relatedAssets = getObjectiveProductAssets(ObjectiveType.Family);

  const rows = assets
    .filter(
      asset =>
        isStakeholder(asset, familyMember) ||
        hasFilledInsuranceRisks(asset, familyMember.sugarUuid),
    )
    .filter(
      asset => (asset as RelatedObjectiveAsset).relatedObjectiveUuid === objectiveAsset.assetUuid,
    )
    .filter(asset => relatedAssets.includes(asset.type))
    .map((asset): Row => {
      const investmentAsset = asset as CommonInvestmentAsset;
      const stakeholder = isStakeholder(asset, familyMember);
      return {
        assetUuid: investmentAsset.assetUuid,
        advisorProposalState: investmentAsset.advisorProposalState,
        clientProposalState: investmentAsset.clientProposalState,
        asset,
        name: investmentAsset.name ? investmentAsset.name : assetNames[investmentAsset.type],
        category: getCategory(asset),
        monthlyValue: getMonthlyValue(asset),
        stakeholder,
        skipSum: getSkipSum(asset, situation) || !stakeholder,
      };
    })
    .sort(sortRowByName);

  return {
    rows,
    sum: sumRows(rows),
    fulfillment: null,
    rating: null,
  };
}

function hasFilledInsuranceRisks(asset: Asset, familyMemberUuid: string): boolean {
  return (asset as LifeInsuranceAsset).insuredPersons?.some(
    insuredPerson =>
      insuredPerson.insuredPersonUuid === familyMemberUuid &&
      (hasFilledPredefinedRisks(insuredPerson) || hasFilledOtherRisks(insuredPerson)),
  );
}

function hasFilledPredefinedRisks(insuredPerson: InsuredPerson): boolean {
  return Object.values(RiskIdCamelCased).some(
    riskId => Number(insuredPerson[riskId as keyof InsuredPerson]) > 0,
  );
}

function hasFilledOtherRisks(insuredPerson: InsuredPerson): boolean {
  return insuredPerson.otherRisks.some(otherRisk => otherRisk.value > 0);
}

function getInsuranceRisks(
  currentTable: Table,
  advisorProposedTable: Table,
  clientProposedTable: Table,
  personInsuranceRisk: PersonInsuranceRisk,
): InsuranceRisk[] {
  const currentRisks = mapAssetsToRiskValues(currentTable, personInsuranceRisk, Situation.Current);
  const advisorProposedRisks = mapAssetsToRiskValues(
    advisorProposedTable,
    personInsuranceRisk,
    Situation.AdvisorProposed,
  );
  const clientProposedRisks = mapAssetsToRiskValues(
    clientProposedTable,
    personInsuranceRisk,
    Situation.ClientProposed,
  );
  return mergeRisks(
    currentRisks,
    advisorProposedRisks,
    clientProposedRisks,
    personInsuranceRisk.risks,
  );
}

function mapAssetsToRiskValues(
  table: Table,
  personInsuranceRisk: PersonInsuranceRisk,
  situation: Situation,
): AssetRisk[] {
  return personInsuranceRisk.risks.map(inputRisk => ({
    key: inputRisk.key,
    value: sumLifeInsuranceRisks(
      table,
      personInsuranceRisk.person.id,
      inputRisk.key as RiskId,
      situation,
    ),
  }));
}

function sumLifeInsuranceRisks(
  table: Table,
  familyMemberUuid: string,
  key: RiskId,
  situation: Situation,
): number {
  return table.rows.reduce((sum, row) => {
    if (getSkipSum(row.asset, situation)) return sum;

    const insuredPerson = (row.asset as LifeInsuranceAsset).insuredPersons?.find(
      person => person.insuredPersonUuid === familyMemberUuid,
    );
    if (!insuredPerson) return sum;

    return sum + (Number(insuredPerson[camelCasedRiskIds[key] as keyof InsuredPerson]) ?? 0);
  }, 0);
}

function mergeRisks(
  currentRisks: AssetRisk[],
  advisorProposedRisks: AssetRisk[],
  clientProposedRisks: AssetRisk[],
  insuranceRisks: InsuranceRisk[],
): InsuranceRisk[] {
  const currentRisksMap = mapValues(keyBy(currentRisks, 'key'), 'value');
  const advisorProposedRisksMap = mapValues(keyBy(advisorProposedRisks, 'key'), 'value');
  const clientProposedRisksMap = mapValues(keyBy(clientProposedRisks, 'key'), 'value');

  return insuranceRisks.map(insuranceRisk => ({
    ...insuranceRisk,
    currentValue: currentRisksMap[insuranceRisk.key],
    advisorProposedValue: advisorProposedRisksMap[insuranceRisk.key],
    clientProposedValue: clientProposedRisksMap[insuranceRisk.key],
  }));
}

function getAdditionalRisksFromAdvisor(
  personInsuranceRisks: PersonInsuranceRisk,
  table: Table,
  familyMember: FamilyMember,
) {
  return getAdditionalRisks(
    personInsuranceRisks,
    table.rows.filter(row => row.advisorProposalState !== AdvisorProposalState.Terminated),
    familyMember,
  );
}

function getAdditionalRisksNotInInput(
  personInsuranceRisks: PersonInsuranceRisk,
  table: Table,
  familyMember: FamilyMember,
) {
  return getAdditionalRisks(personInsuranceRisks, table.rows, familyMember);
}

function getAdditionalRisks(
  personInsuranceRisks: PersonInsuranceRisk,
  rows: Row[],
  familyMember: FamilyMember,
): InputRisk[] {
  const inputRisks = personInsuranceRisks?.risks.map(risk => risk.key) ?? [];

  const tableRisks = uniq(
    flatten(
      rows
        .map(row => row.asset)
        .map(asset => mapAssetToRisks(asset as LifeInsuranceAsset, familyMember.sugarUuid)),
    ),
  );

  const additionalRisks = difference<string>(tableRisks, inputRisks);
  return additionalRisks.map(risk => ({
    key: risk,
    label: risksNames[camelCasedRiskIds[risk as RiskId]],
  }));
}

function mapAssetToRisks(asset: LifeInsuranceAsset, personUuid: string): string[] {
  const insuredPerson = asset.insuredPersons?.find(p => p.insuredPersonUuid === personUuid);
  if (!insuredPerson) return [];
  return allRiskIds.filter(
    riskId => insuredPerson[camelCasedRiskIds[riskId] as keyof InsuredPerson] > 0,
  );
}
