import {
  GoldClient,
  GoldSummary,
  ModelationGold,
  PaymentMethodGoldContractEnum,
} from '@generated/model';
import {createAction, props, union} from '@ngrx/store';
import {GoldSummaryForm} from 'src/app/modules/gold/store/gold.models';
import {InvestmentGoldPerson} from 'src/app/services/analysis-data.utils';

export const updatePersons = createAction(
  '[Gold] Update Persons',
  props<{persons: InvestmentGoldPerson[]}>(),
);

export const updateSummary = createAction('[Gold] Update Summary', props<{summary: GoldSummary}>());

export const updateClient = createAction('[Gold] Update Client', props<{client: GoldClient}>());

export const updateModelation = createAction(
  '[Gold] Update Modelation',
  props<{modelation: ModelationGold}>(),
);

export const updateSummaryForm = createAction(
  '[Gold] Update Summary Form',
  props<{formValue: GoldSummaryForm}>(),
);

export const addContract = createAction(
  '[Gold] Add Contract',
  props<{paymentMethod: PaymentMethodGoldContractEnum}>(),
);

export const removeContract = createAction(
  '[Gold] Remove Contract',
  props<{
    contract: {
      transferId: string;
      index: number;
    };
  }>(),
);

export const sendToERecord = createAction('[Gold] Send to ERecord');

export const eRecordSuccess = createAction('[Gold] ERecord Success');

export const eRecordError = createAction('[Gold] ERecord Error', props<{errorMessage: string}>());

const allActions = union({
  updatePersons,
  updateSummary,
  updateClient,
  updateModelation,
  addContract,
  removeContract,
  updateSummaryForm,
  sendToERecord,
  eRecordSuccess,
  eRecordError,
});

export type GoldActionsUnion = typeof allActions;
