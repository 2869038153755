import {Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';
import {AssetType, objectivesAssets} from '@shared/analysis/models/asset';
import {objectiveAssetsDescription} from '@shared/analysis/objectives.helpers';

@UntilDestroy()
@Component({
  selector: 'kpt-description-formly',
  templateUrl: './description-formly.component.html',
  styleUrls: ['./description-formly.component.scss'],
})
export class DescriptionFormlyComponent extends FieldType {
  showDescription = false;

  isObjectiveAsset(): boolean {
    return (
      objectivesAssets.some(type => type === this.model.type) &&
      !!objectiveAssetsDescription[this.model.type as AssetType]
    );
  }

  getDescription(long: boolean = false) {
    const description = objectiveAssetsDescription[this.model.type as AssetType];
    return long ? description.long : description.main;
  }
}
