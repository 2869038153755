import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {AssetType} from '@shared/analysis/models/asset';
import {LifeInsurancePersonVM} from '@shared/analysis/models/life-insurance-risks-vm';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {selectCurrentAssets} from '@shared/analysis/store';
import {getPersonAge} from '@shared/utils';
import {
  getFinancialOutlook,
  getMonthlySickLeavePayContractor,
  getNetMonthlyIncomeContractor,
  getNumberOfChildrenWithTaxAdvantage,
  getOtherIncomeContractor,
  getOtherIncomeEmployee,
  getPersonIncomeContractorLifeInsurance,
  getPersonIncomeLifeInsurance,
  getPersonPassiveIncomeLifeInsurance,
  getPersonSavings,
  getWeightedPersonExpenses,
} from 'src/app/services/analysis-data.service';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export const selectLifeInsurancePersons = createSelector(
  getFamilyMembers,
  selectCurrentAssets,
  (familyMembers, assets): LifeInsurancePersonVM[] => {
    const familyProvision = assets.find(
      a => a.type === AssetType.FamilyProvision,
    ) as FamilyProvisionAsset;

    return familyMembers.map((familyMember: FamilyMember, index: number) => {
      const personId = familyMember.sugarUuid;

      const data = familyProvision?.lifeInsurancePersonDatas.find(p => p.personId === personId) ?? {
        personId,
      };

      // TODO: debts together
      const debts = 0;
      // const debts = Math.round(
      //   this.getPersonDebts(
      //     personId,
      //     assets,
      //     additionalData.debtsTogether || familyMember.familyHead,
      //   ),
      // );
      return {
        data,
        id: personId,
        name: familyMember.firstName || familyMember.lastName,
        lastName: familyMember.lastName,
        familyHead: familyMember.familyHead,
        age: getPersonAge(familyMember.birthDate),
        sex: familyMember.sex,
        child: familyMember.type === 'CHILD',
        income: Math.round(getPersonIncomeLifeInsurance(personId, assets)),
        incomeContractor: Math.round(getPersonIncomeContractorLifeInsurance(personId, assets)),
        other: Math.round(getOtherIncomeEmployee(personId, assets)),
        otherContractor: Math.round(getOtherIncomeContractor(personId, assets)),
        passiveIncome: Math.round(getPersonPassiveIncomeLifeInsurance(personId, assets)),
        savings: Math.round(getPersonSavings(personId, assets)),
        debts,
        expenses: Math.round(getWeightedPersonExpenses(personId, familyMembers, assets)),
        order: index,
        childrenTaxAdvantage: getNumberOfChildrenWithTaxAdvantage(personId, assets),
        financialOutlook: getFinancialOutlook(personId, assets),
        netMonthlyIncomeContractor: getNetMonthlyIncomeContractor(personId, assets),
        monthlySickLeaveInsurancePaymentContractor: getMonthlySickLeavePayContractor(
          personId,
          assets,
        ),
        // TODO: discuss how debts together should work with Kapitol
        debtsTogether: false,
      };
    });
  },
);
