import {Moment} from 'moment';

export enum FunctionType {
  Constant = 'constant',
  Linear = 'linear',
  FutureValue = 'futureValue',
  LossOfWork = 'lossOfWork',
  Installment = 'installment',
  Amortization = 'amortization',
  CreditFee = 'creditFee',
  LifeInsurance = 'lifeInsurance',
  BuildingSavings = 'buildingSavings',
  SupplementaryPensionSavings = 'supplementaryPensionSavings',
  PensionInsurance = 'pensionInsurance',
}

export enum UnitType {
  CurrentBalance = 'currentBalance',
}

export enum FunctionOperation {
  Add = 'add',
  Override = 'override',
}

export enum Frequency {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'halfYear',
  Year = 'year',
}

export enum Liquidity {
  High = 'high',
  Low = 'low',
}

export type ValueTable = ValueRow[];
type ValueRow = [Moment, number];

export interface AnalysisContext {
  unit: Unit;
  start: Moment;
  end: Moment;
  date: Moment;
  periods: number;
  units: Unit[];
}

export interface Unit {
  id: string;
  name: string;
  type?: UnitType;
  functions: UnitFunction[];
}

export interface BaseUnitFunction {
  type: FunctionType;
  liquidity: Liquidity;
  frequency?: Frequency;
  start: string;
  /**
   * last date of application or positive number of repetitions
   * absent for unlimited
   */
  end?: string | number;
  operation?: FunctionOperation;
}

export interface ConstantFunction extends BaseUnitFunction {
  type: FunctionType.Constant;
  transfer?: boolean;
  params: ConstantParams;
}

export interface LinearFunction extends BaseUnitFunction {
  type: FunctionType.Linear;
  frequency: Frequency;
  params: LinearParams;
}

export interface FutureValueFunction extends BaseUnitFunction {
  type: FunctionType.FutureValue;
  frequency: Frequency;
  params: FutureValueParams;
}

export interface LossOfWorkFunction extends BaseUnitFunction {
  type: FunctionType.LossOfWork;
  frequency: Frequency;
  params: LossOfWorkParams;
}

export interface InstallmentFunction extends BaseUnitFunction {
  type: FunctionType.Installment;
  frequency: Frequency;
  params: InstallmentParams;
}

export interface AmortizationFunction extends BaseUnitFunction {
  type: FunctionType.Amortization;
  frequency: Frequency;
  params: AmortizationParams;
}

export interface CreditFeeFunction extends BaseUnitFunction {
  type: FunctionType.CreditFee;
  frequency: Frequency;
  params: CreditFeeParams;
}

export interface LifeInsuranceFunction extends BaseUnitFunction {
  type: FunctionType.LifeInsurance;
  frequency: Frequency;
  params: LifeInsuranceParams;
}

export interface BuildingSavingsFunction extends BaseUnitFunction {
  type: FunctionType.BuildingSavings;
  frequency: Frequency;
  params: BuildingSavingsParams;
}

export interface SupplementaryPensionSavingsFunction extends BaseUnitFunction {
  type: FunctionType.SupplementaryPensionSavings;
  frequency: Frequency;
  params: PensionParams;
}

export interface PensionInsuranceFunction extends BaseUnitFunction {
  type: FunctionType.PensionInsurance;
  frequency: Frequency;
  params: PensionParams;
}

export type UnitFunction =
  | ConstantFunction
  | LinearFunction
  | FutureValueFunction
  | LossOfWorkFunction
  | InstallmentFunction
  | AmortizationFunction
  | CreditFeeFunction
  | LifeInsuranceFunction
  | BuildingSavingsFunction
  | SupplementaryPensionSavingsFunction
  | PensionInsuranceFunction;

export interface ConstantParams {
  value: number;
}

export interface LinearParams {
  slope: number;
  constantTerm?: number;
}

export interface FutureValueParams {
  yearRate: number;
  periodicPayment: number;
  presentValue: number;
}

export interface LossOfWorkParams {
  workUnitId: string;
}

export interface InstallmentParams {
  loan: number;
  yearRate: number;
}

export interface AmortizationParams {
  loan: number;
  yearRate: number;
}

export interface CreditFeeParams {
  value: number;
  loan: number;
  yearRate: number;
}

export interface LifeInsuranceParams {
  presentValue: number;
  futureValue: number;
  futureValueDate: string;
  yearRate: number;
}

export interface BuildingSavingsParams {
  periodicPayment: number;
  oneTimePayment: number;
  yearRate: number;
}

export interface PensionParams {
  yearRate: number;
  oneTimePayment: number;
  ownContribution: number;
  employerContribution: number;
}
