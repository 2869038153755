import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {FormModule} from '@shared/ui';
import {RadioGroupModule} from '@shared/ui/forms/radio-group/radio-group.module';
import {CheckboxModule} from '../../forms/checkbox/checkbox.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {SelectModule} from '../../forms/select/select.module';
import {FamilyMembersFormlyComponent} from './family-members-formly.component';

@NgModule({
  declarations: [FamilyMembersFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioGroupModule,
    CheckboxModule,
    SelectModule,
    CommonFieldStatusesModule,
    FormModule,
    BackendValidationModule,
  ],
  exports: [FamilyMembersFormlyComponent],
})
export class FamilyMembersFormlyModule {}
