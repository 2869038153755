import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {defaultObjectiveMaxValue} from '@shared/analysis/forms/objectives.helpers';
import {HousingAsset} from '@shared/analysis/models/objectives';
import {FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {round} from 'lodash';

@Component({
  standalone: true,
  selector: 'kpt-housing-asset-form',
  templateUrl: './housing-asset-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class HousingAssetFormComponent {
  @Input() asset: HousingAsset;
  @Input() ageLabel: string;

  defaultObjectiveMaxValue = defaultObjectiveMaxValue;

  getOwnValueRatio() {
    if (!this.asset.value || !this.asset.totalValue) {
      return null;
    }
    return round((this.asset.value / this.asset.totalValue) * 100, 2);
  }

  setOwnValueRatio(ownValueRatio: number) {
    if (!this.asset.value || !this.asset.totalValue) {
      return;
    }

    this.asset.value = round(this.asset.totalValue * (ownValueRatio / 100), 0);
  }
}
