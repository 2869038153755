import {IStepperProgressItem} from '@shared/ui';
import {createStepperProgressConfig} from '@shared/ui/stepper-progress/stepper-progress.utils';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';

export enum PropertyInsurancePage {
  Family = 'family',
  Property = 'property',
  Requirements = 'requirements',
  CurrentSituation = 'current-situation',
  AnalysisSummary = 'analysis-summary',
  AdvisorProposed = 'advisor-proposed',
  ClientProposed = 'client-proposed',
  PlanSummary = 'plan-summary',
}

export const propertyInsurancePageNames = {
  [PropertyInsurancePage.Family]: 'Rodina',
  [PropertyInsurancePage.Property]: 'Majetek',
  [PropertyInsurancePage.Requirements]: 'Požadavky',
  [PropertyInsurancePage.CurrentSituation]: 'Současné řešení',
  [PropertyInsurancePage.AnalysisSummary]: 'Shrnutí',
  [PropertyInsurancePage.AdvisorProposed]: 'Návrh řešení',
  [PropertyInsurancePage.ClientProposed]: 'Volba klienta',
  [PropertyInsurancePage.PlanSummary]: 'Shrnutí',
};

export function propertyInsuranceStepperProgressConfig(
  businessCaseStatus: BusinessCaseStatus,
): IStepperProgressItem[] {
  if (!businessCaseStatus) return [];

  const steps: Record<BusinessCaseStatus, PropertyInsurancePage[]> = {
    [BusinessCaseStatus.FinancialAnalysisPreparation]: [
      PropertyInsurancePage.Family,
      PropertyInsurancePage.Property,
      PropertyInsurancePage.Requirements,
      PropertyInsurancePage.CurrentSituation,
      PropertyInsurancePage.AnalysisSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPreparation]: [
      PropertyInsurancePage.AdvisorProposed,
      PropertyInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPresentation]: [
      PropertyInsurancePage.Requirements,
      PropertyInsurancePage.ClientProposed,
      PropertyInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanFinalisation]: [],
    [BusinessCaseStatus.FinancialPlanTermination]: [],
  };

  const stepperItems: IStepperProgressItem[] = steps[businessCaseStatus].map(url => ({
    url,
    label: propertyInsurancePageNames[url],
  }));

  return createStepperProgressConfig(
    stepperItems,
    SecuredPage.Client,
    ClientPage.PropertyInsurance,
  );
}
