<div class="custom-slider">
  <div class="slider-label">
    {{ label }}
  </div>

  <ngx-slider
    [value]="value"
    (valueChange)="valueChange.emit($event)"
    [highValue]="highValue"
    (highValueChange)="highValueChange.emit($event)"
    [options]="{
      floor, ceil, step, translate, disabled
    }"
  ></ngx-slider>
</div>
