/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GeneratePdfParams {
  data: __model.PdfGeneration;
  family_uuid: string;
}

export interface GetPdfParams {
  type: string;
  /** format: uuid */
  client_uuid?: string;
  access_token: string;
  family_uuid: string;
  filename: string;
}

export interface GetMeetingParams {
  type: string;
  family_uuid: string;
  situation: string;
}

export interface PutMeetingParams {
  data: __model.MeetingPutRequest;
  type: string;
  family_uuid: string;
  situation: string;
}

export interface GetNewInvestmentQuestionnaireParams {
  family_uuid: string;
}

export interface CreateUpdateNewInvestmentQuestionnaireParams {
  data: __model.NewInvestmentQuestionnaire[];
  family_uuid: string;
}

export interface RememberClientParams {
  data: __model.GetClient;
}

export interface SetClientCategoryParams {
  data: __model.ClientCategory;
  family_uuid: string;
}

@Injectable()
export class SharedService {
  constructor(private http: HttpClient) {}

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/generatePdf */
  generatePdf(params: GeneratePdfParams): Observable<__model.PdfGeneration> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.PdfGeneration>(`/api/shared/generate-pdf/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/getPdf */
  getPdf(params: GetPdfParams): Observable<void> {
    const queryParamBase = {
      type: params.type,
      client_uuid: params.client_uuid,
      access_token: params.access_token,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      family_uuid: params.family_uuid,
      filename: params.filename,
    };
    return this.http.get<void>(`/api/shared/get-pdf/${pathParams.family_uuid}/${pathParams.filename}`, {params: queryParams});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/getMeeting */
  getMeeting(params: GetMeetingParams): Observable<__model.MeetingGetResult> {
    const pathParams = {
      type: params.type,
      family_uuid: params.family_uuid,
      situation: params.situation,
    };
    return this.http.get<__model.MeetingGetResult>(`/api/shared/meeting/${pathParams.type}/${pathParams.family_uuid}/${pathParams.situation}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/putMeeting */
  putMeeting(params: PutMeetingParams): Observable<__model.MeetingPutRequest> {
    const bodyParams = params.data;

    const pathParams = {
      type: params.type,
      family_uuid: params.family_uuid,
      situation: params.situation,
    };
    return this.http.put<__model.MeetingPutRequest>(`/api/shared/meeting/${pathParams.type}/${pathParams.family_uuid}/${pathParams.situation}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/getNewInvestmentQuestionnaire */
  getNewInvestmentQuestionnaire(params: GetNewInvestmentQuestionnaireParams): Observable<__model.NewInvestmentQuestionnaire[]> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.NewInvestmentQuestionnaire[]>(`/api/shared/new-investment-questionnaire/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/createUpdateNewInvestmentQuestionnaire */
  createUpdateNewInvestmentQuestionnaire(params: CreateUpdateNewInvestmentQuestionnaireParams): Observable<__model.NewInvestmentQuestionnaire[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.NewInvestmentQuestionnaire[]>(`/api/shared/new-investment-questionnaire/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/rememberClient */
  rememberClient(params: RememberClientParams): Observable<__model.GetClient> {
    const bodyParams = params.data;

    return this.http.post<__model.GetClient>(`/api/shared/remember-client/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/shared/setClientCategory */
  setClientCategory(params: SetClientCategoryParams): Observable<__model.ClientCategory> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.ClientCategory>(`/api/shared/set-client-category/${pathParams.family_uuid}/`, bodyParams || {});
  }
}
