<kpt-input
  type="tel"
  [formControl]="control"
  [readonly]="readonly"
  [disabledField]="disabled"
  [appendText]="appendText"
  [label]="label"
  [subtext]="descriptionI18N"
  (blur)="blur()"
  (focus)="focus()"
>
</kpt-input>
