import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from '@lib/components';
import {FormModule, HelpModule} from '@shared/ui';
import {InputModule} from '@shared/ui/forms/input/input.module';
import {AssetListItemComponent} from './asset-list-item.component';

@NgModule({
  declarations: [AssetListItemComponent],
  imports: [CommonModule, InputModule, ReactiveFormsModule, HelpModule, FormModule, TooltipModule],
  exports: [AssetListItemComponent],
})
export class AssetListItemModule {}
