<div class="form-group" [ngClass]="customFormGroupClass" [attr.id]="id">
  <ng-container *ngIf="type !== 'textarea' && type !== 'number' && !autocompleteObservable">
    <input
      #field
      class="{{ 'form-control ' + getSmartformClasses() }}"
      [autocomplete]="smartformEnabled ? 'off' : autocomplete ? 'on' : 'off'"
      (blur)="onInputBlur($event.target.value)"
      (input)="onInputChange($event.target.value)"
      (focus)="onInputFocus($event)"
      (keydown)="keydown.emit($event)"
      (keyup)="keyup.emit($event)"
      (keypress)="keypress.emit($event)"
      [type]="inputType"
      [name]="name"
      [ngClass]="customFieldClass"
      [readonly]="readonly"
      [placeholder]="placeholder || label || ''"
      [attr.maxLength]="maxLength ? maxLength : null"
      [class.form-control--not-empty]="!!_value || !!value"
      [disabled]="disabled"
      [attr.required]="required"
      [imask]="mask"
      [unmask]="mask ? 'typed' : null"
    />
  </ng-container>

  <!-- Note that I have to duplicate the whole input as I'm not able to apply matAutocomplete directive conditionally -->
  <ng-container *ngIf="type !== 'textarea' && type !== 'number' && autocompleteObservable">
    <input
      #field
      class="{{ 'form-control ' + getSmartformClasses() }}"
      [autocomplete]="smartformEnabled ? 'off' : autocomplete ? 'on' : 'off'"
      (blur)="onInputBlur($event.target.value)"
      (input)="onInputChange($event.target.value)"
      (focus)="onInputFocus($event)"
      (keydown)="keydown.emit($event)"
      (keyup)="keyup.emit($event)"
      (keypress)="keypress.emit($event)"
      [type]="inputType"
      [name]="name"
      [ngClass]="customFieldClass"
      [readonly]="readonly"
      [placeholder]="placeholder || label || ''"
      [attr.maxLength]="maxLength ? maxLength : null"
      [class.form-control--not-empty]="!!_value || !!value"
      [disabled]="disabled"
      [attr.required]="required"
      [imask]="mask"
      [unmask]="mask ? 'typed' : null"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onInputChange($event.option.value)">
      <mat-option *ngFor="let value of autocompleteObservable | async" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>

  <input
    *ngIf="type === 'number'"
    #field
    class="form-control"
    (blur)="onInputBlur($event.target.value)"
    (input)="onInputChange($event.target.value)"
    (focus)="onInputFocus($event)"
    [type]="inputType"
    [name]="name"
    [ngClass]="customFieldClass"
    [readonly]="readonly"
    [placeholder]="placeholder || label || ''"
    [class.form-control--not-empty]="_value !== undefined && _value !== null && _value !== ''"
    [disabled]="disabled"
    [required]="required"
    [attr.min]="min"
    [attr.max]="max"
    [attr.step]="step"
    [autocomplete]="autocomplete ? 'on' : 'off'"
    [imask]="mask"
    [unmask]="mask ? 'typed' : null"
  />

  <textarea
    *ngIf="type === 'textarea'"
    #field
    class="form-control"
    (blur)="onInputBlur($event.target.value)"
    (input)="onInputChange($event.target.value)"
    (focus)="onInputFocus($event)"
    [ngClass]="customFieldClass"
    [disabled]="disabled"
    [attr.maxLength]="maxLength ? maxLength : null"
    [name]="name"
    [class.form-control--not-empty]="_value && _value.length"
    [readonly]="readonly"
    [placeholder]="placeholder || ''"
    [required]="required"
    [rows]="rows"
    [spellcheck]="spellCheck"
    >{{ value }}</textarea
  >

  <!-- "material-like" placeholder -->
  <label class="control-label" (click)="labelClick()">
    <ng-template [ngTemplateOutlet]="labelTemplate || defaultLabelTemplateRef"> </ng-template>
  </label>

  <!-- suffix in input field, e.g: `Kč` -->
  <div *ngIf="!!appendText" class="input-group-append">
    <span class="input-group-append__text" [innerHTML]="appendText"> </span>
  </div>

  <!-- append e.g <kpt-help> as a TemplateRef -->
  <div class="input-group-append" *ngIf="appendTemplate">
    <ng-template [ngTemplateOutlet]="appendTemplate"></ng-template>
  </div>

  <!-- tiny text below the input -->
  <div *ngIf="!!subtext" class="input-subtext" [class.input-subtext--disabled]="!!disabled">
    {{ subtext }}
  </div>

  <!-- warning text below the input -->
  <div *ngIf="!!warningText" class="input-warning-text">
    {{ warningText }}
  </div>

  <!-- show only when kpt-invalid class is active -->
  <kpt-form-field-error
    [showErrorsOn]="showErrorsOn"
    [customErrors]="customErrors"
  ></kpt-form-field-error>

  <!-- maybe move to cmp -->
  <ng-template #defaultLabelTemplateRef>
    <span [innerHTML]="htmlLabel || label"></span>
  </ng-template>

  <ng-template #defaultPlainLabelTemplateRef>
    {{ label }}
  </ng-template>
</div>
