// noinspection CssInvalidPropertyValue

import {IntroAdvisor} from '@generated/defs/IntroAdvisor';
import {Options, Step} from 'intro.js';

export interface IntroJsOptions extends Options {
  autoPosition?: boolean;
}

export type Scenario = keyof IntroAdvisor;

export const defaultOptions: IntroJsOptions = {
  exitOnEsc: true,
  exitOnOverlayClick: true,
  disableInteraction: true,
  nextLabel: 'Pokračovat',
  prevLabel: 'Zpět',
  doneLabel: 'Beru na vědomí',
  tooltipClass: 'onboarding-tooltip',
  highlightClass: 'onboarding-highlighter',
};

export const scenarios: Record<Scenario, () => IntroJsOptions> = {
  financialAnalysisOverview,
  simpleFinancialAnalysisOverview,
  homepageOverview,
  clientPageOverview,
  financialPlanOverview,
};

type StepName =
  | 'family1'
  | 'family2'
  | 'finances1'
  | 'finances2'
  | 'properties'
  | 'feedback'
  | 'finish';

function commonFinancialAnalysisSteps(): Record<StepName, Step> {
  /* eslint-disable max-len */
  return {
    family1: {
      title: 'Stanice Rodina',
      intro:
        `
        <p>Nejprve vás provedeme základními ovládacími prvky.</p>
        <p>Nyní se nacházíte v sekci <strong>Rodina</strong>. Mezi jednotlivými sekcemi se můžete pohybovat jak klikem na <strong>danou sekci v&nbsp;horním menu</strong>, tak pomocí <strong>šipek v&nbsp;bočních částech obrazovky</strong>.</p>
        <p><strong>Přes ikonu menu</strong> v&nbsp;pravém horním rohu <strong>se dostanete do&nbsp;ostatních částí Kappky</strong>.</p>
        <p><strong>TIP:</strong> V&nbsp;Kappce se nemusíte pohybovat v&nbsp;pořadí, v&nbsp;jakém jsou sekce řazeny. Najděte si váš vlastní systém a používejte sekce dle&nbsp;vašich potřeb. Kappka slouží jako pomůcka především pro&nbsp;vás.</p>
        ` + introImage('/assets/images/intro/financial-analysis-overview/family-1.png', 831, 498),
      position: 'bottom',
    },
    family2: {
      title: 'Stanice Rodina',
      intro:
        `
        <p>Ve stanici „rodina“ můžete upravovat a přidávat členy rodiny.</p>
        <p><strong>Přes ikonu tužky</strong> u&nbsp;každého člena rodiny <strong>můžete editovat jeho údaje</strong>. Tlačítkem <strong>„+”</strong> můžete přidat člena rodiny.</p>
        <p><strong>TIP:</strong> Pro založení dalšího člena rodiny vám stačí jméno, příjmení a datum narození (nemusíte zadávat další údaje).</p>
        ` + introImage('/assets/images/intro/financial-analysis-overview/family-2.png', 807, 554),
      position: 'bottom',
    },
    finances1: {
      title: 'Stanice Hospodaření',
      intro:
        `
        <p>Ve&nbsp;stanici <strong>„hospodaření” zapisujete příjmy a výdaje klienta</strong>.</p> 
        <p><strong>V&nbsp;horní části obrazovky vidíte přehled</strong> vámi zadaných údajů.</p>
        <p><strong>Jednotlivé položky u&nbsp;příjmů a výdajů můžete nechat</strong> pro potřeby přehlednější prezentace klientovi <strong>zabalené</strong>.</p>
        <p><strong>TIP:</strong> Pokud nechcete výdaje rozpadávat na&nbsp;jednotlivé položky, napište do&nbsp;nadřazené položky celkovou sumu.</p>
        ` + introImage('/assets/images/intro/financial-analysis-overview/finances-1.png', 918, 749),
      position: 'bottom-middle-aligned',
    },
    finances2: {
      title: 'Stanice Hospodaření',
      intro:
        `
        <p>U&nbsp;jednotlivých smluv můžete určit, <strong>k&nbsp;jaké prioritě se vztahuje</strong> (jaký cíl klienta plní). Např. zde u&nbsp;pojištění auta se může jednat o&nbsp;cíl Zajištění majetku a rodiny.</p> 
        <p>Kromě navázání priority můžete rovnou navázat, i k&nbsp;jakému majetku smlouva patří.</p>
        <p><strong>TIP:</strong> Pokud ještě nemáte zadaný majetek klienta, nemusíte nikam překlikávat, stačí jej vytvořit pomocí pole <strong>„+&nbsp;přidat položku”</strong> v&nbsp;tomto místě.</p>
        ` + introImage('/assets/images/intro/financial-analysis-overview/finances-2.png', 905, 558),
      position: 'bottom-middle-aligned',
    },
    properties: {
      title: 'Stanice Majetek',
      intro:
        `
        <p>Do <strong>stanice „majetek” zapisujete s&nbsp;klientem jeho majetek, ale také jeho závazky</strong>. Propojenost se stanicí “hospodaření” a prioritami jsme si již ukázali v&nbsp;předchozím kroku.</p>
        <p>Stanice „majetek” funguje na&nbsp;stejném principu jako stanice “hospodaření”.</p>
        ` +
        introImage('/assets/images/intro/financial-analysis-overview/properties.png', 1038, 804),
      position: 'bottom-right-aligned',
    },
    feedback: {
      title: 'Kontaktovat podporu',
      intro: `
        <p>Pro&nbsp;zpětnou vazbu prosím použijte tlačítko <strong>„Kontaktovat podporu”</strong> v&nbsp;pravém dolním rohu obrazovky. <strong>Váš podnět</strong> následně odbavíme.</p>
        <p>V&nbsp;případě zájmu prosím navštivte náš KAPITOL Portál, kde se můžete přihlásit na&nbsp;školení. Pokud není vypsaný volný termín a máte o&nbsp;školení zájem, napište nám na&nbsp;podpora@kapitol.cz. </p>
        `,
      position: 'bottom',
    },
    finish: {
      title: 'Děkujeme za pozornost',
      intro: `
        <p>Interaktivní průvodce je u&nbsp;konce. Můžete si jej kdykoliv spustit znovu kliknutím na&nbsp;ikonu „interaktivní průvodce”.</p>
        `,
      position: 'left',
    },
  };
  /* eslint-enable max-len */
}

function financialAnalysisOverview(): IntroJsOptions {
  /* eslint-disable max-len */
  const commonSteps = commonFinancialAnalysisSteps();
  return {
    autoPosition: false,
    steps: [
      {
        element: document.querySelector('.header__screen-title'),
        title: 'Vítejte v nové finanční analýze',
        intro:
          `
        <p>Rádi bychom vám touto cestou představili, jak se pohybovat ve&nbsp;finanční analýze, včetně tipů pro&nbsp;ještě efektivnější využití této části pro&nbsp;váš obchod.</p>
        <p><strong>Pokud jste tu kvůli produktovému modulu, nemusíte vyplňovat tuto kompletní analýzu, stačí vám zjednodušená analýza,</strong> která je plně kompatibilní (pokud něco změníte ve&nbsp;zjednodušené, projeví se v&nbsp;komplexní a naopak).</p>
        <p>Projití průvodcem nezabere více než&nbsp;5&nbsp;minut.</p>
        <p>Můžeme začít?</p>
        ` +
          introImage('/assets/images/intro/financial-analysis-overview/client-page.png', 886, 558),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(1) > a',
        ),
        ...commonSteps.family1,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(1) > a',
        ),
        ...commonSteps.family2,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        title: 'Stanice Plány',
        intro:
          `
        <p><strong>Sekce „plány”</strong> slouží pro&nbsp;<strong>naplánování cílů a priorit klienta</strong>, které s&nbsp;vaší pomocí bude časem naplňovat.</p>
        <p>KAPITOL nabízí Finančního průvodce, jež vychází z&nbsp;doporučení KAPITOLu. Finanční průvodce obsahuje cíle, které vedou k&nbsp;finanční pohodě a následně k&nbsp;finanční nezávislosti. Jeho viditelnost si na&nbsp;ose můžete zvolit kliknutím na&nbsp;tlačítko „Doporučení KAPITOLu”.</p> 
        <p>O&nbsp;jednotlivých cílech se dočtete kliknutím na&nbsp;odkaz „Finanční průvodce KAPITOLu”.</p>
        ` +
          introImage('/assets/images/intro/financial-analysis-overview/objectives-1.png', 797, 543),
        position: 'bottom',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        title: 'Stanice Plány',
        intro:
          `
        <p>Jednotlivé cíle umístíte na&nbsp;osu <strong>přesunutím dlaždice z&nbsp;výběru priorit</strong> níže do&nbsp;požadovaného místa na&nbsp;ose. V&nbsp;případě potřeby dlaždici odstraníte přesunutím zpátky do&nbsp;výběru. 
        Můžete také zrušit všechny priority pomocí tlačítka „×&nbsp;smazat všechny cíle”.</p>
        <p><strong>Cíle jsou rozděleny na:</strong></p>
        <p><strong>KAPITOL doporučuje zajistit</strong> &ndash; naleznete zde všechny priority z&nbsp;Finančního průvodce.</p>
        <p><strong>Co dalšího si chci splnit</strong> &ndash; další možné cíle klienta.</p>
        ` +
          introImage('/assets/images/intro/financial-analysis-overview/objectives-2.png', 792, 544),
        position: 'bottom',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        title: 'Stanice Plány',
        intro:
          `
        <p><strong>Každou prioritu můžete rozkliknout a doplnit detaily k&nbsp;zadání vašeho klienta.</strong></p> 
        <p>Pokud kliknete na&nbsp;<strong>„Zobrazit více”</strong>, zobrazí se vám doporučení z&nbsp;Finančního průvodce.</p>
        <p>Zadané údaje <strong>se projeví ve&nbsp;výpočtech řešení pro&nbsp;danou prioritu ve&nbsp;finanční analýze</strong>.</p>
        ` +
          introImage('/assets/images/intro/financial-analysis-overview/objectives-3.png', 881, 530),
        position: 'bottom',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(3) > a',
        ),
        title: 'Stanice Priority',
        intro:
          `
        <p>Nyní se nacházíte ve&nbsp;stanici <strong>„priority”, kde váš klient rozhoduje, v&nbsp;jakém pořadí chce své plány řešit</strong>.</p>
        <p>Prioritu v&nbsp;pořadí posunete tak, že ji přesunete myší na&nbsp;příslušné místo.</p>
        <p><strong>TIP:</strong> Pokud cokoliv zaznačíte do&nbsp;poznámky, tato poznámka bude viditelná ve&nbsp;všech stanicích s&nbsp;touto prioritou.</p>
        ` + introImage('/assets/images/intro/financial-analysis-overview/priorities.png', 788, 543),
        position: 'bottom',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(4) > a',
        ),
        ...commonSteps.finances1,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(4) > a',
        ),
        ...commonSteps.finances2,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(5) > a',
        ),
        ...commonSteps.properties,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(6) > a',
        ),
        title: 'Stanice Preference klienta',
        intro:
          `
        <p>Nyní se nacházíte ve&nbsp;stanici „preference klienta”. Jedná se o&nbsp;investiční dotazník, který s&nbsp;klientem standardně vyplníte.</p>
        ` +
          introImage(
            '/assets/images/intro/financial-analysis-overview/investment-survey.png',
            768,
            558,
          ),
        position: 'bottom-right-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(7) > a',
        ),
        title: 'Stanice Shrnutí',
        intro:
          `
        <p>Zde si můžete s&nbsp;klientem nastavit schůzku a odeslat potvrzení o&nbsp;termínu e-mailem.</p> 
        <p>Schůzka se vám automaticky uloží do&nbsp;vašeho kalendáře v&nbsp;Portále/Sugaru.</p> 
        <p>Součástí e-mailu klientovi je kromě možnosti propsat si událost do&nbsp;kalendáře také finanční průvodce KAPITOLu v&nbsp;PDF formátu.</p> 
        ` + introImage('/assets/images/intro/financial-analysis-overview/summary.png', 778, 655),
        position: 'bottom-right-aligned',
      },
      {
        title: 'TIP: Shrnutí',
        intro:
          `
        <p><strong>Využijte záložku „Shrnutí”</strong></p>
        <p>Najdete zde všechny informace o&nbsp;klientovi, které jste zadali. Nemusíte se tak proklikávat jednotlivými stanicemi analýzy a dohledávat údaje. <strong>Zkrátka vše o&nbsp;klientovi na&nbsp;jednom místě.</strong></p>
        ` +
          introImage('/assets/images/intro/financial-analysis-overview/summary-tab.png', 1050, 670),
        position: 'top',
      },
      {
        // element: document.querySelector('iframe[class*=ybug]'),
        ...commonSteps.feedback,
      },
      {
        element: document.querySelector('kpt-intro-js'),
        ...commonSteps.finish,
      },
    ],
  };
  /* eslint-enable max-len */
}

function simpleFinancialAnalysisOverview(): IntroJsOptions {
  const commonSteps = commonFinancialAnalysisSteps();
  /* eslint-disable max-len */
  return {
    autoPosition: false,
    steps: [
      {
        element: document.querySelector('.header__screen-title'),
        title: 'Vítejte ve zjednodušené finanční analýze',
        intro:
          `
        <p>Rádi bychom vám touto cestou představili, jak se pohybovat ve&nbsp;zjednodušené finanční analýze.</p>
        <p>Zjednodušená finanční analýza se od&nbsp;komplexní liší tím, že obsahuje pouze stanice Majetek a Hospodaření. Slouží pro&nbsp;rychlé zadání informací, které potřebujete pro jednotlivé moduly, v&nbsp;okamžiku, kdy nemáte prostor pro&nbsp;realizaci detailnější analýzy, a potřebujete rychle obsloužit konkrétní klientovu potřebu.</p>
        <p>Zjednodušená finanční analýza je plně propojená s&nbsp;komplexní finanční analýzou, tedy údaje, které zapíšete do&nbsp;zjednodušené analýzy, se projeví v&nbsp;komplexní a naopak.</p>
        ` +
          introImage(
            '/assets/images/intro/simple-financial-analysis-overview/client-page.png',
            867,
            558,
          ),
        position: 'bottom-middle-aligned',
      },
      {
        title: 'Údaje z finanční analýzy v každém modulu',
        intro:
          `
        <p>Po vstupu do&nbsp;modulu, který souvisí s&nbsp;finanční analýzou, nově zobrazujeme již zadané informace z&nbsp;finanční analýzy.</p> 
        <p>Pokud jste do&nbsp;finanční analýzy nic nezadali, můžete zjednodušenou variantu vyplnit přes tlačítko a poté se vrátit tam, kde jste přestali.</p> 
        ` +
          introImage(
            '/assets/images/intro/simple-financial-analysis-overview/modules.png',
            948,
            747,
          ),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(1) > a',
        ),
        ...commonSteps.family1,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(1) > a',
        ),
        ...commonSteps.family2,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        ...commonSteps.finances1,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        ...commonSteps.finances2,
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(3) > a',
        ),
        ...commonSteps.properties,
      },
      {
        // element: document.querySelector('iframe[class*=ybug]'),
        ...commonSteps.feedback,
      },
      {
        element: document.querySelector('kpt-intro-js'),
        ...commonSteps.finish,
      },
    ],
  };
  /* eslint-enable max-len */
}

function introImage(imageSrc: string, width: number, height: number) {
  const wrapperWidth = 560;
  const wrapperHeight = (height / width) * wrapperWidth;
  return `
          <style>
            .intro-image-wrapper {
              position: relative;
              width: 100%;
              height: ${wrapperHeight}px;
              margin-top: 2.5rem;
            }
            .intro-image, .intro-hover-box {
              z-index: 100;
              position: absolute;
              width: 100%;
              height: ${wrapperHeight}px;
              top: 0;
              left: 0;
            }
            .intro-image {
              border: 2px solid #60aea1;
              transition: all 0.7s ease;
            }
            .intro-hover-box {
              z-index: 101;
            }
            .intro-hover-box:hover ~ .intro-image {
              width: ${width}px;
              height: ${height}px;
              left: calc((100% - ${width}px) / 2);
              top: calc((100% - ${height}px) / 2);
            }
         </style>
         <div class="intro-image-wrapper">
           <div class="intro-hover-box"></div>
           <img class="intro-image" src="${imageSrc}"/>
         </div>`;
}

function homepageOverview(): IntroJsOptions {
  /* eslint-disable max-len */
  const commonSteps = commonFinancialAnalysisSteps();
  return {
    autoPosition: false,
    steps: [
      {
        element: document.querySelector('#top-line-for-intro-js'),
        title: 'Vítejte v KAPPCE',
        intro:
          `
        <h3>V krátké chvíli vám pomocí interaktivního průvodce představíme, co nabízí úvodní obrazovka.</h3> 
        <p>Vítejte na&nbsp;první stránce KAPPKY, která slouží jako „otvírák“ obchodního rozhovoru s&nbsp;Vaším klientem. Najdete tu <strong>představení poradce, KAPITOLu,</strong> způsobu práce, obchodní pomůcky a&nbsp;argumenty pro&nbsp;poradce.</p> 
        <p><strong>TIP:</strong> Využijte jednu z&nbsp;mnoha obchodních pomůcek v&nbsp;KAPPCE <strong>„Jak zacházet s&nbsp;penězi“.</strong></p>
        ` + introImage('/assets/images/intro/homepage/homepage-1.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('#top-line-for-intro-js'),
        title: 'Vyhledání klienta a propojení s kalendářem',
        intro:
          `
        <p>Zde si můžete <strong>vyhledat či založit klienta</strong> a následně zvolit požadovanou rodinu.</p> 
        <p>Zároveň si v&nbsp;sekci <strong>Poslední aktivity</strong> můžete zobrazit Vaše <strong>naplánované schůzky (záložka Moje schůzky).</strong></p>
        <p><strong>TIP:</strong> Jakmile si vyberete klienta, na&nbsp;současné obrazovce zůstáváte. <strong>Klienta si zobrazíte pod&nbsp;Posledními aktivitami pod záložkou Aktuální klient.</strong> Je jen na&nbsp;Vás, jaký průběh obchodní řeči si zvolíte.</p>
          ` + introImage('/assets/images/intro/homepage/homepage-2.png', 830, 516),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('#top-line-for-intro-js'),
        title: 'Finanční průvodce KAPITOLu',
        intro:
          `
        <p>Při vytváření personalizovaného finančního plánu je jako jeden z&nbsp;nezbytných kroků pro klienta <strong>uvědomit si své finanční priority</strong> a cíle a dále je konkretizovat. Ty za&nbsp;nás nejdůležitější jsou zpracovány ve&nbsp;finančním průvodci.</p> 
        <p><strong>TIP: Průvodce existuje i v&nbsp;tištěné variantě</strong> pro&nbsp;lepší prezentaci klientovi a také jako materiál, který si klient může odnést domů.</p>
        ` + introImage('/assets/images/intro/homepage/homepage-3.png', 818, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('kpt-intro-js'),
        ...commonSteps.finish,
      },
    ],
  };
  /* eslint-enable max-len */
}

function clientPageOverview(): IntroJsOptions {
  /* eslint-disable max-len */
  const commonSteps = commonFinancialAnalysisSteps();
  return {
    autoPosition: false,
    steps: [
      {
        element: document.querySelector('#top-line-for-intro-js'),
        title: 'Vítejte v rozcestníku pro výběr komplexního nebo modulového řešení',
        intro:
          `
        <h3>V krátké chvíli vám pomocí interaktivního průvodce představíme, co všechno nabízí tato obrazovka.</h3>
        <p>Po výběru klienta si můžete vybrat způsob řešení obchodního případu. Cíle a potřeby klienta můžeme vyřešit buď cestou <strong>komplexního finančního poradenství</strong>, jehož výsledkem bude personalizovaný <strong>finanční plán</strong>, nebo se můžeme podívat na <strong>řešení konkrétní životní situace</strong> a k&nbsp;plánu se můžete vrátit později.</p>
        <p><strong>TIP:</strong> V případě, že se k&nbsp;plánu budete vracet s&nbsp;klientem později, <strong>stačí doplnit pouze chybějící data</strong> (již zadané údaje vám díky propojeným systémům zůstaly nezměněné).</p>
        ` + introImage('/assets/images/intro/client-page/client-page-1.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('#top-line-for-intro-js'),
        title: 'Ostatní nástroje',
        intro:
          `
        <br>
        <p>Kromě různých přístupů k&nbsp;řešení obchodního případu zde máme sekci <strong>Ostatní nástroje</strong>, odkud se jedním proklikem dostanete na&nbsp;<strong>srovnávač aut, vozidel, kalkulaček našich obchodních partnerů</strong> a také do&nbsp;KAPITOL Portálu.</p>
        <p><strong>TIP:</strong> Naplánujte si přímo z&nbsp;KAPPKY schůzku s&nbsp;klientem nebo se podívejte na&nbsp;jeho detail v&nbsp;KAPITOL Portále.</p>
        ` + introImage('/assets/images/intro/client-page/client-page-2.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('kpt-intro-js'),
        ...commonSteps.finish,
      },
    ],
  };
  /* eslint-enable max-len */
}

function financialPlanOverview(): IntroJsOptions {
  /* eslint-disable max-len */
  const commonSteps = commonFinancialAnalysisSteps();
  return {
    autoPosition: false,
    steps: [
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(1) > a',
        ),
        title: 'Vítejte na úvodní obrazovce Finančního plánu',
        intro:
          `
        <br>
        <h3>V krátké chvíli vám pomocí interaktivního průvodce představíme, co nabízí modul Finanční plán.</h3>
        <p>Na&nbsp;úvodní stránce Finančního plánu máte připravenou jednoduchou argumentaci pro&nbsp;klienta, proč je dobré mít finanční plán.</p>
        <p><strong>TIP:</strong> I&nbsp;z&nbsp;této stránky máte možnost přejít na&nbsp;uzavření finančního plánu.</p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-1.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        title: 'Sekce Hospodaření',
        intro:
          `
        <p><strong>KAPPKA zároveň podporuje argumentaci v&nbsp;oblasti využívání finančních prostředků neúčelově</strong> a pomáhá si uvědomovat nutnost přesunout tyto prostředky tak, aby si klient své&nbsp;priority reálně plnil. Máte tedy možnost tyto skutečnosti velmi jednoduše prezentovat a obhájit si před&nbsp;klientem Vaši&nbsp;nastavenou strategii.</p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-2.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(2) > a',
        ),
        title: 'Sekce Hospodaření',
        intro:
          `
        <p><strong>KAPPKA umí zobrazit, jestli si klient své&nbsp;cíle reálně plní,</strong> nebo se naopak jedná pouze o&nbsp;plány, které jsou prozatím odsunuty na&nbsp;neurčito.</p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-3.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(3) > a',
        ),
        title: 'Sekce Majetek',
        intro:
          `
        <p>
        <p>V sekci Majetek najdete grafy definující <strong>současný majetek a závazky klienta.</strong></p>
        <p><strong>TIP:</strong> V hospodaření i majetku využijte sekce: „V čem se Vám určitě daří a příležitosti ke&nbsp;zlepšení.“ <strong>Některé výroky Vám vygenerujeme automaticky a některé jsou na&nbsp;posouzení poradce.</strong></p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-4.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(4) > a',
        ),
        title: 'Sekce Priority',
        intro:
          `
        <p>K&nbsp;přípravě konkrétního návrhu doporučení poradce (tedy nové strategie) a k&nbsp;řešení jednotlivých cílů slouží sekce Priority. <strong>Zde posuzujete současné nastavení priorit,</strong> což v&nbsp;praxi znamená, jak by si klient své priority plnil za&nbsp;současného finančního nastavení a jak je daný produkt k&nbsp;plnění priority vhodný. <strong>Pak navrhnete adekvátní doporučení pro&nbsp;každý klientův cíl.</strong></p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-5.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(4) > a',
        ),
        title: 'Prezentační mód návrhu Finančního plánu',
        intro:
          `
        <p>V prezentačním módu pak s&nbsp;klientem návrh procházíte a upravujete dle&nbsp;jeho přání.</p>
        <p><strong>Příklad:</strong> Zde konkrétně vidíme případ, kdy si klient sice svůj cíl plnil, ale jakmile si ho konkretizoval, tedy uvedl, jakou rentu chce pobírat, KAPPKA vypočítala, že si na tento cíl neinvestuje dostatečně. Poradce tedy současný produkt posoudil jako vhodný a k&nbsp;tomu přidal investici tak, aby si klient splnil svůj cíl na&nbsp;100&nbsp;%. Portfolio samozřejmě vybírá na&nbsp;základě principů obchodu investičního poradenství.</p>
        <p><strong>Vždy je na&nbsp;klientovi, jak návrh plánu posoudí.</strong></p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-6.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(6) > a',
        ),
        title: 'Manažerský souhrn',
        intro:
          `
          <p><strong>Finanční plán klientovi na&nbsp;závěr představujeme ve&nbsp;zjednodušené formě,</strong> aby pro&nbsp;něj bylo vše přehledné a srozumitelné.</p>
          <p>KAPPKA navíc ukazuje přínos Finančního plánu na&nbsp;majetku klienta, a to v&nbsp;podobě grafu vývoje majetku v&nbsp;souladu s&nbsp;dodržením navržené strategie.</p>
        ` + introImage('/assets/images/intro/financial-plan/financial-plan-7.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(6) > a',
        ),
        title: 'Přehled o stavu posouzení klienta/poradce',
        intro:
          `
          <p>Ještě než budete uzavírat návrh finančního plánu/uzavírat finanční plán, najdete zde <strong>přehled posouzení jednotlivých priorit ze&nbsp;strany poradce a klienta.</strong></p>
          <p>Pro&nbsp;uzavření návrhu musí být <strong>kompletní návrh u&nbsp;každé priority.</strong> Pro&nbsp;uzavření celkového plánu je nutné, aby bylo u&nbsp;<strong>všech priorit všechno kompletní.</strong></p>
           ` + introImage('/assets/images/intro/financial-plan/financial-plan-8.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector(
          'kpt-stepper-progress li.progress-bar__item:nth-child(6) > a',
        ),
        title: 'Přehled o stavu posouzení klienta/poradce',
        intro:
          `
          <p>Na závěr KAPPKA nezapomíná ani na&nbsp;<strong>pravidelné servisní schůzky</strong> či obchodní příležitosti, které si v&nbsp;aplikaci jednoduše nastavíte.</p>
           ` + introImage('/assets/images/intro/financial-plan/financial-plan-9.png', 836, 540),
        position: 'bottom-middle-aligned',
      },
      {
        element: document.querySelector('kpt-intro-js'),
        ...commonSteps.finish,
      },
    ],
  };
  /* eslint-enable max-len */
}
