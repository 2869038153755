<div *ngIf="!to.hidden" class="row">
  <div class="col-sm-6">
    <kpt-slider
      [parentForm]="sliderParentForm"
      [label]="to.label"
      sliderName="slider"
      [disabledSlider]="to.disabled"
      [sliderOptions]="to.sliderOption"
    >
    </kpt-slider>
  </div>
  <div class="col-sm-6">
    <kpt-input
      label=""
      type="number"
      [min]="to.sliderOption.floor"
      [max]="to.sliderOption.ceil"
      [formControl]="inputForm"
      [required]="to.required"
      [appendText]="to.appendText"
      [disabledField]="to.disabled"
    >
    </kpt-input>
  </div>
</div>
