import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {LifeinsuranceService} from '@generated/controllers/Lifeinsurance';
import {Error} from '@generated/store/integrations/deleteFiles/states/actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {isEqual} from 'lodash';
import {of} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pairwise,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {LifeInsuranceActionsUnion} from 'src/app/modules/life-insurance-old/store/life-insurance.actions';
import {
  selectFiles,
  selectForms,
  selectSummaries,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';
import {selectInsuranceSelectionsVM} from '../1-insurance-protection/insurance-protection.selectors';
import {getUniqueRisksData} from './life-insurance.helpers';
import {LifeInsuranceRiskUniqueIdent} from './life-insurance.models';

const DEBOUNCE_TIME = 400;

@Injectable()
export class LifeInsuranceEffects {
  updateSummaries = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LifeInsuranceActions.updateSummaries.type),
        debounceTime(DEBOUNCE_TIME),
        switchMap(action => {
          return this.lifeInsuranceService
            .createUpdateERecordData({
              data: action.summaries,
              family_uuid: action.familyUuid,
            })
            .pipe(catchError((error: HttpErrorResponse) => of(console.error(error))));
        }),
      ),
    {dispatch: false},
  );

  deleteInsuranceGroupForm = createEffect(() =>
    this.actions$.pipe(
      ofType(LifeInsuranceActions.deleteInsuranceGroupForm.type),
      withLatestFrom(this.store.pipe(select(selectForms)), this.store.pipe(select(selectFiles))),
      mergeMap(([action, forms, files]) => {
        const personFiles = files.filter(file => file.personId === action.personId);
        const formIds = forms
          .filter(form => form.personId === action.personId)
          .map(form => form.id);
        const removeDmsUuids = personFiles
          .filter(file => !formIds.includes(file.formId))
          .map(file => file.dmsUuid);
        return of(LifeInsuranceActions.removeFormFiles({dmsUuids: removeDmsUuids}));
      }),
    ),
  );

  removeFormFiles = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LifeInsuranceActions.removeFormFiles.type),
        filter(action => action.dmsUuids.length > 0),
        mergeMap(action => {
          const deleteFiles = action.dmsUuids.map(dmsUuid => ({dmsUuid}));
          return this.integrationsService
            .deleteFiles({data: deleteFiles})
            .pipe(catchError((error: HttpErrorResponse) => of(new Error(error))));
        }),
      ),
    {dispatch: false},
  );

  unCheckZeroRisks = createEffect(() =>
    this.actions$.pipe(
      ofType(LifeInsuranceActions.updatePersons.type),
      withLatestFrom(this.store.pipe(select(selectInsuranceSelectionsVM))),
      filter(res => res[1].some(person => person.individualSelection)),
      map(([_, insuranceSelection]) => {
        const personsWithIndividualSelection = insuranceSelection.filter(
          p => p.individualSelection,
        );

        const zeroRisks = personsWithIndividualSelection.reduce((acc, personSelection) => {
          acc = [
            ...acc,
            ...getUniqueRisksData(personSelection, personSelection.leftProvision.risks),
            ...getUniqueRisksData(personSelection, personSelection.rightProvision.risks),
          ];

          return acc;
        }, [] as LifeInsuranceRiskUniqueIdent[]);

        return LifeInsuranceActions.updateIndividualRisks({zeroRisks});
      }),
    ),
  );

  removeGeneralFiles = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LifeInsuranceActions.removeGeneralFile.type),
        mergeMap(action => {
          return this.integrationsService
            .deleteFiles({data: [{dmsUuid: action.dmsUuid}]})
            .pipe(catchError((error: HttpErrorResponse) => of(new Error(error))));
        }),
      ),
    {dispatch: false},
  );

  setFinancialAnalysisChangeFlag = createEffect(() =>
    this.actions$.pipe(
      ofType(LifeInsuranceActions.setPersons.type),
      pairwise(),
      map(([oldValues, newValues]) => {
        const changed = !isEqual(oldValues.persons, newValues.persons);
        return LifeInsuranceActions.setFinancialAnalysisChangeFlag({changed});
      }),
    ),
  );

  terminateAppointment = createEffect(() =>
    this.actions$.pipe(
      ofType(LifeInsuranceActions.terminateAppointment.type),
      withLatestFrom(this.store.pipe(select(selectSummaries))),
      mergeMap(([action, summaries]) => {
        return of(LifeInsuranceActions.updateSummaries({summaries, familyUuid: action.familyUuid}));
      }),
    ),
  );

  constructor(
    private lifeInsuranceService: LifeinsuranceService,
    private actions$: Actions<LifeInsuranceActionsUnion>,
    private integrationsService: IntegrationsService,
    private store: Store<State>,
  ) {}
}
