import {ChooseFundResponse} from '@generated/defs/ChooseFundResponse';
import {
  CurrentInvestment,
  InvestmentData,
  InvestmentSummary,
  InvestmentTermination,
  ReturnOnInvestment,
  WhereToInvest,
} from '@generated/model';
import {createAction, props, union} from '@ngrx/store';
import {InvestmentBaseData} from './investment.models';

export const setBaseData = createAction(
  '[Investment] Set Base Data',
  props<{baseData: InvestmentBaseData}>(),
);

export const setData = createAction('[Investment] Set Data', props<{data: InvestmentData}>());

export const setProducts = createAction(
  '[Investment] Set Products',
  props<{products: ChooseFundResponse[]}>(),
);

export const updateReturnOnInvestment = createAction(
  '[Investment] Update Return on Investment',
  props<{returnOnInvestment: Partial<ReturnOnInvestment>}>(),
);

export const updateWhereToInvest = createAction(
  '[Investment] Update Where to Invest',
  props<{whereToInvest: Partial<WhereToInvest>}>(),
);

export const updateCurrentInvestment = createAction(
  '[Investment] Update Current Investment',
  props<{currentInvestment: Partial<CurrentInvestment>}>(),
);

export const updateSummary = createAction(
  '[Investment] Update Summary',
  props<{summary: InvestmentSummary; familyUuid: string}>(),
);

export const terminateAppointment = createAction(
  '[Investment] Terminate Appointment',
  props<{termination: InvestmentTermination; familyUuid: string}>(),
);

const allActions = union({
  setBaseData,
  setData,
  setProducts,
  updateReturnOnInvestment,
  updateWhereToInvest,
  updateCurrentInvestment,
  updateSummary,
  terminateAppointment,
});

export type InvestmentActionsUnion = typeof allActions;
