import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AccordionModule} from '@shared/lib';
import {AccordionSwitchComponent} from '@shared/lib/components/accordion-switch/accordion-switch.component';
import {CheckboxModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@NgModule({
  declarations: [AccordionSwitchComponent],
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    CheckboxModule,
    SwitchModule,
    FlexboxModule,
  ],
  exports: [AccordionSwitchComponent],
})
export class AccordionSwitchModule {}
