import * as moment from 'moment';
import {SexFamilyMemberEnum} from '../models/family-member';

export const birthNumberRegExp = /^(\d{2})(\d{2})(\d{2})[ |/]?(\d{3})(\d?)$/;

const getBirthNumberParts = (birthNumber: string, expression = new RegExp(birthNumberRegExp)) => {
  const matches = birthNumber.match(expression);
  return matches
    ? {
        year: matches[1],
        month: matches[2],
        day: matches[3],
        ext: matches[4],
        c: matches[5],
      }
    : null;
};

export const isForeignerBirthNumber = (
  birthNumber: string,
  expression = new RegExp(birthNumberRegExp),
) => {
  const parts = getBirthNumberParts(birthNumber, expression);
  return parts ? parts.ext + parts.c === '9999' : false;
};

export const birthNumberValidation = (
  birthNumber: string,
  expression = new RegExp(birthNumberRegExp),
) => {
  const parts = getBirthNumberParts(birthNumber, expression);
  if (!parts) return false;
  const {year, month, day, ext, c} = parts;

  if (ext + c !== '9999' && c !== '') {
    const bn = year + month + day + ext;
    let mod = Number(bn) % 11;

    if (mod === 10) mod = 0;

    if (mod !== parseInt(c, 10)) return false;

    const monthNumber = Number(month);
    if (
      !(
        (monthNumber >= 1 && monthNumber <= 12) ||
        (monthNumber >= 21 && monthNumber <= 32) ||
        (monthNumber >= 51 && monthNumber <= 62) ||
        (monthNumber >= 71 && monthNumber <= 82)
      )
    ) {
      return false;
    }
  } else if (c === '' && year >= '54') return false;

  return true;
};

const getForeignersYearOfBirth = (year: number, month: number, day: number): number => {
  const birthDate = new Date(2000 + year, month - 1, day);
  return birthDate > new Date() ? birthDate.getFullYear() - 100 : birthDate.getFullYear();
};

export const getSexAndBirthDate = (
  birthNumber: string,
): {sex: SexFamilyMemberEnum; birthDate: Date} => {
  const parts = getBirthNumberParts(birthNumber);
  if (!birthNumberValidation(birthNumber) || !parts) {
    return null;
  }
  let year = Number(parts.year);
  let month = Number(parts.month);
  const day = Number(parts.day);

  let sex: SexFamilyMemberEnum = 'MALE';

  if (month >= 20 && month <= 50) {
    month -= 20;
  } else if (month >= 50 && month < 70) {
    sex = 'FEMALE';
    month -= 50;
  } else if (month >= 70) {
    sex = 'FEMALE';
    month -= 70;
  }

  if (isForeignerBirthNumber(birthNumber)) {
    year = getForeignersYearOfBirth(year, month, day);
  } else {
    year += year < 54 ? 1900 : 1800;
    if (birthNumber.length === 10) year += 100;
  }

  return {sex, birthDate: new Date(year, month - 1, day)};
};

export const getBirthDateWarning = (
  oldBirthNumber: string,
  birthNumber: string,
  birthDate: Date,
): string => {
  if (oldBirthNumber.length > birthNumber.length) {
    if (birthNumber.length < 10) {
      const birthDateMoment = moment(birthDate);
      if (birthDateMoment && moment().year() - birthDateMoment.year() >= 100) {
        return 'Datum narození je před 100 lety. Je to správně?';
      }
    }
  } else if (birthNumber.length >= 10) {
    return null;
  }
};
