import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {FoundPortalClient} from '@generated/defs/FoundPortalClient';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {isNilOrEmpty} from '@shared/lib';
import {IModal} from '@shared/lib/components/modal/models/modal.model';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {filter} from 'rxjs/operators';
import {getClientTableCols} from 'src/app/modules/consultant/consultant-page.config';
import {State} from 'src/store';
import {FindClientsState as FindClientsState} from 'src/store/reducers/find-clients.reducer';
import {getFamilyMembersState} from 'src/store/selectors/family-member.selectors';
import {notLoadingNorError} from 'src/store/store-helpers';

export type ResultSection = 'Not found' | 'Found';

@UntilDestroy()
@Component({
  selector: 'kpt-client-search',
  styleUrls: ['./client-search.component.scss'],
  templateUrl: './client-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSearchComponent implements OnDestroy, OnInit {
  @Output() addToFamilyAction = new EventEmitter<string>();
  @Output() reassignAction = new EventEmitter<FoundPortalClient>();
  @Input() foundClientsState: FindClientsState;
  @Input() showSearchResults = true;
  @Input() showClientSelection = true;
  @Input() duplicates = false;
  @Input() birthNumberToAssign: string;

  tableCols = getClientTableCols();
  familyMemberUuids: string[] = [];
  clientNavigating = -1;

  constructor(
    private store: Store<State>,
    private modalService: ModalService,
    private integrationsService: IntegrationsService,
    private cdRef: ChangeDetectorRef,
  ) {}

  selectClient(client: FoundPortalClient, rowIndex: number) {
    const clientId = client.sugarUuid;
    if (isNilOrEmpty(clientId)) return;
    this.clientNavigating = rowIndex;
    this.clearAndNavigate(clientId);
  }

  addClientToFamily(client: FoundPortalClient) {
    const clientId = client.sugarUuid;
    if (isNilOrEmpty(clientId)) return;
    this.addToFamilyAction.emit(clientId);
  }

  ngOnInit(): void {
    if (!this.showClientSelection) {
      this.store
        .pipe(select(getFamilyMembersState), filter(notLoadingNorError))
        .pipe(untilDestroyed(this))
        .subscribe(familyMembersState => {
          this.familyMemberUuids = familyMembersState.familyMembers.map(m => m.sugarUuid);
        });
    }
  }

  ngOnDestroy(): void {}

  showResultsFor(section: ResultSection): boolean {
    if (section === 'Not found') {
      return (
        !this.duplicates &&
        this.showSearchResults &&
        !this.foundClientsState.loading &&
        this.foundClientsState.clients.length === 0
      );
    } else {
      return (
        this.showSearchResults &&
        !this.foundClientsState.loading &&
        this.foundClientsState.clients.length > 0
      );
    }
  }

  reassign(client: FoundPortalClient) {
    this.reassignAction.emit(client);
  }

  assign(client: FoundPortalClient, addToFamily = false) {
    const clientId = client.sugarUuid;
    this.integrationsService
      .createAssignment({sugar_uuid: clientId, data: {birthNumber: this.birthNumberToAssign}})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (addToFamily) {
          this.addClientToFamily(client);
        } else {
          this.clearAndNavigate(clientId);
        }
      });
  }

  foundByBirthNumber() {
    return {
      condition: (row: any) =>
        row.access === 'permitted' || row.birthNumber === this.birthNumberToAssign,
    };
  }

  inCurrentFamily(client: FoundPortalClient): boolean {
    return this.familyMemberUuids.includes(client.sugarUuid);
  }

  private clearAndNavigate(clientId: string) {
    this.integrationsService
      .getClient({sugar_uuid: clientId})
      .pipe(untilDestroyed(this))
      .subscribe(
        portalClient => {
          this.clientNavigating = -1;
          this.cdRef.markForCheck();
          this.modalService.setData({});
          this.openModal({
            component: 'FamiliesModalComponent',
            data: portalClient,
          });
        },
        _ => (this.clientNavigating = -1),
      );
  }

  private openModal = (modal: IModal) => this.modalService.openModal(modal);
}
