import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CarouselTemplateDirective} from '@shared/directives/carousel-stepper/carousel-stepper-template.directive';
import {trackByItem, trackByProperty} from '@shared/lib';
import {CarouselStepComponent} from '@shared/ui/carousel-stepper/carousel-step/carousel-step.component';

/**
 * @description
 * Component that merges carousel and stepper functionality.
 * It reads given content and shows properly as carousel with step names above.
 * To make it work use `CarouselStepComponent` to define steps.
 *
 * Navigation between steps may be done with left/right arrow keys
 *
 * If needed, last step may contain finishing button, that onClick emits EventEmitter, to hook any action on in.
 *
 * @see `CarouselStepComponent`
 *
 * Example:
 * ```
 * <kpt-carousel-stepper (finishAction)="resolve()" finishButtonLabel="Resolve" >
 *
 *  <kpt-carousel-step stepGroup="First step">
 *    This is body of the first step
 *  </kpt-carousel-step>
 *
 *  <kpt-carousel-step stepGroup="Second step">
 *    This is body of the second step
 *  </kpt-carousel-step>
 *
 *  <kpt-carousel-step stepGroup="Second step">
 *    This is body of the third step with same step name as step two
 *  </kpt-carousel-step>
 *
 * </kpt-carousel-stepper>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'kpt-carousel-stepper',
  templateUrl: './carousel-stepper.component.html',
  styleUrls: ['./carousel-stepper.component.scss'],
})
export class CarouselStepperComponent implements AfterContentInit {
  @Input()
  showFinishButton = true;

  @Input()
  finishButtonLabel = 'Vyhodnotit';

  @Input()
  finishedSteps: Record<string, boolean> = {};

  @Input()
  allStepsMustBeFinished = false;

  @Input()
  activeStep = 0;

  @Input()
  disableNextWhenInvalid = true;

  @Input()
  stepLabel = 'Otázka';

  @Input()
  showSteps = true;

  @Input()
  disableAllSteps = false;

  @Input()
  disableFinishingStep = false;

  @Input()
  disableSkippingSteps = false;

  @Input()
  showBackButtonOnFirstPage = false;

  @Input()
  backButtonOnFirstPageLinkUrl: string = null;

  @Input()
  backButtonOnFirstPageLabel: string = null;

  @Input()
  wrapLabels: boolean;

  @Input()
  showStepperFooter = true;

  @Output()
  finishAction = new EventEmitter<void>();

  @Output()
  nextStepAction = new EventEmitter<string>();

  @Output()
  activeStepChange = new EventEmitter<number>();

  @Output()
  beforeActiveStepChange = new EventEmitter<number>();

  show = false;
  steps: {
    stepGroup: string;
    stepName: string;
    template: TemplateRef<CarouselTemplateDirective>;
  }[] = [];
  stepTitles = new Set<string>();
  stepTitle = '';

  trackByItem = trackByItem;
  trackByProperty = trackByProperty;

  @ViewChild('stepperContainer') private stepperContainer: ElementRef;
  @ContentChildren(CarouselStepComponent) private inputSteps: QueryList<CarouselStepComponent>;

  // TODO(pz) target only this component instead of the document
  // TODO(pk) scroll behaviour is not applied when move is done by keys
  // @HostListener('document:keydown.ArrowLeft', ['$event']) onKeyLeftHandler() {
  //   this.prev();
  // }

  // @HostListener('document:keydown.ArrowRight', ['$event']) onKeyRightHandler() {
  //   this.next();
  // }

  constructor(private router: Router) {}

  get backButtonLabel() {
    if (this.showCustomBackButton() && this.backButtonOnFirstPageLabel) {
      return this.backButtonOnFirstPageLabel;
    }
    return 'Zpět';
  }

  getTemplate(index: number): TemplateRef<CarouselTemplateDirective> {
    if (index >= this.steps.length || index < 0) return null;
    const step = this.steps[index];

    if (this.activeStep === index) this.stepTitle = step.stepGroup;

    return step.template;
  }

  ngAfterContentInit() {
    this.refresh();
    this.show = true;

    this.inputSteps.changes.pipe(untilDestroyed(this)).subscribe(() => this.refresh());
  }

  canContinue() {
    if (this.activeStep >= this.steps.length || this.activeStep < 0) return false;

    return this.finishedSteps[this.steps[this.activeStep].stepName];
  }

  canFinish() {
    return this.allStepsMustBeFinished
      ? Object.values(this.finishedSteps).every(Boolean)
      : this.canContinue();
  }

  next() {
    if (this.activeStep + 2 > this.steps.length) return;
    this.activeStep++;
    this.activeStepChange.emit(this.activeStep);

    if (!this.isScrolledIntoView())
      this.stepperContainer.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  prev() {
    if (this.activeStep - 1 < 0) return;
    this.activeStep--;
    this.activeStepChange.emit(this.activeStep);

    if (!this.isScrolledIntoView())
      this.stepperContainer.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  getNextStep(activeStep: number, forward: boolean): number {
    const nextStep = forward ? activeStep + 1 : activeStep - 1;
    if (!this.steps[nextStep]) return activeStep;
    if (!this.inputSteps.toArray()[nextStep].skip) {
      return nextStep;
    } else {
      return this.getNextStep(nextStep, forward);
    }
  }

  stepClick(stepGroup: string) {
    if (
      this.stepGroupFinished(stepGroup) ||
      !this.disableSkippingSteps ||
      this.stepGroupInProgress(stepGroup)
    ) {
      const stepToMove = this.steps.findIndex(step => step.stepGroup === stepGroup);
      this.animatedMove(stepToMove);
    }
  }

  stepGroupInProgress(groupName: string) {
    const stepNames = this.steps
      .filter(step => step.stepGroup === groupName)
      .map(step => step.stepName);
    return (
      stepNames.some(stepName => this.finishedSteps[stepName]) &&
      !stepNames.every(stepName => this.finishedSteps[stepName])
    );
  }

  stepGroupFinished(groupName: string) {
    const filteredSteps = this.steps.filter(step => step.stepGroup === groupName);
    const stepName = filteredSteps[filteredSteps.length - 1].stepName;
    return this.finishedSteps[stepName];
  }

  proceed(forward: boolean) {
    if (!forward && this.showCustomBackButton()) {
      this.router.navigateByUrl(this.backButtonOnFirstPageLinkUrl);
      return;
    }

    const nextStep = this.getNextStep(this.activeStep, forward);
    this.nextStepAction.emit(this.steps[this.activeStep].stepName);
    if (forward) {
      if (!this.canContinue()) return;
    }

    this.animatedMove(nextStep);
  }

  private refresh() {
    this.stepTitles = new Set<string>();
    this.steps = [];

    this.inputSteps.forEach(inputStep => {
      this.stepTitles.add(inputStep.stepGroup);
      this.steps.push({
        stepGroup: inputStep.stepGroup,
        stepName: inputStep.stepName,
        template: inputStep.stepTemplate.content,
      });
    });
  }

  private animatedMove(stepToMove: number) {
    this.beforeActiveStepChange.emit(stepToMove);
    if (this.activeStep > stepToMove) {
      this.prev();
      setTimeout(() => this.animatedMove(stepToMove), 100);
    }

    if (this.activeStep < stepToMove) {
      this.next();
      setTimeout(() => this.animatedMove(stepToMove), 100);
    }
  }

  private isScrolledIntoView() {
    const rect = this.stepperContainer.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    return elemTop >= 0 && elemBottom <= window.innerHeight;
  }

  private showCustomBackButton(): boolean {
    return this.backButtonOnFirstPageLinkUrl && this.activeStep === 0;
  }
}
