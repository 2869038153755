import {FieldLabel} from '@shared/analysis/field-label';
import {AssetType} from '@shared/analysis/models/asset';

export interface Risk {
  key: MovableRisks | ImmovableRisks;
  active: boolean;
  limit: number;
  unit?: RiskUnit;
}

export type RiskUnit = 'million';

export enum ImmovableRisks {
  RequiredInsuredAmount = 'requiredInsuredAmount',
  Household = 'household',
  HoldingInsurance = 'holdingInsurance',
  CivilLiability = 'civilLiability',
  Other = 'otherImmovable',
}

/**
 * Vehicle risks available during the requirements phase.
 */
export enum MovableRisks {
  CompulsoryInsurance = 'compulsoryInsurance',
  AccidentInsurance = 'accidentInsurance',
  Theft = 'theft',
  Vandalism = 'vandalism',
  NaturalDisasters = 'naturalDisasters',
  Windshield = 'windshield',
  Other = 'otherMovable',
}

/**
 * Risks available when advisor is creating a proposal. Compulsory insurance is split into two risks,
 * accident insurance has different key.
 */
export enum MainMovableRisks {
  CompulsoryInsuranceHealth = 'compulsoryInsuranceHealthInMil',
  CompulsoryInsuranceProperty = 'compulsoryInsurancePropertyInMil',
  AccidentInsurance = 'accidentInsuranceTotal',
}

export type PropertyRisks = ImmovableRisks | MovableRisks | MainMovableRisks;

export interface RiskDefinition {
  key: MovableRisks | ImmovableRisks;
  label: string;
  unit?: RiskUnit;
}

const vehicleRiskDefinitions: RiskDefinition[] = [
  {
    key: MovableRisks.CompulsoryInsurance,
    label: FieldLabel.COMPULSORY_INSURANCE_LONGER,
    unit: 'million',
  },
  {key: MovableRisks.AccidentInsurance, label: FieldLabel.ACCIDENT_INSURANCE_LONGER},
  {key: MovableRisks.Theft, label: FieldLabel.THEFT},
  {key: MovableRisks.Windshield, label: FieldLabel.WINDSHIELD},
  {key: MovableRisks.Vandalism, label: FieldLabel.VANDALISM},
  {key: MovableRisks.NaturalDisasters, label: FieldLabel.NATURAL_DISASTERS},
  {key: MovableRisks.Other, label: FieldLabel.OTHER_RISK},
];

const propertyRiskDefinitions: RiskDefinition[] = [
  {key: ImmovableRisks.Household, label: FieldLabel.HOUSEHOLD},
  {key: ImmovableRisks.RequiredInsuredAmount, label: FieldLabel.PROPERTY},
  {key: ImmovableRisks.HoldingInsurance, label: FieldLabel.HOLDING_INSURANCE},
  {key: ImmovableRisks.CivilLiability, label: FieldLabel.CIVIL_LIABILITY},
  {key: ImmovableRisks.Other, label: FieldLabel.OTHER_RISK},
];

export interface RiskListItem {
  def: RiskDefinition;
  data: Risk;
}

export function getRiskList(asset: {type: AssetType; risks: Risk[]}): RiskListItem[] {
  let riskDefinitions: RiskDefinition[];
  switch (asset.type) {
    case AssetType.VehicleProvision:
    case AssetType.VehicleRequirements:
      riskDefinitions = vehicleRiskDefinitions;
      break;
    case AssetType.PropertyProvision:
    case AssetType.PropertyRequirements:
      riskDefinitions = propertyRiskDefinitions;
      break;
    default:
      throw new Error(`Unsupported asset type`);
  }

  const riskList: RiskListItem[] = riskDefinitions.map(def => ({
    def,
    data: {
      key: def.key,
      active: false,
      limit: null,
      unit: def.unit,
    },
  }));

  for (const risk of asset.risks) {
    const item = riskList.find(i => i.def.key === risk.key);
    if (item) {
      item.data = risk;
    } else {
      // append unknown risks to the end of the list
      riskList.push({
        def: {
          key: risk.key,
          label: risk.key,
          unit: risk.unit,
        },
        data: risk,
      });
    }
  }

  return riskList;
}

export function setRiskActive(
  asset: {type: AssetType; risks: Risk[]},
  risk: RiskListItem,
  active: boolean,
) {
  let assetRisk = asset.risks.find(r => r.key === risk.data.key);
  if (!assetRisk) {
    assetRisk = risk.data;
    asset.risks.push(assetRisk);
  }
  assetRisk.active = active;
}

export function setRiskLimit(
  asset: {type: AssetType; risks: Risk[]},
  risk: RiskListItem,
  limit: number,
) {
  let assetRisk = asset.risks.find(r => r.key === risk.data.key);
  if (!assetRisk) {
    assetRisk = risk.data;
    asset.risks.push(assetRisk);
  }
  assetRisk.limit = limit;
}

export function getMainMovableLabel(key: PropertyRisks): string {
  switch (key) {
    case MainMovableRisks.CompulsoryInsuranceHealth:
      return FieldLabel.COMPULSORY_INSURANCE_HEALTH;
    case MainMovableRisks.CompulsoryInsuranceProperty:
      return FieldLabel.COMPULSORY_INSURANCE_PROPERTY;
    case MainMovableRisks.AccidentInsurance:
      return FieldLabel.ACCIDENT_INSURANCE_TOTAL;
    default:
      return undefined;
  }
}
