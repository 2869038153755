import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {IModalSize} from '@shared/ui/modal/models/size.model';

@Component({
  selector: 'kpt-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input() isModalOpen: boolean;
  @Input() size?: IModalSize;
  @Input() hideClose = false;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.size = Object.values(IModalSize).includes(this.size) ? this.size : null;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(_: KeyboardEvent) {
    if (!this.hideClose) this.close.emit();
  }

  // Fix for datepicker rerender action
  scroll = () => {};

  backdropClose(event: MouseEvent) {
    if (!this.hideClose && (event.target as HTMLElement).classList.contains('modal')) {
      this.close.emit();
    }
  }
}
