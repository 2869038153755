import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import {TabComponent} from './tab.component';

@Component({
  selector: 'kpt-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements AfterContentInit, AfterViewInit {
  @Output() selectAction: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();
  @Input() allCaps: boolean;
  @Input() shift: number;
  @Input() alternativeColors: boolean;
  @Input() wrap: boolean;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChild('tabList', {static: true}) tabList: ElementRef;
  @ViewChildren('tabItem') tabItems: QueryList<ElementRef>;

  selectedTab: TabComponent;

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  ngAfterViewInit() {
    const tabsArray = this.tabItems.toArray();

    if (!tabsArray.length) {
      return;
    }
  }

  selectTab(selectedTab: TabComponent) {
    this.tabs.toArray().forEach(tab => (tab.active = false));
    this.selectedTab = selectedTab;
    selectedTab.active = true;
    this.selectAction.emit(selectedTab);
  }
}
