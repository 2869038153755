import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {CreateClientParams} from '@generated/controllers/Integrations';
import {SharedService} from '@generated/controllers/Shared';
import {FindClient} from '@generated/model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {sexFamilyMemberOptions} from '@shared/models/family-member';
import {ErrorMessages, IOption} from '@shared/ui';
import {createStringFromTemplate, CustomValidators, getBirthDateWarning} from '@shared/utils';
import {getSexAndBirthDate} from '@shared/utils/birth-number.fnc';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {trimBirthDateWithinObject} from '@shared/utils/trim-date.fnc';
import {isEqual} from 'lodash';
import {merge, timer} from 'rxjs';
import {delayWhen, distinctUntilChanged, filter, map, pairwise, share} from 'rxjs/operators';
import {State} from 'src/store';
import {
  CreateClientActionTypes,
  CreateClientSuccess,
} from 'src/store/actions/create-client.actions';
import {selectCreateClientLoading} from 'src/store/selectors/create-client.selectors';
import {selectFindClientLoading} from 'src/store/selectors/find-clients.selector';

@UntilDestroy()
@Component({
  selector: 'kpt-client-search-create-form',
  templateUrl: './client-search-create-form.component.html',
  styleUrls: ['./client-search-create-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSearchCreateFormComponent implements OnInit, OnDestroy {
  @Input() duplicateClientCheck = false;
  @Input() clientFound = false;
  @Input() foundByBirthNumber = false;
  @Input() isForeignerBirthNumber = false;
  @Input() get enableCreation() {
    return this._enableCreation;
  }
  set enableCreation(v: boolean) {
    this._enableCreation = v;
    this.primaryButton = v === true ? 'create' : 'search';
  }

  @Output() change = new EventEmitter<FindClient>();
  @Output() create = new EventEmitter<CreateClientParams>();
  @Output() search = new EventEmitter<FindClient>();
  @Output() clientCreated = new EventEmitter<string>();
  @Output() errors = new EventEmitter<ValidationErrors>();

  findClientLoading$ = this.store.select(selectFindClientLoading).pipe(
    delayWhen(res => (res === false ? timer(100) : timer(0))),
    share(),
  );
  createClientLoading$ = this.store.select(selectCreateClientLoading).pipe(
    delayWhen(res => (res === false ? timer(100) : timer(0))),
    share(),
  );

  primaryButton: 'search' | 'create' = 'search';
  controlValidators: {[name: string]: ValidatorFn[]} = {
    phoneNumber: [CustomValidators.phoneNumber()],
    email: [Validators.email],
    birthNumber: [CustomValidators.birthNumber()],
    sex: [CustomValidators.matchesBirthNumber('sex')],
    birthDate: [CustomValidators.matchesBirthNumber('birthDate'), CustomValidators.birthDate()],
  };

  form = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(null, this.controlValidators.phoneNumber),
    email: new UntypedFormControl(null, this.controlValidators.email),
    birthNumber: new UntypedFormControl(null, this.controlValidators.birthNumber),
    sex: new UntypedFormControl(null, this.controlValidators.sex),
    birthDate: new UntypedFormControl(null, this.controlValidators.birthDate),
  });

  sexOptions: IOption[] = sexFamilyMemberOptions;

  birthDateTooOldWarning: string;

  private _enableCreation = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private sharedService: SharedService,
  ) {
    this.actions$
      .pipe(ofType(CreateClientActionTypes.CreateClientSuccess), untilDestroyed(this))
      .subscribe((createdClientAction: CreateClientSuccess) => {
        this.sharedService
          .rememberClient({data: {sugarUuid: createdClientAction.payload.sugarUuid}})
          .subscribe(() =>
            this.clientCreated.emit(createdClientAction.payload.families[0].familyUuid),
          );
      });
  }

  ngOnInit() {
    merge(
      this.form.get('firstName').valueChanges,
      this.form.get('lastName').valueChanges,
      this.form.get('phoneNumber').valueChanges,
      this.form.get('email').valueChanges,
      this.form.get('birthNumber').valueChanges,
    )
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe(() => {
        this.change.emit(this.getFormValue());

        this.primaryButton = 'search';
        this.form.setValidators(this.searchValidator);
        this.setValidators(false);
        this.form.updateValueAndValidity();
      });

    this.form
      .get('birthNumber')
      .valueChanges.pipe(
        distinctUntilChanged<string>(isEqual),
        map(birthNumber => getSexAndBirthDate(birthNumber)),
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe(sexAndBirthDate => {
        this.form.patchValue(sexAndBirthDate);
      });

    this.form
      .get('birthNumber')
      .valueChanges.pipe(distinctUntilChanged<string>(isEqual), pairwise(), untilDestroyed(this))
      .subscribe(([oldBirthNumber, birthNumber]: [string, string]) => {
        const birthDate: Date = this.form.get('birthDate').value;
        this.birthDateTooOldWarning = getBirthDateWarning(oldBirthNumber, birthNumber, birthDate);
      });

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(_ => {
      this.errors.next(this.form.errors);
    });
  }

  onSearch() {
    this.primaryButton = 'search';
    this.form.setValidators(this.searchValidator);
    this.setValidators(false);
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.search.emit(this.getFormValue());
    }
  }

  onCreate() {
    this.primaryButton = 'create';

    this.setValidators(true);
    this.form.updateValueAndValidity();
    markFormGroupTouched(this.form);
    if (this.form.valid && (!this.foundByBirthNumber || this.isForeignerBirthNumber))
      this.createClientData();
  }

  getErrorMsg() {
    if (this.form.errors !== null) {
      const missingFields = this.form.errors.createNoFieldFiled
        ? 'Telefon a E-mail'
        : 'Příjmení, Telefon, E-mail a Rodné číslo';

      return createStringFromTemplate(ErrorMessages.oneOf, {missingFields});
    }

    return null;
  }

  ngOnDestroy() {}

  private getFormValue() {
    const form = this.form.getRawValue();
    Object.entries(form).forEach(([key, value]) => {
      if (value === '') form[key] = null;
    });
    return form as FindClient;
  }

  private createClientData() {
    this.create.emit({...trimBirthDateWithinObject(this.getFormValue())});
  }

  private setValidators(create: boolean) {
    const validators = create ? [Validators.required] : [];
    ['lastName', 'sex', 'birthDate'].forEach(name => {
      this.form.get(name).setValidators([...(this.controlValidators[name] || []), ...validators]);
      this.form.get(name).updateValueAndValidity({emitEvent: false});
    });
  }

  // validators when the would search a client
  private searchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const lastName = control.get('lastName').value;
    const phoneNumber = control.get('phoneNumber').value;
    const email = control.get('email').value;
    const birthNumber = control.get('birthNumber').value;

    return lastName || phoneNumber || email || birthNumber ? null : {searchNoFieldFiled: true};
  };
}
