import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {AssetType} from '@shared/analysis/models/asset';
import {objectiveAssetsDescription} from '@shared/analysis/objectives.helpers';

@Component({
  standalone: true,
  selector: 'kpt-objective-description',
  templateUrl: './objective-description.component.html',
  styleUrls: ['./objective-description.component.scss'],
  imports: [CommonModule],
})
export class ObjectiveDescriptionComponent {
  @Input() assetType: AssetType;

  showDescription = false;

  hasDescription(): boolean {
    return Boolean(objectiveAssetsDescription[this.assetType]);
  }

  getDescription(long = false) {
    const description = objectiveAssetsDescription[this.assetType];
    return long ? description.long : description.main;
  }
}
