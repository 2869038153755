import {Component, Input} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ktl-partner-logo',
  templateUrl: './partner-logo.component.html',
})
export class PartnerLogoComponent {
  @Input()
  size: string;

  @Input()
  partnerCode: string;

  get src() {
    const code = this.partnerCode || 'KAPITOL';
    return `${environment.poradceUrl}api/v1/public/download/${code}/partner-logo`;
  }
}
