import {calculateRepayment} from '@lib/utils';
import {
  buildingSavings as buildingSavingsFormula,
  futureValue as futureValueFormula,
  pensionInsurance as pensionInsuranceFormula,
  periodicPayment,
} from '@shared/analysis/formulas';
import {dateDiff, frequencyRate, getEnd, getPeriodsCount} from '@shared/analysis/helpers';
import {
  AmortizationFunction,
  AnalysisContext,
  BaseUnitFunction,
  BuildingSavingsFunction,
  ConstantFunction,
  CreditFeeFunction,
  FunctionType,
  FutureValueFunction,
  InstallmentFunction,
  LifeInsuranceFunction,
  LinearFunction,
  Liquidity,
  LossOfWorkFunction,
  PensionInsuranceFunction,
  SupplementaryPensionSavingsFunction,
  UnitFunction,
} from '@shared/analysis/models/unit';
import * as moment from 'moment';

// TODO add jsdoc
function constantValue(fn: ConstantFunction, _context: AnalysisContext): number {
  return fn.params.value;
}

// TODO add jsdoc
function linearValue(fn: LinearFunction, context: AnalysisContext): number {
  return context.periods * fn.params.slope + (fn.params.constantTerm || 0);
}

// TODO add jsdoc
function futureValue(fn: FutureValueFunction, context: AnalysisContext): number {
  const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
  return futureValueFormula(
    periodRate,
    context.periods,
    -fn.params.periodicPayment,
    -fn.params.presentValue,
  );
}

function lifeInsurance(fn: LifeInsuranceFunction, context: AnalysisContext): number {
  const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
  const periods = getPeriodsCount(
    moment(fn.start),
    moment(fn.params.futureValueDate),
    fn.frequency,
  );

  const periodicPaymentValue = periodicPayment(
    periodRate,
    periods,
    -fn.params.presentValue,
    fn.params.futureValue,
  );
  return futureValue(
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: fn.frequency,
      start: fn.start,
      params: {
        yearRate: fn.params.yearRate,
        presentValue: fn.params.presentValue,
        periodicPayment: periodicPaymentValue,
      },
    },
    context,
  );
}

// TODO add jsdoc
function lossOfWork(fn: LossOfWorkFunction, context: AnalysisContext): number {
  const workUnit = context.units.find(unit => unit.id === fn.params.workUnitId);
  if (!workUnit || workUnit.functions[0].type !== 'linear') return 0;

  const amount = (workUnit.functions[0] as LinearFunction).params.slope;
  const end = getEnd(fn, context.date);
  const workEnd = getEnd(workUnit.functions[0], context.date);

  if (end <= workEnd) {
    return -amount * context.periods;
  } else {
    const periods = getPeriodsCount(moment(fn.start), workEnd, fn.frequency);
    return -amount * periods;
  }
}

// TODO add jsdoc
function installment(fn: InstallmentFunction, context: AnalysisContext): number {
  const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
  const loanTerm = getTermLength(fn);
  return context.periods * calculateRepayment(fn.params.loan, periodRate, loanTerm);
}

// TODO add jsdoc
function amortization(fn: AmortizationFunction, context: AnalysisContext): number {
  if (context.periods === 0) return 0; // TODO: verify boundaries
  let totalAmortizations = 0;
  const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
  const loanTerm = getTermLength(fn);
  const loan = fn.params.loan;
  const repayment = calculateRepayment(loan, periodRate, loanTerm);
  let debt = loan;

  for (let month = 0; month < context.periods; month++) {
    const interest = debt * periodRate;
    const periodAmortization = repayment - interest;
    debt -= periodAmortization;
    totalAmortizations += periodAmortization;
  }

  return totalAmortizations;
}

function creditFee(fn: CreditFeeFunction, context: AnalysisContext): number {
  if (typeof fn.params.value === 'number' && fn.params.value > 0) {
    const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
    const loanTerm = getTermLength(fn);
    let repayment = calculateRepayment(fn.params.loan, periodRate, loanTerm);
    if (isNaN(repayment)) repayment = 0;
    const fee = fn.params.value - repayment;
    return context.periods * -fee;
  } else {
    return 0;
  }
}

function getTermLength(fn: BaseUnitFunction): number {
  return typeof fn.end === 'number' ? (fn.end as number) : dateDiff(fn.end, fn.start, fn.frequency);
}

function buildingSavings(fn: BuildingSavingsFunction, context: AnalysisContext): number {
  const periodRate = fn.params.yearRate * frequencyRate[fn.frequency];
  const initialMonth = moment(fn.start).month();
  const includeStateContribution = moment(fn.end).diff(moment(fn.start), 'years') >= 6 || !fn.end;

  return buildingSavingsFormula(
    periodRate,
    context.periods,
    fn.params.periodicPayment,
    fn.params.oneTimePayment,
    initialMonth,
    includeStateContribution,
    fn.frequency,
  );
}

function supplementaryPensionSavings(
  fn: SupplementaryPensionSavingsFunction,
  context: AnalysisContext,
): number {
  const periodRate = Math.pow(1 + fn.params.yearRate, frequencyRate[fn.frequency]) - 1;
  const stateContribution = computeStateContribution(fn.params.ownContribution);
  const periodicPaymentValue =
    fn.params.ownContribution + fn.params.employerContribution + stateContribution;
  return futureValueFormula(
    periodRate,
    context.periods,
    -periodicPaymentValue,
    -fn.params.oneTimePayment,
  );
}

function pensionInsurance(fn: PensionInsuranceFunction, context: AnalysisContext): number {
  const stateContribution = computeStateContribution(fn.params.ownContribution);
  const periodicPaymentValue = fn.params.ownContribution + fn.params.employerContribution;
  return pensionInsuranceFormula(
    fn.params.yearRate,
    context.periods,
    periodicPaymentValue,
    fn.params.oneTimePayment,
    stateContribution,
  );
}

function computeStateContribution(ownContribution: number): number {
  return ownContribution >= 300
    ? 90 + (ownContribution >= 1000 ? 700 : ownContribution - 300) * 0.2
    : 0;
}

export type FunctionTypeFn = (fn: UnitFunction, context: AnalysisContext) => number;

export const functionTable: Record<FunctionType, FunctionTypeFn> = {
  constant: constantValue,
  linear: linearValue,
  futureValue,
  lossOfWork,
  installment,
  amortization,
  creditFee,
  lifeInsurance,
  buildingSavings,
  supplementaryPensionSavings,
  pensionInsurance,
};
