import {Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldWrapper} from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'kpt-captioned-input-formly-wrapper',
  templateUrl: './captioned-input-formly-wrapper.component.html',
  styleUrls: ['./captioned-input-formly-wrapper.component.scss'],
})
export class CaptionedInputFormlyWrapperComponent extends FieldWrapper {}
