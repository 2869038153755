import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SharedService} from '@generated/controllers/Shared';
import {LoginService} from '@lib/services';
import {Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {ModalService} from '@shared/lib/components/modal';
import * as moment from 'moment/moment';
import {Observable, of} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(
    private store: Store<State>,
    private sharedService: SharedService,
    private loginService: LoginService,
    private modalService: ModalService,
  ) {}

  getPdfLink(
    familyUuid: string,
    accessToken: string,
    type: string,
    outputFilename: string,
  ): string {
    const filename = encodeURIComponent(outputFilename);
    const queryParams = new HttpParams().set('type', type).set('access_token', accessToken);
    return `${
      environment.apiBaseUrl
    }/api/shared/get-pdf/${familyUuid}/${filename}?${queryParams.toString()}`;
  }

  print$(
    familyUuid: string,
    address: string,
    linkType: string,
    outputFilename: string,
    pdfOption?: string,
  ): Observable<string> {
    if (environment.name === 'local') return of(address);
    return this.sharedService
      .generatePdf({
        family_uuid: familyUuid,
        data: {address, pdfOption},
      })
      .pipe(
        switchMap(() => this.loginService.accessToken),
        map(accessToken => this.getPdfLink(familyUuid, accessToken, linkType, outputFilename)),
        first(),
      );
  }

  openPdf(pdfUrl: string) {
    if (!window.open(pdfUrl, '_blank')) {
      this.modalService.openModal({
        component: 'PopUpBlockedModalComponent',
        data: {
          url: pdfUrl,
        },
      });
    }
  }

  getPdfName(createNameFn: (name: string, date: string) => string): Observable<string> {
    return this.store.pipe(
      selectFamilyHead(),
      map(familyHead => {
        const name = `${familyHead.firstName} ${familyHead.lastName}`;
        const date = moment().format('DD.MM.YYYY');
        return createNameFn(name, date);
      }),
    );
  }
}
