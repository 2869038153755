import {IStepperProgressItem} from '@shared/ui';
import produce from 'immer';
import {SecuredPage} from 'src/app/layouts/secured';
// eslint-disable-next-line max-len
import {aboutKapitolCurrentStepperProgressConfig} from 'src/app/modules/about-kapitol/about-kapitol-page.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';

// eslint-disable-next-line max-len
import {consumerLoansStepperProgressConfig} from 'src/app/modules/consumer-loans/consumer-loans-page.config';
import {
  financialAnalysisStepperProgressConfig as financialAnalysisStepperProgressConfig,
  simpleFinancialAnalysisStepperProgressConfig,
} from 'src/app/modules/financial-analysis/financial-analysis-page.config';
import {financialPlanStepperProgressConfig} from 'src/app/modules/financial-plan/financial-plan-page.config';
import {goldConfig} from 'src/app/modules/gold/gold-page.component.config';
import {housingStepperProgressConfig} from 'src/app/modules/housing/housing-page.config';
import {investmentStepperProgressConfig} from 'src/app/modules/investment/investment-page.config';
import {investmentStepperProgressConfig as investmentStepperProgressConfigOld} from 'src/app/modules/investment-old/investment.component.config';
import {lifeInsuranceStepperProgressConfig} from 'src/app/modules/life-insurance/life-insurance-page.config';
import {lifeInsuranceStepperProgressConfigOld} from 'src/app/modules/life-insurance-old/life-insurance-page.config';
import {pensionStepperProgressConfig} from 'src/app/modules/pension/pension-page.config';
import {pensionStepperProgressConfigOld} from 'src/app/modules/pension-old/pension-page.config';
import {propertyInsuranceStepperProgressConfig} from 'src/app/modules/property-insurance/property-insurance-page.config';
import {vehicleInsuranceStepperProgressConfig} from 'src/app/modules/vehicle-insurance/vehicle-insurance-page.config';
import {insertPlaceholders} from 'src/shared/ui/stepper-progress/stepper-progress.utils';
import {StepperAction, StepperActions} from '../actions/stepper.actions';

// export type Stepper = SecuredPage | ClientPage;

// client/sub pages first
const configs = {
  [ClientPage.FinancialAnalysis]: financialAnalysisStepperProgressConfig,
  [ClientPage.SimpleFinancialAnalysis]: simpleFinancialAnalysisStepperProgressConfig,
  [ClientPage.FinancialPlan]: financialPlanStepperProgressConfig,
  [ClientPage.Gold]: goldConfig,
  [ClientPage.Housing]: housingStepperProgressConfig,
  [ClientPage.LifeInsuranceOld]: lifeInsuranceStepperProgressConfigOld,
  [ClientPage.LifeInsurance]: lifeInsuranceStepperProgressConfig(null),
  [ClientPage.ConsumerLoan]: consumerLoansStepperProgressConfig,
  [ClientPage.PensionOld]: pensionStepperProgressConfigOld,
  [ClientPage.Pension]: pensionStepperProgressConfig(null),
  [ClientPage.InvestmentOld]: investmentStepperProgressConfigOld,
  [ClientPage.Investment]: investmentStepperProgressConfig(null),
  [ClientPage.VehicleInsurance]: vehicleInsuranceStepperProgressConfig(null),
  [ClientPage.PropertyInsurance]: propertyInsuranceStepperProgressConfig(null),
  [SecuredPage.AboutKapitol]: aboutKapitolCurrentStepperProgressConfig,
};

export type Stepper = keyof typeof configs;

export const steppers: Stepper[] = Object.keys(configs) as Stepper[];
const steppersRegExps: RegExp[] = steppers.map(s => {
  const pattern = `((^${s}$)|(/${s}$))`;
  return new RegExp(pattern, 'm');
});

export type StepperState = Partial<Record<Stepper, IStepperProgressItem[]>> & {
  current: Stepper;
};

export const initialStepperState: StepperState = {
  current: null,
  ...configs,
};

function getStepperByUrl(url: string): Stepper {
  url = url.substring(0, url.lastIndexOf('/'));
  return steppers[steppersRegExps.findIndex(regExp => url.match(regExp))];
}

const producer = (state = initialStepperState, action: StepperAction) =>
  StepperActions.match(action, {
    ST_UpdateConfig: ({stepper, config}) => {
      state[stepper] = config;
    },
    ST_UpdateCurrent: ({url}) => {
      state.current = getStepperByUrl(url) ?? null;
    },
    ST_MarkStepDone: ({url, params}) => {
      url = insertPlaceholders(url, params);
      const stepper = getStepperByUrl(url);
      const step = state[stepper]?.find(s => s?.url === url);
      if (step) step.done = true;
    },
    default: () => {},
  });

export function reducer(state = initialStepperState, action: StepperAction): StepperState {
  return produce(producer)(state, action);
}
