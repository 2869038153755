import {
  allInvestmentAssets,
  AssetType,
  basicInvestmentAssets,
  creditAssets,
  immovablePropertyAssets,
  insuranceAssets,
  movablePropertyAssets,
  pensionInvestmentAssets,
} from 'src/shared/analysis/models/asset';

export enum ObjectiveType {
  Family = 'family',
  Property = 'property',
  Vehicle = 'vehicle',
  Reserve = 'reserve',
  Housing = 'housing',
  Pension = 'pension',
  Independence = 'independence',
  FinancialResources = 'financialResources',
  AdditionalContracts = 'additionalContracts',
}

export enum ObjectiveSource {
  FinancialAnalysis = 'financialAnalysis',
  LifeInsurance = 'lifeInsurance',
}

export const objectiveTypeNames: Record<ObjectiveType, string> = {
  [ObjectiveType.Family]: 'Zajištění rodiny',
  [ObjectiveType.Property]: 'Zajištění majetku',
  [ObjectiveType.Vehicle]: 'Pojištění vozidel',
  [ObjectiveType.Reserve]: 'Tvorba rezerv',
  [ObjectiveType.Housing]: 'Bydlení',
  [ObjectiveType.Pension]: 'Zajištění na penzi',
  [ObjectiveType.Independence]: 'Finanční nezávislost',
  [ObjectiveType.FinancialResources]: 'Účelové prostředky',
  [ObjectiveType.AdditionalContracts]: 'Další smlouvy',
};

/** what asset types does one care about when creating fin. plan */
export const objectiveSourceAssets: Record<ObjectiveType, AssetType[]> = {
  [ObjectiveType.Family]: [AssetType.FamilyProvision],
  [ObjectiveType.Property]: [AssetType.PropertyProvision],
  [ObjectiveType.Vehicle]: [AssetType.Car],
  [ObjectiveType.Reserve]: [AssetType.LongTermReserve, AssetType.ShortTermReserve],
  [ObjectiveType.Housing]: [AssetType.OwnHousing],
  [ObjectiveType.Pension]: [AssetType.Retirement],
  [ObjectiveType.Independence]: [AssetType.FinancialIndependence],
  [ObjectiveType.FinancialResources]: [
    AssetType.ChildrenFuture,
    AssetType.ChildBirth,
    AssetType.BadDebtsRepayment,
    AssetType.NewHousing,
    AssetType.Reconstruction,
    AssetType.Furnishings,
    AssetType.Car,
    AssetType.Vacation,
    AssetType.OtherObjective,
  ],
  [ObjectiveType.AdditionalContracts]: [
    ...insuranceAssets,
    ...allInvestmentAssets,
    ...creditAssets,
  ],
};

export enum ObjectiveImage {
  CAR = '/assets/images/financial-plan/car.svg',
  CHILDREN = '/assets/images/financial-plan/children.svg',
  FURNISHINGS = '/assets/images/financial-plan/furnishings.svg',
  GENERAL_RESERVE = '/assets/images/financial-plan/savings.svg',
  INCOME_PROVISION = '/assets/images/financial-plan/reserves.svg',
  INDEPENDENCE = '/assets/images/financial-plan/independence.svg',
  NEW_HOUSING = '/assets/images/financial-plan/new-housing.svg',
  PENSION = '/assets/images/financial-plan/pension.svg',
  PROPERTY_PROTECTION = '/assets/images/financial-plan/property.svg',
  RECONSTRUCTION = '/assets/images/financial-plan/reconstruction.svg',
  VACATION = '/assets/images/financial-plan/vacation.svg',
}

export const objectiveImageGroups: Record<ObjectiveImage, AssetType[]> = {
  [ObjectiveImage.CAR]: [AssetType.Car],
  [ObjectiveImage.CHILDREN]: [AssetType.ChildrenFuture],
  [ObjectiveImage.FURNISHINGS]: [AssetType.Furnishings],
  [ObjectiveImage.GENERAL_RESERVE]: [AssetType.LongTermReserve],
  [ObjectiveImage.INCOME_PROVISION]: [
    AssetType.FamilyProvision,
    AssetType.PropertyProvision,
    AssetType.VehicleProvision,
  ],
  [ObjectiveImage.INDEPENDENCE]: [
    AssetType.FinancialIndependence,
    AssetType.OtherObjective,
    AssetType.BadDebtsRepayment,
    AssetType.ShortTermReserve,
  ],
  [ObjectiveImage.NEW_HOUSING]: [AssetType.NewHousing, AssetType.OwnHousing],
  [ObjectiveImage.PENSION]: [AssetType.Retirement],
  [ObjectiveImage.PROPERTY_PROTECTION]: [...immovablePropertyAssets, ...movablePropertyAssets],
  [ObjectiveImage.RECONSTRUCTION]: [AssetType.Reconstruction],
  [ObjectiveImage.VACATION]: [AssetType.Vacation],
};

/** what asset types could be added to a financial plan objective */
export function getObjectiveProductAssets(group: ObjectiveType): AssetType[] {
  switch (group) {
    case ObjectiveType.Family:
      return [
        AssetType.RiskLifeInsurance,
        AssetType.CapitalLifeInsurance,
        AssetType.InvestmentLifeInsurance,
      ];
    case ObjectiveType.Property: {
      return [AssetType.PropertyInsurance];
    }
    case ObjectiveType.Vehicle: {
      return [AssetType.VehicleInsurance];
    }
    case ObjectiveType.Reserve:
      return [
        ...basicInvestmentAssets,
        AssetType.InvestmentLifeInsurance,
        AssetType.CapitalLifeInsurance,
        AssetType.CurrentAccountUsage,
      ];
    case ObjectiveType.Housing:
      return [...basicInvestmentAssets, AssetType.CurrentAccountUsage];
    case ObjectiveType.Pension:
      return [
        ...basicInvestmentAssets,
        ...pensionInvestmentAssets,
        AssetType.InvestmentLifeInsurance,
        AssetType.CapitalLifeInsurance,
        AssetType.CurrentAccountUsage,
      ];
    case ObjectiveType.Independence:
      return [
        ...basicInvestmentAssets,
        ...pensionInvestmentAssets,
        AssetType.InvestmentLifeInsurance,
        AssetType.CapitalLifeInsurance,
        AssetType.CurrentAccountUsage,
      ];
    case ObjectiveType.FinancialResources:
      return [...basicInvestmentAssets, AssetType.CurrentAccountUsage];
    case ObjectiveType.AdditionalContracts:
      return [...insuranceAssets, ...allInvestmentAssets, ...creditAssets];
    default:
      return [];
  }
}

export interface ObjectiveAssetDescription {
  main: string;
  long: string;
}

/* eslint-disable max-len */
export const objectiveAssetsDescription: Partial<Record<AssetType, ObjectiveAssetDescription>> = {
  [AssetType.OwnHousing]: {
    main: '“Pokud nebydlíte ve vlastním, dáváte peníze někomu cizímu”',
    long:
      'Lidé si při pořízení vlastního bydlení nejvíce bojí toho, že onemocní, přijdou o práci a nebudou moct splácet hypotéku. To je samozřejmě oprávněné, ale je potřeba si uvědomit, že stejné obavy byste měli mít i při bydlení v nájmu. Jakmile nebudete moci splácet nájemné, nemáte kde bydlet. Proto v tomto případě doporučuje držet se pravidla č.1 zajistit rodinu a majetek. Hlavní rozdíl je v tom, že po třiceti letech v nájmu jste pořád v cizím, kdežto po 30 letech hypotéky vlastníte nemovitost i majetek.\n' +
      'Pokud se vaše měsíční výdaje v rámci nájemného alespoň rovnají měsíční splátce hypotéky a vy už máte představa o tom, “kde a jak se chcete usadit” a nebo o tom začínáte uvažovat, vyřešte si raději vlastní bydlení. Je třeba zmínit, že vyřešení bydlení je jednou z hlavních životních priorit a vyžaduje dlouhodobou přípravu a finanční plánování.',
  },
  [AssetType.Retirement]: {
    main: '“Jedinou finanční jistotou na stáří je tvoření vlastních rezerv.”',
    long: 'Lidé mají  rádi finanční jistotu a přesto často podceňují dlouhodobé tvoření rezerv na stáří. Co se týče penze od státu, tam si díky její proměnlivosti nemůžeme být jistí, že naplní vaše očekávání. Jedinou finanční jistotu na stáří získají ti, kteří si na důchod tvoří rezervy sami. KAPITOL radí - začněte co nejdříve, neboť když začnete 4 roky před důchodem a požadujete měsíční rentu 20 000 kč, museli byste buď každý rok ušetřit milion nebo každý měsíc odkládat 80 000 KČ. Když ale začnete brzy a budete investovat pravidelně, budete měsíčně potřebovat na tento cíl mnohem menší částky (v řádu pár tisíců); investovat navíc můžete už od 500 měsíčně.',
  },
  [AssetType.FinancialIndependence]: {
    main: '“Každý milion aktiv generuje 5 tisíc měsíční renty.”',
    long:
      '&#8226;&nbsp;Tvorba rezerv je jednou z nejdůležitějších částí finanční pohody. Na tvorbu rezerv v horizontu desítek let jsou nejvhodnější pravidelné investice. Čím dříve začnete, tím “míň se na to nadřete”. Zde jde hlavně o čas a o následné úroky z úroků, čas zde hraje ve váš prospěch. Ideální je si odkládat několik tisíc měsíčně.\n\n' +
      '&#8226;&nbsp;Pokud nic nenecháte náhodě a na aktiva budete investovat 30 let pravidelně 3000 Kč, můžete si nastřádat přes 3 miliony Kč. Je zde obecné pravidlo - každý milion v rentě mi generuje 5 tisíc měsíčně - pro rentu 20 000 potřebuji aktiva v hodnotě 4 milionů. Vždy je vhodné mít v portfoliu zastoupeny konzervativní, nemovitostní a akciové investice, které v průběhu času měníte, respektive čím jste starší, tím více zkonvervativňujete portfolio.',
  },
  [AssetType.ShortTermReserve]: {
    main: '“Ideální rezerva by se měla rovnat vašim měsíčním výdajům”',
    long: 'Během rutinního provozu domácnosti se může stát spousta nečekaných událostí, jako rozbitá pračka, televize, rozbije se vám auto, havarijní situace týkající se vašeho bydlení jako kotel, aj. Pro tyto nečekané výdaje je dobré mít vytvořenou rezervu, kterou máte k dispozici na účtu s okamžitým přístupem. Pak nemusíte hradit tyto výdaje pomocí špatných dluhů jako jsou kreditky, debety aj. či, a nemusíte ani rušit dlouhodobou strategickou investici. KAPITOL doporučuje mít na peněžním účtu 3násobek výdajů rodiny. Avšak už i s jednou měsíční rezervou navíc se budete cítit mnohem lépe, než když budete fungovat “od výplaty k výplatě”',
  },
  [AssetType.FamilyProvision]: {
    main: '“Není pojištění jako pojištění”',
    long: 'V životě nás ohrožuje spousta faktorů, které mohou ohrozit “zdravé cashflow” vaší rodiny. Je tedy dobré být opatrný, což v tomto případě znamená zabezpečit se pro případ nečekaných událostí. KAPITOL vám doporučuje sjednat si kvalitní (ne drahé!) pojištění, a to jak pro případy škod na majetku, tak na zdraví. Doporučujeme nezapomínat na pojištění invalidity, na což většina čechů zapomíná a to i zejména ti, kteří mají vyšší příjmy a neměli by toto pojištění podceňovat. Rizikové životní pojištění je ideální s klesající pojistnou částkou. Ještě je dobré zmínit, že dražší pojištění s letovým salonkem v ceně nutně negarantuje lepší pojistné plnění.',
  },
  [AssetType.PropertyProvision]: {
    main: '“Není pojištění jako pojištění”',
    long: 'V životě nás ohrožuje spousta faktorů, které mohou ohrozit “zdravé cashflow” vaší rodiny. Je tedy dobré být opatrný, což v tomto případě znamená zabezpečit se pro případ nečekaných událostí. KAPITOL vám doporučuje sjednat si kvalitní (ne drahé!) pojištění, a to jak pro případy škod na majetku, tak na zdraví. Doporučujeme nezapomínat na pojištění invalidity, na což většina čechů zapomíná a to i zejména ti, kteří mají vyšší příjmy a neměli by toto pojištění podceňovat. Rizikové životní pojištění je ideální s klesající pojistnou částkou. Ještě je dobré zmínit, že dražší pojištění s letovým salonkem v ceně nutně negarantuje lepší pojistné plnění.',
  },
  [AssetType.VehicleProvision]: {
    main: '“Není pojištění jako pojištění”',
    long: 'V životě nás ohrožuje spousta faktorů, které mohou ohrozit “zdravé cashflow” vaší rodiny. Je tedy dobré být opatrný, což v tomto případě znamená zabezpečit se pro případ nečekaných událostí. KAPITOL vám doporučuje sjednat si kvalitní (ne drahé!) pojištění, a to jak pro případy škod na majetku, tak na zdraví. Doporučujeme nezapomínat na pojištění invalidity, na což většina čechů zapomíná a to i zejména ti, kteří mají vyšší příjmy a neměli by toto pojištění podceňovat. Rizikové životní pojištění je ideální s klesající pojistnou částkou. Ještě je dobré zmínit, že dražší pojištění s letovým salonkem v ceně nutně negarantuje lepší pojistné plnění.',
  },
  [AssetType.BadDebtsRepayment]: {
    main: '“Pořizujte pouze podstatné věci, ne rozmary”',
    long:
      'Všeobecně je dobré si vyhodnotit, jak draze a k jakému účelu si peníze půjčujeme. Špatná rozhodnutí vás a vaší rodinu dokážou dostat mrknutím oka do velkých nepříjemností. Půjčku na dovolenou, na které jste týden potom můžete splácet rok či dva, či více. Už po krátké době po dovolené si uvědomíte, že to nestálo za to. Drahý telefon na splátkový prodej,  či půjčka na dovolenou, kterou budete splácet několik měsíců či let vám sice udělají chvilkovou radost, ale ve výsledku to může nadrobit více škody, než užitku.\n' +
      'Pokud dluhy tohoto typu už máte, snažte se jich zbavit co nejdříve. Psychologická výhra, kterou pocítíte při prvním splaceném dluhu vám výrazně pomůže v tom dalším. A následně uvidíte, jaký to bude mít úžasný vliv na vaše měsíční zdroje, které pak můžete cílit na opravdu důležité životní plány.\n\n' +
      'Přejeme hodně štěstí!',
  },
};
/* eslint-enable max-len */
