import {Component, OnDestroy} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {selectFinancialAnalysisDataChanged} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-analysis-changed-alert',
  templateUrl: './analysis-changed-alert.component.html',
})
export class AnalysisChangedAlertComponent implements OnDestroy {
  analysisDataChanged$ = this.store.pipe(
    select(selectFinancialAnalysisDataChanged),
    take(1),
    untilDestroyed(this),
  );
  analysisDataChangedLabel =
    'Data ve finanční analýze byla změněna.' +
    ' Prosíme zkontrolujte si nastavení pojistných částek a rizika, která chce klient zajistit.';

  constructor(private store: Store<State>) {}

  ngOnDestroy() {
    this.store.dispatch(LifeInsuranceActions.setFinancialAnalysisChangeFlag({changed: false}));
  }
}
