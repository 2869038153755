import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {RelatedAssetHeaderComponent} from '@shared/analysis/asset-types/common/related-asset-header/related-asset-header.component';
import {RiskListInputComponent} from '@shared/analysis/asset-types/common/risk-list-input/risk-list-input.component';
import {VehicleRequirementsCommonComponent} from '@shared/analysis/asset-types/common/vehicle-requirements-common/vehicle-requirements-common.component';
import {VehicleRequirementsAsset} from '@shared/analysis/models/objectives';
import {ButtonModule, FormModule, HelpModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@Component({
  standalone: true,
  selector: 'kpt-vehicle-requirements-form',
  templateUrl: './vehicle-requirements-form.component.html',
  styleUrls: ['./vehicle-requirements-form.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    ButtonModule,
    SwitchModule,
    FlexboxModule,
    RiskListInputComponent,
    RelatedAssetHeaderComponent,
    HelpModule,
    VehicleRequirementsCommonComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VehicleRequirementsFormComponent {
  @Input() asset: VehicleRequirementsAsset;
  @Input() disabled = false;
  @Input() showRelatedAssetHeader: boolean;

  constructor() {}
}
