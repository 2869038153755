import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {TabsModule} from '@shared/ui';
import {RelatedAssetHeaderFormlyComponent} from '@shared/ui/formly/related-asset-header-formly/related-asset-header-formly.component';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';

@NgModule({
  declarations: [RelatedAssetHeaderFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    TabsModule,
  ],
  exports: [RelatedAssetHeaderFormlyComponent],
})
export class RelatedAssetHeaderFormlyModule {}
