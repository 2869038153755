import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AttachedFileTileComponent} from './attached-file-tile.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AttachedFileTileComponent],
  exports: [AttachedFileTileComponent],
})
export class AttachedFileTileModule {}
