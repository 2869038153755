import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';
import {SwitchComponent} from '@shared/ui/forms/switch/switch.component';

@NgModule({
  declarations: [SwitchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FieldErrorModule,
    MatSlideToggleModule,
    FlexboxModule,
  ],
  exports: [SwitchComponent],
})
export class SwitchModule {}
