import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FAMILY_ID} from '@shared/constants';
import {readFamilyIdFromUrl} from '@shared/utils/client';

@Component({
  template: `
    <router-outlet></router-outlet>
    <router-outlet name="assetDetail"></router-outlet>
    <router-outlet name="modal"></router-outlet>

    <kpt-guard></kpt-guard>
  `,
  providers: [
    {
      provide: FAMILY_ID,
      useFactory: readFamilyIdFromUrl,
      deps: [ActivatedRoute],
    },
  ],
})
export class ClientContainerComponent {}
