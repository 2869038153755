<div class="graph-legend-holder">
  <ul class="graph-legend">
    <li class="graph-legend__item graph-legend__item--incomes">Příjmy</li>
    <li class="graph-legend__item graph-legend__item--costs">Výdaje</li>
    <li class="graph-legend__item graph-legend__item--solution">
      <span class="icon icon--bulb graph-legend__item-icon"></span>
      Řešení
    </li>
  </ul>
</div>
