import {produce} from 'immer';
import {cloneDeep, get, merge, reject, union, uniqBy} from 'lodash';
import {
  computeRecommendedInsuranceAmount,
  setDefaultExpandedForms,
} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store/index';
import {
  defaultExpandedForm,
  findPerson,
  findUniqueRisk,
  getDefaultAge,
  getOrderForAlternativeForm,
  newGroupForm,
  newRisk,
  nextAlternativeSetId,
  nextContractId,
  nextId,
  nextLinkId,
  selectRisk,
  updateIndividualSelection,
  updateLinkedRiskForm,
} from 'src/app/modules/life-insurance-old/store/life-insurance.helpers';
import {
  LifeInsuranceForm,
  LifeInsuranceFormGroup,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  adultExpensesRiskIds,
  adultIncomeRiskIds,
  riskDefinitions,
  riskIdsByProvision,
} from 'src/app/services/risk-definitions';
import {Provision} from 'src/store/models/risk.models';

import {RiskSelection} from '../1-insurance-protection/risk-list/risk-list.component';
import {LifeInsuranceActionsUnion} from './life-insurance.actions';
import {initialChoice, initialState, LifeInsuranceState} from './life-insurance.state';

const lifeInsuranceReducer = produce(
  (state: LifeInsuranceState, action: LifeInsuranceActionsUnion) => {
    switch (action.type) {
      case LifeInsuranceActions.loadData.type: {
        state.persons = action.data.persons;
        state.forms = action.data.forms;
        state.risks = action.data.risks;
        state.choice = action.data.choice;
        state.files = action.data.files;
        state.customRiskDefinitions = action.data.definitions;
        state.ui.showInsuranceCalculations = action.data.risks.length > 0;
        state.ui.expandedForm = setDefaultExpandedForms(action.data.persons, state.ui.expandedForm);

        break;
      }

      case LifeInsuranceActions.loadSummary.type: {
        state.summaries = action.summaries;
        break;
      }

      case LifeInsuranceActions.updatePersons.type: {
        const oldRecommendedInsurance = computeRecommendedInsuranceAmount(state.persons);

        for (const newPerson of action.persons) {
          const person = state.persons.find(p => p.id === newPerson.id);

          person.age = newPerson.age;
          person.income = newPerson.income;
          person.incomeContractor = newPerson.incomeContractor;
          person.passiveIncome = newPerson.passiveIncome;
          person.expenses = newPerson.expenses;
          person.savings = newPerson.savings;
          person.debts = action.debts ? action.debts : newPerson.debts;
          person.debtsTogether = action.debtsTogether;
        }

        const newRecommendedInsurance = computeRecommendedInsuranceAmount(state.persons);
        if (state.choice.amount === null || state.choice.amount === oldRecommendedInsurance) {
          state.choice.amount = newRecommendedInsurance;
        }
        break;
      }

      case LifeInsuranceActions.setPersons.type: {
        state.persons = action.persons;
        break;
      }

      case LifeInsuranceActions.resetInput.type: {
        state.persons = action.persons;
        state.forms = [];
        state.risks = [];
        state.customRiskDefinitions = [];
        state.choice = initialChoice;
        state.ui.showInsuranceCalculations = false;
        state.ui.expandedForm = setDefaultExpandedForms(action.persons, {});

        break;
      }

      case LifeInsuranceActions.calculateInsurance.type: {
        if (!state.choice.amount) {
          state.choice.amount = computeRecommendedInsuranceAmount(state.persons);
        }
        state.ui.showInsuranceCalculations = true;
        break;
      }

      case LifeInsuranceActions.selectRisk.type: {
        selectRisk(action as RiskSelection, state);
        break;
      }

      case LifeInsuranceActions.selectRisks.type: {
        if (!action.membersRisks) break;
        const uniqueAdultRisks = union(adultIncomeRiskIds, adultExpensesRiskIds);
        const uniqueRisksDefinitions = uniqBy(riskDefinitions, 'id').filter(r => !r.onlyProposals);
        const filteredAdultRisksDefinitions = uniqueRisksDefinitions.filter(r =>
          uniqueAdultRisks.includes(r.id),
        );

        Object.keys(action.membersRisks).forEach(personId => {
          const personValues = get(action.membersRisks, personId);
          filteredAdultRisksDefinitions.forEach(r => {
            const previousRisk = state.risks.find(
              originalRisk => originalRisk.personId === personId && originalRisk.riskId === r.id,
            );
            const riskValues = personValues[r.id];
            if (!riskValues) {
              // when you add child to family, some riskValues are not present here
              return;
            }

            if (riskValues.income || riskValues.expenses) {
              const provision = riskValues.income ? Provision.Income : Provision.Expenses;
              const selectedRisk: RiskSelection = {
                riskId: r.id,
                personId,
                provision,
              };
              if ((previousRisk && previousRisk.provision !== provision) || !previousRisk) {
                updateIndividualSelection(personId, true, state);

                selectRisk(selectedRisk, state);
              }
            } else if (previousRisk) {
              updateIndividualSelection(personId, true, state);
              selectRisk(
                {
                  riskId: r.id,
                  personId,
                  provision: previousRisk.provision,
                },
                state,
              );
            }
          });
        });
        break;
      }

      case LifeInsuranceActions.updateIndividualRisks.type: {
        state.risks = state.risks.filter(stateRisk => !findUniqueRisk(stateRisk, action.zeroRisks));
        break;
      }

      case LifeInsuranceActions.selectProvision.type: {
        const customRiskDefIds = state.customRiskDefinitions
          .filter(def => def.personId === action.personId)
          .map(def => def.id);

        state.risks = reject(
          state.risks,
          r => r.personId === action.personId && r.myChoice && !customRiskDefIds.includes(r.riskId),
        );

        const newRisks = riskIdsByProvision[action.provision].map(riskId =>
          newRisk(action.personId, riskId, action.provision, state),
        );

        state.risks.push(...newRisks);
        break;
      }

      case LifeInsuranceActions.changeIndividualSelection.type: {
        updateIndividualSelection(action.personId, action.selected, state);
        break;
      }

      case LifeInsuranceActions.updateReservesOrPension.type: {
        const person = findPerson(action.personId, state);
        merge(person, action.changes);
        break;
      }

      case LifeInsuranceActions.updateAge.type: {
        const risk = state.risks.find(
          r => r.personId === action.personId && r.riskId === action.riskId,
        );
        if (risk) risk.age = action.age;
        break;
      }

      case LifeInsuranceActions.updateChoice.type: {
        state.choice = action.choice;
        break;
      }

      case LifeInsuranceActions.addCustomRisk.type: {
        const personId = action.personId;
        const orders = state.customRiskDefinitions
          .filter(def => def.personId === personId)
          .map(def => def.order);
        const newOrder = nextId(orders);
        const ids = state.customRiskDefinitions
          .map(def => def.id)
          .map(id => Number(id.replace('custom-', '')));
        const newId = nextId(ids);

        const customRiskId = `custom-${newId}`;
        state.customRiskDefinitions.push({
          id: customRiskId,
          personId,
          label: '',
          myChoice: action.myChoice,
          order: newOrder,
        });

        state.risks.push(newRisk(personId, customRiskId, Provision.Custom, state));
        break;
      }

      case LifeInsuranceActions.deleteCustomRisk.type: {
        state.customRiskDefinitions = reject(
          state.customRiskDefinitions,
          def => def.id === action.customRiskDefId,
        );
        state.risks = reject(state.risks, r => r.riskId === action.customRiskDefId);
        break;
      }

      case LifeInsuranceActions.updateCustomRisk.type: {
        const customRiskDef = state.customRiskDefinitions.find(
          def => def.id === action.customRiskDefId,
        );
        const risk = state.risks.find(r => r.riskId === action.customRiskDefId);
        if (action.label !== undefined) customRiskDef.label = action.label;
        if (action.value !== undefined) risk.value = action.value;
        if (action.age !== undefined) risk.age = action.age;
        break;
      }

      case LifeInsuranceActions.toggleInsuranceGroup.type: {
        const expanded = state.ui.expandedForm;
        expanded[action.personId] = expanded[action.personId] || defaultExpandedForm();
        expanded[action.personId][action.group] = !expanded[action.personId][action.group];
        break;
      }

      case LifeInsuranceActions.updateCurrentInsurance.type: {
        findPerson(action.personId, state).currentInsurance = action.currentInsurance;
        break;
      }

      case LifeInsuranceActions.addCurrentGroupForm.type: {
        const personId = action.personId;
        const linkId = nextLinkId(state);
        state.forms.push(
          newGroupForm(personId, LifeInsuranceFormGroup.Current, linkId, false, false, state),
        );
        state.forms.push(
          newGroupForm(personId, LifeInsuranceFormGroup.Recommended, linkId, false, false, state),
        );
        state.forms.push(
          newGroupForm(personId, LifeInsuranceFormGroup.Final, linkId, false, false, state),
        );
        break;
      }

      case LifeInsuranceActions.addRecommendedGroupForm.type: {
        const personId = action.personId;
        const linkId = nextLinkId(state);
        const contractId = nextContractId(state);

        const newRecommendedForm = newGroupForm(
          personId,
          LifeInsuranceFormGroup.Recommended,
          linkId,
          true,
          true,
          state,
        );
        newRecommendedForm.stakeholderId = action.stakeholderId;
        newRecommendedForm.contractId = contractId;
        state.forms.push(newRecommendedForm);

        const newFinalForm = newGroupForm(
          personId,
          LifeInsuranceFormGroup.Final,
          linkId,
          true,
          true,
          state,
        );
        newFinalForm.stakeholderId = action.stakeholderId;
        newFinalForm.contractId = contractId;
        state.forms.push(newFinalForm);
        break;
      }

      case LifeInsuranceActions.addAlternativeInsuranceGroupForm.type: {
        const personId = action.personId;
        const linkId = nextLinkId(state);
        const contractId = nextContractId(state);

        const originalRecommendedForm = state.forms.find(f => f.id === action.alternativeToId);
        const originalFinalForm = state.forms.find(
          f =>
            f.personId === personId &&
            f.group === LifeInsuranceFormGroup.Final &&
            f.linkId === originalRecommendedForm.linkId,
        );

        const newRecommendedForm = newGroupForm(
          personId,
          LifeInsuranceFormGroup.Recommended,
          linkId,
          true,
          true,
          state,
        );
        state.forms.push(newRecommendedForm);

        const newFinalForm = newGroupForm(
          personId,
          LifeInsuranceFormGroup.Final,
          linkId,
          true,
          true,
          state,
        );
        state.forms.push(newFinalForm);

        newRecommendedForm.stakeholderId = action.stakeholderId;
        newRecommendedForm.contractId = contractId;
        newFinalForm.stakeholderId = action.stakeholderId;
        newFinalForm.contractId = contractId;

        if (!originalRecommendedForm.alternativeSetId) {
          originalRecommendedForm.alternativeSetId = nextAlternativeSetId(state);
        }
        const alternativeSetId = originalRecommendedForm.alternativeSetId;
        newRecommendedForm.alternativeSetId = alternativeSetId;
        originalFinalForm.alternativeSetId = alternativeSetId;
        newFinalForm.alternativeSetId = alternativeSetId;

        newRecommendedForm.order = getOrderForAlternativeForm(
          personId,
          LifeInsuranceFormGroup.Recommended,
          alternativeSetId,
          state,
        );
        newFinalForm.order = getOrderForAlternativeForm(
          personId,
          LifeInsuranceFormGroup.Final,
          alternativeSetId,
          state,
        );

        break;
      }

      case LifeInsuranceActions.updateContract.type: {
        const contractId = action.contractId || nextContractId(state);
        const form = state.forms.find(f => f.id === action.formId);
        form.contractId = contractId;
        const linkedForm = state.forms.find(
          f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
        );
        linkedForm.contractId = contractId;
        linkedForm.insuranceType = form.insuranceType;

        break;
      }

      case LifeInsuranceActions.deleteInsuranceGroupForm.type: {
        const linkId = state.forms.find(f => f.id === action.formId).linkId;
        const alternativeSetId = state.forms.find(f => f.id === action.formId).alternativeSetId;
        const formIds = state.forms.filter(f => f.linkId === linkId).map(f => f.id);
        state.forms = reject(state.forms, f => f.linkId === linkId);
        state.risks = reject(state.risks, r => formIds.includes(r.formId));

        if (action.group === LifeInsuranceFormGroup.Current) {
          // when the last form in the Current group was deleted, delete the remaining forms
          const currentCount = state.forms.filter(
            f => f.personId === action.personId && f.group === LifeInsuranceFormGroup.Current,
          ).length;

          if (currentCount === 0) {
            const remainingFormsFn = (f: LifeInsuranceForm) =>
              f.personId === action.personId && f.group !== LifeInsuranceFormGroup.Selected;
            const formIds2 = state.forms.filter(remainingFormsFn).map(f => f.id);
            state.forms = reject(state.forms, remainingFormsFn);
            state.risks = reject(state.risks, r => formIds2.includes(r.formId));
            // show current insurance selection
            findPerson(action.personId, state).currentInsurance = null;
          }
        }

        // when alternativeSet is empty (i.e. there is one in Recommended and one in Final), remove the alternativeSetId
        const alternativeForms = state.forms.filter(f => f.alternativeSetId === alternativeSetId);
        if (alternativeSetId && alternativeForms.length === 2) {
          alternativeForms.forEach(f => (f.alternativeSetId = null));
        }
        break;
      }

      case LifeInsuranceActions.cancelInsuranceGroupForm.type: {
        const form = state.forms.find(f => f.id === action.formId);
        form.cancelled = true;

        if (action.group === LifeInsuranceFormGroup.Recommended) {
          const linkedForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
          );
          if (!linkedForm.cancelled) {
            linkedForm.cancelled = true;
          }
        }

        if (action.group === LifeInsuranceFormGroup.Final && form.contractId) {
          const formsOnTheSameContract = state.forms.filter(
            f =>
              f.group === LifeInsuranceFormGroup.Final &&
              f.contractId === form.contractId &&
              f.id !== form.id,
          );
          for (const f of formsOnTheSameContract) f.cancelled = true;
        }
        break;
      }

      case LifeInsuranceActions.restoreInsuranceGroupForm.type: {
        const form = state.forms.find(f => f.id === action.formId);
        form.cancelled = false;

        if (action.group === LifeInsuranceFormGroup.Recommended) {
          const linkedForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
          );
          if (linkedForm.cancelled) {
            linkedForm.cancelled = false;
          }
        }

        if (action.group === LifeInsuranceFormGroup.Final && form.contractId) {
          const formsOnTheSameContract = state.forms.filter(
            f =>
              f.group === LifeInsuranceFormGroup.Final &&
              f.contractId === form.contractId &&
              f.id !== form.id,
          );
          for (const f of formsOnTheSameContract) f.cancelled = false;
        }
        break;
      }

      case LifeInsuranceActions.updateFormName.type: {
        const form = state.forms.find(f => f.id === action.formId);
        if (action.group === LifeInsuranceFormGroup.Current) {
          const recommendedForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Recommended,
          );
          if (form.name === recommendedForm.name) {
            recommendedForm.name = action.name;
          }
        }
        if (
          action.group === LifeInsuranceFormGroup.Current ||
          action.group === LifeInsuranceFormGroup.Recommended
        ) {
          const finalForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
          );
          if (form.name === finalForm.name) {
            finalForm.name = action.name;
          }
        }
        form.name = action.name;
        break;
      }

      case LifeInsuranceActions.updateFormPrice.type: {
        const form = state.forms.find(f => f.id === action.formId);
        if (action.group === LifeInsuranceFormGroup.Current) {
          const recommendedForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Recommended,
          );
          if (form.price === recommendedForm.price) {
            recommendedForm.price = action.price;
          }
        }
        if (
          action.group === LifeInsuranceFormGroup.Current ||
          action.group === LifeInsuranceFormGroup.Recommended
        ) {
          const finalForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
          );
          if (form.price === finalForm.price) {
            finalForm.price = action.price;
          }
        }
        form.price = action.price;
        break;
      }

      case LifeInsuranceActions.updateFormProduct.type: {
        const form = state.forms.find(f => f.id === action.formId);
        form.insuranceType = action.insuranceType;
        form.productId = action.productId;

        const finalForm = state.forms.find(
          f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
        );
        finalForm.productId = action.productId;
        form.insuranceType = action.insuranceType;
        break;
      }

      case LifeInsuranceActions.updateStakeholder.type: {
        const form = state.forms.find(f => f.id === action.formId);
        form.stakeholderId = action.stakeholderId;

        const finalForm = state.forms.find(
          f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Final,
        );
        finalForm.stakeholderId = action.stakeholderId;
        break;
      }

      case LifeInsuranceActions.updateRiskForm.type: {
        const previousRisk = state.risks.find(
          r => r.formId === action.formId && r.riskId === action.riskId,
        );
        const form = state.forms.find(f => f.id === action.formId);
        const person = findPerson(action.personId, state);
        const defaultAge = getDefaultAge(person);
        const nextRisk = {
          personId: action.personId,
          formId: action.formId,
          riskId: action.riskId,
          value: action.value,
          age: action.age,
        };

        state.risks = reject(
          state.risks,
          r => r.formId === action.formId && r.riskId === action.riskId,
        );
        if (action.value !== null || action.age !== defaultAge) {
          state.risks.push(nextRisk);
        }

        const previousValue = previousRisk ? previousRisk.value : null;
        const previousAge = previousRisk ? previousRisk.age : defaultAge;

        if (action.group === LifeInsuranceFormGroup.Current) {
          const recommendedForm = state.forms.find(
            f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Recommended,
          );
          const recommendedRisk = state.risks.find(
            r => r.formId === recommendedForm.id && r.riskId === action.riskId,
          );

          let recommendedValue = recommendedRisk ? recommendedRisk.value : null;
          let recommendedAge = recommendedRisk ? recommendedRisk.age : defaultAge;
          // Prevent changing of the Final values when the Current and Recommended values differ.
          if (previousValue !== recommendedValue) recommendedValue = null;
          if (previousAge !== recommendedAge) recommendedAge = null;

          updateLinkedRiskForm(
            form,
            LifeInsuranceFormGroup.Recommended,
            action,
            previousValue,
            previousAge,
            defaultAge,
            state,
          );

          updateLinkedRiskForm(
            recommendedForm,
            LifeInsuranceFormGroup.Final,
            action,
            recommendedValue,
            recommendedAge,
            defaultAge,
            state,
          );
        }

        if (action.group === LifeInsuranceFormGroup.Recommended) {
          updateLinkedRiskForm(
            form,
            LifeInsuranceFormGroup.Final,
            action,
            previousValue,
            previousAge,
            defaultAge,
            state,
          );
        }
        break;
      }

      case LifeInsuranceActions.updateSummaries.type: {
        state.summaries = action.summaries;
        break;
      }

      case LifeInsuranceActions.initializeSummariesProgress.type: {
        state.ui.summariesProgress = {
          unfinishedParticipantIds: action.unfinishedParticipantIds,
          selectedParticipantId: action.selectedParticipantId,
        };
        break;
      }

      case LifeInsuranceActions.pickParticipantForSummaryForm.type: {
        state.ui.summariesProgress = {
          unfinishedParticipantIds: state.ui.summariesProgress.unfinishedParticipantIds.filter(
            participantId => participantId !== action.selectedParticipantId,
          ),
          selectedParticipantId: action.selectedParticipantId,
        };
        break;
      }

      case LifeInsuranceActions.finishParticipantSummaryForm.type: {
        state.ui.summariesProgress.selectedParticipantId =
          state.ui.summariesProgress.selectedParticipantId === action.finishedParticipantId
            ? null
            : state.ui.summariesProgress.selectedParticipantId;
        break;
      }

      case LifeInsuranceActions.addFormFile.type: {
        const file = cloneDeep(action.file);
        file.group = action.group;
        file.personId = action.personId;
        file.formId = action.formId;
        state.files.push(file);
        break;
      }

      case LifeInsuranceActions.updateFormFileName.type: {
        const index = state.files.findIndex(f => f.dmsUuid === action.dmsUuid);
        state.files[index].name = action.name;
        break;
      }

      case LifeInsuranceActions.removeFormFiles.type: {
        state.files = reject(state.files, f => action.dmsUuids.includes(f.dmsUuid));
        break;
      }

      case LifeInsuranceActions.addGeneralFile.type: {
        const file = cloneDeep(action.file);
        file.personId = action.personId;
        file.general = true;
        state.files.push(file);
        break;
      }

      case LifeInsuranceActions.updateGeneralFileName.type: {
        const index = state.files.findIndex(f => f.dmsUuid === action.dmsUuid);
        state.files[index].name = action.name;
        break;
      }

      case LifeInsuranceActions.setFinancialAnalysisChangeFlag.type: {
        state.ui.financialAnalysisChanged = action.changed;
        break;
      }

      case LifeInsuranceActions.removeGeneralFile.type: {
        state.files = reject(state.files, f => action.dmsUuid === f.dmsUuid);
        break;
      }

      case LifeInsuranceActions.setAdditionalAnalysisData.type: {
        state.additionalAnalysisData.debtsTogether = action.debtsTogether;
        break;
      }

      case LifeInsuranceActions.useOtherIncome.type: {
        findPerson(action.personId, state).useOtherIncome = action.use;
        break;
      }

      case LifeInsuranceActions.updateOtherIncomeUuids.type: {
        findPerson(action.personId, state).otherIncomeAssetUuids = action.assetUuids;
        break;
      }

      case LifeInsuranceActions.usePaymentProtectionInsurance.type: {
        findPerson(action.personId, state).usePaymentProtectionInsurance = action.use;
        break;
      }

      case LifeInsuranceActions.useSixMonthReserve.type: {
        findPerson(action.personId, state).useSixMonthReserve = action.use;
        break;
      }

      case LifeInsuranceActions.updateSixMonthReserve.type: {
        findPerson(action.personId, state).sixMonthReserve = action.sixMonthReserve;
        break;
      }
    }
  },
);

export function reducer(
  state = initialState,
  action: LifeInsuranceActionsUnion,
): LifeInsuranceState {
  return lifeInsuranceReducer(state, action);
}
