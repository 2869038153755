import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NamedGroupFormlyComponent} from './named-group-formly.component';

@NgModule({
  declarations: [NamedGroupFormlyComponent],
  imports: [CommonModule],
  exports: [NamedGroupFormlyComponent],
})
export class NamedGroupFormlyModule {}
