import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'kpt-accordion-switch',
  templateUrl: './accordion-switch.component.html',
})
export class AccordionSwitchComponent {
  @Input()
  label: string;

  @Input()
  contentBackground = false;

  @Input()
  open = false;

  @Output()
  openChange = new EventEmitter<boolean>();
}
