import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {Asset, ValueAsset} from '@shared/analysis/models/asset';
import {OtherIncomesAsset} from '@shared/analysis/models/other-incomes';
import {selectFinances as selectFinancesAnalysis} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

export const selectOtherIncomesCategory = createSelector(selectFinancesAnalysis, finances =>
  createCategory(finances.otherIncomes),
);

function createCategory(otherIncomes: AssetPageStateValue): Category {
  return {
    label: otherIncomes.title,
    value: formatCZKNotNull(otherIncomes.sum),
    categoryTypes: [createCategoryType(otherIncomes.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: createItems(asset as OtherIncomesAsset).filter(Boolean),
  };
}

function createItems(asset: OtherIncomesAsset): Item[] {
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}
