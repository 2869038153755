import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {StepperProgressComponent} from '@shared/ui/stepper-progress/stepper-progress.component';

@NgModule({
  declarations: [StepperProgressComponent],
  imports: [CommonModule, RouterModule],
  exports: [StepperProgressComponent],
})
export class StepperProgressModule {}
