import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {IModalSize} from '@shared/ui';
import {InsuranceType} from 'src/app/modules/life-insurance-old/6-summary/summary.models';

export const requirementsI18n: {[key: string]: string} = {
  taxRelief: 'Daňové odpočty',
  employeeContribution: 'Příspěvek zaměstnavatele',
  highProtection:
    'Vysokou pojistnou ochranu pro sebe a&nbsp;možnost tuto pojistnou ochranu v&nbsp;budoucnu' +
    ' měnit podle aktuální životní situace pro sebe a&nbsp;své blízké',
  investmentOption: 'Možnost investice volných finančních prostředků',
  investmentOptionWithProtection:
    'Možnost investice volných finančních prostředků v&nbsp;kombinaci ' +
    's&nbsp;pojistnou ochranou, kterou lze v&nbsp;budoucnu variabilně měnit',
  risksCovered: 'Jako jediný obsahuje tento produkt krytí rizik požadovaných klientem',
  bestRatio: 'Nejlepší poměr mezi nákladovostí produktu a&nbsp;pojistnou ochranou',
  bestPrice:
    'Všechny doporučené produkty jsou kvalitativně srovnatelné, vybraný produkt je pro klienta cenově' +
    ' nejvýhodnější',
  bestReturn: 'Nejvyšší výše odkupného na konci pojistného období',
  paymentProtectionInsurance:
    'Ponížení zajištění výdajů o&nbsp;splátku hypotéky v&nbsp;návaznosti ' +
    'na&nbsp;pojištění schopnosti splácet/úvěrový balíček pro&nbsp;pojistná rizika, která má klient ' +
    'sjednána. V&nbsp;případě změny/zániku tohoto připojištění doporučujeme kontaktovat Poradce ' +
    'za&nbsp;účelem aktualizace navrhovaného řešení.',
  sixMonthReserve:
    'Pojistná částka u&nbsp;rizik pracovní neschopnosti, ' +
    'hospitalizace, denního odškodného a tělesného poškození (byla-li všechna sjednána) byla nastavena ' +
    'na&nbsp;denní dávku 0&nbsp;Kč z&nbsp;důvodu dostatečné rezervy klienta, která postačuje ' +
    'na&nbsp;období min. 6&nbsp;měsíců. Klient byl informován ohledně nutnosti informování poradce, ' +
    'pokud by se tato rezerva snížila pro&nbsp;aktualizaci smlouvy a doplnění zajištění těchto rizik.',
};

@Component({
  selector: 'kpt-client-requirements',
  templateUrl: './client-requirements.component.html',
  styleUrls: ['./client-requirements.component.scss'],
})
export class ClientRequirementsComponent implements OnInit {
  @Input()
  requirementsForm: UntypedFormGroup;

  @Input()
  insuranceType: InsuranceType;

  constructor(private modalService: ModalService) {}

  ngOnInit() {}

  openFormModal() {
    this.requirementsForm.markAsTouched();
    this.modalService.openModal({
      component: 'ClientRequirementsModalComponent',
      size: IModalSize.LG,
      data: {
        requirements: this.requirementsForm.getRawValue(),
        form: this.requirementsForm,
        insuranceType: this.insuranceType,
      },
    });
  }

  isFormInvalid(): boolean {
    return this.requirementsForm.touched && !this.requirementsForm.valid;
  }
}
