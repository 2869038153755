<ng-container *ngFor="let item of getItems(); let i = index; trackBy: trackByFn">
  <ng-container *ngIf="isNewList(item)">
    <kpt-menu-list
      [items]="item"
      [startingIndex]="getIndexString(i)"
      (itemClick)="itemClick.emit($event)"
    >
    </kpt-menu-list>
  </ng-container>
  <ng-container *ngIf="!isNewList(item)">
    <div [attr.menu-index]="getIndexString(i)">
      <div class="menu-item" (click)="itemClick.emit(item)">
        <div class="menu-item__content">
          <ng-container *ngIf="item?.image">
            <kpt-avatar
              [imgSrc]="item?.image?.src"
              [imgAlt]="item?.image?.alt"
              [imgTitle]="item?.image?.title"
            >
            </kpt-avatar>
          </ng-container>
          <div
            *ngIf="item?.icon && !item?.icon.endsWith('.svg') && !item.image"
            [ngClass]="'icon icon--' + item?.icon"
            class="menu-item__icon"
          ></div>
          <svg-icon
            *ngIf="item?.icon && item?.icon.endsWith('.svg') && !item.image"
            class="icon menu-item__icon"
            [src]="item?.icon"
          ></svg-icon>
          <div class="menu-item__label">{{ item?.label }}</div>
        </div>
        <span
          *ngIf="item?.items"
          [ngClass]="{
            'icon--arrow-down': !showSubMenu(getIndexString(i)),
            'icon--arrow-up': showSubMenu(getIndexString(i))
          }"
          class="icon menu-item__toggle-icon"
          (click)="toggle(getIndexString(i)); $event.stopPropagation()"
        ></span>
      </div>
      <div *ngIf="item?.items && showSubMenu(getIndexString(i))" class="menu-item__sub">
        <kpt-menu-list
          [items]="item.items"
          [startingIndex]="getIndexString(i)"
          [divider]="false"
          (itemClick)="this.itemClick.emit($event)"
        ></kpt-menu-list>
      </div>
    </div>
    <div *ngIf="item?.addDivider || (lastItem(i) && divider)" class="divider"></div>
  </ng-container>
</ng-container>
