<div class="row mb-2" *ngIf="categoryType.label || categoryType.value">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h4>{{ categoryType.label }}</h4>
      <h4>{{ categoryType.value }}</h4>
    </div>
  </div>
</div>
<div class="row d-print-block mb-2">
  <div class="col-12">
    <kpt-items-group *ngFor="let itemsGroup of categoryType.itemsGroups" [itemsGroup]="itemsGroup">
    </kpt-items-group>
  </div>
  <div *ngFor="let note of categoryType.additionalNotes" class="col-12">
    <span [innerHTML]="note"></span>
  </div>
</div>
