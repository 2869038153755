import {Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldWrapper} from '@ngx-formly/core';
import {isCommonFinancialProductAsset} from '@shared/analysis/asset.utils';

@UntilDestroy()
@Component({
  selector: 'kpt-important-value-formly-wrapper',
  templateUrl: './important-value-formly-wrapper.component.html',
  styleUrls: ['../../../recommended-value/recommended-value.component.scss'],
})
export class ImportantValueFormlyWrapperComponent extends FieldWrapper implements OnInit {
  isValidated = false;

  ngOnInit(): void {
    if (isCommonFinancialProductAsset(this.model)) this.isValidated = true;
  }

  isValid() {
    return Boolean(this.field.formControl.value);
  }
}
