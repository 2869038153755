import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AlertModule} from '@shared/ui';
import {AnalysisChangedAlertComponent} from './analysis-changed-alert.component';

@NgModule({
  declarations: [AnalysisChangedAlertComponent],
  imports: [CommonModule, AlertModule],
  exports: [AnalysisChangedAlertComponent],
})
export class AnalysisChangedAlertModule {}
