<div *ngIf="isObjectiveAsset()">
  <div class="d-inline-block mb-4">
    <span [innerHTML]="getDescription()"></span>
    <span *ngIf="!showDescription" (click)="showDescription = true" class="description-btn pl-3">
      <span class="description-btn__label">Zobrazit více</span>
      <span class="icon icon--read-more"></span>
    </span>
  </div>
  <div class="long-desc mb-4" *ngIf="showDescription">
    <span [innerHTML]="getDescription(true)"></span>
    <span (click)="showDescription = false" class="description-btn pl-3">
      <span class="description-btn__label">Zobrazit méně</span>
      <span class="icon icon--read-less"></span>
    </span>
  </div>
</div>
