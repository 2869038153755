import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FormModule, ModalModule} from '@shared/ui';
import {SwitchModule} from '@shared/ui/forms/switch';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-feature-flags-modal',
  templateUrl: './feature-flags-modal.component.html',
  styleUrls: [],
  imports: [CommonModule, FormsModule, ModalModule, FormModule, SwitchModule],
})
export class FeatureFlagsModalComponent {
  constructor(public featureFlagsService: FeatureFlagsService) {}

  dismiss = () => location.reload();
}
