import {filterAssets} from '@shared/analysis/asset.utils';
import {calculateMonthlySum} from '@shared/analysis/evaluation';
import {
  Asset,
  AssetType,
  basicInvestmentAssets,
  commonExpenseAssets,
  creditAssets,
  insuranceAssets,
  pensionInvestmentAssets,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
} from '@shared/analysis/models/asset';
import {CommonExpenseAsset} from '@shared/analysis/models/common-expenses';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {ContractorAsset} from '@shared/analysis/models/main-incomes';

export function applyIndispensability(assets: Asset[]): Asset[] {
  return assets.map(asset => ({
    ...asset,
    value:
      (asset as CommonExpenseAsset).value * ((asset as CommonExpenseAsset).indispensablePart ?? 1),
  }));
}

export function applyDispensability(assets: Asset[]): Asset[] {
  return assets.map(asset => ({
    ...asset,
    value:
      (asset as CommonExpenseAsset).value *
      (1 - (asset as CommonExpenseAsset).indispensablePart ?? 1),
  }));
}

export function findAssetById(assets: Asset[], uuid: string): Asset | undefined {
  return assets.find(asset => asset.assetUuid === uuid);
}

export function findAssetByRelatedObjectiveUuid(assets: Asset[], uuid: string): Asset | undefined {
  return assets.find(asset => (asset as RelatedObjectiveAsset).relatedObjectiveUuid === uuid);
}

export function findAssetsByRelatedObjectiveUuid(
  assets: Asset[],
  uuid: string,
): Asset[] | undefined {
  return assets.filter(asset => (asset as RelatedObjectiveAsset).relatedObjectiveUuid === uuid);
}

export function findAssetsByStakeholder(assets: Asset[], uuid: string): Asset[] | undefined {
  return assets.filter(asset => (asset as StakeholderAsset).stakeholderUuid === uuid);
}

export function findAssetByRelatedPropertyUuid(assets: Asset[], uuid: string): Asset | undefined {
  return assets.find(asset => (asset as RelatedPropertyAsset).relatedPropertyUuid === uuid);
}

export function findAssetsByRelatedPropertyUuid(
  assets: Asset[],
  uuid: string,
): Asset[] | undefined {
  return assets.filter(asset => (asset as RelatedPropertyAsset).relatedPropertyUuid === uuid);
}

export function calculateCommonExpensesSum(assets: Asset[]): number {
  return -calculateMonthlySum(
    filterAssets(assets, [...commonExpenseAssets, AssetType.CommonExpensesSum]).map(
      replaceContractorAssetType,
    ),
  );
}

export function calculateInsuranceProductsSum(assets: Asset[]): number {
  return -calculateMonthlySum(
    filterAssets(assets, [...insuranceAssets, AssetType.InsuranceProductsSum]),
  );
}

export function calculateInvestmentProductsSum(assets: Asset[]): number {
  return -calculateMonthlySum(
    filterAssets(assets, [
      ...basicInvestmentAssets,
      ...pensionInvestmentAssets,
      AssetType.InvestmentProductsSum,
    ]),
  );
}

export function calculateCreditProductsSum(assets: Asset[]): number {
  return -calculateMonthlySum(filterAssets(assets, [...creditAssets, AssetType.CreditProductsSum]));
}

export const replaceContractorAssetType = (asset: Asset) => {
  return asset.type === AssetType.Contractor
    ? ({...asset, type: AssetType.ContractorInsurance} as ContractorAsset)
    : asset;
};
