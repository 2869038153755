/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface AssetParams {
  data: __model.Asset;
  family_uuid: string;
}

export interface AnalysisAssetsReadParams {
  family_uuid: string;
}

export interface AnalysisAssetsCreateParams {
  data: __model.Asset[];
  family_uuid: string;
}

export interface FinishFaParams {
  family_uuid: string;
}

export interface GetRisksParams {
  family_uuid: string;
}

export interface CreateUpdateRisksParams {
  data: __model.RiskAdditionalData;
  family_uuid: string;
}

@Injectable()
export class AnalysisService {
  constructor(private http: HttpClient) {}

  /**
   * Create or update a single asset for a family
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/analysis_asset_create
   */
  asset(params: AssetParams): Observable<__model.Asset> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.Asset>(`/api/analysis/asset/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /**
   * Get all assets for a family
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/analysis_assets_read
   */
  analysisAssetsRead(params: AnalysisAssetsReadParams): Observable<void> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<void>(`/api/analysis/assets/${pathParams.family_uuid}/`);
  }

  /**
   * Save all assets for a family
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/analysis_assets_create
   */
  analysisAssetsCreate(params: AnalysisAssetsCreateParams): Observable<__model.Asset[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.Asset[]>(`/api/analysis/assets/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/markFinancialAnalysisAsFinished */
  finishFa(params: FinishFaParams): Observable<void> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<void>(`/api/analysis/finish-fa/${pathParams.family_uuid}/`, {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/getRisks */
  getRisks(params: GetRisksParams): Observable<__model.RiskAdditionalData> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.RiskAdditionalData>(`/api/analysis/risks/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/analysis/createUpdateRisks */
  createUpdateRisks(params: CreateUpdateRisksParams): Observable<__model.RiskAdditionalData> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.RiskAdditionalData>(`/api/analysis/risks/${pathParams.family_uuid}/`, bodyParams || {});
  }
}
