import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {LoginService} from '@lib/services';
import {Scenario} from '@lib/services/intro-js.config';
import {IntroJsService} from '@lib/services/intro-js.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {DialogService} from '@shared/services/modal.service';
import {ERecordModalComponent} from 'src/app/modules/client/card-board/e-record-modal/e-record-modal.component';
import {ClientPageConfig} from 'src/app/modules/client/client-page.config';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {BusinessCaseService} from 'src/app/modules/financial-plan/business-case.service';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';

type CurrentPlanState = 'preparation' | 'presentation' | 'finished';
type CurrentPlanImage = CurrentPlanState | 'noPlan';

@UntilDestroy()
@Component({
  selector: 'kpt-card-board1',
  templateUrl: './card-board1.component.html',
  styleUrls: ['./card-board1.component.scss'],
})
export class CardBoard1Component implements OnInit {
  cardBoard$ = this.config.getCardBoard();
  scenario: Scenario = 'clientPageOverview';
  businessCaseStatusDescription$ = this.businessCaseService.getBusinessCaseStatusDescription();
  finalizedBusinessCases$ = this.businessCaseService.getFinalizedBusinessCases();
  readonly currentPlanImages: Record<CurrentPlanImage, string> = {
    noPlan: '/assets/images/homepage/equity.svg',
    preparation: '/assets/images/homepage/equity.svg',
    presentation: '/assets/images/homepage/plan-presentation.svg',
    finished: '/assets/images/homepage/plan-finished.svg',
  };

  private familyHead: FamilyMember;

  constructor(
    public businessCaseService: BusinessCaseService,
    private store: Store<State>,
    private config: ClientPageConfig,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private introJsService: IntroJsService,
    private dialogService: DialogService,
  ) {
    this.store.pipe(selectFamilyHead(), untilDestroyed(this)).subscribe(head => {
      this.familyHead = head;
    });
  }

  get financialDivision(): boolean {
    return this.loginService.isUserInFinancialDivision();
  }

  get goldDivision(): boolean {
    return this.loginService.isUserInGoldDivision();
  }

  ngOnInit() {
    if (this.financialDivision) {
      this.introJsService.showIntro(this.scenario, true);
    }
  }

  currentPlanState(): CurrentPlanState {
    if (this.businessCaseService.finalised) {
      return 'finished';
    }
    if (this.businessCaseService.presentationMode) {
      return 'presentation';
    }
    return 'preparation';
  }

  cardAction(card: ModuleCard) {
    if (card.icon === 'erecord') {
      this.openERecordModal();
      return;
    }

    if (card.icon === 'plan-meeting') {
      const portalUrl = `${environment.poradceUrl}portal-poradce/kalendar?newEvent=true&newEventClientId=${this.familyHead.sugarUuid}`;
      window.open(portalUrl, '_blank');
      return;
    }

    if (!card.disabled && card.target) {
      if (card.target.startsWith('https')) {
        window.open(card.target, '_blank');
      } else {
        this.router.navigate([card.target], {relativeTo: this.route});
      }
    }
  }

  private openERecordModal() {
    this.dialogService.load(
      ERecordModalComponent,
      {
        familyMember: this.familyHead,
      },
      {
        size: 'sm',
      },
    );
  }
}
