import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {IStepperProgressItem} from './models';
const placeholderPrefix = ':';

export function createStepUrl(url: string, section?: SecuredPage, page?: ClientPage): string {
  let sectionPath: string = section;
  if (section === 'client') sectionPath += '/:familyId';
  return '/' + ['secured', sectionPath, page, url].filter(Boolean).join('/');
}

export function createStepperProgressConfig(
  steps: IStepperProgressItem[],
  section?: SecuredPage,
  page?: ClientPage,
): IStepperProgressItem[] {
  return steps.map(step => ({...step, url: createStepUrl(step.url, section, page)}));
}

export function containsPlaceholders(url: string): boolean {
  return url.includes(placeholderPrefix);
}

export function insertPlaceholders(url: string, params: Record<string, any>): string {
  Object.entries(params).forEach(([param, value]) => {
    if (value) url = url.replace(value, placeholderPrefix + param);
  });
  return url;
}

export function replacePlaceholders(url: string, params: Record<string, any>): string {
  Object.entries(params).forEach(([param, value]) => {
    if (value) url = url.replace(placeholderPrefix + param, value);
  });
  return url;
}
