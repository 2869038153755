<kpt-radio-group
  *ngIf="familyMembersType === 'radio'"
  [formControl]="formControl"
  [required]="to.required"
  [labelTemplate]="to.labelTemplate"
  [disabledField]="to.disabled"
  [itemClass]="'d-inline-block mr-6'"
  [options]="options$ | async"
  [label]="to.label"
>
</kpt-radio-group>

<kpt-select
  *ngIf="familyMembersType === 'select'"
  [formControl]="formControl"
  [required]="to.required"
  [disabledField]="to.disabled"
  [options]="options$ | async"
  [label]="to.label"
>
</kpt-select>

<ng-container *ngIf="familyMembersType === 'checkbox'">
  <kpt-label>
    <span [innerHTML]="to.label"></span>
  </kpt-label>

  <ng-container *ngIf="!to.horizontal; else horizontalCheckboxes">
    <kpt-checkbox
      *ngFor="let option of options$ | async; let index = index"
      [disabledField]="to.disabled"
      [checked]="isChecked(option)"
      (valueChange)="onChange(option.key, $event)"
      [label]="option.label"
    >
    </kpt-checkbox>
  </ng-container>

  <ng-template #horizontalCheckboxes>
    <div class="row">
      <div class="col-2" *ngFor="let option of options$ | async; let index = index">
        <kpt-checkbox
          [disabledField]="to.disabled"
          [checked]="isChecked(option)"
          (valueChange)="onChange(option.key, $event)"
          [label]="option.label"
        >
        </kpt-checkbox>
      </div>
    </div>
  </ng-template>
</ng-container>
