<kpt-input
  #fieldRef
  [formControl]="inputForm"
  [label]="to.label"
  type="percent"
  [required]="to.required"
  appendText="%"
  [readonly]="to.readonly"
  [min]="to.min"
  [max]="to.max"
  [subtext]="to.subtext"
  [appendTemplate]="to.appendTemplate"
  [disabledField]="to.disabled"
  [showErrorsOn]="!formControl.valid"
  (valueChange)="onChange(field, $event)"
  (focus)="onFocus()"
  (blur)="onBlur()"
>
</kpt-input>
