import {Component, EventEmitter, Input, Output} from '@angular/core';

export enum Directions {
  NONE = 0,
  ASC = 1,
  DESC = -1,
}

@Component({
  selector: 'kpt-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent {
  @Input() direction: Directions = Directions.NONE;
  @Output() changeDirection = new EventEmitter<Directions>();
  directions = Directions;

  constructor() {}

  onToggleSort() {
    if (this.direction === Directions.ASC) return this.changeDirection.emit(Directions.DESC);
    return this.changeDirection.emit(Directions.ASC);
  }
}
