import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {GetClientActionTypes, GetClientFinish, GetClientStart} from '../actions/get-client.actions';
import {State} from '../index';
import {getGetClientState} from '../selectors/get-client.selectors';

@Injectable()
export class GetClientEffects {
  getClientX = createEffect(() =>
    this.actions$.pipe(
      ofType(GetClientActionTypes.GetClientStart),
      map((action: GetClientStart) => action.payload.sugar_uuid),
      withLatestFrom(this.store.pipe(select(getGetClientState))),
      switchMap(([sugar_uuid, clientState]) => {
        if (clientState.client && sugar_uuid === clientState.client.sugarUuid) {
          return of(new GetClientFinish({...clientState}));
        }

        return this.integrationsService.getClient({sugar_uuid}).pipe(
          map(client => new GetClientFinish({client, error: null})),
          catchError((error: HttpErrorResponse) => of(new GetClientFinish({client: null, error}))),
        );
      }),
    ),
  );

  constructor(
    private store: Store<State>,
    private integrationsService: IntegrationsService,
    private actions$: Actions,
  ) {}
}
