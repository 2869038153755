import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RisksSelectionService} from '@shared/analysis/components/risks-selection/risks-selection.service';
import {CommonBaseModule} from '@shared/lib';
import {LifeInsuranceSharedModule} from '@shared/life-insurance/life-insurance-shared.module';
import {FormModule} from '@shared/ui';
import {InputModule} from '@shared/ui/forms/input/input.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {RisksSelectionComponent} from './risks-selection.component';

@NgModule({
  declarations: [RisksSelectionComponent],
  imports: [
    CommonModule,
    InputModule,
    ReactiveFormsModule,
    FormModule,
    CommonBaseModule,
    LifeInsuranceSharedModule,
    TooltipModule,
  ],
  providers: [RisksSelectionService],
  exports: [RisksSelectionComponent],
})
export class RisksSelectionModule {}
