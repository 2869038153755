<div class="page" *ngIf="cardBoard$ | async as cardBoard">
  <ng-container *ngIf="financialDivision">
    <h2 class="heading mt-6">Vyberte co budeme řešit</h2>
    <div class="life-situation mb-8">
      <div class="simple-analysis">
        <kpt-card
          [config]="cardBoard.lifeSituation.mainCard"
          [selected]="
            selectedLifeSituation$.value?.value === cardBoard.lifeSituation.mainCard.value
          "
          (action)="selectedLifeSituation$.next(cardBoard.lifeSituation.mainCard)"
        ></kpt-card>
      </div>
      <div class="box card-holder" *ngFor="let card of cardBoard.lifeSituation.cards">
        <div class="card-holder">
          <kpt-card
            [config]="card"
            [selected]="selectedLifeSituation$.value.value === card.value"
            (action)="selectedLifeSituation$.next(card)"
          ></kpt-card>
        </div>
      </div>
    </div>

    <div *ngIf="!(showLegacyAction$ | async)">
      <h2 class="heading mt-3">Jak budeme postupovat</h2>
      <div class="life-situation-flow mb-8">
        <div>
          <div class="card-holder">
            <kpt-card
              *ngIf="actionAnalysis$ | async as action"
              [config]="action"
              (action)="cardAction(action)"
            ></kpt-card>
          </div>
        </div>
        <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
        <div>
          <div class="card-holder">
            <kpt-card
              *ngIf="actionPreparation$ | async as action"
              [config]="action"
              (action)="cardAction(action)"
            ></kpt-card>
          </div>
        </div>
        <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
        <div>
          <div class="card-holder">
            <kpt-card
              *ngIf="actionPresentation$ | async as action"
              [config]="action"
              (action)="cardAction(action)"
            ></kpt-card>
          </div>
        </div>
        <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
        <div>
          <div class="card-holder">
            <kpt-card
              *ngIf="actionSignature$ | async as action"
              [config]="action"
              (action)="cardAction(action)"
            ></kpt-card>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showLegacyAction$ | async">
      <h2 class="heading mt-3">Jak budeme postupovat</h2>
      <div class="life-situation-flow mb-8">
        <div>
          <div class="card-holder">
            <kpt-card
              *ngIf="actionLegacy$ | async as action"
              [config]="action"
              (action)="cardAction(action)"
            ></kpt-card>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="finalizedBusinessCases$ | async as finalizedBusinessCases">
      <div *ngIf="finalizedBusinessCases.length > 0" class="mb-8">
        <h2 class="heading mt-3">V minulosti jsme řešili</h2>
        <div class="card-holder">
          <kpt-previous-plans-card
            [finalizedBusinessCases]="finalizedBusinessCases"
          ></kpt-previous-plans-card>
        </div>
      </div>
    </ng-container>

    <h2 class="heading mt-3">Ostatní nástroje</h2>
    <div class="other-tools">
      <div class="box card-holder" *ngFor="let card of cardBoard.otherTools.cards">
        <div class="card-holder">
          <kpt-card [config]="card" (action)="cardAction(card)"></kpt-card>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="goldDivision && !financialDivision">
    <h2 class="heading mt-3">Investujeme do zlata</h2>
    <div class="gold">
      <div class="analysis">
        <div class="card-holder">
          <kpt-card
            [config]="cardBoard.gold.mainCard"
            (action)="cardAction(cardBoard.gold.mainCard)"
          ></kpt-card>
        </div>
      </div>
      <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
      <div class="gold-module">
        <div class="card-holder">
          <kpt-card
            [config]="cardBoard.gold.cards[0]"
            (action)="cardAction(cardBoard.gold.cards[0])"
          ></kpt-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>
