import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RiskAdditionalData} from '@generated/defs/RiskAdditionalData';
import {RiskAdditionalFamilyMemberData} from '@generated/defs/RiskAdditionalFamilyMemberData';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {
  PersonRiskWarnings,
  RiskWarnings,
} from '@shared/analysis/components/risks-selection/risks-selection.models';
import {Asset} from '@shared/analysis/models/asset';
import {selectCurrentAssets, selectRisksAdditionalData} from '@shared/analysis/store';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {cloneDeep, isEqual, isNil, some, union, uniqBy} from 'lodash';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {calculateRisk} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {
  LifeInsurancePerson,
  LifeInsuranceRisk,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectAllRisks,
  selectOrderedPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {
  adultExpensesRiskIds,
  adultIncomeRiskIds,
  adultProposalRiskIds,
  childRiskIds,
  getRiskDefinition,
  riskDefinitions,
} from 'src/app/services/risk-definitions';
import {State} from 'src/store';
import {Provision, RiskDefinition, RiskId} from 'src/store/models/risk.models';

/**
 * @deprecated
 */
@UntilDestroy()
@Injectable()
export class RisksSelectionService {
  form: UntypedFormGroup;
  familyUuid: string = null;
  familyMembers: LifeInsurancePerson[];

  formChanged = new BehaviorSubject<boolean>(false);
  formLoaded = new BehaviorSubject<boolean>(false);

  filteredAdultRisksDefinitions: RiskDefinition[];
  filteredChildrenRisksDefinitions: RiskDefinition[];

  risks: LifeInsuranceRisk[];
  risksAdditionalData: RiskAdditionalData;
  assets: Asset[];
  warnings: RiskWarnings = {
    persons: {},
    hasSomeWarning: false,
  };

  allRisksEnabled: boolean;

  constructor(
    private store: Store<State>,
    private lifeInsuranceDataService: LifeInsuranceDataService,
    private analysisDataService: AnalysisDataService,
  ) {
    const uniqueAdultRisks = union(adultIncomeRiskIds, adultExpensesRiskIds);
    const uniqueRisksDefinitions = uniqBy(riskDefinitions, 'id').filter(r => !r.onlyProposals);
    this.filteredAdultRisksDefinitions = uniqueRisksDefinitions.filter(r =>
      uniqueAdultRisks.includes(r.id),
    );
    this.filteredAdultRisksDefinitions.sort(
      (riskDef1: RiskDefinition, riskDef2: RiskDefinition) =>
        adultProposalRiskIds.indexOf(riskDef1.id as RiskId) -
        adultProposalRiskIds.indexOf(riskDef2.id as RiskId),
    );
    this.filteredChildrenRisksDefinitions = uniqueRisksDefinitions.filter(r =>
      childRiskIds.includes(r.id),
    );
    this.filteredChildrenRisksDefinitions.sort(
      (riskDef1: RiskDefinition, riskDef2: RiskDefinition) =>
        childRiskIds.indexOf(riskDef1.id as RiskId) - childRiskIds.indexOf(riskDef2.id as RiskId),
    );

    combineLatest([
      this.store.pipe(select(selectOrderedPersons)),
      this.store.pipe(select(selectAllRisks)),
      this.store.pipe(select(selectRisksAdditionalData)),
      this.store.pipe(select(selectCurrentAssets)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([persons, risks, risksAdditionalData, assets]) => {
        this.familyMembers = persons;
        this.risks = risks;
        this.risksAdditionalData = risksAdditionalData;
        this.assets = assets;
      });
  }

  /**
   * @deprecated
   */
  init(
    form: UntypedFormGroup,
    familyUuid: string,
    allRisksEnabled = false,
    onlyFamilyHead = false,
  ) {
    this.form = form;
    this.familyUuid = familyUuid;
    this.allRisksEnabled = allRisksEnabled;
    this.formChanged.next(false);

    this.analysisDataService
      .getLifeInsurancePersonsOld$()
      .pipe(take(1))
      .subscribe(allPersons => {
        this.updatePersons(onlyFamilyHead ? allPersons.filter(p => p.familyHead) : allPersons);
        this.initForms();
        this.formLoaded.next(true);
      });
  }

  private initForms() {
    this.createForms();
    this.createWarnings();
    const originalValue = this.form.getRawValue();
    this.form.valueChanges
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe(newValue => {
        this.formChanged.next(!isEqual(originalValue, newValue));
        this.createWarnings();
      });
  }

  private updatePersons(allPersons: LifeInsurancePerson[]) {
    const newPersons = allPersons.filter(
      p => !this.familyMembers.some(actualP => actualP.id === p.id),
    );
    this.familyMembers = cloneDeep(
      this.familyMembers
        .filter(actualPerson => allPersons.some(m => m.id === actualPerson.id))
        .concat(newPersons),
    );
    if (newPersons.length > 0) this.lifeInsuranceDataService.setPersons(this.familyMembers);
  }

  private createForms() {
    const membersRisks = new UntypedFormGroup({});
    this.familyMembers.forEach(person => {
      const additionalData = this.risksAdditionalData.membersRisks?.find(
        d => d.sugarUuid === person.id,
      );
      const memberFormGroup = new UntypedFormGroup(
        this.createRisksForms(person.id, person.child, additionalData),
      );

      if (!person.child) {
        memberFormGroup.addControl('createReserves', new UntypedFormControl(person.createReserves));
        memberFormGroup.addControl('taxRelief', new UntypedFormControl(person.taxRelief));
        memberFormGroup.addControl(
          'employeeContribution',
          new UntypedFormControl(person.employeeContribution),
        );

        memberFormGroup.addControl(
          'pensionEnsurement',
          new UntypedFormControl(person.pensionEnsurement),
        );
        memberFormGroup.addControl(
          'pensionEnsurementType',
          new UntypedFormControl(person.pensionEnsurementType),
        );

        memberFormGroup.addControl(
          'riskProfession',
          new UntypedFormControl(additionalData?.riskProfession ? 'yes' : 'no'),
        );
        memberFormGroup.addControl(
          'riskProfessionNote',
          new UntypedFormControl(additionalData?.riskProfessionNote),
        );
      }
      membersRisks.addControl(person.id, memberFormGroup);
    });
    this.form.addControl(
      'currentState',
      new UntypedFormControl(this.risksAdditionalData.currentState),
    );
    this.form.addControl('note', new UntypedFormControl(this.risksAdditionalData.note));
    this.form.addControl('membersRisks', membersRisks);
  }

  private createRisksForms(
    personId: string,
    child: boolean,
    additionalData: RiskAdditionalFamilyMemberData,
  ): {[key: string]: AbstractControl} {
    const riskFormGroups = {} as {[key: string]: UntypedFormGroup};
    this.filteredRisksDefinitions(child).forEach(r => {
      const riskFormGroup = new UntypedFormGroup({});

      const incomeFormControl = new UntypedFormControl(this.isRiskSelected(personId, r.id, true));
      riskFormGroup.addControl('income', incomeFormControl);
      incomeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
        if (v) {
          riskFormGroup.get('expenses')?.patchValue(false, {emitEvent: false});
        }
      });
      if (!this.allRisksEnabled && this.shouldDisableRisk(true, r, child, personId)) {
        incomeFormControl.disable();
      }

      const expensesFormControl = new UntypedFormControl(
        this.isRiskSelected(personId, r.id, false),
      );
      riskFormGroup.addControl('expenses', expensesFormControl);
      expensesFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
        if (v) {
          riskFormGroup.get('income')?.patchValue(false, {emitEvent: false});
        }
      });
      if (!this.allRisksEnabled && this.shouldDisableRisk(false, r, child, personId)) {
        expensesFormControl.disable();
      }

      const riskNote = additionalData?.risksNotes.find(n => n.riskId === r.id)?.note;
      riskFormGroup.addControl('note', new UntypedFormControl(isNil(riskNote) ? '' : riskNote));

      riskFormGroups[r.id] = riskFormGroup;
    });
    return riskFormGroups;
  }

  private isRiskSelected(personId: string, riskId: string, income: boolean): boolean {
    const provision: Provision[] = income
      ? [Provision.Income, Provision.Standard]
      : [Provision.Expenses, Provision.Minimal];
    return Boolean(
      this.risks.find(
        risk =>
          risk.personId === personId &&
          risk.riskId === riskId &&
          provision.includes(risk.provision),
      ),
    );
  }

  private filteredRisksDefinitions(child: boolean): RiskDefinition[] {
    return child ? this.filteredChildrenRisksDefinitions : this.filteredAdultRisksDefinitions;
  }

  private getProvision(income: boolean, child: boolean): Provision {
    return income
      ? child
        ? Provision.Standard
        : Provision.Income
      : child
      ? Provision.Minimal
      : Provision.Expenses;
  }

  private shouldDisableRisk(
    income: boolean,
    risk: RiskDefinition,
    child: boolean,
    personId: string,
  ): boolean {
    const calculatedRisk = calculateRisk(
      risk,
      this.getProvision(income, child),
      this.familyMembers.find(p => p.id === personId),
      this.familyMembers,
      this.assets,
    );

    const provision = this.getProvision(income, child);
    return isNil(getRiskDefinition(risk.id, provision)) || calculatedRisk === 0;
  }

  private createWarnings() {
    const personWarnings = Object.assign(
      {},
      ...this.familyMembers.map((person): {[personId: string]: PersonRiskWarnings} => {
        const riskWarnings = this.filteredRisksDefinitions(person.child).map(risk => {
          if (
            this.form.get(['membersRisks', person.id, risk.id, 'income']) &&
            this.form.get(['membersRisks', person.id, risk.id, 'income']).value &&
            this.shouldDisableRisk(true, risk, person.child, person.id)
          ) {
            return {
              [risk.id]: {income: 'Požadujete zajištění, ale riziko nehrozí. Upravte analýzu.'},
            };
          }
          if (
            this.form.get(['membersRisks', person.id, risk.id, 'expenses']) &&
            this.form.get(['membersRisks', person.id, risk.id, 'expenses']).value &&
            this.shouldDisableRisk(false, risk, person.child, person.id)
          ) {
            return {
              [risk.id]: {expenses: 'Požadujete zajištění, ale riziko nehrozí. Upravte analýzu.'},
            };
          }
          return {[risk.id]: ''};
        });
        return {[person.id]: Object.assign({}, ...riskWarnings)};
      }),
    );

    this.warnings.persons = personWarnings;
    this.warnings.hasSomeWarning = some(
      Object.values(personWarnings).map(person => some(Object.values(person))),
    );
  }
}
