import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[grow],[shrink],[basis]',
})
export class FlexItemDirective {
  _grow = 0;

  get grow() {
    return this._grow;
  }

  @Input() set grow(value: number | '') {
    this._grow = value === '' ? 1 : value;
  }

  _shrink = 1;

  get shrink() {
    return this._shrink;
  }

  @Input() set shrink(value: number | '') {
    this._shrink = value === '' ? 1 : value;
  }

  _basis = '0%'; // default basis 0% is patch for IE

  get basis() {
    return this._basis;
  }

  @Input() set basis(value: string | '') {
    this._basis = value === '' ? '20%' : value;
  }

  @HostBinding('style.flex')
  get flex() {
    return `${this.grow} ${this.shrink} ${this.basis}`;
  }
}
