import {Component, Inject, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {FAMILY_ID} from '@shared/constants';
import {FamilyService} from '@shared/services/family/family.service';
import {map, take} from 'rxjs/operators';
import {State} from 'src/store';
import {getFamilyHead} from 'src/store/selectors/family-member.selectors';

@Component({
  selector: 'kpt-missing-email-alert',
  templateUrl: './missing-email-alert.component.html',
  styleUrls: ['./missing-email-alert.component.scss'],
})
export class MissingEmailAlertComponent {
  @Input() message: string;

  constructor(
    private store: Store<State>,
    private familyService: FamilyService,
    @Inject(FAMILY_ID) private familyUuid: string,
  ) {}

  editFamilyHead() {
    this.store
      .pipe(
        select(getFamilyHead),
        map(familyHead => familyHead.sugarUuid),
        take(1),
      )
      .subscribe(sugarUuid => {
        this.familyService.editFamilyMember(sugarUuid, this.familyUuid, false);
      });
  }
}
