import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {RiskListInputComponent} from '@shared/analysis/asset-types/common/risk-list-input/risk-list-input.component';
import {VehicleRequirementsCommonComponent} from '@shared/analysis/asset-types/common/vehicle-requirements-common/vehicle-requirements-common.component';
import {VehicleProvisionAsset} from '@shared/analysis/models/objectives';
import {ButtonModule, FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@Component({
  standalone: true,
  selector: 'kpt-vehicle-provision-form',
  templateUrl: './vehicle-provision-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    ButtonModule,
    SwitchModule,
    FlexboxModule,
    ObjectiveAgeInputComponent,
    RiskListInputComponent,
    ObjectiveDescriptionComponent,
    VehicleRequirementsCommonComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VehicleProvisionFormComponent {
  @Input() asset: VehicleProvisionAsset;
  @Input() disabled = false;
}
