import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SurveyResults} from '@shared/investment-survey-old/component/summary/life-insurance/life-insurance-summary.component';
import {PensionInvestmentSurveyResult} from '@shared/investment-survey-old/component/summary/pension/pension-summary.models';

export interface ResultSet {
  sugarUuid: string;
  investmentResult: UntypedFormGroup;
  lifeInsuranceResult: SurveyResults;
  supplementaryPensionSavingsResult: PensionInvestmentSurveyResult;
}

@Component({
  selector: 'kpt-analysis-investment-survey-summary',
  templateUrl: './investment-survey-summary.component.html',
  styleUrls: ['./investment-survey-summary.component.scss'],
})
export class InvestmentSurveySummaryComponent {
  @Input()
  results: ResultSet[];

  @Input()
  readonly = false;
}
