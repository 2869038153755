import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {FindClient} from '@generated/defs/FindClient';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {
  FindClientsActionTypes,
  FindClientsError,
  FindClientsStart,
  FindClientsSuccess,
} from '../actions/find-clients.actions';

@Injectable()
export class FindClientsEffects {
  findClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindClientsActionTypes.FindClientsStart),
      map((action: FindClientsStart) => action.payload),
      switchMap((client: FindClient) =>
        this.integrationsService.findClients({data: client}).pipe(
          map(clients => new FindClientsSuccess(clients)),
          catchError((error: HttpErrorResponse) => of(new FindClientsError(error))),
          takeUntil(this.actions$.pipe(ofType(FindClientsActionTypes.FindClientsCancel))),
        ),
      ),
    ),
  );

  constructor(private integrationsService: IntegrationsService, private actions$: Actions) {}
}
