import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {IOption} from '@shared/ui/forms/models/option.model';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {CommonFieldComponent} from '../common-field/common-field.component';

@Component({
  selector: 'kpt-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    ...getProvidersFor(SelectComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => SelectComponent)},
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent extends CommonFieldComponent implements ControlValueAccessor {
  @Input() set value(v: any) {
    this.writeValue(v);
  }

  @Input() options: IOption[];
  @Input() scrollUp = false;
  @Input() clearable = false;
  @Input() ngOptionTmpRef: TemplateRef<any>;
  @Input() ngFooterTmpRef: TemplateRef<any>;
  @Output() selected = new EventEmitter<string>();

  writeValue(value: any) {
    this.inputValue = value;

    if (value !== this.inputValue) {
      this.onChange(value);
    }

    this.selected.emit(value);
  }

  onSelectChange(value: string) {
    this.selected.emit(value);
  }
}
