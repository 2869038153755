import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * The DialogService is capable to inject components into the dom using KendoDialogService
 *
 * *To remember*
 * When trying to inject a component into the DialogService the component must be included
 * in a *not LazyLoaded* module as entryComponent
 *
 * *IMPORTANT*
 * When passing data into the component it's important to initiate the fields in the component with expected data
 * The reason for this is if the field is not initiated the DialogService will throw
 * an error that trying to inject data into
 * an undefined (not initiated) field in the component.
 * This is happening because `hasOwnProperty` doesn't recognize *@Input()* fields that are
 * undefined when part of an component instance
 * As this fields are being initialized after ngOnInit()
 *
 * @example
 *
 * 1. This works
 * export class TestComponent {
 *  @Input() data: string[] = [];
 * }
 *
 * 2. This will not works
 * export class TestComponent {
 *  @Input() data: string[];
 * }
 *
 * this.dialogService.load(TestComponent, {
 *  data: ['data'] // This fields must be initiated in TestComponent as in the first part of the example
 * })
 *
 */
@Injectable()
export class DialogService {
  private dialog?: NgbModalRef;
  private openDialogs$ = new BehaviorSubject<number>(0);

  get hasOpenDialogs(): Observable<boolean> {
    return this.openDialogs$.pipe(map(openDialogs => openDialogs > 0));
  }

  constructor(private ngbModal: NgbModal) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // load<T, R extends keyof T>(
  load<T>(
    component: T,
    data?: any, // {[P in R]: T[R]},
    dialogSettings?: NgbModalOptions,
  ): Promise<any> {
    this.dialog = this.ngbModal.open(component, {
      ...dialogSettings,
      centered: true,
      backdrop: false,
      animation: false,
    });
    this.openDialogs$.next(this.openDialogs$.value + 1);
    this.loadInputData(data);
    return this.getDialogRef().result;
  }

  close(reason?: any): void {
    if (this.dialog) {
      this.dialog.close(reason);
      this.openDialogs$.next(this.openDialogs$.value - 1);
    }
  }

  dismiss(reason?: any) {
    if (this.dialog) {
      this.dialog.dismiss(reason);
      this.openDialogs$.next(this.openDialogs$.value - 1);
    }
  }

  private getDialogRef() {
    return this.dialog;
  }

  private loadInputData<T>(data?: T): void {
    if (this.dialog && data) {
      const dialogContentComponentInstance = this.dialog.componentInstance;
      if (dialogContentComponentInstance) {
        Object.keys(data).forEach((dataKey: string) => {
          if (dialogContentComponentInstance.hasOwnProperty(dataKey)) {
            dialogContentComponentInstance[dataKey] = data[dataKey as keyof T];
          } else {
            console.error(
              `Tried to modify field "${dataKey}" which is not part of "${dialogContentComponentInstance.constructor}"`,
            );
          }
        });
      }
    }
  }
}
