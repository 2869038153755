import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {FieldLabel} from '@shared/analysis/field-label';
import * as moment from 'moment';
import {createItem} from 'src/app/modules/client/summary/store/summary.helpers';
import {
  documentTypes,
  maritalStatus,
} from 'src/app/modules/financial-analysis/family/components/member-detail/family-member.definitions';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {Category, CategoryType, Item, ItemsGroup, Section} from './summary.models';

export const selectFamily = createSelector(getFamilyMembers, familyMembers =>
  createSection(familyMembers),
);

function createSection(familyMembers: FamilyMember[]): Section {
  return {
    label: 'Rodina',
    categories: [createCategory(familyMembers)],
  };
}

function createCategory(familyMembers: FamilyMember[]): Category {
  return {
    categoryTypes: [createCategoryType(familyMembers)],
  };
}

function createCategoryType(familyMembers: FamilyMember[]): CategoryType {
  return {
    itemsGroups: familyMembers.map(familyMember => createItemsGroup(familyMember)),
  };
}

function createItemsGroup(familyMember: FamilyMember): ItemsGroup {
  return {
    label: `${familyMember.firstName} ${familyMember.lastName}, ${familyMember.age} let`,
    items: createItems(familyMember),
  };
}

function createItems(familyMember: FamilyMember): Item[] {
  const items: Item[] = [];
  items.push(createItem(FieldLabel.BIRTH_DATE, formatDate(familyMember.birthDate)));
  items.push(
    createItem(
      FieldLabel.PHONE_NUMBER,
      familyMember.phoneNumber?.replace(
        /(\D{1}\d{3})(\d{3})(\d{3})(\d{3})/,
        '$1\xa0$2\xa0$3\xa0$4',
      ),
    ),
  );
  items.push(createItem(FieldLabel.EMAIL, familyMember.email));
  items.push(createItem(FieldLabel.BIRTH_NUMBER, familyMember.birthNumber));
  items.push(createItem(FieldLabel.STREET, familyMember.street));
  items.push(createItem(FieldLabel.CITY, familyMember.city));
  items.push(createItem(FieldLabel.ZIP_CODE, familyMember.zipCode));
  items.push(
    createItem(FieldLabel.MARTIAL_STATUS, getMartialStatusLabel(familyMember.maritalStatus)),
  );
  items.push(createItem(FieldLabel.PLACE_OF_BIRTH, familyMember.placeOfBirth));
  items.push(createItem(FieldLabel.NATIONALITY, familyMember.nationality));
  items.push(createItem(FieldLabel.DOCUMENT_TYPE, getDocumentTypeLabel(familyMember.documentType)));
  items.push(createItem(FieldLabel.DOCUMENT_NUMBER, familyMember.documentNumber));
  items.push(createItem(FieldLabel.AUTHORITY, familyMember.authority));
  items.push(createItem(FieldLabel.STATE_AUTHORITY, familyMember.stateAuthority));
  items.push(createItem(FieldLabel.DATE_OF_EXPIRY, formatDate(familyMember.dateOfExpiry)));

  return items.filter(Boolean);
}

function getMartialStatusLabel(memberMartialStatus: string): string {
  const martialStatus = maritalStatus.find(
    status => (status.key as string) === memberMartialStatus,
  );
  return martialStatus ? (martialStatus.label as string) : null;
}

function getDocumentTypeLabel(key: string): string {
  const documentType = documentTypes.find(type => (type.key as string) === key);
  return documentType ? (documentType.label as string) : null;
}

function formatDate(isoString: string): string | null {
  const date = moment(isoString);
  return date.isValid() ? date.format('DD.MM.YYYY') : null;
}
