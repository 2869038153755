import {RiskSummaryTable, RiskSummaryResult} from './investment-survey.models';

export function getSummaryResultsFor(
  points: number,
  summaryTable: RiskSummaryTable,
): RiskSummaryResult {
  let result;
  Object.entries(summaryTable).forEach(([key, value]) => {
    if (value.range(points)) {
      result = {key, value: value.i18n};
      return;
    }
  });

  return result;
}
