import {FamilyMember} from '@generated/defs/FamilyMember';
import {
  IndependenceInputMode,
  RetirementInputMode,
} from '@shared/analysis/forms/definitions.models';
import {Asset, AdvisorProposalState, ClientProposalState} from '@shared/analysis/models/asset';
import {
  ObjectivesAsset,
  PropertyRequirementsAsset,
  VehicleRequirementsAsset,
} from '@shared/analysis/models/objectives';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {GraphInput} from '@shared/ui/graph/graph.component';
import {FinancesPropertyData} from 'src/app/modules/financial-plan/store/financial-plan.models';

export interface FinancialPlan {
  financial: FinancesPropertyData;
  properties: FinancesPropertyData;
  objectives: FinancialPlanObjectives;
}

export interface FinancialPlanObjectives {
  [ObjectiveType.Family]: FamilyObjective[];
  [ObjectiveType.Property]: PropertyObjective[];
  [ObjectiveType.Vehicle]: VehicleObjective[];
  [ObjectiveType.Pension]: PensionObjective[];
  [ObjectiveType.FinancialResources]: FinancialResourcesObjective[];
  [ObjectiveType.Independence]: FinancialIndependenceObjective[];
  [ObjectiveType.Housing]: FinancialResourcesObjective[];
  [ObjectiveType.Reserve]: FinancialResourcesObjective[];
  [ObjectiveType.AdditionalContracts]: AdditionalContractsObjective[];
}

export interface Objective {
  type: ObjectiveType;
  objectiveAsset: ObjectivesAsset;
  objectiveValue: number;
  name: string;
  introText: string;
  table: {
    current: Table;
    advisorProposed: Table;
    clientProposed: Table;
  };
  advisorProposedBenefit?: number;
  clientProposedBenefit?: number;
}

export interface FamilyObjective extends Objective {
  type: ObjectiveType.Family;
  familyMember: FamilyMember;
  input: InputRisk[];
  additionalRisksFromAdvisor: InputRisk[];
  currentRisksNotInInput: InputRisk[];
  chart: InsuranceRisk[];
}

export interface PropertyObjective extends Objective {
  type: ObjectiveType.Property;
  requirementsAsset: PropertyRequirementsAsset;
  propertyAsset: PropertyAsset;
  input: InputRisk[];
  chart: InsuranceRisk[];
}

export interface VehicleObjective extends Objective {
  type: ObjectiveType.Vehicle;
  requirementsAsset: VehicleRequirementsAsset;
  propertyAsset: PropertyAsset;
  input: InputRisk[];
  chart: InsuranceRisk[];
}

export interface FinancialResourcesObjective extends Objective {
  input: ObjectiveInput;
  chart: GraphInput;
}

export interface PensionObjective extends Objective {
  type: ObjectiveType.Pension;
  familyMemberUuid: string;
  familyMember: FamilyMember;
  input: PensionInput | null;
  chart: GraphInput;
}

export interface FinancialIndependenceObjective extends Objective {
  type: ObjectiveType.Independence;
  input: IndependenceInput | null;
  chart: GraphInput;
}

export interface AdditionalContractsObjective extends Objective {
  input: string[];
  advisorProposal: string;
}

export interface ObjectiveInput {
  requiredValue: number;
  finalDate: string;
}

export interface PensionInput {
  inputMode: RetirementInputMode;
  reserve: number;
  monthlyValue: number;
  statePension: number;
  ownMonthlyValue: number;
  totalMonthlyValue: number;
  reserveYear: number;
  pensionDuration: number;
  pensionLeaveYear: number;
  pensionLeaveAge: number;
}

export interface IndependenceInput {
  inputMode: IndependenceInputMode;
  reserve: number;
  monthlyValue: number;
  reserveYear: number;
  lifeExpectancy: number;
  independenceDuration: number;
  independenceStartYear: number;
  independenceStartAge: number;
}

export interface Table {
  rows: Row[];
  sum: Sum;
  fulfillment: Fulfillment;
  rating: number;
}

export interface Fulfillment {
  type: FulfillmentType;
  percent?: number;
  absoluteSelected?: number;
  absoluteTotal?: number;
}

export enum FulfillmentType {
  Percent = 'percent',
  Absolute = 'absolute',
}

export interface Row {
  assetUuid: string;
  advisorProposalState: AdvisorProposalState;
  clientProposalState: ClientProposalState;
  asset: Asset;
  name: string;
  category: string;
  presentValue?: number;
  monthlyValue?: number;
  finalValue?: number;
  yearlyRate?: number;
  stakeholder?: boolean;
  skipSum?: boolean;
}

export interface Sum {
  presentValue?: number;
  monthlyValue?: number;
  finalValue?: number;
}

export interface InsuranceRisk {
  key: string;
  heading: string;
  currentValue: number;
  advisorProposedValue: number;
  clientProposedValue: number;
  computedValue?: number;
}

export interface AssetRisk {
  key: string;
  value: number;
}

export interface InputRisk {
  key: string;
  label: string;
  limit?: number;
}

export interface CompletionState {
  completeForAdvisorOverall: boolean;
  completeForClientOverall: boolean;
  objectives: ObjectiveCompletion[];
}

export interface ObjectiveCompletion {
  objectiveUuid: string;
  completeForAdvisor: boolean;
  completeForClient: boolean;
}
