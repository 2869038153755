<div
  #graphWrapper
  class="col financial-analysis-graph"
  [class.drag-active]="dragActive"
  [class.white-bg]="whiteBackground"
>
  <svg
    [attr.width]="graphWrapper.offsetWidth > 0 ? graphWrapper.offsetWidth - wrapperPadding : 0"
  ></svg>
</div>
