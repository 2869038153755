import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DirectivesModule} from '@shared/directives/directives.module';
import {FinancialAnalysisGraphComponent} from './financial-analysis-graph.component';
import {FinancialAnalysisGraphDeprecatedComponent} from './financial-analysis-graph.deprecated.component';

@NgModule({
  declarations: [FinancialAnalysisGraphComponent, FinancialAnalysisGraphDeprecatedComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [FinancialAnalysisGraphComponent, FinancialAnalysisGraphDeprecatedComponent],
})
export class FinancialAnalysisGraphModule {}
