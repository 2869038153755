import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Section} from 'src/app/modules/client/summary/store/summary.models';

@Component({
  selector: 'kpt-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements OnInit {
  @Input()
  section: Section;

  get showSection() {
    return this.section?.headerItems?.length > 0 || this.section?.categories?.length > 0;
  }

  constructor() {}

  ngOnInit(): void {}
}
