import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ModalModule, StepperProgressModule} from '@shared/ui';
import {BusinessToolModalComponent} from './business-tool-modal.component';

@NgModule({
  declarations: [BusinessToolModalComponent],
  imports: [CommonModule, RouterModule, ModalModule, StepperProgressModule],
})
export class BusinessToolModule {}
