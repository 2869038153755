import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '@shared/ui/forms/input/input.module';
import {ConfirmFieldComponent} from './confirm-field.component';

@NgModule({
  declarations: [ConfirmFieldComponent],
  imports: [InputModule, ReactiveFormsModule],
  exports: [ConfirmFieldComponent],
})
export class ConfirmFieldModule {}
