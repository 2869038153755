<kpt-modal-wrapper
  *ngIf="data$ | async as assetData"
  [heading]="assetData.name"
  [topLeftTemplate]="topLeft"
  [hideClose]="isSubModal"
  [class.d-none]="subModalOpened"
  (close)="close()"
>
  <ng-template #topLeft>
    <div *ngIf="isCommonFinancialProductAsset(assetData.model)" class="partner-logo">
      <ktl-partner-logo
        *ngIf="assetData.model?.proposal || !isForeignContract(assetData.model); else noPartnerLogo"
        [partnerCode]="assetData.model?.partnerId"
        size="4.44rem"
      ></ktl-partner-logo>
      <ng-template #noPartnerLogo>
        <img src="/assets/images/import.svg" alt="Cizí smlouva" />
      </ng-template>
    </div>
  </ng-template>

  <div *ngIf="isSubModal && !selectedType" [formGroup]="chooseAssetTypeForm" class="row">
    <div class="m-auto col-6">
      <kpt-select
        [label]="newAssetTypeSelect"
        [options]="assetData.assetTypeOptions"
        formControlName="chooseAssetTypeFormControl"
        (selected)="findDefinition($event)"
      ></kpt-select>
    </div>
  </div>
  <kpt-asset-form *ngIf="assetData.model" [value]="assetData.model"></kpt-asset-form>
  <div class="text-center pt-4">
    <kpt-button
      *ngIf="isSubModal"
      class="mr-5"
      label="Jít zpět"
      (action)="onBack.emit()"
      [hasOutline]="true"
      type="primary"
    >
    </kpt-button>
    <kpt-button
      *ngIf="showDeleteButton"
      class="mr-5"
      (action)="remove()"
      [isSubmitButton]="false"
      label="Smazat"
      [hasOutline]="true"
      type="primary"
    >
    </kpt-button>
    <kpt-button
      (action)="save()"
      [isSubmitButton]="true"
      [isDisabled]="!assetData.model"
      [isLoading]="loading$ | async"
      label="Uložit"
      type="primary"
    >
    </kpt-button>
    <div *ngIf="devMode">
      <details>
        <summary>Model</summary>
        <pre style="text-align: left">{{ assetData.model | jsonSafe }}</pre>
      </details>

      <details *ngIf="coreContract$ | async as coreContract">
        <summary>Core</summary>
        <pre style="text-align: left">{{ coreContract | jsonSafe }}</pre>
      </details>

      <details>
        <summary>Validation</summary>
        <pre style="text-align: left">
RequiringAttention: {{ fieldsRequiringAttention(assetData) }}</pre
        >
        <pre style="text-align: left">Validators: {{ assetData.validators | jsonSafe }}</pre>
      </details>
    </div>
  </div>
</kpt-modal-wrapper>

<kpt-asset-detail-modal
  *ngIf="subModalOpened"
  [assetUuid]="subModalData.assetUuid"
  [currentAssetType]="subModalData.currentAssetType"
  (onBack)="closeSubModal()"
  [newAssetTypeGroup]="subModalData.newAssetTypeGroup"
  [result$]="subModalData.result$"
  [isSubModal]="true"
>
</kpt-asset-detail-modal>
