import {Component, Input} from '@angular/core';

@Component({
  selector: 'kpt-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  @Input()
  value: string;

  @Input()
  placeholder: string;
}
