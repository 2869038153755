import {Directive, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {FormGroupDirective, NgForm} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BackendValidationService} from '@shared/backend-validation/backend-validation.service';

@UntilDestroy()
@Directive({
  selector: '[kptBackendValidation]',
})
export class BackendValidationFormDirective implements OnInit, OnDestroy {
  @Input() kptBackendValidation: string;

  constructor(
    private backendValidationService: BackendValidationService,
    @Optional() private formGroup: FormGroupDirective,
    @Optional() private ngForm: NgForm,
  ) {
    if (!this.formGroup && !this.ngForm) {
      throw new Error('kptBackendValidation must be used with formGroup or ngForm');
    }
  }

  ngOnInit() {
    this.backendValidationService.activate(this.kptBackendValidation);
  }

  ngOnDestroy() {
    this.backendValidationService.deactivate();
  }
}
