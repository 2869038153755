import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {map, mapTo, withLatestFrom} from 'rxjs/operators';
import {periodicPayment} from 'src/shared/analysis/formulas';
import {
  ConsumerActionTypes,
  UpdateConsumerInput,
  UpdateConsumerInputOutput,
} from 'src/store/actions/consumer.actions';
import {getConsumerState} from 'src/store/selectors/consumer.selectors';
import {State} from '../index';

@Injectable()
export class ConsumerCreditEffects {
  // fire UpdateConsumerInput action at startup
  initEffect$ = createEffect(() =>
    this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), mapTo(new UpdateConsumerInput({}))),
  );

  updateConsumerInput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsumerActionTypes.UpdateInput),
      map((action: UpdateConsumerInput) => action.payload),
      withLatestFrom(this.store.pipe(select(getConsumerState))),
      map(([partialInput, state]) => {
        const input = {...state.input, ...partialInput};
        const monthlyRepayment = periodicPayment(
          input.interest / 100 / 12,
          input.loanTerm,
          input.loan,
        );
        const output = {...state.output, monthlyRepayment};

        return new UpdateConsumerInputOutput({input, output});
      }),
    ),
  );

  constructor(private actions$: Actions, private store: Store<State>) {}
}
