import {
  InvestmentSurveyAction,
  InvestmentSurveyActions,
} from '@shared/investment-survey-old/store/investment-survey.actions';
import {
  initialInvestmentSurveyState,
  InvestmentSurveyState,
} from '@shared/investment-survey-old/store/investment-survey.state';
import produce from 'immer';

const producer = (state: InvestmentSurveyState, action: InvestmentSurveyAction) =>
  InvestmentSurveyActions.match(action, {
    IS_SetData: data => {
      state.surveyAnswers = data.surveyAnswers;
    },
    IS_UpdateData: data => {
      state.surveyAnswers = data.surveyAnswers;
    },
    default: () => {},
  });

export function reducer(
  state = initialInvestmentSurveyState,
  action: InvestmentSurveyAction,
): InvestmentSurveyState {
  return produce(producer)(state, action);
}
