import {DecimalPipe} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EventData, EventSortingFnc} from '@shared/ui/financial-analysis-graph/event-sorting.fnc';
import {getAgeInYear, getYearFromAge} from '@shared/utils';
import * as d3 from 'd3';
import * as d3tip from 'd3-tip';
import {isNil, uniq} from 'lodash';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {GRAPH_CONST} from './financial-analysis-graph.constant';
import {
  AnalysisGraphType,
  IAnalysisTimeLineItem,
  IFromTo,
  IGraphEvents,
} from './models/financial-analysis-graph.model';

@UntilDestroy()
@Component({
  selector: 'kpt-financial-analysis-graph-deprecated',
  templateUrl: './financial-analysis-graph-depracated.component.html',
  styleUrls: ['./financial-analysis-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinancialAnalysisGraphDeprecatedComponent
  implements AfterViewInit, OnChanges, OnInit, OnDestroy
{
  @Input() events: IGraphEvents[];
  @Input() timeLine: IAnalysisTimeLineItem[];
  @Input() personalData: any;
  @Input() fromTo: IFromTo;
  @Input() dataTransferData: string;
  @Input() dragActive = false;
  @Input() graphType = AnalysisGraphType.OBJECTIVES;
  @Input() tooltipIsOpen = false;
  @Output() eventAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() tooltipOpenAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() tooltipCloseAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() dragendUpdateAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() dragendRemoveAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('graphWrapper', {static: true}) graphWrapper: ElementRef;

  isReviewGraph = false;
  wrapperPadding = 8;

  resizeEvent$ = fromEvent(window, 'resize').pipe(debounceTime(200), untilDestroyed(this));
  private age: number;
  private dragAllowed = false;
  private lifeLine: d3.ScaleLinear<number, number>;
  private originRectXOffset: number;
  private originRectYOffset: number;

  // elements dimensions
  private colWidth: number;
  private rectHeight = 25;
  private rectRadius = 5;
  private tooltipWidth = 30;

  // life-graph offsets
  private imgOffset = -(
    GRAPH_CONST.LIFE_LINE.OFFSET_Y +
    GRAPH_CONST.SILHOUETTE.HEIGHT +
    GRAPH_CONST.LIFE_LINE.TEXT_OFFSET_Y +
    15
  );

  private baseLine = 20;
  private addLine = this.rectHeight + 10;
  private textOffset = 10;
  private offsetEventData: EventData[] = [];
  private svg: d3.Selection<d3.BaseType, {}, null, undefined>;
  private tickSize = 5;
  private wrapper: HTMLElement;
  private wrapperRect: ClientRect | DOMRect;

  private actualDraggedValue: number;

  private tip = (d3tip as any)
    .default()
    .attr('class', 'tooltip-graph-line-content')
    .offset([-5, 0])
    .html((d: any) => {
      return (
        `${getAgeInYear(d.year, this.personalData.age || GRAPH_CONST.MIN_AGE)} let (${
          d.year
        })<br>` +
        `příjem: ${this.decimalPile.transform(d.income)} Kč<br>` +
        `výdaje: ${this.decimalPile.transform(d.cost)} Kč<br>` +
        `úspory: ${this.decimalPile.transform(d.saving)} Kč`
      );
    });

  constructor(
    private cd: ChangeDetectorRef,
    private decimalPile: DecimalPipe,
    private hostElement: ElementRef,
  ) {
    this.resizeEvent$.pipe(untilDestroyed(this)).subscribe(() => this.redraw());
  }

  ngOnInit() {
    this.isReviewGraph = this.graphType !== AnalysisGraphType.OBJECTIVES;
    this.age = this.personalData.age || GRAPH_CONST.MIN_AGE;
  }

  ngAfterViewInit() {
    this.wrapper = (this.hostElement.nativeElement as HTMLElement).querySelector<HTMLElement>(
      '.financial-analysis-graph',
    );
    // this.svg = d3.select(this.wrapper).append('svg');
    this.svg = d3.select(this.wrapper).select('svg');
    this.cd.markForCheck();
    setTimeout(() => {
      this.redraw();
    });
  }

  ngOnChanges() {
    this.redraw();
  }

  redraw() {
    if (!this.wrapper || !this.wrapper.offsetParent) {
      return;
    }

    this.closeTooltip();
    this.wrapperRect = this.wrapper.getBoundingClientRect();

    this.colWidth =
      (this.wrapperRect.width + GRAPH_CONST.COL_PADDING) / GRAPH_CONST.COL_COUNT -
      GRAPH_CONST.COL_PADDING;

    this.clearStage();
    this.initStage();
    if (this.isReviewGraph) {
      this.drawTodayLine();
      this.drawGraph();
    } else {
      this.addPersonGraphics();
    }
  }

  ngOnDestroy(): void {}

  private curveType = (context: CanvasRenderingContext2D | d3.Path) => {
    // return new CurveStepRounded(context, {
    //     distance: 0,
    //     shift: 0.5,
    //     tilt: 0,
    // });
    return d3.curveStep(context);
  };

  private initStage = () => {
    const data = this.events.filter(f => f.label);
    const indexes: any[] = Object.keys(data);
    const {from, to} = this.fromTo;
    const lifeLineRangeStart = this.isReviewGraph ? this.colWidth * 1.5 : this.colWidth;
    const tickValues = (): number[] => {
      return uniq([
        this.age,
        GRAPH_CONST.HALFLIFE_AGE,
        GRAPH_CONST.PENSION_AGE,
        GRAPH_CONST.MAX_AGE,
      ]);
    };

    this.lifeLine = d3
      .scaleLinear()
      .domain([getAgeInYear(from, this.age), getAgeInYear(to, this.age)])
      .range([lifeLineRangeStart, this.wrapperRect.width - this.wrapperPadding - this.colWidth]);

    this.svg
      .attr('width', this.wrapperRect.width - this.wrapperPadding)
      .attr('height', GRAPH_CONST.MAX_STAGE_HEIGHT + GRAPH_CONST.LIFE_LINE.OFFSET_Y)
      .attr('class', 'financial-analysis-graph-svg')
      .on('dragleave', () => {
        this.actualDraggedValue = null;
      })
      .on('dragover', () => {
        const stage = this.svg.node() as d3.ContainerElement;
        const x = d3.mouse(stage)[0];
        const newAge = Math.round(this.lifeLine.invert(x));
        if (this.actualDraggedValue !== newAge) {
          this.actualDraggedValue = newAge;
          if (this.isInDragArea()) {
            this.showActualTick(newAge);
          } else {
            this.removeActualTick();
          }
        }
        event.preventDefault();
      })
      .on('drop', () => {
        const getDroppedData = () => {
          try {
            return JSON.parse(d3.event.dataTransfer.getData(this.dataTransferData));
          } catch (e) {
            return null;
          }
        };
        const droppedData = getDroppedData();
        if (droppedData) {
          if (this.isInDragArea()) {
            const stage = this.svg.node() as d3.ContainerElement;
            const x = d3.mouse(stage)[0];
            const newAge = Math.round(this.lifeLine.invert(x));
            const newData = {
              ...droppedData,
              startYear: getYearFromAge(newAge, this.age),
            };
            this.dropAction.emit(newData);
            this.redraw();
          }
        }
      });

    this.svg.append('g').attr('class', 'line-chart');

    this.svg.append('g').attr('class', 'life-line');

    d3.select('svg .life-line')
      .append('g')
      .attr('class', 'axis')
      .call(
        d3
          .axisTop(this.lifeLine)
          .tickSize(this.tickSize)
          .tickValues(tickValues())
          .tickFormat((d: number) => {
            return d === this.age ? 'Dnes' : `${d}`;
          }),
      )
      .attr('transform', `translate(0, -${GRAPH_CONST.LIFE_LINE.OFFSET_Y})`)
      .selectAll('text')
      .style('text-anchor', 'start')
      .style('font-weight', 'unset')
      .attr('transform', `translate(-1, -7)`);

    d3.select('svg .life-line')
      .append('line')
      .attr('x1', this.lifeLine(this.age))
      .attr('y1', -GRAPH_CONST.LIFE_LINE.OFFSET_Y)
      .attr('x2', this.lifeLine(this.age))
      .attr('y2', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - 10)
      .attr('class', 'vertical-top-today line');

    d3.select('svg .life-line')
      .append('rect')
      .attr('class', 'drag-area')
      .attr('width', () => {
        const min = getAgeInYear(from, this.age);
        const max = getAgeInYear(to, this.age);
        return this.lifeLine(max) - this.lifeLine(min);
      })
      .attr('x', () => {
        const min = getAgeInYear(from, this.age);
        return this.lifeLine(min);
      })
      .attr('y', -(GRAPH_CONST.MAX_STAGE_HEIGHT + GRAPH_CONST.LIFE_LINE.OFFSET_Y))
      .attr('height', GRAPH_CONST.MAX_STAGE_HEIGHT + GRAPH_CONST.LIFE_LINE.OFFSET_Y);

    d3.select('svg .life-line')
      .selectAll('text.age')
      .data(indexes)
      .enter()
      .append('text')
      .attr('x', (d: number) => this.lifeLine(getAgeInYear(data[d].startYear, this.age)))
      .attr('y', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - GRAPH_CONST.LIFE_LINE.TEXT_OFFSET_Y)
      .style('text-anchor', 'start')
      .attr('class', 'text age')
      .text((d: number) => getAgeInYear(data[d].startYear, this.age));

    d3.select('svg .life-line')
      .selectAll('line.vertical-top')
      .data(indexes)
      .enter()
      .append('line')
      .attr('x1', (d: number) => this.lifeLine(getAgeInYear(data[d].startYear, this.age)))
      .attr('y1', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - GRAPH_CONST.LINE.VERTICAL_DASHED)
      .attr('x2', (d: number) => this.lifeLine(getAgeInYear(data[d].startYear, this.age)))
      .attr(
        'y2',
        -GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          GRAPH_CONST.LINE.VERTICAL_DASHED -
          GRAPH_CONST.MAX_STAGE_HEIGHT,
      )
      .attr('class', 'vertical-top line line--dashed dashed');

    d3.select('svg .life-line')
      .append('line')
      .attr('x1', this.isReviewGraph ? this.lifeLine(this.age) - 30 : 0)
      .attr('y1', -GRAPH_CONST.LIFE_LINE.OFFSET_Y)
      .attr('x2', this.wrapperRect.width)
      .attr('y2', -GRAPH_CONST.LIFE_LINE.OFFSET_Y)
      .attr('class', 'line fake-y-axis');

    this.addEvents(data, indexes);
    this.addLineEvents(indexes);
  };

  private addLineEvents = (indexes: any[]) => {
    const events = d3
      .select('svg .life-line')
      .selectAll('g.event-line')
      .data(indexes)
      .enter()
      .append('g')
      .attr('class', 'event-line')
      .lower();

    const lineGroup = events.append('g').attr('class', 'event-line-group');

    lineGroup.append('path').attr('d', 'M1,1').attr('class', 'event-rect-line').call(this.setLine);
  };

  private addEvents = (data: IGraphEvents[], indexes: any[]) => {
    const events = d3
      .select('svg .life-line')
      .selectAll('g.event')
      .data(indexes)
      .enter()
      .append('g')
      .attr('class', (d: any) => this.eventTooltipClass(data[d]));

    const rectGroup = events
      .append('g')
      .attr('class', (d: any) => this.getEventTypeClass(data[d]))
      .on('click', (d: number) => {
        this.closeTooltip();
        if (data[d].isDraggable) {
          this.eventAction.emit(data[d]);
        }
      })
      .call(
        d3
          .drag()
          .on('start', (d: number, index, elements) =>
            this.dragStarted(d, index, elements, this.lifeLine),
          )
          .on('drag', (d: number, index, elements) => {
            if (data[d].isDraggable) this.dragged(d, index, elements, this.lifeLine);
          })
          .on('end', (d: number, index, elements) => {
            this.dragended(d, index, elements, data, this.age, this.lifeLine);
          }),
      );

    rectGroup
      .append('text')
      .attr('x', (d: number) => {
        return this.textOffset + this.lifeLine(getAgeInYear(data[d].startYear, this.age));
      })
      .attr('dy', 0)
      .attr('y', -GRAPH_CONST.LIFE_LINE.OFFSET_Y)
      .style('text-anchor', 'start')
      .attr('class', 'event-label text')
      .text((d: number) => data[d].labelFormatted || data[d].label)
      .call(this.computeYOffsets, data)
      .call(this.moveToMiddle, this.rectHeight);

    rectGroup.append('path').attr('class', 'event-rect').call(this.setPath).lower();

    const tooltipGroup = events.append('g').attr('class', 'event-circle-group');

    tooltipGroup
      .append('path')
      .attr('class', 'event-circle')
      .on('click', (d: number, index, element) => {
        const svgRect = (this.svg.node() as d3.ContainerElement).getBoundingClientRect();
        const circleRect = element[index].getBoundingClientRect();
        const width = circleRect.width;
        const tx = circleRect.left - svgRect.left + this.wrapperPadding;
        const ty = circleRect.top - svgRect.top;
        this.toggleTooltipCircle(element[index].parentNode);
        this.tooltipOpenAction.emit({data: data[d], rect: {x: tx, y: ty, width}});
      })
      .call(this.setTooltip);

    tooltipGroup
      .append('text')
      .attr('x', (d: number) => {
        return (
          this.lifeLine(getAgeInYear(data[d].startYear, this.age)) +
          this.offsetEventData[d].width +
          this.tooltipWidth * 0.5 -
          this.rectRadius
        );
      })
      .attr('dy', 0)
      .attr('y', (d: number) => {
        return -(
          GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          this.baseLine -
          this.addLine * this.offsetEventData[d].yOffset -
          this.rectHeight / 2 -
          this.rectRadius
        );
      })
      .style('text-anchor', 'middle')
      .attr('class', 'event-label icon')
      .text(GRAPH_CONST.ICONS.bulb);
  };

  private clearStage = () => {
    d3.select(this.wrapper).selectAll('svg > *').remove();
  };

  private addPersonGraphics = () => {
    d3.select('svg .life-line')
      .append('svg:image')
      .attr('x', this.lifeLine(this.age) - GRAPH_CONST.SILHOUETTE.WIDTH / 2)
      .attr('y', this.imgOffset)
      .attr('width', GRAPH_CONST.SILHOUETTE.WIDTH)
      .attr('height', GRAPH_CONST.SILHOUETTE.HEIGHT)
      .attr('class', 'person')
      .attr(
        'xlink:href',
        GRAPH_CONST.PICTURES[this.personalData.sex as keyof typeof GRAPH_CONST.PICTURES],
      );
  };

  private drawGraph = () => {
    const lineChart = d3.select('g.line-chart');
    const lineChartMax = d3.max(this.timeLine, (d: any) => {
      return Math.max(d.income, d.cost);
    });
    const yScale = d3
      .scaleLinear()
      .domain([0, lineChartMax])
      .range([
        GRAPH_CONST.MAX_STAGE_HEIGHT -
          GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          10 +
          GRAPH_CONST.LIFE_LINE.OFFSET_Y,
        0,
      ]);
    const pathIncome = d3
      .area()
      .x((d: any) => this.lifeLine(getAgeInYear(d.year, this.age)))
      .y((d: any) => yScale(d.income))
      .curve(this.curveType);
    const pathCost = d3
      .area()
      .x((d: any) => this.lifeLine(getAgeInYear(d.year, this.age)))
      .y((d: any) => yScale(d.cost))
      .curve(this.curveType);
    const pathDifference = d3
      .area()
      .x((d: any) => this.lifeLine(getAgeInYear(d.year, this.age)))
      .y1((d: any) => yScale(Math.max(d.cost, d.income)))
      .y0((d: any) => yScale(d.income))
      .curve(this.curveType);

    const moneyLineOffsetX = this.lifeLine(this.age) - 20;
    d3.select('svg .life-line')
      .append('line')
      .attr('x1', moneyLineOffsetX)
      .attr('y1', -(GRAPH_CONST.LIFE_LINE.OFFSET_Y - GRAPH_CONST.MONEY_LINE.OFFSET_Y))
      .attr('x2', moneyLineOffsetX)
      .attr(
        'y2',
        -(
          GRAPH_CONST.MAX_STAGE_HEIGHT +
          GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          GRAPH_CONST.MONEY_LINE.OFFSET_Y
        ),
      )
      .attr('class', 'fake-y-axis line line--thick');

    d3.select('svg .life-line')
      .append('g')
      .attr('class', 'money-line')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(this.tickSize)
          .tickFormat((d: number) => {
            return `${this.decimalPile.transform(d)} Kč`;
          }),
      )
      .attr(
        'transform',
        `translate(${this.lifeLine(this.age) - 30},
        -${
          GRAPH_CONST.MAX_STAGE_HEIGHT +
          GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          GRAPH_CONST.MONEY_LINE.OFFSET_Y +
          5
        })`,
      )
      .selectAll('text')
      .style('text-anchor', 'end');

    lineChart
      .append('path')
      .attr('class', 'line-chart--paths difference')
      .attr('d', pathDifference(this.timeLine as any));

    lineChart
      .append('path')
      .attr('class', 'line-chart--paths line line--incomes')
      .attr('d', pathIncome(this.timeLine as any));

    lineChart
      .append('path')
      .attr('class', 'line-chart--paths line line--costs')
      .attr('d', pathCost(this.timeLine as any));

    lineChart
      .selectAll('.dot--incomes')
      .data(this.timeLine)
      .enter()
      .append('circle')
      .attr('class', 'line-chart--paths dot dot--incomes')
      .attr('cx', (d: any, _i) => {
        return this.lifeLine(getAgeInYear(d.year, this.age));
      })
      .attr('cy', (d: any) => {
        return yScale(d.income);
      })
      .attr('r', 6)
      .call(this.tip)
      .on('mouseover', this.tip.show)
      .on('mouseout', this.tip.hide);

    lineChart
      .selectAll('.dot-costs')
      .data(this.timeLine)
      .enter()
      .append('circle')
      .attr('class', 'line-chart--paths dot dot--costs')
      .attr('cx', (d: any, _i) => {
        return this.lifeLine(getAgeInYear(d.year, this.age));
      })
      .attr('cy', (d: any) => {
        return yScale(d.cost);
      })
      .attr('r', 6)
      .call(this.tip)
      .on('mouseover', this.tip.show)
      .on('mouseout', this.tip.hide);
  };

  private removeActualTick = () => {
    const actualTick = this.svg.selectAll('.tick-actual');
    actualTick.remove();
  };

  private showActualTick = (age: number) => {
    this.removeActualTick();
    const year = getYearFromAge(age, this.age);

    const actualTick = this.svg
      .select('svg .life-line')
      .insert('g', '.fake-y-axis')
      .attr('class', 'tick-actual');

    actualTick
      .append('text')
      .attr('x', this.lifeLine(getAgeInYear(year, this.age)))
      .attr('y', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - GRAPH_CONST.LIFE_LINE.TEXT_OFFSET_Y)
      .style('text-anchor', 'start')
      .attr('class', 'text age-actual-value')
      .text(age);

    actualTick
      .append('line')
      .attr('x1', this.lifeLine(getAgeInYear(year, this.age)))
      .attr('y1', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - 30)
      .attr('x2', this.lifeLine(getAgeInYear(year, this.age)))
      .attr('y2', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - 350)
      .attr('class', 'vertical-bottom line line--actual dashed');
  };

  private closeTooltip = (_fromElement: any = null) => {
    this.tooltipCloseAction.emit();
    this.toggleTooltipCircle();
  };

  private toggleTooltipCircle = (element: any = null) => {
    d3.selectAll('.event-circle-group').classed('event-circle-group--active', false);
    if (!isNil(element)) {
      d3.select(element).classed('event-circle-group--active', true);
    }
  };

  private moveToMiddle = (content: any, height: number) => {
    content.each((_d: any, index: number, element: any[]) => {
      const text = d3.select(element[index]);
      const textHeight = text.node().getBoundingClientRect().height;
      text.attr('transform', `translate(0, -${(height - textHeight) / 2 + 2})`);
    });
  };

  private setLine = (content: any) => {
    content.each((_d: any, index: number, element: any[]) => {
      const params = this.getEventParams(index);
      d3.select(element[index]).attr('d', this.computeLine(params.x, params.y, params.offset));
    });
  };

  private setPath = (content: any) => {
    content.each((_d: any, index: number, element: any[]) => {
      const params = this.getEventParams(index);
      d3.select(element[index]).attr(
        'd',
        this.computePath(params.x, params.y, params.offset, params.width),
      );
    });
  };

  private setTooltip = (content: any) => {
    content.each((_d: any, index: number, element: any[]) => {
      const params = this.getEventParams(index);
      d3.select(element[index]).attr(
        'd',
        this.computeTooltipPath(params.x, params.y, params.offset, params.width),
      );
    });
  };

  private getEventParams(index: number): {x: number; y: number; offset: number; width: number} {
    return {
      x: this.lifeLine(getAgeInYear(this.events[index].startYear, this.age)),
      y: -GRAPH_CONST.LIFE_LINE.OFFSET_Y,
      offset: this.baseLine + this.offsetEventData[index].yOffset * this.addLine,
      width: this.offsetEventData[index].width,
    };
  }

  private computeYOffsets = (content: any, data: IGraphEvents[]) => {
    const textWidths: {[key: string]: number} = {};
    content.each((_d: any, index: number, element: any[]) => {
      const parent = d3.select(element[index]).node().parentNode;
      textWidths[parent.childNodes[0].innerHTML] = parent.childNodes[0].getBBox().width;
    });

    this.offsetEventData = new EventSortingFnc(
      data.map((d: IGraphEvents) => {
        const tooltipGap = d.tooltip ? GRAPH_CONST.TOOLTIP_SPACE : 0;
        return {
          x: this.lifeLine(getAgeInYear(d.startYear, this.age)),
          y: 0,
          width: (textWidths[d.labelFormatted] || textWidths[d.label]) + 2 * this.textOffset,
          yOffset: 0,
          tooltipGap,
        };
      }),
    ).getOffsetData();

    content.each((_d: any, index: number, element: any[]) => {
      const offset = this.baseLine + this.offsetEventData[index].yOffset * this.addLine + 20;
      d3.select(element[index]).attr('y', offset - GRAPH_CONST.LIFE_LINE.OFFSET_Y);
    });
  };

  private computeLine(x: number, y: number, lineLength: number) {
    const bottomLeft = y + lineLength;

    return `M${x},${y}
            V${bottomLeft}`;
  }

  private computePath(x: number, y: number, lineLength: number, width: number) {
    const bottomLeft = y + lineLength + this.rectHeight;
    const bottomRight = x + width;
    const topRight = y + lineLength;

    return `M${x},${bottomLeft - this.rectHeight - this.rectRadius}
            V${bottomLeft - this.rectRadius}
            Q${x},${bottomLeft},${x + this.rectRadius},${bottomLeft}
            H${bottomRight - this.rectRadius}
            Q${bottomRight},${bottomLeft},${bottomRight},${bottomLeft - this.rectRadius}
            V${topRight}
            Q${bottomRight},${topRight - this.rectRadius},${bottomRight - this.rectRadius},${
      topRight - this.rectRadius
    }
            H${x}`;
  }

  private computeTooltipPath(x: number, y: number, lineLength: number, width: number) {
    const bottomLeft = y + lineLength + this.rectHeight;
    const bottomRight = x + width + this.tooltipWidth - this.rectRadius;
    const topRight = y + lineLength;

    return `M${bottomRight - this.tooltipWidth},${bottomLeft}
            H${bottomRight - this.rectRadius}
            Q${bottomRight},${bottomLeft},${bottomRight},${bottomLeft - this.rectRadius}
            V${topRight}
            Q${bottomRight},${topRight - this.rectRadius},${bottomRight - this.rectRadius},${
      topRight - this.rectRadius
    }
            H${bottomRight - this.tooltipWidth}`;
  }

  private dragStarted(_d: any, index: number, element: ArrayLike<Element>, lifeLine: any) {
    d3.selectAll('g .event-line').attr('style', e => (+e === index ? 'display: none' : ''));

    d3.selectAll('line.vertical-top.line.line--dashed.dashed').attr('style', e =>
      +e === index ? 'display: none' : '',
    );

    d3.selectAll('text.text.age').attr('style', e => (+e === index ? 'display: none' : ''));

    const draggedRectElement = d3.select(element[index]).node().querySelector('path');
    const path = draggedRectElement.getAttribute('d');
    this.originRectXOffset = d3.event.x - parseFloat(path.substr(1, path.indexOf(',') - 1));
    this.originRectYOffset =
      d3.event.y - parseFloat(path.slice(path.indexOf(',') + 1, path.indexOf('V')));
    this.showActualTick(Math.round(lifeLine.invert(d3.event.x - this.originRectXOffset)));
    this.dragAllowed = false;
  }

  private dragged = (_d: any, index: number, element: ArrayLike<Element>, lifeLine: any) => {
    const rect = d3.select(element[index]).node();
    d3.select(rect.parentNode as Element).classed('event-moving', true);

    this.dragAllowed = true;
    this.dragActive = true;

    const path = d3.select(element[index]).node().querySelector('path').getAttribute('d');

    const originX = parseFloat(path.substr(1, path.indexOf(',') - 1));
    const originY = parseFloat(path.slice(path.indexOf(',') + 1, path.indexOf('V')));
    const x = d3.event.x - originX - this.originRectXOffset;
    const y = d3.event.y - originY - this.originRectYOffset;

    d3.select(rect).attr('transform', `translate(${x},${y})`);

    const newAge = Math.round(lifeLine.invert(d3.event.x - this.originRectXOffset));
    this.actualDraggedValue = newAge;

    if (this.isInDragArea()) {
      this.showActualTick(newAge);
    } else {
      this.removeActualTick();
    }

    this.closeTooltip();
    this.cd.markForCheck();
  };

  private dragended = (
    d: any,
    _index: number,
    _element: ArrayLike<Element>,
    data: any,
    age: number,
    lifeLine: any,
  ) => {
    this.dragActive = false;
    this.cd.markForCheck();
    if (this.dragAllowed) {
      if (this.isInDragArea()) {
        const newAge = Math.round(lifeLine.invert(d3.event.x - this.originRectXOffset));
        const newYear = getYearFromAge(newAge, age);
        const updatedData = {
          typeIndex: d,
          data: {
            startYear: newYear,
          },
          values: data[d].values,
        };
        this.dragendUpdateAction.emit(updatedData);
      } else {
        this.dragendRemoveAction.emit(d);
      }
      this.redraw();
    }
  };

  private getEventTypeClass = (data: any) => {
    const eventRectClass = 'event-rect-group';
    const eventTypeClass = data.buttonType ? data.buttonType : '';
    const eventMoveClass = data.isDraggable ? 'move' : '';
    return [eventRectClass, eventTypeClass, eventMoveClass].join(' ');
  };

  private eventTooltipClass = (data: any) => {
    const baseEventClass = 'event';
    const hasTooltipClass = data.tooltip ? 'event-tooltip' : '';
    return [baseEventClass, hasTooltipClass].join(' ');
  };

  private isInDragArea = () => {
    const dragArea = d3.select('svg .drag-area');
    const [x, y] = d3.mouse(dragArea.node() as SVGGElement);
    const droppedX = x;
    const droppedY = y;
    const width = parseFloat(dragArea.attr('width'));
    const height = parseFloat(dragArea.attr('y'));
    const offsetX = parseFloat(dragArea.attr('x'));
    return droppedY < 0 && droppedY > height && droppedX - offsetX < width && droppedX > offsetX;
  };

  private drawTodayLine() {
    d3.select('svg .life-line')
      .append('line')
      .attr('x1', this.lifeLine(this.age))
      .attr('y1', -GRAPH_CONST.LIFE_LINE.OFFSET_Y - GRAPH_CONST.LINE.VERTICAL_DASHED)
      .attr('x2', this.lifeLine(this.age))
      .attr(
        'y2',
        -GRAPH_CONST.LIFE_LINE.OFFSET_Y -
          GRAPH_CONST.LINE.VERTICAL_DASHED -
          GRAPH_CONST.MAX_STAGE_HEIGHT,
      )
      .attr('class', 'vertical-top line line--dashed dashed');
  }
}
