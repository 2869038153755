<div class="row">
  <div class="col-sm-6">
    <kpt-slider
      [parentForm]="helperForm"
      [label]="to.label"
      sliderName="slider"
      [sliderOptions]="to.sliderOption"
    >
    </kpt-slider>
  </div>
  <div class="col-sm-6">
    <kpt-input
      label=""
      type="number"
      [formControl]="helperForm.get('input')"
      [required]="to.required"
      [appendText]="to.appendText"
      [readonly]="true"
      [disabledField]="to.disabled"
    >
    </kpt-input>
  </div>
</div>
