/* eslint-disable max-len */
import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {
  adjustPropertiesWithLoans,
  calculateChart,
  setupCurrentBalance,
} from '@shared/analysis/evaluation';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {ObjectiveAsset} from '@shared/analysis/models/objectives';
import {Frequency, Liquidity, ValueTable} from '@shared/analysis/models/unit';
import {selectCurrentAssets, selectObjectivesAssets} from '@shared/analysis/store';
import {transformAssetsToUnitsForFP} from '@shared/analysis/transformations-for-fp';
import {convertDateToAge} from '@shared/ui/formly/year-input-formly/year-input-formly.component';
import * as moment from 'moment';
import {ResultView} from 'src/app/modules/financial-analysis/sandbox/sandbox.models';
import {selectAdvisorProposedAssets} from 'src/app/modules/financial-plan/store/advisor-proposed-assets.selectors';
import {selectClientOrAdvisorProposedAssets} from 'src/app/modules/financial-plan/store/client-or-advisor-proposed-assets.selectors';
import {getFamilyHead} from 'src/store/selectors/family-member.selectors';
/* eslint-enable max-len */

const upperAge = 80;

export const selectCurrentEquity = createSelector(
  selectCurrentAssets,
  getFamilyHead,
  (currentAssets, familyHead): ValueTable => calculate(currentAssets, familyHead),
);

export const selectAdvisorProposedEquity = createSelector(
  selectAdvisorProposedAssets,
  getFamilyHead,
  (proposedAssets, familyHead): ValueTable => calculate(proposedAssets, familyHead),
);

export const selectClientOrAdvisorProposedEquity = createSelector(
  selectClientOrAdvisorProposedAssets,
  getFamilyHead,
  (proposedAssets, familyHead): ValueTable => calculate(proposedAssets, familyHead),
);

export const selectObjectivesInYears = createSelector(
  selectObjectivesAssets,
  getFamilyHead,
  (objectives, familyHead): AssetType[][] => {
    const currentAge = familyHead.age;
    const years = new Array<AssetType[]>(upperAge - currentAge + 1);
    for (const objective of objectives as ObjectiveAsset[]) {
      const objectiveAge = convertDateToAge(objective.start, currentAge);
      const index = objectiveAge - currentAge;
      if (index >= 0 && index < years.length) {
        years[index] = [...(years[index] ?? []), objective.type];
      }
    }
    return years;
  },
);

function calculate(assets: Asset[], familyMember: FamilyMember): ValueTable {
  if (!familyMember) return [];

  const startTime = new Date().getTime();

  assets = adjustPropertiesWithLoans(assets);
  let units = transformAssetsToUnitsForFP(assets);
  units = setupCurrentBalance(units);

  const result = calculateChart(
    moment().format('YYYY-MM-DD'),
    moment()
      .add(upperAge - familyMember.age, 'years')
      .format('YYYY-MM-DD'),
    units,
    Frequency.Year,
    [Liquidity.Low, Liquidity.High],
    ResultView.Equity,
  );
  // eslint-disable-next-line no-console
  console.log(`Execution took ${new Date().getTime() - startTime} ms`);
  return result;
}
