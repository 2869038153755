import {Injectable} from '@angular/core';
import {HousingDemand} from '@generated/defs/HousingDemand';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {map, mapTo, withLatestFrom} from 'rxjs/operators';
import {periodicPayment} from 'src/shared/analysis/formulas';
import {LoanRecommendation} from 'src/store/models/housing.models';
import {State as MortgageState} from 'src/store/reducers/housing.reducer';
import {
  HousingActionTypes,
  UpdateHousingInput,
  UpdateHousingInputOutput,
} from '../actions/housing.actions';
import {State} from '../index';
import {getHousingState} from '../selectors/housing.selectors';

@Injectable()
export class HousingEffects {
  // fire UpdateHousingInput action at startup
  initEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mapTo(new UpdateHousingInput({partialInput: {}, sliders: false})),
    ),
  );

  updateHousingInput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HousingActionTypes.UpdateInput),
      map((action: UpdateHousingInput) => action.payload),
      withLatestFrom(this.store.pipe(select(getHousingState))),
      map(([{partialInput, sliders}, state]) => {
        const input = changeRelatedInputs(state, partialInput, sliders);
        const monthlyRepayment = periodicPayment(
          input.interest / 100 / 12,
          input.loanTerm,
          input.loan,
        );
        const loanRecommendation = recommendLoan(input);
        const output = {...state.output, monthlyRepayment, loanRecommendation};

        return new UpdateHousingInputOutput({input, output});
      }),
    ),
  );

  constructor(private actions$: Actions, private store: Store<State>) {}
}

function changeRelatedInputs(
  state: MortgageState,
  partialInput: Partial<HousingDemand>,
  sliders: boolean,
) {
  const changed = {...state.input, ...partialInput};

  if (!Object.keys(partialInput).filter(key => ['value', 'savings', 'loan'].includes(key)).length)
    return changed;

  if (changed.value === changed.loan + changed.savings) return changed;

  if ('value' in partialInput) {
    if ('loan' in partialInput || sliders) {
      changed.savings = Math.min(changed.savings, changed.value);
      changed.savings = Math.max(changed.savings, Math.ceil(changed.value * 0.1));
      changed.loan = changed.value - changed.savings;
      return changed;
    }
    changed.loan = Math.floor(changed.value * 0.9);
    changed.savings = changed.value - changed.loan;
    return changed;
  }

  if ('loan' in partialInput) {
    changed.value = Math.max(changed.value, Math.ceil(changed.loan / 0.9));
    changed.savings = changed.value - changed.loan;
    return changed;
  }

  changed.savings = Math.max(changed.savings, Math.ceil(changed.value * 0.1));
  changed.value = Math.max(changed.value, changed.savings);
  changed.loan = changed.value - changed.savings;
  return changed;
}

function recommendLoan(input: HousingDemand): LoanRecommendation {
  if (!input.intent) {
    return LoanRecommendation.Both;
  }
  if (input.intent === 'settlementOfOwnership') {
    return LoanRecommendation.Both;
  }
  if (
    (input.intent === 'reconstruction' && !input.hasAnotherLiability && input.loan < 800_000) ||
    (input.hasBuildingSavings && input.targetSavings > input.loan)
  ) {
    return LoanRecommendation.BuildingSavings;
  }
  return LoanRecommendation.Mortgage;
}
