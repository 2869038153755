<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input
    label="Pojmenování cíle"
    type="text"
    name="name"
    [(ngModel)]="asset.name"
    required
  ></kpt-input>

  <kpt-switch
    [labelTemplate]="labelNew"
    [checked]="asset.requirementType === RequirementType.NewContract"
    [disabledField]="disabled"
    (click)="changeRequirementType(RequirementType.NewContract, $event)"
  >
    <ng-template #labelNew>
      <h3 class="section-header">Sjednat investici</h3>
    </ng-template>
  </kpt-switch>
  <div *ngIf="asset.requirementType === RequirementType.NewContract" class="section-body">
    <kpt-radio-group
      [options]="paymentOptions"
      itemClass="d-inline-block mr-6"
      name="payment"
      [(ngModel)]="asset.payment"
    ></kpt-radio-group>

    <kpt-row *ngIf="asset.payment === OtherObjectivePaymentType.OneTime">
      <kpt-objective-age-input
        grow
        label="Chci si to splnit ve věku"
        name="start"
        [(start)]="this.asset.start"
      ></kpt-objective-age-input>
      <kpt-input
        grow
        type="number"
        label="Očekávaná částka"
        appendText="Kč"
        name="value"
        [(ngModel)]="asset.value"
        [max]="defaultObjectiveMaxValue"
        required
      ></kpt-input>
    </kpt-row>

    <ng-container *ngIf="asset.payment === OtherObjectivePaymentType.Month">
      <kpt-slider2
        [value]="dateToYear(asset.start)"
        (valueChange)="asset.start = yearToDate($event)"
        [highValue]="dateToYear(asset.end)"
        (highValueChange)="asset.end = yearToDate($event)"
        [floor]="yearMin"
        [ceil]="yearMax"
        [translate]="sliderTranslate"
      ></kpt-slider2>
      <kpt-input
        grow
        type="number"
        label="Očekávaná částka"
        appendText="Kč"
        name="value"
        [(ngModel)]="asset.value"
        [max]="defaultObjectiveMaxValue"
        required
      ></kpt-input>
    </ng-container>

    <kpt-input
      label="Poznámka"
      type="textarea"
      rows="3"
      name="note"
      [(ngModel)]="asset.note"
    ></kpt-input>
  </div>

  <kpt-switch
    [labelTemplate]="labelChange"
    [checked]="asset.requirementType === RequirementType.ChangeContract"
    [disabledField]="disabled"
    (click)="changeRequirementType(RequirementType.ChangeContract, $event)"
  >
    <ng-template #labelChange>
      <h3 class="section-header">Změnit smlouvu</h3>
    </ng-template>
  </kpt-switch>
  <div *ngIf="asset.requirementType === RequirementType.ChangeContract" class="section-body">
    <kpt-input label="Poznámka" type="textarea" name="note" [(ngModel)]="asset.note"></kpt-input>
  </div>

  <kpt-switch
    [labelTemplate]="labelOther"
    [checked]="asset.requirementType === RequirementType.Other"
    [disabledField]="disabled"
    (click)="changeRequirementType(RequirementType.Other, $event)"
  >
    <ng-template #labelOther>
      <h3 class="section-header">Jiné</h3>
    </ng-template>
  </kpt-switch>
  <div *ngIf="asset.requirementType === RequirementType.Other" class="section-body">
    <kpt-input label="Poznámka" type="textarea" name="note" [(ngModel)]="asset.note"></kpt-input>
  </div>

  <kpt-investment-risk-profile [asset]="asset"></kpt-investment-risk-profile>
</ng-container>
