import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'kpt-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent {
  @Input() control: UntypedFormControl;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() appendText: string;
  @Input() label: string;
  @Input() descriptionI18N: string;

  DEFAULT_VALUE = '+420';

  focus() {
    if (!this.control.value) this.control.setValue(this.DEFAULT_VALUE);
  }

  blur() {
    if (this.control.value.trim() === this.DEFAULT_VALUE) this.control.setValue(null);
  }
}
