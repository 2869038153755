import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'kpt-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
})
export class ShowMoreComponent {
  @Input() open = false;
  @Input() openText = 'Zobrazit více';
  @Input() closeText = 'Zobrazit méně';
  @Input() prependTemplate?: TemplateRef<any>;
}
