import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {VehicleProvisionAsset} from '@shared/analysis/models/objectives';
import {selectMovableProperties} from '@shared/analysis/store';
import * as moment from 'moment';
import {first, map} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class VehicleProvisionAssetType extends BaseAssetType {
  constructor(private store: Store<State>) {
    super();
  }

  async create(): Promise<VehicleProvisionAsset> {
    const vehicles = await this.store
      .pipe(
        select(selectMovableProperties),
        map(assets => assets.filter(a => a.type === AssetType.Vehicle)),
        first(),
      )
      .toPromise();

    return {
      type: AssetType.VehicleProvision,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      risks: [],
      name: null,
      description: null,
      selectedAssets: vehicles.map(p => p.assetUuid),
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.VehicleProvision],
      type: AssetType.VehicleProvision,
    };
  }
}
