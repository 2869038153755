import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectAdultFamilyMembers} from '@shared/analysis/operators';
import {FAMILY_ID} from '@shared/constants';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LifeInsurancePriorityProducts} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {selectPriorityProducts} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-summary-priorities-results',
  templateUrl: './summary-priorities-results.component.html',
  styleUrls: ['./summary-priorities-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryPrioritiesResultsComponent implements OnInit {
  adultFamilyMembers$ = this.store.pipe(selectAdultFamilyMembers(), untilDestroyed(this));

  priorityProducts$: Observable<LifeInsurancePriorityProducts> = this.store.pipe(
    select(selectPriorityProducts),
    filter(r => Object.keys(r).length > 0),
    untilDestroyed(this),
  );

  constructor(
    private store: Store<State>,
    private lifeInsuranceDataService: LifeInsuranceDataService,
    @Inject(FAMILY_ID) private familyId: string,
  ) {}

  ngOnInit() {
    this.adultFamilyMembers$.subscribe(members => {
      this.lifeInsuranceDataService.loadPriorityQuestionnaire(
        this.familyId,
        members.map(m => m.sugarUuid),
      );
    });
  }
}
