import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {InputModule} from '../../forms/input/input.module';
import {DescriptionFormlyComponent} from './description-formly.component';

@NgModule({
  declarations: [DescriptionFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
  ],
  exports: [DescriptionFormlyComponent],
})
export class DescriptionFormlyModule {}
