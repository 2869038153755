import {NgModule} from '@angular/core';
import {BackendValidationControlDirective} from '@shared/backend-validation/backend-validation-control.directive';
import {BackendValidationFormDirective} from '@shared/backend-validation/backend-validation-form.directive';

@NgModule({
  declarations: [BackendValidationFormDirective, BackendValidationControlDirective],
  exports: [BackendValidationFormDirective, BackendValidationControlDirective],
  providers: [],
})
export class BackendValidationModule {}
