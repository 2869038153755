import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SimpleGraphComponent} from '@shared/ui/simple-graph/simple-graph.component';
import {ChartsModule} from 'ng2-charts';

@NgModule({
  declarations: [SimpleGraphComponent],
  imports: [CommonModule, ChartsModule],
  exports: [SimpleGraphComponent],
})
export class SimpleGraphModule {}
