import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CollapseModule} from '../../directives/collapse/collapse.module';
import {AccordionContainerComponent} from './accordion-container.component';
import {AccordionGroupComponent} from './accordion-group.component';

/**
 * Source code taken from the ngx-bootstrap library:
 * https://github.com/valor-software/ngx-bootstrap/blob/development/src/accordion/accordion.module.ts
 */
@NgModule({
  imports: [CommonModule, CollapseModule],
  declarations: [AccordionContainerComponent, AccordionGroupComponent],
  exports: [AccordionContainerComponent, AccordionGroupComponent],
})
export class AccordionModule {
  static forRoot(): ModuleWithProviders<AccordionModule> {
    return {ngModule: AccordionModule};
  }
}
