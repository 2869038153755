import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AddAssetButtonModule} from '@shared/lib/components/add-asset-button/add-asset-button.module';
import {ButtonModule} from 'src/shared/ui';
import {EmptyStateComponent} from './empty-state.component';

@NgModule({
  declarations: [EmptyStateComponent],
  imports: [CommonModule, RouterModule, AddAssetButtonModule, ButtonModule],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
