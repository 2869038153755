import {Observable, of, ReplaySubject} from 'rxjs';
import {switchMap} from 'rxjs/operators';

interface ToObservableOptions {
  observable?: PropertyKey;
  completeAfter?: PropertyKey;
  subject?: PropertyKey;
  storage?: PropertyKey;
}

/**
 * Creates observable of decorated property
 *
 * Decorated property can be of type any | Observable<any>,
 * is initiated before constructor and
 * is completed after method stated in options.completeAfter
 *
 * Under the hood it creates ReplaySubject
 * If name of Subject property is defined in options.subject param,
 * created Observable can be controlled explicitly via this Subject.
 *
 * Hello world! example: (https://stackblitz.com/edit/toobservable-decorator)
 *
 *   class MyClass {
 *
 *     @ToObservable({observable: 'bar', completeAfter: 'destroy'})
 *     foo: string;
 *
 *     bar: Observable<string>;
 *
 *     constructor() {
 *       this.foo = 'Hello';
 *       this.bar.pipe(
 *         filter(Boolean),
 *         map(str => str.toUpperCase()),
 *       ).subscribe(
 *         console.log,
 *       );
 *     }
 *
 *     destroy() {}
 *   }
 *
 *   const instance = new MyClass();
 *   instance.foo = 'world!';
 *   instance.destroy();
 *   instance.foo = 'This will not be logged.';
 *
 * Angular example:
 *
 *    @Component({
 *     template: '<div>{{ foo$ | async }}</div>',
 *   })
 *   export class MyComponent implements OnDestroy {
 *
 *     @Input() @ToObservable()
 *     foo: string | Observable<string> = 'Hello';
 *
 *     ngOnDestroy() {}
 *   }
 *
 * @param options
 *  observable - name of added Observable property (default: decoratedPropName + '$')
 *  completeAfter - name of method, after which Observable will be completed
 *  subject - name of added Subject property (default: Symbol)
 *  storage - name of private raw value storage (default: Symbol)
 */

export function ToObservable(options?: ToObservableOptions) {
  return (target: any, key: PropertyKey) => {
    const strKey = key.toString();
    // options and params preparation
    options = {
      storage: Symbol(`private storage for value of ${strKey}`),
      subject: Symbol(`source subject for ${strKey}`),
      observable: `${strKey}$`,
      completeAfter: 'ngOnDestroy',
      ...options,
    };
    const className = target.constructor.name;
    const automaticComplete = !!options.completeAfter;

    // some checks
    if (options.subject === strKey) {
      throw new Error(
        `${className}.${strKey}.subjectKey must differ from original name. E.g. "_${strKey}"`,
      );
    }

    if (options.observable === strKey) {
      throw new Error(
        `${className}.${strKey}.observableKey must differ from original name. E.g. "${strKey}$"`,
      );
    }

    // beware, due to the order of the decorators processing,
    // it checks only the collisions with the already declared properties
    if (Object.getOwnPropertyDescriptor(target, options.subject)) {
      throw new Error(
        `${className}.${strKey}.subjectKey overrides existing ${className}.${options.subject.toString()}!`,
      );
    }

    if (automaticComplete && !isFunction(target.constructor.prototype[options.completeAfter])) {
      throw new Error(
        `${className}.${options.completeAfter.toString()} declared as ` +
          `${className}.${strKey}.completeAfter is not function!`,
      );
    }

    // delete original property (probably not needed)
    if (!delete target[strKey]) {
      throw new Error(`${className}.${strKey} can't be modified!`);
    }

    function ensureSubject(that: any) {
      ensureProperty(that, options.subject, () => new ReplaySubject(1));
    }

    function ensureStorage(that: any) {
      ensureProperty(that, options.storage, () => undefined);
    }

    // define property accessors
    Object.defineProperty(target, key, {
      get() {
        ensureStorage(this);
        return this[options.storage];
      },
      set(newVal: any) {
        ensureStorage(this);
        this[options.storage] = newVal;
        ensureSubject(this);
        this[options.subject].next(newVal);
      },
      enumerable: true,
      configurable: true,
    });

    // define observable
    Object.defineProperty(target, options.observable, {
      get() {
        ensureProperty(this, options.observable, () => {
          ensureSubject(this);
          return this[options.subject].pipe(
            switchMap(source => (source instanceof Observable ? source : of(source))),
          );
        });
        return this[options.observable];
      },
      set() {
        throw new Error(`${className}.${options.observable.toString()} can't be modified!`);
      },
      enumerable: true,
      configurable: true,
    });

    // schedule completion
    if (automaticComplete) {
      wrapMethod(target, options.completeAfter, null, function () {
        if (this[options.subject]) this[options.subject].complete();
      });
    }
  };
}

// if does not exist, adds a property to the object
// and initialize it by value returned from initializeValue() call
function ensureProperty(target: any, key: PropertyKey, initializeValue: () => any = null) {
  if (!target.hasOwnProperty(key)) {
    Object.defineProperty(target, key, {
      value: initializeValue ? initializeValue() : null,
      writable: true,
      enumerable: false,
      configurable: true,
    });
  }
}

// wraps the object's method so that the given functions are triggered before and after it's call
function wrapMethod(
  target: any,
  key: PropertyKey,
  processBefore: (...args: any[]) => void,
  processAfter: (result: any) => void,
) {
  const originalDescriptor = Object.getOwnPropertyDescriptor(target, key);
  const newDescriptor = getWrappedMethodDescriptor(originalDescriptor, processBefore, processAfter);
  Object.defineProperty(target, key, newDescriptor);
}

function getWrappedMethodDescriptor(
  descriptor: TypedPropertyDescriptor<any>,
  processBefore: (...args: any[]) => void,
  processAfter: (result: any) => void,
): TypedPropertyDescriptor<any> {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    if (processBefore) {
      processBefore.apply(this, args);
    }
    const result = originalMethod.apply(this, args);
    if (processAfter) {
      processAfter.call(this, result);
    }
    return result;
  };

  return descriptor;
}

export function isFunction(obj: any): boolean {
  return typeof obj === 'function';
}
