import {formatPercent as formatPercentAngular} from '@angular/common';
import {formatCZK} from '@lib/utils';
import {isNilOrEmpty} from '@shared/lib';
import * as moment from 'moment';
import {Item} from 'src/app/modules/client/summary/store/summary.models';

export function createItem(label: string, value: string, assetUuid?: string): Item {
  if (isNilOrEmpty(value) && isNilOrEmpty(assetUuid)) return undefined;
  return {label, value, assetUuid};
}

export function formatDate(date: string): string {
  if (isNilOrEmpty(date)) return undefined;
  return moment(date).format('DD.MM.YYYY');
}

export function formatCZKNotNull(value: number): string {
  if (isNilOrEmpty(value)) return undefined;
  return formatCZK(value);
}

export function numberToString(value: number, suffix?: string): string {
  if (isNilOrEmpty(value)) return undefined;
  return `${value}${suffix ? ` ${suffix}` : ''}`;
}

export function getCheckboxTextValue(value: boolean): string {
  if (typeof value === 'boolean') {
    return value ? 'Ano' : 'Ne';
  }
  return undefined;
}

export function formatPercent(value: number) {
  if (isNilOrEmpty(value)) return undefined;
  return formatPercentAngular(value, 'cs-CZ', '0.0-2');
}
