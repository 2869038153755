/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {NgModule} from '@angular/core';
import {EffectsModule as NgrxEffectsModule} from '@ngrx/effects';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';

import {UiService} from '../../../controllers/Ui';
import {FormsSharedModule} from '../../forms-shared.module';
import {CreateOrUpdateAdvisorUiSettingsFormService} from './createOrUpdateAdvisorUiSettings.service';

import {CreateOrUpdateAdvisorUiSettingsEffects} from './states/effects';
import {CreateOrUpdateAdvisorUiSettingsReducer} from './states/reducers';
import {selectorName} from './states/reducers';

@NgModule({
  imports: [
    FormsSharedModule,
    NgrxStoreModule.forFeature(selectorName, CreateOrUpdateAdvisorUiSettingsReducer),
    NgrxEffectsModule.forFeature([CreateOrUpdateAdvisorUiSettingsEffects]),
  ],
  providers: [
    UiService,
    CreateOrUpdateAdvisorUiSettingsFormService,
  ],
})
export class CreateOrUpdateAdvisorUiSettingsModule {}
