import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {mergeMap, take} from 'rxjs/operators';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

/**
 * @deprecated
 */
@UntilDestroy()
@Injectable()
export class SynchronizationService {
  private familyUuid$ = this.store.pipe(select(getFamilyUuid), take(1));

  constructor(
    private store: Store<State>,
    private analysisService: AnalysisDataService,
    private lifeInsuranceService: LifeInsuranceDataService,
  ) {}

  /**
   * @deprecated
   */
  syncPersonsFromFinancialAnalysisToLifeInsurance() {
    this.familyUuid$
      .pipe(
        mergeMap(_familyUuid => this.analysisService.getLifeInsurancePersonsOld$().pipe(take(1))),
        untilDestroyed(this),
      )
      .subscribe(persons => this.lifeInsuranceService.setPersons(persons));
  }
}
