import {PurposesBuildingParamsEnum} from '@generated/controllers/Building';
import {ConsumerCreditDemand} from '@generated/defs/ConsumerCreditDemand';
import {HousingDemand, IntentHousingDemandEnum} from '@generated/defs/HousingDemand';

export function isHousing(input: HousingDemand | ConsumerCreditDemand): input is HousingDemand {
  return (input as HousingDemand).purposes !== undefined;
}

export const ANY_FIXATION = 0;

interface IntentOption {
  key: IntentHousingDemandEnum;
  label: string;
}

export const IntentOptions: IntentOption[] = [
  {key: 'buildingOrPurchase', label: 'Výstavbu či koupi'},
  {key: 'reconstruction', label: 'Rekonstrukci'},
  {key: 'refinancing', label: 'Refinancování'},
  {key: 'settlementOfOwnership', label: 'Vypořádání vlastnictví'},
];

interface PurposeOption {
  key: PurposesBuildingParamsEnum;
  label: string;
}

export const PurposeOptions: PurposeOption[] = [
  {key: 'house', label: 'Hypotéka na bydlení'},
  {key: 'building', label: 'Stavební spoření'},
  {key: 'refinancing', label: 'Refinancování hypotéky'},
  {key: 'american', label: 'Americká hypotéka'},
];

export const purposeNamesMap: {[key: string]: string} = PurposeOptions.reduce(
  (map: {[key: string]: string}, option) => {
    map[option.key] = option.label;
    return map;
  },
  {},
);

export const feeNamesMap: {[key: string]: string} = {
  m: 'měsíční',
  y: 'roční',
  b: 'počáteční',
  e: 'koncový',
};
