import {ValidatorFn} from '@angular/forms';
import {LabelType, TranslateFunction} from '@angular-slider/ngx-slider';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyFieldConfigCache} from '@ngx-formly/core/lib/components/formly.field.config';
import {FIELDS_REQUIRED_FOR_FINANCIAL_PLAN} from '@shared/analysis/asset.utils';
import {getYearsMonthsPluralString} from '@shared/lib';
import {IOption, SliderOptions} from '@shared/ui';
import {Observable} from 'rxjs';
import {FormlyTypes, FormlyWrappers} from './formly.enums';

// TODO: review our formly helper methods. There are two formly.utils.ts files.

export const getRadioGroupField = (
  key: string,
  label: string,
  options: IOption[],
  templateOptions: any = {},
  expressionProperties: any = {},
  defaultValue: any = null,
  hideExpression: string = null,
  validation: ValidatorFn[] = [],
  wrappers: string[] = [],
): FormlyFieldConfig => {
  return {
    type: FormlyTypes.RadioGroup,
    key,
    defaultValue,
    templateOptions: {
      ...templateOptions,
      label,
      options,
      valueProp: 'key',
      labelProp: 'label',
    },
    expressionProperties: {
      ...expressionProperties,
    },
    hideExpression,
    validators: {
      validation,
    },
    wrappers,
  };
};

export const getSelectField = (
  key: string,
  label: string,
  options: IOption[] | Observable<IOption[]>,
  templateOptions: any = {},
  parameters: any = {},
): FormlyFieldConfig => {
  let wrappers = [FormlyWrappers.CoreValue];
  if (FIELDS_REQUIRED_FOR_FINANCIAL_PLAN.includes(key)) {
    wrappers = [...wrappers, FormlyWrappers.ImportantValue];
  }

  return {
    type: FormlyTypes.Select,
    key,
    wrappers,
    ...parameters,
    templateOptions: {
      ...templateOptions,
      label,
      options,
    },
  };
};

export const getBasicField = (
  type: FormlyTypes,
  key: string,
  label: string,
  templateOptions: any = {},
  expressionProperties: any = {},
  hideExpression: any = null,
  validation: ValidatorFn[] = [],
  wrappers: string[] = [],
  config: FormlyFieldConfigCache = {},
): FormlyFieldConfig => {
  wrappers = [...wrappers, FormlyWrappers.CoreValue];
  if (FIELDS_REQUIRED_FOR_FINANCIAL_PLAN.includes(key)) {
    wrappers = [...wrappers, FormlyWrappers.ImportantValue];
  }

  return {
    ...config,
    type,
    key,
    expressionProperties: {
      ...expressionProperties,
    },
    templateOptions: {
      ...templateOptions,
      label,
    },
    hideExpression,
    validators: {
      validation,
    },
    wrappers,
  };
};

export const getSliderPercentField = (
  key: string,
  label: string,
  ceil = 1,
  templateOptions: any = {},
  expressionProperties: any = {},
  translate: TranslateFunction = sliderPercentRangeTranslate,
): FormlyFieldConfig => {
  return {
    key,
    type: FormlyTypes.SliderRange,
    defaultValue: 0,
    expressionProperties: {
      ...expressionProperties,
    },
    templateOptions: {
      ...templateOptions,
      label,
      sliderOption: {
        floor: 0,
        minRange: 0,
        step: 0.01,
        ceil,
        translate,
      } as SliderOptions,
    },
  };
};

export const getSliderDateRangeField = (
  key: string,
  label: string,
  ceil: number,
  templateOptions: any = {},
  translate: TranslateFunction = sliderDateRangeTranslate,
): FormlyFieldConfig => {
  return {
    key,
    type: FormlyTypes.SliderRange,
    defaultValue: new Date().getFullYear(),
    templateOptions: {
      ...templateOptions,
      label,
      sliderOption: {
        floor: new Date().getFullYear(),
        step: 1,
        ceil,
        translate,
      } as SliderOptions,
    },
  };
};

export const sliderDateRangeTranslate = (value: number, label: LabelType): string => {
  const duration = value - new Date().getFullYear();
  switch (label) {
    case LabelType.Low:
      return `za ${getYearsMonthsPluralString(duration * 12)} (${value})`;
    case LabelType.High:
      return `do ${getYearsMonthsPluralString(duration * 12)} (${value})`;
    case LabelType.Floor:
    case LabelType.Ceil:
      return duration + ' let';
    default:
      return value.toString();
  }
};

export const sliderPercentRangeTranslate = (value: number): string => {
  return `${Math.floor(value * 100)} %`;
};

export function createTabs(...fields: FormlyFieldConfig[]): FormlyFieldConfig {
  return {
    wrappers: [FormlyWrappers.Tabs],
    fieldGroup: [...fields],
    templateOptions: {
      activeTabIndex: 0,
    },
  };
}

export function createNamedGroup(
  groupName: string,
  ...fields: FormlyFieldConfig[]
): FormlyFieldConfig {
  return {
    templateOptions: {
      label: groupName,
    },
    wrappers: [FormlyWrappers.NamedGroup],
    fieldGroup: [...fields],
  };
}

export function createTab(
  id: string,
  tabName: string,
  ...fields: FormlyFieldConfig[]
): FormlyFieldConfig {
  return {
    id,
    templateOptions: {
      label: tabName,
    },
    wrappers: [FormlyWrappers.Tab],
    fieldGroup: [...fields],
  };
}

export function extendTab(
  originalTab: FormlyFieldConfig,
  position: 'start' | 'end',
  // eslint-disable-next-line comma-dangle
  ...fields: FormlyFieldConfig[]
): FormlyFieldConfig {
  let fieldGroup;

  if (position === 'start') {
    fieldGroup = [...fields, ...originalTab.fieldGroup];
  } else if (position === 'end') {
    fieldGroup = [...originalTab.fieldGroup, ...fields];
  }

  const tab: FormlyFieldConfig = {
    ...originalTab,
    fieldGroup,
  };

  return tab;
}

export function createFulfillmentField(
  expressionProperties: any = {},
  hideExpression: any = null,
): FormlyFieldConfig {
  return {
    type: FormlyTypes.Fulfillment,
    expressionProperties: {
      ...expressionProperties,
    },
    hideExpression,
  };
}

export function createDescriptionField(): FormlyFieldConfig {
  return {
    type: FormlyTypes.Description,
  };
}

export function createRisksLifeInsuranceField(): FormlyFieldConfig {
  return {
    type: FormlyTypes.RisksLifeInsurance,
  };
}

export function createLabelField(
  label: string,
  templateOptions: any = {},
  hideExpression: any = null,
): FormlyFieldConfig {
  return {
    type: FormlyTypes.Label,
    templateOptions: {
      ...templateOptions,
      label,
    },
    hideExpression,
  };
}

export const getRelatedAssetSelectField = (
  key: string,
  label: string,
  options: IOption[] | Observable<IOption[]>,
  templateOptions: any = {},
  parameters: any = {},
): FormlyFieldConfig => {
  let wrappers: FormlyWrappers[] = [];
  if (FIELDS_REQUIRED_FOR_FINANCIAL_PLAN.includes(key)) {
    wrappers = [...wrappers, FormlyWrappers.ImportantValue];
  }
  return {
    type: FormlyTypes.RelatedAssetSelect,
    key,
    wrappers,
    ...parameters,
    templateOptions: {
      ...templateOptions,
      label,
      options,
    },
  };
};
