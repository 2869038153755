import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {RadioGroupModule} from '@shared/ui/forms/radio-group/radio-group.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {RadioGroupFormlyComponent} from './radio-group-formly.component';

@NgModule({
  declarations: [RadioGroupFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioGroupModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
  ],
  exports: [RadioGroupFormlyComponent],
})
export class RadioGroupFormlyModule {}
