import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InvestmentSurveyModule} from '@shared/investment-survey-old/investment-survey.module';
import {InvestmentSurveySummaryComponent} from './investment-survey-summary.component';
import {InvestmentSurveySummaryService} from './investment-survey-summary.service';

@NgModule({
  declarations: [InvestmentSurveySummaryComponent],
  imports: [InvestmentSurveyModule, CommonModule],
  providers: [InvestmentSurveySummaryService],
  exports: [InvestmentSurveySummaryComponent],
})
export class InvestmentSurveySummaryModule {}
