import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {ofType, unionize, UnionOf} from 'unionize';

export const InvestmentSurveyActions = unionize(
  {
    IS_SetData: ofType<{surveyAnswers: SurveyAnswers[]}>(),
    IS_UpdateData: ofType<{surveyAnswers: SurveyAnswers[]}>(),
  },
  {tag: 'type', value: 'payload'},
);

export type InvestmentSurveyAction = UnionOf<typeof InvestmentSurveyActions>;
