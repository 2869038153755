import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED, RouterNavigatedAction} from '@ngrx/router-store';
import {map} from 'rxjs/operators';
import {StepperActions} from '../actions/stepper.actions';

@Injectable()
export class StepperEffects {
  navigated = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction) => {
        return StepperActions.ST_UpdateCurrent({url: action.payload.routerState.url});
      }),
    ),
  );

  constructor(private actions$: Actions) {}
}
