import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

const financialAnalysis = {
  url: 'financial-analysis',
  done: false,
  label: 'Finanční analýza',
};
const insuranceProtection = {
  url: 'insurance-protection',
  done: false,
  label: 'Pojistná ochrana',
};
const myChoice = {
  url: 'my-choice',
  done: false,
  label: 'Moje volba',
};
const priorities = {
  url: 'priorities',
  done: false,
  label: 'Priority',
};
const investmentSurvey = {
  url: 'investment-survey',
  done: false,
  label: 'Preference klienta',
};
const proposals = {
  url: 'proposals',
  done: false,
  label: 'Prezentace návrhu',
};
const summary = {
  url: 'summary',
  done: false,
  label: 'Shrnutí',
};

export const lifeInsuranceStepperProgressConfigOld: IStepperProgressItem[] =
  createStepperProgressConfig(
    [
      financialAnalysis,
      insuranceProtection,
      myChoice,
      priorities,
      investmentSurvey,
      proposals,
      summary,
    ],
    SecuredPage.Client,
    ClientPage.LifeInsuranceOld,
  );

export const lifeInsuranceStepperProgressConfigInModal: IStepperProgressItem[] = [
  insuranceProtection,
  myChoice,
];
