import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@shared/ui/button';
import {DropdownModule} from '@shared/ui/dropdown/dropdown.module';
import {TabComponent} from './tab.component';
import {TabsComponent} from './tabs.component';

@NgModule({
  declarations: [TabComponent, TabsComponent],
  exports: [TabComponent, TabsComponent],
  imports: [ButtonModule, CommonModule, DropdownModule],
})
export class TabsModule {}
