import {Injectable} from '@angular/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {Subject} from 'rxjs';
import {AssetTypeGroup, SubModalData} from './modal-wrapper/modal/asset-detail-modal.models';

@Injectable()
export class AssetDetailModalHandlerService {
  openSubModal$ = new Subject<SubModalData>();

  constructor(private assetsHandlerService: AssetsHandlerService) {}

  edit(assetUuid: string) {
    this.openSubModal$.next({assetUuid});
  }

  add(currentAssetType: AssetType, relatedUuidType: string) {
    this.openSubModal$.next({
      currentAssetType,
      newAssetTypeGroup:
        relatedUuidType === 'relatedPropertyUuid'
          ? AssetTypeGroup.Property
          : AssetTypeGroup.Objective,
    });
  }

  createAsset(model: Asset) {
    this.assetsHandlerService.addAsset(model);
  }

  updateAsset(model: Asset) {
    this.assetsHandlerService.updateAsset(model);
  }
}
