import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {defaultObjectiveMaxValue} from '@shared/analysis/forms/objectives.helpers';
import {ShortTermReserveAsset} from '@shared/analysis/models/objectives';
import {selectIndispensableExpensesSum} from '@shared/analysis/store';
import {CommonBaseModule} from '@shared/lib';
import {FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {map, take} from 'rxjs/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-short-term-reserve-form',
  templateUrl: './short-term-reserve-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    CommonBaseModule,
    InvestmentRiskProfileComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ShortTermReserveFormComponent {
  @Input() asset: ShortTermReserveAsset;

  reserve$ = this.store.pipe(
    select(selectIndispensableExpensesSum),
    map(expenses => 3 * expenses),
    untilDestroyed(this),
  );

  defaultObjectiveMaxValue = defaultObjectiveMaxValue;

  constructor(private store: Store<State>) {}

  setReserve(e: MouseEvent) {
    e.preventDefault();

    this.reserve$.pipe(take(1)).subscribe(reserve => {
      this.asset.value = reserve;
    });
  }
}
