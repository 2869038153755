import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'kpt-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  @Input() hasBorder = false;
  @Input() imgAlt: string;
  @Input() imgTitle: string;
  @Input() imgSrc: string;

  constructor(public domSanitizer: DomSanitizer) {}
}
