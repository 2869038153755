import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentTypeLifeInsuranceFileEnum} from '@generated/defs/LifeInsuranceFile';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {ProposalsAlternativesData} from '@shared/lib/components/modal/modals/proposals-alternatives/proposals-alternatives.component';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {cloneDeep, isEqual, keyBy, omit} from 'lodash';
import {pipe} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, take} from 'rxjs/operators';
import {
  InsuranceFormIcon,
  InsuranceFormVM,
  InsuranceGroupVM,
  PersonalInsuranceProposalVM,
  RiskFormVM,
  selectRecommendedMainFormsVM,
} from 'src/app/modules/life-insurance-old/5-proposals/proposals.selectors';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {
  LifeInsuranceCurrentInsurance,
  LifeInsuranceFormGroup,
  LifeInsuranceFormState,
  LifeInsurancePerson,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {selectAdultPersons} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {IModalSize, IOption} from 'src/shared/ui';
import {State} from 'src/store';
import {StoreFileUpdate} from '../../../../../shared/models/file.models';
import {InsuranceGroupStoreFile} from './insurance-group.models';

const DEBOUNCE_TIME = 100;

@UntilDestroy()
@Component({
  selector: 'kpt-insurance-group',
  templateUrl: './insurance-group.component.html',
  styleUrls: ['./insurance-group.component.scss'],
})
export class InsuranceGroupComponent implements OnChanges, OnDestroy {
  @Input() proposal: PersonalInsuranceProposalVM;
  @Input() group: LifeInsuranceFormGroup;
  @Output() opened = new EventEmitter<ElementRef>();
  formGroup: UntypedFormGroup;
  deleteConfirmations: Record<number, boolean> = {};
  LifeInsuranceFormState = LifeInsuranceFormState;
  LifeInsuranceFormGroup = LifeInsuranceFormGroup;
  InsuranceFormIcon = InsuranceFormIcon;

  currentInsuranceOptions: IOption[] = [
    {
      key: LifeInsuranceCurrentInsurance.HasInsurance,
      label: 'Mám životní pojištění a&nbsp;chci sdělit detaily',
    },
    {
      key: LifeInsuranceCurrentInsurance.DontTell,
      label: 'Mám životní pojištění, ale nechci sdělit detaily',
    },
    {
      key: LifeInsuranceCurrentInsurance.NoInsurance,
      label: 'Nemám životní pojištění',
    },
  ];

  personsMap: Record<string, LifeInsurancePerson> = {};
  activeFieldPath: (string | number)[];

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    private modalService: ModalService,
    private elementRef: ElementRef,
  ) {
    this.store
      .pipe(select(selectAdultPersons), untilDestroyed(this))
      .subscribe(persons => (this.personsMap = keyBy(persons, 'id')));
  }

  get personId(): string {
    return this.proposal.person.id;
  }

  get currentInsurance(): LifeInsuranceCurrentInsurance {
    return this.proposal.person.currentInsurance;
  }

  get showCurrentInsuranceSelection(): boolean {
    return (
      this.group === LifeInsuranceFormGroup.Current &&
      this.currentInsurance !== LifeInsuranceCurrentInsurance.HasInsurance
    );
  }

  get insuranceGroup(): InsuranceGroupVM {
    return this.proposal[this.group];
  }

  get documentType(): DocumentTypeLifeInsuranceFileEnum {
    return this.group === LifeInsuranceFormGroup.Current ? 'S' : 'MŽP';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.insuranceGroup.myChoice) {
      if (changes.proposal.firstChange) {
        this.createForm();
      } else {
        this.updateForm();
      }
    }
  }

  ngOnDestroy() {}

  trackByFormId(_: number, item: InsuranceFormVM) {
    return item.formId;
  }

  trackByRiskId(_: number, item: RiskFormVM) {
    return item.riskId;
  }

  toggleGroup() {
    this.store.dispatch(
      LifeInsuranceActions.toggleInsuranceGroup({
        personId: this.personId,
        group: this.group,
      }),
    );

    if (!this.insuranceGroup.expanded) {
      this.opened.emit(this.elementRef);
    }
  }

  currentInsuranceChanged(currentInsurance: LifeInsuranceCurrentInsurance) {
    this.store.dispatch(
      LifeInsuranceActions.updateCurrentInsurance({personId: this.personId, currentInsurance}),
    );
    if (currentInsurance === LifeInsuranceCurrentInsurance.HasInsurance) {
      this.addCurrentGroupForm();
    }
  }

  addGroupForm() {
    if (this.group === LifeInsuranceFormGroup.Recommended) {
      this.store.pipe(select(selectRecommendedMainFormsVM), first()).subscribe(recommended => {
        if (recommended[this.personId].length === 0) {
          this.addRecommendedGroupForm();
          return;
        }

        this.modalService.openModal({
          component: 'ProposalsAlternativesComponent',
          size: IModalSize.LG,
          data: {
            forms: recommended,
            personId: this.personId,
          } as ProposalsAlternativesData,
        });
      });
    } else this.addCurrentGroupForm();
  }

  addRecommendedGroupForm() {
    this.store.pipe(selectFamilyHead(), take(1)).subscribe(familyHead => {
      this.store.dispatch(
        LifeInsuranceActions.addRecommendedGroupForm({
          personId: this.personId,
          stakeholderId: familyHead.sugarUuid,
        }),
      );
    });
  }

  addCurrentGroupForm() {
    this.store.dispatch(
      LifeInsuranceActions.addCurrentGroupForm({
        personId: this.personId,
      }),
    );
  }

  updateFormName(form: InsuranceFormVM, name: string) {
    this.store.dispatch(
      LifeInsuranceActions.updateFormName({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
        name,
      }),
    );
  }

  updateFormPrice(form: InsuranceFormVM, price: number) {
    this.store.dispatch(
      LifeInsuranceActions.updateFormPrice({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
        price,
      }),
    );
  }

  deleteForm(form: InsuranceFormVM) {
    this.deleteConfirmations[form.formId] = true;
  }

  cancelDeletion(form: InsuranceFormVM) {
    this.deleteConfirmations[form.formId] = false;
  }

  confirmDeleteForm(form: InsuranceFormVM) {
    this.store.dispatch(
      LifeInsuranceActions.deleteInsuranceGroupForm({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
      }),
    );
    this.deleteConfirmations[form.formId] = false;
  }

  cancelForm(form: InsuranceFormVM) {
    this.store.dispatch(
      LifeInsuranceActions.cancelInsuranceGroupForm({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
      }),
    );
  }

  restoreForm(form: InsuranceFormVM) {
    this.store.dispatch(
      LifeInsuranceActions.restoreInsuranceGroupForm({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
      }),
    );
  }

  addFile(file: InsuranceGroupStoreFile) {
    this.store.dispatch(
      LifeInsuranceActions.addFormFile({
        personId: this.personId,
        group: this.group,
        formId: file.formId,
        file: {
          ...file,
          personId: this.personId,
          group: this.group,
          documentType: this.documentType,
        },
      }),
    );
  }

  removeFile(dmsUuid: string) {
    this.store.dispatch(LifeInsuranceActions.removeFormFiles({dmsUuids: [dmsUuid]}));
  }

  updateFile(fileUpdate: StoreFileUpdate) {
    this.store.dispatch(LifeInsuranceActions.updateFormFileName(fileUpdate));
  }

  isLastOfAlternatives(alternativeSetId: number, nextFormIndex: number): boolean {
    if (
      !alternativeSetId ||
      !this.insuranceGroup.forms[nextFormIndex] ||
      !this.insuranceGroup.forms[nextFormIndex].alternativeSetId
    )
      return false;

    return alternativeSetId !== this.insuranceGroup.forms[nextFormIndex].alternativeSetId;
  }

  saveActiveFieldPath(path: (string | number)[]) {
    this.activeFieldPath = path;
  }

  resetActiveFieldPath() {
    this.activeFieldPath = null;
  }

  private createForm() {
    this.formGroup = this.fb.group({
      forms: this.fb.array(this.createForms()),
    });
  }

  private createForms() {
    const pipes = pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged(isEqual),
      untilDestroyed(this),
    );

    return (this.insuranceGroup.forms || []).map(form => {
      const nameFormControl = new UntypedFormControl(form.name);
      nameFormControl.valueChanges.pipe(pipes).subscribe((name: string) => {
        this.updateFormName(form, name);
      });

      const priceFormControl = new UntypedFormControl(form.price);
      priceFormControl.valueChanges.pipe(pipes).subscribe((price: number) => {
        this.updateFormPrice(form, price);
      });

      return this.fb.group({
        ...form,
        name: nameFormControl,
        price: priceFormControl,
        risks: this.fb.array(
          form.risks.map(risk => {
            const formGroup = this.fb.group({...risk});
            formGroup.get('age').setValidators([Validators.max(128)]);
            formGroup.get('value').setValidators([Validators.min(0)]);
            formGroup.get('age').markAsTouched();
            formGroup.get('value').markAsTouched();
            formGroup.valueChanges.pipe(pipes).subscribe((riskForm: RiskFormVM) => {
              if (formGroup.valid) this.updateRiskForm(form, riskForm);
            });
            return formGroup;
          }),
        ),
      });
    });
  }

  private updateForm() {
    if (
      this.formGroup.get('forms').value.length !== this.insuranceGroup.forms.length ||
      (this.insuranceGroup.forms.length > 0 &&
        this.formGroup.get(['forms', 0, 'risks']).value.length !==
          this.insuranceGroup.forms[0].risks.length)
    ) {
      this.createForm();
    } else {
      const insuranceGroup = this.getInsuranceGroupWithoutActiveField();
      this.formGroup.get('forms').patchValue(insuranceGroup.forms, {emitEvent: false});
    }
  }

  private getInsuranceGroupWithoutActiveField(): Partial<InsuranceGroupVM> {
    let insuranceGroup: Partial<InsuranceGroupVM> = cloneDeep(this.insuranceGroup);
    if (this.activeFieldPath) {
      insuranceGroup = omit(insuranceGroup, this.activeFieldPath.join('.'));
    }

    return insuranceGroup;
  }

  private updateRiskForm(form: InsuranceFormVM, riskForm: RiskFormVM) {
    this.store.dispatch(
      LifeInsuranceActions.updateRiskForm({
        personId: this.personId,
        group: this.group,
        formId: form.formId,
        riskId: riskForm.riskId,
        value: riskForm.value,
        age: riskForm.age,
      }),
    );
  }
}
