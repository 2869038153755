<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>

  <kpt-row>
    <kpt-objective-age-input
      grow
      label="Chci splatit svoje špatné úvěry ve věku"
      name="start"
      [(start)]="this.asset.start"
    ></kpt-objective-age-input>
    <kpt-input
      grow
      type="number"
      label="Výše špatných úvěrů"
      appendText="Kč"
      name="value"
      [(ngModel)]="asset.value"
      required
      [max]="defaultObjectiveMaxValue"
    ></kpt-input>
  </kpt-row>

  <kpt-input
    label="Poznámka"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>

  <kpt-investment-risk-profile [asset]="asset"></kpt-investment-risk-profile>
</ng-container>
