import {Injectable} from '@angular/core';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {NewHousingAsset} from '@shared/analysis/models/objectives';
import * as moment from 'moment';
import {assetNames, AssetType} from '../../models/asset';

@Injectable()
export class NewHousingAssetType extends BaseAssetType {
  constructor() {
    super();
  }

  create(): NewHousingAsset {
    return {
      type: AssetType.NewHousing,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      name: null,
      description: null,
      totalValue: null,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.NewHousing],
      type: AssetType.NewHousing,
    };
  }
}
