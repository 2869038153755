import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {InputModule} from '../../forms/input/input.module';
import {YearInputFormlyComponent} from './year-input-formly.component';

@NgModule({
  declarations: [YearInputFormlyComponent],
  imports: [CommonModule, ReactiveFormsModule, InputModule, BackendValidationModule],
  exports: [YearInputFormlyComponent],
})
export class YearInputFormlyModule {}
