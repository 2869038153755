import {Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-payment-protection-insurance-accordion',
  templateUrl: './payment-protection-insurance-accordion.component.html',
  styleUrls: ['./payment-protection-insurance-accordion.component.scss'],
})
export class PaymentProtectionInsuranceAccordionComponent {
  @Input()
  person: LifeInsurancePerson;

  constructor(private store: Store<State>) {}

  toggleUsePaymentProtectionInsurance(checked: boolean) {
    this.store.dispatch(
      LifeInsuranceActions.usePaymentProtectionInsurance({
        personId: this.person.id,
        use: checked,
      }),
    );
  }
}
