import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

// own models
import {IAddType} from './models/type.model';

@Component({
  selector: 'kpt-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddComponent {
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() customClass?: string;
  @Input() label: string;
  @Input() type?: IAddType;

  constructor() {
    this.type = Object.values(IAddType).includes(this.type) ? this.type : IAddType.DEFAULT;
  }
}
