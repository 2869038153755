import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {PageTemplateAsset} from '@shared/analysis/assets-page.models';
import {existsValueAppearanceFunction} from '@shared/analysis/forms/value-appearance.definitions';
import {AssetType} from '@shared/analysis/models/asset';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isConversionToMonthlyRequired} from 'src/app/modules/financial-analysis/shared/services/assets-page.service';
import {requiresAttention} from '../../asset.utils';
import {FASection} from '../../models/section';

@Component({
  selector: 'kpt-asset-list-item',
  templateUrl: './asset-list-item.component.html',
  styleUrls: ['./asset-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetListItemComponent {
  @Input()
  data: PageTemplateAsset;

  @Input()
  startTemplate: TemplateRef<any>;

  @Input()
  afterLabelTemplate: TemplateRef<any>;

  @Input()
  beforeInputTemplate: TemplateRef<any>;

  @Input()
  endTemplate: TemplateRef<any>;

  @Input()
  sectionKey: FASection;

  @Input()
  valueDisabled = false;

  @Input()
  clickable = false;

  @Output()
  valueChange = new EventEmitter<number>();

  @Output()
  clickItem = new EventEmitter<void>();

  readonly requiresAttention = requiresAttention;

  constructor(public financialAnalysisAssetsService: AssetsHandlerService) {}

  persisting(assetType: AssetType): Observable<boolean> {
    return this.financialAnalysisAssetsService.persistingAsset$.pipe(
      map(persistingAssetType => persistingAssetType === assetType),
    );
  }

  isInputReadOnly() {
    return (
      (this.data.assetData && this.data.assetData.inputMode === 'detail') ||
      existsValueAppearanceFunction(this.data.type, this.sectionKey) ||
      (this.data.assetData && isConversionToMonthlyRequired(this.data.assetData)) ||
      this.valueDisabled
    );
  }
}
