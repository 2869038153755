<div class="p-column insurance-group sums" [class.my-choice]="insuranceGroup.myChoice">
  <div class="p-row header">
    {{ headings[group] }}
    <kpt-help
      *ngIf="helps[group]"
      class="pl-3"
      [text]="helps[group]"
      [direction]="ITooltipDirection.BOTTOM"
    ></kpt-help>
  </div>

  <div
    class="p-row body"
    *ngFor="let sumRow of insuranceGroup.sumRows; trackBy: trackByRiskId; let index = index"
  >
    <ng-container *ngIf="!sumRow.editable">
      <ng-container *ngIf="getRowError(index); else priceIsOk">
        <ng-template #tooltip let-toggle="toggle">
          <div class="price price-out-of-limits" (click)="toggle()">
            {{ sumRow.value | currencyCZK }}
            <span class="icon custom-tooltip__toggle icon--question-mark"></span>
          </div>
        </ng-template>
        <shd-tooltip
          [actionTemplate]="tooltip"
          direction="top"
          customClass="price-out-of-limits-tooltip"
        >
          {{ getRowErrorMsg(index) }}
        </shd-tooltip>
      </ng-container>

      <ng-template #priceIsOk>
        <span class="price">
          {{ sumRow.value | currencyCZK }}
        </span>
      </ng-template>

      <span class="age"> {{ sumRow.age | number }} let </span>
    </ng-container>

    <div *ngIf="sumRow.editable" class="custom">
      <div class="value">
        <kpt-input
          type="number"
          [formControl]="risksForm.at(index).get('value')"
          customFormGroupClass="input-no-stepper"
        >
        </kpt-input>
      </div>
      <div class="age">
        <kpt-input
          type="number"
          [formControl]="risksForm.at(index).get('age')"
          customFormGroupClass="input-no-stepper"
        >
        </kpt-input>
      </div>
    </div>
  </div>

  <div class="p-row footer">
    <ng-container *ngIf="insuranceGroup.sumPrice">
      {{ insuranceGroup.sumPrice | currencyCZK }} měsíčně
    </ng-container>
  </div>
</div>
