<div [formGroup]="parentForm" class="custom-slider mb-global-indentation custom-slider-left-hack">
  <div class="slider-label">
    {{ label }}
  </div>

  <label
    *ngIf="bottomLabel"
    class="bottom-label"
    [style.left.%]="percentage + (50 - percentage) / 50"
    [innerHTML]="bottomLabel"
  >
  </label>

  <div
    *ngIf="mergedSliderOptions?.warningRange || mergedSliderOptions?.dangerRange"
    class="slider-segments"
  >
    <div
      *ngIf="mergedSliderOptions?.warningRange"
      class="slider-segment slider-segment--warning"
      [style.left.%]="mergedSliderOptions.warningRange[0]"
      [style.width.%]="mergedSliderOptions.warningRange[1] - mergedSliderOptions.warningRange[0]"
    ></div>

    <div
      *ngIf="mergedSliderOptions?.dangerRange"
      class="slider-segment slider-segment--danger"
      [style.left.%]="mergedSliderOptions.dangerRange[0]"
      [style.width.%]="mergedSliderOptions.dangerRange[1] - mergedSliderOptions.dangerRange[0]"
    ></div>
  </div>
  <ngx-slider
    [class.ngx-slider--filled-bar]="mergedSliderOptions?.fillBar"
    [options]="mergedSliderOptions"
    [formControl]="parentForm.controls[sliderName]"
    (valueChange)="onValueChange()"
  >
  </ngx-slider>
</div>
