<kpt-modal-wrapper [hideClose]="true">
  <div class="text-center mt-3 mb-6">
    <h3 [innerHtml]="title"></h3>
  </div>
  <div class="text-center">
    <kpt-button
      (action)="confirm(true)"
      [isSubmitButton]="true"
      customClass="px-8"
      [label]="yesButton"
      class="mr-4"
      type="primary"
    >
    </kpt-button>
    <kpt-button
      (action)="confirm(false)"
      [isSubmitButton]="true"
      customClass="px-8"
      [label]="noButton"
      class="ml-4"
      hasOutline="true"
      type="primary"
    >
    </kpt-button>
  </div>
</kpt-modal-wrapper>
