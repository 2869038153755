import {createSelector} from '@ngrx/store';
import {assetNames} from '@shared/analysis/models/asset';
import {PropertyInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {CommonPropertyAsset, PropertyAsset} from '@shared/analysis/models/properties';
import {ImmovableRisks, PropertyRisks} from '@shared/analysis/models/risks';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectImmovableProperties,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {
  getPropertyInputRisks,
  getPropertyTable,
  getSkipSum,
  introTexts,
  mergePropertyRisks,
  safeNumber,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  selectPropertyObjectiveAsset,
  selectPropertyRequirementsAssets,
  setFulfillmentAndRating,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  AssetRisk,
  InputRisk,
  InsuranceRisk,
  PropertyObjective,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';

export const selectPropertyObjectives = (opts?: {filterSelectedAssets: boolean}) =>
  createSelector(
    selectCurrentAssets,
    selectObjectiveAdvisorProposedAssets,
    selectObjectiveClientProposedAssets,
    selectPropertyObjectiveAsset,
    selectPropertyRequirementsAssets,
    selectImmovableProperties,
    (
      currentAssets,
      advisorProposedAssets,
      clientProposedAssets,
      objectiveAsset,
      requirementsAssets,
      propertyAssets,
    ): PropertyObjective[] => {
      if (!objectiveAsset) return [];

      if (opts?.filterSelectedAssets) {
        propertyAssets = propertyAssets.filter(a =>
          objectiveAsset.selectedAssets?.includes(a.assetUuid),
        );
      }

      return propertyAssets.map((propertyAsset: PropertyAsset): PropertyObjective => {
        const requirementsAsset = requirementsAssets.find(
          asset => asset.relatedPropertyUuid === propertyAsset.assetUuid,
        );
        const inputRisks = getPropertyInputRisks(requirementsAsset);

        const currentTable = getPropertyTable(
          Situation.Current,
          currentAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Property,
        );
        const advisorProposedTable = getPropertyTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Property,
        );
        const clientProposedTable = getPropertyTable(
          Situation.ClientProposed,
          clientProposedAssets,
          propertyAsset,
          objectiveAsset,
          ObjectiveType.Property,
        );
        const propertyRisks = getPropertyRisks(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          inputRisks,
        );

        setFulfillmentAndRating(
          currentTable,
          advisorProposedTable,
          clientProposedTable,
          propertyRisks,
        );

        currentTable.rating = null;
        advisorProposedTable.rating = null;
        clientProposedTable.rating = null;

        const name = (propertyAsset as CommonPropertyAsset).name ?? assetNames[propertyAsset.type];

        return {
          type: ObjectiveType.Property,
          objectiveAsset,
          objectiveValue: null,
          requirementsAsset,
          propertyAsset,
          introText: introTexts[ObjectiveType.Property],
          name,
          input: inputRisks,
          chart: propertyRisks,
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
        };
      });
    },
  );

function getPropertyRisks(
  currentTable: Table,
  advisorProposedTable: Table,
  clientProposedTable: Table,
  inputRisks: InputRisk[],
): InsuranceRisk[] {
  const currentPropertyRisks = mapAssetsToPropertyRisks(
    currentTable,
    inputRisks,
    Situation.Current,
  );
  const advisorProposedPropertyRisks = mapAssetsToPropertyRisks(
    advisorProposedTable,
    inputRisks,
    Situation.AdvisorProposed,
  );
  const clientProposedPropertyRisks = mapAssetsToPropertyRisks(
    clientProposedTable,
    inputRisks,
    Situation.ClientProposed,
  );
  return mergePropertyRisks(
    currentPropertyRisks,
    advisorProposedPropertyRisks,
    clientProposedPropertyRisks,
    inputRisks,
  );
}

function mapAssetsToPropertyRisks(
  table: Table,
  inputRisks: InputRisk[],
  situation: Situation,
): AssetRisk[] {
  return inputRisks
    .map((risk: InputRisk) => ({
      key: risk.key,
      value: sumPropertyRisks(risk.key as PropertyRisks, table, situation),
    }))
    .filter((risk: AssetRisk) => !isNaN(risk.value));
}

function sumPropertyRisks(risk: PropertyRisks, table: Table, situation: Situation): number {
  return table.rows.reduce((sum, row) => {
    if (getSkipSum(row.asset, situation)) return sum;
    const riskKey = risk as ImmovableRisks;
    // TODO: Should Other be included in the PropertyInsuranceAsset? Also, the same problem
    // exists for vehicle insurance.
    if (riskKey === ImmovableRisks.Other) {
      return sum;
    }
    return sum + safeNumber(() => (row.asset as PropertyInsuranceAsset)[riskKey]);
  }, 0);
}
