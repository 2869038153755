import {Product} from '@generated/defs/Product';
import {createAction, props, union} from '@ngrx/store';
import {ProductSet} from 'src/store/models/products.models';

export const loadProducts = createAction('[Products] Load', props<{productSet: ProductSet}>());

export const loadProductsSuccess = createAction(
  '[Products] Load Success',
  props<{productSet: ProductSet; products: Product[]}>(),
);

export const ProductActions = {
  loadProducts,
  loadProductsSuccess,
};
const allActions = union(ProductActions);

export type ProductActionsUnion = typeof allActions;
