import {IntroAdvisor, UiSettingsAdvisor} from '@generated/model';
import {ofType, unionize, UnionOf} from 'unionize';

export const AdvisorUiSettingsActions = unionize(
  {
    UI_SetAdvisorUiSettings: ofType<UiSettingsAdvisor>(),
    UI_ClearAdvisorUiSettings: {},
    UI_UpdateIntroUiSettings: ofType<{intro: Partial<IntroAdvisor>}>(),
  },
  {tag: 'type', value: 'payload'},
);

export type AdvisorUiSettingsAction = UnionOf<typeof AdvisorUiSettingsActions>;
