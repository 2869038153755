import {LifeInsuranceFile} from '@generated/defs/LifeInsuranceFile';
import {FamilyMember} from '@generated/model';
import {createSelector} from '@ngrx/store';
import {Asset} from '@shared/analysis/models/asset';
import {selectCurrentAssets} from '@shared/analysis/store/index';
import {sum} from 'lodash';
import {calculateRisk} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {getDefaultAge} from 'src/app/modules/life-insurance-old/store/life-insurance.helpers';
import {
  LifeInsuranceCustomRiskDefinition,
  LifeInsuranceForm,
  LifeInsuranceFormGroup,
  LifeInsuranceFormState,
  LifeInsurancePerson,
  LifeInsuranceRisk,
  LifeInsuranceUI,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectCustomRiskDefinitions,
  selectFiles,
  selectForms,
  selectOrderedPersons,
  selectRisks,
  selectUI,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {
  adultProposalRiskIds,
  childRiskIds,
  getRiskDefinition,
  proposalRiskIds,
  riskDefinitions,
} from 'src/app/services/risk-definitions';
import {Provision, RiskId} from 'src/store/models/risk.models';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export const selectInsuranceProposalsVM = createSelector(
  selectOrderedPersons,
  getFamilyMembers,
  selectRisks,
  selectCustomRiskDefinitions,
  selectForms,
  selectUI,
  selectFiles,
  selectCurrentAssets,
  (
    persons: LifeInsurancePerson[],
    familyMembers: FamilyMember[],
    risks: LifeInsuranceRisk[],
    customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
    forms: LifeInsuranceForm[],
    ui: LifeInsuranceUI,
    files: LifeInsuranceFile[],
    assets: Asset[],
  ): PersonalInsuranceProposalVM[] => {
    return persons.map(person => {
      return {
        person,
        familyMember: familyMembers.find(familyMember => familyMember.sugarUuid === person.id),
        riskDefs: getRiskDefinitions(person, customRiskDefinitions),
        riskLimits: getRiskLimits(person, risks, customRiskDefinitions, persons, assets),
        [LifeInsuranceFormGroup.Current]: getInsuranceGroup(
          person,
          risks,
          customRiskDefinitions,
          forms,
          files,
          ui,
          LifeInsuranceFormGroup.Current,
        ),
        [LifeInsuranceFormGroup.Selected]: getSelectedInsurance(
          person,
          risks,
          customRiskDefinitions,
          persons,
          assets,
        ),
        [LifeInsuranceFormGroup.Recommended]: getInsuranceGroup(
          person,
          risks,
          customRiskDefinitions,
          forms,
          files,
          ui,
          LifeInsuranceFormGroup.Recommended,
        ),
        [LifeInsuranceFormGroup.Final]: getInsuranceGroup(
          person,
          risks,
          customRiskDefinitions,
          forms,
          files,
          ui,
          LifeInsuranceFormGroup.Final,
        ),
        files,
      };
    });
  },
);

function getRiskDefinitions(
  person: LifeInsurancePerson,
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
): RiskDefinitionVM[] {
  const riskIds = person.child ? childRiskIds : adultProposalRiskIds;
  const riskDefs: RiskDefinitionVM[] = riskIds
    .map(id => riskDefinitions.find(r => r.id === id))
    .map(def => ({id: def.id, label: def.label, editable: false}));
  const customDefs: RiskDefinitionVM[] = getCustomRiskDefinitions(
    customRiskDefinitions,
    person,
  ).map(def => ({id: def.id, label: def.label, editable: !def.myChoice}));

  riskDefs.sort(
    (riskDef1: RiskDefinitionVM, riskDef2: RiskDefinitionVM) =>
      proposalRiskIds.indexOf(riskDef1.id as RiskId) -
      proposalRiskIds.indexOf(riskDef2.id as RiskId),
  );

  return [...riskDefs, ...customDefs];
}

function getSelectedInsurance(
  person: LifeInsurancePerson,
  risks: LifeInsuranceRisk[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  persons: LifeInsurancePerson[],
  assets: Asset[],
): InsuranceGroupVM {
  const riskIds = getRiskIds(person, customRiskDefinitions);
  const sumRows: SumRowVM[] = riskIds.map(riskId => {
    const risk = risks.find(r => r.personId === person.id && r.riskId === riskId && r.myChoice);

    if (risk) {
      const riskDef = riskDefinitions.find(rd => rd.id === risk.riskId);
      if (riskDef) {
        return {
          value: calculateRisk(riskDef, risk.provision, person, persons, assets),
          age: risk.age,
          riskId: risk.riskId,
          editable: false,
        } as SumRowVM;
      } else {
        let myChoice = true;
        const customRiskDef = customRiskDefinitions.find(
          def => def.personId === person.id && def.id === risk.riskId,
        );
        if (customRiskDef) {
          myChoice = customRiskDef.myChoice;
        }
        return {
          value: risk.value,
          age: risk.age,
          riskId: customRiskDef.id,
          editable: !myChoice,
        } as SumRowVM;
      }
    }

    return {
      value: 0,
      age: getDefaultAge(person),
    } as SumRowVM;
  });

  return {
    myChoice: true,
    sumRows,
    group: LifeInsuranceFormGroup.Selected,
  };
}

function getRiskLimits(
  person: LifeInsurancePerson,
  risks: LifeInsuranceRisk[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  persons: LifeInsurancePerson[],
  assets: Asset[],
): RiskLimitVM[] {
  const riskIds = getRiskIds(person, customRiskDefinitions);
  const provisionForMin: Provision = person.child ? Provision.Minimal : Provision.Expenses;
  const provisionForMax: Provision = person.child ? Provision.Standard : Provision.Income;
  return riskIds.map(riskId => {
    const riskDefForMin = getRiskDefinition(riskId, provisionForMin);
    const riskDefForMax = getRiskDefinition(riskId, provisionForMax);
    const risk = risks.find(r => r.personId === person.id && r.riskId === riskId && r.myChoice);
    return {
      min: riskDefForMin
        ? calculateRisk(riskDefForMin, provisionForMin, person, persons, assets)
        : 0,
      max: riskDefForMax
        ? calculateRisk(riskDefForMax, provisionForMax, person, persons, assets)
        : 0,
      required: Boolean(risk),
    };
  });
}

function getInsuranceGroup(
  person: LifeInsurancePerson,
  risks: LifeInsuranceRisk[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  forms: LifeInsuranceForm[],
  files: LifeInsuranceFile[],
  ui: LifeInsuranceUI,
  group: LifeInsuranceFormGroup,
): InsuranceGroupVM {
  const insuranceForms = getInsuranceForms(
    forms,
    person,
    group,
    risks,
    customRiskDefinitions,
    files,
  );
  const insuranceFormsForSum =
    group === LifeInsuranceFormGroup.Recommended
      ? omitAlternatives(insuranceForms)
      : insuranceForms;
  const sumRows = sumInsuranceForms(insuranceFormsForSum, customRiskDefinitions, person);
  const sumPrice = sumPaymentPrice(insuranceFormsForSum);
  const expanded = isFormExpanded(person, group, ui);

  return {
    group,
    forms: insuranceForms,
    myChoice: false,
    sumRows,
    sumPrice,
    expanded,
  };
}

function getInsuranceForms(
  forms: LifeInsuranceForm[],
  person: LifeInsurancePerson,
  group: LifeInsuranceFormGroup,
  risks: LifeInsuranceRisk[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  files: LifeInsuranceFile[],
): InsuranceFormVM[] {
  return forms
    .filter(f => f.personId === person.id && f.group === group)
    .sort((a, b) => a.order - b.order)
    .map((form): InsuranceFormVM => {
      const riskForms = getRiskForms(person, group, form, forms, risks, customRiskDefinitions);
      const state = getFormState(group, form, forms, risks, person);
      const icon = getInsuranceFormIcon(group, form);
      const formChanged = hasFormChange(person, form, risks, forms);
      const priceChanged = isPriceChanged(form, forms);
      const formFiles = getFormFiles(person, group, form, forms, files, formChanged);

      return {
        name: form.name,
        withProduct: form.withProduct,
        productId: form.productId,
        price: form.price,
        risks: riskForms,
        state,
        formId: form.id,
        cancelled: form.cancelled,
        icon,
        files: formFiles,
        alternativeSetId: form.alternativeSetId,
        stakeholderId: form.stakeholderId,
        contractId: form.contractId,
        changed: formChanged,
        priceChanged,
      };
    });
}

function getRiskForms(
  person: LifeInsurancePerson,
  group: LifeInsuranceFormGroup,
  form: LifeInsuranceForm,
  forms: LifeInsuranceForm[],
  risks: LifeInsuranceRisk[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
): RiskFormVM[] {
  const definedRiskIds = person.child ? childRiskIds : adultProposalRiskIds;
  const customRiskIds = getCustomRiskDefinitions(customRiskDefinitions, person).map(def => def.id);
  const riskIds: string[] = [...definedRiskIds, ...customRiskIds];

  return riskIds.map((riskId): RiskFormVM => {
    const risk = risks.find(
      r => r.personId === person.id && r.riskId === riskId && r.formId === form.id,
    );

    const riskForm: RiskFormVM = {
      riskId,
      value: risk ? risk.value : null,
      age: risk ? risk.age : getDefaultAge(person),
      changed: false,
    };

    const linkedRisk = findLinkedRisk(person, group, form, riskId, forms, risks);
    if (linkedRisk) {
      riskForm.changed =
        (riskForm.value || 0) !== (linkedRisk.value || 0) ||
        (riskForm.age || getDefaultAge(person)) !== (linkedRisk.age || getDefaultAge(person));
    }

    return riskForm;
  });
}

function findLinkedRisk(
  person: LifeInsurancePerson,
  group: LifeInsuranceFormGroup,
  form: LifeInsuranceForm,
  riskId: string,
  forms: LifeInsuranceForm[],
  risks: LifeInsuranceRisk[],
): LinkedRiskReturnValue {
  const defaultReturnValue: LinkedRiskReturnValue = {value: null, age: getDefaultAge(person)};

  let linkedGroup: LifeInsuranceFormGroup;
  if (group === LifeInsuranceFormGroup.Recommended) {
    linkedGroup = LifeInsuranceFormGroup.Current;
  } else if (group === LifeInsuranceFormGroup.Final) {
    linkedGroup = LifeInsuranceFormGroup.Recommended;
  } else {
    return null;
  }
  const linkedForm = findLinkedForm(form, linkedGroup, forms);
  if (!linkedForm) return null;

  const linkedRisk = risks.find(r => r.formId === linkedForm.id && r.riskId === riskId);

  if (linkedRisk) {
    return {value: linkedRisk.value, age: linkedRisk.age};
  } else {
    return defaultReturnValue;
  }
}

export function findLinkedForm(
  form: LifeInsuranceForm,
  linkedGroup: LifeInsuranceFormGroup,
  forms: LifeInsuranceForm[],
): LifeInsuranceForm {
  return forms.find(f => f.linkId === form.linkId && f.group === linkedGroup) || null;
}

function getFormState(
  group: LifeInsuranceFormGroup,
  form: LifeInsuranceForm,
  forms: LifeInsuranceForm[],
  risks: LifeInsuranceRisk[],
  person: LifeInsurancePerson,
): LifeInsuranceFormState {
  switch (group) {
    case LifeInsuranceFormGroup.Recommended:
      return getFormStateForRecommended(form, forms, risks, person);
    case LifeInsuranceFormGroup.Final:
      return getFormStateForFinal(form, forms, risks, person);
    default:
      return LifeInsuranceFormState.None;
  }
}

function getFormStateForRecommended(
  recommendedForm: LifeInsuranceForm,
  forms: LifeInsuranceForm[],
  risks: LifeInsuranceRisk[],
  person: LifeInsurancePerson,
): LifeInsuranceFormState {
  if (recommendedForm.cancelled) return LifeInsuranceFormState.Cancelled;

  if (
    recommendedForm.alternativeSetId &&
    isAlternativeInSet(recommendedForm, LifeInsuranceFormGroup.Recommended, forms)
  ) {
    return LifeInsuranceFormState.Alternative;
  }

  const currentForm = findLinkedForm(recommendedForm, LifeInsuranceFormGroup.Current, forms);
  if (!currentForm) return LifeInsuranceFormState.New;

  const formIsChanged = isFormChanged(recommendedForm, currentForm, risks, person);

  return formIsChanged ? LifeInsuranceFormState.Changed : LifeInsuranceFormState.Unchanged;
}

function getFormStateForFinal(
  finalForm: LifeInsuranceForm,
  forms: LifeInsuranceForm[],
  risks: LifeInsuranceRisk[],
  person: LifeInsurancePerson,
): LifeInsuranceFormState {
  // see description in https://jirakapitol.atlassian.net/browse/KAPP-146

  const currentForm = findLinkedForm(finalForm, LifeInsuranceFormGroup.Current, forms);
  const recommendedForm = findLinkedForm(finalForm, LifeInsuranceFormGroup.Recommended, forms);

  if (currentForm) {
    if (finalForm.cancelled && recommendedForm.cancelled) return LifeInsuranceFormState.Cancelled;

    if (finalForm.cancelled && !recommendedForm.cancelled)
      return LifeInsuranceFormState.CancelledByClient;
  }

  const changedAgainstRecommended = isFormChanged(finalForm, recommendedForm, risks, person);

  if (!currentForm) {
    if (finalForm.cancelled) return LifeInsuranceFormState.RejectedByClient;

    if (!changedAgainstRecommended) return LifeInsuranceFormState.New;

    if (changedAgainstRecommended) return LifeInsuranceFormState.NewWithClientChanges;
  }

  const changedAgainstCurrent = isFormChanged(finalForm, currentForm, risks, person);

  if (changedAgainstCurrent) {
    if (!changedAgainstRecommended && recommendedForm.cancelled)
      return LifeInsuranceFormState.ChangedByClient;

    if (!changedAgainstRecommended) return LifeInsuranceFormState.Changed;

    if (changedAgainstRecommended) return LifeInsuranceFormState.ChangedByClient;
  }

  if (!changedAgainstCurrent) {
    if (!changedAgainstRecommended && recommendedForm.cancelled)
      return LifeInsuranceFormState.UnchangedByClient;

    if (!changedAgainstRecommended) return LifeInsuranceFormState.Unchanged;

    if (changedAgainstRecommended) return LifeInsuranceFormState.UnchangedByClient;
  }
}

export function isFormChanged(
  form: LifeInsuranceForm,
  linkedForm: LifeInsuranceForm,
  risks: LifeInsuranceRisk[],
  person: LifeInsurancePerson,
): boolean {
  if (form.price !== linkedForm.price) return true;

  const getValue = (risk: LifeInsuranceRisk) => (risk && risk.value) || 0;
  const getAge = (risk: LifeInsuranceRisk) => (risk && risk.age) || getDefaultAge(person);

  const riskIds = Array.from(new Set(risks.map(risk => risk.riskId)));

  for (const riskId of riskIds) {
    const risk = risks.find(r => r.riskId === riskId && r.formId === form.id);
    const linkedRisk = risks.find(r => r.riskId === riskId && r.formId === linkedForm.id);

    if (getValue(risk) !== getValue(linkedRisk) || getAge(risk) !== getAge(linkedRisk)) {
      return true;
    }
  }

  return false;
}

function hasFormChange(
  person: LifeInsurancePerson,
  form: LifeInsuranceForm,
  risks: LifeInsuranceRisk[],
  forms: LifeInsuranceForm[],
): boolean {
  const linkedForm = findClosestLinkedForm(form, forms);

  return linkedForm ? isFormChanged(form, linkedForm, risks, person) : false;
}

function isPriceChanged(form: LifeInsuranceForm, forms: LifeInsuranceForm[]): boolean {
  const linkedForm = findClosestLinkedForm(form, forms);

  return linkedForm ? form.price !== linkedForm.price : false;
}

function findClosestLinkedForm(form: LifeInsuranceForm, forms: LifeInsuranceForm[]) {
  let linkedForm: LifeInsuranceForm = null;

  if (form.group === LifeInsuranceFormGroup.Recommended) {
    linkedForm = findLinkedForm(form, LifeInsuranceFormGroup.Current, forms);
  } else if (form.group === LifeInsuranceFormGroup.Final) {
    linkedForm = findLinkedForm(form, LifeInsuranceFormGroup.Recommended, forms);
  }
  return linkedForm;
}

function isAlternativeInSet(
  form: LifeInsuranceForm,
  group: LifeInsuranceFormGroup,
  forms: LifeInsuranceForm[],
) {
  const formsInSet = forms
    .filter(f => f.alternativeSetId === form.alternativeSetId && f.group === group)
    .sort((a, b) => a.order - b.order);
  return formsInSet.findIndex(f => f.id === form.id) > 0; // alternative form is not the first one in a set
}

function getInsuranceFormIcon(group: LifeInsuranceFormGroup, form: LifeInsuranceForm) {
  if (group === LifeInsuranceFormGroup.Current) {
    return InsuranceFormIcon.Delete;
  } else if (group === LifeInsuranceFormGroup.Recommended && form.isNew) {
    return InsuranceFormIcon.Delete;
  } else if (form.cancelled) {
    return InsuranceFormIcon.Restore;
  } else {
    return InsuranceFormIcon.Cancel;
  }
}

function getFormFiles(
  person: LifeInsurancePerson,
  group: LifeInsuranceFormGroup,
  form: LifeInsuranceForm,
  forms: LifeInsuranceForm[],
  files: LifeInsuranceFile[],
  formChanged: boolean,
): LifeInsuranceFileVM[] {
  const formFiles = files
    .filter(f => f.personId === person.id && f.group === group && f.formId === form.id)
    .map(file => ({...file, shortcut: false}));

  if (group !== LifeInsuranceFormGroup.Final) {
    return formFiles;
  } else {
    if (formFiles.length > 0 && formChanged) {
      return formFiles;
    } else {
      const linkedForm = forms.find(
        f => f.linkId === form.linkId && f.group === LifeInsuranceFormGroup.Recommended,
      );
      return files
        .filter(
          f =>
            f.personId === person.id &&
            f.group === LifeInsuranceFormGroup.Recommended &&
            f.formId === linkedForm.id,
        )
        .map(file => ({...file, shortcut: true}));
    }
  }
}

function getRiskIds(
  person: LifeInsurancePerson,
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
): string[] {
  const definedRiskIds = person.child ? childRiskIds : adultProposalRiskIds;
  const customRiskIds = getCustomRiskDefinitions(customRiskDefinitions, person).map(def => def.id);

  return [...definedRiskIds, ...customRiskIds];
}

function getCustomRiskDefinitions(
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  person: LifeInsurancePerson,
): LifeInsuranceCustomRiskDefinition[] {
  // sort by myChoice, then by order
  return customRiskDefinitions
    .filter(def => def.personId === person.id)
    .sort((a, b) =>
      a.myChoice !== b.myChoice ? Number(b.myChoice) - Number(a.myChoice) : a.order - b.order,
    );
}

function sumInsuranceForms(
  insuranceForms: InsuranceFormVM[],
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[],
  person: LifeInsurancePerson,
): SumRowVM[] {
  const riskIds = getRiskIds(person, customRiskDefinitions);
  const forms = insuranceForms.filter(f => !f.cancelled);

  return riskIds.map(riskId => {
    const sumRow: SumRowVM = {value: 0, age: 0};

    for (const form of forms) {
      const risk = form.risks.find(r => r.riskId === riskId);

      if (risk) {
        sumRow.value += risk.value;
        sumRow.age = Math.max(sumRow.age, risk.age);
      }
    }

    if (sumRow.age === 0) sumRow.age = getDefaultAge(person);

    return sumRow;
  });
}

function omitAlternatives<T extends InsuranceFormVM | LifeInsuranceForm>(forms: T[]) {
  const formsWithoutAlternatives: T[] = [];
  let previousAlternativeSetId: number = null;

  for (const form of forms) {
    if (
      (form.alternativeSetId && form.alternativeSetId !== previousAlternativeSetId) ||
      !form.alternativeSetId
    ) {
      formsWithoutAlternatives.push(form);
    }
    previousAlternativeSetId = form.alternativeSetId;
  }

  return formsWithoutAlternatives;
}

function sumPaymentPrice(insuranceForms: InsuranceFormVM[]): number {
  return sum(insuranceForms.filter(form => !form.cancelled).map(form => form.price));
}

function isFormExpanded(
  person: LifeInsurancePerson,
  group: LifeInsuranceFormGroup,
  ui: LifeInsuranceUI,
): boolean {
  return (ui.expandedForm[person.id] && ui.expandedForm[person.id][group]) || false;
}

export const selectRecommendedMainFormsVM = createSelector(
  selectOrderedPersons,
  selectForms,
  (persons: LifeInsurancePerson[], forms: LifeInsuranceForm[]): RecommendedMainFormsVM => {
    return persons.reduce<RecommendedMainFormsVM>((result, person) => {
      const newRecommendedForms = forms
        .filter(
          form =>
            form.personId === person.id &&
            form.group === LifeInsuranceFormGroup.Recommended &&
            form.isNew,
        )
        .sort((a, b) => a.order - b.order);

      const mainForms = omitAlternatives(newRecommendedForms);

      return {...result, [person.id]: mainForms};
    }, {});
  },
);

export type PersonalInsuranceProposalVM = {
  person: LifeInsurancePerson;
  familyMember: FamilyMember;
  riskDefs: RiskDefinitionVM[];
  riskLimits: RiskLimitVM[];
  files: LifeInsuranceFile[];
} & {
  [group in LifeInsuranceFormGroup]: InsuranceGroupVM;
};

export interface RiskDefinitionVM {
  id: string;
  label: string;
  editable: boolean;
}

export interface InsuranceGroupVM {
  group: LifeInsuranceFormGroup;
  sumRows: SumRowVM[];
  sumPrice?: number;
  myChoice: boolean;
  forms?: InsuranceFormVM[];
  expanded?: boolean;
}

export interface RiskLimitVM {
  min: number;
  max: number;
  required: boolean;
}

export interface InsuranceFormVM {
  formId: number;
  name: string;
  withProduct: boolean;
  productId: number;
  price: number;
  risks: RiskFormVM[];
  state: LifeInsuranceFormState;
  cancelled: boolean;
  icon: InsuranceFormIcon;
  files: LifeInsuranceFileVM[];
  alternativeSetId: number;
  stakeholderId: string;
  contractId: number;
  changed: boolean;
  priceChanged: boolean;
}

export interface RiskFormVM {
  riskId: string;
  value: number;
  age: number;
  changed: boolean;
}

export interface SumRowVM {
  value: number;
  age: number;
  riskId?: string;
  editable?: boolean;
}

export enum InsuranceFormIcon {
  Delete = 'delete',
  Cancel = 'cancel',
  Restore = 'restore',
}

interface LinkedRiskReturnValue {
  value: number;
  age: number;
}

export interface RecommendedMainFormsVM {
  [personId: string]: LifeInsuranceForm[];
}

export interface LifeInsuranceFileVM extends LifeInsuranceFile {
  shortcut: boolean;
}
