import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TooltipModule} from '@lib/components';
import {HelpComponent} from './help.component';

@NgModule({
  declarations: [HelpComponent],
  imports: [CommonModule, TooltipModule],
  exports: [HelpComponent],
})
export class HelpModule {}
