<kpt-switch
  [labelTemplate]="labelNew"
  [checked]="asset.requirementType === RequirementType.NewContract"
  [disabledField]="disabled"
  (click)="changeRequirementType(RequirementType.NewContract, $event)"
>
  <ng-template #labelNew>
    <h3 class="section-header">Sjednat pojištění</h3>
  </ng-template>
</kpt-switch>
<div *ngIf="asset.requirementType === RequirementType.NewContract" class="section-body">
  <kpt-row justifyContent="flex-start">
    <kpt-select
      class="contract-period"
      name="contractPeriod"
      label="Na jaké období chci pojistit"
      [(ngModel)]="asset.contractPeriod"
      [options]="propertyContractPeriods"
    >
    </kpt-select>
    <kpt-input
      *ngIf="asset.contractPeriod === ContractPeriod.Custom"
      name="contractPeriodCustom"
      [(ngModel)]="asset.contractPeriodCustom"
    ></kpt-input>
  </kpt-row>
  <kpt-risk-list-input [asset]="asset"></kpt-risk-list-input>
  <kpt-input label="Poznámka" type="textarea" name="note" [(ngModel)]="asset.note"></kpt-input>
</div>

<kpt-switch
  [labelTemplate]="labelChange"
  [checked]="asset.requirementType === RequirementType.ChangeContract"
  [disabledField]="disabled"
  (click)="changeRequirementType(RequirementType.ChangeContract, $event)"
>
  <ng-template #labelChange>
    <h3 class="section-header">Změnit smlouvu</h3>
  </ng-template>
</kpt-switch>
<div *ngIf="asset.requirementType === RequirementType.ChangeContract" class="section-body">
  <kpt-input label="Poznámka" type="textarea" name="note" [(ngModel)]="asset.note"></kpt-input>
</div>

<kpt-switch
  [labelTemplate]="labelOther"
  [checked]="asset.requirementType === RequirementType.Other"
  [disabledField]="disabled"
  (click)="changeRequirementType(RequirementType.Other, $event)"
>
  <ng-template #labelOther>
    <h3 class="section-header">Jiné</h3>
  </ng-template>
</kpt-switch>
<div *ngIf="asset.requirementType === RequirementType.Other" class="section-body">
  <kpt-input label="Poznámka" type="textarea" name="note" [(ngModel)]="asset.note"></kpt-input>
</div>
