import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {getBasicField} from '@shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {ContractorDefinitions} from './contractor.definitions';
import {AssetDefinition} from './definitions.models';
import {getBaseAssetFields, sumByPaths, updateNetIncome} from './forms.helpers';

@Injectable()
export class MainIncomes {
  private commonIncomeAssets = this.abstractAssets.commonIncomeAssets();

  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private contractor: ContractorDefinitions,
  ) {}

  getAll() {
    return [this.getEmployment(), this.contractor.getContractor()];
  }

  private getEmployment(): AssetDefinition {
    const sumNetOtherIncomeOptions = {change: sumByPaths('value', ['netIncome', 'otherIncome'])};
    const updateNetIncomeOptions = {change: updateNetIncome};

    return {
      name: assetNames[AssetType.Employment],
      type: AssetType.Employment,
      fields: [
        this.commonIncomeAssets.assetUuid,
        this.commonIncomeAssets.familyMemberUuid,
        row([
          col(
            getBasicField(FormlyTypes.Number, 'netIncome', FieldLabel.NET_INCOME, {
              ...sumNetOtherIncomeOptions,
              subtext: 'Zadejte čistý příjem',
            }),
          ),
          col(
            getBasicField(FormlyTypes.Number, 'otherIncome', FieldLabel.OTHER_INCOME, {
              ...sumNetOtherIncomeOptions,
              subtext: 'Zadejte ostatní čistý příjem',
            }),
          ),
        ]),
        row([
          col(
            this.abstractAssets.getValueAssetField(
              {},
              {label: FieldLabel.MONTHLY_INCOME, ...updateNetIncomeOptions},
            ),
          ),
          col(getBasicField(FormlyTypes.Text, 'profession', FieldLabel.PROFESSION)),
        ]),
        this.commonIncomeAssets.start,
        getBaseAssetFields().note,
      ],
      validators: getAssetValidators(AssetType.Employment),
      model: {},
    };
  }
}
