import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {calculateCurrentEquity} from '@shared/analysis/evaluation';
import {Asset} from '@shared/analysis/models/asset';
import {selectProperties} from '@shared/analysis/store';
import {formatCZKNotNull} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, ItemsGroup} from './../summary.models';

export const selectInvestmentCategory = createSelector(selectProperties, properties =>
  createCategory(properties.investmentProperties),
);

function createCategory(investment: AssetPageStateValue): Category {
  return {
    label: investment.title,
    value: formatCZKNotNull(investment.sum),
    categoryTypes: [createCategoryType(investment.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull(calculateCurrentEquity([asset])),
    items: [],
  };
}
