import {Component, EventEmitter, Input, Output, Renderer2, TemplateRef} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';

@Component({
  selector: 'kpt-common-field',
  template: ``,
  providers: [...getProvidersFor(CommonFieldComponent)],
})
export class CommonFieldComponent implements ControlValueAccessor {
  @Output() valueChange = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();

  @Input() readonly: boolean;
  @Input() required = false;
  @Input() placeholder = '';
  @Input() id: string;
  @Input() success: boolean; // explicitly sets kpt-is-success class
  @Input() warning: boolean; // explicitly sets kpt-is-warning class
  @Input() customFieldClass: string; // class on <input>s or <textarea>
  @Input() customFormGroupClass: string; // class on the wrapper
  @Input() appendTemplate: TemplateRef<any>;
  @Input() showErrorsOn: boolean | 'touched' = 'touched';
  @Input() customErrors: {[key: string]: string} | string = null; // extra or different error msgs
  @Input() subtext: string; // small description under the field
  @Input() warningText: string; // small warning text under the field
  @Input() label: string;
  @Input() htmlLabel = ''; // custom html instead of label

  // We want to be able to disable control either via input property or via `FormControl` API.
  // For both cases to work, we need to keep track of two separate disabled states.
  // (source: https://medium.com/@dmmishchenko/the-pitfalls-of-combining-controlvalueaccessor-and-the-disabled-flag-in-angular-forms-2b8c2994d689)
  //
  // 1) disabled state set via input property
  @Input() disabledField = false;
  // 2) disabled state set via FormControl API
  disabledControl = false;

  get disabled() {
    return this.disabledField || this.disabledControl;
  }

  get value(): any {
    return this.__value;
  }

  @Input() set value(value: any) {
    this.__value = value;
  }

  _value: any = '';

  get inputValue(): any {
    return this._value;
  }

  set inputValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }
  private __value: any;

  constructor(protected renderer: Renderer2) {}

  writeValue(value: any) {
    this._value = value;
    if (value !== this._value) {
      this.onChange(value);
    }
  }

  onChange = (_: any) => {
    this._value = _;
  };

  onTouched = (_: any) => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabledControl = disabled;
  }
}
