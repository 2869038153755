/* eslint-disable max-classes-per-file */
import {HttpErrorResponse} from '@angular/common/http';

import {CreateClientParams} from '@generated/controllers/Integrations';
import {PortalClient} from '@generated/model';
import {Action} from '@ngrx/store';

/* eslint-disable @typescript-eslint/no-shadow */
export enum CreateClientActionTypes {
  CreateClientStart = '[Create Client] Start',
  CreateClientSuccess = '[Create Client] Success',
  CreateClientError = '[Create Client] Error',
  CreateClientCancel = '[Create Client] Cancel',
}
/* eslint-enable */

export class CreateClientStart implements Action {
  readonly type = CreateClientActionTypes.CreateClientStart;

  constructor(public payload: CreateClientParams) {}
}

export class CreateClientSuccess implements Action {
  readonly type = CreateClientActionTypes.CreateClientSuccess;

  constructor(public payload: PortalClient) {}
}

export class CreateClientError implements Action {
  readonly type = CreateClientActionTypes.CreateClientError;

  constructor(public payload: HttpErrorResponse) {}
}

export class CreateClientCancel implements Action {
  readonly type = CreateClientActionTypes.CreateClientCancel;

  constructor() {}
}

export type CreateClientActions =
  | CreateClientStart
  | CreateClientSuccess
  | CreateClientError
  | CreateClientCancel;
