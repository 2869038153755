import {createSelector} from '@ngrx/store';
import {selectCurrentAssets, selectProposedAssets} from '@shared/analysis/store';
import {pull} from 'lodash';
import {AdvisorProposalState, Asset} from 'src/shared/analysis/models/asset';

/**
 * Select assets which represent the proposed state made by the advisor.
 * Client proposition is ignored. */
export const selectAdvisorProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  (currentAssets, proposedAssets): Asset[] => {
    const advisorProposedAssets = [...currentAssets];

    pullTerminatedAssets(advisorProposedAssets);
    pushAdvisorUpdatedAssets(advisorProposedAssets, currentAssets, proposedAssets);
    pushNewAssets(advisorProposedAssets, proposedAssets);

    return advisorProposedAssets;
  },
);

function pullTerminatedAssets(advisorProposedAssets: Asset[]) {
  const terminatedAssets = advisorProposedAssets.filter(
    asset => asset.advisorProposalState === AdvisorProposalState.Terminated,
  );

  pull(advisorProposedAssets, ...terminatedAssets);
}

function pushAdvisorUpdatedAssets(
  advisorProposedAssets: Asset[],
  currentAssets: Asset[],
  proposedAssets: Asset[],
) {
  const advisorUpdatedAssets = proposedAssets.filter(
    asset => asset.advisorProposalState === AdvisorProposalState.Updated,
  );
  advisorProposedAssets.push(...advisorUpdatedAssets);

  const currentAssetsOfAdvisorUpdatedAssets = advisorUpdatedAssets.map(asset =>
    currentAssets.find(a => a.assetUuid === asset.originalAssetUuid),
  );
  pull(advisorProposedAssets, ...currentAssetsOfAdvisorUpdatedAssets);
}

function pushNewAssets(clientProposedAssets: Asset[], proposedAssets: Asset[]) {
  const newAssets = proposedAssets.filter(
    asset => asset.advisorProposalState === AdvisorProposalState.New,
  );
  clientProposedAssets.push(...newAssets);
}
