import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {FieldType} from '@ngx-formly/core';
import {selectAllAssets} from '@shared/analysis/store';
import {map} from 'rxjs/operators';
import {State} from 'src/store';

@Component({
  selector: 'kpt-related-asset-header-formly',
  templateUrl: './related-asset-header-formly.component.html',
})
export class RelatedAssetHeaderFormlyComponent extends FieldType {
  relatedAssetName$ = this.store.pipe(
    select(selectAllAssets),
    map(assets => {
      const relatedAsset = assets.find(asset => asset.assetUuid === this.model.relatedPropertyUuid);
      if (relatedAsset && 'name' in relatedAsset) {
        return relatedAsset?.name;
      }
      return '';
    }),
  );

  constructor(private store: Store<State>) {
    super();
  }
}
