<div (click)="showIntro()">
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6173 24.873C13.4062 27.3169 16.2962 28.904 19.5568 28.904C21.3464 28.904 23.0244 28.4259 24.4699 27.5905L30 29.3605L28.0741 23.9877C28.9107 22.5414 29.3895 20.8623 29.3895 19.0713C29.3895 15.6397 27.6316 12.6186 24.9671 10.8596"
      stroke="#7FBEB3"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0264 25.1267C20.2801 25.1267 25.3497 20.0571 25.3497 13.8034C25.3497 7.54965 20.2801 2.48001 14.0264 2.48001C7.77265 2.48001 2.703 7.54965 2.703 13.8034C2.703 15.8658 3.25442 17.7995 4.21786 19.465L1.99997 25.6524L8.36848 23.6141C10.0331 24.5762 11.9655 25.1267 14.0264 25.1267Z"
      stroke="#7FBEB3"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="question-mark"
      d="M9.78223 10.6187C9.78237 9.20324 10.4898 7.08011 14.0484 7.08011C17.607 7.08011 18.2746 9.20324 18.2746 10.6187C18.2747 13.4495 14.0484 13.4495 14.0484 13.4495L14.0285 16.2803"
      stroke="#7FBEB3"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      class="question-mark"
      d="M15.0919 20.1727C15.0919 20.7699 14.6201 21.2343 14.0303 21.2343C13.4406 21.2343 12.9688 20.7699 12.9688 20.1727C12.9688 19.5756 13.4406 19.1112 14.0303 19.1112C14.6201 19.1112 15.0919 19.5756 15.0919 20.1727Z"
      stroke="#7FBEB3"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</div>
