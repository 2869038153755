/* eslint-disable max-classes-per-file */
import {HttpErrorResponse} from '@angular/common/http';
import {
  AddClientToFamilyParams,
  CreatePortalFamilyMemberParams,
  DeleteFamilyMemberParams,
  UpdateFamilyMemberParams,
} from '@generated/controllers/Integrations';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Action} from '@ngrx/store';

export enum FamilyMemberActionTypes {
  LoadMembersStart = '[Family Members] Load Members Start',
  LoadMembersFinish = '[Family Members] Load Members Finish',
  CreateMemberStart = '[Family Members] Create Member Start',
  CreateMemberFinish = '[Family Members] Create Member Finish',
  DeleteMemberStart = '[Family Members] Delete Member Start',
  DeleteMemberFinish = '[Family Members] Delete Member Finish',
  UpdateMemberStart = '[Family Members] Update Member Start',
  UpdateMemberFinish = '[Family Members] Update Member Finish',
  /* eslint-disable @typescript-eslint/no-shadow */
  AddExistingFamilyMemberStart = '[Family Members] Add Existing Member Start',
  AddExistingFamilyMemberFinish = '[Family Members] Add Existing Member Finish',
  /* eslint-enable */
}

export class LoadFamilyMembersStart implements Action {
  readonly type = FamilyMemberActionTypes.LoadMembersStart;

  constructor(public payload: {familyUuid: string}) {}
}

export class LoadFamilyMembersFinish implements Action {
  readonly type = FamilyMemberActionTypes.LoadMembersFinish;

  constructor(
    public payload: {familyMembers: FamilyMember[]; error: HttpErrorResponse; familyUuid: string},
  ) {}
}

export class CreateFamilyMemberStart implements Action {
  readonly type = FamilyMemberActionTypes.CreateMemberStart;

  constructor(public payload: CreatePortalFamilyMemberParams) {}
}

export class CreateFamilyMemberFinish implements Action {
  readonly type = FamilyMemberActionTypes.CreateMemberFinish;

  constructor(public payload: {newMember: FamilyMember; error: HttpErrorResponse}) {}
}

export class UpdateFamilyMemberStart implements Action {
  readonly type = FamilyMemberActionTypes.UpdateMemberStart;

  constructor(public payload: UpdateFamilyMemberParams) {}
}

export class UpdateFamilyMemberFinish implements Action {
  readonly type = FamilyMemberActionTypes.UpdateMemberFinish;

  constructor(public payload: {newMember: FamilyMember; error: HttpErrorResponse}) {}
}

export class DeleteFamilyMemberStart implements Action {
  readonly type = FamilyMemberActionTypes.DeleteMemberStart;

  constructor(public payload: DeleteFamilyMemberParams) {}
}

export class DeleteFamilyMemberFinish implements Action {
  readonly type = FamilyMemberActionTypes.DeleteMemberFinish;

  constructor(public payload: {sugar_uuid: string; error: HttpErrorResponse}) {}
}

export class AddExistingFamilyMemberStart implements Action {
  readonly type = FamilyMemberActionTypes.AddExistingFamilyMemberStart;

  constructor(public payload: AddClientToFamilyParams) {}
}

export class AddExistingFamilyMemberFinish implements Action {
  readonly type = FamilyMemberActionTypes.AddExistingFamilyMemberFinish;

  constructor(
    public payload: {family_uuid: string; familyMember: FamilyMember; error: HttpErrorResponse},
  ) {}
}

export type FamilyMembersActions =
  | LoadFamilyMembersStart
  | LoadFamilyMembersFinish
  | CreateFamilyMemberStart
  | CreateFamilyMemberFinish
  | DeleteFamilyMemberStart
  | DeleteFamilyMemberFinish
  | UpdateFamilyMemberStart
  | UpdateFamilyMemberFinish
  | AddExistingFamilyMemberStart
  | AddExistingFamilyMemberFinish;
