import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorFilter} from './error-filter';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  constructor(private responseFilter: ErrorFilter) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if ('HttpErrorResponse' === error.name && this.responseFilter.filter(error.status)) {
          this.handleErrorResponse(req, error);
        }
        return throwError(error);
      }),
    );
  }

  private handleErrorResponse(req: HttpRequest<any>, res: HttpErrorResponse): void {
    const event: Sentry.Event = {
      message: `${req.method} request to ${req.url} failed with ${res.status}.`,
      contexts: {
        context: {
          request: {
            method: req.method,
            url: req.url,
          },
          response: {
            status: res.status,
            message: res.message,
          },
        },
      },
      extra: {
        'request.body': req.body,
        'response.error': res.error,
      },
    };

    Sentry.captureEvent(event);
  }
}
