import produce from 'immer';
import {findLastIndex} from 'lodash';
import {FinancialPlanAction, FinancialPlanActions} from './financial-plan.actions';
import {FinancialPlanState, initialFinancialPlanState} from './financial-plan.state';

const producer = (state: FinancialPlanState, action: FinancialPlanAction) =>
  FinancialPlanActions.match(action, {
    FP_SetData: data => {
      state.financialPlanData = data.financialPlanData;
    },
    FP_SetFinancialEvaluationSentences: data => {
      state.financialPlanData.financial.evaluation.sentences = data.sentences;
    },
    FP_SetFinancialEvaluationRating: data => {
      state.financialPlanData.financial.evaluation.rating = data.rating;
    },
    FP_SetFinancialRecommendationsSentences: data => {
      state.financialPlanData.financial.recommendations.sentences = data.sentences;
    },
    FP_SetFinancialRecommendationsRating: data => {
      state.financialPlanData.financial.recommendations.rating = data.rating;
    },
    FP_SetPropertiesEvaluationSentences: data => {
      state.financialPlanData.properties.evaluation.sentences = data.sentences;
    },
    FP_SetPropertiesEvaluationRating: data => {
      state.financialPlanData.properties.evaluation.rating = data.rating;
    },
    FP_SetPropertiesRecommendationsSentences: data => {
      state.financialPlanData.properties.recommendations.sentences = data.sentences;
    },
    FP_SetPropertiesRecommendationsRating: data => {
      state.financialPlanData.properties.evaluation.rating = data.rating;
    },
    FP_SetAdditionalContractsAdvisorSolution: data => {
      state.financialPlanData.additionalContracts = {advisorSolution: data.advisorSolution};
    },
    FP_UpdateRating: data => {
      let objectives = state.financialPlanData.objectives;
      if (!objectives) {
        state.financialPlanData.objectives = objectives = [];
      }
      const objectiveItem = objectives.find(o => o.objectiveAssetUuid === data.objectiveAssetUuid);
      if (objectiveItem) {
        objectiveItem.data[data.field].rating = data.value;
      }
    },
    FP_SetPrintSettings: data => {
      state.printSettings = data.settings;
    },
    FP_TogglePrintSection: data => {
      const sections = new Set(state.printSettings.hiddenSections);
      if (!data.print) sections.add(data.section.id);
      else {
        const lastHiddenAncestorIndex = findLastIndex(data.ancestors || [], s =>
          sections.has(s.id),
        );
        for (let i = 0; i <= lastHiddenAncestorIndex ?? -1; i++) {
          addAll(
            sections,
            data.ancestors[i].children.map(s => s.id),
          );
        }
        deleteAll(sections, [
          data.section.id,
          ...data.ancestors.map(s => s.id),
          ...data.descendants.map(s => s.id),
        ]);
      }
      state.printSettings.hiddenSections = Array.from(sections);
    },
    FP_TogglePrintSetting: data => {
      state.printSettings[data.setting] = data.value;
    },
    default: () => {},
  });

export function reducer(
  state = initialFinancialPlanState,
  action: FinancialPlanAction,
): FinancialPlanState {
  return produce(producer)(state, action);
}

function addAll<T>(set: Set<T>, items: T[]) {
  items.forEach(set.add, set);
}

function deleteAll<T>(set: Set<T>, items: T[]) {
  items.forEach(set.delete, set);
}
