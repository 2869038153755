import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ErrorFilter} from './error-filter';
import {SENTRY_INTERCEPTOR_CONFIG} from './sentry-interceptor.config';
import {SentryInterceptor} from './sentry.interceptor';

export interface SentryInterceptorConfig {
  blacklistCodes?: number[];
  whitelistCodes?: number[];
}

@NgModule()
export class SentryInterceptorModule {
  static forRoot(
    config: SentryInterceptorConfig = {},
  ): ModuleWithProviders<SentryInterceptorModule> {
    return {
      ngModule: SentryInterceptorModule,
      providers: [
        {
          provide: SENTRY_INTERCEPTOR_CONFIG,
          useValue: config,
        },
        ErrorFilter,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SentryInterceptor,
          multi: true,
        },
      ],
    };
  }
}
