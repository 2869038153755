import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {DatepickerModule} from '../../forms/datepicker/datepicker.module';
import {DatepickerFormlyComponent} from './datepicker-formly.component';

@NgModule({
  declarations: [DatepickerFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatepickerModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
  ],
  exports: [DatepickerFormlyComponent],
})
export class DatepickerFormlyModule {}
