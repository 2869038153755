import {Injectable} from '@angular/core';
import {BasePropertyType} from '@shared/analysis/asset-types/common/base-property-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {VehicleAsset} from '@shared/analysis/models/movable-properties';

@Injectable()
export class VehicleAssetType extends BasePropertyType {
  constructor() {
    super();
  }

  create(): VehicleAsset {
    return {
      ...super.create(),
      type: AssetType.Vehicle,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.Vehicle],
      type: AssetType.Vehicle,
    };
  }
}
