import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {IOption} from '@shared/ui';
import {isObservable, Observable, of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AssetDetailModalHandlerService} from 'src/app/modules/client/pages/asset-detail/asset-detail-modal-handler.service';

@Component({
  selector: 'kpt-related-asset-select-formly',
  templateUrl: './related-asset-select-formly.component.html',
  styleUrls: ['./related-asset-select-formly.component.scss'],
})
export class RelatedAssetSelectFormlyComponent extends FieldType implements OnInit {
  options$: Observable<IOption[]>;

  constructor(private assetsHandlerService: AssetDetailModalHandlerService) {
    super();
  }

  noop() {}

  ngOnInit() {
    if (isObservable(this.to.options)) {
      this.options$ = this.to.options.pipe(filter(r => Boolean(r)));
    } else {
      this.options$ = of(this.to.options);
    }
  }

  edit(assetUuid: string) {
    this.assetsHandlerService.edit(assetUuid);
  }

  add() {
    this.assetsHandlerService.add(this.model.type, this.key as string);
  }

  preventDefault(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }
}
