import {formatCurrency, formatNumber} from '@angular/common';

export type CurrencyBoldness = 'none' | 'amount' | 'all';

export function formatCZK(value: number, boldness: CurrencyBoldness = 'none'): string {
  switch (boldness) {
    case 'amount':
      return `<strong>${replaceMinusSign(
        formatCurrency(value, 'cs-CZ', '', 'symbol', '1.0-0'),
      )}</strong>&nbsp;Kč`;
    case 'all':
      return formatCZKbold(value);
    default:
      return replaceMinusSign(formatCurrency(value, 'cs-CZ', 'Kč', 'symbol', '1.0-0'));
  }
}

export function formatCZKbold(value: number): string {
  return `<strong>${formatCZK(value)}</strong>`;
}

export function formatNumberInCzech(value: number, maxFractionDigits = 0): string {
  const unitDefinitions = [
    {threshold: 1e9, unit: 'mld.'},
    {threshold: 1e6, unit: 'mil.'},
    {threshold: 1e3, unit: 'tis.'},
  ];
  for (const {threshold, unit} of unitDefinitions) {
    if (Math.abs(value) >= threshold) {
      return replaceMinusSign(
        `${formatNumber(value / threshold, 'cs-CZ', '1.0-' + maxFractionDigits)}\u00A0${unit}`,
      );
    }
  }
  return replaceMinusSign(formatNumber(value, 'cs-CZ', '1.0-' + maxFractionDigits));
}

export function formatNumberInCzechFixedWidth(value: number): string {
  const unitDefinitions = [
    {threshold: 1e9, unit: 'mld.'},
    {threshold: 1e6, unit: 'mil.'},
    {threshold: 1e3, unit: 'tis.'},
  ];
  for (const {threshold, unit} of unitDefinitions) {
    if (Math.abs(value) >= threshold) {
      const fractionDigits = getFractionDigits(value / threshold);
      return replaceMinusSign(
        `${formatNumber(
          value / threshold,
          'cs-CZ',
          `1.${fractionDigits}-${fractionDigits}`,
        )}\u00A0${unit}`,
      );
    }
  }
  const fractionDigits = getFractionDigits(value);
  return replaceMinusSign(formatNumber(value, 'cs-CZ', `1.${fractionDigits}-${fractionDigits}`));
}

function getFractionDigits(number: number): number {
  if (number < 10) return 2;
  if (number < 100) return 1;
  return 0;
}

export function formatCzkInCzech(value: number, maxFractionDigits = 0): string {
  return formatNumberInCzech(value, maxFractionDigits) + '\u00A0Kč';
}

export function formatCzkInCzechFixedWidth(value: number): string {
  return formatNumberInCzechFixedWidth(value) + '\u00A0Kč';
}

/** Replace dash (-) with minus (−) sign. */
export function replaceMinusSign(value: string): string {
  return value.replace(/^-/, '\u2212');
}
