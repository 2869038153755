import {Component, Input} from '@angular/core';
import {PensionInvestmentSurveyResult} from './pension-summary.models';

const investorLevelTooltips: string[] = [
  'nezávisle na získaných bodech – klient neakceptující ztrátu – požadavek na investici s garancí bez ztrát,' +
    ' klientovi lze doporučit pouze garantované fondy',

  '-4 až 5 bodů – základní investor – klient vzhledem ke svým znalostem a zkušenostem by měl do svého portfolia' +
    ' zařadit produkty odpovídající pouze konzervativnímu profilu, tj. spojené s nízkým rizikem ztráty (SRI max. 2)',

  '6 až 14 bodů – informovaný investor – klient má znalosti a zkušenosti umožňující zařadit mu do svého portfolia' +
    ' zařadit produkty odpovídající konzervativnímu až vyváženému profilu, tj. spojené s vyšším rizikem ztráty investice (SRI max. 5)',

  '15 až 28 bodů – pokročilý investor – klient s touto úrovní může do svého portfolia zařadit produkty' +
    ' odpovídající všem investičním profilům (i s vysokým rizikem ztráty – SRI max. 7)',
];

@Component({
  selector: 'kpt-investment-survey-pension-summary',
  templateUrl: './pension-summary.component.html',
  styleUrls: ['./pension-summary.component.scss'],
})
export class PensionSummaryComponent {
  @Input()
  result: PensionInvestmentSurveyResult;

  constructor() {}

  get investorLevelTooltip(): string {
    return investorLevelTooltips.join('<br><br>');
  }
}
