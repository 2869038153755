import {Component, OnInit} from '@angular/core';
import {FamilyMember} from '@generated/model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Selector, Store} from '@ngrx/store';
import {FieldWrapper, FormlyForm} from '@ngx-formly/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {
  AdvisorProposalState,
  Asset,
  AssetType,
  ClientProposalState,
} from '@shared/analysis/models/asset';
import {
  InsuranceAsset,
  InsuredPerson,
  RiskLifeInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {PersonalInsuranceSelectionVM} from '@shared/analysis/models/life-insurance-risks-vm';
import {ObjectivesAsset} from '@shared/analysis/models/objectives';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectObjectivesAssets,
} from '@shared/analysis/store';
import {isEqual, reject, sum} from 'lodash';
import {combineLatest, Observable} from 'rxjs';
import {delay, distinctUntilChanged, map, startWith, take} from 'rxjs/operators';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  getFamilyProvisionTable,
  PersonInsuranceRisk,
  selectPersonsInsuranceRisks,
  Table,
} from 'src/app/modules/financial-plan/store';
import {LifeInsuranceService} from 'src/app/modules/life-insurance/life-insurance.service';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';
import {reverseCamelCasedRiskIds, RiskIdCamelCased} from 'src/store/models/risk.models';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

const allowedAssetTypes: AssetType[] = [AssetType.RiskLifeInsurance];

@UntilDestroy()
@Component({
  selector: 'kpt-insurance-value-formly-wrapper',
  templateUrl: './insurance-value-formly-wrapper.component.html',
  styleUrls: ['./insurance-value-formly-wrapper.component.scss'],
})
export class InsuranceValueFormlyWrapperComponent extends FieldWrapper implements OnInit {
  insuranceValue: number;

  private allowedAssetType: boolean;

  constructor(
    private store: Store<State>,
    private assetsHandlerService: AssetsHandlerService,
    private formlyForm: FormlyForm,
    private featureFlagsService: FeatureFlagsService,
    private lifeInsuranceService: LifeInsuranceService,
  ) {
    super();
  }

  get visible(): boolean {
    return (
      this.allowedAssetType &&
      this.assetsHandlerService.openAssetSituation !== Situation.Current &&
      isFinite(this.insuranceValue) &&
      this.insuranceValue > 0
    );
  }

  ngOnInit() {
    const situation = this.assetsHandlerService.openAssetSituation;
    const situationAssets$ = this.getSituationAssetsWithoutCurrentlyEdited(situation);
    const currentAsset$ = this.getCurrentAsset();
    const objectiveAsset$ = this.getObjectiveAsset(currentAsset$);
    const insuranceRisks$ = this.getInsuranceRisks();
    const familyMembers$ = this.getFamilyMembers();

    if (this.featureFlagsService.showNewDashboard) {
      this.lifeInsuranceService.selections$
        .pipe(
          take(1),
          map(selections => this.computeInsuranceValue2(selections)),
          delay(0),
          untilDestroyed(this),
        )
        .subscribe(insuranceValue => (this.insuranceValue = insuranceValue));
    } else {
      combineLatest([situationAssets$, objectiveAsset$, insuranceRisks$, familyMembers$])
        .pipe(
          map(([situationAssets, objectiveAsset, insuranceRisks, familyMembers]) =>
            this.computeInsuranceValue(
              objectiveAsset,
              situation,
              situationAssets,
              insuranceRisks,
              familyMembers,
            ),
          ),
          delay(0),
          untilDestroyed(this),
        )
        .subscribe(insuranceValue => (this.insuranceValue = insuranceValue));
    }

    currentAsset$.pipe(untilDestroyed(this)).subscribe(asset => {
      this.allowedAssetType =
        asset && allowedAssetTypes.some(assetType => assetType === asset.type);
    });
  }

  setInsuranceValue() {
    this.form.get(this.key as string).setValue(Math.round(this.insuranceValue));
  }

  private getCurrentAsset(): Observable<InsuranceAsset> {
    return this.formlyForm.form.valueChanges.pipe(
      startWith(this.formlyForm.form.value as object),
      map(value => ({...this.formlyForm.model, ...value} as InsuranceAsset)),
      distinctUntilChanged(isEqual),
    );
  }

  private getObjectiveAsset(currentAsset$: Observable<any>): Observable<ObjectivesAsset> {
    return combineLatest([currentAsset$, this.store.pipe(select(selectObjectivesAssets))]).pipe(
      map(([currentAsset, objectiveAssets]) => {
        return objectiveAssets.find(
          a => a.assetUuid === currentAsset.relatedObjectiveUuid,
        ) as ObjectivesAsset;
      }),
      distinctUntilChanged(isEqual),
    );
  }

  private getSituationAssetsWithoutCurrentlyEdited(situation: Situation): Observable<Asset[]> {
    const situationAssetsSelector = this.getSituationAssetsSelector(situation);
    return this.store.pipe(
      select(situationAssetsSelector),
      map(assets => reject(assets, asset => asset.assetUuid === this.formlyForm.model.assetUuid)),
      distinctUntilChanged(isEqual),
    );
  }

  private getSituationAssetsSelector(situation: Situation): Selector<State, Asset[]> {
    switch (situation) {
      case Situation.Current:
        return selectCurrentAssets;
      case Situation.AdvisorProposed:
        return selectObjectiveAdvisorProposedAssets;
      case Situation.ClientProposed:
        return selectObjectiveClientProposedAssets;
    }
  }

  private getInsuranceRisks(): Observable<PersonInsuranceRisk[]> {
    return this.store.pipe(select(selectPersonsInsuranceRisks));
  }

  private getFamilyMembers(): Observable<FamilyMember[]> {
    return this.store.pipe(select(getFamilyMembers));
  }

  private computeInsuranceValue(
    objectiveAsset: ObjectivesAsset,
    situation: Situation,
    situationAssets: Asset[],
    insuranceRisks: PersonInsuranceRisk[],
    familyMembers: FamilyMember[],
  ): number {
    if (!objectiveAsset) return -1;

    const riskId = this.key as string;

    const familyMemberUuid = (this.model as InsuredPerson).insuredPersonUuid;
    const familyMember = familyMembers.find(member => member.sugarUuid === familyMemberUuid);
    if (!familyMember) return -1;

    const personInsuranceRisks = insuranceRisks.find(
      personInsuranceRisk => personInsuranceRisk.person.id === familyMemberUuid,
    );
    if (!personInsuranceRisks) return -1;

    const insuranceRisk = personInsuranceRisks.risks.find(
      risk => risk.key === reverseCamelCasedRiskIds[riskId as RiskIdCamelCased],
    );
    if (!insuranceRisk) return -1;

    const table = getFamilyProvisionTable(situation, situationAssets, familyMember, objectiveAsset);

    const otherAssetsSum = this.sumOtherAssets(table, familyMemberUuid, riskId);

    return insuranceRisk.computedValue - otherAssetsSum;
  }

  private computeInsuranceValue2(selections: PersonalInsuranceSelectionVM[]) {
    const riskId = this.key as string;

    const familyMemberUuid = (this.model as InsuredPerson).insuredPersonUuid;

    const selection = selections.find(s => s.person.id === familyMemberUuid);
    if (!selection) return -1;

    let risk = selection.leftProvision.risks.find(r => r.data.active && r.data.key === riskId);
    if (!risk) {
      risk = selection.rightProvision.risks.find(r => r.data.active && r.data.key === riskId);
    }
    if (!risk) return -1;

    return risk.calculatedValue;
  }

  private sumOtherAssets(table: Table, familyMemberUuid: string, riskId: string): number {
    return sum(
      table.rows
        .filter(
          row =>
            row.advisorProposalState !== AdvisorProposalState.Terminated &&
            row.clientProposalState !== ClientProposalState.AdvisorTerminated &&
            row.clientProposalState !== ClientProposalState.ClientTerminated &&
            row.clientProposalState !== ClientProposalState.NewClientRejected,
        )
        .map(row => {
          const insuredPerson = (row.asset as RiskLifeInsuranceAsset).insuredPersons.find(
            person => person.insuredPersonUuid === familyMemberUuid,
          );
          if (!insuredPerson) return 0;
          return insuredPerson[riskId as keyof InsuredPerson] ?? 0;
        }),
    );
  }
}
