<kpt-input
  #fieldRef
  [formControl]="formControl"
  [label]="to.label"
  [type]="type"
  [min]="to.min"
  [max]="to.max"
  [maxLength]="to.maxLength"
  [required]="to.required"
  [rows]="to.rows"
  [appendText]="to.appendText"
  [autofocus]="to.autofocus"
  [readonly]="to.readonly"
  (valueChange)="onChange(field, $event)"
  [subtext]="to.subtext"
  [appendTemplate]="to.appendTemplate"
  [disabledField]="to.disabled"
  [smartformEnabled]="to.smartformEnabled"
  [smartformInstance]="to.smartformInstance"
  [smartformFieldType]="to.smartformFieldType"
  [mask]="to.mask"
  [autocompleteObservable]="to.autocompleteObservable"
>
</kpt-input>
