import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {ToObservable} from '@shared/decorators/to-observable';
import {ProposalsProductModalData} from '@shared/lib/components/modal/modals/proposals-product/proposals-product.component';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {ProductsService} from '@shared/services/products.service';
import {IModalSize} from '@shared/ui';
import {combineLatest, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay, take} from 'rxjs/operators';
import {DataValidationService} from 'src/app/modules/life-insurance-old/6-summary/data-validation.service';
import {
  LifeInsuranceFormGroup,
  LifeInsurancePerson,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  isFinancialStateBad,
  selectAdultPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';
import {ProductSet} from 'src/store/models/products.models';

@UntilDestroy()
@Component({
  selector: 'kpt-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
})
export class ProductHeaderComponent implements OnDestroy {
  @Input()
  @ToObservable()
  productId: number;
  productId$: Observable<number>;

  @Input()
  formId: number;

  @Input()
  group: LifeInsuranceFormGroup;

  @Input()
  personId: string;

  partnerName: string;
  productName: string;
  productCode: string;
  LifeInsuranceFormGroup = LifeInsuranceFormGroup;
  private investmentEligiblePersons$ = this.getInvestmentEligiblePersons();

  constructor(
    private store: Store<State>,
    private productsService: ProductsService,
    private modalService: ModalService,
    private dataValidatorService: DataValidationService,
    private cd: ChangeDetectorRef,
  ) {
    combineLatest(
      this.productId$,
      this.productsService.getProducts(ProductSet.LifeInsuranceRisk),
      this.productsService.getProducts(ProductSet.LifeInsuranceInvestment),
    )
      .pipe(untilDestroyed(this))
      .subscribe(([productId, riskProducts, investmentProducts]) => {
        const products = [...riskProducts, ...investmentProducts];
        const product = products.find(p => p.id === productId);
        this.partnerName = product ? product.partnerName : 'neznámé';
        this.productName = product ? product.name : 'neznámé';
        this.productCode = product ? product.code : 'neznámé';
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {}

  selectProduct() {
    this.investmentEligiblePersons$.pipe(take(1)).subscribe(investmentEligiblePersons => {
      const data: ProposalsProductModalData = {
        productId: this.productId,
        formId: this.formId,
        investmentEligiblePersons,
      };
      this.modalService.openModal({
        component: 'ProposalsProductComponent',
        size: IModalSize.MD,
        data,
      });
    });
  }

  private getInvestmentEligiblePersons(): Observable<LifeInsurancePerson[]> {
    return this.store.pipe(
      select(selectAdultPersons),
      mergeMap(persons => this.filterInvestmentEligiblePersons(persons)),
      shareReplay(1),
      untilDestroyed(this),
    );
  }

  private filterInvestmentEligiblePersons(
    persons: LifeInsurancePerson[],
  ): Observable<LifeInsurancePerson[]> {
    return combineLatest(
      persons.map(person =>
        !isFinancialStateBad(person) && person.createReserves
          ? this.dataValidatorService
              .investmentEligibleFromSurvey(person.id)
              .pipe(map(eligible => (eligible ? person : null)))
          : of(null),
      ),
    ).pipe(map(eligiblePersons => eligiblePersons.filter(Boolean)));
  }
}
