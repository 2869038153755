<div class="content-box py-7 px-6 px-md-0" [class.wide]="wide">
  <div class="content-box__holder">
    <div class="mb-global-indentation">
      <div class="row">
        <div [class.col-12]="wide" [class.col-md-10]="!wide" [class.offset-md-1]="!wide">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
