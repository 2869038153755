import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {EventSituationButtonConfig} from '@shared/ui';

@Component({
  selector: 'kpt-event-situation-button',
  templateUrl: './event-situation-button.component.html',
  styleUrls: ['./event-situation-button.component.scss'],
})
export class EventSituationButtonComponent implements OnInit {
  @Input()
  config: EventSituationButtonConfig;

  @Input()
  dataTransferData: string;

  @Output()
  eventAction: EventEmitter<EventSituationButtonConfig> =
    new EventEmitter<EventSituationButtonConfig>();

  @Output()
  dragActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('eventSituation', {static: true})
  eventSituation: ElementRef;

  private clonedElement: any;

  ngOnInit() {
    this.config.isDraggable = !(this.config && this.config.isDraggable === false);
    this.config.isActive = !(this.config && this.config.isActive === false);
  }

  dragStart = (event: any, config: any) => {
    if (config.isDraggable && config.isActive) {
      const target = event.srcElement || event.target;
      const originalRect = (
        this.eventSituation.nativeElement as HTMLElement
      ).getBoundingClientRect();
      this.clonedElement = target.cloneNode(true);
      this.clonedElement.style.width = `${originalRect.width}px`;
      this.clonedElement.style.height = `${originalRect.height}px`;
      this.clonedElement.style.position = 'absolute';
      this.clonedElement.style.left = '-9999px';
      event.dataTransfer.setData(this.dataTransferData, JSON.stringify(config));
      document.body.appendChild(this.clonedElement);
      event.dataTransfer.setDragImage(this.clonedElement, 0, originalRect.height / 2);
      this.config.isDragged = true;
      this.dragActive.emit(true);
    }
  };

  dragEnd = (_event: any) => {
    this.dragActive.emit(false);
    this.config.isDragged = false;
    this.clonedElement.parentNode.removeChild(this.clonedElement);
  };
}
