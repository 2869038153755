import {Product} from '@generated/defs/Product';
import {produce} from 'immer';
import {ProductActions, ProductActionsUnion} from 'src/store/actions/products.actions';

export interface ProductsState {
  [productSet: string]: {
    loading: boolean;
    products: Product[];
  };
}

export const initialState: ProductsState = {};

const productsReducer = produce((state: ProductsState, action: ProductActionsUnion) => {
  switch (action.type) {
    case ProductActions.loadProducts.type: {
      if (!state[action.productSet]) state[action.productSet] = {loading: false, products: []};
      state[action.productSet].loading = true;
      break;
    }

    case ProductActions.loadProductsSuccess.type: {
      state[action.productSet].loading = false;
      state[action.productSet].products = action.products;
      break;
    }
  }
});

export function reducer(state = initialState, action: ProductActionsUnion): ProductsState {
  return productsReducer(state, action);
}
