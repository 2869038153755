import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {DocumentTypeLifeInsuranceFileEnum} from '@generated/defs/LifeInsuranceFile';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AttachmentsModalData} from '@shared/lib/components/modal/modals/attachments-modal/attachments-modal.models';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {documentTypeOptions, StoreFile, StoreFileUpdate, UIFile} from '@shared/models/file.models';
import {LifeInsuranceInitialUploadData, UploaderService} from '@shared/services/uploader.service';
import {isEqual, sortBy} from 'lodash';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'kpt-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  providers: [UploaderService],
})
export class AttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label = 'Vybrat soubory';
  @Input() modalTitle = 'Nahrát soubory';
  @Input() documentType: DocumentTypeLifeInsuranceFileEnum = 'DOTPP';
  @Input() initialUploadData: LifeInsuranceInitialUploadData;
  @Input() options: FileUploaderOptions = {
    url: environment.apiBaseUrl + '/api/integrations/upload-file/',
    allowedFileType: ['image', 'pdf', 'doc', 'xls', 'ppt', 'application'],
    maxFileSize: 1024 * 1024 * 15,
  };
  @Input() files: StoreFile[] = [];

  @Output() fileUploaded = new EventEmitter<UIFile>();
  @Output() fileUpdated = new EventEmitter<StoreFileUpdate>();
  @Output() fileRemoved = new EventEmitter<string>();

  isFileDragOver = false;
  uploader: FileUploader;
  filesList$: Observable<UIFile[]>;
  fileAddingFailed: FileLikeObject = null;

  private hideClose$ = new BehaviorSubject<boolean>(false);
  private files$ = new BehaviorSubject<StoreFile[]>(this.files);
  private uploadInProgress$ = new BehaviorSubject<boolean>(false);
  private onModalSubmit$ = new EventEmitter<UIFile[]>();
  private modalFinished$ = new Subject<void>();

  constructor(private uploaderService: UploaderService, private modalService: ModalService) {}

  ngOnInit() {
    this.uploader = this.uploaderService.initUploader(this.options, this.initialUploadData);

    this.uploaderService.filesAdded$.pipe(untilDestroyed(this)).subscribe(files => {
      this.fileAddingFailed = null;
      this.openModal(files);
    });

    this.uploaderService.fileUploaded$
      .pipe(filter<StoreFile>(Boolean), untilDestroyed(this))
      .subscribe(file => {
        this.fileUploaded.next(file);
        this.modalService.closeModal();
        this.uploadInProgress$.next(true);
        this.hideClose$.next(false);
      });

    this.uploaderService.fileAddingFailed$
      .pipe(untilDestroyed(this))
      .subscribe(file => (this.fileAddingFailed = file));

    this.filesList$ = combineLatest(this.files$, this.uploaderService.queuedFiles$).pipe(
      map(([files, queuedFiles]) => sortBy([...files, ...queuedFiles], 'created')),
      distinctUntilChanged(isEqual),
      untilDestroyed(this),
    );

    this.onModalSubmit$
      .pipe(untilDestroyed(this))
      .subscribe((files: UIFile[]) => this.onModalSubmit(files));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.files) {
      this.files$.next(this.files);
    }
  }

  ngOnDestroy() {}

  onFileDragOver(display: boolean) {
    this.isFileDragOver = display;
  }

  onUpdateFile(update: StoreFileUpdate) {
    this.fileUpdated.emit(update);
  }

  onRemoveFromQueue(item: FileItem) {
    this.uploaderService.removeFromQueue(item);
  }

  onShowDeleteConfirmation({dmsUuid}: UIFile) {
    this.fileRemoved.emit(dmsUuid);
  }

  private openModal(files: UIFile[]) {
    this.uploadInProgress$.next(false);

    const data: AttachmentsModalData = {
      files,
      documentTypeOptions,
      onSubmit: this.onModalSubmit$,
      uploadInProgress: this.uploadInProgress$,
    };
    this.modalService.openModal({
      title: 'Nahrát soubory',
      component: 'AttachmentsModalComponent',
      data,
      hideClose$: this.hideClose$.asObservable(),
    });

    this.modalService.activeModal$
      .pipe(
        filter(modal => !modal),
        takeUntil(this.modalFinished$),
        untilDestroyed(this),
      )
      .subscribe(() => this.onModalCancel(files));
  }

  private onModalSubmit(files: UIFile[]) {
    this.uploadInProgress$.next(true);
    this.hideClose$.next(true);
    this.modalFinished$.next();
    files.forEach(file => {
      this.uploaderService.updateFile(file);
    });
    this.uploaderService.uploadFiles();
  }

  private onModalCancel(files: UIFile[]) {
    this.modalFinished$.next();
    files.forEach(({item}) => {
      this.uploaderService.removeFromQueue(item);
    });
  }
}
