import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldWrapper, FormlyFieldConfig} from '@ngx-formly/core';
import {combineLatest, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormlyFormHandlerService} from '../../formly-form-handler.service';
import {TabsFormlyService} from '../tabs-formly/tabs-formly.service';

const enterAnimation = trigger('enterAnimation', [
  transition(':enter', [style({opacity: 0}), animate('300ms', style({opacity: 1}))]),
]);

@UntilDestroy()
@Component({
  selector: 'kpt-tab-formly',
  templateUrl: './tab-formly.component.html',
  styleUrls: ['./tab-formly.component.scss'],
  animations: [enterAnimation],
})
export class TabFormlyComponent extends FieldWrapper implements OnInit, OnDestroy {
  hasError = false;
  active = false;
  formKeys: string[]; // keys inside form displayed in this tab

  constructor(
    private tabsService: TabsFormlyService,
    @Optional() private formlyFormHandler: FormlyFormHandlerService,
  ) {
    super();
  }

  ngOnInit() {
    this.formKeys = this.getFormKeys();

    this.tabsService.addTab(this);

    const valueChanges = this.form.valueChanges.pipe(
      startWith(true),
      map(_ => this.isValid()),
    );

    combineLatest([
      valueChanges,
      this.formlyFormHandler ? this.formlyFormHandler.getSubmitted() : of(false),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([valid, submitted]) => {
        this.hasError = !valid && submitted;
      });
  }

  ngOnDestroy() {
    this.tabsService.removeTab(this);
  }

  private isValid(): boolean {
    let valid = true;
    this.formKeys.forEach(key => {
      if (this.form.get(key)?.valid === false) valid = false;
    });

    return valid;
  }

  private getFormKeys(): string[] {
    return this.getFieldConfigs(this.field.fieldGroup)
      .map(ctrl => ctrl.key as string)
      .filter(key => Boolean(key));
  }

  private getFieldConfigs(formlyFieldConfigs: FormlyFieldConfig[]): FormlyFieldConfig[] {
    return formlyFieldConfigs.reduce(
      (controls, ctrl) =>
        controls.concat(
          ctrl.type === 'formly-group' ? this.getFieldConfigs(ctrl.fieldGroup) : [ctrl],
        ),
      [] as FormlyFieldConfig[],
    );
  }
}
