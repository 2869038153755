<div *ngIf="loading" class="placeloader__wrapper">
  <div class="placeloader__holder">
    <div class="placeloader">
      <span class="placeloader__symbol">
        <span class="placeloader__symbol-circle"></span>
        <span class="placeloader__symbol-circle"></span>
        <span class="placeloader__symbol-circle"></span>
      </span>
      <span *ngIf="label" class="placeloader__text">
        {{ label }}
      </span>
    </div>
  </div>
</div>
