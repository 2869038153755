<kpt-modal-wrapper
  *ngIf="data$ | async as memberData"
  [heading]="memberData.name"
  (close)="dismiss()"
>
  <form [formGroup]="form">
    <div class="p-4">
      <formly-form [model]="memberData.model" [fields]="memberData.fields" [form]="form">
      </formly-form>
    </div>

    <div class="text-center">
      <kpt-button
        (action)="save()"
        [isSubmitButton]="true"
        [label]="memberData.model.sugarUuid ? 'Uložit' : 'Přidat'"
        type="primary"
      >
      </kpt-button>

      <kpt-button
        *ngIf="memberData.showRemoveButton"
        (action)="remove()"
        label="Smazat"
        [hasOutline]="true"
        customClass="ml-3"
        type="primary"
      >
      </kpt-button>
    </div>
  </form>
</kpt-modal-wrapper>
