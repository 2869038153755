import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {column, row} from '@lib/utils';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {liveCoreSecondaryStatus, liveCoreStatus} from '@shared/analysis/core-sync.data';
import {FieldLabel} from '@shared/analysis/field-label';
import {AssetType, RegularPaymentType} from '@shared/analysis/models/asset';
import {getOptionsFromNames, participationTypeNames} from '@shared/analysis/models/assets.enums';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {InsuredPerson, LifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {selectFamilyHead} from '@shared/analysis/operators';
import {selectCurrentAssets} from '@shared/analysis/store';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {getBasicField, getSelectField, getSliderPercentField} from '@shared/ui/formly/formly.utils';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable({providedIn: 'root'})
export class FinancialProductAssetType {
  private familyHead: FamilyMember;

  constructor(private store: Store<State>) {
    this.store.pipe(selectFamilyHead()).subscribe(familyHead => {
      this.familyHead = familyHead;
    });
  }

  getLifeInsurance(): Partial<LifeInsuranceAsset> {
    return {
      ...this.getInsurance(),
      insuredPersons: [],
    };
  }

  getInsurance() {
    return {
      ...this.getFinancialProduct(),
      ...this.getStakeholderUuid(),
    };
  }

  getLifeInsuranceWithValue() {
    return {
      ...this.getLifeInsurance(),
      ...this.getPresentValueAndDate(),
    };
  }

  private getPresentValueAndDate() {
    return {
      presentValue: 0,
      presentValueDate: this.today(),
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected getFinancialProduct(): Partial<CommonFinancialProductAsset> {
    return {
      ...this.getValueAndDate(),
      ...this.getStakeholderUuid(),
      frequency: RegularPaymentType.Month,
      attachments: [],
      foreignContract: true,
      coreStatus: liveCoreStatus.code,
      coreSecondaryStatus: liveCoreSecondaryStatus.code,
    };
  }

  private getValueAndDate() {
    return {
      ...this.getValue(),
      start: this.today(),
    };
  }

  private getValue() {
    return {
      value: 0,
    };
  }

  private getStakeholderUuid() {
    return {
      stakeholderUuid: this.getFamilyHeadUuid(),
    };
  }

  private getFamilyHeadUuid(): string | undefined {
    return this.familyHead?.sugarUuid;
  }

  private today(): string {
    return moment().format('YYYY-MM-DD');
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async copyRisksFromFamilyProvision(asset: LifeInsuranceAsset) {
    const assets = await this.store.pipe(select(selectCurrentAssets), take(1)).toPromise();
    const familyProvision = assets.find(
      a => a.type === AssetType.FamilyProvision,
    ) as FamilyProvisionAsset;
    if (!familyProvision) {
      return;
    }

    const personRisks: {[personId: string]: Partial<InsuredPerson>} = {};

    for (const risk of familyProvision?.lifeInsuranceRisks ?? []) {
      if (risk.active) {
        if (!personRisks[risk.personId]) {
          personRisks[risk.personId] = {
            insuredPersonUuid: risk.personId,
            otherRisks: [],
          };
          asset.insuredPersons.push(personRisks[risk.personId] as InsuredPerson);
        }
        personRisks[risk.personId][risk.key] = risk.limit;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  commonInsuranceParticipationFields(): FormlyFieldConfig[] {
    return [
      row([
        column(
          [getSliderPercentField('percentParticipation', FieldLabel.PARTICIPATION, 1)],
          [6, 6],
        ),
        column(
          [
            getSelectField(
              'participationType',
              FieldLabel.PARTICIPATION_TYPE,
              getOptionsFromNames(participationTypeNames),
            ),
          ],
          [2, 2],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Currency,
              'participationValue',
              FieldLabel.PARTICIPATION_VALUE,
            ),
          ],
          [4, 4],
        ),
      ]),
    ];
  }
}
