import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {CheckboxModule, FormModule} from '@shared/ui/index';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {InsuranceOfCreditProductFormlyComponent} from './insurance-of-credit-product-formly.component';

@NgModule({
  declarations: [InsuranceOfCreditProductFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    FormModule,
    FormlyModule,
  ],
  exports: [InsuranceOfCreditProductFormlyComponent],
})
export class InsuranceOfCreditProductFormlyModule {}
