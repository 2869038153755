import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {PensionEnsurementAccordionComponent} from '@shared/analysis/asset-types/family-provision/pension-ensurement-accordion/pension-ensurement-accordion.component';
import {ReservesAccordionComponent} from '@shared/analysis/asset-types/family-provision/reserves-accordion/reserves-accordion.component';
import {RisksSelectionModule} from '@shared/analysis/components/risks-selection/risks-selection.module';
import {
  adultRiskDefinitions,
  childRiskDefinitions,
} from '@shared/analysis/models/life-insurance-risks';
import {
  getRisksVM,
  RiskVM,
  setRiskActive,
  setRiskAge,
  setRiskLimit,
} from '@shared/analysis/models/life-insurance-risks-vm';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {selectCurrentAssets} from '@shared/analysis/store';
import {AccordionModule, CommonBaseModule} from '@shared/lib';
import {LifeInsuranceSharedModule} from '@shared/life-insurance/life-insurance-shared.module';
import {FormModule} from '@shared/ui';
import {ButtonGroupComponent} from '@shared/ui/button-group/button-group.component';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {uniq} from 'lodash';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {LifeInsuranceService} from 'src/app/modules/life-insurance/life-insurance.service';
import {State} from 'src/store';

interface RiskRow {
  personId: string;
  riskKey: string;
  leftRisk: RiskVM;
  rightRisk: RiskVM;
  limit?: number;
  age?: number;
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-family-provision-form',
  templateUrl: './family-provision-form.component.html',
  styleUrls: ['./family-provision-form.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    RisksSelectionModule,
    ButtonGroupComponent,
    AccordionModule,
    LifeInsuranceSharedModule,
    TooltipModule,
    ReservesAccordionComponent,
    PensionEnsurementAccordionComponent,
    CommonBaseModule,
    InvestmentRiskProfileComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class FamilyProvisionFormComponent implements OnInit, OnChanges {
  @Input()
  asset: FamilyProvisionAsset;

  @Input() mode: 'analysis' | 'plan' | 'summary' = 'analysis';
  @Input() disabled = false;
  @Input() selectedPersonId: string;

  asset$ = new BehaviorSubject<FamilyProvisionAsset>(null);

  persons$ = this.lifeInsuranceService.persons$.pipe(untilDestroyed(this));

  personButtons$ = this.persons$.pipe(
    map(persons => {
      return persons.map(person => ({
        label: person.name,
        value: person.id,
      }));
    }),
    untilDestroyed(this),
  );

  selectedPersonId$ = new BehaviorSubject<string>(null);
  selectedPerson$ = combineLatest([this.persons$, this.selectedPersonId$]).pipe(
    map(([persons, selectedPersonId]) => {
      // use selectedPersonId from component's input if available, otherwise use the one selected by user
      selectedPersonId = this.selectedPersonId || selectedPersonId;
      return persons.find(p => p.id === selectedPersonId);
    }),
    untilDestroyed(this),
  );

  rows$: Observable<RiskRow[]> = combineLatest([
    this.asset$,
    this.persons$,
    this.selectedPerson$,
    this.store.pipe(select(selectCurrentAssets)),
  ]).pipe(
    map(([asset, persons, selectedPerson, otherAssets]) => {
      if (!asset) {
        return [];
      }

      const selections = getRisksVM(asset, persons, otherAssets);

      const selection = selections.find(s => s.person.id === selectedPerson.id);
      if (!selection) {
        return [];
      }

      // Get a list of risk keys for the selected person. Note that some risks are only on the left or right side.
      let riskKeys = (selectedPerson.child ? childRiskDefinitions : adultRiskDefinitions).map(
        d => d.key,
      );
      riskKeys = uniq(riskKeys);

      // generate rows for the table
      const rows: RiskRow[] = riskKeys.map(riskKey => ({
        personId: selectedPerson.id,
        riskKey,
        leftRisk: selection.leftProvision.risks.find(r => r.riskDef.key === riskKey),
        rightRisk: selection.rightProvision.risks.find(r => r.riskDef.key === riskKey),
      }));

      // set limit and ages on the rows
      for (const row of rows) {
        row.limit = row.leftRisk?.data.limit || row.rightRisk?.data.limit;
        row.age = row.leftRisk?.data.age || row.rightRisk?.data.age;
      }

      return rows;
    }),
    untilDestroyed(this),
  );

  personData$ = combineLatest([this.asset$, this.selectedPerson$]).pipe(
    map(([asset, selectedPerson]) => {
      let personData = asset.lifeInsurancePersonDatas.find(
        data => data.personId === selectedPerson.id,
      );

      if (!personData) {
        personData = {
          personId: selectedPerson.id,
          riskProfession: false,
          riskProfessionNote: '',
        };
        asset.lifeInsurancePersonDatas.push(personData);
      }

      return personData;
    }),
    untilDestroyed(this),
  );

  wantsInvestment$ = this.lifeInsuranceService.wantsInvestment$.pipe(untilDestroyed(this));

  constructor(
    private store: Store<State>,
    private lifeInsuranceService: LifeInsuranceService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.persons$.pipe(first()).subscribe(persons => {
      if (persons.length) {
        this.selectedPersonId$.next(persons[0].id);
      }
    });

    if (this.route.snapshot.queryParams.mode === 'plan') {
      this.mode = 'plan';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.asset) {
      this.asset$.next(changes.asset.currentValue);
    }
  }

  setActive(risk: RiskVM, active: boolean) {
    setRiskActive(this.asset, risk, active);
    // force $selections to update
    this.asset$.next(this.asset);
  }

  setLimit(row: RiskRow, limit: number) {
    const risk = row.leftRisk?.data.active ? row.leftRisk : row.rightRisk;
    setRiskLimit(this.asset, risk, limit);
    // force $selections to update
    this.asset$.next(this.asset);
  }

  setAge(row: RiskRow, age: number) {
    const risk = row.leftRisk?.data.active ? row.leftRisk : row.rightRisk;
    setRiskAge(this.asset, risk, age);
    // force $selections to update
    this.asset$.next(this.asset);
  }

  limitLowerThanRecommended(row: RiskRow) {
    if (!row.leftRisk?.data.active && !row.rightRisk?.data.active) {
      return false;
    }

    const calculatedValue = row.leftRisk.data.active
      ? row.leftRisk.calculatedValue
      : row.rightRisk.calculatedValue;

    return calculatedValue && row.limit && row.limit < calculatedValue;
  }

  setRecommendedLimit(row: RiskRow) {
    const risk = row.leftRisk?.data.active ? row.leftRisk : row.rightRisk;
    setRiskLimit(this.asset, risk, risk.calculatedValue);
    // force $selections to update
    this.asset$.next(this.asset);
  }

  trackByPersonAndRisk(_index: number, row: RiskRow) {
    return `${row.personId}-${row.riskKey}`;
  }
}
