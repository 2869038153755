<div
  class="accordion__item"
  [class.accordion__item--active]="isOpen"
  [class.accordion__item--disabled]="isDisabled"
>
  <div class="accordion__item__first-row">
    <ng-content select=".accordion-item-stripe"></ng-content>
    <div
      [class]="'accordion__toggle ' + panelClass"
      [ngClass]="{accordion__toggle__custom: headingTemplate}"
      (click)="toggleOpen()"
    >
      <ng-container *ngTemplateOutlet="headingTemplate || defaultHeadingTemplate"></ng-container>
    </div>
  </div>

  <div
    *ngIf="!isOpen && hasPersistedContent"
    class="accordion__content accordion__content--persisted"
  >
    <ng-content select="[accordion-item-persisted]"></ng-content>
  </div>

  <div class="accordion__content">
    <div class="accordion__content-item">
      <p>
        <ng-content></ng-content>
        <ng-content select="[accordion-additional-item]"></ng-content>
      </p>
    </div>
  </div>
</div>

<ng-template #defaultHeadingTemplate>
  <ng-content select=".accordion-item-title"></ng-content>
  <span class="accordion__toggle-title" [innerHTML]="heading"></span>
  <ng-content select=".accordion-item-subheading"></ng-content>
  <span
    *ngIf="!hideIcon"
    class="icon accordion__toggle-icon"
    [ngClass]="[isOpen ? iconOpened : iconClosed]"
  ></span>
</ng-template>
