/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {UiService} from '@generated/controllers/Ui';
import {select, Store} from '@ngrx/store';
import {isEqual} from 'lodash';
import {of, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  skip,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {State} from 'src/store';
import {
  AdvisorUiSettingsActions,
  AdvisorUiSettingsState,
  selectAdvisorUiSettingsState,
} from './store';
/* eslint-enable max-len */

const DEBOUNCE_TIME = 500;

@Injectable()
export class AdvisorUiSettingsDataService {
  private cancel$ = new Subject<void>();

  constructor(private store: Store<State>, private uiService: UiService) {}

  loadDataAndSaveChanges() {
    this.stop();
    this.loadUiSettings();
    this.listenToUiSettingsChanges();
  }

  stop() {
    this.cancel$.next();
    this.store.dispatch(AdvisorUiSettingsActions.UI_ClearAdvisorUiSettings());
  }

  private loadUiSettings() {
    this.uiService
      .getAdvisorUiSettings()
      .pipe(take(1))
      .subscribe(data => {
        this.store.dispatch(AdvisorUiSettingsActions.UI_SetAdvisorUiSettings(data));
      });
  }

  private listenToUiSettingsChanges() {
    this.store
      .pipe(
        select(selectAdvisorUiSettingsState),
        skip(2), // skip once subscribed to store and skip once data from BE are written to store
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged<AdvisorUiSettingsState>(isEqual),
        takeUntil(this.cancel$),
        switchMap(settings => {
          return this.uiService
            .createOrUpdateAdvisorUiSettings({
              data: settings,
            })
            .pipe(
              catchError(error => {
                console.error('Failed to save advisor ui settings:', error);
                return of();
              }),
            );
        }),
      )
      .subscribe();
  }
}
