import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'join',
  pure: false,
  // TODO uncomment when upgraded to NG 13
  // standalone: true,
})
export class JoinPipe implements PipeTransform {
  transform(value: readonly string[] | null | undefined, glue: string | null | undefined): string {
    if (!value) return '';
    return value.filter(Boolean).join(glue || '');
  }
}
