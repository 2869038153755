import {DocumentTypeLifeInsuranceFileEnum} from '@generated/defs/LifeInsuranceFile';
import {PensionEnsurementTypePersonEnum} from '@generated/defs/Person';
import {PriorityQuestionnaireSerializerAnswer} from '@generated/defs/PriorityQuestionnaireSerializerAnswer';
import {SixMonthReserve} from '@generated/defs/SixMonthReserve';
import {StoreFile} from '@shared/models/file.models';
import {InsuranceType} from 'src/app/modules/life-insurance-old/6-summary/summary.models';
import {FinancialOutlookType} from 'src/shared/analysis/models/income-attributes';
import {Provision} from 'src/store/models/risk.models';

/**
 * @deprecated
 */
export interface LifeInsurancePerson {
  id: string;
  child: boolean;
  name: string;
  lastName?: string;
  age: number;
  income?: number;
  incomeContractor?: number;
  passiveIncome?: number;
  expenses?: number;
  savings?: number;
  debts?: number;
  createReserves?: boolean;
  taxRelief?: boolean;
  employeeContribution?: boolean;
  pensionEnsurement?: boolean;
  pensionEnsurementType?: PensionEnsurementTypePersonEnum;
  individualSelection: boolean;
  order: number;
  childrenTaxAdvantage?: number;
  financialOutlook?: FinancialOutlookType;
  currentInsurance?: LifeInsuranceCurrentInsurance;
  netMonthlyIncomeContractor?: number;
  monthlySickLeaveInsurancePaymentContractor?: number;
  other?: number;
  otherContractor?: number;
  debtsTogether: boolean;
  familyHead?: boolean;
  resetScoredProducts?: boolean;
  useOtherIncome?: boolean;
  otherIncomeAssetUuids: string[];
  usePaymentProtectionInsurance?: boolean;
  useSixMonthReserve?: boolean;
  sixMonthReserve: SixMonthReserve;
}

// same as Risk
/**
 * @deprecated
 */
export interface LifeInsuranceRisk {
  personId: string;
  riskId: string;
  formId: number;
  value?: number;
  age?: number;
  provision?: Provision;
  myChoice?: boolean;
}

/**
 * @deprecated
 */
export interface LifeInsuranceRiskUniqueIdent {
  personId: string;
  riskId: string;
  provision: Provision;
}

/**
 * @deprecated
 */
export interface LifeInsuranceCustomRiskDefinition {
  id: string;
  personId: string;
  label?: string;
  myChoice?: boolean;
  order: number;
}

/**
 * @deprecated
 */
export interface LifeInsuranceForm {
  id: number;
  personId: string;
  group: LifeInsuranceFormGroup;
  linkId?: number;
  withProduct?: boolean;
  name?: string;
  productId?: number;
  insuranceType?: InsuranceType;
  price?: number;
  cancelled?: boolean;
  isNew?: boolean;
  order?: number;
  alternativeSetId?: number;
  stakeholderId?: string; // save to BE
  contractId?: number; // save to BE
}

/**
 * @deprecated
 */
export enum LifeInsuranceFormGroup {
  Current = 'current',
  Selected = 'selected',
  Recommended = 'recommended',
  Final = 'final',
}

/**
 * @deprecated
 */
export enum LifeInsuranceFormState {
  None = 'none',
  New = 'new',
  Unchanged = 'unchanged',
  Changed = 'changed',
  Cancelled = 'cancelled',
  UnchangedByClient = 'unchanged-by-client',
  ChangedByClient = 'changed-by-client',
  CancelledByClient = 'cancelled-by-client',
  NewWithClientChanges = 'new-with-client-changes',
  RejectedByClient = 'rejected-by-client',
  Alternative = 'alternative',
}

/**
 * @deprecated
 */
export enum LifeInsuranceCurrentInsurance {
  NoInsurance = 'no-insurance',
  DontTell = 'dont-tell',
  HasInsurance = 'has-insurance',
}

/**
 * @deprecated
 */
export interface LifeInsuranceUI {
  expandedForm: {
    [personId: string]: ExpandedForm;
  };
  showInsuranceCalculations: boolean;
  summariesProgress: LifeInsuranceSummariesProgress;
  financialAnalysisChanged: boolean;
}

/**
 * @deprecated
 */
export type ExpandedForm = {
  [group in LifeInsuranceFormGroup]: boolean;
};

/**
 * @deprecated
 */
export interface LifeInsuranceSummariesProgress {
  unfinishedParticipantIds: string[];
  selectedParticipantId: string;
}
/**
 * @deprecated
 */
export interface LifeInsurancePriorityProducts {
  [key: string]: PriorityQuestionnaireSerializerAnswer;
}

/**
 * @deprecated
 */
export interface LifeInsuranceStoreFile extends StoreFile<DocumentTypeLifeInsuranceFileEnum> {
  formId?: number;
}

/**
 * @deprecated
 */
export interface AdditionalAnalysisData {
  debtsTogether: boolean;
}
