import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NotificationMessageModule} from '@shared/ui/notification-message/notification-message.module';
import {NotificationCenterComponent} from './notification-center.component';

@NgModule({
  declarations: [NotificationCenterComponent],
  imports: [CommonModule, NotificationMessageModule],
  exports: [NotificationCenterComponent],
})
export class NotificationCenterModule {}
