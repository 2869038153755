import {AbstractControl} from '@angular/forms';
import {SixMonthReserve} from '@generated/defs/SixMonthReserve';
import {convertToMonthly} from '@shared/analysis/asset.utils';
import {calculateEquity} from '@shared/analysis/evaluation';
import {Asset, AssetType, FamilyMemberAsset, ValueAsset} from '@shared/analysis/models/asset';
import {MortgageAsset} from '@shared/analysis/models/credit-products';
import {creditProductInsuranceRisks} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.models';
import {get, sum, uniq} from 'lodash';
import * as moment from 'moment';
import {
  ExpandedForm,
  LifeInsurancePerson,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  MortgageRepaymentByRisk,
  Provision,
  RiskDefinition,
  RiskId,
  RiskInput,
} from 'src/store/models/risk.models';
import {defaultExpandedForm} from './store/life-insurance.helpers';

export function calculateRisk(
  risk: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePerson,
  persons: LifeInsurancePerson[],
  assets: Asset[],
): number {
  try {
    return risk.calculate(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(`Error while calculating risk for riskId=${risk?.id}, provision=${provision}`);
    return 0;
  }
}

export function calculateHelp(
  risk: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePerson,
  persons: LifeInsurancePerson[],
  assets: Asset[],
): string {
  try {
    return risk.help(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(`Error while calculating help for riskId=${risk.id}, provision=${provision}`);
    return '';
  }
}

export function calculateDescription(
  risk: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePerson,
  persons: LifeInsurancePerson[],
  assets: Asset[],
): string {
  try {
    return risk.description(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(
      `Error while calculating description for riskId=${risk.id}, provision=${provision}`,
    );
    return '';
  }
}

function getSixMonthReserve(person: LifeInsurancePerson): SixMonthReserve {
  return {
    incapacity: person.useSixMonthReserve && person.sixMonthReserve.incapacity,
    dailyCompensation: person.useSixMonthReserve && person.sixMonthReserve.dailyCompensation,
    physicalDamage: person.useSixMonthReserve && person.sixMonthReserve.physicalDamage,
    hospitalization: person.useSixMonthReserve && person.sixMonthReserve.hospitalization,
  };
}

function getRiskInput(
  persons: LifeInsurancePerson[],
  person: LifeInsurancePerson,
  assets: Asset[],
  provision: Provision,
): RiskInput {
  const childrenAges = persons.filter(p => p.child).map(p => p.age);
  const otherIncome = computeOtherIncome(person, assets);
  const mortgageRepaymentByRisk = computeMortgageRepaymentAndRisks(person, assets, persons);
  const sixMonthReserve = getSixMonthReserve(person);

  return {
    provision,
    monthlyIncomeEmployee: person.income || 0,
    monthlyIncomeContractor: person.incomeContractor || 0,
    otherEmployee: person.other || 0,
    otherContractor: person.otherContractor || 0,
    insuranceContractor:
      (person.incomeContractor > 0 && person.monthlySickLeaveInsurancePaymentContractor) || 0,
    netMonthlyExpense: person.expenses,
    age: person.age,
    debt: person.debts,
    childrenAges,
    childrenTaxAdvantage: person.childrenTaxAdvantage || 0,
    persons,
    otherIncome,
    mortgageRepaymentByRisk,
    sixMonthReserve,
  };
}

export function computeRecommendedInsuranceAmount(persons: LifeInsurancePerson[]): number {
  return Math.round(
    0.08 *
      persons.reduce(
        (acc: number, p: LifeInsurancePerson) =>
          acc + (!p.child ? p.income + p.incomeContractor : 0),
        0,
      ),
  );
}

export function setDefaultExpandedForms(
  persons: LifeInsurancePerson[],
  stateExpandedForm: {[personId: string]: ExpandedForm} = {},
): {[personId: string]: ExpandedForm} {
  return persons.reduce((acc, p) => {
    if (!stateExpandedForm[p.id]) {
      acc[p.id] = {
        ...defaultExpandedForm(),
        current: true,
      };
    } else {
      acc[p.id] = stateExpandedForm[p.id];
    }

    return acc;
  }, {} as {[personId: string]: ExpandedForm});
}

export const getInsuredPersons = (
  product: AbstractControl,
  participants: {[sugarUuid: string]: string} = {},
): string => {
  return product
    .get('insuredPersonIds')
    .value.map((id: string) => participants[id])
    .join(', ');
};

function computeOtherIncome(person: LifeInsurancePerson, assets: Asset[]) {
  return person.useOtherIncome
    ? sum(
        assets
          .filter(
            asset =>
              (person.otherIncomeAssetUuids ?? []).includes(asset.assetUuid) &&
              (asset as FamilyMemberAsset).familyMemberUuid === person.id,
          )
          .map(asset => (asset as ValueAsset).value),
      )
    : 0;
}

function computeMortgageRepaymentAndRisks(
  person: LifeInsurancePerson,
  assets: Asset[],
  persons: LifeInsurancePerson[],
): MortgageRepaymentByRisk {
  if (!person.usePaymentProtectionInsurance) return {};

  const repaymentByRisk: MortgageRepaymentByRisk = {};
  const today = moment().toISOString();

  assets
    .filter(asset => asset.type === AssetType.Mortgage && asset.stakeholderUuid === person.id)
    .forEach((asset: MortgageAsset) => {
      const risks = asset.insuredRisksByDebtors.find(r => r.debtorUuid === person.id);

      const selectedRiskIds = uniq(
        creditProductInsuranceRisks
          .filter(r => get(risks, r.key))
          .filter(risk => Boolean(risk.riskId))
          .map(risk => risk.riskId),
      );

      selectedRiskIds
        .filter(riskId => riskId !== RiskId.DeathDebtRepayment)
        .forEach(riskId => {
          repaymentByRisk[riskId] =
            (repaymentByRisk[riskId] ?? 0) + convertToMonthly(asset.value, asset.frequency);
        });
    });

  assets
    .filter(asset => asset.type === AssetType.Mortgage && asset.stakeholderUuid === person.id)
    .forEach((asset: MortgageAsset) => {
      const personIds = persons.map(p => p.id);
      const selectedRiskIds = uniq(
        creditProductInsuranceRisks
          .filter(r =>
            asset.insuredRisksByDebtors.some(
              risks => get(risks, r.key) && personIds.includes(risks.debtorUuid),
            ),
          )
          .filter(risk => Boolean(risk.riskId))
          .map(risk => risk.riskId),
      );

      selectedRiskIds
        .filter(riskId => riskId === RiskId.DeathDebtRepayment)
        .forEach(riskId => {
          repaymentByRisk[riskId] =
            (repaymentByRisk[riskId] ?? 0) - calculateEquity(today, [asset]);
        });
    });

  return repaymentByRisk;
}
