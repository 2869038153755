import {Injectable, NgZone} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SmartformInstance} from '@shared/models/smartform.models';
import {ReplaySubject} from 'rxjs';
import {delay} from 'rxjs/operators';
import {baseColors} from 'src/assets/scss/01_base/colors';
import {environment} from '../../environments/environment';

declare let smartform: any;

@Injectable()
export class SmartformService {
  scriptLoaded$ = new ReplaySubject(1);
  private scriptInserted = false;

  constructor(private ngZone: NgZone) {}

  insertSmartformScript() {
    if (this.scriptInserted === false) {
      this.scriptInserted = true;

      this.loadScript().then(_ => {
        this.scriptLoaded$.next(true);
      });
    }
  }

  scriptReady() {
    return this.scriptLoaded$.pipe(delay(400));
  }

  initSmartformInstance(form: UntypedFormGroup, smartformInstance: SmartformInstance) {
    this.ngZone.runOutsideAngular(() => {
      this.initSmartformInstanceInternal(form, smartformInstance);
    });
  }

  initSmartformInstanceInternal(form: UntypedFormGroup, smartformInstance: SmartformInstance) {
    if (typeof smartform.rebindAllForms !== 'function') return;
    smartform.rebindAllForms();

    const smartformInstanceName = smartform.getInstance(
      'smartform-instance-' + smartformInstance.name,
    );
    if (smartformInstanceName === null) return;

    smartformInstanceName.setZIndex(10000);
    smartformInstanceName.setSelectionBackgroundColor(baseColors.btnOutlineBgHover);
    smartformInstanceName.setForegroundColor(baseColors.blue);
    smartformInstanceName.setBorderColor(baseColors.gray);
    smartformInstanceName.setSelectionCallback((_: any, text: string, fieldType: string) => {
      if (fieldType === 'smartform-city') {
        form.patchValue({[smartformInstance.fieldNames.city]: text});
      } else if (fieldType === 'smartform-zip') {
        form.patchValue({[smartformInstance.fieldNames.zip]: text});
      } else if (fieldType === 'smartform-street') {
        form.patchValue({[smartformInstance.fieldNames.street]: text});
      } else if (fieldType === 'smartform-number') {
        form.patchValue({[smartformInstance.fieldNames.number]: text});
      } else if (fieldType === 'smartform-street-and-number') {
        form.patchValue({[smartformInstance.fieldNames.streetAndNumber]: text});
      } else if (fieldType === 'smartform-whole-address') {
        form.patchValue({[smartformInstance.fieldNames.wholeAddress]: text});
      }
      return true;
    });
  }

  private loadScript() {
    return new Promise((resolve, _) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://secure.smartform.cz/api/v1/smartform.js';
      document.head.insertBefore(script, document.head.firstChild);
      script.onload = resolve;

      const script2 = document.createElement('script');
      script2.text = `var smartform = smartform || {};
        smartform.beforeInit = function () {
          smartform.setClientId('${environment.smartformClientId}');
        }`;
      document.head.append(script2);
    });
  }
}
