import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, NgModuleRef, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {selectAllAssets} from '@shared/analysis/store';
import {cloneDeep} from 'lodash';
import {first} from 'rxjs/operators';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {AssetsHandlerService} from 'src/shared/analysis/assets-handler.service';
import {AssetType} from 'src/shared/analysis/models/asset';
import {State} from 'src/store';
import {AssetDetailCreator} from './asset-detail-creator.service';
import {AssetDetailModule} from './asset-detail.module';
import {AssetModalSize} from './modal-wrapper/asset-detail-modal-wrapper.component';

export enum AssetDetailType {
  Edit = 'asset-edit',
  New = 'asset-create',
  Clone = 'asset-clone',
}

@Component({
  selector: 'kpt-asset-detail-page',
  template: '',
  styleUrls: ['./asset-detail.component.scss'],
})
export class AssetDetailPageComponent implements OnInit, OnDestroy {
  modalType: AssetDetailType;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private assetCreator: AssetDetailCreator,
    private assetsService: AssetsHandlerService,
    private ngModuleRef: NgModuleRef<AssetDetailModule>,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.modalType = this.route.snapshot.data.type;
  }

  ngOnInit() {
    const modalSizeParam = this.route.snapshot.queryParamMap.get('modalSize') as AssetModalSize;
    const modalSize = modalSizeParam ?? AssetModalSize.Large;
    const queryParamsMap = this.route.snapshot.queryParamMap;
    const advisorProposalState = queryParamsMap.get('advisorProposalState');
    const clientProposalState = queryParamsMap.get('clientProposalState');
    const relatedObjectiveUuid = queryParamsMap.get('relatedObjectiveUuid');
    const relatedPropertyUuid = queryParamsMap.get('relatedPropertyUuid');
    const stakeholderUuid = queryParamsMap.get('stakeholderUuid');
    const proposal = queryParamsMap.get('proposal');
    const showDeleteButton = queryParamsMap.has('showDeleteButton');
    const situation = queryParamsMap.get('situation');

    this.assetsService.openAssetSituation = situation as Situation;

    if (this.modalType === AssetDetailType.Edit) {
      this.assetsService.openEditModal(
        this.route.snapshot.paramMap.get('uuid'),
        this.ngModuleRef,
        modalSize,
        showDeleteButton,
        {},
      );
    } else if (this.modalType === AssetDetailType.New) {
      const assetType = this.route.snapshot.paramMap.get('type') as AssetType;
      if (!assetType) return;

      this.assetsService.openAddModal(
        assetType,
        this.ngModuleRef,
        modalSize,
        showDeleteButton,
        false,
        {
          relatedObjectiveUuid,
          relatedPropertyUuid,
          proposal: proposal === 'true',
          advisorProposalState,
          ...(stakeholderUuid ? {stakeholderUuid} : {}),
        },
      );
    } else if (this.modalType === AssetDetailType.Clone) {
      const originalAssetUuid = this.route.snapshot.paramMap.get('uuid');
      this.store.pipe(select(selectAllAssets), first()).subscribe(assets => {
        const originalAsset = assets.find(asset => asset.assetUuid === originalAssetUuid);
        if (!originalAsset) return;
        const assetType = originalAsset.type;
        this.assetsService.openAddModal(
          assetType,
          this.ngModuleRef,
          modalSize,
          showDeleteButton,
          true,
          {
            ...cloneDeep(originalAsset),
            proposal: true,
            foreignContract: true,
            sugarUuid: null,
            advisorProposalState,
            clientProposalState,
            originalAssetUuid,
          },
        );
      });
    }
  }

  ngOnDestroy() {
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (returnUrl) this.assetCreator.destroy();
      }, 100);
    }
  }
}
