import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {ClientRequirements} from '@generated/defs/ClientRequirements';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {requirementsI18n} from 'src/app/modules/life-insurance-old/6-summary/client-requirements/client-requirements.component';
import {InsuranceType} from 'src/app/modules/life-insurance-old/6-summary/summary.models';

@UntilDestroy()
@Component({
  selector: 'kpt-client-requirements-modal',
  templateUrl: './client-requirements-modal.component.html',
  styleUrls: ['./client-requirements-modal.component.scss'],
})
export class ClientRequirementsModalComponent implements OnInit, OnDestroy {
  clientRequirementsIzpForm = new UntypedFormGroup({
    taxRelief: new UntypedFormControl(),
    employeeContribution: new UntypedFormControl(),
    highProtection: new UntypedFormControl(),
    investmentOption: new UntypedFormControl(),
    investmentOptionWithProtection: new UntypedFormControl(),
    risksCovered: new UntypedFormControl(),
    bestRatio: new UntypedFormControl(),
    bestPrice: new UntypedFormControl(),
    bestReturn: new UntypedFormControl(),
    paymentProtectionInsurance: new UntypedFormControl(),
    sixMonthReserve: new UntypedFormControl(),
    otherRequirement: new UntypedFormControl(),
    otherRequirementText: new UntypedFormControl(),
  });

  clientRequirementsRzpForm = new UntypedFormGroup({
    highProtection: new UntypedFormControl(),
    paymentProtectionInsurance: new UntypedFormControl(),
    sixMonthReserve: new UntypedFormControl(),
    otherRequirement: new UntypedFormControl(),
    otherRequirementText: new UntypedFormControl(),
  });

  clientRequirementsForm: UntypedFormGroup = this.clientRequirementsIzpForm;

  requirements = requirementsI18n;
  data: {requirements: ClientRequirements; form: UntypedFormGroup; insuranceType: InsuranceType};
  InsuranceType = InsuranceType;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    if (this.data.insuranceType === InsuranceType.Risk) {
      this.clientRequirementsForm = this.clientRequirementsRzpForm;
    }
    this.clientRequirementsForm.patchValue(this.data.requirements);
    this.clientRequirementsForm.setValidators(this.requirementsValidator);
    this.updateValidation(this.clientRequirementsForm.get('otherRequirement').value);

    this.clientRequirementsForm
      .get('otherRequirement')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(otherRequirement => this.updateValidation(otherRequirement));
  }

  save() {
    markFormGroupTouched(this.clientRequirementsForm);
    if (!this.clientRequirementsForm.valid) return;

    this.data.form.patchValue(this.clientRequirementsForm.getRawValue());
    this.modalService.closeModal();
  }

  ngOnDestroy(): void {}

  private updateValidation(isOtherRequirement: boolean) {
    if (isOtherRequirement) {
      this.clientRequirementsForm.get('otherRequirementText').enable();
      this.clientRequirementsForm.get('otherRequirementText').setValidators(Validators.required);
    } else {
      this.clientRequirementsForm.get('otherRequirementText').setValue(null);
      this.clientRequirementsForm.get('otherRequirementText').disable();
    }

    this.clientRequirementsForm.get('otherRequirementText').updateValueAndValidity();
  }

  private requirementsValidator: ValidatorFn = (
    control: UntypedFormGroup,
  ): ValidationErrors | null => {
    if (control.get('otherRequirement') && !control.get('otherRequirementText'))
      return {noOtherText: true};
    return null;
  };
}
