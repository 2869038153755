import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PipesModule} from '@shared/pipes';
import {RecommendedValueComponent} from '@shared/ui/recommended-value/recommended-value.component';

@NgModule({
  declarations: [RecommendedValueComponent],
  exports: [RecommendedValueComponent],
  imports: [CommonModule, PipesModule, RouterModule],
})
export class RecommendedValueModule {}
