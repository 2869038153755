import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {Subject} from 'rxjs';

export enum AssetTypeGroup {
  Objective = 'objective',
  Property = 'property',
}

export interface SubModalData {
  assetDefinition?: AssetDefinition;
  assetUuid?: string;
  result$?: Subject<Asset>;
  currentAssetType?: AssetType;
  newAssetTypeGroup?: AssetTypeGroup;
}
