<kpt-modal-wrapper heading="Sjednání smlouvy" (close)="dismiss()">
  <!-- focus catcher -->
  <input class="focus-catcher" type="text" />
  <p>
    Chystáte se přejít do eZáznamu.<br />
    Zvolte oblast, pro kterou chcete sjednat novou smlouvu:
  </p>
  <div class="erecord-grid">
    <kpt-button
      type="primary"
      [hasOutline]="true"
      customClass="btn-block"
      [isDisabled]="!insuranceAvailable"
      (action)="open(insuranceHref)"
      >Pojištění, investice, DPS</kpt-button
    >
    <kpt-button
      type="primary"
      [hasOutline]="true"
      customClass="btn-block"
      [isDisabled]="!consumerLoansAvailable"
      (action)="open(consumerLoansHref)"
      >Úvěry pro fyzické osoby</kpt-button
    >
    <kpt-button
      type="primary"
      [hasOutline]="true"
      customClass="btn-block"
      [isDisabled]="!bankProductsAvailable"
      (action)="open(bankProductsHref)"
      >Bankovní produkty</kpt-button
    >
    <kpt-button
      type="primary"
      [hasOutline]="true"
      customClass="btn-block"
      [isDisabled]="!otherProductsAvailable"
      (action)="open(otherProductsHref)"
      >Ostatní</kpt-button
    >
  </div>
</kpt-modal-wrapper>
