import {TypedOption} from '@shared/ui';

export type SexFamilyMemberEnum = 'MALE' | 'FEMALE';

export const sexFamilyMemberOptions: TypedOption<SexFamilyMemberEnum>[] = [
  {
    key: 'MALE',
    label: 'muž',
  },
  {
    key: 'FEMALE',
    label: 'žena',
  },
];

export type TypeFamilyMemberEnum = 'ADULT' | 'CHILD';

export type StatusFamilyMemberEnum = 'PARTNER' | 'MARRIED' | 'SINGLE';
