<kpt-sum-column [proposal]="proposal" [group]="group"></kpt-sum-column>

<div
  *ngIf="!insuranceGroup.myChoice"
  class="expand-position"
  [class.collapse]="insuranceGroup.expanded"
  [class.expand]="!insuranceGroup.expanded"
  (click)="toggleGroup()"
></div>

<div class="forms" *ngIf="insuranceGroup.expanded" [formGroup]="formGroup">
  <div *ngIf="showCurrentInsuranceSelection" class="p-column current-insurance-selection">
    <kpt-radio-group
      [value]="currentInsurance"
      [options]="currentInsuranceOptions"
      (change)="currentInsuranceChanged($event)"
    >
    </kpt-radio-group>
  </div>
  <ng-container *ngIf="!showCurrentInsuranceSelection">
    <kpt-attached-files
      class="p-column insurance-group form"
      *ngFor="let form of insuranceGroup.forms; trackBy: trackByFormId; let formIndex = index"
      [group]="group"
      [form]="form"
      [class.cancelled]="form.cancelled"
      [class.alternative-set]="form.alternativeSetId"
      [class.last-of-alternatives]="isLastOfAlternatives(form.alternativeSetId, formIndex + 1)"
      [initialUploadData]="{
        documentType: documentType,
        firstName: proposal.familyMember.firstName,
        lastName: proposal.familyMember.lastName,
        birthNumber: proposal.familyMember.birthNumber
      }"
      [files]="form.files"
      (addFormFile)="addFile($event)"
      (updateFormFile)="updateFile($event)"
      (removeFormFile)="removeFile($event)"
    >
      <div class="form-state">
        <span *ngIf="form.state === LifeInsuranceFormState.None"></span>
        <span *ngIf="form.state === LifeInsuranceFormState.New">nové</span>
        <span *ngIf="form.state === LifeInsuranceFormState.Changed">změněno</span>
        <span *ngIf="form.state === LifeInsuranceFormState.Unchanged">beze změny</span>
        <span *ngIf="form.state === LifeInsuranceFormState.Cancelled">ke zrušení</span>
        <span *ngIf="form.state === LifeInsuranceFormState.UnchangedByClient"
          >beze změny na žádost klienta</span
        >
        <span *ngIf="form.state === LifeInsuranceFormState.ChangedByClient"
          >změněno na žádost klienta</span
        >
        <span *ngIf="form.state === LifeInsuranceFormState.CancelledByClient"
          >ke zrušení na žádost klienta</span
        >
        <span *ngIf="form.state === LifeInsuranceFormState.NewWithClientChanges"
          >nové s úpravou klientem</span
        >
        <span *ngIf="form.state === LifeInsuranceFormState.RejectedByClient"
          >odmítnuto klientem</span
        >
        <span *ngIf="form.state === LifeInsuranceFormState.Alternative">alternativa</span>
        <a
          *ngIf="form.icon === InsuranceFormIcon.Delete"
          class="icon--interactive header-icon"
          (click)="deleteForm(form)"
        >
          <span class="icon icon--bin"></span>
        </a>
        <a
          *ngIf="form.icon === InsuranceFormIcon.Cancel"
          class="icon--interactive header-icon"
          (click)="cancelForm(form)"
        >
          <span class="icon icon--eye"></span>
        </a>
        <a
          *ngIf="form.icon === InsuranceFormIcon.Restore"
          class="icon--interactive header-icon"
          (click)="restoreForm(form)"
        >
          <span class="icon icon--refresh"></span>
        </a>
      </div>

      <div class="p-row header name">
        <kpt-product-header
          *ngIf="form.withProduct"
          [productId]="form.productId"
          [formId]="form.formId"
          [group]="group"
          [personId]="personId"
        ></kpt-product-header>
        <ng-container *ngIf="!form.withProduct">
          <kpt-input
            type="textarea"
            [spellCheck]="false"
            *ngIf="group === LifeInsuranceFormGroup.Current"
            [formControl]="formGroup.get(['forms', formIndex, 'name'])"
          >
          </kpt-input>
          <kpt-input
            type="textarea"
            [readonly]="true"
            [spellCheck]="false"
            *ngIf="group !== LifeInsuranceFormGroup.Current"
            [formControl]="formGroup.get(['forms', formIndex, 'name'])"
          >
          </kpt-input>
        </ng-container>
      </div>

      <div
        class="p-row body"
        *ngFor="let risk of form.risks; trackBy: trackByRiskId; let riskIndex = index"
        [class.changed]="risk.changed && !form.cancelled"
      >
        <div class="value">
          <kpt-input
            type="number"
            [showErrorsOn]="false"
            [formControl]="formGroup.get(['forms', formIndex, 'risks', riskIndex, 'value'])"
            [autocomplete]="false"
            (focusin)="saveActiveFieldPath(['forms', formIndex, 'risks', riskIndex, 'value'])"
            (focusout)="resetActiveFieldPath()"
            customFormGroupClass="input-no-stepper hide-label"
          >
          </kpt-input>
        </div>
        <div class="age">
          <kpt-input
            type="number"
            [showErrorsOn]="false"
            [formControl]="formGroup.get(['forms', formIndex, 'risks', riskIndex, 'age'])"
            [autocomplete]="false"
            (focusin)="saveActiveFieldPath(['forms', formIndex, 'risks', riskIndex, 'age'])"
            (focusout)="resetActiveFieldPath()"
            customFormGroupClass="input-no-stepper hide-label"
          >
          </kpt-input>
        </div>
      </div>

      <div class="p-row footer" [class.changed]="form.priceChanged && !form.cancelled">
        <div class="value price">
          <kpt-input
            type="number"
            [formControl]="formGroup.get(['forms', formIndex, 'price'])"
            [autocomplete]="false"
            (focusin)="saveActiveFieldPath(['forms', formIndex, 'price'])"
            (focusout)="resetActiveFieldPath()"
            customFormGroupClass="input-no-stepper hide-label"
          >
          </kpt-input>
        </div>
        <div class="age"></div>
      </div>

      <div
        *ngIf="
          form.stakeholderId &&
          (group === LifeInsuranceFormGroup.Recommended || group === LifeInsuranceFormGroup.Final)
        "
        class="p-row stakeholder"
      >
        <kpt-label
          placeholder="Pojistník"
          [value]="personsMap[form.stakeholderId]?.name"
        ></kpt-label>
      </div>

      <div
        *ngIf="deleteConfirmations[form.formId]"
        class="delete-confirmation"
        (click)="cancelDeletion(form)"
        (clickOutside)="cancelDeletion(form)"
        [delayClickOutsideInit]="true"
      >
        <kpt-button
          type="primary"
          label="Smazat"
          (click)="confirmDeleteForm(form); $event.stopPropagation()"
        ></kpt-button>
      </div>
    </kpt-attached-files>

    <div
      class="plus expand-position"
      *ngIf="
        group === LifeInsuranceFormGroup.Current || group === LifeInsuranceFormGroup.Recommended
      "
      (click)="addGroupForm()"
    >
      +
    </div>
  </ng-container>
</div>
