import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Store} from '@ngrx/store';
import {liveCoreSecondaryStatus, liveCoreStatus} from '@shared/analysis/core-sync.data';
import {IndependenceInputMode} from '@shared/analysis/forms/definitions.models';
import {Asset, AssetType, RegularPaymentType} from '@shared/analysis/models/asset';
import {InputMode} from '@shared/analysis/models/common-expenses';
import {PropertyOwnershipType} from '@shared/analysis/models/immovable-properties';
import {InvestmentStrategy} from '@shared/analysis/models/investment-products';
import {
  ChildBirthAsset,
  FinancialIndependenceAsset,
  OtherObjectivePaymentType,
} from '@shared/analysis/models/objectives';
import {selectChildrenFamilyMembers, selectFamilyHead} from '@shared/analysis/operators';
import * as moment from 'moment';
import {State} from 'src/store';

@Injectable()
export class DefaultValueService {
  private familyHead: FamilyMember;
  private firstChild: FamilyMember;

  constructor(private store: Store<State>) {
    this.store.pipe(selectFamilyHead()).subscribe(familyHead => {
      this.familyHead = familyHead;
    });

    this.store
      .pipe(selectChildrenFamilyMembers())
      .subscribe(children => (this.firstChild = children[0]));
  }

  getDefaultValue(assetType: AssetType): Asset {
    const defaultValues: Record<AssetType, Asset> = {
      // Sandbox Assets
      [AssetType.SandboxCurrentBalance]: this.getEmpty(),
      [AssetType.SandboxIncome]: this.getEmpty(),
      [AssetType.SandboxExpenses]: this.getEmpty(),
      [AssetType.SandboxUnitTrust]: this.getEmpty(),
      [AssetType.SandboxHousePurchase]: this.getEmpty(),
      [AssetType.SandboxMortgage]: this.getEmpty(),
      [AssetType.SandboxCarPurchase]: this.getEmpty(),
      [AssetType.SandboxConsumerLoan]: this.getEmpty(),
      [AssetType.SandboxLossOfWork]: this.getEmpty(),
      [AssetType.SandboxInheritance]: this.getEmpty(),

      // Objectives
      [AssetType.OwnHousing]: this.getObjective(),
      [AssetType.ChildrenFuture]: this.getObjective(),
      [AssetType.Retirement]: this.getEmpty(),
      [AssetType.FinancialIndependence]: this.getFinancialIndependence(),
      [AssetType.ShortTermReserve]: this.getObjective(),
      [AssetType.LongTermReserve]: this.getObjective(),
      [AssetType.BadDebtsRepayment]: this.getObjective(),
      [AssetType.FamilyProvision]: this.getObjective(),
      [AssetType.PropertyProvision]: this.getEmpty(),
      [AssetType.PropertyRequirements]: this.getEmpty(),
      [AssetType.VehicleProvision]: this.getEmpty(),
      [AssetType.VehicleRequirements]: this.getEmpty(),
      [AssetType.ChildBirth]: this.getChildBirth(),
      [AssetType.NewHousing]: this.getObjective(),
      [AssetType.Reconstruction]: this.getObjective(),
      [AssetType.Furnishings]: this.getObjective(),
      [AssetType.Car]: this.getObjective(),
      [AssetType.Vacation]: this.getObjective(),
      [AssetType.OtherObjective]: this.getOtherObjective(),

      // Common Expenses
      [AssetType.CommonExpensesSum]: this.getValueAndDate(),
      [AssetType.Food]: this.getCommonExpenseWithInputMode(),
      [AssetType.HousingExpenses]: this.getCommonExpenseWithInputMode(),
      [AssetType.Drugstore]: this.getCommonExpenseWithInputMode(),
      [AssetType.Transport]: this.getCommonExpenseWithInputMode(),
      [AssetType.Communication]: this.getCommonExpenseWithInputMode(),
      [AssetType.Clothes]: this.getCommonExpense(),
      [AssetType.Hobbies]: this.getCommonExpense(),
      [AssetType.Vacations]: this.getCommonExpense(),
      [AssetType.ChildrenExpenses]: this.getChildrenExpenses(),
      [AssetType.OtherCommonExpenses]: this.getCommonExpenseWithInputMode(),
      [AssetType.ContractorInsurance]: this.getValueAndDate(),

      // Main Incomes
      [AssetType.Employment]: this.getIncome(),
      [AssetType.Contractor]: this.getIncome(),
      [AssetType.FinancialOutlook]: this.getEmpty(),
      [AssetType.ChildrenTaxAdvantage]: this.getEmpty(),

      // Other Incomes
      [AssetType.Maternity]: this.getIncome(),
      [AssetType.Parental]: this.getIncome(),
      [AssetType.UnemploymentBenefits]: this.getIncome(),
      [AssetType.PensionIncome]: this.getIncome(),
      [AssetType.OtherPensionIncome]: this.getIncome(),
      [AssetType.RentalIncome]: this.getIncome(),
      [AssetType.Dividends]: this.getIncome(),
      [AssetType.OtherPassiveIncome]: this.getIncome(),

      // Financial Products - Insurance
      [AssetType.InsuranceProductsSum]: this.getValueAndDate(),
      [AssetType.InvestmentLifeInsurance]: this.getLifeInsuranceWithValue(),
      [AssetType.RiskLifeInsurance]: this.getLifeInsurance(),
      [AssetType.CapitalLifeInsurance]: this.getLifeInsuranceWithValue(),
      [AssetType.PropertyInsurance]: this.getEmpty(),
      [AssetType.LiabilityInsurance]: this.getInsurance(),
      [AssetType.VehicleInsurance]: this.getEmpty(),
      [AssetType.TravelInsurance]: this.getTravelInsurance(),
      [AssetType.BusinessInsurance]: this.getBusinessInsurance(),

      // Financial Products - Investment
      [AssetType.InvestmentProductsSum]: this.getValueAndDate(),
      [AssetType.UnitTrust]: this.getUnitTrust(),
      [AssetType.RealEstateFund]: this.getInvestment(),
      [AssetType.Commodity]: this.getInvestment(),
      [AssetType.ExchangeTradedFunds]: this.getInvestment(),
      [AssetType.Certificates]: this.getInvestment(),
      [AssetType.CombinedDeposits]: this.getInvestment(),
      [AssetType.BuildingSavings]: this.getInvestment(),
      [AssetType.SupplementaryPensionSavings]: this.getSupplementaryPensionSavings(),
      [AssetType.PensionInsurance]: this.getInvestment(),
      [AssetType.SavingsAccount]: this.getInvestment(),

      // Financial Products - Credit
      [AssetType.CreditProductsSum]: this.getValueAndDate(),
      [AssetType.Mortgage]: this.getMortgage(),
      [AssetType.BuildingSavingsLoan]: this.getCredit(),
      [AssetType.Leasing]: this.getCredit(),
      [AssetType.CreditCard]: this.getCredit(),
      [AssetType.Overdraft]: this.getCredit(),
      [AssetType.ConsumerLoan]: this.getCredit(),
      [AssetType.OtherIndividualLoan]: this.getCredit(),

      // Immovable Properties
      [AssetType.ImmovablePropertiesSum]: this.getValueAndDate(),
      [AssetType.Flat]: this.getImmovableProperty(),
      [AssetType.House]: this.getImmovableProperty(),
      [AssetType.Household]: this.getImmovableProperty(),
      [AssetType.HolidayProperty]: this.getImmovableProperty(),
      [AssetType.ApartmentBuilding]: this.getImmovableProperty(),
      [AssetType.Garage]: this.getImmovableProperty(),
      [AssetType.Land]: this.getImmovableProperty(),
      [AssetType.OtherImmovableProperty]: this.getImmovableProperty(),

      // Movable Properties
      [AssetType.MovablePropertiesSum]: this.getValueAndDate(),
      [AssetType.Vehicle]: this.getEmpty(),
      [AssetType.Valuables]: this.getProperty(),
      [AssetType.SpecialValuables]: this.getProperty(),

      // Financial Properties
      [AssetType.FinancialPropertiesSum]: this.getValueAndDate(),
      [AssetType.CurrentAccount]: this.getValueAndDate(),
      [AssetType.CurrentAccountUsage]: this.getValue(),
      [AssetType.TermDeposit]: this.getInvestment(),
      [AssetType.Bonds]: this.getInvestment(),
      [AssetType.QualifiedInvestorFunds]: this.getInvestment(),
      [AssetType.OtherFinancialProperty]: this.getProperty(),

      // Properties Sums
      [AssetType.InvestmentPropertiesSum]: this.getValueAndDate(),
      [AssetType.CreditPropertiesSum]: this.getValueAndDate(),
      [AssetType.InsurancePropertiesSum]: this.getValueAndDate(),

      // Virtual Assets
      [AssetType.VirtualMonthlyBalance]: this.getEmpty(),
    };

    return defaultValues[assetType];
  }

  private getValue(): Asset {
    return {
      value: 0,
    } as Asset;
  }

  private getValueAndDate(): Asset {
    return {
      ...this.getValue(),
      start: this.today(),
    } as Asset;
  }

  private getObjective(): Asset {
    return {
      ...this.getValueAndDate(),
    } as Asset;
  }

  private getFinancialIndependence(): Asset {
    const lifeExpectancy = this.familyHead.sex === 'MALE' ? 76 : 82;
    return {
      ...this.getObjective(),
      monthlyRent: 0,
      independenceInputMode: IndependenceInputMode.Value,
      lifeExpectancy,
    } as FinancialIndependenceAsset;
  }

  private getChildBirth(): Asset {
    return {
      ...this.getObjective(),
      maternityFamilyMemberUuid: this.getFamilyHeadUuid(),
      parentalFamilyMemberUuid: this.getFamilyHeadUuid(),
      parentalBenefitInMonths: 0,
    } as ChildBirthAsset;
  }

  private getOtherObjective(): Asset {
    return {
      ...this.getValueAndDate(),
      payment: OtherObjectivePaymentType.OneTime,
    } as Asset;
  }

  private getCommonExpense(): Asset {
    return {
      ...this.getValueAndDate(),
      indispensablePart: 1,
    } as Asset;
  }

  private getCommonExpenseWithInputMode(): Asset {
    return {
      ...this.getCommonExpense(),
      inputMode: InputMode.Simple,
    } as Asset;
  }

  private getChildrenExpenses(): Asset {
    return {
      ...this.getCommonExpense(),
      familyMemberUuid: this.getFirstChild(),
    } as Asset;
  }

  private getIncome(): Asset {
    return {
      ...this.getValueAndDate(),
      familyMemberUuid: this.getFamilyHeadUuid(),
    } as Asset;
  }

  private getLifeInsuranceWithValue(): Asset {
    return {
      ...this.getLifeInsurance(),
      ...this.getPresentValueAndDate(),
    } as Asset;
  }

  private getLifeInsurance(): Asset {
    return {
      ...this.getInsurance(),
      insuredPersons: [],
    } as Asset;
  }

  private getTravelInsurance(): Asset {
    return {
      ...this.getInsurance(),
      insuredPersonUuids: [],
      riskSports: false,
      otherRisks: [],
    } as Asset;
  }

  private getBusinessInsurance(): Asset {
    return {
      ...this.getInsurance(),
      otherRisks: [],
    } as Asset;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getInsurance(): Asset {
    return {
      ...this.getFinancialProduct(),
      ...this.getStakeholderUuid(),
    } as Asset;
  }

  private getUnitTrust(): Asset {
    return {
      ...this.getInvestment(),
      strategy: InvestmentStrategy.Balanced,
    } as Asset;
  }

  private getInvestment(): Asset {
    return {
      ...this.getFinancialProduct(),
      ...this.getPresentValueAndDate(),
    } as Asset;
  }

  private getSupplementaryPensionSavings(): Asset {
    return {
      ...this.getInvestment(),
      strategy: InvestmentStrategy.Balanced,
    } as Asset;
  }

  private getCredit(): Asset {
    return {
      ...this.getFinancialProduct(),
      outstandingValue: 0,
      outstandingValueDate: this.today(),
    } as Asset;
  }

  private getMortgage(): Asset {
    return {
      ...this.getCredit(),
      insuredRisksByDebtors: [],
    } as Asset;
  }

  private getFinancialProduct(): Asset {
    return {
      ...this.getValueAndDate(),
      ...this.getStakeholderUuid(),
      frequency: RegularPaymentType.Month,
      attachments: [],
      foreignContract: true,
      coreStatus: liveCoreStatus.code,
      coreSecondaryStatus: liveCoreSecondaryStatus.code,
    } as Asset;
  }

  private getImmovableProperty(): Asset {
    return {
      ...this.getProperty(),
      ownershipType: PropertyOwnershipType.Personal,
    } as Asset;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getProperty(): Asset {
    return {
      ...this.getValueAndDate(),
    } as Asset;
  }

  private getPresentValueAndDate(): Asset {
    return {
      presentValue: 0,
      presentValueDate: this.today(),
    } as Asset;
  }

  private getStakeholderUuid(): Asset {
    return {
      stakeholderUuid: this.getFamilyHeadUuid(),
    } as Asset;
  }

  private getEmpty(): Asset {
    return {} as Asset;
  }

  private getFamilyHeadUuid(): string | undefined {
    return this.familyHead?.sugarUuid;
  }

  private getFirstChild(): string | undefined {
    return this.firstChild?.sugarUuid;
  }

  private today(): string {
    return moment().format('YYYY-MM-DD');
  }
}
