import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';

// TODO(pk) if we would use Angular 8+ we can use form.markAllAsTouched
export function markFormGroupTouched(formGroup: UntypedFormGroup | UntypedFormArray) {
  formGroup.markAsTouched({onlySelf: true});
  if (formGroup.controls) {
    Object.values(formGroup.controls).forEach((control: UntypedFormGroup | UntypedFormArray) => {
      markFormGroupTouched(control);
    });
  }
}
