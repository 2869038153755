/* eslint-disable max-classes-per-file */
import {CommonModule} from '@angular/common';
import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {LoginService} from '@lib/services';
import * as fromRouter from '@ngrx/router-store';
import {createFeatureSelector} from '@ngrx/store';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'secured/consultant',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/login/logout',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./layouts/public/public-layout.module').then(m => m.PublicLayoutModule),
  },
  {
    path: 'secured',
    canActivate: [LoginService],
    loadChildren: () =>
      import('./layouts/secured/secured-layout.module').then(m => m.SecuredLayoutModule),
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export const getRouterState =
  createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const {url} = routerState;
    const {queryParams} = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const {params} = state;

    return {url, queryParams, params};
  }
}
