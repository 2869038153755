import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SortComponent} from '@shared/ui/sort/sort.component';

@NgModule({
  declarations: [SortComponent],
  exports: [SortComponent],
  imports: [CommonModule],
})
export class SortModule {}
