<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-row>
    <kpt-objective-age-input
      grow
      name="start"
      [(start)]="this.asset.start"
    ></kpt-objective-age-input>
    <kpt-input grow label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>
  </kpt-row>

  <kpt-vehicle-requirements-common
    [asset]="asset"
    [disabled]="disabled"
  ></kpt-vehicle-requirements-common>
</ng-container>
