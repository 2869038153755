/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {FormlyModule} from '@ngx-formly/core';
import {DescriptionFormlyModule} from '@shared/ui/formly/description-formly/description-formly.module';
import {formlyTypesDefinition} from '@shared/ui/formly/formly-types';
import {formlyWrappersDefinition} from '@shared/ui/formly/formly-wrappers';
import {FulfillmentFormlyModule} from '@shared/ui/formly/fulfillment-formly/fulfillment-formly.module';
import {InsuranceOfCreditProductFormlyModule} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.module';
import {LabelFormlyModule} from '@shared/ui/formly/label-formly/label-formly.module';
import {PercentageInputFormlyModule} from '@shared/ui/formly/percentage-input-formly/percentage-input-formly.module';
import {RadioGroupFormlyModule} from '@shared/ui/formly/radio-group-formly/radio-group-formly.module';
import {RelatedAssetHeaderFormlyModule} from '@shared/ui/formly/related-asset-header-formly/related-asset-header-formly.module';
import {RelatedAssetSelectFormlyModule} from '@shared/ui/formly/related-asset-select-formly/related-asset-select-formly.module';
import {RisksLifeInsuranceFormlyModule} from '@shared/ui/formly/risks-life-insurance-formly/risks-life-insurance-formly.module';
import {SliderInputFormlyModule} from '@shared/ui/formly/slider-input-formly/slider-input-formly.module';
import {SliderRangeFormlyModule} from '@shared/ui/formly/slider-range-formly/slider-range-formly.module';
import {SwitchFormlyModule} from '@shared/ui/formly/switch-formly/switch-formly.module';
import {AssetValueFormlyWrapperModule} from '@shared/ui/formly/wrappers/asset-value-formly-wrapper/asset-value-formly-wrapper.module';
import {CaptionedInputFormlyWrapperModule} from '@shared/ui/formly/wrappers/captioned-input-formly-wrapper/captioned-input-formly-wrapper.module';
import {CoreValueFormlyWrapperModule} from '@shared/ui/formly/wrappers/core-value-formly-wrapper/core-value-formly-wrapper.module';
import {ImportantValueFormlyWrapperModule} from '@shared/ui/formly/wrappers/important-value-formly-wrapper/important-value-formly-wrapper.module';
import {InsuranceValueFormlyWrapperModule} from '@shared/ui/formly/wrappers/insurance-value-formly-wrapper/insurance-value-formly-wrapper.module';
import {YearInputFormlyModule} from '@shared/ui/formly/year-input-formly/year-input-formly.module';
import {InputFormlyModule} from 'src/shared/ui/formly/input-formly/input-formly.module';
import {SelectFormlyModule} from 'src/shared/ui/formly/select-formly/select-formly.module';
import {AssetLifeInsuranceRisksFormlyModule} from './asset-life-insurance-risks-formly/asset-life-insurance-risks-formly.module';
import {CheckboxFormlyModule} from './checkbox-formly/checkbox-formly.module';
import {DatepickerFormlyModule} from './datepicker-formly/datepicker-formly.module';
import {FamilyMembersFormlyModule} from './family-members-formly/family-members-formly.module';
import {PartnerSelectFormlyModule} from './partner-select-formly/partner-select-formly.module';
import {ProductSelectFormlyModule} from './product-select-formly/product-select-formly.module';
import {RepeatableFormlyModule} from './repeatable-formly/repeatable-formly.module';
import {SliderInputRangeFormlyModule} from './slider-input-range-formly/slider-input-range-formly.module';
import {NamedGroupFormlyModule} from './wrappers/named-group-formly/named-group-formly.module';
import {TabFormlyModule} from './wrappers/tab-formly/tab-formly.module';
import {TabsFormlyModule} from './wrappers/tabs-formly/tabs-formly.module';
/* eslint-enable max-len */

@NgModule({
  imports: [
    FormlyModule.forRoot({
      types: formlyTypesDefinition,
      wrappers: formlyWrappersDefinition,
    }),
    InputFormlyModule,
    DatepickerFormlyModule,
    CheckboxFormlyModule,
    SwitchFormlyModule,
    SelectFormlyModule,
    RadioGroupFormlyModule,
    SliderInputRangeFormlyModule,
    FamilyMembersFormlyModule,
    SliderRangeFormlyModule,
    SliderInputFormlyModule,
    TabsFormlyModule,
    TabFormlyModule,
    NamedGroupFormlyModule,
    RepeatableFormlyModule,
    PercentageInputFormlyModule,
    YearInputFormlyModule,
    DescriptionFormlyModule,
    FulfillmentFormlyModule,
    LabelFormlyModule,
    RelatedAssetSelectFormlyModule,
    RelatedAssetHeaderFormlyModule,
    RisksLifeInsuranceFormlyModule,
    AssetLifeInsuranceRisksFormlyModule,
    AssetValueFormlyWrapperModule,
    CoreValueFormlyWrapperModule,
    ImportantValueFormlyWrapperModule,
    InsuranceValueFormlyWrapperModule,
    CaptionedInputFormlyWrapperModule,
    InsuranceOfCreditProductFormlyModule,
  ],
  providers: [],
  exports: [
    FormlyModule,
    InputFormlyModule,
    DatepickerFormlyModule,
    CheckboxFormlyModule,
    SelectFormlyModule,
    RadioGroupFormlyModule,
    SliderInputRangeFormlyModule,
    FamilyMembersFormlyModule,
    SliderRangeFormlyModule,
    SliderInputFormlyModule,
    TabsFormlyModule,
    TabFormlyModule,
    RepeatableFormlyModule,
    PercentageInputFormlyModule,
    YearInputFormlyModule,
    LabelFormlyModule,
    RelatedAssetSelectFormlyModule,
    RelatedAssetHeaderFormlyModule,
    AssetLifeInsuranceRisksFormlyModule,
    AssetValueFormlyWrapperModule,
    CoreValueFormlyWrapperModule,
    ImportantValueFormlyWrapperModule,
    InsuranceValueFormlyWrapperModule,
    CaptionedInputFormlyWrapperModule,
    InsuranceOfCreditProductFormlyModule,
    ProductSelectFormlyModule,
    PartnerSelectFormlyModule,
  ],
})
export class FormlyKappkaModule {}
