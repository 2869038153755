import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

import {IStepperProgressItem} from './models/stepper-progress.model';

@Component({
  selector: 'kpt-stepper-progress',
  templateUrl: './stepper-progress.component.html',
  styleUrls: ['./stepper-progress.component.scss'],
})
export class StepperProgressComponent {
  @Input()
  config: IStepperProgressItem[] = [];

  constructor(private router: Router) {}

  useWhiteColor(): boolean {
    return this.router.url.split('?')[0].includes('/financial-analysis/objectives');
  }

  isRouteIsActive(routePath: string): boolean {
    // query params get encoded -> does not work/ should be ignored
    return this.router.url.split('?')[0] === routePath;
  }
}
