export interface ChildAgeRange {
  from: number;
  to: number;
}

export enum ChildAgeType {
  PRE_SCHOOL = 'preschool',
  MIDDLE_CHILDHOOD = 'middleChildhood',
  TEENAGER = 'teenager',
}

export const childrenTypes: Record<ChildAgeType, ChildAgeRange> = {
  [ChildAgeType.PRE_SCHOOL]: {from: 0, to: 7},
  [ChildAgeType.MIDDLE_CHILDHOOD]: {from: 7, to: 14},
  [ChildAgeType.TEENAGER]: {from: 14, to: Infinity},
};
