import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {isNilOrEmpty} from '@shared/lib';
import {isNil, round} from 'lodash';
import {filter} from 'rxjs/operators';

const minMaxValidator = (min: number, max: number): ValidatorFn => {
  return (formControl: UntypedFormControl): ValidationErrors => {
    const validators: ValidatorFn[] = [];
    if (!isNilOrEmpty(min)) {
      validators.push(Validators.min(min));
    }
    if (!isNilOrEmpty(max)) {
      validators.push(Validators.max(max));
    }
    if (isNilOrEmpty(validators)) {
      return null;
    }
    return Validators.compose(validators)(formControl);
  };
};

@UntilDestroy()
@Component({
  selector: 'kpt-percentage-input-formly',
  templateUrl: './percentage-input-formly.component.html',
  styleUrls: ['./percentage-input-formly.component.scss'],
})
export class PercentageInputFormlyComponent extends FieldType implements OnInit, OnDestroy {
  inputForm: UntypedFormControl;
  private focus = false;

  ngOnInit() {
    this.inputForm = new UntypedFormControl(
      this.getValueToShow(this.formControl.value),
      this.validators(),
    );

    this.formControl.valueChanges
      .pipe(
        filter(() => !this.focus),
        untilDestroyed(this),
      )
      .subscribe(val => {
        this.inputForm.patchValue(this.getValueToShow(val), {emitEvent: false});
      });

    if (this.formControl.errors) {
      this.inputForm.markAsTouched();
    }
    this.inputForm.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
      this.formControl.patchValue(
        !isNilOrEmpty(val)
          ? !isNil(this.to.precision)
            ? round(val / 100, this.to.precision + 2)
            : val / 100
          : null,
      );
    });
  }

  ngOnDestroy() {}

  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.change) {
      this.to.change(field, $event);
    }
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
    this.inputForm.patchValue(this.getValueToShow(this.formControl.value), {emitEvent: false});
  }

  private validators(): ValidatorFn {
    return (c: UntypedFormControl): ValidationErrors =>
      minMaxValidator(this.to.min * 100, this.to.max * 100)(c);
  }

  private getValueToShow(value: number): number {
    return !isNilOrEmpty(value)
      ? !isNil(this.to.precision)
        ? round(value * 100, this.to.precision)
        : value * 100
      : null;
  }
}
