import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {formatCZK} from '@lib/utils';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {IndependenceInputMode} from '@shared/analysis/forms/definitions.models';
import {defaultObjectiveMaxValue} from '@shared/analysis/forms/objectives.helpers';
import {FinancialIndependenceAsset} from '@shared/analysis/models/objectives';
import {FormModule, Slider2Component} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@Component({
  standalone: true,
  selector: 'kpt-financial-independence-form',
  templateUrl: './financial-independence-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    SwitchModule,
    Slider2Component,
    InvestmentRiskProfileComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class FinancialIndependenceFormComponent {
  @Input() asset: FinancialIndependenceAsset;

  inputModeOptions = {
    false: IndependenceInputMode.Value,
    true: IndependenceInputMode.Rent,
  };

  defaultObjectiveMaxValue = defaultObjectiveMaxValue;

  translateSlider(value: number) {
    return formatCZK(value);
  }
}
