import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GetAdvisorModule} from '@generated/store/integrations/getAdvisor/getAdvisor.module';
import {CommonBaseModule} from '@shared/lib';
import {CardBoardActionModule} from '@shared/lib/components/card-board-action/card-board-action.module';
import {AdvisorSectionComponent} from '@shared/ui/sidebar/advisor-section/advisor-section.component';
import {ClientSectionComponent} from '@shared/ui/sidebar/client-section/client-section.component';
import {SidebarComponent} from '@shared/ui/sidebar/sidebar.component';

@NgModule({
  declarations: [SidebarComponent, ClientSectionComponent, AdvisorSectionComponent],
  exports: [SidebarComponent, ClientSectionComponent, AdvisorSectionComponent],
  imports: [CommonModule, CommonBaseModule, GetAdvisorModule, CardBoardActionModule],
})
export class SidebarModule {}
