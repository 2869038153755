import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'kpt-dropdown-bootstrap',
  templateUrl: './dropdown-bootstrap.component.html',
  styleUrls: ['./dropdown-bootstrap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownBootstrapComponent implements OnInit {
  @Input()
  toggleTemplate: TemplateRef<any>;

  @Input()
  contentTemplate: TemplateRef<any>;

  @Input()
  contentTemplateContext: any;

  @Input()
  menuWidth = '13rem';

  @Input()
  isDisabled = false;

  constructor() {}

  ngOnInit() {}
}
