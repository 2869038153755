import {formatCZKbold} from '@lib/utils';
import {PersonRisk, Provision, RiskInput} from '@shared/analysis/models/life-insurance-risks';
import {czechCommaJoin} from '@shared/lib/utils';

const useSixMonthReserve =
  'K&nbsp;pokrytí tohoto rizika využiji rezervu ve&nbsp;výši 6&nbsp;měsíčních příjmů, ' +
  'proto je pojistná částka nastavena na&nbsp;nulu.';

// some helper functions

// ready for 2022
export function grossMonthlyIncome(netMonthlyIncome: number, taxAdvantagedChildrenAmount: number) {
  // counts the gross monthly income from the net monthly income (using tax advantages for children)
  // we use this formula: net income = gross income - health/social insurance - (tax - tax sale)
  // NI = GI - 0.11 * GI - (0.15 * GI - person sale - children sale)
  // person sale = 27 840 = 12 x 2320 Kč (was 24 840 = 12 x 2070 Kč in 2020)
  // 1st children sale = 15 204 = 12 x 1267 Kč (the same as in 2020)
  // 2nd children sale = 22 320 = 12 x 1860 Kč
  // 3rd and next children sale = 27 840 = 12 x 2320 Kč
  if (netMonthlyIncome === 0) {
    return 0;
  }
  switch (taxAdvantagedChildrenAmount) {
    case 0: {
      return Math.round((netMonthlyIncome - 2320) / 0.74);
    }
    case 1: {
      return Math.round((netMonthlyIncome - 2320 - 1267) / 0.74);
    }
    case 2: {
      return Math.round((netMonthlyIncome - 2320 - 1267 - 1860) / 0.74);
    }
    default: {
      return Math.round(
        (netMonthlyIncome - 2320 - 1267 - 1860 - (taxAdvantagedChildrenAmount - 2) * 2320) / 0.74,
      );
    }
  }
}

// ready for 2022
export function disabilityPension(age: number, grossMonthlyIncome1: number) {
  // income is gross monthly income
  if (grossMonthlyIncome1 === 0) {
    return 0;
  }
  const baseDisabilityPension = 4040; // was 3270 in 2019, 3490 in 2020, 3550 in 2021, 3900 in 2022
  const percentagePerYear = 0.015; // 1,5 % per year (for 3rd grade disability pension)
  function reducedMonthlyIncome(MonthlyIncome: number) {
    // income is gross monthly income
    const firstLimit = 17_743; // was 14_388 in 2019, 15_328 in 2020, 15_595 in 2021, 17_121 in 2022
    const secondLimit = 161_296; // was 130_796 in 2019, 139_340 in 2020, 141_764 in 2021, 155_644 in 2022
    const percentageInSecondLimit = 0.26; // was 0.26 in 2019 and 2020
    return MonthlyIncome <= firstLimit
      ? MonthlyIncome
      : MonthlyIncome <= secondLimit
      ? firstLimit + Math.round(percentageInSecondLimit * (MonthlyIncome - firstLimit))
      : firstLimit + Math.round(percentageInSecondLimit * (secondLimit - firstLimit));
  }
  return (
    Math.round((age - 15) * reducedMonthlyIncome(grossMonthlyIncome1) * percentagePerYear) +
    baseDisabilityPension
  );
}

// ready for 2021
export function orphanPension(totalDisabilityPension: number) {
  // income is gross monthlyincome
  return 3550 + Math.round(0.4 * totalDisabilityPension);
  // was 3270 in 2019 and 3490 in 2020, number 2070 was probably a mistake or a typo
}

function childExpenseTotal(age: number) {
  const childExpensesArray = [
    44_800, 38_400, 54_000, 54_000, 61_200, 61_200, 61_200, 99_600, 99_600, 99_600, 99_600, 99_600,
    99_600, 99_600, 99_600, 154_800, 154_800, 154_800, 154_800, 192_000, 192_000, 192_000, 192_000,
    192_000, 192_000, 192_000, 192_000,
  ];
  return childExpensesArray.slice(age).reduce((a, b) => a + b);
}

function threeLevelsReduction(
  firstLimit: number,
  secondLimit: number,
  thirdLimit: number,
  percentageInFirstLimit: number,
  percentageInSecondLimit: number,
  percentageInThirdLimit: number,
  base: number,
) {
  return base <= firstLimit
    ? Math.ceil(base * percentageInFirstLimit)
    : base <= secondLimit
    ? Math.ceil(firstLimit * percentageInFirstLimit + percentageInSecondLimit * (base - firstLimit))
    : base <= thirdLimit
    ? Math.ceil(
        firstLimit * percentageInFirstLimit +
          (secondLimit - firstLimit) * percentageInSecondLimit +
          percentageInThirdLimit * (base - secondLimit),
      )
    : Math.ceil(
        firstLimit * percentageInFirstLimit +
          (secondLimit - firstLimit) * percentageInSecondLimit +
          percentageInThirdLimit * (thirdLimit - secondLimit),
      );
}

// reday for 2021
export function dailySickLeavePaymentContractor(monthlyInsurancePayment: number) {
  const dailyBase = ((monthlyInsurancePayment / 0.021) * 12) / 365;
  const reducedDailyBase = threeLevelsReduction(1182, 1773, 3545, 0.9, 0.6, 0.3, dailyBase);
  return Math.ceil(reducedDailyBase * 0.6);
}

// ready for 2021
function dailySickLeavePayment(netMonthlyIncome: number, taxAdvantagedChildrenAmount: number) {
  if (netMonthlyIncome === 0) {
    return 0;
  }
  const daysInMonth = 30;
  const daysOfWageCompensation = 14;
  const firstNumberOfDays = 16;
  const percentageInFirstDays = 0.6;
  const monthlySickLeavePayment = Math.floor(
    threeLevelsReduction(
      1182,
      1773,
      3545,
      0.9,
      0.6,
      0.3,
      (grossMonthlyIncome(netMonthlyIncome, taxAdvantagedChildrenAmount) * 12) / 365,
    ) *
      (firstNumberOfDays * percentageInFirstDays),
  );
  // first, second, third limit in 2019: 1090, 1635, 3270,
  // first, second, third limit in 2020: 1162, 1742, 3484
  return monthlySickLeavePayment / (daysInMonth - daysOfWageCompensation);
}

// functions for counting the amounts in life insurance slides

export function seriousDisease(input: RiskInput) {
  const incomeValue =
    (input.monthlyIncomeEmployee + input.monthlyIncomeContractor - input.otherIncome) * 12;
  return input.provision === Provision.Income
    ? Math.max(incomeValue, 0)
    : input.netMonthlyExpense * 12;
}

export function permanentDisability(_: RiskInput) {
  return 1_000_000;
}

export function incapacity(input: RiskInput) {
  if (input.sixMonthReserveIncapacity) return 0;

  return incapacityForWork(input);
}

export function incapacityForWork(input: RiskInput) {
  const totalDailyIncome = (input.monthlyIncomeContractor + input.monthlyIncomeEmployee) / 30;
  const totalDailyExpense =
    (input.netMonthlyExpense - (input.mortgageRepaymentByRisk?.[PersonRisk.Incapacity] ?? 0)) / 30;
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  if (input.provision === Provision.Income) {
    const incapacityValue = Math.round(
      totalDailyIncome -
        dailySickLeavePayment(officialIncomeEmp, input.childrenTaxAdvantage) -
        dailySickLeavePaymentContractor(input.insuranceContractor) -
        input.otherIncome / 30,
    );
    return incapacityValue < 0 ? 0 : incapacityValue;
  } else {
    const incapacityValue = Math.round(
      totalDailyExpense -
        dailySickLeavePayment(officialIncomeEmp, input.childrenTaxAdvantage) -
        dailySickLeavePaymentContractor(input.insuranceContractor),
    );
    return incapacityValue < 0 ? 0 : incapacityValue;
  }
}

export function dailyCompensation(input: RiskInput) {
  if (input.sixMonthReserveDailyCompensation) return 0;

  return incapacityForWork(input);
}

export function hospitalization(input: RiskInput) {
  if (input.sixMonthReserveHospitalization) return 0;

  return incapacityForWork(input);
}

export function physicalDamage(input: RiskInput) {
  if (input.sixMonthReservePhysicalDamage) return 0;

  return incapacityForWork(input) * 500;
}

export function deathDebtRepayment(input: RiskInput) {
  const debt = input.debt - (input.mortgageRepaymentByRisk?.[PersonRisk.DeathDebtRepayment] ?? 0);

  if (input.provision === Provision.Income) {
    return debt;
  } else {
    if (input.persons.length === 1 || !input.persons[0].debtsTogether) {
      return debt;
    }
    return debt * myIncomeShare(input);
  }
}

export function invalidityDebtRepayment(input: RiskInput) {
  if (input.persons.length === 1 || !input.persons[0].debtsTogether) {
    return input.debt;
  }
  return input.debt * myIncomeShare(input);
}

export function deathThriceAnnualIncome(input: RiskInput) {
  return 36 * (input.monthlyIncomeContractor + input.monthlyIncomeEmployee);
}

export function invalidity(input: RiskInput) {
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  const assesmentBase = ((input.monthlyIncomeContractor - input.otherContractor) * 12) / 2;
  const disabilityPensionEmployee = disabilityPension(
    input.age,
    grossMonthlyIncome(officialIncomeEmp, input.childrenTaxAdvantage),
  );
  const disabilityPensionContractor = disabilityPension(input.age, assesmentBase / 12);

  const netMonthlyExpense =
    input.netMonthlyExpense - (input.mortgageRepaymentByRisk?.[PersonRisk.Invalidity] ?? 0);
  const expenseInvalidityValue =
    (netMonthlyExpense * 1.2 - disabilityPensionContractor - disabilityPensionEmployee) *
    12 *
    (65 - input.age);

  const incomeInvalidityValue =
    (input.monthlyIncomeEmployee +
      input.monthlyIncomeContractor -
      disabilityPensionContractor -
      disabilityPensionEmployee -
      input.otherIncome) *
    12 *
    (65 - input.age);

  return input.provision === Provision.Income
    ? incomeInvalidityValue < 0
      ? 0
      : incomeInvalidityValue
    : expenseInvalidityValue < 0
    ? 0
    : expenseInvalidityValue;
}

export function deathChildrenEnsurement(input: RiskInput) {
  let deathChildrenEnsurementSum = 0;
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  const assesmentBase = ((input.monthlyIncomeContractor - input.otherContractor) * 12) / 2;
  const realDisabilityPension = disabilityPension(
    input.age,
    grossMonthlyIncome(officialIncomeEmp, input.childrenTaxAdvantage),
  ); // the amount for an employee
  const realDisabilityPensionContractor = disabilityPension(input.age, assesmentBase / 12);
  const totalDisabilityPension = realDisabilityPension + realDisabilityPensionContractor;
  for (const age of input.childrenAges) {
    if (age < 27) {
      deathChildrenEnsurementSum +=
        childExpenseTotal(age) - orphanPension(totalDisabilityPension) * 12 * (27 - age);
    }
  }
  return deathChildrenEnsurementSum;
}

export function childDailyCompensation(input: RiskInput) {
  return input.provision === Provision.Standard ? 300 : 150;
}

export function childPhysicalDamage(input: RiskInput) {
  return input.provision === Provision.Standard ? 300 * 500 : 150 * 500;
}

export function childHospitalization(input: RiskInput) {
  return input.provision === Provision.Standard ? 500 : 300;
}

export function childSeriousDisease(input: RiskInput) {
  return input.provision === Provision.Standard ? 1_000_000 : 500_000;
}

export function childPermanentDisability(input: RiskInput) {
  return input.provision === Provision.Standard ? 1_500_000 : 1_000_000;
}

export function helpIncapacity(input: RiskInput) {
  if (input.sixMonthReserveIncapacity) return useSixMonthReserve;

  return helpIncapacityForWork(input);
}

export function helpDailyCompensation(input: RiskInput) {
  if (input.sixMonthReserveDailyCompensation) return useSixMonthReserve;

  return helpIncapacityForWork(input);
}

export function helpHospitalization(input: RiskInput) {
  if (input.sixMonthReserveHospitalization) return useSixMonthReserve;

  return helpIncapacityForWork(input);
}

export function helpIncapacityForWork(input: RiskInput) {
  const incapacityValue = incapacityForWork(input);
  const textFirstPart = helpIncapacityFirstPart(input);
  if (input.provision === Provision.Income) {
    return incapacityValue === 0
      ? textFirstPart + `Na&nbsp;výpadek příjmů se tedy nepotřebuji připojistit.`
      : textFirstPart +
          `K&nbsp;dorovnání výpadku příjmů se tedy potřebuji připojistit na ${formatCZKbold(
            incapacityValue,
          )} denně.`;
  } else {
    return incapacityValue === 0
      ? textFirstPart + `K&nbsp;pokrytí výdajů se tedy nepotřebuji připojistit.`
      : textFirstPart +
          `K&nbsp;pokrytí výdajů se tedy potřebuji připojistit na ${formatCZKbold(
            incapacityValue,
          )} denně.`;
  }
}

export function helpPhysicalDamage(input: RiskInput) {
  if (input.sixMonthReservePhysicalDamage) return useSixMonthReserve;

  const incapacityValue = incapacityForWork(input);
  const textFirstPart = helpIncapacityFirstPart(input);
  if (input.provision === Provision.Income) {
    return incapacityValue === 0
      ? textFirstPart + `Na&nbsp;výpadek příjmů se tedy nepotřebuji připojistit.`
      : textFirstPart +
          `K&nbsp;dorovnání výpadku příjmů se tedy potřebuji připojistit na ${formatCZKbold(
            incapacityValue,
          )} denně, ` +
          `u&nbsp;rizika tělesného poškození na 500násobek, tedy ${formatCZKbold(
            incapacityValue * 500,
          )}.`;
  } else {
    return incapacityValue === 0
      ? textFirstPart + `K&nbsp;pokrytí výdajů se tedy nepotřebuji připojistit.`
      : textFirstPart +
          `K&nbsp;pokrytí výdajů se tedy potřebuji připojistit na ${formatCZKbold(
            incapacityValue,
          )} denně, ` +
          `u&nbsp;rizika tělesného poškození na 500násobek, tedy ${formatCZKbold(
            incapacityValue * 500,
          )}.`;
  }
}

export function helpSeriousDisease(input: RiskInput) {
  return input.provision === Provision.Income
    ? `Pojistná částka je rovna ročnímu příjmu klienta, kterými může pokrýt zvýšené náklady související
    se&nbsp;závažným onemocněním (náklady na&nbsp;léčbu, přestavbu bytu, rehabilitační pomůcky atd…). ` +
        `${
          input.otherIncome > 0 ? 'Od&nbsp;pojistné částky jsou odečteny další zvolené příjmy.' : ''
        }`
    : `Pojistná částka pokrývá roční výdaje klienta, kterými může pokrýt zvýšené náklady související
    se&nbsp;závažným onemocněním (náklady na&nbsp;léčbu, přestavbu bytu, rehabilitační pomůcky atd…)`;
}

export function helpPermanentDisability(input: RiskInput) {
  return (
    `Je mi ${input.age} let, minimální doporučená částka připojištění je ` +
    `${formatCZKbold(permanentDisability(input))}.`
  );
}

function myIncomeShare(input: RiskInput) {
  if (input.persons.length === 1 || !input.persons[0].debtsTogether) {
    return 1;
  }
  const myIncome = (input.monthlyIncomeContractor || 0) + (input.monthlyIncomeEmployee || 0);
  const totalIncomes =
    (input.persons[0].income || 0) +
    (input.persons[0].incomeContractor || 0) +
    (input.persons[1].income || 0) +
    (input.persons[1].incomeContractor || 0);
  return totalIncomes ? myIncome / totalIncomes : 0;
}

export function helpInvalidityDebtRepayment(input: RiskInput) {
  return (
    `Výše mých úvěrů je ${formatCZKbold(input.debt)}.` +
    ` Poměrná část mých příjmů je <b>${Math.round(myIncomeShare(input) * 100)}&nbsp;%.</b> ` +
    ` K&nbsp;zajištění tohoto rizika se potřebuji připojistit na ${formatCZKbold(
      invalidityDebtRepayment(input),
    )}.`
  );
}

export function helpDeathDebtRepayment(input: RiskInput) {
  const alreadyInsuredValue = input.mortgageRepaymentByRisk?.[PersonRisk.DeathDebtRepayment] ?? 0;
  const alreadyInsured =
    alreadyInsuredValue > 0 ? `, již je pojištěno ${formatCZKbold(alreadyInsuredValue)}` : ``;
  const textBeginning = `Výše mých úvěrů je ${formatCZKbold(input.debt)}${alreadyInsured}.`;
  return input.provision === Provision.Income
    ? textBeginning
    : textBeginning +
        ` Poměrná část mých příjmů je <b>${Math.round(myIncomeShare(input) * 100)}&nbsp;%</b>. ` +
        ` K&nbsp;zajištění tohoto rizika se potřebuji připojistit na ${formatCZKbold(
          deathDebtRepayment(input),
        )}.`;
}

export function helpDeathThriceAnnualIncome(input: RiskInput) {
  const netYearIncome = (input.monthlyIncomeEmployee + input.monthlyIncomeContractor) * 12;
  return (
    `Můj čistý roční příjem je ${formatCZKbold(netYearIncome)}, jeho 3násobek je ` +
    `${formatCZKbold(3 * netYearIncome)}.`
  );
}

export function helpInvalidity(input: RiskInput) {
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  const assesmentBase = ((input.monthlyIncomeContractor - input.otherContractor) * 12) / 2;
  const realDisabilityPension = disabilityPension(
    input.age,
    grossMonthlyIncome(officialIncomeEmp, input.childrenTaxAdvantage),
  );
  const realDisabilityPensionContractor = disabilityPension(input.age, assesmentBase / 12);
  const totalDisabilityPension = realDisabilityPension + realDisabilityPensionContractor;
  const monthlyIncomeEnsurement =
    input.monthlyIncomeEmployee +
    input.monthlyIncomeContractor -
    totalDisabilityPension -
    input.otherIncome;
  const mortgageRepayment = input.mortgageRepaymentByRisk?.[PersonRisk.Invalidity] ?? 0;
  const netMonthlyExpense = Math.round((input.netMonthlyExpense - mortgageRepayment) * 1.2);
  const monthlyExpenseEnsurement = netMonthlyExpense - totalDisabilityPension;

  if (input.provision === Provision.Income) {
    const netIncomeStr = `Můj čistý měsíční příjem je ${formatCZKbold(
      input.monthlyIncomeContractor + input.monthlyIncomeEmployee,
    )}. `;
    const disabilityPensionStr =
      `Stát mi bude v případě invalidity 3.&nbsp;stupně vyplácet ` +
      `${formatCZKbold(totalDisabilityPension)} měsíčně` +
      `${
        input.otherIncome > 0
          ? ' a využiji svůj další příjem ve výši ' + formatCZKbold(input.otherIncome)
          : ''
      }. `;
    return invalidity(input) <= 0
      ? netIncomeStr +
          disabilityPensionStr +
          `K&nbsp;dorovnání příjmů se tedy nepotřebuji připojistit.`
      : netIncomeStr +
          disabilityPensionStr +
          `K&nbsp;dorovnání příjmů se tedy potřebuji ` +
          `připojistit na ${formatCZKbold(monthlyIncomeEnsurement)} měsíčně. Za rok částka činí ` +
          `${formatCZKbold(12 * monthlyIncomeEnsurement)}, do ` +
          `doby, než odejdu do důchodu, ${formatCZKbold(
            (65 - input.age) * 12 * monthlyIncomeEnsurement,
          )}.`;
  } else {
    const mortgage1 =
      mortgageRepayment > 0 ? `, splátka hypotéky ${formatCZKbold(mortgageRepayment)}` : ``;
    const mortgage2 = mortgageRepayment > 0 ? ` bez splátky hypotéky` : ``;
    const textFirstPart =
      `Moje měsíční výdaje jsou ${formatCZKbold(input.netMonthlyExpense)}${mortgage1}, ` +
      `měsíční výdaje${mortgage2} navýšené o&nbsp;20&nbsp;% jsou ${formatCZKbold(
        netMonthlyExpense,
      )}. Stát mi bude v&nbsp;` +
      `případě invalidity 3.&nbsp;stupně vyplácet ${formatCZKbold(
        totalDisabilityPension,
      )} měsíčně. `;
    return invalidity(input) <= 0
      ? textFirstPart + `K&nbsp;pokrytí výdajů se tedy nepotřebuji připojistit.`
      : textFirstPart +
          `K&nbsp;pokrytí výdajů se tedy potřebuji připojistit na ` +
          `${formatCZKbold(monthlyExpenseEnsurement)} měsíčně. Za rok částka činí ` +
          `${formatCZKbold(12 * monthlyExpenseEnsurement)}, do doby, než odejdu do důchodu, ` +
          `${formatCZKbold((65 - input.age) * 12 * monthlyExpenseEnsurement)}.`;
  }
}

export function helpDeathChildrenEnsurement(input: RiskInput) {
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  const assesmentBase = ((input.monthlyIncomeContractor - input.otherContractor) * 12) / 2;
  const realDisabilityPension = disabilityPension(
    input.age,
    grossMonthlyIncome(officialIncomeEmp, input.childrenTaxAdvantage),
  ); // the amount for an employee
  const realDisabilityPensionContractor = disabilityPension(input.age, assesmentBase / 12);
  const totalDisabilityPension = realDisabilityPension + realDisabilityPensionContractor;
  if (input.childrenAges.length === 1) {
    return (
      `Mému dítěti je ${input.childrenAges[0]} let. Náklady na něj do jeho 26 let jsou ` +
      `${formatCZKbold(
        childExpenseTotal(input.childrenAges[0]),
      )}. V&nbsp;případě mé smrti by pobíralo sirotčí důchod ` +
      `${formatCZKbold(orphanPension(totalDisabilityPension))}` +
      ` měsíčně, do 26 let věku by tedy od státu získalo ${formatCZKbold(
        12 * (27 - input.childrenAges[0]) * orphanPension(totalDisabilityPension),
      )}.` +
      ` K&nbsp;pokrytí výdajů se tedy potřebuji připojistit na ${formatCZKbold(
        deathChildrenEnsurement(input),
      )}.`
    );
  } else {
    const ages = czechCommaJoin(input.childrenAges);
    const expensesArray = input.childrenAges.map(childExpenseTotal).map(formatCZKbold);
    const expenses = czechCommaJoin(expensesArray);
    return (
      `Mým dětem je ${ages} let. Náklady na ně do jejich 26 let jsou ` +
      `${expenses}. V&nbsp;případě mé smrti by každé pobíralo sirotčí důchod ` +
      `${formatCZKbold(orphanPension(totalDisabilityPension))}.` +
      ` K&nbsp;pokrytí výdajů se tedy potřebuji připojistit na ${formatCZKbold(
        deathChildrenEnsurement(input),
      )}.`
    );
  }
}

export function helpChildDailyCompensation(input: RiskInput) {
  return `Minimální doporučená částka připojištění je ${formatCZKbold(
    childDailyCompensation(input),
  )} denně.`;
}

export function helpChildPhysicalDamage(input: RiskInput) {
  return `Minimální doporučená částka připojištění je ${formatCZKbold(
    500 * childDailyCompensation(input),
  )} denně.`;
}

export function helpChildHospitalization(input: RiskInput) {
  return `Minimální doporučená částka připojištění je ${formatCZKbold(
    childHospitalization(input),
  )} denně.`;
}

export function helpChildSeriousDisease(input: RiskInput) {
  return `Minimální doporučená částka připojištění je ${formatCZKbold(
    childSeriousDisease(input),
  )}.`;
}

export function helpChildPermanentDisability(input: RiskInput) {
  return `Minimální doporučená částka připojištění je ${formatCZKbold(
    childPermanentDisability(input),
  )}.`;
}

export function incapacityDescription(input: RiskInput) {
  const incapacityComputationStr = incapacityComputationForIncome(input);
  const insuranceAmountStr = insuranceAmountForIncome(input);
  const secondPart = helpIncapacity(input);
  return `${incapacityComputationStr} Dávka na den je ${insuranceAmountStr}.<br><br>` + secondPart;
}

export function incapacityExDescription(input: RiskInput) {
  const secondPart = helpIncapacity(input);
  const mortgageRepayment = input.mortgageRepaymentByRisk?.[PersonRisk.Incapacity] ?? 0;
  const mortgage = mortgageRepayment > 0 ? ` poníženými o&nbsp;splátku hypotéky` : ``;
  return (
    `Při výpočtu je zohledněna „platba od státu“ (nemocenská). Dávka na den je rozdíl ` +
    `mezi výdaji${mortgage} a nemocenskou dělená 30 (počet dní v&nbsp;měsíci). ` +
    `Pokud je částka nulová, znamená to, že nemocenská pokryje výdaje domácnosti.<br><br>` +
    secondPart
  );
}

export function dailyCompensationDescription(input: RiskInput) {
  const incapacityComputationStr = incapacityComputationForIncome(input);
  const insuranceAmountStr = insuranceAmountForIncome(input);
  const secondPart = helpDailyCompensation(input);
  return `${incapacityComputationStr} Dávka na den je ${insuranceAmountStr}.<br><br>` + secondPart;
}

export function dailyCompensationExDescription(input: RiskInput) {
  const secondPart = helpDailyCompensation(input);
  const mortgageRepayment = input.mortgageRepaymentByRisk?.[PersonRisk.Incapacity] ?? 0;
  const mortgage = mortgageRepayment > 0 ? ` poníženými o&nbsp;splátku hypotéky` : ``;
  return (
    `Při výpočtu je zohledněna „platba od státu“ (nemocenská). Dávka na den je rozdíl ` +
    `mezi výdaji${mortgage} a nemocenskou dělená 30 (počet dní v&nbsp;měsíci). ` +
    `Pokud je částka nulová, znamená to, že nemocenská pokryje výdaje domácnosti.<br><br>` +
    secondPart
  );
}

export function physicalDamageDescription(input: RiskInput) {
  const incapacityComputationStr = incapacityComputationForIncome(input);
  const insuranceAmountStr = insuranceAmountForIncome(input);
  const secondPart = helpPhysicalDamage(input);
  return (
    `${incapacityComputationStr} Pojistná částka je ${insuranceAmountStr} a vynásobený 500.<br><br>` +
    secondPart
  );
}

export function physicalDamageExDescription(input: RiskInput) {
  const secondPart = helpPhysicalDamage(input);
  return (
    'Při výpočtu je zohledněna „platba od státu“ (nemocenská). Pojistná částka ' +
    'je rozdíl mezi výdaji a nemocenskou dělená 30 (počet dní v měsíci) a vynásobená 500.<br><br>' +
    secondPart
  );
}

export function hospitalizationDescription(input: RiskInput) {
  const secondPart = helpHospitalization(input);
  return (
    'Minimální dávka na den se rovná dávce pracovní neschopnosti, která pokryje ' +
    'nehrazených prvních 15 dnů pracovní neschopnosti. Je možnost nastavit na vyšší částku: výdaje na nadstandardní ' +
    'pokoj, náklady na dojíždění rodiny do nemocnice, výdaje domácnosti atd.<br><br>' +
    secondPart
  );
}

export function seriousDescription(input: RiskInput) {
  const value = seriousDisease(input);
  return (
    `Pojistná částka je rovna ročnímu příjmu klienta, kterými může pokrýt zvýšené náklady související ` +
    `se&nbsp;závažným onemocněním (náklady na&nbsp;léčbu, přestavbu bytu, rehabilitační pomůcky atd…). ` +
    `${
      input.otherIncome > 0 ? 'Od&nbsp;pojistné částky jsou odečteny další zvolené příjmy.' : ''
    }` +
    `<br><br>Pojistná částka je konstantní, tzn., že je po celou dobu trvání pojistného rizika stejná.` +
    (input.otherIncome > 0 && value === 0
      ? `<br><br>Další příjem mi dorovnává výpadek hlavního přijmu, ` +
        `k&nbsp;dorovnání příjmů se tedy nepotřebuji připojistit.`
      : '')
  );
}

export function seriousExDescription() {
  return (
    'Pojistná částka pokrývá roční výdaje klienta, kterými může pokrýt zvýšené náklady související ' +
    'se&nbsp;závažným onemocněním (náklady na&nbsp;léčbu, přestavbu bytu, rehabilitační pomůcky atd…)' +
    '<br><br>Pojistná částka je konstantní, tzn., že je po celou dobu pojištění stejná.'
  );
}

const permanentProgressionDescription = `
  Trvalé následky úrazu nemají specifický výpočet.<br>
  Doporučená minimální pojistná částka je 1&thinsp;000&thinsp;000 Kč.<br><br>

  <h4 class="permanent-disability__title font-weight-bold">
    Příklady plnění při nastavení 4násobné progrese
  </h4>

  <table class="permanent-disability__table">
    <thead>
      <th>Příklady trvalých<br>následků úrazu (TNÚ)</th>
      <th>Pojistná částka<br>1&thinsp;000&thinsp;000&thinsp;Kč</th>
    </thead>
    <tbody>
      <tr>
        <td>Ztráta zubu<br>(1&thinsp;% TNÚ)</td>
        <td>Plnění 10&thinsp;000&thinsp;Kč</td>
      </tr>
      <tr>
        <td>Ztráta palce nohy<br>(10&thinsp;% TNÚ)</td>
        <td>Plnění 100&thinsp;000&thinsp;Kč</td>
      </tr>
      <tr>
        <td>Ztráta horní končetiny<br>(60&thinsp;% TNÚ)</td>
        <td>Plnění 1&thinsp;800 000&thinsp;Kč</td>
      </tr>
      <tr>
        <td>Úplné ochrnutí<br>(100&thinsp;% TNÚ)</td>
        <td>Plnění 4&thinsp;000&thinsp;000&thinsp;Kč</td>
      </tr>
    </tbody>
  </table>

  <div class="permanent-disability__graphs">
    <div class="permanent-disability__graph">
      <strong class="permanent-disability__graph-info">
        1&thinsp;000&thinsp;000&thinsp;Kč<br>
      </strong>
      <span class="permanent-disability__arrow"></span>
      <span class="permanent-disability__bar">
        <em class="permanent-disability__bar-desc">
          0–25 %<br>TNÚ
        </em>
      </span>
    </div>
    <div class="permanent-disability__graph">
      <strong class="permanent-disability__graph-info">
        2&thinsp;000&thinsp;000&thinsp;Kč<br>
      </strong>
      <span class="permanent-disability__arrow"></span>
      <span class="permanent-disability__bar">
        <strong class="permanent-disability__bar-multiply">&times; 2</strong>
        <em class="permanent-disability__bar-desc">
          25–50 %<br>TNÚ
        </em>
      </span>
    </div>
    <div class="permanent-disability__graph">
      <strong class="permanent-disability__graph-info">
        3&thinsp;000&thinsp;000&thinsp;Kč<br>
      </strong>
      <span class="permanent-disability__arrow"></span>
      <span class="permanent-disability__bar">
        <strong class="permanent-disability__bar-multiply">&times; 3</strong>
        <em class="permanent-disability__bar-desc">
          50–75 %<br>TNÚ
        </em>
      </span>
    </div>
    <div class="permanent-disability__graph">
      <strong class="permanent-disability__graph-info">
        4&thinsp;000&thinsp;000&thinsp;Kč<br>
      </strong>
      <span class="permanent-disability__bar">
        <strong class="permanent-disability__bar-multiply">&times; 4</strong>
        <em class="permanent-disability__bar-desc">
          75–100 %<br>TNÚ
        </em>
      </span>
    </div>
  </div>

  <div class="permanent-disability__description">
    <h5 class="permanent-disability__title">
      Příklad 4násobné progrese TNÚ
    </h5>
    <p>
      Do 25 % TNÚ je klientovi plněno ze základní poj. částky.
      Ve chvíli, kdy je úraz závažnější a vyhodnocen nad 25 % TNÚ,
      začíná fungovat tzv.&nbsp;progrese, kdy dochází k násobení základní poj.&nbsp;částky.
    </p>
  </div>
`;

export function permanentDescription() {
  return permanentProgressionDescription;
}

export function permanentExDescription() {
  return permanentProgressionDescription;
}

export function invalidityDescription(input: RiskInput) {
  const secondPart = helpInvalidity(input);
  return (
    `Při výpočtu je zohledněn „důchod od&nbsp;státu“ (invalidní důchod)` +
    `${input.otherIncome > 0 ? ', čistý příjem a další příjem' : ' a čistý příjem'}. ` +
    `Od&nbsp;čistého měsíčního příjmu je odečten invalidní důchod` +
    `${input.otherIncome > 0 ? ' a další příjem' : ''}, rozdíl je vynásoben 12&nbsp;měsíci ` +
    `v&nbsp;roce a dále vynásoben počtem let, které zbývají do&nbsp;starobního důchodu. ` +
    `Tím jsou dorovnány příjmy do&nbsp;65&nbsp;let.<br><br>Pojistná částka může být:<ol class="alpha">` +
    `<li>lineárně klesající, tzn., že klesá měsíčně vždy o&nbsp;stejnou hodnotu</li>` +
    `<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u&nbsp;hypotéky).</li></ol>` +
    secondPart
  );
}

export function invalidityExDescription(input: RiskInput) {
  const mortgageRepayment =
    input.mortgageRepaymentByRisk?.[PersonRisk.Invalidity] > 0
      ? ` ponížené o splátky hypotéky`
      : ``;
  const secondPart = helpInvalidity(input);
  return (
    `Při výpočtu je zohledněn „důchod od&nbsp;státu“ (invalidní důchod) a výdaje domácnosti${mortgageRepayment}, ` +
    `které navyšujeme o&nbsp;20&nbsp;% z&nbsp;důvodu zvýšených nákladů spojených s&nbsp;léčbou. ` +
    `Od&nbsp;výdajů je odečten invalidní důchod, rozdíl je vynásoben 12&nbsp;měsíci v&nbsp;roce a dále vynásoben počtem let, ` +
    `které zbývají do&nbsp;starobního důchodu. ` +
    `Tím jsou dorovnány výdaje do&nbsp;65&nbsp;let.<br><br>Pojistná částka může být:<ol class="alpha">` +
    `<li>lineárně klesající, tzn., že klesá měsíčně vždy o&nbsp;stejnou hodnotu</li>` +
    `<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u&nbsp;hypotéky).</li></ol>` +
    secondPart
  );
}

export function invalidityDebtDescription(input: RiskInput) {
  const secondPart = helpInvalidityDebtRepayment(input);
  const firstPart =
    input.persons.length === 1
      ? 'Částka odpovídá výši úvěru.<br><br>'
      : 'Částka odpovídá výši úvěru a v případě společných úvěrů je poměrově rozdělena ' +
        'podle výše příjmů připadajících na jednotlivé dospělé členy rodiny.<br><br>';
  return (
    firstPart +
    'Pojistná částka může být:<ol class="alpha">' +
    '<li>lineárně klesající, tzn., že klesá měsíčně vždy o stejnou hodnotu</li>' +
    '<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u hypotéky).</li></ol>' +
    secondPart
  );
}

export function deathDebtDescription(input: RiskInput) {
  const secondPart = helpDeathDebtRepayment(input);
  const insuredDebt =
    input.mortgageRepaymentByRisk?.[PersonRisk.DeathDebtRepayment] > 0
      ? ` ponížené o již pojištěný úvěr`
      : '';
  const firstPart =
    input.persons.length === 1
      ? `Částka odpovídá výši úvěrů${insuredDebt}.<br><br>`
      : `Částka odpovídá výši úvěru${insuredDebt} a v případě společných úvěrů je poměrově rozdělena podle výše příjmů ` +
        `připadajících na jednotlivé dospělé členy rodiny.<br><br>`;
  return (
    firstPart +
    'Pojistná částka může být:<ol class="alpha">' +
    '<li>lineárně klesající, tzn., že klesá měsíčně vždy o stejnou hodnotu</li>' +
    '<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u hypotéky).</li></ol>' +
    secondPart
  );
}

export function deathDebtIncomeDescription(input: RiskInput) {
  const secondPart = helpDeathDebtRepayment(input);
  const insuredDebt =
    input.mortgageRepaymentByRisk?.[PersonRisk.DeathDebtRepayment] > 0
      ? ` ponížené o již pojištěný úvěr`
      : '';
  return (
    `Částka odpovídá výši úvěrů${insuredDebt}.<br><br>` +
    'Pojistná částka může být:<ol class="alpha">' +
    '<li>lineárně klesající, tzn., že klesá měsíčně vždy o stejnou hodnotu</li>' +
    '<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u hypotéky).</li></ol>' +
    secondPart
  );
}

export function deathThriceDescription(input: RiskInput) {
  const secondPart = helpDeathThriceAnnualIncome(input);
  return (
    '3&times; roční příjem z&nbsp;čisté mzdy.<br><br>' +
    'Pojistná částka je konstantní, tzn., že je po celou dobu trvání pojistného rizika stejná.<br><br>' +
    secondPart
  );
}

export function deathChildrenDescription(input: RiskInput) {
  const secondPart = helpDeathChildrenEnsurement(input);
  return (
    'Rozdíl mezi orientačními náklady na dítě a výší sirotčího důchodu, na který by ' +
    'mělo dítě nárok v&nbsp;případě smrti rodiče, dorovnaný do 26 let věku dítěte.<br>' +
    'Pojistná částka může být:<ol class="alpha">' +
    '<li>lineárně klesající, tzn., že klesá měsíčně vždy o stejnou hodnotu</li>' +
    '<li>anuitně klesající, tzn., že klesá měsíčně dle zvolené úrokové míry (podobně jako u hypotéky).</li></ol>' +
    secondPart
  );
}

export function childMinValueDescription() {
  return 'Doporučená minimální částka.';
}
export function childStandardValueDescription() {
  return 'Doporučená standardní částka.';
}
export function childMinConstantValueDescription() {
  return (
    'Doporučená minimální částka.<br><br>' +
    'Pojistná částka je konstantní, tzn., že je po celou dobu pojištění stejná.'
  );
}

export function childStandardConstantValueDescription() {
  return (
    'Doporučená standardní částka.<br><br>' +
    'Pojistná částka je konstantní, tzn., že je po celou dobu pojištění stejná.'
  );
}

function incapacityComputationForIncome(input: RiskInput): string {
  return `Při výpočtu je zohledněna „platba od státu“ (nemocenská)${
    input.otherIncome > 0 ? ' a další příjem, který se využije při výpadku hlavního příjmu' : ''
  }.`;
}

function insuranceAmountForIncome(input: RiskInput): string {
  return `rozdíl mezi čistým příjmem${
    input.otherIncome > 0 ? ', nemocenskou a dalším příjmem' : ' a nemocenskou'
  } dělený 30 (počet dní v&nbsp;měsíci)`;
}

function helpIncapacityFirstPart(input: RiskInput) {
  const officialIncomeEmp = input.monthlyIncomeEmployee - input.otherEmployee;
  const monthlyIncome = input.monthlyIncomeContractor + input.monthlyIncomeEmployee;
  const dailyIncome = Math.round(monthlyIncome / 30);
  const dailySickLeavePaymentAsEmployee = dailySickLeavePayment(
    officialIncomeEmp,
    input.childrenTaxAdvantage,
  );
  const dailySickLeavePaymentAsContractor = dailySickLeavePaymentContractor(
    input.insuranceContractor,
  );
  const dailySickLeavePaymentTotal =
    dailySickLeavePaymentAsContractor + dailySickLeavePaymentAsEmployee;
  if (input.provision === Provision.Income) {
    return (
      `Můj čistý měsíční příjem je ${formatCZKbold(monthlyIncome)} – od státu měsíčně dostanu ` +
      `${formatCZKbold(dailySickLeavePaymentTotal * 30)}, moje denní příjmy tedy jsou ` +
      `${formatCZKbold(dailyIncome)}. ` +
      `${
        input.otherIncome > 0
          ? 'Můj další příjem je ' + formatCZKbold(input.otherIncome) + '. '
          : ''
      }` +
      `<br>Stát mi bude v&nbsp;případě nemoci vyplácet ${formatCZKbold(
        dailySickLeavePaymentTotal,
      )} denně` +
      `${
        input.otherIncome > 0
          ? ' a využiji další příjem ' + formatCZKbold(input.otherIncome / 30) + ' denně'
          : ''
      }. `
    );
  } else {
    const mortgageRepayment = input.mortgageRepaymentByRisk?.[PersonRisk.Incapacity] ?? 0;
    const mortgage =
      mortgageRepayment > 0
        ? `Měsíční splátka hypotéky je ${formatCZKbold(mortgageRepayment)}. `
        : '';
    const dailyExpense = (input.netMonthlyExpense - mortgageRepayment) / 30;
    return (
      `Moje měsíční výdaje jsou ${formatCZKbold(input.netMonthlyExpense)} &ndash; od státu ` +
      `měsíčně dostanu ${formatCZKbold(
        30 * dailySickLeavePaymentTotal,
      )}. ${mortgage}Moje denní výdaje jsou tedy ` +
      `${formatCZKbold(dailyExpense)}. Stát mi bude v&nbsp;případě nemoci vyplácet ` +
      `${formatCZKbold(dailySickLeavePaymentTotal)} denně. `
    );
  }
}
