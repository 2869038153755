import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {Asset, AssetType, ValueAsset} from '@shared/analysis/models/asset';
import {propertyOwnershipTypeNames} from '@shared/analysis/models/assets.enums';
import {
  CommonImmovablePropertyAsset,
  ExtendedImmovablePropertyAsset,
} from '@shared/analysis/models/immovable-properties';
import {selectProperties} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

export const selectImmovableCategory = createSelector(selectProperties, properties =>
  createCategory(properties.immovableProperties),
);

function createCategory(immovable: AssetPageStateValue): Category {
  return {
    label: immovable.title,
    value: formatCZKNotNull(immovable.sum),
    categoryTypes: [createCategoryType(immovable.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: [...createImmovablePropertiesItems(asset as CommonImmovablePropertyAsset)].filter(
      Boolean,
    ),
  };
}

function createImmovablePropertiesItems(asset: CommonImmovablePropertyAsset): Item[] {
  const extraItems: Item[] = [];
  if (
    [
      AssetType.Flat,
      AssetType.House,
      AssetType.HolidayProperty,
      AssetType.OtherImmovableProperty,
    ].includes(asset.type)
  ) {
    const extendedAsset = asset as ExtendedImmovablePropertyAsset;
    extraItems.push(
      createItem(
        FieldLabel.IMMOVABLE_PROPERTY_AREA,
        extendedAsset.immovablePropertyArea
          ? `${extendedAsset.immovablePropertyArea} m&sup2;`
          : null,
      ),
      createItem(
        FieldLabel.HOUSEHOLD_AREA,
        extendedAsset.householdArea ? `${extendedAsset.householdArea} m&sup2;` : null,
      ),
    );
  }
  return [
    createItem(FieldLabel.TANGIBLE_START, formatDate(asset.start)),
    createItem(FieldLabel.PURPOSE, asset.purpose),
    createItem(FieldLabel.OWNERSHIP_TYPE, propertyOwnershipTypeNames[asset.ownershipType]),
    createItem(FieldLabel.TOTAL_AREA, asset.totalArea ? `${asset.totalArea} m&sup2;` : null),
    ...extraItems,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}
