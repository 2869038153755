import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {filterAssets} from '@shared/analysis/asset.utils';
import {PageTemplateAsset} from '@shared/analysis/assets-page.models';
import {
  Asset,
  AssetType,
  FamilyMemberAsset,
  NameAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {InputModeAsset} from '@shared/analysis/models/common-expenses';
import {selectCurrentAssets} from '@shared/analysis/store';
import {cloneDeep, pull} from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-other-incomes-accordion',
  templateUrl: './other-incomes-accordion.component.html',
  styleUrls: ['./other-incomes-accordion.component.scss'],
})
export class OtherIncomesAccordionComponent {
  @Input()
  person: LifeInsurancePerson;

  otherIncomeAssets$: Observable<PageTemplateAsset[]> = this.store.pipe(
    select(selectCurrentAssets),
    map(assets =>
      filterAssets(assets, [
        AssetType.PensionIncome,
        AssetType.OtherPensionIncome,
        AssetType.RentalIncome,
        AssetType.Dividends,
        AssetType.OtherPassiveIncome,
      ]),
    ),
    map(assets =>
      assets.filter(asset => (asset as FamilyMemberAsset).familyMemberUuid === this.person.id),
    ),
    map((assets): PageTemplateAsset[] => {
      return assets.map(asset => ({
        name: this.assetHelper.getName(asset),
        valueFormControl: new UntypedFormControl((asset as ValueAsset).value),
        assetData: asset as Asset & NameAsset & InputModeAsset & ValueAsset,
        assetUuid: asset.assetUuid,
      }));
    }),
  );

  constructor(private store: Store<State>, private assetHelper: AssetHelperService) {}

  isSelected(assetUuid: string): boolean {
    return (this.person.otherIncomeAssetUuids ?? []).includes(assetUuid);
  }

  selectAsset(assetUuid: string, checked: boolean) {
    const assetUuids = cloneDeep(this.person.otherIncomeAssetUuids ?? []);
    if (checked && !assetUuids.includes(assetUuid)) {
      assetUuids.push(assetUuid);
    } else {
      pull(assetUuids, assetUuid);
    }
    this.store.dispatch(
      LifeInsuranceActions.updateOtherIncomeUuids({personId: this.person.id, assetUuids}),
    );
  }

  toggleAsset(assetUuid: string) {
    this.selectAsset(assetUuid, !(this.person.otherIncomeAssetUuids ?? []).includes(assetUuid));
  }

  toggleUseOtherIncome() {
    this.store.dispatch(
      LifeInsuranceActions.useOtherIncome({
        personId: this.person.id,
        use: !this.person.useOtherIncome,
      }),
    );
  }
}
