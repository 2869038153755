import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {czechCommaJoin} from '@shared/lib';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {ProductsService} from '@shared/services/products.service';
import {IOption} from '@shared/ui';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {groupBy, mapValues, toPairs, uniq, uniqBy} from 'lodash';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, map, startWith, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {
  LifeInsuranceForm,
  LifeInsuranceFormGroup,
  LifeInsurancePerson,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectAdultPersons,
  selectForms,
  selectOrderedPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';
import {ProductSet} from 'src/store/models/products.models';

export interface ProposalsProductModalData {
  productId: number;
  formId: number;
  investmentEligiblePersons: LifeInsurancePerson[];
}

@UntilDestroy()
@Component({
  selector: 'kpt-proposals-alternatives',
  templateUrl: './proposals-product.component.html',
  styleUrls: ['./proposals-product.component.scss'],
})
export class ProposalsProductComponent implements OnInit, OnDestroy {
  data: ProposalsProductModalData;
  form = new UntypedFormGroup({
    insuranceType: new UntypedFormControl(null, [Validators.required]),
    partnerId: new UntypedFormControl(null, [Validators.required]),
    productId: new UntypedFormControl(null, [Validators.required]),
    stakeholderId: new UntypedFormControl(null, [Validators.required]),
    contractId: new UntypedFormControl(null),
  });
  insuranceTypeOptions: IOption[] = [];
  partnerOptions$: Observable<IOption[]>;
  productOptions$: Observable<IOption[]>;
  stakeholderOptions$: Observable<IOption[]>;
  personsWithSameProduct$: Observable<string>;
  contractOptions: IOption[];
  partnerOptions: IOption[];
  productOptions: IOption[];
  personsWithSameProduct: string;

  constructor(
    private modalService: ModalService,
    private productsService: ProductsService,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.preparePersonsWithSameProduct();
    this.prepareContractOptions();
    this.prepareInsuranceTypeOptions();
    this.prepareProducts();
    this.makeInitialSelection();
    // prepare stakeholder options after initial selection because stakeholder options depends on
    // selected insurance type
    this.prepareStakeholders();
  }

  ngOnDestroy() {}

  submit() {
    if (!this.form.valid) {
      markFormGroupTouched(this.form);
      return;
    }

    const insuranceType = this.form.get('insuranceType').value;
    const productId = this.form.get('productId').value;
    const stakeholderId = this.form.get('stakeholderId').value;
    const contractId = this.form.get('contractId').value;
    const formId = this.data.formId;
    this.store.dispatch(LifeInsuranceActions.updateFormProduct({productId, formId, insuranceType}));
    this.store.dispatch(LifeInsuranceActions.updateStakeholder({formId, stakeholderId}));
    this.store.dispatch(LifeInsuranceActions.updateContract({formId, contractId}));
    this.modalService.closeModal();
  }

  private prepareStakeholders() {
    this.stakeholderOptions$ = this.form.get('insuranceType').valueChanges.pipe(
      startWith(this.form.get('insuranceType').value),
      switchMap(insuranceType => {
        return insuranceType === 'investment'
          ? of(this.data.investmentEligiblePersons)
          : this.store.pipe(select(selectAdultPersons));
      }),
      map(persons => persons.map(person => ({key: person.id, label: person.name}))),
    );

    this.stakeholderOptions$.pipe(untilDestroyed(this)).subscribe(stakeholderOptions => {
      const selectedStakeholderId = this.form.get('stakeholderId').value;
      if (!stakeholderOptions.find(option => option.key === selectedStakeholderId)) {
        this.form.get('stakeholderId').setValue(null);
      }
    });
  }

  private preparePersonsWithSameProduct() {
    this.personsWithSameProduct$ = combineLatest(
      this.form.get('productId').valueChanges as Observable<number>,
      this.form.get('stakeholderId').valueChanges as Observable<string>,
      this.store.pipe(select(selectForms)),
      this.store.pipe(select(selectOrderedPersons)),
    ).pipe(
      filter(([productId]) => Boolean(productId)),
      map(([productId, stakeholderId, forms, persons]) => {
        const personId = forms.find(f => f.id === this.data.formId).personId;
        const formsWithSameProduct = forms.filter(
          f =>
            f.personId !== personId &&
            f.group === LifeInsuranceFormGroup.Recommended &&
            f.stakeholderId === stakeholderId &&
            f.productId === productId,
        );
        return this.getPersonNamesFromForms(formsWithSameProduct, persons);
      }),
      untilDestroyed(this),
    );

    this.personsWithSameProduct$.subscribe(result => (this.personsWithSameProduct = result));
  }

  private prepareContractOptions() {
    combineLatest(
      this.form.get('productId').valueChanges as Observable<number>,
      this.form.get('stakeholderId').valueChanges as Observable<string>,
      this.store.pipe(select(selectForms)),
      this.store.pipe(select(selectOrderedPersons)),
    )
      .pipe(untilDestroyed(this))
      .subscribe(([productId, stakeholderId, forms, persons]) => {
        const form = forms.find(f => f.id === this.data.formId);
        const isOnCompoundContract = !!forms.find(
          f =>
            f.id !== form.id &&
            f.contractId === form.contractId &&
            f.group === LifeInsuranceFormGroup.Recommended,
        );
        const contractId = isOnCompoundContract ? 0 : form.contractId;
        const singleContractOption = {
          key: contractId,
          label: `Samostatná smlouva`,
        };

        const personId = forms.find(f => f.id === this.data.formId).personId;
        const formsWithSameProduct = forms.filter(
          f =>
            f.personId !== personId &&
            f.group === LifeInsuranceFormGroup.Recommended &&
            f.stakeholderId === stakeholderId &&
            f.productId === productId,
        );

        const contractGroupsWithForms = groupBy(formsWithSameProduct, f => f.contractId);
        const contractGroupsWithNames = mapValues(contractGroupsWithForms, groupedForms =>
          this.getPersonNamesFromForms(groupedForms, persons),
        );
        const options = toPairs(contractGroupsWithNames).map(
          ([key, value]): IOption => ({key: Number(key), label: `Společná smlouva s: ${value}`}),
        );

        for (const option of options) {
          const personHasSameContractId = forms.find(
            f =>
              f.personId === personId &&
              f.contractId === option.key &&
              f.group === LifeInsuranceFormGroup.Recommended,
          );
          if (personHasSameContractId && form.contractId !== option.key) option.disabled = true;
        }

        this.contractOptions = [singleContractOption, ...options];
      });
  }

  private prepareInsuranceTypeOptions() {
    this.insuranceTypeOptions =
      this.data.investmentEligiblePersons.length > 0
        ? [
            {key: 'risk', label: 'Rizikové životní pojištění'},
            {key: 'investment', label: 'Investiční životní pojištění'},
          ]
        : [{key: 'risk', label: 'Rizikové životní pojištění'}];
  }

  private makeInitialSelection() {
    const stakeholderId$ = this.store.pipe(
      select(selectForms),
      map(forms => forms.find(f => f.id === this.data.formId)),
      map(form => (form ? form.stakeholderId : null)),
    );

    const contractId$ = this.store.pipe(
      select(selectForms),
      map(forms => forms.find(f => f.id === this.data.formId)),
      map(form => (form ? form.contractId : null)),
    );

    combineLatest(
      this.productsService.getProducts(ProductSet.LifeInsuranceRisk),
      this.productsService.getProducts(ProductSet.LifeInsuranceInvestment),
      stakeholderId$,
      contractId$,
    )
      .pipe(take(1))
      .subscribe(([riskProducts, investmentProducts, stakeholderId, contractId]) => {
        if (this.data.productId) {
          const riskProduct = riskProducts.find(p => p.id === this.data.productId);
          const investmentProduct = investmentProducts.find(p => p.id === this.data.productId);
          if (riskProduct) this.form.get('insuranceType').setValue('risk');
          if (investmentProduct) this.form.get('insuranceType').setValue('investment');

          const product = riskProduct || investmentProduct;
          if (product) {
            this.form.get('partnerId').setValue(product.partnerId);
            this.form.get('productId').setValue(product.id);
          }
        }

        this.form.get('stakeholderId').setValue(stakeholderId);
        this.form.get('contractId').setValue(contractId);
      });
  }

  private prepareProducts() {
    const productsByType$ = this.form.get('insuranceType').valueChanges.pipe(
      map(type =>
        type === 'risk' ? ProductSet.LifeInsuranceRisk : ProductSet.LifeInsuranceInvestment,
      ),
      switchMap(productSet => this.productsService.getProducts(productSet)),
      tap(_ => {
        this.form.get('partnerId').setValue(null);
        this.form.get('productId').setValue(null);
      }),
      untilDestroyed(this),
    );

    this.partnerOptions$ = productsByType$.pipe(
      map(products =>
        products.map(product => ({key: product.partnerId, label: product.partnerName} as IOption)),
      ),
      map(options => uniqBy(options, option => option.key)),
      untilDestroyed(this),
    );

    this.partnerOptions$.subscribe(r => (this.partnerOptions = r));

    const partnerId$ = this.form.get('partnerId').valueChanges.pipe(untilDestroyed(this));

    partnerId$.subscribe(() => {
      this.form.get('productId').setValue(null);
    });

    this.productOptions$ = partnerId$.pipe(
      withLatestFrom(productsByType$),
      map(([partnerId, products]) =>
        products
          .filter(product => product.partnerId === partnerId)
          .map(product => ({key: product.id, label: product.name})),
      ),
      untilDestroyed(this),
    );

    this.productOptions$.subscribe(r => (this.productOptions = r));
  }

  private getPersonNamesFromForms(
    formsWithSameProduct: LifeInsuranceForm[],
    persons: LifeInsurancePerson[],
  ): string {
    const personIdsWithSameProduct = uniq(formsWithSameProduct.map(form => form.personId)).sort(
      (personIdA, personIdB) =>
        persons.find(person => person.id === personIdA).order -
        persons.find(person => person.id === personIdB).order,
    );

    return czechCommaJoin(
      personIdsWithSameProduct.map(personId => persons.find(person => person.id === personId).name),
    );
  }
}
