import {AbstractControl} from '@angular/forms';
import {Answers} from '@shared/investment-survey-old/models/answer';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {IOption, SliderOptions} from '@shared/ui';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';

export enum QuestionsOrigin {
  LIFE_INSURANCE = 'lifeInsurance',
  INVESTMENT = 'investment',
  SUPPLEMENTARY_PENSION_SAVINGS = 'supplementaryPensionSavings',
  FINANCIAL_ANALYSIS = 'financialAnalysis',
  INVESTMENT_REQUIREMENTS = 'investmentRequirements',
  INVESTMENT_ALL = 'investmentAll',
}

export enum QuestionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  TABLE = 'table',
  SLIDER = 'slider',
}

export enum AdditionalAnswerType {
  TEXT = 'text',
  INPUT_TEXT = 'inputText',
  INPUT_NUMBER = 'inputNumber',
  INPUT_SELECT = 'inputSelect',
}

export interface AdditionalText {
  text: string;
}

export interface AdditionalInput {
  label: string;
  help?: string;
}

export interface AdditionalSelect {
  label: string;
  options: IOption[];
  help?: string;
}

export interface AdditionalAnswerPart {
  id: string;
  type: AdditionalAnswerType;
  content: AdditionalText | AdditionalInput | AdditionalSelect;
}

export interface Answer {
  id: string;
  text: string;
  shortText?: string;
  score: number;
  additionalAnswerParts?: AdditionalAnswerPart[];
  customClass?: string;
}

export interface Row {
  id: string;
  text: string;
  forcedScore?: Record<string, number>;
}

export interface GraphSettings {
  options: ChartOptions;
  datasets: ChartDataSets[];
  type: ChartType;
  labels: Label[];
}

export interface Question {
  id: string;
  type: QuestionType;
  question: string;
  description?: string;
  moreDescription?: string;
  answers: Answer[];
  rowsHeader?: string;
  rows?: Row[];
  lifeInsurance: boolean;
  investment: boolean;
  supplementaryPensionSavings: boolean;
  excludedFromAnalysis?: boolean;
  investmentRequirements?: boolean;
  sliderOptions?: SliderOptions;
  graph?: GraphSettings;
  onValueChange?: (
    currentValue: any,
    oldAnswers: Answers,
    currentValues: any,
    form: AbstractControl,
  ) => void;
  hidden?: (surveyAnswers: SurveyAnswers[]) => boolean;
  graphConditions?: GraphCondition[];
}

export interface GraphCondition {
  targetAnswer: string;
  questionsOrigins: QuestionsOrigin[];
  modifyQuestion: (question: Question, answers?: Answers) => Question;
}

export interface QuestionCategory {
  name: string;
  questions: Question[];
}
