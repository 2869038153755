<ng-template #defaultContentTemplate let-data>
  <div class="accordion__content">
    <div class="accordion__content-item">
      <p>
        {{ data }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #defaultHeadingTemplate let-data>
  <div
    class="accordion__item"
    *ngFor="let item of accordionItems"
    [class.accordion__item--empty]="!item.data"
    [class.accordion__item--active]="item.isActive"
    [class.accordion__item--disabled]="item.isDisabled"
  >
    <div
      class="accordion__toggle"
      (click)="
        !!item.data && isCollapsable
          ? (item.isActive = !item.isActive)
          : accordionItemAction.emit(item)
      "
    >
      <span
        *ngIf="item.stateColor"
        [ngClass]="'accordion__state-indicator bg--' + item.stateColor"
      ></span>
      <span class="accordion__toggle-title" [innerHTML]="item.label"></span>
      <span class="accordion__toggle-amount">
        <span [innerHTML]="item.value | number : '1.0-0'"></span>
        &nbsp;
        <span *ngIf="!!item.currency" [innerHTML]="item.currency"> </span>
      </span>
      <span
        class="icon accordion__toggle-icon"
        [class.icon--plus]="!!item.data && !item.isActive && isCollapsable"
        [class.icon--minus]="!!item.data && !!item.isActive && isCollapsable"
        [class.icon--edit]="!item.data || !isCollapsable"
      >
      </span>
    </div>
    <div *ngIf="!!item.data">
      <ng-container
        *ngTemplateOutlet="
          accordionContentTemplate || defaultContentTemplate;
          context: {$implicit: item.data}
        "
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<div class="accordion">
  <div *ngIf="!!title" class="accordion__header">
    <h3 class="accordion__header-title">
      {{ title }}
    </h3>
    <span *ngIf="accordionSubtitleTemplate" class="accordion__header-subtitle">
      <ng-container *ngTemplateOutlet="accordionSubtitleTemplate; context: {$implicit: data}">
      </ng-container>
    </span>
  </div>
  <div class="accordion__body">
    <ng-container
      *ngTemplateOutlet="
        accordionHeadingTemplate || defaultHeadingTemplate;
        context: {$implicit: data}
      "
    ></ng-container>
  </div>
</div>
