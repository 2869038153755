import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AttachedFileTileModule} from '@shared/lib/components/attached-file-tile/attached-file-tile.module';
import {AlertModule} from '@shared/ui';
import {ButtonModule} from '@shared/ui/button/button.module';
import {FileUploadModule} from 'ng2-file-upload';
import {AttachmentsComponent} from './attachments.component';

@NgModule({
  imports: [CommonModule, FileUploadModule, ButtonModule, AttachedFileTileModule, AlertModule],
  declarations: [AttachmentsComponent],
  exports: [AttachmentsComponent],
})
export class AttachmentsModule {}
