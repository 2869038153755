<ng-container *ngIf="familyHead$ | async as familyHead">
  <div class="client-page__tabs">
    <div class="row">
      <div class="col-12">
        <kpt-content-box>
          <kpt-tabs (selectAction)="tabChanged($event.name)" [allCaps]="true">
            <kpt-tab
              tabTitle="Analýza a plánování"
              name="cardBoard"
              [active]="activeTabId === 'subjects'"
            >
              <kpt-card-board1 *ngIf="!featureFlagsService.showNewDashboard"></kpt-card-board1>
              <kpt-card-board2 *ngIf="featureFlagsService.showNewDashboard"></kpt-card-board2>
            </kpt-tab>
            <kpt-tab tabTitle="Výsledky" name="summary" [active]="activeTabId === 'summary'">
              <kpt-summary></kpt-summary>
            </kpt-tab>
          </kpt-tabs>
        </kpt-content-box>
      </div>
    </div>
  </div>
</ng-container>
