import {Component} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'kpt-named-group-formly',
  templateUrl: './named-group-formly.component.html',
  styleUrls: ['./named-group-formly.component.scss'],
})
export class NamedGroupFormlyComponent extends FieldWrapper {
  // keep labels in the code but hide for now
  hideLabel = true;

  constructor() {
    super();
  }
}
