<div
  ng2FileDrop
  (fileOver)="onDragOverColumn($event)"
  (onFileDrop)="onFilesSelected()"
  [uploader]="uploader"
  class="well drop-zone"
>
  <ng-content></ng-content>

  <div class="upload-items-wrapper">
    <div *ngIf="filesList && filesList.length" class="upload-items">
      <kpt-attached-file
        *ngFor="let file of filesList; let i = index"
        [file]="file"
        [shortcut]="file.shortcut"
        (update)="onUpdateFormFile($event)"
        (removeFromQueue)="onRemoveItemFromQueue($event)"
        (showDeleteConfirmation)="onShowDeleteConfirmation($event)"
      >
      </kpt-attached-file>
    </div>

    <div *ngIf="showUploader" class="upload-model">
      <input
        #file
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        style="display: none"
        (change)="onFilesSelected()"
      />

      <div class="upload-link" (click)="file.click()">
        <div class="plus upload-button">+</div>
        <span class="label">{{
          initialUploadData.documentType === 'S' ? 'Nahrát smlouvu' : 'Nahrát modelaci'
        }}</span>
      </div>
    </div>

    <div
      *ngIf="deletingFile"
      class="delete-confirmation file"
      (click)="onCancelDeleteFile()"
      (clickOutside)="onCancelDeleteFile()"
      [delayClickOutsideInit]="true"
    >
      <p>
        Smazat&nbsp;<span class="file-name" [title]="deletingFile?.name">{{
          deletingFile?.name
        }}</span
        >&nbsp;?
      </p>
      <kpt-button
        class="confirm-delete-file"
        type="primary"
        label="Smazat"
        (click)="onConfirmDeleteFile(); $event.stopPropagation()"
      >
      </kpt-button>
    </div>

    <div class="cancelled-overlay" *ngIf="form?.cancelled"></div>
  </div>
</div>
