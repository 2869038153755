/* eslint-disable max-len */
import {Injectable, OnDestroy} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {ModelationGold, PensionEnsurementTypePersonEnum} from '@generated/model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {
  allInvestmentAssets,
  Asset,
  assetNames,
  AssetType,
  basicInvestmentAssets,
  commonExpenseAssets,
  commonExpenseNoContractorAssets,
  creditAssets,
  financialPropertyAssets,
  immovablePropertyAssets,
  incomeAssets,
  insuranceAssets,
  movablePropertyAssets,
  otherIncomesAssets,
  pensionInvestmentAssets,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {
  ContractorAsset,
  EmploymentAsset,
  MainIncomesAsset,
} from '@shared/analysis/models/main-incomes';
import {getFamilyMembersAdultsThenChildren} from '@shared/lib';
import {ERecordIncomeSource, ERecordNewFinancialAnalysis} from '@shared/models/erecord.models';
import {getPersonAge} from '@shared/utils';
import {groupBy, mapValues, sum, sumBy} from 'lodash';
import {combineLatest, from, Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {FinancialOverview} from 'src/app/modules/financial-plan/store/financial-plan.models';
import {InvestmentActions} from 'src/app/modules/investment-old/store';
import {InvestmentBaseData} from 'src/app/modules/investment-old/store/investment.models';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {
  AdditionalAnalysisData,
  LifeInsurancePerson as LifeInsurancePersonOld,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectAdditionalAnalysisData,
  selectOrderedPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {PensionActions} from 'src/app/modules/pension/pension/store/pension.actions';
import {PensionPerson} from 'src/app/modules/pension/pension/store/pension.models';
import {getPensionPersons} from 'src/app/modules/pension/pension/store/pension.selectors';
import {AssetHelperService} from 'src/shared/analysis/asset-helper.service';
import {filterAssets, findAssetField} from 'src/shared/analysis/asset.utils';
import {calculateCurrentEquity, calculateMonthlySum} from 'src/shared/analysis/evaluation';
import {FinancialAnalysisDataService} from 'src/shared/analysis/financial-analysis-data.service';
import {
  ChildrenTaxAdvantageAsset,
  FinancialOutlookAsset,
  FinancialOutlookType,
  PersonTaxAdvantage,
} from 'src/shared/analysis/models/income-attributes';
import {selectFamilyHead, selectFamilyMembers} from 'src/shared/analysis/operators';
import {
  calculateCommonExpensesSum,
  calculateCreditProductsSum,
  calculateInsuranceProductsSum,
  calculateInvestmentProductsSum,
  replaceContractorAssetType,
  selectCommonBadDebtsAssets,
  selectCurrentAccountAssets,
  selectCurrentAssets,
  selectExpensesSum,
  selectFinancialPropertiesSum,
  selectIncomesAssets,
  selectIncomesSum,
  selectIndispensableExpensesSum,
  selectInvestmentProductsSum,
  selectMonthlyBalance,
  selectObjectivesAssets,
  selectOtherIncomesSum,
  selectSavingAccountAssets,
} from 'src/shared/analysis/store';
import {LIFE_EXPECTANCY_FEMALE, LIFE_EXPECTANCY_MALE} from 'src/shared/constants/misc.constants';
import {State} from 'src/store';
import {DataPart} from '../modules/assets-and-liabilities/assets-accordion/components/my-situation/my-situation.component';
import {GoldActions} from '../modules/gold/store';
import {
  computeMaxMonthlyInvestment,
  computeMaxOneTimeInvestment,
  computeOptimalInvestment,
  InvestmentGoldPerson,
} from './analysis-data.utils';
/* eslint-enable max-len */

@UntilDestroy()
@Injectable()
export class AnalysisDataService implements OnDestroy {
  private assets$ = this.store.pipe(select(selectCurrentAssets), untilDestroyed(this));
  private sortedFamilyMembers$ = this.store.pipe(
    selectFamilyMembers(),
    map(getFamilyMembersAdultsThenChildren),
    untilDestroyed(this),
  );
  private additionalAnalysisData$ = this.store.pipe(
    select(selectAdditionalAnalysisData),
    untilDestroyed(this),
  );
  private familyHead$ = this.store.pipe(selectFamilyHead(), untilDestroyed(this));
  private pensionPersons$ = this.store.pipe(select(getPensionPersons), untilDestroyed(this));
  private lifeInsuranceMembers$ = this.store.pipe(
    select(selectOrderedPersons),
    untilDestroyed(this),
  );

  constructor(
    private store: Store<State>,
    private faDataService: FinancialAnalysisDataService,
    private assetHelper: AssetHelperService,
  ) {}

  ngOnDestroy() {}

  loadClientDataAndSaveAssetChanges$(familyId: string): Observable<[FamilyMember[], Asset[]]> {
    return from(this.faDataService.loadDataAndSaveChanges(familyId)).pipe(
      switchMap(() => combineLatest([this.sortedFamilyMembers$, this.assets$])),
    );
  }

  // Pension
  loadFamilyMembers(_familyId: string) {
    combineLatest([this.sortedFamilyMembers$, this.familyHead$, this.assets$, this.pensionPersons$])
      .pipe(take(1))
      .subscribe(([members, familyHead, assets, pensionPersons]) => {
        const persons = this.getFamilyMembersFromAnalysis(members, assets, pensionPersons);
        const familyHeadId = familyHead ? familyHead.sugarUuid : null;
        this.store.dispatch(
          PensionActions.initializePersons({persons: persons ? persons : [], familyHeadId}),
        );
      });
  }

  loadFamilyInvestmentBaseData(_familyId: string) {
    return combineLatest([
      this.store.select(selectFinancialPropertiesSum),
      this.store.select(selectMonthlyBalance),
    ]).subscribe(([familySavings, familyMonthlyBalance]) => {
      const baseData: InvestmentBaseData = {
        familySavings,
        familyMonthlyBalance,
        maxOneTimeInvestment: computeMaxOneTimeInvestment(familySavings),
        maxMonthlyInvestment: computeMaxMonthlyInvestment(familyMonthlyBalance),
      };
      this.store.dispatch(InvestmentActions.setBaseData({baseData}));
    });
  }

  // Gold
  loadClient() {
    this.familyHead$.pipe(filter<FamilyMember>(Boolean)).subscribe(familyHead => {
      this.store.dispatch(
        GoldActions.updateClient({
          client: {
            clientId: familyHead.sugarUuid,
            emailForSigning: familyHead.email,
            meetingPlace: '',
          },
        }),
      );
    });
  }

  // Life Insurance
  /**
   * @deprecated
   */
  reloadFinancialAnalysis() {
    this.getLifeInsurancePersonsOld$()
      .pipe(take(1))
      .subscribe(persons => {
        this.store.dispatch(LifeInsuranceActions.resetInput({persons}));
      });
    return;
  }

  /**
   * @deprecated
   */
  getLifeInsurancePersonsOld$(): Observable<LifeInsurancePersonOld[]> {
    return combineLatest([
      this.lifeInsuranceMembers$,
      this.sortedFamilyMembers$,
      this.assets$,
      this.additionalAnalysisData$,
    ]).pipe(
      map(([lifeInsuranceMembers, members, assets, additionalData]) =>
        this.getFamilyMembersFromAnalysisLifeInsurance(
          lifeInsuranceMembers,
          members,
          assets,
          additionalData,
        ),
      ),
    );
  }

  // Life Insurance
  getFinancialAnalysisForSummary$(
    _familyId: string,
    personId: string,
  ): Observable<ERecordNewFinancialAnalysis> {
    return combineLatest([this.sortedFamilyMembers$, this.assets$, this.familyHead$]).pipe(
      take(1),
      map(([members, assets, familyHead]: [FamilyMember[], Asset[], FamilyMember]) => {
        const housingCosts = this.getHousingCosts(personId, members, assets);
        const investments = this.getMonthlyInvestments(personId, members, assets);
        return {
          familyHead: familyHead.sugarUuid === personId,
          personId,
          netIncome: this.getPersonIncome(personId, assets),
          liabilities: getPersonDebts(null, assets),
          monthlyInstallments: this.getPersonDebtsMonthly(personId, members, assets),
          incomeSource: this.getPersonIncomeSource(personId, assets),
          housingCosts,
          investments,
          otherCosts: this.getOtherCosts(assets, housingCosts + investments),
          savings: this.getPersonCurrentAccountValue(personId, assets),
          dependentPersons: this.getDependentPersons(members),
          assets: this.getPersonAssets(personId, assets),
          financialOutlook: (getFinancialOutlook(personId, assets) || 'positive').toLowerCase(),
        };
      }),
    );
  }

  // Gold
  loadFamilySavingsAndBalance(_familyId: string) {
    return combineLatest([
      this.store.select(selectFinancialPropertiesSum),
      this.store.select(selectMonthlyBalance),
    ]).subscribe(([savings, monthlyBalance]) => {
      const modelation: ModelationGold = {
        savings,
        monthlyBalance,
        oneTimeInvestment: computeOptimalInvestment(savings),
        regularInvestment: computeOptimalInvestment(monthlyBalance),
      };
      this.store.dispatch(GoldActions.updateModelation({modelation}));
    });
  }

  // used to restrict entering Investment module
  getInvestmentRequiredData$(_familyId: string): Observable<{
    familyBalance: number;
    familyHeadOutlook: FinancialOutlookType;
  }> {
    return combineLatest([
      this.assets$,
      this.store.select(selectMonthlyBalance),
      this.familyHead$,
    ]).pipe(
      map(([assets, familyBalance, familyHead]) => {
        return {
          familyBalance,
          familyHeadOutlook: familyHead
            ? getFinancialOutlook(familyHead.sugarUuid, assets)
            : undefined,
        };
      }),
    );
  }

  // used to restrict entering LifeInsurance module
  // - at least one adult person has to have non-zero incomes
  // - total expenses have to be non-zero
  /**
   * @deprecated
   */
  isLifeInsuranceDataAvailable$(_familyId: string): Observable<boolean> {
    return combineLatest([this.sortedFamilyMembers$, this.assets$]).pipe(
      map(([members, assets]) => {
        const nonZeroTotalExpenses = getFamilyExpenses(assets) > 0;
        const nonZeroIncomes = members
          .filter(p => p.type === 'ADULT')
          .some(
            p =>
              this.getPersonIncome(p.sugarUuid, assets) > 0 ||
              this.getPersonPassiveIncome(p.sugarUuid, assets) > 0,
          );

        return nonZeroIncomes && nonZeroTotalExpenses;
      }),
    );
  }

  getFamilyMembersGoldInvestment$(): Observable<InvestmentGoldPerson[]> {
    return this.sortedFamilyMembers$.pipe(
      map(members =>
        members.map(m => ({
          id: m.sugarUuid,
          familyHead: m.familyHead,
          birthDate: m.birthDate,
          name: m.firstName || m.lastName,
        })),
      ),
    );
  }

  getFinancialOverview$(): Observable<FinancialOverview> {
    return combineLatest(
      this.store.pipe(selectFamilyMembers()),
      this.store.pipe(select(selectIncomesAssets)),
      this.store.pipe(select(selectIncomesSum)),
      this.store.pipe(select(selectOtherIncomesSum)),
      this.store.pipe(select(selectMonthlyBalance)),
      this.store.pipe(select(selectExpensesSum)),
      this.store.pipe(select(selectIndispensableExpensesSum)),
      this.store.pipe(select(selectFinancialPropertiesSum)),
      this.store.pipe(select(selectInvestmentProductsSum)),
      this.store.pipe(select(selectObjectivesAssets)),
      this.store.pipe(select(selectCurrentAccountAssets)),
      this.store.pipe(select(selectCommonBadDebtsAssets)),
      this.store.pipe(select(selectSavingAccountAssets)),
    ).pipe(
      map(
        ([
          familyMembers,
          incomesAssets,
          incomesSum,
          otherIncomesSum,
          monthlyBalance,
          expensesSum,
          indispensableExpensesSum,
          financialPropertiesSum,
          investmentProductsSum,
          objectivesAssets,
          currentAccountAssets,
          commonBadDebtsAssets,
          savingAccountAssets,
        ]: [
          FamilyMember[],
          MainIncomesAsset[],
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          Asset[],
          ValueAsset[],
          Asset[],
          ValueAsset[],
        ]): FinancialOverview => {
          const hasBadDebtsRepayment = !!objectivesAssets.find(
            asset => asset.type === AssetType.BadDebtsRepayment,
          );

          const incomeByFamilyMember: number[] = familyMembers.map((familyMember: FamilyMember) => {
            return incomesAssets
              .filter(asset => asset.familyMemberUuid === familyMember.sugarUuid)
              .map(asset => asset.value)
              .reduce((sum_, value) => sum_ + value, 0);
          });

          const currentAccountBalance = calculateCurrentEquity(currentAccountAssets as Asset[]);

          const savingAccountBalance = calculateCurrentEquity(savingAccountAssets as Asset[]);

          return {
            income: incomesSum,
            passiveIncome: otherIncomesSum,
            averageIncomeByFamilyMember: incomesSum / familyMembers.length,
            incomeByFamilyMember,
            monthlyBalance,
            currentAccountBalance,
            savingAccountBalance,
            expenses: expensesSum,
            indispensableExpenses: indispensableExpensesSum,
            financialReserve: financialPropertiesSum,
            useInvestmentsProducts: investmentProductsSum > 0,
            hasBadDebtsRepayment,
            hasCommonBadDebts: commonBadDebtsAssets.length > 0,
          };
        },
      ),
    );
  }

  // Assets and Liabilities
  getAssetsAndLiabilitiesOverview$(_familyId: string): Observable<{
    incomes: DataPart;
    costs: DataPart;
    assets: DataPart;
    liabilities: DataPart;
  }> {
    const liabilityAssets: AssetType[] = [
      AssetType.CommonExpensesSum,
      ...commonExpenseNoContractorAssets,
      AssetType.InsuranceProductsSum,
      ...insuranceAssets,
      AssetType.CreditProductsSum,
      ...creditAssets,
    ];

    const costAssets: AssetType[] = [
      ...liabilityAssets,
      AssetType.InvestmentProductsSum,
      ...allInvestmentAssets,
    ];

    return this.store.pipe(
      select(selectCurrentAssets),
      filter(assets => assets.length > 0),
      map(faAssets => {
        const incomes = this.createDataPart(faAssets, incomeAssets);
        const costs = this.createDataPart(faAssets, costAssets);
        const assets: DataPart = {};
        const liabilities: DataPart = {};

        Object.keys(costs).forEach((key: AssetType) => {
          if (liabilityAssets.includes(key)) liabilities[key] = costs[key];
          else assets[key] = costs[key];
        });

        return {incomes, costs, assets, liabilities};
      }),
    );
  }

  getPersonCurrentAccountValue$(personId: string): Observable<number> {
    return this.assets$.pipe(map(assets => this.getPersonCurrentAccountValue(personId, assets)));
  }

  private createDataPart(assets: Asset[], types: AssetType[]): DataPart {
    const assetsByType = groupBy(filterAssets(assets, types), a => a.type);
    return mapValues(assetsByType, (assets2, type: AssetType) => ({
      label: assetNames[type],
      value: Math.abs(calculateMonthlySum(assets2)),
    }));
  }

  private getFamilyMembersFromAnalysis(
    familyMembers: FamilyMember[],
    assets: Asset[],
    pensionPersons: PensionPerson[],
  ): PensionPerson[] {
    return familyMembers
      .filter(familyMember => familyMember.type === 'ADULT')
      .map((familyMember: FamilyMember) => {
        const personId = familyMember.sugarUuid;
        const oldPerson = pensionPersons.find(p => p.sugarUuid === personId);
        return {
          sugarUuid: personId,
          age: getPersonAge(familyMember.birthDate),
          lifeExpectancy:
            familyMember.sex === 'MALE' ? LIFE_EXPECTANCY_MALE : LIFE_EXPECTANCY_FEMALE,
          income: Math.round(this.getPersonIncome(personId, assets)),
          passiveIncome: Math.round(this.getPersonPassiveIncome(personId, assets)),
          savings: oldPerson ? oldPerson.savings : 0,
          alreadySaved: Math.round(getPersonSavings(personId, assets)),
          rent: oldPerson ? oldPerson.rent : 0,
          strategy: oldPerson ? oldPerson.strategy : 'balanced',
          contribution: oldPerson ? oldPerson.contribution : 0,
          employersContribution: oldPerson ? oldPerson.employersContribution : 0,
        };
      });
  }

  /**
   * @deprecated
   */
  private getFamilyMembersFromAnalysisLifeInsurance(
    lifeInsuranceMembers: LifeInsurancePersonOld[],
    familyMembers: FamilyMember[],
    assets: Asset[],
    additionalData: AdditionalAnalysisData,
  ): LifeInsurancePersonOld[] {
    return familyMembers.map((familyMember: FamilyMember, index: number) => {
      const lifeInsurancePerson = lifeInsuranceMembers.find(
        member => member.id === familyMember.sugarUuid,
      );
      let pensionEnsurementType: PensionEnsurementTypePersonEnum;
      if (lifeInsurancePerson && lifeInsurancePerson.pensionEnsurement) {
        pensionEnsurementType = lifeInsurancePerson.pensionEnsurementType;
      }

      const personId = familyMember.sugarUuid;
      const debts = Math.round(
        getPersonDebts(personId, assets, additionalData.debtsTogether || familyMember.familyHead),
      );
      return {
        id: personId,
        name: familyMember.firstName || familyMember.lastName,
        lastName: familyMember.lastName,
        familyHead: familyMember.familyHead,
        age: getPersonAge(familyMember.birthDate),
        child: familyMember.type === 'CHILD',
        income: Math.round(getPersonIncomeLifeInsurance(personId, assets)),
        incomeContractor: Math.round(getPersonIncomeContractorLifeInsurance(personId, assets)),
        other: Math.round(getOtherIncomeEmployee(personId, assets)),
        otherContractor: Math.round(getOtherIncomeContractor(personId, assets)),
        passiveIncome: Math.round(getPersonPassiveIncomeLifeInsurance(personId, assets)),
        savings: Math.round(getPersonSavings(personId, assets)),
        debts,
        expenses: Math.round(getWeightedPersonExpenses(personId, familyMembers, assets)),
        individualSelection: Boolean(
          lifeInsurancePerson && lifeInsurancePerson.individualSelection,
        ),
        order: index,
        childrenTaxAdvantage: getNumberOfChildrenWithTaxAdvantage(personId, assets),
        financialOutlook: getFinancialOutlook(personId, assets),
        netMonthlyIncomeContractor: getNetMonthlyIncomeContractor(personId, assets),
        monthlySickLeaveInsurancePaymentContractor: getMonthlySickLeavePayContractor(
          personId,
          assets,
        ),
        debtsTogether: additionalData.debtsTogether,
        createReserves: lifeInsurancePerson && lifeInsurancePerson.createReserves,
        taxRelief:
          lifeInsurancePerson &&
          lifeInsurancePerson.createReserves &&
          lifeInsurancePerson.taxRelief,
        employeeContribution:
          lifeInsurancePerson &&
          lifeInsurancePerson.createReserves &&
          lifeInsurancePerson.employeeContribution,
        pensionEnsurement: lifeInsurancePerson && lifeInsurancePerson.pensionEnsurement,
        pensionEnsurementType,
        useOtherIncome: lifeInsurancePerson?.useOtherIncome,
        otherIncomeAssetUuids: lifeInsurancePerson?.otherIncomeAssetUuids ?? [],
        useSixMonthReserve: lifeInsurancePerson?.useSixMonthReserve,
        sixMonthReserve: lifeInsurancePerson?.sixMonthReserve ?? {
          incapacity: false,
          hospitalization: false,
          dailyCompensation: false,
          physicalDamage: false,
        },
        usePaymentProtectionInsurance: lifeInsurancePerson?.usePaymentProtectionInsurance,
      };
    });
  }

  private getPersonIncome(personId: string, assets: Asset[]): number {
    const employments = filterAssets(
      assets,
      [AssetType.Employment],
      'now',
      personId,
    ) as EmploymentAsset[];
    const contracts = filterAssets(
      assets,
      [AssetType.Contractor],
      'now',
      personId,
    ) as ContractorAsset[];
    return (
      sumBy(employments, a => (a.netIncome != null ? a.netIncome : a.value)) +
      sumBy(contracts, a => a.value)
    );
  }

  private getPersonPassiveIncome(personId: string, assets: Asset[]): number {
    return calculateMonthlySum(filterAssets(assets, otherIncomesAssets, null, personId) as Asset[]);
  }

  private getPersonCurrentAccountValue(personId: string, assets: Asset[]): number {
    return calculateCurrentEquity(filterAssets(assets, [AssetType.CurrentAccount], null, personId));
  }

  private getPersonAssets(personId: string, assets: Asset[]): string {
    const propertyAssets = filterAssets(
      assets,
      [...immovablePropertyAssets, ...movablePropertyAssets],
      null,
      personId,
    )
      .filter(asset => (asset as ValueAsset).value >= 200_000)
      .map(a => this.assetHelper.getName(a));
    return propertyAssets.join(', ');
  }

  private getPersonDebtsMonthly(
    personId: string,
    persons: FamilyMember[],
    assets: Asset[],
  ): number {
    const monthlyDebts = -calculateMonthlySum(
      filterAssets(assets, [...creditAssets, AssetType.CreditPropertiesSum]),
    );
    return getWeightedPersonValue(personId, persons, assets, monthlyDebts);
  }

  private getFamilyExpensesLifeInsurance(assets: Asset[]): number {
    return (
      calculateCommonExpensesSum(assets) +
      calculateInsuranceProductsSum(assets) +
      calculateInvestmentProductsSum(assets) +
      calculateCreditProductsSum(assets)
    );
  }

  private getHousingCosts(personId: string, persons: FamilyMember[], assets: Asset[]): number {
    const housingCosts = -calculateMonthlySum(filterAssets(assets, [AssetType.HousingExpenses]));
    return getWeightedPersonValue(personId, persons, assets, housingCosts);
  }

  private getMonthlyInvestments(
    personId: string,
    persons: FamilyMember[],
    assets: Asset[],
  ): number {
    const investments = -calculateMonthlySum(
      filterAssets(assets, [...allInvestmentAssets, AssetType.InvestmentProductsSum]),
    );
    return getWeightedPersonValue(personId, persons, assets, investments);
  }

  private getOtherCosts(assets: Asset[], subtractValue: number): number {
    return this.getFamilyExpensesLifeInsurance(assets) - subtractValue;
  }

  private getDependentPersons(members: FamilyMember[]): number {
    return members.filter(member => member.type === 'CHILD').length;
  }

  private getPersonIncomeSource(personId: string, assets: Asset[]): ERecordIncomeSource {
    const otherIncomeAssets = filterAssets(assets, otherIncomesAssets, null, personId);

    return {
      maternity: otherIncomeAssets.some(asset => asset.type === AssetType.Maternity),
      parental: otherIncomeAssets.some(asset => asset.type === AssetType.Parental),
      unemploymentBenefits: otherIncomeAssets.some(
        asset => asset.type === AssetType.UnemploymentBenefits,
      ),
      pensionIncome: otherIncomeAssets.some(asset => asset.type === AssetType.PensionIncome),
      otherPensionIncome: otherIncomeAssets.some(
        asset => asset.type === AssetType.OtherPensionIncome,
      ),
      rentalIncome: otherIncomeAssets.some(asset => asset.type === AssetType.RentalIncome),
      dividendsIncome: otherIncomeAssets.some(asset => asset.type === AssetType.Dividends),
      otherPassiveIncome: otherIncomeAssets.some(
        asset => asset.type === AssetType.OtherPassiveIncome,
      ),
    };
  }
}

export function getPersonIncomeLifeInsurance(personId: string, assets: Asset[]): number {
  const employments = filterAssets(assets, [AssetType.Employment], null, personId) as Asset[];
  return calculateMonthlySum(employments);
}

export function getPersonIncomeContractorLifeInsurance(personId: string, assets: Asset[]): number {
  const contracts = filterAssets(assets, [AssetType.Contractor], null, personId) as Asset[];
  return calculateMonthlySum(contracts);
}

export function getOtherIncomeEmployee(personId: string, assets: Asset[]): number {
  const employments = filterAssets(
    assets,
    [AssetType.Employment],
    'now',
    personId,
  ) as EmploymentAsset[];
  return sumBy(employments, a => a.otherIncome);
}

export function getOtherIncomeContractor(personId: string, assets: Asset[]): number {
  const contracts = filterAssets(
    assets,
    [AssetType.Contractor],
    'now',
    personId,
  ) as ContractorAsset[];
  return sumBy(contracts, a => a.otherIncome);
}

export function getPersonPassiveIncomeLifeInsurance(personId: string, assets: Asset[]): number {
  return calculateMonthlySum(filterAssets(assets, otherIncomesAssets, null, personId) as Asset[]);
}

// TODO current account is per family and not per person
export function getPersonSavings(personId: string, assets: Asset[]): number {
  const savings = filterAssets(
    assets,
    [...financialPropertyAssets, ...allInvestmentAssets, AssetType.InvestmentPropertiesSum],
    null,
    personId,
  ) as Asset[];
  return calculateCurrentEquity(savings);
}

export function getWeightedPersonExpenses(
  personId: string,
  persons: FamilyMember[],
  assets: Asset[],
): number {
  const familyExpenses = getFamilyExpenses(assets);
  return getWeightedPersonValue(personId, persons, assets, familyExpenses);
}

export function getWeightedPersonValue(
  personId: string,
  persons: FamilyMember[],
  assets: Asset[],
  baseValue: number,
): number {
  const income = getPersonIncomeLifeInsurance(personId, assets);
  const incomeContractor = getPersonIncomeContractorLifeInsurance(personId, assets);
  const passiveIncome = getPersonPassiveIncomeLifeInsurance(personId, assets);
  const totalIncome = sum(
    persons.map(person => getPersonIncomeLifeInsurance(person.sugarUuid, assets)),
  );
  const totalIncomeContractor = sum(
    persons.map(person => getPersonIncomeContractorLifeInsurance(person.sugarUuid, assets)),
  );
  const totalPassiveIncome = sum(
    persons.map(person => getPersonPassiveIncomeLifeInsurance(person.sugarUuid, assets)),
  );

  const familyMemberTotalIncome = income + incomeContractor + passiveIncome;
  const familyTotalIncome = totalIncome + totalIncomeContractor + totalPassiveIncome;

  return income + familyTotalIncome ? (familyMemberTotalIncome / familyTotalIncome) * baseValue : 0;
}

export function getNumberOfChildrenWithTaxAdvantage(personId: string, assets: Asset[]): number {
  const persons: PersonTaxAdvantage[] = findAssetField<ChildrenTaxAdvantageAsset>(
    assets,
    AssetType.ChildrenTaxAdvantage,
    'persons',
  );
  return persons?.find(p => p.familyMemberUuid === personId)?.childrenUuids?.length || 0;
}

export function getFinancialOutlook(_personId: string, assets: Asset[]): FinancialOutlookType {
  return findAssetField<FinancialOutlookAsset>(assets, AssetType.FinancialOutlook, 'outlook');
}

export function getNetMonthlyIncomeContractor(personId: string, assets: Asset[]): number {
  const contracts = filterAssets(
    assets,
    [AssetType.Contractor],
    'now',
    personId,
  ) as ContractorAsset[];
  return sumBy(contracts, a => (a.netIncome != null ? a.netIncome : a.value));
}

export function getMonthlySickLeavePayContractor(personId: string, assets: Asset[]): number {
  const contracts = filterAssets(
    assets,
    [AssetType.Contractor],
    'now',
    personId,
  ) as ContractorAsset[];
  return sumBy(
    contracts.filter(a => a.sicknessInsurancePayer),
    a => a.sicknessInsuranceValue,
  );
}

export function getFamilyExpenses(assets: Asset[]): number {
  return -calculateMonthlySum(
    filterAssets(assets, [
      ...commonExpenseAssets,
      AssetType.CommonExpensesSum,
      ...insuranceAssets,
      AssetType.InsuranceProductsSum,
      ...basicInvestmentAssets,
      ...pensionInvestmentAssets,
      AssetType.InvestmentProductsSum,
      ...creditAssets,
      AssetType.CreditProductsSum,
    ]).map(replaceContractorAssetType),
  );
}

export function getPersonDebts(personId: string, assets: Asset[], includeSumAsset = true): number {
  return -calculateCurrentEquity(
    filterAssets(
      assets,
      [...creditAssets, includeSumAsset && AssetType.CreditPropertiesSum],
      null,
      personId,
    ),
  );
}
