import {AfterViewInit, Directive, OnDestroy, Optional} from '@angular/core';
import {
  AbstractControl,
  FormControlDirective,
  FormControlName,
  NgControl,
  NgModel,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BackendValidationFormDirective} from '@shared/backend-validation/backend-validation-form.directive';
import {
  BackendError,
  BackendValidationService,
} from '@shared/backend-validation/backend-validation.service';
import {Observable} from 'rxjs';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl],[formControlName],[ngModel]',
})
export class BackendValidationControlDirective implements AfterViewInit, OnDestroy, Validator {
  path: string;

  private error: BackendError = null;
  private erroredValue: any = null;

  constructor(
    @Optional() private backendValidationFormDirective: BackendValidationFormDirective,
    @Optional() private formControlDirective: FormControlDirective,
    @Optional() private formControlNameDirective: FormControlName,
    private backendValidationService: BackendValidationService,
    private control: NgControl,
  ) {}

  ngAfterViewInit() {
    if (!this.backendValidationFormDirective) return;

    this.path = this.getPath();
    this.control.control.addValidators(this.validate.bind(this));
    if (this.path) {
      this.backendValidationService.addControl(this.path, this);
    }

    this.control.control.valueChanges.subscribe(() => {
      this.erroredValue = null;
    });
  }

  ngOnDestroy() {
    if (this.path) {
      this.backendValidationService.removeControl(this.path);
    }
  }

  validate(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> | ValidationErrors | null {
    if (!this.backendValidationFormDirective) return null;

    if (!this.error) return null;
    // Hide the error if user has changed the value
    // TODO: possible race condition with auto-save: if value changes after the save was invoked, but before we
    // got the response, the error might be shown for the wrong value
    if (control.value !== this.erroredValue) return null;

    return {backendValidation: {message: this.error.messages[0]}};
  }

  resetError() {
    this.error = null;
    this.erroredValue = null;
    this.control.control.updateValueAndValidity();
  }

  setError(error: BackendError) {
    this.error = error;
    this.erroredValue = this.control.control.value;
    this.control.control.markAsTouched();
    this.control.control.updateValueAndValidity();
  }

  private getPath(): string {
    let path: string;
    if (this.control instanceof NgModel) {
      path = this.control.path.join('.');
    } else if (this.formControlDirective) {
      path = this.findPath(this.formControlDirective.form).join('.');
    } else if (this.formControlNameDirective) {
      path = this.formControlNameDirective.path.join('.');
    }

    if (!path) {
      // This happens for some formly forms, e.g., vehicle insurance form, second tab (caused by checkbox-formly.component)
      console.warn('Unable to get path for form control.');
    }

    return path;
  }

  private findPath(formControl: AbstractControl, path: string[] = []): string[] {
    if (!formControl.parent) return path;

    const entry = Object.entries(formControl.parent.controls).find(
      ([_key, value]) => value === formControl,
    );
    if (!entry) throw new Error('Unable to find formControl from its parent');

    return this.findPath(formControl.parent, [entry[0], ...path]);
  }
}
