import {Injectable} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {VehicleAssetType} from '@shared/analysis/asset-types/vehicle/vehicle-asset-type';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class MovableProperties {
  private commonPropertyAsset: FormlyFieldConfig[];

  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private vehicle: VehicleAssetType,
  ) {
    this.commonPropertyAsset = this.abstractAssets.commonPropertyAssetFields();
  }

  valuables(): AssetDefinition {
    return {
      name: assetNames[AssetType.Valuables],
      type: AssetType.Valuables,
      fields: this.commonPropertyAsset,
      validators: getAssetValidators(AssetType.Valuables),
      model: {},
    };
  }

  specialValuables(): AssetDefinition {
    return {
      name: assetNames[AssetType.SpecialValuables],
      type: AssetType.SpecialValuables,
      fields: this.commonPropertyAsset,
      validators: getAssetValidators(AssetType.SpecialValuables),
      model: {},
    };
  }

  getAll() {
    return [this.vehicle.getAssetDefinition(), this.valuables(), this.specialValuables()];
  }
}
