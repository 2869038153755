<span *ngIf="topLeftTemplate" class="top-left-corner">
  <ng-template [ngTemplateOutlet]="topLeftTemplate"></ng-template>
</span>
<span *ngIf="!hideClose" class="icon icon--close-modal" (click)="close.emit()"></span>

<div *ngIf="heading || headingTemplate" class="modal-header">
  <h3 *ngIf="heading && !headingTemplate" class="modal-title">{{ heading }}</h3>
  <ng-template *ngIf="headingTemplate" [ngTemplateOutlet]="headingTemplate"></ng-template>
</div>

<div class="modal-body">
  <ng-content></ng-content>
</div>
