import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

// own models
import {IBannerObj} from './models/banner-object.model';
import {IBannerType} from './models/type.model';

@Component({
  selector: 'kpt-banner-ui',
  templateUrl: './banner-ui.component.html',
  styleUrls: ['./banner-ui.component.scss'],
})
export class BannerUIComponent {
  @Input() buttonLabel: string;
  @Input() data?: IBannerObj;
  @Input() staticBannerTitle?: string;
  @Output() staticBannerAction?: EventEmitter<any> = new EventEmitter<any>();
  @Input() type?: IBannerType;

  constructor(private router: Router) {
    this.type = Object.values(IBannerType).includes(this.type) ? this.type : IBannerType.DEFAULT;
  }

  bannerAction(value: string, type: string) {
    if (type === 'external') {
      window.open(value);
    } else {
      this.router.navigate([value]);
    }
  }
}
