<kpt-accordion-container>
  <kpt-accordion-group
    [headingTemplate]="headingTemplate"
    [preventDefault]="true"
    [isOpen]="open"
    [class.content-background]="contentBackground"
  >
    <ng-template #headingTemplate>
      <kpt-switch
        [checked]="open"
        (valueChange)="openChange.emit($event)"
        [label]="label"
      ></kpt-switch>
    </ng-template>

    <ng-content></ng-content>
  </kpt-accordion-group>
</kpt-accordion-container>
