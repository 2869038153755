import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {HelpModule} from '@shared/ui';
import {SwitchFormlyComponent} from '@shared/ui/formly/switch-formly/switch-formly.component';
import {SwitchModule} from '@shared/ui/forms/switch';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';

@NgModule({
  declarations: [SwitchFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SwitchModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    HelpModule,
  ],
  exports: [SwitchFormlyComponent],
})
export class SwitchFormlyModule {}
