<div *ngIf="pagination.pages && pagination.pages.length" class="pagination-holder">
  <kpt-button type="secondary" [label]="label"></kpt-button>
  <nav>
    <ul class="pagination">
      <li *ngIf="prevDots" class="page-item">
        <a class="page-link" (click)="setPage(defaultPage)">
          {{ defaultPage }}
        </a>
      </li>
      <li *ngIf="prevDots" class="page-item">
        <span class="page-dots"> &hellip; </span>
      </li>
      <li
        *ngFor="let page of pagination.pages"
        class="page-item"
        [class.active]="pagination.activePage === page"
      >
        <a class="page-link" (click)="setPage(page)">
          {{ page }}
        </a>
      </li>
      <li *ngIf="nextDots" class="page-item">
        <span class="page-dots"> &hellip; </span>
      </li>
      <li *ngIf="nextDots" class="page-item">
        <a class="page-link" (click)="setPage(pagination.totalPages)">
          {{ pagination.totalPages }}
        </a>
      </li>
    </ul>
  </nav>
  <kpt-select
    *ngIf="hasOptions"
    customFormGroupClass="mb-0 pt-0"
    id="pagination-selectbox"
    [label]="optionsLabel"
    (selected)="changePerPage($event)"
    [options]="perPageOptions"
  ></kpt-select>
</div>
