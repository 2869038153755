import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'kpt-load-from-analysis',
  templateUrl: './load-from-analysis.component.html',
  styleUrls: ['./load-from-analysis.component.scss'],
})
export class LoadFromAnalysisComponent {
  @Output() load = new EventEmitter<void>();
}
