import {HousingDemand} from '@generated/defs/HousingDemand';
import {ANY_FIXATION} from 'src/app/modules/housing/helpers';
import {HousingActions, HousingActionTypes} from '../actions/housing.actions';
import {HousingOutput, LoanRecommendation} from '../models/housing.models';

export interface State {
  input: HousingDemand;
  output: HousingOutput;
}

export const initialState: State = {
  input: {
    intent: null,
    hasAnotherLiability: false,
    hasBuildingSavings: false,
    targetSavings: null,
    fixation: ANY_FIXATION,
    interest: 3.29,
    loan: null,
    savings: null,
    loanTerm: 240,
    value: null,
    purposes: 'house',
  },
  output: {
    monthlyRepayment: 9_000,
    loanRecommendation: LoanRecommendation.Both,
  },
};

export function reducer(state = initialState, action: HousingActions): State {
  switch (action.type) {
    case HousingActionTypes.UpdateInputOutput:
      const input = {...state.input, ...action.payload.input};
      const output = {...state.output, ...action.payload.output};
      return {...state, input, output};

    default:
      return state;
  }
}
