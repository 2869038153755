import {FamilyMember} from '@generated/model';
import {createSelector} from '@ngrx/store';
import {FinancialProductsAsset} from '@shared/analysis/models/financial-products';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {convertToMonthly, filterAssets} from 'src/shared/analysis/asset.utils';
import {ChartColors} from 'src/shared/analysis/components/chart/chart-colors';
import {ChartSizes} from 'src/shared/analysis/components/chart/chart-sizes';
import {
  Chart,
  Column,
  Section,
  Summary,
} from 'src/shared/analysis/components/chart/chart.component';
import {PriorityFulfillmentSources} from 'src/shared/analysis/components/priority-list/priority-fulfillment-sources';
import {Priority} from 'src/shared/analysis/components/priority-list/priority-list.component';
import {calculateMonthlySum} from 'src/shared/analysis/evaluation';
import {
  allInvestmentAssets,
  Asset,
  assetNames,
  AssetType,
  commonExpenseAssets,
  creditAssets,
  financialPropertyAssets,
  immovablePropertyAssets,
  insuranceAssets,
  isFamilyMemberAsset,
  movablePropertyAssets,
  RelatedPropertyAsset,
  ValueAsset,
} from 'src/shared/analysis/models/asset';
import {CommonExpenseAsset} from 'src/shared/analysis/models/common-expenses';
import {CreditAsset, PropertyLoanAsset} from 'src/shared/analysis/models/credit-products';
import {FinancialPropertyAsset} from 'src/shared/analysis/models/financial-properties';
import {
  CapitalLifeInsuranceAsset,
  InsuranceAsset,
  InvestmentLifeInsuranceAsset,
} from 'src/shared/analysis/models/insurance-products';
import {InvestmentAsset} from 'src/shared/analysis/models/investment-products';
import {CommonIncomeAsset} from 'src/shared/analysis/models/main-incomes';
import {ObjectiveType} from 'src/shared/analysis/objectives.helpers';
import {
  applyDispensability,
  applyIndispensability,
  findAssetById,
  getAssetName,
  selectCurrentAssets,
  selectExpensesSum,
  selectFinancialPropertiesSum,
  selectImmovablePropertiesSum,
  selectIncomesAssets,
  selectIncomesSum,
  selectInsurancePropertiesSum,
  selectInvestmentPropertiesSum,
  selectMovablePropertiesSum,
} from 'src/shared/analysis/store';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {Fulfillment, FulfillmentType, Objective} from './objectives.models';
import {selectFinancialPlanObjectives} from './objectives.selectors';

const selectInvestmentAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, allInvestmentAssets),
);

const selectCreditAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, creditAssets),
);

const selectInsuranceAssets = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, insuranceAssets),
);

const selectAllExpenses = createSelector(selectCurrentAssets, assets =>
  filterAssets(assets, [...commonExpenseAssets, AssetType.CommonExpensesSum]),
);

function assetsToSections(assets: Asset[], color: ChartColors): Section[] {
  return (assets as FinancialProductsAsset[]).map(asset => ({
    value: convertToMonthly(asset.value, asset.frequency),
    label: getAssetName(asset),
    color,
  }));
}

function uniteExpenses(assets: Asset[], color: ChartColors): Section {
  const sorted = sortAssetsDescending(
    assets.map(asset => {
      if (asset.type === AssetType.Contractor) {
        return {
          ...asset,
          value: -(
            asset.healthInsuranceValue +
            asset.socialInsuranceValue +
            asset.sicknessInsuranceValue
          ),
        };
      } else return asset;
    }),
  );
  const label =
    sorted.length > 3
      ? sorted
          .slice(0, 3)
          .map(asset => getAssetName(asset))
          .join(', ') + ', ...'
      : sorted.map(asset => getAssetName(asset)).join(', ');
  return {
    value: -calculateMonthlySum(sorted),
    label,
    color,
  };
}

function sortAssetsDescending(assets: Asset[]): Asset[] {
  return assets.sort((a1, a2) => Math.abs((a2 as ValueAsset).value) - (a1 as ValueAsset).value);
}

export const selectIncomeExpenseChart = createSelector(
  getFamilyMembers,
  selectIncomesAssets,
  selectInvestmentAssets,
  selectCreditAssets,
  selectInsuranceAssets,
  selectAllExpenses,
  selectIncomesSum,
  selectExpensesSum,
  (
    familyMembers,
    incomes,
    investments,
    credits,
    insurances,
    expenses,
    incomeSum,
    expenseSum,
  ): Chart => {
    const balance = incomeSum - expenseSum;

    const incomeColorRange = Math.floor(incomes.length / 3);
    const leftSections: Section[] = [
      {
        value: Math.max(-balance, 0),
        empty: true,
      },
      ...incomes
        .filter(inc => (inc as CommonIncomeAsset).value !== 0)
        .map((asset, index) => ({
          value: (asset as CommonIncomeAsset).value,
          label:
            assetNames[asset.type] + getFamilyMemberName(getFamilyMember(asset, familyMembers)),
          color: getColorForIncomeAsset(index, incomeColorRange),
        })),
    ];

    const investmentSections = assetsToSections(
      investments.filter(asset => (asset as InvestmentAsset).value !== 0),
      ChartColors.investmentColor,
    );
    const creditSections = assetsToSections(
      credits.filter(asset => (asset as CreditAsset).value !== 0),
      ChartColors.expenseColor,
    );
    const insuranceSections = assetsToSections(
      insurances.filter(asset => (asset as InsuranceAsset).value !== 0),
      ChartColors.insuranceColor,
    );
    const dispensableExpenseSection = uniteExpenses(
      applyDispensability(expenses).filter(exp => (exp as CommonExpenseAsset).value !== 0),
      ChartColors.dispensableExpenseColor,
    );
    const indispensableExpenseSection = uniteExpenses(
      applyIndispensability(expenses).filter(exp => (exp as CommonExpenseAsset).value !== 0),
      ChartColors.indispensableExpenseColor,
    );

    const rightFirstSection: Section = {
      value: balance,
    };

    if (balance > 0) {
      rightFirstSection.label = 'Volné prostředky';
      rightFirstSection.color = ChartColors.freeAssetColor;
    } else if (balance < 0) {
      rightFirstSection.label = 'Chybějící prostředky';
      rightFirstSection.color = ChartColors.expenseDarkColor;
    }

    const rightSections: Section[] = [
      rightFirstSection,
      ...investmentSections,
      ...creditSections,
      ...insuranceSections,
      dispensableExpenseSection,
      indispensableExpenseSection,
    ];

    const leftSummary = {
      label: 'Celkové příjmy',
      value: incomeSum,
    };

    const rightLegend = {
      legends: [
        {
          label: 'Běžné výdaje',
          categories: [
            {label: 'nezbytné', color: ChartColors.indispensableExpenseColor},
            {label: 'zbytné', color: ChartColors.dispensableExpenseColor},
          ],
        },
        {
          label: 'Finanční produkty a zdroje',
          categories: [
            {label: 'spotřeba', color: ChartColors.expenseColor},
            {label: 'zajištění', color: ChartColors.insuranceColor},
            {label: 'investice', color: ChartColors.investmentColor},
          ],
        },
      ],
    };

    const leftColumn: Column = {
      label: 'Příjmy',
      sections: leftSections,
      footer: leftSummary,
    };

    const rightColumn: Column = {
      label: 'Výdaje a zdroje',
      sections: rightSections,
      footer: rightLegend,
    };

    return {
      leftColumn,
      rightColumn,
      separator: true,
      size: ChartSizes.Large,
    };
  },
);

export function computeAverageAbsoluteFulfillment(
  objectives: Objective[],
  situation: Situation,
): Fulfillment {
  const defaultFulfillment: Fulfillment = {
    absoluteSelected: 0,
    absoluteTotal: 0,
    type: FulfillmentType.Absolute,
  };
  const fulfillment = objectives.reduce((acc, obj) => {
    acc.absoluteSelected += obj.table[situation].fulfillment.absoluteSelected;
    acc.absoluteTotal += obj.table[situation].fulfillment.absoluteTotal;
    return acc;
  }, defaultFulfillment);

  fulfillment.percent = fulfillment.absoluteSelected / fulfillment.absoluteTotal;

  return fulfillment;
}

export const selectExpensesAndPrioritiesChart = ({showNewDashboard}: {showNewDashboard: boolean}) =>
  createSelector(
    selectInvestmentAssets,
    selectCreditAssets,
    selectInsuranceAssets,
    selectAllExpenses,
    selectIncomesSum,
    selectExpensesSum,
    selectFinancialPlanObjectives({showNewDashboard}),
    (investments, credits, insurances, expenses, incomeSum, expenseSum, objectives): Chart => {
      const balance = incomeSum - expenseSum;

      const familyAndPropertyObjectives = [
        objectives[ObjectiveType.Family],
        objectives[ObjectiveType.Property],
        objectives[ObjectiveType.Vehicle],
      ].filter(objectiveGroup => objectiveGroup.length > 0);

      const otherObjectives = [
        ...objectives[ObjectiveType.Pension],
        ...objectives[ObjectiveType.FinancialResources],
        ...objectives[ObjectiveType.Independence],
        ...objectives[ObjectiveType.Housing],
        ...objectives[ObjectiveType.Reserve],
      ];

      let count = 1;
      const leftPriorities: Priority[] = [
        ...familyAndPropertyObjectives.map(objectiveGroup => ({
          index: count++,
          label: assetNames[objectiveGroup[0].objectiveAsset.type],
          prioritySource: objectiveGroup[0].table.current.rows.length
            ? PriorityFulfillmentSources.Expenses
            : PriorityFulfillmentSources.None,
          fulfillment: computeAverageAbsoluteFulfillment(objectiveGroup, Situation.Current),
          uuid: objectiveGroup[0].objectiveAsset.assetUuid,
        })),

        ...otherObjectives.map(obj => ({
          index: count++,
          label: assetNames[obj.objectiveAsset.type],
          prioritySource: obj.table.current.rows.length
            ? PriorityFulfillmentSources.Expenses
            : PriorityFulfillmentSources.None,
          fulfillment: obj.table.current.rows.length
            ? obj.table.current.fulfillment
            : {
                type: FulfillmentType.Percent,
                percent: 0,
              },
          uuid: obj.objectiveAsset.assetUuid,
        })),
      ];

      const investmentSections = assetsToSections(
        investments.filter(asset => (asset as CommonExpenseAsset).value !== 0),
        ChartColors.investmentColor,
      );
      const creditSections = assetsToSections(
        credits.filter(asset => (asset as CommonExpenseAsset).value !== 0),
        ChartColors.expenseColor,
      );
      const insuranceSections = assetsToSections(
        insurances.filter(asset => (asset as CommonExpenseAsset).value !== 0),
        ChartColors.insuranceColor,
      );
      const dispensableExpenseSection = uniteExpenses(
        applyDispensability(expenses).filter(exp => (exp as CommonExpenseAsset).value !== 0),
        ChartColors.dispensableExpenseColor,
      );
      const indispensableExpenseSection = uniteExpenses(
        applyIndispensability(expenses).filter(exp => (exp as CommonExpenseAsset).value !== 0),
        ChartColors.indispensableExpenseColor,
      );

      const rightFirstSection: Section = {
        value: balance,
      };

      if (balance > 0) {
        rightFirstSection.label = 'Volné prostředky';
        rightFirstSection.color = ChartColors.freeAssetColor;
      } else if (balance < 0) {
        rightFirstSection.label = 'Chybějící prostředky';
        rightFirstSection.color = ChartColors.expenseDarkColor;
      }

      const rightSections = [
        rightFirstSection,
        ...investmentSections,
        ...creditSections,
        ...insuranceSections,
        dispensableExpenseSection,
        indispensableExpenseSection,
      ];

      const leftLegend = {
        legends: [
          {
            label: 'Plnění priorit',
            categories: [
              {label: 'plněno z výdajů', color: ChartColors.freeAssetLightColor},
              {label: 'plněno majetkem', color: ChartColors.investmentColor},
              {label: 'neplněno', color: ChartColors.expenseDarkColor},
            ],
          },
        ],
      };

      const rightLegend = {
        legends: [
          {
            label: 'Běžné výdaje',
            categories: [
              {label: 'nezbytné', color: ChartColors.indispensableExpenseColor},
              {label: 'zbytné', color: ChartColors.dispensableExpenseColor},
            ],
          },
          {
            label: 'Finanční produkty a zdroje',
            categories: [
              {label: 'spotřeba', color: ChartColors.expenseColor},
              {label: 'zajištění', color: ChartColors.insuranceColor},
              {label: 'investice', color: ChartColors.investmentColor},
            ],
          },
        ],
      };

      const leftColumn: Column = {
        label: 'Vaše priority',
        priorities: leftPriorities,
        footer: leftLegend,
      };

      const rightColumn: Column = {
        label: 'Výdaje a zdroje',
        sections: rightSections,
        footer: rightLegend,
      };

      return {
        leftColumn,
        rightColumn,
        size: ChartSizes.Large,
      };
    },
  );

function createNonMortgagedSection(property: Asset, value: number): Section {
  return {
    label: getAssetName(property),
    value,
    color: ChartColors.freeAssetColor,
  };
}

export const selectPropertyChart = createSelector(
  selectCurrentAssets,
  selectImmovablePropertiesSum,
  selectMovablePropertiesSum,
  selectInsurancePropertiesSum,
  selectFinancialPropertiesSum,
  selectInvestmentPropertiesSum,
  (assets, immovableSum, movableSum, insuranceSum, financialSum, investmentSum): Chart => {
    const investmentSections = filterAssets(assets, allInvestmentAssets).map(property =>
      createNonMortgagedSection(property, (property as InvestmentAsset).presentValue),
    );

    const financialSections = filterAssets(assets, financialPropertyAssets).map(property =>
      createNonMortgagedSection(property, (property as FinancialPropertyAsset).value),
    );

    const insuranceSections = [
      ...filterAssets(assets, [AssetType.InvestmentLifeInsurance]).map(property =>
        createNonMortgagedSection(
          property,
          (property as InvestmentLifeInsuranceAsset).presentValue,
        ),
      ),
      ...filterAssets(assets, [AssetType.CapitalLifeInsurance]).map(property =>
        createNonMortgagedSection(property, (property as CapitalLifeInsuranceAsset).presentValue),
      ),
    ];

    const nonMortgagedProperties = filterAssets(assets, [
      ...immovablePropertyAssets,
      ...movablePropertyAssets,
    ]);

    const creditProperties = filterAssets(assets, creditAssets);

    const usedCreditProperties: CreditAsset[] = [];
    const otherCreditProperties: CreditAsset[] = [];

    const mortgaged = creditProperties
      .map(credit => {
        const property = findAssetById(
          nonMortgagedProperties,
          (credit as RelatedPropertyAsset).relatedPropertyUuid,
        );
        if (property && (credit as CreditAsset).outstandingValue !== 0) {
          nonMortgagedProperties.splice(nonMortgagedProperties.indexOf(property), 1);
          usedCreditProperties.push(credit as CreditAsset);
          return {property, credit};
        } else {
          otherCreditProperties.push(credit as CreditAsset);
        }
      })
      .filter(property => property);

    const nonMortgagedSections = nonMortgagedProperties.map(property => ({
      label: getAssetName(property),
      value: (property as ValueAsset).value,
      color: ChartColors.freeAssetColor,
    }));

    const mortgagedSections = mortgaged.map(relatedPair => {
      const property = relatedPair.property;
      const credit = relatedPair.credit;
      const propertyValue =
        (credit as PropertyLoanAsset).originalValue || (property as ValueAsset).value;
      const creditRemainingValue = (credit as CreditAsset).outstandingValue;
      return {
        label: getAssetName(property),
        value: propertyValue,
        color: ChartColors.expenseDarkColor,
        highlightPercent: 1 - creditRemainingValue / propertyValue,
        colorPercent: ChartColors.expenseColor,
      };
    });

    const otherCreditSections = otherCreditProperties.map(property => ({
      label: getAssetName(property),
      value: property.outstandingValue,
      color: ChartColors.expenseDarkColor,
    }));

    const rightSections = [
      ...investmentSections,
      ...financialSections,
      ...insuranceSections,
      ...nonMortgagedSections,
      ...mortgagedSections,
      ...otherCreditSections,
    ];

    const usedCreditPropertiesValue = sumCreditOwedValue(usedCreditProperties);

    const otherCreditPropertiesValue = sumCreditOwedValue(otherCreditProperties);

    const owedValue = usedCreditPropertiesValue + otherCreditPropertiesValue;

    const sum =
      immovableSum +
      movableSum +
      insuranceSum +
      financialSum +
      investmentSum +
      otherCreditPropertiesValue;

    const leftSectionsCount = [
      movableSum,
      investmentSum + insuranceSum + financialSum,
      otherCreditPropertiesValue,
      immovableSum,
    ].reduce((acc, cur) => acc + (cur ? 1 : 0), 0);
    const propertyColorRange = Math.floor(leftSectionsCount / 3);

    const leftSections = [
      {
        label: assetNames[AssetType.MovablePropertiesSum],
        value: movableSum,
        color: getColorForIncomeAsset(0, propertyColorRange),
        showPercent: true,
      },
      {
        label: assetNames[AssetType.InvestmentPropertiesSum],
        value: investmentSum + insuranceSum + financialSum,
        color: getColorForIncomeAsset(1, propertyColorRange),
        showPercent: true,
      },
      {
        label: assetNames[AssetType.CreditPropertiesSum],
        value: otherCreditPropertiesValue,
        color: getColorForIncomeAsset(2, propertyColorRange),
        showPercent: true,
      },
      {
        label: assetNames[AssetType.ImmovablePropertiesSum],
        value: immovableSum,
        color: getColorForIncomeAsset(3, propertyColorRange),
        showPercent: true,
      },
    ];

    const ownedSummary: Summary = {
      label: 'Celkový majetek po odečtení závazků',
      value: sum - owedValue,
    };

    const totalSummary: Summary = {
      label: 'Aktuální hodnota celkového majetku',
      value: sum,
    };

    const leftColumn: Column = {
      label: 'Celkový majetek',
      sections: leftSections,
      footer: owedValue ? {...totalSummary, alignment: 'right'} : null,
    };

    const rightColumn: Column = {
      label: 'Bilance majetku',
      sections: rightSections,
      footer: owedValue ? {...ownedSummary, alignment: 'left'} : null,
    };

    return {
      leftColumn,
      rightColumn,
      summary: owedValue ? null : totalSummary,
      size: ChartSizes.Large,
    };
  },
);

function sumCreditOwedValue(assets: Asset[]) {
  return assets.reduce((acc, cur) => acc + (cur as CreditAsset).outstandingValue, 0);
}

function getFamilyMember(asset: Asset, familyMembers: FamilyMember[]): FamilyMember {
  return isFamilyMemberAsset(asset) && asset.familyMemberUuid
    ? familyMembers.find(member => member.sugarUuid === asset.familyMemberUuid)
    : null;
}

function getFamilyMemberName(familyMember: FamilyMember): string {
  return familyMember ? ' ' + familyMember.firstName : '';
}

function getColorForIncomeAsset(index: number, incomeColorRange: number): ChartColors {
  return ++index <= incomeColorRange
    ? ChartColors.incomeDarkColor
    : index <= 2 * incomeColorRange
    ? ChartColors.incomeColor
    : ChartColors.incomeLightColor;
}
