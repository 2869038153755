import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {Asset, AssetType, FamilyMemberAsset} from '@shared/analysis/models/asset';
import {BuildingSavingsLoanAsset, MortgageAsset} from '@shared/analysis/models/credit-products';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {ChildrenTaxAdvantageAsset} from '@shared/analysis/models/income-attributes';
import {
  CapitalLifeInsuranceAsset,
  InvestmentLifeInsuranceAsset,
  RiskLifeInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {selectAllAssets} from '@shared/analysis/store';
import {cloneDeep, pull, reject} from 'lodash';
import {take} from 'rxjs/operators';
import {State} from 'src/store';

type RemoveOperation = (familyMemberUuid: string, assetType: AssetType, assets: Asset[]) => void;

@Injectable()
export class RemoveRelatedDataService {
  constructor(private store: Store<State>, private assetsHandlerService: AssetsHandlerService) {}

  removeRelateDataOfFamilyMember(familyMemberUuid: string) {
    this.store.pipe(select(selectAllAssets), take(1)).subscribe(assets => {
      this.removeRelatedData(familyMemberUuid, assets);
    });
  }

  private removeRelatedData(familyMemberUuid: string, assets: Asset[]) {
    Object.entries(this.getRelatedAssets()).forEach(
      ([assetType, removeOperations]: [AssetType, RemoveOperation[]]) => {
        removeOperations.forEach(removeOperation => {
          try {
            removeOperation(familyMemberUuid, assetType, assets);
          } catch (e) {
            console.error(
              `Failed to execute a remove operation for asset type` +
                ` ${assetType} and family member ${familyMemberUuid}`,
            );
          }
        });
      },
    );
  }

  private getRelatedAssets(): Record<AssetType, RemoveOperation[]> {
    return {
      // Sandbox Assets
      [AssetType.SandboxCurrentBalance]: [],
      [AssetType.SandboxIncome]: [],
      [AssetType.SandboxExpenses]: [],
      [AssetType.SandboxUnitTrust]: [],
      [AssetType.SandboxHousePurchase]: [],
      [AssetType.SandboxMortgage]: [],
      [AssetType.SandboxCarPurchase]: [],
      [AssetType.SandboxConsumerLoan]: [],
      [AssetType.SandboxLossOfWork]: [],
      [AssetType.SandboxInheritance]: [],

      // Objectives
      [AssetType.OwnHousing]: [],
      [AssetType.ChildrenFuture]: [],
      [AssetType.Retirement]: [this.removeByFamilyMember.bind(this)],
      [AssetType.FinancialIndependence]: [],
      [AssetType.ShortTermReserve]: [],
      [AssetType.LongTermReserve]: [],
      [AssetType.BadDebtsRepayment]: [],
      [AssetType.FamilyProvision]: [],
      [AssetType.PropertyProvision]: [],
      [AssetType.PropertyRequirements]: [],
      [AssetType.VehicleProvision]: [],
      [AssetType.VehicleRequirements]: [],
      [AssetType.ChildBirth]: [],
      [AssetType.NewHousing]: [],
      [AssetType.Reconstruction]: [],
      [AssetType.Furnishings]: [],
      [AssetType.Car]: [],
      [AssetType.Vacation]: [],
      [AssetType.OtherObjective]: [],

      // Common Expenses
      [AssetType.CommonExpensesSum]: [],
      [AssetType.Food]: [],
      [AssetType.HousingExpenses]: [],
      [AssetType.Drugstore]: [],
      [AssetType.Transport]: [],
      [AssetType.Communication]: [],
      [AssetType.Clothes]: [],
      [AssetType.Hobbies]: [],
      [AssetType.Vacations]: [],
      [AssetType.ChildrenExpenses]: [this.removeByFamilyMember.bind(this)],
      [AssetType.OtherCommonExpenses]: [],
      [AssetType.ContractorInsurance]: [],

      // Main Incomes
      [AssetType.Employment]: [this.removeByFamilyMember.bind(this)],
      [AssetType.Contractor]: [this.removeByFamilyMember.bind(this)],
      [AssetType.FinancialOutlook]: [],
      [AssetType.ChildrenTaxAdvantage]: [this.removeTaxAdvantage.bind(this)],

      // Other Incomes
      [AssetType.Maternity]: [this.removeByFamilyMember.bind(this)],
      [AssetType.Parental]: [this.removeByFamilyMember.bind(this)],
      [AssetType.UnemploymentBenefits]: [this.removeByFamilyMember.bind(this)],
      [AssetType.PensionIncome]: [this.removeByFamilyMember.bind(this)],
      [AssetType.OtherPensionIncome]: [this.removeByFamilyMember.bind(this)],
      [AssetType.RentalIncome]: [this.removeByFamilyMember.bind(this)],
      [AssetType.Dividends]: [this.removeByFamilyMember.bind(this)],
      [AssetType.OtherPassiveIncome]: [this.removeByFamilyMember.bind(this)],

      // Financial Products - Insurance
      [AssetType.InsuranceProductsSum]: [],
      [AssetType.InvestmentLifeInsurance]: [
        this.removeInsuredPerson.bind(this),
        this.removeByStakeholder.bind(this),
      ],
      [AssetType.RiskLifeInsurance]: [
        this.removeInsuredPerson.bind(this),
        this.removeByStakeholder.bind(this),
      ],
      [AssetType.CapitalLifeInsurance]: [
        this.removeInsuredPerson.bind(this),
        this.removeByStakeholder.bind(this),
      ],
      [AssetType.PropertyInsurance]: [this.removeByStakeholder.bind(this)],
      [AssetType.LiabilityInsurance]: [this.removeByStakeholder.bind(this)],
      [AssetType.VehicleInsurance]: [this.removeByStakeholder.bind(this)],
      [AssetType.TravelInsurance]: [this.removeByStakeholder.bind(this)],
      [AssetType.BusinessInsurance]: [this.removeByStakeholder.bind(this)],

      // Financial Products - Investment
      [AssetType.InvestmentProductsSum]: [],
      [AssetType.UnitTrust]: [this.removeByStakeholder.bind(this)],
      [AssetType.RealEstateFund]: [this.removeByStakeholder.bind(this)],
      [AssetType.Commodity]: [this.removeByStakeholder.bind(this)],
      [AssetType.BuildingSavings]: [this.removeByStakeholder.bind(this)],
      [AssetType.SupplementaryPensionSavings]: [this.removeByStakeholder.bind(this)],
      [AssetType.PensionInsurance]: [this.removeByStakeholder.bind(this)],
      [AssetType.SavingsAccount]: [this.removeByStakeholder.bind(this)],
      [AssetType.ExchangeTradedFunds]: [this.removeByStakeholder.bind(this)],
      [AssetType.Certificates]: [this.removeByStakeholder.bind(this)],
      [AssetType.CombinedDeposits]: [this.removeByStakeholder.bind(this)],

      // Financial Products - Credit
      [AssetType.CreditProductsSum]: [],
      [AssetType.Mortgage]: [this.removeDebtors.bind(this), this.removeByStakeholder.bind(this)],
      [AssetType.BuildingSavingsLoan]: [
        this.removeDebtors.bind(this),
        this.removeByStakeholder.bind(this),
      ],
      [AssetType.Leasing]: [this.removeByStakeholder.bind(this)],
      [AssetType.CreditCard]: [this.removeByStakeholder.bind(this)],
      [AssetType.Overdraft]: [this.removeByStakeholder.bind(this)],
      [AssetType.ConsumerLoan]: [this.removeByStakeholder.bind(this)],
      [AssetType.OtherIndividualLoan]: [this.removeByStakeholder.bind(this)],

      // Immovable Properties
      [AssetType.ImmovablePropertiesSum]: [],
      [AssetType.Flat]: [],
      [AssetType.House]: [],
      [AssetType.Household]: [],
      [AssetType.HolidayProperty]: [],
      [AssetType.ApartmentBuilding]: [],
      [AssetType.Garage]: [],
      [AssetType.Land]: [],
      [AssetType.OtherImmovableProperty]: [],

      // Movable Properties
      [AssetType.MovablePropertiesSum]: [],
      [AssetType.Vehicle]: [],
      [AssetType.Valuables]: [],
      [AssetType.SpecialValuables]: [],

      // Financial Properties
      [AssetType.FinancialPropertiesSum]: [],
      [AssetType.CurrentAccount]: [],
      [AssetType.TermDeposit]: [this.removeByStakeholder.bind(this)],
      [AssetType.Bonds]: [this.removeByStakeholder.bind(this)],
      [AssetType.QualifiedInvestorFunds]: [this.removeByStakeholder.bind(this)],
      [AssetType.OtherFinancialProperty]: [],

      // Other Assets
      [AssetType.CurrentAccountUsage]: [],

      // Properties Sums
      [AssetType.InvestmentPropertiesSum]: [],
      [AssetType.CreditPropertiesSum]: [],
      [AssetType.InsurancePropertiesSum]: [],

      // Virtual Assets
      [AssetType.VirtualMonthlyBalance]: [],
    };
  }

  private removeByFamilyMember(familyMemberUuid: string, assetType: AssetType, assets: Asset[]) {
    assets
      .filter(asset => asset.type === assetType)
      .filter(asset => (asset as FamilyMemberAsset).familyMemberUuid === familyMemberUuid)
      .forEach(asset => {
        this.assetsHandlerService.removeAsset(asset.assetUuid);
      });
  }

  private removeByStakeholder(familyMemberUuid: string, assetType: AssetType, assets: Asset[]) {
    assets
      .filter(asset => asset.type === assetType)
      .filter(asset => (asset as StakeholderAsset).stakeholderUuid === familyMemberUuid)
      .forEach(asset => {
        this.assetsHandlerService.removeAsset(asset.assetUuid);
      });
  }

  private removeTaxAdvantage(familyMemberUuid: string, assetType: AssetType, assets: Asset[]) {
    assets
      .filter(asset => asset.type === assetType)
      .forEach(asset => {
        const taxAdvantageAsset = cloneDeep(asset) as ChildrenTaxAdvantageAsset;
        taxAdvantageAsset.persons = reject(
          taxAdvantageAsset.persons,
          person => person.familyMemberUuid === familyMemberUuid,
        );
        taxAdvantageAsset.persons.forEach(person => {
          person.childrenUuids = pull(person.childrenUuids, familyMemberUuid);
        });
        this.assetsHandlerService.updateAsset(taxAdvantageAsset);
      });
  }

  private removeDebtors(familyMemberUuid: string, assetType: AssetType, assets: Asset[]) {
    assets
      .filter(asset => asset.type === assetType)
      .forEach(asset => {
        const propertyLoanAsset = cloneDeep(asset) as MortgageAsset | BuildingSavingsLoanAsset;
        propertyLoanAsset.debtorUuids = pull(propertyLoanAsset.debtorUuids, familyMemberUuid);
        this.assetsHandlerService.updateAsset(propertyLoanAsset);
      });
  }

  private removeInsuredPerson(familyMemberUuid: string, assetType: AssetType, assets: Asset[]) {
    assets
      .filter(asset => asset.type === assetType)
      .forEach(asset => {
        const propertyLoanAsset = cloneDeep(asset) as
          | RiskLifeInsuranceAsset
          | InvestmentLifeInsuranceAsset
          | CapitalLifeInsuranceAsset;
        propertyLoanAsset.insuredPersons = reject(
          propertyLoanAsset.insuredPersons,
          insuredPerson => insuredPerson.insuredPersonUuid === familyMemberUuid,
        );
        this.assetsHandlerService.updateAsset(propertyLoanAsset);
      });
  }
}
