import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {NotificationService} from '@lib/services/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectAdultFamilyMembers} from '@shared/analysis/operators';
import {FAMILY_ID} from '@shared/constants';
import {InvestmentSurveyDataService} from '@shared/investment-survey-old/investment-survey-data.service';
import {InvestmentSurveyUtilsService} from '@shared/investment-survey-old/investment-survey-utils.service';
import {
  hasInvestmentPortfolioInconsistency,
  hasInvestmentPurposeInconsistency,
  hasLifeInsurancePortfolioInconsistency,
} from '@shared/investment-survey-old/investment-survey.utils';
import {Answers, emptyAnswers} from '@shared/investment-survey-old/models/answer';
import {
  AdditionalAnswerPart,
  AdditionalAnswerType,
  Answer,
  Question,
  QuestionCategory,
  QuestionsOrigin,
  QuestionType,
} from '@shared/investment-survey-old/models/question';
import {LastUpdated, SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {InvestmentSurveyActions, selectAllSurveyAnswers} from '@shared/investment-survey-old/store';
import {DialogService} from '@shared/services/modal.service';
import {IOption} from '@shared/ui';
import {SurveyParticipant} from '@shared/ui/survey/survey-participants/survey-participants.component';
import {flatMap, get, isEmpty, isEqual, isFunction, pick, pickBy} from 'lodash';
import * as moment from 'moment';
import {combineLatest, Observable, of} from 'rxjs';
import {delay, distinctUntilChanged, filter, map, skip, take} from 'rxjs/operators';
import {State} from 'src/store';
import {InconsistencyWarningModalComponent} from './inconsistency-warning/inconsistency-warning-modal.component';

interface CheckboxGroup {
  [key: string]: boolean;
}

interface TableCheckboxGroup {
  [key: string]: CheckboxGroup;
}

@UntilDestroy()
@Component({
  selector: 'kpt-investment-survey',
  templateUrl: './investment-survey.component.html',
  styleUrls: ['./investment-survey.component.scss'],
})
export class InvestmentSurveyComponent implements OnInit, OnDestroy {
  questionCategories: QuestionCategory[];
  personalizedGraphQuestions: Record<string, Question>;

  @Input()
  checkFinished = false;

  @Input()
  questionsOrigin = QuestionsOrigin.FINANCIAL_ANALYSIS;

  @Input()
  showMultipleParticipants = false;

  form = new UntypedFormGroup({});

  @Input()
  showSteps = true;

  @Input()
  participantsHideState: Observable<{[key: string]: boolean}>;

  @Output()
  evaluateAnswers = new EventEmitter<SurveyAnswers[]>();

  @Input()
  showFinishButton = true;

  activeStep = 0;

  finishedSteps: {[key: string]: boolean} = {};

  QuestionType = QuestionType;
  AdditionalAnswerType = AdditionalAnswerType;

  participantsForm = new UntypedFormGroup({});

  participants: SurveyParticipant[] = [];
  radioOptions: {[key: string]: IOption[]} = {};

  private surveyAnswers: SurveyAnswers[];
  private valueChangesRegistered = false;
  private valueChangesDisabled = false;

  constructor(
    private store: Store<State>,
    private questions: Questions,
    private investmentSurveyService: InvestmentSurveyDataService,
    private dialogService: DialogService,
    private investmentSurveyUtilsService: InvestmentSurveyUtilsService,
    private notificationService: NotificationService,
    @Inject(FAMILY_ID) private familyId: string,
  ) {}

  ngOnInit() {
    this.investmentSurveyService.loadDataAndSaveChanges(this.familyId);

    this.questionCategories = this.questions.allQuestionsCategories(this.questionsOrigin);

    combineLatest([
      this.participantsHideState ? this.participantsHideState : of({}),
      this.store.pipe(selectAdultFamilyMembers(), take(1)),
    ])
      .pipe(
        filter(
          ([hideState, _]) =>
            !this.participantsHideState ||
            (this.participantsHideState && Object.keys(hideState).length > 0),
        ),
        map(([hideState, members]) => {
          return this.mapToSurveyParticipants(members, hideState);
        }),
        untilDestroyed(this),
      )
      .subscribe(participants => {
        this.participants = participants;

        this.prepareRadioOptions();
        this.participants.forEach(p => {
          this.participantsForm.addControl(p.sugarUuid, new UntypedFormControl(p.participate));
          if (!p.disabled) this.form.addControl(p.sugarUuid, this.createSurveyForm());
        });

        this.setFormChecking();
      });

    this.initData();
  }

  ngOnDestroy() {
    this.investmentSurveyService.stop();
  }

  resolve() {
    if (Object.values(this.finishedSteps).every(Boolean))
      this.evaluateAnswers.emit(this.getActiveSurveyAnswers());
  }

  getActiveParticipants(): SurveyParticipant[] {
    return this.participants.filter(p => p.participate && !p.disabled);
  }

  checkConsistency(nextStep: number) {
    this.checkPortfolioConsistency(nextStep);
    this.checkInvestmentPurposeConsistency(nextStep);
  }

  checkPortfolioConsistency(nextStep: number) {
    if (
      nextStep === this.activeStep &&
      nextStep === 8 &&
      (this.questionsOrigin === QuestionsOrigin.INVESTMENT ||
        QuestionsOrigin.INVESTMENT_ALL ||
        this.questionsOrigin === QuestionsOrigin.LIFE_INSURANCE)
    ) {
      const inconsistentPersonNames: string[] = [];
      this.getActiveParticipants().forEach(p => {
        const survey = this.surveyAnswers.find(a => a.sugarUuid === p.sugarUuid);
        const answers = survey.answers;
        if (
          this.questionsOrigin === QuestionsOrigin.INVESTMENT ||
          this.questionsOrigin === QuestionsOrigin.INVESTMENT_ALL
        ) {
          if (hasInvestmentPortfolioInconsistency(answers)) {
            inconsistentPersonNames.push(p.name);
          }
        } else if (this.questionsOrigin === QuestionsOrigin.LIFE_INSURANCE) {
          if (hasLifeInsurancePortfolioInconsistency(answers)) {
            inconsistentPersonNames.push(p.name);
          }
        }
      });
      if (inconsistentPersonNames.length > 0) {
        const message = `POZOR, odpovědi klienta ${inconsistentPersonNames.join(' a ')}
        u&nbsp;otázky č.&nbsp;7 (preference poměru výnos/riziko) a u&nbsp;otázky č.&nbsp;8
        (preference vzorového portfolia s&nbsp;poměrem výnos/ztráta) jsou ve&nbsp;vzájemném rozporu.
        Toto může být způsobené neporozuměním otázkám nebo překlepem. Přejete si přesto pokračovat?`;
        this.openInconsistencyWarningModal(message, 6);
      }
    }
  }

  checkInvestmentPurposeConsistency(nextStep: number) {
    if (
      nextStep === this.activeStep &&
      nextStep === 10 &&
      this.questionsOrigin === QuestionsOrigin.INVESTMENT_ALL
    ) {
      const inconsistentPersonNames: string[] = [];
      this.getActiveParticipants().forEach(p => {
        const survey = this.surveyAnswers.find(a => a.sugarUuid === p.sugarUuid);
        const answers = survey.answers;
        if (hasInvestmentPurposeInconsistency(answers)) {
          inconsistentPersonNames.push(p.name);
        }
      });
      if (inconsistentPersonNames.length > 0) {
        const message = `POZOR, odpovědi klienta ${inconsistentPersonNames.join(' a ')}
        u&nbsp;otázky č.&nbsp;6 (investiční horizont) a u&nbsp;otázky č.&nbsp;10 (účel investice)
        jsou ve&nbsp;vzájemném rozporu. Toto může být způsobené neporozuměním otázkám nebo překlepem.
        Přejete si přesto pokračovat?`;
        this.openInconsistencyWarningModal(message, 9);
      }
    }
  }

  validateAnswers(surveyAnswers: SurveyAnswers[]) {
    this.validateEsgDistribution(surveyAnswers);
  }

  validateEsgDistribution(surveyAnswers: any) {
    let showWarning = false;
    if (this.getQuestionId(this.activeStep) === 'esgDistribution') {
      for (const answers of surveyAnswers) {
        if (
          answers.answers.esgDistribution.environment &&
          answers.answers.esgDistribution.social &&
          answers.answers.esgDistribution.environmentPercent +
            answers.answers.esgDistribution.socialPercent >
            100
        ) {
          answers.answers.esgDistribution.socialPercent =
            100 - answers.answers.esgDistribution.environmentPercent || null;
          showWarning = true;
        }
      }
    }
    if (showWarning) {
      this.notificationService.dispatchWarning(
        'Součet environmentální a sociální složky musí činit maximálně 100 %.',
      );
    }
  }

  questionRowsStyle(question: Question): string {
    let rowCount = 10;
    switch (question.type) {
      case QuestionType.TABLE:
        rowCount = question.rows.length + 2;
        break;
      case QuestionType.CHECKBOX:
        rowCount = question.answers.length + 1;
        break;
      case QuestionType.RADIO:
        rowCount = this.radioOptions[question.id].length + 1;
        break;
    }
    return `repeat(${rowCount}, auto)`;
  }

  questionColumnsStyle(): string {
    const columnCount: number = this.getActiveParticipants().length + 1;
    return `repeat(${columnCount}, ${99 / Math.min(columnCount, 4)}%)`;
  }

  private openInconsistencyWarningModal(message: string, backToStep: number) {
    this.dialogService
      .load(InconsistencyWarningModalComponent, {message}, {size: 'sm'})
      .then((goBack: {goBack: boolean}) => {
        if (goBack.goBack) this.activeStep = backToStep;
      });
  }

  private prepareRadioOptions() {
    let radioOptions: {[key: string]: IOption[]} = {};
    this.questionCategories.forEach(questionCategory => {
      questionCategory.questions
        .filter(question => question.type === QuestionType.RADIO)
        .forEach(question => {
          radioOptions = {
            ...radioOptions,
            [question.id]: question.answers.map(answer => ({label: answer.text, key: answer.id})),
          };
        });
    });
    this.radioOptions = radioOptions;
  }

  private populateForm(surveyAnswers: SurveyAnswers[]) {
    try {
      this.valueChangesDisabled = true;
      this.participants
        .filter(
          p =>
            !surveyAnswers.some(s => s.sugarUuid === p.sugarUuid) && this.showMultipleParticipants,
        )
        .reverse()
        .forEach(participant => {
          if (this.participants.filter(p => p.participate).length > 1) {
            participant.participate = false;
            this.participantsForm.get(participant.sugarUuid).setValue(false);
            this.form.removeControl(participant.sugarUuid);
          }
        });
      if (this.participants.filter(p => p.participate).length === 0) {
        const participant = this.participants.find(p =>
          this.showMultipleParticipants ? p.participate : p.familyHead,
        );
        participant.participate = true;
        this.participantsForm.get(participant.sugarUuid).setValue(true);
        this.form.addControl(participant.sugarUuid, this.createSurveyForm());
        this.setFormChecking();
      }
      surveyAnswers.forEach(survey => {
        if (
          !this.participants.some(
            participant => participant.sugarUuid === survey.sugarUuid && participant.participate,
          )
        )
          return;

        const pickedAnswers = pickBy({...emptyAnswers(), ...survey.answers});
        const toPatch: any = {};
        for (const k of Object.keys(pickedAnswers)) {
          if (typeof pickedAnswers[k] === 'string') {
            toPatch[k] = {answer: pickedAnswers[k]};
          } else toPatch[k] = pickedAnswers[k];
        }
        this.form.get(survey.sugarUuid).patchValue(toPatch);
      });
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.valueChangesDisabled = false;
    }
  }

  private initData() {
    this.store
      .pipe(select(selectAllSurveyAnswers), skip(1), untilDestroyed(this))
      .subscribe(surveyAnswers => {
        this.surveyAnswers = surveyAnswers;
        if (this.participants.filter(p => p.participate).length === 0) return;
        this.populateForm(surveyAnswers);
        if (!this.valueChangesRegistered) {
          this.valueChangesRegistered = true;
          this.registerValueChanges();
          this.registerParticipantsValueChanges();
          this.personalizeGraphQuestions();
          this.filterQuestions(surveyAnswers);
        }
        if (this.activeStep === 0 && surveyAnswers.length > 0 && this.checkFinished) {
          this.checkFinished = false;
          this.resolve();
        }
      });
  }

  private getActiveSurveyAnswers(): SurveyAnswers[] {
    return this.surveyAnswers.filter(answers =>
      this.getActiveParticipants().some(
        participant => participant.sugarUuid === answers.sugarUuid && participant.participate,
      ),
    );
  }

  private registerParticipantsValueChanges() {
    this.participantsForm.valueChanges.pipe(untilDestroyed(this)).subscribe(participants => {
      Object.entries(participants).forEach(([sugarUuid, participate]) => {
        this.participants.find(p => p.sugarUuid === sugarUuid).participate = participate as boolean;
      });
      this.participants.forEach(p => {
        if (this.form.contains(p.sugarUuid) && !p.participate) {
          this.form.removeControl(p.sugarUuid);
        } else if (!this.form.contains(p.sugarUuid) && p.participate) {
          this.form.addControl(p.sugarUuid, this.createSurveyForm());
          this.activeStep = 0;
        }
      });
      this.checkFinished = false;
      this.setFormChecking();
      this.updateFinishSteps();
    });
  }

  private mapToSurveyParticipants(
    persons: FamilyMember[],
    hideState: {[key: string]: boolean},
  ): SurveyParticipant[] {
    return persons
      .sort(person => (person.familyHead ? -1 : 1))
      .filter(person => this.showMultipleParticipants || person.familyHead)
      .map(person => {
        return {
          sugarUuid: person.sugarUuid,
          name: `${person.firstName} ${person.lastName}`,
          participate: !hideState[person.sugarUuid],
          disabled: hideState[person.sugarUuid],
          familyHead: person.familyHead,
        } as SurveyParticipant;
      });
  }

  private updateFinishSteps() {
    this.iterateThroughCriteria(
      (sugarUuid: string, categoryId: string, categoryControl: AbstractControl) =>
        this.processForms(sugarUuid, categoryId, categoryControl.value),
    );
  }

  private processForms(sugarUuid: string, categoryId: string, value: any) {
    const question = this.questions.questionByQuestionId(categoryId, this.questionsOrigin);
    if (isFunction(question.hidden) && question.hidden(this.surveyAnswers)) {
      this.finishedSteps[question.id] = true;
    } else {
      switch (question.type) {
        case QuestionType.RADIO:
          this.processRadioButton(sugarUuid, categoryId, value.answer);
          break;
        case QuestionType.CHECKBOX:
          this.processCheckboxGroup(sugarUuid, categoryId, value);
          break;
        case QuestionType.TABLE:
          this.processTableCheckboxGroup(sugarUuid, categoryId, value);
          break;
        case QuestionType.SLIDER:
          this.processSlider(sugarUuid, categoryId, value.answer);
      }
    }
  }

  private convertFormValuesToAnswers(values: any): SurveyAnswers[] {
    const surveyAnswers: SurveyAnswers[] = [];
    for (const sugarUuid of Object.keys(values)) {
      const oldSurvey = this.surveyAnswers.find(a => a.sugarUuid === sugarUuid);
      const oldAnswers = oldSurvey ? oldSurvey.answers : ({} as Answers);

      this.questionCategories.forEach(category => {
        category.questions.forEach(q => {
          if (q.onValueChange)
            q.onValueChange(
              values[sugarUuid][q.id],
              oldAnswers,
              values[sugarUuid],
              this.form.get(sugarUuid),
            );
        });
      });

      let answers: Answers = {...emptyAnswers(), ...oldAnswers};
      for (const questionKey of Object.keys(values[sugarUuid])) {
        answers = {...answers, [questionKey]: values[sugarUuid][questionKey]};
      }

      const survey: SurveyAnswers = {
        sugarUuid,
        lastUpdate: this.dateTimeOfUpdate(answers, sugarUuid),
        answers,
      };

      surveyAnswers.push(survey);
    }
    return surveyAnswers;
  }

  private dateTimeOfUpdate(updatedAnswers: Answers, sugarUuid: string): LastUpdated {
    const allQuestions = this.questions.allQuestions(this.questionsOrigin);

    const now = moment().format('YYYY-MM-DDThh:mm');

    let investmentChanged = false;
    let lifeInsuranceChanged = false;
    let supplementaryPensionSavingsChanged = false;

    const oldSurvey = this.surveyAnswers.find(survey => survey.sugarUuid === sugarUuid);
    const diffKeys: string[] = [];
    for (const answerKey of Object.keys(updatedAnswers)) {
      if (!oldSurvey || !isEqual(updatedAnswers[answerKey], oldSurvey.answers[answerKey])) {
        diffKeys.push(answerKey);
      }
    }
    const changedQuestions = allQuestions.filter(question => diffKeys.includes(question.id));
    changedQuestions.forEach(changedQuestion => {
      if (changedQuestion.investment) investmentChanged = true;
      if (changedQuestion.lifeInsurance) lifeInsuranceChanged = true;
      if (changedQuestion.supplementaryPensionSavings) supplementaryPensionSavingsChanged = true;
    });

    return {
      investment: investmentChanged ? now : oldSurvey.lastUpdate.investment,
      lifeInsurance: lifeInsuranceChanged ? now : oldSurvey.lastUpdate.lifeInsurance,
      supplementaryPensionSavings: supplementaryPensionSavingsChanged
        ? now
        : oldSurvey.lastUpdate.supplementaryPensionSavings,
    };
  }

  private registerValueChanges() {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        delay(0),
        filter(() => !this.valueChangesDisabled),
        untilDestroyed(this),
      )
      .subscribe(_ => {
        const value = this.form.getRawValue();
        const surveyAnswers = this.convertFormValuesToAnswers(value);
        this.personalizeGraphQuestions(value);

        if (!this.showMultipleParticipants) {
          surveyAnswers.push(
            ...this.surveyAnswers.filter(
              s => !surveyAnswers.some(newS => newS.sugarUuid === s.sugarUuid),
            ),
          );
        }

        this.filterQuestions(surveyAnswers);

        this.validateAnswers(surveyAnswers);

        this.store.dispatch(InvestmentSurveyActions.IS_UpdateData({surveyAnswers}));
      });
  }

  private personalizeGraphQuestions(newValues: any = null) {
    if (!this.surveyAnswers) return;
    this.questionCategories.forEach(c => {
      c.questions
        .filter(q => q.graph)
        .forEach(q => {
          this.getActiveParticipants().forEach(p => {
            this.personalizeGraphQuestion(
              this.personalizedGraphQuestions && this.personalizedGraphQuestions[p.sugarUuid]
                ? this.personalizedGraphQuestions[p.sugarUuid]
                : q,
              p.sugarUuid,
              newValues,
            );
          });
        });
    });
  }

  private personalizeGraphQuestion(question: Question, sugarUuid: string, newValues: any = null) {
    const survey = this.surveyAnswers.find(a => a.sugarUuid === sugarUuid);
    const answers = survey ? survey.answers : ({} as Answers);
    if ((!newValues || !newValues[sugarUuid]) && isEmpty(answers)) return;
    if (question.graphConditions) {
      const graphCondition = question.graphConditions.find(c =>
        c.questionsOrigins.includes(this.questionsOrigin),
      );
      if (graphCondition) {
        if (
          !newValues ||
          get(newValues[sugarUuid], graphCondition.targetAnswer) !==
            get(answers, graphCondition.targetAnswer)
        ) {
          this.personalizedGraphQuestions = {
            ...this.personalizedGraphQuestions,
            [sugarUuid]: graphCondition.modifyQuestion(
              question,
              newValues ? newValues[sugarUuid] : answers,
            ),
          };
        }
      }
    }
  }

  private processCheckboxGroup(sugarUuid: string, question: string, checkboxGroup: CheckboxGroup) {
    const keys = Object.keys(checkboxGroup).filter(key => checkboxGroup[key]);
    const additionalAnswersFilled = this.checkAdditionalAnswers(keys, question, sugarUuid);

    const currentCheckboxGroup = this.investmentSurveyUtilsService.isCheckboxGroupSet(
      checkboxGroup,
      question,
    );
    if (this.getActiveParticipants().length > 1) {
      const partnerSugarUuid = this.getActiveParticipants().find(
        p => p.sugarUuid !== sugarUuid,
      ).sugarUuid;
      const partnerCheckboxGroup = get(this.form.getRawValue(), [partnerSugarUuid, question], null);
      this.finishedSteps[question] =
        this.investmentSurveyUtilsService.isCheckboxGroupSet(partnerCheckboxGroup, question) &&
        currentCheckboxGroup &&
        additionalAnswersFilled;
    } else {
      this.finishedSteps[question] = currentCheckboxGroup && additionalAnswersFilled;
    }
  }

  private checkAdditionalAnswers(keys: string[], questionId: string, sugarUuid: string): boolean {
    let additionalAnswersFilled = true;
    let partnerAdditionalAnswersFilled = true;
    const question = this.questions.questionByQuestionId(questionId, this.questionsOrigin);
    question.answers
      .filter(answer => answer.additionalAnswerParts)
      .filter(answer => keys.includes(answer.id))
      .map(answer => answer.additionalAnswerParts)
      .reduce(
        (all, current): AdditionalAnswerPart[] => all.concat(current),
        [] as AdditionalAnswerPart[],
      )
      .forEach(additionalAnswer => {
        if (additionalAnswer.type !== AdditionalAnswerType.TEXT) {
          const additionalAnswerValue = get(
            this.form.getRawValue(),
            [sugarUuid, questionId, additionalAnswer.id],
            null,
          );
          if (!additionalAnswerValue) additionalAnswersFilled = false;

          if (this.getActiveParticipants().length > 1) {
            const partnerSugarUuid = this.getActiveParticipants().find(
              p => p.sugarUuid !== sugarUuid,
            ).sugarUuid;
            const partnerAdditionalAnswerValue = get(
              this.form.getRawValue(),
              [partnerSugarUuid, questionId, additionalAnswer.id],
              null,
            );
            if (!partnerAdditionalAnswerValue) partnerAdditionalAnswersFilled = false;
          }
        }
      });
    return additionalAnswersFilled && partnerAdditionalAnswersFilled;
  }

  private processTableCheckboxGroup(
    sugarUuid: string,
    question: string,
    tableCheckboxGroup: TableCheckboxGroup,
  ) {
    let filled = true;

    Object.entries(tableCheckboxGroup).forEach(([key, checkboxGroup]: [string, CheckboxGroup]) => {
      if (!checkboxGroup) {
        filled = false;
        return;
      }
      const currentCheckboxGroup = this.investmentSurveyUtilsService.isCheckboxGroupSet(
        checkboxGroup,
        question,
      );
      if (this.getActiveParticipants().length > 1) {
        const partnerSugarUuid = this.getActiveParticipants().find(
          p => p.sugarUuid !== sugarUuid,
        ).sugarUuid;
        const partnerCheckboxGroup = get(
          this.form.getRawValue(),
          [partnerSugarUuid, question, key],
          null,
        );
        if (
          !(
            this.investmentSurveyUtilsService.isCheckboxGroupSet(partnerCheckboxGroup, question) &&
            currentCheckboxGroup
          )
        )
          filled = false;
      } else {
        if (!currentCheckboxGroup) filled = false;
      }
    });

    this.finishedSteps[question] = filled;
  }

  private processRadioButton(sugarUuid: string, question: string, value: string) {
    const additionalAnswersFilled = this.checkAdditionalAnswers([value], question, sugarUuid);

    if (this.getActiveParticipants().length > 1) {
      const partnerSugarUuid = this.getActiveParticipants().find(
        p => p.sugarUuid !== sugarUuid,
      ).sugarUuid;
      const partnerValue = get(
        this.form.getRawValue(),
        [partnerSugarUuid, question, 'answer'],
        null,
      );
      this.finishedSteps[question] =
        partnerValue !== null && value !== null && additionalAnswersFilled;
    } else {
      this.finishedSteps[question] = value !== null && additionalAnswersFilled;
    }
  }

  private processSlider(sugarUuid: string, question: string, value: string) {
    if (this.getActiveParticipants().length > 1) {
      const partnerSugarUuid = this.getActiveParticipants().find(
        p => p.sugarUuid !== sugarUuid,
      ).sugarUuid;
      const partnerValue = get(
        this.form.getRawValue(),
        [partnerSugarUuid, question, 'answer'],
        null,
      );
      this.finishedSteps[question] = partnerValue > 0 && +value > 0;
    } else {
      this.finishedSteps[question] = +value > 0;
    }
  }

  private iterateThroughCriteria(
    action: (sugarUuid: string, categoryId: string, categoryControl: AbstractControl) => void,
  ) {
    const activeParticipants = this.getActiveParticipants().map(p => p.sugarUuid);
    Object.entries(pick(this.form.controls, activeParticipants)).forEach(
      ([sugarUuid, personControl]: [string, UntypedFormGroup]) => {
        Object.entries(personControl.controls).forEach(
          ([categoryId, categoryControl]: [string, UntypedFormGroup]) => {
            action(sugarUuid, categoryId, categoryControl);
          },
        );
      },
    );
  }

  private createSurveyForm(): UntypedFormGroup {
    const createBasicFormGroup = (): UntypedFormGroup => {
      return new UntypedFormGroup({
        answer: new UntypedFormControl(),
      });
    };

    const createCheckboxFormGroup = (question: Question): UntypedFormGroup => {
      let checkboxControls: {[key: string]: AbstractControl} = {};

      question.answers.forEach(answer => {
        checkboxControls = {
          ...checkboxControls,
          [answer.id]: new UntypedFormControl(false),
        };
      });

      return new UntypedFormGroup(checkboxControls);
    };

    const createTableFormGroup = (question: Question): UntypedFormGroup => {
      let controls: {[key: string]: UntypedFormGroup} = {};

      const createRowControls = (answers: Answer[]): UntypedFormGroup => {
        let rowControls: {[key: string]: UntypedFormControl} = {};
        answers.forEach(answer => {
          rowControls = {
            ...rowControls,
            [answer.id]: new UntypedFormControl(false),
          };
        });
        return new UntypedFormGroup(rowControls);
      };

      question.rows.forEach(tableRow => {
        controls = {
          ...controls,
          [tableRow.id]: createRowControls(question.answers),
        } as {[key: string]: UntypedFormGroup};
      });
      return new UntypedFormGroup(controls);
    };

    const createFormGroup = (question: Question): UntypedFormGroup => {
      switch (question.type) {
        case QuestionType.RADIO:
        case QuestionType.SLIDER:
          return createBasicFormGroup();
        case QuestionType.CHECKBOX:
          return createCheckboxFormGroup(question);
        case QuestionType.TABLE:
          return createTableFormGroup(question);
      }
    };

    let formControls: {[key: string]: UntypedFormGroup} = {};
    this.questionCategories.forEach(category => {
      category.questions.forEach(question => {
        formControls = {
          ...formControls,
          [question.id]: createFormGroup(question),
        };

        question.answers
          .filter(answer => answer.additionalAnswerParts)
          .map(answer => answer.additionalAnswerParts)
          .reduce(
            (all, current): AdditionalAnswerPart[] => all.concat(current),
            [] as AdditionalAnswerPart[],
          )
          .forEach(additionalAnswer => {
            if (additionalAnswer.type !== AdditionalAnswerType.TEXT) {
              formControls[question.id].addControl(additionalAnswer.id, new UntypedFormControl());
            }
          });
      });
    });

    return new UntypedFormGroup(formControls);
  }

  private setFormChecking() {
    this.iterateThroughCriteria(
      (sugarUuid: string, categoryId: string, categoryControl: AbstractControl) => {
        categoryControl.valueChanges
          .pipe(
            distinctUntilChanged(),
            map(value => ({
              sugarUuid,
              categoryId,
              value,
            })),
            untilDestroyed(this),
          )
          .subscribe(payload =>
            this.processForms(payload.sugarUuid, payload.categoryId, payload.value),
          );
      },
    );
  }

  private filterQuestions(surveyAnswers: SurveyAnswers[]) {
    const questionCategories = this.questions.allQuestionsCategories(this.questionsOrigin);

    const newQuestionCategories = questionCategories.map(category => ({
      ...category,
      questions: category.questions.filter(question =>
        isFunction(question.hidden) ? !question.hidden(surveyAnswers) : true,
      ),
    }));

    if (JSON.stringify(this.questionCategories) !== JSON.stringify(newQuestionCategories)) {
      this.questionCategories = newQuestionCategories;
    }
  }

  private getQuestionId(step: number): string {
    return flatMap(this.questionCategories, category => category.questions)[step].id;
  }
}
