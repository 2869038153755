import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'kpt-recommended-value',
  templateUrl: './recommended-value.component.html',
  styleUrls: ['./recommended-value.component.scss'],
})
export class RecommendedValueComponent {
  @Input() visible = true;
  @Input() linkText: string;
  @Output() onClick = new EventEmitter<void>();

  constructor() {}
}
