import {NgModule} from '@angular/core';

import {ChildrenTaxAdvantage} from '@shared/analysis/forms/children-tax-advantage.definitions';
import {ContractorDefinitions} from '@shared/analysis/forms/contractor.definitions';
import {FinancialOutlook} from '@shared/analysis/forms/financial-outlook.definitions';
import {OtherAssets} from '@shared/analysis/forms/other-assets.definitions';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {CommonExpenses} from './common-expenses.definitions';
import {CreditProducts} from './credit-products.definitions';
import {AssetDefinitions} from './definitions';
import {FinancialProperties} from './financial-properties.definitions';
import {ImmovableProperties} from './immovable-properties.definitions';
import {InsuranceProducts} from './insurance-products.definitions';
import {InvestmentProducts} from './investment-products.definitions';
import {MainIncomes} from './main-incomes.definitions';
import {MovableProperties} from './movable-properties.definitions';
import {ObjectivesDefinitions} from './objectives.definitions';
import {OtherIncomes} from './other-incomes.definitions';
import {Properties} from './properties.definitions';

@NgModule({
  providers: [
    AssetDefinitions,
    AbstractAssetsDefinitions,
    InsuranceProducts,
    CreditProducts,
    InvestmentProducts,
    ObjectivesDefinitions,
    MainIncomes,
    OtherIncomes,
    CommonExpenses,
    FinancialOutlook,
    ChildrenTaxAdvantage,
    ContractorDefinitions,

    Properties,
    MovableProperties,
    ImmovableProperties,
    FinancialProperties,
    OtherAssets,
  ],
})
export class AssetsDefinitionsModule {}
