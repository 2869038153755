/* eslint-disable max-classes-per-file */
import {HttpErrorResponse} from '@angular/common/http';
import {GetClientParams} from '@generated/controllers/Integrations';
import {PortalClient} from '@generated/defs/PortalClient';
import {Action} from '@ngrx/store';

/* eslint-disable @typescript-eslint/no-shadow */
export enum GetClientActionTypes {
  GetClientStart = '[Get Client] Start',
  GetClientFinish = '[Get Client] Finish',
}
/* eslint-enable */

export class GetClientStart implements Action {
  readonly type = GetClientActionTypes.GetClientStart;

  constructor(public payload: GetClientParams) {}
}

export class GetClientFinish implements Action {
  readonly type = GetClientActionTypes.GetClientFinish;

  constructor(public payload: {client: PortalClient; error: HttpErrorResponse}) {}
}

export type GetClientActions = GetClientStart | GetClientFinish;
