import {ProductQuery} from '@generated/defs/ProductQuery';
import {
  AssetType,
  basicInvestmentAssets,
  creditAssets,
  insuranceAssets,
  otherInvestmentAssets,
  pensionInvestmentAssets,
} from '@shared/analysis/models/asset';

export enum ProductSet {
  LifeInsuranceRisk = 'lifeInsuranceRisk',
  LifeInsuranceInvestment = 'lifeInsuranceInvestment',
  GoldInGoldDivision = 'goldInGoldDivision',
  GoldInFinanceDivision = 'goldInFinanceDivision',
  Investments = 'investments',
  InvestmentsAll = 'investmentsAll',
  InsuranceAll = 'insuranceAll',
  BankAll = 'bankAll',
  PensionAll = 'pensionAll',
  CommoditiesAll = 'commoditiesAll',
}

export const productSetParams: Record<ProductSet, ProductQuery> = {
  [ProductSet.LifeInsuranceRisk]: {
    divisions: ['finance'],
    categoryPaths: [
      ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3riziko'],
      ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3rRizInv'],
    ],
  },
  [ProductSet.LifeInsuranceInvestment]: {
    divisions: ['finance'],
    categoryPaths: [
      ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3Invest'],
      ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3rRizInv'],
    ],
  },
  [ProductSet.GoldInGoldDivision]: {
    divisions: ['gold'],
    categoryPaths: [['Kat1InvestKovy']],
  },
  [ProductSet.GoldInFinanceDivision]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1InvestKovy']],
  },
  [ProductSet.Investments]: {
    divisions: ['finance'],
    categoryPaths: [
      ['Kat1Investicni', 'Kat2Dluhopisy'],
      ['Kat1Investicni', 'Kat2NemFond'],
      ['Kat1Investicni', 'Kat2NemFondKI'],
      ['Kat1Investicni', 'Kat2PodilFondy'],
      ['Kat1Investicni', 'Kat2Certifikaty'],
      ['Kat1Investicni', 'Kat2ETF'],
    ],
  },
  [ProductSet.InvestmentsAll]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1Investicni']],
  },
  [ProductSet.InsuranceAll]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1Pojisteni']],
  },
  [ProductSet.BankAll]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1Bankovni']],
  },
  [ProductSet.PensionAll]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1Penze']],
  },
  [ProductSet.CommoditiesAll]: {
    divisions: ['finance'],
    categoryPaths: [['Kat1InvestKovy']],
  },
};

export function getProductSet(assetType: AssetType): ProductSet {
  const rule = productSets4AssetTypes.find(([_, assetTypes]) => assetTypes.includes(assetType));
  if (!rule) return undefined;
  return rule[0];
}

// sorted by priority, write more specific rules first
// TODO consider to use directly assetTypesToCoreCategoriesTable
const productSets4AssetTypes: [ProductSet, AssetType[]][] = [
  [ProductSet.CommoditiesAll, [AssetType.Commodity]],
  [ProductSet.BankAll, [AssetType.BuildingSavings, AssetType.SavingsAccount]],
  [ProductSet.InvestmentsAll, [...basicInvestmentAssets, ...otherInvestmentAssets]],
  [ProductSet.InsuranceAll, insuranceAssets],
  [ProductSet.BankAll, creditAssets],
  [ProductSet.PensionAll, pensionInvestmentAssets],
];
