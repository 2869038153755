import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from 'src/shared/ui/formly/formly.enums';
import {getBasicField} from 'src/shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';
import {getBaseAssetFields, sumByPaths, updateNetIncome} from './forms.helpers';

@Injectable()
export class ContractorDefinitions {
  private commonIncomeAssets = this.abstractAssets.commonIncomeAssets();

  constructor(private abstractAssets: AbstractAssetsDefinitions) {}

  getContractor(): AssetDefinition {
    const sumNetOtherIncomeOptions = {change: sumByPaths('value', ['netIncome', 'otherIncome'])};
    const updateNetIncomeOptions = {change: updateNetIncome};

    return {
      name: assetNames[AssetType.Contractor],
      type: AssetType.Contractor,
      fields: [
        this.commonIncomeAssets.assetUuid,
        this.commonIncomeAssets.familyMemberUuid,
        row([
          col(
            getBasicField(FormlyTypes.Number, 'netIncome', FieldLabel.NET_INCOME, {
              ...sumNetOtherIncomeOptions,
              subtext: 'Zadejte čistý příjem',
            }),
          ),
          col(
            getBasicField(FormlyTypes.Number, 'otherIncome', FieldLabel.OTHER_INCOME, {
              ...sumNetOtherIncomeOptions,
              subtext: 'Zadejte ostatní čistý příjem',
            }),
          ),
        ]),
        row([
          col(
            this.abstractAssets.getValueAssetField(
              {},
              {label: FieldLabel.MONTHLY_INCOME, ...updateNetIncomeOptions},
            ),
          ),
          col(getBasicField(FormlyTypes.Text, 'profession', FieldLabel.PROFESSION)),
        ]),
        this.commonIncomeAssets.start,
        row([
          col(
            getBasicField(FormlyTypes.Number, 'healthInsuranceValue', FieldLabel.HEALTH_INSURANCE),
          ),
          col(
            getBasicField(FormlyTypes.Number, 'socialInsuranceValue', FieldLabel.SOCIAL_INSURANCE),
          ),
        ]),
        row([
          col(
            getBasicField(
              FormlyTypes.Checkbox,
              'sicknessInsurancePayer',
              'Jsem plátce nemocenského pojištění',
            ),
          ),
          col(
            getBasicField(
              FormlyTypes.Number,
              'sicknessInsuranceValue',
              FieldLabel.SICKNESS_INSURANCE,
              {},
              {'templateOptions.disabled': '!model.sicknessInsurancePayer'},
            ),
          ),
        ]),
        getBaseAssetFields().note,
      ],
      validators: getAssetValidators(AssetType.Contractor),
      model: {},
    };
  }
}
