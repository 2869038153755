import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Product} from '@generated/defs/Product';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {ProductsService} from '@shared/services/products.service';
import {IOption} from '@shared/ui';
import {combineLatest} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {RecommendedMainFormsVM} from 'src/app/modules/life-insurance-old/5-proposals/proposals.selectors';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {State} from 'src/store';
import {ProductSet} from 'src/store/models/products.models';

export interface ProposalsAlternativesData {
  forms: RecommendedMainFormsVM;
  personId: string;
}

@UntilDestroy()
@Component({
  selector: 'kpt-proposals-alternatives',
  templateUrl: './proposals-alternatives.component.html',
  styleUrls: ['./proposals-alternatives.component.scss'],
})
export class ProposalsAlternativesComponent implements OnInit, OnDestroy {
  data: ProposalsAlternativesData;
  title: string;

  options: IOption[] = [
    {
      key: 'new',
      label: 'Přidat nové doporučení',
      disabled: false,
    },
    {
      key: 'alternative',
      label: 'Přidat alternativu pro',
      disabled: false,
    },
  ];

  alternativesForm = new UntypedFormGroup({
    alternative: new UntypedFormControl('new'),
    alternativeFor: new UntypedFormControl(undefined),
  });

  recommendedForms: IOption[] = [];

  constructor(
    private store: Store<State>,
    private modalService: ModalService,
    private productsService: ProductsService,
  ) {}

  ngOnInit() {
    combineLatest(
      this.productsService.getProducts(ProductSet.LifeInsuranceRisk),
      this.productsService.getProducts(ProductSet.LifeInsuranceInvestment),
    )
      .pipe(
        map(([riskProducts, investmentProducts]) => [...riskProducts, ...investmentProducts]),
        untilDestroyed(this),
      )
      .subscribe(products => {
        this.initForm(products);
      });
  }

  ngOnDestroy() {}

  add() {
    this.store.pipe(selectFamilyHead(), take(1)).subscribe(familyHead => {
      if (this.alternativesForm.get('alternative').value === 'alternative') {
        this.store.dispatch(
          LifeInsuranceActions.addAlternativeInsuranceGroupForm({
            personId: this.data.personId,
            alternativeToId: +this.alternativesForm.get('alternativeFor').value,
            stakeholderId: familyHead.sugarUuid,
          }),
        );
      } else {
        this.store.dispatch(
          LifeInsuranceActions.addRecommendedGroupForm({
            personId: this.data.personId,
            stakeholderId: familyHead.sugarUuid,
          }),
        );
      }

      this.modalService.closeModal();
    });
  }

  private initForm(products: Product[]) {
    this.recommendedForms = this.data.forms[this.data.personId].map(form => {
      const product = products.find(p => p.id === form.productId);
      return {
        key: form.id.toString(),
        label: product ? product.name : 'neznámé',
      } as IOption;
    });

    this.alternativesForm.get('alternativeFor').setValue(this.recommendedForms[0].key);
  }
}
