import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {PropertyProvisionAsset} from '@shared/analysis/models/objectives';
import {selectImmovableProperties} from '@shared/analysis/store';
import * as moment from 'moment';
import {first} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class PropertyProvisionAssetType extends BaseAssetType {
  constructor(private store: Store<State>) {
    super();
  }

  async create(): Promise<PropertyProvisionAsset> {
    const properties = await this.store
      .pipe(select(selectImmovableProperties), first())
      .toPromise();

    return {
      type: AssetType.PropertyProvision,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      risks: [],
      name: null,
      description: null,
      selectedAssets: properties.map(p => p.assetUuid),
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.PropertyProvision],
      type: AssetType.PropertyProvision,
    };
  }
}
