import {get} from 'lodash';

export function trackByIndex(index: number) {
  return index;
}

export function trackByItem(_index: number, item: any) {
  return item;
}

export function trackByProperty(path: string) {
  return (_index: number, item: any) => get(item, path);
}
