<div *ngIf="resultForm" [formGroup]="resultForm">
  <ng-container *ngIf="!resultForm.get('eligibility').value; else eligible">
    <label class="result-value">
      S ohledem na výsledek investičního dotazníku klientovi nelze sjednat investici.
    </label>
  </ng-container>

  <ng-template #eligible>
    <div class="row">
      <div class="col">
        <div class="investment-note">
          Vyhodnocení se týká základních investičních nástrojů (pouze investičních nástrojů
          uvedených v § 29 odst. 3 ZPKT)
        </div>
      </div>
    </div>
    <div class="result">
      <div class="result-title">
        Znalosti a zkušenosti
        <kpt-help
          class="help-with-link hidden-print"
          tooltipCustomClass="align-left"
          [text]="investorLevelTooltip"
        >
        </kpt-help>
      </div>
      <div class="result-value result-points">
        {{ resultPointsLabel }}
      </div>
    </div>
    <div class="result">
      <div class="result-title">
        Kategorie klienta
        <kpt-help
          class="help-with-link hidden-print"
          tooltipCustomClass="align-left"
          [text]="
            'Vymezení kategorií klientů při poskytování služeb dle zákona č 256/2004 sb. o podnikání' +
            ' na kapitálovém trhu ' +
            '<a href=\'/assets/files/vymezeni_kategorii_zakazniku.pdf\' target=\'_blank\'>najdete zde</a>'
          "
        >
        </kpt-help>
      </div>
      <div class="result-value">
        {{ clientCategory }}
        <span *ngIf="!readonly" class="icon icon--edit" (click)="openClientCategoryModal()"></span>
      </div>
    </div>
    <div class="result">
      <div class="result-title">Investiční horizont</div>
      <div class="result-value">{{ resultForm.get('horizon').value }}</div>
    </div>
    <div class="result">
      <div class="result-title">Rizikový profil klienta</div>
      <div class="result-value">{{ resultForm.get('riskProfile').value }}</div>
    </div>
    <div class="result">
      <div class="result-title">Investiční preference</div>
      <div class="result-value">{{ resultForm.get('investmentPreferences').value }}</div>
    </div>
    <div class="result">
      <div class="result-title">Preferované portfolio</div>
      <div class="result-value">{{ resultForm.get('preferredPortfolio').value }}</div>
    </div>
    <div class="result">
      <div class="result-title">Investiční strategie s maximálním možným rizikem</div>
      <div class="result-value">{{ resultForm.get('strategy').value }}</div>
    </div>
    <div *ngIf="resultForm.get('inconsistentPortfolioAnswers').value" class="pt-3">
      Odpovědi klienta v&nbsp;investičním dotazníku ve&nbsp;vztahu k&nbsp;preferenci poměru
      výnos/riziko a k&nbsp;preferenci vzorového portfolia jsou ve&nbsp;vzájemném rozporu. Klient
      byl na&nbsp;toto upozorněn a přál si přesto pokračovat k&nbsp;vyhodnocení s&nbsp;těmito
      odpověďmi.
    </div>
    <div *ngIf="resultForm.get('inconsistentInvestmentPurposeAnswers').value" class="pt-3">
      Odpovědi klienta v&nbsp;investičním dotazníku ve&nbsp;vztahu k&nbsp;investičnímu horizontu a
      k&nbsp;účelu investice jsou ve&nbsp;vzájemném rozporu. Klient byl na&nbsp;toto upozorněn a
      přál si přesto pokračovat k&nbsp;vyhodnocení s&nbsp;těmito odpověďmi.
    </div>
  </ng-template>
</div>
