import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'kpt-single-radio-button',
  templateUrl: './single-radio-button.component.html',
  styleUrls: ['./single-radio-button.component.scss'],
})
export class SingleRadioButtonComponent {
  @Input()
  controlForm: UntypedFormControl;

  @Input()
  notEmpty = true;

  @Input()
  label: string;

  @Input()
  disabled = false;

  @Input()
  value: any;

  @Input()
  error?: string;

  @Input()
  checked: boolean;

  @Output()
  change = new EventEmitter<any>();
}
