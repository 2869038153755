<kpt-accordion-group class="top-level" [isOpen]="true" *ngIf="showSection">
  <div class="accordion-item-title">
    <h2 class="m-auto">{{ section.label }}</h2>
  </div>
  <div *ngIf="section.headerItems?.length > 0" class="row header-row mb-5">
    <div class="col-12 d-flex flex-wrap text-center">
      <div *ngFor="let item of section.headerItems" class="header-item">
        <h3 class="header-item-value" [innerHTML]="item.value"></h3>
        <p class="header-item-label" [innerHTML]="item.label"></p>
      </div>
    </div>
  </div>
  <kpt-section-category *ngFor="let category of section.categories" [category]="category">
  </kpt-section-category>
  <ng-content></ng-content>
</kpt-accordion-group>
