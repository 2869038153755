import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';
import {ToggleComponent} from '@shared/ui/forms/toggle/toggle.component';

@NgModule({
  declarations: [ToggleComponent],
  imports: [CommonModule, ReactiveFormsModule, FieldErrorModule],
  exports: [ToggleComponent],
})
export class ToggleModule {}
