import {regularPaymentTypeToFrequency} from '@shared/analysis/helpers';
import {Asset, AssetType, DescriptionAsset} from '@shared/analysis/models/asset';
import {CommonExpensesAsset} from '@shared/analysis/models/common-expenses';
import {CreditAsset} from '@shared/analysis/models/credit-products';
import {FinancialProductsAsset} from '@shared/analysis/models/financial-products';
import {
  BondsAsset,
  FinancialPropertyAsset,
  QualifiedInvestorFundsAsset,
  TermDepositAsset,
} from '@shared/analysis/models/financial-properties';
import {InvestmentLifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {
  BuildingSavingsAsset,
  CommonInvestmentAsset,
  InvestmentAsset,
  PensionInsuranceAsset,
  SupplementaryPensionSavingsAsset,
} from '@shared/analysis/models/investment-products';
import {ContractorAsset, MainIncomesAsset} from '@shared/analysis/models/main-incomes';
import {
  ObjectivesAsset,
  OtherObjectiveAsset,
  OtherObjectivePaymentType,
} from '@shared/analysis/models/objectives';
import {OtherIncomesAsset} from '@shared/analysis/models/other-incomes';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {
  SandboxCarPurchaseAsset,
  SandboxConsumerLoanAsset,
  SandboxCurrentBalanceAsset,
  SandboxExpensesAsset,
  SandboxHousePurchaseAsset,
  SandboxIncomeAsset,
  SandboxInheritanceAsset,
  SandboxLossOfWorkAsset,
  SandboxMortgageAsset,
  SandboxUnitTrustAsset,
} from '@shared/analysis/models/sandbox';
import {
  Frequency,
  FunctionType,
  Liquidity,
  Unit,
  UnitFunction,
  UnitType,
} from '@shared/analysis/models/unit';
import {isNumber} from 'lodash';
import * as moment from 'moment';

function toAsset(asset: Asset, functions: UnitFunction[]): Unit {
  return {
    id: asset.assetUuid,
    name: (asset as DescriptionAsset).description,
    functions,
  };
}

const sandboxCurrentBalance = (asset: SandboxCurrentBalanceAsset): Unit => {
  return {
    ...toAsset(asset, [
      {
        type: FunctionType.Constant,
        liquidity: Liquidity.High,
        start: asset.start,
        params: {value: asset.value},
      },
    ]),
    type: UnitType.CurrentBalance,
  };
};

const sandboxIncome = (asset: SandboxIncomeAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: asset.value, constantTerm: asset.value},
    },
  ]);
};

const sandboxExpenses = (asset: SandboxExpensesAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.value},
    },
  ]);
};

const sandboxUnitTrust = (asset: SandboxUnitTrustAsset): Unit => {
  return toAsset(asset, [
    {
      // monthly payment, initial value
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.initialAmount},
    },
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.start,
      end: asset.end,
      params: {
        yearRate: asset.yearRate,
        presentValue: asset.initialAmount,
        periodicPayment: asset.value,
      },
    },
  ]);
};

const sandboxHousePurchase = (asset: SandboxHousePurchaseAsset): Unit => {
  const functions: UnitFunction[] = [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: asset.value},
    },
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      params: {value: -asset.value},
    },
  ];
  if (isNumber(asset.appreciation) && asset.appreciation !== 0) {
    functions.push(
      {
        // simulates appreciation
        type: FunctionType.FutureValue,
        frequency: Frequency.Year,
        liquidity: Liquidity.Low,
        start: asset.start,
        params: {yearRate: asset.appreciation, presentValue: asset.value, periodicPayment: 0},
      },
      {
        // simulates appreciation
        type: FunctionType.Constant,
        liquidity: Liquidity.Low,
        start: asset.start,
        params: {value: -asset.value},
      },
    );
  }
  return toAsset(asset, functions);
};

const sandboxMortgage = (asset: SandboxMortgageAsset): Unit => {
  return toAsset(asset, [
    {
      // I got a loan
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      params: {value: asset.value},
    },
    {
      // I paid for a property
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: -asset.value},
    },
    {
      // I pay monthly installment
      type: FunctionType.Installment,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.termInMonths,
      params: {loan: -asset.value, yearRate: asset.yearRate},
    },
    {
      // the loan is amortized
      type: FunctionType.Amortization,
      frequency: Frequency.Month,
      liquidity: Liquidity.Low,
      start: asset.start,
      end: asset.termInMonths,
      params: {loan: asset.value, yearRate: asset.yearRate},
    },
  ]);
};

const sandboxCarPurchase = (asset: SandboxCarPurchaseAsset): Unit => {
  const functions: UnitFunction[] = [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: asset.value},
    },
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      params: {value: -asset.value},
    },
  ];
  if (isNumber(asset.depreciation) && asset.depreciation !== 0) {
    functions.push(
      {
        // simulates appreciation
        type: FunctionType.FutureValue,
        frequency: Frequency.Year,
        liquidity: Liquidity.Low,
        start: asset.start,
        params: {yearRate: -asset.depreciation, presentValue: asset.value, periodicPayment: 0},
      },
      {
        // simulates appreciation
        type: FunctionType.Constant,
        liquidity: Liquidity.Low,
        start: asset.start,
        params: {value: -asset.value},
      },
    );
  }
  return toAsset(asset, functions);
};

const sandboxConsumerLoan = (asset: SandboxConsumerLoanAsset): Unit => {
  return toAsset(asset, [
    {
      // I got money from the loan
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      params: {value: asset.value},
    },
    {
      // I owe money
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: -asset.value},
    },
    {
      // I pay monthly installment
      type: FunctionType.Installment,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.termInMonths,
      params: {loan: -asset.value, yearRate: asset.yearRate},
    },
    {
      // the loan is amortized
      type: FunctionType.Amortization,
      frequency: Frequency.Month,
      liquidity: Liquidity.Low,
      start: asset.start,
      end: asset.termInMonths,
      params: {loan: asset.value, yearRate: asset.yearRate},
    },
  ]);
};

const sandboxLossOfWork = (asset: SandboxLossOfWorkAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.LossOfWork,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {workUnitId: '25'},
    },
  ]);
};

const sandboxInheritance = (asset: SandboxInheritanceAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.High, // may be Low?
      start: asset.start,
      params: {value: asset.value},
    },
  ]);
};

const oneTimeExpense = (asset: ObjectivesAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      params: {value: -asset.value},
    },
  ]);
};

const regularExpense = (asset: CommonExpensesAsset | OtherObjectiveAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.value},
    },
  ]);
};

const otherObjectiveExpense = (asset: OtherObjectiveAsset): Unit => {
  if (asset.payment === OtherObjectivePaymentType.OneTime) {
    return oneTimeExpense(asset);
  } else {
    return regularExpense(asset);
  }
};

const contractorInsurance = (asset: ContractorAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.healthInsuranceValue},
    },
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.socialInsuranceValue},
    },
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: asset.sicknessInsurancePayer ? -asset.sicknessInsuranceValue : 0},
    },
  ]);
};

const regularIncome = (asset: MainIncomesAsset | OtherIncomesAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: asset.value, constantTerm: asset.value},
    },
  ]);
};

const contractor = (asset: ContractorAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: Frequency.Month,
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: asset.value, constantTerm: asset.value},
    },
  ]);
};

const regularInsuranceExpense = (asset: FinancialProductsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.value},
    },
  ]);
};

const lifeInsuranceExpense = (asset: InvestmentLifeInsuranceAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.start,
      end: asset.end,
      params: {slope: -asset.value},
    },
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.presentValueDate,
      params: {value: (!asset.futureValue || !asset.futureValueDate) && asset.presentValue},
    },
    {
      type: FunctionType.LifeInsurance,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.Low,
      start: asset.presentValueDate,
      params: {
        futureValue: asset.futureValue,
        yearRate: asset.yearlyRate,
        presentValue: asset.presentValue,
        futureValueDate: asset.futureValueDate,
      },
    },
  ]);
};

const investment = (asset: InvestmentAsset): Unit => {
  // TODO(pz): add transfer from the investment account back to the current account on the end date
  const investmentAsset = asset as CommonInvestmentAsset;

  return toAsset(asset, [
    {
      // present value
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: investmentAsset.presentValueDate,
      params: {value: -investmentAsset.presentValue},
      transfer: true,
    },
    {
      // monthly payment
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: investmentAsset.presentValueDate,
      end: asset.end,
      params: {slope: -asset.value},
    },
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: investmentAsset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: investmentAsset.yearlyRate,
        presentValue: investmentAsset.presentValue,
        periodicPayment: asset.value,
      },
    },
  ]);
};

const currentBalance = (asset: FinancialPropertyAsset): Unit => {
  return {
    ...toAsset(asset, [
      {
        type: FunctionType.Constant,
        liquidity: Liquidity.High,
        start: asset.start,
        params: {value: asset.value},
      },
    ]),
    type: UnitType.CurrentBalance,
  };
};

const credit = (asset: CreditAsset): Unit => {
  return toAsset(asset, [
    {
      // I get money from a loan
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.outstandingValueDate,
      transfer: true,
      params: {value: asset.outstandingValue},
    },
    {
      // I owe money
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      params: {value: -asset.outstandingValue},
    },
    {
      // I pay monthly installment
      type: FunctionType.Installment,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.outstandingValueDate,
      end: asset.end,
      params: {loan: -asset.outstandingValue, yearRate: asset.yearlyRate},
    },
    {
      // the loan is amortized
      type: FunctionType.Amortization,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      end: asset.end,
      params: {loan: asset.outstandingValue, yearRate: asset.yearlyRate},
    },
    {
      // credit fee
      type: FunctionType.CreditFee,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.outstandingValueDate,
      end: asset.end,
      params: {value: asset.value, loan: asset.outstandingValue, yearRate: asset.yearlyRate},
    },
  ]);
};

const creditPropertiesSum = (asset: CreditAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: -asset.value},
    },
  ]);
};

const property = (asset: PropertyAsset): Unit => {
  const expense: UnitFunction = {
    // I pay for a property
    type: FunctionType.Constant,
    liquidity: Liquidity.High,
    start: asset.start,
    transfer: true,
    params: {value: -asset.value},
  };
  const income: UnitFunction = {
    // I buy a property
    type: FunctionType.Constant,
    liquidity: Liquidity.Low,
    start: asset.start,
    params: {value: asset.value},
  };
  const inFuture = moment().isBefore(asset.start);
  const functions = inFuture ? [expense, income] : [income];
  return toAsset(asset, functions);
};

const termDeposit = (asset: TermDepositAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const bonds = (asset: BondsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const qualifiedInvestorFunds = (asset: QualifiedInvestorFundsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const ownHousing = (asset: ObjectivesAsset): Unit => {
  return toAsset(asset, [
    {
      // I pay for a housing
      type: FunctionType.Constant,
      liquidity: Liquidity.High,
      start: asset.start,
      transfer: true,
      params: {value: -asset.value},
    },
    {
      // I buy a housing
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: asset.value},
    },
  ]);
};

const buildingSavings = (asset: BuildingSavingsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.presentValueDate,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.presentValue},
    },
    {
      type: FunctionType.BuildingSavings,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        periodicPayment: asset.value,
      },
    },
  ]);
};

const supplementaryPensionSavings = (asset: SupplementaryPensionSavingsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.presentValueDate,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.presentValue},
    },
    {
      type: FunctionType.SupplementaryPensionSavings,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        ownContribution: asset.value,
        employerContribution: asset.employerContribution,
      },
    },
  ]);
};

const pensionInsurance = (asset: PensionInsuranceAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Linear,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.High,
      start: asset.presentValueDate,
      end: asset.end,
      params: {slope: -asset.value, constantTerm: -asset.presentValue},
    },
    {
      type: FunctionType.PensionInsurance,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        ownContribution: asset.value,
        employerContribution: asset.employerContribution,
      },
    },
  ]);
};

const noop = (asset: Asset): Unit => {
  return toAsset(asset, []);
};

const assetsToUnits: Record<AssetType, (asset: Asset) => Unit> = {
  // Sandbox Assets
  [AssetType.SandboxCurrentBalance]: sandboxCurrentBalance,
  [AssetType.SandboxIncome]: sandboxIncome,
  [AssetType.SandboxExpenses]: sandboxExpenses,
  [AssetType.SandboxUnitTrust]: sandboxUnitTrust,
  [AssetType.SandboxHousePurchase]: sandboxHousePurchase,
  [AssetType.SandboxMortgage]: sandboxMortgage,
  [AssetType.SandboxCarPurchase]: sandboxCarPurchase,
  [AssetType.SandboxConsumerLoan]: sandboxConsumerLoan,
  [AssetType.SandboxLossOfWork]: sandboxLossOfWork,
  [AssetType.SandboxInheritance]: sandboxInheritance,

  // Objectives
  [AssetType.OwnHousing]: ownHousing,
  [AssetType.ChildrenFuture]: oneTimeExpense,
  [AssetType.Retirement]: oneTimeExpense,
  [AssetType.FinancialIndependence]: oneTimeExpense,
  [AssetType.ShortTermReserve]: oneTimeExpense,
  [AssetType.LongTermReserve]: noop,
  [AssetType.BadDebtsRepayment]: oneTimeExpense,
  [AssetType.FamilyProvision]: noop,
  [AssetType.PropertyProvision]: noop,
  [AssetType.PropertyRequirements]: noop,
  [AssetType.VehicleProvision]: noop,
  [AssetType.VehicleRequirements]: noop,
  [AssetType.ChildBirth]: regularExpense,
  [AssetType.NewHousing]: oneTimeExpense,
  [AssetType.Reconstruction]: oneTimeExpense,
  [AssetType.Furnishings]: oneTimeExpense,
  [AssetType.Car]: oneTimeExpense,
  [AssetType.Vacation]: oneTimeExpense,
  [AssetType.OtherObjective]: otherObjectiveExpense,

  // Common Expenses
  [AssetType.CommonExpensesSum]: regularExpense,
  [AssetType.Food]: regularExpense,
  [AssetType.HousingExpenses]: regularExpense,
  [AssetType.Drugstore]: regularExpense,
  [AssetType.Transport]: regularExpense,
  [AssetType.Communication]: regularExpense,
  [AssetType.Clothes]: regularExpense,
  [AssetType.Hobbies]: regularExpense,
  [AssetType.Vacations]: regularExpense,
  [AssetType.ChildrenExpenses]: regularExpense,
  [AssetType.OtherCommonExpenses]: regularExpense,
  [AssetType.ContractorInsurance]: contractorInsurance,

  // Main Incomes
  [AssetType.Employment]: regularIncome,
  [AssetType.Contractor]: contractor,
  [AssetType.FinancialOutlook]: noop,
  [AssetType.ChildrenTaxAdvantage]: noop,

  // Other Incomes
  [AssetType.Maternity]: regularIncome,
  [AssetType.Parental]: regularIncome,
  [AssetType.UnemploymentBenefits]: regularIncome,
  [AssetType.PensionIncome]: regularIncome,
  [AssetType.OtherPensionIncome]: regularIncome,
  [AssetType.RentalIncome]: regularIncome,
  [AssetType.Dividends]: regularIncome,
  [AssetType.OtherPassiveIncome]: regularIncome,

  // Financial Products - Insurance
  [AssetType.InsuranceProductsSum]: regularExpense,
  [AssetType.InvestmentLifeInsurance]: lifeInsuranceExpense,
  [AssetType.RiskLifeInsurance]: regularInsuranceExpense,
  [AssetType.CapitalLifeInsurance]: lifeInsuranceExpense,
  [AssetType.PropertyInsurance]: regularInsuranceExpense,
  [AssetType.LiabilityInsurance]: regularInsuranceExpense,
  [AssetType.VehicleInsurance]: regularInsuranceExpense,
  [AssetType.TravelInsurance]: regularInsuranceExpense,
  [AssetType.BusinessInsurance]: regularInsuranceExpense,

  // Financial Products - Investment
  [AssetType.InvestmentProductsSum]: regularExpense,
  [AssetType.UnitTrust]: investment,
  [AssetType.RealEstateFund]: investment,
  [AssetType.Commodity]: investment,
  [AssetType.BuildingSavings]: buildingSavings,
  [AssetType.SupplementaryPensionSavings]: supplementaryPensionSavings,
  [AssetType.PensionInsurance]: pensionInsurance,
  [AssetType.SavingsAccount]: investment,
  [AssetType.ExchangeTradedFunds]: investment,
  [AssetType.Certificates]: investment,
  [AssetType.CombinedDeposits]: investment,

  // Financial Products - Credit
  [AssetType.CreditProductsSum]: regularExpense,
  [AssetType.Mortgage]: credit,
  [AssetType.BuildingSavingsLoan]: credit,
  [AssetType.Leasing]: credit,
  [AssetType.CreditCard]: credit,
  [AssetType.Overdraft]: credit,
  [AssetType.ConsumerLoan]: credit,
  [AssetType.OtherIndividualLoan]: credit,

  // Immovable Properties
  [AssetType.ImmovablePropertiesSum]: property,
  [AssetType.Flat]: property,
  [AssetType.House]: property,
  [AssetType.Household]: property,
  [AssetType.HolidayProperty]: property,
  [AssetType.ApartmentBuilding]: property,
  [AssetType.Garage]: property,
  [AssetType.Land]: property,
  [AssetType.OtherImmovableProperty]: property,

  // Movable Properties
  [AssetType.MovablePropertiesSum]: property,
  [AssetType.Vehicle]: property,
  [AssetType.Valuables]: property,
  [AssetType.SpecialValuables]: property,

  // Financial Properties
  [AssetType.FinancialPropertiesSum]: currentBalance,
  [AssetType.CurrentAccount]: currentBalance,
  [AssetType.TermDeposit]: termDeposit,
  [AssetType.Bonds]: bonds,
  [AssetType.QualifiedInvestorFunds]: qualifiedInvestorFunds,
  [AssetType.OtherFinancialProperty]: property,

  // Other Assets
  [AssetType.CurrentAccountUsage]: noop, // no calculations are performed

  // Properties Sums
  [AssetType.InvestmentPropertiesSum]: noop, // asset is not used
  [AssetType.CreditPropertiesSum]: creditPropertiesSum,
  [AssetType.InsurancePropertiesSum]: lifeInsuranceExpense,

  // Virtual Assets
  [AssetType.VirtualMonthlyBalance]: noop,
};

export function transformAssetsToUnits(assets: Asset[]): Unit[] {
  return assets.map(asset => assetsToUnits[asset.type](asset));
}
