<kpt-modal-wrapper [heading]="header" (close)="dismiss()">
  <!-- focus catcher -->
  <input class="focus-catcher" type="text" />
  <div class="attachments">
    <a *ngIf="fpDocument" class="attachments__item" [href]="fpDocument" target="_blank">
      <img src="/assets/images/homepage/plan-finished.svg" />
      <h4>Finanční plán</h4>
    </a>
    <a *ngIf="fpAttachment" class="attachments__item" [href]="fpAttachment" target="_blank">
      <img src="/assets/images/homepage/plan-finished.svg" />
      <h4>Finanční plán - příloha</h4>
    </a>
    <a *ngIf="faResults" class="attachments__item" [href]="faResults" target="_blank">
      <img src="/assets/images/homepage/plan-finished.svg" />
      <h4>Finanční analýza</h4>
    </a>
  </div>
</kpt-modal-wrapper>
