import {Component, Input} from '@angular/core';

@Component({
  selector: 'kpt-placeloader',
  templateUrl: './placeloader.component.html',
  styleUrls: ['./placeloader.component.scss'],
})
export class PlaceloaderComponent {
  @Input() label?: string;

  @Input() loading = false;
}
