import {formatPercent} from '@angular/common';
import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {getGetAdvisorStateSelector} from '@generated/store/integrations/getAdvisor/states/reducers';
import {formatCZK, formatCzkInCzechFixedWidth} from '@lib/utils';
import {select, Store} from '@ngrx/store';
import {Asset, assetNames, NameAsset, RegularPaymentType} from '@shared/analysis/models/asset';
import {
  contractStateNames,
  paymentDayNames,
  regularPaymentTypeNames,
} from '@shared/analysis/models/assets.enums';
import {FinancialProductsAsset} from '@shared/analysis/models/financial-products';
import {ObjectiveAsset} from '@shared/analysis/models/objectives';
import {ValueTable} from '@shared/analysis/models/unit';
import {ObjectiveType, objectiveTypeNames} from '@shared/analysis/objectives.helpers';
import {selectFamilyHead} from '@shared/analysis/operators';
import {getAssetName, selectAllAssets, selectObjectivesAssets} from '@shared/analysis/store';
import {getYearsPluralString} from '@shared/lib';
import {PhoneNumberPipe} from '@shared/pipes';
import {getPersonAge} from '@shared/utils';
import * as moment from 'moment';
import {Moment} from 'moment';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  FPData,
  FPPrint,
  FPPrintEquityChartRow,
  FPPrintObjectiveAsset,
  FPPrintPaymentsCalendarPerson,
  FPPrintPaymentsComparisonPerson,
  FPPrintPaymentsOverview,
  FPPrintPriorityPlanFulfillment,
  FPPrintPriorityPlanItem,
  OverallFinancialPlan,
} from 'src/app/modules/financial-plan/financial-plan-overall.models';
import {
  PaymentCalendar,
  selectPaymentCalendar,
} from 'src/app/modules/financial-plan/payment-overview/payment-overview.selectors';
import {
  FinancialPlanObjectives,
  Fulfillment,
  FulfillmentType,
  Objective,
  PaymentsOverview,
  PriorityPlan,
  PriorityPlanItem,
  selectFinancialPlanObjectives,
} from 'src/app/modules/financial-plan/store';
import {
  selectCurrentEquity,
  selectClientOrAdvisorProposedEquity,
  selectAdvisorProposedEquity,
} from 'src/app/modules/financial-plan/store/financial-plan-equity.selectors';
import {
  selectExpensesAndPrioritiesChart,
  selectIncomeExpenseChart,
  selectPropertyChart,
} from 'src/app/modules/financial-plan/store/financial-plan-graphs.selectors';
import {
  selectPaymentsOverview,
  selectPriorityPlan,
} from 'src/app/modules/financial-plan/store/financial-plan-overview.selectors';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';

@Injectable({
  providedIn: 'root',
})
export class FinancialPlanOverallService {
  constructor(
    private store: Store<State>,
    private phoneNumberPipe: PhoneNumberPipe,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  getOverallFinancialPlan(nextMeetingDate?: string): Observable<OverallFinancialPlan> {
    return combineLatest([
      this.getFinancialPlanData(),
      this.getFinancialPlanPrint(nextMeetingDate),
    ]).pipe(map(([data, print]) => ({data, print})));
  }

  private getFinancialPlanData(): Observable<FPData> {
    const assets$ = this.store.pipe(select(selectAllAssets));
    const incomeExpenseChart$ = this.store.pipe(select(selectIncomeExpenseChart));
    const expensesAndPrioritiesChart$ = this.store.pipe(
      select(
        selectExpensesAndPrioritiesChart({
          showNewDashboard: this.featureFlagsService.showNewDashboard,
        }),
      ),
    );
    const propertyBalanceChart$ = this.store.pipe(select(selectPropertyChart));
    const objectives$ = this.store.pipe(
      select(
        selectFinancialPlanObjectives({
          showNewDashboard: this.featureFlagsService.showNewDashboard,
        }),
      ),
    );
    const paymentCalendar$ = this.store.pipe(select(selectPaymentCalendar));
    const priorityPlan$ = this.store.pipe(
      select(selectPriorityPlan({showNewDashboard: this.featureFlagsService.showNewDashboard})),
    );
    const currentEquity$ = this.store.pipe(select(selectCurrentEquity), map(this.formatTable));
    const advisorProposedEquity$ = this.store.pipe(
      select(selectAdvisorProposedEquity),
      map(this.formatTable),
    );
    const clientProposedEquity$ = this.store.pipe(
      select(selectClientOrAdvisorProposedEquity),
      map(this.formatTable),
    );
    const paymentsOverview$ = this.store.pipe(select(selectPaymentsOverview));

    return combineLatest([
      assets$,
      incomeExpenseChart$,
      expensesAndPrioritiesChart$,
      propertyBalanceChart$,
      objectives$,
      paymentCalendar$,
      priorityPlan$,
      currentEquity$,
      advisorProposedEquity$,
      clientProposedEquity$,
      paymentsOverview$,
    ]).pipe(
      map(
        ([
          assets,
          incomeExpenseChart,
          expensesAndPrioritiesChart,
          propertyBalanceChart,
          objectives,
          paymentCalendar,
          priorityPlan,
          currentEquity,
          advisorProposedEquity,
          clientProposedEquity,
          paymentsOverview,
        ]) => ({
          financialAnalysis: {
            assets,
          },
          financialPlan: {
            finances: {
              incomeExpense: incomeExpenseChart,
              expensesAndPriorities: expensesAndPrioritiesChart,
            },
            properties: propertyBalanceChart,
            objectives,
            paymentCalendar,
            overview: {
              priorities: priorityPlan,
              equities: {
                current: currentEquity,
                advisorProposed: advisorProposedEquity,
                clientProposed: clientProposedEquity,
              },
              payments: paymentsOverview,
            },
          },
        }),
      ),
    );
  }

  private getFinancialPlanPrint(nextMeetingDate?: string): Observable<FPPrint> {
    const advisor$ = this.store.pipe(
      select(getGetAdvisorStateSelector),
      map(state => state.data),
    );
    const client$ = this.store.pipe(selectFamilyHead());
    const assets$ = this.store.pipe(select(selectAllAssets));
    const objectiveAssets$ = this.store.pipe(select(selectObjectivesAssets)) as Observable<
      ObjectiveAsset[]
    >;
    const objectives$ = this.store.pipe(
      select(
        selectFinancialPlanObjectives({
          showNewDashboard: this.featureFlagsService.showNewDashboard,
        }),
      ),
    );
    const paymentCalendar$ = this.store.pipe(select(selectPaymentCalendar));
    const priorityPlan$ = this.store.pipe(
      select(selectPriorityPlan({showNewDashboard: this.featureFlagsService.showNewDashboard})),
    );
    const currentEquity$ = this.store.pipe(select(selectCurrentEquity), map(this.formatTable));
    const advisorProposedEquity$ = this.store.pipe(
      select(selectAdvisorProposedEquity),
      map(this.formatTable),
    );
    const clientProposedEquity$ = this.store.pipe(
      select(selectClientOrAdvisorProposedEquity),
      map(this.formatTable),
    );
    const paymentsOverview$ = this.store.pipe(select(selectPaymentsOverview));

    return combineLatest([
      combineLatest([advisor$, client$, assets$, objectiveAssets$, objectives$]),
      combineLatest([
        paymentCalendar$,
        priorityPlan$,
        currentEquity$,
        advisorProposedEquity$,
        clientProposedEquity$,
        paymentsOverview$,
      ]),
    ]).pipe(
      map(
        ([
          [advisor, client, assets, objectiveAssets, objectives],
          [
            paymentCalendar,
            priorityPlan,
            currentEquity,
            advisorProposedEquity,
            clientProposedEquity,
            paymentsOverview,
          ],
        ]): FPPrint => ({
          advisor: {
            firstName: advisor.personalInfo.firstName,
            lastName: advisor.personalInfo.lastName,
            email: advisor.contacts.email,
            phone: this.phoneNumberPipe.transform(advisor.contacts.phone),
          },
          client: {
            firstName: client.firstName,
            lastName: client.lastName,
            age: getPersonAge(client.birthDate),
            gender: client.sex,
          },
          meeting: {
            currentMeetingDate: moment().toISOString(),
            currentMeetingDateStr: this.formatDate(moment()),
            nextMeetingDate: nextMeetingDate || null,
            nextMeetingDateStr: nextMeetingDate ? this.formatDate(nextMeetingDate) : null,
          },
          objectiveChart: {
            assets: this.getObjectiveAssets(objectiveAssets, client),
          },
          priorityPlan: {
            assets: this.getPriorityPlanAssets(priorityPlan),
          },
          equity: {
            dataset: this.getEquity(
              currentEquity,
              advisorProposedEquity,
              clientProposedEquity,
              client,
            ),
          },
          payments: {
            overview: this.getPaymentsOverview(paymentsOverview),
            comparison: this.getPaymentsComparisonPersons(paymentCalendar),
            calendar: this.getPaymentsCalendarPersons(
              paymentCalendar,
              assets as FinancialProductsAsset[],
              objectives,
            ),
          },
        }),
      ),
    );
  }

  private getPaymentsComparisonPersons(
    paymentCalendar: PaymentCalendar,
  ): FPPrintPaymentsComparisonPerson[] {
    return paymentCalendar.comparison.persons
      .map(person => ({
        firstName: person.familyMember.firstName,
        lastName: person.familyMember.lastName,
        states: person.contractsByContractState.map(state => ({
          state: state.contractState,
          stateStr: contractStateNames[state.contractState] ?? null,
          assets: state.contracts.map(contract => ({
            name: contract.name,
            currentPayment: contract.currentPayment ?? 0,
            currentPaymentStr: formatCZK(contract.currentPayment ?? 0),
            proposedPayment: contract.proposedPayment ?? 0,
            proposedPaymentStr: formatCZK(contract.proposedPayment ?? 0),
          })),
        })),
      }))
      .filter(person => person.states.length > 0);
  }

  private getPaymentsCalendarPersons(
    paymentCalendar: PaymentCalendar,
    assets: FinancialProductsAsset[],
    objectives: FinancialPlanObjectives,
  ): FPPrintPaymentsCalendarPerson[] {
    return paymentCalendar.paymentsByPerson
      .map(person => ({
        firstName: person.familyMember.firstName,
        lastName: person.familyMember.lastName,
        states: person.paymentsByContractState.map(state => ({
          state: state.contractState,
          stateStr: contractStateNames[state.contractState] ?? null,
          assets: state.paymentDetails.map(contract => {
            const asset = assets.find(a => a.assetUuid === contract.assetUuid);
            if (!asset) return null;

            const objectiveType = this.findObjective(objectives, contract.assetUuid);

            return {
              objectiveType,
              objectiveTypeStr: objectiveTypeNames[objectiveType] ?? null,
              assetType: asset.type,
              assetName: assetNames[asset.type] ?? null,
              customName: (asset as NameAsset).name,
              fullName: getAssetName(asset as Asset),
              payment: contract.payment ?? 0,
              paymentStr: formatCZK(contract.payment ?? 0),
              frequency: asset.frequency,
              frequencyStr: regularPaymentTypeNames[contract.frequency] ?? null,
              paymentDay: contract.paymentDay,
              paymentDayStr: this.formatPaymentDay(contract.paymentDay, contract.frequency),
              paymentStart: contract.paymentStart,
              paymentStartStr: this.formatDate(contract.paymentStart),
              paymentStartStr2: this.formatDate2(contract.paymentStart),
              paymentEnd: contract.paymentEnd,
              paymentEndStr: this.formatDate(contract.paymentEnd),
              paymentEndStr2: this.formatDate2(contract.paymentEnd),
              contractNumber: contract.contractNumber,
              start: contract.start,
              startStr: this.formatDate(contract.start),
              startStr2: this.formatDate2(contract.start),
              end: contract.end,
              endStr: this.formatDate(contract.end),
              endStr2: this.formatDate2(contract.end),
              bankAccountNumber: contract.bankAccountNumber,
              variableSymbol: contract.variableSymbol,
              paymentNote: contract.paymentNote,
            };
          }),
        })),
      }))
      .filter(person => person.states.length > 0);
  }

  private getObjectiveAssets(
    objectiveAssets: ObjectiveAsset[],
    client: FamilyMember,
  ): FPPrintObjectiveAsset[] {
    return objectiveAssets.map(asset => {
      return {
        assetName: assetNames[asset.type],
        customName: asset.name,
        fullName: getAssetName(asset as Asset),
        date: asset.start,
        age: moment(asset.start).diff(client.birthDate, 'years'),
      };
    });
  }

  private getPriorityPlanAssets(priorityPlan: PriorityPlan): FPPrintPriorityPlanItem[] {
    return priorityPlan.items.map(item => ({
      objectiveType: item.objectiveType,
      objectiveTypeStr: objectiveTypeNames[item.objectiveType] ?? null,
      assetType: item.objectiveAsset?.type ?? null,
      assetName: item.objectiveAsset ? assetNames[item.objectiveAsset.type] : 'Další smlouvy',
      customName: item.objectiveAsset?.name ?? null,
      fullName: item.objectiveAsset ? getAssetName(item.objectiveAsset as Asset) : 'Další smlouvy',
      yearsFromNow: item.objectiveAsset ? item.when : null,
      yearsFromNowStr: item.objectiveAsset ? getYearsPluralString(item.when) : null,
      targetValue: item.objectiveAsset ? item.value : null,
      targetValueStr: item.objectiveAsset ? formatCZK(item.value) : null,
      originalFulfillment: this.getFulfillment(item, item.originalFulfillment),
      proposedFulfillment: this.getFulfillment(item, item.proposedFulfillment),
    }));
  }

  private getFulfillment(
    item: PriorityPlanItem,
    fulfillment: Fulfillment,
  ): FPPrintPriorityPlanFulfillment {
    if (!item.objectiveAsset) return null;
    return {
      type: fulfillment.type,
      percent: fulfillment.percent,
      percentStr: formatPercent(fulfillment.percent, 'cs', '1.0'),
      absoluteSelected: fulfillment.absoluteSelected,
      absoluteTotal: fulfillment.absoluteTotal,
      absoluteStr:
        fulfillment.type === FulfillmentType.Absolute
          ? `${fulfillment.absoluteSelected}/${fulfillment.absoluteTotal}`
          : null,
    };
  }

  private getEquity(
    currentEquity: [string, number][],
    advisorProposedEquity: [string, number][],
    clientProposedEquity: [string, number][],
    client: FamilyMember,
  ): FPPrintEquityChartRow[] {
    const currentYear = moment().get('year');
    return currentEquity.map((_, index) => {
      const currentRow = currentEquity[index];
      const advisorProposedRow = advisorProposedEquity[index];
      const clientProposedRow = clientProposedEquity[index];
      const age = moment(currentRow[0]).diff(client.birthDate, 'years');
      const fromNow = moment(currentRow[0]).get('year') - currentYear;
      const difference = clientProposedRow[1] - currentRow[1];

      return {
        age,
        ageStr: getYearsPluralString(age),
        fromNow,
        fromNowStr: getYearsPluralString(fromNow),
        currentValue: currentRow[1],
        currentValueStr: formatCZK(currentRow[1]),
        currentValueStr2: formatCzkInCzechFixedWidth(currentRow[1]),
        advisorProposedValue: advisorProposedRow[1],
        advisorProposedValueStr: formatCZK(advisorProposedRow[1]),
        advisorProposedValueStr2: formatCzkInCzechFixedWidth(advisorProposedRow[1]),
        proposedValue: clientProposedRow[1],
        proposedValueStr: formatCZK(clientProposedRow[1]),
        proposedValueStr2: formatCzkInCzechFixedWidth(clientProposedRow[1]),
        clientProposedValue: clientProposedRow[1],
        clientProposedValueStr: formatCZK(clientProposedRow[1]),
        clientProposedValueStr2: formatCzkInCzechFixedWidth(clientProposedRow[1]),
        differenceValue: difference,
        differenceValueStr: formatCZK(difference),
        differenceValueStr2: formatCzkInCzechFixedWidth(difference),
      };
    });
  }

  private findObjective(
    fpObjectives: FinancialPlanObjectives,
    assetUuid: string,
  ): ObjectiveType | null {
    const objectiveEntries = Object.entries(fpObjectives) as [ObjectiveType, Objective[]][];

    for (const [objectiveType, objectives] of objectiveEntries) {
      for (const objective of objectives) {
        for (const row of [
          ...objective.table.current.rows,
          ...objective.table.advisorProposed.rows,
          ...objective.table.clientProposed.rows,
        ]) {
          if (row.assetUuid === assetUuid) return objectiveType;
        }
      }
    }

    return null;
  }

  private formatTable(table: ValueTable): [string, number][] {
    return table.map(row => [moment(row[0]).format('YYYY-MM-DD'), row[1]]);
  }

  private formatDate(date: string | Moment): string {
    const m = moment(date);
    if (!m.isValid()) return null;
    return m.locale('cs-months').format('D. MMMM YYYY');
  }

  private formatDate2(date: string | Moment): string {
    const m = moment(date);
    if (!m.isValid()) return null;
    return m.format('D. M. YYYY').replace(/ /g, '\u00a0');
  }

  private getPaymentsOverview(overview: PaymentsOverview): FPPrintPaymentsOverview {
    return {
      currentPayment: overview.currentValue,
      currentPaymentStr: formatCZK(overview.currentValue),
      proposedPayment: overview.newValue,
      proposedPaymentStr: formatCZK(overview.newValue),
      difference: overview.savings,
      differenceStr: formatCZK(overview.savings),
      investment: overview.investmentFutureValue,
      investmentStr: formatCZK(overview.investmentFutureValue),
      differenceHtml: overview.differenceHtml,
    };
  }

  private formatPaymentDay(paymentDay: number, frequency: RegularPaymentType): string {
    if (!paymentDay || !frequency) return null;
    return `K ${paymentDay}. dni ${paymentDayNames[frequency]}`;
  }
}
