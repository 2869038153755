import {Component} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {LoginService} from '@lib/services';
import {DialogService} from '@shared/services/modal.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'kpt-e-record-modal',
  templateUrl: './e-record-modal.component.html',
  styleUrls: ['./e-record-modal.component.scss'],
})
export class ERecordModalComponent {
  familyMember: FamilyMember = null;

  constructor(private dialogService: DialogService, private loginService: LoginService) {}

  open(url: string) {
    this.dialogService.dismiss();
    window.open(url, '_blank');
  }

  get insuranceAvailable(): boolean {
    const jwtToken = this.loginService.jwtToken;
    return (
      jwtToken.zp || jwtToken.pmv || jwtToken.nop || jwtToken.npp || jwtToken.dps || jwtToken.inv
    );
  }

  get insuranceHref(): string {
    return this.getERecordClientUrl('pojisteni');
  }

  get canAccessConsumerLoans(): boolean {
    const jwtToken = this.loginService.jwtToken;
    return jwtToken.su || jwtToken.sub_attr;
  }

  get individualClient(): boolean {
    return this.familyMember.legalForm === 'person';
  }

  get consumerLoansAvailable(): boolean {
    return this.canAccessConsumerLoans && this.individualClient;
  }

  get consumerLoansHref(): string {
    return this.getERecordClientUrl('uveryFO');
  }

  get bankProductsAvailable(): boolean {
    const jwtToken = this.loginService.jwtToken;
    return jwtToken.st;
  }

  get bankProductsHref(): string {
    return this.getERecordClientUrl('uveryBezZzj');
  }

  get otherProductsAvailable(): boolean {
    const jwtToken = this.loginService.jwtToken;
    return (
      jwtToken.dps || jwtToken.inv || jwtToken.pvr || jwtToken.pmv || jwtToken.nop || jwtToken.st
    );
  }

  get otherProductsHref(): string {
    return this.getERecordClientUrl('bezZzj');
  }

  dismiss() {
    this.dialogService.dismiss();
  }

  private getERecordClientUrl(productArea: string): string {
    return `${environment.eRecordUrl}NewZzj/id=${this.familyMember.sugarUuid}&sekce=${productArea}`;
  }
}
