<div class="form-group" [ngClass]="customFormGroupClass" [attr.id]="id">
  <input
    class="form-control"
    type="text"
    [imask]="mask"
    [attr.min]="min"
    [attr.max]="max"
    unmask="typed"
    [formControl]="formControl"
    [readonly]="readonly"
    (blur)="onInputBlur($event.target.value)"
    (focus)="onInputFocus($event)"
  />

  <!-- suffix in input field, e.g: `Kč` -->
  <div *ngIf="!!appendText" class="input-group-append">
    <span class="input-group-append__text" [innerHTML]="appendText"> </span>
  </div>

  <!-- show only when kpt-invalid class is active -->
  <kpt-form-field-error
    [showErrorsOn]="showErrorsOn"
    [customErrors]="customErrors"
  ></kpt-form-field-error>
</div>
