import {createSelector} from '@ngrx/store';
import {AdvisorProposalState, financialProductAssets} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {selectAllAssets, selectCurrentAssets, selectProposedAssets} from '@shared/analysis/store';
import {without} from 'lodash';
import {isAssetAssignedToPriority} from '../financial-plan.utils';
import {ContractPair} from './additional-contracts.model';

export const selectAdditionalContractPairs = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  selectAllAssets,
  (currentAssets, proposedAssets, allAssets) => {
    const contractTypes = financialProductAssets;
    let currentContractAssets = currentAssets
      .filter(asset => !isAssetAssignedToPriority(asset, allAssets))
      .filter(asset => contractTypes.includes(asset.type)) as CommonFinancialProductAsset[];
    const proposedContractAssets = proposedAssets
      .filter(asset => !isAssetAssignedToPriority(asset, allAssets))
      .filter(asset => contractTypes.includes(asset.type)) as CommonFinancialProductAsset[];

    const contractPairs: ContractPair[] = proposedContractAssets.map(proposedAsset => {
      const currentAsset = currentContractAssets.find(
        asset => asset.assetUuid === proposedAsset.originalAssetUuid,
      );
      currentContractAssets = without(currentContractAssets, currentAsset);
      return {currentAsset, proposedAsset};
    });

    const currentUnchangedAssets = currentContractAssets.filter(
      asset =>
        !asset.advisorProposalState ||
        asset.advisorProposalState === AdvisorProposalState.Unchanged,
    );
    currentContractAssets = without(currentContractAssets, ...currentUnchangedAssets);
    contractPairs.push(
      ...currentUnchangedAssets.map(asset => ({currentAsset: asset, proposedAsset: asset})),
    );

    const currentTerminatedAssets = currentContractAssets.filter(
      asset => asset.advisorProposalState === AdvisorProposalState.Terminated,
    );
    currentContractAssets = without(currentContractAssets, ...currentTerminatedAssets);
    contractPairs.push(
      ...currentTerminatedAssets.map(asset => ({currentAsset: asset, proposedAsset: null})),
    );
    return contractPairs;
  },
);
