import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LifeInsurancePersonData} from '@shared/analysis/models/life-insurance-risks';
import {isFinancialStateBad} from '@shared/analysis/models/life-insurance-risks-vm';
import {AccordionModule} from '@shared/lib';
import {CheckboxModule, HelpModule} from '@shared/ui';
import {SwitchModule} from '@shared/ui/forms/switch';
import {map} from 'rxjs/operators';
import {LifeInsuranceService} from 'src/app/modules/life-insurance/life-insurance.service';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-reserves-accordion',
  templateUrl: './reserves-accordion.component.html',
  styleUrls: ['./reserves-accordion.component.scss'],
  imports: [CommonModule, FormsModule, AccordionModule, SwitchModule, CheckboxModule, HelpModule],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ReservesAccordionComponent {
  @Input()
  personId: string;

  @Input()
  personData: LifeInsurancePersonData;

  @Input() mode: 'analysis' | 'plan' | 'summary' = 'plan';

  selectedPerson$ = this.lifeInsuranceService.persons$.pipe(
    map(persons => persons.find(p => p.id === this.personId)),
  );

  disabled$ = this.selectedPerson$.pipe(
    map(selectedPerson => {
      if (this.mode === 'analysis') {
        return false;
      }
      if (this.mode === 'summary') {
        return true;
      }
      if (!selectedPerson) {
        return true;
      }
      return isFinancialStateBad(selectedPerson);
    }),
    untilDestroyed(this),
  );

  taxReliefHelp = `<div class="text-left">
      Podmínky pro daňové zvýhodnění soukromého životního pojištění podle zákona o daních z příjmů:
      <ul class="m-4">
        <li>pojistník=pojištěný</li>
        <li>pojištění sjednáno minimálně do 60&nbsp;let věku</li>
        <li>pojistná doba minimálně 5&nbsp;let</li>
        <li>pojistná smlouva neumožňuje výběry finančních prostředků během trvání pojištění</li>
      </ul>
      Proto je třeba zvážit a zvolit, zda chci využívat výhody daňového odpočtu zaplaceného pojistného a případně
      příspěvků zaměstnavatele, ale bez možnosti vybírat finanční prostředky ze smlouvy, nebo možnost výběrů finančních
      prostředků ze smlouvy, ale bez daňového zvýhodnění pro mě a mého zaměstnavatele.
  </div>`;

  constructor(private lifeInsuranceService: LifeInsuranceService) {}
}
