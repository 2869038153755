import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {calculateMonthlySum} from '@shared/analysis/evaluation';
import {RetirementInputMode} from '@shared/analysis/forms/definitions.models';
import {Asset, mainIncomesAssets} from '@shared/analysis/models/asset';
import {MainIncomesAsset} from '@shared/analysis/models/main-incomes';
import {ObjectivesAsset, RetirementAsset} from '@shared/analysis/models/objectives';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {getPensionByIncome} from '@shared/lib';
import * as moment from 'moment';
import {introTexts} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  getChartData,
  getTable,
  selectObjectiveAssetsByObjectiveType,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  PensionInput,
  PensionObjective,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export const selectPensionObjectives = createSelector(
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectObjectiveAssetsByObjectiveType(ObjectiveType.Pension),
  getFamilyMembers,
  (
    currentAssets,
    advisorProposedAssets,
    clientProposedAssets,
    pensionObjectiveAssets,
    familyMembers,
  ): PensionObjective[] => {
    return pensionObjectiveAssets.map((objectiveAsset: RetirementAsset): PensionObjective => {
      const input = getPensionInput(objectiveAsset, familyMembers, currentAssets);
      const objectiveValue = input?.reserve ?? 0;
      const currentTable = getTable(
        Situation.Current,
        currentAssets,
        objectiveAsset.assetUuid,
        objectiveValue,
        objectiveAsset.start,
      );
      const advisorProposedTable = getTable(
        Situation.AdvisorProposed,
        advisorProposedAssets,
        objectiveAsset.assetUuid,
        objectiveValue,
        objectiveAsset.start,
      );
      const clientProposedTable = getTable(
        Situation.ClientProposed,
        clientProposedAssets,
        objectiveAsset.assetUuid,
        objectiveValue,
        objectiveAsset.start,
      );
      const chart = getChartData(currentTable, advisorProposedTable, objectiveValue);
      const familyMember = familyMembers.find(p => p.sugarUuid === objectiveAsset.familyMemberUuid);

      return {
        type: ObjectiveType.Pension,
        objectiveAsset,
        objectiveValue,
        familyMemberUuid: objectiveAsset.familyMemberUuid,
        familyMember,
        name: getPensionObjectiveName(objectiveAsset),
        introText: introTexts[ObjectiveType.Pension],
        input,
        chart,
        table: {
          current: currentTable,
          advisorProposed: advisorProposedTable,
          clientProposed: clientProposedTable,
        },
        advisorProposedBenefit: advisorProposedTable.sum.finalValue - currentTable.sum.finalValue,
        clientProposedBenefit: clientProposedTable.sum.finalValue - currentTable.sum.finalValue,
      };
    });
  },
);

function getPensionObjectiveName(objectiveAsset: ObjectivesAsset): string {
  return objectiveAsset.name ? objectiveAsset.name : 'Zajištění na penzi';
}

function getPensionInput(
  retirementAsset: RetirementAsset,
  familyMembers: FamilyMember[],
  currentAssets: Asset[],
): PensionInput | null {
  const familyMember = familyMembers.find(p => p.sugarUuid === retirementAsset.familyMemberUuid);
  if (!familyMember) return null;

  const inputMode = retirementAsset.retirementInputMode;

  if (inputMode === RetirementInputMode.Rent && !retirementAsset.pensionDuration) {
    return null;
  }

  const pensionStartYear = moment(retirementAsset.start).year();
  const currentYear = moment().year();
  const reserveYear = pensionStartYear - currentYear;

  const mainIncome = getMainIncome(currentAssets, familyMember.sugarUuid);
  const statePension = getPensionByIncome(mainIncome);

  const monthlyValue = getMonthlyValue(retirementAsset);
  const ownMonthlyValue = Math.max(monthlyValue - statePension, 0);
  const totalMonthlyValue = monthlyValue + statePension;
  const reserve = getReserveValue(retirementAsset, ownMonthlyValue);

  return {
    inputMode,
    reserve,
    monthlyValue,
    statePension,
    ownMonthlyValue,
    totalMonthlyValue,
    reserveYear,
    pensionDuration: retirementAsset.pensionDuration,
    pensionLeaveYear: pensionStartYear,
    pensionLeaveAge: familyMember?.age + reserveYear,
  };
}

function getReserveValue(retirementAsset: RetirementAsset, ownMonthlyMoney: number): number {
  return retirementAsset.retirementInputMode === RetirementInputMode.Value
    ? retirementAsset.value
    : ownMonthlyMoney * 12 * retirementAsset.pensionDuration;
}

function getMonthlyValue(retirementAsset: RetirementAsset): number {
  return retirementAsset.retirementInputMode === RetirementInputMode.Value
    ? retirementAsset.value / retirementAsset.pensionDuration / 12
    : retirementAsset.monthlyRent;
}

function getMainIncome(currentAssets: Asset[], sugarUuid: string) {
  const mainIncomeAssets = currentAssets.filter(asset => mainIncomesAssets.includes(asset.type));
  const mainIncome = mainIncomeAssets.filter(
    (asset: MainIncomesAsset) => asset.familyMemberUuid === sugarUuid,
  );
  return calculateMonthlySum(mainIncome);
}
