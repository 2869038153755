/* tslint:disable:max-line-length max-classes-per-file */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {DeleteFilesParams} from '../../../../controllers/Integrations';

export enum Actions {
  START = '[Integrations deleteFiles] Start',
  SUCCESS = '[Integrations deleteFiles] Success',
  ERROR = '[Integrations deleteFiles] Error',
}

export class Start implements Action {
  readonly type = Actions.START;
  constructor(public payload: DeleteFilesParams) {}
}

export class Success implements Action {
  readonly type = Actions.SUCCESS;
  constructor(public payload: void) {}
}

export class Error implements Action {
  readonly type = Actions.ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export type DeleteFilesAction = Start | Success | Error;
