import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {
  fieldsRequiringAttention,
  isCommonFinancialProductAsset,
  isForeignContract,
} from '@shared/analysis/asset.utils';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {validObjectiveRelations, validPropertyRelations} from '@shared/analysis/models/relations';
import {FinancialAnalysisActions, selectAssetFromAll} from '@shared/analysis/store';
import {selectCoreContractByUuid} from '@shared/analysis/store/core-contracts.selectors';
import {IOption} from '@shared/ui';
import {cloneDeep} from 'lodash';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import {filter, finalize, first, map, mergeMap, tap} from 'rxjs/operators';
import {AssetDetailModalHandlerService} from 'src/app/modules/client/pages/asset-detail/asset-detail-modal-handler.service';
import {environment} from 'src/environments/environment';
import {AssetDefinition} from 'src/shared/analysis/forms/definitions.models';
import {Asset, assetNames, AssetType} from 'src/shared/analysis/models/asset';
import {State} from 'src/store';
import {v4 as uuid} from 'uuid';
import {AssetTypeGroup, SubModalData} from './asset-detail-modal.models';

@UntilDestroy()
@Component({
  selector: 'kpt-asset-detail-modal',
  templateUrl: './asset-detail-modal.component.html',
  styleUrls: ['./asset-detail-modal.component.scss'],
})
export class AssetDetailModalComponent implements OnInit {
  data$: Observable<any>;
  coreContract$: Observable<any>;
  @ViewChild(AssetFormComponent)
  assetForm: AssetFormComponent<Asset>;

  subModalOpened = false;
  subModalData: SubModalData;

  @Input()
  assetDefinition: AssetDefinition = {} as AssetDefinition;
  @Input()
  assetUuid: any = null;
  @Input()
  showDeleteButton = false;
  @Input()
  defaults = {};
  @Input()
  clone = false;
  @Input()
  result$ = new Subject<Asset>();

  chooseAssetTypeForm = new UntypedFormGroup({
    chooseAssetTypeFormControl: new UntypedFormControl(),
  });
  @Input()
  isSubModal: boolean;
  @Output()
  onBack = new EventEmitter<void>();
  @Input()
  newAssetTypeGroup: AssetTypeGroup;
  @Input()
  currentAssetType: AssetType;

  selectedType: AssetType = null;

  devMode = environment.name === 'local';

  loading$ = new BehaviorSubject<boolean>(false);

  readonly isForeignContract = isForeignContract;
  readonly isCommonFinancialProductAsset = isCommonFinancialProductAsset;
  readonly fieldsRequiringAttention = fieldsRequiringAttention;

  constructor(
    private store: Store<State>,
    private assetTypeService: AssetTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private assetHelperService: AssetHelperService,
    private assetDetailModalHandlerService: AssetDetailModalHandlerService,
    private assetsHandlerService: AssetsHandlerService,
  ) {}

  ngOnInit() {
    if (this.isSubModal && !this.assetUuid) {
      this.offerSelection();
      return;
    }

    const asset$ = this.assetUuid
      ? this.store.select(selectAssetFromAll(this.assetUuid)).pipe(
          filter(v => Boolean(v)),
          first(),
        )
      : of({} as Asset);

    this.coreContract$ = asset$.pipe(
      map(asset => (asset as any)?.sugarUuid),
      filter(sugarUuid => !!sugarUuid),
      mergeMap(sugarUuid => this.store.select(selectCoreContractByUuid(sugarUuid))),
    );

    this.data$ = asset$.pipe(
      tap(model => {
        if (model.type) {
          this.assetDefinition = this.assetTypeService.getAssetDefinition(model.type);
        }
        this.selectedType = this.assetDefinition.type;
      }),
      map(model => {
        return {
          ...this.assetDefinition,
          model: cloneDeep({...model, ...this.defaults}),
        };
      }),
    );

    this.assetDetailModalHandlerService.openSubModal$
      .pipe(
        filter(() => !this.subModalOpened),
        untilDestroyed(this),
      )
      .subscribe(subModalData => {
        if (subModalData && subModalData.assetUuid) {
          const result$ = new Subject<Asset>();
          result$.subscribe(model => {
            this.assetDetailModalHandlerService.updateAsset(model);
            this.closeSubModal();
          });
          this.subModalData = {...subModalData, result$};
          this.subModalOpened = true;
        } else if (subModalData) {
          const result$ = new Subject<Asset>();
          result$.subscribe(model => {
            if (subModalData.newAssetTypeGroup === AssetTypeGroup.Objective)
              this.setRelatedObjectiveUuid(model.assetUuid);
            else this.setRelatedPropertyUuid(model.assetUuid);
            this.closeSubModal();
          });
          this.subModalData = {...subModalData, result$};
          this.subModalOpened = true;
        }
      });
  }

  closeSubModal() {
    this.subModalOpened = false;
    this.subModalData = null;
  }

  save() {
    this.assetForm.setSubmitted();

    if (this.assetForm.isValid()) {
      const formValue = this.assetForm.getValue();
      const data = this.assetHelperService.prepareAsset({
        ...formValue,
        assetUuid: this.assetUuid ?? uuid(),
      });
      this.loading$.next(true);
      const saveAsset = () => {
        if (this.clone) {
          this.store.dispatch(
            FinancialAnalysisActions.FA_ClearProposalStateBeforeUpdate({
              originalAssetUuid: data.originalAssetUuid,
              clientProposal: Boolean(data.clientProposalState),
            }),
          );
        }
        return from(this.assetsHandlerService.saveAsset(data, true));
      };
      saveAsset()
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe(() => {
          this.close();
          this.result$.next(data);
        });
    }
  }

  async remove() {
    await this.assetsHandlerService.removeAsset(this.assetUuid);
    this.close();
  }

  close() {
    if (this.isSubModal) return;
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (returnUrl) this.router.navigateByUrl(returnUrl);
  }

  async findDefinition(assetType: AssetType) {
    if (!assetType) return;
    this.assetDefinition = this.assetTypeService.getAssetDefinition(assetType);
    this.selectedType = this.assetDefinition.type;

    const defaultValue = await this.assetTypeService.create(assetType);

    this.data$ = of({
      ...this.assetDefinition,
      model: {...defaultValue, type: assetType},
    });
  }

  get newAssetTypeSelect() {
    return `O jaký typ ${
      this.newAssetTypeGroup === AssetTypeGroup.Objective ? 'priority' : 'majetku'
    } se jedná?`;
  }

  private offerSelection() {
    const relations =
      this.newAssetTypeGroup === AssetTypeGroup.Objective
        ? validObjectiveRelations
        : validPropertyRelations;
    const assetTypeOptions = relations[this.currentAssetType].map(assetType => {
      return {
        key: assetType,
        label: assetNames[assetType],
      } as IOption;
    });
    this.data$ = of({
      assetTypeOptions,
      name: `Přidat ${
        this.newAssetTypeGroup === AssetTypeGroup.Objective ? 'prioritu' : 'majetek'
      }`,
    });
  }

  private setRelatedObjectiveUuid(assetUuid: string) {
    this.assetForm.patchValue({relatedObjectiveUuid: assetUuid});
  }

  private setRelatedPropertyUuid(assetUuid: string) {
    this.assetForm.patchValue({relatedPropertyUuid: assetUuid});
  }
}
