import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {defaultObjectiveMaxValue} from '@shared/analysis/forms/objectives.helpers';
import {CarAsset} from '@shared/analysis/models/objectives';
import {FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';

@Component({
  standalone: true,
  selector: 'kpt-car-form',
  templateUrl: './car-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    InvestmentRiskProfileComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CarFormComponent {
  @Input() asset: CarAsset;

  defaultObjectiveMaxValue = defaultObjectiveMaxValue;
}
