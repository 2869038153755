import {
  AssetType,
  BaseAsset,
  DateAsset,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';

export interface CreditProductsSumAsset extends BaseAsset, ValueAsset, DateAsset {
  type: AssetType.CreditProductsSum;
}

export interface CommonCreditAsset extends CommonFinancialProductAsset {
  // Asset has hidden field `value`

  // Group Detaily smlouvy
  /** Zbývá splatit; currency */
  outstandingValue: number;
  /** Zbývá splatit platné ke dni; datepicker, format YYYY-MM-DD */
  outstandingValueDate: string;
  /** Úroková sazba */
  yearlyRate: number;
}

export interface InsuredRisksByDebtor {
  debtorUuid: string;
  paymentProtectionInsurance: boolean;
  protectionRiskDeathDebt: boolean;
  protectionRiskInvalidity: boolean;
  protectionRiskIncapacity: boolean;
  protectionRiskLossOfWork: boolean;
  creditPackageKey: boolean;
  packageRiskDeathDebt: boolean;
  packageRiskInvalidity: boolean;
  packageRiskSeriousDisease: boolean;
  packageRiskIncapacity: boolean;
}

export interface PropertyLoanAsset
  extends CommonCreditAsset,
    RelatedObjectiveAsset,
    RelatedPropertyAsset {
  // Group Detaily smlouvy
  /** Účel */
  purpose: PropertyLoanPurpose;
  /** Počet let fixace */
  fixationInYears: number;
  /** Nejbližší datum fixace; datepicker, format YYYY-MM-DD */
  nearestFixationDate: string;

  // Group Původní hodnoty
  /** Původní banka */
  originalBank: string;
  /** Původní výše hypotéky; currency */
  originalValue: number;
  /** Původní LTV */
  originalLtv: number;

  // Group Detaily nemovitosti
  /** Předpokládaná aktuální hodnota zastavované nemovitosti; currency */
  propertyEstimatedValue: number;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field

  // Group Pojištění
  /** Je sjednáno pojištění nemovitosti? */
  hasRealEstateInsurance: boolean;
  /** Jaké pojištění nemovitosti? */
  realEstateInsurance: string;

  // Group Osoby
  /** Dlužníci; adult family members */
  debtorUuids: string[];
  /** Jiní dlužníci */
  otherDebtors: string;
  /** Je sjednaný produkt */
  insuredRisksByDebtors: InsuredRisksByDebtor[];
}

export enum PropertyLoanPurpose {
  /** koupě nemovitosti */
  PropertyPurchase = 'propertyPurchase',
  /** výstavba */
  Construction = 'construction',
  /** rekonstrukce */
  Reconstruction = 'reconstruction',
  /** refundace (zpětné proplacení již uhrazené investice) */
  Refund = 'refund',
  /** konsolidace */
  Consolidation = 'consolidation',
  /** refinancování */
  Refinancing = 'refinancing',
  /** vypořádání dědictví */
  InheritanceSettlement = 'inheritanceSettlement',
  /** převod členských práv */
  MembershipRightsTransfer = 'membershipRightsTransfer',
  /** vypořádání podílů */
  SharesSettlement = 'sharesSettlement',
  /** jiný účel */
  OtherPurpose = 'otherPurpose',
}

export interface MortgageAsset extends PropertyLoanAsset {
  type: AssetType.Mortgage;
}

export interface BuildingSavingsLoanAsset extends PropertyLoanAsset {
  type: AssetType.BuildingSavingsLoan;
}

export interface LeasingAsset extends CommonCreditAsset, RelatedPropertyAsset {
  type: AssetType.Leasing;
  // Group Detaily smlouvy
  /** Smlouva pro */
  leasingType: LeasingType;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field
}

export enum LeasingType {
  /** Fyzická osoba */
  Private = 'private',
  /** Fyzická osoba – podnikatel */
  Business = 'business',
}

export interface CreditCardAsset extends CommonCreditAsset {
  type: AssetType.CreditCard;
  // Group Detaily smlouvy
  /** Úvěrový limit; currency */
  creditLimit: number;
}

export interface OverdraftAsset extends CommonCreditAsset {
  type: AssetType.Overdraft;
  // Group Detaily smlouvy
  /** Úvěrový limit; currency */
  creditLimit: number;
}

export interface ConsumerLoanAsset extends CommonCreditAsset, RelatedPropertyAsset {
  type: AssetType.ConsumerLoan;
  // Group Detaily smlouvy
  /** Původní banka */
  originalBank: string;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field
}

export interface OtherIndividualLoanAsset extends CommonCreditAsset, RelatedPropertyAsset {
  type: AssetType.OtherIndividualLoan;
  // Group Detaily smlouvy
  /** Účel */
  purpose: string;
  /** Původní výše */
  originalValue: number;
  /** Zajištění */
  reinsurance: boolean;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field
}

export type CreditAsset =
  | MortgageAsset
  | BuildingSavingsLoanAsset
  | LeasingAsset
  | CreditCardAsset
  | OverdraftAsset
  | ConsumerLoanAsset
  | OtherIndividualLoanAsset;
