import {Component} from '@angular/core';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'kpt-datepicker-formly',
  templateUrl: './datepicker-formly.component.html',
})
export class DatepickerFormlyComponent extends FieldType {
  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.translate) {
      this.to.translate(field, $event);
    }
  }
}
