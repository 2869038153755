import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

const financialAnalysis = {
  url: 'financial-analysis',
  done: false,
  label: 'Finanční analýza',
};
const whyInvests = {
  url: 'why-invest',
  done: false,
  label: 'Proč investovat',
};
const investmentReturn = {
  url: 'investment-return',
  done: false,
  label: 'Výnos investic',
};
const whereToInvest = {
  url: 'where-to-invest',
  done: false,
  label: 'Kam investovat',
};
const investmentSurvey = {
  url: 'investment-survey',
  done: false,
  label: 'Preference klienta',
};
const investmentSummary = {
  url: 'investment-summary',
  done: false,
  label: 'Shrnutí',
};

export const investmentStepperProgressConfig: IStepperProgressItem[] = createStepperProgressConfig(
  [
    financialAnalysis,
    whyInvests,
    investmentReturn,
    whereToInvest,
    investmentSurvey,
    investmentSummary,
  ],
  SecuredPage.Client,
  ClientPage.InvestmentOld,
);

export const investmentStepperProgressConfigInModal: IStepperProgressItem[] = [
  whyInvests,
  investmentReturn,
  whereToInvest,
];
