<div class="modal show fade d-block">
  <div class="modal-dialog modal-dialog-centered" [ngClass]="windowClass">
    <div class="modal-content">
      <kpt-asset-detail-modal
        [result$]="result$"
        [assetUuid]="assetUuid"
        [assetDefinition]="assetDefinition"
        [showDeleteButton]="showDeleteButton"
        [defaults]="defaults"
        [clone]="clone"
      >
      </kpt-asset-detail-modal>
    </div>
  </div>
</div>
