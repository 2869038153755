import {ObjectiveAsset} from '@shared/analysis/models/objectives';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {Fulfillment} from 'src/app/modules/financial-plan/store/objectives.models';
import {ObjectiveItem} from '../objectives/objectives.models';

export interface FinancialOverview {
  income: number;
  passiveIncome: number;
  averageIncomeByFamilyMember: number;
  incomeByFamilyMember: number[];
  monthlyBalance: number;
  currentAccountBalance: number;
  savingAccountBalance: number;
  expenses: number;
  indispensableExpenses: number;
  financialReserve: number;
  useInvestmentsProducts: boolean;
  hasBadDebtsRepayment: boolean;
  hasCommonBadDebts: boolean;
}

export interface FinancialPlanData {
  financial: FinancesPropertyData;
  properties: FinancesPropertyData;
  objectives: ObjectiveItem[];
  additionalContracts: AdditionalContractsData;
}

export interface FinancesPropertyData {
  evaluation: SentencesAndRating;
  recommendations: SentencesAndRating;
}

export interface AdditionalContractsData {
  advisorSolution: string;
}

export interface SentencesAndRating {
  rating: number;
  sentences: Sentence[];
}

export interface Sentence {
  id: string;
}

/** Vaše příjmy */
export interface IncomesChart {
  persons: PersonIncome[];
}

/** Vaše výdaje */
export interface ExpensesChart {
  indispensable: number;
  dispensable: number;
  financialProducts: number;
}

/** Potenciální majetek */
export interface PotentialPropertyChart {
  items: PropertyItem[];
}

/** Struktura majetku */
export interface PropertyStructureChart {
  items: PropertyItem[];
}

/** Závazky */
export interface LiabilitiesChart {
  /** Aktuální hodnota celkového majetku */
  totalProperties: number;
  /** Aktuální výše závazků */
  liabilities: number;
}

/** Prostředky ohrožené inflací */
export interface InflationRisk {
  amount: number;
  years: number;
  loss: number;
}

export interface PersonIncome {
  label: string;
  amount: number;
}

export interface PropertyItem {
  label: string;
  amount: number;
}

export type ObjectiveArea = 'current' | 'proposed';

export interface PrintSettingsUI {
  tree: PrintSection;
  hideCurrent?: boolean;
}

export interface PrintSection<T extends string = string> {
  id: T;
  name?: string;
  print?: boolean;
  parentId?: string;
  children?: PrintSection[];
}

export interface PaymentsOverview {
  newValue: number;
  investmentFutureValue: number;
  savings: number;
  currentValue: number;
  differenceHtml: string;
}

export interface PriorityPlan {
  items: PriorityPlanItem[];
}

export interface PriorityPlanItem {
  objectiveUuid: string;
  objectiveType: ObjectiveType;
  objectiveAsset: ObjectiveAsset;
  name: string;
  when: number;
  value: number;
  originalFulfillment: Fulfillment;
  proposedFulfillment: Fulfillment;
}
