import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@shared/ui/button/button.module';
import {FormModule} from '@shared/ui/forms/form.module';
import {PaginationComponent} from '@shared/ui/pagination/pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  imports: [ButtonModule, CommonModule, FormModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
