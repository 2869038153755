import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AttachmentsModalData} from '@shared/lib/components/modal/modals/attachments-modal/attachments-modal.models';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {StoreFileUpdate, UIFile} from '@shared/models/file.models';
import {FileItem} from 'ng2-file-upload';

export enum Status {
  Uploading = 'uploading',
  Canceled = 'canceled',
  Ready = 'ready',
}

@UntilDestroy()
@Component({
  selector: 'kpt-attached-file-tile',
  templateUrl: './attached-file-tile.component.html',
  styleUrls: ['./attached-file-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFileTileComponent implements OnInit, OnDestroy {
  @Input() file: UIFile;
  @Input() shortcut: boolean;
  @Output() update = new EventEmitter<StoreFileUpdate>();
  @Output() removeFromQueue = new EventEmitter<FileItem>();
  @Output() showDeleteConfirmation = new EventEmitter<UIFile>();

  status: Status;
  Status = Status;

  private modalDone$ = new EventEmitter<UIFile[]>();

  constructor(
    private modalService: ModalService,
    private lifeInsuranceData: LifeInsuranceDataService,
    private cd: ChangeDetectorRef,
  ) {}

  @HostBinding() get class() {
    return this.status ? `file--${this.status}` : null;
  }

  get createdDate() {
    return this.file && new Date(this.file.created);
  }

  ngOnInit() {
    this.initStatusAndItem();

    this.modalDone$
      .pipe(untilDestroyed(this))
      .subscribe((files: UIFile[]) => this.onModalDone(files));
  }

  ngOnDestroy() {}

  onCancelUpload() {
    this.file.item.cancel();
    this.status = Status.Canceled;
  }

  onRemoveFromQueue() {
    this.removeFromQueue.emit(this.file.item);
  }

  onRepeatUpload() {
    this.file.item.upload();
    this.status = Status.Uploading;
  }

  onEdit() {
    const data: AttachmentsModalData = {
      files: [this.file],
      onSubmit: this.modalDone$,
    };
    this.modalService.openModal({
      title: 'Upravit soubor',
      component: 'AttachmentsModalComponent',
      data,
    });
  }

  onRemove() {
    this.showDeleteConfirmation.emit(this.file);
  }

  onPreview() {
    if (this.status !== Status.Ready) {
      return;
    }
    // TODO make generic
    this.lifeInsuranceData.getFile(this.file.dmsUuid).subscribe(previewFileBlob => {
      if (this.file.mimeType === 'application/octet-stream') {
        this.openOrSaveFile(previewFileBlob);
      } else {
        const fileUrl = window.URL.createObjectURL(previewFileBlob);
        window.open(fileUrl);
      }
    });
  }

  private initStatusAndItem() {
    if (!this.file.item) {
      this.status = Status.Ready;
      return;
    }
    const {item} = this.file;
    item.onError = () => {
      this.status = Status.Canceled;
      this.cd.markForCheck();
    };
    if (item.isCancel || item.isError) {
      this.status = Status.Canceled;
      return;
    }
    // TODO new status pending?
    // if (item.isUploading) {
    this.status = Status.Uploading;
    // }
  }

  private openOrSaveFile(blob: Blob) {
    // TODO move to utils
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.file.name;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  private onModalDone([{name}]: UIFile[]) {
    this.modalService.closeModal();
    // this.file.name = file.name;
    this.update.emit({dmsUuid: this.file.dmsUuid, name});
  }
}
