import {Directive, HostBinding, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {CommonFieldComponent} from './common-field.component';

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'kpt-input, kpt-datepicker, kpt-datetimepicker, kpt-select, kpt-radio-group, kpt-toggle, kpt-checkbox',
})
export class CommonFieldStatusesDirective {
  private cd: NgControl;

  constructor(@Optional() controlDirective: NgControl, private el: CommonFieldComponent) {
    this.cd = controlDirective;
  }

  @HostBinding('class.kpt-is-invalid') get getInvalidClass() {
    if (this.cd) {
      return (
        this.cd.control.status === 'INVALID' &&
        (this.cd.control.touched || (this.el && this.el.showErrorsOn === true))
      );
    }
  }

  @HostBinding('class.kpt-is-valid') get getValidClass() {
    if (this.cd) return this.cd.control.status === 'VALID';
  }

  @HostBinding('class.kpt-is-pending') get getPendingClass() {
    if (this.cd) return this.cd.control.status === 'PENDING';
  }

  @HostBinding('class.kpt-is-touched') get getTouchedClass() {
    if (this.cd) return this.cd.control.touched;
  }

  @HostBinding('class.kpt-is-not-empty') get getNotEmptyClass() {
    if (this.cd) {
      return (
        this.cd.control.value !== null &&
        this.cd.control.value !== '' &&
        this.cd.control.value !== undefined
      );
    }
  }

  @HostBinding('class.kpt-is-success') get getSuccessClass() {
    if (this.cd) return this.el && this.el.success;
  }

  @HostBinding('class.kpt-is-warning') get getWarningClass() {
    if (this.cd) return this.el && this.el.warning;
  }

  @HostBinding('class.kpt-is-dirty') get getDirtyClass() {
    if (this.cd) return this.cd.control.dirty;
  }

  @HostBinding('class.kpt-is-pristine') get getPristineClass() {
    if (this.cd) return this.cd.control.pristine;
  }

  @HostBinding('class.kpt-is-disabled') get getDisabledClass() {
    if (this.cd) return this.cd.control.disabled;
  }
}
