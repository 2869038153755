import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {GetPortalFamilyMembersModule} from '@generated/store/integrations/getPortalFamilyMembers/getPortalFamilyMembers.module';
import {ConfirmDialogModule} from '@shared/building-blocks/confirm-dialog/confirm-dialog.module';
import {DirectivesModule} from '@shared/directives';
import {ClientSearchCreateFormModule} from '@shared/lib/components/client-search-create/client-search-create-form.module';
import {PopUpBlockedModalComponent} from '@shared/lib/components/modal/modals';
import {AddFamilyMemberModalComponent} from '@shared/lib/components/modal/modals/add-family-member-modal/add-family-member-modal.component';
import {ClientRequirementsModalComponent} from '@shared/lib/components/modal/modals/client-requirements-modal/client-requirements-modal.component';
import {ProposalsProductComponent} from '@shared/lib/components/modal/modals/proposals-product/proposals-product.component';
import {AlertModule, ButtonModule, FormModule, MenuItemModule, ModalModule} from '@shared/ui';
import {DropdownBootstrapModule} from '@shared/ui/dropdown-bootstrap/dropdown-bootstrap.module';
import {ConfirmFieldModule} from '@shared/ui/forms/confirm-field/confirm-field.module';
import {SwitchModule} from '@shared/ui/forms/switch';
import {ClientSearchModule} from 'src/app/modules/consultant/client-selection/client-search';
import {ModalDirective} from './directives/modal.directive';
import {ModalContainerComponent} from './modal-container.component';
import {AttachmentsModalComponent} from './modals/attachments-modal/attachments-modal.component';
import {CancelMeetingModalComponent} from './modals/cancel-meeting-modal/cancel-meeting-modal.component';
import {FamiliesModalComponent} from './modals/families-modal/families-modal.component';
import {ProposalsAlternativesComponent} from './modals/proposals-alternatives/proposals-alternatives.component';
import {ReassignComponent} from './modals/reassign/reassign.component';

@NgModule({
  declarations: [
    ModalContainerComponent,
    ModalDirective,
    FamiliesModalComponent,
    ClientRequirementsModalComponent,
    ProposalsAlternativesComponent,
    ReassignComponent,
    ProposalsProductComponent,
    CancelMeetingModalComponent,
    AttachmentsModalComponent,
    PopUpBlockedModalComponent,
    AddFamilyMemberModalComponent,
  ],
  exports: [
    ModalContainerComponent,
    ClientRequirementsModalComponent,
    AddFamilyMemberModalComponent,
  ],
  imports: [
    DropdownBootstrapModule,
    CommonModule,
    ConfirmDialogModule,
    ConfirmFieldModule,
    MenuItemModule,
    ModalModule,
    FormModule,
    ReactiveFormsModule,
    ButtonModule,
    DirectivesModule,
    GetPortalFamilyMembersModule,
    AlertModule,
    ClientSearchCreateFormModule,
    ClientSearchModule,
    SwitchModule,
  ],
})
export class ModalContainerModule {}
