import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {formatCZK} from '@lib/utils';
import {getPensionByIncome} from '@shared/lib';
import {IOption} from '@shared/ui';
import {AVERAGE_PENSION} from 'src/app/modules/life-insurance-old/constants';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';

@Component({
  selector: 'kpt-pension-ensurement-accordion-old',
  templateUrl: './pension-ensurement-accordion-old.component.html',
  styleUrls: ['./pension-ensurement-accordion-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PensionEnsurementAccordionOldComponent {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  person: LifeInsurancePerson;

  @Input()
  readonly: boolean;

  averagePension = AVERAGE_PENSION;

  pensionEnsurementHelp = `Měsíční výdaje průměrného důchodce:
    <table class="mt-4 mx-auto">
      <tr><td class="text-left">Jídlo:</td><td class="text-right">${formatCZK(2_790)}</td></tr>
      <tr><td class="text-left">Bydlení:</td><td class="text-right">${formatCZK(347)}</td></tr>
      <tr><td class="text-left">Cíle a potřeby:</td><td class="text-right">${formatCZK(
        2_275,
      )}</td></tr>
      <tr><td class="text-left">Spoření a pojištění:</td><td class="text-right">${formatCZK(
        237,
      )}</td></tr>
      <tr><td class="text-left">Ostatní výdaje:</td><td class="text-right">${formatCZK(
        2_804,
      )}</td></tr>
      <tr><td class="text-left">Úvěry:</td><td class="text-right">${formatCZK(271)}</td></tr>
      <tr><th class="text-left pt-4">Celkem:</th><th class="text-right pt-4">${formatCZK(
        this.averagePension,
      )}</th></tr>
    </table>
  `;

  get pension() {
    return getPensionByIncome(this.person.income);
  }

  get ensurementTypes(): IOption[] {
    const income = formatCZK(this.person.income);
    const expenses = formatCZK(this.person.expenses);
    const average = formatCZK(this.averagePension);
    return [
      {key: 'income', label: `aby moje příjmy byly v důchodu stejné jako dnes (${income})`},
      {key: 'expense', label: `aby moje výdaje byly v důchodu pokryté jako dnes (${expenses})`},
      {
        key: 'average',
        label: `aby u mě byly v důchodu pokryty průměrné výdaje důchodce v ČR (${average})`,
      },
    ];
  }

  constructor() {}
}
