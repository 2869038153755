<div class="notification-message-wrapper">
  <kpt-notification-message
    *ngIf="connectionInfo"
    @enterAnimation
    [canHide]="connectionInfo.type === MessageType.ONLINE"
    [message]="connectionInfo"
    (dismiss)="dismissConnectionInfo()"
  >
  </kpt-notification-message>

  <ng-container *ngFor="let message of messages; let i = index">
    <kpt-notification-message
      *ngIf="message.type === MessageType.NEW_VERSION"
      @enterAnimation
      [canHide]="false"
      [message]="message"
    >
      <span class="refresh-button" messageAction (click)="refresh()"> Aktualizovat </span>
    </kpt-notification-message>

    <kpt-notification-message
      @enterAnimation
      *ngIf="i < 3 && message.type !== MessageType.NEW_VERSION"
      [canHide]="i === 0"
      [message]="message"
      (dismiss)="dismiss(i)"
    >
    </kpt-notification-message>
  </ng-container>
  <kpt-notification-message
    @enterAnimation
    *ngIf="messages.length > messagesToShow"
    [message]="messagesQueue"
    (dismiss)="dismiss(messagesToShow)"
  >
  </kpt-notification-message>
</div>
