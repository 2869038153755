import {Injectable, Type} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AssetType} from '@shared/analysis/models/asset';
import {ClientContainerComponent} from 'src/app/modules/client';

@Injectable()
export class AssetLinkService {
  constructor(private router: Router) {}

  async navigateToAdd(assetType: AssetType, queryParams: Params = {}) {
    await this.navigate(this.getAddLink(assetType), queryParams);
  }

  async navigateToEdit(assetUuid: string, queryParams: Params = {}) {
    await this.navigate(this.getEditLink(assetUuid), queryParams);
  }

  getAddLink(assetType: AssetType) {
    return {outlets: {assetDetail: 'asset/new/' + assetType}};
  }

  getEditLink(assetUuid: string) {
    return {outlets: {assetDetail: 'asset/edit/' + assetUuid}};
  }

  getCloneLink(assetUuid: string) {
    return {outlets: {assetDetail: 'asset/clone/' + assetUuid}};
  }

  private async navigate(link: any, queryParams: Params) {
    const containerRoute = this.findRoute(ClientContainerComponent);
    if (!containerRoute) {
      throw new Error(
        `Cannot navigate to asset detail, route for ClientContainerComponent not found`,
      );
    }
    await this.router.navigate(['.', link], {
      relativeTo: containerRoute,
      queryParams: {...queryParams, returnUrl: this.router.url},
    });
  }

  private findRoute(component: Type<any>, route?: ActivatedRoute): ActivatedRoute {
    route = route ?? this.router.routerState.root;

    if (route.snapshot.component === component) {
      return route;
    }
    for (const child of route.children) {
      const found = this.findRoute(component, child);
      if (found) {
        return found;
      }
    }
    return null;
  }
}
