import {Component, Input} from '@angular/core';
import {
  AdvisorProposalState,
  ClientProposalState,
  ContractState,
} from '@shared/analysis/models/asset';
import {
  advisorProposalStateNames,
  contractStateNames,
  clientProposalStateNames,
} from '@shared/analysis/models/assets.enums';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';

@Component({
  selector: 'kpt-proposal-state',
  templateUrl: './proposal-state.component.html',
  styleUrls: ['./proposal-state.component.scss'],
})
export class ProposalStateComponent {
  @Input()
  state: AdvisorProposalState | ClientProposalState | ContractState;

  @Input()
  situation: Situation;

  @Input()
  contract = false;

  advisorProposalStateNames = advisorProposalStateNames;
  contractStateNames = contractStateNames;
  clientProposalStateNames = clientProposalStateNames;
  Situation = Situation;
}
