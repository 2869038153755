import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {Dictionary} from 'lodash';
import {MenuItem} from './menu.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'kpt-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit {
  @Input() items: (MenuItem | MenuItem[])[] = [];
  @Input() startingIndex: string;
  @Input() divider = true;

  @Output() itemClick: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  toggledItems: Dictionary<boolean> = {};

  ngOnInit() {
    this.setDefaultToggleState();
  }

  trackByFn = (index: number) => {
    return this.getIndexString(index);
  };

  isNewList(item: MenuItem | MenuItem[]): boolean {
    return Array.isArray(item);
  }

  lastItem(index: number): boolean {
    return this.items.length - 1 === index;
  }

  getIndexString(...indexItems: (number | string)[]) {
    return [this.startingIndex, ...indexItems]
      .filter(singleIndex => typeof singleIndex !== 'undefined')
      .join('_');
  }

  toggle(index: string) {
    if (!this.toggledItems.hasOwnProperty(index)) {
      this.toggledItems[index] = true;
      return;
    }
    this.toggledItems[index] = !this.toggledItems[index];
  }

  showSubMenu(index: string) {
    return this.toggledItems[index];
  }

  getItems() {
    return this.items.filter((singleItem: MenuItem | MenuItem[]) => {
      if (this.isNewList(singleItem)) {
        return true;
      }
      if (!singleItem) {
        return false;
      }
      const {disabled} = singleItem as MenuItem;
      return typeof disabled !== 'boolean' || !disabled;
    });
  }

  private getSingleItems(): MenuItem[] {
    return this.getItems().filter(item => !this.isNewList(item)) as MenuItem[];
  }

  private setDefaultToggleState() {
    this.getSingleItems().forEach((item, index) => {
      const indexString = this.getIndexString(index);
      if (item.toggledAtInit && !this.toggledItems.hasOwnProperty(indexString)) {
        this.toggledItems[indexString] = true;
      }
    });
  }
}
