<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">
        {{ meetingCanceled ? 'Jednání s klientem bylo ukončeno' : title }}
      </h3>
    </div>
  </div>
</div>
<div class="modal-body" [formGroup]="form">
  <ng-container *ngIf="!meetingCanceled || !(data.eRecordSent$ | async); else canceledMeetingView">
    <div class="cancel-options">
      <div class="col-10 offset-1">
        <label>Odůvodnění</label>
      </div>
      <div class="col-10 offset-1">
        <kpt-single-radio-button
          [controlForm]="form.get('reason')"
          [disabled]="meetingCanceled"
          label="Klient odmítl vyplnit Preference klienta v oblasti investování"
          [value]="ReasonType.REFUSE_TO_FILL_SURVEY"
        >
        </kpt-single-radio-button>
      </div>
      <div class="col-10 offset-1 other-requirement">
        <kpt-single-radio-button
          [controlForm]="form.get('reason')"
          [disabled]="meetingCanceled"
          label="Jiný"
          [value]="ReasonType.OTHER_REASON"
        >
        </kpt-single-radio-button>
        <kpt-input
          [disabledField]="meetingCanceled"
          [formControl]="form.get('otherReasonText')"
          label="&nbsp;"
        ></kpt-input>
      </div>
    </div>
    <div class="text-center" *ngIf="meetingCanceled">
      <kpt-alert type="info"> Probíhá odesílání záznamu z jednání… </kpt-alert>
    </div>
    <div class="d-flex justify-content-center">
      <kpt-button type="primary" [isDisabled]="meetingCanceled" (action)="closeModal()"
        >NE, chci pokračovat</kpt-button
      >
      <kpt-button
        type="primary"
        [isDisabled]="!form.valid || meetingCanceled"
        (action)="sendERecord()"
      >
        ANO, chci ukončit
      </kpt-button>
    </div>
  </ng-container>
  <ng-template #canceledMeetingView>
    <div class="d-flex justify-content-center">
      <kpt-button type="primary" (action)="closeModal(true)">Zavřít</kpt-button>
    </div>
  </ng-template>
</div>
