import {Pipe, PipeTransform} from '@angular/core';
import {groupBy, ValueIteratee} from 'lodash';

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe<T extends object = object> implements PipeTransform {
  transform(data: T, iteratee?: ValueIteratee<T[keyof T]>): any {
    return groupBy(data, iteratee);
  }
}
