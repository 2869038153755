import {LifeInsuranceFile} from '@generated/defs/LifeInsuranceFile';
import {Summary} from '@generated/defs/Summary';
import {Choice} from '@generated/model';
import {
  AdditionalAnalysisData,
  LifeInsuranceCustomRiskDefinition,
  LifeInsuranceForm,
  LifeInsurancePerson,
  LifeInsuranceRisk,
  LifeInsuranceUI,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';

export interface LifeInsuranceState {
  forms: LifeInsuranceForm[];
  persons: LifeInsurancePerson[];
  risks: LifeInsuranceRisk[];
  customRiskDefinitions: LifeInsuranceCustomRiskDefinition[];
  files: LifeInsuranceFile[];
  choice: Choice;
  summaries: Summary[];
  ui: LifeInsuranceUI;
  additionalAnalysisData: AdditionalAnalysisData;
}

export const initialChoice: Choice = {
  amount: null,
  securingDependentPersons: false,
  evenUpIncome: false,
  rebuildExpenses: false,
  medicalCostsCoverage: false,
  utilitiesPurchase: false,
  liabilitiesCoverage: false,
  higherStandard: false,
  other: false,
  otherText: '',
};

export const initialState: LifeInsuranceState = {
  ui: {
    expandedForm: {},
    showInsuranceCalculations: false,
    summariesProgress: {
      unfinishedParticipantIds: [],
      selectedParticipantId: null,
    },
    financialAnalysisChanged: false,
  },
  forms: [],
  persons: [],
  risks: [],
  customRiskDefinitions: [],
  files: [],
  choice: initialChoice,
  summaries: [],
  additionalAnalysisData: {debtsTogether: undefined},
};
