<kpt-accordion-switch
  *ngIf="!person.child"
  label="Mám rezervu ve výši 6 měsíčních příjmů"
  class="switch"
  [open]="person.useSixMonthReserve"
  (openChange)="toggleUseSixMonthReserve()"
>
  Rezervu využiji pro následující rizika:
  <form [formGroup]="formGroup" class="risks">
    <kpt-checkbox [label]="labels.hospitalization" formControlName="hospitalization"></kpt-checkbox>
    <kpt-checkbox [label]="labels.incapacity" formControlName="incapacity"></kpt-checkbox>
    <kpt-checkbox
      [label]="labels.dailyCompensation"
      formControlName="dailyCompensation"
    ></kpt-checkbox>
    <kpt-checkbox [label]="labels.physicalDamage" formControlName="physicalDamage"></kpt-checkbox>
  </form>
</kpt-accordion-switch>
