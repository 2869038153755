import {createSelector} from '@ngrx/store';
import {selectPropertiesOverview} from '@shared/analysis/store';
// eslint-disable-next-line max-len
import {
  selectCreditCategory,
  selectFinancialCategory,
  selectImmovableCategory,
  selectInsuranceCategory,
  selectInvestmentCategory,
  selectMovableCategory,
} from 'src/app/modules/client/summary/store/properties';
import {formatCZKNotNull} from 'src/app/modules/client/summary/store/summary.helpers';
import {AssetPageOverview} from 'src/app/modules/financial-analysis/shared/models/overview.model';
import {Category, Section} from './summary.models';

export const selectProperties = createSelector(
  selectPropertiesOverview,
  selectImmovableCategory,
  selectMovableCategory,
  selectInsuranceCategory,
  selectInvestmentCategory,
  selectFinancialCategory,
  selectCreditCategory,
  (
    propertiesOverview,
    immovableCategory,
    movableCategory,
    insuranceCategory,
    investmentCategory,
    financialCategory,
    creditCategory,
  ) =>
    createSection(propertiesOverview, [
      immovableCategory,
      movableCategory,
      insuranceCategory,
      investmentCategory,
      financialCategory,
      creditCategory,
    ]),
);

function createSection(propertiesOverview: AssetPageOverview[], categories: Category[]): Section {
  return {
    label: 'Majetek',
    headerItems: propertiesOverview.map(overview => ({
      ...overview,
      value: formatCZKNotNull(overview.value),
    })),
    categories,
  };
}
