import {
  AssetType,
  basicInvestmentAssets,
  creditAssets,
  immovablePropertyAssets,
  movablePropertyAssets,
  pensionInvestmentAssets,
} from '@shared/analysis/models/asset';
import {without} from 'lodash';

export interface AssetRelation {
  sourceTypes: AssetType[];
  targetTypes: AssetType[];
}

export type ValidAssetRelations = Record<AssetType, AssetType[]>;

export type RelationType = 'relatedObjectiveUuid' | 'relatedPropertyUuid' | 'stakeholderUuid';

const objectiveAssetRelations: AssetRelation[] = [
  {
    sourceTypes: [
      AssetType.InvestmentLifeInsurance,
      AssetType.RiskLifeInsurance,
      AssetType.CapitalLifeInsurance,
    ],
    targetTypes: [AssetType.FamilyProvision],
  },
  {
    sourceTypes: [AssetType.PropertyInsurance],
    targetTypes: [AssetType.PropertyProvision],
  },
  {
    sourceTypes: [AssetType.VehicleInsurance],
    targetTypes: [AssetType.VehicleProvision],
  },
  {
    sourceTypes: [AssetType.InvestmentLifeInsurance, AssetType.CapitalLifeInsurance],
    targetTypes: [AssetType.Retirement, AssetType.LongTermReserve, AssetType.FinancialIndependence],
  },
  {
    sourceTypes: [...pensionInvestmentAssets],
    targetTypes: [AssetType.FinancialIndependence],
  },
  {
    sourceTypes: [
      ...basicInvestmentAssets,
      ...pensionInvestmentAssets,
      AssetType.CurrentAccountUsage,
    ],
    targetTypes: [AssetType.Retirement],
  },
  {
    sourceTypes: [...basicInvestmentAssets, AssetType.CurrentAccountUsage],
    targetTypes: [
      AssetType.FinancialIndependence,
      AssetType.ShortTermReserve,
      AssetType.LongTermReserve,
      AssetType.OwnHousing,
      AssetType.ChildrenFuture,
      AssetType.BadDebtsRepayment,
      AssetType.NewHousing,
      AssetType.Reconstruction,
      AssetType.Furnishings,
      AssetType.Car,
      AssetType.Vacation,
      AssetType.OtherObjective,
    ],
  },
  {
    sourceTypes: [AssetType.Mortgage, AssetType.BuildingSavingsLoan],
    targetTypes: [AssetType.OwnHousing],
  },
];

/** Defines what objective assets can be in relation to a given asset. */
export const validObjectiveRelations: ValidAssetRelations = objectiveAssetRelations.reduce(
  (aggregation: ValidAssetRelations, assetRelation: AssetRelation) => {
    for (const sourceType of assetRelation.sourceTypes) {
      aggregation[sourceType] = [...(aggregation[sourceType] || []), ...assetRelation.targetTypes];
    }
    return aggregation;
  },
  {} as any,
);

const propertyAssetRelations: AssetRelation[] = [
  {
    sourceTypes: [AssetType.PropertyInsurance],
    targetTypes: [...immovablePropertyAssets, ...without(movablePropertyAssets, AssetType.Vehicle)],
  },
  {
    sourceTypes: [AssetType.VehicleInsurance],
    targetTypes: [AssetType.Vehicle],
  },
  {
    sourceTypes: [...creditAssets],
    targetTypes: [...immovablePropertyAssets, ...movablePropertyAssets],
  },
];

/** Defines what property assets can be in relation to a given asset. */
export const validPropertyRelations: ValidAssetRelations = propertyAssetRelations.reduce(
  (aggregation: ValidAssetRelations, assetRelation: AssetRelation) => {
    for (const sourceType of assetRelation.sourceTypes) {
      aggregation[sourceType] = [...(aggregation[sourceType] || []), ...assetRelation.targetTypes];
    }
    return aggregation;
  },
  {} as any,
);
