import {RegularPaymentType} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import * as moment from 'moment';

export interface CoreContract {
  id?: string;
  contactId?: string;
  foreign?: boolean;
  name?: string;
  opportunityNumber?: string;
  paymentAmount: number;
  paymentFrequency: SugarEnum;
  signatureDate: string;
  beginDate?: string;
  endDate?: string;
  productCode?: string;
  partner?: {
    id?: string;
    code: string;
    companyName: string;
  };
  productCategory1: SugarEnum | null;
  productCategory2?: SugarEnum | null;
  productCategory3?: SugarEnum | null;
  status?: SugarEnum | null;
  secondaryStatus?: SugarEnum | null;
}

export interface SugarEnum {
  code: string;
  codeDesc: string;
}

export function coreContractToAsset(
  coreContract: CoreContract,
): Partial<CommonFinancialProductAsset> {
  return {
    stakeholderUuid: coreContract.contactId,
    sugarUuid: coreContract.id,
    foreignContract: coreContract.foreign,
    value: coreContract.paymentAmount,
    frequency: coreFrequencyToRegularPaymentType(coreContract.paymentFrequency),
    name: coreContract.name,
    contractNumber: coreContract.opportunityNumber,
    partnerId: coreContract.partner?.code,
    productId: coreContract.productCode,
    start: coreContract.beginDate ?? moment().format('YYYY-MM-DD'),
    end: coreContract.endDate,
    coreStatus: coreContract.status?.code,
    coreSecondaryStatus: coreContract.secondaryStatus?.code,
  };
}

function coreFrequencyToRegularPaymentType(coreFrequency: SugarEnum): RegularPaymentType {
  switch (coreFrequency?.code) {
    case '219902325555379':
      return RegularPaymentType.Month;
    case '219902325555380':
      return RegularPaymentType.Quarter;
    case '219902325555381':
      return RegularPaymentType.HalfYear;
    case '219902325555477':
      return RegularPaymentType.Year;
    default:
      return RegularPaymentType.Month;
  }
}
