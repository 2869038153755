<div [ngSwitch]="status" class="action-buttons">
  <ng-container *ngSwitchCase="Status.Uploading">
    <!-- TODO uncomment when backend is ready -->
    <!--<div class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': file?.item?.progress + '%' }"></div>
    </div>-->
    <div class="lds-dual-ring"></div>

    <span class="icon icon--close" (click)="onCancelUpload()"> </span>
  </ng-container>

  <ng-container *ngSwitchCase="Status.Canceled">
    <span *ngIf="status === Status.Canceled" class="icon icon--refresh" (click)="onRepeatUpload()">
    </span
    >&nbsp;

    <span class="icon icon--bin" (click)="onRemoveFromQueue()"> </span>
  </ng-container>

  <ng-container *ngSwitchCase="Status.Ready">
    <span class="icon icon--edit" (click)="onEdit()"> </span>&nbsp;

    <span class="icon icon--bin" (click)="onRemove()"> </span>
  </ng-container>
</div>

<span class="icon icon--file icon--superlarge"></span>

<div class="footer">
  <div class="file-name" [title]="file.name" (click)="onPreview()">
    {{ file.name }}
  </div>
  <div class="file-created">
    {{ createdDate | date }}
  </div>
</div>
