<div class="event-situation-list row">
  <ng-container *ngFor="let buttonConfig of config; let i = index">
    <div class="event-container m-2" [class.offset-md-1]="i % 10 === 0 && buttonOffset">
      <kpt-event-situation-button-deprecated
        [dataTransferData]="'event-situation-data'"
        [config]="buttonConfig"
        (eventAction)="eventAction.emit($event)"
        (dragActive)="dragActive.emit($event)"
      ></kpt-event-situation-button-deprecated>
    </div>
  </ng-container>
</div>
