import {IStepperProgressItem} from '@shared/ui';
import {createStepperProgressConfig} from '@shared/ui/stepper-progress/stepper-progress.utils';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';

export enum PensionPage {
  Family = 'family',
  Requirements = 'requirements',
  Finances = 'finances',
  Pension = 'pension',
  CurrentSituation = 'current-situation',
  AnalysisSummary = 'analysis-summary',
  AdvisorProposed = 'advisor-proposed',
  ClientProposed = 'client-proposed',
  PlanSummary = 'plan-summary',
}

export const pensionPageNames = {
  [PensionPage.Family]: 'Rodina',
  [PensionPage.Requirements]: 'Požadavky',
  [PensionPage.Finances]: 'Hospodaření',
  [PensionPage.Pension]: 'Penze',
  [PensionPage.CurrentSituation]: 'Současné řešení',
  [PensionPage.AnalysisSummary]: 'Shrnutí',
  [PensionPage.AdvisorProposed]: 'Návrh řešení',
  [PensionPage.ClientProposed]: 'Volba klienta',
  [PensionPage.PlanSummary]: 'Shrnutí',
};

export function pensionStepperProgressConfig(
  businessCaseStatus: BusinessCaseStatus,
): IStepperProgressItem[] {
  if (!businessCaseStatus) return [];

  const steps: Record<BusinessCaseStatus, PensionPage[]> = {
    [BusinessCaseStatus.FinancialAnalysisPreparation]: [
      PensionPage.Family,
      PensionPage.Requirements,
      PensionPage.Finances,
      PensionPage.Pension,
      PensionPage.CurrentSituation,
      PensionPage.AnalysisSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPreparation]: [
      PensionPage.AdvisorProposed,
      PensionPage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPresentation]: [
      PensionPage.Requirements,
      PensionPage.ClientProposed,
      PensionPage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanFinalisation]: [],
    [BusinessCaseStatus.FinancialPlanTermination]: [],
  };

  const stepperItems: IStepperProgressItem[] = steps[businessCaseStatus]?.map(url => ({
    url,
    label: pensionPageNames[url],
  }));

  return createStepperProgressConfig(stepperItems, SecuredPage.Client, ClientPage.Pension);
}
