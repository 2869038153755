import {createSelector} from '@ngrx/store';
import {selectFinanceOverview} from '@shared/analysis/store';
import {
  selectCreditCategory,
  selectExpensesCategory,
  selectInsuranceCategory,
  selectInvestmentCategory,
  selectMainIncomesCategory,
  selectOtherIncomesCategory,
} from 'src/app/modules/client/summary/store/finances';
import {formatCZKNotNull} from 'src/app/modules/client/summary/store/summary.helpers';
import {AssetPageOverview} from 'src/app/modules/financial-analysis/shared/models/overview.model';
import {Category, Section} from './summary.models';

export const selectFinances = createSelector(
  selectFinanceOverview,
  selectExpensesCategory,
  selectInsuranceCategory,
  selectInvestmentCategory,
  selectCreditCategory,
  selectMainIncomesCategory,
  selectOtherIncomesCategory,
  (
    financeOverview,
    expensesCategory,
    insuranceCategory,
    investmentCategory,
    creditCategory,
    mainIncomesCategory,
    otherIncomesCategory,
  ) =>
    createSection(financeOverview, [
      expensesCategory,
      insuranceCategory,
      investmentCategory,
      creditCategory,
      mainIncomesCategory,
      otherIncomesCategory,
    ]),
);

function createSection(financeOverview: AssetPageOverview[], categories: Category[]): Section {
  return {
    label: 'Hospodaření',
    headerItems: financeOverview.map(overview => ({
      ...overview,
      value: formatCZKNotNull(overview.value),
    })),
    categories,
  };
}
