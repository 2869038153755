import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AssetDetailModalWrapperComponent} from './asset-detail-modal-wrapper.component';
import {AssetDetailModalModule} from './modal/asset-detail-modal.module';

@NgModule({
  imports: [CommonModule, AssetDetailModalModule],
  exports: [AssetDetailModalWrapperComponent],
  declarations: [AssetDetailModalWrapperComponent],
})
export class AssetDetailModalWrapperModule {}
