import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {AssetFormFormlyComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form-formly.component';
import {AssetFormNgModelComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form-ng-model.component';
import {Asset} from '@shared/analysis/models/asset';
import {FinancialAnalysisActions} from '@shared/analysis/store';
import {FormlyFormHandlerService} from '@shared/ui/formly/formly-form-handler.service';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-asset-form',
  templateUrl: './asset-form.component.html',
  imports: [CommonModule, AssetFormNgModelComponent, AssetFormFormlyComponent],
  providers: [FormlyFormHandlerService],
})
export class AssetFormComponent<T extends Asset> implements OnInit, OnChanges {
  @Input() value: T;
  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();

  @Input() disabled = false;

  @Input() autoSave = false;

  // These are inputs specific to only some asset types.
  @Input() showRelatedAssetHeader = true;
  @Input() mode: 'analysis' | 'plan' | 'summary' = 'analysis';
  @Input() selectedPersonId: string;
  @Input() showPersonSelector = true;

  value$ = new BehaviorSubject<T>(null);

  @ViewChild(AssetFormNgModelComponent) formNgModel: AssetFormNgModelComponent<T>;
  @ViewChild(AssetFormFormlyComponent) formFormly: AssetFormFormlyComponent<T>;

  private get assetFormComponent(): AssetFormNgModelComponent<T> | AssetFormFormlyComponent<T> {
    if (this.formNgModel) return this.formNgModel;
    if (this.formFormly) return this.formFormly;
    throw new Error('AssetFormComponent: neither formNgModel nor formFormly is set!');
  }

  useNgModel$ = this.value$.pipe(
    filter(value => Boolean(value)),
    map(value => value.type),
    distinctUntilChanged(),
    // if an asset type does not have formly fields, use ngModel
    map(type => !this.assetTypeService.getAssetDefinition(type)?.fields),
  );

  constructor(private store: Store<State>, private assetTypeService: AssetTypeService) {}

  ngOnInit() {
    if (this.autoSave) {
      this.valueChange.pipe(untilDestroyed(this)).subscribe(value => {
        this.store.dispatch(FinancialAnalysisActions.FA_SaveAsset(value));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.value$.next(changes.value.currentValue);
    }
  }

  setSubmitted() {
    this.assetFormComponent.setSubmitted();
  }

  isValid(): boolean {
    return this.assetFormComponent.isValid();
  }

  getValue(): T {
    return this.assetFormComponent.getValue();
  }

  patchValue(changes: Partial<T>) {
    this.assetFormComponent.patchValue(changes);
  }

  /**
   * Used by asset forms to invoke change event manually if needed.
   *
   * @param value
   */
  emitChange(value: T) {
    this.valueChange.emit(value);
  }
}
