import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@shared/pipes';
import {AvatarComponent} from './avatar.component';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, PipesModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
