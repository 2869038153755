<div class="summary-title">
  <h1><span class="icon icon--financial-analyse"></span>&nbsp;Finanční analýza</h1>
</div>
<kpt-accordion-container>
  <kpt-section [section]="familySummary | async"></kpt-section>
  <kpt-section [section]="plans | async"></kpt-section>
  <kpt-section [section]="finances | async">
    <kpt-readonly-risks-selection
      *ngIf="!featureFlagsService.showNewDashboard"
    ></kpt-readonly-risks-selection>
    <kpt-family-provision-summary
      *ngIf="featureFlagsService.showNewDashboard"
    ></kpt-family-provision-summary>
    <kpt-summary-priorities-results></kpt-summary-priorities-results>
  </kpt-section>
  <kpt-section [section]="properties | async"></kpt-section>
  <kpt-section [section]="contracts | async"></kpt-section>
  <kpt-investment-survey-summary></kpt-investment-survey-summary>
</kpt-accordion-container>
<div class="text-center mt-6">
  <kpt-button
    label="Vytisknout"
    type="primary"
    [isLoading]="isPrinting"
    (action)="print()"
    class="hidden-print"
  >
  </kpt-button>
</div>
