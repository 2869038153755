import {CoreContract} from '@shared/analysis/core-sync.models';
import {ofType, unionize, UnionOf} from 'unionize';

export const CoreContractsActions = unionize(
  {
    FA_SetCoreContracts: ofType<{coreContracts: CoreContract[]}>(),
    FA_SaveCoreContract: ofType<{coreContract: CoreContract}>(),
    FA_DeleteCoreContract: ofType<{id: string}>(),
  },
  {tag: 'type', value: 'payload'},
);

export type CoreContractsAction = UnionOf<typeof CoreContractsActions>;
