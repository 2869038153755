import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {IOption} from '@shared/ui';
import {isObservable, Observable, of} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'kpt-select-formly',
  templateUrl: './select-formly.component.html',
})
export class SelectFormlyComponent extends FieldType implements OnInit {
  options$: Observable<IOption[]>;

  noop() {}

  ngOnInit() {
    if (isObservable(this.to.options)) {
      this.options$ = this.to.options.pipe(filter(r => Boolean(r)));
    } else {
      this.options$ = of(this.to.options);
    }
  }
}
