import {base} from './environment.base';
import {Environment} from './environment.model';

export const environment: Environment = {
  ...base,
  name: 'dev',
  sentryDataSourceName: 'https://3a581a468e4343df9c1ab708f66b7a0d@error.kapitol.cz/2',
  YBugProjectId: 'x3tfmwkttaxsrd33y52y',
  YBugTitle: 'Zpětná vazba',
};
