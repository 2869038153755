<kpt-col class="form-group" [attr.id]="id" [ngClass]="customFormGroupClass" alignItems="stretch">
  <kpt-row justifyContent="space-between">
    <mat-slide-toggle
      #field
      (blur)="blur.emit($event)"
      (change)="onCheckedChange($event)"
      [checked]="checked"
      [ngClass]="customFieldClass"
      [required]="required"
      [disabled]="disabled"
      [labelPosition]="labelPosition"
    >
      <ng-template [ngTemplateOutlet]="labelTemplate || defaultLabelTemplateRef"></ng-template>
    </mat-slide-toggle>
    <ng-container *ngIf="appendTemplate">
      <ng-template [ngTemplateOutlet]="appendTemplate"></ng-template>
    </ng-container>
  </kpt-row>
  <!-- tiny text below the input -->
  <div *ngIf="!!subtext" class="input-subtext" [class.input-subtext--disabled]="!!disabled">
    {{ subtext }}
  </div>

  <!-- warning text below the input -->
  <div *ngIf="!!warningText" class="input-warning-text">
    {{ warningText }}
  </div>

  <kpt-form-field-error
    [showErrorsOn]="showErrorsOn"
    [customErrors]="customErrors"
  ></kpt-form-field-error>
</kpt-col>

<ng-template #defaultLabelTemplateRef>
  <span [innerHTML]="htmlLabel || label"></span>
</ng-template>
