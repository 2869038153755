import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {LifeInsuranceFile} from '@generated/defs/LifeInsuranceFile';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {StoreFileUpdate, UIFile} from '@shared/models/file.models';
import {LifeInsuranceInitialUploadData, UploaderService} from '@shared/services/uploader.service';
import {sortBy} from 'lodash';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {filter} from 'rxjs/operators';
import {InsuranceFormVM} from 'src/app/modules/life-insurance-old/5-proposals/proposals.selectors';
import {LifeInsuranceFormGroup} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {environment} from 'src/environments/environment';
import {InsuranceGroupStoreFile, InsuranceGroupUIFile} from '../insurance-group.models';

@UntilDestroy()
@Component({
  selector: 'kpt-attached-files',
  templateUrl: './attached-files.component.html',
  styleUrls: ['./attached-files.component.scss'],
  providers: [UploaderService],
})
export class AttachedFilesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() group: LifeInsuranceFormGroup;
  @Input() form: InsuranceFormVM;
  @Input() files: LifeInsuranceFile[] = [];
  @Input() initialUploadData: LifeInsuranceInitialUploadData;
  @Output() addFormFile = new EventEmitter<InsuranceGroupStoreFile>();
  @Output() updateFormFile = new EventEmitter<StoreFileUpdate>();
  @Output() removeFormFile = new EventEmitter<string>();

  @HostBinding('class.has-files') hasFiles = false;
  @HostBinding('class.file-over') draggedFileOverColumn = false;

  uploader: FileUploader;

  filesList: InsuranceGroupUIFile[] = [];
  deletingFile: null | InsuranceGroupUIFile = null;

  LifeInsuranceFormGroup = LifeInsuranceFormGroup;

  private uploaderConfig = {
    url: environment.apiBaseUrl + '/api/integrations/upload-file/',
    allowedFileType: ['image', 'pdf', 'doc', 'xls', 'ppt', 'application'],
    maxFileSize: 1024 * 1024 * 15,
  };

  private queuedFiles: UIFile[] = [];

  constructor(private uploaderService: UploaderService) {}

  get showUploader(): boolean {
    return (
      (this.group !== LifeInsuranceFormGroup.Final && this.filesList.length === 0) ||
      (this.group === LifeInsuranceFormGroup.Final &&
        this.form.changed &&
        this.filesList.filter(f => !f.shortcut).length === 0)
    );
  }

  ngOnInit() {
    this.uploaderService.queuedFiles$.pipe(untilDestroyed(this)).subscribe(queuedFiles => {
      this.queuedFiles = queuedFiles;
      this.updateFilesList();
    });

    this.uploaderService.fileUploaded$
      .pipe(filter<InsuranceGroupStoreFile>(Boolean), untilDestroyed(this))
      .subscribe(file => {
        this.addFormFile.emit({
          ...file,
          formId: this.form.formId,
        });
      });

    this.uploader = this.uploaderService.initUploader(this.uploaderConfig, this.initialUploadData);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.files) this.updateFilesList();
  }

  ngOnDestroy(): void {}

  onDragOverColumn(display: boolean): void {
    this.draggedFileOverColumn = display;
  }

  onFilesSelected() {
    this.uploaderService.uploadFiles();
  }

  onRemoveItemFromQueue(item: FileItem) {
    this.uploaderService.removeFromQueue(item);
  }

  onUpdateFormFile(fileUpdate: StoreFileUpdate) {
    this.updateFormFile.emit(fileUpdate);
  }

  onShowDeleteConfirmation(file: UIFile) {
    this.deletingFile = file;
  }

  onConfirmDeleteFile() {
    this.removeFormFile.emit(this.deletingFile.dmsUuid);
    this.deletingFile = null;
  }

  onCancelDeleteFile() {
    this.deletingFile = null;
  }

  private updateFilesList() {
    this.filesList = sortBy([...this.files, ...this.queuedFiles], 'created');
    this.hasFiles = this.filesList.length > 0;
  }
}
