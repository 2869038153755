import {
  BusinessCaseAction,
  BusinessCaseActions,
} from '@shared/business-case/store/business-case.actions';
import {
  BusinessCaseStoreState,
  initialBusinessCaseState,
} from '@shared/business-case/store/business-case.state';
import produce from 'immer';

const producer = (state: BusinessCaseStoreState, action: BusinessCaseAction) =>
  BusinessCaseActions.match(action, {
    BC_SetData: ({businessCases, situation}) => {
      state.businessCases = businessCases ?? [];
      state.situation = situation;
      state.loading = false;
    },
    BC_SetFinalizedBusinessCases: businessCases => {
      state.finalizedBusinessCases = businessCases;
    },
    BC_ResetState: () => {
      state = initialBusinessCaseState;
    },
    BC_UpdateStatusAndTimestamp: ({situation, status, timestamp}) => {
      const businessCase = state.businessCases.find(c => c.situationId === situation);
      if (businessCase) {
        businessCase.status = status;
        businessCase.timestamp = timestamp;
      }
    },
    default: () => {},
  });

export function reducer(
  state = initialBusinessCaseState,
  action: BusinessCaseAction,
): BusinessCaseStoreState {
  return produce(producer)(state, action);
}
