export enum FormlyTypes {
  Text = 'text',
  Number = 'number',
  Currency = 'currency',
  Password = 'password',
  Textarea = 'textarea',
  Checkbox = 'checkbox',
  Switch = 'switch',
  Datepicker = 'datepicker',
  Year = 'year',
  Select = 'select',
  RadioGroup = 'radio_group',
  SliderInputRange = 'slider_input_range',
  SliderInput = 'slider_input',
  SliderRange = 'slider_range',
  FamilyMemberRadio = 'family-member-radio',
  FamilyMemberCheckbox = 'family-member-checkbox',
  FamilyMemberSelect = 'family-member-select',
  Repeatable = 'repeatable',
  Percentage = 'percentage',
  Description = 'description',
  Fulfillment = 'fulfillment',
  Label = 'label',
  RelatedAssetSelect = 'related-asset-select',
  RelatedAssetHeader = 'related-asset-header',
  RisksLifeInsurance = 'risks-life-insurance',
  AssetLifeInsuranceRisks = 'asset-life-insurance-risks',
  InsuranceOfCreditProduct = 'insurance-of-credit-product',
  PartnerSelect = 'partner-select',
  ProductSelect = 'product-select',
}

export enum FamilyMemberTypes {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Select = 'select',
}

export enum FormlyWrappers {
  Tab = 'formly-tab',
  Tabs = 'formly-tabs',
  NamedGroup = 'named-group',
  AssetValue = 'asset-value',
  CoreValue = 'core-value',
  ImportantValue = 'important-value',
  InsuranceValue = 'insurance-value',
  CaptionedInput = 'captioned-input',
}
