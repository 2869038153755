import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {LoginService} from '@lib/services';
import {Scenario} from '@lib/services/intro-js.config';
import {IntroJsService} from '@lib/services/intro-js.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {LifeSituation, selectBusinessCases} from '@shared/business-case/store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ClientPage} from 'src/app/modules/client';
import {ClientPageConfig} from 'src/app/modules/client/client-page.config';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {BusinessCaseService} from 'src/app/modules/financial-plan/business-case.service';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';

enum PlanAction {
  Requirements = 'requirements',
  PlanPreparation = 'planPreparation',
  PlanPresentation = 'planPresentation',
  Signature = 'signature',
}

@UntilDestroy()
@Component({
  selector: 'kpt-card-board2',
  templateUrl: './card-board2.component.html',
  styleUrls: ['./card-board2.component.scss'],
})
export class CardBoard2Component implements OnInit {
  cardBoard$ = combineLatest([
    this.config.getCardBoard(),
    this.store.pipe(select(selectBusinessCases)),
  ]).pipe(
    map(([cardBoard, businessCases]) => {
      for (const card of [cardBoard.lifeSituation.mainCard, ...cardBoard.lifeSituation.cards]) {
        const businessCase = businessCases.find(b => b.situationId === card.value);
        if (businessCase) {
          card.statusIcon = this.getSituationStatusIcon(businessCase.status as BusinessCaseStatus);
          card.statusColor = this.getSituationStatusColor(
            businessCase.status as BusinessCaseStatus,
          );
          card.statusTooltip = this.businessCaseService.getStatusTooltip(businessCase);
        }
      }
      return cardBoard;
    }),
    untilDestroyed(this),
  );

  scenario: Scenario = 'clientPageOverview';
  finalizedBusinessCases$ = this.businessCaseService.getFinalizedBusinessCases();

  selectedLifeSituation$ = new BehaviorSubject<ModuleCard>(null);

  actionStatus$ = combineLatest([
    this.store.pipe(select(selectBusinessCases)),
    this.selectedLifeSituation$,
  ]).pipe(
    map(([businessCases, selectedLifeSituation]) => {
      const businessCase = businessCases.find(b => b.situationId === selectedLifeSituation.value);
      return businessCase?.status as BusinessCaseStatus;
    }),
    untilDestroyed(this),
  );

  actionAnalysis$: Observable<ModuleCard> = this.actionStatus$.pipe(
    map(
      status => ({
        target: '',
        type: 'primary',
        icon: 'financial-analyse',
        label: 'Sběr požadavků',
        value: PlanAction.Requirements,
        statusIcon: this.getActionStatusIcon(
          status,
          BusinessCaseStatus.FinancialAnalysisPreparation,
        ),
        statusColor: this.getActionStatusColor(
          status,
          BusinessCaseStatus.FinancialAnalysisPreparation,
        ),
      }),
      untilDestroyed(this),
    ),
  );

  actionPreparation$: Observable<ModuleCard> = combineLatest([
    this.actionStatus$,
    this.selectedLifeSituation$,
  ]).pipe(
    map(([status, selectedLifeSituation]) => ({
      target: '',
      type: 'primary',
      icon: selectedLifeSituation.icon,
      label: 'Návrh poradce',
      value: PlanAction.PlanPreparation,
      statusIcon: this.getActionStatusIcon(status, BusinessCaseStatus.FinancialPlanPreparation),
      statusColor: this.getActionStatusColor(status, BusinessCaseStatus.FinancialPlanPreparation),
    })),
  );

  actionPresentation$: Observable<ModuleCard> = this.actionStatus$.pipe(
    map(state => ({
      target: '',
      type: 'primary',
      icon: '/assets/images/homepage/plan-presentation.svg',
      iconMarginTop: '6px',
      label: 'Prezentace návrhu',
      value: PlanAction.PlanPresentation,
      statusIcon: this.getActionStatusIcon(state, BusinessCaseStatus.FinancialPlanPresentation),
      statusColor: this.getActionStatusColor(state, BusinessCaseStatus.FinancialPlanPresentation),
    })),
  );

  actionSignature$: Observable<ModuleCard> = this.actionStatus$.pipe(
    map(state => ({
      target: '',
      type: 'primary',
      icon: 'erecord',
      label: 'Podpis záznamu z jednání',
      value: PlanAction.Signature,
      statusIcon: this.getActionStatusIcon(state, BusinessCaseStatus.FinancialPlanFinalisation),
      statusColor: this.getActionStatusColor(state, BusinessCaseStatus.FinancialPlanFinalisation),
    })),
  );

  showLegacyAction$ = this.selectedLifeSituation$.pipe(
    map(
      selectedLifeSituation =>
        selectedLifeSituation.value &&
        selectedLifeSituation.value !== LifeSituation.All &&
        selectedLifeSituation.value !== LifeSituation.VehicleInsurance &&
        selectedLifeSituation.value !== LifeSituation.PropertyInsurance &&
        selectedLifeSituation.value !== LifeSituation.Investment &&
        selectedLifeSituation.value !== LifeSituation.LifeInsurance &&
        selectedLifeSituation.value !== LifeSituation.Pension,
    ),
  );

  actionLegacy$: Observable<ModuleCard> = this.selectedLifeSituation$.pipe(
    map(selectedLifeSituation => ({
      target: '',
      type: 'primary',
      icon: selectedLifeSituation.icon,
      label: 'Sjednat',
      value: 'legacyAction',
    })),
  );

  private familyHead: FamilyMember;

  constructor(
    public businessCaseService: BusinessCaseService,
    private store: Store<State>,
    private config: ClientPageConfig,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private introJsService: IntroJsService,
  ) {
    this.store.pipe(selectFamilyHead(), untilDestroyed(this)).subscribe(head => {
      this.familyHead = head;
    });
  }

  get financialDivision(): boolean {
    return this.loginService.isUserInFinancialDivision();
  }

  get goldDivision(): boolean {
    return this.loginService.isUserInGoldDivision();
  }

  ngOnInit() {
    this.cardBoard$.pipe(take(1)).subscribe(cardBoard => {
      let lifeSituation = [cardBoard.lifeSituation.mainCard, ...cardBoard.lifeSituation.cards].find(
        card => card.value === localStorage.getItem('selectedLifeSituation'),
      );
      if (!lifeSituation) {
        lifeSituation = cardBoard.lifeSituation.mainCard;
      }

      this.selectedLifeSituation$.pipe(untilDestroyed(this)).subscribe(selectedLifeSituation => {
        if (selectedLifeSituation?.value) {
          localStorage.setItem('selectedLifeSituation', selectedLifeSituation.value);
        }
      });

      this.selectedLifeSituation$.next(lifeSituation);
    });

    if (this.financialDivision) {
      this.introJsService.showIntro(this.scenario, true);
    }
  }

  cardAction(card: ModuleCard) {
    if (Object.values(PlanAction).includes(card.value as PlanAction)) {
      this.handlePlanAction(card);
    } else if (card.value === 'legacyAction') {
      this.handleLegacyAction();
    } else {
      this.handleOtherAction(card);
    }
  }

  private async handlePlanAction(card: ModuleCard) {
    if (this.selectedLifeSituation$.value.value === LifeSituation.All) {
      // go to either financial analysis or financial plan based on the action card
      if (card.value === PlanAction.Requirements) {
        this.router.navigate([ClientPage.FinancialAnalysis], {relativeTo: this.route});
      } else {
        this.router.navigate([ClientPage.FinancialPlan], {relativeTo: this.route});
      }
    } else {
      // go to the specific life situation to the requested phase
      const defs: Record<PlanAction, BusinessCaseStatus> = {
        [PlanAction.Requirements]: BusinessCaseStatus.FinancialAnalysisPreparation,
        [PlanAction.PlanPreparation]: BusinessCaseStatus.FinancialPlanPreparation,
        [PlanAction.PlanPresentation]: BusinessCaseStatus.FinancialPlanPresentation,
        [PlanAction.Signature]: BusinessCaseStatus.FinancialPlanFinalisation,
      };

      const phase = defs[card.value as PlanAction];
      if (phase === undefined) throw new Error(`Unknown plan action: ${card.value}`);

      this.router.navigate([this.selectedLifeSituation$.value.target], {
        queryParams: {phase},
        relativeTo: this.route,
      });
    }
  }

  private handleLegacyAction() {
    this.router.navigate([this.selectedLifeSituation$.value.target], {
      relativeTo: this.route,
    });
  }

  private handleOtherAction(card: ModuleCard) {
    if (card.icon === 'plan-meeting') {
      const portalUrl = `${environment.poradceUrl}portal-poradce/kalendar?newEvent=true&newEventClientId=${this.familyHead.sugarUuid}`;
      window.open(portalUrl, '_blank');
      return;
    }

    if (!card.disabled && card.target) {
      if (card.target.startsWith('https')) {
        window.open(card.target, '_blank');
      } else {
        this.router.navigate([card.target], {relativeTo: this.route});
      }
    }
  }

  private getSituationStatusIcon(status: BusinessCaseStatus): string {
    if (status === BusinessCaseStatus.FinancialPlanTermination) {
      return null;
    } else if (
      this.statusToNumber(status) >
      this.statusToNumber(BusinessCaseStatus.FinancialPlanPresentation)
    ) {
      return 'check';
    } else if (
      this.statusToNumber(status) >
      this.statusToNumber(BusinessCaseStatus.FinancialAnalysisPreparation)
    ) {
      return 'edit';
    }
    return null;
  }

  private getSituationStatusColor(status: BusinessCaseStatus): string {
    if (
      this.statusToNumber(status) >
      this.statusToNumber(BusinessCaseStatus.FinancialPlanPresentation)
    ) {
      return 'green';
    } else if (
      this.statusToNumber(status) >
      this.statusToNumber(BusinessCaseStatus.FinancialAnalysisPreparation)
    ) {
      return null;
    }
    return null;
  }

  private getActionStatusIcon(
    currentStatus: BusinessCaseStatus,
    cardStatus: BusinessCaseStatus,
  ): string {
    if (currentStatus === BusinessCaseStatus.FinancialPlanTermination) {
      return null;
    } else if (this.statusToNumber(cardStatus) < this.statusToNumber(currentStatus)) {
      return 'check';
    }
    return null;
  }

  private getActionStatusColor(
    currentStatus: BusinessCaseStatus,
    cardStatus: BusinessCaseStatus,
  ): string {
    if (this.statusToNumber(cardStatus) < this.statusToNumber(currentStatus)) {
      return 'green';
    }
    return null;
  }

  private statusToNumber(state: BusinessCaseStatus): number {
    const defs: Record<BusinessCaseStatus, number> = {
      [BusinessCaseStatus.FinancialAnalysisPreparation]: 1,
      [BusinessCaseStatus.FinancialPlanPreparation]: 2,
      [BusinessCaseStatus.FinancialPlanPresentation]: 3,
      [BusinessCaseStatus.FinancialPlanFinalisation]: 4,
      [BusinessCaseStatus.FinancialPlanTermination]: 5,
    };

    return defs[state] ?? -1;
  }
}
