import {inject, Injectable} from '@angular/core';
import {BadDebtsRepaymentAssetType} from '@shared/analysis/asset-types/bad-debts-repayment/bad-debts-repayment-asset-type';
import {CapitalLifeInsuranceAssetType} from '@shared/analysis/asset-types/capital-life-insurance/capital-life-insurance-asset-type';
import {CarAssetType} from '@shared/analysis/asset-types/car/car-asset-type';
import {ChildBirthAssetType} from '@shared/analysis/asset-types/child-birth/child-birth-asset-type';
import {ChildrenFutureAssetType} from '@shared/analysis/asset-types/children-future/children-future-asset-type';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FamilyProvisionAssetType} from '@shared/analysis/asset-types/family-provision/family-provision-asset-type';
import {FinancialIndependenceAssetType} from '@shared/analysis/asset-types/financial-independence/financial-independence-asset-type';
import {FurnishingsAssetType} from '@shared/analysis/asset-types/furnishings/furnishings-asset-type';
import {InvestmentLifeInsuranceAssetType} from '@shared/analysis/asset-types/investment-life-insurance/investment-life-insurance-asset-type';
import {LongTermReserveAssetType} from '@shared/analysis/asset-types/long-term-reserve/long-term-reserve-asset-type';
import {NewHousingAssetType} from '@shared/analysis/asset-types/new-housing/new-housing-asset-type';
import {OtherObjectiveAssetType} from '@shared/analysis/asset-types/other-objective/other-objective-asset-type';
import {PropertyInsuranceAssetType} from '@shared/analysis/asset-types/property-insurance/property-insurance-asset-type';
import {PropertyProvisionAssetType} from '@shared/analysis/asset-types/property-provision/property-provision-asset-type';
import {PropertyRequirementsAssetType} from '@shared/analysis/asset-types/property-requirements/property-requirements-asset-type';
import {ReconstructionAssetType} from '@shared/analysis/asset-types/reconstruction/reconstruction-asset-type';
import {RetirementAssetType} from '@shared/analysis/asset-types/retirement/retirement-asset-type';
import {RiskLifeInsuranceAssetType} from '@shared/analysis/asset-types/risk-life-insurance/risk-life-insurance-asset-type';
import {ShortTermReserveAssetType} from '@shared/analysis/asset-types/short-term-reserve/short-term-reserve-asset-type';
import {VacationAssetType} from '@shared/analysis/asset-types/vacation/vacation-asset-type';
import {VehicleAssetType} from '@shared/analysis/asset-types/vehicle/vehicle-asset-type';
import {VehicleInsuranceAssetType} from '@shared/analysis/asset-types/vehicle-insurance/vehicle-insurance-asset-type';
import {VehicleProvisionAssetType} from '@shared/analysis/asset-types/vehicle-provision/vehicle-provision-asset-type';
import {VehicleRequirementsAssetType} from '@shared/analysis/asset-types/vehicle-requirements/vehicle-requirements-asset-type';
import {DefaultValueService} from '@shared/analysis/default-value.service';
import {AssetDefinitions} from '@shared/analysis/forms/definitions';
import {AdvisorProposalState, Asset, AssetType} from '@shared/analysis/models/asset';

interface AssetTypeServiceMap {
  [AssetType.PropertyProvision]: PropertyProvisionAssetType;
  [AssetType.PropertyRequirements]: PropertyRequirementsAssetType;
  [AssetType.PropertyInsurance]: PropertyInsuranceAssetType;
  [AssetType.Vehicle]: VehicleAssetType;
  [AssetType.VehicleInsurance]: VehicleInsuranceAssetType;
  [AssetType.VehicleProvision]: VehicleProvisionAssetType;
  [AssetType.VehicleRequirements]: VehicleRequirementsAssetType;
  [AssetType.LongTermReserve]: LongTermReserveAssetType;
  [AssetType.ShortTermReserve]: ShortTermReserveAssetType;
  [AssetType.FinancialIndependence]: FinancialIndependenceAssetType;
  [AssetType.ChildrenFuture]: ChildrenFutureAssetType;
  [AssetType.ChildBirth]: ChildBirthAssetType;
  [AssetType.BadDebtsRepayment]: BadDebtsRepaymentAssetType;
  [AssetType.NewHousing]: NewHousingAssetType;
  [AssetType.Reconstruction]: ReconstructionAssetType;
  [AssetType.Furnishings]: FurnishingsAssetType;
  [AssetType.Car]: CarAssetType;
  [AssetType.Vacation]: VacationAssetType;
  [AssetType.OtherObjective]: OtherObjectiveAssetType;
  [AssetType.Retirement]: RetirementAssetType;
  [AssetType.FamilyProvision]: FamilyProvisionAssetType;
  [AssetType.RiskLifeInsurance]: RiskLifeInsuranceAssetType;
  [AssetType.CapitalLifeInsurance]: CapitalLifeInsuranceAssetType;
  [AssetType.InvestmentLifeInsurance]: InvestmentLifeInsuranceAssetType;
  [key: string]: BaseAssetType;
}

@Injectable()
export class AssetTypeService {
  private assetTypes: AssetTypeServiceMap = {
    [AssetType.PropertyProvision]: inject(PropertyProvisionAssetType),
    [AssetType.PropertyRequirements]: inject(PropertyRequirementsAssetType),
    [AssetType.PropertyInsurance]: inject(PropertyInsuranceAssetType),
    [AssetType.Vehicle]: inject(VehicleAssetType),
    [AssetType.VehicleInsurance]: inject(VehicleInsuranceAssetType),
    [AssetType.VehicleProvision]: inject(VehicleProvisionAssetType),
    [AssetType.VehicleRequirements]: inject(VehicleRequirementsAssetType),
    [AssetType.LongTermReserve]: inject(LongTermReserveAssetType),
    [AssetType.ShortTermReserve]: inject(ShortTermReserveAssetType),
    [AssetType.FinancialIndependence]: inject(FinancialIndependenceAssetType),
    [AssetType.ChildrenFuture]: inject(ChildrenFutureAssetType),
    [AssetType.ChildBirth]: inject(ChildBirthAssetType),
    [AssetType.BadDebtsRepayment]: inject(BadDebtsRepaymentAssetType),
    [AssetType.NewHousing]: inject(NewHousingAssetType),
    [AssetType.Reconstruction]: inject(ReconstructionAssetType),
    [AssetType.Furnishings]: inject(FurnishingsAssetType),
    [AssetType.Car]: inject(CarAssetType),
    [AssetType.Vacation]: inject(VacationAssetType),
    [AssetType.OtherObjective]: inject(OtherObjectiveAssetType),
    [AssetType.Retirement]: inject(RetirementAssetType),
    [AssetType.FamilyProvision]: inject(FamilyProvisionAssetType),
    [AssetType.RiskLifeInsurance]: inject(RiskLifeInsuranceAssetType),
    [AssetType.CapitalLifeInsurance]: inject(CapitalLifeInsuranceAssetType),
    [AssetType.InvestmentLifeInsurance]: inject(InvestmentLifeInsuranceAssetType),
  };

  constructor(
    private defaultValueService: DefaultValueService,
    private assetDefinitions: AssetDefinitions,
  ) {}

  getAssetDefinition(assetType: AssetType) {
    if (!this.assetTypes[assetType]) {
      // the old way
      return this.assetDefinitions.getAllByType()[assetType];
    }
    return this.assetTypes[assetType].getAssetDefinition();
  }

  async create<T extends Asset = Asset>(
    assetType: AssetType,
    opts?: {
      relatedObjectiveUuid?: string;
      relatedPropertyUuid?: string;
      stakeholderUuid?: string;
      proposal?: boolean;
      advisorProposalState?: AdvisorProposalState;
    },
  ): Promise<T> {
    const {
      relatedObjectiveUuid,
      relatedPropertyUuid,
      stakeholderUuid,
      proposal,
      advisorProposalState,
    } = opts ?? {};

    if (!this.assetTypes[assetType]) {
      // the old way
      return this.defaultValueService.getDefaultValue(assetType) as T;
    }

    switch (assetType) {
      case AssetType.Vehicle:
      case AssetType.PropertyProvision:
      case AssetType.VehicleProvision:
      case AssetType.LongTermReserve:
      case AssetType.ShortTermReserve:
      case AssetType.FinancialIndependence:
      case AssetType.ChildrenFuture:
      case AssetType.ChildBirth:
      case AssetType.BadDebtsRepayment:
      case AssetType.NewHousing:
      case AssetType.Reconstruction:
      case AssetType.Furnishings:
      case AssetType.Car:
      case AssetType.Vacation:
      case AssetType.OtherObjective:
      case AssetType.FamilyProvision:
        return this.assetTypes[assetType].create() as T;
      case AssetType.Retirement:
        return (await this.assetTypes[assetType].create({stakeholderUuid})) as T;
      case AssetType.PropertyRequirements:
      case AssetType.VehicleRequirements:
        return (await this.assetTypes[assetType].create({
          relatedPropertyUuid,
          relatedObjectiveUuid,
        })) as T;
      case AssetType.PropertyInsurance:
      case AssetType.VehicleInsurance:
        return (await this.assetTypes[assetType].create({
          relatedObjectiveUuid,
          relatedPropertyUuid,
          stakeholderUuid,
          proposal,
          advisorProposalState,
        })) as T;
      case AssetType.CapitalLifeInsurance:
      case AssetType.InvestmentLifeInsurance:
      case AssetType.RiskLifeInsurance:
        return (await this.assetTypes[assetType].create({
          proposal,
        })) as T;
      default:
        throw new Error(`Create method not implemented for asset type ${assetType}`);
    }
  }
}
