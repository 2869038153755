import {isString} from 'lodash';

export const scrollToElement = (el: HTMLElement | string) => {
  const element: HTMLElement = isString(el)
    ? document.getElementById(el as string)
    : (el as HTMLElement);
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};
