<ng-container *ngIf="asset">
  <kpt-row>
    <kpt-input
      grow
      label="RZ"
      name="registrationPlate"
      [(ngModel)]="asset.registrationPlate"
    ></kpt-input>
    <kpt-input
      grow
      label="VIN"
      name="vehicleIdentificationNumber"
      [(ngModel)]="asset.vehicleIdentificationNumber"
      [maxLength]="17"
    ></kpt-input>
    <kpt-col grow alignItems="center" justifyContent="center">
      <kpt-button
        class="load-button"
        type="simple"
        (click)="loadVehicleInfo()"
        [isDisabled]="loading"
        >Načíst údaje o vozidle</kpt-button
      >
      <div class="load-error">{{ error }}&nbsp;</div>
    </kpt-col>
  </kpt-row>
  <kpt-input label="Název" name="name" [(ngModel)]="asset.name"></kpt-input>
  <kpt-row>
    <kpt-input
      grow
      type="number"
      label="Současná tržní hodnota"
      appendText="Kč"
      name="value"
      [(ngModel)]="asset.value"
      required
    ></kpt-input>
    <kpt-datepicker
      grow
      label="Od kdy je majetek k dispozici?"
      name="start"
      [(ngModel)]="asset.start"
      required
    >
    </kpt-datepicker>
  </kpt-row>
  <kpt-row>
    <kpt-input
      grow
      label="Tovární značka"
      name="manufacturer"
      [(ngModel)]="asset.manufacturer"
      (ngModelChange)="manufacturer$.next($event)"
      [autocompleteObservable]="manufacturersAutocomplete$"
    ></kpt-input>
    <kpt-input
      grow
      label="Model"
      name="model"
      [(ngModel)]="asset.model"
      (ngModelChange)="model$.next($event)"
      [autocompleteObservable]="modelAutocomplete$"
    ></kpt-input>
    <kpt-input
      grow
      label="Kategorie"
      name="category"
      [(ngModel)]="asset.category"
      [autocompleteObservable]="categoryAutocomplete$"
    ></kpt-input>
    <kpt-input
      grow
      label="Číslo VTP"
      name="vehicleRegistrationCertificate"
      [(ngModel)]="asset.vehicleRegistrationCertificate"
    ></kpt-input>
  </kpt-row>
  <kpt-row>
    <kpt-input
      grow
      type="number"
      label="Rok uvedení do provozu"
      name="yearBeginningUse"
      [mask]="null"
      [(ngModel)]="asset.yearBeginningUse"
    ></kpt-input>
    <kpt-input
      grow
      label="Palivo"
      name="fuelType"
      [(ngModel)]="asset.fuelType"
      [autocompleteObservable]="fuelTypeAutocomplete$"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Počet sedadel"
      name="seats"
      [(ngModel)]="asset.seats"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Objem motoru"
      name="engineCapacity"
      appendText="cm³"
      [(ngModel)]="asset.engineCapacity"
    ></kpt-input>
  </kpt-row>
  <kpt-row>
    <kpt-input
      grow
      type="number"
      label="Výkon"
      name="enginePower"
      appendText="kW"
      [(ngModel)]="asset.enginePower"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Váha"
      name="weight"
      appendText="kg"
      [(ngModel)]="asset.weight"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Stav tachometru"
      name="mileage"
      appendText="km"
      [(ngModel)]="asset.mileage"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Roční nájezd"
      name="annualMileage"
      appendText="km"
      [(ngModel)]="asset.annualMileage"
    ></kpt-input>
  </kpt-row>
  <kpt-switch label="Leasing" name="leasing" [(ngModel)]="asset.leasing"></kpt-switch>
</ng-container>
