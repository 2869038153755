import {Injectable} from '@angular/core';
import {defaultOptions, Scenario, scenarios} from '@lib/services/intro-js.config';
import {ResourceLoaderService} from '@lib/services/resource-loader.service';
import {select, Store} from '@ngrx/store';
import {AdvisorUiSettingsActions, selectIntroUiSettings} from '@shared/advisor-ui-settings/store';
import {IntroJs} from 'intro.js';
import {filter, map, take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable({
  providedIn: 'root',
})
export class IntroJsService {
  private introJsLoaded: Promise<any>;

  constructor(private resourceLoaderService: ResourceLoaderService, private store: Store<State>) {}

  async showIntro(scenario: Scenario, onboarding = false) {
    if (onboarding && (await this.isScenarioFinished(scenario))) return;

    await this.load();

    (window.introJs() as IntroJs).setOptions({...defaultOptions, ...scenarios[scenario]()}).start();
    window.scrollTo(0, 0);

    this.markIntroCompleted(onboarding, scenario);
  }

  private load(): Promise<void> {
    if (this.introJsLoaded) return this.introJsLoaded;

    this.introJsLoaded = Promise.all([
      this.resourceLoaderService.loadScript({
        src: 'https://cdnjs.cloudflare.com/ajax/libs/intro.js/4.3.0/intro.min.js',
      }),
      this.resourceLoaderService.loadStyle({
        src: 'https://cdnjs.cloudflare.com/ajax/libs/intro.js/4.3.0/introjs.min.css',
      }),
    ]);

    return this.introJsLoaded;
  }

  private markIntroCompleted(onboarding: boolean, scenario: Scenario) {
    if (onboarding) {
      this.store.dispatch(
        AdvisorUiSettingsActions.UI_UpdateIntroUiSettings({intro: {[scenario]: true}}),
      );
    }
  }

  private async isScenarioFinished(scenario: Scenario): Promise<boolean> {
    return await this.store
      .pipe(
        select(selectIntroUiSettings),
        map(intro => intro[scenario]),
        filter(finished => finished === true || finished === false),
        take(1),
      )
      .toPromise();
  }
}
