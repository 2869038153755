import {createSelector} from '@ngrx/store';
import {Asset} from '@shared/analysis/models/asset';
import {selectAllAssets} from '@shared/analysis/store';
import {flatten} from 'lodash';
import {
  isDecisionMadeByAdvisor,
  isDecisionMadeByClient,
} from 'src/app/modules/financial-plan/objectives/objectives.selectors';
import {selectInvestmentObjectives} from 'src/app/modules/financial-plan/store/investment-objective.selectors';
import {
  CompletionState,
  Objective,
  ObjectiveCompletion,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {selectFinancialPlanObjectives} from 'src/app/modules/financial-plan/store/objectives.selectors';
import {selectPropertyObjectives} from 'src/app/modules/financial-plan/store/property-objective.selectors';
import {selectVehicleObjectives} from 'src/app/modules/financial-plan/store/vehicle-objective.selectors';

export const selectCompletionStateOfFinancialPlan = ({
  showNewDashboard,
}: {
  showNewDashboard: boolean;
}) =>
  createSelector(
    selectFinancialPlanObjectives({showNewDashboard}),
    selectAllAssets,
    (financialPlanObjectives, allAssets): CompletionState => {
      const allObjectives = flatten<Objective>(Object.values(financialPlanObjectives));
      return computeCompletion(allObjectives, allAssets);
    },
  );

export const selectCompletionStateOfPropertyInsurance = createSelector(
  selectPropertyObjectives({filterSelectedAssets: true}),
  selectAllAssets,
  (objectives, allAssets): CompletionState => {
    return computeCompletion(objectives, allAssets);
  },
);

export const selectCompletionStateOfVehicleInsurance = createSelector(
  selectVehicleObjectives({filterSelectedAssets: true}),
  selectAllAssets,
  (objectives, allAssets): CompletionState => {
    return computeCompletion(objectives, allAssets);
  },
);

export const selectCompletionStateOfInvestment = createSelector(
  selectInvestmentObjectives({filterSelectedAssets: true}),
  selectAllAssets,
  (objectives, allAssets): CompletionState => {
    return computeCompletion(objectives, allAssets);
  },
);

function computeCompletion(objectives: Objective[], allAssets: Asset[]): CompletionState {
  const objectiveCompletions = objectives.map((objective): ObjectiveCompletion => {
    return {
      objectiveUuid: objective.objectiveAsset?.assetUuid,
      completeForAdvisor: isCompleteForAdvisor(objective, allAssets),
      completeForClient: isCompleteForClient(objective, allAssets),
    };
  });
  return {
    objectives: compactObjectives(objectiveCompletions),
    completeForAdvisorOverall: objectiveCompletions.every(obj => obj.completeForAdvisor),
    completeForClientOverall: objectiveCompletions.every(obj => obj.completeForClient),
  };
}

function isCompleteForAdvisor(objective: Objective, assets: Asset[]): boolean {
  const currentAssetsComplete =
    objective.table.current.rows.length > 0 &&
    objective.table.current.rows.every(currentRow =>
      isDecisionMadeByAdvisor(currentRow.assetUuid, assets),
    );
  const hasOnlyNewAssets =
    objective.table.current.rows.length === 0 && objective.table.advisorProposed.rows.length > 0;
  const emptyAdditionalContracts =
    !objective.objectiveAsset &&
    objective.table.current.rows.length === 0 &&
    objective.table.advisorProposed.rows.length === 0;

  return currentAssetsComplete || hasOnlyNewAssets || emptyAdditionalContracts;
}

function isCompleteForClient(objective: Objective, assets: Asset[]): boolean {
  const advisorProposedAssetComplete =
    objective.table.advisorProposed.rows.length > 0 &&
    objective.table.advisorProposed.rows.every(advisorProposedRow =>
      isDecisionMadeByClient(advisorProposedRow.assetUuid, assets),
    );
  const emptyAdditionalContracts =
    !objective.objectiveAsset &&
    objective.table.current.rows.length === 0 &&
    objective.table.advisorProposed.rows.length === 0;

  return advisorProposedAssetComplete || emptyAdditionalContracts;
}

function compactObjectives(objectiveCompletions: ObjectiveCompletion[]): ObjectiveCompletion[] {
  const objectivesMap: Record<string, ObjectiveCompletion> = {};

  for (const objective of objectiveCompletions) {
    if (!objectivesMap[objective.objectiveUuid]) {
      objectivesMap[objective.objectiveUuid] = objective;
    } else {
      objectivesMap[objective.objectiveUuid].completeForAdvisor &&= objective.completeForAdvisor;
      objectivesMap[objective.objectiveUuid].completeForClient &&= objective.completeForClient;
    }
  }

  return Object.values(objectivesMap);
}
