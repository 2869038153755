/* eslint-disable max-classes-per-file */
import {HttpErrorResponse} from '@angular/common/http';
import {FindClient} from '@generated/defs/FindClient';
import {FoundPortalClient} from '@generated/defs/FoundPortalClient';
import {Action} from '@ngrx/store';

/* eslint-disable @typescript-eslint/no-shadow */
export enum FindClientsActionTypes {
  FindClientsStart = '[Find Clients] Update Clients Start',
  FindClientsSuccess = '[Find Clients] Update Clients Success',
  FindClientsError = '[Find Clients] Update Clients Error',
  ClearInput = '[Find Clients] Clear Input',
  FindClientsCancel = '[Find Clients] Update Clients Cancel',
}
/* eslint-enable */

export class FindClientsStart implements Action {
  readonly type = FindClientsActionTypes.FindClientsStart;

  constructor(public payload: FindClient) {}
}

export class FindClientsSuccess implements Action {
  readonly type = FindClientsActionTypes.FindClientsSuccess;

  constructor(public payload: FoundPortalClient[]) {}
}

export class FindClientsError implements Action {
  readonly type = FindClientsActionTypes.FindClientsError;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearClientsInput implements Action {
  readonly type = FindClientsActionTypes.ClearInput;

  constructor() {}
}

export class FindClientsCancel implements Action {
  readonly type = FindClientsActionTypes.FindClientsCancel;

  constructor() {}
}

export type FindClientsActions =
  | FindClientsStart
  | FindClientsSuccess
  | FindClientsError
  | FindClientsCancel
  | ClearClientsInput;
