import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {Asset, ValueAsset} from '@shared/analysis/models/asset';
import {selectFinances as selectFinancesAnalysis} from '@shared/analysis/store';
import {formatCZKNotNull} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, ItemsGroup} from './../summary.models';

export const selectInsuranceCategory = createSelector(selectFinancesAnalysis, finances =>
  createCategory(finances.insuranceProducts),
);

function createCategory(insurance: AssetPageStateValue): Category {
  return {
    label: insurance.title,
    value: formatCZKNotNull(insurance.sum),
    categoryTypes: [createCategoryType(insurance.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: [],
  };
}
