import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

const financialAnalysis = {
  url: 'financial-analysis',
  done: false,
  label: 'Finanční analýza',
};
const myPension = {
  url: 'my-pension',
  done: false,
  label: 'Moje penze',
};
const pensionSavings = {
  url: 'pension-savings',
  done: false,
  label: 'Penzijní spoření',
};

export const pensionStepperProgressConfigOld: IStepperProgressItem[] = createStepperProgressConfig(
  [financialAnalysis, myPension, pensionSavings],
  SecuredPage.Client,
  ClientPage.PensionOld,
);

export const pensionStepperProgressConfigInModal: IStepperProgressItem[] = [
  myPension,
  pensionSavings,
];
