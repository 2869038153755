import {IStepperProgressItem} from '@shared/ui';
import {createStepperProgressConfig} from '@shared/ui/stepper-progress/stepper-progress.utils';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';

export enum LifeInsurancePage {
  Family = 'family',
  Requirements = 'requirements',
  Finances = 'finances',
  Properties = 'properties',
  InsuranceProtection = 'insurance-protection',
  Survey = 'survey',
  CurrentSituation = 'current-situation',
  AnalysisSummary = 'analysis-summary',
  AdvisorProposed = 'advisor-proposed',
  ClientProposed = 'client-proposed',
  PlanSummary = 'plan-summary',
}

export const lifeInsurancePageNames = {
  [LifeInsurancePage.Family]: 'Rodina',
  [LifeInsurancePage.Requirements]: 'Požadavky',
  [LifeInsurancePage.Finances]: 'Hospodaření',
  [LifeInsurancePage.Properties]: 'Majetek',
  [LifeInsurancePage.Survey]: 'Dotazník',
  [LifeInsurancePage.InsuranceProtection]: 'Pojistná ochrana',
  [LifeInsurancePage.CurrentSituation]: 'Současné řešení',
  [LifeInsurancePage.AnalysisSummary]: 'Shrnutí',
  [LifeInsurancePage.AdvisorProposed]: 'Návrh řešení',
  [LifeInsurancePage.ClientProposed]: 'Volba klienta',
  [LifeInsurancePage.PlanSummary]: 'Shrnutí',
};

export function lifeInsuranceStepperProgressConfig(
  businessCaseStatus: BusinessCaseStatus,
): IStepperProgressItem[] {
  if (!businessCaseStatus) return [];

  const steps: Record<BusinessCaseStatus, LifeInsurancePage[]> = {
    [BusinessCaseStatus.FinancialAnalysisPreparation]: [
      LifeInsurancePage.Family,
      LifeInsurancePage.Requirements,
      LifeInsurancePage.Finances,
      LifeInsurancePage.Properties,
      LifeInsurancePage.InsuranceProtection,
      LifeInsurancePage.Survey,
      LifeInsurancePage.CurrentSituation,
      LifeInsurancePage.AnalysisSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPreparation]: [
      LifeInsurancePage.AdvisorProposed,
      LifeInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPresentation]: [
      LifeInsurancePage.Requirements,
      LifeInsurancePage.ClientProposed,
      LifeInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanFinalisation]: [],
    [BusinessCaseStatus.FinancialPlanTermination]: [],
  };

  const stepperItems: IStepperProgressItem[] = steps[businessCaseStatus]?.map(url => ({
    url,
    label: lifeInsurancePageNames[url],
  }));

  return createStepperProgressConfig(stepperItems, SecuredPage.Client, ClientPage.LifeInsurance);
}
