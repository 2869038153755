import {
  AssetType,
  BaseAsset,
  DateAsset,
  RelatedObjectiveAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';

export interface InvestmentProductsSumAsset extends BaseAsset, ValueAsset, DateAsset {
  type: AssetType.InvestmentProductsSum;
}

export interface CommonInvestmentAsset extends CommonFinancialProductAsset, RelatedObjectiveAsset {
  // Group Detaily smlouvy & Basic tab
  /** Úroková sazba */
  yearlyRate: number;
  /** Současná hodnota investovaných prostředků; currency */
  presentValue: number;
  /** Současná hodnota platná ke dni */
  presentValueDate: string;
  /** Cílová částka; currency */
  targetAmount: number;
  // tab has `relatedObjectiveUuid` field
}

export interface UnitTrustAsset extends CommonInvestmentAsset {
  type: AssetType.UnitTrust;
  // Group Detaily smlouvy
  /** Strategie */
  strategy: InvestmentStrategy;
}

export enum InvestmentStrategy {
  /** konzervativní */
  Conservative = 'conservative',
  /** vyvážená */
  Balanced = 'balanced',
  /** dynamická */
  Dynamic = 'dynamic',
}

export interface RealEstateFundAsset extends CommonInvestmentAsset {
  type: AssetType.RealEstateFund;
}

export interface CommodityAsset extends CommonInvestmentAsset {
  type: AssetType.Commodity;
}

export interface ExchangeTradedFundsAsset extends CommonInvestmentAsset {
  type: AssetType.ExchangeTradedFunds;
}

export interface CertificatesAsset extends CommonInvestmentAsset {
  type: AssetType.Certificates;
}

export interface CombinedDepositsAsset extends CommonInvestmentAsset {
  type: AssetType.CombinedDeposits;
}

export interface BuildingSavingsAsset extends CommonInvestmentAsset {
  type: AssetType.BuildingSavings;
}

export interface SupplementaryPensionSavingsAsset extends CommonInvestmentAsset {
  type: AssetType.SupplementaryPensionSavings;
  // Group Platba
  /** Příspěvek zaměstnavatele; currency */
  employerContribution: number;
  /** Možnost zkonzervativnění */
  conservationPossibility: boolean;
  /** Strategie */
  strategy: InvestmentStrategy;
}

export interface PensionInsuranceAsset extends CommonInvestmentAsset {
  type: AssetType.PensionInsurance;
  // Group Platba
  /** Příspěvek zaměstnavatele; currency */
  employerContribution: number;
}

export interface SavingsAccountAsset extends CommonInvestmentAsset {
  type: AssetType.SavingsAccount;
  // tab has `value` field with label 'Pravidelný vklad'
  // tab has `frequency` field with label 'Frekvence'
}

export type InvestmentAsset =
  | UnitTrustAsset
  | RealEstateFundAsset
  | CommodityAsset
  | ExchangeTradedFundsAsset
  | CertificatesAsset
  | CombinedDepositsAsset
  | BuildingSavingsAsset
  | SupplementaryPensionSavingsAsset
  | PensionInsuranceAsset
  | SavingsAccountAsset;
