import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

export enum FinancialPlanPage {
  Introduction = 'introduction',
  Finances = 'finances',
  Properties = 'properties',
  Priorities = 'priorities',
  PaymentOverview = 'payment-overview',
  BalanceSheet = 'balance-sheet',
  Overview = 'overview',
}
export enum FinancialPlanPageFinancesTabs {
  IncomesAndExpenses = 'incomesAndExpenses',
  ExpensesAndPriorities = 'expensesAndPriorities',
}

export enum FinancialPlanPageOverviewTabs {
  Priorities = 'priorities',
  Equity = 'equity',
  Payments = 'payments',
  Meeting = 'meeting',
}

export const financialPlanPageNames: Record<FinancialPlanPage, string> = {
  [FinancialPlanPage.Introduction]: 'Úvod',
  [FinancialPlanPage.Finances]: 'Hospodaření',
  [FinancialPlanPage.Properties]: 'Majetek',
  [FinancialPlanPage.Priorities]: 'Priority',
  [FinancialPlanPage.PaymentOverview]: 'Přehled plateb',
  [FinancialPlanPage.BalanceSheet]: 'Rozvaha',
  [FinancialPlanPage.Overview]: 'Přehled',
};

export type PaymentOverviewSection = 'payments' | 'contracts';

export const paymentOverviewSections: PaymentOverviewSection[] = ['payments', 'contracts'];

export const paymentOverviewSectionNames: Record<PaymentOverviewSection, string> = {
  payments: 'Sumarizace plateb',
  contracts: 'Platební kalendář',
};

export const financialPlanStepperProgressConfig: IStepperProgressItem[] =
  createStepperProgressConfig(
    [
      {
        url: FinancialPlanPage.Introduction,
        done: false,
        label: financialPlanPageNames.introduction,
      },
      {
        url: FinancialPlanPage.Finances,
        done: false,
        label: financialPlanPageNames.finances,
      },
      {
        url: FinancialPlanPage.Properties,
        done: false,
        label: financialPlanPageNames.properties,
      },
      {
        url: FinancialPlanPage.Priorities,
        done: false,
        label: financialPlanPageNames.priorities,
      },
      {
        url: FinancialPlanPage.PaymentOverview,
        done: false,
        label: financialPlanPageNames[FinancialPlanPage.PaymentOverview],
      },
      // {
      //   url: FinancialPlanPage.BalanceSheet,
      //   done: false,
      //   label: financialPlanPageNames[FinancialPlanPage.BalanceSheet],
      // },
      {
        url: FinancialPlanPage.Overview,
        done: false,
        label: financialPlanPageNames.overview,
      },
      // {
      //   url: 'contracts',
      //   done: false,
      //   label: 'Smlouvy',
      // },
      // {
      //   url: 'summary',
      //   done: false,
      //   label: 'Shrnutí',
      // },
      // {
      //   url: 'settings',
      //   done: false,
      //   label: 'Nastavení',
      // },
    ],
    SecuredPage.Client,
    ClientPage.FinancialPlan,
  );
