<kpt-input
  [formControl]="inputForm"
  [label]="to.label"
  type="number"
  [min]="to.min"
  [max]="to.max"
  [required]="to.required"
  [appendText]="to.appendText"
  [readonly]="to.readonly"
  (valueChange)="onChange(field, $event)"
  [subtext]="to.subtext"
  [appendTemplate]="to.appendTemplate"
  [disabledField]="to.disabled"
>
</kpt-input>
