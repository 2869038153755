<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>
  <kpt-objective-age-input
    grow
    label="Plánujeme dítě ve věku"
    name="start"
    [(start)]="this.asset.start"
  ></kpt-objective-age-input>

  <kpt-family-members
    type="radio"
    label="Na mateřskou dovolenou půjde"
    [onlyAdults]="true"
    [(value)]="asset.maternityFamilyMemberUuid"
  ></kpt-family-members>

  <kpt-family-members
    type="radio"
    label="Na rodičovskou dovolenou půjde"
    [onlyAdults]="true"
    [(value)]="asset.parentalFamilyMemberUuid"
  ></kpt-family-members>

  <kpt-row>
    <kpt-slider2
      grow
      label="Délka pobírání rodičovského příspěvku"
      [floor]="0"
      [ceil]="36"
      [translate]="translateSlider"
      [(value)]="asset.parentalBenefitInMonths"
    ></kpt-slider2>
    <kpt-input
      grow
      type="number"
      name="parentalBenefitInMonths"
      [min]="0"
      [max]="36"
      appendText="měsíců"
      [(ngModel)]="asset.parentalBenefitInMonths"
    ></kpt-input>
  </kpt-row>

  <kpt-input
    label="Poznámka"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>

  <kpt-investment-risk-profile [asset]="asset"></kpt-investment-risk-profile>
</ng-container>
