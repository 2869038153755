<div
  class="modal"
  [class.modal--open]="isModalOpen"
  (click)="backdropClose($event)"
  (scroll)="scroll()"
>
  <div class="modal-dialog modal-dialog-centered" [ngClass]="size ? 'modal-' + size : ''">
    <div class="modal-content">
      <button *ngIf="!hideClose" type="button" class="close" (click)="close.emit($event)">
        <span class="icon icon--close-modal icon--wider-click-area"></span>
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
