<ul *ngIf="tabs$ | async as tabs" class="tabs-nav">
  <li
    class="tabs-nav__item"
    [class.is-active]="tab.active"
    [class.has-error]="tab.hasError"
    (click)="setActiveTab(tab)"
    *ngFor="let tab of tabs; trackBy: trackByIndex"
  >
    {{ tab.to.label }}
  </li>
</ul>

<ng-container #fieldComponent></ng-container>
