import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {BusinessCaseMeeting} from '@generated/defs/BusinessCaseMeeting';
import {GetBusinessCaseResponse} from '@generated/defs/GetBusinessCaseResponse';
import {MeetingPostRequest} from '@generated/defs/MeetingPostRequest';
import {PostCloseBusinessCaseResponse} from '@generated/defs/PostCloseBusinessCaseResponse';
import {PostStatusSaveBusinessCaseResponse} from '@generated/defs/PostStatusSaveBusinessCaseResponse';
import {LoginService, NotificationService} from '@lib/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {
  BusinessCaseActions,
  LifeSituation,
  selectBusinessCaseLoading,
  selectBusinessCaseMode,
  selectBusinessCaseSituation,
  selectBusinessCaseStatus,
  selectBusinessCaseTimestamp,
  selectCurrentBusinessCase,
  selectFinalizedBusinessCases,
  selectRawBusinessCaseStatus,
} from '@shared/business-case/store';
import {contactSupport} from '@shared/models/message-localization.models';
import {DialogService} from '@shared/services/modal.service';
import * as moment from 'moment';
import {combineLatest, Observable} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';
import {NewPlanModalComponent} from 'src/app/modules/client/card-board/new-plan-modal/new-plan-modal.component';
import {FinancialPlanOverallService} from 'src/app/modules/financial-plan/financial-plan-overall.service';
import {
  BusinessCaseMode,
  BusinessCaseStatus,
} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  CompletionState,
  selectCompletionStateOfFinancialPlan,
  selectCompletionStateOfInvestment,
  selectCompletionStateOfPropertyInsurance,
  selectCompletionStateOfVehicleInsurance,
} from 'src/app/modules/financial-plan/store';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';

const dataVersion = '1.3';
const notificationDuration = 7_000;

export type FinalizationMode =
  | 'designIncomplete'
  | 'designComplete'
  | 'presentationIncomplete'
  | 'presentationComplete';

export type MeetingData = Omit<MeetingPostRequest, 'division'>;

export interface BusinessCaseDescription {
  description: string;
  timestamp: string;
}

const statusTexts: Record<BusinessCaseStatus, string> = {
  [BusinessCaseStatus.FinancialAnalysisPreparation]: 'Tvorba analýzy',
  [BusinessCaseStatus.FinancialPlanPreparation]: 'Tvorba plánu',
  [BusinessCaseStatus.FinancialPlanPresentation]: 'Prezentace plánu',
  [BusinessCaseStatus.FinancialPlanFinalisation]: 'Plán uzavřen',
  [BusinessCaseStatus.FinancialPlanTermination]: 'Jednání ukončeno',
};

const closeReasons: Partial<Record<BusinessCaseStatus, string>> = {
  [BusinessCaseStatus.FinancialPlanFinalisation]: 'Finanční plán uzavřen',
  [BusinessCaseStatus.FinancialPlanTermination]: 'Jednání bylo ukončeno',
};

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class BusinessCaseService {
  familyId: string;
  situation: LifeSituation;
  mode: FinalizationMode = 'designIncomplete';
  private businessCaseStatus: BusinessCaseStatus;
  private businessCaseMode: BusinessCaseMode;
  private completionState: CompletionState;
  private email: string;
  private division: string;

  constructor(
    private notificationService: NotificationService,
    private store: Store<State>,
    private integrationsService: IntegrationsService,
    private loginService: LoginService,
    private financialPlanOverallService: FinancialPlanOverallService,
    private dialogService: DialogService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.store
      .pipe(select(selectBusinessCaseStatus), untilDestroyed(this))
      .subscribe(businessCaseStatus => (this.businessCaseStatus = businessCaseStatus));

    this.store
      .pipe(select(selectBusinessCaseMode), untilDestroyed(this))
      .subscribe(businessCaseMode => (this.businessCaseMode = businessCaseMode));

    this.store
      .pipe(
        select(selectBusinessCaseSituation),
        switchMap(situation => {
          switch (situation) {
            case LifeSituation.PropertyInsurance:
              return this.store.pipe(select(selectCompletionStateOfPropertyInsurance));
            case LifeSituation.VehicleInsurance:
              return this.store.pipe(select(selectCompletionStateOfVehicleInsurance));
            case LifeSituation.Investment:
              return this.store.pipe(select(selectCompletionStateOfInvestment));
            default:
              return this.store.pipe(
                select(
                  selectCompletionStateOfFinancialPlan({
                    showNewDashboard: this.featureFlagsService.showNewDashboard,
                  }),
                ),
              );
          }
        }),
        untilDestroyed(this),
      )
      .subscribe(completionState => {
        this.completionState = completionState;
      });

    this.store
      .pipe(
        selectFamilyHead(),
        map(member => member.email),
        untilDestroyed(this),
      )
      .subscribe(email => (this.email = email));

    this.division = this.loginService.advisorDivision;
  }

  get designMode(): boolean {
    return this.businessCaseMode === BusinessCaseMode.Design;
    // return this.mode === 'designIncomplete' || this.mode === 'designComplete';
  }

  get designIncomplete(): boolean {
    return !this.terminated && this.designMode && !this.completionState.completeForAdvisorOverall;
    // return this.mode === 'designIncomplete';
  }

  get designComplete(): boolean {
    return !this.terminated && this.designMode && this.completionState.completeForAdvisorOverall;
    // return this.mode === 'designComplete';
  }

  get presentationMode(): boolean {
    return this.businessCaseMode === BusinessCaseMode.Presentation;
    // return this.mode === 'presentationIncomplete' || this.mode === 'presentationComplete';
  }

  get presentationIncomplete(): boolean {
    return (
      !this.terminated &&
      this.presentationMode &&
      (!this.completionState.completeForAdvisorOverall ||
        !this.completionState.completeForClientOverall)
    );
    // return this.mode === 'presentationIncomplete';
  }

  get presentationComplete(): boolean {
    return (
      !this.terminated &&
      this.presentationMode &&
      this.completionState.completeForAdvisorOverall &&
      this.completionState.completeForClientOverall
    );
    // return this.mode === 'presentationComplete';
  }

  get terminated(): boolean {
    return this.businessCaseStatus === BusinessCaseStatus.FinancialPlanTermination;
  }

  get finalised(): boolean {
    return this.businessCaseStatus === BusinessCaseStatus.FinancialPlanFinalisation;
  }

  async loadData(familyId: string, situation: LifeSituation, askForRestart = true) {
    this.familyId = familyId;
    this.situation = situation;

    this.store.dispatch(BusinessCaseActions.BC_ResetState());

    const businessCases = await this.integrationsService
      .listCases({family_uuid: familyId, data: {latestOnly: true, division: this.division}})
      .toPromise();

    this.store.dispatch(BusinessCaseActions.BC_SetData({businessCases, situation}));

    if (askForRestart) {
      this.askForRestartIfFinalized();
    }
  }

  async loadFinalizedBusinessCases() {
    const response = await this.integrationsService
      .listCases({family_uuid: this.familyId, data: {division: this.division}})
      .pipe(
        map(cases => cases || []),
        map(cases => cases.filter(c => c.status === BusinessCaseStatus.FinancialPlanFinalisation)),
      )
      .toPromise();
    this.store.dispatch(BusinessCaseActions.BC_SetFinalizedBusinessCases(response));
  }

  async closeFA(meetingData: MeetingData) {
    const timestamp = this.getTimestamp();

    try {
      await this.saveStatusOnPortal({
        status: BusinessCaseStatus.FinancialPlanPreparation,
        print: false,
        sendEmail: false,
        meeting: undefined,
        timestamp,
      });

      await this.integrationsService
        .planMeeting({family_uuid: this.familyId, data: {...meetingData, division: this.division}})
        .toPromise();

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanPreparation, timestamp);

      this.notificationService.dispatchSuccess(
        'Finanční analýza byla uzavřena a e-mail s další schůzkou byl odeslán.',
        notificationDuration,
      );
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při uzavírání finanční analýzy. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async closeFPDraft() {
    const timestamp = this.getTimestamp();

    try {
      await this.saveStatusOnPortal({
        status: BusinessCaseStatus.FinancialPlanPresentation,
        print: false,
        sendEmail: false,
        meeting: undefined,
        timestamp,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanPresentation, timestamp);

      this.notificationService.dispatchSuccess(
        'Návrh finančního plánu byl uzavřen a je připraven pro prezentaci.',
        notificationDuration,
      );
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při uzavírání návrhu finančního plánu. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async newFPDraft() {
    const timestamp = this.getTimestamp();

    try {
      await this.saveStatusOnPortal({
        status: BusinessCaseStatus.FinancialPlanPreparation,
        print: false,
        sendEmail: false,
        meeting: undefined,
        timestamp,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanPreparation, timestamp);

      this.notificationService.dispatchSuccess(
        'Byl vytvořen nový návrh finančního plánu.',
        notificationDuration,
      );
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při vytváření nového návrhu finančního plánu. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async terminateFP() {
    const timestamp = this.getTimestamp();

    try {
      await this.closeOnPortal({
        status: BusinessCaseStatus.FinancialPlanTermination,
        print: false,
        sendEmail: false,
        meeting: undefined,
        timestamp,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanTermination, timestamp);

      this.notificationService.dispatchSuccess('Jednání bylo ukončeno', notificationDuration);
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při ukončování jednání. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async resumeFP() {
    const timestamp = this.getTimestamp();

    try {
      await this.saveStatusOnPortal({
        status: BusinessCaseStatus.FinancialPlanPreparation,
        print: false,
        sendEmail: false,
        meeting: undefined,
        timestamp,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanPreparation, timestamp);

      this.notificationService.dispatchSuccess('Jednání bylo obnoveno', notificationDuration);
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při obnovování jednání. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async closeFinalFP(
    meetingData: MeetingData | false,
    callData: MeetingData | false,
    nextData: MeetingData | false,
    fpAttachmentUrl: string,
    faResultsUrl: string,
  ) {
    try {
      let meeting: BusinessCaseMeeting;
      if (meetingData) {
        meeting = {
          startDate: meetingData.startDate,
          endDate: meetingData.endDate,
          locationDesc: meetingData.locationDesc,
          description: meetingData.description,
          meetingType: 'PlanServiceMeeting',
        };
      }
      if (callData) {
        meeting = {
          startDate: callData.startDate,
          endDate: callData.endDate,
          locationDesc: callData.locationDesc,
          description: callData.description,
          meetingType: 'RemindMeeting',
        };
      }
      if (nextData) {
        meeting = {
          startDate: nextData.startDate,
          endDate: nextData.endDate,
          locationDesc: nextData.locationDesc,
          description: nextData.description,
          meetingType: 'PlanNextMeeting',
        };
      }
      const timestamp = this.getTimestamp();

      await this.closeOnPortal({
        status: BusinessCaseStatus.FinancialPlanFinalisation,
        print: true,
        sendEmail: true,
        meeting,
        timestamp,
        fpAttachmentUrl,
        faResultsUrl,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanFinalisation, timestamp);

      this.notificationService.dispatchSuccess('Finanční plán byl uzavřen.', notificationDuration);
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při uzavírání finančního plánu. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async interruptFP(meetingData: MeetingData) {
    try {
      const meeting: BusinessCaseMeeting = {
        startDate: meetingData.startDate,
        endDate: meetingData.endDate,
        locationDesc: meetingData.locationDesc,
        description: meetingData.description,
        meetingType: 'PlanMeeting',
      };
      const timestamp = this.getTimestamp();

      await this.saveStatusOnPortal({
        status: BusinessCaseStatus.FinancialPlanPresentation,
        print: true,
        sendEmail: true,
        meeting,
        timestamp,
      });

      this.updateBusinessCaseStatus(BusinessCaseStatus.FinancialPlanPresentation, timestamp);

      this.notificationService.dispatchSuccess(
        'Jednání bylo přerušeno a e-mail s další schůzkou byl odeslán.',
        notificationDuration,
      );
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při přerušení jednání. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  async printFP(): Promise<string> {
    const timestamp = this.getTimestamp();

    try {
      const response = await this.integrationsService
        .printSend({
          family_uuid: this.familyId,
          situation_id: this.situation,
          data: {
            dataVersion,
            jsonData: await this.getJsonData(),
            email: this.email,
            print: true,
            sendEmail: false,
            division: this.division,
            timestamp,
          },
        })
        .toPromise();
      return response.documentPreviewUrl;
    } catch (e) {
      console.error(e);
      this.notificationService.dispatchError(
        'Nastala chyba při přípravě tisku. ' + contactSupport,
        notificationDuration,
      );
      throw e;
    }
  }

  getBusinessCaseStatusDescription(): Observable<BusinessCaseDescription | null> {
    return combineLatest([
      this.store.pipe(select(selectRawBusinessCaseStatus)),
      this.store.pipe(select(selectBusinessCaseTimestamp)),
    ]).pipe(
      map(([businessCaseStatus, timestamp]): BusinessCaseDescription | null => {
        if (!timestamp) return null;

        const timeAgo = moment(timestamp).fromNow();

        switch (businessCaseStatus) {
          case BusinessCaseStatus.FinancialAnalysisPreparation:
          case BusinessCaseStatus.FinancialPlanPreparation:
          case BusinessCaseStatus.FinancialPlanPresentation:
            return {description: `Rozpracován, naposledy otevřen ${timeAgo}`, timestamp};
          case BusinessCaseStatus.FinancialPlanFinalisation:
            return {description: `Dokončen ${timeAgo}`, timestamp};
          case BusinessCaseStatus.FinancialPlanTermination:
            return {description: `Přerušen ${timeAgo}`, timestamp};
          default:
            return null;
        }
      }),
    );
  }

  getStatusTooltip(businessCase: GetBusinessCaseResponse): string {
    const timeAgo = businessCase.timestamp ? moment(businessCase.timestamp).fromNow() : null;

    switch (businessCase.status) {
      case BusinessCaseStatus.FinancialAnalysisPreparation:
      case BusinessCaseStatus.FinancialPlanPreparation:
      case BusinessCaseStatus.FinancialPlanPresentation:
        return `Rozpracován, naposledy otevřen ${timeAgo}`;
      case BusinessCaseStatus.FinancialPlanFinalisation:
        return `Dokončen ${timeAgo}`;
      case BusinessCaseStatus.FinancialPlanTermination:
        return `Přerušen ${timeAgo}`;
      default:
        return null;
    }
  }

  getFinalizedBusinessCases(): Observable<GetBusinessCaseResponse[]> {
    return this.store.pipe(select(selectFinalizedBusinessCases));
  }

  private async askForRestartIfFinalized() {
    const businessCase = await combineLatest([
      this.store.pipe(select(selectCurrentBusinessCase)),
      this.store.pipe(select(selectBusinessCaseLoading)),
    ])
      .pipe(
        filter(([, loading]) => !loading),
        first(),
        map(([b]) => b),
      )
      .toPromise();

    if (businessCase?.status === BusinessCaseStatus.FinancialPlanFinalisation) {
      this.dialogService.load(NewPlanModalComponent, {}, {size: 'sm'});
    }
  }

  private updateBusinessCaseStatus(status: BusinessCaseStatus, timestamp: string) {
    this.store.dispatch(
      BusinessCaseActions.BC_UpdateStatusAndTimestamp({
        situation: this.situation,
        status,
        timestamp,
      }),
    );
  }

  private async saveStatusOnPortal({
    status,
    print,
    sendEmail,
    meeting,
    timestamp,
  }: {
    status: BusinessCaseStatus;
    print: boolean;
    sendEmail: boolean;
    meeting: BusinessCaseMeeting | undefined;
    timestamp: string;
  }): Promise<PostStatusSaveBusinessCaseResponse> {
    return this.integrationsService
      .statusSave({
        family_uuid: this.familyId,
        situation_id: this.situation,
        data: {
          dataVersion,
          statusCode: status,
          statusText: statusTexts[status],
          jsonData: await this.getJsonData(meeting?.startDate),
          email: this.email,
          print,
          sendEmail,
          meeting,
          division: this.division,
          timestamp,
        },
      })
      .toPromise();
  }

  private async closeOnPortal({
    status,
    print,
    sendEmail,
    meeting,
    timestamp,
    fpAttachmentUrl,
    faResultsUrl,
  }: {
    status: BusinessCaseStatus;
    print: boolean;
    sendEmail: boolean;
    meeting: BusinessCaseMeeting | undefined;
    timestamp: string;
    fpAttachmentUrl?: string;
    faResultsUrl?: string;
  }): Promise<PostCloseBusinessCaseResponse> {
    return this.integrationsService
      .close({
        family_uuid: this.familyId,
        situation_id: this.situation,
        data: {
          dataVersion,
          statusCode: status,
          statusText: statusTexts[status],
          closeReason: closeReasons[status],
          jsonData: await this.getJsonData(meeting?.startDate),
          email: this.email,
          print,
          sendEmail,
          meeting,
          division: this.division,
          timestamp,
          faResultsUrl,
          fpAttachmentUrl,
        },
      })
      .toPromise();
  }

  private getJsonData(nextMeetingDate?: string) {
    return this.financialPlanOverallService
      .getOverallFinancialPlan(nextMeetingDate)
      .pipe(take(1))
      .toPromise();
  }

  private getTimestamp() {
    return moment().toISOString();
  }
}
