import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CreateClientsState} from '../reducers/create-client.reducer';

export const getCreateClientsState = createFeatureSelector<CreateClientsState>('createClient');

export const selectCreateClientLoading = createSelector(
  getCreateClientsState,
  (state: CreateClientsState) => state.loading,
);

export const selectCancellableRequest = createSelector(
  getCreateClientsState,
  (state: CreateClientsState) => state.cancellableRequest,
);
