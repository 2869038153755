import {
  Asset,
  assetNames,
  AssetType,
  immovablePropertyAssets,
  movablePropertyAssets,
  NameAsset,
  objectivesAssets,
  RelatedObjectiveAsset,
} from '@shared/analysis/models/asset';
import {flatMap} from 'lodash';
import {PrintSection} from './store/financial-plan.models';

// to smoothly animate graphs when store dispatches multiple results quickly one after each other
export const smoothDebounce = 100;

export enum FinancialResource {
  Housing = 'housing',
  Independence = 'independence',
  Reserve = 'reserve',
}

export function getContractName(
  currentAsset: Asset,
  proposedAsset: Asset,
  assetType: AssetType,
): string {
  const name = (proposedAsset as NameAsset)?.name ?? (currentAsset as NameAsset)?.name;
  return assetNames[assetType] + (name ? ` – ${name}` : '');
}

export function isAssetAssignedToPriority(asset: Asset, assets: Asset[]): boolean {
  if (!(asset as RelatedObjectiveAsset).relatedObjectiveUuid) return false;

  const relatedAsset = assets.find(
    a => a.assetUuid === (asset as RelatedObjectiveAsset).relatedObjectiveUuid,
  );
  if (!relatedAsset) return false;

  // assigned to a priority except property protection priority
  if (objectivesAssets.includes(relatedAsset.type)) return true;

  // assigned to property protection priority
  if (
    [...movablePropertyAssets, ...immovablePropertyAssets].includes(relatedAsset.type) &&
    [AssetType.PropertyInsurance, AssetType.VehicleInsurance].includes(asset.type)
  )
    return true;

  return false;
}

export function getPrintSectionAncestors(
  section: PrintSection,
  allSections: PrintSection[],
  includeCurrent = false,
): PrintSection[] {
  const parent = section.parentId ? allSections.find(s => s.id === section.parentId) : null;
  return [
    ...(includeCurrent ? [section] : []),
    ...(parent ? getPrintSectionAncestors(parent, allSections, true) : []),
  ];
}

export function getPrintSectionDescendants(
  section: PrintSection,
  includeCurrent = false,
): PrintSection[] {
  return [
    ...(includeCurrent ? [section] : []),
    ...flatMap(section.children || [], c => getPrintSectionDescendants(c, true)),
  ];
}
