/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {ResultSet} from '@shared/analysis/components/investment-survey-summary/investment-survey-summary.component';
import {InvestmentSummaryService} from '@shared/investment-survey-old/component/summary/investment/investment-summary.service';
import {SurveyResults} from '@shared/investment-survey-old/component/summary/life-insurance/life-insurance-summary.component';
import {LifeInsuranceSummaryService} from '@shared/investment-survey-old/component/summary/life-insurance/life-insurance-summary.service';
import {PensionInvestmentSurveyResult} from '@shared/investment-survey-old/component/summary/pension/pension-summary.models';
import {PensionSummaryService} from '@shared/investment-survey-old/component/summary/pension/pension-summary.service';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {isNil, omit} from 'lodash';
/* eslint-enable max-len */

export interface SurveyResultState {
  results: ResultSet[];
  showSummary: boolean;
  resolveError: boolean;
}

@Injectable()
export class InvestmentSurveySummaryService {
  constructor(
    private investmentSummaryService: InvestmentSummaryService,
    private lifeInsuranceSummaryService: LifeInsuranceSummaryService,
    private pensionSummaryService: PensionSummaryService,
  ) {}

  resolve(surveyAnswers: SurveyAnswers[], familyMembers: FamilyMember[]): SurveyResultState {
    let resolveError = false;
    const results: ResultSet[] = [];
    let showSummary = true;
    let resultState: SurveyResultState;
    try {
      familyMembers
        .map(familyMember => familyMember.sugarUuid)
        .forEach(sugarUuid => {
          const survey = surveyAnswers.find(s => s.sugarUuid === sugarUuid);
          if (!survey) return;
          const resultSet: ResultSet = {
            sugarUuid,
            lifeInsuranceResult: this.lifeInsuranceResult(survey),
            investmentResult: this.investmentResult(survey),
            supplementaryPensionSavingsResult: this.pensionResult(survey),
          };
          if (!Object.values(resultSet).every(Boolean)) {
            showSummary = false;
            return;
          }
          results.push(resultSet);
        });
    } catch (e) {
      console.error(e);
      resolveError = true;
      throw e;
    } finally {
      resultState = {results, resolveError, showSummary};
    }
    return resultState;
  }

  private lifeInsuranceResult(surveyAnswers: SurveyAnswers): SurveyResults {
    const result = this.lifeInsuranceSummaryService.calculateResults(surveyAnswers, true, true);
    if (Object.values(result).some(isNil)) return null;
    return result;
  }

  private investmentResult(surveyData: SurveyAnswers): UntypedFormGroup {
    const results = this.investmentSummaryService.evaluate(surveyData);
    if (Object.values(omit(results, 'category')).some(isNil)) return null;
    const resultForm = new UntypedFormGroup({
      knowledgeExperiencePoints: new UntypedFormControl(),
      horizon: new UntypedFormControl(),
      investmentPreferences: new UntypedFormControl(),
      preferredPortfolio: new UntypedFormControl(),
      bareLosses: new UntypedFormControl(),
      riskProfile: new UntypedFormControl(),
      strategy: new UntypedFormControl(),
      eligibility: new UntypedFormControl(),
      inconsistentPortfolioAnswers: new UntypedFormControl(),
      inconsistentInvestmentPurposeAnswers: new UntypedFormControl(),
    });
    this.investmentSummaryService.prepareResultForm(resultForm, results);

    return resultForm;
  }

  private pensionResult(surveyAnswers: SurveyAnswers): PensionInvestmentSurveyResult {
    const result = this.pensionSummaryService.evaluate(surveyAnswers, true);
    return Object.values(result).every(Boolean) ? result : null;
  }
}
