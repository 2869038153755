import {calculateCurrentEquity} from '@shared/analysis/evaluation';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {ContractorAsset} from '@shared/analysis/models/main-incomes';
import {isFunction} from '@shared/lib';
import {FASection} from '../models/section';

type SectionFunction = Partial<Record<FASection, (asset: Asset) => number>>;

const contractor: SectionFunction = {
  expenses: (asset: Asset) => {
    const contractorAsset = asset as ContractorAsset;
    return (
      contractorAsset.socialInsuranceValue +
      contractorAsset.healthInsuranceValue +
      (contractorAsset.sicknessInsurancePayer ? contractorAsset.sicknessInsuranceValue : 0)
    );
  },
};

const investmentProperties: SectionFunction = {
  investmentProperties: asset => calculateCurrentEquity([asset]),
};

const insuranceProperties: SectionFunction = {
  insuranceProperties: asset => calculateCurrentEquity([asset]),
};

const creditProperties: SectionFunction = {
  creditProperties: asset => -calculateCurrentEquity([asset]),
};

export const valueAppearanceFunctions: Partial<Record<AssetType, SectionFunction>> = {
  [AssetType.Contractor]: contractor,
  [AssetType.UnitTrust]: investmentProperties,
  [AssetType.RealEstateFund]: investmentProperties,
  [AssetType.Commodity]: investmentProperties,
  [AssetType.ExchangeTradedFunds]: investmentProperties,
  [AssetType.Certificates]: investmentProperties,
  [AssetType.CombinedDeposits]: investmentProperties,
  [AssetType.BuildingSavings]: investmentProperties,
  [AssetType.SupplementaryPensionSavings]: investmentProperties,
  [AssetType.PensionInsurance]: investmentProperties,
  [AssetType.SavingsAccount]: investmentProperties,
  [AssetType.Mortgage]: creditProperties,
  [AssetType.BuildingSavingsLoan]: creditProperties,
  [AssetType.Leasing]: creditProperties,
  [AssetType.CreditCard]: creditProperties,
  [AssetType.Overdraft]: creditProperties,
  [AssetType.ConsumerLoan]: creditProperties,
  [AssetType.OtherIndividualLoan]: creditProperties,
  [AssetType.TermDeposit]: investmentProperties,
  [AssetType.Bonds]: investmentProperties,
  [AssetType.QualifiedInvestorFunds]: investmentProperties,
  [AssetType.InvestmentLifeInsurance]: insuranceProperties,
  [AssetType.CapitalLifeInsurance]: insuranceProperties,
};

export const existsValueAppearanceFunction = (
  assetType: AssetType,
  section: FASection,
): boolean => {
  return (
    valueAppearanceFunctions[assetType] && isFunction(valueAppearanceFunctions[assetType][section])
  );
};
