import {InvestmentPurpose} from '@generated/defs/InvestmentPurpose';
import {Answers} from '@shared/investment-survey-old/models/answer';

export function hasInvestmentPortfolioInconsistency(answers: Answers): boolean {
  return (
    (answers.risk.answer === 'almost-none-risk' && answers.graph.answer !== 'A') ||
    (answers.risk.answer === 'low-risk' && !['A', 'B'].includes(answers.graph.answer as string)) ||
    (['partial-risk', 'max-risk'].includes(answers.risk.answer as string) &&
      !['C', 'D'].includes(answers.graph.answer as string))
  );
}

export function hasLifeInsurancePortfolioInconsistency(answers: Answers): boolean {
  return (
    (answers.risk.answer === 'no-risk' && answers.graph.answer !== 'A') ||
    (answers.risk.answer === 'almost-none-risk' &&
      !['A', 'B'].includes(answers.graph.answer as string)) ||
    (answers.risk.answer === 'low-risk' && !['B', 'C'].includes(answers.graph.answer as string)) ||
    (['partial-risk', 'max-risk'].includes(answers.risk.answer as string) &&
      !['C', 'D'].includes(answers.graph.answer as string))
  );
}

export function hasInvestmentPurposeInconsistency(answers: Answers): boolean {
  const shortTermDuration = ['less-than-year', 'min-one-year', 'min-three-years'];
  const longTermDuration = ['min-five-years', 'min-eight-years'];
  const investmentDuration = answers.investmentDurationInv.answer as string;
  const investmentPurpose = answers.investmentPurpose as unknown as InvestmentPurpose;
  return (
    (shortTermDuration.includes(investmentDuration) &&
      (investmentPurpose.longTermKnownIntention || investmentPurpose.longTermNoIntention)) ||
    (longTermDuration.includes(investmentDuration) &&
      (investmentPurpose.shortTermKnownIntention || investmentPurpose.shortTermNoIntention))
  );
}
