import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {PopUpBlockedData} from './pop-up-blocked.model';

@Component({
  selector: 'kpt-pop-up-blocked-modal',
  templateUrl: './pop-up-blocked-modal.component.html',
  styleUrls: ['./pop-up-blocked-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopUpBlockedModalComponent {
  title: string;
  data: PopUpBlockedData;

  constructor(private modalService: ModalService) {}

  get modalTitle(): string {
    if (this.title) return this.title;
    return 'Prohlížeč zablokoval otevření PDF v&nbsp;novém okně.';
  }

  openUrl() {
    window.open(this.data.url, '_blank');
    this.modalService.closeModal();
  }
}
