import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {financialOutlookI18n} from '@shared/analysis/forms/financial-outlook.definitions';
import {Asset, assetNames, AssetType, ValueAsset} from '@shared/analysis/models/asset';
import {
  ChildrenTaxAdvantageAsset,
  FinancialOutlookAsset,
  FinancialOutlookType,
} from '@shared/analysis/models/income-attributes';
import {
  ContractorAsset,
  EmploymentAndContractorAsset,
  EmploymentAsset,
} from '@shared/analysis/models/main-incomes';
import {
  selectCurrentAssets,
  selectFinances as selectFinancesAnalysis,
} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {prepareTaxAdvantageSentence} from 'src/app/modules/financial-analysis/finances/main-family-income/main-family-income.helpers';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

const selectChildrenTaxAdvantageAndFinancialOutlook = createSelector(selectCurrentAssets, assets =>
  assets.filter(asset =>
    [AssetType.ChildrenTaxAdvantage, AssetType.FinancialOutlook].includes(asset.type),
  ),
);

export const selectMainIncomesCategory = createSelector(
  selectFinancesAnalysis,
  selectChildrenTaxAdvantageAndFinancialOutlook,
  getFamilyMembers,
  (finances, childrenTaxAdvantageAndFinancialOutlook, family) =>
    createCategory(finances.mainIncomes, childrenTaxAdvantageAndFinancialOutlook, family),
);

function createCategory(
  mainIncomes: AssetPageStateValue,
  childrenTaxAdvantageAndFinancialOutlookAssets: Asset[],
  family: FamilyMember[],
): Category {
  return {
    label: mainIncomes.title,
    value: formatCZKNotNull(mainIncomes.sum),
    categoryTypes: [
      createCategoryType(mainIncomes.assets, childrenTaxAdvantageAndFinancialOutlookAssets, family),
    ],
  };
}

function createCategoryType(
  assets: Asset[],
  childrenTaxAdvantageAndFinancialOutlookAssets: Asset[],
  family: FamilyMember[],
): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
    additionalNotes: createAdditionalNotes(childrenTaxAdvantageAndFinancialOutlookAssets, family),
  };
}

function createAdditionalNotes(assets: Asset[], family: FamilyMember[]): string[] {
  const financialOutlook = assets.find(
    asset => asset.type === AssetType.FinancialOutlook,
  ) as FinancialOutlookAsset;
  const childrenTaxAdvantage = assets.find(
    asset => asset.type === AssetType.ChildrenTaxAdvantage,
  ) as ChildrenTaxAdvantageAsset;
  const taxAdvantageSentence = prepareTaxAdvantageSentence(childrenTaxAdvantage, family);
  const outlook =
    financialOutlook && financialOutlook.outlook
      ? financialOutlook.outlook
      : FinancialOutlookType.Positive;
  return [
    `${assetNames[AssetType.FinancialOutlook]}:&nbsp;<strong>${financialOutlookI18n[
      outlook
    ].toLowerCase()}</strong>`,
    taxAdvantageSentence &&
      `${assetNames[AssetType.ChildrenTaxAdvantage]}:&nbsp;${taxAdvantageSentence}`,
  ];
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: [...createItemFunctions[asset.type](asset)].filter(Boolean),
  };
}

const createItemFunctions: Partial<Record<AssetType, (asset: Asset) => Item[]>> = {
  [AssetType.Employment]: createEmploymentItems,
  [AssetType.Contractor]: createContractorItems,
};

function createEmploymentItems(asset: EmploymentAsset): Item[] {
  return createEmploymentAndContractorItems(asset);
}

function createContractorItems(asset: ContractorAsset): Item[] {
  return createEmploymentAndContractorItems(asset);
}

function createEmploymentAndContractorItems(asset: EmploymentAndContractorAsset): Item[] {
  return [
    createItem(FieldLabel.NET_INCOME, formatCZKNotNull(asset.netIncome)),
    createItem(FieldLabel.OTHER_INCOME, formatCZKNotNull(asset.otherIncome)),
    createItem(FieldLabel.PROFESSION, asset.profession),
    createItem(FieldLabel.INCOME_START, formatDate(asset.start)),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}
