import {I18nPluralPipe, NgLocalization} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {isNumber} from 'lodash';

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  itemPluralMapping = {
    let: {
      '=1': 'rok',
      '=2': 'roky',
      '=3': 'roky',
      '=4': 'roky',
      other: 'let',
    },
    měsíců: {
      '=1': 'měsíc',
      '=2': 'měsíce',
      '=3': 'měsíce',
      '=4': 'měsíce',
      other: 'měsíců',
    },
  };

  constructor(private localization: NgLocalization) {}

  transform(value: string | number, label: keyof PluralPipe['itemPluralMapping']): string {
    const i18nPipe: I18nPluralPipe = new I18nPluralPipe(this.localization);
    const amount = Math.abs(typeof value === 'string' ? parseInt(value, 10) : value);
    if (!this.itemPluralMapping[label] || !isNumber(amount)) {
      return label;
    }
    return i18nPipe.transform(amount, this.itemPluralMapping[label]);
  }
}
