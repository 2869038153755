export interface IGraphEvents {
  label: string;
  labelFormatted?: string;
  type: string;
  startYear: number;
  icon?: string;
  buttonType?: string;
  isDraggable?: boolean;
  params?: any;
  showRemoveButton?: boolean;
  values?: any;
  typeIndex?: number;
  tooltip?: boolean;
  data?: any;
  recommended?: boolean;
}

export enum AnalysisGraphType {
  OBJECTIVES = 'objectives',
  BILANCE = 'bilance',
}

export interface IAnalysisTimeLineItem {
  date: number;
  cost?: number;
  income?: number;
}

export interface IFromTo {
  from: number;
  to: number;
}
