import {Component, Input} from '@angular/core';
import {Question} from '@shared/investment-survey-old/models/question';

@Component({
  selector: 'kpt-survey-step',
  template: ` <div class="step-content">
    <div class="step-title">
      <label
        *ngIf="question?.question"
        class="font-weight-semibold"
        [innerHTML]="question.question"
      ></label>
      <ng-content select=".title-body"></ng-content>
    </div>

    <p *ngIf="question?.description" [innerHTML]="question.description" class="description"></p>

    <ng-container *ngIf="question?.moreDescription">
      <p *ngIf="moreInfoVisible" [innerHTML]="question.moreDescription"></p>
      <p>
        <kpt-show-more
          [open]="moreInfoVisible"
          [prependTemplate]="prepend"
          (click)="this.moreInfoVisible = !this.moreInfoVisible"
        >
          <ng-template #prepend>
            <span class="icon icon--bulb"></span>
          </ng-template>
        </kpt-show-more>
      </p>
    </ng-container>

    <div class="step-body-content">
      <ng-content></ng-content>
    </div>
  </div>`,
  styleUrls: ['./survey-step.component.scss'],
})
export class SurveyStepComponent {
  @Input()
  question: Question;

  moreInfoVisible = false;
}
