import {ElementRef, Injectable} from '@angular/core';
import {FindClient, FoundPortalClient} from '@generated/model';
import {select, Store} from '@ngrx/store';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {take} from 'rxjs/operators';
import {isForeignerBirthNumber, scrollToElement} from 'src/shared/utils';
import {State} from 'src/store';
import {CreateClientCancel} from 'src/store/actions/create-client.actions';
import {FindClientsCancel, FindClientsStart} from 'src/store/actions/find-clients.actions';
import {FindClientsState} from 'src/store/reducers/find-clients.reducer';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@Injectable({
  providedIn: 'root',
})
export class ClientSearchService {
  isClientLoading = false;
  showSearchResults = false;
  duplicateClientCheck = false;
  clientFound = false;
  foundByBirthNumber = false;
  enableCreation = false;
  showResultsWrapper = false;
  searchedBirthNumber: string;
  cancellableFindClient = false;
  cancellableCreateClient = false;
  isSearching = false;

  constructor(private store: Store<State>, private modalService: ModalService) {}

  get isForeignerBirthNumber() {
    return this.searchedBirthNumber ? isForeignerBirthNumber(this.searchedBirthNumber) : false;
  }

  setState(state: FindClientsState, resultsElem: ElementRef) {
    this.duplicateClientCheck = this.isSearching;
    if (state.clients.length > 0) this.clientFound = true;
    else this.enableCreation = true;
    this.showResultsWrapper = true;

    this.foundByBirthNumber = this.checkBirthNumber(state.clients);
    this.isSearching = false;
    setTimeout(() => {
      resultsElem.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    });
  }

  resetSearchState() {
    this.showSearchResults = false;
    this.clientFound = false;
    this.duplicateClientCheck = false;
    this.foundByBirthNumber = false;
    this.enableCreation = false;
    this.searchedBirthNumber = null;
    this.showResultsWrapper = false;

    // cancel possible running requests
    if (this.cancellableFindClient) {
      this.store.dispatch(new FindClientsCancel());
    }

    if (this.cancellableCreateClient) {
      this.store.dispatch(new CreateClientCancel());
    }
  }

  searchClients(findClient: FindClient) {
    this.resetSearchState();
    this.searchedBirthNumber = findClient.birthNumber;
    this.isSearching = true;
    this.showSearchResults = true;
    this.store.dispatch(new FindClientsStart(findClient));
  }

  enableClientCreation(value: boolean, element: HTMLDivElement) {
    this.enableCreation = value;
    this.showSearchResults = !value;
    if (value) scrollToElement(element);
  }

  reassign(client: FoundPortalClient, addToFamily = false) {
    this.store.pipe(select(getFamilyUuid), take(1)).subscribe(familyUuid => {
      this.modalService.openModal({
        component: 'ReassignComponent',
        data: {
          client,
          familyUuid,
          addToFamily,
        },
        title: `Převzetí klienta
      ${client.firstName ? ' ' + client.firstName : ''}${
          client.lastName ? ' ' + client.lastName : ''
        }`,
      });
    });
  }

  private checkBirthNumber(clients: FoundPortalClient[]) {
    return clients.some(
      client => client.access === 'permitted' || client.birthNumber === this.searchedBirthNumber,
    );
  }
}
