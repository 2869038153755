/**
 * @file Automatically generated by barrelsby.
 */

export * from './birth-number.fnc';
export * from './create-string-from-template.fnc';
export * from './custom-validators.fnc';
export * from './get-age-in-year.fnc';
export * from './get-form-values.fnc';
export * from './get-person-age.fnc';
export * from './get-providers-for.fnc';
export * from './get-start-year.fnc';
export * from './get-year-from-age.fnc';
export * from './infinite-scroll-observable.fnc';
export * from './is-router-link.fnc';
export * from './scroll-to-element.fnc';
