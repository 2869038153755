import {PensionEnsurementTypePersonEnum} from '@generated/defs/Person';
import {LifeInsurancePersonVM} from '@shared/analysis/models/life-insurance-risks-vm';
import {RiskUnit} from '@shared/analysis/models/risks';
import {
  childDailyCompensation,
  childHospitalization,
  childMinConstantValueDescription,
  childMinValueDescription,
  childPermanentDisability,
  childPhysicalDamage,
  childSeriousDisease,
  childStandardConstantValueDescription,
  childStandardValueDescription,
  dailyCompensation,
  dailyCompensationDescription,
  dailyCompensationExDescription,
  deathChildrenDescription,
  deathChildrenEnsurement,
  deathDebtDescription,
  deathDebtIncomeDescription,
  deathDebtRepayment,
  deathThriceAnnualIncome,
  deathThriceDescription,
  helpChildDailyCompensation,
  helpChildHospitalization,
  helpChildPermanentDisability,
  helpChildPhysicalDamage,
  helpChildSeriousDisease,
  helpDailyCompensation,
  helpDeathChildrenEnsurement,
  helpDeathDebtRepayment,
  helpDeathThriceAnnualIncome,
  helpHospitalization,
  helpIncapacity,
  helpInvalidity,
  helpInvalidityDebtRepayment,
  helpPermanentDisability,
  helpPhysicalDamage,
  helpSeriousDisease,
  hospitalization,
  hospitalizationDescription,
  incapacity,
  incapacityDescription,
  incapacityExDescription,
  invalidity,
  invalidityDebtDescription,
  invalidityDebtRepayment,
  invalidityDescription,
  invalidityExDescription,
  permanentDescription,
  permanentDisability,
  permanentExDescription,
  physicalDamage,
  physicalDamageDescription,
  physicalDamageExDescription,
  seriousDescription,
  seriousDisease,
  seriousExDescription,
} from './life-insurance-risk-functions';

export interface LifeInsuranceRisk {
  personId: string;
  key: PersonRisk;
  active: boolean;
  limit: number;
  unit?: RiskUnit;
  age: number;
  provision: Provision;
}

export interface LifeInsurancePersonData {
  personId: string;
  riskProfession?: boolean;
  riskProfessionNote?: string;
  createReserves?: boolean;
  taxRelief?: boolean;
  employeeContribution?: boolean;
  pensionEnsurement?: boolean;
  pensionEnsurementType?: PensionEnsurementTypePersonEnum;
  usePaymentProtectionInsurance?: boolean;
  useOtherIncome?: boolean;
  otherIncomeAssetUuids?: string[];
  useSixMonthReserve?: boolean;
  sixMonthReserveIncapacity?: boolean;
  sixMonthReserveDailyCompensation?: boolean;
  sixMonthReservePhysicalDamage?: boolean;
  sixMonthReserveHospitalization?: boolean;
}

export enum PersonRisk {
  Incapacity = 'incapacity',
  DailyCompensation = 'dailyCompensation',
  PhysicalDamage = 'physicalDamage',
  Hospitalization = 'hospitalization',
  SeriousDisease = 'seriousDisease',
  PermanentDisability = 'permanentDisability',
  Invalidity = 'invalidity',
  InvalidityDebtRepayment = 'invalidityDebtRepayment',
  DeathDebtRepayment = 'deathDebtRepayment',
  DeathThriceAnnualIncome = 'deathThriceAnnualIncome',
  DeathChildrenEnsurement = 'deathChildrenEnsurement',
  ChildDailyCompensation = 'childDailyCompensation',
  ChildPhysicalDamage = 'childPhysicalDamage',
  ChildHospitalization = 'childHospitalization',
  ChildSeriousDisease = 'childSeriousDisease',
  ChildPermanentDisability = 'childPermanentDisability',
}

export enum Outlook {
  Same,
  Decreasing,
  Min,
  Daily,
}

export enum Provision {
  Income = 'income',
  Expenses = 'expenses',
  Standard = 'standard',
  Minimal = 'minimal',
  Custom = 'custom',
}

export interface RiskDefinition {
  key: PersonRisk;
  label: string;
  onlyProposals?: boolean;
  child?: boolean;
  help?: (input: RiskInput) => string;
  description: (input: RiskInput) => string;
  outlook: Outlook;
  provision: Provision;
  calculate: (input: RiskInput) => number;
}

export interface RiskInput {
  monthlyIncomeEmployee?: number;
  monthlyIncomeContractor?: number;
  otherEmployee?: number;
  otherContractor?: number;
  insuranceContractor?: number;
  netMonthlyExpense?: number;
  debt?: number;
  age?: number;
  childrenAges?: number[];
  childrenTaxAdvantage?: number;
  provision: Provision;
  persons?: LifeInsurancePersonVM[];
  otherIncome?: number;
  mortgageRepaymentByRisk?: MortgageRepaymentByRisk;
  sixMonthReserveIncapacity?: boolean;
  sixMonthReserveDailyCompensation?: boolean;
  sixMonthReservePhysicalDamage?: boolean;
  sixMonthReserveHospitalization?: boolean;
}

export type MortgageRepaymentByRisk = Partial<Record<PersonRisk, number>>;

export const riskLabels: {[key: string]: string} = {
  incapacity: 'Pracovní neschopnost',
  dailyCompensation: 'Denní odškodné',
  physicalDamage: 'Tělesné poškození',
  hospitalization: 'Hospitalizace',
  seriousDisease: 'Vážná onemocnění',
  permanentDisability: 'Trvalé následky úrazu',
  invalidity: 'Invalidita',
  invalidityDebtRepayment: 'Invalidita – splacení úvěru',
  deathDebtRepayment: 'Smrt – splacení úvěru',
  deathThriceAnnualIncome: 'Smrt – zajištění rodiny 3 roční příjmy',
  deathChildrenEnsurement: 'Smrt – zajištění dětí',
};

export const riskDefinitions: RiskDefinition[] = [
  {
    key: PersonRisk.DeathDebtRepayment,
    provision: Provision.Income,
    label: riskLabels.deathDebtRepayment,
    outlook: Outlook.Decreasing,
    calculate: deathDebtRepayment,
    help: helpDeathDebtRepayment,
    description: deathDebtIncomeDescription,
  },
  {
    key: PersonRisk.DeathDebtRepayment,
    provision: Provision.Expenses,
    label: riskLabels.deathDebtRepayment,
    outlook: Outlook.Decreasing,
    calculate: deathDebtRepayment,
    help: helpDeathDebtRepayment,
    description: deathDebtDescription,
  },
  {
    key: PersonRisk.DeathThriceAnnualIncome,
    provision: Provision.Income,
    label: riskLabels.deathThriceAnnualIncome,
    outlook: Outlook.Same,
    calculate: deathThriceAnnualIncome,
    help: helpDeathThriceAnnualIncome,
    description: deathThriceDescription,
  },
  {
    key: PersonRisk.DeathChildrenEnsurement,
    provision: Provision.Expenses,
    label: riskLabels.deathChildrenEnsurement,
    outlook: Outlook.Decreasing,
    calculate: deathChildrenEnsurement,
    help: helpDeathChildrenEnsurement,
    description: deathChildrenDescription,
  },
  {
    key: PersonRisk.SeriousDisease,
    provision: Provision.Income,
    label: riskLabels.seriousDisease,
    outlook: Outlook.Same,
    calculate: seriousDisease,
    help: helpSeriousDisease,
    description: seriousDescription,
  },
  {
    key: PersonRisk.SeriousDisease,
    provision: Provision.Expenses,
    label: riskLabels.seriousDisease,
    outlook: Outlook.Same,
    calculate: seriousDisease,
    help: helpSeriousDisease,
    description: seriousExDescription,
  },
  {
    key: PersonRisk.Invalidity,
    provision: Provision.Income,
    label: riskLabels.invalidity,
    outlook: Outlook.Decreasing,
    calculate: invalidity,
    help: helpInvalidity,
    description: invalidityDescription,
  },
  {
    key: PersonRisk.Invalidity,
    provision: Provision.Expenses,
    label: riskLabels.invalidity,
    outlook: Outlook.Decreasing,
    calculate: invalidity,
    help: helpInvalidity,
    description: invalidityExDescription,
  },
  {
    key: PersonRisk.InvalidityDebtRepayment,
    provision: Provision.Expenses,
    label: riskLabels.invalidityDebtRepayment,
    outlook: Outlook.Decreasing,
    calculate: invalidityDebtRepayment,
    help: helpInvalidityDebtRepayment,
    description: invalidityDebtDescription,
  },
  {
    key: PersonRisk.Hospitalization,
    provision: Provision.Income,
    label: riskLabels.hospitalization,
    outlook: Outlook.Daily,
    calculate: hospitalization,
    help: helpHospitalization,
    description: hospitalizationDescription,
  },
  {
    key: PersonRisk.Hospitalization,
    provision: Provision.Expenses,
    label: riskLabels.hospitalization,
    outlook: Outlook.Daily,
    calculate: hospitalization,
    help: helpHospitalization,
    description: hospitalizationDescription,
  },
  {
    key: PersonRisk.Incapacity,
    provision: Provision.Income,
    label: riskLabels.incapacity,
    outlook: Outlook.Daily,
    calculate: incapacity,
    help: helpIncapacity,
    description: incapacityDescription,
  },
  {
    key: PersonRisk.Incapacity,
    provision: Provision.Expenses,
    label: riskLabels.incapacity,
    outlook: Outlook.Daily,
    calculate: incapacity,
    help: helpIncapacity,
    description: incapacityExDescription,
  },
  {
    key: PersonRisk.PermanentDisability,
    provision: Provision.Income,
    label: riskLabels.permanentDisability,
    outlook: Outlook.Min,
    calculate: permanentDisability,
    help: helpPermanentDisability,
    description: permanentDescription,
  },
  {
    key: PersonRisk.PermanentDisability,
    provision: Provision.Expenses,
    label: riskLabels.permanentDisability,
    outlook: Outlook.Min,
    calculate: permanentDisability,
    help: helpPermanentDisability,
    description: permanentExDescription,
  },
  {
    key: PersonRisk.DailyCompensation,
    provision: Provision.Income,
    label: riskLabels.dailyCompensation,
    outlook: Outlook.Daily,
    calculate: dailyCompensation,
    help: helpDailyCompensation,
    description: dailyCompensationDescription,
  },
  {
    key: PersonRisk.DailyCompensation,
    provision: Provision.Expenses,
    label: riskLabels.dailyCompensation,
    outlook: Outlook.Daily,
    calculate: dailyCompensation,
    help: helpDailyCompensation,
    description: dailyCompensationExDescription,
  },
  {
    key: PersonRisk.PhysicalDamage,
    provision: Provision.Income,
    label: riskLabels.physicalDamage,
    outlook: Outlook.Same,
    calculate: physicalDamage,
    help: helpPhysicalDamage,
    description: physicalDamageDescription,
  },
  {
    key: PersonRisk.PhysicalDamage,
    provision: Provision.Expenses,
    label: riskLabels.physicalDamage,
    outlook: Outlook.Same,
    calculate: physicalDamage,
    help: helpPhysicalDamage,
    description: physicalDamageExDescription,
  },
  {
    key: PersonRisk.ChildSeriousDisease,
    provision: Provision.Standard,
    label: riskLabels.seriousDisease,
    outlook: Outlook.Same,
    calculate: childSeriousDisease,
    help: helpChildSeriousDisease,
    description: childStandardConstantValueDescription,
  },
  {
    key: PersonRisk.ChildSeriousDisease,
    provision: Provision.Minimal,
    label: riskLabels.seriousDisease,
    outlook: Outlook.Same,
    calculate: childSeriousDisease,
    help: helpChildSeriousDisease,
    description: childMinConstantValueDescription,
  },
  {
    key: PersonRisk.ChildHospitalization,
    provision: Provision.Standard,
    label: riskLabels.hospitalization,
    outlook: Outlook.Daily,
    calculate: childHospitalization,
    help: helpChildHospitalization,
    description: childStandardValueDescription,
  },
  {
    key: PersonRisk.ChildHospitalization,
    provision: Provision.Minimal,
    label: riskLabels.hospitalization,
    outlook: Outlook.Daily,
    calculate: childHospitalization,
    help: helpChildHospitalization,
    description: childMinValueDescription,
  },
  {
    key: PersonRisk.ChildPermanentDisability,
    provision: Provision.Standard,
    label: riskLabels.permanentDisability,
    outlook: Outlook.Same,
    calculate: childPermanentDisability,
    help: helpChildPermanentDisability,
    description: childStandardConstantValueDescription,
  },
  {
    key: PersonRisk.ChildPermanentDisability,
    provision: Provision.Minimal,
    label: riskLabels.permanentDisability,
    outlook: Outlook.Same,
    calculate: childPermanentDisability,
    help: helpChildPermanentDisability,
    description: childMinConstantValueDescription,
  },
  {
    key: PersonRisk.ChildDailyCompensation,
    provision: Provision.Standard,
    label: riskLabels.dailyCompensation,
    outlook: Outlook.Daily,
    calculate: childDailyCompensation,
    help: helpChildDailyCompensation,
    description: childStandardValueDescription,
  },
  {
    key: PersonRisk.ChildDailyCompensation,
    provision: Provision.Minimal,
    label: riskLabels.dailyCompensation,
    outlook: Outlook.Daily,
    calculate: childDailyCompensation,
    help: helpChildDailyCompensation,
    description: childMinValueDescription,
  },
  {
    key: PersonRisk.ChildPhysicalDamage,
    provision: Provision.Standard,
    label: riskLabels.physicalDamage,
    outlook: Outlook.Same,
    calculate: childPhysicalDamage,
    help: helpChildPhysicalDamage,
    description: childStandardValueDescription,
  },
  {
    key: PersonRisk.ChildPhysicalDamage,
    provision: Provision.Minimal,
    label: riskLabels.physicalDamage,
    outlook: Outlook.Same,
    calculate: childPhysicalDamage,
    help: helpChildPhysicalDamage,
    description: childMinValueDescription,
  },
];

export const riskDefinitionsMap: {
  [provision in Provision]: {[riskKey: string]: RiskDefinition};
} = {
  [Provision.Income]: {},
  [Provision.Expenses]: {},
  [Provision.Standard]: {},
  [Provision.Minimal]: {},
  [Provision.Custom]: {},
};
for (const riskDef of riskDefinitions) {
  if (!riskDefinitionsMap[riskDef.provision]) {
    riskDefinitionsMap[riskDef.provision] = {};
  }
  riskDefinitionsMap[riskDef.provision][riskDef.key] = riskDef;
}

export const adultIncomeRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Income,
);

export const adultExpensesRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Expenses,
);

export const adultRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Income || r.provision === Provision.Expenses,
);

export const childMinimalRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Minimal,
);

export const childStandardRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Standard,
);

export const childRiskDefinitions = riskDefinitions.filter(
  r => r.provision === Provision.Minimal || r.provision === Provision.Standard,
);

export const otherSideProvision: {[provision in Provision]: Provision} = {
  [Provision.Income]: Provision.Expenses,
  [Provision.Expenses]: Provision.Income,
  [Provision.Standard]: Provision.Minimal,
  [Provision.Minimal]: Provision.Standard,
  [Provision.Custom]: Provision.Custom,
};

export function getRiskId(risk: Pick<LifeInsuranceRisk, 'personId' | 'provision' | 'key'>): string {
  return `${risk.personId}-${risk.provision}-${risk.key}`;
}
