<nav class="progress">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="l-offset r-offset">
          <ul class="progress-bar">
            <li
              *ngFor="let item of config"
              class="progress-bar__item"
              [class.progress-bar__item--done]="!!item.done && !isRouteIsActive(item.url)"
              [class.progress-bar__item--disabled]="!!item.disabled"
              [routerLinkActive]="[item.url ? 'progress-bar__item--active' : '']"
            >
              <a
                class="progress-bar__link"
                [routerLink]="item.url ? item.url : null"
                queryParamsHandling="preserve"
              >
                <span class="progress-bar__bullet"></span>
                <span
                  class="progress-bar__label"
                  [class.progress-bar__label-white]="useWhiteColor()"
                >
                  {{ item.label }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
