<div class="button-group">
  <ng-container *ngFor="let item of items">
    <button
      type="button"
      class="button-group-item"
      [class.active]="item.value === selectedValue"
      (click)="selectItem(item)"
    >
      {{ item.label }}
    </button>
  </ng-container>
</div>
