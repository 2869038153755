import {Injectable} from '@angular/core';
import {IModal} from '@shared/lib/components/modal/models/modal.model';
import {isEqual} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  data$ = new BehaviorSubject<any>({});
  activeModal$ = new BehaviorSubject<IModal>(null);

  get activeModal() {
    return this.activeModal$.value;
  }

  get hasOpenModals(): Observable<boolean> {
    return this.activeModal$.pipe(map(Boolean));
  }

  private readonly noOverflowStyle = this.buildStyleElement();

  constructor() {
    this.activeModal$.pipe(distinctUntilChanged(isEqual)).subscribe(activeModal => {
      if (activeModal) document.body.appendChild(this.noOverflowStyle);
      else if (document.body.contains(this.noOverflowStyle))
        document.body.removeChild(this.noOverflowStyle);
    });
  }

  setData(data: any) {
    this.data$.next(data);
  }

  openModal(modalData: IModal) {
    this.activeModal$.next(modalData);
  }

  closeModal() {
    this.activeModal$.next(null);
  }

  private buildStyleElement(): HTMLStyleElement {
    const style = document.createElement('style');
    style.setAttribute('data-debug', 'Injected by ModalService.');
    style.textContent = `
            body {
                overflow: hidden !important ;
            }
        `;

    return style;
  }
}
