import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {Asset, AssetType, ValueAsset} from '@shared/analysis/models/asset';
import {VehicleAsset} from '@shared/analysis/models/movable-properties';
import {CommonPropertyAsset} from '@shared/analysis/models/properties';
import {selectProperties} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
  getCheckboxTextValue,
  numberToString,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

export const selectMovableCategory = createSelector(selectProperties, properties =>
  createCategory(properties.movableProperties),
);

function createCategory(movable: AssetPageStateValue): Category {
  return {
    label: movable.title,
    value: formatCZKNotNull(movable.sum),
    categoryTypes: [createCategoryType(movable.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: [...createImmovablePropertiesItems(asset as CommonPropertyAsset)].filter(Boolean),
  };
}

function createImmovablePropertiesItems(asset: CommonPropertyAsset): Item[] {
  if (asset.type === AssetType.Vehicle) return createVehicleItems(asset as VehicleAsset);
  return [
    createItem(FieldLabel.TANGIBLE_START, formatDate(asset.start)),
    createItem(FieldLabel.PURPOSE, asset.purpose),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createVehicleItems(asset: VehicleAsset): Item[] {
  return [
    createItem(FieldLabel.TANGIBLE_START, formatDate(asset.start)),
    createItem(FieldLabel.PURPOSE, asset.purpose),
    createItem(FieldLabel.MANUFACTURER, asset.manufacturer),
    createItem(FieldLabel.MODEL, asset.model),
    createItem(FieldLabel.CATEGORY, asset.category),
    createItem(FieldLabel.REGISTRATION_PLATE, asset.registrationPlate),
    createItem(FieldLabel.VEHICLE_IDENTIFICATION_NUMBER, asset.vehicleIdentificationNumber),
    createItem(FieldLabel.VEHICLE_REGISTRATION_CERTIFICATE, asset.vehicleRegistrationCertificate),
    createItem(FieldLabel.YEAR_BEGINNING_USE, numberToString(asset.yearBeginningUse)),
    createItem(FieldLabel.MILEAGE, numberToString(asset.mileage, 'km')),
    createItem(FieldLabel.ANNUAL_MILEAGE, numberToString(asset.annualMileage, 'km')),
    createItem(FieldLabel.LEASING, getCheckboxTextValue(asset.leasing)),
    createItem(FieldLabel.FUEL_TYPE, asset.fuelType),
    createItem(FieldLabel.SEATS, numberToString(asset.seats)),
    createItem(FieldLabel.ENGINE_CAPACITY, numberToString(asset.seats, 'cm³')),
    createItem(FieldLabel.ENGINE_POWER, numberToString(asset.seats, 'kW')),
    createItem(FieldLabel.WEIGHT, numberToString(asset.seats, 'kg')),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}
