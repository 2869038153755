import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@shared/ui/button/button.module';
import {TableComponent} from '@shared/ui/table/table.component';

@NgModule({
  declarations: [TableComponent],
  exports: [TableComponent],
  imports: [ButtonModule, CommonModule],
})
export class TableModule {}
