import {FamilyMember} from '@generated/defs/FamilyMember';
import {childrenTypes} from '@shared/ui/family-member/family-member.model';
import {getPersonAge} from '@shared/utils/get-person-age.fnc';
import {Font} from 'chartjs-plugin-datalabels/types/options';
import {isArray, isNil} from 'lodash';

export function isNilOrEmpty(value: any) {
  return isNil(value) || value === '';
}

export function isFunction(value: any) {
  return typeof value === 'function';
}

export const yearsPluralMapping = {
  '=0': 'let',
  '=1': 'rok',
  '=2': 'roky',
  few: 'roky',
  many: 'let',
  other: 'let',
};

export function getMonthsPluralString(months: number, withoutNumber = false): string {
  const nrStr = withoutNumber ? '' : `${months} `;
  if (months === 1) return nrStr + 'měsíc';
  if (months > 1 && months < 5) return nrStr + 'měsíce';
  return nrStr + 'měsíců';
}

export function getYearsMonthsPluralString(months: number, withoutNumber = false): string {
  return getYearsPluralString(months / 12, withoutNumber);
}

export function getYearsPluralString(years: number, withoutNumber = false): string {
  const nrStr = withoutNumber ? '' : `${years} `;
  if (years === 1) return nrStr + 'rok';
  if (years > 1 && years < 5) return nrStr + 'roky';
  return nrStr + 'let';
}

export function getFamilyMembersChildrenBetweenAdults(members: FamilyMember[]) {
  const adults = members.filter(m => m.type === 'ADULT').sort(sortFamilyMembersAdults);
  const children = members.filter(m => m.type === 'CHILD').sort(sortFamilyMembersChildren);
  const firstAdult = adults.splice(0, 1);

  return [...firstAdult, ...children, ...adults].map(member => ({
    ...member,
    age: getPersonAge(member.birthDate),
  }));
}

function sortFamilyMembersAdults(m1: FamilyMember, m2: FamilyMember) {
  if (m1.sex === 'FEMALE' && m2.sex === 'MALE') return -1;
  if (m1.sex === 'MALE' && m2.sex === 'FEMALE') return 1;
  if (m1.sex === 'FEMALE' && m1.familyHead && m2.sex === 'FEMALE' && !m2.familyHead) return -1;
  if (m1.sex === 'FEMALE' && !m1.familyHead && m2.sex === 'FEMALE' && m2.familyHead) return 1;
  if (!m1.familyHead && m2.familyHead) return 1;
  if (m1.familyHead && !m2.familyHead) return -1;
  if (m1.age > m2.age) return -1;
  if (m1.age < m2.age) return 1;
  return 0;
}

function sortFamilyMembersChildren(m1: FamilyMember, m2: FamilyMember) {
  if (m1.sex === 'FEMALE' && m2.sex === 'MALE' && m1.age < childrenTypes.middleChildhood.to)
    return -1;
  if (m1.sex === 'MALE' && m2.sex === 'FEMALE' && m2.age < childrenTypes.middleChildhood.to)
    return 1;
  if (
    m1.sex === 'MALE' &&
    m2.sex === 'FEMALE' &&
    m1.age >= childrenTypes.teenager.from &&
    m2.age >= childrenTypes.teenager.from
  )
    return -1;
  if (
    m1.sex === 'FEMALE' &&
    m2.sex === 'MALE' &&
    m1.age >= childrenTypes.teenager.from &&
    m2.age >= childrenTypes.teenager.from
  )
    return 1;
  if (
    m1.sex === 'FEMALE' &&
    m2.sex === 'MALE' &&
    m1.age >= childrenTypes.teenager.from &&
    m2.age < childrenTypes.middleChildhood.to
  )
    return -1;
  if (
    m1.sex === 'MALE' &&
    m2.sex === 'FEMALE' &&
    m2.age >= childrenTypes.teenager.from &&
    m1.age < childrenTypes.middleChildhood.to
  )
    return 1;
  if (m1.age < m2.age && m1.sex === 'FEMALE' && m2.sex === 'FEMALE') return -1;
  if (m1.age > m2.age && m1.sex === 'FEMALE' && m2.sex === 'FEMALE') return 1;
  if (m1.age > m2.age && m1.sex === 'MALE' && m2.sex === 'MALE') return -1;
  if (m1.age < m2.age && m1.sex === 'MALE' && m2.sex === 'MALE') return 1;
  return 0;
}

export function getFamilyMembersAdultsThenChildren(members: FamilyMember[]) {
  return [...members].sort(sortFamilyMembersAdultsThenChildren);
}

function sortFamilyMembersAdultsThenChildren(m1: FamilyMember, m2: FamilyMember) {
  if (m1.familyHead && !m2.familyHead) return -1;
  if (!m1.familyHead && m2.familyHead) return 1;
  if (m1.type === 'ADULT' && m2.type === 'CHILD') return -1;
  if (m1.type === 'CHILD' && m2.type === 'ADULT') return 1;
  if (m1.age > m2.age) return -1;
  if (m1.age < m2.age) return 1;
  return 0;
}

export function removeNullOrUndefined<T>(obj: T[] | T) {
  const remove = (o: any) =>
    Object.entries(o).forEach(([key, val]) => {
      if (val && typeof val === 'object') {
        remove(val);
        if (Object.keys(val).length === 0) delete o[key];
      } else if (isNil(val)) delete o[key];
    });

  Array.isArray(obj) ? obj.forEach(remove) : remove(obj);
}

export function czechCommaJoin(array: (string | number)[]): string {
  if (!isArray(array) || array.length === 0) return '';
  if (array.length === 1) return String(array[0]);
  return array.slice(0, array.length - 1).join(', ') + ' a ' + array[array.length - 1];
}

export function responsiveDataLabelFont(
  sizeDesktop: number,
  sizeTablet: number,
  weight: 'normal' | 'bold',
  lineHeight: string | number,
): () => Font {
  const xlBreakpoint = 1280;
  return () => ({
    size: document.body.clientWidth < xlBreakpoint ? sizeTablet : sizeDesktop,
    weight,
    lineHeight,
  });
}
