<div
  dropdown
  #dropdown="bs-dropdown"
  container="body"
  [isDisabled]="isDisabled"
  class="kpt-dropdown-bootstrap__wrapper"
>
  <span dropdownToggle>
    <ng-template
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{opened: dropdown.isOpen}"
    >
    </ng-template>
  </span>

  <div *dropdownMenu class="kpt-dropdown-bootstrap__dropdown-menu" [style.min-width]="menuWidth">
    <ng-template
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="contentTemplateContext"
    >
    </ng-template>
  </div>
</div>
