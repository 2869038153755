import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'precision',
})
export class PrecisionPipe implements PipeTransform {
  transform(value: number, precision = 0): any {
    const divisor = Math.pow(10, precision);
    return value / divisor;
  }
}
