/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {createFeatureSelector} from '@ngrx/store';

import {HttpErrorResponse} from '@angular/common/http';
import * as __model from '../../../../model';
import * as actions from './actions';

export interface CreateAssignmentState {
  data: __model.CreateAssignment | null;
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialCreateAssignmentState: CreateAssignmentState = {
  data: null,
  loading: false,
  error: null,
};

export const selectorName = 'Integrations_CreateAssignment';
export const getCreateAssignmentStateSelector = createFeatureSelector<CreateAssignmentState>(selectorName);

export function CreateAssignmentReducer(
  state: CreateAssignmentState = initialCreateAssignmentState,
  action: actions.CreateAssignmentAction): CreateAssignmentState {
  switch (action.type) {
    case actions.Actions.START: return {...state, loading: true, error: null};
    case actions.Actions.SUCCESS: return {...state, data: action.payload, loading: false};
    case actions.Actions.ERROR: return {...state, error: action.payload, loading: false};
    default: return state;
  }
}
