import {Injectable} from '@angular/core';
import {col, column, row} from '@lib/utils';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FinancialProductAssetType} from '@shared/analysis/asset-types/common/financial-product-asset-type';
import {FieldLabel} from '@shared/analysis/field-label';
import {AbstractAssetsDefinitions} from '@shared/analysis/forms/abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from '@shared/analysis/forms/definitions.models';
import {createProductTab} from '@shared/analysis/forms/forms.helpers';
import {interestRates} from '@shared/analysis/interest-rate.definitions';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {
  InvestmentLifeInsuranceAsset,
  LifeInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {getAssetValidators} from '@shared/analysis/validations.helpers';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {
  createFulfillmentField,
  createNamedGroup,
  createTabs,
  getBasicField,
} from '@shared/ui/formly/formly.utils';

@Injectable()
export class InvestmentLifeInsuranceAssetType extends BaseAssetType {
  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private financialProductAssetType: FinancialProductAssetType,
  ) {
    super();
  }

  async create(
    opts: Pick<InvestmentLifeInsuranceAsset, 'proposal'>,
  ): Promise<Partial<InvestmentLifeInsuranceAsset>> {
    const asset: Partial<InvestmentLifeInsuranceAsset> = {
      assetUuid: this.createId(),
      ...this.financialProductAssetType.getLifeInsuranceWithValue(),
      proposal: opts.proposal,
      type: AssetType.InvestmentLifeInsurance,
    };

    // There are three ways how RiskLifeInsuranceAsset can be created:
    //
    // 1. Proposal is created
    // 2. Existing contract is created
    // 3. Existing contract is synchronized from Core
    //
    // If 1, then prefill the contract limits as specified in the family provision asset
    if (opts.proposal) {
      await this.financialProductAssetType.copyRisksFromFamilyProvision(
        asset as LifeInsuranceAsset,
      );
    }

    return asset;
  }

  getAssetDefinition(): AssetDefinition {
    const type = AssetType.InvestmentLifeInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        this.abstractAssets.getCommonFinancialProductGroup('presentValue'),
        row([
          col(this.abstractAssets.lifeInsuranceAsset().futureValue),
          col(this.abstractAssets.lifeInsuranceAsset().futureValueDate),
        ]),
        this.abstractAssets.getRelatedAndStakeholderField(type),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        createNamedGroup(
          'Strategie',
          this.abstractAssets.yearlyRate(interestRates[type], {
            subtext:
              'Uvedená sazba se vztahuje k technické úrokové míře pro garantovaný ' +
              'fond/vnitřní fond pojistitele, která byla/je garantovaná.',
          }),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          null,
          row(
            [
              column(
                [
                  getBasicField(
                    FormlyTypes.Currency,
                    'employerContribution',
                    FieldLabel.EMPLOYER_CONTRIBUTION,
                  ),
                  getBasicField(FormlyTypes.Number, 'employerId', FieldLabel.EMPLOYER_ID),
                ],
                [6, 6],
              ),
              column(
                [
                  getBasicField(FormlyTypes.Text, 'employerName', FieldLabel.EMPLOYER_NAME),
                  getBasicField(FormlyTypes.Text, 'employerContact', FieldLabel.EMPLOYER_CONTACT),
                ],
                [6, 6],
              ),
            ],
            'd-block mb-5',
          ),
        ),
        createNamedGroup('Osoby', this.abstractAssets.lifeInsuranceAsset().insuredPersons),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }
}
