import {ClientGuard} from '../../services/client-guard';
import {SecuredLayoutComponent} from './secured-layout.component';
import {SecuredPage} from './secured-layout.config';

export const routes = [
  {
    path: '',
    component: SecuredLayoutComponent,
    children: [
      {
        path: SecuredPage.AboutKapitol,
        loadChildren: () =>
          import('../../modules/about-kapitol/about-kapitol.module').then(
            m => m.AboutKapitolModule,
          ),
      },
      {
        path: SecuredPage.AboutConsultant,
        loadChildren: () =>
          import('../../modules/about-consultant/about-consultant-page.module').then(
            m => m.AboutConsultantPageModule,
          ),
      },
      {
        path: SecuredPage.Client,
        canActivate: [ClientGuard],
        loadChildren: () =>
          import('../../modules/client/client-container.module').then(m => m.ClientContainerModule),
      },
      {
        path: SecuredPage.Consultant,
        loadChildren: () =>
          import('../../modules/consultant/consultant-page.module').then(
            m => m.ConsultantPageModule,
          ),
      },
      {
        path: SecuredPage.LifeInTime,
        loadChildren: () =>
          import('../../modules/life-in-time/life-in-time.module').then(m => m.LifeInTimeModule),
      },
      {
        path: SecuredPage.AssetsAndLiabilities,
        loadChildren: () =>
          import('../../modules/assets-and-liabilities/assets-and-liabilities-page.module').then(
            m => m.AssetsAndLiabilitiesPageModule,
          ),
      },
      {
        path: '404',
        loadChildren: () =>
          import('../../modules/not-found/not-found.module').then(m => m.NotFoundModule),
        data: {title: '404'},
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
];
