import {PersonRisk} from '@shared/analysis/models/life-insurance-risks';
import {RiskId} from 'src/store/models/risk.models';

export interface CreditProductInsuranceRisk {
  key: string;
  label: string;
  class?: string;
  type?: 'protection' | 'package';
  /**
   * @deprecated Use `riskKey`
   */
  riskId?: RiskId;
  riskKey?: PersonRisk;
  group?: boolean;
}

export const paymentProtectionInsuranceKey = 'paymentProtectionInsurance';
export const creditPackageKey = 'creditPackageKey';

export const creditProductInsuranceRisks: CreditProductInsuranceRisk[] = [
  {
    key: paymentProtectionInsuranceKey,
    label: 'Pojištění schopnosti splácet',
    type: 'protection',
    group: true,
  },
  {
    key: 'protectionRiskDeathDebt',
    label: 'Zajištění smrti',
    class: 'risk',
    type: 'protection',
    riskId: RiskId.DeathDebtRepayment,
    riskKey: PersonRisk.DeathDebtRepayment,
  },
  {
    key: 'protectionRiskInvalidity',
    label: 'Zajištění invalidity',
    class: 'risk',
    type: 'protection',
    riskId: RiskId.Invalidity,
    riskKey: PersonRisk.Invalidity,
  },
  {
    key: 'protectionRiskIncapacity',
    label: 'Zajištění pracovní neschopnosti',
    class: 'risk',
    type: 'protection',
    riskId: RiskId.Incapacity,
    riskKey: PersonRisk.Incapacity,
  },
  {
    key: 'protectionRiskLossOfWork',
    label: 'Zajištění ztráty zaměstnání',
    class: 'risk',
    type: 'protection',
  },
  {
    key: creditPackageKey,
    label: 'Úvěrový balíček',
    type: 'package',
    group: true,
  },
  {
    key: 'packageRiskDeathDebt',
    label: 'Zajištění smrti',
    class: 'risk',
    type: 'package',
    riskId: RiskId.DeathDebtRepayment,
    riskKey: PersonRisk.DeathDebtRepayment,
  },
  {
    key: 'packageRiskInvalidity',
    label: 'Zajištění invalidity',
    class: 'risk',
    type: 'package',
    riskId: RiskId.Invalidity,
    riskKey: PersonRisk.Invalidity,
  },
  {
    key: 'packageRiskSeriousDisease',
    label: 'Zajištění vážných onemocnění',
    class: 'risk',
    type: 'package',
    riskId: RiskId.SeriousDisease,
  },
  {
    key: 'packageRiskIncapacity',
    label: 'Zajištění pracovní neschopnosti',
    class: 'risk',
    type: 'package',
    riskId: RiskId.Incapacity,
    riskKey: PersonRisk.Incapacity,
  },
];
