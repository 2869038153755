<kpt-accordion-switch
  *ngIf="!person.child"
  label="Chci zahrnout další příjmy k hlavnímu příjmu"
  class="switch"
  [open]="person.useOtherIncome"
  (openChange)="toggleUseOtherIncome()"
>
  <ng-container *ngIf="otherIncomeAssets$ | async as otherIncomeAssets">
    <ng-container *ngIf="otherIncomeAssets.length === 0">
      Žádné další příjmy pro osobu {{ person.name }} nejsou zadány ve finanční analýze.
    </ng-container>
    <kpt-asset-list-item
      *ngFor="let asset of otherIncomeAssets"
      [data]="asset"
      [valueDisabled]="true"
      [startTemplate]="checkbox"
      (clickItem)="toggleAsset(asset.assetUuid)"
      class="asset-item"
    >
      <ng-template #checkbox>
        <kpt-checkbox
          class="checkbox"
          [checked]="isSelected(asset.assetUuid)"
          (valueChange)="selectAsset(asset.assetUuid, $event)"
        >
        </kpt-checkbox>
      </ng-template>
    </kpt-asset-list-item>
  </ng-container>
</kpt-accordion-switch>
