import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable()
export class FormlyFormHandlerService {
  private submitted$ = new ReplaySubject<boolean>(1);

  setSubmitted() {
    this.submitted$.next(true);
  }

  getSubmitted(): Observable<boolean> {
    return this.submitted$.asObservable();
  }

  markAsTouched(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach((k: string) => {
      form.get(k).markAsTouched();
    });
  }
}
