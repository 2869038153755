<kpt-modal
  [size]="modalService.activeModal?.size"
  [isModalOpen]="isModalOpened"
  [hideClose]="
    modalService.activeModal?.hideClose || (modalService.activeModal?.hideClose$ | async)
  "
  (close)="closeModal()"
>
  <ng-template kptDynamicModal></ng-template>
</kpt-modal>
