import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AdvisorUiSettingsState} from './advisor-ui-settings.state';

export const storeFeatureName = 'advisorUiSettings';

export const selectAdvisorUiSettingsState =
  createFeatureSelector<AdvisorUiSettingsState>(storeFeatureName);

export const selectIntroUiSettings = createSelector(
  selectAdvisorUiSettingsState,
  state => state.intro,
);
