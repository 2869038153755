import {createSelector} from '@ngrx/store';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import {introTexts} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  getChartData,
  getObjectiveName,
  getTable,
  selectObjectiveAssetsByObjectiveType,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  FinancialResourcesObjective,
  ObjectiveInput,
} from 'src/app/modules/financial-plan/store/objectives.models';

export const selectFinancialResourcesObjectives = (objectiveType: ObjectiveType) =>
  createSelector(
    selectCurrentAssets,
    selectObjectiveAdvisorProposedAssets,
    selectObjectiveClientProposedAssets,
    selectObjectiveAssetsByObjectiveType(objectiveType),
    (
      currentAssets,
      advisorProposedAssets,
      clientProposedAssets,
      objectiveAssets,
    ): FinancialResourcesObjective[] => {
      return objectiveAssets.map((objectiveAsset): FinancialResourcesObjective => {
        const objectiveValue = objectiveAsset.value;
        const input: ObjectiveInput = {
          requiredValue: objectiveValue,
          finalDate: objectiveAsset.start,
        };
        const currentTable = getTable(
          Situation.Current,
          currentAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const advisorProposedTable = getTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const clientProposedTable = getTable(
          Situation.ClientProposed,
          clientProposedAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const chart = getChartData(currentTable, advisorProposedTable, objectiveValue);

        return {
          type: objectiveType,
          objectiveAsset,
          objectiveValue,
          name: getObjectiveName(objectiveAsset),
          introText: introTexts[objectiveType],
          input,
          chart,
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
          advisorProposedBenefit: advisorProposedTable.sum.finalValue - currentTable.sum.finalValue,
          clientProposedBenefit: clientProposedTable.sum.finalValue - currentTable.sum.finalValue,
        };
      });
    },
  );
