import {ConsumerCreditDemand} from '@generated/defs/ConsumerCreditDemand';
import {ConsumerCreditOutput} from 'src/store/models/consumer-credit.models';
import {ConsumerActions, ConsumerActionTypes} from '../actions/consumer.actions';

export interface State {
  input: ConsumerCreditDemand;
  output: ConsumerCreditOutput;
}

export const initialState: State = {
  input: {
    interest: 5.0,
    loan: null,
    loanTerm: 36,
  },
  output: {
    monthlyRepayment: 0,
  },
};

export function reducer(state = initialState, action: ConsumerActions): State {
  switch (action.type) {
    case ConsumerActionTypes.UpdateInput:
      return {...state, input: {...state.input, ...action.payload}};

    case ConsumerActionTypes.UpdateInputOutput:
      const input = {...state.input, ...action.payload.input};
      const output = {...state.output, ...action.payload.output};
      return {...state, input, output};

    default:
      return state;
  }
}
