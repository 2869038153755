<div *ngFor="let field of field.fieldGroup; let i = index" class="row">
  <formly-field class="col" [field]="field"></formly-field>
  <div *ngIf="canAddRemove" [ngClass]="removeButtonClass">
    <kpt-button
      type="primary"
      [hasOutline]="true"
      size="sm"
      (click)="remove(i)"
      label="smazat"
    ></kpt-button>
  </div>
</div>

<kpt-button
  *ngIf="canAddRemove"
  type="primary"
  [hasOutline]="true"
  size="sm"
  (click)="add()"
  [label]="to.addButtonLabel ? to.addButtonLabel : 'Přidat'"
></kpt-button>
