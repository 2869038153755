<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">
        {{ title }}
      </h3>
    </div>
  </div>
</div>
<form [formGroup]="alternativesForm" class="modal-body">
  <div class="alternatives-container">
    <kpt-toggle formControlName="alternative" class="alternative-toggle" [options]="options">
    </kpt-toggle>

    <kpt-select
      class="recommended-forms"
      [options]="recommendedForms"
      [formControl]="alternativesForm.get('alternativeFor')"
      (click)="alternativesForm.get('alternative').setValue('alternative')"
    ></kpt-select>
  </div>

  <div class="d-flex justify-content-center">
    <kpt-button type="primary" (action)="add()">Přidat</kpt-button>
  </div>
</form>
