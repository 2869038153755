import {Injectable} from '@angular/core';
import {LifeinsuranceService} from '@generated/controllers/Lifeinsurance';
import {SharedService} from '@generated/controllers/Shared';
import {PriorityQuestionnaireSerializerAnswer} from '@generated/defs/PriorityQuestionnaireSerializerAnswer';
import {select, Store} from '@ngrx/store';
import {LifeInsuranceSummaryService} from '@shared/investment-survey-old/component/summary/life-insurance/life-insurance-summary.service';
import {InvestmentSurveyUtilsService} from '@shared/investment-survey-old/investment-survey-utils.service';
import {QuestionsOrigin} from '@shared/investment-survey-old/models/question';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {selectAllSurveyAnswers} from '@shared/investment-survey-old/store';
import {isEmpty} from 'lodash';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {State} from 'src/store';
import {selectInvestmentSurveyHiddenState} from '../store/life-insurance.selectors';

@Injectable()
export class DataValidationService {
  private familyId$ = new BehaviorSubject<string>(null);

  private investmentQuestionnaires$ = this.familyId$.pipe(
    filter(familyId => Boolean(familyId)),
    switchMap(familyId =>
      this.sharedService.getNewInvestmentQuestionnaire({family_uuid: familyId}),
    ),
    shareReplay(1),
  );

  private priorityQuestionnaires$: Observable<PriorityQuestionnaireSerializerAnswer[]>;

  constructor(
    private lifeInsuranceService: LifeinsuranceService,
    private lifeInsuranceSummaryService: LifeInsuranceSummaryService,
    private investmentSurveyUtilsService: InvestmentSurveyUtilsService,
    private sharedService: SharedService,
    private questions: Questions,
    private store: Store<State>,
  ) {}

  loadPriorityQuestionnaire(familyId: string, clientIds: string[]) {
    this.priorityQuestionnaires$ = this.lifeInsuranceService
      .getPriorityQuestionnaire({
        family_uuid: familyId,
        data: {clients: clientIds.map(clientId => ({sugarUuid: clientId}))},
      })
      .pipe(shareReplay(1));
  }

  setFamilyId(familyId: string) {
    this.familyId$.next(familyId);
  }

  /** Tells whether investment life insurance is suitable for a client based on the investment survey. */
  investmentEligibleFromSurvey(clientId: string): Observable<boolean> {
    return this.investmentQuestionnaires$.pipe(
      map(
        questionnaires =>
          questionnaires.find(
            answers => answers.sugarUuid === clientId,
          ) as unknown as SurveyAnswers,
      ),
      map((answers: SurveyAnswers) => {
        const surveyResults = this.lifeInsuranceSummaryService.calculateResults(answers);
        return surveyResults.eligibility;
      }),
      catchError(() => of(false)),
    );
  }

  isPriorityQuestionnaireUnfinished$(clientId: string): Observable<boolean> {
    return this.priorityQuestionnaires$.pipe(
      map(questionnaires => {
        const questionnaire = questionnaires.find(q => q.clientUuid === clientId);
        return !questionnaire || isEmpty(questionnaire.scoredProducts);
      }),
      take(1),
    );
  }

  isInvestmentQuestionnaireUnfinished$(clientId: string): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(selectAllSurveyAnswers)),
      this.store.pipe(select(selectInvestmentSurveyHiddenState)),
    ]).pipe(
      map(
        ([surveyAnswers, hiddenState]) =>
          !this.validateInvestmentQuestionnaire(surveyAnswers, clientId) && !hiddenState[clientId],
      ),
    );
  }

  private validateInvestmentQuestionnaire(surveyAnswers: SurveyAnswers[], sugarUuid: string) {
    return this.investmentSurveyUtilsService.answersValid(
      surveyAnswers,
      sugarUuid,
      this.questions.allQuestionsCategories(QuestionsOrigin.LIFE_INSURANCE),
    );
  }
}
