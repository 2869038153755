import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: any,
    format: string,
    withLabel = false,
    dateTimeSeparator = ' ',
    formatDiffYear = format,
  ): any {
    moment.locale('cs');

    if (!value || !moment(value).isValid()) {
      return '';
    }

    function formatter(this: moment.Moment, now: moment.Moment) {
      return this.isSame(now, 'year') ? format : formatDiffYear;
    }

    const valueMoment = moment(value);
    return withLabel
      ? moment(value).calendar(null, {
          sameDay: `[Dnes]${dateTimeSeparator}H:mm`,
          nextDay: `[Zítra]${dateTimeSeparator}H:mm`,
          lastDay: `[Včera]${dateTimeSeparator}H:mm`,
          nextWeek: formatter,
          lastWeek: formatter,
          sameElse: formatter,
        })
      : valueMoment.format(formatter.call(valueMoment, moment()));
  }
}
