<!-- TODO resolve zero, empty string and so on -->
<ng-container *ngIf="coreValue$ | async as coreValue; else justField">
  <kpt-recommended-value
    [visible]="!isEqualToCurrentValue(coreValue)"
    (onClick)="setValue(coreValue)"
    [linkText]="'Nastavit ' + formatValue(coreValue) + ' podle evidence KAPITOLu.'"
  >
    <ng-container #fieldComponent></ng-container>
  </kpt-recommended-value>
</ng-container>

<ng-template #justField>
  <ng-container #fieldComponent></ng-container>
</ng-template>
