import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonComponent} from '@shared/ui/button/button.component';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
