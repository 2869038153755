import {createSelector} from '@ngrx/store';
import {Asset} from '@shared/analysis/models/asset';
import {selectCurrentAssets} from '@shared/analysis/store';
import {
  calculateDescription,
  calculateHelp,
  calculateRisk,
} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {
  LifeInsurancePerson,
  LifeInsuranceRisk,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectOrderedPersons,
  selectRisksOfMyChoice,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {getRiskDefinition, riskIdsByProvision} from 'src/app/services/risk-definitions';
import {Provision, RiskDefinition, RiskId} from 'src/store/models/risk.models';

export const selectInsuranceSelectionsVM = createSelector(
  selectOrderedPersons,
  selectRisksOfMyChoice,
  selectCurrentAssets,
  (
    persons: LifeInsurancePerson[],
    risks: LifeInsuranceRisk[],
    assets: Asset[],
  ): PersonalInsuranceSelectionVM[] =>
    persons.map(person => ({
      person,
      individualSelection: person.individualSelection,
      leftProvision: {
        value: person.income,
        provision: person.child ? Provision.Standard : Provision.Income,
        risks: getRisksVM(
          person,
          risks,
          person.child ? Provision.Standard : Provision.Income,
          persons,
          assets,
        ),
      },
      rightProvision: {
        value: person.expenses,
        provision: person.child ? Provision.Minimal : Provision.Expenses,
        risks: getRisksVM(
          person,
          risks,
          person.child ? Provision.Minimal : Provision.Expenses,
          persons,
          assets,
        ),
      },
    })),
);

function getRisksVM(
  person: LifeInsurancePerson,
  risks: LifeInsuranceRisk[],
  provision: Provision,
  persons: LifeInsurancePerson[],
  assets: Asset[],
): RiskVM[] {
  return riskIdsByProvision[provision]
    .filter(riskId => {
      return !(!person.individualSelection && riskId === RiskId.InvalidityDebtRepayment);
    })
    .map(riskId => {
      const riskDef = getRiskDefinition(riskId, provision);
      if (!riskDef) return null;

      return {
        riskDef,
        calculatedValue: calculateRisk(riskDef, provision, person, persons, assets),
        calculatedHelp: calculateHelp(riskDef, provision, person, persons, assets),
        calculatedDescription: calculateDescription(riskDef, provision, person, persons, assets),

        selected: !!risks.find(
          risk =>
            risk.personId === person.id && risk.riskId === riskId && risk.provision === provision,
        ),
      };
    })
    .filter(risk => !!risk);
}

export interface PersonalInsuranceSelectionVM {
  person: LifeInsurancePerson;
  individualSelection: boolean;
  leftProvision: ProvisionVM;
  rightProvision: ProvisionVM;
}

export interface ProvisionVM {
  value: number;
  provision: Provision;
  risks: RiskVM[];
}

export interface RiskVM {
  riskDef: RiskDefinition;
  calculatedValue: number;
  calculatedHelp: string;
  calculatedDescription: string;
  selected: boolean;
}
