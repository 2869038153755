import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MaskOptions} from '@lib/utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {InputComponentType} from '@shared/ui/forms/input/input.component';
import {SliderOptions} from '@shared/ui/slider/slider.model';

@UntilDestroy()
@Component({
  selector: 'kpt-slider-with-input',
  templateUrl: './slider-with-input.component.html',
  styleUrls: ['./slider-with-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderWithInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() sliderName: string;
  @Input() sliderOptions: SliderOptions = {};
  @Input() label?: string;
  @Input() appendText?: string;
  @Input() smallInput = false;
  @Input() inputFormControl?: UntypedFormControl;
  @Input() type: InputComponentType = 'number';
  @Input() mask: MaskOptions;
  /** Update value of input when slider value changes and vice versa */
  @Input() sync = true;

  constructor() {}

  ngOnInit() {
    if (this.sync) {
      this.initControlListeners();
    }
  }

  ngAfterViewInit() {
    // fix for KAPP-668
    if (this.sliderOptions && this.sliderOptions.showTicks)
      window.dispatchEvent(new Event('resize'));
  }

  ngOnDestroy() {}

  private initControlListeners() {
    const sliderControl = this.parentForm && this.parentForm.get(this.sliderName);
    if (sliderControl) {
      this.initControlListener(sliderControl);
    }
    if (this.inputFormControl) {
      this.initControlListener(this.inputFormControl);
    }
  }

  private initControlListener(control: AbstractControl) {
    control.valueChanges.pipe(untilDestroyed(this)).subscribe(e => {
      control.setValue(e, {emitEvent: false});
    });
  }
}
