import {Component, OnInit} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FieldArrayType} from '@ngx-formly/core';
import {InsuredPerson} from '@shared/analysis/models/insurance-products';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-asset-life-insurance-risks-formly',
  templateUrl: './asset-life-insurance-risks-formly.component.html',
  styleUrls: ['./asset-life-insurance-risks-formly.component.scss'],
})
export class AssetLifeInsuranceRisksFormlyComponent extends FieldArrayType implements OnInit {
  tabNames: Record<string, string> = {};
  showTabs = false;

  constructor(private store: Store<State>) {
    super();
  }

  async ngOnInit() {
    // Avoid ExpressionChangedAfterItHasBeenCheckedError https://github.com/angular/angular/issues/23657#issuecomment-899646526
    await Promise.resolve();

    this.store.pipe(selectFamilyMembers(), untilDestroyed(this)).subscribe(members => {
      const models = members.map(m => this.prepareModel(m));
      const modelsCount = this.field.model.length;
      for (let i = 0; i < modelsCount; i++) this.remove(0);
      models.forEach(model => this.add(null, model));
      this.showTabs = true;
    });
  }

  private prepareModel(member: FamilyMember): InsuredPerson {
    this.tabNames[member.sugarUuid] = member.firstName;
    return (
      this.field.model.find((m: InsuredPerson) => m.insuredPersonUuid === member.sugarUuid) ?? {
        insuredPersonUuid: member.sugarUuid,
        incapacity: null,
        dailyCompensation: null,
        physicalDamage: null,
        hospitalization: null,
        seriousDisease: null,
        permanentDisability: null,
        invalidity: null,
        invalidityDebtRepayment: null,
        deathDebtRepayment: null,
        deathThriceAnnualIncome: null,
        deathChildrenEnsurement: null,
        otherRisks: [],
      }
    );
  }
}
