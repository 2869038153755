<div
  class="wrapper"
  [ngClass]="spacingClass"
  [ngStyle]="{
    'flex-wrap': isWrapping ? 'wrap' : 'nowrap',
    'flex-direction': flexDirection,
    'align-items': alignItems,
    'justify-content': justifyContent,
    overflow: overflow
  }"
>
  <ng-content></ng-content>
</div>
