import {FamilyMember} from '@generated/defs/FamilyMember';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {getBirthDateWarning, getSexAndBirthDate} from '@shared/utils';
import {combineLatest, Observable} from 'rxjs';
import {map, pairwise, startWith} from 'rxjs/operators';

export function updateSexAndBirthDate(field: FormlyFieldConfig, value: string) {
  const sexAndBirthDate = getSexAndBirthDate(value);
  if (sexAndBirthDate) {
    field.form.patchValue(sexAndBirthDate);
  }
}

export function birthDateWarning(
  _model: FamilyMember,
  _formState: any,
  field: FormlyFieldConfig,
): Observable<string> {
  const originalBirthNumber: string = field.form.get('birthNumber').value;
  return combineLatest([
    field.form.get('birthNumber').valueChanges.pipe(startWith(originalBirthNumber), pairwise()),
    field.form.get('birthDate').valueChanges,
  ]).pipe(
    map(([[oldBirthNumber, birthNumber], birthDate]) =>
      getBirthDateWarning(oldBirthNumber, birthNumber, birthDate),
    ),
  );
}
