import {Component, Input} from '@angular/core';
import {AccordionGroupComponent} from './accordion-group.component';

@Component({
  selector: 'kpt-accordion-container',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionContainerComponent {
  @Input() heading: string;
  @Input() subheading: string;
  @Input() customClass: string;
  /** if `true` expanding one item will close all others */
  @Input() closeOthers: boolean;

  protected groups: AccordionGroupComponent[] = [];

  closeOtherPanels(openGroup: AccordionGroupComponent): void {
    if (!this.closeOthers) {
      return;
    }

    this.groups.forEach((group: AccordionGroupComponent) => {
      if (group !== openGroup) {
        group.isOpen = false;
      }
    });
  }

  addGroup(group: AccordionGroupComponent): void {
    this.groups.push(group);
  }

  removeGroup(group: AccordionGroupComponent): void {
    const index = this.groups.indexOf(group);
    if (index !== -1) {
      this.groups.splice(index, 1);
    }
  }
}
