import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {SharedService} from '@generated/controllers/Shared';
import {PortalClient} from '@generated/defs/PortalClient';
import {FamilyMemberShort} from '@generated/model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ConfirmDialogService} from '@shared/building-blocks/confirm-dialog/confirm-dialog.service';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {IOption} from '@shared/ui';
import {finalize, map, mergeMap, take} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'kpt-families-modal',
  templateUrl: './families-modal.component.html',
  styleUrls: ['./families-modal.component.scss'],
})
export class FamiliesModalComponent implements OnInit {
  addingFamilyInProgress = false;
  showAddFamily = false;
  data: PortalClient;
  title: string;
  namesToEdit: Record<string, boolean> = {};

  familiesForm: UntypedFormGroup = new UntypedFormGroup({
    families: new UntypedFormControl(),
    newFamily: new UntypedFormControl(),
  });

  families: (IOption & {members?: FamilyMemberShort[]})[] = [];

  constructor(
    private integrationService: IntegrationsService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: ModalService,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  ngOnInit(): void {
    this.families = this.data.families.map(({familyUuid, name, members}) => ({
      key: familyUuid,
      label: name,
      members,
    }));
    this.familiesForm.get('families').setValue(this.data.families[0].familyUuid);
    this.data.families.forEach(f => {
      this.familiesForm.addControl(f.familyUuid, new UntypedFormControl());
      this.namesToEdit[f.familyUuid] = false;
    });
  }

  createFamily() {
    this.addingFamilyInProgress = true;
    const familyName = this.familiesForm.get('newFamily').value;

    this.integrationService
      .createFamily({data: {name: familyName}, sugar_uuid: this.data.sugarUuid})
      .pipe(
        mergeMap(({familyUuid}) =>
          this.integrationService
            .getClient({sugar_uuid: this.data.sugarUuid})
            .pipe(map(({families}) => families.find(family => family.familyUuid === familyUuid))),
        ),
        finalize(() => {
          this.addingFamilyInProgress = false;
        }),
        untilDestroyed(this),
      )
      .subscribe(({familyUuid, name, members}) => {
        this.families = [...this.families, {key: familyUuid, label: name, members}];
        this.familiesForm.get('families').setValue(familyUuid);
        this.familiesForm.get('newFamily').reset();
        this.familiesForm.addControl(familyUuid, new UntypedFormControl(name));
        this.namesToEdit[familyUuid] = false;
        this.showAddFamily = false;
      });
  }

  navigateBack() {
    this.closeModal();
  }

  navigateToClientPage() {
    this.closeModal();
    this.sharedService
      .rememberClient({data: {sugarUuid: this.data.sugarUuid}})
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate([`secured/client/${this.familiesForm.get('families').value}`]);
      });
    return;
  }

  editFamilyName(familyUuid: string) {
    const family = this.families.find(f => f.key === familyUuid);
    this.familiesForm.get(family.key as string).setValue(family.label);
    this.namesToEdit[familyUuid] = true;
  }

  saveFamilyName(familyUuid: string) {
    const familyName = this.familiesForm.get(familyUuid).value;
    this.integrationService
      .renameFamily({data: {name: familyName}, family_uuid: familyUuid})
      .subscribe(() => {
        this.namesToEdit[familyUuid] = false;
        const family = this.families.find(f => f.key === familyUuid);
        family.label = familyName;
      });
  }

  async comfirmDeleteFamily(familyUuid: string) {
    if (
      await this.confirmDialogService.confirm(
        'Opravdu si přejete smazat tuto rodinu?<br/>' +
          'Budou smazána všechna data z analýzy a tato změna je nevratná.',
      )
    ) {
      this.deleteFamily(familyUuid);
    }
  }

  deleteFamily(familyUuid: string) {
    this.integrationService
      .deleteFamily({family_uuid: familyUuid})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const family = this.families.find(f => f.key === familyUuid);
        const index = this.families.indexOf(family);
        this.families.splice(index, 1);
        delete this.namesToEdit[familyUuid];
        this.familiesForm.removeControl(familyUuid);
        if (this.familiesForm.get('families').value === familyUuid) {
          this.familiesForm.get('families').setValue('');
        }
      });
  }

  cancelFamilyNameEditing(familyUuid: string) {
    this.namesToEdit[familyUuid] = false;
  }

  private closeModal() {
    this.modalService.closeModal();
  }
}
