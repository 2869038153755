import {Injectable} from '@angular/core';
import {DocumentTypeLifeInsuranceFileEnum} from '@generated/model';
import {LoginService} from '@lib/services';
import {StoreFile, UIFile} from '@shared/models/file.models';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';

interface UploadingFileData {
  originalName: string;
  created: string;
  documentType?: string;
}

export interface LifeInsuranceInitialUploadData {
  documentType?: DocumentTypeLifeInsuranceFileEnum | string;
  firstName: string;
  lastName: string;
  birthNumber: string;
}

@Injectable()
export class UploaderService {
  private uploader: FileUploader;
  private defDocumentType: string;
  private uploadingFilesData: UploadingFileData[] = [];
  private filesAddedSubject = new Subject<UIFile[]>();
  private queuedFilesSubject = new BehaviorSubject<UIFile[]>([]);
  private fileUploadedSubject = new Subject<StoreFile>();
  private fileAddingFailedSubject = new Subject<FileLikeObject>();

  constructor(private loginService: LoginService) {}

  get filesAdded$() {
    return this.filesAddedSubject.asObservable();
  }

  get queuedFiles$() {
    return this.queuedFilesSubject.asObservable();
  }

  get fileUploaded$() {
    return this.fileUploadedSubject.asObservable();
  }

  get fileAddingFailed$() {
    return this.fileAddingFailedSubject.asObservable();
  }

  initUploader(options: FileUploaderOptions, initialData: LifeInsuranceInitialUploadData) {
    this.uploader = new FileUploader(options);

    // FileUploader has problem with async onBeforeUploadItem where token was attached to the request (by uploader.setOptions).
    // Therefore, we had to override this particular function to attach the token. (viz commit)
    this.uploader.uploadItem = async value => {
      this.loginService.accessToken.pipe(take(1)).subscribe(token => {
        const index = this.uploader.getIndexOfItem(value);
        const item = this.uploader.queue[index];
        this.uploader.setOptions({
          authToken: `Bearer ${token}`,
        });

        item._prepareToUploading();
        if (this.uploader.isUploading) return;

        this.uploader.isUploading = true;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.uploader['_xhrTransport'](item);
      });
    };
    this.defDocumentType = initialData.documentType;
    this.setInitialData(initialData);

    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onAfterAddingAll = (items: FileItem[]) =>
      this.filesAddedSubject.next(this.getUIFiles(items));
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);

    // TODO use when backend is ready
    // this.uploader.onProgressItem = (item: FileItem, progress: any) => {
    //   console.log(item.file.name, progress);
    // };

    return this.uploader;
  }

  updateFile({item, name, created, documentType}: UIFile) {
    item.file.name = name;
    this.setAdditionalDataForItem(item, {
      ...this.getAdditionalDataForItem(item),
      originalName: name,
      documentType,
    });
    item.file.name = this.getNormalizedFileName(item, created);
  }

  uploadFiles() {
    this.uploader.uploadAll();
    this.updateQueuedFiles();
  }

  removeFromQueue(item: FileItem) {
    this.removeAdditionalDataForItem(item);
    this.uploader.removeFromQueue(item);
    this.updateQueuedFiles();
  }

  private setInitialData(data: Partial<LifeInsuranceInitialUploadData>) {
    if (this.uploader) {
      this.uploader.setOptions({
        ...this.uploader.options,
        additionalParameter: {
          ...this.uploader.options.additionalParameter,
          ...data,
        },
      });
    }
  }

  private getNormalizedFileName = (fileItem: FileItem, timestamp: string) => {
    const fileNameSplitted = fileItem.file.name.split('.');
    const fileSuffix = fileNameSplitted.pop();

    // get rid of diacritics and special characters
    const fileName = `${fileNameSplitted.join('.')}_${timestamp}`
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w]/gi, '-');

    return `${fileName}.${fileSuffix}`;
  };

  private setAdditionalDataForItem(item: FileItem, data: UploadingFileData) {
    this.uploadingFilesData[this.uploader.getIndexOfItem(item)] = data;
  }

  private getAdditionalDataForItem(item: FileItem): UploadingFileData {
    return this.uploadingFilesData[this.uploader.getIndexOfItem(item)];
  }

  private removeAdditionalDataForItem(item: FileItem): void {
    this.uploadingFilesData.splice(this.uploader.getIndexOfItem(item), 1);
  }

  private getUIFiles(items: FileItem[]) {
    return items.map(item => {
      const {originalName, created, documentType} = this.getAdditionalDataForItem(item);
      return {
        name: originalName,
        mimeType: item.file.type,
        created,
        item,
        documentType,
      };
    });
  }

  private updateQueuedFiles() {
    this.queuedFilesSubject.next(this.getUIFiles(this.uploader.queue));
  }

  private onAfterAddingFile(item: FileItem) {
    const created = new Date().toISOString();
    // fixing CORS issue
    item.withCredentials = false;
    // additional data for store / db
    this.setAdditionalDataForItem(item, {
      originalName: item.file.name,
      created,
    });
    item.file.name = this.getNormalizedFileName(item, created);
  }

  private onBeforeUploadItem(item: FileItem) {
    const {documentType} = this.getAdditionalDataForItem(item);
    this.setInitialData({documentType: documentType || this.defDocumentType});
  }

  private onWhenAddingFileFailed(item: FileLikeObject) {
    this.fileAddingFailedSubject.next(item);
  }

  private onSuccessItem(item: FileItem, responseStr: string) {
    const response = JSON.parse(responseStr);
    const {originalName, created, documentType} = this.getAdditionalDataForItem(item);

    this.fileUploadedSubject.next({
      name: originalName,
      dmsUuid: response.dmsUuid,
      // TODO hotfix for doc / xls files -> replace by correct mime type
      mimeType: item.file.type || 'application/octet-stream',
      documentType,
      created,
    });

    this.removeFromQueue(item);
  }
}
