import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class PrintInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (location.href.includes('/financial-plan/print')) {
      return next.handle(req.clone({headers: req.headers.set('X-Print', 'true')}));
    } else {
      return next.handle(req);
    }
  }
}
