import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {debounceTime} from 'rxjs/operators';
import {
  PersonalInsuranceProposalVM,
  RiskDefinitionVM,
} from 'src/app/modules/life-insurance-old/5-proposals/proposals.selectors';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-header-column',
  templateUrl: './header-column.component.html',
  styleUrls: ['./header-column.component.scss'],
})
export class HeaderColumnComponent implements OnChanges, OnDestroy {
  @Input()
  proposal: PersonalInsuranceProposalVM;
  risksForm: UntypedFormArray;

  constructor(private store: Store<State>, private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal.firstChange) {
      this.createForm();
    } else {
      this.updateForm();
    }
  }

  ngOnDestroy() {}

  trackByRiskId(_: number, item: RiskDefinitionVM) {
    return item.id;
  }

  addCustomRisk(personId: string) {
    this.store.dispatch(LifeInsuranceActions.addCustomRisk({personId, myChoice: false}));
  }

  deleteCustomRisk(riskId: string) {
    this.store.dispatch(LifeInsuranceActions.deleteCustomRisk({customRiskDefId: riskId}));
  }

  private createForm() {
    this.risksForm = this.fb.array(
      this.proposal.riskDefs.map(riskDef => {
        const formControl = new UntypedFormControl(riskDef.label);
        if (riskDef.editable) {
          formControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe(value => {
              this.store.dispatch(
                LifeInsuranceActions.updateCustomRisk({
                  customRiskDefId: riskDef.id,
                  label: value,
                }),
              );
            });
        }
        return formControl;
      }),
    );
  }

  private updateForm() {
    if (this.proposal.riskDefs.length !== this.risksForm.length) {
      this.createForm();
    } else {
      this.risksForm.setValue(this.proposal.riskDefs.map(def => def.label));
    }
  }
}
