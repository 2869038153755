import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@shared/pipes/pipes.module';
import {AccordionComponent} from '@shared/ui/accordion/accordion.component';
import {ButtonModule} from '@shared/ui/button/button.module';

@NgModule({
  declarations: [AccordionComponent],
  imports: [ButtonModule, CommonModule, PipesModule],
  exports: [AccordionComponent],
})
export class AccordionModule {}
