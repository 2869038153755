import {TypeOption} from '@ngx-formly/core/lib/services/formly.config';
import {DescriptionFormlyComponent} from '@shared/ui/formly/description-formly/description-formly.component';
import {FulfillmentFormlyComponent} from '@shared/ui/formly/fulfillment-formly/fulfillment-formly.component';
import {InsuranceOfCreditProductFormlyComponent} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.component';
import {LabelFormlyComponent} from '@shared/ui/formly/label-formly/label-formly.component';
import {PercentageInputFormlyComponent} from '@shared/ui/formly/percentage-input-formly/percentage-input-formly.component';
import {RelatedAssetHeaderFormlyComponent} from '@shared/ui/formly/related-asset-header-formly/related-asset-header-formly.component';
import {RelatedAssetSelectFormlyComponent} from '@shared/ui/formly/related-asset-select-formly/related-asset-select-formly.component';
import {SliderInputFormlyComponent} from '@shared/ui/formly/slider-input-formly/slider-input-formly.component';
import {SliderRangeFormlyComponent} from '@shared/ui/formly/slider-range-formly/slider-range-formly.component';
import {SwitchFormlyComponent} from '@shared/ui/formly/switch-formly/switch-formly.component';
import {AssetLifeInsuranceRisksFormlyComponent} from './asset-life-insurance-risks-formly/asset-life-insurance-risks-formly.component';
import {CheckboxFormlyComponent} from './checkbox-formly/checkbox-formly.component';
import {DatepickerFormlyComponent} from './datepicker-formly/datepicker-formly.component';
import {FamilyMembersFormlyComponent} from './family-members-formly/family-members-formly.component';
import {FamilyMemberTypes, FormlyTypes} from './formly.enums';
import {InputFormlyComponent} from './input-formly/input-formly.component';
import {PartnerSelectFormlyComponent} from './partner-select-formly/partner-select-formly.component';
import {ProductSelectFormlyComponent} from './product-select-formly/product-select-formly.component';
import {RadioGroupFormlyComponent} from './radio-group-formly/radio-group-formly.component';
import {RepeatableFormlyComponent} from './repeatable-formly/repeatable-formly.component';
import {RisksLifeInsuranceFormlyComponent} from './risks-life-insurance-formly/risks-life-insurance-formly.component';
import {SelectFormlyComponent} from './select-formly/select-formly.component';
import {SliderInputRangeFormlyComponent} from './slider-input-range-formly/slider-input-range-formly.component';
import {YearInputFormlyComponent} from './year-input-formly/year-input-formly.component';

export const formlyTypesDefinition: TypeOption[] = [
  {
    name: FormlyTypes.Text,
    component: InputFormlyComponent,
  },
  {
    name: FormlyTypes.Number,
    component: InputFormlyComponent,
  },
  {
    name: FormlyTypes.Currency,
    component: InputFormlyComponent,
    defaultOptions: {
      templateOptions: {
        appendText: 'Kč',
      },
    },
  },
  {
    name: FormlyTypes.Password,
    component: InputFormlyComponent,
  },
  {
    name: FormlyTypes.Textarea,
    component: InputFormlyComponent,
  },
  {
    name: FormlyTypes.Checkbox,
    component: CheckboxFormlyComponent,
  },
  {
    name: FormlyTypes.Switch,
    component: SwitchFormlyComponent,
  },
  {
    name: FormlyTypes.Datepicker,
    component: DatepickerFormlyComponent,
  },
  {
    name: FormlyTypes.Year,
    component: YearInputFormlyComponent,
    defaultOptions: {
      templateOptions: {
        appendText: 'let',
      },
    },
  },
  {
    name: FormlyTypes.Select,
    component: SelectFormlyComponent,
  },
  {
    name: FormlyTypes.RadioGroup,
    component: RadioGroupFormlyComponent,
  },
  {
    /** slider with text input */
    name: FormlyTypes.SliderInputRange,
    component: SliderInputRangeFormlyComponent,
  },
  {
    name: FormlyTypes.FamilyMemberRadio,
    component: FamilyMembersFormlyComponent,
    defaultOptions: {
      templateOptions: {
        familyMembersType: FamilyMemberTypes.Radio,
      },
    },
  },
  {
    name: FormlyTypes.FamilyMemberCheckbox,
    component: FamilyMembersFormlyComponent,
    defaultOptions: {
      templateOptions: {
        familyMembersType: FamilyMemberTypes.Checkbox,
      },
    },
  },
  {
    name: FormlyTypes.FamilyMemberSelect,
    component: FamilyMembersFormlyComponent,
    defaultOptions: {
      templateOptions: {
        familyMembersType: FamilyMemberTypes.Select,
      },
    },
  },
  {
    /** slider with from/to range support */
    name: FormlyTypes.SliderRange,
    component: SliderRangeFormlyComponent,
  },
  {
    /** slider with input */
    name: FormlyTypes.SliderInput,
    component: SliderInputFormlyComponent,
  },
  {
    name: FormlyTypes.Repeatable,
    component: RepeatableFormlyComponent,
  },
  {
    name: FormlyTypes.Percentage,
    component: PercentageInputFormlyComponent,
  },
  {
    name: FormlyTypes.Description,
    component: DescriptionFormlyComponent,
  },
  {
    name: FormlyTypes.Fulfillment,
    component: FulfillmentFormlyComponent,
  },
  {
    name: FormlyTypes.Label,
    component: LabelFormlyComponent,
  },
  {
    name: FormlyTypes.RelatedAssetSelect,
    component: RelatedAssetSelectFormlyComponent,
  },
  {
    name: FormlyTypes.RelatedAssetHeader,
    component: RelatedAssetHeaderFormlyComponent,
  },
  {
    name: FormlyTypes.RisksLifeInsurance,
    component: RisksLifeInsuranceFormlyComponent,
  },
  {
    name: FormlyTypes.AssetLifeInsuranceRisks,
    component: AssetLifeInsuranceRisksFormlyComponent,
  },
  {
    name: FormlyTypes.InsuranceOfCreditProduct,
    component: InsuranceOfCreditProductFormlyComponent,
  },
  {
    name: FormlyTypes.PartnerSelect,
    component: PartnerSelectFormlyComponent,
  },
  {
    name: FormlyTypes.ProductSelect,
    component: ProductSelectFormlyComponent,
  },
];
