import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private title$ = new BehaviorSubject<string>('');
  private paused = false;

  setTitle(title: string) {
    if (!this.paused) {
      this.title$.next(title);
    }
  }

  getTitle(): Observable<string> {
    return this.title$.asObservable();
  }

  pause() {
    this.paused = true;
  }

  unpause() {
    this.paused = false;
  }
}
