import {Injectable} from '@angular/core';
import {column, row} from '@lib/utils';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FieldLabel} from '@shared/analysis/field-label';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {getObjectivesField} from '@shared/analysis/forms/objectives.helpers';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {getAssetValidators} from '@shared/analysis/validations.helpers';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {
  createDescriptionField,
  createRisksLifeInsuranceField,
  getBasicField,
} from '@shared/ui/formly/formly.utils';
import * as moment from 'moment';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';

@Injectable()
export class FamilyProvisionAssetType extends BaseAssetType {
  constructor(private featureFlagsService: FeatureFlagsService) {
    super();
  }

  async create(): Promise<FamilyProvisionAsset> {
    return {
      type: AssetType.FamilyProvision,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      name: null,
      description: null,
      lifeInsuranceRisks: [],
      lifeInsurancePersonDatas: [],
    };
  }

  getAssetDefinition(): AssetDefinition {
    if (this.featureFlagsService.showNewDashboard) {
      return {
        name: assetNames[AssetType.FamilyProvision],
        type: AssetType.FamilyProvision,
      };
    } else {
      return {
        name: assetNames[AssetType.FamilyProvision],
        type: AssetType.FamilyProvision,
        fields: [
          createDescriptionField(),
          row(
            [
              column(
                [getBasicField(FormlyTypes.Year, 'start', FieldLabel.PROVISION_START)],
                [6, 6],
              ),
              column([getBasicField(FormlyTypes.Text, 'name', FieldLabel.NAME)], [6, 6]),
            ],
            'd-block pb-4',
          ),
          createRisksLifeInsuranceField(),
          ...getObjectivesField(),
          {key: 'lifeInsuranceRisks'},
          {key: 'lifeInsurancePersonDatas'},
        ],
        validators: getAssetValidators(AssetType.FamilyProvision),
        model: {},
      };
    }
  }
}
