import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {RiskWarnings} from '@shared/analysis/components/risks-selection/risks-selection.models';
import {RisksSelectionService} from '@shared/analysis/components/risks-selection/risks-selection.service';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {RiskDefinition} from 'src/store/models/risk.models';

@UntilDestroy()
@Component({
  selector: 'kpt-risks-selection',
  templateUrl: './risks-selection.component.html',
  styleUrls: ['./risks-selection.component.scss'],
})
export class RisksSelectionComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() person: LifeInsurancePerson;
  @Input() formGroup: UntypedFormGroup;
  @Input() showWarnings = true;

  filteredAdultRisksDefinitions: RiskDefinition[];
  filteredChildrenRisksDefinitions: RiskDefinition[];
  riskWarnings: RiskWarnings;

  constructor(private risksSelectionService: RisksSelectionService) {}

  ngOnInit() {
    this.filteredAdultRisksDefinitions = this.risksSelectionService.filteredAdultRisksDefinitions;
    this.filteredChildrenRisksDefinitions =
      this.risksSelectionService.filteredChildrenRisksDefinitions;
    this.riskWarnings = this.risksSelectionService.warnings;
  }

  getRiskWarning(personId: string, riskId: string, provision: 'income' | 'expenses'): string {
    return this.riskWarnings.persons[personId]?.[riskId]?.[provision];
  }
}
