<header
  class="header"
  [class.header--sticked]="isHeaderSticked"
  [class.header--no-background-color]="noBackground"
  [class.header--with-stepper]="!!hasStepper"
>
  <div id="top-line-for-intro-js"></div>
  <div class="container-fluid">
    <div class="header-inner">
      <div class="row">
        <div class="col-4 d-flex">
          <a class="header__controls--left" (click)="logoClick()">
            <img
              src="assets/images/logo-kappka.svg"
              class="header-logo"
              alt="logo"
              [title]="appInfo"
            />
          </a>
          <kpt-select
            *ngIf="divisionFormControl && divisionOptions.length > 1"
            class="header__advisor-select"
            [formControl]="divisionFormControl"
            [options]="divisionOptions"
          >
          </kpt-select>
        </div>
        <div class="col-4">
          <div class="header__screen-title-holder">
            <h3 class="header__screen-title">{{ [pageName, clientName] | join : ' &ndash; ' }}</h3>
          </div>
        </div>
        <div class="col-4 info-icons-col" *ngIf="advisorPersonalInfo">
          <div class="header__advisor-info">
            <kpt-avatar
              [hasBorder]="true"
              (click)="navigateToAboutConsultantPage()"
              class="cursor-pointer header__advisor-info__avatar"
              [imgSrc]="advisorDetail?.photoUrl || '/assets/images/layout/default-avatar.svg'"
              imgAlt="Poradce - {{ advisorPersonalInfo.firstName }} {{
                advisorPersonalInfo.lastName
              }}"
              imgTitle="{{ advisorPersonalInfo.firstName }} {{ advisorPersonalInfo.lastName }}"
              [small]="true"
            >
            </kpt-avatar>
            <div class="ml-4 d-flex align-items-start flex-column justify-content-center">
              <h3
                class="mb-0 cursor-pointer header__advisor-info__name"
                (click)="navigateToAboutConsultantPage()"
              >
                {{ advisorPersonalInfo.firstName }} {{ advisorPersonalInfo.lastName }}
              </h3>
              <div
                *ngIf="advisorDetail?.position"
                class="cursor-pointer header__advisor-info__position"
                (click)="navigateToAboutConsultantPage()"
              >
                {{ advisorDetail.position }}
              </div>
            </div>
          </div>
          <div class="text-right icons-section"></div>
          <div class="header__controls header__controls--right">
            <a class="control" (click)="menu()">
              <span class="icon icon--menu"></span>
              <span class="control__label menu"> Menu </span>
            </a>
          </div>

          <!--          <div class="header__controls header__controls&#45;&#45;right">-->
          <!--            <a class="control">-->
          <!--              <span class="icon icon&#45;&#45;article"></span>-->
          <!--              <span class="control__label"> Články </span>-->
          <!--            </a>-->
          <!--          </div>-->

          <!--          <div class="header__controls header__controls&#45;&#45;right">-->
          <!--            <a class="control">-->
          <!--              <span class="icon icon&#45;&#45;add"></span>-->
          <!--              <span class="control__label"> Přílohy </span>-->
          <!--            </a>-->
          <!--          </div>-->

          <!--          <div class="header__controls header__controls&#45;&#45;right">-->
          <!--            <a class="control">-->
          <!--              <span class="icon icon&#45;&#45;bulb"></span>-->
          <!--              <span class="control__label"> Poznámky </span>-->
          <!--            </a>-->
          <!--          </div>-->

          <!-- TODO remove once hamburger menu released -->
          <!--<div *ngIf="showClose" class="header__controls header__controls--right">
            <a class="control" (click)="close(familyId)"><span class="icon icon--close"></span></a>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</header>
