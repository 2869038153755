import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {LoginService} from '@lib/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ClientPageConfig,
  getComplexPlanMenuItems,
  getLifeSituationMenuItems,
  getLifeSituationMenuItems2,
  getOtherToolsMenuItems,
} from 'src/app/modules/client/client-page.config';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {MenuItem} from 'src/shared/lib';

interface MenuItemExtended extends MenuItem {
  appendClientsUrl?: boolean;
  target?: string;
  isExternalLink?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'kpt-client-section',
  templateUrl: 'client-section.component.html',
  styleUrls: ['client-section.component.scss'],
})
export class ClientSectionComponent {
  @Input() user: FamilyMember;

  @Output() navigate = new EventEmitter<MenuItemExtended>();

  itemsPrimary: MenuItem[] = [];
  itemsSecondary: MenuItem[] = [];
  itemsLifeSituations: MenuItem[] = [];
  itemsOthers: MenuItem[] = [];
  itemsGold: MenuItem[] = [];

  constructor(
    private config: ClientPageConfig,
    private loginService: LoginService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.config
      .getCardBoard()
      .pipe(untilDestroyed(this))
      .subscribe(cards => {
        if (!this.featureFlagsService.showNewDashboard) {
          this.itemsPrimary = getComplexPlanMenuItems(cards.complexPlan);
          this.itemsSecondary = getLifeSituationMenuItems(cards.lifeSituation);
          this.itemsOthers = getOtherToolsMenuItems(cards.otherTools);
          this.itemsGold = getLifeSituationMenuItems(cards.gold);
        } else {
          this.itemsLifeSituations = getLifeSituationMenuItems2(cards.lifeSituation);
          this.itemsOthers = getOtherToolsMenuItems(cards.otherTools);
          this.itemsGold = getLifeSituationMenuItems(cards.gold);
        }
      });
  }

  get financialDivision(): boolean {
    return this.loginService.isUserInFinancialDivision();
  }

  get goldDivision(): boolean {
    return this.loginService.isUserInGoldDivision();
  }

  createUserMenuItem() {
    if (!this.user) {
      return;
    }
    const {firstName, lastName} = this.user;
    return [
      {
        target: '',
        label: 'Karta klienta',
        appendClientsUrl: true,
        image: {
          src: '/assets/images/layout/default-avatar-white.svg',
          title: `${firstName} ${lastName}`,
          alt: `${firstName} ${lastName}`,
        },
      },
    ];
  }

  getMenuList(): MenuItemExtended[][] {
    return this.featureFlagsService.showNewDashboard ? this.getMenuList2() : this.getMenuList1();
  }

  navigateTo(item: MenuItemExtended) {
    this.navigate.emit(item);
  }

  private getMenuList1(): MenuItemExtended[][] {
    return [
      this.createUserMenuItem(),
      ...(this.financialDivision ? [this.itemsPrimary, this.itemsSecondary, this.itemsOthers] : []),
      ...(this.goldDivision && !this.financialDivision ? [this.itemsGold] : []),
      [
        {
          label: 'Změnit klienta',
          target: '/secured/consultant',
          icon: 'switch',
          appendClientsUrl: false,
        },
      ],
    ];
  }

  private getMenuList2(): MenuItemExtended[][] {
    return [
      this.createUserMenuItem(),
      ...(this.financialDivision ? [this.itemsLifeSituations, this.itemsOthers] : []),
      ...(this.goldDivision && !this.financialDivision ? [this.itemsGold] : []),
      [
        {
          label: 'Změnit klienta',
          target: '/secured/consultant',
          icon: 'switch',
          appendClientsUrl: false,
        },
      ],
    ];
  }
}
