<div class="form-group" [id]="id" [ngClass]="customFormGroupClass" #field>
  <span class="control-label radiogroup-control-label" *ngIf="label && label?.length">
    <ng-template [ngTemplateOutlet]="labelTemplate || defaultPlainLabelTemplateRef"></ng-template>
    <ng-template [ngTemplateOutlet]="appendHelpTextRef"></ng-template>
  </span>
  <div
    *ngFor="let option of optionsList; trackBy: optionKey"
    class="custom-control custom-radio"
    [ngClass]="itemClass"
  >
    <input
      type="radio"
      (blur)="blur.emit($event)"
      [readonly]="readonly"
      class="custom-control-input"
      [class.form-control--not-empty]="inputValue === option.key"
      [required]="required"
      [(ngModel)]="inputValue"
      [ngClass]="customFieldClass"
      [disabled]="disabled || option.disabled"
      [checked]="_value === option.key"
      [value]="option.key"
    />

    <label class="custom-control-label" (click)="labelClick(option.key)">
      <ng-template
        [ngTemplateOutletContext]="{option: option}"
        [ngTemplateOutlet]="itemTemplate || defaultItemTemplateRef"
      ></ng-template>
    </label>

    <ng-template #defaultItemTemplateRef>
      <span [innerHTML]="option.label"></span>
    </ng-template>
  </div>

  <kpt-form-field-error
    [showErrorsOn]="showErrorsOn"
    [customErrors]="customErrors"
  ></kpt-form-field-error>

  <!-- maybe move to cmp -->
  <ng-template #defaultPlainLabelTemplateRef>
    <span [innerHTML]="htmlLabel || label"></span>
  </ng-template>

  <ng-template #appendHelpTextRef>
    <div *ngIf="!!appendHelpText" class="input-group-append ml-4">
      <!-- TODO replace by help component once it is moved to shared -->
      <shd-tooltip icon="question-mark" [direction]="appendHelpTextDirection">
        <span class="text small" [innerHTML]="appendHelpText"></span>
      </shd-tooltip>
    </div>
  </ng-template>
</div>
