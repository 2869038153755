export const esgPreferenceDescription = `
<p>
  Pojmy „udržitelnost“ a „ESG“ znamenají, že investiční produkty a investiční
  složky IŽP se dle evropské legislativy hodnotí z hlediska jejich dopadu na
  životní prostředí, z hlediska společenské odpovědnosti a principu správného a
  etického managementu společností.
</p>

<p>
  Hesla „životní prostředí“ (<strong>E</strong>nvironmental), „společenská
  odpovědnost“ (<strong>S</strong>ocial) a etické řízení společností
  (<strong>G</strong>overnance) pak skládají anglickou zkratku
  <strong>ESG</strong>, pod kterou jsou tyto investice známy. Tyto investice
  mohou být obecně známé např. pod pojmy „zelené investice“ nebo „udržitelné
  investice“.
</p>

<p>
  Stěžejní povinnosti KAPITOLU a jeho poradců ve vztahu k udržitelnosti investic
  vychází z Nařízení Evropského parlamentu a Rady (EU) 2019/2088 ze dne 27.
  listopadu 2019 o zveřejňování informací souvisejících s udržitelností v
  odvětví finančních služeb (dále jako SFDR).
</p>
`;

export const esgPreferenceMoreDescription = `
<p>
  Udržitelné investice v jednotlivých oblastech ESG se mohou soustředit typicky
  na tyto oblasti:
</p>
<p><strong>Životní prostředí (Environmental)</strong></p>
<ul>
  <li>uhlíková neutralita a omezování emisí skleníkových plynů</li>
  <li>ochrana biotopů a vodních zdrojů</li>
  <li>ochrana biodiverzity</li>
</ul>
<p><strong>Společenská odpovědnost (Social)</strong></p>
<ul>
  <li>spravedlivé pracovní podmínky</li>
  <li>nediskriminační prostředí</li>
  <li>férově nastavené dodavatelské řetězce</li>
  <li>prevence vykořisťování a moderního otroctví</li>
  <li>potírání dětské práce</li>
</ul>
<p><strong>Etický management společností (Governance)</strong></p>
<ul>
  <li>nezávisle fungující řídící orgány</li>
  <li>dodržování etických kodexů</li>
  <li>prevence korupce</li>
  <li>spravedlivé odměňování zaměstnanců a vrcholného managementu</li>
</ul>
<p>
  Naopak udržitelné investice se typicky vyhýbají sektorům jako je těžba
  fosilních paliv, energetika s využitím fosilních paliv, výroba alkoholu,
  zpracování tabáku, výroba a export zbraní nebo obchod se zeměmi porušujícími
  lidská práva.
</p>
`;

export const esgObjectivesDescription = `
<p>Co znamenají světle a tmavě zelené investice?</p>
`;

export const esgObjectivesMoreDescription = `
<p>
  <strong>Světle zelené investice – investice dle čl. 8 nařízení SFDR:</strong>
</p>
<p>
  Tyto investice nemají za primární cíl některou z oblastí udržitelnosti avšak
  pozitivním způsobem přispívají k plnění cílů v oblasti udržitelných financí.
  Může se jednat např. o investice do výrobních podniků využívajících nejlepší
  dostupné technologie z hlediska emise skleníkových plynů nebo splňujících
  standardy společenské odpovědnosti.
</p>

<p>
  <strong>Tmavě zelené investice – investice dle čl. 9 nařízení SFDR:</strong>
</p>
<p>
  Tyto investice sledují jako svůj primární cíl některou z oblastí
  udržitelnosti. Může se jednat např. o investice do elektromobility nebo do
  výroby energie z obnovitelných zdrojů.
</p>
`;

export const esgBondsMoreDescription = `
<p>
  Státní dluhopisy jsou obtížně posouditelné z&nbsp;hlediska udržitelnosti. 
  Vyloučení státních dluhopisů ze&nbsp;základu portfolia investice může mít 
  za&nbsp;následek menší celkový podíl udržitelných investic. Na&nbsp;druhou 
  stranu zahrnutí státních dluhopisů do&nbsp;portfolia investice obecně snižuje 
  rizikovost a kolísavost investice.
</p>
`;
