import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'kpt-summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.scss'],
})
export class SummaryListComponent implements OnInit {
  @Input() title: string;
  @Input() items: string[];

  constructor() {}

  ngOnInit() {}
}
