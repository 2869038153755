<kpt-content-box [wide]="true" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <h2>Jaké jsou možnosti</h2>
  <kpt-analysis-changed-alert></kpt-analysis-changed-alert>
  <div
    *ngFor="let proposal of insuranceProposals$ | async; trackBy: trackByPersonId"
    [class.drag-over-page]="dragOverPage"
  >
    <div class="personal-insurance-proposal">
      <kpt-header-column [proposal]="proposal"></kpt-header-column>
      <div class="scroll-view" #scrollView>
        <kpt-insurance-group
          *ngFor="let group of formGroups"
          [proposal]="proposal"
          [group]="group"
          (opened)="scrollInto($event)"
        >
        </kpt-insurance-group>
      </div>
    </div>
    <ng-container *ngIf="(priorityProducts$ | async)[proposal.person.id]">
      <div class="priority-result-message d-flex justify-content-left align-items-center my-6">
        <span class="icon icon--bulb tip-icon"></span>
        Na základě dotazníku priorit v oblasti životního pojištění si můžete zobrazit doporučené
        produkty.
        <a class="toggle-priority-results" (click)="onToggleSuggestions(proposal.person.id)">
          {{ showSuggestions[proposal.person.id] ? 'Skrýt doporučení' : 'Zobrazit doporučení' }}
        </a>
      </div>
      <kpt-priorities-results
        *ngIf="showSuggestions[proposal.person.id]"
        class="d-flex justify-content-left mb-6"
        [prioritiesData]="(priorityProducts$ | async)[proposal.person.id]"
      >
      </kpt-priorities-results>
    </ng-container>

    <h3>Přílohy</h3>
    <kpt-attachments
      [initialUploadData]="{
        firstName: proposal.familyMember.firstName,
        lastName: proposal.familyMember.lastName,
        birthNumber: proposal.familyMember.birthNumber
      }"
      [files]="getGeneralFilesOfPerson$(proposal.person.id) | async"
      (fileUploaded)="onGeneralFileUploaded($event, proposal.person.id)"
      (fileUpdated)="onGeneralFileUpdated($event)"
      (fileRemoved)="onGeneralFileRemoved($event)"
    >
    </kpt-attachments>
  </div>
</kpt-content-box>
