import {FamilyMember} from '@generated/defs/FamilyMember';
import {select} from '@ngrx/store';
import {getFamilyMembersAdultsThenChildren} from '@shared/lib';
import {OperatorFunction, pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {State} from 'src/store';
import {FamilyMemberState} from 'src/store/reducers/family-members.reducer';
import {getFamilyMembersState} from 'src/store/selectors/family-member.selectors';
import {notLoadingNorError} from 'src/store/store-helpers';

export function selectFamilyMembers(): OperatorFunction<State, FamilyMember[]> {
  return pipe(
    select(getFamilyMembersState),
    filter(notLoadingNorError),
    map((familyMembersState: FamilyMemberState) =>
      getFamilyMembersAdultsThenChildren(familyMembersState.familyMembers),
    ),
  );
}

export function selectFamilyHead(): OperatorFunction<State, FamilyMember> {
  return pipe(
    selectFamilyMembers(),
    map(familyMembers => familyMembers.find(member => member.familyHead)),
    filter(v => Boolean(v)),
  );
}

export function selectAdultFamilyMembers(): OperatorFunction<State, FamilyMember[]> {
  return pipe(
    selectFamilyMembers(),
    map(familyMembers => familyMembers.filter(member => member.type === 'ADULT')),
    filter(v => Boolean(v)),
  );
}

export function selectChildrenFamilyMembers(): OperatorFunction<State, FamilyMember[]> {
  return pipe(
    selectFamilyMembers(),
    map(familyMembers => familyMembers.filter(member => member.type === 'CHILD')),
    filter(v => Boolean(v)),
  );
}
