import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {consumerLoansStepperProgressConfigInModal} from 'src/app/modules/consumer-loans/consumer-loans-page.config';
import {housingStepperProgressConfigInModal} from 'src/app/modules/housing/housing-page.config';
import {investmentStepperProgressConfigInModal} from 'src/app/modules/investment-old/investment.component.config';
import {lifeInsuranceStepperProgressConfigInModal} from 'src/app/modules/life-insurance-old/life-insurance-page.config';
import {pensionStepperProgressConfigInModal} from 'src/app/modules/pension-old/pension-page.config';
import {PageTitleService} from 'src/app/services/page-title.service';
import {IStepperProgressItem} from 'src/shared/ui/stepper-progress/models/stepper-progress.model';

@Component({
  selector: 'kpt-business-tool-modal',
  templateUrl: 'business-tool-modal.component.html',
  styleUrls: ['business-tool-modal.component.scss'],
})
export class BusinessToolModalComponent implements OnDestroy {
  stepperConfig: IStepperProgressItem[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageTitleService: PageTitleService,
  ) {
    this.setStepperConfig();
    this.pageTitleService.pause();
  }

  ngOnDestroy() {
    this.pageTitleService.unpause();
  }

  close() {
    this.router.navigate(['../'], {relativeTo: this.route, queryParamsHandling: 'preserve'});
  }

  private setStepperConfig() {
    switch (this.route.routeConfig.path) {
      case ClientPage.LifeInsuranceOld:
        this.stepperConfig = lifeInsuranceStepperProgressConfigInModal;
        break;
      case ClientPage.Housing:
        this.stepperConfig = housingStepperProgressConfigInModal;
        break;
      case ClientPage.InvestmentOld:
        this.stepperConfig = investmentStepperProgressConfigInModal;
        break;
      case ClientPage.PensionOld:
        this.stepperConfig = pensionStepperProgressConfigInModal;
        break;
      case ClientPage.ConsumerLoan:
        this.stepperConfig = consumerLoansStepperProgressConfigInModal;
        break;
      default:
        throw new Error('Unsupported module.');
    }
  }
}
