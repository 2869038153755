import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {uniqueId} from 'lodash';
import * as moment from 'moment';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
  BsDaterangepickerDirective,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import {csLocale} from 'ngx-bootstrap/locale';
import {CommonFieldComponent} from '../common-field/common-field.component';
import {
  defaultDateFormat,
  defaultDatepickerConfig,
  defaultMonthpickerConfig,
  monthDateFormat,
} from './datepicker.config';

const locale = 'cs';

const isValidDate = (stringDate: string): boolean => {
  return stringDate !== null && !isNaN(new Date(stringDate).getTime());
};

@Component({
  selector: 'kpt-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    ...getProvidersFor(DatepickerComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => DatepickerComponent)},
  ],
})
export class DatepickerComponent extends CommonFieldComponent implements OnInit, OnChanges {
  @ViewChild('datePicker', {static: true}) datePicker: BsDaterangepickerDirective;

  @Input() labelTemplate: TemplateRef<any>;
  @Input() monthPicker = false;
  @Input() config: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  @Input() id = uniqueId('datepicker');

  // once the generated forms gets removed, we can switch this maybe to Date obj
  @Input() minDate: string;
  @Input() maxDate: string;

  // Current selected date.
  _value: Date | string = null;

  // Get set date of date picker control.
  get value(): Date | string {
    return this._value;
  }

  // Select a date.
  set value(date: Date | string) {
    if (this._value === date) return;
    if (!isValidDate(date as string)) date = null;
    this._value = date;
    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  minDateObj: Date;
  maxDateObj: Date;
  defaultDateFormat = defaultDateFormat;
  monthDateFormat = monthDateFormat;
  private minMode: BsDatepickerViewMode = 'month';

  constructor(
    private cd: ChangeDetectorRef,
    private localeService: BsLocaleService,
    renderer: Renderer2,
  ) {
    super(renderer);

    csLocale.invalidDate = '';
    defineLocale(locale, csLocale);
    this.localeService.use(locale);
  }

  ngOnInit() {
    if (!!this.monthPicker) {
      this.config = defaultMonthpickerConfig;
      this.config.minMode = this.minMode;
    } else {
      this.config = defaultDatepickerConfig;
    }
  }

  writeValue(value: string): void {
    if (value !== null) {
      const date = new Date(value);
      date.setHours(0, 0, 0);
      this._value = date;
    } else this._value = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate) {
      this.minDateObj = this.minDate
        ? moment(this.minDate === 'now' ? {} : this.minDate).toDate()
        : null;
    }
    if (changes.maxDate) {
      this.maxDateObj = this.maxDate
        ? moment(this.maxDate === 'now' ? {} : this.maxDate).toDate()
        : null;
    }
  }

  labelClick = () => {
    this.datePicker.show();
    this.cd.markForCheck();
  };

  onInputFocus() {
    // Fixes bug, where datepicker input with empty value does nothing on the first click,
    // and you have to click again to open the dropdown.
    setTimeout(() => {
      this.datePicker.show();
      this.cd.markForCheck();
      // Timeout 100 is needed, without it, we open the dropdown too early, and for some reason the
      // dropdown gets immediately closed. We use ngx-bootstrap's datepicker, and as we're planning
      // to remove ngx-bootstrap eventually, I don't want to invest more time into figuring out why.
      // this happens.
    }, 100);
  }
}
