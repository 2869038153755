import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  ChildrenTaxAdvantageAsset,
  PersonTaxAdvantage,
} from '@shared/analysis/models/income-attributes';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {selectCurrentAssets} from '@shared/analysis/store';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {createTab, createTabs, getBasicField} from 'src/shared/ui/formly/formly.utils';
import {State} from 'src/store';
import {assetNames, AssetType} from '../models/asset';
import {RichAssetDefinition} from './definitions.models';

interface ChildTaxAdvantageForm {
  [uuid: string]: string | boolean;
}

@Injectable()
export class ChildrenTaxAdvantage {
  /**
   * This prefix has two purposes.
   *  1) To simply determine its adult.
   *  2) sugarUuid sometimes starts with number and property cannot start with number.
   */
  private readonly adultPrefix = 'adult';
  private readonly delimiter = '$';

  constructor(private store: Store<State>) {}

  createTaxChildrenDefinition(): Observable<RichAssetDefinition> {
    return combineLatest(
      this.store.select(selectCurrentAssets),
      this.store.pipe(selectFamilyMembers()),
    ).pipe(
      map(([assets, family]) => {
        const childrenTaxAdvantageAsset = assets.find(
          asset => asset.type === AssetType.ChildrenTaxAdvantage,
        ) as ChildrenTaxAdvantageAsset;
        return {
          asset: childrenTaxAdvantageAsset,
          definition: {
            name: assetNames[AssetType.ChildrenTaxAdvantage],
            type: AssetType.ChildrenTaxAdvantage,
            fields: [createTabs(...this.createFields(family))],
            model: this.convertFromDto(childrenTaxAdvantageAsset),
          },
        };
      }),
    );
  }

  /**
   * Method that converts Formly form to ChildrenTaxAdvantageAsset
   *
   * @param formModel Form object given by Formly
   * @param familyMembers Family is used here to get valid Uuid -> uuid with dashes '-'
   */
  convertToDto(
    formModel: ChildTaxAdvantageForm,
    familyMembers: FamilyMember[],
  ): ChildrenTaxAdvantageAsset {
    const getValidUuid = (uuid: string, family: FamilyMember[]) =>
      family.find(m => this.removeAllButCharacters(m.sugarUuid) === uuid).sugarUuid;

    const getChildren = (adultUuid: string) =>
      Object.entries(formModel)
        .filter(([key, _]) => key.includes(`${adultUuid}${this.delimiter}`))
        .filter(([_, value]) => Boolean(value))
        .map(([key, _]) =>
          getValidUuid(key.substring(`${adultUuid}${this.delimiter}`.length), familyMembers),
        );

    const persons: PersonTaxAdvantage[] = familyMembers
      .filter(m => m.type === 'ADULT')
      .map(m => ({
        familyMemberUuid: m.sugarUuid,
        childrenUuids: getChildren(this.getAdultUuid(m.sugarUuid)),
      }));

    return {
      assetUuid: null,
      persons,
      type: AssetType.ChildrenTaxAdvantage,
    };
  }

  convertFromDto(asset: ChildrenTaxAdvantageAsset): ChildTaxAdvantageForm {
    if (!asset) return {};
    return asset.persons.reduce((model: ChildTaxAdvantageForm, person: PersonTaxAdvantage) => {
      person.childrenUuids.forEach(
        child =>
          (model[this.getChildUuid(child, this.getAdultUuid(person.familyMemberUuid))] = true),
      );
      return model;
    }, {});
  }

  private createFields(family: FamilyMember[]): FormlyFieldConfig[] {
    return family
      .filter(member => member.type === 'ADULT')
      .reduce((fields, member) => [...fields, this.createAdultBox(member, family)], []);
  }

  private getChildUuid(childId: string, adultId: string) {
    return `${adultId}${this.delimiter}${this.removeAllButCharacters(childId)}`;
  }

  private removeAllButCharacters(text: string) {
    return text.replace(/[^\w\s!?]/g, '');
  }

  /** Cannot start with number and cannot contain dashes '-' */
  private getAdultUuid(adultId: string) {
    return `${this.adultPrefix}${this.removeAllButCharacters(adultId)}`;
  }

  private createAdultBox(member: FamilyMember, family: FamilyMember[]): FormlyFieldConfig {
    const id = this.getAdultUuid(member.sugarUuid);
    const adults = family.filter(f => f.type === 'ADULT');
    const children = family.filter(f => f.type !== 'ADULT');

    const getOtherChildrenState = (model: ChildTaxAdvantageForm, childId: string) =>
      adults
        .filter(a => a.sugarUuid !== member.sugarUuid)
        .some(a => model[this.getChildUuid(childId, this.getAdultUuid(a.sugarUuid))]);

    const childDisableConditions = (childId: string) => {
      return (model: ChildTaxAdvantageForm) => getOtherChildrenState(model, childId);
    };

    return createTab(member.sugarUuid, member.firstName ? member.firstName : member.lastName, {
      fieldGroupClassName: 'row',
      fieldGroup: [
        ...children.map(child => ({
          ...getBasicField(
            FormlyTypes.Checkbox,
            this.getChildUuid(child.sugarUuid, id),
            child.firstName,
          ),
          className: 'col-3',
          formControlSubstitute: this.getChildUuid(child.sugarUuid, id),
          expressionProperties: {
            'templateOptions.disabled': childDisableConditions(child.sugarUuid),
          },
        })),
      ],
    });
  }
}
