import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {interestRates} from '@shared/analysis/interest-rate.definitions';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class FinancialProperties {
  constructor(private abstractAssets: AbstractAssetsDefinitions) {}

  currentAccount(): AssetDefinition {
    const commonFinancialPropertyAsset = this.abstractAssets.commonFinancialPropertyAsset();
    const type = AssetType.CurrentAccount;

    return {
      name: assetNames[type],
      type,
      fields: [
        commonFinancialPropertyAsset.assetUuid,
        commonFinancialPropertyAsset.name,
        row([col(commonFinancialPropertyAsset.value), col(commonFinancialPropertyAsset.start)]),
        row([
          col(this.abstractAssets.yearlyRate(interestRates[AssetType.CurrentAccount])),
          col(commonFinancialPropertyAsset.institutionName),
        ]),
        commonFinancialPropertyAsset.note,
      ],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  getAll(): AssetDefinition[] {
    return [this.currentAccount()];
  }
}
