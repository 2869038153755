import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {AssetDefinitions} from '@shared/analysis/forms/definitions';
import {Asset, AssetType, AdvisorProposalState} from '@shared/analysis/models/asset';
import {
  FinancialAnalysisAction,
  FinancialAnalysisActions,
} from '@shared/analysis/store/financial-analysis.actions';
import {
  selectAllAssets,
  selectProposedAssets,
} from '@shared/analysis/store/financial-analysis.selectors';
import {concatMap, filter, first, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {State} from 'src/store';
import {selectAssetByType} from './financial-analysis.selectors';

@Injectable()
export class FinancialAnalysisEffects {
  removeSumAssets = createEffect(() =>
    this.actions$.pipe(
      ofType('FA_AddAsset', 'FA_SaveAsset'),
      concatMap(action => this.assetDefinitions.getSumAssetTypes(action.payload.type, true)),
      mergeMap(type => this.store.pipe(select(selectAssetByType(type)), first())),
      filter<Asset>(Boolean),
      map(({assetUuid}) => FinancialAnalysisActions.FA_RemoveAsset({assetUuid})),
    ),
  );

  /** When a relation is removed from a CurrentAccountUsage asset this effect removes the asset. */
  removeCurrentAccountUsageAsset = createEffect(() =>
    this.actions$.pipe(
      filter(FinancialAnalysisActions.is.FA_RemoveObjectiveRelation),
      map(action => action.payload.assetUuid),
      withLatestFrom(this.store.pipe(select(selectAllAssets))),
      filter(
        ([assetUuid, assets]) =>
          assets.find(asset => asset.assetUuid === assetUuid)?.type ===
          AssetType.CurrentAccountUsage,
      ),
      map(([assetUuid, _]) => FinancialAnalysisActions.FA_RemoveAsset({assetUuid})),
    ),
  );

  /** When an objective asset is removed this effect also removes associated CurrentAccountUsage assets. */
  removeCurrentAccountUsageAssets = createEffect(() =>
    this.actions$.pipe(
      filter(FinancialAnalysisActions.is.FA_RemoveAsset),
      mergeMap(({payload: {assetUuid}}) =>
        this.store.pipe(
          select(selectAllAssets),
          first(),
          concatMap(assets => assets),
          filter(
            asset =>
              asset.type === AssetType.CurrentAccountUsage &&
              asset.relatedObjectiveUuid === assetUuid,
          ),
          map(asset => FinancialAnalysisActions.FA_RemoveAsset({assetUuid: asset.assetUuid})),
        ),
      ),
    ),
  );

  /** todo */
  removeProposedUnchangedAssets = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.store.pipe(select(selectProposedAssets))),
      filter(
        ([_, assets]) =>
          assets.filter(asset => asset.advisorProposalState === AdvisorProposalState.Unchanged)
            .length > 0,
      ),
      map(() => FinancialAnalysisActions.FA_RemoveProposedUnchangedAssets()),
    ),
  );

  updateLifeInsuranceRisks = createEffect(() =>
    this.actions$.pipe(
      ofType('FA_AddAsset', 'FA_UpdateAsset', 'FA_SaveAsset'),
      filter(action => action.payload.type === AssetType.FamilyProvision),
      map(({payload}) =>
        LifeInsuranceActions.selectRisks({membersRisks: (payload as any).membersRisks}),
      ),
    ),
  );

  constructor(
    private store: Store<State>,
    private assetDefinitions: AssetDefinitions,
    private actions$: Actions<FinancialAnalysisAction>,
  ) {}
}
