/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormArrayExtended} from '../../../common/formArrayExtended';
import {BuildingService} from '../../../controllers/Building';

@Injectable()
export class BuildingFormService {
  form: UntypedFormGroup;
  constructor(
    private buildingService: BuildingService,
  ) {
    this.form = new UntypedFormGroup({
      loan: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/), Validators.required]),
      fullinfo: new UntypedFormControl(false, []),
      fixation: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/)]),
      value: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/)]),
      loan_term: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/), Validators.required]),
      purposes: new FormArrayExtended(() => (
        new UntypedFormControl(undefined, [Validators.required])), [], [Validators.required]),
    });
  }

  submit(raw = false) {
    const data = raw ?
      this.form.getRawValue() :
      this.form.value;
    return this.buildingService.building(data);
  }
}
