import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {CommonPropertyAsset} from '@shared/analysis/models/properties';

export abstract class BasePropertyType extends BaseAssetType {
  constructor() {
    super();
  }

  create(): Omit<CommonPropertyAsset, 'type'> {
    return {
      assetUuid: this.createId(),
      name: null,
      purpose: null,
      value: 0,
      start: this.today(),
    };
  }
}
