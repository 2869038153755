export interface MessageLocalization {
  message: string;
  localizedMessage: string;
}

export const genericValidationLocalization = 'Nesprávná data ve formuláři.';

export const genericErrorLocalization = 'Nastala chyba při zpracování.';

export const connectionEstablished = 'Vaše internetové připojení bylo obnoveno.';

export const connectionInterrupted =
  'Zdá se, že nemáte připojení. Některá data nemusí být uložena. Zkontrolujte Vaše připojení.';

export const connectionTimeout = 'Spojení bylo přerušeno, opakujte akci.';

export const connectionRefused = 'Spojení bylo přerušeno.';

const baseContactSupport =
  'Pro nahlášení na KAPITOL podporu prosím použijte tlačítko „Kontaktovat podporu“ v pravém dolním rohu';
export const contactSupport = `${baseContactSupport}.`;
export const contactSupportAndRepeat = `${baseContactSupport} nebo opakujte akci později.`;

// http status 4xx
export const warningLocalizations: MessageLocalization[] = [
  {
    message: 'The sum asset was sent together with detailed asset.',
    localizedMessage: 'Součet nemůže být odeslán spolu s detailním rozpadem.',
  },
  {
    message: 'The submitted client is not a parent in the submitted family.',
    localizedMessage: 'Zadaný klient není rodičem v rodině.',
  },
  {
    message:
      'The email or the phone number has to be provided, the birth date or the age has to be provided.',
    localizedMessage: 'Musí být vyplněn e-mail nebo telefonní číslo a rodné číslo nebo věk.',
  },
  {
    message: 'The email or the phone number has to be provided.',
    localizedMessage: 'Musí být vyplněn e-mail nebo telefonní číslo.',
  },
  {
    message: 'At least one input has to be provided for searching.',
    localizedMessage: 'Pro vyhledávání musí být vyplněno alespoň jedno pole.',
  },
  {
    message: 'Bad birth number',
    localizedMessage: 'Rodné číslo je neplatné.',
  },
  {
    message:
      'The string provided as a birth number does not match with the right format of a birth number',
    localizedMessage: 'Rodné číslo není ve správném formátu.',
  },
  {
    message: 'The first six characters of the birth number do not match with a date',
    localizedMessage: 'Rodné číslo obsahuje neplatné datum.',
  },
  {
    message: 'The birthdate provided does not match with the birth number',
    localizedMessage: 'Datum narození neodpovídá rodnému číslu.',
  },
  {
    message: 'The birth numbers from 19th century are not valid',
    localizedMessage: 'Rodné číslo musí být minimálně z 20. století.',
  },
  {
    message: 'The sex provided does not match with the birth number',
    localizedMessage: 'Pohlaví neodpovídá rodnému číslu.',
  },
  {
    message: 'The check digit of the birth number (position 10) is wrong.',
    localizedMessage: 'Kontrolní číslice v rodném čísle není správná.',
  },
  {
    message: "you can't delete the only parent in the family",
    localizedMessage: 'Nelze smazat posledního dospělého člena rodiny.',
  },
  {
    message: 'The frozen modelation cannot be freezed.',
    localizedMessage: 'Zmrazená modelace nemůže být opět zmrazena.',
  },
  {
    message: 'The modelation with no results cannot be freezed.',
    localizedMessage: 'Modelace bez výsledků nemůže být zmrazena.',
  },
  {
    message: 'The unfrozen modelation has no loadable results.',
    localizedMessage: 'Nezmrazená modelace nemá žádné výsledky k načtení.',
  },
  {
    message: 'The frozen modelation cannot be updated.',
    localizedMessage: 'Zmrazená modelace nemůže být aktualizována.',
  },
];

const portalFamilyMsg =
  'nelze vyhledat a načíst data o klientovi a jeho rodině, nelze je ani založit';
const dmsMsg = 'nefunguje ukládání příloh do úložiště KAPITOLu';
const eucsMsg = 'dotazník priorit je nedostupný';
const eRecordMsg = 'nefunguje odeslání záznamu/zápisu z jednání';
const kompasMsg = 'nelze vybrat partnera a produkt';
const portalMsg = 'nelze načíst data o poradci';
const smsGateMsg = 'nejde podepsat SMS, podepište vlastnoručně';

// http status 5xx
export const errorLocalizations: MessageLocalization[] = [
  {
    message: 'Portal is unavailable.',
    localizedMessage: unavailableMessage('Portal', portalFamilyMsg),
  },
  {
    message: 'Portal call caused an error.',
    localizedMessage: communicationErrorMessage('Portal', portalFamilyMsg, 'E01'),
  },
  {
    message: 'DMS is unavailable.',
    localizedMessage: unavailableMessage('DMS', dmsMsg),
  },
  {
    message: 'DMS call caused an error.',
    localizedMessage: communicationErrorMessage('DMS', dmsMsg, 'E02'),
  },
  {
    message: 'EUCS is unavailable.',
    localizedMessage: unavailableMessage('EUCS', eucsMsg),
  },
  {
    message: 'EUCS call caused an error.',
    localizedMessage: communicationErrorMessage('EUCS', eucsMsg, 'E03'),
  },
  {
    message: 'Ezaznam is unavailable.',
    localizedMessage: unavailableMessage('eZáznam', eRecordMsg),
  },
  {
    message: 'Ezaznam call caused an error.',
    localizedMessage: communicationErrorMessage('eZáznam', eRecordMsg, 'E04'),
  },
  {
    message: 'Kompas is unavailable.',
    localizedMessage: unavailableMessage('Kompas', kompasMsg),
  },
  {
    message: 'Kompas call caused an error.',
    localizedMessage: communicationErrorMessage('Kompas', kompasMsg, 'E05'),
  },
  {
    message: 'Portal is unavailable.',
    localizedMessage: unavailableMessage('Portál', portalMsg),
  },
  {
    message: 'Portal call caused an error.',
    localizedMessage: communicationErrorMessage('Portál', portalMsg, 'E06'),
  },
  {
    message: 'SMS Gate is unavailable.',
    localizedMessage: unavailableMessage('SMS brána', smsGateMsg),
  },
  {
    message: 'SMS Gate call caused an error.',
    localizedMessage: communicationErrorMessage('SMS brána', smsGateMsg, 'E07'),
  },
  {
    message: 'Portal is unavailable due to authentication problem.',
    localizedMessage: communicationErrorMessage('Portal', portalFamilyMsg, 'E08'),
  },
  {
    message: 'Portal returned unexpected data.',
    localizedMessage: communicationErrorMessage('Portal', portalFamilyMsg, 'E09'),
  },
  {
    message: 'Remote API is unavailable or its call caused an error.',
    localizedMessage: 'Externí API je nedostupné (E10). ' + contactSupport,
  },
  {
    message: 'The advisor does not exist in Portal.',
    localizedMessage: 'Poradce neexistuje v Portal (E11). ' + contactSupport,
  },
  {
    message: 'The family has no head and it contains no adults, so the head cannot be chosen.',
    localizedMessage: communicationErrorMessage('Portal', portalFamilyMsg, 'E12'),
  },
];

function unavailableMessage(serviceName: string, description: string): string {
  return `Služba ${serviceName} je nedostupná – ${description}. ${contactSupportAndRepeat}`;
}

function communicationErrorMessage(
  serviceName: string,
  description: string,
  errorCode?: string,
): string {
  const errorCodeStr = errorCode ? ` (${errorCode})` : '';
  return `Při komunikaci se službou ${serviceName} nastala chyba${errorCodeStr} – ${description}. ${contactSupport}`;
}
