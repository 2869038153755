import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {GuardModalInterface} from '../models/guard.model';
import {GuardService} from '../services/guard.service';

@Component({
  selector: 'kpt-guard',
  styleUrls: ['./guard.component.scss'],
  templateUrl: './guard.component.html',
})
export class GuardComponent {
  modalData$: Observable<GuardModalInterface>;

  constructor(
    private guardService: GuardService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.modalData$ = this.guardService.getModalData$();
  }

  closeModal() {
    this.guardService.setModalData(null);
  }

  proceedAnyway() {
    // add "force" queryParameter to bypass the guard
    this.router.navigate([this.guardService.getTargetUrl()], {
      relativeTo: this.route,
      queryParams: {
        force: true,
      },
    });
    this.guardService.setModalData(null);
    this.guardService.setTargetUrl('');
  }
}
