import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IntroJsComponent} from './intro-js.component';

@NgModule({
  declarations: [IntroJsComponent],
  exports: [IntroJsComponent],
  imports: [CommonModule],
})
export class IntroJsModule {}
