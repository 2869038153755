import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IOption} from '@shared/ui/forms/models/option.model';
import {cloneDeep} from 'lodash';
import {IPagination} from './models/pagination.model';
// own models
import {defaultPerPageConfig} from './models/per-page.config';

// (zh) not used anywhere in app
@Component({
  selector: 'kpt-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() allItems: number;
  @Input() hasOptions = false;
  @Input() label: string;
  @Input() optionsLabel?: string;
  @Input() pageSize?: number;
  @Output() perPageChange: EventEmitter<any> = new EventEmitter();

  defaultPage = 1;

  pagination: IPagination;

  perPageOptions?: IOption[];

  prevDots = false;

  nextDots = false;

  constructor() {}

  ngOnInit() {
    if (this.hasOptions) {
      this.perPageOptions = cloneDeep(defaultPerPageConfig);
      this.pageSize = defaultPerPageConfig[0].key as number;
    }
    this.setPage(this.defaultPage);
  }

  changePerPage(value: number) {
    this.pageSize = value;
    this.perPageChange.emit(value);
    this.setPage(this.defaultPage);
  }

  setPage(page: number) {
    this.pagination = this.getPagination(this.allItems, page, this.pageSize);
  }

  setPages = (startPage: number, endPage: number) =>
    Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

  getPagination(totalItems: number, activePage: number, pageSize: number) {
    const totalPages = Math.ceil(totalItems / pageSize);
    const isLarger = totalPages > 9;
    let pages = [];

    if (!isLarger) {
      pages = this.setPages(1, totalPages);
      this.prevDots = false;
      this.nextDots = false;
    } else if (isLarger && activePage < 6) {
      pages = this.setPages(1, 7);
      this.prevDots = false;
      this.nextDots = true;
    } else if (isLarger && activePage > 5 && activePage > totalPages - 5) {
      pages = this.setPages(totalPages - 6, totalPages);
      this.prevDots = true;
      this.nextDots = false;
    } else {
      pages = this.setPages(activePage - 2, activePage + 2);
      this.prevDots = true;
      this.nextDots = true;
    }

    return {
      activePage,
      pageSize,
      totalPages,
      pages,
    };
  }
}
