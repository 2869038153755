/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {NgModule} from '@angular/core';
import {EffectsModule as NgrxEffectsModule} from '@ngrx/effects';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';

import {AnalysisService} from '../../../controllers/Analysis';
import {FormsSharedModule} from '../../forms-shared.module';
import {CreateUpdateRisksFormService} from './createUpdateRisks.service';

import {CreateUpdateRisksEffects} from './states/effects';
import {CreateUpdateRisksReducer} from './states/reducers';
import {selectorName} from './states/reducers';

@NgModule({
  imports: [
    FormsSharedModule,
    NgrxStoreModule.forFeature(selectorName, CreateUpdateRisksReducer),
    NgrxEffectsModule.forFeature([CreateUpdateRisksEffects]),
  ],
  providers: [
    AnalysisService,
    CreateUpdateRisksFormService,
  ],
})
export class CreateUpdateRisksModule {}
