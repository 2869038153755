import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {RouterModule} from '@angular/router';
import {AssetLinkService} from '@shared/lib/components/asset-link/asset-link.service';
import {AssetLinkComponent} from './asset-link.component';

@NgModule({
  declarations: [AssetLinkComponent],
  imports: [CommonModule, RouterModule],
  exports: [AssetLinkComponent],
  providers: [AssetLinkService],
})
export class AssetLinkModule {}
