import {
  CoreContractsAction,
  CoreContractsActions,
} from '@shared/analysis/store/core-contracts.actions';
import {
  CoreContractsState,
  initialCoreContractsState,
} from '@shared/analysis/store/core-contracts.state';
import produce from 'immer';

const producer = (state: CoreContractsState, action: CoreContractsAction) =>
  CoreContractsActions.match(action, {
    FA_SetCoreContracts: payload => {
      state.coreContracts = payload.coreContracts;
    },

    FA_SaveCoreContract: payload => {
      const index = state.coreContracts.findIndex(c => c.id === payload.coreContract.id);
      if (index === -1) {
        state.coreContracts.push(payload.coreContract);
      } else {
        state.coreContracts[index] = payload.coreContract;
      }
    },

    FA_DeleteCoreContract: payload => {
      state.coreContracts = state.coreContracts.filter(c => c.id !== payload.id);
    },

    default: () => {},
  });

export function reducer(
  state = initialCoreContractsState,
  action: CoreContractsAction,
): CoreContractsState {
  return produce(producer)(state, action);
}
