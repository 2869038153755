import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {IndependenceInputMode} from '@shared/analysis/forms/definitions.models';
import {FinancialIndependenceAsset, ObjectivesAsset} from '@shared/analysis/models/objectives';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
} from '@shared/analysis/store';
import * as moment from 'moment';
import {introTexts} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  getChartData,
  getTable,
  selectObjectiveAssetsByObjectiveType,
} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  FinancialIndependenceObjective,
  IndependenceInput,
} from 'src/app/modules/financial-plan/store/objectives.models';
import {getFamilyHead} from 'src/store/selectors/family-member.selectors';

export const selectFinancialIndependenceObjectives = createSelector(
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectObjectiveAssetsByObjectiveType(ObjectiveType.Independence),
  getFamilyHead,
  (
    currentAssets,
    advisorProposedAssets,
    clientProposedAssets,
    independenceObjectiveAssets,
    familyHead,
  ): FinancialIndependenceObjective[] => {
    return independenceObjectiveAssets.map(
      (objectiveAsset: FinancialIndependenceAsset): FinancialIndependenceObjective => {
        const input = getIndependenceInput(objectiveAsset, familyHead);
        const objectiveValue = input?.reserve ?? 0;
        const currentTable = getTable(
          Situation.Current,
          currentAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const advisorProposedTable = getTable(
          Situation.AdvisorProposed,
          advisorProposedAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const clientProposedTable = getTable(
          Situation.ClientProposed,
          clientProposedAssets,
          objectiveAsset.assetUuid,
          objectiveValue,
          objectiveAsset.start,
        );
        const chart = getChartData(currentTable, advisorProposedTable, objectiveValue);

        return {
          type: ObjectiveType.Independence,
          objectiveAsset,
          objectiveValue,
          name: getPensionObjectiveName(objectiveAsset),
          introText: introTexts[ObjectiveType.Independence],
          input,
          chart,
          table: {
            current: currentTable,
            advisorProposed: advisorProposedTable,
            clientProposed: clientProposedTable,
          },
          advisorProposedBenefit: advisorProposedTable.sum.finalValue - currentTable.sum.finalValue,
          clientProposedBenefit: clientProposedTable.sum.finalValue - currentTable.sum.finalValue,
        };
      },
    );
  },
);

function getPensionObjectiveName(objectiveAsset: ObjectivesAsset): string {
  return objectiveAsset.name ? objectiveAsset.name : 'Finanční nezávislost';
}

function getIndependenceInput(
  independenceAsset: FinancialIndependenceAsset,
  familyHead: FamilyMember,
): IndependenceInput | null {
  const inputMode = independenceAsset.independenceInputMode;

  const independenceStartYear = moment(independenceAsset.start).year();
  const currentYear = moment().year();
  const reserveYear = independenceStartYear - currentYear;

  const independenceStartAge = moment(independenceAsset.start).diff(familyHead.birthDate, 'years');

  const independenceDuration = Math.max(independenceAsset.lifeExpectancy - independenceStartAge, 0);
  if (
    inputMode === IndependenceInputMode.Rent &&
    (!independenceAsset.lifeExpectancy || independenceDuration === 0)
  ) {
    return null;
  }

  const monthlyValue = getMonthlyValue(independenceAsset, independenceDuration);

  const requestedReserve = getReserveValue(independenceAsset, independenceDuration);

  return {
    inputMode,
    reserve: requestedReserve,
    monthlyValue,
    reserveYear,
    lifeExpectancy: independenceAsset.lifeExpectancy,
    independenceDuration,
    independenceStartAge,
    independenceStartYear,
  };
}

function getReserveValue(
  independenceAsset: FinancialIndependenceAsset,
  independenceDuration: number,
): number {
  return independenceAsset.independenceInputMode === IndependenceInputMode.Value
    ? independenceAsset.value
    : independenceAsset.monthlyRent * 12 * independenceDuration;
}

function getMonthlyValue(
  independenceAsset: FinancialIndependenceAsset,
  independenceDuration: number,
): number {
  return independenceAsset.independenceInputMode === IndependenceInputMode.Value
    ? independenceAsset.value / independenceDuration / 12
    : independenceAsset.monthlyRent;
}
