import {
  AdvisorProposalState,
  Asset,
  ClientProposalState,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
} from '@shared/analysis/models/asset';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {RelationType} from '@shared/analysis/models/relations';
import produce from 'immer';
import {cloneDeep} from 'lodash';
import {v4 as uuid} from 'uuid';
import {FinancialAnalysisAction, FinancialAnalysisActions} from './financial-analysis.actions';
import {FinancialAnalysisState, initialFinancialAnalysisState} from './financial-analysis.state';

type RelatedAsset = RelatedObjectiveAsset & RelatedPropertyAsset & StakeholderAsset;

const producer = (state: FinancialAnalysisState, action: FinancialAnalysisAction) =>
  FinancialAnalysisActions.match(action, {
    FA_SetData: data => {
      state.assets = data.assets;
    },

    FA_AddAsset: asset => {
      const newAsset = cloneDeep(asset);
      if (!newAsset.assetUuid) newAsset.assetUuid = uuid();
      state.assets.push(newAsset);
      updateRelations(state, newAsset);
    },

    FA_UpdateAsset: asset => {
      const index = state.assets.findIndex(a => a.assetUuid === asset.assetUuid);
      if (index === -1) return;

      asset = cloneDeep(asset);
      state.assets[index] = asset;

      updateRelations(state, asset);
    },

    FA_SaveAsset: asset => {
      asset = cloneDeep(asset);
      const index = state.assets.findIndex(a => a.assetUuid === asset.assetUuid);
      if (index === -1) {
        state.assets.push(asset);
      } else {
        state.assets[index] = asset;
      }

      updateRelations(state, asset);
    },

    FA_RemoveAsset: payload => {
      removeAsset(state, payload.assetUuid);
    },

    FA_SetCurrentlyEditedAsset: asset => {
      state.currentlyEditedAsset = asset;
    },

    FA_AddObjectiveRelation: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      (asset as RelatedObjectiveAsset).relatedObjectiveUuid = payload.relatedObjectiveUuid;
      updateRelation(state, asset, 'relatedObjectiveUuid');
    },

    FA_AddPropertyRelation: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      (asset as RelatedPropertyAsset).relatedPropertyUuid = payload.relatedPropertyUuid;
      updateRelation(state, asset, 'relatedPropertyUuid');
    },

    FA_AddStakeholderRelation: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      (asset as StakeholderAsset).stakeholderUuid = payload.stakeholderUuid;
      updateRelation(state, asset, 'stakeholderUuid');
    },

    FA_RemoveObjectiveRelation: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      (asset as RelatedObjectiveAsset).relatedObjectiveUuid = null;
      updateRelation(state, asset, 'relatedObjectiveUuid');
    },

    FA_RemovePropertyRelation: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      (asset as RelatedPropertyAsset).relatedPropertyUuid = null;
      updateRelation(state, asset, 'relatedPropertyUuid');
    },

    FA_RemoveProposedUnchangedAssets: () => {
      const proposedUnchangedAssets = state.assets.filter(
        asset => asset.proposal && asset.advisorProposalState === AdvisorProposalState.Unchanged,
      );
      for (const proposedAsset of proposedUnchangedAssets) {
        const currentAsset = state.assets.find(
          asset => asset.assetUuid === proposedAsset.originalAssetUuid,
        );
        if (currentAsset) currentAsset.advisorProposalState = AdvisorProposalState.Unchanged;
        state.assets = state.assets.filter(asset => asset.assetUuid !== proposedAsset.assetUuid);
      }
    },

    FA_RemoveProposedAssets: () => {
      state.assets = state.assets.filter(asset => !asset.proposal);
    },

    FA_ProposeCurrentAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.advisorProposalState = AdvisorProposalState.Unchanged;
      asset.clientProposalState = null;

      // Remove advisor and client proposed assets if exist
      removeProposedAssets(state, payload.assetUuid);
    },

    FA_ClearProposalStateBeforeUpdate: payload => {
      const originalAsset = state.assets.find(a => a.assetUuid === payload.originalAssetUuid);
      if (payload.clientProposal) {
        originalAsset.clientProposalState = null;
        // clear also clientProposalState on the current asset if exists
        const currentAsset = state.assets.find(
          a => a.assetUuid === originalAsset.originalAssetUuid,
        );
        if (currentAsset) {
          currentAsset.clientProposalState = null;
        }
      } else {
        originalAsset.advisorProposalState = null;
        originalAsset.clientProposalState = null;

        // Remove client proposed assets
        removeProposedAssets(state, payload.originalAssetUuid);
      }
    },

    FA_TerminateAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.advisorProposalState = AdvisorProposalState.Terminated;
      asset.clientProposalState = null;

      // Remove advisor and client proposed assets if exist
      removeProposedAssets(state, payload.assetUuid);
    },

    FA_AcceptNewAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.NewClientApproved;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_RejectNewAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.NewClientRejected;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_AcceptUnchangedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.Unchanged;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_TerminateUnchangedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.ClientTerminated;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_AcceptUpdatedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.AdvisorUpdated;

      const currentAsset = state.assets.find(a => a.assetUuid === asset.originalAssetUuid);
      if (currentAsset) currentAsset.clientProposalState = null;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_RejectUpdatedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = null;

      const currentAsset = state.assets.find(a => a.assetUuid === asset.originalAssetUuid);
      if (currentAsset) currentAsset.clientProposalState = ClientProposalState.Unchanged;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_TerminateUpdatedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = null;

      const currentAsset = state.assets.find(a => a.assetUuid === asset.originalAssetUuid);
      if (currentAsset) currentAsset.clientProposalState = ClientProposalState.ClientTerminated;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_AcceptTerminatedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.AdvisorTerminated;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_RejectTerminatedAsset: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.clientProposalState = ClientProposalState.Unchanged;

      // Remove client proposed asset if exists
      state.assets = state.assets.filter(a => a.originalAssetUuid !== payload.assetUuid);
    },

    FA_ExcludeAssetFromAnalysis: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.excludedFromAnalysis = true;
    },

    FA_IncludeAssetInAnalysis: payload => {
      const asset = state.assets.find(a => a.assetUuid === payload.assetUuid);
      asset.excludedFromAnalysis = false;
    },

    default: () => {},
  });

export function reducer(
  state = initialFinancialAnalysisState,
  action: FinancialAnalysisAction,
): FinancialAnalysisState {
  return produce(producer)(state, action);
}

function updateRelations(state: FinancialAnalysisState, newAsset: Asset) {
  updateRelation(state, newAsset, 'relatedObjectiveUuid');
  updateRelation(state, newAsset, 'relatedPropertyUuid');
  updateRelation(state, newAsset, 'stakeholderUuid');
}

function updateRelation(
  state: FinancialAnalysisState,
  newAsset: Asset,
  relationType: RelationType,
) {
  updateRelationInOriginalAssets(state, newAsset, relationType, 2);
  updateRelationInProposedAssets(state, newAsset, relationType, 2);
}

function updateRelationInOriginalAssets(
  state: FinancialAnalysisState,
  updatedAsset: Asset,
  relationType: RelationType,
  level: number,
) {
  if (level === 0) return; // prevent looping in case of bad data

  const originalAssets = state.assets.filter(a => a.assetUuid === updatedAsset.originalAssetUuid);
  originalAssets.forEach(originalAsset => {
    (originalAsset as RelatedAsset)[relationType] = (updatedAsset as RelatedAsset)[relationType];
    updateRelationInOriginalAssets(state, originalAsset, relationType, level - 1);
  });
}

function updateRelationInProposedAssets(
  state: FinancialAnalysisState,
  updatedAsset: Asset,
  relationType: RelationType,
  level: number,
) {
  if (level === 0) return; // prevent looping in case of bad data

  const proposedAssets = state.assets.filter(a => a.originalAssetUuid === updatedAsset.assetUuid);
  proposedAssets.forEach(proposedAsset => {
    (proposedAsset as RelatedAsset)[relationType] = (updatedAsset as RelatedAsset)[relationType];
    updateRelationInProposedAssets(state, proposedAsset, relationType, level - 1);
  });
}

function removeAsset(state: FinancialAnalysisState, assetUuid: string) {
  state.assets = state.assets.filter(asset => asset.assetUuid !== assetUuid);
  // remove (advisor or client) proposed assets
  const proposedAssets = state.assets.filter(a => a.originalAssetUuid === assetUuid);
  for (const proposedAsset of proposedAssets) {
    // when removing the current asset remove also client proposed assets
    removeAsset(state, proposedAsset.assetUuid);
  }
  // unlink related assets (eg. when removing an objective asset or a property asset)
  unlinkRelatedAssets(state, assetUuid);
}

function unlinkRelatedAssets(state: FinancialAnalysisState, originalAssetUuid: string) {
  state.assets
    .filter(a => (a as RelatedObjectiveAsset).relatedObjectiveUuid === originalAssetUuid)
    .forEach(a => ((a as RelatedObjectiveAsset).relatedObjectiveUuid = null));
  state.assets
    .filter(a => (a as RelatedPropertyAsset).relatedPropertyUuid === originalAssetUuid)
    .forEach(a => ((a as RelatedPropertyAsset).relatedPropertyUuid = null));
}

function removeProposedAssets(state: FinancialAnalysisState, currentAssetUuid: string) {
  const advisorProposedAssets = state.assets.filter(a => a.originalAssetUuid === currentAssetUuid);
  for (const advisorProposedAsset of advisorProposedAssets) {
    // Remove client proposed assets
    state.assets = state.assets.filter(a => a.originalAssetUuid !== advisorProposedAsset.assetUuid);
    // Remove advisor proposed asset
    state.assets = state.assets.filter(a => a.assetUuid !== advisorProposedAsset.assetUuid);
  }
}
