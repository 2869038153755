import {Pipe, PipeTransform} from '@angular/core';
import {consultantLevels} from './consultant-levels';

@Pipe({
  name: 'consultantLevel',
})
export class ConsultantLevelPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return consultantLevels[value] || null;
    }
    return null;
  }
}
