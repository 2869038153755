import {fromEvent} from 'rxjs';
import {debounceTime, filter, map, pairwise} from 'rxjs/operators';

const isUserScrollingDown = (positions: number[]) => positions[0] < positions[1];

const isUserAtTheBottomLimit = (pixelsFromBottom: number) => (positions: number[]) =>
  positions[1] + pixelsFromBottom >= document.documentElement.offsetHeight;

export const infiniteScrollObservable = (pixelsFromBottom = 0, debounce = 300) =>
  fromEvent(document, 'scroll').pipe(
    debounceTime(debounce),
    map(() => document.documentElement.scrollTop + window.innerHeight),
    pairwise(),
    filter(isUserScrollingDown),
    filter(isUserAtTheBottomLimit(pixelsFromBottom)),
  );
