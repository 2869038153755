import {AssetType} from '@shared/analysis/models/asset';
import {ObjectiveAsset} from '@shared/analysis/models/objectives';
import {ObjectiveSource, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {GraphInput} from '@shared/ui/graph/graph.component';
import {Table} from 'src/app/modules/financial-plan/store';

export interface ObjectivePage<T, D> {
  relatedAssets?: T;
  data$: D;
  items?: ObjectiveItem[];
}

export interface ObjectiveItem {
  type: ObjectiveType;
  source: ObjectiveSource;
  objectiveAssetUuid?: string;
  familyMemberUuid?: string;
  propertyAssetUuid?: string;
  data: ObjectiveItemData;
}

export interface ObjectiveItemData {
  introText: string;
  current: ObjectiveItemSetup;
  proposed: ObjectiveItemSetup;
}

export interface ObjectiveItemSetup {
  rating: number;
}

export interface PropertyProtectionPage {
  insuranceAssets: AssetType[];
}

export enum Situation {
  Current = 'current',
  AdvisorProposed = 'advisorProposed',
  ClientProposed = 'clientProposed',
}

export type PageObjectiveItem = ObjectiveItem & {
  objectiveAssetUuid: string;
  objectiveAsset: ObjectiveAsset;
  introText: string;
  objectiveName: string;
  objectiveValue: number;
  assetType: AssetType;
  objectiveStart: string;
  chart: GraphInput;
  benefitValue: number;
  hideOnPrint: boolean;
  data: {
    current: Table;
    proposed: Table;
  };
};

export enum BusinessCaseStatus {
  FinancialAnalysisPreparation = 'financialAnalysisPreparation',
  FinancialPlanPreparation = 'financialPlanPreparation',
  FinancialPlanPresentation = 'financialPlanPresentation',
  FinancialPlanFinalisation = 'financialPlanFinalisation',
  FinancialPlanTermination = 'financialPlanTermination',
}

export enum BusinessCaseMode {
  Design = 'design',
  Presentation = 'presentation',
}
