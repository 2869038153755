import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform = (value: string): string => {
    if (!value || value.length <= 4) {
      return;
    } else {
      return value.replace(/(\D{1}\d{3})(\d{3})(\d{3})(\d{3})/, '$1\xa0$2\xa0$3\xa0$4');
    }
  };
}
