<kpt-radio-group
  *ngIf="type === FamilyMembersInputType.Radio"
  [required]="required"
  [disabledField]="disabled"
  itemClass="d-inline-block mr-6"
  [options]="options$ | async"
  [label]="label"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit($event)"
>
</kpt-radio-group>

<div *ngIf="type === FamilyMembersInputType.Select">
  family-members for select not implemented yet
</div>

<div *ngIf="type === FamilyMembersInputType.Checkbox">
  family-members for checkbox not implemented yet
</div>
