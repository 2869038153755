import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PopUpBlockedModalComponent} from '@shared/lib/components/modal/modals';
import {AttachmentsModalComponent} from '@shared/lib/components/modal/modals/attachments-modal/attachments-modal.component';
import {CancelMeetingModalComponent} from '@shared/lib/components/modal/modals/cancel-meeting-modal/cancel-meeting-modal.component';
import {ClientRequirementsModalComponent} from '@shared/lib/components/modal/modals/client-requirements-modal/client-requirements-modal.component';
import {FamiliesModalComponent} from '@shared/lib/components/modal/modals/families-modal/families-modal.component';
import {ProposalsAlternativesComponent} from '@shared/lib/components/modal/modals/proposals-alternatives/proposals-alternatives.component';
import {ProposalsProductComponent} from '@shared/lib/components/modal/modals/proposals-product/proposals-product.component';
import {ReassignComponent} from '@shared/lib/components/modal/modals/reassign/reassign.component';
import {ModalDirective} from './directives/modal.directive';
import {IModal} from './models/modal.model';
import {ModalService} from './services/modal.service';

@UntilDestroy()
@Component({
  selector: 'kpt-modal-dynamic',
  templateUrl: './modal-container.component.html',
})
export class ModalContainerComponent implements AfterContentInit, OnDestroy {
  @ViewChild(ModalDirective, {static: true})
  createModal: ModalDirective;
  isModalOpened = false;
  component: ComponentRef<any>;

  private modalComponents: {[key: string]: any} = {
    FamiliesModalComponent,
    ClientRequirementsModalComponent,
    ProposalsAlternativesComponent,
    ReassignComponent,
    ProposalsProductComponent,
    CancelMeetingModalComponent,
    AttachmentsModalComponent,
    PopUpBlockedModalComponent,
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public modalService: ModalService,
  ) {}

  ngAfterContentInit() {
    this.modalService.activeModal$.pipe(untilDestroyed(this)).subscribe(modal => {
      if (!!modal && modal.component) {
        this.isModalOpened = true;
        this.createModalComponent(modal);
      } else {
        this.isModalOpened = false;
        this.destroyComponent();
      }
    });
  }

  destroyComponent() {
    if (this.component) {
      this.component.destroy();
    }
    this.component = null;
  }

  closeModal() {
    this.modalService.closeModal();
  }

  ngOnDestroy(): void {}

  private createModalComponent = (modal: IModal): void => {
    if (this.component) {
      this.component.destroy();
    }

    if (!this.createModal) {
      return;
    }

    const componentToLoad = this.modalComponents[modal.component];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);
    const modalElmRef = this.createModal.viewContainerRef;
    modalElmRef.clear();
    this.component = modalElmRef.createComponent(componentFactory);

    if (modal.title) this.component.instance.title = modal.title;
    if (modal.data) this.component.instance.data = modal.data;

    this.isModalOpened = true;
    this.component.changeDetectorRef.detectChanges();
  };
}
