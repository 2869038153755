<div class="row" *ngIf="loaded">
  <div class="col-12 mt-5" *ngFor="let person of familyMembers">
    <h3>Zajištění příjmů &ndash; {{ person.name }}</h3>
    <kpt-risks-selection
      [readonly]="true"
      [showWarnings]="false"
      [formGroup]="form.get('membersRisks')"
      [person]="person"
    ></kpt-risks-selection>
    <kpt-input
      [readonly]="true"
      [formControl]="form.get('note')"
      label="Poznámka"
      type="textarea"
    ></kpt-input>
    <kpt-input
      [readonly]="true"
      [formControl]="form.get('currentState')"
      label="Stávající stav"
      type="textarea"
    ></kpt-input>
  </div>
</div>
