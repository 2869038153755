import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer} from '@shared/analysis/store/core-contracts.reducer';
import {storeFeatureName} from '@shared/analysis/store/core-contracts.selectors';

@NgModule({
  imports: [StoreModule.forFeature(storeFeatureName, reducer)],
  providers: [],
})
export class CoreContractsModule {}
