/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {NgModule} from '@angular/core';
import {EffectsModule as NgrxEffectsModule} from '@ngrx/effects';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';

import {ConsumerService} from '../../../controllers/Consumer';
import {FormsSharedModule} from '../../forms-shared.module';
import {ConsumerFormService} from './consumer.service';

import {ConsumerEffects} from './states/effects';
import {ConsumerReducer} from './states/reducers';
import {selectorName} from './states/reducers';

@NgModule({
  imports: [
    FormsSharedModule,
    NgrxStoreModule.forFeature(selectorName, ConsumerReducer),
    NgrxEffectsModule.forFeature([ConsumerEffects]),
  ],
  providers: [
    ConsumerService,
    ConsumerFormService,
  ],
})
export class ConsumerModule {}
