<div
  class="custom-tooltip"
  [class.custom-tooltip--open]="isOpen"
  [ngClass]="[
    direction ? 'custom-tooltip--' + direction : '',
    size ? 'custom-tooltip--' + size : ''
  ]"
  (kptOffClick)="isOpen = false"
  [offClickEnabled]="isOpen"
>
  <ng-container
    *ngTemplateOutlet="actionTemplate || defaultActionTemplate; context: {toggle: toggle}"
  ></ng-container>
  <div
    class="custom-tooltip__content"
    [ngClass]="customClass ? customClass : ''"
    [style.width]="+width === 0 ? 'auto' : width"
    [style.textAlign]="textAlign"
  >
    <ng-content></ng-content>
  </div>
</div>

<ng-template #defaultActionTemplate let-toggle="toggle">
  <ng-container *ngIf="!customToggle">
    <div
      class="custom-tooltip__toggle"
      (click)="!hover && toggle($event)"
      (mouseover)="hover && toggle($event)"
      (mouseout)="hover && toggle($event)"
    >
      <mat-icon *ngIf="matIcon" class="icon tooltip__toggle-icon" [svgIcon]="matIcon"></mat-icon>
      <span
        *ngIf="!matIcon"
        class="icon tooltip__toggle-icon"
        [ngClass]="[icon ? 'icon--' + icon : '']"
      ></span>
    </div>
  </ng-container>
  <ng-container *ngIf="customToggle">
    <div
      class="customToggle"
      (click)="!hover && toggle($event)"
      (mouseover)="hover && toggle($event)"
      (mouseout)="hover && toggle($event)"
    >
      <ng-content select="[toggle]"></ng-content>
    </div>
  </ng-container>
</ng-template>
