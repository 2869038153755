import {Component, HostBinding, Input} from '@angular/core';

export type TipVariant = 'default' | 'alt';

@Component({
  selector: 'kpt-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
})
export class TipComponent {
  @Input() variant: TipVariant = 'default';

  @HostBinding('class') get className() {
    return `tip tip--${this.variant}`;
  }
}
