<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title text-center" [innerHTML]="modalTitle"></h3>
    </div>
  </div>
</div>
<div class="modal-body">
  Pro Kappku doporučujeme povolit otevírání nových oken. PDF znovu otevřete tlačítkem níže.
  <div class="text-center mt-6">
    <kpt-button label="Otevřít PDF" type="primary" (action)="openUrl()"> </kpt-button>
  </div>
</div>
