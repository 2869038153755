import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {InputModule} from '../../forms/input/input.module';
import {SliderModule} from '../../slider';
import {SliderInputFormlyComponent} from './slider-input-formly.component';

@NgModule({
  declarations: [SliderInputFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    SliderModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
  ],
  exports: [SliderInputFormlyComponent],
})
export class SliderInputFormlyModule {}
