import {Component, Input, TemplateRef} from '@angular/core';

// Own models
import {IBreadcrumbItems} from './models/breadcrumb.model';

@Component({
  selector: 'kpt-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumbItems: IBreadcrumbItems;
  @Input() dropdownContent?: TemplateRef<any>;
}
