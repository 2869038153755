import {Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'kpt-switch-formly',
  templateUrl: './switch-formly.component.html',
})
export class SwitchFormlyComponent extends FieldType {
  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.change) {
      this.to.change(field, $event);
    }
  }
}
