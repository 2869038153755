import {ITableColumnConfig} from '@shared/ui';
import * as moment from 'moment';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {MenuItem} from 'src/shared/lib';

export function getConsultantMenuItems(): MenuItem[] {
  const cards: ModuleCard[] = [
    getConsultantCard(),
    ...getPerformanceMenuCards(),
    getOnlineTableCard(),
    getMoneyGuideCard(),
  ];

  return [
    {
      target: '/secured/consultant',
      label: 'Představení',
      items: removeIconsFromCards(cards),
      toggledAtInit: true,
    },
  ];
}

function removeIconsFromCards(cards: ModuleCard[]): ModuleCard[] {
  return cards.map(card => ({...card, icon: ''}));
}

export function getClientTableCols(): ITableColumnConfig[] {
  return [
    {
      label: 'Klient',
      views: [
        {
          content: row =>
            !!row.firstName ? `${row.firstName} ${row.lastName}` : `${row.lastName}`,
        },
      ],
    },
    {
      label: 'Telefon',
      views: [
        {
          cellClass: ['text-nowrap'],
          content: row => (!!row.phoneNumber ? `${row.phoneNumber}` : '-'),
        },
      ],
    },
    {
      label: 'E-mail',
      views: [
        {
          content: row => (!!row.email ? `${row.email}` : '-'),
        },
      ],
    },
    {
      label: 'Datum narození',
      views: [
        {
          content: row =>
            !!row.birthDate
              ? moment(row.birthDate).format('DD. MM. YYYY').replace(/ /g, '&nbsp;')
              : '-',
        },
      ],
    },
    {
      label: 'Adresa',
      views: [
        {
          content: row =>
            !!row.street && !!row.city
              ? `${row.street}, ${row.city}`
              : !!row.street && !row.city
              ? `${row.street}`
              : !row.street && !!row.city
              ? `${row.city}`
              : '-',
        },
      ],
    },
    {
      label: 'Pečovatel',
      views: [
        {
          contentTemplateName: 'advisor',
        },
      ],
    },
  ];
}

export function getConsultantCard(): ModuleCard {
  return {
    target: '/secured/about-consultant',
    type: 'secondary',
    isEditable: false,
    icon: '',
    label: 'Představení poradce',
  };
}

export function getPerformanceMenuCards(): ModuleCard[] {
  return [
    {
      target: '/secured/about-kapitol',
      type: 'secondary',
      isEditable: false,
      icon: '',
      label: 'Představení KAPITOLu',
      imagePath: './assets/images/logos/logo-kapitol.svg',
    },
    {
      target: '/assets/files/financni-pruvodce.pdf',
      type: 'secondary',
      isEditable: false,
      icon: '',
      label: 'Finanční průvodce',
      imagePath: './assets/images/homepage/financial-guide.svg',
    },
    {
      target: '/assets/files/jak-tvorime-financni-plan.pdf',
      type: 'secondary',
      isEditable: false,
      icon: '',
      label: 'Jak tvoříme fin. plán',
      imagePath: './assets/images/homepage/creating-financial-plan.svg',
    },
    {
      target: '/assets/files/financni-plan.pdf',
      type: 'secondary',
      isEditable: false,
      icon: '',
      label: 'Ukázka fin. plánu',
      imagePath: './assets/images/homepage/equity.svg',
    },
  ];
}

export function getOnlineTableCard(): ModuleCard {
  return {
    target: '/assets/files/financni-pruvodce.pdf',
    type: 'secondary',
    isEditable: false,
    disabled: true,
    icon: 'whiteboard',
    label: 'Online tabule',
    header: 'Připravujeme',
  };
}

export function getMoneyGuideCard(): ModuleCard {
  return {
    target: '/secured/assets-and-liabilities/assets',
    type: 'secondary',
    isEditable: false,
    icon: 'handle-money',
    label: 'Jak zacházet s penězi',
    header: '\u00A0',
  };
}
