import {GetBusinessCaseResponse} from '@generated/defs/GetBusinessCaseResponse';

export enum LifeSituation {
  All = 'all',
  VehicleInsurance = 'vehicle-insurance',
  PropertyInsurance = 'property-insurance',
  Investment = 'investment',
  LifeInsurance = 'life-insurance',
  Pension = 'pension',
}

export interface BusinessCaseStoreState {
  loading: boolean;
  situation: LifeSituation;
  businessCases: GetBusinessCaseResponse[];
  finalizedBusinessCases: GetBusinessCaseResponse[];
}

export const initialBusinessCaseState: BusinessCaseStoreState = {
  loading: true,
  situation: null,
  businessCases: [],
  finalizedBusinessCases: [],
};
