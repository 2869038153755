import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from 'src/shared/ui/formly/formly.enums';
import {
  createNamedGroup,
  createTabs,
  getBasicField,
  getSelectField,
} from 'src/shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {getOptionsFromNames, leasingTypeNames} from '../models/assets.enums';
import {
  CommonCreditAssetFields,
  CommonFinancialProductAssetFields,
} from '../models/financial-products.helpers';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition, AssetFieldConfig, FinancialProductTab} from './definitions.models';
import {createProductTab} from './forms.helpers';

@Injectable()
export class CreditProducts {
  private commonFields: AssetFieldConfig<CommonFinancialProductAssetFields>; // TODO
  private commonCreditAsset: AssetFieldConfig<CommonCreditAssetFields>;

  constructor(private abstractAssets: AbstractAssetsDefinitions) {
    this.commonFields = this.abstractAssets.getCommonFinancialProductFields();
    this.commonCreditAsset = this.abstractAssets.commonCreditAsset();
  }

  mortgage(): AssetDefinition {
    const type = AssetType.Mortgage;

    const propertyLoanAssetFields = this.abstractAssets.propertyLoanAsset();

    const extraTabFields: FormlyFieldConfig[] = [propertyLoanAssetFields.insuredRisksByDebtors];

    return {
      name: assetNames[type],
      type,
      fields: [this.getPropertyLoanFields(type, undefined, extraTabFields)],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  buildingSavingsLoan(): AssetDefinition {
    const type = AssetType.BuildingSavingsLoan;
    return {
      name: assetNames[type],
      type,
      fields: [this.getPropertyLoanFields(type, FieldLabel.ORIGINAL_LOAN_VALUE)],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  leasing(): AssetDefinition {
    const type = AssetType.Leasing;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        row([
          col(this.abstractAssets.getRelatedPropertyField(type)),
          col(this.abstractAssets.getStakeholderField()),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(
              getSelectField(
                'leasingType',
                FieldLabel.LEASING_TYPE,
                getOptionsFromNames(leasingTypeNames),
              ),
            ),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  creditCard(): AssetDefinition {
    const type = AssetType.CreditCard;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        this.abstractAssets.getStakeholderField(),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(getBasicField(FormlyTypes.Currency, 'creditLimit', FieldLabel.CREDIT_LIMIT)),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  overdraft(): AssetDefinition {
    const type = AssetType.Overdraft;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        this.abstractAssets.getStakeholderField(),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(getBasicField(FormlyTypes.Currency, 'creditLimit', FieldLabel.CREDIT_LIMIT)),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  consumerLoan(): AssetDefinition {
    const type = AssetType.ConsumerLoan;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        row([
          col(this.abstractAssets.getRelatedPropertyField(type)),
          col(this.abstractAssets.getStakeholderField()),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(getBasicField(FormlyTypes.Text, 'originalBank', 'Původní banka')),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  otherIndividualLoan(): AssetDefinition {
    const type = AssetType.OtherIndividualLoan;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        row([
          col(this.abstractAssets.getRelatedPropertyField(type)),
          col(this.abstractAssets.getStakeholderField()),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(getBasicField(FormlyTypes.Text, 'purpose', 'Účel')),
          ]),
          row([
            col(getBasicField(FormlyTypes.Number, 'originalValue', FieldLabel.ORIGINAL_VALUE)),
            col(getBasicField(FormlyTypes.Checkbox, 'reinsurance', FieldLabel.REINSURANCE)),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  getAll() {
    return [
      this.mortgage(),
      this.buildingSavingsLoan(),
      this.leasing(),
      this.creditCard(),
      this.overdraft(),
      this.consumerLoan(),
      this.otherIndividualLoan(),
    ];
  }

  private getPropertyLoanFields(
    type: AssetType,
    originalValueLabel?: string,
    extraTabFields: FormlyFieldConfig[] = [],
  ) {
    const propertyLoanAssetFields = this.abstractAssets.propertyLoanAsset(originalValueLabel);
    return createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        createNamedGroup(
          null,
          this.abstractAssets.getProductGroup(type),
          this.abstractAssets.getCommonFinancialProductGroup('contractName'),
          this.commonFields.proposal,
          this.commonFields.advisorProposalState,
          this.commonFields.clientProposalState,
          this.commonFields.originalAssetUuid,

          row([
            col(propertyLoanAssetFields.originalValue),
            col(this.abstractAssets.getStakeholderField()),
          ]),
          row([
            col(this.abstractAssets.getRelatedObjectiveField(type)),
            col(this.abstractAssets.getRelatedPropertyField(type)),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),

        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(this.commonCreditAsset.outstandingValue),
            col(this.commonCreditAsset.outstandingValueDate),
          ]),
          row([
            col(this.commonCreditAsset.yearlyRate),
            col(propertyLoanAssetFields.nearestFixationDate),
          ]),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          'Detaily smlouvy',
          row([col(propertyLoanAssetFields.purpose), col(propertyLoanAssetFields.fixationInYears)]),
          row([
            col(propertyLoanAssetFields.originalBank),
            col(propertyLoanAssetFields.originalLtv),
          ]),
        ),
        createNamedGroup('Detaily nemovitosti', propertyLoanAssetFields.propertyEstimatedValue),
        createNamedGroup(
          'Pojištění',
          row([
            col(propertyLoanAssetFields.hasRealEstateInsurance),
            col(propertyLoanAssetFields.realEstateInsurance),
          ]),
        ),
        createNamedGroup(
          'Osoby',
          propertyLoanAssetFields.debtorUuids,
          propertyLoanAssetFields.otherDebtors,
        ),
        ...extraTabFields,
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );
  }
}
