import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {invert, isNil} from 'lodash';
import {filter, tap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'kpt-checkbox-formly',
  templateUrl: './checkbox-formly.component.html',
})
export class CheckboxFormlyComponent extends FieldType implements OnInit, OnDestroy {
  formControlSubstitute = new UntypedFormControl();
  disabledValueChange = false;
  optionsMapReversed: any;

  ngOnInit() {
    this.optionsMapReversed = this.to.optionsMap ? invert(this.to.optionsMap) : null;

    const initialValue = !isNil(this.field.defaultValue)
      ? this.field.defaultValue
      : this.formControl.value;
    if (this.optionsMapReversed) {
      this.formControlSubstitute.patchValue(this.optionsMapReversed[initialValue] !== 'false');
    } else {
      this.formControlSubstitute.patchValue(initialValue);
    }

    this.formControl.valueChanges
      .pipe(
        tap(() => (this.disabledValueChange = true)),
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.formControlSubstitute.patchValue(
          this.optionsMapReversed ? this.optionsMapReversed[res] !== 'false' : res,
        );
        this.disabledValueChange = false;
      });

    this.formControlSubstitute.valueChanges
      .pipe(
        filter(() => !this.disabledValueChange),
        untilDestroyed(this),
      )
      .subscribe((value: boolean) => {
        const val = value.toString();
        if (this.to.optionsMap) {
          this.formControl.patchValue(this.to.optionsMap[val]);
        } else this.formControl.patchValue(value);
      });
  }

  ngOnDestroy() {}

  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.change) {
      this.to.change(field, $event);
    }
  }
}
