import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';
import {InputComponent} from '@shared/ui/forms/input/input.component';
import {
  InputMaxValidatorDirective,
  InputMinValidatorDirective,
} from '@shared/ui/forms/input/input.directives';
import {IMaskModule} from 'angular-imask';

@NgModule({
  declarations: [InputComponent, InputMaxValidatorDirective, InputMinValidatorDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FieldErrorModule,
    FormsModule,
    IMaskModule,
    MatAutocompleteModule,
  ],
  exports: [InputComponent, InputMaxValidatorDirective, InputMinValidatorDirective],
})
export class InputModule {}
