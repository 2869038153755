<button
  #button
  [attr.type]="!!isSubmitButton ? 'submit' : 'button'"
  class="btn"
  [ngClass]="[
    type !== null && !hasOutline ? 'btn-' + type : '',
    hasOutline ? 'btn-outline-' + type : '',
    size ? 'btn-' + size : '',
    variant === 'dots' ? 'btn--raw' : '',
    customClass ? customClass : '',
    icon ? 'btn--icon' : '',
    isLoading ? 'btn--loading' : ''
  ]"
  [disabled]="isDisabled"
  (click)="click($event)"
>
  <span
    *ngIf="!!icon && !variant"
    class="icon btn__icon"
    [ngClass]="[
      icon ? icon : '',
      customIconClass ? customIconClass : '',
      iconPosition ? 'btn__icon--' + iconPosition : '',
      size === 'sm' ? 'icon--small' : ''
    ]"
  >
  </span>
  <span
    *ngIf="variant === 'dots'"
    class="icon btn__icon btn__icon--alone"
    [ngClass]="[
      dotsDirection ? 'icon--dots-' + dotsDirection : 'icon--dots-vertical',
      customIconClass ? customIconClass : ''
    ]"
  >
  </span>
  <span *ngIf="label" class="btn__text">
    {{ label }}
  </span>
  <ng-content></ng-content>
</button>
