import {
  AdvisorProposalState,
  ClientProposalState,
  ContractState,
  RegularPaymentType,
} from '@shared/analysis/models/asset';
import {IOption} from 'src/shared/ui';
import {LeasingType, PropertyLoanPurpose} from './credit-products';
import {PropertyOwnershipType} from './immovable-properties';
import {
  LiabilityInsuranceRegion,
  LiabilityInsuranceRiskGroup,
  LiabilityInsuranceScope,
  ParticipationType,
} from './insurance-products';
import {InvestmentStrategy} from './investment-products';
import {OtherObjectivePaymentType} from './objectives';

export const frequencyNames: Record<RegularPaymentType, string> = {
  [RegularPaymentType.Month]: 'Měsíčně',
  [RegularPaymentType.Quarter]: 'Čtvrtletně',
  [RegularPaymentType.HalfYear]: 'Půlročně',
  [RegularPaymentType.Year]: 'Ročně',
};

export const regularPaymentTypeNames: Record<RegularPaymentType, string> = {
  [RegularPaymentType.Month]: 'měsíc',
  [RegularPaymentType.Quarter]: 'čtvrtletí',
  [RegularPaymentType.HalfYear]: 'půlrok',
  [RegularPaymentType.Year]: 'rok',
};

export const paymentDayNames: Record<RegularPaymentType, string> = {
  [RegularPaymentType.Month]: 'v měsíci',
  [RegularPaymentType.Quarter]: 've čtvrtletí',
  [RegularPaymentType.HalfYear]: 'v půlroce',
  [RegularPaymentType.Year]: 'v roce',
};

export const advisorProposalStateNames: Record<AdvisorProposalState, string> = {
  [AdvisorProposalState.New]: 'Nová',
  [AdvisorProposalState.Unchanged]: 'Beze změny',
  [AdvisorProposalState.Updated]: 'Aktualizovaná',
  [AdvisorProposalState.Terminated]: 'Ukončená',
};

export const contractStateNames: Record<ContractState, string> = {
  [ContractState.New]: 'Nové',
  [ContractState.Unchanged]: 'Beze změny',
  [ContractState.Updated]: 'Aktualizované',
  [ContractState.Terminated]: 'Ukončené',
};

export const clientProposalStateNames: Record<ClientProposalState, string> = {
  [ClientProposalState.NewClientApproved]: 'Nová schválená klientem',
  [ClientProposalState.NewClientUpdated]: 'Nová upravená klientem',
  [ClientProposalState.NewClientRejected]: 'Odmítnutý návrh klientem',
  [ClientProposalState.Unchanged]: 'Beze změny',
  [ClientProposalState.AdvisorUpdated]: 'Aktualizovaná poradcem',
  [ClientProposalState.ClientUpdated]: 'Aktualizovaná klientem',
  [ClientProposalState.AdvisorTerminated]: 'Ukončená poradcem',
  [ClientProposalState.ClientTerminated]: 'Ukončená klientem',
};

export const propertyLoanPurposeNames: Record<PropertyLoanPurpose, string> = {
  [PropertyLoanPurpose.PropertyPurchase]: 'Koupě nemovitosti',
  [PropertyLoanPurpose.Construction]: 'Výstavba',
  [PropertyLoanPurpose.Reconstruction]: 'Rekonstrukce',
  [PropertyLoanPurpose.Refund]: 'Refundace (zpětné proplacení již uhrazené investice)',
  [PropertyLoanPurpose.Consolidation]: 'Konsolidace',
  [PropertyLoanPurpose.Refinancing]: 'Refinancování',
  [PropertyLoanPurpose.InheritanceSettlement]: 'Vypořádání dědictví',
  [PropertyLoanPurpose.MembershipRightsTransfer]: 'Převod členských práv',
  [PropertyLoanPurpose.SharesSettlement]: 'Vypořádání podílů',
  [PropertyLoanPurpose.OtherPurpose]: 'Jiný účel',
};

export const leasingTypeNames: Record<LeasingType, string> = {
  [LeasingType.Business]: 'Fyzická osoba – podnikatel',
  [LeasingType.Private]: 'Fyzická osoba',
};

export const liabilityInsuranceRiskGroupNames: Record<LiabilityInsuranceRiskGroup, string> = {
  [LiabilityInsuranceRiskGroup.ProfessionalDriver]: 'Řidič z povolání',
  [LiabilityInsuranceRiskGroup.Other]: 'Ostatní',
};

export const liabilityInsuranceScopeNames: Record<LiabilityInsuranceScope, string> = {
  [LiabilityInsuranceScope.Driver]: 'Řidič',
  [LiabilityInsuranceScope.NonDriver]: 'Neřidič',
};

export const liabilityInsuranceRegionNames: Record<LiabilityInsuranceRegion, string> = {
  [LiabilityInsuranceRegion.CZ]: 'ČR',
  [LiabilityInsuranceRegion.Europe]: 'Evropa',
  [LiabilityInsuranceRegion.Global]: 'Celý svět',
};

export const investmentStrategyNames: Record<InvestmentStrategy, string> = {
  [InvestmentStrategy.Conservative]: 'Konzervativní',
  [InvestmentStrategy.Balanced]: 'Vyvážená',
  [InvestmentStrategy.Dynamic]: 'Dynamická',
};

export const otherObjectivePaymentTypeNames: Record<OtherObjectivePaymentType, string> = {
  [OtherObjectivePaymentType.OneTime]: 'Jednorázová',
  [OtherObjectivePaymentType.Month]: 'Měsíční',
};

export const propertyOwnershipTypeNames: Record<PropertyOwnershipType, string> = {
  [PropertyOwnershipType.Personal]: 'Osobní',
  [PropertyOwnershipType.Cooperative]: 'Družstevní',
  [PropertyOwnershipType.CommunityProperty]: 'Společné jmění manželů',
  [PropertyOwnershipType.Other]: 'Jiné',
};

export const participationTypeNames: Record<ParticipationType, string> = {
  [ParticipationType.Min]: 'Min.',
  [ParticipationType.Fix]: 'Fixní',
};

export const getOptionsFromNames = (namesObject: {[key: string]: string}): IOption[] => {
  return Object.keys(namesObject).map(key => {
    return {
      key,
      label: namesObject[key],
    };
  });
};
