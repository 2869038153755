import {Asset} from 'src/shared/analysis/models/asset';

export const initialFinancialAnalysisState: FinancialAnalysisState = {
  assets: [],
  currentlyEditedAsset: null,
};

export interface FinancialAnalysisState {
  assets: Asset[];
  currentlyEditedAsset: Asset;
}
