<div class="form-group" [attr.id]="id" [ngClass]="customFormGroupClass">
  <div class="custom-checkbox custom-control">
    <input
      #field
      type="checkbox"
      (blur)="blur.emit($event)"
      (change)="valueChange.emit(field.value)"
      class="custom-control-input"
      [class.custom-control-input--indeterminate]="indeterminate"
      [checked]="checked"
      [ngClass]="customFieldClass"
      [readonly]="readonly"
      [required]="required"
      [disabled]="disabled"
    />
    <label class="custom-control-label" (click)="labelClick()">
      <ng-template [ngTemplateOutlet]="labelTemplate || defaultLabelTemplateRef"></ng-template>
    </label>

    <ng-container *ngIf="appendTemplate">
      <ng-template [ngTemplateOutlet]="appendTemplate"></ng-template>
    </ng-container>
  </div>

  <!-- tiny text below the input -->
  <div *ngIf="!!subtext" class="input-subtext" [class.input-subtext--disabled]="!!disabled">
    {{ subtext }}
  </div>

  <!-- warning text below the input -->
  <div *ngIf="!!warningText" class="input-warning-text">
    {{ warningText }}
  </div>

  <kpt-form-field-error
    [showErrorsOn]="showErrorsOn"
    [customErrors]="customErrors"
  ></kpt-form-field-error>

  <ng-template #defaultLabelTemplateRef>
    <span [innerHTML]="htmlLabel || label"></span>
  </ng-template>
</div>
