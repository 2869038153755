import {PrintSettings} from '@generated/model';
import {ofType, unionize, UnionOf} from 'unionize';
import {FinancialPlanData, ObjectiveArea, PrintSection, Sentence} from './financial-plan.models';

export const FinancialPlanActions = unionize(
  {
    FP_SetData: ofType<{financialPlanData: FinancialPlanData}>(),
    FP_SetFinancialEvaluationSentences: ofType<{sentences: Sentence[]}>(),
    FP_SetFinancialEvaluationRating: ofType<{rating: number}>(),
    FP_SetFinancialRecommendationsSentences: ofType<{sentences: Sentence[]}>(),
    FP_SetFinancialRecommendationsRating: ofType<{rating: number}>(),
    FP_SetPropertiesEvaluationSentences: ofType<{sentences: Sentence[]}>(),
    FP_SetPropertiesEvaluationRating: ofType<{rating: number}>(),
    FP_SetPropertiesRecommendationsSentences: ofType<{sentences: Sentence[]}>(),
    FP_SetPropertiesRecommendationsRating: ofType<{rating: number}>(),
    FP_SetAdditionalContractsAdvisorSolution: ofType<{advisorSolution: string}>(),
    FP_UpdateRating: ofType<{objectiveAssetUuid: string; field: ObjectiveArea; value: number}>(),
    FP_SetPrintSettings: ofType<{settings: PrintSettings}>(),
    FP_TogglePrintSection: ofType<{
      section: PrintSection;
      ancestors?: PrintSection[];
      descendants?: PrintSection[];
      print: boolean;
    }>(),
    FP_TogglePrintSetting: ofType<{
      setting: keyof Omit<PrintSettings, 'hiddenSections'>;
      value: boolean;
    }>(),
  },
  {tag: 'type', value: 'payload'},
);

export type FinancialPlanAction = UnionOf<typeof FinancialPlanActions>;
