export interface MaskOptions {
  mask: NumberConstructor | string;
  lazy?: boolean;
  blocks?: {[block: string]: MaskOptions};
}

export interface NumberMaskOptions extends MaskOptions {
  mask: NumberConstructor;
  thousandsSeparator?: string;
  scale?: number;
  signed?: boolean;
  padFractionalZeros?: boolean;
  min?: number;
  max?: number;
}

export interface PatternMaskOptions extends MaskOptions {
  mask: string;
  placeholderChar?: string;
}

export function numberMask(
  positive = false,
  decimals = 2,
  min?: number,
  max?: number,
): NumberMaskOptions {
  return {
    mask: Number,
    thousandsSeparator: ' ',
    scale: decimals,
    signed: !positive,
    min,
    max,
  };
}

export const numMask = numberMask();
export const posNumMask = numberMask(true);
export const intMask = numberMask(false, 0);
export const posIntMask = numberMask(true, 0);

export const phoneMask: PatternMaskOptions = {
  mask: '+{42PF} NR',
  blocks: {
    PF: numberMask(true, 0, 0, 1),
    NR: numberMask(true, 0, 0, 999_999_999),
  },
};

export const percentMask: NumberMaskOptions = {
  mask: Number,
  scale: 2,
  signed: false,
  padFractionalZeros: true,
};

export function getUnmaskedNumValue(value: string): number {
  return value ? parseInt(value.replace(/ /g, ''), 10) : 0;
}

export function getUnmaskedFloatNumValue(value: string): number {
  return value ? parseFloat(value.replace(/ /g, '').replace(/,/g, '.')) : 0;
}
