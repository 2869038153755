import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FindClientsState} from '../reducers/find-clients.reducer';

export const getFindClientsState = createFeatureSelector<FindClientsState>('findClients');

export const selectFindClientLoading = createSelector(
  getFindClientsState,
  (state: FindClientsState) => state.loading,
);

export const selectCancellableRequest = createSelector(
  getFindClientsState,
  (state: FindClientsState) => state.cancellableRequest,
);
