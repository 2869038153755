<kpt-row
  *ngFor="let risk of risks$ | async; trackBy: trackByKey"
  [ngModelGroup]="'risk.' + risk.data.key"
  class="risk-row"
>
  <kpt-switch
    grow="8"
    [label]="risk.def.label"
    name="key"
    [ngModel]="risk.data.active"
    (ngModelChange)="setRiskActive(asset, risk, $event)"
  ></kpt-switch>
  <kpt-input
    *ngIf="risk.data.active"
    grow="4"
    label="Limit"
    type="number"
    [appendText]="risk.data.unit | riskUnitLabel"
    name="limit"
    [ngModel]="risk.data.limit"
    (ngModelChange)="setRiskLimit(asset, risk, $event)"
  ></kpt-input>
</kpt-row>
