import {Injectable} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class ImmovableProperties {
  private commonImmovablePropertyAsset: FormlyFieldConfig[];
  private extendedImmovablePropertyAsset: FormlyFieldConfig[];

  constructor(private abstractAssets: AbstractAssetsDefinitions) {
    this.commonImmovablePropertyAsset = this.abstractAssets.commonImmovablePropertiesFields();
    this.extendedImmovablePropertyAsset = this.abstractAssets.extendedImmovablePropertiesFields();
  }

  flat(): AssetDefinition {
    return {
      name: assetNames[AssetType.Flat],
      type: AssetType.Flat,
      fields: this.extendedImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.Flat),
      model: {},
    };
  }

  house(): AssetDefinition {
    return {
      name: assetNames[AssetType.House],
      type: AssetType.House,
      fields: this.extendedImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.House),
      model: {},
    };
  }

  household(): AssetDefinition {
    return {
      name: assetNames[AssetType.Household],
      type: AssetType.Household,
      fields: this.commonImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.Household),
      model: {},
    };
  }

  holidayProperty(): AssetDefinition {
    return {
      name: assetNames[AssetType.HolidayProperty],
      type: AssetType.HolidayProperty,
      fields: this.extendedImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.HolidayProperty),
      model: {},
    };
  }

  apartmentBuilding(): AssetDefinition {
    return {
      name: assetNames[AssetType.ApartmentBuilding],
      type: AssetType.ApartmentBuilding,
      fields: this.commonImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.ApartmentBuilding),
      model: {},
    };
  }

  garage(): AssetDefinition {
    return {
      name: assetNames[AssetType.Garage],
      type: AssetType.Garage,
      fields: this.commonImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.Garage),
      model: {},
    };
  }

  land(): AssetDefinition {
    return {
      name: assetNames[AssetType.Land],
      type: AssetType.Land,
      fields: this.commonImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.Land),
      model: {},
    };
  }

  otherImmovableProperty(): AssetDefinition {
    return {
      name: assetNames[AssetType.OtherImmovableProperty],
      type: AssetType.OtherImmovableProperty,
      fields: this.extendedImmovablePropertyAsset,
      validators: getAssetValidators(AssetType.OtherImmovableProperty),
      model: {},
    };
  }

  getAll() {
    return [
      this.flat(),
      this.house(),
      this.household(),
      this.holidayProperty(),
      this.apartmentBuilding(),
      this.garage(),
      this.land(),
      this.otherImmovableProperty(),
    ];
  }
}
