import {Component, Input} from '@angular/core';
import {Strategy} from 'src/app/modules/life-insurance-old/4-investment-survey/investment-survey.models';
import {pointsI18n} from '../summary.utils';

export interface SurveyResults {
  name: string;
  longTermCostCoverage: string;
  experience: string;
  experiencePoints: number;
  experienceLabel: string;
  riskPoints: number;
  riskId: string;
  riskI18n: string;
  strategyKey: string;
  strategy: Strategy;
  strategyLabel: string;
  horizon: string;
  horizonLabel: string;
  eligibility: boolean;
  inconsistentPortfolioAnswers: boolean;
}

const investorLevelTooltips: string[] = [
  '-4 až -3 bodů – klient neakceptující ztrátu (klient nemá žádné znalosti). Nutné sjednat inv. strategii do' +
    ' garantovaného fondu (GF). Současně musí být v Záznamu z jednání uveden požadavek klienta na daňové úlevy nebo' +
    ' příspěvek zaměstnavatele (tj. spojené s nízkým rizikem ztráty – SRI max. 2)',

  '-2 až 6 bodů – základní investor – klient vzhledem ke svým znalostem a zkušenostem by měl do svého portfolia zařadit' +
    ' produkty odpovídající pouze konzervativnímu profilu (tj. spojené s nízkým rizikem ztráty – SRI max. 2)',

  '7 až 14 bodů – informovaný investor – klient má znalosti a zkušenosti umožňující zařadit mu do svého portfolia zařadit' +
    ' produkty odpovídající konzervativnímu až vyváženému  profilu (tj. spojené s vyšším rizikem ztráty investice – SRI max. 4)',

  '15 až 28 – pokročilý investor – klient s touto úrovní může do svého portfolia zařadit produkty odpovídající všem' +
    ' investičním profilům (i s vysokým rizikem ztráty – SRI max. 5)',
];

@Component({
  selector: 'kpt-investment-survey-life-insurance-summary',
  templateUrl: './life-insurance-summary.component.html',
  styleUrls: ['./life-insurance-summary.component.scss'],
})
export class LifeInsuranceSummaryComponent {
  @Input()
  results: SurveyResults;

  constructor() {}

  get experience() {
    return `${this.results.experiencePoints} ${pointsI18n(this.results.experiencePoints)} - ${
      this.results.experienceLabel
    }`;
  }

  get risk() {
    return `${this.results.riskPoints} ${pointsI18n(this.results.riskPoints)} - ${
      this.results.riskI18n
    }`;
  }

  get investorLevelTooltip(): string {
    return investorLevelTooltips.join('<br><br>');
  }
}
