// List taken from https://dsugar.kapitol.cz/rest/v11_1/lang/cs_CZ/

export const consultantLevels: Record<string, string> = {
  /* new positions */
  760866341388305: 'Tipař',
  760866341388318: 'Tipař',
  760866341388304: 'Partner',
  760866341388323: 'Základní stupeň',
  760866341388324: 'Poradce junior',
  760866341388325: 'Poradce',
  760866341388326: 'Poradce senior',
  760866341388328: 'Vedoucí týmu',
  760866341388327: 'Samostatný poradce',
  760866341388329: 'Manažer',
  760866341388330: 'Vedoucí manažer',
  760866341388331: 'Ředitel',
  760866341388332: 'Oblastní ředitel',
  760866341388333: 'Vrchní ředitel',

  /* old positions */
  /*760866341388322: 'DP',
  760866341388321: 'D',
  760866341388298: 'C',
  760866341388305: 'T',
  760866341388318: 'T+',
  760866341388304: 'P',
  760866341388319: 'Z-',
  760866341388316: 'Z',
  760866341388317: 'Z+',
  760866341388290: 'A',
  760866341388312: 'A+',
  760866341388291: '1',
  760866341388311: '1+',
  760866341388292: '2',
  760866341388310: '2+',
  760866341388293: '3',
  760866341388309: '3+',
  760866341388294: '4',
  760866341388308: '4+',
  760866341388295: '5',
  760866341388307: '5+',
  760866341388296: '6',
  760866341388306: '6+',
  760866341388320: '7',
  760866341388323: 'PZ',
  760866341388324: 'P1',
  760866341388325: 'P2',
  760866341388326: 'P3',
  760866341388328: 'M2',
  760866341388327: 'P4',
  760866341388329: 'M3',
  760866341388330: 'M4',
  760866341388331: 'M5',
  760866341388332: 'M6',
  760866341388333: 'M7',*/
};
