import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '../../pipes';
import {LoanChartComponent} from './loan-chart.component';

@NgModule({
  declarations: [LoanChartComponent],
  imports: [CommonModule, PipesModule],
  exports: [LoanChartComponent],
})
export class LoanChartModule {}
