/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {NgModule} from '@angular/core';
import {EffectsModule as NgrxEffectsModule} from '@ngrx/effects';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';

import {BuildingService} from '../../../controllers/Building';
import {FormsSharedModule} from '../../forms-shared.module';
import {BuildingFormService} from './building.service';

import {BuildingEffects} from './states/effects';
import {BuildingReducer} from './states/reducers';
import {selectorName} from './states/reducers';

@NgModule({
  imports: [
    FormsSharedModule,
    NgrxStoreModule.forFeature(selectorName, BuildingReducer),
    NgrxEffectsModule.forFeature([BuildingEffects]),
  ],
  providers: [
    BuildingService,
    BuildingFormService,
  ],
})
export class BuildingModule {}
