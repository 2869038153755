/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConsumerService} from '../../../controllers/Consumer';

@Injectable()
export class ConsumerFormService {
  form: UntypedFormGroup;
  constructor(
    private consumerService: ConsumerService,
  ) {
    this.form = new UntypedFormGroup({
      loan: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/), Validators.required]),
      fullinfo: new UntypedFormControl(false, []),
      loan_term: new UntypedFormControl(undefined, [Validators.min(0), Validators.pattern(/^([+-]?[1-9]\d*|0)$/)]),
    });
  }

  submit(raw = false) {
    const data = raw ?
      this.form.getRawValue() :
      this.form.value;
    return this.consumerService.consumer(data);
  }
}
