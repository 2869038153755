export enum IButtonType {
  DANGER = 'danger',
  LIGHT = 'light',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  WHITE = 'white',
  SIMPLE = 'simple',
}
