<div class="wrapper">
  <img src="assets/images/alert-icon.svg" class="alert-icon" />
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

<kpt-add-asset-button
  *ngIf="showAddAssetButton && situation"
  class="hidden-print"
  variant="icon"
  [situation]="situation"
  [objectiveType]="objectiveType"
  [relatedObjectiveUuid]="relatedObjectiveUuid"
  [relatedPropertyUuid]="relatedPropertyUuid"
  [stakeholderUuid]="stakeholderUuid"
  [assetType]="assetType"
>
</kpt-add-asset-button>

<a
  *ngIf="!situation && buttonLink"
  [routerLink]="buttonLink"
  [queryParams]="queryParams"
  class="hidden-print"
>
  <kpt-button icon="icon--edit" type="simple"> </kpt-button>
</a>
