import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition, IndependenceInputMode} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {FinancialIndependenceAsset} from '@shared/analysis/models/objectives';
import {selectFamilyHead} from '@shared/analysis/operators';
import * as moment from 'moment';
import {State} from 'src/store';

@Injectable()
export class FinancialIndependenceAssetType extends BaseAssetType {
  private familyHead: FamilyMember;
  constructor(private store: Store<State>) {
    super();

    this.store.pipe(selectFamilyHead()).subscribe(familyHead => {
      this.familyHead = familyHead;
    });
  }

  create(): FinancialIndependenceAsset {
    const lifeExpectancy = this.familyHead.sex === 'MALE' ? 76 : 82;

    return {
      type: AssetType.FinancialIndependence,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      name: null,
      description: null,
      monthlyRent: 0,
      independenceInputMode: IndependenceInputMode.Value,
      lifeExpectancy,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.FinancialIndependence],
      type: AssetType.FinancialIndependence,
    };
  }
}
