import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

export const aboutKapitolCurrentStepperProgressConfig: IStepperProgressItem[] =
  createStepperProgressConfig(
    [
      {
        url: 'about-us',
        done: false,
        label: 'O nás',
      },
      {
        url: 'we-provide',
        done: false,
        label: 'Poskytujeme',
      },
      {
        url: 'cooperation',
        done: false,
        label: 'Spolupracujeme',
      },
      {
        url: 'consultation-option',
        done: false,
        label: 'Možnosti poradenství',
      },
      {
        url: 'why-consultant',
        done: false,
        label: 'Proč mít poradce',
      },
    ],
    SecuredPage.AboutKapitol,
  );
