import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'thinsp',
})
export class ThinSpacePipe implements PipeTransform {
  /** replaces space and &nbsp; with  &thinsp; */
  transform(value: string, _?: any): string {
    return typeof value === 'string' ? value.replace(/[ \u00a0]/g, '\u2009') : value;
  }
}
