import {HttpErrorResponse} from '@angular/common/http';
import {FoundPortalClient} from '@generated/defs/FoundPortalClient';
import produce from 'immer';
import {FindClientsActions, FindClientsActionTypes} from '../actions/find-clients.actions';

export interface FindClientsState {
  clients: FoundPortalClient[];
  loading: boolean;
  cancellableRequest: boolean;
  error: HttpErrorResponse;
}

const initialState: FindClientsState = {
  clients: [],
  loading: false,
  error: null,
  cancellableRequest: false,
};

const findClientsReducer = produce((newState: FindClientsState, action: FindClientsActions) => {
  switch (action.type) {
    case FindClientsActionTypes.FindClientsStart:
      newState.loading = true;
      newState.cancellableRequest = true;
      return;

    case FindClientsActionTypes.FindClientsSuccess:
      newState.loading = false;
      newState.error = null;
      newState.cancellableRequest = false;
      newState.clients = action.payload;
      return;

    case FindClientsActionTypes.FindClientsError:
      newState.loading = false;
      newState.cancellableRequest = false;
      newState.error = action.payload;
      newState.clients = [];
      return;

    case FindClientsActionTypes.FindClientsCancel:
      newState.loading = false;
      newState.cancellableRequest = false;
      return;

    case FindClientsActionTypes.ClearInput:
      return initialState;
  }
});

export function reducer(state = initialState, action: FindClientsActions): FindClientsState {
  return findClientsReducer(state, action);
}
