import {Injectable, OnDestroy} from '@angular/core';
import {NotificationService} from '@lib/services/notification.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent, merge, Observable, Observer} from 'rxjs';
import {map, skip} from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ConnectionInfoService implements OnDestroy {
  private online$ = merge<boolean>(
    fromEvent(window, 'offline').pipe(map(() => false)),
    fromEvent(window, 'online').pipe(map(() => true)),
    new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
    }),
  );

  constructor(private notificationService: NotificationService) {}

  init() {
    this.online$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(online => this.notificationService.dispatchConnectionChange(online));
  }

  ngOnDestroy() {}
}
