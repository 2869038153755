import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {Actions, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {CoreSyncCommonService} from '@shared/analysis/core-sync-common.service';
import {
  cancelledCoreStatus,
  coreCategoriesToKappkaTable,
  CoreCategory,
  draftCoreStatus,
  liveCoreStatus,
  terminatedCoreStatus,
  validCoreCategories,
} from '@shared/analysis/core-sync.data';
import {CoreContract, coreContractToAsset, SugarEnum} from '@shared/analysis/core-sync.models';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {RemoveRelatedDataService} from '@shared/analysis/remove-related-data.service';
import {CoreContractsActions} from '@shared/analysis/store/core-contracts.actions';
import {
  selectFinancialProductAssets,
  selectFinancialProductAssetsWithExcluded,
} from '@shared/analysis/store/index';
import {RemoteLoggerService} from '@shared/services/remote-logger.service';
import {cloneDeep, isEqual} from 'lodash';
import {Subject} from 'rxjs';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {State} from 'src/store';
import {
  DeleteFamilyMemberFinish,
  FamilyMemberActionTypes,
} from 'src/store/actions/family-members.actions';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';
import {v4 as uuid} from 'uuid';

@Injectable()
export class CoreSyncService {
  private coreCategories: CoreCategory[];
  private cancel$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private coreSyncCommonService: CoreSyncCommonService,
    private assetsHandlerService: AssetsHandlerService,
    private integrationsService: IntegrationsService,
    private assetTypeService: AssetTypeService,
    private removeRelatedDataService: RemoveRelatedDataService,
    private actions$: Actions,
    private remoteLoggerService: RemoteLoggerService,
  ) {}

  async syncCoreContracts() {
    const familyUuid = await this.store.pipe(select(getFamilyUuid), take(1)).toPromise();
    this.info('Start syncing core contracts for family id', familyUuid);
    try {
      const contracts = await this.integrationsService
        .opportunity({family_uuid: familyUuid})
        .toPromise();
      this.info('Received core contracts', JSON.stringify(contracts));
      this.store.dispatch(CoreContractsActions.FA_SetCoreContracts({coreContracts: contracts}));
      await this.importContractsFromCore(contracts);
      await this.saveForeignAssetsToCore();
      this.info('Finished syncing core contracts');
    } catch (error) {
      this.warn('Core contracts cannot be synced', error.message);
    }
  }

  startSync() {
    this.actions$
      .pipe(
        ofType(FamilyMemberActionTypes.DeleteMemberFinish),
        map((action: DeleteFamilyMemberFinish) => action.payload),
        tap(payload => {
          this.removeRelatedDataService.removeRelateDataOfFamilyMember(payload.sugar_uuid);
        }),
        takeUntil(this.cancel$),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(FamilyMemberActionTypes.AddExistingFamilyMemberFinish),
        tap(async action => {
          this.info('Existing client added to the family. Invoking sync with the core.', action);
          await this.syncCoreContracts();
        }),
        takeUntil(this.cancel$),
      )
      .subscribe();

    this.syncCoreContracts();
  }

  stop() {
    this.cancel$.next();
  }

  async updateAsset(
    asset: CommonFinancialProductAsset,
    coreContract: CoreContract,
    postprocess?: (newAsset: CommonFinancialProductAsset) => CommonFinancialProductAsset,
  ) {
    try {
      this.info(
        'Update asset',
        JSON.stringify(asset.assetUuid),
        'with contract',
        JSON.stringify(coreContract),
      );

      const clonedAsset = cloneDeep(asset);
      this.updateAssetValidity(clonedAsset, coreContract);

      const updatedAsset = postprocess ? postprocess(clonedAsset) : clonedAsset;

      if (!isEqual(asset, updatedAsset)) {
        await this.saveAsset(updatedAsset as Asset);
      } else {
        this.info('No need to save unchanged asset', asset.assetUuid);
      }
    } catch (e) {
      this.error('Failed to update asset', asset.assetUuid, e);
    }
  }

  private async importContractsFromCore(coreContracts: CoreContract[]) {
    this.info('Start importing contracts from core');
    this.coreCategories = await validCoreCategories;
    const assets = await this.store
      .pipe(select(selectFinancialProductAssetsWithExcluded), take(1))
      .toPromise();
    for (const coreContract of coreContracts) {
      this.info(`Processing core contract ${JSON.stringify(coreContract)}`);
      const assetWithSugarUuid = assets.find(a => a.sugarUuid === coreContract.id);
      if (assetWithSugarUuid) {
        this.info(
          `Found asset (assetUuid: ${assetWithSugarUuid.assetUuid}) with the same sugar id`,
        );
        await this.updateAsset(assetWithSugarUuid, coreContract);
      } else {
        if (!this.isCoreContractImportable(coreContract)) {
          this.info(
            `Core contract (sugar id: ${coreContract.id}) has not draft, live or terminated status. Skipping import`,
          );
          continue;
        }
        const matchingAssetType = this.translateCoreCategoryToAssetType(
          [
            coreContract.productCategory1,
            coreContract.productCategory2,
            coreContract.productCategory3,
          ],
          this.coreCategories,
        );
        if (!matchingAssetType) {
          // skip import, the core category does not have a matching Kappka asset type
          this.info(
            `Skipping import, matching kappka asset type not found for core contract ` +
              `(sugar id: ${coreContract.id}) with categories: ` +
              `${coreContract.productCategory1?.codeDesc} / ` +
              `${coreContract.productCategory2?.codeDesc} / ` +
              `${coreContract.productCategory3?.codeDesc}`,
          );
          continue;
        }
        const matchingAsset = assets.find(
          a =>
            !a.sugarUuid &&
            a.type === matchingAssetType &&
            a.contractNumber &&
            a.contractNumber === coreContract.opportunityNumber,
        );
        if (matchingAsset) {
          this.info('Found asset with the same contract number');
          await this.updateAsset(matchingAsset, coreContract);
        } else {
          this.info('Asset with the same contract number not found. Creating a new asset');
          await this.createAsset(matchingAssetType, coreContract);
        }
      }
    }
  }

  private async saveForeignAssetsToCore() {
    this.info('Start saving foreign assets to core');
    const assets = await this.store.pipe(select(selectFinancialProductAssets), take(1)).toPromise();
    const foreignAssets = assets.filter(asset => !asset.sugarUuid);
    this.info(`Found ${foreignAssets.length} foreign assets`);
    for (const asset of foreignAssets) {
      await this.coreSyncCommonService.saveForeignAssetToCore(asset as Asset);
    }
  }

  private async createAsset(assetType: AssetType, coreContract: CoreContract) {
    this.info(
      'Create asset with asset type',
      assetType,
      'from contract',
      JSON.stringify(coreContract),
    );
    const fields = coreContractToAsset(coreContract);

    const defaults = await this.assetTypeService.create(assetType);

    await this.saveAsset({...defaults, assetUuid: uuid(), type: assetType, ...fields} as Asset);
  }

  private translateCoreCategoryToAssetType(
    category: [SugarEnum, SugarEnum, SugarEnum],
    coreCategories: CoreCategory[],
  ): AssetType | undefined {
    const cat1 = coreCategories.find(c => String(c.id) === category[0]?.code)?.kod ?? '';
    const cat2 = coreCategories.find(c => String(c.id) === category[1]?.code)?.kod ?? '';
    const cat3 = coreCategories.find(c => String(c.id) === category[2]?.code)?.kod ?? '';
    return (
      this.findCoreCategory(cat1, cat2, cat3) ??
      this.findCoreCategory(cat1, cat2, '') ??
      this.findCoreCategory(cat1, '', '')
    );
  }

  private findCoreCategory(cat1: string, cat2: string, cat3: string) {
    return coreCategoriesToKappkaTable.find(c => isEqual(c.core, [cat1, cat2, cat3]))?.kappka;
  }

  private updateAssetValidity(asset: CommonFinancialProductAsset, coreContract: CoreContract) {
    const fields = coreContractToAsset(coreContract);
    // TODO what if stakeholderUuid is not among family members?
    // asset.stakeholderUuid = fields.stakeholderUuid;
    asset.foreignContract = fields.foreignContract ?? true;
    asset.sugarUuid = fields.sugarUuid;
    asset.end = fields.end;
    asset.coreStatus = fields.coreStatus;
    asset.coreSecondaryStatus = fields.coreSecondaryStatus;
  }

  private async saveAsset(asset: Asset): Promise<Asset> {
    this.info('Save asset', JSON.stringify(asset));
    const defaults = await this.assetTypeService.create(asset.type);
    asset = {...defaults, ...asset};
    return this.assetsHandlerService.saveAsset(asset);
  }

  private isCoreContractImportable(coreContract: CoreContract): boolean {
    const code = coreContract.status?.code;
    return (
      code === liveCoreStatus.code ||
      code === draftCoreStatus.code ||
      code === terminatedCoreStatus.code ||
      code === cancelledCoreStatus.code
    );
  }

  private info(...data: unknown[]) {
    this.remoteLoggerService.info('Core Sync', ...data);
  }

  private warn(...data: unknown[]) {
    this.remoteLoggerService.warn('Core Sync', ...data);
  }

  private error(...data: unknown[]) {
    this.remoteLoggerService.error('Core Sync', ...data);
  }
}
