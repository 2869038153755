<kpt-checkbox
  [formControl]="formControlSubstitute"
  [required]="to.required"
  [readonly]="to.readonly"
  [labelTemplate]="to.labelTemplate"
  [checked]="to.checked"
  [disabledField]="to.disabled"
  [subtext]="to.subtext"
  (valueChange)="onChange(field, $event)"
  [label]="to.label"
>
</kpt-checkbox>
