import {createSelector} from '@ngrx/store';
import {Asset, assetNames, AssetType} from '@shared/analysis/models/asset';
import {CommonInvestmentAsset} from '@shared/analysis/models/investment-products';
import {
  VehicleProvisionAsset,
  FamilyProvisionAsset,
  ObjectiveAsset,
  ObjectivesAsset,
  PropertyProvisionAsset,
  VehicleRequirementsAsset,
  PropertyRequirementsAsset,
} from '@shared/analysis/models/objectives';
import {objectiveSourceAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  findAssetsByRelatedObjectiveUuid,
  selectCurrentAssets,
  selectObjectivesAssets,
  selectRequirementsAssets,
} from '@shared/analysis/store';
import {GraphInput} from '@shared/ui/graph/graph.component';
import {
  getCategory,
  getFutureValue,
  getMonthlyValue,
  getPresentValue,
  getSkipSum,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  Fulfillment,
  FulfillmentType,
  InsuranceRisk,
  Row,
  Sum,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';

export const selectFamilyObjectiveAsset = createSelector(
  selectObjectivesAssets,
  objectiveAssets =>
    objectiveAssets.find(asset => asset.type === AssetType.FamilyProvision) as FamilyProvisionAsset,
);

export const selectPropertyObjectiveAsset = createSelector(
  selectObjectivesAssets,
  objectiveAssets => {
    return objectiveAssets.find(
      asset => asset.type === AssetType.PropertyProvision,
    ) as PropertyProvisionAsset;
  },
);

export const selectPropertyRequirementsAssets = createSelector(
  selectRequirementsAssets,
  requirementsAssets =>
    requirementsAssets.filter(
      asset => asset.type === AssetType.PropertyRequirements,
    ) as PropertyRequirementsAsset[],
);

export const selectVehicleObjectiveAsset = createSelector(
  selectObjectivesAssets,
  objectiveAssets => {
    return objectiveAssets.find(
      asset => asset.type === AssetType.VehicleProvision,
    ) as VehicleProvisionAsset;
  },
);

export const selectVehicleRequirementsAssets = createSelector(
  selectRequirementsAssets,
  requirementsAssets =>
    requirementsAssets.filter(
      asset => asset.type === AssetType.VehicleRequirements,
    ) as VehicleRequirementsAsset[],
);

export const selectObjectiveAssetsByObjectiveType = (type: ObjectiveType) =>
  createSelector(
    selectCurrentAssets,
    (assets): ObjectivesAsset[] =>
      assets.filter(asset => objectiveSourceAssets[type].includes(asset.type)) as ObjectivesAsset[],
  );

export function getTable(
  situation: Situation,
  assets: Asset[],
  objectiveAssetUuid: string,
  objectiveValue: number,
  finalDate?: string,
): Table {
  const rows = findAssetsByRelatedObjectiveUuid(assets, objectiveAssetUuid).map((asset): Row => {
    const investmentAsset = asset as CommonInvestmentAsset;
    return {
      assetUuid: investmentAsset.assetUuid,
      advisorProposalState: investmentAsset.advisorProposalState,
      clientProposalState: investmentAsset.clientProposalState,
      asset,
      name: investmentAsset.name ? investmentAsset.name : assetNames[investmentAsset.type],
      category: getCategory(asset),
      presentValue: getPresentValue(asset),
      finalValue: finalDate ? getFutureValue(finalDate, asset) : -1,
      monthlyValue: getMonthlyValue(asset),
      yearlyRate: investmentAsset.yearlyRate,
      skipSum: getSkipSum(asset, situation),
    };
  });

  const sum = sumRows(rows);

  return {
    rows,
    sum,
    fulfillment: computeFulfillment(sum, objectiveValue),
    rating: computeRating(sum.finalValue, objectiveValue),
  };
}

export function sumRows(rows: Row[]): Sum {
  return rows
    .filter(row => !row.skipSum)
    .reduce(
      (subtotal, item) => {
        subtotal.presentValue += item.presentValue;
        subtotal.monthlyValue += item.monthlyValue;
        subtotal.finalValue += item.finalValue;
        return subtotal;
      },
      {presentValue: 0, monthlyValue: 0, finalValue: 0},
    );
}

function computeFulfillment(sum: Sum, objectiveValue: number): Fulfillment {
  const fulfillment =
    sum.finalValue === 0 && objectiveValue === 0 ? 1 : sum.finalValue / objectiveValue;
  return {
    type: FulfillmentType.Percent,
    percent: Number.isFinite(fulfillment) ? fulfillment : null,
  };
}

export function computeRating(finalValue: number, objectiveValue: number): number {
  if (!Number.isFinite(objectiveValue) || objectiveValue === 0) return 0;
  const rating = Math.ceil((finalValue / objectiveValue) * 10) / 2;
  if (rating < 0) return 0;
  if (rating > 5) return 5;
  return rating;
}

export function getObjectiveName(objectiveAsset: ObjectiveAsset): string {
  return objectiveAsset.name ? objectiveAsset.name : assetNames[objectiveAsset.type];
}

export function getChartData(
  currentTable: Table,
  proposedTable: Table,
  objectiveValue: number,
): GraphInput {
  return {
    labels: ['Současné řešení', 'Potřebná částka', 'Návrh řešení'],
    values: [currentTable.sum.finalValue, objectiveValue, proposedTable.sum.finalValue],
  };
}

export function sortRowByName(rowA: Row, rowB: Row): number {
  return rowA.name < rowB.name ? -1 : 1;
}

export function setFulfillmentAndRating(
  currentTable: Table,
  advisorProposedTable: Table,
  clientProposedTable: Table,
  insuranceRisks: InsuranceRisk[],
) {
  setFulfillmentAndRatingForTable(currentTable, risk => risk.currentValue > 0, insuranceRisks);
  setFulfillmentAndRatingForTable(
    advisorProposedTable,
    risk => risk.advisorProposedValue > 0,
    insuranceRisks,
  );
  setFulfillmentAndRatingForTable(
    clientProposedTable,
    risk => risk.clientProposedValue > 0,
    insuranceRisks,
  );
}

function setFulfillmentAndRatingForTable(
  table: Table,
  filterFn: (risk: InsuranceRisk) => boolean,
  insuranceRisks: InsuranceRisk[],
) {
  table.fulfillment = {
    type: FulfillmentType.Absolute,
    absoluteSelected: insuranceRisks ? insuranceRisks.filter(filterFn).length : 0,
    absoluteTotal: insuranceRisks ? insuranceRisks.length : 0,
  };
  table.rating = insuranceRisks
    ? computeRating(insuranceRisks.filter(filterFn).length, insuranceRisks.length)
    : 0;
}
