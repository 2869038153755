import {Component, Input, TemplateRef, ViewEncapsulation} from '@angular/core';
import {ITooltipDirection, ITooltipSize} from './models';

@Component({
  selector: 'shd-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() customClass?: string;
  @Input() direction?: ITooltipDirection;
  @Input() size?: ITooltipSize;
  @Input() icon?: string;
  @Input() matIcon?: string;
  @Input() width?: string;
  @Input() textAlign: string;
  @Input() stopClickPropagation = false;
  @Input() hover?: boolean = false;
  @Input() customToggle?: boolean = false;

  isOpen = false;

  constructor() {
    this.direction = Object.values(ITooltipDirection).includes(this.direction)
      ? this.direction
      : null;
    this.size = Object.values(ITooltipSize).includes(this.size) ? this.size : null;
  }

  toggle = (event: MouseEvent) => {
    this.isOpen = !this.isOpen;
    if (this.stopClickPropagation) event.stopPropagation();
  };
}
