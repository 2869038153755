import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TabFormlyComponent} from './tab-formly.component';

@NgModule({
  declarations: [TabFormlyComponent],
  imports: [CommonModule],
  exports: [TabFormlyComponent],
})
export class TabFormlyModule {}
