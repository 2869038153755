import {Component, ElementRef, forwardRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {CommonFieldComponent} from '../common-field/common-field.component';

@Component({
  selector: 'kpt-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    ...getProvidersFor(CheckboxComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => CheckboxComponent)},
  ],
})
export class CheckboxComponent extends CommonFieldComponent {
  @ViewChild('field', {static: true})
  field: ElementRef;

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  checked = false;

  @Input()
  indeterminate: boolean;

  writeValue(val: boolean) {
    this.checked = val;
  }

  labelClick() {
    if (!this.field.nativeElement) return;
    this.field.nativeElement.focus();

    this.checked = !this.checked;
    this.onChange(this.checked);
    this.valueChange.emit(this.checked);
  }
}
