import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SliderBottomLabelFunction, SliderOptions} from './slider.model';

@Component({
  selector: 'kpt-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit, OnChanges {
  mergedSliderOptions: SliderOptions = {};

  @Input()
  set sliderOptions(sliderOptions: SliderOptions) {
    this.mergedSliderOptions = {
      ...sliderOptions,
      disabled: this.mergedSliderOptions.disabled,
    };
  }

  @Input()
  set disabledSlider(disabled: boolean) {
    this.mergedSliderOptions = {...this.mergedSliderOptions, disabled};
  }

  @Input() parentForm: UntypedFormGroup;
  @Input() sliderName: string;
  @Input() label?: string;
  @Output() onChange = new EventEmitter();

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  percentage: number;

  get bottomLabel() {
    return (
      this.getBottomLabel &&
      this.percentage > 0 &&
      this.percentage < 100 &&
      this.getBottomLabel(this.parentForm.get(this.sliderName).value, this.percentage)
    );
  }

  private get getBottomLabel(): SliderBottomLabelFunction {
    return this.mergedSliderOptions && this.mergedSliderOptions.bottomLabel
      ? this.mergedSliderOptions.bottomLabel === 'percentage'
        ? (_0, percentage) => (percentage ? `${this.percentage.toFixed(0)}&nbsp;%` : null)
        : this.mergedSliderOptions.bottomLabel
      : null;
  }

  ngAfterViewInit(): void {
    this.percentage = this.getPercentage();
  }

  ngOnChanges() {
    this.percentage = this.getPercentage();
  }

  onValueChange() {
    this.percentage = this.getPercentage();
    this.onChange.emit(this.percentage);
  }

  private getPercentage() {
    const currentValue = this.parentForm.get(this.sliderName).value;
    return Number.parseInt(currentValue, 10) / (this.mergedSliderOptions.ceil / 100);
  }
}
