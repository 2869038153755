import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DialogService} from '@shared/services/modal.service';
import {AbstractFormTemplateComponent} from 'src/app/modules/financial-analysis/shared/components/template-abstract.component';
import {categoriesOptions} from 'src/app/modules/investment-old/investment-survey/investment-survey.models';

@Component({
  selector: 'kpt-client-category-selection',
  templateUrl: './client-category-selection.component.html',
  styleUrls: ['./client-category-selection.component.scss'],
})
export class ClientCategorySelectionComponent
  extends AbstractFormTemplateComponent
  implements OnInit
{
  form = new UntypedFormGroup({category: new UntypedFormControl()});
  clientCategoryKey: string = null;

  categoriesOptions = categoriesOptions;

  constructor(public modalService: DialogService) {
    super(modalService);
  }

  ngOnInit(): void {
    this.form.get('category').patchValue(this.clientCategoryKey);
  }

  close() {
    this.modalService.close(this.form.get('category').value);
  }
}
