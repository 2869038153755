import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {omit} from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {GuardModalInterface} from '../models/guard.model';

@Injectable()
export class GuardService {
  private targetUrl: string;
  private modalData$: BehaviorSubject<GuardModalInterface | null> = new BehaviorSubject(null);
  private modalResponse$ = new Subject<boolean>();

  constructor(private router: Router) {}

  getModalData$() {
    return this.modalData$.asObservable();
  }

  setModalData(val: GuardModalInterface) {
    this.modalData$.next(val);
  }

  setModalResponse(val: boolean) {
    this.modalResponse$.next(val);
  }

  getModalResponse() {
    return this.modalResponse$.asObservable();
  }

  setTargetUrl(url: string) {
    this.targetUrl = url;
  }

  getTargetUrl(): string {
    return this.targetUrl;
  }

  // remove guard bypassing queryParameter
  removeForceQueryParameter(route: ActivatedRoute) {
    route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.force) {
        this.router.navigate([], {
          relativeTo: route,
          queryParams: {...omit(params, 'force')},
          replaceUrl: true,
        });
      }
    });
  }
}
