<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">Výběr rodiny</h3>
    </div>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="familiesForm">
    <div class="col-10 offset-1">
      <h3>Pro pokračování vyberte rodinu</h3>
      <ng-container *ngFor="let option of families">
        <span class="row">
          <kpt-single-radio-button
            [value]="option.key"
            [controlForm]="familiesForm.get('families')"
          >
            <span class="family-name" *ngIf="!namesToEdit[option.key]">{{ option.label }}</span>
          </kpt-single-radio-button>
          <kpt-confirm-field
            *ngIf="namesToEdit[option.key]"
            class="edit-name"
            label="Nový název rodiny"
            [controlForm]="familiesForm.get(option.key)"
            (confirm)="saveFamilyName(option.key)"
            (cancel)="cancelFamilyNameEditing(option.key)"
          >
          </kpt-confirm-field>
          <kpt-dropdown-bootstrap
            *ngIf="!namesToEdit[option.key]"
            class="family-dropdown"
            menuWidth="14rem"
            [toggleTemplate]="dropdownToggleRef"
            [contentTemplate]="dropdownCurrentRef"
            [contentTemplateContext]="{familyuuid: option.key}"
          >
          </kpt-dropdown-bootstrap>
        </span>
        <ul *ngIf="option.members" class="family-members mt-4">
          <li *ngFor="let member of option.members">
            {{ member.name }}
          </li>
        </ul>
      </ng-container>

      <ng-template #dropdownToggleRef>
        <img class="dropdown-toggle" src="assets/images/vertical-dots.svg" />
      </ng-template>

      <ng-template #dropdownCurrentRef let-familyuuid="familyuuid">
        <kpt-menu-item icon="icon--edit" (click)="editFamilyName(familyuuid)">
          Přejmenovat
        </kpt-menu-item>
        <kpt-menu-item icon="icon--close" (click)="comfirmDeleteFamily(familyuuid)">
          Smazat
        </kpt-menu-item>
      </ng-template>

      <div *ngIf="showAddFamily" class="text-center">
        <kpt-input
          formControlName="newFamily"
          label="Název nové rodiny"
          [disabledField]="addingFamilyInProgress"
        >
        </kpt-input>
        <div class="footer">
          <kpt-button
            (action)="createFamily()"
            label="Přidat"
            type="primary"
            [isDisabled]="!familiesForm.get('newFamily').value || addingFamilyInProgress"
          >
          </kpt-button>
          <kpt-button
            type="primary"
            customClass="ml-3"
            [isSubmitButton]="false"
            [hasOutline]="true"
            (click)="showAddFamily = false"
            [label]="'Zrušit'"
          >
          </kpt-button>
        </div>
      </div>

      <ng-container *ngIf="!showAddFamily">
        <kpt-button
          label="Přidat novou rodinu"
          type="primary"
          icon="icon--plus"
          customClass="pr-0"
          (action)="showAddFamily = true"
        >
        </kpt-button>

        <div class="footer">
          <kpt-button
            type="primary"
            customClass="mr-3"
            [isSubmitButton]="false"
            [hasOutline]="true"
            (click)="navigateBack()"
            [label]="'Zpět'"
          >
          </kpt-button>
          <kpt-button
            (action)="navigateToClientPage()"
            label="Pokračovat"
            type="primary"
            [isDisabled]="!familiesForm.get('families').value"
          >
          </kpt-button>
        </div>
      </ng-container>
    </div>
  </form>
</div>
