<div
  role="button"
  class="card"
  (click)="action.emit($event)"
  [ngClass]="config.type ? 'card--' + config.type : ''"
  [class.card--disabled]="!!config.disabled"
  [class.card--selected]="selected"
  [attr.tabindex]="!!config.disabled ? '-1' : '0'"
>
  <span *ngIf="!!config.isExternalLink" class="card__external-link">
    <span class="icon icon--external-link"></span>
  </span>
  <span *ngIf="!!config.header" class="card__header">
    {{ config.header }}
  </span>
  <ng-container
    *ngTemplateOutlet="
      imageTemplate || (!!config.imagePath ? imageFileTemplate : defaultImageTemplate)
    "
  ></ng-container>
  <span class="card__label">
    {{ label || config.label }}
  </span>
  <span *ngIf="!!config.isEditable && !config.disabled" class="card__edit-state">
    <a class="control control--border-none family-member__control">
      <span class="icon icon--edit"> </span>
    </a>
  </span>
  <span
    *ngIf="config.statusIcon"
    class="status-icon"
    [class.status-icon-green]="config.statusColor === 'green'"
    [title]="config.statusTooltip"
  >
    <span class="icon icon--{{ config.statusIcon }}"></span>
  </span>
</div>

<ng-template #imageFileTemplate>
  <div class="card__image">
    <img [src]="config.imagePath" />
  </div>
</ng-template>

<ng-template #defaultImageTemplate>
  <span *ngIf="config.icon" class="card__icon">
    <span
      *ngIf="!config.icon.endsWith('.svg')"
      class="icon icon--superlarge"
      [ngClass]="'icon--' + config.icon"
    >
    </span>
    <svg-icon
      *ngIf="config.icon.endsWith('.svg')"
      [src]="config.icon"
      class="svg-icon"
      [style.margin-top]="config.iconMarginTop"
    ></svg-icon>
  </span>
</ng-template>
