import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {selectAllAssets} from '@shared/analysis/store';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemsGroup} from 'src/app/modules/client/summary/store/summary.models';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-items-group',
  templateUrl: './items-group.component.html',
  styleUrls: ['./items-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsGroupComponent {
  @Input() itemsGroup: ItemsGroup;

  constructor(private store: Store<State>, private assetHelperService: AssetHelperService) {}

  get label(): Observable<string> {
    if (this.itemsGroup.assetUuid) {
      return this.store.pipe(
        select(selectAllAssets),
        map(assets => assets.find(asset => asset.assetUuid === this.itemsGroup.assetUuid)),
        map(asset => this.assetHelperService.getName(asset)),
        untilDestroyed(this),
      );
    }
    return of(this.itemsGroup.label);
  }
}
