import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@lib/pipes';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {FormModule, RecommendedValueModule} from '@shared/ui';
import {InsuranceValueFormlyWrapperComponent} from './insurance-value-formly-wrapper.component';

@NgModule({
  declarations: [InsuranceValueFormlyWrapperComponent],
  imports: [CommonModule, FormModule, BackendValidationModule, PipesModule, RecommendedValueModule],
  exports: [InsuranceValueFormlyWrapperComponent],
})
export class InsuranceValueFormlyWrapperModule {}
