import {UiSettingsAdvisor} from '@generated/defs/UiSettingsAdvisor';
import produce from 'immer';
import {cloneDeep} from 'lodash';
import {AdvisorUiSettingsAction, AdvisorUiSettingsActions} from './advisor-ui-settings.actions';
import {AdvisorUiSettingsState, initialAdvisorUiSettingsState} from './advisor-ui-settings.state';

const producer = (state: AdvisorUiSettingsState, action: AdvisorUiSettingsAction) =>
  AdvisorUiSettingsActions.match(action, {
    UI_SetAdvisorUiSettings: data => {
      return cloneDeep(data);
    },
    UI_ClearAdvisorUiSettings: () => {
      return {
        intro: {},
      } as UiSettingsAdvisor as unknown as void;
    },
    UI_UpdateIntroUiSettings: ({intro}) => {
      state.intro = {...state.intro, ...intro};
    },
    default: () => {},
  });

export function reducer(
  state = initialAdvisorUiSettingsState,
  action: AdvisorUiSettingsAction,
): AdvisorUiSettingsState {
  return produce(producer)(state, action);
}
