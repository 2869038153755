/* eslint-disable max-classes-per-file */
import {HousingDemand} from '@generated/defs/HousingDemand';
import {Action} from '@ngrx/store';
import {HousingOutput} from '../models/housing.models';

export enum HousingActionTypes {
  UpdateInput = '[Housing] Update Input',
  UpdateInputOutput = '[Housing] Update Input/Output',
}

export class UpdateHousingInput implements Action {
  readonly type = HousingActionTypes.UpdateInput;

  constructor(public payload: {partialInput: Partial<HousingDemand>; sliders: boolean}) {}
}

export class UpdateHousingInputOutput implements Action {
  readonly type = HousingActionTypes.UpdateInputOutput;

  constructor(public payload: {input: Partial<HousingDemand>; output: Partial<HousingOutput>}) {}
}

export type HousingActions = UpdateHousingInput | UpdateHousingInputOutput;
