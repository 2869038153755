import {
  ZzjInvestmentLossValue,
  ZzjInvestmentPortfolio,
  ZzjInvestmentPreference,
} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile-portal';
import {
  IndependenceInputMode,
  RetirementInputMode,
} from '@shared/analysis/forms/definitions.models';
import {
  AssetType,
  BaseAsset,
  DateAsset,
  DescriptionAsset,
  FamilyMemberAsset,
  NameAsset,
  RelatedPropertyAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {
  LifeInsurancePersonData,
  LifeInsuranceRisk,
} from '@shared/analysis/models/life-insurance-risks';
import {Risk} from '@shared/analysis/models/risks';

export enum OtherObjectivePaymentType {
  OneTime = 'oneTime',
  Month = 'month',
}

export interface ObjectiveAsset
  extends BaseAsset,
    ValueAsset,
    DateAsset,
    DescriptionAsset,
    NameAsset {
  generatedFromPriorities?: boolean;
  selected?: boolean;
  priority?: number;
}

export interface InvestmentObjectiveAsset extends ObjectiveAsset {
  investmentPreference?: ZzjInvestmentPreference;
  investmentPortfolio?: ZzjInvestmentPortfolio;
  investmentLossValue?: ZzjInvestmentLossValue;
}

export interface HousingAsset extends InvestmentObjectiveAsset {
  totalValue: number;
}

export interface OwnHousingAsset extends HousingAsset {
  type: AssetType.OwnHousing;
}

export interface ChildrenFutureAsset extends InvestmentObjectiveAsset {
  type: AssetType.ChildrenFuture;
}

export interface RetirementAsset extends ObjectiveAsset, FamilyMemberAsset {
  type: AssetType.Retirement;
  monthlyRent: number;
  retirementInputMode: RetirementInputMode;
  pensionDuration: number;
}

export interface FinancialIndependenceAsset extends InvestmentObjectiveAsset {
  type: AssetType.FinancialIndependence;
  monthlyRent: number;
  lifeExpectancy: number;
  independenceInputMode: IndependenceInputMode;
}

export interface ShortTermReserveAsset extends InvestmentObjectiveAsset {
  type: AssetType.ShortTermReserve;
}

export interface LongTermReserveAsset extends InvestmentObjectiveAsset {
  type: AssetType.LongTermReserve;
}

export interface BadDebtsRepaymentAsset extends InvestmentObjectiveAsset {
  type: AssetType.BadDebtsRepayment;
}

export interface FamilyProvisionAsset extends ObjectiveAsset {
  type: AssetType.FamilyProvision;

  insuranceAmount?: number;
  lifeInsuranceRisks: LifeInsuranceRisk[];
  lifeInsurancePersonDatas: LifeInsurancePersonData[];

  investmentPreference?: ZzjInvestmentPreference;
  investmentPortfolio?: ZzjInvestmentPortfolio;
  investmentLossValue?: ZzjInvestmentLossValue;
}

export interface PropertyProvisionAsset extends ObjectiveAsset {
  type: AssetType.PropertyProvision;

  requirementType?: RequirementType;
  contractPeriod?: ContractPeriod;
  contractPeriodCustom?: string;
  risks: Risk[];
  selectedAssets?: string[];
}

export interface VehicleProvisionAsset extends ObjectiveAsset {
  type: AssetType.VehicleProvision;

  requirementType?: RequirementType;
  contractPeriod?: ContractPeriod;
  contractPeriodCustom?: string;
  risks: Risk[];
  selectedAssets?: string[];
}

export interface ChildBirthAsset extends InvestmentObjectiveAsset {
  type: AssetType.ChildBirth;
  maternityFamilyMemberUuid: string;
  parentalFamilyMemberUuid: string;
  parentalBenefitInMonths: number;
}

export interface NewHousingAsset extends HousingAsset {
  type: AssetType.NewHousing;
}

export interface ReconstructionAsset extends HousingAsset {
  type: AssetType.Reconstruction;
}

export interface FurnishingsAsset extends InvestmentObjectiveAsset {
  type: AssetType.Furnishings;
}

export interface CarAsset extends InvestmentObjectiveAsset {
  type: AssetType.Car;
}

export interface VacationAsset extends InvestmentObjectiveAsset {
  type: AssetType.Vacation;
}

export interface OtherObjectiveAsset extends InvestmentObjectiveAsset {
  type: AssetType.OtherObjective;
  payment: OtherObjectivePaymentType;
  requirementType?: RequirementType;
}

export type ObjectivesAsset =
  | OwnHousingAsset
  | ChildrenFutureAsset
  | RetirementAsset
  | FinancialIndependenceAsset
  | ShortTermReserveAsset
  | LongTermReserveAsset
  | BadDebtsRepaymentAsset
  | FamilyProvisionAsset
  | PropertyProvisionAsset
  | VehicleProvisionAsset
  | ChildBirthAsset
  | NewHousingAsset
  | ReconstructionAsset
  | FurnishingsAsset
  | CarAsset
  | VacationAsset
  | OtherObjectiveAsset;

/**
 *
 */
export interface PropertyRequirementsAsset
  extends BaseAsset,
    DescriptionAsset,
    RelatedPropertyAsset {
  type: AssetType.PropertyRequirements;

  requirementType?: RequirementType;
  contractPeriod?: ContractPeriod;
  contractPeriodCustom?: string;
  risks: Risk[];
}

export interface VehicleRequirementsAsset
  extends BaseAsset,
    DescriptionAsset,
    RelatedPropertyAsset {
  type: AssetType.VehicleRequirements;

  requirementType?: RequirementType;
  contractPeriod?: ContractPeriod;
  contractPeriodCustom?: string;
  risks: Risk[];
}

export enum RequirementType {
  NewContract = 'NEW_CONTRACT',
  ChangeContract = 'CHANGE_CONTRACT',
  Other = 'OTHER',
}

export enum ContractPeriod {
  Indefinitely = 'INDEFINITELY',
  OneYear = 'ONE_YEAR',
  Custom = 'CUSTOM',
}

export const contractPeriodLabels: Record<ContractPeriod, string> = {
  [ContractPeriod.Indefinitely]: 'Na neurčito',
  [ContractPeriod.OneYear]: 'Na 1 rok',
  [ContractPeriod.Custom]: 'Jiné',
};

export const vehicleContractPeriods = [
  {key: ContractPeriod.Indefinitely, label: contractPeriodLabels[ContractPeriod.Indefinitely]},
  {key: ContractPeriod.OneYear, label: contractPeriodLabels[ContractPeriod.OneYear]},
  {key: ContractPeriod.Custom, label: contractPeriodLabels[ContractPeriod.Custom]},
];

export const propertyContractPeriods = [
  {key: ContractPeriod.Indefinitely, label: contractPeriodLabels[ContractPeriod.Indefinitely]},
  {key: ContractPeriod.OneYear, label: contractPeriodLabels[ContractPeriod.OneYear]},
  {key: ContractPeriod.Custom, label: contractPeriodLabels[ContractPeriod.Custom]},
];

// Requirements assets are related to objectives, but they are not themselves objectives. You can't add them to the list of
// the objectives. They are used to gather further information about other objectives, related to the specific movable or
// immovable property.
export type RequirementsAsset = PropertyRequirementsAsset | VehicleRequirementsAsset;
