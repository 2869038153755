import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store/index';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {risksNames} from 'src/app/services/risk-definitions';
import {State} from 'src/store';
import {RiskIdCamelCased} from 'src/store/models/risk.models';

@UntilDestroy()
@Component({
  selector: 'kpt-six-month-reserve-accordion',
  templateUrl: './six-month-reserve-accordion.component.html',
  styleUrls: ['./six-month-reserve-accordion.component.scss'],
})
export class SixMonthReserveAccordionComponent implements OnInit, OnDestroy {
  @Input()
  person: LifeInsurancePerson;

  labels = {
    hospitalization: risksNames[RiskIdCamelCased.Hospitalization],
    incapacity: risksNames[RiskIdCamelCased.Incapacity],
    dailyCompensation: risksNames[RiskIdCamelCased.DailyCompensation],
    physicalDamage: risksNames[RiskIdCamelCased.PhysicalDamage],
  };

  formGroup = new UntypedFormGroup({
    hospitalization: new UntypedFormControl(),
    incapacity: new UntypedFormControl(),
    dailyCompensation: new UntypedFormControl(),
    physicalDamage: new UntypedFormControl(),
  });

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.formGroup.setValue(this.person.sixMonthReserve);

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.store.dispatch(
        LifeInsuranceActions.updateSixMonthReserve({
          personId: this.person.id,
          sixMonthReserve: value,
        }),
      );
    });
  }

  ngOnDestroy() {}

  toggleUseSixMonthReserve() {
    this.store.dispatch(
      LifeInsuranceActions.useSixMonthReserve({
        personId: this.person.id,
        use: !this.person.useSixMonthReserve,
      }),
    );
  }
}
