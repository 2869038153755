import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {Asset, ValueAsset} from '@shared/analysis/models/asset';
import {CurrentAccountAsset} from '@shared/analysis/models/financial-properties';
import {selectProperties} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

export const selectFinancialCategory = createSelector(selectProperties, properties =>
  createCategory(properties.financialProperties),
);

function createCategory(financial: AssetPageStateValue): Category {
  return {
    label: financial.title,
    value: formatCZKNotNull(financial.sum),
    categoryTypes: [createCategoryType(financial.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull((asset as ValueAsset).value),
    items: createItems(asset as CurrentAccountAsset).filter(Boolean),
  };
}

function createItems(asset: CurrentAccountAsset): Item[] {
  return [
    createItem(FieldLabel.FINANCIAL_PROPERTY_START, formatDate(asset.start)),
    createItem(FieldLabel.YEARLY_RATE, asset.yearlyRate && `${asset.yearlyRate * 100}&nbsp;%`),
    createItem(FieldLabel.INSTITUTION_NAME, asset.institutionName),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}
