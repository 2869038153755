import {Injectable} from '@angular/core';
import {SharedService} from '@generated/controllers/Shared';
import {NewInvestmentQuestionnaire} from '@generated/model';
import {select, Store} from '@ngrx/store';
import {emptyAnswers} from '@shared/investment-survey-old/models/answer';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {InvestmentSurveyActions, selectAllSurveyAnswers} from '@shared/investment-survey-old/store';
import {isEqual} from 'lodash';
import * as moment from 'moment';
import {Observable, of, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {State} from 'src/store';

const DEBOUNCE_TIME = 200;

@Injectable()
export class InvestmentSurveyDataService {
  private cancel$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private sharedService: SharedService,
    private questions: Questions,
  ) {}

  async loadDataAndSaveChanges(familyId: string): Promise<void> {
    this.stop();
    await this.loadData(familyId);
    this.listenToChanges(familyId);
  }

  stop() {
    this.cancel$.next();
  }

  async loadData(familyId: string): Promise<void> {
    const surveyAnswers = await (
      this.sharedService.getNewInvestmentQuestionnaire({
        family_uuid: familyId,
      }) as unknown as Observable<SurveyAnswers[]>
    ).toPromise();
    this.checkAge(surveyAnswers);
    this.store.dispatch(InvestmentSurveyActions.IS_SetData({surveyAnswers}));
  }

  private checkAge(surveyAnswers: SurveyAnswers[]): void {
    const allQuestions = this.questions.allQuestions();
    surveyAnswers.forEach(survey => {
      const beforeSixMonths = moment().subtract(6, 'months');

      const investmentExpired = moment(survey.lastUpdate.investment).isBefore(beforeSixMonths);
      const lifeInsuranceExpired = moment(survey.lastUpdate.lifeInsurance).isBefore(
        beforeSixMonths,
      );
      const supplementaryPensionSavingsExpired = moment(
        survey.lastUpdate.supplementaryPensionSavings,
      ).isBefore(beforeSixMonths);

      if (!investmentExpired && !lifeInsuranceExpired && !supplementaryPensionSavingsExpired)
        return;
      if (investmentExpired && lifeInsuranceExpired && supplementaryPensionSavingsExpired) {
        survey.answers = emptyAnswers();
        return;
      }

      const questionsToDelete = allQuestions.filter(
        question =>
          ![
            question.supplementaryPensionSavings && !supplementaryPensionSavingsExpired,
            question.investment && !investmentExpired,
            question.lifeInsurance && !lifeInsuranceExpired,
          ].includes(true),
      );
      questionsToDelete.forEach(question => delete survey.answers[question.id]);
    });
  }

  private listenToChanges(familyId: string) {
    this.store
      .pipe(
        select(selectAllSurveyAnswers),
        skip(1), // skip once subscribed to store
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged<SurveyAnswers[]>(isEqual),
        takeUntil(this.cancel$),
        switchMap(surveyAnswers => {
          return this.sharedService
            .createUpdateNewInvestmentQuestionnaire({
              family_uuid: familyId,
              data: surveyAnswers as unknown as NewInvestmentQuestionnaire[],
            })
            .pipe(
              catchError(error => {
                console.error('Failed to save investment questionnaire:', error);
                return of();
              }),
            );
        }),
      )
      .subscribe();
  }
}
