import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {FoundPortalClient} from '@generated/defs/FoundPortalClient';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {CustomValidators} from '@shared/utils';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {State} from 'src/store';
import {AddExistingFamilyMemberStart} from 'src/store/actions/family-members.actions';

@UntilDestroy()
@Component({
  selector: 'kpt-reassign',
  templateUrl: './reassign.component.html',
  styleUrls: ['./reassign.component.scss'],
})
export class ReassignComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    birthNumber: new UntypedFormControl(null, [
      Validators.required,
      CustomValidators.birthNumber(),
    ]),
  });

  title: string;
  data: {
    client: FoundPortalClient;
    familyUuid: string;
    addToFamily: boolean;
  };
  wrongBirthNumber = false;
  addToFamily = false;

  constructor(
    private store: Store<State>,
    private integrationsService: IntegrationsService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.wrongBirthNumber = false));
  }

  ngOnDestroy() {}

  reassign() {
    markFormGroupTouched(this.form);
    if (!this.form.valid) return;
    const birthNumber = this.form.get('birthNumber').value;
    const clientId = this.data.client.sugarUuid;
    this.integrationsService
      .createAssignment({sugar_uuid: clientId, data: {birthNumber}})
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          if (this.data.addToFamily) {
            this.addClientToFamily();
          } else {
            this.clearAndNavigate(clientId);
          }
        },
        error => (this.wrongBirthNumber = error.error.detail === 'Bad birth number'),
      );
  }

  private clearAndNavigate(clientId: string) {
    this.integrationsService
      .getClient({sugar_uuid: clientId})
      .pipe(untilDestroyed(this))
      .subscribe(portalClient => {
        this.modalService.openModal({
          component: 'FamiliesModalComponent',
          data: portalClient,
        });
      });
  }

  private addClientToFamily() {
    this.store.dispatch(
      new AddExistingFamilyMemberStart({
        sugar_uuid: this.data.client.sugarUuid,
        family_uuid: this.data.familyUuid,
        type: 'parent',
      }),
    );
    this.modalService.closeModal();
  }
}
