import {Component, forwardRef, Input, TemplateRef} from '@angular/core';
import {ITooltipDirection} from '@lib/components';
import {IOption} from '@shared/ui/forms/models/option.model';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {CommonFieldComponent} from '../common-field/common-field.component';

@Component({
  selector: 'kpt-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    ...getProvidersFor(RadioGroupComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => RadioGroupComponent)},
  ],
})
export class RadioGroupComponent extends CommonFieldComponent {
  optionsList: IOption[] = [];

  @Input()
  itemClass: string;

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  appendHelpText: string;

  @Input()
  appendHelpTextDirection?: ITooltipDirection = ITooltipDirection.TOP;

  @Input()
  set options(list: (IOption | string)[]) {
    if (!list) return;
    if (!list.length || typeof list[0] !== 'string') {
      this.optionsList = [...(list as IOption[])];
      return;
    }
    this.optionsList = list.map(option => ({key: option as string, label: option as string}));
  }
  get options() {
    return this.optionsList;
  }

  labelClick(key: any) {
    this._value = key;
    this.onChange(key);
    this.change.emit(key);
  }

  optionKey(_index: number, option: IOption) {
    return option.key;
  }
}
