import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {SliderComponent} from '@shared/ui/slider/slider.component';

@NgModule({
  declarations: [SliderComponent],
  imports: [CommonModule, NgxSliderModule, ReactiveFormsModule],
  exports: [SliderComponent],
})
export class SliderModule {}
