import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {LabelType} from '@angular-slider/ngx-slider';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {FieldLabel} from '@shared/analysis/field-label';
import {defaultObjectiveMaxValue} from '@shared/analysis/forms/objectives.helpers';
import {
  OtherObjectiveAsset,
  OtherObjectivePaymentType,
  RequirementType,
} from '@shared/analysis/models/objectives';
import {getYearsMonthsPluralString} from '@shared/lib';
import {FormModule, Slider2Component, TypedOption} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@Component({
  standalone: true,
  selector: 'kpt-other-objective-form',
  templateUrl: './other-objective-form.component.html',
  styleUrls: ['other-objective-form.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    Slider2Component,
    InvestmentRiskProfileComponent,
    SwitchModule,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class OtherObjectiveFormComponent {
  @Input() asset: OtherObjectiveAsset;
  @Input() disabled: boolean;

  paymentOptions: TypedOption<OtherObjectivePaymentType>[] = [
    {
      label: FieldLabel.OBJECTIVE_ONE_TIME_PAYMENT,
      key: OtherObjectivePaymentType.OneTime,
    },
    {
      label: FieldLabel.OBJECTIVE_MONTHLY_PAYMENT,
      key: OtherObjectivePaymentType.Month,
    },
  ];

  yearMin = new Date().getFullYear();
  yearMax = new Date().getFullYear() + 50;

  defaultObjectiveMaxValue = defaultObjectiveMaxValue;
  OtherObjectivePaymentType = OtherObjectivePaymentType;
  RequirementType = RequirementType;

  constructor(private assetForm: AssetFormComponent<any>) {}

  dateToYear(date: string): number {
    return new Date(date).getFullYear();
  }

  yearToDate(year: number): string {
    return `${year}-01-01`;
  }

  sliderTranslate(value: number, label: LabelType) {
    const duration = value - new Date().getFullYear();
    switch (label) {
      case LabelType.Low:
        return `za ${getYearsMonthsPluralString(duration * 12)} (${value})`;
      case LabelType.High:
        return `do ${getYearsMonthsPluralString(duration * 12)} (${value})`;
      case LabelType.Floor:
      case LabelType.Ceil:
        return duration + ' let';
      default:
        return value.toString();
    }
  }

  changeRequirementType(requirementType: RequirementType, e: MouseEvent) {
    e.preventDefault();
    if (this.disabled) return;

    if (this.asset.requirementType === requirementType) {
      this.asset.requirementType = null;
    } else {
      this.asset.requirementType = requirementType;
    }

    this.assetForm.emitChange(this.asset);
  }
}
