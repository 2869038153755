import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BadDebtsRepaymentFormComponent} from '@shared/analysis/asset-types/bad-debts-repayment/bad-debts-repayment-form.component';
import {CarFormComponent} from '@shared/analysis/asset-types/car/car-form.component';
import {ChildBirthFormComponent} from '@shared/analysis/asset-types/child-birth/child-birth-form.component';
import {ChildrenFutureFormComponent} from '@shared/analysis/asset-types/children-future/children-future-form.component';
import {FamilyProvisionFormComponent} from '@shared/analysis/asset-types/family-provision/family-provision-form.component';
import {FinancialIndependenceFormComponent} from '@shared/analysis/asset-types/financial-independence/financial-independence-form.component';
import {FurnishingsFormComponent} from '@shared/analysis/asset-types/furnishings/furnishings-form.component';
import {LongTermReserveFormComponent} from '@shared/analysis/asset-types/long-term-reserve/long-term-reserve-form.component';
import {NewHousingFormComponent} from '@shared/analysis/asset-types/new-housing/new-housing-form.component';
import {OtherObjectiveFormComponent} from '@shared/analysis/asset-types/other-objective/other-objective-form.component';
import {OwnHousingFormComponent} from '@shared/analysis/asset-types/own-housing/own-housing-form.component';
import {PropertyProvisionFormComponent} from '@shared/analysis/asset-types/property-provision/property-provision-form.component';
import {PropertyRequirementsFormComponent} from '@shared/analysis/asset-types/property-requirements/property-requirements-form.component';
import {ReconstructionFormComponent} from '@shared/analysis/asset-types/reconstruction/reconstruction-form.component';
import {RetirementFormComponent} from '@shared/analysis/asset-types/retirement/retirement-form.component';
import {ShortTermReserveFormComponent} from '@shared/analysis/asset-types/short-term-reserve/short-term-reserve-form.component';
import {VacationFormComponent} from '@shared/analysis/asset-types/vacation/vacation-form.component';
import {VehicleFormComponent} from '@shared/analysis/asset-types/vehicle/vehicle-form.component';
import {VehicleProvisionFormComponent} from '@shared/analysis/asset-types/vehicle-provision/vehicle-provision-form.component';
import {VehicleRequirementsFormComponent} from '@shared/analysis/asset-types/vehicle-requirements/vehicle-requirements-form.component';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {FormModule} from '@shared/ui';
import {cloneDeep, isEqual} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, skip} from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-asset-form-ng-model',
  templateUrl: './asset-form-ng-model.component.html',
  imports: [
    CommonModule,
    FormsModule,
    VehicleProvisionFormComponent,
    VehicleRequirementsFormComponent,
    VehicleFormComponent,
    FormModule,
    PropertyProvisionFormComponent,
    PropertyRequirementsFormComponent,
    BadDebtsRepaymentFormComponent,
    OtherObjectiveFormComponent,
    CarFormComponent,
    ChildBirthFormComponent,
    ChildrenFutureFormComponent,
    FinancialIndependenceFormComponent,
    FurnishingsFormComponent,
    LongTermReserveFormComponent,
    NewHousingFormComponent,
    OwnHousingFormComponent,
    ReconstructionFormComponent,
    ShortTermReserveFormComponent,
    VacationFormComponent,
    RetirementFormComponent,
    FamilyProvisionFormComponent,
  ],
})
export class AssetFormNgModelComponent<T extends Asset> implements OnInit, OnChanges {
  @Input() value: T;
  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Input() disabled = false;
  @Input() showRelatedAssetHeader: boolean;
  @Input() selectedPersonId: string;
  @Input() mode: 'analysis' | 'plan' | 'summary' = 'analysis';
  @Input() showPersonSelector = true;

  value$ = new BehaviorSubject<T>(null);

  /**
   * When we receive a read-only `value`, we turn it into a writable model and use 2-way data binding to edit it.
   */
  model: T;

  AssetType = AssetType;

  @ViewChild('form', {static: true}) form: NgForm;

  ngOnInit() {
    this.value$.pipe(untilDestroyed(this)).subscribe(value => {
      this.model = cloneDeep(value);
    });

    const formChange$ = this.form.valueChanges.pipe(
      debounceTime(0), // https://github.com/angular/angular/issues/24818
      distinctUntilChanged(isEqual),
      skip(1), // skip initial value
      untilDestroyed(this),
    );
    formChange$.subscribe(() => {
      this.valueChange.next(this.getValue());
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (!isEqual(changes.value.currentValue, this.value$.value)) {
        this.value$.next(cloneDeep(changes.value.currentValue));
      }
    }

    if (changes.disabled) {
      setTimeout(() => {
        changes.disabled.currentValue ? this.form.form.disable() : this.form.form.enable();
      });
    }
  }

  setSubmitted() {
    this.form.form.markAllAsTouched();
  }

  isValid(): boolean {
    return this.form.valid;
  }

  getValue(): T {
    if (!this.model?.type) {
      throw new Error(`'AssetFormComponent: no asset type on value`);
    }
    return cloneDeep(this.model);
  }

  patchValue(changes: Partial<T>) {
    const newValue = {...this.model, ...changes};
    this.value$.next(newValue);
  }
}
