<div class="row">
  <div class="col-12 mt-5" *ngFor="let person of persons$ | async">
    <h3>Zajištění příjmů &ndash; {{ person.name }}</h3>
    <kpt-asset-form
      *ngIf="familyProvision$ | async as familyProvision"
      [showRelatedAssetHeader]="false"
      [value]="familyProvision"
      [disabled]="true"
      [selectedPersonId]="person.id"
      mode="summary"
    ></kpt-asset-form>
  </div>
</div>
