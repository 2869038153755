<div class="modal-header">
  <div class="row">
    <div class="col">
      <h3 class="modal-title">Klient požaduje</h3>
    </div>
  </div>
</div>
<div class="modal-body">
  <form
    *ngIf="data.insuranceType === InsuranceType.Investment"
    [formGroup]="clientRequirementsForm"
  >
    <div class="row">
      <div class="col">
        <kpt-checkbox label="Daňové odpočty" formControlName="taxRelief"></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['employeeContribution']"
          formControlName="employeeContribution"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['highProtection']"
          formControlName="highProtection"
        ></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['investmentOption']"
          formControlName="investmentOption"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['investmentOptionWithProtection']"
          formControlName="investmentOptionWithProtection"
        ></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['risksCovered']"
          formControlName="risksCovered"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['bestRatio']"
          formControlName="bestRatio"
        ></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['bestPrice']"
          formControlName="bestPrice"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['sixMonthReserve']"
          formControlName="sixMonthReserve"
        ></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['paymentProtectionInsurance']"
          formControlName="paymentProtectionInsurance"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['bestReturn']"
          formControlName="bestReturn"
        ></kpt-checkbox>
      </div>
      <div class="col other-requirement">
        <kpt-checkbox [label]="'&nbsp;'" formControlName="otherRequirement"></kpt-checkbox>
        <kpt-input
          [formControl]="clientRequirementsForm.get('otherRequirementText')"
          label="Jiný požadavek"
        ></kpt-input>
      </div>
    </div>
  </form>
  <form *ngIf="data.insuranceType === InsuranceType.Risk" [formGroup]="clientRequirementsForm">
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['sixMonthReserve']"
          formControlName="sixMonthReserve"
        ></kpt-checkbox>
      </div>
      <div class="col">
        <kpt-checkbox
          [label]="requirements['paymentProtectionInsurance']"
          formControlName="paymentProtectionInsurance"
        ></kpt-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <kpt-checkbox
          [label]="requirements['highProtection']"
          formControlName="highProtection"
        ></kpt-checkbox>
      </div>
      <div class="col other-requirement">
        <kpt-checkbox [label]="'&nbsp;'" formControlName="otherRequirement"></kpt-checkbox>
        <kpt-input
          [formControl]="clientRequirementsForm.get('otherRequirementText')"
          label="Jiný požadavek"
        ></kpt-input>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <kpt-button (action)="save()" label="Uložit" type="primary"> </kpt-button>
</div>
