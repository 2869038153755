import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';
import {SelectComponent} from '@shared/ui/forms/select/select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, FieldErrorModule, FormsModule],
  exports: [SelectComponent],
})
export class SelectModule {}
