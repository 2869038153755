<kpt-accordion-group
  [headingTemplate]="pensionEnsurementHeading"
  [preventDefault]="true"
  [isOpen]="formGroup.get('pensionEnsurement').value && !person.child"
  [formGroup]="formGroup"
>
  <ng-template #pensionEnsurementHeading>
    <kpt-switch
      formControlName="pensionEnsurement"
      customFormGroupClass="form-group--full-width-switch"
      [checked]="formGroup.get('pensionEnsurement').value"
      [disabledField]="readonly"
      label="Chci se zajistit na penzi"
    >
    </kpt-switch>
  </ng-template>

  <div *ngIf="!person.child">
    <div class="mb-6">
      Můj starobní důchod bude <strong>{{ pension | currencyCZK }}</strong> měsíčně. <br />
      Průměrné měsíční výdaje důchodce v&nbsp;ČR jsou přibližně
      <strong>{{ averagePension | currencyCZK }}</strong
      >.
      <kpt-help class="pl-3 hidden-print" [text]="pensionEnsurementHelp"></kpt-help>
    </div>

    <div><strong>Chci se zajistit:</strong></div>
    <kpt-radio-group
      formControlName="pensionEnsurementType"
      [disabledField]="readonly"
      [options]="ensurementTypes"
    >
    </kpt-radio-group>
  </div>
</kpt-accordion-group>
