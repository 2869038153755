import {NgModule} from '@angular/core';
import {FormsSharedModule} from '@generated/store/forms-shared.module';
import {FormModule, HelpModule} from '@shared/ui';
import {ShowMoreModule} from '@shared/ui/show-more/show-more.module';
import {SurveyParticipantsComponent} from './survey-participants/survey-participants.component';
import {SurveyStepComponent} from './survey-step/survey-step.component';
import {SurveyStepQuestionComponent} from './survey-step-question/survey-step-question.component';

@NgModule({
  declarations: [SurveyStepComponent, SurveyStepQuestionComponent, SurveyParticipantsComponent],
  exports: [SurveyStepComponent, SurveyStepQuestionComponent, SurveyParticipantsComponent],
  imports: [FormsSharedModule, FormModule, HelpModule, ShowMoreModule],
})
export class SurveyModule {}
