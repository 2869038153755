import {Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent} from 'rxjs';
import {filter} from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[kptOffClick]',
})
export class OffClickDirective implements OnDestroy {
  @Input()
  offClickEnabled = true;

  @Output()
  kptOffClick: EventEmitter<Event> = new EventEmitter<Event>();

  readonly documentBodyElement: HTMLElement;
  readonly baseElement: HTMLElement;

  constructor(private el: ElementRef) {
    this.baseElement = this.el.nativeElement;
    this.documentBodyElement = document.body;
    fromEvent(document, 'mousedown')
      .pipe(
        filter(() => this.offClickEnabled),
        untilDestroyed(this),
      )
      .subscribe((event: Event) => this.clickHandler(event.target, event));
  }

  ngOnDestroy() {}

  private clickHandler(currentElement: any, event: Event): void {
    if (currentElement === this.documentBodyElement || currentElement == null) {
      this.kptOffClick.emit(event);
      return;
    }

    if (currentElement === this.baseElement) {
      return;
    }

    this.clickHandler(currentElement.parentElement, event);
  }
}
