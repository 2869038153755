import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ModalService} from '@shared/lib/components/modal/services/modal.service';
import {isEqual} from 'lodash';
import {distinctUntilChanged} from 'rxjs/operators';
import {CancelMeetingData, ReasonType} from './cancel-meeting.model';

@UntilDestroy()
@Component({
  selector: 'kpt-cancel-meeting-modal',
  templateUrl: './cancel-meeting-modal.component.html',
  styleUrls: ['./cancel-meeting-modal.component.scss'],
})
export class CancelMeetingModalComponent implements OnInit, OnDestroy {
  ReasonType = ReasonType;

  form = new UntypedFormGroup({
    reason: new UntypedFormControl(undefined, Validators.required),
    otherReasonText: new UntypedFormControl(),
  });

  title: string;
  data: CancelMeetingData;
  meetingCanceled = false;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged<{reason: string; otherReasonText: string}>(isEqual),
      )
      .subscribe(form => this.updateValidation(form.reason));
  }

  ngOnDestroy(): void {}

  sendERecord() {
    this.meetingCanceled = true;
    this.data.sendERecord(this.form.value);
  }

  closeModal(redirect = false) {
    if (redirect) this.data.redirect();
    this.modalService.closeModal();
  }

  private updateValidation(reason: string) {
    if (reason === ReasonType.OTHER_REASON) {
      this.form.get('otherReasonText').enable();
      this.form.get('otherReasonText').setValidators(Validators.required);
    } else {
      this.form.get('otherReasonText').setValue(null);
      this.form.get('otherReasonText').disable();
    }

    this.form.get('otherReasonText').updateValueAndValidity();
  }
}
