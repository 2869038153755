import {mergeWith} from 'lodash';

export function isFunction(value: any) {
  return typeof value === 'function';
}

export function extendFunction(
  newFn: (...args: any[]) => void,
  oldFn?: (...args: any[]) => void,
): (...args: any[]) => void {
  return (...args) => {
    if (isFunction(oldFn)) oldFn(...args);
    newFn(...args);
  };
}

export function updateObject<T>(object: T, update: Partial<T>): T {
  return mergeWith({...object}, update, (__, value) => (Array.isArray(value) ? value : undefined));
}

export function hasNonDefaultValues<T>(
  obj: T,
  keys: (keyof T)[] = Object.keys(obj || {}) as (keyof T)[],
) {
  return keys.some(k => Boolean(obj[k]));
}

export function stripHtmlTags(string: string) {
  return string.replace(/<\/?[^>]+(>|$)/g, '');
}
