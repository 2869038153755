import {functionTable} from '@shared/analysis/functions';
import {dateAdd, dateDiff, getEnd, getPeriodsCount} from '@shared/analysis/helpers';
import {Asset, AssetType, DateAsset} from '@shared/analysis/models/asset';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {
  AnalysisContext,
  ConstantFunction,
  Frequency,
  FunctionOperation,
  Liquidity,
  Unit,
  UnitType,
  ValueTable,
} from '@shared/analysis/models/unit';
import {transformAssetsToUnits} from '@shared/analysis/transformations';
import {cloneDeep, flatMap, sortBy} from 'lodash';
import * as moment from 'moment';
import {ResultView} from 'src/app/modules/financial-analysis/sandbox/sandbox.models';

/**
 * Calculates current monthly cashflow.
 */
export function calculateMonthlySum(assets: Asset[]): number {
  const now = moment().format('YYYY-MM-DD');
  return calculateMonthlyCashflow(now, assets);
}

/**
 * Calculates monthly cashflow for given date.
 */
export function calculateMonthlyCashflow(start: string, assets: Asset[]): number {
  assets = omitFutureAssets(assets, start);
  assets = removeEnds(assets);
  let units = transformAssetsToUnits(assets);
  units = omitTransferFunctions(units);

  const end = dateAdd(start, 1, Frequency.Year).format('YYYY-MM-DD');
  const result = calculateChart(
    start,
    end,
    units,
    Frequency.Year,
    [Liquidity.High],
    ResultView.Cashflow,
  );
  return result[1][1] / 12;
}

/**
 * Calculates current equity.
 */
export function calculateCurrentEquity(assets: Asset[]): number {
  const now = moment().format('YYYY-MM-DD');
  return calculateEquity(now, assets);
}

/**
 * Calculates equity for given date.
 */
export function calculateEquity(start: string, assets: Asset[]): number {
  let units = transformAssetsToUnits(assets);
  units = omitTransferFunctions(units);
  units = setupCurrentBalance(units);
  units = moveCurrentBalanceToLowLiquidity(units);

  const end = dateAdd(start, 1, Frequency.Year).format('YYYY-MM-DD');
  const result = calculateChart(
    start,
    end,
    units,
    Frequency.Year,
    [Liquidity.Low],
    ResultView.Equity,
  );
  return result[0][1];
}

/**
 * Evaluate given units for date range from `start` to `end` in given frequency and liquidities.
 * Result is returned either as equity or cashflow based on the `resultView` parameter.
 */
export function calculateChart(
  start: string,
  end: string,
  units: Unit[],
  frequency: Frequency,
  liquidities: Liquidity[],
  resultView: ResultView,
): ValueTable {
  const startDate = moment(start);
  const endDate = moment(end);

  const periods = dateDiff(endDate, startDate, frequency);
  const result: ValueTable = [];
  let previousValue = NaN;

  for (let i = 0; i <= periods; i++) {
    const time = dateAdd(startDate, i, frequency);
    let value = evaluateUnits(units, time, liquidities);

    if (resultView === ResultView.Cashflow) {
      if (isNaN(previousValue)) {
        [value, previousValue] = [null, value];
      } else {
        [value, previousValue] = [value - previousValue, value];
      }
    }

    result.push([time, value]);
  }

  return result;
}

export function setupCurrentBalance(units: Unit[]): Unit[] {
  units = cloneDeep(units);

  const sortedCurrentBalanceUnits = sortBy(
    units.filter(unit => unit.type === UnitType.CurrentBalance),
    unit => unit.functions[0].start,
  );

  let sum = 0;
  sortedCurrentBalanceUnits.forEach(
    unit => (sum = (unit.functions[0] as ConstantFunction).params.value += sum),
  );

  sortedCurrentBalanceUnits.forEach(currentBalanceUnit => {
    const unitsWithoutCurrentBalance = units.filter(unit => unit !== currentBalanceUnit);

    const adjustedStart = dateAdd(currentBalanceUnit.functions[0].start, 1, Frequency.Day);

    const equityAtTimeOfCurrentBalance = evaluateUnits(
      unitsWithoutCurrentBalance,
      moment(adjustedStart),
      [Liquidity.High],
    );

    (currentBalanceUnit.functions[0] as ConstantFunction).params.value -=
      equityAtTimeOfCurrentBalance;
  });

  return units;
}

export function omitTransferFunctions(units: Unit[]): Unit[] {
  return units.map(unit => ({
    ...unit,
    functions: unit.functions.filter((fn: ConstantFunction) => !fn.transfer),
  }));
}

function omitFutureAssets(assets: Asset[], start: string): Asset[] {
  return cloneDeep(assets).filter(asset => !moment((asset as DateAsset).start).isAfter(start));
}

function removeEnds(assets: Asset[]): Asset[] {
  return cloneDeep(assets).map(asset => {
    (asset as DateAsset).end = null;
    return asset;
  });
}

export function moveCurrentBalanceToLowLiquidity(units: Unit[]): Unit[] {
  return units.map(unit => {
    if (unit.type === UnitType.CurrentBalance) {
      return {
        ...unit,
        functions: [{...unit.functions[0], liquidity: Liquidity.Low}],
      };
    } else return unit;
  });
}

/**
 * Computes value of a function in a given date
 */
export function evaluateUnits(
  units: Unit[],
  date: moment.Moment,
  liquidities: Liquidity[] = [Liquidity.Low, Liquidity.High],
) {
  return units.reduce((prev, unit) => {
    return prev + evaluateUnit(unit, date, liquidities, units);
  }, 0);
}

/**
 * Computes value of a function in a given date
 *
 * @param unit to compute
 * @param date to which a values are computed
 * @param liquidities to use
 * @param units all user units
 */
export function evaluateUnit(
  unit: Unit,
  date: moment.Moment,
  liquidities: Liquidity[],
  units: Unit[],
) {
  let value = 0;

  unit.functions
    .filter(fn => liquidities.includes(fn.liquidity))
    .forEach(fn => {
      const start = moment(fn.start);
      if (date < start) return; // no value before start

      const end = getEnd(fn, date);

      const periods = getPeriodsCount(start, end, fn.frequency);

      const context: AnalysisContext = {
        unit,
        start,
        end,
        periods,
        units,
        date,
      };

      let newValue = functionTable[fn.type](fn, context);

      if (isNaN(newValue)) newValue = 0;

      value = addValue(value, newValue, fn.operation);
    });

  return value;
}

function addValue(
  currentValue: number,
  newValue: number,
  operation: FunctionOperation = FunctionOperation.Add,
) {
  if (operation === FunctionOperation.Add) return currentValue + newValue;
  return newValue;
}

export function getUnitFunctions(units: Unit[]) {
  return flatMap(units, a => a.functions);
}

export const inflationValue = 1.02;

export function inflationInYears(
  finances: number,
  years: number,
  inflation: number = inflationValue,
) {
  return finances * (1 - 1 / inflation ** years);
}

/**
 * Applies inflation to a given function
 *
 * @param fn function to apply inflation to
 * @param yearly_inflation
 *
 * infation will be additional layer
 * be careful about correct implementation via purchase power `(1 + nominal) / (1 + inflation) - 1`
 * i.e. infl. 5 %, interest 7 %:
 * today 1000 generates 1070 in a year, has to be 1050, so value increases by 1070/1050 = 1.019047619, i.e 1.9 %
 * today 1000 generates 3870 in 20 years, has to be 2653, so value increases by 1.458, i.e 45.8 %, not 48.6 %
 */

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function inflate(chart: ValueTable, rate: number) {}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function valorize(chart: ValueTable, rate: number, period: Frequency) {}

export function adjustPropertiesWithLoans(assets: Asset[]): Asset[] {
  assets = cloneDeep(assets);
  for (const asset of assets) {
    if (asset.type === AssetType.Mortgage || asset.type === AssetType.BuildingSavingsLoan) {
      const propertyAsset = assets.find(
        a => a.assetUuid === asset.relatedPropertyUuid,
      ) as PropertyAsset;
      if (!propertyAsset) continue;
      if (asset.originalValue) {
        propertyAsset.value -= asset.originalValue;
      } else {
        propertyAsset.value -= asset.outstandingValue;
      }
    }
    if (
      asset.type === AssetType.Leasing ||
      asset.type === AssetType.ConsumerLoan ||
      asset.type === AssetType.OtherIndividualLoan
    ) {
      const propertyAsset = assets.find(
        a => a.assetUuid === asset.relatedPropertyUuid,
      ) as PropertyAsset;
      if (!propertyAsset) continue;
      propertyAsset.value -= asset.outstandingValue;
    }
  }
  return assets;
}
