export interface HousingOutput {
  monthlyRepayment: number;
  loanRecommendation: LoanRecommendation;
}

export enum LoanRecommendation {
  Mortgage = 'mortgage',
  BuildingSavings = 'buildingSavings',
  Both = 'both',
}
