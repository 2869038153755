import {ValidationErrors, ValidatorFn} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Asset, AssetType} from '../models/asset';

export type AssetValidators = Record<string, (ValidatorFn | ValidationErrors)[]>;
export type AssetValidatorsRules = Record<string, {name: string; comparator?: number}[]>;

export interface AssetDefinition {
  name: string;
  tooltip?: string;
  type: AssetType;
  /**
   * @deprecated Do not use formly, implement an asset form instead.
   */
  fields?: FormlyFieldConfig[];
  /**
   * @deprecated Do not use formly, implement an asset form instead.
   */
  validators?: AssetValidators;
  /**
   * @deprecated Do not use formly, implement an asset form instead.
   */
  customValidators?: Record<string, ValidatorFn[]>;
  /**
   * @deprecated Do not use formly, implement an asset form instead.
   */
  model?: any;
}

export interface RichAssetDefinition {
  asset: Asset;
  definition: AssetDefinition;
}

export type AssetFieldConfig<T> = {
  [key in keyof T]: FormlyFieldConfig;
};

export enum FinancialProductTab {
  Basic = 'basic',
  Extra = 'extra',
  PaymentInfo = 'paymentInfo',
}

export const tabNames: Record<FinancialProductTab, string> = {
  [FinancialProductTab.Basic]: 'Základní údaje',
  [FinancialProductTab.Extra]: 'Další údaje',
  [FinancialProductTab.PaymentInfo]: 'Platební údaje',
};

export enum RetirementInputMode {
  Value = 'value',
  Rent = 'rent',
}

export enum IndependenceInputMode {
  Value = 'value',
  Rent = 'rent',
}
