import {IOption} from '@shared/ui/forms';

export const defaultPerPageConfig: IOption[] = [
  {
    key: 12,
    label: 12,
  },
  {
    key: 24,
    label: 24,
  },
  {
    key: 36,
    label: 36,
  },
  {
    key: 48,
    label: 48,
  },
];
