import {NgModule} from '@angular/core';
// own classes
import {OffClickDirective} from '@shared/directives/off-click';
import {PartTemplateDirective} from '@shared/directives/part-template.directive';

@NgModule({
  declarations: [OffClickDirective, PartTemplateDirective],
  exports: [OffClickDirective, PartTemplateDirective],
})
export class DirectivesModule {}
