<div class="page" *ngIf="cardBoard$ | async as cardBoard">
  <kpt-card-action>
    <ng-template let-cardAction="cardAction">
      <ng-container *ngIf="financialDivision">
        <h2 class="heading mt-3">
          Řešíme komplexní finanční plán
          <kpt-intro-js class="intro-js" [scenario]="scenario"></kpt-intro-js>
        </h2>
        <div class="complex-plan">
          <div class="analysis">
            <div class="card-holder">
              <kpt-card
                [config]="cardBoard.complexPlan.mainCard"
                (action)="cardAction(cardBoard.complexPlan.mainCard)"
              ></kpt-card>
            </div>
          </div>
          <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
          <div class="plan">
            <div class="card-holder">
              <kpt-card
                [config]="cardBoard.complexPlan.cards[0]"
                (action)="cardAction(cardBoard.complexPlan.cards[0])"
              ></kpt-card>
            </div>
          </div>
          <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
          <div class="box current-plan">
            <ng-container
              *ngIf="
                businessCaseStatusDescription$ | async as businessCaseStatusDescription;
                else noPlan
              "
            >
              <div class="card-holder">
                <div class="card-wrapper">
                  <a (click)="cardAction(cardBoard.complexPlan.cards[0])">
                    <div>
                      <ng-container [ngSwitch]="currentPlanState()">
                        <img *ngSwitchCase="'preparation'" [src]="currentPlanImages.preparation" />
                        <ng-container *ngSwitchCase="'presentation'">
                          <img [src]="currentPlanImages.presentation" />
                          <p>Prezentovat Finanční plán</p>
                        </ng-container>
                        <ng-container *ngSwitchCase="'finished'">
                          <img [src]="currentPlanImages.finished" />
                          <p>Finanční plán uzavřen</p>
                        </ng-container>
                      </ng-container>
                    </div>
                  </a>
                </div>
                <p class="current-plan__description">
                  {{ businessCaseStatusDescription.description }}
                  <span style="white-space: nowrap"
                    >({{ businessCaseStatusDescription.timestamp | date }})</span
                  >
                </p>
              </div>
            </ng-container>
            <ng-template #noPlan>
              <div class="card-holder">
                <div class="card-wrapper">
                  <a [href]="currentPlanImages.noPlan" target="_blank">
                    <div>
                      <img [src]="currentPlanImages.noPlan" />
                    </div>
                  </a>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="finalizedBusinessCases$ | async as finalizedBusinessCases">
          <div *ngIf="finalizedBusinessCases.length > 0" class="previous-plans">
            <div class="card-holder">
              <kpt-previous-plans-card
                [finalizedBusinessCases]="finalizedBusinessCases"
              ></kpt-previous-plans-card>
            </div>
          </div>
        </ng-container>

        <h2 class="heading mt-6">Řešíme konkrétní životní situaci</h2>
        <div class="life-situation mb-8">
          <div class="simple-analysis">
            <kpt-card
              [config]="cardBoard.lifeSituation.mainCard"
              (action)="cardAction(cardBoard.lifeSituation.mainCard)"
            ></kpt-card>
          </div>
          <div class="arrow2"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
          <div class="box card-holder" *ngFor="let card of cardBoard.lifeSituation.cards">
            <div class="card-holder">
              <kpt-card [config]="card" (action)="cardAction(card)"></kpt-card>
            </div>
          </div>
        </div>

        <div class="other-tools">
          <h2 class="heading">Ostatní nástroje</h2>
          <div class="box card-holder" *ngFor="let card of cardBoard.otherTools.cards">
            <div class="card-holder">
              <kpt-card [config]="card" (action)="cardAction(card)"></kpt-card>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="goldDivision && !financialDivision">
        <h2 class="heading mt-3">Investujeme do zlata</h2>
        <div class="gold">
          <div class="analysis">
            <div class="card-holder">
              <kpt-card
                [config]="cardBoard.gold.mainCard"
                (action)="cardAction(cardBoard.gold.mainCard)"
              ></kpt-card>
            </div>
          </div>
          <div class="arrow1"><span class="icon icon--arrow-up2 arrow-icon"></span></div>
          <div class="gold-module">
            <div class="card-holder">
              <kpt-card
                [config]="cardBoard.gold.cards[0]"
                (action)="cardAction(cardBoard.gold.cards[0])"
              ></kpt-card>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </kpt-card-action>
</div>
