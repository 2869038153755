import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {RiskUnitLabelPipe} from '@shared/analysis/asset-types/common/risk-list-input/risk-unit-label.pipe';
import {AssetType} from '@shared/analysis/models/asset';
import {
  getRiskList,
  Risk,
  RiskListItem,
  setRiskActive,
  setRiskLimit,
} from '@shared/analysis/models/risks';
import {ToObservable} from '@shared/decorators/to-observable';
import {FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-risk-list-input',
  templateUrl: './risk-list-input.component.html',
  styleUrls: ['./risk-list-input.component.scss'],
  imports: [CommonModule, FormsModule, FormModule, FlexboxModule, SwitchModule, RiskUnitLabelPipe],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class RiskListInputComponent implements OnDestroy {
  @Input()
  @ToObservable()
  asset: {type: AssetType; risks: Risk[]};
  asset$!: Observable<{type: AssetType; risks: Risk[]}>;

  risks$ = this.asset$.pipe(
    map(asset => getRiskList(asset)),
    untilDestroyed(this),
  );

  setRiskActive = setRiskActive;
  setRiskLimit = setRiskLimit;

  ngOnDestroy() {}

  trackByKey(_index: number, risk: RiskListItem) {
    return risk.data.key;
  }
}
