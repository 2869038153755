import {groupBy, head, mapValues, uniq} from 'lodash';
import {
  Outlook,
  Provision,
  RiskDefinition,
  RiskId,
  RiskIdCamelCased,
} from 'src/store/models/risk.models';
import {
  childDailyCompensation,
  childHospitalization,
  childMinConstantValueDescription,
  childMinValueDescription,
  childPermanentDisability,
  childPhysicalDamage,
  childSeriousDisease,
  childStandardConstantValueDescription,
  childStandardValueDescription,
  childSurvival,
  childSurvivalDescription,
  dailyCompensation,
  dailyCompensationDescription,
  dailyCompensationExDescription,
  deathChildrenDescription,
  deathChildrenEnsurement,
  deathDebtDescription,
  deathDebtIncomeDescription,
  deathDebtRepayment,
  deathThriceAnnualIncome,
  deathThriceDescription,
  helpChildDailyCompensation,
  helpChildHospitalization,
  helpChildPermanentDisability,
  helpChildPhysicalDamage,
  helpChildSeriousDisease,
  helpChildSurvival,
  helpDailyCompensation,
  helpDeathChildrenEnsurement,
  helpDeathDebtRepayment,
  helpDeathThriceAnnualIncome,
  helpHospitalization,
  helpIncapacity,
  helpInvalidity,
  helpInvalidityDebtRepayment,
  helpPermanentDisability,
  helpPhysicalDamage,
  helpSeriousDisease,
  helpSurvival,
  hospitalization,
  hospitalizationDescription,
  incapacity,
  incapacityDescription,
  incapacityExDescription,
  invalidity,
  invalidityDebtDescription,
  invalidityDebtRepayment,
  invalidityDescription,
  invalidityExDescription,
  permanentDescription,
  permanentDisability,
  permanentExDescription,
  physicalDamage,
  physicalDamageDescription,
  physicalDamageExDescription,
  seriousDescription,
  seriousDisease,
  seriousExDescription,
  survival,
  survivalDescription,
} from './risk-functions';

/**
 * @deprecated
 */
export const riskLabels = {
  incapacityLabel: 'Pracovní neschopnost',
  compensationLabel: 'Denní odškodné',
  physicalDamageLabel: 'Tělesné poškození',
  hospitalizationLabel: 'Hospitalizace',
  seriousLabel: 'Vážná onemocnění',
  permanentLabel: 'Trvalé následky úrazu',
  invalidityLabel: 'Invalidita',
  invalidityDebtLabel: 'Invalidita – splacení úvěru',
  deathDebtLabel: 'Smrt – splacení úvěru',
  deathThriceLabel: 'Smrt – zajištění rodiny 3 roční příjmy',
  deathChildrenLabel: 'Smrt – zajištění dětí',
  survivalLabel: 'Dožití',
};

/**
 * @deprecated
 */
// risks by assetTypes
export const risksNames = {
  [RiskIdCamelCased.DailyCompensation]: riskLabels.compensationLabel,
  [RiskIdCamelCased.Incapacity]: riskLabels.incapacityLabel,
  [RiskIdCamelCased.PhysicalDamage]: riskLabels.physicalDamageLabel,
  [RiskIdCamelCased.Hospitalization]: riskLabels.hospitalizationLabel,
  [RiskIdCamelCased.SeriousDisease]: riskLabels.seriousLabel,
  [RiskIdCamelCased.PermanentDisability]: riskLabels.permanentLabel,
  [RiskIdCamelCased.Invalidity]: riskLabels.invalidityLabel,
  [RiskIdCamelCased.InvalidityDebtRepayment]: riskLabels.invalidityDebtLabel,
  [RiskIdCamelCased.DeathDebtRepayment]: riskLabels.deathDebtLabel,
  [RiskIdCamelCased.DeathThriceAnnualIncome]: riskLabels.deathThriceLabel,
  [RiskIdCamelCased.DeathChildrenEnsurement]: riskLabels.deathChildrenLabel,
  [RiskIdCamelCased.ChildDailyCompensation]: riskLabels.compensationLabel,
  [RiskIdCamelCased.ChildPhysicalDamage]: riskLabels.physicalDamageLabel,
  [RiskIdCamelCased.ChildHospitalization]: riskLabels.hospitalizationLabel,
  [RiskIdCamelCased.ChildSeriousDisease]: riskLabels.seriousLabel,
  [RiskIdCamelCased.ChildPermanentDisability]: riskLabels.permanentLabel,
  [RiskIdCamelCased.Survival]: riskLabels.survivalLabel,
  [RiskIdCamelCased.ChildSurvival]: riskLabels.survivalLabel,
};

/**
 * @deprecated
 */
export const riskDefinitions: RiskDefinition[] = [
  {
    id: RiskId.Incapacity,
    provision: Provision.Income,
    label: riskLabels.incapacityLabel,
    outlook: Outlook.Daily,
    calculate: incapacity,
    help: helpIncapacity,
    description: incapacityDescription,
  },
  {
    id: RiskId.Incapacity,
    provision: Provision.Expenses,
    label: riskLabels.incapacityLabel,
    outlook: Outlook.Daily,
    calculate: incapacity,
    help: helpIncapacity,
    description: incapacityExDescription,
  },
  {
    id: RiskId.DailyCompensation,
    provision: Provision.Income,
    label: riskLabels.compensationLabel,
    outlook: Outlook.Daily,
    calculate: dailyCompensation,
    help: helpDailyCompensation,
    description: dailyCompensationDescription,
  },
  {
    id: RiskId.DailyCompensation,
    provision: Provision.Expenses,
    label: riskLabels.compensationLabel,
    outlook: Outlook.Daily,
    calculate: dailyCompensation,
    help: helpDailyCompensation,
    description: dailyCompensationExDescription,
  },
  {
    id: RiskId.PhysicalDamage,
    provision: Provision.Income,
    label: riskLabels.physicalDamageLabel,
    outlook: Outlook.Same,
    calculate: physicalDamage,
    help: helpPhysicalDamage,
    description: physicalDamageDescription,
  },
  {
    id: RiskId.PhysicalDamage,
    provision: Provision.Expenses,
    label: riskLabels.physicalDamageLabel,
    outlook: Outlook.Same,
    calculate: physicalDamage,
    help: helpPhysicalDamage,
    description: physicalDamageExDescription,
  },
  {
    id: RiskId.Hospitalization,
    provision: Provision.Income,
    label: riskLabels.hospitalizationLabel,
    outlook: Outlook.Daily,
    calculate: hospitalization,
    help: helpHospitalization,
    description: hospitalizationDescription,
  },
  {
    id: RiskId.Hospitalization,
    provision: Provision.Expenses,
    label: riskLabels.hospitalizationLabel,
    outlook: Outlook.Daily,
    calculate: hospitalization,
    help: helpHospitalization,
    description: hospitalizationDescription,
  },
  {
    id: RiskId.SeriousDisease,
    provision: Provision.Income,
    label: riskLabels.seriousLabel,
    outlook: Outlook.Same,
    calculate: seriousDisease,
    help: helpSeriousDisease,
    description: seriousDescription,
  },
  {
    id: RiskId.SeriousDisease,
    provision: Provision.Expenses,
    label: riskLabels.seriousLabel,
    outlook: Outlook.Same,
    calculate: seriousDisease,
    help: helpSeriousDisease,
    description: seriousExDescription,
  },
  {
    id: RiskId.PermanentDisability,
    provision: Provision.Income,
    label: riskLabels.permanentLabel,
    outlook: Outlook.Min,
    calculate: permanentDisability,
    help: helpPermanentDisability,
    description: permanentDescription,
  },
  {
    id: RiskId.PermanentDisability,
    provision: Provision.Expenses,
    label: riskLabels.permanentLabel,
    outlook: Outlook.Min,
    calculate: permanentDisability,
    help: helpPermanentDisability,
    description: permanentExDescription,
  },
  {
    id: RiskId.Invalidity,
    provision: Provision.Income,
    label: riskLabels.invalidityLabel,
    outlook: Outlook.Decreasing,
    calculate: invalidity,
    help: helpInvalidity,
    description: invalidityDescription,
  },
  {
    id: RiskId.Invalidity,
    provision: Provision.Expenses,
    label: riskLabels.invalidityLabel,
    outlook: Outlook.Decreasing,
    calculate: invalidity,
    help: helpInvalidity,
    description: invalidityExDescription,
  },
  {
    id: RiskId.InvalidityDebtRepayment,
    provision: Provision.Expenses,
    label: riskLabels.invalidityDebtLabel,
    outlook: Outlook.Decreasing,
    calculate: invalidityDebtRepayment,
    help: helpInvalidityDebtRepayment,
    description: invalidityDebtDescription,
  },
  {
    id: RiskId.DeathDebtRepayment,
    provision: Provision.Income,
    label: riskLabels.deathDebtLabel,
    outlook: Outlook.Decreasing,
    calculate: deathDebtRepayment,
    help: helpDeathDebtRepayment,
    description: deathDebtIncomeDescription,
  },
  {
    id: RiskId.DeathDebtRepayment,
    provision: Provision.Expenses,
    label: riskLabels.deathDebtLabel,
    outlook: Outlook.Decreasing,
    calculate: deathDebtRepayment,
    help: helpDeathDebtRepayment,
    description: deathDebtDescription,
  },
  {
    id: RiskId.DeathThriceAnnualIncome,
    provision: Provision.Income,
    label: riskLabels.deathThriceLabel,
    outlook: Outlook.Same,
    calculate: deathThriceAnnualIncome,
    help: helpDeathThriceAnnualIncome,
    description: deathThriceDescription,
  },
  {
    id: RiskId.DeathChildrenEnsurement,
    provision: Provision.Expenses,
    label: riskLabels.deathChildrenLabel,
    outlook: Outlook.Decreasing,
    calculate: deathChildrenEnsurement,
    help: helpDeathChildrenEnsurement,
    description: deathChildrenDescription,
  },
  {
    id: RiskId.Survival,
    provision: Provision.Income,
    label: riskLabels.survivalLabel,
    outlook: null,
    calculate: survival,
    help: helpSurvival,
    description: survivalDescription,
    onlyProposals: true,
  },
  {
    id: RiskId.Survival,
    provision: Provision.Expenses,
    label: riskLabels.survivalLabel,
    outlook: null,
    calculate: survival,
    help: helpSurvival,
    description: survivalDescription,
    onlyProposals: true,
  },
  {
    id: RiskId.ChildDailyCompensation,
    provision: Provision.Standard,
    label: riskLabels.compensationLabel,
    outlook: Outlook.Daily,
    calculate: childDailyCompensation,
    help: helpChildDailyCompensation,
    description: childStandardValueDescription,
  },
  {
    id: RiskId.ChildDailyCompensation,
    provision: Provision.Minimal,
    label: riskLabels.compensationLabel,
    outlook: Outlook.Daily,
    calculate: childDailyCompensation,
    help: helpChildDailyCompensation,
    description: childMinValueDescription,
  },
  {
    id: RiskId.ChildPhysicalDamage,
    provision: Provision.Standard,
    label: riskLabels.physicalDamageLabel,
    outlook: Outlook.Same,
    calculate: childPhysicalDamage,
    help: helpChildPhysicalDamage,
    description: childStandardValueDescription,
  },
  {
    id: RiskId.ChildPhysicalDamage,
    provision: Provision.Minimal,
    label: riskLabels.physicalDamageLabel,
    outlook: Outlook.Same,
    calculate: childPhysicalDamage,
    help: helpChildPhysicalDamage,
    description: childMinValueDescription,
  },
  {
    id: RiskId.ChildHospitalization,
    provision: Provision.Standard,
    label: riskLabels.hospitalizationLabel,
    outlook: Outlook.Daily,
    calculate: childHospitalization,
    help: helpChildHospitalization,
    description: childStandardValueDescription,
  },
  {
    id: RiskId.ChildHospitalization,
    provision: Provision.Minimal,
    label: riskLabels.hospitalizationLabel,
    outlook: Outlook.Daily,
    calculate: childHospitalization,
    help: helpChildHospitalization,
    description: childMinValueDescription,
  },
  {
    id: RiskId.ChildSeriousDisease,
    provision: Provision.Standard,
    label: riskLabels.seriousLabel,
    outlook: Outlook.Same,
    calculate: childSeriousDisease,
    help: helpChildSeriousDisease,
    description: childStandardConstantValueDescription,
  },
  {
    id: RiskId.ChildSeriousDisease,
    provision: Provision.Minimal,
    label: riskLabels.seriousLabel,
    outlook: Outlook.Same,
    calculate: childSeriousDisease,
    help: helpChildSeriousDisease,
    description: childMinConstantValueDescription,
  },
  {
    id: RiskId.ChildPermanentDisability,
    provision: Provision.Standard,
    label: riskLabels.permanentLabel,
    outlook: Outlook.Same,
    calculate: childPermanentDisability,
    help: helpChildPermanentDisability,
    description: childStandardConstantValueDescription,
  },
  {
    id: RiskId.ChildPermanentDisability,
    provision: Provision.Minimal,
    label: riskLabels.permanentLabel,
    outlook: Outlook.Same,
    calculate: childPermanentDisability,
    help: helpChildPermanentDisability,
    description: childMinConstantValueDescription,
  },
  {
    id: RiskId.ChildSurvival,
    provision: Provision.Standard,
    label: riskLabels.survivalLabel,
    outlook: null,
    calculate: childSurvival,
    help: helpChildSurvival,
    description: childSurvivalDescription,
    onlyProposals: true,
  },
  {
    id: RiskId.ChildSurvival,
    provision: Provision.Minimal,
    label: riskLabels.survivalLabel,
    outlook: null,
    calculate: childSurvival,
    help: helpChildSurvival,
    description: childSurvivalDescription,
    onlyProposals: true,
  },
];

// following lists of ids determine what risks are shown and in which order
/**
 * @deprecated
 */
export const adultIncomeRiskIds = [
  RiskId.DeathDebtRepayment,
  RiskId.DeathThriceAnnualIncome,
  RiskId.SeriousDisease,
  RiskId.Invalidity,
  RiskId.Hospitalization,
  RiskId.Incapacity,
  RiskId.PermanentDisability,
  RiskId.DailyCompensation,
  RiskId.PhysicalDamage,
  RiskId.Survival,
];

/**
 * @deprecated
 */
export const adultExpensesRiskIds = [
  RiskId.DeathDebtRepayment,
  RiskId.DeathChildrenEnsurement,
  RiskId.SeriousDisease,
  RiskId.Invalidity,
  RiskId.InvalidityDebtRepayment,
  RiskId.Hospitalization,
  RiskId.Incapacity,
  RiskId.PermanentDisability,
  RiskId.DailyCompensation,
  RiskId.PhysicalDamage,
  RiskId.Survival,
];

/**
 * @deprecated
 */
export const childRiskIds: RiskId[] = [
  RiskId.ChildSeriousDisease,
  RiskId.ChildHospitalization,
  RiskId.ChildPermanentDisability,
  RiskId.ChildDailyCompensation,
  RiskId.ChildPhysicalDamage,
  RiskId.ChildSurvival,
];

/**
 * @deprecated
 */
export const adultProposalRiskIds: RiskId[] = [
  RiskId.DeathDebtRepayment,
  RiskId.DeathThriceAnnualIncome,
  RiskId.DeathChildrenEnsurement,
  RiskId.SeriousDisease,
  RiskId.Invalidity,
  RiskId.InvalidityDebtRepayment,
  RiskId.Hospitalization,
  RiskId.Incapacity,
  RiskId.PermanentDisability,
  RiskId.DailyCompensation,
  RiskId.PhysicalDamage,
  RiskId.Survival,
];

/**
 * @deprecated
 */
export const proposalRiskIds: RiskId[] = [...adultProposalRiskIds, ...childRiskIds];

/**
 * @deprecated
 */
export const orderedRiskIds = proposalRiskIds;

/**
 * @deprecated
 */
export const allRiskIds = uniq([...adultIncomeRiskIds, ...adultExpensesRiskIds, ...childRiskIds]);

/**
 * @deprecated
 */
export const riskIdsByProvision = {
  [Provision.Income]: adultIncomeRiskIds,
  [Provision.Expenses]: adultExpensesRiskIds,
  [Provision.Standard]: childRiskIds,
  [Provision.Minimal]: childRiskIds,
  [Provision.Custom]: [] as RiskId[],
};

const riskDefinitionsById = groupBy(riskDefinitions, risk => risk.id);
// creates a two-dimensional map in shape {[id: string]: {[provision: string]: RiskDefinition}}
const riskDefinitionsMap = mapValues(riskDefinitionsById, definitions =>
  mapValues(
    groupBy(definitions, risk => risk.provision),
    head,
  ),
);

/**
 * @deprecated
 */
export function getRiskDefinition(riskId: string, provision: Provision): RiskDefinition {
  return (riskDefinitionsMap[riskId] && riskDefinitionsMap[riskId][provision]) || null;
}
