import {Component, Input} from '@angular/core';

@Component({
  selector: 'kpt-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() hasBorder = false;
  @Input() imgAlt: string;
  @Input() imgTitle: string;
  @Input() imgSrc: string;
  @Input() small: boolean;

  constructor() {}
}
