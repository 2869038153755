import {Component, Input, ViewChild} from '@angular/core';
import {CarouselTemplateDirective} from '@shared/directives/carousel-stepper/carousel-stepper-template.directive';

@Component({
  selector: 'kpt-carousel-step',
  template: ` <ng-template [kptCarouselTemplate]="stepGroup" [stepName]="stepName" [skip]="skip">
    <ng-content></ng-content>
  </ng-template>`,
})
export class CarouselStepComponent {
  @ViewChild(CarouselTemplateDirective, {static: true}) stepTemplate: CarouselTemplateDirective;

  @Input()
  stepGroup: string;

  @Input()
  stepName: string;

  @Input()
  skip = false;
}
