export interface EventData {
  x: number;
  y: number;
  width: number;
  yOffset: number;
  tooltipGap: number;
  recommended?: boolean;
}

export class EventSortingFnc {
  gap = 10;

  eventsByLevel: EventData[][] = [];
  recommendationEventsByLevel: EventData[][] = [];
  offsetData: EventData[] = [];

  actualLevel = 0;
  recommendationActualLevel = 0;
  freeLevel = 0;
  recommendationFreeLevel = 0;

  constructor(private data: EventData[]) {
    this.computeYAxisOffsets();
  }

  getOffsetData() {
    return this.offsetData;
  }

  isCollision(event: EventData, previousEvent: EventData): boolean {
    return event.x < previousEvent.x + previousEvent.width + this.gap + previousEvent.tooltipGap;
  }

  initArray(level: number, event: EventData, recommended: boolean) {
    if (recommended) {
      if (this.recommendationEventsByLevel[level])
        this.recommendationEventsByLevel[level].push(event);
      else this.recommendationEventsByLevel.push([event]);
    } else {
      if (this.eventsByLevel[level]) this.eventsByLevel[level].push(event);
      else this.eventsByLevel.push([event]);
    }
  }

  /**
   * Array of arrays that represents level of nested event. First checks for collision in last used event level
   */
  private computeYAxisOffsets() {
    for (let i = 0; i < this.data.length; i++) {
      const event = this.data[i];
      if (event.recommended) {
        if (i === 0 || this.data.findIndex(e => e.recommended) === i) {
          event.yOffset = this.recommendationActualLevel;
          this.recommendationEventsByLevel.push([event]);
          this.offsetData.push(event);
          continue;
        }

        this.checkForCollision(event, this.recommendationActualLevel, true, true);
        continue;
      }
      if (i === 0 || this.data.findIndex(e => !e.recommended) === i) {
        event.yOffset = this.actualLevel;
        this.eventsByLevel.push([event]);
        this.offsetData.push(event);
        continue;
      }

      this.checkForCollision(event, this.actualLevel, true, false);
    }
  }

  private checkForCollision(event: EventData, level: number, first: boolean, recommended: boolean) {
    const eventsByLevel = recommended ? this.recommendationEventsByLevel : this.eventsByLevel;
    let actualLevelArray;
    if (eventsByLevel[level]) {
      actualLevelArray = eventsByLevel[level];
    } else {
      actualLevelArray = eventsByLevel[level - 1];
      level--;
    }

    const previousEvent = actualLevelArray[actualLevelArray.length - 1];

    if (this.isCollision(event, previousEvent)) {
      if (first) {
        if (recommended) {
          this.recommendationFreeLevel = level + 1;
          this.recommendationActualLevel = level + 1;
        } else {
          this.freeLevel = level + 1;
          this.actualLevel = level + 1;
        }
        first = false;
      }

      if (level !== 0) {
        level--;
        this.checkForCollision(event, level, first, recommended);
      } else {
        const freeLevel = recommended ? this.recommendationFreeLevel : this.freeLevel;
        event.yOffset = freeLevel;
        this.initArray(freeLevel, event, recommended);
        this.offsetData.push(event);
      }
    } else {
      if (first) {
        if (recommended) {
          this.recommendationFreeLevel = level;
          this.recommendationActualLevel = level;
        } else {
          this.freeLevel = level;
          this.actualLevel = level;
        }
        first = false;
      }

      if (recommended) {
        this.recommendationFreeLevel = level;
      } else {
        this.freeLevel = level;
      }

      if (level !== 0) {
        level--;
        this.checkForCollision(event, level, first, recommended);
      } else {
        const freeLevel = recommended ? this.recommendationFreeLevel : this.freeLevel;
        event.yOffset = freeLevel;
        this.initArray(freeLevel, event, recommended);
        this.offsetData.push(event);
      }
    }
  }
}
