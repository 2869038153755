import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {RisksSelectionService} from '@shared/analysis/components/risks-selection/risks-selection.service';
import {RisksSelectionDataService} from '@shared/analysis/risks-selection-data.service';
import {selectRisksAdditionalData} from '@shared/analysis/store';
import {FAMILY_ID} from '@shared/constants';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {combineLatest} from 'rxjs';
import {skip} from 'rxjs/operators';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {
  selectAllRisks,
  selectOrderedPersons,
} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-readonly-risks-selection',
  templateUrl: './readonly-risks-selection.component.html',
  styleUrls: ['./readonly-risks-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyRisksSelectionComponent implements OnInit {
  form = new UntypedFormGroup({});
  familyMembers: LifeInsurancePerson[];
  loaded = false;

  constructor(
    private store: Store<State>,
    private risksSelectionService: RisksSelectionService,
    private lifeInsuranceDataService: LifeInsuranceDataService,
    private risksSelectionDataService: RisksSelectionDataService,
    private cdRef: ChangeDetectorRef,
    @Inject(FAMILY_ID) private familyUuid: string,
  ) {}

  ngOnInit() {
    this.lifeInsuranceDataService.loadData(this.familyUuid);
    this.risksSelectionDataService.getRisksAdditionalData(this.familyUuid);
    combineLatest([
      this.store.pipe(select(selectAllRisks)),
      this.store.pipe(select(selectRisksAdditionalData)),
    ])
      .pipe(skip(3), untilDestroyed(this))
      .subscribe(_ => {
        this.prepareRisksSelection();
      });
  }

  private prepareRisksSelection() {
    this.store
      .pipe(select(selectOrderedPersons), untilDestroyed(this))
      .subscribe(familyMembers => (this.familyMembers = familyMembers));
    this.risksSelectionService.init(this.form, this.familyUuid, false, false);
    this.risksSelectionService.formLoaded
      .pipe(untilDestroyed(this))
      .subscribe(loaded => (this.loaded = loaded));
    this.cdRef.detectChanges();
  }
}
