import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectAllAssets} from '@shared/analysis/store';
import {TabsModule} from '@shared/ui';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-related-asset-header',
  templateUrl: './related-asset-header.component.html',
  imports: [CommonModule, TabsModule],
})
export class RelatedAssetHeaderComponent implements OnChanges {
  @Input() relatedPropertyUuid: string;

  relatedPropertyUuid$ = new BehaviorSubject<string>(null);

  relatedAssetName$ = combineLatest([
    this.store.pipe(select(selectAllAssets)),
    this.relatedPropertyUuid$,
  ]).pipe(
    map(([assets, relatedPropertyUuid]) => {
      const relatedAsset = assets.find(asset => asset.assetUuid === relatedPropertyUuid);
      if (relatedAsset && 'name' in relatedAsset) {
        return relatedAsset.name;
      }
      return '';
    }),
    untilDestroyed(this),
  );

  constructor(private store: Store<State>) {}

  ngOnChanges(changes: SimpleChanges) {
    this.relatedPropertyUuid$.next(changes.relatedPropertyUuid.currentValue);
  }
}
