import {GraphEvent} from '@shared/ui/financial-analysis-graph/models/graph-event';

export interface TooltipEvent {
  data: GraphEvent;
  rect: TooltipRect;
}

export interface TooltipRect {
  x: number;
  y: number;
  width: number;
}
