import {Component, Input} from '@angular/core';
import {ITooltipDirection} from '@lib/components';

@Component({
  selector: 'kpt-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  @Input() direction: ITooltipDirection = ITooltipDirection.TOP;
  @Input() text: string;
  @Input() tooltipCustomClass = '';
  @Input() stopClickPropagation = false;
}
