import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';
import {ProductsService} from '@shared/services/products.service';
import {IOption} from '@shared/ui';
import {uniqBy} from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {getProductSet} from 'src/store/models/products.models';

@UntilDestroy()
@Component({
  selector: 'kpt-partner-select-formly',
  templateUrl: './partner-select-formly.component.html',
})
export class PartnerSelectFormlyComponent extends FieldType implements OnInit {
  partners$: Observable<IOption[]>;

  constructor(private productsService: ProductsService) {
    super();
  }

  ngOnInit() {
    const selectedProduct = this.formControl?.parent?.get('productId')?.value;
    this.partners$ = this.productsService.getProducts(getProductSet(this.to.assetType)).pipe(
      map(products =>
        this.productsService.filterByAssetType(products, selectedProduct, this.to.assetType),
      ),
      map(products =>
        products.map(
          product =>
            ({
              key: String(product.partnerCode),
              label: product.partnerName,
              disabled:
                !product.valid &&
                products.filter(p2 => p2.partnerCode === product.partnerCode).length === 1,
            } as IOption),
        ),
      ),
      map(options => uniqBy(options, option => option.key)),
      untilDestroyed(this),
    );
  }
}
