import {Component} from '@angular/core';
import {DialogService} from '@shared/services/modal.service';

@Component({
  selector: 'kpt-previous-plan-detail-modal',
  templateUrl: './previous-plan-detail-modal.component.html',
  styleUrls: ['./previous-plan-detail-modal.component.scss'],
})
export class PreviousPlanDetailModalComponent {
  header: string = null;
  fpDocument: string = null;
  fpAttachment: string = null;
  faResults: string = null;

  constructor(private dialogService: DialogService) {}

  dismiss() {
    this.dialogService.close(void 0);
  }
}
