import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'yearToInterval',
})
export class YearToIntervalPipe implements PipeTransform {
  transform(value: string): number {
    if (!value) {
      value = '';
    }

    const currentYear = new Date().getFullYear();
    return +value - currentYear;
  }
}
