<div class="form-group ng-select ng-select-custom">
  <ng-select
    class="form-control"
    bindValue="key"
    bindLabel="label"
    notFoundText="Žádné položky k dispozici"
    [items]="options"
    [selectOnTab]="true"
    [searchable]="false"
    [(ngModel)]="inputValue"
    (change)="onSelectChange($event && $event.key ? $event.key : $event)"
    (open)="onTouched($event)"
    [placeholder]="label"
    [clearable]="clearable"
    [class.is-disabled]="disabled"
    [attr.tabindex]="disabled ? '-1' : null"
    [disabled]="disabled"
    [class.scroll-up]="scrollUp"
  >
    <ng-template ng-option-tmp *ngIf="ngOptionTmpRef" let-item="item">
      <ng-template [ngTemplateOutlet]="ngOptionTmpRef" [ngTemplateOutletContext]="{item: item}">
      </ng-template>
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="ngFooterTmpRef">
      <ng-template [ngTemplateOutlet]="ngFooterTmpRef"> </ng-template>
    </ng-template>
  </ng-select>

  <div *ngIf="!!subtext" class="input-subtext" [class.input-subtext--disabled]="disabled">
    {{ subtext }}
  </div>

  <!-- warning text below the input -->
  <div *ngIf="!!warningText" class="input-warning-text">
    {{ warningText }}
  </div>

  <kpt-form-field-error [showErrorsOn]="showErrorsOn" [customErrors]="customErrors">
  </kpt-form-field-error>
</div>
