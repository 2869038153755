import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PipesModule} from '@shared/pipes';
import {HeaderInfoRowComponent} from '@shared/ui/header-info-row/header-info-row.component';

@NgModule({
  declarations: [HeaderInfoRowComponent],
  exports: [HeaderInfoRowComponent],
  imports: [CommonModule, PipesModule, RouterModule],
})
export class HeaderInfoRowModule {}
