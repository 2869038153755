import {IModalSize} from '@shared/ui';
import {Observable} from 'rxjs';

export interface IModal {
  component: string;
  size?: IModalSize;
  title?: string;
  data?: any;
  hideClose?: boolean;
  hideClose$?: Observable<boolean>;
}
