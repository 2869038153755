export interface Section {
  label: string;
  headerItems?: Item[];
  categories: Category[];
}

export interface Category {
  label?: string;
  value?: string;
  categoryTypes: CategoryType[];
}

export interface CategoryType {
  label?: string;
  value?: string;
  itemsGroups: ItemsGroup[];
  additionalNotes?: string[];
}

export interface ItemsGroup {
  label?: string;
  assetUuid?: string;
  value?: string;
  items: Item[];
}

export interface Item {
  label: string;
  value: string;
  assetUuid?: string;
}
