import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CheckboxComponent} from '@shared/ui/forms/checkbox/checkbox.component';
import {FieldErrorModule} from '@shared/ui/forms/field-error/field-error.module';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule, FieldErrorModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
