<label *ngIf="label" class="label">
  {{ label }}
  <ng-content></ng-content>
</label>

<kpt-slider
  class="slider"
  [parentForm]="parentForm"
  [sliderName]="sliderName"
  [sliderOptions]="sliderOptions"
>
</kpt-slider>

<kpt-input
  class="input"
  [type]="type"
  [formControl]="inputFormControl || parentForm.get(sliderName)"
  [min]="sliderOptions.floor"
  [max]="sliderOptions.ceil"
  [step]="sliderOptions.step"
  [mask]="mask"
  [appendText]="appendText"
  customFormGroupClass="input-no-stepper"
  [class.input--small]="smallInput"
>
</kpt-input>
