import {PageTemplateAsset} from '@shared/analysis/assets-page.models';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import * as moment from 'moment';
import {
  Asset,
  AssetType,
  BaseAsset,
  financialProductAssets,
  RegularPaymentType,
} from './models/asset';
import {CommonIncomeAsset} from './models/main-incomes';
import {FASection} from './models/section';

export function findAsset<T extends Asset | CommonIncomeAsset>(
  assets: Asset[],
  type: AssetType,
  familyMemberUuid?: string,
): T {
  return assets.find(
    asset =>
      asset.type === type &&
      (!familyMemberUuid ||
        ('familyMemberUuid' in asset ? asset.familyMemberUuid === familyMemberUuid : true)),
  ) as T;
}

export function filterAssets(
  assets: Asset[],
  types: AssetType[],
  activeAt?: 'now' | moment.Moment,
  familyMemberUuid?: string,
): Asset[] {
  return assets.filter(
    asset =>
      types.includes(asset.type) &&
      (!activeAt || isActiveAt(asset, activeAt)) &&
      (!familyMemberUuid ||
        ('familyMemberUuid' in asset
          ? asset.familyMemberUuid === familyMemberUuid
          : 'stakeholderUuid' in asset
          ? asset.stakeholderUuid === familyMemberUuid
          : true)),
  );
}

export function findAssetField<T extends Asset | CommonIncomeAsset>(
  assets: Asset[],
  type: AssetType,
  field: keyof T,
  familyMemberUuid?: string,
): any {
  const asset = findAsset(assets, type, familyMemberUuid) as T;
  return asset ? asset[field] : undefined;
}

export function isActiveAt(asset: Asset, date: 'now' | moment.Moment): boolean {
  if (!('start' in asset)) return true;
  if (date === 'now') date = moment();
  const start = asset.start ? moment(asset.start) : null;
  let end: moment.Moment = null;
  if (typeof asset.end === 'number' && 'frequency' in asset)
    end = start.add(getDuration(asset.frequency, asset.end));
  else if (asset.end) end = moment(asset.end);
  return (!start || start.isSameOrBefore(date)) && (!end || end.isSameOrAfter(date));
}

export function getDuration(frequency: RegularPaymentType, amount = 1): moment.Duration {
  switch (frequency) {
    case 'month':
      return moment.duration(1 * amount, 'month');
    case 'quarter':
      return moment.duration(3 * amount, 'months');
    case 'halfYear':
      return moment.duration(6 * amount, 'months');
    case 'year':
      return moment.duration(1 * amount, 'year');
  }
}

export function convertToMonthly(value: number, frequency: RegularPaymentType): number {
  switch (frequency) {
    case RegularPaymentType.Year:
      return value / 12;
    case RegularPaymentType.HalfYear:
      return value / 6;
    case RegularPaymentType.Quarter:
      return value / 3;
  }
  return value;
}

export function isCommonFinancialProductAsset(
  asset: BaseAsset,
): asset is CommonFinancialProductAsset {
  return financialProductAssets.includes(asset?.type);
}

export function isForeignContract(asset: BaseAsset) {
  return (asset as CommonFinancialProductAsset)?.foreignContract !== false;
}

export function isOwnContract(asset: BaseAsset) {
  return !asset.proposal && !isForeignContract(asset);
}

export const FIELDS_REQUIRED_FOR_FINANCIAL_PLAN = [
  'frequency',
  'value',
  'start',
  'stakeholderUuid',
  'frequency',
  'relatedObjectiveUuid',
  'presentValue',
  'presentValueDate',
  'yearlyRate',
];

export function fieldsRequiredForFinancialPlan(assetDefinition: PageTemplateAsset) {
  if (!isCommonFinancialProductAsset(assetDefinition.assetData)) return [];
  if (assetDefinition.excludedFromAnalysis) return [];

  const {model, assetData} = assetDefinition;
  const assetFields = Object.keys({...model, ...assetData});
  return FIELDS_REQUIRED_FOR_FINANCIAL_PLAN.filter(fn => assetFields.includes(fn));
}

export function isEmptyAssetDefinition(assetDefinition: PageTemplateAsset) {
  if (typeof assetDefinition.model === 'object' && Object.keys(assetDefinition.model).length)
    return false;

  return !assetDefinition.assetData;
}

export function fieldsRequiringAttention(assetDefinition: PageTemplateAsset) {
  if (isEmptyAssetDefinition(assetDefinition)) return [];

  const required = fieldsRequiredForFinancialPlan(assetDefinition);
  if (!required.length) return [];

  return required.filter(fieldName => {
    const value =
      assetDefinition.model?.[fieldName] || (assetDefinition.assetData as any)?.[fieldName];
    return !value;
  });
}

export function requiresAttention(assetDefinition: PageTemplateAsset) {
  return fieldsRequiringAttention(assetDefinition).length > 0;
}

export const sumAssetTypes: Partial<Record<FASection, AssetType>> = {
  expenses: AssetType.CommonExpensesSum,
  insuranceProducts: AssetType.InsuranceProductsSum,
  investmentProducts: AssetType.InvestmentProductsSum,
  creditProducts: AssetType.CreditProductsSum,
  immovableProperties: AssetType.ImmovablePropertiesSum,
  movableProperties: AssetType.MovablePropertiesSum,
  insuranceProperties: AssetType.InsurancePropertiesSum,
  investmentProperties: AssetType.InvestmentPropertiesSum,
  creditProperties: AssetType.CreditPropertiesSum,
};

export const relatedSumAssetTypes: Partial<Record<FASection, AssetType>> = {
  investmentProperties: AssetType.InvestmentProductsSum,
  insuranceProperties: AssetType.InsuranceProductsSum,
  creditProperties: AssetType.CreditProductsSum,
};
