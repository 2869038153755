<ng-container *ngIf="text; else disabled">
  <shd-tooltip
    [direction]="direction"
    [customClass]="tooltipCustomClass"
    [stopClickPropagation]="stopClickPropagation"
    icon="question-mark"
  >
    <span class="text small" [innerHTML]="text"></span>
  </shd-tooltip>
</ng-container>

<ng-template #disabled>
  <img src="assets/images/help-disabled.svg" />
</ng-template>
