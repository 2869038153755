/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface AddClientToFamilyParams {
  family_uuid: string;
  sugar_uuid: string;
  type: string;
}

export interface CreateAssignmentParams {
  data: __model.CreateAssignment;
  sugar_uuid: string;
}

export interface CreateClientParams {
  data: __model.CreatePortalClient;
}

export interface CreateFamilyParams {
  data: __model.FamilyName;
  sugar_uuid: string;
}

export interface DeleteFamilyParams {
  family_uuid: string;
}

export interface DeleteFilesParams {
  data: __model.DMSId[];
}

export interface EzaznamParams {
  data: __model.EzaznamRequest;
  family_uuid: string;
}

export interface FindClientsParams {
  data: __model.FindClient;
}

export interface GetAdvisorPortalParams {
  /** default: KF */
  division?: DivisionGetAdvisorPortalParamsEnum;
}

export type DivisionGetAdvisorPortalParamsEnum = 'KF' | 'GOLD';

export interface GetAdvisorsMeetingsParams {
  /**
   * Set the date (including the timezone) from which the meetings should be listed, ascending or descending. Ascending results will include the meeting starting at the datetime specified, descenting results will not include a meeting starting at the datetime specified.
   * format: date-time
   * default: 2024-07-16T08:40:53.799960Z
   */
  date_start?: string;
  /**
   * Set how many meetings do you want to have in a response at most (set to 20 by default).
   * default: 20
   */
  amount?: number;
  /**
   * Set this to False if you want to get all meetings of the advisor, not only the meetings with some client (it is set to True by default).
   * default: true
   */
  clients_only?: boolean;
  /**
   * Set this parameter to uuid of a client if you want to get meetings of the specified client only.
   * format: uuid
   */
  client_uuid?: string;
  /** Set the offest of the respond, that means how many first results will be omitted from the response. */
  offset?: number;
  /**
   * Ordering of the results, ascending or descending.
   * default: ASC
   */
  order?: OrderGetAdvisorsMeetingsParamsEnum;
}

export type OrderGetAdvisorsMeetingsParamsEnum = 'ascending' | 'descending';

export interface GetAnswersParams {
  data: __model.EsrovnaniAnswer;
  family_uuid: string;
}

export interface GetClientParams {
  sugar_uuid: string;
}

export interface GetFamilyMemberParams {
  family_uuid: string;
  sugar_uuid: string;
}

export interface UpdateFamilyMemberParams {
  data: __model.FamilyMember;
  family_uuid: string;
  sugar_uuid: string;
}

export interface DeleteFamilyMemberParams {
  family_uuid: string;
  sugar_uuid: string;
}

export interface GetInfoParams {
  dms_uuid: string;
}

export interface GetPortalFamilyMembersParams {
  family_uuid: string;
}

export interface CreatePortalFamilyMemberParams {
  data: __model.FamilyMember;
  family_uuid: string;
}

export interface FindVehicleParams {
  rpOrVin: string;
}

export interface ListVehicleModelsParams {
  manufacturer: string;
}

export interface ListCasesParams {
  data: __model.ListBusinessCasesRequest;
  family_uuid: string;
}

export interface CloseParams {
  data: __model.PostCloseBusinessCase;
  family_uuid: string;
  situation_id: string;
}

export interface PrintSendParams {
  data: __model.PrintSendBusinessCase;
  family_uuid: string;
  situation_id: string;
}

export interface StatusSaveParams {
  data: __model.PostStatusSaveBusinessCase;
  family_uuid: string;
  situation_id: string;
}

export interface OpportunityParams {
  /** default: KF */
  division?: string;
  family_uuid: string;
}

export interface PostOpportunityParams {
  data: __model.Opportunity;
  /** default: KF */
  division?: string;
  family_uuid: string;
}

export interface DeleteForeignOpportunityParams {
  family_uuid: string;
  opportunity_id: string;
}

export interface PlanMeetingParams {
  data: __model.MeetingPostRequest;
  family_uuid: string;
}

export interface GetQuestionnaireAnswersParams {
  category: CategoryGetQuestionnaireAnswersParamsEnum;
  /** format: uuid */
  clientId: string;
}

export type CategoryGetQuestionnaireAnswersParamsEnum = 'INV' | 'IZP' | 'DPS';

export interface PostQuestionnaireAnswersParams {
  data: __model.CreateQuestionnaireAnswers;
}

export interface QuestionnaireResultParams {
  /** format: uuid */
  schemaGuid: string;
  /** format: uuid */
  assetUuid: string;
  /** format: uuid */
  sugarUuid: string;
  horizon: number;
  category: string;
  questionnaireScore: number;
  portfolio: string;
  preference: string;
  lossValue: string;
  family_uuid: string;
}

export interface QuestionnaireSchemaParams {
  /** format: uuid */
  guid?: string;
  category?: string;
}

export interface ProductsParams {
  data: __model.ProductQuery;
}

export interface GetMeetingFromPortalParams {
  /** format: date-time */
  date_start: string;
  /** format: date-time */
  date_end: string;
  /** format: uuid */
  id?: string;
  /** format: uuid */
  client_uuid?: string;
}

export interface RemoteLogParams {
  data: __model.RemoteLog[];
}

export interface RenameFamilyParams {
  data: __model.FamilyName;
  family_uuid: string;
}

export interface SendSmsParams {
  data: __model.SendSMS;
  family_uuid: string;
}

export interface SignDocumentParams {
  data: __model.SignDocument;
  family_uuid: string;
}

export interface UploadFileParams {
  data: __model.File;
}

@Injectable()
export class IntegrationsService {
  constructor(private http: HttpClient) {}

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_add-client-to-family_create */
  addClientToFamily(params: AddClientToFamilyParams): Observable<__model.FamilyMember> {
    const {family_uuid, sugar_uuid, type} = params;
    return this.http.post<__model.FamilyMember>(
      `/api/integrations/add-client-to-family/${family_uuid}/${sugar_uuid}/`,
      {type},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_create-assignment_create */
  createAssignment(params: CreateAssignmentParams): Observable<__model.CreateAssignment> {
    const bodyParams = params.data;

    const pathParams = {
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.post<__model.CreateAssignment>(
      `/api/integrations/create-assignment/${pathParams.sugar_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_create-client_create */
  createClient(params: CreateClientParams): Observable<__model.PortalClient> {
    const bodyParams = params.data;

    return this.http.post<__model.PortalClient>(
      `/api/integrations/create-client/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_create-family_create */
  createFamily(params: CreateFamilyParams): Observable<__model.GetFamily> {
    const bodyParams = params.data;

    const pathParams = {
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.post<__model.GetFamily>(
      `/api/integrations/create-family/${pathParams.sugar_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_delete-family_delete */
  deleteFamily(params: DeleteFamilyParams): Observable<__model.FamilyName> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.delete<__model.FamilyName>(
      `/api/integrations/delete-family/${pathParams.family_uuid}/`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_delete-files_create */
  deleteFiles(params: DeleteFilesParams): Observable<void> {
    const bodyParams = params.data;

    return this.http.post<void>(`/api/integrations/delete-files/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_ezaznam_create */
  ezaznam(params: EzaznamParams): Observable<__model.EzaznamResponse> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.EzaznamResponse>(
      `/api/integrations/ezaznam/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_find-clients_create */
  findClients(params: FindClientsParams): Observable<__model.FoundPortalClient[]> {
    const bodyParams = params.data;

    return this.http.post<__model.FoundPortalClient[]>(
      `/api/integrations/find-clients/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-advisor-portal_list */
  getAdvisorPortal(params: GetAdvisorPortalParams): Observable<__model.AdvisorPortal> {
    const queryParamBase = {
      division: params.division,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.AdvisorPortal>(`/api/integrations/get-advisor-portal/`, {
      params: queryParams,
    });
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-advisor_list */
  getAdvisor(): Observable<__model.Advisor> {
    return this.http.get<__model.Advisor>(`/api/integrations/get-advisor/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getMeetingFromPortal */
  getAdvisorsMeetings(params: GetAdvisorsMeetingsParams): Observable<__model.Meeting[]> {
    const queryParamBase = {
      date_start: params.date_start,
      amount: params.amount,
      clients_only: params.clients_only,
      client_uuid: params.client_uuid,
      offset: params.offset,
      order: params.order,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.Meeting[]>(`/api/integrations/get-advisors-meetings/`, {
      params: queryParams,
    });
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-answers_create */
  getAnswers(params: GetAnswersParams): Observable<__model.EsrovnaniResults[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.EsrovnaniResults[]>(
      `/api/integrations/get-answers/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-client_read */
  getClient(params: GetClientParams): Observable<__model.PortalClient> {
    const pathParams = {
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.get<__model.PortalClient>(
      `/api/integrations/get-client/${pathParams.sugar_uuid}/`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getFamilyMember */
  getFamilyMember(params: GetFamilyMemberParams): Observable<__model.FamilyMember> {
    const pathParams = {
      family_uuid: params.family_uuid,
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.get<__model.FamilyMember>(
      `/api/integrations/get-delete-update-portal-family-members/${pathParams.family_uuid}/${pathParams.sugar_uuid}`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/updateFamilyMember */
  updateFamilyMember(params: UpdateFamilyMemberParams): Observable<__model.FamilyMember> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.put<__model.FamilyMember>(
      `/api/integrations/get-delete-update-portal-family-members/${pathParams.family_uuid}/${pathParams.sugar_uuid}`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/deleteFamilyMember */
  deleteFamilyMember(params: DeleteFamilyMemberParams): Observable<void> {
    const pathParams = {
      family_uuid: params.family_uuid,
      sugar_uuid: params.sugar_uuid,
    };
    return this.http.delete<void>(
      `/api/integrations/get-delete-update-portal-family-members/${pathParams.family_uuid}/${pathParams.sugar_uuid}`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-info_read */
  getInfo(params: GetInfoParams): Observable<void> {
    const pathParams = {
      dms_uuid: params.dms_uuid,
    };
    return this.http.get<void>(`/api/integrations/get-info/${pathParams.dms_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getLastSeenClients */
  getLastClients(): Observable<__model.LastClient[]> {
    return this.http.get<__model.LastClient[]>(`/api/integrations/get-last-clients/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getListOfStates */
  getListOfStates(): Observable<__model.States> {
    return this.http.get<__model.States>(`/api/integrations/get-list-of-states/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_get-questions_list */
  getQuestions(): Observable<__model.EsrovnaniQuestions> {
    return this.http.get<__model.EsrovnaniQuestions>(`/api/integrations/get-questions/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getPortalFamilyMembers */
  getPortalFamilyMembers(params: GetPortalFamilyMembersParams): Observable<__model.FamilyMember[]> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.FamilyMember[]>(
      `/api/integrations/list-create-portal-family-members/${pathParams.family_uuid}/`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/createPortalFamilyMember */
  createPortalFamilyMember(
    params: CreatePortalFamilyMemberParams,
  ): Observable<__model.FamilyMember> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.FamilyMember>(
      `/api/integrations/list-create-portal-family-members/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/findVehicle */
  findVehicle(params: FindVehicleParams): Observable<__model.Vehicle> {
    const queryParamBase = {
      rpOrVin: params.rpOrVin,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.Vehicle>(`/api/integrations/portal/find-vehicle/`, {
      params: queryParams,
    });
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/listVehicleManufacturers */
  listVehicleManufacturers(): Observable<string[]> {
    return this.http.get<string[]>(`/api/integrations/portal/list-vehicle-manufacturers/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/listVehicleModels */
  listVehicleModels(params: ListVehicleModelsParams): Observable<string[]> {
    const queryParamBase = {
      manufacturer: params.manufacturer,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<string[]>(`/api/integrations/portal/list-vehicle-models/`, {
      params: queryParams,
    });
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/listBusinessCases */
  listCases(params: ListCasesParams): Observable<__model.GetBusinessCaseResponse[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.GetBusinessCaseResponse[]>(
      `/api/integrations/portal/plan/business-case/${pathParams.family_uuid}/list-cases/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/postCloseBusinessCase */
  close(params: CloseParams): Observable<__model.PostCloseBusinessCaseResponse> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
      situation_id: params.situation_id,
    };
    return this.http.post<__model.PostCloseBusinessCaseResponse>(
      `/api/integrations/portal/plan/business-case/${pathParams.family_uuid}/${pathParams.situation_id}/close/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/printSendBusinessCase */
  printSend(params: PrintSendParams): Observable<__model.PrintSendBusinessCaseResponse> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
      situation_id: params.situation_id,
    };
    return this.http.post<__model.PrintSendBusinessCaseResponse>(
      `/api/integrations/portal/plan/business-case/${pathParams.family_uuid}/${pathParams.situation_id}/print-send/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/postStatusSaveBusinessCase */
  statusSave(params: StatusSaveParams): Observable<__model.PostStatusSaveBusinessCaseResponse> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
      situation_id: params.situation_id,
    };
    return this.http.post<__model.PostStatusSaveBusinessCaseResponse>(
      `/api/integrations/portal/plan/business-case/${pathParams.family_uuid}/${pathParams.situation_id}/status-save/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getSecondaryStatus */
  secondaryStatus(): Observable<__model.OpportunityStatus[]> {
    return this.http.get<__model.OpportunityStatus[]>(
      `/api/integrations/portal/plan/opportunity/secondary-status/`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getForeignOpportunity */
  opportunity(params: OpportunityParams): Observable<__model.Opportunity[]> {
    const queryParamBase = {
      division: params.division,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.Opportunity[]>(
      `/api/integrations/portal/plan/opportunity/${pathParams.family_uuid}/`,
      {params: queryParams},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/postOpportunity */
  postOpportunity(params: PostOpportunityParams): Observable<__model.Opportunity> {
    const bodyParams = params.data;

    const queryParamBase = {
      division: params.division,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.Opportunity>(
      `/api/integrations/portal/plan/opportunity/${pathParams.family_uuid}/foreign/`,
      bodyParams || {},
      {params: queryParams},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/deleteForeignOpportunity */
  deleteForeignOpportunity(params: DeleteForeignOpportunityParams): Observable<void> {
    const pathParams = {
      family_uuid: params.family_uuid,
      opportunity_id: params.opportunity_id,
    };
    return this.http.delete<void>(
      `/api/integrations/portal/plan/opportunity/${pathParams.family_uuid}/foreign/${pathParams.opportunity_id}/`,
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/postMeeting */
  planMeeting(params: PlanMeetingParams): Observable<__model.MeetingPostRequest> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.MeetingPostRequest>(
      `/api/integrations/portal/plan/plan-meeting/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getQuestionnaireAnswers */
  getQuestionnaireAnswers(
    params: GetQuestionnaireAnswersParams,
  ): Observable<__model.QuestionnaireExistingAnswers> {
    const queryParamBase = {
      category: params.category,
      clientId: params.clientId,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.QuestionnaireExistingAnswers>(
      `/api/integrations/portal/questionnaire-answers/`,
      {params: queryParams},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/postQuestionnaireAnswers */
  postQuestionnaireAnswers(
    params: PostQuestionnaireAnswersParams,
  ): Observable<__model.QuestionnaireExistingAnswers> {
    const bodyParams = params.data;

    return this.http.post<__model.QuestionnaireExistingAnswers>(
      `/api/integrations/portal/questionnaire-answers/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getQuestionnaireResult */
  questionnaireResult(params: QuestionnaireResultParams): Observable<__model.QuestionnaireResult> {
    const queryParamBase = {
      schemaGuid: params.schemaGuid,
      assetUuid: params.assetUuid,
      sugarUuid: params.sugarUuid,
      horizon: params.horizon,
      category: params.category,
      questionnaireScore: params.questionnaireScore,
      portfolio: params.portfolio,
      preference: params.preference,
      lossValue: params.lossValue,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.QuestionnaireResult>(
      `/api/integrations/portal/questionnaire-result/${pathParams.family_uuid}/`,
      {params: queryParams},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getQuestionnaireSchema */
  questionnaireSchema(params: QuestionnaireSchemaParams): Observable<__model.QuestionnaireSchema> {
    const queryParamBase = {
      guid: params.guid,
      category: params.category,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.QuestionnaireSchema>(
      `/api/integrations/portal/questionnaire-schema/`,
      {params: queryParams},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_products_create */
  products(params: ProductsParams): Observable<__model.Product[]> {
    const bodyParams = params.data;

    return this.http.post<__model.Product[]>(`/api/integrations/products/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/getMeetingFromPortal */
  getMeetingFromPortal(params: GetMeetingFromPortalParams): Observable<__model.PortalMeeting> {
    const queryParamBase = {
      date_start: params.date_start,
      date_end: params.date_end,
      id: params.id,
      client_uuid: params.client_uuid,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.PortalMeeting>(`/api/integrations/read-update-delete-meeting/`, {
      params: queryParams,
    });
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/deleteMeetingFromPortal */
  deleteMeetingFromPortal(): Observable<void> {
    return this.http.delete<void>(`/api/integrations/read-update-delete-meeting/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_remote-log_create */
  remoteLog(params: RemoteLogParams): Observable<void> {
    const bodyParams = params.data;

    return this.http.post<void>(`/api/integrations/remote-log/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_rename-family_update */
  renameFamily(params: RenameFamilyParams): Observable<__model.FamilyName> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.put<__model.FamilyName>(
      `/api/integrations/rename-family/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_send-sms_create */
  sendSms(params: SendSmsParams): Observable<void> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<void>(
      `/api/integrations/send-sms/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_sign-document_create */
  signDocument(params: SignDocumentParams): Observable<__model.EzaznamResponse> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.EzaznamResponse>(
      `/api/integrations/sign-document/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/integrations/integrations_upload-file_create */
  uploadFile(params: UploadFileParams): Observable<__model.DMSId> {
    const bodyParams = params.data;

    return this.http.post<__model.DMSId>(`/api/integrations/upload-file/`, bodyParams || {});
  }
}
