<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>

  <kpt-row>
    <kpt-objective-age-input
      grow
      [label]="ageLabel"
      name="start"
      [(start)]="this.asset.start"
    ></kpt-objective-age-input>
    <kpt-input
      grow
      type="number"
      label="Celková výše investice"
      appendText="Kč"
      name="totalValue"
      [(ngModel)]="asset.totalValue"
      [max]="defaultObjectiveMaxValue"
    ></kpt-input>
  </kpt-row>

  <kpt-row>
    <kpt-input
      grow
      type="percent"
      label="Podíl vlastních prostředků"
      name="ownValueRatio"
      appendText="%"
      [ngModel]="getOwnValueRatio()"
      (ngModelChange)="setOwnValueRatio($event)"
      [min]="0"
      [max]="1"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Chci si našetřit částku"
      appendText="Kč"
      name="value"
      [(ngModel)]="asset.value"
      required
      [max]="defaultObjectiveMaxValue"
    ></kpt-input>
  </kpt-row>

  <kpt-input
    label="Poznámka"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>
</ng-container>
