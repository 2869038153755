import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';
import {ProductsService} from '@shared/services/products.service';
import {IOption} from '@shared/ui';
import {getFormValues} from '@shared/utils';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {getProductSet} from 'src/store/models/products.models';

@UntilDestroy()
@Component({
  selector: 'kpt-product-select-formly',
  templateUrl: './product-select-formly.component.html',
})
export class ProductSelectFormlyComponent extends FieldType implements OnInit {
  products$: Observable<IOption[]>;

  constructor(private productService: ProductsService) {
    super();
  }

  ngOnInit() {
    const partnerIdControl = this.formControl?.parent?.get('partnerId');
    if (!partnerIdControl) return;

    const selectedProduct = this.formControl.value;
    this.products$ = combineLatest([
      getFormValues(partnerIdControl),
      this.productService.getProducts(getProductSet(this.to.assetType)),
    ]).pipe(
      map(([partnerId, products]) =>
        products.filter(product => String(product.partnerCode) === String(partnerId)),
      ),
      map(products =>
        this.productService.filterByAssetType(products, selectedProduct, this.to.assetType),
      ),
      map(products =>
        products.map(product => ({
          key: String(product.code),
          label: product.name,
          disabled: !product.valid,
        })),
      ),
      tap(options => this.checkSelectedProduct(options)),
      untilDestroyed(this),
    );
  }

  private checkSelectedProduct(options: IOption[]) {
    const selectedProduct = this.formControl.value;
    if (selectedProduct && !options.some(option => option.key === selectedProduct)) {
      this.formControl.setValue(null);
    }
  }
}
