import {Component, Input} from '@angular/core';
import {IndicatorBadge} from './model/badge.model';
import {IndicatorDirection} from './model/direction.model';
import {IndicatorPosition} from './model/position.model';

@Component({
  selector: 'kpt-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {
  @Input() direction: IndicatorDirection;
  @Input() position: IndicatorPosition;
  @Input() badge: IndicatorBadge;

  constructor() {
    this.position = Object.values(IndicatorPosition).includes(this.position)
      ? this.position
      : IndicatorPosition.LEFT;
    this.badge = Object.values(IndicatorBadge).includes(this.badge)
      ? this.badge
      : IndicatorBadge.NONE;
  }
}
