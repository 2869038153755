import {HttpErrorResponse} from '@angular/common/http';
import produce from 'immer';
import {CreateClientActions, CreateClientActionTypes} from '../actions/create-client.actions';

export interface CreateClientsState {
  loading: boolean;
  cancellableRequest: boolean;
  error: HttpErrorResponse;
}

const initialState: CreateClientsState = {
  loading: false,
  error: null,
  cancellableRequest: false,
};

const createClientsReducer = produce(
  (newState: CreateClientsState, action: CreateClientActions) => {
    switch (action.type) {
      case CreateClientActionTypes.CreateClientStart:
        newState.loading = true;
        newState.cancellableRequest = true;
        return;

      case CreateClientActionTypes.CreateClientSuccess:
        newState.loading = false;
        newState.error = null;
        newState.cancellableRequest = false;
        return;

      case CreateClientActionTypes.CreateClientError:
        newState.loading = false;
        newState.cancellableRequest = false;
        newState.error = action.payload;
        return;

      case CreateClientActionTypes.CreateClientCancel:
        newState.loading = false;
        newState.cancellableRequest = false;
        return;
    }
  },
);

export function reducer(state = initialState, action: CreateClientActions): CreateClientsState {
  return createClientsReducer(state, action);
}
