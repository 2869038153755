import {Component} from '@angular/core';
import {DialogService} from '@shared/services/modal.service';

@Component({
  selector: 'kpt-inconsistency-warning-modal',
  templateUrl: './inconsistency-warning-modal.component.html',
  styleUrls: ['./inconsistency-warning-modal.component.scss'],
})
export class InconsistencyWarningModalComponent {
  message = '';

  constructor(private modalService: DialogService) {}

  goBack() {
    this.modalService.close({goBack: true});
  }

  continue() {
    this.modalService.close({goBack: false});
  }
}
