import {Injectable} from '@angular/core';
import {AdvisorPortal} from '@generated/defs/AdvisorPortal';
import {Start as GetAdvisorPortal} from '@generated/store/integrations/getAdvisorPortal/states/actions';
import {getGetAdvisorPortalStateSelector} from '@generated/store/integrations/getAdvisorPortal/states/reducers';
import {LoginService} from '@lib/services';
import {Store} from '@ngrx/store';
import {AdvisorDetail} from '@shared/advisor-detail/models/advisor-detail';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {State} from 'src/store';
import {replenish} from 'src/store/store-helpers';

@Injectable()
export class AdvisorDetailService {
  constructor(private store: Store<State>, private loginService: LoginService) {}

  getAdvisorDetail(): Observable<AdvisorDetail> {
    return replenish(getGetAdvisorPortalStateSelector, this.store, GetAdvisorPortal, {
      division: this.loginService.advisorDivision,
    }).pipe(
      map(a => a.data),
      filter<AdvisorPortal>(Boolean),
      map(a => ({
        aboutMe: a.aboutMe,
        certifications: a.certificationsDescriptions?.join(', '),
        workExperience: a.workExperience,
        photoUrl: a.photoUrl,
        position: a.position,
      })),
    );
  }
}
