import {Injectable} from '@angular/core';
import {CreateClientParams} from '@generated/controllers/Integrations';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {ConfirmDialogService} from '@shared/building-blocks/confirm-dialog/confirm-dialog.service';
import {DialogService} from '@shared/services/modal.service';
import {trimDatesWithinObject} from '@shared/utils/trim-date.fnc';
import {map, take} from 'rxjs/operators';
import {FamilyMemberDetailComponent} from 'src/app/modules/financial-analysis/family/components/member-detail/family-member-detail.component';
import {State} from 'src/store';
import {
  CreateFamilyMemberStart,
  DeleteFamilyMemberStart,
  UpdateFamilyMemberStart,
} from 'src/store/actions/family-members.actions';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

type Result = {model: FamilyMember; type: string};

@Injectable({
  providedIn: 'root',
})
export class FamilyService {
  missingEmailOfFamilyHead$ = this.store.pipe(
    selectFamilyHead(),
    map(head => !head.email),
  );

  constructor(
    private store: Store<State>,
    private dialogService: DialogService,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  async editFamilyMember(sugarUuid: string, familyUuid: string, showDeleteButton: boolean) {
    try {
      const result: Result = await this.dialogService.load(
        FamilyMemberDetailComponent,
        {sugarUuid, showDeleteButton},
        {size: 'sm'},
      );
      if (result.type === 'save') {
        await this.updateFamilyMember(result, sugarUuid, familyUuid);
      } else if (result.type === 'remove') {
        if (await this.confirmDeleteFamilyMember()) {
          this.deleteFamilyMember(sugarUuid, familyUuid);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async addFamilyMember(familyUuid: string, createClient: CreateClientParams) {
    try {
      const result: Result = await this.dialogService.load(
        FamilyMemberDetailComponent,
        {sugarUuid: null, createClient},
        {size: 'sm'},
      );
      this.createFamilyMember(familyUuid, result);
    } catch (e) {
      console.error(e);
    }
  }

  private getFamilyMemberType(sugarUuid: string): Promise<string> {
    return this.store
      .pipe(
        select(getFamilyMembers),
        map(familyMembers => familyMembers.find(member => member.sugarUuid === sugarUuid)),
        map(familyMember => familyMember.type),
        take(1),
      )
      .toPromise();
  }

  private async updateFamilyMember(result: Result, sugarUuid: string, familyUuid: string) {
    this.store.dispatch(
      new UpdateFamilyMemberStart({
        sugar_uuid: sugarUuid,
        family_uuid: familyUuid,
        data: {
          ...trimDatesWithinObject(result.model, ['birthDate', 'dateOfExpiry']),
          type: result.model.type ?? (await this.getFamilyMemberType(sugarUuid)),
        },
      }),
    );
  }

  private deleteFamilyMember(sugarUuid: string, familyUuid: string) {
    this.store.dispatch(
      new DeleteFamilyMemberStart({
        sugar_uuid: sugarUuid,
        family_uuid: familyUuid,
      }),
    );
  }

  private createFamilyMember(familyUuid: string, result: Result) {
    this.store.dispatch(
      new CreateFamilyMemberStart({
        family_uuid: familyUuid,
        data: {
          ...trimDatesWithinObject(result.model, ['birthDate', 'dateOfExpiry']),
        },
      }),
    );
  }

  private confirmDeleteFamilyMember(): Promise<boolean> {
    const message =
      '<p>Pozor! Smazáním osoby odeberete všechny informace z&nbsp;analýzy ' +
      'spojené s&nbsp;touto osobou, tedy její příjmy, výdaje a zaevidované smlouvy. ' +
      'Změna je nenávratná, informace budou smazány.</p>' +
      '<p>Pokud nechcete přijít o&nbsp;smlouvy, změňte komu patří v&nbsp;poli ' +
      '"Koho je smlouva?" v&nbsp;detailu smlouvy.</p>' +
      '<p>Opravdu chcete osobu z&nbsp;analýzy smazat?</p>';
    return this.confirmDialogService.confirm(message, 'Smazat', 'Zpět');
  }
}
