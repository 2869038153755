import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AdvisorDetailModule} from '@shared/advisor-detail/advisor-detail.module';
import {ModalContainerModule} from '@shared/lib/components/modal/modal-container.module';
import {HeaderInfoRowModule, HeaderModule, SidebarModule, StepperProgressModule} from '@shared/ui';
import {NotificationCenterModule} from '@shared/ui/notification-center/notification-center.module';
import {SecuredLayoutComponent} from './secured-layout.component';
import {routes} from './secured-layout.routes';

@NgModule({
  declarations: [SecuredLayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    ModalContainerModule,
    RouterModule.forChild(routes),
    StepperProgressModule,
    SidebarModule,
    NotificationCenterModule,
    AdvisorDetailModule,
    HeaderInfoRowModule,
  ],
})
export class SecuredLayoutModule {}
