import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {select, Store} from '@ngrx/store';
import {isCommonFinancialProductAsset, isForeignContract} from '@shared/analysis/asset.utils';
import {
  assetTypesToCoreCategoriesTable,
  CoreCategory,
  liveCoreStatus,
  validCoreCategories,
} from '@shared/analysis/core-sync.data';
import {CoreContract} from '@shared/analysis/core-sync.models';
import {getEndDate} from '@shared/analysis/helpers';
import {Asset, RegularPaymentType} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {CoreContractsActions} from '@shared/analysis/store/core-contracts.actions';
import {selectCoreContracts} from '@shared/analysis/store/core-contracts.selectors';
import {FinancialAnalysisActions} from '@shared/analysis/store/index';
import {RemoteLoggerService} from '@shared/services/remote-logger.service';
import {cloneDeep, merge, pick} from 'lodash';
import {from, Subject} from 'rxjs';
import {debounceTime, groupBy, mergeMap, take} from 'rxjs/operators';
import {State} from 'src/store/index';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@Injectable({
  providedIn: 'root',
})
export class CoreSyncCommonService {
  private coreCategories: CoreCategory[];
  private updateCoreContractForAsset$ = new Subject<CommonFinancialProductAsset>();

  constructor(
    private store: Store<State>,
    private integrationsService: IntegrationsService,
    private remoteLoggerService: RemoteLoggerService,
  ) {
    this.loadCoreCategories();
    this.initUpdateCoreContractForAsset();
  }

  async saveForeignAssetToCore(asset: Asset): Promise<CoreContract> {
    if (asset.proposal) return;

    if (!isCommonFinancialProductAsset(asset)) return;

    const coreContract = this.assetToCoreContract(asset);
    if (!coreContract) return;

    try {
      this.info(
        'Save core contract',
        JSON.stringify(coreContract),
        'for assetUuid',
        asset.assetUuid,
      );
      this.info('Call Portal POST endpoint with data', coreContract);
      const familyUuid = await this.store.pipe(select(getFamilyUuid), take(1)).toPromise();
      const savedCoreContract = await this.integrationsService
        .postOpportunity({
          family_uuid: familyUuid,
          data: coreContract,
        })
        .toPromise();
      this.store.dispatch(
        CoreContractsActions.FA_SaveCoreContract({coreContract: savedCoreContract}),
      );

      asset = cloneDeep(asset);
      asset.sugarUuid = savedCoreContract.id;
      asset.foreignContract = true;
      this.store.dispatch(FinancialAnalysisActions.FA_UpdateAsset(asset as Asset));

      return savedCoreContract;
    } catch (e) {
      this.error(`Failed to save core contract for asset (assetUuid: ${asset.assetUuid})`, e);
      throw e;
    }
  }

  enqueueUpdateCoreContractForAsset(asset: CommonFinancialProductAsset) {
    this.updateCoreContractForAsset$.next(asset);
  }

  async getCoreContractForAsset(asset: CommonFinancialProductAsset) {
    const coreContracts = await this.store.pipe(select(selectCoreContracts), take(1)).toPromise();
    return coreContracts.find(c => c.id === asset.sugarUuid);
  }

  async updateCoreContractForAsset(asset: CommonFinancialProductAsset) {
    if (asset.proposal) return;

    this.info('Update core contract for asset', JSON.stringify(asset));
    const coreContract = await this.getCoreContractForAsset(asset);
    if (!coreContract) {
      this.info(
        'Unable to update core contract for assetUuid',
        asset.assetUuid,
        'with sugarUuid',
        asset.sugarUuid,
        'because it has no corresponding core contract.',
      );
      return;
    }
    if (isForeignContract(asset)) {
      await this.updateForeignCoreContractForAsset(asset, coreContract);
    } else {
      await this.updateKapitolCoreContractForAsset(asset, coreContract);
    }
  }

  async deleteForeignCoreContractForAsset(asset: CommonFinancialProductAsset): Promise<void> {
    this.info('Delete foreign contract for asset', JSON.stringify(asset));
    try {
      const familyUuid = await this.store.pipe(select(getFamilyUuid), take(1)).toPromise();
      await this.integrationsService
        .deleteForeignOpportunity({
          family_uuid: familyUuid,
          opportunity_id: asset.sugarUuid,
        })
        .toPromise();
      this.store.dispatch(CoreContractsActions.FA_DeleteCoreContract({id: asset.sugarUuid}));
      this.info('Foreign contract', asset.sugarUuid, 'deleted');
    } catch (e) {
      this.info('Failed to delete foreign contract', asset.sugarUuid);
    }
  }

  private async updateForeignCoreContractForAsset(
    asset: CommonFinancialProductAsset,
    coreContract: CoreContract,
  ) {
    const coreContractForUpdate = merge(cloneDeep(coreContract), this.assetToCoreContract(asset));
    await this.updateCoreContract(coreContractForUpdate);
  }

  private async updateKapitolCoreContractForAsset(
    asset: CommonFinancialProductAsset,
    coreContract: CoreContract,
  ) {
    const editableFieldOfKapitolContract: (keyof CoreContract)[] = ['secondaryStatus', 'endDate'];
    const coreContractForUpdate = merge(
      cloneDeep(coreContract),
      pick(this.assetToCoreContract(asset), editableFieldOfKapitolContract),
    );
    await this.updateCoreContract(coreContractForUpdate);
  }

  private async updateCoreContract(coreContractForUpdate: CoreContract) {
    // TODO update only when coreContractForUpdate is different from coreContract
    this.info('Update core contract', JSON.stringify(coreContractForUpdate));
    const familyUuid = await this.store.pipe(select(getFamilyUuid), take(1)).toPromise();
    const savedCoreContract = await this.integrationsService
      .postOpportunity({
        family_uuid: familyUuid,
        data: coreContractForUpdate,
      })
      .toPromise();
    this.store.dispatch(
      CoreContractsActions.FA_SaveCoreContract({coreContract: savedCoreContract}),
    );
    this.info('Updated core contract', JSON.stringify(savedCoreContract));
  }

  private assetToCoreContract(asset: CommonFinancialProductAsset): CoreContract | undefined {
    const coreCategoryCodes = assetTypesToCoreCategoriesTable[asset.type];

    if (!coreCategoryCodes.length) {
      this.info(
        'Skipping asset',
        asset.assetUuid,
        'with asset type',
        asset.type,
        'because it has no corresponding core category',
      );
      return undefined;
    }

    // Use first core category path if there are multiple
    const [cat1, cat2, cat3] = coreCategoryCodes[0];

    return {
      id: asset.sugarUuid ?? null,
      contactId: asset.stakeholderUuid,
      foreign: asset.foreignContract ?? true,
      name: asset.name ?? null,
      opportunityNumber: asset.contractNumber,
      paymentAmount: asset.value,
      paymentFrequency: {
        code: this.regularPaymentTypeToCoreFrequency(asset.frequency),
        codeDesc: null,
      },
      signatureDate: asset.start,
      beginDate: asset.start,
      endDate: getEndDate(asset.start, asset.end, asset.frequency) ?? null,
      partner: null,
      productCode: null,
      productCategory1: {
        code: this.coreCategoryCodeToId(cat1),
        codeDesc: null,
      },
      productCategory2: {
        code: this.coreCategoryCodeToId(cat2),
        codeDesc: null,
      },
      productCategory3: {
        code: this.coreCategoryCodeToId(cat3),
        codeDesc: null,
      },
      status: liveCoreStatus,
      secondaryStatus: {
        code: asset.coreSecondaryStatus,
        codeDesc: null,
      },
    };
  }

  private regularPaymentTypeToCoreFrequency(paymentType: RegularPaymentType): string {
    switch (paymentType) {
      case RegularPaymentType.Month:
        return '219902325555379';
      case RegularPaymentType.Quarter:
        return '219902325555380';
      case RegularPaymentType.HalfYear:
        return '219902325555381';
      case RegularPaymentType.Year:
        return '219902325555477';
      default:
        return '219902325555379';
    }
  }

  private coreCategoryCodeToId(code: string): string | null {
    return this.coreCategories.find(c => c.kod === code)?.id?.toString() ?? null;
  }

  private async loadCoreCategories() {
    this.coreCategories = await validCoreCategories;
  }

  private initUpdateCoreContractForAsset() {
    this.updateCoreContractForAsset$
      .pipe(
        groupBy(asset => asset.assetUuid),
        mergeMap(group =>
          group.pipe(
            debounceTime(500),
            mergeMap(asset => from(this.updateCoreContractForAsset(asset))),
          ),
        ),
      )
      .subscribe();
  }

  private info(...data: unknown[]) {
    this.remoteLoggerService.info('Core Sync', ...data);
  }

  private error(...data: unknown[]) {
    this.remoteLoggerService.error('Core Sync', ...data);
  }
}
