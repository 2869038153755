<div class="results">
  <ng-container *ngIf="!results.eligibility; else eligible">
    <label class="result-value">
      S ohledem na výsledek investičního dotazníku klientovi nelze sjednat investiční životní
      pojištění.
    </label>
  </ng-container>

  <ng-template #eligible>
    <div class="result">
      <div class="result-title">
        Dlouhodobé krytí nákladů (DKN)
        <kpt-help
          class="pl-3"
          tooltipCustomClass="align-left"
          text="Ukazatel DKN určuje, zda v případě výpadku příjmů finanční rezerva dokáže pokrýt výdaje domácnosti, včetně nákladů na finanční produkty, alespoň na dobu 2 měsíců."
        >
        </kpt-help>
      </div>
      <div class="result-value">{{ results.longTermCostCoverage }}</div>
    </div>
    <div class="result">
      <div class="result-title">
        Znalosti a zkušenosti
        <kpt-help class="pl-3" tooltipCustomClass="align-left" [text]="investorLevelTooltip">
        </kpt-help>
      </div>
      <div class="result-value result-points">{{ experience }}</div>
    </div>
    <div class="result">
      <div class="result-title">Investiční horizont</div>
      <div class="result-value">{{ results.horizonLabel }}</div>
    </div>
    <div class="result">
      <div class="result-title">Rizikový profil klienta</div>
      <div class="result-value">{{ risk }}</div>
    </div>
    <div class="result">
      <div class="result-title">Investiční strategie s maximálním možným rizikem</div>
      <div class="result-value">{{ results.strategyLabel }}</div>
    </div>
    <div *ngIf="results.inconsistentPortfolioAnswers" class="pt-3">
      Odpovědi klienta v&nbsp;investičním dotazníku ve&nbsp;vztahu k&nbsp;preferenci poměru
      výnos/riziko a k&nbsp;preferenci vzorového portfolia jsou ve&nbsp;vzájemném rozporu. Klient
      byl na&nbsp;toto upozorněn a přál si přesto pokračovat k&nbsp;vyhodnocení s&nbsp;těmito
      odpověďmi.
    </div>
  </ng-template>
</div>
