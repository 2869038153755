import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Selector, Store} from '@ngrx/store';
import {FieldWrapper} from '@ngx-formly/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {periodicPayment} from '@shared/analysis/formulas';
import {
  frequencyRate,
  getPeriodsCount,
  regularPaymentTypeToFrequency,
} from '@shared/analysis/helpers';
import {Asset, AssetType} from '@shared/analysis/models/asset';
import {InvestmentAsset} from '@shared/analysis/models/investment-products';
import {ObjectivesAsset} from '@shared/analysis/models/objectives';
import {objectiveSourceAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectCurrentAssets,
  selectObjectiveAdvisorProposedAssets,
  selectObjectiveClientProposedAssets,
  selectObjectivesAssets,
} from '@shared/analysis/store';
import {flatten, isEqual, reject} from 'lodash';
import * as moment from 'moment';
import {combineLatest, Observable} from 'rxjs';
import {delay, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {
  getTable,
  Objective,
  selectFinancialPlanObjectives,
} from 'src/app/modules/financial-plan/store';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';

const allowedObjectiveAssets: AssetType[] = [
  ...objectiveSourceAssets[ObjectiveType.Reserve],
  ...objectiveSourceAssets[ObjectiveType.Housing],
  ...objectiveSourceAssets[ObjectiveType.Pension],
  ...objectiveSourceAssets[ObjectiveType.Independence],
  ...objectiveSourceAssets[ObjectiveType.FinancialResources],
];

@UntilDestroy()
@Component({
  selector: 'kpt-asset-value-formly-wrapper',
  templateUrl: './asset-value-formly-wrapper.component.html',
})
export class AssetValueFormlyWrapperComponent extends FieldWrapper implements OnInit {
  payment: number;

  private disallowedAssetType: boolean;
  private disallowedRoute: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private assetsHandlerService: AssetsHandlerService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  get visible(): boolean {
    return (
      !this.disallowedAssetType &&
      !this.disallowedRoute &&
      isFinite(this.payment) &&
      this.payment > 0
    );
  }

  ngOnInit() {
    if (!this.router.url.includes('financial-plan')) {
      this.disallowedRoute = true;
      return;
    }

    const situation = this.assetsHandlerService.openAssetSituation;
    const situationAssets$ = this.getSituationAssetsWithoutCurrentlyEdited(situation);
    const currentAsset$ = this.getCurrentAsset();
    const objectiveAsset$ = this.getObjectiveAsset(currentAsset$);
    const objective$ = this.getObjective(objectiveAsset$);

    combineLatest([situationAssets$, objective$, objectiveAsset$, currentAsset$])
      .pipe(
        map(([situationAssets, objective, objectiveAsset, currentAsset]) =>
          this.computePayment(objective, objectiveAsset, situation, situationAssets, currentAsset),
        ),
        delay(0),
        untilDestroyed(this),
      )
      .subscribe(payment => (this.payment = payment));

    objectiveAsset$.pipe(untilDestroyed(this)).subscribe(objectiveAsset => {
      this.disallowedAssetType =
        !objectiveAsset ||
        !allowedObjectiveAssets.some(assetType => assetType === objectiveAsset.type);
    });
  }

  setPayment() {
    this.form.get('value').setValue(Math.round(this.payment));
  }

  private getCurrentAsset() {
    return this.field.form.valueChanges.pipe(
      startWith(this.field.form.value),
      map(model => ({...this.model, ...model} as InvestmentAsset)),
      distinctUntilChanged(isEqual),
    );
  }

  private getObjectiveAsset(currentAsset$: Observable<any>): Observable<ObjectivesAsset> {
    return combineLatest([currentAsset$, this.store.pipe(select(selectObjectivesAssets))]).pipe(
      map(([currentAsset, objectiveAssets]) => {
        return objectiveAssets.find(
          a => a.assetUuid === currentAsset.relatedObjectiveUuid,
        ) as ObjectivesAsset;
      }),
      distinctUntilChanged(isEqual),
    );
  }

  private getObjective(objectiveAsset$: Observable<ObjectivesAsset>): Observable<Objective | null> {
    return combineLatest([
      objectiveAsset$,
      this.store.pipe(
        select(
          selectFinancialPlanObjectives({
            showNewDashboard: this.featureFlagsService.showNewDashboard,
          }),
        ),
      ),
    ]).pipe(
      map(([objectiveAsset, objectives]) => {
        if (!objectiveAsset) return null;
        return flatten<Objective>(Object.values(objectives)).find(
          objective => objective.objectiveAsset?.assetUuid === objectiveAsset.assetUuid,
        );
      }),
      distinctUntilChanged(isEqual),
    );
  }

  private getSituationAssetsWithoutCurrentlyEdited(situation: Situation): Observable<Asset[]> {
    const situationAssetsSelector = this.getSituationAssetsSelector(situation);
    return this.store.pipe(
      select(situationAssetsSelector),
      map(assets => reject(assets, asset => asset.assetUuid === this.model.assetUuid)),
      distinctUntilChanged(isEqual),
    );
  }

  private getSituationAssetsSelector(situation: Situation): Selector<State, Asset[]> {
    switch (situation) {
      case Situation.Current:
        return selectCurrentAssets;
      case Situation.AdvisorProposed:
        return selectObjectiveAdvisorProposedAssets;
      case Situation.ClientProposed:
        return selectObjectiveClientProposedAssets;
    }
  }

  private computePayment(
    objective: Objective,
    objectiveAsset: ObjectivesAsset,
    situation: Situation,
    situationAssets: Asset[],
    currentAsset: InvestmentAsset,
  ): number {
    if (!objectiveAsset) return -1;

    const targetValue = objective.objectiveValue;
    const targetDate = objectiveAsset.start;

    const table = getTable(
      situation,
      situationAssets,
      objectiveAsset.assetUuid,
      targetValue,
      targetDate,
    );

    const futureValue = targetValue - table.sum.finalValue;
    const frequency = regularPaymentTypeToFrequency(currentAsset.frequency);
    const periodRate = currentAsset.yearlyRate * frequencyRate[frequency];
    const periods = getPeriodsCount(
      moment(currentAsset.presentValueDate),
      moment(targetDate),
      frequency,
    );

    return periodicPayment(periodRate, periods, -currentAsset.presentValue, futureValue);
  }
}
