import {periodicPayment} from 'src/shared/analysis/formulas';

export interface Payment {
  interest: number;
  amortization: number;
}

/**
 * Calculates repayment.
 *
 * @param loan amount
 * @param rate rate per period
 * @param loanTerm length in periods
 */
export function calculateRepayment(loan: number, rate: number, loanTerm: number): number {
  if (rate < 0 || loanTerm <= 0) return NaN;
  if (rate === 0) return loan / loanTerm;
  const q = (1 + rate) ** loanTerm;
  return (loan * rate * q) / (q - 1);
}

/**
 * Calculates periodic payment parts for given periods
 *
 * @param loan
 * @param rate
 * @param numLoanPeriods loan term in periods
 * @param numPeriods number of payment periods to calculate
 */
export function calculatePeriodicPayments(
  loan: number,
  rate: number,
  numLoanPeriods: number,
  numPeriods = numLoanPeriods,
): Payment[] {
  const payments = [];
  const pmt = periodicPayment(rate, numLoanPeriods, loan);
  let unpaidPrincipal = loan;
  for (let period = 0; period < numPeriods; period++) {
    const interest = unpaidPrincipal * rate;
    const amortization = pmt - interest;
    unpaidPrincipal -= amortization;
    payments.push({interest, amortization});
  }
  return payments;
}

/**
 * Calculated unpaid principal after given number of periods
 *
 * @param loan
 * @param rate
 * @param numPeriods
 */
export function calculateUnpaidPrincipal(
  loan: number,
  rate: number,
  numLoanPeriods: number,
  numPeriods: number,
) {
  return calculatePeriodicPayments(loan, rate, numLoanPeriods, numPeriods).reduce(
    (unpaidPrincipal, {amortization}) => unpaidPrincipal - amortization,
    loan,
  );
}

export function calculateLTV(credit: number, guarantee: number, ceiledToDecimals?: number) {
  if (!guarantee) return null;
  const ltv = (credit || 0) / guarantee;
  return !isNaN(ceiledToDecimals)
    ? Math.ceil(ltv * 10 ** ceiledToDecimals) / 10 ** ceiledToDecimals
    : ltv;
}
