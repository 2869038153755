import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DropdownBootstrapComponent} from './dropdown-bootstrap.component';

@NgModule({
  declarations: [DropdownBootstrapComponent],
  exports: [DropdownBootstrapComponent],
  imports: [CommonModule, BsDropdownModule.forRoot()],
})
export class DropdownBootstrapModule {}
