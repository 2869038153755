import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {
  PropertyProvisionAsset,
  PropertyRequirementsAsset,
} from '@shared/analysis/models/objectives';
import {selectAllAssets} from '@shared/analysis/store';
import {map, take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class PropertyRequirementsAssetType extends BaseAssetType {
  constructor(private store: Store<State>) {
    super();
  }

  async create(opts: {
    relatedPropertyUuid: string;
    relatedObjectiveUuid: string;
  }): Promise<PropertyRequirementsAsset> {
    if (!opts.relatedPropertyUuid) throw new Error('relatedPropertyUuid is required');
    if (!opts.relatedObjectiveUuid) throw new Error('relatedObjectiveUuid is required');

    const provision = await this.store
      .pipe(
        select(selectAllAssets),
        map(
          assets =>
            assets.find(a => a.type === AssetType.PropertyProvision) as PropertyProvisionAsset,
        ),
        take(1),
      )
      .toPromise();

    return {
      type: AssetType.PropertyRequirements,
      assetUuid: this.createId(),
      relatedPropertyUuid: opts.relatedPropertyUuid,
      requirementType: provision.requirementType,
      contractPeriod: provision.contractPeriod,
      contractPeriodCustom: provision.contractPeriodCustom,
      risks: provision.risks,
      note: provision.note,
      description: provision.description,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.PropertyRequirements],
      type: AssetType.PropertyRequirements,
    };
  }
}
