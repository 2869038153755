import {Component} from '@angular/core';
import {AssetType} from '@shared/analysis/models/asset';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {DialogService} from '@shared/services/modal.service';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';

export interface AddAssetResult {
  type: 'new' | 'existing';
  assetType?: AssetType;
  assetUuid?: string;
  situation?: Situation;
}

@Component({
  selector: 'kpt-add-asset-modal',
  templateUrl: './add-asset-modal.component.html',
})
export class AddAssetModalComponent {
  objectiveType: ObjectiveType = null;
  relatedObjectiveUuid: string = null;
  relatedPropertyUuid: string = null;
  stakeholderUuid: string = null;
  assetType: AssetType = null;
  situation: Situation = Situation.Current;
  bothSituations = false;

  Situation = Situation;
  ObjectiveType = ObjectiveType;

  constructor(private modalService: DialogService) {}

  dismiss() {
    this.modalService.dismiss();
  }

  addExistingAsset(assetUuid: string) {
    this.modalService.close({type: 'existing', assetUuid} as AddAssetResult);
  }

  addNewAsset(assetType: AssetType, situation: Situation) {
    if (assetType) {
      this.modalService.close({type: 'new', assetType, situation} as AddAssetResult);
    }
  }
}
