import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {hasLifeInsurancePortfolioInconsistency} from '@shared/investment-survey-old/investment-survey.utils';
import {Answers} from '@shared/investment-survey-old/models/answer';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {SurveyParticipant} from '@shared/ui/survey/survey-participants/survey-participants.component';
import {isEqual} from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {
  LongTermCostCoverageEnum,
  Strategy,
  experienceLabelI18n,
  RiskSummaryResult,
  riskSummaryTable,
  ExperienceKey,
} from 'src/app/modules/life-insurance-old/4-investment-survey/investment-survey.models';
import {getSummaryResultsFor} from 'src/app/modules/life-insurance-old/4-investment-survey/investment-survey.utils';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {selectOrderedPersons} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';
import {SurveyResults} from './life-insurance-summary.component';

@Injectable()
export class LifeInsuranceSummaryService {
  participants: SurveyParticipant[];

  constructor(private store: Store<State>, private questions: Questions) {
    this.loadSurveyParticipants().subscribe(participants => (this.participants = participants));
  }

  loadSurveyParticipants(): Observable<SurveyParticipant[]> {
    return this.store.pipe(
      select(selectOrderedPersons),
      distinctUntilChanged<LifeInsurancePerson[]>(isEqual),
      map((members: LifeInsurancePerson[]) => this.loadFamilyMembersFromAnalysis(members)),
    );
  }

  calculateResults(
    survey: SurveyAnswers,
    withLastName = true,
    analysisResult = false,
  ): SurveyResults {
    const experiencePoints = this.getExperiencePoints(survey.answers, analysisResult);
    const experience = this.getExperienceResultKey(
      experiencePoints,
      survey.answers,
      analysisResult,
    );
    const experienceLabel = experienceLabelI18n[experience];
    const riskPoints = analysisResult
      ? this.getRiskPoints(survey.answers)
      : this.getAppropriateRiskPoints(survey.answers);
    const risk = this.getRisk(riskPoints);
    const horizon = survey.answers.investmentDurationLi.answer as string;
    const horizonLabel = this.questions
      .questionByQuestionId('investmentDurationLi')
      .answers.find(a => a.id === horizon)?.text;
    const strategy = this.getStrategy(risk, experience);
    const strategyLabel = this.getStrategyLabel(strategy, survey.answers);
    const strategyKey = this.getStrategyKey(strategy);

    const participant = this.participants.find(p => p.sugarUuid === survey.sugarUuid);

    const eligibility = horizon !== 'max-five-years';
    const inconsistentPortfolioAnswers =
      !analysisResult && hasLifeInsurancePortfolioInconsistency(survey.answers);

    return {
      name: withLastName ? `${participant.name} ${participant.lastName}` : participant.name,
      longTermCostCoverage: participant.longTermCostCoverage,
      experience,
      experiencePoints,
      experienceLabel,
      riskPoints,
      riskId: risk ? risk.key : null,
      riskI18n: risk ? risk.value : null,
      strategy,
      strategyLabel,
      horizon,
      horizonLabel,
      strategyKey,
      eligibility,
      inconsistentPortfolioAnswers,
    };
  }

  private getExperiencePoints(answers: Answers, analysisResult = false): number {
    const experienceAnswers = {
      financialMarketNews: answers.financialMarketNews,
      education: answers.education,
      experience: answers.experience,
      transactions: answers.transactions,
    } as Answers;

    return this.questions.getPoints(
      analysisResult
        ? {...experienceAnswers, knowledgeInv: answers.knowledgeInv}
        : {...experienceAnswers, knowledgeLi: answers.knowledgeLi},
    );
  }

  private getExperienceResultKey(
    points: number,
    answers: Answers,
    analysisResult = false,
  ): ExperienceKey {
    if (this.forceGuaranteedFund(answers)) return 'a';
    if (analysisResult) {
      if (points <= -4) {
        return 'a';
      } else if (points <= 6) {
        return 'b';
      } else if (points <= 18) {
        return 'c';
      } else return 'd';
    }
    if (points <= -3) {
      return 'a';
    } else if (points <= 6) {
      return 'b';
    } else if (points <= 14) {
      return 'c';
    } else return 'd';
  }

  private getRiskPoints(answers: Answers): number {
    const riskAnswers = {
      risk: answers.risk,
      graph: answers.graph,
      lossOfInvestment: answers.lossOfInvestment,
    } as Answers;

    return this.questions.getPoints(riskAnswers);
  }

  private getAppropriateRiskPoints(answers: Answers): number {
    let riskPoints = this.questions.getPoints(answers, ['risk']);
    let graphPoints = this.questions.getPoints(answers, ['graph']);
    const lossOfInvestmentPoints = this.questions.getPoints(answers, ['lossOfInvestment']);
    if (answers.risk.answer === 'no-risk' && answers.graph.answer !== 'A') {
      graphPoints = 1;
    } else if (
      answers.risk.answer === 'almost-none-risk' &&
      !['A', 'B'].includes(answers.graph.answer as string)
    ) {
      graphPoints = 2;
    } else if (
      answers.risk.answer === 'low-risk' &&
      !['B', 'C'].includes(answers.graph.answer as string)
    ) {
      if (graphPoints === 1) riskPoints = 1;
      else graphPoints = 3;
    } else if (
      ['partial-risk', 'max-risk'].includes(answers.risk.answer as string) &&
      !['C', 'D'].includes(answers.graph.answer as string)
    ) {
      riskPoints = graphPoints;
    }
    return riskPoints + graphPoints + lossOfInvestmentPoints;
  }

  private getRisk(riskPoints: number): RiskSummaryResult {
    return getSummaryResultsFor(riskPoints, riskSummaryTable);
  }

  private getStrategy(risk: RiskSummaryResult, experience: string): Strategy {
    if (experience === 'a') return 'A';
    if (experience === 'b') return 'B';
    if (experience === 'c') {
      if (risk.key === 'a') return 'B';
      return 'C';
    }

    if (risk.key === 'c') return 'D';
    return 'C';
  }

  private getStrategyLabel(strategy: Strategy, answers: Answers): string {
    if (this.forceGuaranteedFund(answers)) return 'lze sjednat pouze garantovaný fond';

    if (strategy === 'A') return 'Garantovaný fond';
    if (strategy === 'B') return 'Konzervativní strategie';
    if (strategy === 'C') return 'Vyvážená strategie';
    return 'Dynamická strategie';
  }

  private getStrategyKey(strategy: Strategy): string {
    if (strategy === 'A') return 'guaranteed-fund';
    if (strategy === 'B') return 'conservative';
    if (strategy === 'C') return 'balanced';
    return 'dynamic';
  }

  private loadFamilyMembersFromAnalysis(persons: LifeInsurancePerson[]): SurveyParticipant[] {
    return persons
      .filter(person => !person.child)
      .map(person => {
        const personId = person.id;
        return {
          sugarUuid: personId,
          name: person.name,
          lastName: person.lastName,
          longTermCostCoverage: this.getLongTermCostCoverage(person),
        } as SurveyParticipant;
      });
  }

  private getLongTermCostCoverage(person: LifeInsurancePerson) {
    const savingsThreshold = 50_000;
    const savings = person.savings;
    const expenses = person.expenses;

    if (!expenses && savings > savingsThreshold) return LongTermCostCoverageEnum.sufficient;

    const longTermCostCoverageValue = savings / (2 * expenses);
    return longTermCostCoverageValue >= 1 && savings > savingsThreshold
      ? LongTermCostCoverageEnum.sufficient
      : LongTermCostCoverageEnum.insufficient;
  }

  private forceGuaranteedFund(answers: Answers): boolean {
    return (
      answers.lossOfInvestment.answer === 'everything' ||
      answers.graph.answer === 'A' ||
      answers.risk.answer === 'no-risk'
    );
  }
}
