import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {FieldLabel} from '@shared/analysis/field-label';
import {RetirementInputMode} from '@shared/analysis/forms/definitions.models';
import {AssetType} from '@shared/analysis/models/asset';
import {
  ChildBirthAsset,
  NewHousingAsset,
  ObjectiveAsset,
  OtherObjectiveAsset,
  OtherObjectivePaymentType,
  OwnHousingAsset,
  ReconstructionAsset,
  RetirementAsset,
} from '@shared/analysis/models/objectives';
import {selectObjectivesAssets} from '@shared/analysis/store';
import {getMonthsPluralString, getYearsPluralString} from '@shared/lib';
import * as moment from 'moment';
import {
  createItem,
  formatCZKNotNull,
  formatPercent,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {Category, CategoryType, Item, ItemsGroup, Section} from './summary.models';

export const selectPlans = createSelector(
  getFamilyMembers,
  selectObjectivesAssets,
  (familyMembers, assets) => createSection(assets as ObjectiveAsset[], familyMembers),
);

function createSection(assets: ObjectiveAsset[], familyMembers: FamilyMember[]): Section {
  return {
    label: 'Plány',
    categories: assets.length === 0 ? [] : [createCategory(assets, familyMembers)],
  };
}

function createCategory(assets: ObjectiveAsset[], familyMembers: FamilyMember[]): Category {
  return {
    categoryTypes: [createCategoryType(assets, familyMembers)],
  };
}

function createCategoryType(assets: ObjectiveAsset[], familyMembers: FamilyMember[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset, familyMembers)),
  };
}

function createItemsGroup(asset: ObjectiveAsset, familyMembers: FamilyMember[]): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    items: [
      ...createItemFunctions[asset.type](asset, familyMembers),
      ...createCommonItems(asset),
    ].filter(Boolean),
  };
}

const createItemFunctions: Partial<
  Record<AssetType, (asset: ObjectiveAsset, familyMembers?: FamilyMember[]) => Item[]>
> = {
  [AssetType.OwnHousing]: createOwnHousingItems,
  [AssetType.ChildrenFuture]: createChildrenFutureItems,
  [AssetType.Retirement]: createRetirementItems,
  [AssetType.FinancialIndependence]: createFinancialIndependenceItems,
  [AssetType.ShortTermReserve]: createShortTermReserveItems,
  [AssetType.LongTermReserve]: createLongTermReserveItems,
  [AssetType.BadDebtsRepayment]: createBadDebtsRepaymentItems,
  [AssetType.FamilyProvision]: createFamilyProvisionItems,
  [AssetType.PropertyProvision]: createPropertyProvisionItems,
  [AssetType.VehicleProvision]: createVehicleProvisionItems,
  [AssetType.ChildBirth]: createChildBirthItems,
  [AssetType.NewHousing]: createNewHousingItems,
  [AssetType.Reconstruction]: createReconstructionItems,
  [AssetType.Furnishings]: createFurnishingsItems,
  [AssetType.Car]: createCarItems,
  [AssetType.Vacation]: createVacationItems,
  [AssetType.OtherObjective]: createOtherObjectiveItems,
};

function createOwnHousingItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const ownHousingAsset = asset as OwnHousingAsset;
  return [
    createItem(FieldLabel.OWN_HOUSING_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.TOTAL_VALUE, formatCZKNotNull(ownHousingAsset.totalValue)),
    createItem(FieldLabel.OWN_VALUE_RATIO, formatPercent(asset.value / ownHousingAsset.totalValue)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createChildrenFutureItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.CHILDREN_FUTURE_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createRetirementItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const retirementAsset = asset as RetirementAsset;
  return [
    getFamilyMemberNameItem(
      FieldLabel.RETIREMENT_FAMILY_MEMBER_RADIO_LABEL,
      familyMembers,
      retirementAsset.familyMemberUuid,
    ),
    createItem(
      FieldLabel.RETIREMENT_START,
      getAgeByFutureDate(asset.start, familyMembers, retirementAsset.familyMemberUuid),
    ),
    retirementAsset.retirementInputMode === RetirementInputMode.Value
      ? createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value))
      : createItem(
          FieldLabel.RETIREMENT_MONTHLY_RENT,
          formatCZKNotNull(retirementAsset.monthlyRent),
        ),
    createItem(FieldLabel.RETIREMENT_PENSION_DURATION, `${retirementAsset.pensionDuration}`),
  ];
}

function createFinancialIndependenceItems(
  asset: ObjectiveAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.INDEPENDENCE_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createShortTermReserveItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.SHORT_RESERVE_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.SHORT_RESERVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createLongTermReserveItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.LONG_TERM_RESERVE_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.LONG_TERM_RESERVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createBadDebtsRepaymentItems(
  asset: ObjectiveAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(
      FieldLabel.BAD_DEBTS_REPAYMENT_START,
      getAgeByFutureDate(asset.start, familyMembers),
    ),
    createItem(FieldLabel.BAD_DEBTS_REPAYMENT_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createFamilyProvisionItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [createItem(FieldLabel.PROVISION_START, getAgeByFutureDate(asset.start, familyMembers))];
}

function createPropertyProvisionItems(
  asset: ObjectiveAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [createItem(FieldLabel.PROVISION_START, getAgeByFutureDate(asset.start, familyMembers))];
}

function createVehicleProvisionItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [createItem(FieldLabel.PROVISION_START, getAgeByFutureDate(asset.start, familyMembers))];
}

function createChildBirthItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const childBirthAsset = asset as ChildBirthAsset;
  return [
    createItem(FieldLabel.CHILD_BIRTH_START, getAgeByFutureDate(asset.start, familyMembers)),
    getFamilyMemberNameItem(
      FieldLabel.CHILD_BIRTH_MATERNITY,
      familyMembers,
      childBirthAsset.maternityFamilyMemberUuid,
    ),
    getFamilyMemberNameItem(
      FieldLabel.CHILD_BIRTH_PARENTAL,
      familyMembers,
      childBirthAsset.parentalFamilyMemberUuid,
    ),
    createItem(
      FieldLabel.CHILD_BIRTH_START,
      getMonthsPluralString(childBirthAsset.parentalBenefitInMonths),
    ),
  ];
}

function createNewHousingItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const newHousingAsset = asset as NewHousingAsset;
  return [
    createItem(FieldLabel.NEW_HOUSING_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.TOTAL_VALUE, formatCZKNotNull(newHousingAsset.totalValue)),
    createItem(
      FieldLabel.OWN_VALUE_RATIO,
      `${Math.round((asset.value / newHousingAsset.totalValue) * 100)}%`,
    ),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createReconstructionItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const reconstructionAsset = asset as ReconstructionAsset;
  return [
    createItem(FieldLabel.RECONSTRUCTION_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.TOTAL_VALUE, formatCZKNotNull(reconstructionAsset.totalValue)),
    createItem(
      FieldLabel.OWN_VALUE_RATIO,
      `${Math.round((asset.value / reconstructionAsset.totalValue) * 100)}%`,
    ),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createFurnishingsItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.FURNISHING_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createCarItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.NEW_CAR_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createVacationItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.VACATION_START, getAgeByFutureDate(asset.start, familyMembers)),
    createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createOtherObjectiveItems(asset: ObjectiveAsset, familyMembers: FamilyMember[]): Item[] {
  const otherObjectiveAsset = asset as OtherObjectiveAsset;
  if (otherObjectiveAsset.payment === OtherObjectivePaymentType.OneTime) {
    return [
      createItem(
        FieldLabel.OTHER_OBJECTIVE_ONE_TIME_START,
        getAgeByFutureDate(asset.start, familyMembers),
      ),
      createItem(FieldLabel.OBJECTIVE_VALUE, formatCZKNotNull(asset.value)),
    ];
  }
  return [
    createItem(
      FieldLabel.OTHER_OBJECTIVE_MONTHLY_START,
      `za ${getAgeByFutureDate(asset.start, familyMembers)} po dobu ${getAgeByFutureDate(
        asset.end as string,
        familyMembers,
      )}`,
    ),
    createItem(FieldLabel.OTHER_OBJECTIVE_MONTHLY_VALUE, formatCZKNotNull(asset.value)),
  ];
}

function createCommonItems(asset: ObjectiveAsset): Item[] {
  return [createNoteItem(asset), createDescriptionItem(asset)];
}

function createNoteItem(asset: ObjectiveAsset): Item {
  return createItem(FieldLabel.NOTE, asset.note);
}

function createDescriptionItem(asset: ObjectiveAsset): Item {
  return createItem(FieldLabel.OBJECTIVE_DESCRIPTION, asset.description);
}

function getAgeByFutureDate(
  start: string,
  familyMembers: FamilyMember[],
  memberUuid?: string,
): string {
  const head =
    familyMembers.find(m => m.sugarUuid === memberUuid) ?? familyMembers.find(m => m.familyHead);
  if (!head) return '–';
  return getYearsPluralString(moment(start).diff(moment(head.birthDate).startOf('year'), 'years'));
}

function getFamilyMemberNameItem(
  label: string,
  familyMembers: FamilyMember[],
  memberUuid: string,
): Item {
  const foundMember = familyMembers.find(familyMember => familyMember.sugarUuid === memberUuid);
  return foundMember ? createItem(label, foundMember.firstName) : null;
}
