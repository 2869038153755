import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CreateClientParams} from '@generated/controllers/Integrations';
import {FoundPortalClient} from '@generated/defs/FoundPortalClient';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {ClientSearchService} from '@shared/services/client/client-search.service';
import {FamilyService} from '@shared/services/family/family.service';
import {filter} from 'rxjs/operators';
import {DialogService} from 'src/shared/services/modal.service';
import {State} from 'src/store';
import {selectCancellableRequest as selectCancellableRequestCreateClient} from 'src/store/selectors/create-client.selectors';
import {
  getFindClientsState,
  selectCancellableRequest,
} from 'src/store/selectors/find-clients.selector';
import {notLoadingNorError} from 'src/store/store-helpers';

@UntilDestroy()
@Component({
  selector: 'kpt-add-family-member-modal',
  templateUrl: './add-family-member-modal.component.html',
  styleUrls: ['./add-family-member-modal.component.scss'],
})
export class AddFamilyMemberModalComponent implements OnInit, OnDestroy {
  findClients$ = this.store.pipe(select(getFindClientsState));
  cancellableRequestFindClient$ = this.store.select(selectCancellableRequest);
  cancellableRequestCreateClient$ = this.store.select(selectCancellableRequestCreateClient);
  familyUuid: string = null;

  @ViewChild('results', {read: ElementRef, static: false}) private resultsElem: ElementRef;

  constructor(
    private store: Store<State>,
    public modalService: DialogService,
    public clientSearchService: ClientSearchService,
    private familyService: FamilyService,
  ) {}

  ngOnInit(): void {
    this.findClients$
      .pipe(
        filter(() => this.clientSearchService.showSearchResults),
        filter(notLoadingNorError),
        untilDestroyed(this),
      )
      .subscribe(state => this.clientSearchService.setState(state, this.resultsElem));

    this.cancellableRequestFindClient$
      .pipe(untilDestroyed(this))
      .subscribe((r: boolean) => (this.clientSearchService.cancellableFindClient = r));

    this.cancellableRequestCreateClient$
      .pipe(untilDestroyed(this))
      .subscribe((r: boolean) => (this.clientSearchService.cancellableCreateClient = r));
  }

  ngOnDestroy(): void {
    this.clientSearchService.resetSearchState();
  }

  createClient(createClient: CreateClientParams) {
    this.modalService.dismiss();
    this.familyService.addFamilyMember(this.familyUuid, createClient);
  }

  addClientToFamily(clientId: string) {
    this.modalService.close(clientId);
  }

  dismiss() {
    this.modalService.dismiss();
  }

  reassign(client: FoundPortalClient) {
    this.dismiss();
    this.clientSearchService.reassign(client, true);
  }
}
