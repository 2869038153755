import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {FormModule, TabsModule} from '@shared/ui';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {AssetLifeInsuranceRisksFormlyComponent} from './asset-life-insurance-risks-formly.component';

@NgModule({
  declarations: [AssetLifeInsuranceRisksFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    FormModule,
    FormlyModule,
    TabsModule,
  ],
  exports: [AssetLifeInsuranceRisksFormlyComponent],
})
export class AssetLifeInsuranceRisksFormlyModule {}
