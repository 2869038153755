<div class="bubbles-holder">
  <div class="bubble bubble--left">
    <div class="bubble__inner">
      <div class="bubble__text">
        <span class="bubble__label"> Celkové příjmy </span>
        <span class="bubble__amount"> {{ incomes | number : '1.0-0' }} Kč </span>
      </div>
    </div>
  </div>
  <div class="bubble bubble--center">
    <div class="bubble__inner">
      <div class="bubble__text">
        <span class="bubble__label"> Celkové výdaje </span>
        <span class="bubble__amount"> {{ costs | number : '1.0-0' }} Kč </span>
      </div>
    </div>
  </div>
  <div class="bubble bubble--right">
    <div class="bubble__inner">
      <div class="bubble__text">
        <span class="bubble__label"> Měsíční zůstatek </span>
        <span class="bubble__amount"> {{ incomes - costs | number : '1.0-0' }} Kč </span>
      </div>
    </div>
  </div>
</div>
