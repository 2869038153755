export type ExperienceKey = 'a' | 'b' | 'c' | 'd';
export type RiskKey = 'a' | 'b' | 'c';

export type Strategy = 'A' | 'B' | 'C' | 'D' | 'GF';

export enum LongTermCostCoverageEnum {
  sufficient = 'Dostatečné',
  insufficient = 'Nedostatečné',
}

export type RiskSummaryResult = {
  key: RiskKey;
  value: string;
};

export type RiskSummaryTable = {
  [key in RiskKey]: {
    i18n: string;
    range(points: number): boolean;
  };
};

export enum EligibleResult {
  NotFilled,
  NotEligible,
  Eligible,
}

export type InvestmentEligible = Record<string, EligibleResult>;

export const experienceLabelI18n: Record<ExperienceKey, string> = {
  a: 'Klient neakceptující ztrátu',
  b: 'Základní investor',
  c: 'Informovaný investor',
  d: 'Pokročilý investor',
};

export const riskSummaryTable: RiskSummaryTable = {
  a: {
    i18n: 'Konzervativní profil (SRI max. 2)',
    range: (points: number) => points <= 3,
  },
  b: {
    i18n: 'Vyvážený profil (SRI max. 4)',
    range: (points: number) => points >= 4 && points <= 7,
  },
  c: {
    i18n: 'Dynamický profil (SRI max. 5)',
    range: (points: number) => points >= 8,
  },
};
