import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldType} from '@ngx-formly/core';
import {debounceTime} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'kpt-slider-input-formly',
  templateUrl: './slider-input-formly.component.html',
  styleUrls: ['./slider-input-formly.component.scss'],
})
export class SliderInputFormlyComponent extends FieldType implements OnInit, OnDestroy {
  sliderParentForm: UntypedFormGroup;

  inputForm: UntypedFormControl;
  sliderForm: UntypedFormControl;

  ngOnInit() {
    this.sliderForm = new UntypedFormControl(this.convertInput(this.formControl.value));

    this.sliderParentForm = new UntypedFormGroup({
      slider: this.sliderForm,
    });

    this.inputForm = new UntypedFormControl(
      this.to.sliderToInputTranslate
        ? this.to.sliderToInputTranslate(this.sliderForm.value)
        : this.sliderForm.value,
      this.formControl.validator,
      this.formControl.asyncValidator,
    );

    this.sliderForm.valueChanges.pipe(untilDestroyed(this)).subscribe(evt => {
      this.onSliderChange(evt);
    });

    this.inputForm.valueChanges
      .pipe(debounceTime(this.to.disabled ? 0 : 500), untilDestroyed(this))
      .subscribe(evt => this.onInputChange(evt));
  }

  onSliderChange(evt: number) {
    this.formControl.patchValue(this.convertOutput(evt), {emitEvent: false});
    if (this.to.sliderToInputTranslate) {
      evt = this.to.sliderToInputTranslate(evt);
    }
    this.inputForm.patchValue(evt, {emitEvent: false});
  }

  onInputChange(evt: number) {
    if (this.to.inputToSliderTranslate) {
      evt = this.to.inputToSliderTranslate(evt);
    }
    this.sliderForm.patchValue(evt, {emitEvent: false});
    this.formControl.patchValue(this.convertOutput(evt), {emitEvent: false});
  }

  ngOnDestroy() {}

  private convertInput(value: any) {
    return this.to.convertInput ? this.to.convertInput(value) : value;
  }

  private convertOutput(value: number) {
    return this.to.convertOutput ? this.to.convertOutput(value) : value;
  }
}
