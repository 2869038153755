import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

const loan = {
  url: 'loan',
  done: false,
  label: 'Úvěr',
};

export const consumerLoansStepperProgressConfig: IStepperProgressItem[] =
  createStepperProgressConfig([loan], SecuredPage.Client, ClientPage.ConsumerLoan);

export const consumerLoansStepperProgressConfigInModal: IStepperProgressItem[] = [loan];
