import {DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {AmountPipe} from './amount/amount.pipe';
import {ConsultantLevelPipe} from './consultant-level/consultant-level.pipe';
import {DateFormatPipe} from './date-format/date-format.pipe';
import {KeysPipe} from './keys/keys.pipe';
import {PhoneNumberPipe} from './phone-number/phone-number.pipe';
import {PluralPipe} from './plural/plural.pipe';
import {PrecisionPipe} from './precision/precision.pipe';
import {SafeUrlPipe} from './safe-url/sage-url.pipe';
import {TruncatePipe} from './truncate/truncate.pipe';
import {ValuesPipe} from './values/values.pipe';
import {VocativPipe} from './vocativ/vocativ.pipe';
import {YearToIntervalPipe} from './year-to-interval/year-to-interval.pipe';
import {YesNoPipe} from './yes-no/yes-no.pipe';

@NgModule({
  declarations: [
    AmountPipe,
    ConsultantLevelPipe,
    DateFormatPipe,
    KeysPipe,
    PhoneNumberPipe,
    PluralPipe,
    PrecisionPipe,
    SafeUrlPipe,
    TruncatePipe,
    ValuesPipe,
    VocativPipe,
    YearToIntervalPipe,
    YesNoPipe,
  ],
  exports: [
    AmountPipe,
    DateFormatPipe,
    KeysPipe,
    PhoneNumberPipe,
    PluralPipe,
    PrecisionPipe,
    SafeUrlPipe,
    TruncatePipe,
    ValuesPipe,
    VocativPipe,
    YearToIntervalPipe,
    YesNoPipe,
    ConsultantLevelPipe,
  ],
  providers: [
    AmountPipe,
    DateFormatPipe,
    DecimalPipe,
    KeysPipe,
    PhoneNumberPipe,
    PluralPipe,
    PrecisionPipe,
    SafeUrlPipe,
    TruncatePipe,
    ValuesPipe,
    VocativPipe,
    YearToIntervalPipe,
    YesNoPipe,
  ],
})
export class PipesModule {}
