import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FieldType} from '@ngx-formly/core';
import {RisksSelectionService} from '@shared/analysis/components/risks-selection/risks-selection.service';
import {RisksSelectionDataService} from '@shared/analysis/risks-selection-data.service';
import {selectRisksAdditionalData} from '@shared/analysis/store';
import {SynchronizationService} from '@shared/analysis/synchronization.service';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {combineLatest, of} from 'rxjs';
import {filter, mergeMap, take, tap} from 'rxjs/operators';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {selectOrderedPersons} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@UntilDestroy()
@Component({
  selector: 'kpt-risks-life-insurance-formly',
  templateUrl: './risks-life-insurance-formly.component.html',
  styleUrls: ['./risks-life-insurance-formly.component.scss'],
})
export class RisksLifeInsuranceFormlyComponent extends FieldType implements OnInit {
  familyMembers: LifeInsurancePerson[];
  loaded: boolean;

  constructor(
    private lifeInsuranceDataService: LifeInsuranceDataService,
    private syncService: SynchronizationService,
    private store: Store<State>,
    private risksSelectionService: RisksSelectionService,
    private risksSelectionDataService: RisksSelectionDataService,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.store.select(getFamilyUuid).pipe(take(1)),
      this.lifeInsuranceDataService.loaded$,
    ])
      .pipe(
        filter(([_, loaded]) => loaded),
        tap(([familyUuid, _]) => this.risksSelectionDataService.getRisksAdditionalData(familyUuid)),
        mergeMap(([familyUuid, _]) => {
          this.syncService.syncPersonsFromFinancialAnalysisToLifeInsurance();
          return combineLatest([
            this.store.pipe(select(selectOrderedPersons)).pipe(take(1)),
            of(familyUuid),
            this.store.pipe(select(selectRisksAdditionalData), filter(Boolean)),
          ]);
        }),
        mergeMap(([familyMembers, familyUuid, _additionalData]) => {
          this.familyMembers = familyMembers;
          this.risksSelectionService.init(this.form, familyUuid, true, false);
          return this.risksSelectionService.formLoaded;
        }),
        tap(loaded => (this.loaded = loaded)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
