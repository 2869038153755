<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>

  <kpt-row>
    <kpt-objective-age-input
      grow
      label="Chci dosáhnout finanční nezávislosti ve věku"
      name="start"
      [(start)]="this.asset.start"
    ></kpt-objective-age-input>
    <kpt-input
      grow
      type="number"
      label="Chci si našetřit částku"
      appendText="Kč"
      [disabled]="asset.independenceInputMode === 'rent'"
      name="value"
      [(ngModel)]="asset.value"
      required
      [max]="defaultObjectiveMaxValue"
    ></kpt-input>
  </kpt-row>

  <kpt-switch
    label="Zadat měsíční rentu"
    [optionsMap]="inputModeOptions"
    name="independenceInputMode"
    [(ngModel)]="asset.independenceInputMode"
  ></kpt-switch>

  <kpt-row class="mb-3">
    <kpt-slider2
      grow
      label="Částka, kterou chci měsíčně"
      [floor]="0"
      [ceil]="100000"
      [step]="1000"
      [translate]="translateSlider"
      [disabled]="asset.independenceInputMode === 'value'"
      [(value)]="asset.monthlyRent"
    ></kpt-slider2>
    <kpt-input
      grow
      type="number"
      [min]="0"
      [max]="100000"
      [step]="1000"
      appendText="Kč"
      [disabled]="asset.independenceInputMode === 'value'"
      name="monthlyRent"
      [(ngModel)]="asset.monthlyRent"
    ></kpt-input>
  </kpt-row>

  <kpt-input
    type="number"
    label="Očekávám, že se dožiji"
    appendText="let"
    name="lifeExpectancy"
    [(ngModel)]="asset.lifeExpectancy"
  ></kpt-input>

  <kpt-input
    label="Poznámka"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>

  <kpt-investment-risk-profile [asset]="asset"></kpt-investment-risk-profile>
</ng-container>
