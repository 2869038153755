export type ValueLabelPair = {
  value: string;
  label: string;
};

export enum ZzjInvestmentPreference {
  Guaranty = 'GUARANTY',
  RatherGuaranty = 'RATHER_GUARANTY',
  Balance = 'BALANCE',
  RatherYield = 'RATHER_YIELD',
  Yield = 'YIELD',
}

export enum ZzjInvestmentPortfolio {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum ZzjInvestmentLossValue {
  AllSavings = 'ALL_SAVINGS',
  FutureThreat = 'FUTURE_THREAT',
  AcceptableDamage = 'ACCEPTABLE_DAMAGE',
  NoBigDeal = 'NO_BIG_DEAL',
}

export const INVESTMENT_QUESTION_PREFERENCE_LABEL =
  'Co je pro mě důležitější – vysoký výnos nebo větší jistota?';
export const INVESTMENT_QUESTION_PREFERENCE_ITEMS: ValueLabelPair[] = [
  {
    value: ZzjInvestmentPreference.Guaranty,
    label: 'Chci získat minimálně to, co jsem do investice vložil - požaduji garanci bez ztrát',
  },
  {
    value: ZzjInvestmentPreference.RatherGuaranty,
    label:
      'Nejdůležitější pro mě je co největší zachování hodnoty investice, i za cenu nižšího výnosu.',
  },
  {
    value: ZzjInvestmentPreference.Balance,
    label:
      'Očekávám výnosy nad úrovní bankovních vkladů, a tomu odpovídající riziko vzniku ztráty. ' +
      'Neočekávám nadprůměrné výnosy, neboť nejsem ochoten podstupovat takto zvýšené riziko. ' +
      'Prioritou pro mě je spíše ochrana investice.',
  },
  {
    value: ZzjInvestmentPreference.RatherYield,
    label: 'Očekávám nadprůměrné výnosy a jsem ochoten podstoupit adekvátní riziko vzniku ztráty.',
  },
  {
    value: ZzjInvestmentPreference.Yield,
    label:
      'Vyhledávám zajímavé investice a jsem ochoten/ochotna podstoupit i riziko ztráty celé investice.',
  },
];
export const INVESTMENT_QUESTION_PREFERENCE_SHORT_LABELS: {
  [preference in ZzjInvestmentPreference]: string;
} = {
  [ZzjInvestmentPreference.Guaranty]: 'Garance bez ztrát',
  [ZzjInvestmentPreference.RatherGuaranty]: 'Ochrana',
  [ZzjInvestmentPreference.Balance]: 'Rovnováha',
  [ZzjInvestmentPreference.RatherYield]: 'Růst',
  [ZzjInvestmentPreference.Yield]: 'Spekulace',
};

export const INVESTMENT_QUESTION_PORTFOLIO_LABEL = 'Pro svou investici bych zvolil/a';
export const INVESTMENT_QUESTION_PORTFOLIO_ITEMS: ValueLabelPair[] = [
  {
    value: ZzjInvestmentPortfolio.A,
    label: 'Portfolio A',
  },
  {
    value: ZzjInvestmentPortfolio.B,
    label: 'Portfolio B',
  },
  {
    value: ZzjInvestmentPortfolio.C,
    label: 'Portfolio C',
  },
  {
    value: ZzjInvestmentPortfolio.D,
    label: 'Portfolio D',
  },
];
export const INVESTMENT_QUESTION_LOSS_VALUE_LABEL =
  'Ztráta částky, kterou chci investovat (jednorázově nebo v roční výši), by pro mne znamenala';
export const INVESTMENT_QUESTION_LOSS_VALUE_ITEMS: ValueLabelPair[] = [
  {
    value: ZzjInvestmentLossValue.AllSavings,
    label: 'Ztrátu všech mých úspor',
  },
  {
    value: ZzjInvestmentLossValue.FutureThreat,
    label: 'Ohrožení mých plánů do budoucna',
  },
  {
    value: ZzjInvestmentLossValue.AcceptableDamage,
    label: 'Akceptovatelná ztráta, kterou pokryji z jiných zdrojů ale pocítím finanční újmu',
  },
  {
    value: ZzjInvestmentLossValue.NoBigDeal,
    label:
      'Nic hrozného, mám dostatek jiných finančních prostředků a nepocítím žádnou finanční újmu',
  },
];
