import {AssetType, BaseAsset} from '@shared/analysis/models/asset';

export interface FinancialOutlookAsset extends BaseAsset {
  type: AssetType.FinancialOutlook;
  /** Výhled finanční situace */
  outlook: FinancialOutlookType;
}

export enum FinancialOutlookType {
  /** Pozitivní */
  Positive = 'positive',
  /** Stabilní */
  Stable = 'stable',
  /** Negativní */
  Negative = 'negative',
}

export interface ChildrenTaxAdvantageAsset extends BaseAsset {
  type: AssetType.ChildrenTaxAdvantage;
  persons: PersonTaxAdvantage[];
}

export interface PersonTaxAdvantage {
  familyMemberUuid: string;
  /** Na které děti? */
  childrenUuids: string[];
}

export type IncomeAttributesAsset = FinancialOutlookAsset | ChildrenTaxAdvantageAsset;
