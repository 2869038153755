import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {RelatedAssetHeaderComponent} from '@shared/analysis/asset-types/common/related-asset-header/related-asset-header.component';
import {RiskListInputComponent} from '@shared/analysis/asset-types/common/risk-list-input/risk-list-input.component';
import {
  ContractPeriod,
  propertyContractPeriods,
  PropertyProvisionAsset,
  PropertyRequirementsAsset,
  RequirementType,
} from '@shared/analysis/models/objectives';
import {ButtonModule, FormModule, HelpModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';

@Component({
  standalone: true,
  selector: 'kpt-property-requirements-common',
  templateUrl: './property-requirements-common.component.html',
  styleUrls: ['./property-requirements-common.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    ButtonModule,
    SwitchModule,
    FlexboxModule,
    RiskListInputComponent,
    RelatedAssetHeaderComponent,
    HelpModule,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PropertyRequirementsCommonComponent {
  @Input() asset: PropertyProvisionAsset | PropertyRequirementsAsset;
  @Input() disabled: boolean;
  @Input() showRelatedAssetHeader: boolean;

  RequirementType = RequirementType;
  ContractPeriod = ContractPeriod;
  propertyContractPeriods = propertyContractPeriods;

  constructor(private assetForm: AssetFormComponent<any>) {}

  changeRequirementType(requirementType: RequirementType, e: MouseEvent) {
    e.preventDefault();
    if (this.disabled) return;

    if (this.asset.requirementType === requirementType) {
      this.asset.requirementType = null;
    } else {
      this.asset.requirementType = requirementType;
    }

    this.assetForm.emitChange(this.asset);
  }
}
