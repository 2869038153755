<div *ngIf="agreementType === 'investment'" class="agreement">
  Prohlašuji, že jsem obsahu investičního dotazníku porozuměl/a, že mnou uvedené údaje jsou
  pravdivé, úplné a prosté zkreslení. Pro případ, že požaduji investiční nástroje, které dle
  vyhodnocení investičního dotazníku pro mne nejsou přiměřené, beru na vědomí upozornění, že takový
  přístup neodpovídá mým odborným znalostem nebo zkušenostem potřebným pro pochopení souvisejících
  rizik. Pro případ, že požaduji investiční strategii dynamičtější, než odpovídá profilu dle
  dotazníku, beru na vědomí upozornění, že se tím zvyšuje riziko nedosažení investičních cílů.
  <br /><br />
  Rizikový profil klienta může v kombinaci s odbornými znalostmi a zkušenostmi ovlivnit výslednou
  investiční strategii. Např. klientovi se základními znalostmi a spekulativním rizikovým profilem
  lze doporučit ke sjednání pouze produkty s konzervativní strategií a naopak klientovi s
  pokročilými znalostmi a nízkou tolerancí k riziku lze doporučit ke sjednání produkty s vyváženou
  strategií.
</div>

<div *ngIf="agreementType === 'life-insurance'" class="agreement">
  <h3>Doporučení poradce</h3>
  Poradce poskytuje klientovi radu v souladu s § 78 zák. č. 170/2018 Sb., o distribuci pojištění a
  zajištění, týkající se vhodnosti uzavření pojistné smlouvy, popř. ukončení/změny stávající
  pojistné smlouvy. Poradce prohlašuje, že tato rada je výsledkem výběru z pojistných produktů,
  které může distribuovat a dále z posouzení informací na straně klienta, zejména testu vhodnosti a
  přiměřenosti, analýzy jeho požadavků, cílů a potřeb, rizik, kterým může být klient vystaven po
  dobu trvání pojištění, jeho rodinné a finanční situace a stávajících právních vztahů klienta
  týkajících se dalších produktů finančního trhu. Poskytnutá rada je pro klienta vhodná a je
  přiměřená jeho znalostem a zkušenostem, investičnímu profilu, jeho toleranci k riziku a schopnosti
  nést ztráty.
  <br /><br />
  Rizikový profil klienta může v kombinaci s odbornými znalostmi a zkušenostmi ovlivnit výslednou
  investiční strategii. Např. klientovi se základními znalostmi a vysokou tolerancí k riziku lze
  doporučit ke sjednání pouze produkty s konzervativní strategií a naopak klientovi s pokročilými
  znalostmi a nízkou tolerancí k riziku lze doporučit ke sjednání produkty s konzervativní až
  vyváženou strategií.
  <br /><br />
  Poradce klientovi doporučuje, aby ho kontaktoval v případě změny jeho životní situace související
  s potřebou změny smlouvy. V rámci kvalitní péče o klienta bude poradce s klientem v průběžném
  kontaktu.
</div>

<div *ngIf="agreementType === 'pension'" class="agreement">
  Prohlašuji, že jsem obsahu investičního dotazníku porozuměl/a, že mnou uvedené údaje jsou
  pravdivé, úplné a prosté zkreslení. Pro případ, že požaduji investiční nástroje, které dle
  vyhodnocení investičního dotazníku pro mne nejsou přiměřené, beru na vědomí upozornění, že takový
  přístup neodpovídá mým odborným znalostem nebo zkušenostem potřebným pro pochopení souvisejících
  rizik. Pro případ, že požaduji investiční strategii dynamičtější, než odpovídá profilu dle
  dotazníku, beru na vědomí upozornění, že se tím zvyšuje riziko nedosažení investičních cílů.
  <br /><br />
  Rizikový profil klienta může v kombinaci s odbornými znalostmi a zkušenostmi ovlivnit výslednou
  investiční strategii. Např. klientovi se základními znalostmi a dynamickým rizikovým profilem lze
  doporučit ke sjednání pouze produkty s konzervativní strategií a naopak klientovi s pokročilými
  znalostmi a nízkou tolerancí k riziku lze doporučit ke sjednání produkty s vyváženou strategií.
</div>
