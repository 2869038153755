import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PhaseComponent} from './phase.component';

@NgModule({
  declarations: [PhaseComponent],
  imports: [CommonModule],
  exports: [PhaseComponent],
})
export class PhaseModule {}
