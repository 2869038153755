import {
  AfterContentChecked,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
} from '@angular/core';
import {BorderGroupItemDirective} from '../border-group-item.directive';

// See $spacers and .container-spacing-Y-X CSS classes
// prettier-ignore
type Spacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 |
  '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
export type Orientaion = 'row' | 'col';

@Component({
  selector: 'kpt-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent implements AfterContentChecked {
  @Input() spacing: Spacing = 3;
  @Input() horizontalSpacing: Spacing | undefined;
  @Input() verticalSpacing: Spacing | undefined;
  @Input() wrap = false;
  @Input() alignItems: 'start' | 'center' | 'baseline' | 'end' | 'stretch' = 'center';
  @Input() justifyContent: 'flex-start' | 'center' | 'space-between' | 'space-around' | 'flex-end' =
    'center';
  @HostBinding('style.overflow') @Input() overflow: 'unset' | 'hidden' | 'auto' = 'unset';
  @Input() reversed = false;

  get verticalGap() {
    return this.verticalSpacing || this.spacing || 0;
  }

  get horizontalGap() {
    return this.horizontalSpacing || this.spacing || 0;
  }

  get mainAxeGap() {
    return this.isColumn ? this.verticalGap : this.horizontalGap;
  }

  get isWrapping() {
    return this.wrap || (this.wrap as any) === '';
  }

  get isColumn() {
    return false;
  }

  get orientation(): Orientaion {
    return this.isColumn ? 'col' : 'row';
  }

  get isReversed() {
    return this.reversed || (this.reversed as any) === '';
  }

  get flexDirection() {
    return this.isColumn ? 'column' : 'row' + this.isReversed ? '-reverse' : '';
  }

  get spacingClass() {
    return `container-spacing-${this.verticalGap}-${this.horizontalGap} container-${this.orientation}`;
  }

  @ContentChildren(BorderGroupItemDirective) borderGroupItems:
    | QueryList<BorderGroupItemDirective>
    | undefined;

  ngAfterContentChecked(): void {
    if (!this.borderGroupItems) return;
    const items = this.borderGroupItems.toArray();

    items.forEach(item => (item.container = this.orientation));

    if (!this.mainAxeGap && !this.wrap && items.length > 1) {
      // @ts-ignore
      items.shift().placing = 'first';
      // @ts-ignore
      items.pop().placing = 'last';
      items.forEach(item => (item.placing = 'middle'));
    }
  }
}
