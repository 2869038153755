import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

type AgreementType = 'investment' | 'life-insurance' | 'pension';

@Component({
  selector: 'kpt-summary-agreement',
  templateUrl: './summary-agreement.component.html',
  styleUrls: ['./summary-agreement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryAgreementComponent {
  @Input()
  agreementType: AgreementType = 'investment';

  constructor() {}
}
