import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  AddExistingFamilyMemberFinish,
  AddExistingFamilyMemberStart,
  CreateFamilyMemberFinish,
  CreateFamilyMemberStart,
  DeleteFamilyMemberFinish,
  DeleteFamilyMemberStart,
  FamilyMemberActionTypes,
  LoadFamilyMembersFinish,
  LoadFamilyMembersStart,
  UpdateFamilyMemberFinish,
  UpdateFamilyMemberStart,
} from '../actions/family-members.actions';
import {State} from '../index';
import {getFamilyMembersState} from '../selectors/family-member.selectors';

// Temporary solution, will be fixed in Portal
function fixChildFamilyHead(familyMembers: FamilyMember[]): FamilyMember[] {
  return familyMembers.map(member => {
    if (member.familyHead && member.type === 'CHILD') member.type = 'ADULT';
    return member;
  });
}

@Injectable()
export class FamilyMembersEffects {
  updateInput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FamilyMemberActionTypes.LoadMembersStart),
      map((action: LoadFamilyMembersStart) => action.payload.familyUuid),
      withLatestFrom(this.store.pipe(select(getFamilyMembersState))),
      switchMap(([familyUuid, familyState]) => {
        if (familyState.familyUuid === familyUuid) {
          of(new LoadFamilyMembersFinish({...familyState}));
        }

        return this.integrationsService.getPortalFamilyMembers({family_uuid: familyUuid}).pipe(
          map(familyMembers => fixChildFamilyHead(familyMembers)),
          map(
            familyMembers => new LoadFamilyMembersFinish({familyMembers, familyUuid, error: null}),
          ),
          catchError((error: HttpErrorResponse) =>
            of(new LoadFamilyMembersFinish({familyMembers: [], familyUuid: undefined, error})),
          ),
        );
      }),
    ),
  );

  createFamilyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FamilyMemberActionTypes.CreateMemberStart),
      map((action: CreateFamilyMemberStart) => action.payload),
      switchMap(payload =>
        this.integrationsService
          .createPortalFamilyMember({
            family_uuid: payload.family_uuid,
            data: payload.data,
          })
          .pipe(
            map(newMember => new CreateFamilyMemberFinish({newMember, error: null})),
            catchError((error: HttpErrorResponse) =>
              of(new CreateFamilyMemberFinish({newMember: null, error})),
            ),
          ),
      ),
    ),
  );

  updateFamilyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FamilyMemberActionTypes.UpdateMemberStart),
      map((action: UpdateFamilyMemberStart) => action.payload),
      switchMap(payload =>
        this.integrationsService
          .updateFamilyMember({
            sugar_uuid: payload.sugar_uuid,
            family_uuid: payload.family_uuid,
            data: payload.data,
          })
          .pipe(
            map(newMember => new UpdateFamilyMemberFinish({newMember, error: null})),
            catchError((error: HttpErrorResponse) =>
              of(new UpdateFamilyMemberFinish({newMember: null, error})),
            ),
          ),
      ),
    ),
  );

  deleteFamilyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FamilyMemberActionTypes.DeleteMemberStart),
      map((action: DeleteFamilyMemberStart) => action.payload),
      switchMap(payload =>
        this.integrationsService
          .deleteFamilyMember({
            family_uuid: payload.family_uuid,
            sugar_uuid: payload.sugar_uuid,
          })
          .pipe(
            map(
              () =>
                new DeleteFamilyMemberFinish({
                  sugar_uuid: payload.sugar_uuid,
                  error: null,
                }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(new DeleteFamilyMemberFinish({sugar_uuid: null, error})),
            ),
          ),
      ),
    ),
  );

  addExistingFamilyMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FamilyMemberActionTypes.AddExistingFamilyMemberStart),
      map((action: AddExistingFamilyMemberStart) => action.payload),
      switchMap(payload =>
        this.integrationsService
          .addClientToFamily({
            sugar_uuid: payload.sugar_uuid,
            family_uuid: payload.family_uuid,
            type: payload.type,
          })
          .pipe(
            map(
              familyMember =>
                new AddExistingFamilyMemberFinish({
                  family_uuid: payload.family_uuid,
                  familyMember,
                  error: null,
                }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(new AddExistingFamilyMemberFinish({family_uuid: null, familyMember: null, error})),
            ),
          ),
      ),
    ),
  );

  constructor(
    private store: Store<State>,
    private integrationsService: IntegrationsService,
    private actions$: Actions,
  ) {}
}
