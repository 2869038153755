import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {IOption} from '@shared/ui/forms/models/option.model';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {CommonFieldComponent} from '../common-field/common-field.component';

@Component({
  selector: 'kpt-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    ...getProvidersFor(ToggleComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => ToggleComponent)},
  ],
})
export class ToggleComponent extends CommonFieldComponent {
  @Input() set value(v: any) {
    this._value = v;
  }

  @Input() set options(list: (IOption | string)[]) {
    if (!list) return;
    if (!list.length || typeof list[0] !== 'string') {
      this.optionsList = [...(list as IOption[])];
      return;
    }
    this.optionsList = list.map(option => ({key: option as string, label: option as string}));
  }

  @Output() selected = new EventEmitter<any>();

  _value: any;
  optionsList: IOption[] = [];

  changeAction(key: any) {
    this.onChange(key);
    this.selected.emit(key);
    this._value = key;
  }

  labelClick(key: any) {
    this.changeAction(key);
  }
}
