import {AssetType, AdvisorProposalState} from 'src/shared/analysis/models/asset';
import {CommonFinancialProductAsset} from 'src/shared/analysis/models/financial-products';
import {ObjectiveType} from 'src/shared/analysis/objectives.helpers';

export interface ContractCategory {
  objectiveType: ObjectiveType;
  name: string;
  rows: ContractRow[];
}

export interface ContractRow {
  currentAsset: CommonFinancialProductAsset;
  proposedAsset: CommonFinancialProductAsset;
  name: string;
  assetType: AssetType;
  advisorProposalState: AdvisorProposalState;
  currentPayment: number;
  proposedPayment: number;
}

export interface ContractPair {
  currentAsset: CommonFinancialProductAsset;
  proposedAsset: CommonFinancialProductAsset;
}
