import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalWrapperComponent} from './modal-wrapper/modal-wrapper.component';
import {ModalComponent} from './modal.component';

@NgModule({
  declarations: [ModalComponent, ModalWrapperComponent],
  exports: [ModalComponent, ModalWrapperComponent],
  imports: [CommonModule],
})
export class ModalModule {}
