import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyBoldness, formatCZK} from '../utils';

@Pipe({
  name: 'currencyCZK',
})
export class CurrencyCZKPipe implements PipeTransform {
  transform(value: any, boldness?: CurrencyBoldness): any {
    return formatCZK(value, boldness);
  }
}
