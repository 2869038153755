import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormModule} from '@shared/ui/forms';
import {SliderModule} from '@shared/ui/slider/slider.module';
import {SliderWithInputComponent} from '@shared/ui/slider-with-input/slider-with-input.component';

@NgModule({
  declarations: [SliderWithInputComponent],
  imports: [CommonModule, FormModule, SliderModule, ReactiveFormsModule],
  exports: [SliderWithInputComponent],
})
export class SliderWithInputModule {}
