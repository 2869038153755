// eslint-disable-next-line max-classes-per-file
import {Directive, Input} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

// For template-driven forms, angular's built-in validator directives like `min` and `max` do not work
// with `kpt-input`. They work only if you apply `ngModel` directly to the native `input` element.
// To work around this issue, we register our own validator directives that will work with `kpt-input`.

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'kpt-input[type=number][max][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputMaxValidatorDirective,
      multi: true,
    },
  ],
})
export class InputMaxValidatorDirective implements Validator {
  @Input() max: number;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    const validator = Validators.max(this.max);
    return validator(control);
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'kpt-input[type=number][min][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputMinValidatorDirective,
      multi: true,
    },
  ],
})
export class InputMinValidatorDirective implements Validator {
  @Input() min: number;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    const validator = Validators.min(this.min);
    return validator(control);
  }
}
