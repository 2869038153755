import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MaskOptions, numberMask, percentMask, phoneMask} from '@lib/utils/imask.utils';
import {isNilOrEmpty} from '@shared/lib';
import {getProvidersFor} from '@shared/utils/get-providers-for.fnc';
import {IMaskDirective} from 'angular-imask';
import {Observable} from 'rxjs';
import {CommonFieldComponent} from '../common-field/common-field.component';

export type InputComponentType = 'password' | 'text' | 'number' | 'tel' | 'textarea' | 'percent';

const parseMaskedNumber = (value: any): number => {
  const parsedNumber = parseFloat(String(value)?.replace(/ /g, '').replace(/,/, '.'));
  return isNaN(parsedNumber) ? null : parsedNumber;
};

@Component({
  selector: 'kpt-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    ...getProvidersFor(InputComponent),
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    {provide: CommonFieldComponent, useExisting: forwardRef(() => InputComponent)},
  ],
})
export class InputComponent extends CommonFieldComponent implements AfterViewInit, OnChanges {
  @ViewChild('field') field: ElementRef;
  @ViewChild(IMaskDirective) iMaskDirective: IMaskDirective<any>;

  @Input() autofocus: boolean = null;
  @Input() appendText: string;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() autocomplete: boolean;
  @Input() autocompleteObservable: Observable<string[]>;
  @Input() name: string;
  @Input() maxLength: number;
  @Input() smartformEnabled = false;
  @Input() smartformInstance: string;
  @Input() smartformFieldType: string;
  @Input()
  get type() {
    return this._type;
  }
  set type(v: InputComponentType) {
    this._type = v.toLowerCase() as InputComponentType;
  }
  get mask() {
    return this._mask !== undefined ? this._mask : this.defaultMask;
  }
  @Input()
  set mask(mask: MaskOptions) {
    this._mask = mask;
  }

  // number type
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() step: number = null;

  // textarea type
  @Input() rows = 3;
  @Input() spellCheck = true;

  @Output() keypress = new EventEmitter<KeyboardEvent>();
  @Output() keydown = new EventEmitter<KeyboardEvent>();
  @Output() keyup = new EventEmitter<KeyboardEvent>();

  get inputType(): InputComponentType {
    if (this.mask) return 'text';
    return this.type;
  }

  private _type: InputComponentType = 'text';
  private defaultMask: MaskOptions;
  private _mask: MaskOptions;
  private originalValue: any;
  private hasFocus = false;

  labelClick() {
    if (!this.field.nativeElement) return;
    this.field.nativeElement.focus();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = value => {
      if (this.type === 'number' || this.type === 'percent') {
        fn(value === '' ? null : parseMaskedNumber(value));
        this._value = parseMaskedNumber(value);
        this.numberValueChange(String(value));
      } else {
        fn(value);
        this._value = value;
      }
    };

    if (this.iMaskDirective) {
      this.iMaskDirective.registerOnChange(this.onChange);
    }
  }

  registerOnTouched(fn: (_?: any) => void): void {
    if (this.iMaskDirective) this.iMaskDirective.registerOnTouched(fn);
    else super.registerOnTouched(fn);
  }

  ngAfterViewInit() {
    if (this.iMaskDirective) {
      this.registerOnChange(this.onChange);
      this.registerOnTouched(this.onTouched);
    }

    this.writeValue(this._value);
    // if SSR is ever in plan, use isPlatform fn
    if (this.autofocus) this.field.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type || changes.min || changes.max) this.defaultMask = this.createDefaultMask();
  }

  writeValue(value: any) {
    // IE 9 normalizing
    if (this.type === 'number' || this.type === 'percent') this._value = value == null ? '' : value;
    else if (typeof value === 'undefined') this._value = null;
    else this._value = value;
    if (this.field) {
      if (this.iMaskDirective) this.iMaskDirective.writeValue(this._value);
      else this.renderer.setProperty(this.field.nativeElement, 'value', this._value);
    }
  }

  numberValueChange(value: string) {
    this.valueChange.emit(parseMaskedNumber(value));
  }

  getSmartformClasses(): string {
    return this.smartformEnabled
      ? 'smartform-instance-' + this.smartformInstance + ' smartform-' + this.smartformFieldType
      : '';
  }

  onInputChange(value: any) {
    if (this.type === 'number' || this.type === 'percent') {
      const signsOfDecimal = (value as string).includes(',') || (value as string).includes('.');
      if (
        !signsOfDecimal &&
        value !== '0' &&
        parseMaskedNumber(this._value) === 0 &&
        !isNaN(parseMaskedNumber(value))
      ) {
        this.onChange(parseMaskedNumber(value));
        this.numberValueChange(value);
        if (!this.hasFocus && this.iMaskDirective) {
          this.iMaskDirective.writeValue(parseMaskedNumber(value));
        }
      }
    }
    if (this.type === 'percent' && this.iMaskDirective) return;
    switch (this.type) {
      case 'number':
        this.onChange(value);
        this.numberValueChange(value);
        break;
      default:
        this.onChange(value);
        this.valueChange.emit(value);
        break;
    }
  }

  onInputBlur(value: any) {
    if (
      (this.type === 'number' || this.type === 'percent') &&
      this.originalValue === 0 &&
      isNilOrEmpty(value)
    ) {
      this.onChange(0);
      this.numberValueChange('0');
      if (this.iMaskDirective) this.iMaskDirective.writeValue(0);
    }
    this.onTouched(value);
    this.hasFocus = false;
    this.blur.emit(value);
  }

  onInputFocus(event: any) {
    if (
      (this.type === 'number' || this.type === 'percent') &&
      parseMaskedNumber(event.target.value) === 0
    ) {
      this.originalValue = 0;
      this.onChange(null);
      this.numberValueChange(null);
      if (this.iMaskDirective) this.iMaskDirective.writeValue(null);
    }
    this.hasFocus = true;
    this.focus.emit(event);
  }

  private createDefaultMask() {
    switch (this.type) {
      case 'number':
        return numberMask(false, null);
      case 'tel':
        return phoneMask;
      case 'percent':
        return percentMask;
      default:
        return null;
    }
  }
}
