import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {AccordionContainerComponent} from './accordion-container.component';

// TODO if used in future of the project, refactor to better code quality

/**
 * ### Accordion heading
 * Instead of using `heading` attribute on the `accordion-group`, you can use
 * an `accordion-heading` attribute on `any` element inside of a group that
 * will be used as group's header template.
 */
@Component({
  selector: 'kpt-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.scss'],
})
export class AccordionGroupComponent implements OnInit, OnDestroy {
  /** Clickable text in accordion's group header, check `accordion heading` below for using html in header */
  @Input() heading: string;
  @Input() headingTemplate?: TemplateRef<any>;
  /** Provides an ability to use Bootstrap's contextual panel classes
   * (`panel-primary`, `panel-success`, `panel-info`, etc...).
   * List of all available classes [available here]
   * (https://getbootstrap.com/docs/3.3/components/#panels-alternatives)
   */
  @Input() panelClass: string;
  @Input() stripeClass: string;
  /** if <code>true</code> — disables accordion group */
  @Input() isDisabled: boolean;
  @Input() preventDefault: boolean;
  @Input() iconOpened = 'icon--minus';
  @Input() iconClosed = 'icon--plus';
  @Input() hideIcon = false;
  /** Emits when the opened state changes */
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter();

  // Questionable, maybe .panel-open should be on child div.panel element?
  /** Is accordion group open or closed. This property supports two-way binding */
  @HostBinding('class.panel-open')
  @Input()
  get isOpen(): boolean {
    return this.open;
  }

  set isOpen(value: boolean) {
    if (value !== this.isOpen) {
      if (value) {
        this.accordion.closeOtherPanels(this);
      }
      this.open = value;
      Promise.resolve(null)
        .then(() => {
          this.isOpenChange.emit(value);
        })
        .catch((error: Error) => {
          /* eslint-disable no-console */
          console.log(error);
        });
    }
  }

  @Input() hasPersistedContent = false;

  protected open = false;
  protected accordion: AccordionContainerComponent;

  constructor(
    @Inject(AccordionContainerComponent) accordion: AccordionContainerComponent,
    public elementRef: ElementRef,
  ) {
    this.accordion = accordion;
  }

  ngOnInit(): void {
    this.panelClass = this.panelClass || 'panel-default';
    this.accordion.addGroup(this);
  }

  ngOnDestroy(): void {
    this.accordion.removeGroup(this);
  }

  toggleOpen(): void {
    if (this.isDisabled) return;
    if (this.preventDefault) return;
    if (this.hideIcon) return;
    this.isOpen = !this.isOpen;
  }
}
