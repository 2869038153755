<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">Vybrat produkt</h3>
    </div>
  </div>
</div>
<form [formGroup]="form" class="modal-body">
  <kpt-select
    label="Oblast pojištění"
    [options]="insuranceTypeOptions"
    [formControl]="form.get('insuranceType')"
  ></kpt-select>
  <kpt-select
    label="Partner"
    [options]="partnerOptions"
    [formControl]="form.get('partnerId')"
  ></kpt-select>
  <kpt-select
    label="Produkt"
    [options]="productOptions"
    [formControl]="form.get('productId')"
  ></kpt-select>
  <kpt-select
    label="Pojistník"
    [options]="stakeholderOptions$ | async"
    [formControl]="form.get('stakeholderId')"
  ></kpt-select>

  <ng-container *ngIf="personsWithSameProduct">
    <div class="mb-3">Tento produkt má doporučený již {{ personsWithSameProduct }}</div>

    <kpt-select
      label="Do jaké smlouvy přidat produkt"
      [options]="contractOptions"
      [formControl]="form.get('contractId')"
    ></kpt-select>
  </ng-container>
  <div class="d-flex justify-content-center">
    <kpt-button type="primary" (action)="submit()">Vybrat</kpt-button>
  </div>
</form>
