<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">
        {{ title }}
      </h3>
    </div>
  </div>
</div>
<div class="modal-body">
  <form *ngIf="formFiles" [formGroup]="form">
    <div *ngFor="let fileForm of formFiles.controls" class="row mb-3" [formGroup]="fileForm">
      <div [className]="showDocumentType ? 'col-6' : 'col'">
        <kpt-input label="Název souboru" [formControl]="fileForm.get('name')"> </kpt-input>
      </div>

      <div *ngIf="showDocumentType" class="col-6">
        <kpt-select
          label="Typ souboru"
          [options]="documentTypeOptions"
          formControlName="documentType"
        >
        </kpt-select>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <kpt-button
        [isLoading]="uploadInProgress$ | async"
        [isDisabled]="uploadInProgress$ | async"
        type="primary"
        (action)="submit()"
        >Uložit</kpt-button
      >
    </div>
  </form>
</div>
