import {frequencyRate} from '@shared/analysis/helpers';
import {Frequency} from '@shared/analysis/models/unit';
import * as moment from 'moment';

export enum When {
  Beginning = 1,
  End = 0,
}

/**
 *
 * @param rate
 * @param numPeriods
 * @param periodicPayment periodic payment
 * @param presentValue present value
 * @param when 1 begin, 0 end
 */
export function futureValue(
  rate: number,
  numPeriods: number,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  periodicPayment: number,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  presentValue: number,
  when: When = When.End,
) {
  if (rate) {
    return (
      -presentValue * (1 + rate) ** numPeriods -
      ((periodicPayment * (1 + rate * when)) / rate) * ((1 + rate) ** numPeriods - 1)
    );
  } else {
    return -presentValue - periodicPayment * numPeriods;
  }
}

/**
 *
 * @param rate
 * @param numPeriods
 * @param pv present value
 * @param fv future value
 * @param when 1 begin, 0 end
 */
export function periodicPayment(
  rate: number,
  numPeriods: number,
  pv: number,
  fv = 0,
  when = When.End,
) {
  const temp = (1 + rate) ** numPeriods;
  const fact = rate === 0 ? numPeriods : ((1 + rate * when) * (temp - 1)) / rate;
  return (fv + pv * temp) / fact;
}

/**
 *
 * @param rate
 * @param numPeriods
 * @param pmt periodic payment
 * @param fv future value
 * @param when 1 begin, 0 end
 */
export function presentValue(
  rate: number,
  numPeriods: number,
  pmt: number,
  fv = 0,
  when = When.End,
) {
  const temp = (1 + rate) ** numPeriods;
  const fact = rate === 0 ? numPeriods : ((1 + rate * when) * (temp - 1)) / rate;
  return -(fv + pmt * fact) / temp;
}

function threeLevelsReduction(
  firstLimit: number,
  secondLimit: number,
  thirdLimit: number,
  percentageInFirstLimit: number,
  percentageInSecondLimit: number,
  percentageInThirdLimit: number,
  base: number,
) {
  return base <= firstLimit
    ? Math.ceil(base * percentageInFirstLimit)
    : base <= secondLimit
    ? Math.ceil(firstLimit * percentageInFirstLimit + percentageInSecondLimit * (base - firstLimit))
    : base <= thirdLimit
    ? Math.ceil(
        firstLimit * percentageInFirstLimit +
          (secondLimit - firstLimit) * percentageInSecondLimit +
          percentageInThirdLimit * (base - secondLimit),
      )
    : Math.ceil(
        firstLimit * percentageInFirstLimit +
          (secondLimit - firstLimit) * percentageInSecondLimit +
          percentageInThirdLimit * (thirdLimit - secondLimit),
      );
}

export function maternityMonthly(monthlyAssesmentBasis: number) {
  const dailyAssesmentBasis = (monthlyAssesmentBasis * 12) / 365;
  const reducedDailyAssesmentBasis = -Math.floor(
    -threeLevelsReduction(1162, 1742, 3484, 1.0, 0.6, 0.3, dailyAssesmentBasis),
  );
  const dailyMaternity = -Math.floor(-reducedDailyAssesmentBasis * 0.7);
  return dailyMaternity * 30;
}

export function pensionMonthly(
  montlyAssesmentBasis: number,
  birthDate: string,
  pensionDate: string,
) {
  const reducedAssesmentBasis = threeLevelsReduction(
    15328,
    139340,
    139340,
    1.0,
    0.26,
    0,
    montlyAssesmentBasis,
  );
  let percentage = 40 * 0.015;
  // you get 1.5 % for every year you have worked, for simplicity we expect 40 years
  const pensionYear = Number(birthDate.split('-')[0]) + 65;
  const supposedPensionDate = pensionYear + birthDate.substring(4, 10);
  const datePensionReal = moment(pensionDate);
  const datePensionSupposed = moment(supposedPensionDate);
  const daysDifference = datePensionReal.diff(datePensionSupposed) / (1000 * 24 * 60 * 60);
  if (daysDifference > 0) {
    const quartersDifference = Math.floor(daysDifference / 90);
    percentage = percentage + quartersDifference * 0.015;
    // you get 1,5 % of the reduced assesment basis for every finished 90 days you work more then needed
  } else if (daysDifference < 0) {
    const quartersDifference = -Math.floor(daysDifference / 90);
    // you get substractions for every started 90 days you work less than needed
    if (quartersDifference <= 4) {
      percentage = percentage - 0.009 * quartersDifference;
      // you get 0.9 % of the reduced assesment basis LESS for every started 90 days in the first year
    } else if (quartersDifference <= 8) {
      percentage = percentage - (0.036 + 0.012 * (quartersDifference - 4));
      // you get 1.2 % of the reduced assesment basis LESS for every started 90 days in the second year
    } else {
      percentage = percentage - (0.036 + 0.048 + 0.015 * (quartersDifference - 8));
      // you get 1.5 % of the reduced assesment basis LESS for every started 90 days in the third and more year
    }
  }
  const totalPension = 3490 + percentage * reducedAssesmentBasis;
  return -Math.floor(-totalPension);
}

export function buildingSavings(
  rate: number,
  numPeriods: number,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  periodicPayment: number,
  oneTimePayment = 0,
  initialMonth = 0,
  includeStateContribution = false,
  frequency: Frequency,
) {
  const frequencyPerYear = 1 / frequencyRate[frequency];
  let paymentsPerYear =
    numPeriods < frequencyPerYear
      ? numPeriods
      : Math.floor((12 - initialMonth) / (frequencyRate[frequency] * 12));
  const paymentsInLastYear = (numPeriods - paymentsPerYear) % frequencyPerYear;
  const savingsLength =
    (paymentsPerYear > 0 ? 1 : 0) + // add 1 for payments in first year
    (numPeriods - paymentsPerYear - paymentsInLastYear) * frequencyRate[frequency] + // get count for rest
    (paymentsInLastYear > 0 ? 1 : 0); // add 1 for payments in last year
  let savedMoney = oneTimePayment;
  let stateContribution = 0;
  let interest = 0;
  let stateContributionBase = oneTimePayment;

  for (let y = 0; y < Math.ceil(savingsLength); y++) {
    for (let i = 0; i < paymentsPerYear; i++) {
      interest += savedMoney * rate;
      savedMoney += periodicPayment;
      stateContributionBase += periodicPayment;
      if (includeStateContribution && y >= 1 && i === Math.floor(frequencyPerYear / 4)) {
        stateContributionBase -= stateContribution * 10;
        savedMoney += stateContribution;
      }
    }
    savedMoney += interest;
    stateContributionBase += interest;
    stateContribution = Math.min(stateContributionBase * 0.1, 2000);
    interest = 0;
    paymentsPerYear =
      y < Math.ceil(savingsLength) - 2 || paymentsInLastYear === 0
        ? frequencyPerYear
        : paymentsInLastYear;
  }
  return savedMoney;
}

export function pensionInsurance(
  rate: number,
  numPeriods: number,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  periodicPayment: number,
  oneTimePayment = 0,
  stateContribution = 0,
) {
  const oneTimePaymentWithInterest = oneTimePayment * (1 + rate) ** (numPeriods / 12);
  let annualPayment = 0;
  for (let i = 1; i <= 12; i++) {
    annualPayment += periodicPayment * (1 + rate) ** (i / 12);
  }

  let annualStateContribution = 0;
  for (let i = 0; i < 4; i++) {
    annualStateContribution += stateContribution * (1 + rate) ** (i / 4);
  }
  annualStateContribution = annualStateContribution * 3;
  let savedMoney = 0;
  for (let i = 0; i < numPeriods / 12; i++) {
    savedMoney = (1 + rate) * savedMoney + annualPayment + annualStateContribution;
  }

  return oneTimePaymentWithInterest + savedMoney;
}
