import {DocumentTypeFileEnum} from '@generated/model';
import {TypedOption} from '@shared/ui';
import {FileItem} from 'ng2-file-upload';

export interface StoreFile<DocumentType extends string = string> {
  dmsUuid: string;
  name: string;
  mimeType: string;
  documentType: DocumentType;
  created: string;
}

export interface StoreFileUpdate {
  dmsUuid: string;
  name: string;
}

export interface UIFile {
  name: string;
  created: string;
  mimeType?: string;
  item?: FileItem;
  dmsUuid?: string;
  documentType?: string;
}

export const documentTypeOptions: TypedOption<DocumentTypeFileEnum>[] = [
  {key: 'MŽP', label: 'Modelace – nabídka ŽP'},
  {key: 'NABMAJ', label: 'Nabídka pojištění majetku'},
  {key: 'NABPP', label: 'Nabídka pojištění podnikatelů'},
  {key: 'NABCP', label: 'Nabídka cestovního pojištění'},
  {key: 'NABSR', label: 'Nabídka ze srovnávače pojištění vozidel'},
  {key: 'DOTPP', label: 'Pojištění podnikatelů – dotazník obchodního partnera'},
  {key: 'NAB', label: 'Nabídka'},
  {key: 'PF', label: 'Poptávka flotily'},
  {key: 'OOP', label: 'Opis OP'},
  {key: 'PMK', label: 'Plná moc'},
  {key: 'RS', label: 'Rozhodnutí soudu'},
  {key: 'OP', label: 'Ostatní přílohy'},
  {key: 'S', label: 'Smlouvy'},
  {key: 'KOP', label: 'Kopie občanského průkazu'},
  {key: 'KCU', label: 'Kopie čísla účtu'},
];
