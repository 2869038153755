import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormModule} from '@shared/ui/forms/form.module';
import {PhoneNumberComponent} from '@shared/ui/forms/phone-number/phone-number.component';

@NgModule({
  declarations: [PhoneNumberComponent],
  imports: [CommonModule, FormModule, ReactiveFormsModule],
  exports: [PhoneNumberComponent],
})
export class PhoneNumberModule {}
