import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EventSituationButtonModule} from '@shared/ui/event-situation/button/event-situation-button.module';
import {EventSituationComponent} from './event-situation.component';
import {EventSituationDeprecatedComponent} from './event-situation.deprecated.component';

@NgModule({
  declarations: [EventSituationComponent, EventSituationDeprecatedComponent],
  imports: [CommonModule, EventSituationButtonModule, RouterModule],
  exports: [EventSituationComponent, EventSituationDeprecatedComponent],
})
export class EventSituationModule {}
