import {SexFamilyMemberEnum} from '@generated/defs/FamilyMember';
import {convertToMonthly} from '@shared/analysis/asset.utils';
import {calculateEquity} from '@shared/analysis/evaluation';
import {Asset, AssetType, FamilyMemberAsset, ValueAsset} from '@shared/analysis/models/asset';
import {MortgageAsset} from '@shared/analysis/models/credit-products';
import {FinancialOutlookType} from '@shared/analysis/models/income-attributes';
import {
  adultExpensesRiskDefinitions,
  adultIncomeRiskDefinitions,
  childMinimalRiskDefinitions,
  childStandardRiskDefinitions,
  getRiskId,
  LifeInsurancePersonData,
  LifeInsuranceRisk,
  MortgageRepaymentByRisk,
  otherSideProvision,
  PersonRisk,
  Provision,
  RiskDefinition,
  RiskInput,
} from '@shared/analysis/models/life-insurance-risks';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {creditProductInsuranceRisks} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.models';
import {cloneDeep, get, sum, uniq} from 'lodash';
import * as moment from 'moment';

export interface PersonalInsuranceSelectionVM {
  person: LifeInsurancePersonVM;
  leftProvision: ProvisionVM;
  rightProvision: ProvisionVM;
}

export interface ProvisionVM {
  value: number;
  provision: Provision;
  risks: RiskVM[];
}

export interface RiskVM {
  data: LifeInsuranceRisk;
  riskDef: RiskDefinition;
  calculatedValue: number;
  calculatedHelp: string;
  calculatedDescription: string;
  warning?: string;
}

export interface LifeInsurancePersonVM {
  data: LifeInsurancePersonData;
  id: string;
  child: boolean;
  name: string;
  lastName?: string;
  age: number;
  sex: SexFamilyMemberEnum;
  income?: number;
  incomeContractor?: number;
  passiveIncome?: number;
  expenses?: number;
  savings?: number;
  debts?: number;
  order: number;
  childrenTaxAdvantage?: number;
  financialOutlook?: FinancialOutlookType;
  netMonthlyIncomeContractor?: number;
  monthlySickLeaveInsurancePaymentContractor?: number;
  other?: number;
  otherContractor?: number;
  debtsTogether: boolean;
  familyHead?: boolean;
}

export function getRisksVM(
  familyProvision: FamilyProvisionAsset,
  persons: LifeInsurancePersonVM[],
  otherAssets: Asset[],
): PersonalInsuranceSelectionVM[] {
  let vm = persons.map(person => {
    const leftRiskDefinitions = person.child
      ? childStandardRiskDefinitions
      : adultIncomeRiskDefinitions;
    const rightRiskDefinitions = person.child
      ? childMinimalRiskDefinitions
      : adultExpensesRiskDefinitions;

    const leftRisks = leftRiskDefinitions.map(riskDef =>
      getRiskVM(riskDef, person, persons, otherAssets),
    );
    const rightRisks = rightRiskDefinitions.map(riskDef =>
      getRiskVM(riskDef, person, persons, otherAssets),
    );

    // set risks according to risks set on the asset
    const allRisks = [...leftRisks, ...rightRisks];
    for (const assetRisk of familyProvision?.lifeInsuranceRisks ?? []) {
      const risk = allRisks.find(r => getRiskId(r.data) === getRiskId(assetRisk));
      if (risk) {
        risk.data = assetRisk;
      }
    }

    for (const risk of allRisks) {
      if (risk.data.active && risk.calculatedValue === 0) {
        risk.warning = 'Požadujete zajištění, ale riziko nehrozí. Upravte analýzu.';
      }
    }

    return {
      person,
      leftProvision: {
        value: person.income,
        provision: leftRisks[0].data.provision,
        risks: leftRisks,
      },
      rightProvision: {
        value: person.expenses,
        provision: rightRisks[0].data.provision,
        risks: rightRisks,
      },
    };
  });

  // make vm mutable to allow two-way binding with ngModel
  vm = cloneDeep(vm);

  return vm;
}

function getRiskVM(
  riskDef: RiskDefinition,
  person: LifeInsurancePersonVM,
  persons: LifeInsurancePersonVM[],
  assets: Asset[],
): RiskVM {
  return {
    data: {
      personId: person.id,
      key: riskDef.key,
      active: false,
      limit: null,
      age: null,
      provision: riskDef.provision,
    },
    riskDef,
    calculatedValue: calculateRisk(riskDef, riskDef.provision, person, persons, assets),
    calculatedHelp: calculateHelp(riskDef, riskDef.provision, person, persons, assets),
    calculatedDescription: calculateDescription(
      riskDef,
      riskDef.provision,
      person,
      persons,
      assets,
    ),
  };
}

function computeOtherIncome(person: LifeInsurancePersonVM, assets: Asset[]) {
  return person.data.useOtherIncome
    ? sum(
        assets
          .filter(
            asset =>
              (person.data.otherIncomeAssetUuids ?? []).includes(asset.assetUuid) &&
              (asset as FamilyMemberAsset).familyMemberUuid === person.id,
          )
          .map(asset => (asset as ValueAsset).value),
      )
    : 0;
}

function computeMortgageRepaymentAndRisks(
  person: LifeInsurancePersonVM,
  assets: Asset[],
  persons: LifeInsurancePersonVM[],
): MortgageRepaymentByRisk {
  if (!person.data.usePaymentProtectionInsurance) return {};

  const repaymentByRisk: MortgageRepaymentByRisk = {};
  const today = moment().toISOString();

  assets
    .filter(asset => asset.type === AssetType.Mortgage && asset.stakeholderUuid === person.id)
    .forEach((asset: MortgageAsset) => {
      const risks = asset.insuredRisksByDebtors.find(r => r.debtorUuid === person.id);

      const selectedRiskKeys = uniq(
        creditProductInsuranceRisks
          .filter(r => get(risks, r.key))
          .filter(risk => Boolean(risk.riskKey))
          .map(risk => risk.riskKey),
      );

      selectedRiskKeys
        .filter(riskKey => riskKey !== PersonRisk.DeathDebtRepayment)
        .forEach(riskKey => {
          repaymentByRisk[riskKey] =
            (repaymentByRisk[riskKey] ?? 0) + convertToMonthly(asset.value, asset.frequency);
        });
    });

  assets
    .filter(asset => asset.type === AssetType.Mortgage && asset.stakeholderUuid === person.id)
    .forEach((asset: MortgageAsset) => {
      const personIds = persons.map(p => p.id);
      const selectedRiskKeys = uniq(
        creditProductInsuranceRisks
          .filter(r =>
            asset.insuredRisksByDebtors.some(
              risks => get(risks, r.key) && personIds.includes(risks.debtorUuid),
            ),
          )
          .filter(risk => Boolean(risk.riskKey))
          .map(risk => risk.riskKey),
      );

      selectedRiskKeys
        .filter(riskKey => riskKey === PersonRisk.DeathDebtRepayment)
        .forEach(riskKey => {
          repaymentByRisk[riskKey] =
            (repaymentByRisk[riskKey] ?? 0) - calculateEquity(today, [asset]);
        });
    });

  return repaymentByRisk;
}

function getRiskInput(
  persons: LifeInsurancePersonVM[],
  person: LifeInsurancePersonVM,
  assets: Asset[],
  provision: Provision,
): RiskInput {
  const childrenAges = persons.filter(p => p.child).map(p => p.age);
  const otherIncome = computeOtherIncome(person, assets);
  const mortgageRepaymentByRisk = computeMortgageRepaymentAndRisks(person, assets, persons);

  return {
    provision,
    monthlyIncomeEmployee: person.income || 0,
    monthlyIncomeContractor: person.incomeContractor || 0,
    otherEmployee: person.other || 0,
    otherContractor: person.otherContractor || 0,
    insuranceContractor:
      (person.incomeContractor > 0 && person.monthlySickLeaveInsurancePaymentContractor) || 0,
    netMonthlyExpense: person.expenses,
    age: person.age,
    debt: person.debts,
    childrenAges,
    childrenTaxAdvantage: person.childrenTaxAdvantage || 0,
    persons,
    otherIncome,
    mortgageRepaymentByRisk,
  };
}

export function calculateRisk(
  riskDef: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePersonVM,
  persons: LifeInsurancePersonVM[],
  assets: Asset[],
): number {
  try {
    return riskDef.calculate(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(
      `Error while calculating risk for riskKey=${riskDef?.key}, provision=${provision}`,
    );
    return 0;
  }
}

export function calculateHelp(
  riskDef: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePersonVM,
  persons: LifeInsurancePersonVM[],
  assets: Asset[],
): string {
  try {
    return riskDef.help(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(`Error while calculating help for riskKey=${riskDef.key}, provision=${provision}`);
    return '';
  }
}

export function calculateDescription(
  riskDef: RiskDefinition,
  provision: Provision,
  person: LifeInsurancePersonVM,
  persons: LifeInsurancePersonVM[],
  assets: Asset[],
): string {
  try {
    return riskDef.description(getRiskInput(persons, person, assets, provision));
  } catch (e) {
    console.warn(
      `Error while calculating description for riskKey=${riskDef.key}, provision=${provision}`,
    );
    return '';
  }
}

export function isFinancialStateBad(person: LifeInsurancePersonVM) {
  const incomeThreshold = 500;
  const negativeOutlook = person.financialOutlook === 'negative';
  const noIncome = !person.income && !person.incomeContractor && !person.passiveIncome;
  const lowDifference =
    person.expenses !== undefined &&
    person.expenses !== 0 &&
    person.passiveIncome + person.incomeContractor + person.income - person.expenses <
      incomeThreshold;

  return negativeOutlook || noIncome || lowDifference;
}

export function setRiskActive(asset: FamilyProvisionAsset, risk: RiskVM, active: boolean) {
  asset.lifeInsuranceRisks = asset.lifeInsuranceRisks ?? [];
  let assetRisk = asset.lifeInsuranceRisks.find(r => getRiskId(r) === getRiskId(risk.data));
  if (!assetRisk) {
    assetRisk = risk.data;
    asset.lifeInsuranceRisks.push(assetRisk);
  }
  assetRisk.active = active;

  // risk can be active either on the left or right side, but not on both
  if (active) {
    const otherSideId = getRiskId({
      personId: risk.data.personId,
      key: risk.data.key,
      provision: otherSideProvision[risk.data.provision],
    });
    assetRisk = asset.lifeInsuranceRisks.find(r => getRiskId(r) === otherSideId);
    if (assetRisk) {
      assetRisk.active = false;
    }
  }
}

export function setRiskLimit(asset: FamilyProvisionAsset, risk: RiskVM, limit: number) {
  asset.lifeInsuranceRisks = asset.lifeInsuranceRisks ?? [];
  let assetRisk = asset.lifeInsuranceRisks.find(r => getRiskId(r) === getRiskId(risk.data));
  if (!assetRisk) {
    assetRisk = risk.data;
    asset.lifeInsuranceRisks.push(assetRisk);
  }
  assetRisk.limit = limit;
}

export function setRiskAge(asset: FamilyProvisionAsset, risk: RiskVM, age: number) {
  asset.lifeInsuranceRisks = asset.lifeInsuranceRisks ?? [];
  let assetRisk = asset.lifeInsuranceRisks.find(r => getRiskId(r) === getRiskId(risk.data));
  if (!assetRisk) {
    assetRisk = risk.data;
    asset.lifeInsuranceRisks.push(assetRisk);
  }
  assetRisk.age = age;
}

export function setPersonData(asset: FamilyProvisionAsset, data: LifeInsurancePersonData) {
  asset.lifeInsurancePersonDatas = asset.lifeInsurancePersonDatas ?? [];
  const index = asset.lifeInsurancePersonDatas.findIndex(p => p.personId === data.personId);
  if (index === -1) {
    asset.lifeInsurancePersonDatas.push(data);
  } else {
    asset.lifeInsurancePersonDatas[index] = data;
  }
}
