import {HttpErrorResponse} from '@angular/common/http';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {State} from './index';

interface StandardizedState {
  data?: any;
  loading: boolean;
  error: HttpErrorResponse | null;
}

export function replenish<_, T extends StandardizedState>(
  selector: MemoizedSelector<object, T>,
  store: Store<State>,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actionClass: new (params?: any) => Action,
  params?: any,
) {
  return store.pipe(
    select(selector),
    filter((state: ReturnType<typeof selector>) => {
      // initial state is not reported
      // it cannot since synchronous dispatch (loading: true) would outrun
      // intial state
      if (!state.data && !state.loading && !state.error) {
        store.dispatch(new actionClass(params));
        return false;
      }
      return true;
    }),
  );
}

export function notLoadingNorError<T extends StandardizedState>(state: T) {
  return Boolean(state) && !state.loading && !state.error;
}

export function loadedOk<T extends StandardizedState>(state: T) {
  return Boolean(state && state.data);
}
