import {Injectable} from '@angular/core';
import {DialogService} from '@shared/services/modal.service';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialogService: DialogService) {}

  confirm(title: string, yesButton = 'Ano', noButton = 'Ne'): Promise<boolean> {
    return this.dialogService
      .load(
        ConfirmDialogComponent,
        {
          title,
          yesButton,
          noButton,
        },
        {
          size: 'lg',
        },
      )
      .catch(() => {
        return false;
      });
  }
}
