import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@UntilDestroy()
@Component({
  selector: 'kpt-header-info-row',
  templateUrl: './header-info-row.component.html',
  styleUrls: ['./header-info-row.component.scss'],
})
export class HeaderInfoRowComponent implements OnInit {
  @Input() pageName: string;
  phoneNumber: string;
  email: string;
  familyUuid: string;
  showBreadcrumbs: boolean;

  constructor(private store: Store<State>, private router: Router) {}

  ngOnInit() {
    combineLatest([this.store.pipe(selectFamilyHead()), this.store.pipe(select(getFamilyUuid))])
      .pipe(untilDestroyed(this))
      .subscribe(([familyMember, familyUuid]) => {
        this.phoneNumber = familyMember.phoneNumber;
        this.email = familyMember.email;
        this.familyUuid = familyUuid;
        this.setBreadcrumbsVisibility();
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.setBreadcrumbsVisibility());
  }

  getConsultantLink(): string {
    return '/secured/consultant';
  }

  getClientLink(): string {
    return `/secured/client/${this.familyUuid}`;
  }

  private setBreadcrumbsVisibility() {
    this.showBreadcrumbs = !this.router.url.includes('secured/consultant/');
  }
}
