import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import * as moment from 'moment/moment';
import * as uuid from 'uuid';

export abstract class BaseAssetType {
  abstract getAssetDefinition(): AssetDefinition;

  createId(): string {
    return uuid.v4();
  }

  today(): string {
    return moment().format('YYYY-MM-DD');
  }
}
