import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Answers} from '@shared/investment-survey-old/models/answer';
import {
  AdditionalAnswerType,
  AdditionalInput,
  AdditionalSelect,
  AdditionalText,
  Question,
  QuestionCategory,
  QuestionsOrigin,
  QuestionType,
} from '@shared/investment-survey-old/models/question';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {
  esgBondsMoreDescription,
  esgObjectivesDescription,
  esgObjectivesMoreDescription,
  esgPreferenceDescription,
  esgPreferenceMoreDescription,
} from '@shared/investment-survey-old/questions.descriptions';
import {IOption} from '@shared/ui';
import {cloneDeep, merge, set} from 'lodash';

@Injectable()
export class Questions {
  constructor() {}

  allQuestions(questionsOrigin: QuestionsOrigin = QuestionsOrigin.FINANCIAL_ANALYSIS): Question[] {
    return [
      ...this.knowledge().questions,
      ...this.experience().questions,
      ...this.investmentHorizon().questions,
      ...this.risk().questions,
      ...this.other().questions,
      ...this.investmentRequirements().questions,
      ...this.esg(questionsOrigin).questions,
    ];
  }

  allIds(questionsOrigin: QuestionsOrigin): string[] {
    const ids: string[] = [];
    this.allQuestionsCategories(questionsOrigin).forEach(category => {
      category.questions.forEach(question => ids.push(question.id));
    });
    return ids;
  }

  allQuestionsCategories(questionsOrigin: QuestionsOrigin): QuestionCategory[] {
    let filterFunction: (question: Question) => boolean;
    switch (questionsOrigin) {
      case QuestionsOrigin.FINANCIAL_ANALYSIS:
        filterFunction = question => !question.excludedFromAnalysis;
        break;
      case QuestionsOrigin.INVESTMENT:
        filterFunction = question => question.investment;
        break;
      case QuestionsOrigin.LIFE_INSURANCE:
        filterFunction = question => question.lifeInsurance;
        break;
      case QuestionsOrigin.SUPPLEMENTARY_PENSION_SAVINGS:
        filterFunction = question => question.supplementaryPensionSavings;
        break;
      case QuestionsOrigin.INVESTMENT_REQUIREMENTS:
        filterFunction = question => question.investmentRequirements;
        break;
      case QuestionsOrigin.INVESTMENT_ALL:
        filterFunction = question => question.investment || question.investmentRequirements;
        break;
      default:
        filterFunction = question => !question.excludedFromAnalysis;
        break;
    }
    return [
      this.knowledge(),
      this.experience(),
      this.investmentHorizon(),
      this.risk(),
      this.other(),
      this.investmentRequirements(),
      this.esg(questionsOrigin),
    ].map(category => {
      category.questions = this.filterQuestions(category.questions, filterFunction);
      return category;
    });
  }

  questionByQuestionId(
    questionId: string,
    questionsOrigin: QuestionsOrigin = QuestionsOrigin.FINANCIAL_ANALYSIS,
  ): Question {
    return this.allQuestions(questionsOrigin).find(q => q.id === questionId);
  }

  getPoints(answers: Answers, questionsSet: string[] = []): number {
    let points = 0;
    this.allQuestions()
      .filter(q => (questionsSet.length === 0 || questionsSet.includes(q.id)) && answers[q.id])
      .forEach(question => {
        if (question.type === QuestionType.CHECKBOX) {
          Object.entries(answers[question.id]).forEach(([key, value]: [string, boolean]) => {
            if (value) {
              const answer = question.answers.find(a => key === a.id);
              if (answer) {
                points += answer.score;
              }
            }
          });
        } else if (question.type === QuestionType.TABLE) {
          Object.entries(answers[question.id]).forEach(
            ([rowKey, row]: [string, {[key: string]: boolean}]) => {
              Object.entries(row).forEach(([key, value]: [string, boolean]) => {
                if (value) {
                  const forcedScore = question.rows.find(
                    questionRow => questionRow.id === rowKey,
                  ).forcedScore;
                  points +=
                    (forcedScore && forcedScore[key]) ??
                    question.answers.find(answer => key === answer.id).score;
                }
              });
            },
          );
        } else {
          const answer = question.answers.find(a => answers[question.id].answer === a.id);
          points += answer ? answer.score : 0;
        }
      });
    return points;
  }

  private filterQuestions(questions: Question[], filter: (question: Question) => boolean) {
    return questions.filter(filter);
  }

  private knowledge(): QuestionCategory {
    return {
      name: 'Znalosti klienta',
      questions: [
        {
          id: 'knowledgeInv',
          question:
            'Uveďte, zda je toto tvrzení pravdivé či nikoliv (jestliže nevíte, můžete namísto odpovědi ano/ne, odpovědět “nevím”).',
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 1,
            },
            {
              id: 'no',
              text: 'Ne',
              score: -1,
            },
            {
              id: 'dontKnow',
              text: 'Nevím',
              score: -1,
            },
          ],
          rowsHeader: '',
          rows: [
            {
              id: 'risk',
              text: 'Vyšší výnos je obvykle spojen s vyšším rizikem ztráty investované částky',
            },
            {
              id: 'diversification',
              text:
                'Diverzifikace je zaměření se na pouze jeden investiční nástroj' +
                ' (např. akcie nebo dluhopisy pouze jedné společnosti)',
              forcedScore: {yes: -1, no: 1},
            },
            {
              id: 'lengthInvestment',
              text: 'Obecně platí, že čím delší je doba investice, tím menší je riziko poklesu hodnoty',
            },
            {
              id: 'returnsInvestment',
              text: 'Výnosy investice v minulosti jsou vždy zárukou výnosů v budoucnosti',
              forcedScore: {yes: -1, no: 1},
            },
            {
              id: 'mutualFund',
              text: 'Investice do podílového fondu je vždy spojena s garancí návratu investice',
              forcedScore: {yes: -1, no: 1.5},
            },
            {
              id: 'mutualFundManagement',
              text:
                'Podílové fondy mohou být spravované aktivně (portfolio manažerem)' +
                ' i pasivně (kopírují trh, např. burzovní index)',
              forcedScore: {yes: 1.5, no: -1},
            },
            {
              id: 'realEstateFundsInvestments',
              text:
                'Nemovitostní fondy mohou investovat do všech druhů nemovitostí' +
                ' bez omezení (rezidenční, kancelářské, průmyslové)',
              forcedScore: {yes: 1.5, no: -1},
            },
            {
              id: 'bondsRisk',
              text:
                'Jsou státní dluhopisy České republiky obecně rizikovější' +
                ' než dluhopisy vydávané soukromými společnostmi?',
              forcedScore: {yes: -1, no: 1.5},
            },
          ],
          type: QuestionType.TABLE,
          lifeInsurance: false,
          investment: true,
          supplementaryPensionSavings: false,
          onValueChange: this.onlyOneAnswerSimpleTable(
            [
              'risk',
              'diversification',
              'lengthInvestment',
              'returnsInvestment',
              'mutualFund',
              'mutualFundManagement',
              'realEstateFundsInvestments',
              'bondsRisk',
            ],
            'knowledgeInv',
          ),
        },
        {
          id: 'knowledgeLi',
          question:
            'Uveďte, zda je toto tvrzení pravdivé či nikoliv (jestliže nevíte, můžete namísto odpovědi ano/ne, odpovědět “nevím”).',
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 1,
            },
            {
              id: 'no',
              text: 'Ne',
              score: -1,
            },
            {
              id: 'dontKnow',
              text: 'Nevím',
              score: -1,
            },
          ],
          rowsHeader: '',
          rows: [
            {
              id: 'risk',
              text: 'Vyšší výnos je obvykle spojen s vyšším rizikem ztráty investované částky',
            },
            {
              id: 'portfolioDiversification',
              text: 'Diverzifikace portfolia znamená zaměření se pouze na jeden investiční nástroj (např. akcie jedné společnosti)',
              forcedScore: {yes: -1, no: 1},
            },
            {
              id: 'shares',
              text:
                'Akcie je cenný papír, se kterým je spojeno mj. právo podílet se na zisku akciové společnost' +
                ' a na jejím řízení formou hlasování na valné hromadě',
            },
            {
              id: 'bonds',
              text: 'Dluhopisy vydávané soukromými subjekty jsou obvykle méně rizikové než státní dluhopisy',
              forcedScore: {yes: -1, no: 1},
            },
          ],
          type: QuestionType.TABLE,
          lifeInsurance: true,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          onValueChange: this.onlyOneAnswerSimpleTable(
            ['risk', 'portfolioDiversification', 'shares', 'bonds'],
            'knowledgeLi',
          ),
        },
        {
          id: 'knowledgeSps',
          question:
            'Uveďte, zda je toto tvrzení pravdivé či nikoliv (jestliže nevíte, můžete namísto odpovědi ano/ne, odpovědět “nevím”).',
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 1,
            },
            {
              id: 'no',
              text: 'Ne',
              score: -1,
            },
            {
              id: 'dontKnow',
              text: 'Nevím',
              score: -1,
            },
          ],
          rowsHeader: '',
          rows: [
            {
              id: 'risk',
              text: 'Vyšší výnos je obvykle spojen s vyšším rizikem ztráty investované částky',
            },
            {
              id: 'financialInstruments',
              text:
                'Nástroje peněžního trhu (např. termínované vklady na bankovní účet) patří mezi velmi rizikové' +
                ' nástroje s vysokým rizikem ztráty',
              forcedScore: {yes: -1, no: 1},
            },
            {
              id: 'unitCertificate',
              text: 'Podílový list představuje část majetku podílového fondu',
            },
            {
              id: 'bonds',
              text: 'Dluhopisy vydávané soukromými subjekty jsou obvykle méně rizikové než státní dluhopisy',
              forcedScore: {yes: -1, no: 1},
            },
          ],
          type: QuestionType.TABLE,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: true,
          excludedFromAnalysis: true,
          onValueChange: this.onlyOneAnswerSimpleTable(
            ['risk', 'financialInstruments', 'unitCertificate', 'bonds'],
            'knowledgeSps',
          ),
        },
        {
          id: 'financialMarketNews',
          question: 'Sledujete zprávy z oblasti financí a finančních trhů, které jsou v médiích?',
          answers: [
            {
              id: 'not-interested',
              text: 'Cíleně je nesleduji',
              score: 0,
            },
            {
              id: 'sometimes',
              text: 'Sleduji je občasně',
              score: 1,
            },
            {
              id: 'regularly',
              text: 'Sleduji je pravidelně',
              score: 2,
            },
            {
              id: 'watch-specialized',
              text: 'Sleduji specializované pořady/podcasty/media/články',
              score: 3,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
        },
        {
          id: 'education',
          question: 'Vaše vzdělání a profesionální praxi v oblasti investování nejlépe vystihuje:',
          answers: [
            {
              id: 'nothing',
              text: 'Nemám v tomto oboru vzdělání ani profesionální praxi',
              score: 0,
            },
            {
              id: 'interest',
              text: 'Občasné studium či zájem o oblast investování do investičních nástrojů',
              score: 2,
            },
            {
              id: 'experience',
              text:
                'Cílené zájmové samostudium v oblasti investování,' +
                ' absolvování kurzů, sledování médií, podcastů nebo článků',
              score: 3,
            },
            {
              id: 'university',
              text:
                'Vysokoškolské vzdělání v oboru financí, ekonomie nebo pracovní praxe převážně v oboru' +
                ' investování alespoň dva roky v posledních pěti letech, případně odborná zkouška z této oblasti',
              score: 5,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
        },
      ],
    };
  }

  private experience(): QuestionCategory {
    return {
      name: 'Zkušenosti klienta',
      questions: [
        {
          id: 'experience',
          question:
            'Jaké jsou Vaše dosavadní zkušenosti s investováním? (zatrhněte všechny údaje, které platí).',
          answers: [
            {
              id: 'nothing',
              text: 'Dosud jsem neinvestoval/a',
              score: 0,
            },
            {
              id: 'oneTime',
              text:
                'Jednorázové dlouhodobé investice' +
                ' (např. podílové fondy, doplňkové penzijní spoření, investiční životní pojištění, akcie)',
              score: 2,
            },
            {
              id: 'periodic',
              text:
                'Pravidelné investování' +
                ' (např. podílové fondy, doplňkové penzijní spoření, investiční životní pojištění, akcie)',
              score: 2,
            },
            {
              id: 'continuousEditingPortfolio',
              text:
                'Průběžné upravování vytvořeného portfolia' +
                ' (fondové portfolio, aplikace typu Degiro, eToro, Revolut, apod.)',
              score: 3,
            },
            {
              id: 'shortTermTransactions',
              text: 'Spekulativní (krátkodobé) obchody, kryptoměny, deriváty nebo hedging',
              score: 3,
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
          onValueChange: (
            currentValue: any,
            oldValues: Answers,
            currentValues: any,
            form: UntypedFormGroup,
          ): void => {
            if (!currentValue || !oldValues.experience) return;
            const oldExperienceValues = oldValues.experience as {[key: string]: boolean};
            const enableNextAnswers = (enable: boolean) => {
              ['equity', 'money', 'shares'].forEach(rowId => {
                const rowForm = form.get(['transactions', rowId]);
                if (enable) {
                  rowForm.get('lastThreeYears').enable();
                  rowForm.get('moreThanHundred').enable();
                } else {
                  rowForm.get('lastThreeYears').disable();
                  rowForm.get('moreThanHundred').disable();
                  set(currentValues, ['transactions', rowId, 'lastThreeYears'], false);
                  set(currentValues, ['transactions', rowId, 'moreThanHundred'], false);
                }
              });
            };

            if (
              currentValue.nothing === oldExperienceValues.nothing &&
              currentValue.oneTime === oldExperienceValues.oneTime &&
              currentValue.periodic === oldExperienceValues.periodic &&
              currentValue.continuousEditingPortfolio ===
                oldExperienceValues.continuousEditingPortfolio &&
              currentValue.shortTermTransactions === oldExperienceValues.shortTermTransactions
            )
              return;
            if (currentValue.nothing && !oldExperienceValues.nothing) {
              currentValue.oneTime = false;
              currentValue.periodic = false;
              currentValue.continuousEditingPortfolio = false;
              currentValue.shortTermTransactions = false;
              enableNextAnswers(false);
            } else {
              currentValue.nothing = false;
              enableNextAnswers(true);
            }
          },
        },
        {
          id: 'transactions',
          question:
            '<p>Typy obchodů s investičními nástroji, které jste uzavřel/a' +
            ' (zatrhněte všechny údaje, které platí).</p>' +
            'A: Nikdy<br/>' +
            'B: V posledních třech letech<br/>' +
            'C: Celkem má investice přesáhla 100.000 Kč',
          answers: [
            {
              id: 'never',
              text: 'Nikdy',
              shortText: 'A',
              score: 0,
            },
            {
              id: 'lastThreeYears',
              text: 'V posledních třech letech',
              shortText: 'B',
              score: 1,
            },
            {
              id: 'moreThanHundred',
              text: 'Celkem má investice přesáhla 100.000 Kč',
              shortText: 'C',
              score: 1,
            },
          ],
          rowsHeader: 'Investoval/a jsem do',
          rows: [
            {
              id: 'money',
              text:
                'Fondů peněžního trhu, DPS s konzervativnější strategií,' +
                ' dluhopisového fondu nebo do zajištěného fondu',
            },
            {
              id: 'equity',
              text:
                'Fondu smíšeného, akciového, DPS s dynamičtější strategií,' +
                ' do dluhopisů nebo do diverzifikovaných ETF',
              forcedScore: {lastThreeYears: 2, moreThanHundred: 3},
            },
            {
              id: 'shares',
              text: 'Akcií, sektorových ETF, kryptoměn, nezajištěných certifikátů nebo do derivátů',
              forcedScore: {lastThreeYears: 3, moreThanHundred: 3},
            },
          ],
          type: QuestionType.TABLE,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
          onValueChange: (currentValue: any, oldValues: Answers): void => {
            if (!currentValue || !oldValues.transactions) return;
            const oldTransactionsValues = oldValues.transactions as {
              [key: string]: {[key: string]: boolean};
            };

            const rows = ['money', 'equity', 'shares'];
            rows.forEach(row => {
              const rowValues = currentValue[row];
              const oldRowValues = oldTransactionsValues[row];
              if (
                rowValues.never === oldRowValues.never &&
                rowValues.lastThreeYears === oldRowValues.lastThreeYears &&
                rowValues.moreThanHundred === oldRowValues.moreThanHundred
              )
                return;
              if (rowValues.never && !oldRowValues.never) {
                rowValues.lastThreeYears = false;
                rowValues.moreThanHundred = false;
              } else if (rowValues.moreThanHundred && !oldRowValues.moreThanHundred) {
                rowValues.never = false;
                rowValues.lastThreeYears = true;
              } else rowValues.never = false;
            });
          },
        },
      ],
    };
  }

  private risk(): QuestionCategory {
    return {
      name: 'Vztah k riziku a schopnost nést ztrátu',
      questions: [
        {
          id: 'risk',
          question: 'Co je pro Vás důležitější – vysoký výnos nebo větší jistota?',
          answers: [
            {
              id: 'no-risk',
              text: 'Chci získat minimálně to, co jsem do investice vložil - požaduji garanci bez ztrát',
              score: 0,
            },
            {
              id: 'almost-none-risk',
              text:
                'Nejdůležitější pro mě je co největší zachování hodnoty investice, i za cenu  nižšího výnosu.' +
                ' Prioritou pro mě je především ochrana investice.',
              score: 1,
            },
            {
              id: 'low-risk',
              text:
                'Od investice očekávám adekvátní výnosy.' +
                ' Prioritou je pro mě rovnováha mezi výnosem a ochranou investice.',
              score: 2,
            },
            {
              id: 'partial-risk',
              text:
                'Od investice očekávám nadprůměrné výnosy' +
                ' a jsem ochoten podstoupit adekvátní riziko vzniku ztráty.',
              score: 3,
            },
            {
              id: 'max-risk',
              text:
                'Vyhledávám zajímavé investice a jsem ochoten/ochotna podstoupit' +
                ' i riziko významnější ztráty nebo ztráty celé investice.',
              score: 4,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
        },
        {
          id: 'graph',
          question:
            'Zde uvedený graf zobrazuje ilustrační průměrnou roční (p. a.) míru nejvyššího možného výnosu' +
            ' a nejvyšší možné ztráty v rámci investičního horizontu.' +
            ' Které portfolio byste zvolil/a pro svou investici?',
          answers: [
            {
              id: 'A',
              text: 'Portfolio A',
              score: 1,
            },
            {
              id: 'B',
              text: 'Portfolio B',
              score: 2,
            },
            {
              id: 'C',
              text: 'Portfolio C',
              score: 3,
            },
            {
              id: 'D',
              text: 'Portfolio D',
              score: 4,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
          graph: {
            datasets: [
              {
                data: [-1.5, -3.5, -6, -9],
                label: 'Ztráta',
              },
              {
                data: [2.5, 5, 8, 10],
                label: 'Výnos',
              },
            ],
            options: {
              legend: {display: false},
              hover: {mode: null},
              responsive: true,
              title: {
                display: true,
                fontSize: 16,
                padding: 12,
                text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 1-3 roky v oblasti investic',
              },
              scales: {
                xAxes: [{stacked: true}],
                yAxes: [
                  {
                    scaleLabel: {labelString: '%'},
                    ticks: {min: -15, max: 24},
                  },
                ],
              },
              plugins: {
                datalabels: {
                  anchor: context => (context.datasetIndex === 0 ? 'start' : 'end'),
                  align: context => (context.datasetIndex === 0 ? 'start' : 'end'),
                  formatter: val => `${val} %`,
                },
              },
              tooltips: {
                callbacks: {
                  label: item => `${item.yLabel} %`,
                },
              },
            },
            labels: ['Portfolio A', 'Portfolio B', 'Portfolio C', 'Portfolio D'],
            type: 'bar',
          },
          graphConditions: [
            {
              targetAnswer: 'investmentDurationInv.answer',
              questionsOrigins: [
                QuestionsOrigin.FINANCIAL_ANALYSIS,
                QuestionsOrigin.INVESTMENT,
                QuestionsOrigin.INVESTMENT_ALL,
              ],
              modifyQuestion: (question: Question, answers: Answers): Question => {
                let updatedGraph;
                if (
                  ['less-than-year', 'min-one-year'].includes(
                    answers.investmentDurationInv.answer as string,
                  )
                ) {
                  updatedGraph = {
                    datasets: [
                      {
                        data: [-1.5, -3.5, -6, -9],
                        label: 'Ztráta',
                      },
                      {
                        data: [2.5, 5, 8, 10],
                        label: 'Výnos',
                      },
                    ],
                    options: {
                      title: {
                        text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 1-3 roky v oblasti investic',
                      },
                    },
                  };
                } else if (
                  ['min-three-years'].includes(answers.investmentDurationInv.answer as string)
                ) {
                  updatedGraph = {
                    datasets: [
                      {
                        data: [-2.5, -4.5, -7.5, -9],
                        label: 'Ztráta',
                      },
                      {
                        data: [4, 7, 9.5, 12],
                        label: 'Výnos',
                      },
                    ],
                    options: {
                      title: {
                        text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 3-5 let v oblasti investic',
                      },
                    },
                  };
                } else if (
                  ['min-five-years'].includes(answers.investmentDurationInv.answer as string)
                ) {
                  updatedGraph = {
                    datasets: [
                      {
                        data: [-3, -4.5, -7.5, -10],
                        label: 'Ztráta',
                      },
                      {
                        data: [5.5, 8, 11, 14],
                        label: 'Výnos',
                      },
                    ],
                    options: {
                      title: {
                        text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 5-10 let v oblasti investic',
                      },
                    },
                  };
                } else {
                  updatedGraph = {
                    datasets: [
                      {
                        data: [-3, -5, -8, -10],
                        label: 'Ztráta',
                      },
                      {
                        data: [6, 10, 14, 18],
                        label: 'Výnos',
                      },
                    ],
                    options: {
                      title: {
                        text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 10 a více let v oblasti investic',
                      },
                    },
                  };
                }
                return merge(cloneDeep(question), {graph: updatedGraph});
              },
            },
            {
              targetAnswer: 'investmentDurationLi.answer',
              questionsOrigins: [QuestionsOrigin.LIFE_INSURANCE],
              modifyQuestion: (question: Question): Question => {
                return merge(cloneDeep(question), {
                  graph: {
                    datasets: [
                      {
                        data: [0, -1, -2, -3.5],
                        label: 'Ztráta',
                      },
                      {
                        data: [1, 2.25, 3.5, 6.5],
                        label: 'Výnos',
                      },
                    ],
                    options: {
                      title: {
                        text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 10 let v investičním životním pojištění',
                      },
                    },
                  },
                });
              },
            },
            {
              targetAnswer: 'investmentDurationSps.answer',
              questionsOrigins: [QuestionsOrigin.SUPPLEMENTARY_PENSION_SAVINGS],
              modifyQuestion: (question: Question): Question => {
                return merge(cloneDeep(question), {
                  graph: {
                    datasets: [
                      {
                        data: [0, -1, -2, -4],
                        label: 'Ztráta',
                      },
                      {
                        data: [2, 3, 5, 7],
                        label: 'Výnos',
                      },
                    ],
                  },
                  options: {
                    title: {
                      text: 'Průměrný maximální možný roční výnos/ztráta při horizontu 30 let v doplňkovém penzijním spoření',
                    },
                  },
                });
              },
            },
          ],
        },
        {
          id: 'lossOfInvestment',
          question:
            'Co by pro Vás znamenalo ztrátu částky, kterou chcete investovat (jednorázově nebo v roční výši)?',
          answers: [
            {
              id: 'everything',
              text: 'Značnou ztrátu mých úspor',
              score: 0,
            },
            {
              id: 'plans-threat',
              text: 'Ohrožení mých plánů do budoucna',
              score: 1,
            },
            {
              id: 'acceptable-loss',
              text: 'Akceptovatelná ztráta, kterou pokryji z jiných zdrojů ale pocítím finanční újmu',
              score: 3,
            },
            {
              id: 'nothing',
              text: 'Nic hrozného, mám dostatek jiných finančních prostředků a nepocítím žádnou finanční újmu',
              score: 4,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: true,
        },
      ],
    };
  }

  private investmentHorizon(): QuestionCategory {
    return {
      name: 'Investiční horizont',
      questions: [
        {
          id: 'investmentDurationInv',
          question: 'Jak dlouho plánujete ponechat vaše prostředky zainvestované?',
          answers: [
            {
              id: 'less-than-year',
              text: 'Méně než rok',
              score: 0,
            },
            {
              id: 'min-one-year',
              text: 'Minimálně 1 rok',
              score: 0,
            },
            {
              id: 'min-three-years',
              text: 'Minimálně 3 roky',
              score: 0,
            },
            {
              id: 'min-five-years',
              text: 'Minimálně 5 let',
              score: 0,
            },
            {
              id: 'min-ten-years',
              text: 'Minimálně 10 let',
              score: 0,
            },
            {
              id: 'min-fifteen-years',
              text: 'Minimálně 15 let',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: false,
          investment: true,
          supplementaryPensionSavings: false,
        },
        {
          id: 'investmentDurationLi',
          question: 'Po jakou dobu si přejete investovat v rámci rezervotvorného pojištění?',
          answers: [
            {
              id: 'max-five-years',
              text: '5 let nebo méně',
              score: 0,
            },
            {
              id: 'min-six-years',
              text: '6-10 let',
              score: 0,
            },
            {
              id: 'min-eleven-years',
              text: '11 let a více',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: false,
          supplementaryPensionSavings: false,
        },
        {
          id: 'investmentDurationSps',
          question:
            'Předpokládaná doba spoření v dynamických fondech DPS' +
            ' (Vaše prostředky v doplňkovém penzijním spoření by dle zákona měly být 5 let před dosažením' +
            ' důchodového věku převedeny do Povinného konzervativního fondu, pokud se nerozhodnete jinak)',
          answers: [
            {
              id: 'five-years',
              text: 'Je mi více než 50 let, tj. doba investování do dynamičtějších fondů by měla být max. 5 let',
              score: 2,
            },
            {
              id: 'ten-years',
              text: 'Je mi 45–50 let, tj. doba investování do dynamičtějších fondů by měla být max. 10 let',
              score: 4,
            },
            {
              id: 'fifteen-years',
              text: 'Je mi 40–44 let, tj. doba investování do dynamičtějších fondů by měla být max. 15 let',
              score: 6,
            },
            {
              id: 'twenty-years',
              text: 'Je mi 40–30 let, tj. doba investování do dynamičtějších fondů by měla být max. 20',
              score: 8,
            },
            {
              id: 'twenty-five-years',
              text: 'Je mi méně než 30 let, tj. doba investování do dynamičtějších fondů může být max. 25 let',
              score: 10,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: true,
        },
      ],
    };
  }

  private other(): QuestionCategory {
    return {
      name: 'Doplňující otázky',
      questions: [
        {
          id: 'currentInvestedFinances',
          question: 'Kde máte v současné době vložené finanční prostředky?',
          answers: [
            {
              id: 'savingAccounts',
              text: 'Na spořících účtech nebo termínovaných vkladech',
              score: 0,
            },
            {
              id: 'anotherBroker',
              text: 'V investicích, zainvestované prostřednictvím jiného investičního zprostředkovatele',
              score: 0,
            },
            {
              id: 'buildingSavings',
              text: 'Ve stavebním spoření',
              score: 0,
            },
            {
              id: 'supplementaryPensionSavings',
              text: 'V doplňkovém penzijní spořením',
              score: 0,
            },
            {
              id: 'investmentLifeInsurance',
              text: 'V investičním životním spoření',
              score: 0,
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: true,
          investment: false,
          supplementaryPensionSavings: false,
        },
      ],
    };
  }

  private investmentRequirements(): QuestionCategory {
    return {
      name: 'Požadavky klienta',
      questions: [
        {
          id: 'investmentPurpose',
          question: 'Jaký je účel investice?',
          answers: [
            {
              id: 'shortTermKnownIntention',
              text:
                'Krátkodobé nebo střednědobé uložení finančních prostředků na předem známý záměr ' +
                '(např. významné výdaje typu koupě nemovitosti nebo auta, rekonstrukce, kapitál pro podnikání)',
              score: 0,
            },
            {
              id: 'longTermKnownIntention',
              text: 'Dlouhodobé uložení finančních prostředků na předem známý záměr (např. zajištění na důchod)',
              score: 0,
            },
            {
              id: 'shortTermNoIntention',
              text:
                'Krátkodobé nebo střednědobé uložení finančních prostředků primárně bez konkrétního záměru, ' +
                'ochrana finančních prostředků před inflací',
              score: 0,
            },
            {
              id: 'longTermNoIntention',
              text: 'Dlouhodobé zhodnocení finančních prostředků bez konkrétního záměru',
              score: 0,
            },
            {
              id: 'speculation',
              text: 'Spekulace – snaha o maximální výnos',
              score: 0,
            },
            {
              id: 'alternativeIncome',
              text: 'Tvorba alternativního průběžného příjmu z investice (úroky, dividendy apod.)',
              score: 0,
            },
            {
              id: 'portfolioDiversification',
              text: 'Diverzifikace portfolia (např. sektorová, geografická, měnová) a rozložení rizik',
              score: 0,
            },
            {
              id: 'personalMotivation',
              text: 'Osobní motivace, např. snaha podpořit konkrétní hospodářský sektor nebo udržitelné investice (ESG)',
              score: 0,
            },
            {
              id: 'other',
              text: 'Jiný účel',
              score: 0,
              additionalAnswerParts: [
                {
                  id: 'otherText',
                  type: AdditionalAnswerType.INPUT_TEXT,
                  content: {
                    label: '',
                  } as AdditionalInput,
                },
              ],
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: true,
          onValueChange: (newValue: any, oldValues: Answers): void => {
            const oldValue: any = oldValues.investmentPurpose;
            if (newValue.shortTermKnownIntention && !oldValue.shortTermKnownIntention) {
              newValue.longTermKnownIntention =
                newValue.shortTermNoIntention =
                newValue.longTermNoIntention =
                  false;
            }
            if (newValue.longTermKnownIntention && !oldValue.longTermKnownIntention) {
              newValue.shortTermKnownIntention =
                newValue.shortTermNoIntention =
                newValue.longTermNoIntention =
                  false;
            }
            if (newValue.shortTermNoIntention && !oldValue.shortTermNoIntention) {
              newValue.shortTermKnownIntention =
                newValue.longTermKnownIntention =
                newValue.longTermNoIntention =
                  false;
            }
            if (newValue.longTermNoIntention && !oldValue.longTermNoIntention) {
              newValue.shortTermKnownIntention =
                newValue.longTermKnownIntention =
                newValue.shortTermNoIntention =
                  false;
            }
          },
        },
        {
          id: 'desiredInvestments',
          question: 'Kam chcete investovat finanční prostředky?',
          answers: [
            {
              id: 'mutualFunds',
              text: 'Do podílových fondů',
              score: 0,
            },
            {
              id: 'bonds',
              text: 'Do dluhopisů',
              score: 0,
            },
            {
              id: 'realEstateFunds',
              text: 'Do nemovitostních fondů',
              score: 0,
            },
            {
              id: 'other',
              text: 'Do jiných produktů',
              score: 0,
              additionalAnswerParts: [
                {
                  id: 'otherText',
                  type: AdditionalAnswerType.INPUT_TEXT,
                  content: {
                    label: '',
                  } as AdditionalInput,
                },
              ],
            },
            {
              id: 'doNotKnow',
              text: 'Nevím',
              score: 0,
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: true,
          onValueChange: (currentValue: any, oldValues: Answers): void => {
            if (!currentValue || !oldValues.desiredInvestments) return;
            const desiredInvestmentValues = oldValues.desiredInvestments as {
              [key: string]: boolean | string;
            };

            if (
              currentValue.mutualFunds === desiredInvestmentValues.mutualFunds &&
              currentValue.bonds === desiredInvestmentValues.bonds &&
              currentValue.realEstateFunds === desiredInvestmentValues.realEstateFunds &&
              currentValue.other === desiredInvestmentValues.other &&
              currentValue.otherText === desiredInvestmentValues.otherText &&
              currentValue.doNotKnow === desiredInvestmentValues.doNotKnow
            )
              return;
            if (currentValue.doNotKnow && !desiredInvestmentValues.doNotKnow) {
              currentValue.mutualFunds = false;
              currentValue.bonds = false;
              currentValue.realEstateFunds = false;
              currentValue.other = false;
              currentValue.otherText = null;
            } else currentValue.doNotKnow = false;
            if (!currentValue.other) currentValue.otherText = null;
          },
        },
        {
          id: 'diversification',
          question: 'Chcete diverzifikovat portfolio a rozložit rizika?',
          description:
            'Požadavek na diverzifikaci a rozložení rizik může znamenat doporučení i' +
            ' méně rizikového produktu, než je rizikový profil dle investičního dotazníku.',
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 0,
            },
            {
              id: 'no',
              text: 'Ne',
              score: 0,
            },
            {
              id: 'noPreference',
              text: 'Nemám preferenci',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: true,
        },
        {
          id: 'frequency',
          question: 'Jaký typ investice preferujete?',
          answers: [
            {
              id: 'oneTime',
              text: 'Jednorázová',
              score: 0,
            },
            {
              id: 'regular',
              text: 'Pravidelná',
              score: 0,
            },
            {
              id: 'irregular',
              text: 'Nepravidelná',
              score: 0,
            },
            {
              id: 'noPreference',
              text: 'Nemám preferenci',
              score: 0,
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: true,
          onValueChange: (currentValue: any, oldValues: Answers): void => {
            if (!currentValue || !oldValues.investmentTypePreference) return;
            const frequencyValues = oldValues.investmentTypePreference as {[key: string]: boolean};

            if (
              currentValue.oneTime === frequencyValues.oneTime &&
              currentValue.periodic === frequencyValues.periodic &&
              currentValue.nonPeriodic === frequencyValues.nonPeriodic &&
              currentValue.noPreference === frequencyValues.noPreference
            )
              return;
            if (currentValue.noPreference && !frequencyValues.noPreference) {
              currentValue.oneTime = false;
              currentValue.periodic = false;
              currentValue.nonPeriodic = false;
            } else currentValue.noPreference = false;
          },
        },
        {
          id: 'regularBuyout',
          question: 'Požadujete pravidelný odkup?',
          answers: [
            {
              id: 'yes',
              text: 'Ano, ',
              additionalAnswerParts: [
                {
                  id: 'regularBuyoutAmount',
                  type: AdditionalAnswerType.INPUT_NUMBER,
                  content: {
                    label: 'výše',
                  } as AdditionalInput,
                },
                {
                  id: '',
                  type: AdditionalAnswerType.TEXT,
                  content: {
                    text: ' s frekvencí ',
                  } as AdditionalText,
                },
                {
                  id: 'regularBuyoutFrequency',
                  type: AdditionalAnswerType.INPUT_SELECT,
                  content: {
                    label: 'frekvence',
                    options: [
                      {
                        label: 'měsíční',
                        key: '219902325555379',
                      },
                      {
                        label: 'čtvrtletní',
                        key: '219902325555380',
                      },
                      {
                        label: 'půlroční',
                        key: '219902325555381',
                      },
                      {
                        label: 'roční',
                        key: '219902325555477',
                      },
                    ],
                  } as AdditionalSelect,
                },
              ],
              score: 0,
            },
            {
              id: 'no',
              text: 'Ne',
              score: 0,
            },
            {
              id: 'noPreference',
              text: 'Nemám preferenci',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: false,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: true,
          onValueChange: (currentValue): void => {
            if (currentValue.answer !== 'yes') {
              currentValue.regularBuyoutAmount = null;
              currentValue.regularBuyoutFrequency = null;
            }
          },
        },
      ],
    };
  }

  private esg(questionsOrigin: QuestionsOrigin): QuestionCategory {
    const percents: IOption[] = [
      {key: null, label: ''},
      ...[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(p => ({key: p, label: `${p} %`})),
    ];

    return {
      name: 'Vztah k udržitelnosti investic',
      questions: [
        {
          id: 'esgPreference',
          question:
            questionsOrigin === QuestionsOrigin.LIFE_INSURANCE
              ? 'Mám preferenci ve vztahu k udržitelnosti investiční složky pojištění?'
              : 'Mám preferenci ve vztahu k udržitelnosti investice?',
          description: esgPreferenceDescription,
          moreDescription: esgPreferenceMoreDescription,
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 0,
            },
            {
              id: 'no',
              text: 'Ne',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: false,
        },
        {
          id: 'esgObjectives',
          question: 'Cíle udržitelnosti',
          description: esgObjectivesDescription,
          moreDescription: esgObjectivesMoreDescription,
          answers: [
            {
              id: 'lightGreen',
              text:
                'Preferuji tzv. světle zelené investice dle čl. 8 nařízení SFDR, ' +
                'které splňují požadavky na udržitelnost ale není to jejich hlavním cílem',
              score: 0,
            },
            {
              id: 'darkGreen',
              text: 'Preferuji tzv. tmavě zelené investice dle čl. 9 nařízení SFDR, jejichž hlavním cílem je udržitelnost',
              score: 0,
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: false,
          hidden: (surveyAnswers: SurveyAnswers[]) =>
            surveyAnswers.every(a => a.answers.esgPreference.answer === 'no'),
        },
        {
          id: 'esgDistribution',
          question:
            questionsOrigin === QuestionsOrigin.LIFE_INSURANCE
              ? 'Mám tyto preference v oblastech udržitelnosti a minimálním podílu na portfoliu investiční složky pojištění'
              : 'Mám tyto preference v oblastech udržitelnosti a minimálním podílu na portfoliu investice',
          answers: [
            {
              id: 'noComposition',
              text: 'Nemám preferenci v konkrétním složení',
              score: 0,
              customClass: 'fix-input-width',
              additionalAnswerParts: [
                {
                  id: 'noCompositionPercent',
                  type: AdditionalAnswerType.INPUT_SELECT,
                  content: {
                    label: null,
                    options: percents,
                    help: 'Zadejte, kolik min. % portfolia investice by měly tvořit udržitelné investice.',
                  },
                },
              ],
            },
            {
              id: 'environment',
              text: 'Environmentální složka – ochrana životního prostředí',
              score: 0,
              customClass: 'fix-input-width',
              additionalAnswerParts: [
                {
                  id: 'environmentPercent',
                  type: AdditionalAnswerType.INPUT_SELECT,
                  content: {
                    label: '',
                    options: percents,
                    help: 'Zadejte, kolik min. % portfolia investice by měla tvořit tato složka.',
                  },
                },
              ],
            },
            {
              id: 'social',
              text: 'Sociální složka – společenská odpovědnost',
              score: 0,
              customClass: 'fix-input-width',
              additionalAnswerParts: [
                {
                  id: 'socialPercent',
                  type: AdditionalAnswerType.INPUT_SELECT,
                  content: {
                    label: '',
                    options: percents,
                    help: 'Zadejte, kolik min. % portfolia investice by měla tvořit tato složka.',
                  },
                },
              ],
            },
            {
              id: 'otherDistribution',
              text: 'Mám jinou, vlastní preferenci',
              score: 0,
              customClass: 'fix-input-width',
              additionalAnswerParts: [
                {
                  id: 'otherDistributionText',
                  type: AdditionalAnswerType.INPUT_TEXT,
                  content: {
                    label: '',
                    help: 'Uveďte vlastní rizika či oblasti, které chcete zohlednit a % zastoupení v&nbsp;portfoliu produktu.',
                  },
                },
              ],
            },
            {
              id: 'noPreference',
              text: 'Nemám preferenci a nechávám na doporučení poradce',
              score: 0,
              customClass: 'fix-input-width',
              additionalAnswerParts: [
                {
                  id: 'noPreferenceText',
                  type: AdditionalAnswerType.INPUT_TEXT,
                  content: {
                    label: '',
                    help: 'Zde vyjádřete vlastní doporučení a % zastoupení složek v&nbsp;portfoliu produktu.',
                  },
                },
              ],
            },
          ],
          type: QuestionType.CHECKBOX,
          lifeInsurance: true,
          investment: true,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: false,
          onValueChange: (newValue: any, oldValues: Answers): void => {
            const oldValue: any = oldValues.esgDistribution;
            if (newValue.noComposition && !oldValue.noComposition) {
              newValue.environment =
                newValue.social =
                newValue.otherDistribution =
                newValue.noPreference =
                  false;
            }
            if (
              (newValue.environment && !oldValue.environment) ||
              (newValue.social && !oldValue.social)
            ) {
              newValue.noComposition = newValue.otherDistribution = newValue.noPreference = false;
            }
            if (newValue.otherDistribution && !oldValue.otherDistribution) {
              newValue.noComposition =
                newValue.environment =
                newValue.social =
                newValue.noPreference =
                  false;
            }
            if (newValue.noPreference && !oldValue.noPreference) {
              newValue.noComposition =
                newValue.environment =
                newValue.social =
                newValue.otherDistribution =
                  false;
            }
            if (!newValue.noComposition && oldValue.noComposition) {
              newValue.noCompositionPercent = null;
            }
            if (!newValue.environment && oldValue.environment) {
              newValue.environmentPercent = null;
            }
            if (!newValue.social && oldValue.social) {
              newValue.socialPercent = null;
            }
            if (!newValue.otherDistribution && oldValue.otherDistribution) {
              newValue.otherDistributionText = null;
            }
            if (!newValue.noPreference && oldValue.noPreference) {
              newValue.noPreferenceText = null;
            }
          },
          hidden: (surveyAnswers: SurveyAnswers[]) =>
            surveyAnswers.every(a => a.answers.esgPreference.answer === 'no'),
        },
        {
          id: 'esgBonds',
          question:
            'Mám zájem, aby se do základu portfolia pro minimální % udržitelnosti počítaly také státní dluhopisy?',
          moreDescription: esgBondsMoreDescription,
          answers: [
            {
              id: 'yes',
              text: 'Ano',
              score: 0,
            },
            {
              id: 'no',
              text: 'Ne',
              score: 0,
            },
          ],
          type: QuestionType.RADIO,
          lifeInsurance: true,
          investment: false,
          supplementaryPensionSavings: false,
          excludedFromAnalysis: true,
          investmentRequirements: false,
          hidden: (surveyAnswers: SurveyAnswers[]) =>
            surveyAnswers.every(a => a.answers.esgPreference.answer === 'no'),
        },
      ],
    };
  }

  // answers are yes/no/dontKnow
  private onlyOneAnswerSimpleTable(
    rows: string[],
    questionId: string,
  ): (currentValue: any, oldValues: Answers) => void {
    return (currentValue: any, oldValues: Answers): void => {
      if (!currentValue || !oldValues[questionId]) return;
      const oldAnswerValues = oldValues[questionId] as {[key: string]: {[key: string]: boolean}};
      rows.forEach(row => {
        const rowValues = currentValue[row];
        const oldRowValues = oldAnswerValues[row];
        if (
          oldRowValues &&
          rowValues.yes === oldRowValues.yes &&
          rowValues.no === oldRowValues.no &&
          rowValues.dontKnow === oldRowValues.dontKnow
        )
          return;
        if (rowValues.yes && (!oldRowValues || !oldRowValues.yes)) {
          rowValues.no = false;
          rowValues.dontKnow = false;
        } else if (rowValues.no && (!oldRowValues || !oldRowValues.no)) {
          rowValues.yes = false;
          rowValues.dontKnow = false;
        } else {
          rowValues.yes = false;
          rowValues.no = false;
        }
      });
    };
  }
}
