import {animate, style, transition, trigger} from '@angular/animations';
import {Component, Input} from '@angular/core';
import {MessageType, NotificationMessage} from '@lib/models';

export const enterAnimation = trigger('enterAnimation', [
  transition(':enter', [
    style({opacity: 0, transform: 'translateY(15px)'}),
    animate('100ms', style({opacity: 1, transform: 'translateY(0)'})),
  ]),
  transition(':leave', [animate('100ms', style({opacity: 0, transform: 'translateY(15px)'}))]),
]);

@Component({
  selector: 'kpt-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  animations: [enterAnimation],
})
export class NotificationCenterComponent {
  @Input() messages: NotificationMessage[];

  @Input() connectionInfo: NotificationMessage;

  MessageType = MessageType;

  messagesToShow = 3;

  get messagesQueue(): NotificationMessage {
    return {
      message: `Další upozornění: ${this.messages.length - this.messagesToShow}`,
      type: MessageType.QUEUE,
    };
  }

  dismiss(index: number) {
    this.messages.splice(index, 1);
  }

  dismissConnectionInfo() {
    this.connectionInfo = null;
  }

  refresh() {
    window.location.reload();
  }
}
