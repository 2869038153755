import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import {Router} from '@angular/router';
import {isRouterLink} from '@shared/utils';
import {includes, union} from 'lodash';

import {viewBreakpoints} from '../../constants/breakpoints.constant';
import {ITableColumnConfig, ITableColumnViewConfig} from './models/table.model';

@Component({
  selector: 'kpt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges, OnDestroy {
  @Input() cols: ITableColumnConfig[];
  @Input() rows: any[];

  @Input() caption?: string;
  @Input() mobileDetailCaption?: string;
  @Input() showHead?: boolean;
  @Input() tableClass?: string;
  @Input() rowSelectorFn?: (row: any) => boolean;
  @Input() isStatic = false;

  // where to switch between mobile and desktop layout
  @Input() mobileLayoutBreakpoint = 'md';
  // html template for row detail
  @Input() rowDetailTemplate?: TemplateRef<any>;
  // html template for action column
  @Input() actionColTemplate?: TemplateRef<any>;
  @Input() actionColClass?: string;
  // html custom template for caption
  @Input() customCaptionTemplate?: TemplateRef<any>;
  // html templates for individual columns
  @Input() columnTemplates?: Record<string, TemplateRef<any>>;

  @Input() highlightRowCondition: {condition: (row: any) => boolean};

  @Output() rowOpened: EventEmitter<any> = new EventEmitter<any>();

  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();

  isAdvanced = false;

  openedRow: any = null;
  selectedRow: any = null;
  totalCols = 0;
  mobileCols: ITableColumnConfig[] = [];

  // for performance reasons we calculate various classes on init just once
  desktopLayoutClasses: string[] = [];
  mobileLayoutClasses: string[] = [];

  constructor(public router: Router) {}

  ngOnDestroy(): void {}

  ngOnChanges() {
    this.isAdvanced = includes('table--advanced', this.tableClass);
    this.mobileCols = [];
    this.cols.forEach((column: ITableColumnConfig) => {
      column.views.forEach((view: ITableColumnViewConfig) => this.prepareViewClasses(view));
      if (column.mobileViews) {
        this.mobileCols.push(column);
        column.mobileViews.forEach((view: ITableColumnViewConfig) => this.prepareViewClasses(view));
      }
      this.totalCols += column.views.length;
    });
    this.desktopLayoutClasses = this.getLayoutClasses(false);
    this.mobileLayoutClasses = this.getLayoutClasses(true);

    if (this.rowSelectorFn) {
      this.selectedRow = this.rows.find(this.rowSelectorFn);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    const target: HTMLElement = event.target || event.srcElement;
    if (target.tagName.toLowerCase() === 'a') {
      const link: string = target.getAttribute('href');
      if (isRouterLink(link)) {
        event.preventDefault();
        window.scrollTo(0, 0);
        return this.router.navigate([link]);
      }
    }
  }

  openRow(row: any) {
    if (this.rowDetailTemplate && !this.isStatic) {
      this.openedRow = this.openedRow === row ? null : row;
      this.rowOpened.emit(this.openedRow);
    }
  }

  selectRow(row: any) {
    if (!this.isStatic) {
      this.selectedRow = row;
      this.rowSelected.emit(this.selectedRow);
    }
  }

  private getLayoutClasses(mobile: boolean): string[] {
    if (!this.mobileCols.length) {
      // we don't have mobile views
      return [];
    }

    const position = viewBreakpoints.indexOf(this.mobileLayoutBreakpoint);

    if (mobile) {
      return this.getDisplayClasses(viewBreakpoints.slice(0, position), 'table-row-group');
    } else {
      return this.getDisplayClasses(viewBreakpoints.slice(position), 'table-row-group');
    }
  }

  private prepareViewClasses(view: ITableColumnViewConfig) {
    if (view.hasOwnProperty('showIn')) {
      const displayClasses = this.getDisplayClasses(view.showIn, 'table-cell');
      view.headingClass = view.hasOwnProperty('headingClass')
        ? union(displayClasses, view.headingClass)
        : displayClasses;
      view.cellClass = view.hasOwnProperty('cellClass')
        ? union(displayClasses, view.cellClass)
        : displayClasses;
    }
  }

  private getDisplayClasses(showIn: string[], displayType: string): string[] {
    return viewBreakpoints.map(breakpoint => {
      if (showIn.includes(breakpoint)) {
        return breakpoint === 'xs' ? `d-${displayType}` : `d-${breakpoint}-${displayType}`;
      } else {
        return breakpoint === 'xs' ? 'd-none' : `d-${breakpoint}-none`;
      }
    });
  }
}
