import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {State} from 'src/store';
import {LoadFamilyMembersStart} from 'src/store/actions/family-members.actions';

@Injectable({providedIn: 'root'})
export class ClientGuard implements CanActivate {
  constructor(private store: Store<State>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const familyUuid = route.firstChild ? route.firstChild.params.familyId : null;
    if (!familyUuid) return this.router.parseUrl('/');
    this.store.dispatch(new LoadFamilyMembersStart({familyUuid}));

    return true;
  }
}
