import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {isNil} from 'lodash';

import {IEventSituationButtonConfig} from '../models/event-situation.deprecated.model';

@Component({
  selector: 'kpt-event-situation-button-deprecated',
  templateUrl: './event-situation-button.component.html',
  styleUrls: ['./event-situation-button.component.scss'],
})
export class EventSituationButtonDeprecatedComponent implements OnInit {
  @Input()
  config: IEventSituationButtonConfig;

  @Input()
  dataTransferData: string;

  @Output()
  eventAction: EventEmitter<IEventSituationButtonConfig> =
    new EventEmitter<IEventSituationButtonConfig>();

  @Output()
  dragActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('eventSituation', {static: true})
  eventSituation: ElementRef;

  private clonedElement: any;

  ngOnInit() {
    this.config.isDraggable =
      this.config && !isNil(this.config.isDraggable) ? this.config.isDraggable : true;
    this.config.isActive =
      this.config && !isNil(this.config.isActive) ? this.config.isActive : true;
  }

  dragStart = (event: any, config: any) => {
    if (config.isDraggable && config.isActive) {
      const target = event.srcElement || event.target;
      const originalRect = (
        this.eventSituation.nativeElement as HTMLElement
      ).getBoundingClientRect();
      this.clonedElement = target.cloneNode(true);
      this.clonedElement.style.width = `${originalRect.width}px`;
      this.clonedElement.style.height = `${originalRect.height}px`;
      this.clonedElement.style.position = 'absolute';
      this.clonedElement.style.left = '-9999px';
      event.dataTransfer.setData(this.dataTransferData, JSON.stringify(config));
      document.body.appendChild(this.clonedElement);
      event.dataTransfer.setDragImage(
        this.clonedElement,
        originalRect.width / 2,
        originalRect.height / 2,
      );
      this.dragActive.emit(true);
    }
  };

  dragEnd = (_event: any) => {
    this.dragActive.emit(false);
    this.clonedElement.parentNode.removeChild(this.clonedElement);
  };
}
