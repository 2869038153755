import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '@shared/directives';
import {CarouselTemplateDirective} from '@shared/directives/carousel-stepper/carousel-stepper-template.directive';
import {ButtonModule} from '@shared/ui';
import {CarouselStepComponent} from '@shared/ui/carousel-stepper/carousel-step/carousel-step.component';
import {CarouselStepperComponent} from '@shared/ui/carousel-stepper/carousel-stepper.component';

@NgModule({
  declarations: [CarouselStepperComponent, CarouselStepComponent, CarouselTemplateDirective],
  exports: [CarouselStepperComponent, CarouselStepComponent],
  imports: [CommonModule, DirectivesModule, ButtonModule, RouterModule],
})
export class CarouselStepperModule {}
