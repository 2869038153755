<div class="bar-chart">
  <div class="bar-chart__holder">
    <div class="bar-chart__col">
      <div class="bar-chart__col-inner">
        <div class="bar-chart__col-label">
          <p class="mb-0">
            <span class="h3 mb-0 font-weight-semibold bar-chart__col-number">25&nbsp;let</span>
            zkušeností
          </p>
        </div>
        <div class="bar-chart__col-bar bg--light-blue"></div>
      </div>
    </div>
    <div class="bar-chart__col">
      <div class="bar-chart__col-inner">
        <div class="bar-chart__col-label">
          <p class="mb-0">
            <span class="h3 mb-0 font-weight-semibold bar-chart__col-number">1&nbsp;500</span>
            poradců
          </p>
        </div>
        <div class="bar-chart__col-bar bg--green-100"></div>
      </div>
    </div>
    <div class="bar-chart__col">
      <div class="bar-chart__col-inner">
        <div class="bar-chart__col-label">
          <p class="mb-0">
            <span class="h3 mb-0 font-weight-semibold bar-chart__col-number"
              >1&nbsp;050&nbsp;000</span
            >
            klientů
          </p>
        </div>
        <div class="bar-chart__col-bar bg--green-200"></div>
      </div>
    </div>
  </div>
</div>
