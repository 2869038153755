import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from '@lib/components';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {SingleRadioButtonComponent} from '@shared/ui/forms/single-radio-button/single-radio-button.component';
import {CheckboxModule} from './checkbox/checkbox.module';
import {CommonFieldStatusesModule} from './common-field/common-field-statuses.module';
import {CommonFieldModule} from './common-field/common-field.module';
import {ConfirmFieldModule} from './confirm-field/confirm-field.module';
import {InputModule} from './input/input.module';
import {LabelComponent} from './label/label.component';
import {RadioGroupModule} from './radio-group/radio-group.module';
import {SelectModule} from './select';
import {SimpleAssetValueInputModule} from './simple-asset-value-input/simple-asset-value-input.module';
import {ToggleModule} from './toggle/toggle.module';

@NgModule({
  declarations: [SingleRadioButtonComponent, LabelComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    TooltipModule,
    RadioGroupModule,
    ToggleModule,
    InputModule,
    SimpleAssetValueInputModule,
    CommonFieldStatusesModule,
    CheckboxModule,
    CommonFieldModule,
    ConfirmFieldModule,
    BackendValidationModule,
  ],
  exports: [
    SingleRadioButtonComponent,
    SelectModule,
    LabelComponent,
    RadioGroupModule,
    ToggleModule,
    InputModule,
    SimpleAssetValueInputModule,
    CommonFieldStatusesModule,
    CheckboxModule,
    CommonFieldModule,
    ConfirmFieldModule,
    BackendValidationModule,
  ],
})
export class FormModule {}
