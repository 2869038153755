import {createFeatureSelector, createSelector} from '@ngrx/store';
import {reject} from 'lodash';
import {matchingProductFilter} from '../where-to-invest/where-to-invest.utils';
import {InvestmentState} from './investment.state';

export const storeFeatureName = 'investment';

export const selectState = createFeatureSelector<InvestmentState>(storeFeatureName);

export const selectInvestmentBaseData = createSelector(selectState, state => state.baseData);

export const selectInvestmentData = createSelector(selectState, state => state.data);

export const selectReturnOnInvestment = createSelector(
  selectInvestmentData,
  state => state && state.returnOnInvestment,
);

export const selectWhereToInvest = createSelector(
  selectInvestmentData,
  state => state && state.whereToInvest,
);

export const selectProducts = createSelector(selectState, state => state.products);

export const selectMatchingProducts = createSelector(
  selectWhereToInvest,
  selectProducts,
  (whereToInvest, products) =>
    products ? products.filter(matchingProductFilter(whereToInvest)) : null,
);

export const selectOtherProducts = createSelector(
  selectWhereToInvest,
  selectProducts,
  (whereToInvest, products) =>
    products ? reject(products, matchingProductFilter(whereToInvest)) : null,
);

export const selectSummary = createSelector(selectState, state => state.summary);

export const selectCurrentInvestment = createSelector(
  selectInvestmentData,
  state => state && state.currentInvestment,
);
