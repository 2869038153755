<div class="modal-header">
  <div class="row">
    <div class="col-12">
      <h3 class="modal-title">
        {{ title }}
      </h3>
    </div>
  </div>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="insert-birth-number">
    <kpt-input
      class="birth-number"
      label="Rodné číslo"
      subtext="(pro převzetí zadejte rodné číslo klienta)"
      [formControl]="form.get('birthNumber')"
    >
    </kpt-input>
  </div>
  <div class="invalid-feedback" *ngIf="wrongBirthNumber">
    Nesprávné rodné číslo. Zadejte prosím jiné.
  </div>
  <div class="d-flex justify-content-center">
    <kpt-button type="primary" (action)="reassign()">Převzít</kpt-button>
  </div>
</div>
