import {Component, Input} from '@angular/core';
import {Scenario} from '@lib/services/intro-js.config';
import {IntroJsService} from '@lib/services/intro-js.service';

@Component({
  selector: 'kpt-intro-js',
  templateUrl: './intro-js.component.html',
  styleUrls: ['./intro-js.component.scss'],
})
export class IntroJsComponent {
  @Input()
  scenario: Scenario;

  constructor(private introJsService: IntroJsService) {}

  showIntro() {
    this.introJsService.showIntro(this.scenario);
  }
}
