import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {RisksSelectionModule} from '@shared/analysis/components/risks-selection/risks-selection.module';
import {InputModule} from '@shared/ui/forms/input/input.module';
import {map} from 'rxjs/operators';
import {LifeInsuranceService} from 'src/app/modules/life-insurance/life-insurance.service';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-family-provision-summary',
  templateUrl: './family-provision-summary.component.html',
  imports: [CommonModule, InputModule, RisksSelectionModule, AssetFormComponent],
})
export class FamilyProvisionSummaryComponent {
  persons$ = this.lifeInsuranceService.persons$.pipe(untilDestroyed(this));
  familyProvision$ = this.lifeInsuranceService.objectives$.pipe(
    map(objectives => objectives[0]?.objectiveAsset),
    untilDestroyed(this),
  );

  constructor(private lifeInsuranceService: LifeInsuranceService) {}
}
