<kpt-modal-wrapper (close)="dismiss()">
  <ng-container *ngIf="!bothSituations">
    <kpt-add-existing-asset
      *ngIf="objectiveType !== ObjectiveType.AdditionalContracts"
      [objectiveType]="objectiveType"
      [relatedObjectiveUuid]="relatedObjectiveUuid"
      [relatedPropertyUuid]="relatedPropertyUuid"
      [stakeholderUuid]="stakeholderUuid"
      [situation]="situation"
      (addAsset)="addExistingAsset($event)"
    >
    </kpt-add-existing-asset>

    <kpt-add-new-asset
      [objectiveType]="objectiveType"
      [assetType]="assetType"
      [situation]="situation"
      (addAsset)="addNewAsset($event, situation)"
    >
    </kpt-add-new-asset>
  </ng-container>

  <ng-container *ngIf="bothSituations">
    <kpt-add-new-asset
      class="mb-4"
      [objectiveType]="objectiveType"
      [assetType]="assetType"
      [situation]="Situation.Current"
      (addAsset)="addNewAsset($event, Situation.Current)"
    >
    </kpt-add-new-asset>

    <kpt-add-new-asset
      [objectiveType]="objectiveType"
      [assetType]="assetType"
      [situation]="Situation.AdvisorProposed"
      (addAsset)="addNewAsset($event, Situation.AdvisorProposed)"
    >
    </kpt-add-new-asset>
  </ng-container>
</kpt-modal-wrapper>
