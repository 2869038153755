/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface GetERecordDataParams {
  family_uuid: string;
}

export interface CreateUpdateERecordDataParams {
  data: __model.Summary[];
  family_uuid: string;
}

export interface GetLifeInsuranceDataParams {
  family_uuid: string;
}

export interface CreateUpdateLifeInsuranceDataParams {
  data: __model.LifeInsuranceData;
  family_uuid: string;
}

export interface GetAdditionalFinancialAnalysisParams {
  family_uuid: string;
}

export interface CreateUpdateAdditionalFinancialAnalysisParams {
  data: __model.AdditionalFinancialAnalysis;
  family_uuid: string;
}

export interface HasSignedParams {
  data: __model.IsSigned;
  family_uuid: string;
}

export interface GetInvestmentQuestionnaireParams {
  family_uuid: string;
}

export interface CreateUpdateInvestmentQuestionnaireParams {
  data: __model.InvestmentQuestionnaire[];
  family_uuid: string;
}

export interface GetPriorityQuestionnaireParams {
  data: __model.ReadPriorityQuestionnaire;
  family_uuid: string;
}

export interface CreateUpdatePriorityQuestionnaireParams {
  data: __model.CreateUpdatePriorityQuestionnaire[];
  family_uuid: string;
}

@Injectable()
export class LifeinsuranceService {
  constructor(private http: HttpClient) {}

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/getERecordData */
  getERecordData(params: GetERecordDataParams): Observable<__model.Summary[]> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.Summary[]>(`/api/lifeinsurance/cru-erecord/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/createUpdateERecordData */
  createUpdateERecordData(params: CreateUpdateERecordDataParams): Observable<__model.Summary[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.put<__model.Summary[]>(`/api/lifeinsurance/cru-erecord/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/getLifeInsuranceData */
  getLifeInsuranceData(params: GetLifeInsuranceDataParams): Observable<__model.LifeInsuranceData> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.LifeInsuranceData>(`/api/lifeinsurance/data/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/createUpdateLifeInsuranceData */
  createUpdateLifeInsuranceData(params: CreateUpdateLifeInsuranceDataParams): Observable<__model.LifeInsuranceData> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.put<__model.LifeInsuranceData>(`/api/lifeinsurance/data/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/getAdditionalFinancialAnalysis */
  getAdditionalFinancialAnalysis(params: GetAdditionalFinancialAnalysisParams): Observable<__model.AdditionalFinancialAnalysis> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.AdditionalFinancialAnalysis>(`/api/lifeinsurance/financial-analysis/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/createUpdateAdditionalFinancialAnalysis */
  createUpdateAdditionalFinancialAnalysis(params: CreateUpdateAdditionalFinancialAnalysisParams): Observable<__model.AdditionalFinancialAnalysis> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.AdditionalFinancialAnalysis>(`/api/lifeinsurance/financial-analysis/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/lifeinsurance_has-signed_create */
  hasSigned(params: HasSignedParams): Observable<__model.HasSigned> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.HasSigned>(`/api/lifeinsurance/has-signed/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/getInvestmentQuestionnaire */
  getInvestmentQuestionnaire(params: GetInvestmentQuestionnaireParams): Observable<__model.InvestmentQuestionnaire[]> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.InvestmentQuestionnaire[]>(`/api/lifeinsurance/investment-questionnaire/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/createUpdateInvestmentQuestionnaire */
  createUpdateInvestmentQuestionnaire(params: CreateUpdateInvestmentQuestionnaireParams): Observable<__model.InvestmentQuestionnaire[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.put<__model.InvestmentQuestionnaire[]>(`/api/lifeinsurance/investment-questionnaire/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/getPriorityQuestionnaire */
  getPriorityQuestionnaire(params: GetPriorityQuestionnaireParams): Observable<__model.PriorityQuestionnaireSerializerAnswer[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.PriorityQuestionnaireSerializerAnswer[]>(`/api/lifeinsurance/priority-questionnaire/${pathParams.family_uuid}/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/lifeinsurance/createUpdatePriorityQuestionnaire */
  createUpdatePriorityQuestionnaire(params: CreateUpdatePriorityQuestionnaireParams): Observable<__model.CreateUpdatePriorityQuestionnaire[]> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.put<__model.CreateUpdatePriorityQuestionnaire[]>(`/api/lifeinsurance/priority-questionnaire/${pathParams.family_uuid}/`, bodyParams || {});
  }
}
