import {Injectable} from '@angular/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from 'src/shared/ui/formly/formly.enums';
import {getBasicField} from 'src/shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {monthValueChange, yearValueChange} from './common-expenses.helpers';
import {ContractorDefinitions} from './contractor.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class CommonExpenses {
  food: AssetDefinition = {
    name: assetNames[AssetType.Food],
    type: AssetType.Food,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(),
      this.abstractAssets.baseAssetFields.note,
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'food', FieldLabel.FOOD),
        getBasicField(FormlyTypes.Number, 'lunch', FieldLabel.LUNCH),
        getBasicField(FormlyTypes.Number, 'dinner', FieldLabel.DINNER),
        getBasicField(FormlyTypes.Number, 'other', FieldLabel.OTHER),
      ]),
    ],
    validators: getAssetValidators(AssetType.Food),
    model: {},
  };

  housingExpenses: AssetDefinition = {
    name: assetNames[AssetType.HousingExpenses],
    tooltip: 'nájem/splátky hypotéky, inkaso, energie, aj.',
    type: AssetType.HousingExpenses,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(),
      getBasicField(FormlyTypes.Text, 'name', FieldLabel.NAME),
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'rent', FieldLabel.RENT),
        getBasicField(FormlyTypes.Number, 'water', FieldLabel.WATER),
        getBasicField(FormlyTypes.Number, 'gas', FieldLabel.GAS),
        getBasicField(FormlyTypes.Number, 'electricity', FieldLabel.ELECTRICITY),
        getBasicField(FormlyTypes.Number, 'heating', FieldLabel.HEATING),
        getBasicField(FormlyTypes.Number, 'repairsFund', FieldLabel.REPAIRS_FUND),
        getBasicField(FormlyTypes.Number, 'other', FieldLabel.OTHER),
      ]),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.HousingExpenses),
    model: {},
  };

  transport: AssetDefinition = {
    name: assetNames[AssetType.Transport],
    type: AssetType.Transport,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(),
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'fuel', FieldLabel.FUEL),
        getBasicField(FormlyTypes.Number, 'carService', FieldLabel.CAR_SERVICE),
        getBasicField(FormlyTypes.Number, 'publicTransport', FieldLabel.PUBLIC_TRANSPORT),
        getBasicField(FormlyTypes.Number, 'taxi', FieldLabel.TAXI),
        getBasicField(FormlyTypes.Number, 'other', FieldLabel.OTHER),
      ]),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Transport),
    model: {},
  };

  drugstore: AssetDefinition = {
    name: assetNames[AssetType.Drugstore],
    type: AssetType.Drugstore,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(),
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'basicDrugstore', FieldLabel.BASIC_DRUGSTORE),
        getBasicField(FormlyTypes.Number, 'childrenHygiene', FieldLabel.CHILDREN_HYGIENE),
        getBasicField(FormlyTypes.Number, 'drugs', FieldLabel.DRUGS),
        getBasicField(FormlyTypes.Number, 'other', FieldLabel.OTHER),
      ]),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Drugstore),
    model: {},
  };

  clothes: AssetDefinition = {
    name: assetNames[AssetType.Clothes],
    type: AssetType.Clothes,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(false),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Clothes),
    model: {},
  };

  communication: AssetDefinition = {
    name: assetNames[AssetType.Communication],
    type: AssetType.Communication,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(false),
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'internet', FieldLabel.INTERNET),
        getBasicField(FormlyTypes.Number, 'television', FieldLabel.TELEVISION),
        getBasicField(FormlyTypes.Number, 'phone', FieldLabel.PHONE),
      ]),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Communication),
    model: {},
  };

  hobbies: AssetDefinition = {
    name: assetNames[AssetType.Hobbies],
    type: AssetType.Hobbies,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(false),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Hobbies),
    model: {},
  };

  vacation: AssetDefinition = {
    name: assetNames[AssetType.Vacations],
    type: AssetType.Vacations,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(false, monthValueChange),
      getBasicField(FormlyTypes.Number, 'yearValue', FieldLabel.YEARLY_VALUE, {
        change: yearValueChange,
      }),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.Vacations),
    model: {},
  };

  childrenExpenses: AssetDefinition = {
    name: assetNames[AssetType.ChildrenExpenses],
    type: AssetType.ChildrenExpenses,
    fields: [
      getBasicField(FormlyTypes.FamilyMemberSelect, 'familyMemberUuid', 'Dítě', {
        onlyChildren: true,
      }),
      ...this.abstractAssets.getCommonExpenseFields(false),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.ChildrenExpenses),
    model: {},
  };

  otherCommonExpenses: AssetDefinition = {
    name: assetNames[AssetType.OtherCommonExpenses],
    type: AssetType.OtherCommonExpenses,
    fields: [
      ...this.abstractAssets.getCommonExpenseFields(),
      this.abstractAssets.getInputModeField([
        getBasicField(FormlyTypes.Number, 'entertainment', FieldLabel.ENTERTAINMENT),
        getBasicField(FormlyTypes.Number, 'alimony', FieldLabel.ALIMONY),
        getBasicField(FormlyTypes.Number, 'cigarettes', FieldLabel.CIGARETTES),
        getBasicField(FormlyTypes.Number, 'alcohol', FieldLabel.ALCOHOL),
        getBasicField(FormlyTypes.Number, 'presents', FieldLabel.PRESENTS),
        getBasicField(FormlyTypes.Number, 'other', FieldLabel.OTHER),
      ]),
      this.abstractAssets.baseAssetFields.note,
    ],
    validators: getAssetValidators(AssetType.OtherCommonExpenses),
    model: {},
  };

  contractorInsurance: AssetDefinition = {
    ...this.contractor.getContractor(),
    name: assetNames[AssetType.ContractorInsurance],
  };

  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private contractor: ContractorDefinitions,
  ) {}

  getAll() {
    return [
      this.food,
      this.housingExpenses,
      this.transport,
      this.drugstore,
      this.clothes,
      this.communication,
      this.hobbies,
      this.vacation,
      this.childrenExpenses,
      this.otherCommonExpenses,
      this.contractorInsurance,
    ];
  }
}
