import {
  AssetType,
  BaseAsset,
  DateAsset,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';

export interface InsuranceProductsSumAsset extends BaseAsset, ValueAsset, DateAsset {
  type: AssetType.InsuranceProductsSum;
}

export interface InsuredPerson {
  insuredPersonUuid: string;
  // Tab pojistné částky
  /** Pracovní neschopnost; currency */
  incapacity: number;
  /** Denní odškodné; currency */
  dailyCompensation: number;
  /** Tělesné poškození; currency */
  physicalDamage: number;
  /** Hospitalizace; currency */
  hospitalization: number;
  /** Vážná onemocnění; currency */
  seriousDisease: number;
  /** Trvalé následky úrazu; currency */
  permanentDisability: number;
  /** Invalidita; currency */
  invalidity: number;
  /** Invalidita &ndash; splacení úvěru; currency */
  invalidityDebtRepayment: number;
  /** Smrt &ndash; splacení úvěru; currency */
  deathDebtRepayment: number;
  /** Smrt &ndash; zajištění rodiny 3 roční příjmy; currency */
  deathThriceAnnualIncome: number;
  /** Smrt &ndash; zajištění dětí; currency */
  deathChildrenEnsurement: number;
  /** Denní odškodné; currency */
  childDailyCompensation: number;
  /** Tělesné poškození; currency */
  childPhysicalDamage: number;
  /** Hospitalizace; currency */
  childHospitalization: number;
  /** Vážná onemocnění; currency */
  childSeriousDisease: number;
  /** Trvalé následky úrazu; currency */
  childPermanentDisability: number;
  /** Dožití; currency */
  childSurvival: number;
  /** Jiné riziko */
  otherRisks: OtherRisk[];
}

export interface LifeInsuranceAsset extends CommonFinancialProductAsset, RelatedObjectiveAsset {
  // Tab Osoby
  /** Pojištění */
  insuredPersons: InsuredPerson[];

  /** Předpokládaná hodnota účtu currency */
  futureValue: number;
  /** Předpokládaná hodnota účtu ke dni */
  futureValueDate: string;
}

export interface OtherRisk {
  /** Riziko */
  label: string;
  /** Částka; currency */
  value: number;
}

export interface CommonPropertyInsuranceParticipation {
  /** Spoluúčast; percents, from 0 to 1 */
  percentParticipation: number;
  /** Typ spoluúčasti */
  participationType: ParticipationType;
  /** Spoluúčast; currency */
  participationValue: number;
}

export interface PropertyInsuranceParticipation extends CommonPropertyInsuranceParticipation {
  /** Místo pojištění */
  placeOfInsurance: string;
  /** Varianta produktu/balíček */
  insuranceType: string;
}

export interface OtherPropertyInsuranceParticipation extends PropertyInsuranceParticipation {
  /** Název */
  label: string;
}

export interface InvestmentLifeInsuranceAsset extends LifeInsuranceAsset {
  type: AssetType.InvestmentLifeInsurance;
  /** Současná hodnota investovaných prostředků; currency */
  presentValue: number;
  /** Současná hodnota platná ke dni */
  presentValueDate: string;
  /** Úroková sazba */
  yearlyRate: number;
  // Tab Poznámky a přílohy
  /** Příspěvek zaměstnavatele; currency */
  employerContribution: number;
  /** Název zaměstnavatele */
  employerName: string;
  /** IČO zaměstnavatele */
  employerID: number;
  /** Kontaktní osoby zaměstnavatele */
  employerContact: string;
}

export interface RiskLifeInsuranceAsset extends LifeInsuranceAsset {
  type: AssetType.RiskLifeInsurance;
  /** Současná hodnota investovaných prostředků; currency */
  presentValue: number;
  /** Současná hodnota platná ke dni */
  presentValueDate: string;
}

export interface CapitalLifeInsuranceAsset extends LifeInsuranceAsset {
  type: AssetType.CapitalLifeInsurance;
  /** Současná hodnota investovaných prostředků; currency */
  presentValue: number;
  /** Současná hodnota platná ke dni */
  presentValueDate: string;
  /** Úroková sazba */
  yearlyRate: number;
  /** Příspěvek zaměstnavatele; currency */
  employerContribution: number;
}

export interface PropertyInsuranceAsset
  extends CommonFinancialProductAsset,
    RelatedObjectiveAsset,
    RelatedPropertyAsset,
    PropertyInsuranceParticipation {
  type: AssetType.PropertyInsurance;
  // Tab Detaily smlouvy
  /** Nemovitost - pojištná částka; currency */
  requiredInsuredAmount: number;
  /** Domácnost  */
  household: number;
  /**  Odpovědnost z držby */
  holdingInsurance: number;
  /**  Odpovědnost v běžném občanském životě */
  civilLiability: number;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field
  /** Další spoluúčast */
  otherInsuranceParticipations: OtherPropertyInsuranceParticipation[];
  /** Jiné riziko */
  otherRisks: OtherRisk[];
}

export enum ParticipationType {
  /** Min. */
  Min = 'min',
  /** Fixní */
  Fix = 'fix',
}

export interface LiabilityInsuranceAsset
  extends CommonFinancialProductAsset,
    CommonPropertyInsuranceParticipation {
  type: AssetType.LiabilityInsurance;
  // Tab Detaily smlouvy
  /** Povolání */
  profession: string;
  /** Riziková skupina */
  riskGroup: LiabilityInsuranceRiskGroup;
  /** Rozsah pojištění */
  scope: LiabilityInsuranceScope;
  /** Územní platnost */
  region: LiabilityInsuranceRegion;
  /** Limit pojistného plnění; currency */
  limit: number;
}

export enum LiabilityInsuranceRiskGroup {
  /** řidič z povolání */
  ProfessionalDriver = 'professionalDriver',
  /** ostatní */
  Other = 'other',
}

export enum LiabilityInsuranceScope {
  /** řidič */
  Driver = 'driver',
  /** neřidič */
  NonDriver = 'nonDriver',
}

export enum LiabilityInsuranceRegion {
  /** ČR */
  CZ = 'cz',
  /** Evropa */
  Europe = 'europe',
  /** celý svět */
  Global = 'global',
}

export interface VehicleInsuranceAsset
  extends CommonFinancialProductAsset,
    CommonPropertyInsuranceParticipation,
    RelatedObjectiveAsset,
    RelatedPropertyAsset {
  type: AssetType.VehicleInsurance;
  // Tab Detaily smlouvy
  /** Připojištění */
  vehicleSupplementaryInsurance: VehicleSupplementaryInsurance;
  /** Havarijní pojištění */
  vehicleAccidentInsurance: VehicleAccidentInsurance;
  /** Spoluúčast; percents, from 0 to 1 */
  participation: number;
  /** Pojištění POV zdraví v mil; currency */
  compulsoryInsuranceHealthInMil: number;
  /** Pojištění POV majetek v mil; currency */
  compulsoryInsurancePropertyInMil: number;
  /** Pojistná částka HAV; currency */
  accidentInsuranceTotal: number;
  /** Pojistník */
  policyholder: boolean;
  /** Držitel */
  holder: boolean;
  /** Vlastník */
  owner: boolean;
  // tab has `relatedObjectiveUuid` field
  // tab has `relatedPropertyUuid` field
  /** Jiné riziko */
  otherRisks: OtherRisk[];
}

export interface VehicleSupplementaryInsurance {
  /** Čelní sklo */
  windshield?: number;
  /** Všechna výhledová skla */
  allWindows?: number;
  /** Zavazadla */
  baggage?: number;
  /** Pomoc při nehodě */
  accidentAssistance?: number;
  /** Právní ochrana */
  legalProtection?: number;
  /** Poškození vozidla zvířetem */
  animalDamage?: number;
  /** Vandalismus */
  vandalism?: number;
  /** Živelné pohromy */
  naturalDisasters?: number;
  /** Pojištění nezaviněné nehody */
  unintentionalAccidentInsurance?: number;
  /** Náhradní vozidlo */
  replacementVehicle?: number;
  /** Nestandardní výbava */
  nonStandardEquipment?: number;
  /** Odcizení */
  theft?: number;
}

export interface VehicleAccidentInsurance {
  /** Alarm */
  alarm?: boolean;
  /** Imobilizér */
  immobilizer?: boolean;
  /** Mechanické zabezpečení */
  mechanicalSecurity?: boolean;
  /** Aktivní vyhledávací systém */
  activeSearchSystem?: boolean;
  /** VIN na skle */
  vinOnGlass?: boolean;
}

export interface TravelInsuranceAsset extends CommonFinancialProductAsset {
  type: AssetType.TravelInsurance;
  // Tab Osoby
  /** Pojištění */
  insuredPersonUuids: string[];
  /** Pojištění léčebných výloh */
  medicalExpensesInsurance: number;
  /** Pojištění ÚZO (úraz, zavazadla, odpovědnost) */
  insuranceUzo: number;
  /** Územní rozsah */
  territorialScope: string;
  /** Rizikové sporty */
  riskSports: boolean;
  /** Upřesnění rizikových sportů */
  riskSportsSpecification: string;
  /** Jiné riziko */
  otherRisks: OtherRisk[];
}

export interface BusinessInsuranceAsset
  extends CommonFinancialProductAsset,
    CommonPropertyInsuranceParticipation {
  type: AssetType.BusinessInsurance;
  /** Jiné riziko */
  otherRisks: OtherRisk[];
}

export type InsuranceAsset =
  | InvestmentLifeInsuranceAsset
  | RiskLifeInsuranceAsset
  | CapitalLifeInsuranceAsset
  | PropertyInsuranceAsset
  | LiabilityInsuranceAsset
  | VehicleInsuranceAsset
  | TravelInsuranceAsset
  | BusinessInsuranceAsset;
