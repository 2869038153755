import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FAMILY_ID} from '@shared/constants';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {finalize, switchMap, take} from 'rxjs/operators';
import {
  selectContracts,
  selectFamily,
  selectFinances,
  selectPlans,
  selectProperties,
} from 'src/app/modules/client/summary/store';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {PdfService} from 'src/app/services/pdf.service';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@UntilDestroy()
@Component({
  selector: 'kpt-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  familySummary = this.store.pipe(select(selectFamily), untilDestroyed(this));
  plans = this.store.pipe(select(selectPlans), untilDestroyed(this));
  finances = this.store.pipe(select(selectFinances), untilDestroyed(this));
  properties = this.store.pipe(select(selectProperties), untilDestroyed(this));
  contracts = this.store.pipe(select(selectContracts), untilDestroyed(this));

  isPrinting = false;

  constructor(
    private store: Store<State>,
    private pdfService: PdfService,
    private lifeInsuranceDataService: LifeInsuranceDataService,
    public featureFlagsService: FeatureFlagsService,
    @Inject(FAMILY_ID) private familyUuid: string,
  ) {}

  ngOnInit() {
    this.lifeInsuranceDataService.loadData(this.familyUuid);
  }

  print() {
    if (environment.name === 'local') {
      window.print();
      return;
    }
    this.isPrinting = true;
    this.store
      .pipe(
        select(getFamilyUuid),
        switchMap(familyUuid =>
          this.pdfService.print$(
            familyUuid,
            `secured/client/${familyUuid}/summary`,
            'CS',
            'Záznam z jednání.pdf',
          ),
        ),
        finalize(() => (this.isPrinting = false)),
        take(1),
      )
      .subscribe(pdfUrl => this.pdfService.openPdf(pdfUrl));
  }
}
