import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule, DatepickerModule, FormModule, PhoneNumberModule} from '@shared/ui';
import {ClientSearchCreateFormComponent} from './client-search-create-form.component';

@NgModule({
  declarations: [ClientSearchCreateFormComponent],
  imports: [
    ButtonModule,
    CommonModule,
    DatepickerModule,
    PhoneNumberModule,
    ReactiveFormsModule,
    FormModule,
  ],
  exports: [ClientSearchCreateFormComponent],
})
export class ClientSearchCreateFormModule {}
