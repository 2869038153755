import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CategoryType} from 'src/app/modules/client/summary/store/summary.models';

@Component({
  selector: 'kpt-category-type',
  templateUrl: './category-type.component.html',
  styleUrls: ['./category-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTypeComponent implements OnInit {
  @Input() categoryType: CategoryType;

  constructor() {}

  ngOnInit() {}
}
