export interface Answers {
  [key: string]: Answer | {[key: string]: boolean} | {[key: string]: {[key: string]: boolean}};
}

export interface Answer {
  answer: string;
}

export const emptyAnswers = (): Answers => ({
  knowledgeInv: {
    risk: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    diversification: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    lengthInvestment: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    returnsInvestment: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    mutualFund: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    mutualFundManagement: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    realEstateFundsInvestments: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    bondsRisk: {
      yes: false,
      no: false,
      dontKnow: false,
    },
  },
  knowledgeLi: {
    risk: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    portfolioDiversification: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    shares: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    bonds: {
      yes: false,
      no: false,
      dontKnow: false,
    },
  },
  knowledgeSps: {
    risk: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    financialInstruments: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    unitCertificate: {
      yes: false,
      no: false,
      dontKnow: false,
    },
    bonds: {
      yes: false,
      no: false,
      dontKnow: false,
    },
  },
  financialMarketNews: {answer: null},
  education: {answer: null},
  experience: {
    nothing: false,
    oneTime: false,
    periodic: false,
    continuousEditingPortfolio: false,
    shortTermTransactions: false,
  },
  transactions: {
    money: {
      never: false,
      lastThreeYears: false,
      moreThanHundred: false,
    },
    equity: {
      never: false,
      lastThreeYears: false,
      moreThanHundred: false,
    },
    shares: {
      never: false,
      lastThreeYears: false,
      moreThanHundred: false,
    },
  },
  risk: {answer: null},
  graph: {answer: null},
  lossOfInvestment: {answer: null},
  investmentDurationInv: {answer: null},
  investmentDurationLi: {answer: null},
  investmentDurationSps: {answer: null},
  currentInvestedFinances: {
    savingAccounts: false,
    anotherBroker: false,
    buildingSavings: false,
    supplementaryPensionSavings: false,
    investmentLifeInsurance: false,
  },
  investmentClientEligibility: {answer: null},
  desiredInvestments: {
    mutualFunds: false,
    bonds: false,
    realEstateFunds: false,
    other: false,
    otherText: null,
    doNotKnow: false,
  },
  diversification: {answer: null},
  frequency: {
    oneTime: false,
    regular: false,
    irregular: false,
    noPreference: false,
  },
  regularBuyout: {
    answer: null,
    regularBuyoutAmount: null,
    regularBuyoutFrequency: null,
  },
  investmentPurpose: {
    shortTermKnownIntention: false,
    longTermKnownIntention: false,
    shortTermNoIntention: false,
    longTermNoIntention: false,
    speculation: false,
    alternativeIncome: false,
    portfolioDiversification: false,
    personalMotivation: false,
    other: false,
    otherText: null,
  },
  esgPreference: {
    answer: null,
  },
  esgObjectives: {
    lightGreen: null,
    darkGreen: null,
  },
  esgDistribution: {
    noComposition: null,
    noCompositionPercent: null,
    environment: null,
    environmentPercent: null,
    social: null,
    socialPercent: null,
    otherDistribution: null,
    otherDistributionText: null,
    noPreference: null,
    noPreferenceText: null,
  },
  esgBonds: {
    answer: null,
  },
});
