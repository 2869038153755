import {Injectable} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {convertToMonthly, requiresAttention} from '@shared/analysis/asset.utils';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {
  AssetPageStateValue,
  PageTemplateAsset,
  PageTemplateSection,
  RichAsset,
} from '@shared/analysis/assets-page.models';
import {SectionDefinition} from '@shared/analysis/forms/definitions';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {
  existsValueAppearanceFunction,
  valueAppearanceFunctions,
} from '@shared/analysis/forms/value-appearance.definitions';
import {InputModeAsset} from '@shared/analysis/models/common-expenses';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {has} from 'lodash';
import {Observable, Subject} from 'rxjs';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {
  Asset,
  BaseAsset,
  NameAsset,
  RegularPaymentType,
  ValueAsset,
} from 'src/shared/analysis/models/asset';
import {FASection} from 'src/shared/analysis/models/section';
import {State} from 'src/store';

const valueByAssetTypeAndSection = (asset: Asset, section: FASection): number => {
  if (existsValueAppearanceFunction(asset.type, section)) {
    return valueAppearanceFunctions[asset.type][section](asset);
  }
  if (isConversionToMonthlyRequired(asset)) {
    return convertToMonthly(
      (asset as ValueAsset).value,
      (asset as CommonFinancialProductAsset).frequency,
    );
  }
  return (asset as ValueAsset).value;
};

export const isConversionToMonthlyRequired = (asset: Asset): boolean => {
  return (
    has(asset, 'frequency') &&
    (asset as CommonFinancialProductAsset).frequency !== RegularPaymentType.Month
  );
};

@Injectable()
export class AssetsPageService {
  valueChange$ = new Subject<RichAsset>();

  constructor(
    private store: Store<State>,
    private assetsService: AssetsHandlerService,
    private assetHelper: AssetHelperService,
  ) {}

  getData(selector: any): Observable<Record<string, AssetPageStateValue>> {
    return this.store.select(selector).pipe(
      filter(data => Boolean(data)),
      tap(() => this.assetsService.persistingAsset$.next(null)),
    );
  }

  getValueChange() {
    return this.valueChange$.pipe(
      debounceTime(500), // (pk) This debounceTime is used in finances.spec.ts! Consider that when making changes!
      tap(assetData => {
        //    Make it as const and import is not possible at the moment.
        this.assetsService.persistingAsset$.next(assetData.type);
      }),
      map(
        assetData =>
          ({type: assetData.type, ...assetData.asset, value: assetData.value} as
            | BaseAsset
            | ValueAsset),
      ),
    );
  }

  fillData(
    definitions: SectionDefinition<FASection>[],
    storeData: Record<string, AssetPageStateValue>,
  ): PageTemplateSection[] {
    return definitions.map(definition => {
      return {
        ...this.getAssetsPageSection(definition, storeData[definition.key]),
      };
    }) as PageTemplateSection[];
  }

  private getAssetsPageSection(
    {key, definitions, sumAssetType, type}: SectionDefinition,
    storeValue: AssetPageStateValue,
  ): PageTemplateSection {
    const assets = definitions.reduce<PageTemplateAsset[]>((acc, definition) => {
      const storeAssets = storeValue.assets.filter(
        (asset: Asset) => asset.type === definition.type,
      );

      if (storeAssets.length) {
        storeAssets.forEach((stateAsset: Asset) => {
          acc.push(this.preparePageTemplateAsset(stateAsset, key, definition));
        });
      } else {
        acc.push({
          ...definition,
          valueFormControl: new UntypedFormControl(),
        });
      }

      return acc;
    }, []);

    (storeValue.excludedAssetsFromAnalysis ?? []).forEach(asset => {
      const definition = definitions.find(d => d.type === asset.type);
      assets.push({
        ...this.preparePageTemplateAsset(asset, key, definition),
        excludedFromAnalysis: true,
      });
    });

    const sumFormControl = new UntypedFormControl();
    if (storeValue.sumAsset) {
      sumFormControl.patchValue((storeValue.sumAsset as ValueAsset).value);
    } else {
      sumFormControl.patchValue(Math.round(storeValue.sum));
    }

    return {
      assets,
      title: storeValue.title,
      key,
      type,
      sum: {
        editable: storeValue.sumEditable,
        value: storeValue.sum,
        assetType: sumAssetType,
        asset: storeValue.sumAsset,
        showInput: storeValue.showInput,
        formControl: sumFormControl,
      },
      requiresAttention: assets.some(requiresAttention),
    };
  }

  private preparePageTemplateAsset(
    asset: Asset,
    key: FASection,
    definition: AssetDefinition,
  ): PageTemplateAsset {
    const formCtrl = new UntypedFormControl();
    formCtrl.patchValue(Math.round(valueByAssetTypeAndSection(asset, key)));

    return {
      ...definition,
      name: this.assetHelper.getName(asset),
      valueFormControl: formCtrl,
      assetData: asset as Asset & NameAsset & InputModeAsset & ValueAsset,
      assetUuid: asset.assetUuid,
    };
  }
}
