import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FieldType} from '@ngx-formly/core';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {
  selectAdultFamilyMembers,
  selectChildrenFamilyMembers,
  selectFamilyMembers,
} from '@shared/analysis/operators';
import {IOption} from '@shared/ui';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {State} from 'src/store';
import {CheckboxComponent} from '../../forms/checkbox/checkbox.component';
import {FamilyMemberTypes} from '../formly.enums';

@UntilDestroy()
@Component({
  selector: 'kpt-family-members-formly',
  templateUrl: './family-members-formly.component.html',
})
export class FamilyMembersFormlyComponent extends FieldType implements OnInit, OnDestroy {
  options$: Observable<IOption[]>;
  familyMembersType: FamilyMemberTypes;

  @ViewChildren(CheckboxComponent) checkboxes!: QueryList<CheckboxComponent>;

  constructor(private store: Store<State>, private assetHelperService: AssetHelperService) {
    super();
  }

  ngOnInit() {
    this.familyMembersType = this.to.familyMembersType;

    const familyMembers$ = this.store.pipe(
      this.to.onlyAdult
        ? selectAdultFamilyMembers()
        : this.to.onlyChildren
        ? selectChildrenFamilyMembers()
        : selectFamilyMembers(),
    );

    this.options$ = familyMembers$.pipe(
      map((familyMembers: FamilyMember[]) =>
        familyMembers.map(
          familyMember =>
            ({
              label: this.assetHelperService.formatFamilyMemberCaption(familyMember),
              key: familyMember.sugarUuid,
            } as IOption),
        ),
      ),
    );
  }

  ngOnDestroy() {}

  onChange(value: string, checked: boolean) {
    this.formControl.patchValue(
      checked
        ? [...(this.formControl.value || []), value]
        : [...(this.formControl.value || [])].filter(o => o !== value),
    );

    this.formControl.markAsTouched();
  }

  isChecked(option: IOption) {
    const value = this.formControl.value;
    return value && value.indexOf(option.key) !== -1;
  }
}
