import {isCommonFinancialProductAsset} from '@shared/analysis/asset.utils';
import {CoreContract, SugarEnum} from '@shared/analysis/core-sync.models';
import {AssetType, BaseAsset} from '@shared/analysis/models/asset';
import {flatMap} from 'lodash';

export interface CoreCategoriesToKappkaTable {
  core: [string, string, string];
  kappka: AssetType;
}

export type AssetTypeToCoreCategoryTable = Partial<Record<AssetType, [string, string, string][]>>;

export const coreCategoriesToKappkaTable: CoreCategoriesToKappkaTable[] = [
  {core: ['Kat1Bankovni', 'Kat2Hypo', ''], kappka: AssetType.Mortgage},
  {core: ['Kat1Bankovni', 'Kat2SpotUver', ''], kappka: AssetType.ConsumerLoan},
  {core: ['Kat1Bankovni', 'Kat2StavebSpor', ''], kappka: AssetType.BuildingSavings},
  {core: ['Kat1Bankovni', 'Kat2UverStavSpor', ''], kappka: AssetType.BuildingSavingsLoan},
  {core: ['Kat1Investicni', 'Kat2Dluhopisy', ''], kappka: AssetType.Bonds},
  {core: ['Kat1Investicni', 'Kat2ETF', ''], kappka: AssetType.ExchangeTradedFunds},
  {core: ['Kat1Investicni', 'Kat2Certifikaty', ''], kappka: AssetType.Certificates},
  {core: ['Kat1Investicni', 'Kat2KombVklad', ''], kappka: AssetType.CombinedDeposits},
  {core: ['Kat1Investicni', 'Kat2NemFondKI', ''], kappka: AssetType.QualifiedInvestorFunds},
  {core: ['Kat1Investicni', 'Kat2NemFond', ''], kappka: AssetType.RealEstateFund},
  {core: ['Kat1Investicni', 'Kat2PodilFondy', ''], kappka: AssetType.UnitTrust},
  {core: ['Kat1InvestKovy', '', ''], kappka: AssetType.Commodity},
  {core: ['Kat1Penze', 'Kat2DopPenzSpor', ''], kappka: AssetType.SupplementaryPensionSavings},
  {core: ['Kat1Penze', 'Kat2TransFondy', ''], kappka: AssetType.PensionInsurance},
  {core: ['Kat1Pojisteni', 'Kat2NezivotObcan', 'Kat3Cest'], kappka: AssetType.TravelInsurance},
  {
    core: ['Kat1Pojisteni', 'Kat2NezivotObcan', 'Kat3MajetekObcan'],
    kappka: AssetType.PropertyInsurance,
  },
  {core: ['Kat1Pojisteni', 'Kat2MajPodnik', ''], kappka: AssetType.BusinessInsurance},
  {core: ['Kat1Pojisteni', 'Kat2Vozidla', ''], kappka: AssetType.VehicleInsurance},
  {core: ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3Invest'], kappka: AssetType.InvestmentLifeInsurance},
  {core: ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3ZivOst'], kappka: AssetType.CapitalLifeInsurance},
  {core: ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3riziko'], kappka: AssetType.RiskLifeInsurance},
];

export const assetTypesToCoreCategoriesTable: AssetTypeToCoreCategoryTable = {
  [AssetType.InvestmentLifeInsurance]: [['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3Invest']],
  [AssetType.RiskLifeInsurance]: [
    ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3riziko'],
    ['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3rRizInv'],
  ],
  [AssetType.CapitalLifeInsurance]: [['Kat1Pojisteni', 'Kat2Zivotni', 'Kat3ZivOst']],
  [AssetType.PropertyInsurance]: [['Kat1Pojisteni', 'Kat2NezivotObcan', 'Kat3MajetekObcan']],
  [AssetType.LiabilityInsurance]: [['Kat1Pojisteni', 'Kat2NezivotObcan', 'Kat3OstNeziv']],
  [AssetType.VehicleInsurance]: [['Kat1Pojisteni', 'Kat2Vozidla', '']],
  [AssetType.TravelInsurance]: [['Kat1Pojisteni', 'Kat2NezivotObcan', 'Kat3Cest']],
  [AssetType.BusinessInsurance]: [['Kat1Pojisteni', 'Kat2MajPodnik', '']],
  [AssetType.BuildingSavings]: [['Kat1Bankovni', 'Kat2StavebSpor', '']],
  [AssetType.SupplementaryPensionSavings]: [['Kat1Penze', 'Kat2DopPenzSpor', '']],
  [AssetType.PensionInsurance]: [['Kat1Penze', 'Kat2TransFondy', '']],
  [AssetType.SavingsAccount]: [['Kat1Bankovni', 'Kat2OstatniBank', '']],
  [AssetType.UnitTrust]: [['Kat1Investicni', 'Kat2PodilFondy', '']],
  [AssetType.RealEstateFund]: [['Kat1Investicni', 'Kat2NemFond', '']],
  [AssetType.Commodity]: [['Kat1InvestKovy', '', '']],
  [AssetType.Bonds]: [['Kat1Investicni', 'Kat2Dluhopisy', '']],
  [AssetType.ExchangeTradedFunds]: [['Kat1Investicni', 'Kat2ETF', '']],
  [AssetType.Certificates]: [['Kat1Investicni', 'Kat2Certifikaty', '']],
  [AssetType.CombinedDeposits]: [['Kat1Investicni', 'Kat2KombVklad', '']],
  [AssetType.QualifiedInvestorFunds]: [['Kat1Investicni', 'Kat2NemFondKI', '']],
  [AssetType.TermDeposit]: [['Kat1Bankovni', 'Kat2OstatniBank', '']],
  [AssetType.OtherFinancialProperty]: [],
  [AssetType.Mortgage]: [['Kat1Bankovni', 'Kat2Hypo', '']],
  [AssetType.BuildingSavingsLoan]: [['Kat1Bankovni', 'Kat2UverStavSpor', '']],
  [AssetType.Leasing]: [],
  [AssetType.CreditCard]: [['Kat1Bankovni', 'Kat2SpotUver', '']],
  [AssetType.Overdraft]: [],
  [AssetType.ConsumerLoan]: [['Kat1Bankovni', 'Kat2SpotUver', '']],
  [AssetType.OtherIndividualLoan]: [['Kat1Bankovni', 'Kat2UverICO', '']],
};

export const coreStatuses: SugarEnum[] = [
  {
    code: '219902325556367',
    codeDesc: 'Návrh smlouvy',
  },
  {
    code: '219902325556368',
    codeDesc: 'Zamítnutý návrh smlouvy',
  },
  {
    code: '219902325558157',
    codeDesc: 'Smlouva předána jinému partnerovi',
  },
  {
    code: '219902325556335',
    codeDesc: 'Stornovaná',
  },
  {
    code: '219902325556336',
    codeDesc: 'Ukončená',
  },
  {
    code: '219902325556334',
    codeDesc: 'Živá',
  },
];

export const liveCoreStatus = coreStatuses.find(s => s.code === '219902325556334');
export const draftCoreStatus = coreStatuses.find(s => s.code === '219902325556367');
export const terminatedCoreStatus = coreStatuses.find(s => s.code === '219902325556336');
export const cancelledCoreStatus = coreStatuses.find(s => s.code === '219902325556335');

export const coreSecondaryStatuses: SugarEnum[] = [
  {
    code: '219902325556334',
    codeDesc: 'Živá',
  },
  {
    code: '219902325556336',
    codeDesc: 'Ukončená',
  },
];

export const liveCoreSecondaryStatus = coreSecondaryStatuses.find(
  s => s.code === '219902325556334',
);
export const terminatedCoreSecondaryStatus = coreSecondaryStatuses.find(
  s => s.code === '219902325556336',
);

export const exampleCoreContracts: CoreContract[] = [
  {
    id: '1dc0bffc-2f40-4925-9bfc-297f89a216dd',
    foreign: false,
    name: 'Rizikové životní pojištění',
    opportunityNumber: '1551671384',
    paymentAmount: 300,
    paymentFrequency: {
      code: '219902325555381',
      codeDesc: 'Půlročně',
    },
    signatureDate: '2005-05-01',
    beginDate: '2005-05-01',
    endDate: '2046-05-01',
    productCode: 'AMK',
    partner: {
      id: '576148387921984',
      code: 'AMUNDI',
      companyName: 'Amundi Czech Republic, investiční společ',
    },
    productCategory1: {
      code: '219902325557962',
      codeDesc: 'Pojištění',
    },
    productCategory2: {
      code: '219902325557966',
      codeDesc: 'Životní',
    },
    productCategory3: {
      code: '219902325557996',
      codeDesc: 'Rizikové',
    },
    status: liveCoreStatus,
    secondaryStatus: liveCoreSecondaryStatus,
  },
  {
    id: '2e2ad63b-9e8a-4399-98c6-84a2797ca2ed',
    foreign: false,
    name: 'Investiční životní pojištění 7BN',
    opportunityNumber: '1421547754',
    paymentAmount: 1300,
    paymentFrequency: {
      code: '219902325555379',
      codeDesc: 'Měsíčně',
    },
    signatureDate: '2011-10-01',
    beginDate: '2011-10-01',
    endDate: '2046-09-30',
    productCode: 'AMK',
    productCategory1: {
      code: '219902325557962',
      codeDesc: 'Pojištění',
    },
    productCategory2: {
      code: '219902325557966',
      codeDesc: 'Životní',
    },
    productCategory3: {
      code: '219902325557995',
      codeDesc: 'Investiční',
    },
    partner: {
      id: '576148387921984',
      code: 'AMUNDI',
      companyName: 'Amundi Czech Republic, investiční společ',
    },
    status: draftCoreStatus,
    secondaryStatus: terminatedCoreSecondaryStatus,
  },
];

// TODO(pz) create proxy BE endpoint and take data from it
const coreCategories = [
  {
    id: 215504279044935,
    nazov: 'DruhProduktu',
    polozky: [
      {
        id: 219902325557940,
        kod: 'Nezarazeno',
        nazov: 'Prázdná hodnota',
        popis: null,
        platna: true,
      },
      {
        id: 219902325557941,
        kod: 'HYP',
        nazov: 'Hypoteční úvěr (FO)',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557942,
        kod: 'UPO',
        nazov: 'Úvěr podnikatelský a pro PO',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557943,
        kod: 'SU',
        nazov: 'Spotřebitelský úvěr (FO)',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557944,
        kod: 'OBP',
        nazov: 'Ostatní bankovní produkty',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557945,
        kod: 'SS',
        nazov: 'Stavební spoření',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557946,
        kod: 'USS',
        nazov: 'Úvěr ze stavebního spoření (FO)',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557947,
        kod: 'DPS',
        nazov: 'Doplňkové penzijní spoření',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557948,
        kod: 'DPS_T',
        nazov: 'Doplňkové penzijní spoření - transf. fond',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557983,
        kod: 'Kat3Zlato',
        nazov: 'Zlato',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557984,
        kod: 'Kat3PFJednoraz',
        nazov: 'Jednorázové',
        popis: 'Podílové fondy',
        platna: true,
      },
      {
        id: 219902325557985,
        kod: 'Kat3PFPravidelne',
        nazov: 'Pravidelné',
        popis: 'Podílové fondy',
        platna: true,
      },
      {
        id: 219902325557986,
        kod: 'Kat3BytJednotka',
        nazov: 'Bytová jednotka',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557987,
        kod: 'Kat3Domacnost',
        nazov: 'Domácnost',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557988,
        kod: 'Kat3Chata',
        nazov: 'Chata,Chalupa',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557989,
        kod: 'Kat3MajObOst',
        nazov: 'Ostatní poj. majetku občanů',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557990,
        kod: 'Kat3RodDum',
        nazov: 'Rodinný dům',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557991,
        kod: 'Kat3BytDum',
        nazov: 'Bytový dům',
        popis: 'Neživotní občanské',
        platna: true,
      },
      {
        id: 219902325557992,
        kod: 'Kat3HavPoj',
        nazov: 'Havarijní pojištění',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557993,
        kod: 'Kat3Kombi',
        nazov: 'Kombinované',
        popis: 'Podílové fondy',
        platna: true,
      },
      {
        id: 219902325557994,
        kod: 'Kat3PovRuc',
        nazov: 'Povinné ručení',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557995,
        kod: 'Kat3Invest',
        nazov: 'Investiční',
        popis: 'Životní',
        platna: true,
      },
      {
        id: 219902325557996,
        kod: 'Kat3riziko',
        nazov: 'Rizikové',
        popis: 'Životní',
        platna: true,
      },
      {
        id: 219902325558049,
        kod: 'Kat3SpotUvOst',
        nazov: 'Ostatní spotř.úvěry',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558050,
        kod: 'Kat3MajPojOst',
        nazov: 'Ostatní poj majetku podnikatelů',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558051,
        kod: 'Kat3ZivOst',
        nazov: 'Ostatní životní',
        popis: 'Životní',
        platna: true,
      },
      {
        id: 219902325558054,
        kod: 'Kat3MajObVet',
        nazov: 'Veterinární péče',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558058,
        kod: 'Kat3DluhJednor',
        nazov: 'Jednorázové dluhopisy',
        popis: 'Dluhopisy',
        platna: true,
      },
      {
        id: 219902325558059,
        kod: 'Kat3NemFJed',
        nazov: 'Jednorázové',
        popis: 'Nemovitostní fondy',
        platna: true,
      },
      {
        id: 219902325558060,
        kod: 'Kat3NemFondKIJedn',
        nazov: 'Jednorázové',
        popis: 'Nemovitostní fondy kvalifikovaných investorů',
        platna: true,
      },
      {
        id: 219902325558061,
        kod: 'Kat3NemFondKombi',
        nazov: 'Kombinované',
        popis: 'Nemovitostní fondy',
        platna: true,
      },
      {
        id: 219902325558062,
        kod: 'Kat3Stribro',
        nazov: 'Stříbro',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558063,
        kod: 'Kat3Platina',
        nazov: 'Platina',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558064,
        kod: 'Kat3InvestKombi',
        nazov: 'Investiční kombinované',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558086,
        kod: 'Kat3JednorazCert',
        nazov: 'Jednorázové',
        popis: 'Investiční strategie',
        platna: true,
      },
      {
        id: 219902325558093,
        kod: 'Kat3ZPlyn',
        nazov: 'Zemní plyn',
        popis: 'Energie',
        platna: true,
      },
      {
        id: 219902325558094,
        kod: 'Kat3Elektrina',
        nazov: 'Elektřina',
        popis: 'Energie',
        platna: true,
      },
      {
        id: 219902325558095,
        kod: 'Kat3Safe',
        nazov: 'Safe',
        popis: 'Komodity DPH',
        platna: false,
      },
      {
        id: 219902325558097,
        kod: 'Kat3StribroDPH',
        nazov: 'Stříbro DPH',
        popis: 'Komodity DPH',
        platna: false,
      },
      {
        id: 219902325558098,
        kod: 'Kat3ZlatoDPH',
        nazov: 'Zlato DPH',
        popis: 'Komodity DPH',
        platna: false,
      },
      {
        id: 219902325558099,
        kod: 'Kat3Platina DPH',
        nazov: 'Platina',
        popis: 'Komodity DPH',
        platna: false,
      },
      {
        id: 219902325558134,
        kod: 'Kat3 InvKombi',
        nazov: 'Kombinované Invest.',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558146,
        kod: 'Kat3rRizInv',
        nazov: 'Rizikové i Investiční',
        popis: 'Životní',
        platna: true,
      },
      {
        id: 219902325558162,
        kod: 'Kat3ZivPrevod',
        nazov: 'Převod',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558168,
        kod: 'Kat3MajetekObcan',
        nazov: 'Majetek občanů',
        popis: 'Neživotní občanské',
        platna: true,
      },
      {
        id: 219902325558169,
        kod: 'Kat3OstNeziv',
        nazov: 'Ostatní',
        popis: 'Neživotní občanské',
        platna: true,
      },
      {
        id: 219902325558170,
        kod: 'Kat3Cest',
        nazov: 'Cestovní',
        popis: 'Neživotní občanské',
        platna: true,
      },
      {
        id: 219902325558178,
        kod: 'Kat3DisVPRBD',
        nazov: 'Bytové domy',
        popis: 'Distribuce velkých pojistných rizik',
        platna: true,
      },
      {
        id: 219902325558179,
        kod: 'Kat3ETFKombi',
        nazov: 'ETF Kombinované',
        popis: 'ETF',
        platna: true,
      },
    ],
  },
  {
    id: 215504279044939,
    nazov: 'TypKateg1',
    polozky: [
      {
        id: 219902325557961,
        kod: 'Kat1Bankovni',
        nazov: 'Bankovní',
        popis: null,
        platna: true,
      },
      {
        id: 219902325557962,
        kod: 'Kat1Pojisteni',
        nazov: 'Pojištění',
        popis: null,
        platna: true,
      },
      {
        id: 219902325557968,
        kod: 'Kat1Investicni',
        nazov: 'Investiční',
        popis: null,
        platna: true,
      },
      {
        id: 219902325557969,
        kod: 'Kat1Ostatni',
        nazov: 'Ostatní',
        popis: null,
        platna: true,
      },
      {
        id: 219902325558091,
        kod: 'Kat1ProdDPH',
        nazov: 'Produkty s DPH',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558163,
        kod: 'Kat1Uvery',
        nazov: 'Úvěry',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558164,
        kod: 'Kat1OstBankProd',
        nazov: 'Ostatní bankovní produkty',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558165,
        kod: 'Kat1StavSpor',
        nazov: 'Stavební spoření (vklady)',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558166,
        kod: 'Kat1Penze',
        nazov: 'Penze',
        popis: null,
        platna: true,
      },
      {
        id: 219902325558171,
        kod: 'Kat1InvestKovy',
        nazov: 'Investiční kovy',
        popis: null,
        platna: true,
      },
    ],
  },
  {
    id: 215504279044940,
    nazov: 'TypKateg2',
    polozky: [
      {
        id: 219902325557963,
        kod: 'Kat2StavebSpor',
        nazov: 'Stavební spoření (vklady)',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557964,
        kod: 'Kat2UverICO',
        nazov: 'Úvěry na podnikání, BD a SVJ',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557965,
        kod: 'Kat2MajetekObcan',
        nazov: 'Majetek občanů',
        popis: 'Pojištění',
        platna: false,
      },
      {
        id: 219902325557966,
        kod: 'Kat2Zivotni',
        nazov: 'Životní',
        popis: 'Pojištění',
        platna: true,
      },
      {
        id: 219902325557970,
        kod: 'Kat2DopPenzSpor',
        nazov: 'Doplňkové penzijní spoření',
        popis: 'Penze',
        platna: true,
      },
      {
        id: 219902325557971,
        kod: 'Kat2Hypo',
        nazov: 'Hypoteční úvěry',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557972,
        kod: 'Kat2OstatniBank',
        nazov: 'Ostatní bankovní produkty',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557973,
        kod: 'Kat2SpotUver',
        nazov: 'Spotřebitelské úvěry',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557974,
        kod: 'Kat2UverStavSpor',
        nazov: 'Úvěry ze stavebního spoření',
        popis: 'Bankovní',
        platna: true,
      },
      {
        id: 219902325557975,
        kod: 'Kat2Dluhopisy',
        nazov: 'Dluhopisy',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325557976,
        kod: 'Kat2Komodity',
        nazov: 'Komodity',
        popis: 'Investiční',
        platna: false,
      },
      {
        id: 219902325557977,
        kod: 'Kat2PodilFondy',
        nazov: 'Podílové fondy',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325557978,
        kod: 'Kat2TransFondy',
        nazov: 'Transformované fondy',
        popis: 'Penze',
        platna: true,
      },
      {
        id: 219902325557979,
        kod: 'Kat2MajPodnik',
        nazov: 'Neživotní podnikatelů',
        popis: 'Pojištění',
        platna: true,
      },
      {
        id: 219902325557980,
        kod: 'Kat2VelHospRiz',
        nazov: 'Velká hospodářská rizika',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557981,
        kod: 'Kat2Vozidla',
        nazov: 'Vozidel',
        popis: 'Pojištění',
        platna: true,
      },
      {
        id: 219902325557982,
        kod: 'Kat2Zivotni',
        nazov: 'Životní',
        popis: null,
        platna: false,
      },
      {
        id: 219902325557997,
        kod: 'Kat2VeterPece',
        nazov: 'Pojištění nákladů na veterinátní péči',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558053,
        kod: 'Kat2Cest',
        nazov: 'Cestovní',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558057,
        kod: 'Kat2NemFondKI',
        nazov: 'Nemovitostní fondy kvalifikovaných investorů',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325558065,
        kod: 'Kat2NemFond',
        nazov: 'Nemovitostní fondy',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325558084,
        kod: 'Kat2Majetek',
        nazov: 'Majetek',
        popis: null,
        platna: false,
      },
      {
        id: 219902325558085,
        kod: 'Kat2Certifikaty',
        nazov: 'Investiční strategie',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325558092,
        kod: 'Kat2Energie',
        nazov: 'Energie',
        popis: 'Ostatní',
        platna: true,
      },
      {
        id: 219902325558096,
        kod: 'Kat2KomodityDPH',
        nazov: 'Komodity DPH',
        popis: 'Produkty s DPH',
        platna: false,
      },
      {
        id: 219902325558141,
        kod: 'Kat2InvKovy',
        nazov: 'Investiční kovy',
        popis: 'Produkty s DPH',
        platna: false,
      },
      {
        id: 219902325558149,
        kod: 'Kat2ETF',
        nazov: 'ETF',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325558154,
        kod: 'Kat2KombVklad',
        nazov: 'Kombinované vklady',
        popis: 'Investiční',
        platna: true,
      },
      {
        id: 219902325558158,
        kod: 'Kat2DopTrans',
        nazov: 'Doplňkové penzijní spoření i transformovaný fond',
        popis: 'Investiční',
        platna: false,
      },
      {
        id: 219902325558167,
        kod: 'Kat2NezivotObcan',
        nazov: 'Neživotní občanské',
        popis: 'Pojištění',
        platna: true,
      },
      {
        id: 219902325558172,
        kod: 'Kat2Zlato',
        nazov: 'Zlato',
        popis: 'Investiční kovy',
        platna: true,
      },
      {
        id: 219902325558173,
        kod: 'Kat2Stribro',
        nazov: 'Stříbro',
        popis: 'Investiční kovy',
        platna: true,
      },
      {
        id: 219902325558174,
        kod: 'Kat2Platina',
        nazov: 'Platina',
        popis: 'Investiční kovy',
        platna: true,
      },
      {
        id: 219902325558175,
        kod: 'Kat2Zivotni2',
        nazov: 'Životní II.',
        popis: 'Pojištění',
        platna: false,
      },
      {
        id: 219902325558176,
        kod: 'Kat2DistVPR',
        nazov: 'Distribuce velkých pojistných rizik',
        popis: 'Pojištění',
        platna: true,
      },
      {
        id: 219902325558177,
        kod: 'Kat2BezpSchr',
        nazov: 'Bezpečnostní schránky',
        popis: 'Ostatní',
        platna: true,
      },
    ],
  },
];

export interface CoreCategory {
  id: number;
  kod: string;
  nazov: string;
  popis: string;
  platna: boolean;
}

export const validCoreCategories: Promise<CoreCategory[]> = Promise.resolve(
  flatMap(coreCategories, c => c.polozky).filter(c => c.platna),
);

export function hasCancelledPrimaryStatus(asset: BaseAsset) {
  if (!isCommonFinancialProductAsset(asset)) return false;
  return isCancelledPrimaryStatus(asset.coreStatus);
}

export function hasTerminatedPrimaryStatus(asset: BaseAsset) {
  if (!isCommonFinancialProductAsset(asset)) return false;
  return isTerminatedPrimaryStatus(asset.coreStatus);
}

export function hasTerminatedSecondaryStatus(asset: BaseAsset) {
  if (!isCommonFinancialProductAsset(asset)) return false;
  return isTerminatedSecondaryStatus(asset.coreSecondaryStatus);
}

export function isLiveOrDraftStatus(code: string): boolean {
  return code === liveCoreStatus.code || code === draftCoreStatus.code;
}

export function isCancelledPrimaryStatus(code: string): boolean {
  return code === cancelledCoreStatus.code;
}

export function isTerminatedPrimaryStatus(code: string): boolean {
  return code === terminatedCoreStatus.code;
}

export function isTerminatedSecondaryStatus(code: string): boolean {
  return code === terminatedCoreSecondaryStatus.code;
}
