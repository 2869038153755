import {GoldSummary} from '@generated/model';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  ERecordRequest,
  GoldERecordClient,
  GoldERecordSection,
  GoldSummaryForm,
} from 'src/app/modules/gold/store/gold.models';
import {GoldState} from 'src/app/modules/gold/store/gold.state';
import {parseDatesWithinObject} from 'src/shared/utils/trim-date.fnc';

export const storeFeatureName = 'gold';

export const selectState = createFeatureSelector<GoldState>(storeFeatureName);

export const selectPersons = createSelector(selectState, state => state.persons);

export const selectFamilyName = createSelector(selectPersons, persons =>
  (persons || []).map(p => p.name).join(' a '),
);

export const selectFamilyHead = createSelector(selectPersons, persons =>
  (persons || []).find(p => p.familyHead),
);

export const selectSummary = createSelector(selectState, state => state.summary);

export const selectModelation = createSelector(selectSummary, summary => summary.modelation);

export const selectSummaryForm = createSelector<object, [GoldSummary], GoldSummaryForm>(
  selectSummary,
  ({client, contracts, paymentSchedules}) => ({
    ...client,
    contracts: contracts.map(c =>
      parseDatesWithinObject(c, ['contractSigningDate', 'contractStartDate']),
    ),
    paymentSchedules: paymentSchedules.map(p => parseDatesWithinObject(p, ['dueDate'])),
  }),
);

export const selectERecordRequest = createSelector(selectSummary, summary =>
  createERecordRequest(summary),
);

export const selectERecord = createSelector(selectState, state => state.eRecord);

function createERecordRequest({
  client,
  modelation,
  contracts,
  paymentSchedules,
}: GoldSummary): ERecordRequest {
  if (!client || !modelation || !contracts || !paymentSchedules) {
    return null;
  }
  const goldSection: GoldERecordSection = {
    sectionType: 'gold',
    financialAnalysis: {
      familySavings: modelation.savings,
      familyFreeMonthlyResources: modelation.monthlyBalance,
    },
    recommendation: {
      resolution: client.recommendationResolution,
    },
    purchaseContract: {
      resolution: client.purchaseResolution,
      contracts,
    },
    paymentSchedule: paymentSchedules.map(({accountNumber, bankNumber, ...p}) => ({
      ...p,
      bankAccount: `${accountNumber}/${bankNumber}`,
    })),
  };
  if (client.recommendationResolution === 'recommendation') {
    goldSection.recommendation.oneTime = {
      amount: modelation.oneTimeInvestment,
      percent: (modelation.oneTimeInvestment / modelation.savings) * 100,
    };
    goldSection.recommendation.monthly = {
      amount: modelation.regularInvestment,
      percent: (modelation.regularInvestment / modelation.monthlyBalance) * 100,
    };
  } else goldSection.recommendation.noRecommendationReason = client.noRecommendationReason;

  return {
    client: client as GoldERecordClient,
    sections: [goldSection],
  };
}
