<kpt-modal-wrapper heading="Zahájit přípravu nového Finančního plánu?" (close)="dismiss()">
  <!-- focus catcher -->
  <input class="focus-catcher" type="text" />
  <div class="buttons-grid">
    <kpt-button
      type="primary"
      [hasOutline]="true"
      customClass="btn-block"
      [isLoading]="sending"
      (action)="resetFp()"
      >Ano</kpt-button
    >
    <kpt-button
      type="primary"
      [hasOutline]="true"
      [isDisabled]="sending"
      customClass="btn-block"
      (action)="dismiss()"
      >Ne</kpt-button
    >
  </div>
</kpt-modal-wrapper>
