import {Component, Input, OnInit} from '@angular/core';
import {Chart, ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import {Label} from 'ng2-charts';
import {baseColors} from 'src/assets/scss/01_base/colors';

@Component({
  selector: 'kpt-simple-graph',
  templateUrl: './simple-graph.component.html',
  styleUrls: ['./simple-graph.component.scss'],
})
export class SimpleGraphComponent implements OnInit {
  @Input()
  options: ChartOptions = {
    legend: {display: false},
    hover: {mode: null},
    responsive: true,
  };

  @Input()
  labels: Label[];

  @Input()
  chartType: ChartType = 'bar';

  @Input()
  datasets: ChartDataSets[] = [
    {
      data: [10, 20, 30, 40],
      label: 'Example',
    },
  ];
  @Input()
  ownColors = false;

  legend = true;
  plugins = [ChartDataLabels];
  showGraph = false;

  private activeColorIndex = 0;
  private colorStack = [
    baseColors.lightBlue,
    baseColors.green200,
    baseColors.green100,
    baseColors.green150,
  ];

  ngOnInit(): void {
    Chart.pluginService.register(ChartDataLabels);

    let index = 0;
    for (; index < this.datasets.length; index++) {
      if (!this.ownColors) {
        if (this.chartType === 'bar') this.datasets[index].backgroundColor = this.colorDispenser();
        else {
          this.activeColorIndex++;
          this.datasets[index].borderColor = this.colorDispenser();
          this.datasets[index].backgroundColor = null;
        }
      }
    }

    this.showGraph = true;
  }

  colorDispenser(): string {
    const activeColor = this.colorStack[this.activeColorIndex];
    this.activeColorIndex++;
    if (this.activeColorIndex === 4) this.activeColorIndex = 0;
    return activeColor;
  }
}
