<div
  ng2FileDrop
  class="well drop-zone"
  [class.drop-zone--drag-over]="isFileDragOver"
  [uploader]="uploader"
  (fileOver)="onFileDragOver($event)"
>
  <div class="upload-model">
    <input
      #file
      type="file"
      ng2FileSelect
      style="display: none"
      [uploader]="uploader"
      [multiple]="true"
    />

    <kpt-button type="primary" icon="icon--plus" [label]="label" (action)="file.click()">
    </kpt-button>
  </div>
  <span class="m-2">nebo sem přetáhněte soubory myší</span>
  <kpt-alert type="warning" *ngIf="fileAddingFailed"
    >Soubor {{ fileAddingFailed.name }} nelze nahrát.<br />
    Ukládat lze pouze přílohy těchto typů: image, pdf, doc, xls, ppt, application
  </kpt-alert>
</div>

<div *ngIf="(filesList$ | async)?.length > 0" class="d-flex flex-wrap mt-4">
  <kpt-attached-file-tile
    *ngFor="let file of filesList$ | async"
    [file]="file"
    (update)="onUpdateFile($event)"
    (removeFromQueue)="onRemoveFromQueue($event)"
    (showDeleteConfirmation)="onShowDeleteConfirmation($event)"
  >
  </kpt-attached-file-tile>
</div>
