import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Start as GetListOfStatesStart} from '@generated/store/integrations/getListOfStates/states/actions';
import {getGetListOfStatesStateSelector} from '@generated/store/integrations/getListOfStates/states/reducers';
import {col, column, row} from '@lib/utils';
import {Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {SmartformInstance} from '@shared/models/smartform.models';
import {IOption} from '@shared/ui';
import {getBasicField, getRadioGroupField} from '@shared/ui/formly/formly.utils';
import {CustomValidators} from '@shared/utils';
import {filter, map} from 'rxjs/operators';
import {FormlyTypes, FormlyWrappers} from 'src/shared/ui/formly/formly.enums';
import {State} from 'src/store';
import {replenish} from 'src/store/store-helpers';
import {v4 as uuid} from 'uuid';
import {birthDateWarning, updateSexAndBirthDate} from './family-member.helpers';

export interface FamilyMemberDefinition {
  name?: string;
  fields: FormlyFieldConfig[];
  smartformInstance?: SmartformInstance;
  model: FamilyMember;
  showRemoveButton?: boolean;
}

const sexOptions: IOption[] = [
  {
    key: 'MALE',
    label: 'muž',
  },
  {
    key: 'FEMALE',
    label: 'žena',
  },
];

const memberTypes: IOption[] = [
  {
    key: 'ADULT',
    label: 'dospělý',
  },
  {
    key: 'CHILD',
    label: 'dítě',
  },
];

export const maritalStatus: IOption[] = [
  {
    key: 'none',
    label: 'Nevyplněno',
  },
  {
    key: 'single',
    label: 'Svobodný/á',
  },
  {
    key: 'married',
    label: 'Ženatý/Vdaná',
  },
  {
    key: 'widowed',
    label: 'Ovdovělý/á',
  },
  {
    key: 'divorced',
    label: 'Rozvedený/á',
  },
];

export const documentTypes: IOption[] = [
  {
    key: 'identity-card',
    label: `Občanský průkaz`,
  },
  {
    key: 'passport',
    label: 'Pas',
  },
];

const smartformInstance: SmartformInstance = {
  name: uuid(),
  fieldNames: {
    city: 'city',
    zip: 'zipCode',
    streetAndNumber: 'street',
  },
};

const isChild = (model: FamilyMember) => model.type === 'CHILD';

const childHelp = 'Hlavní klient může být pouze dospělý a nelze změnit na dítě.';

@Injectable()
export class FamilyMemberDefinitionsService {
  private states$ = replenish(
    getGetListOfStatesStateSelector,
    this.store,
    GetListOfStatesStart,
  ).pipe(
    filter(statesData => !statesData.error && !statesData.loading),
    map(statesData => statesData.data),
  );

  constructor(private store: Store<State>) {}

  getFamilyMemberDefinition(): FamilyMemberDefinition {
    return {
      fields: [
        getRadioGroupField(
          'type',
          '',
          memberTypes,
          {caption: childHelp},
          {
            'templateOptions.disabled': 'model.familyHead',
            'templateOptions.hideCaption': '!model.familyHead',
          },
          'ADULT',
          null,
          [],
          [FormlyWrappers.CaptionedInput],
        ),
        ...this.getCommonFields(),
      ],
      model: {} as FamilyMember,
      smartformInstance,
    };
  }

  getChildDefinition(): FamilyMemberDefinition {
    return {
      name: 'Dítě',
      fields: [
        getRadioGroupField(
          'type',
          '',
          memberTypes,
          {caption: childHelp},
          {},
          'CHILD',
          null,
          [],
          [FormlyWrappers.CaptionedInput],
        ),
        ...this.getCommonFields(),
      ],
      model: {} as FamilyMember,
    };
  }

  private getCommonFields(): FormlyFieldConfig[] {
    return [
      row([
        col(
          getBasicField(FormlyTypes.Text, 'firstName', FieldLabel.FIRST_NAME, {
            required: true,
            minLength: 3,
            maxLength: 30,
          }),
        ),
        col(
          getBasicField(FormlyTypes.Text, 'lastName', FieldLabel.LAST_NAME, {
            required: true,
            minLength: 3,
            maxLength: 30,
          }),
        ),
      ]),
      getRadioGroupField('sex', null, sexOptions, {}, {}, 'MALE', null, [
        CustomValidators.matchesBirthNumber('sex'),
      ]),
      row([
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'birthNumber',
              FieldLabel.BIRTH_NUMBER,
              {change: updateSexAndBirthDate},
              null,
              null,
              [CustomValidators.birthNumber()],
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Datepicker,
              'birthDate',
              FieldLabel.BIRTH_DATE,
              {required: true},
              {
                'templateOptions.warningText$': birthDateWarning,
              },
              null,
              [CustomValidators.matchesBirthNumber('birthDate'), CustomValidators.birthDate()],
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'phoneNumber',
              FieldLabel.PHONE_NUMBER,
              {},
              {},
              isChild,
              [CustomValidators.phoneNumber()],
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(FormlyTypes.Text, 'email', FieldLabel.EMAIL, {}, {}, isChild, [
              Validators.email,
            ]),
          ],
          [6, 6],
        ),
        column(
          [
            {
              key: 'maritalStatus',
              type: FormlyTypes.Select,
              defaultValue: 'none',
              hideExpression: isChild,
              templateOptions: {
                label: FieldLabel.MARTIAL_STATUS,
                options: maritalStatus,
              },
            },
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'street',
              FieldLabel.STREET,
              {
                minLength: 3,
                maxLength: 30,
                smartformEnabled: true,
                smartformFieldType: 'street-and-number',
                smartformInstance: smartformInstance.name,
              },
              {},
              isChild,
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'city',
              FieldLabel.CITY,
              {
                minLength: 3,
                maxLength: 30,
                smartformEnabled: true,
                smartformFieldType: 'city',
                smartformInstance: smartformInstance.name,
              },
              {},
              isChild,
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'zipCode',
              FieldLabel.ZIP_CODE,
              {
                smartformEnabled: true,
                smartformFieldType: 'zip',
                smartformInstance: smartformInstance.name,
              },
              {},
              isChild,
              [CustomValidators.postalCode('^\\d{3} ?\\d{2}$')],
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'placeOfBirth',
              FieldLabel.PLACE_OF_BIRTH,
              {
                minLength: 3,
                maxLength: 30,
              },
              {},
              isChild,
            ),
          ],
          [6, 6],
        ),
        column(
          [
            {
              key: 'nationality',
              type: FormlyTypes.Select,
              hideExpression: isChild,
              templateOptions: {
                label: FieldLabel.NATIONALITY,
                options: this.states$.pipe(map(data => data.nationalities)),
              },
            },
          ],
          [6, 6],
        ),
        column(
          [
            {
              key: 'documentType',
              type: FormlyTypes.Select,
              defaultValue: 'identity-card',
              hideExpression: isChild,
              templateOptions: {
                label: FieldLabel.DOCUMENT_TYPE,
                options: documentTypes,
              },
            },
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Text,
              'documentNumber',
              FieldLabel.DOCUMENT_NUMBER,
              {},
              {},
              isChild,
            ),
          ],
          [6, 6],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Datepicker,
              'dateOfExpiry',
              FieldLabel.DATE_OF_EXPIRY,
              {},
              {},
              isChild,
            ),
          ],
          [6, 6],
        ),
        column(
          [getBasicField(FormlyTypes.Text, 'authority', FieldLabel.AUTHORITY, {}, {}, isChild)],
          [6, 6],
        ),
        column(
          [
            {
              key: 'stateAuthority',
              type: FormlyTypes.Select,
              hideExpression: isChild,
              templateOptions: {
                label: FieldLabel.STATE_AUTHORITY,
                options: this.states$.pipe(map(data => data.states)),
              },
            },
          ],
          [6, 6],
        ),
      ]),
      getBasicField(
        FormlyTypes.Checkbox,
        'familyHead',
        FieldLabel.FAMILY_HEAD,
        {
          caption:
            'Pro změnu hlavního klienta prosím zaškrtněte toto pole u jiného člena rodiny, ' +
            'kterého si přejete nastavit jako hlavního.',
        },
        {
          'templateOptions.disabled': 'model.originalFamilyHead',
          'templateOptions.hideCaption': '!model.originalFamilyHead',
        },
        isChild,
        [],
        [FormlyWrappers.CaptionedInput],
      ),
    ];
  }
}
