import {HttpErrorResponse} from '@angular/common/http';
import {FamilyMember} from '@generated/defs/FamilyMember';
import produce from 'immer';
import {FamilyMemberActionTypes, FamilyMembersActions} from '../actions/family-members.actions';

export interface FamilyMemberState {
  familyMembers: FamilyMember[];
  familyUuid: string;
  loading: boolean;
  error: HttpErrorResponse;
}

export const initialState: FamilyMemberState = {
  familyMembers: [],
  familyUuid: null,
  loading: false,
  error: null,
};

const familyMemberReducer = produce((state: FamilyMemberState, action: FamilyMembersActions) => {
  switch (action.type) {
    case FamilyMemberActionTypes.LoadMembersStart:
    case FamilyMemberActionTypes.CreateMemberStart:
    case FamilyMemberActionTypes.UpdateMemberStart:
    case FamilyMemberActionTypes.DeleteMemberStart:
    case FamilyMemberActionTypes.AddExistingFamilyMemberStart:
      state.loading = true;
      return;

    case FamilyMemberActionTypes.LoadMembersFinish:
      state.loading = false;
      state.error = action.payload.error;
      state.familyUuid = action.payload.familyUuid;
      state.familyMembers = action.payload.familyMembers;
      return;

    case FamilyMemberActionTypes.CreateMemberFinish:
      state.loading = false;
      state.error = action.payload.error;
      if (action.payload.newMember.familyHead) {
        for (let i = 0; i < state.familyMembers.length; i++) {
          state.familyMembers[i] = {
            ...state.familyMembers[i],
            familyHead: false,
          };
        }
      }
      if (action.payload.newMember) {
        state.familyMembers.push({...action.payload.newMember});
      }
      return;

    case FamilyMemberActionTypes.UpdateMemberFinish:
      state.loading = false;
      state.error = action.payload.error;

      if (action.payload.newMember) {
        const index = state.familyMembers.findIndex(
          m => m.sugarUuid === action.payload.newMember.sugarUuid,
        );
        state.familyMembers[index] = action.payload.newMember;
        if (action.payload.newMember.familyHead) {
          for (let i = 0; i < state.familyMembers.length; i++) {
            if (i !== index && state.familyMembers[i].familyHead) {
              state.familyMembers[i] = {
                ...state.familyMembers[i],
                familyHead: false,
              };
            }
          }
        }
      }
      return;

    case FamilyMemberActionTypes.DeleteMemberFinish:
      state.loading = false;
      state.error = action.payload.error;

      if (action.payload.sugar_uuid) {
        state.familyMembers = state.familyMembers.filter(
          m => m.sugarUuid !== action.payload.sugar_uuid,
        );
      }
      return;

    case FamilyMemberActionTypes.AddExistingFamilyMemberFinish:
      state.loading = false;
      state.error = action.payload.error;
      state.familyMembers.push({...action.payload.familyMember});
      return;
  }
});

export function reducer(state = initialState, action: FamilyMembersActions): FamilyMemberState {
  return familyMemberReducer(state, action);
}
