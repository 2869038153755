import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {selectAllAssets} from '@shared/analysis/store';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Item} from 'src/app/modules/client/summary/store/summary.models';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemComponent {
  @Input() item: Item;

  constructor(private store: Store<State>, private assetHelperService: AssetHelperService) {}

  get value(): Observable<string> {
    if (this.item.assetUuid) {
      return this.store.pipe(
        select(selectAllAssets),
        map(assets => assets.find(asset => asset.assetUuid === this.item.assetUuid)),
        map(asset => this.assetHelperService.getName(asset)),
        untilDestroyed(this),
      );
    }
    return of(this.item.value);
  }
}
