import {NgModule} from '@angular/core';
import {FormsSharedModule} from '@generated/store/forms-shared.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AssetListItemModule} from '@shared/analysis/components/asset-list-item/asset-list-item.module';
import {AccordionModule, CommonBaseModule} from '@shared/lib';
import {AccordionSwitchModule} from '@shared/lib/components/accordion-switch/accordion-switch.module';
import {OtherIncomesAccordionComponent} from '@shared/life-insurance/components/other-incomes-accordion/other-incomes-accordion.component';
import {PaymentProtectionInsuranceAccordionComponent} from '@shared/life-insurance/components/payment-protection-insurance-accordion/payment-protection-insurance-accordion.component';
import {SixMonthReserveAccordionComponent} from '@shared/life-insurance/components/six-month-reserve-accordion/six-month-reserve-accordion.component';
import {HelpModule} from '@shared/ui';
import {CheckboxModule} from '@shared/ui/forms/checkbox/checkbox.module';
import {RadioGroupModule} from '@shared/ui/forms/radio-group/radio-group.module';
import {SwitchModule} from '@shared/ui/forms/switch';
import {LifeInsuranceEffects} from 'src/app/modules/life-insurance-old/store/life-insurance.effects';
import {reducer} from 'src/app/modules/life-insurance-old/store/life-insurance.reducer';
import {storeFeatureName} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {PensionEnsurementAccordionOldComponent} from './components/pension-ensurement-accordion/pension-ensurement-accordion-old.component';
import {ReservesAccordionOldComponent} from './components/reserves-accordion/reserves-accordion-old.component';

@NgModule({
  declarations: [
    ReservesAccordionOldComponent,
    PensionEnsurementAccordionOldComponent,
    OtherIncomesAccordionComponent,
    PaymentProtectionInsuranceAccordionComponent,
    SixMonthReserveAccordionComponent,
  ],
  imports: [
    CommonBaseModule,
    StoreModule.forFeature(storeFeatureName, reducer),
    EffectsModule.forFeature([LifeInsuranceEffects]),
    HelpModule,
    CheckboxModule,
    AccordionModule,
    FormsSharedModule,
    RadioGroupModule,
    AccordionSwitchModule,
    AssetListItemModule,
    SwitchModule,
  ],
  exports: [
    ReservesAccordionOldComponent,
    PensionEnsurementAccordionOldComponent,
    OtherIncomesAccordionComponent,
    PaymentProtectionInsuranceAccordionComponent,
    SixMonthReserveAccordionComponent,
  ],
})
export class LifeInsuranceSharedModule {}
