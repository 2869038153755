import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@lib/pipes';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {FormModule, HelpModule} from '@shared/ui';
import {CaptionedInputFormlyWrapperComponent} from './captioned-input-formly-wrapper.component';

@NgModule({
  declarations: [CaptionedInputFormlyWrapperComponent],
  imports: [CommonModule, FormModule, BackendValidationModule, HelpModule, PipesModule],
  exports: [CaptionedInputFormlyWrapperComponent],
})
export class CaptionedInputFormlyWrapperModule {}
