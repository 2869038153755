import {Component, Inject, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AdvisorUiSettingsDataService} from '@shared/advisor-ui-settings/advisor-ui-settings-data.service';
import {selectFamilyHead} from '@shared/analysis/operators';
import {LifeSituation} from '@shared/business-case/store';
import {FAMILY_ID} from '@shared/constants';
import {BusinessCaseService} from 'src/app/modules/financial-plan/business-case.service';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {PageTitleService} from 'src/app/services/page-title.service';
import {StyleService} from 'src/app/services/style.service';
import {State} from 'src/store';
import {IClientTabs} from './models/client-tabs.model';

const summaryPrintStyles = 'summary-print';

@UntilDestroy()
@Component({
  styleUrls: ['./client-page.component.scss'],
  templateUrl: './client-page.component.html',
})
export class ClientPageComponent implements OnDestroy {
  activeTabId = IClientTabs.SUBJECTS;
  familyHead$ = this.store.pipe(selectFamilyHead());

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private pageTitleService: PageTitleService,
    private styleService: StyleService,
    private advisorUiSettingsDataService: AdvisorUiSettingsDataService,
    private businessCaseService: BusinessCaseService,
    public featureFlagsService: FeatureFlagsService,
    @Inject(FAMILY_ID) private familyUuid: string,
  ) {
    this.pageTitleService.setTitle('');

    this.route.url.pipe(untilDestroyed(this)).subscribe(segments => {
      if (segments.length > 0 && IClientTabs.SUMMARY === segments[segments.length - 1].path) {
        this.activeTabId = IClientTabs.SUMMARY;
        this.addSummaryPrintStyle();
      }
    });

    this.advisorUiSettingsDataService.loadDataAndSaveChanges();
    this.businessCaseService.loadData(this.familyUuid, LifeSituation.All, false);
    this.businessCaseService.loadFinalizedBusinessCases();
  }

  ngOnDestroy() {
    this.styleService.removeStyle(summaryPrintStyles);
    this.advisorUiSettingsDataService.stop();
  }

  tabChanged(tabName: string) {
    switch (tabName) {
      case 'cardBoard':
        this.styleService.removeStyle(summaryPrintStyles);
        break;
      case 'summary':
        this.addSummaryPrintStyle();
        break;
    }
    this.changeLocation(tabName);
  }

  private changeLocation(tabId: string) {
    this.router.navigateByUrl(
      `secured/client/${this.familyUuid}${tabId === IClientTabs.SUMMARY ? '/summary' : ''}`,
    );
  }

  private async addSummaryPrintStyle() {
    const res = await fetch('/assets/print/summary-print.component.css');
    if (!res.ok) {
      throw new Error(`Could not load summary-print.component.css: ${res.status}`);
    }
    const style = await res.text();
    this.styleService.addStyle(summaryPrintStyles, style);
  }
}
