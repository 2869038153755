import {FamilyMember} from '@generated/defs/FamilyMember';
import {ChildrenTaxAdvantageAsset} from '@shared/analysis/models/income-attributes';

export function prepareTaxAdvantageSentence(
  asset: ChildrenTaxAdvantageAsset,
  family: FamilyMember[],
): string {
  if (!asset) return;
  return asset.persons.reduce((taxAdvantage: string, person) => {
    if (person.childrenUuids.length) {
      const adult = family.find(m => m.sugarUuid === person.familyMemberUuid);
      if (!adult) return taxAdvantage;
      const adultName = `<strong>${adult.firstName ? adult.firstName : adult.lastName}</strong>`;
      const children = person.childrenUuids
        .map(child => family.find(m => m.sugarUuid === child))
        .filter(Boolean)
        .map(child => child.firstName)
        .join(', ');
      if (!children) return taxAdvantage;
      const sentence = `${adultName}&nbsp;→&nbsp;${children}`;
      taxAdvantage = `${taxAdvantage ? taxAdvantage + ';&nbsp;' : ''}${sentence}`;
    }

    return taxAdvantage;
  }, null);
}
