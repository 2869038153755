import {Injectable} from '@angular/core';
import {AssetDefinition} from './definitions.models';
import {FinancialProperties} from './financial-properties.definitions';
import {ImmovableProperties} from './immovable-properties.definitions';
import {MovableProperties} from './movable-properties.definitions';

@Injectable()
export class Properties {
  constructor(
    private movableProperties: MovableProperties,
    private immovableProperties: ImmovableProperties,
    private financialProperties: FinancialProperties,
  ) {}

  getAll(): AssetDefinition[] {
    return [
      ...this.movableProperties.getAll(),
      ...this.immovableProperties.getAll(),
      ...this.financialProperties.getAll(),
    ];
  }

  getMovableProperties() {
    return this.movableProperties.getAll();
  }

  getImmovableProperties() {
    return this.immovableProperties.getAll();
  }

  getFinancialProperties() {
    return this.financialProperties.getAll();
  }
}
