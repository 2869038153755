import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {IAccordionItem} from './models/accordion-item.model';

@Component({
  selector: 'kpt-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent {
  @Input() accordionContentTemplate?: TemplateRef<any>;
  @Input() accordionHeadingTemplate?: TemplateRef<any>;
  @Output() accordionItemAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() accordionItems: IAccordionItem[] = [];
  @Input() accordionSubtitleTemplate?: TemplateRef<any>;
  @Input() title: string;
  @Input() data?: any;
  @Input() isCollapsable = true;
}
