import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DropdownModule} from '@shared/ui/dropdown/dropdown.module';
import {BreadcrumbComponent} from './breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, DropdownModule, RouterModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
