import {NgModule} from '@angular/core';
import {AnalysisService} from '@generated/controllers/Analysis';
import {LifeinsuranceService} from '@generated/controllers/Lifeinsurance';
import {CreateUpdateRisksModule} from '@generated/store/analysis/createUpdateRisks/createUpdateRisks.module';
import {GetRisksModule} from '@generated/store/analysis/getRisks/getRisks.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {AssetTypesModule} from '@shared/analysis/asset-types/asset-types.module';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {CoreContractsModule} from '@shared/analysis/core-contracts.module';
import {CoreSyncCommonService} from '@shared/analysis/core-sync-common.service';
import {CoreSyncService} from '@shared/analysis/core-sync.service';
import {DefaultValueService} from '@shared/analysis/default-value.service';
import {RemoveRelatedDataService} from '@shared/analysis/remove-related-data.service';
import {RisksSelectionDataService} from '@shared/analysis/risks-selection-data.service';
import {FinancialAnalysisEffects} from '@shared/analysis/store/financial-analysis.effects';
import {SynchronizationService} from '@shared/analysis/synchronization.service';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {AssetDetailCreator} from 'src/app/modules/client/pages/asset-detail/asset-detail-creator.service';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {FinancialAnalysisDataService} from './financial-analysis-data.service';
import {AssetsDefinitionsModule} from './forms/definitions.module';
import {reducer, storeFeatureName} from './store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(storeFeatureName, reducer),
    EffectsModule.forFeature([FinancialAnalysisEffects]),
    GetRisksModule,
    CreateUpdateRisksModule,
    AssetsDefinitionsModule,
    CoreContractsModule,
    AssetTypesModule,
  ],
  exports: [AssetTypesModule],
  providers: [
    AnalysisService,
    AssetHelperService,
    AssetsHandlerService,
    AssetDetailCreator,
    DefaultValueService,
    FinancialAnalysisDataService,
    RisksSelectionDataService,
    SynchronizationService,
    AnalysisDataService,
    LifeInsuranceDataService,
    LifeinsuranceService,
    RemoveRelatedDataService,
    CoreSyncService,
    CoreSyncCommonService,
  ],
})
export class FinancialAnalysisSharedModule {}
