import {AssetType, BaseAsset, DateAsset, ValueAsset} from '@shared/analysis/models/asset';
import {CommonPropertyAsset} from '@shared/analysis/models/properties';

export interface ImmovablePropertiesSumAsset extends BaseAsset, ValueAsset, DateAsset {
  type: AssetType.ImmovablePropertiesSum;
}

export interface CommonImmovablePropertyAsset extends CommonPropertyAsset {
  /** Druh vlastnictví */
  ownershipType: PropertyOwnershipType;
  /** Celková plocha; units: m^2 */
  totalArea: number;
}

export interface ExtendedImmovablePropertyAsset extends CommonImmovablePropertyAsset {
  /** Plocha nemovitosti; units: m^2 */
  immovablePropertyArea: number;
  /** Plocha domácnosti; units: m^2 */
  householdArea: number;
}

export enum PropertyOwnershipType {
  /** osobní */
  Personal = 'personal',
  /** družstevní */
  Cooperative = 'cooperative',
  /** společné jmění manželů */
  CommunityProperty = 'communityProperty',
  /** jiné */
  Other = 'other',
}

export interface FlatAsset extends ExtendedImmovablePropertyAsset {
  type: AssetType.Flat;
}

export interface HouseAsset extends ExtendedImmovablePropertyAsset {
  type: AssetType.House;
}

export interface HouseholdAsset extends CommonImmovablePropertyAsset {
  type: AssetType.Household;
}

export interface HolidayPropertyAsset extends ExtendedImmovablePropertyAsset {
  type: AssetType.HolidayProperty;
}

export interface ApartmentBuildingAsset extends CommonImmovablePropertyAsset {
  type: AssetType.ApartmentBuilding;
}

export interface GarageAsset extends CommonImmovablePropertyAsset {
  type: AssetType.Garage;
}

export interface LandAsset extends CommonImmovablePropertyAsset {
  type: AssetType.Land;
}

export interface OtherImmovablePropertyAsset extends ExtendedImmovablePropertyAsset {
  type: AssetType.OtherImmovableProperty;
}

export type ImmovablePropertyAsset =
  | ImmovablePropertiesSumAsset
  | FlatAsset
  | HouseAsset
  | HouseholdAsset
  | HolidayPropertyAsset
  | ApartmentBuildingAsset
  | GarageAsset
  | LandAsset
  | OtherImmovablePropertyAsset;
