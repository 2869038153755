import {Component, OnInit} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {FieldArrayType, FormlyFieldConfig} from '@ngx-formly/core';
import {InsuredRisksByDebtor} from '@shared/analysis/models/credit-products';
import {selectAdultFamilyMembers} from '@shared/analysis/operators';
import {isEqual} from 'lodash';
import {distinctUntilChanged, withLatestFrom} from 'rxjs/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-insurance-of-credit-product-formly',
  templateUrl: './insurance-of-credit-product-formly.component.html',
  styleUrls: ['./insurance-of-credit-product-formly.component.scss'],
})
export class InsuranceOfCreditProductFormlyComponent extends FieldArrayType implements OnInit {
  members$ = this.store.pipe(selectAdultFamilyMembers());

  constructor(private store: Store<State>) {
    super();
  }

  async ngOnInit() {
    // Avoid ExpressionChangedAfterItHasBeenCheckedError https://github.com/angular/angular/issues/23657#issuecomment-899646526
    await Promise.resolve();

    this.members$.pipe(untilDestroyed(this)).subscribe(members => {
      const models = members.map(m => this.prepareModel(m)).filter(m => Boolean(m));
      const modelsCount = this.field.model.length;
      for (let i = 0; i < modelsCount; i++) this.remove(0);
      models.forEach(model => this.add(null, model));
    });

    this.form
      .get('debtorUuids')
      .valueChanges.pipe(
        distinctUntilChanged(isEqual),
        withLatestFrom(this.members$),
        untilDestroyed(this),
      )
      .subscribe(([debtorUuids, members]: [string[], FamilyMember[]]) => {
        for (const member of members) {
          const memberIsDebtor = debtorUuids.includes(member.sugarUuid);
          const debtorHasForm = this.formControl.controls.some(
            control => control.get('debtorUuid').value === member.sugarUuid,
          );
          if (memberIsDebtor && !debtorHasForm) {
            this.addDebtor(member.sugarUuid);
          } else if (!memberIsDebtor && debtorHasForm) {
            this.removeDebtor(member.sugarUuid);
          }
        }
      });
  }

  isChecked(debtorUuid: string): boolean {
    return ((this.form.get('debtorUuids').value as string[]) ?? []).includes(debtorUuid);
  }

  getDebtorFieldGroup(debtorUuid: string): FormlyFieldConfig {
    return this.field.fieldGroup.find(fg => fg.fieldGroup[0].model.debtorUuid === debtorUuid);
  }

  private addDebtor(debtorUuid: string) {
    this.add(null, {
      debtorUuid,
      paymentProtectionInsurance: false,
      protectionRiskDeathDebt: false,
      protectionRiskInvalidity: false,
      protectionRiskIncapacity: false,
      protectionRiskLossOfWork: false,
      creditPackageKey: false,
      packageRiskDeathDebt: false,
      packageRiskInvalidity: false,
      packageRiskSeriousDisease: false,
      packageRiskIncapacity: false,
    });
  }

  private removeDebtor(debtorUuid: string) {
    this.remove(
      this.formControl.controls.findIndex(
        control => control.get('debtorUuid').value === debtorUuid,
      ),
    );
  }

  private prepareModel(member: FamilyMember): InsuredRisksByDebtor {
    return this.field.model.find((m: InsuredRisksByDebtor) => m.debtorUuid === member.sugarUuid);
  }
}
