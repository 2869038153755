import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@shared/ui/button/button.module';
import {BannerUIComponent} from './banner-ui.component';

@NgModule({
  declarations: [BannerUIComponent],
  imports: [ButtonModule, CommonModule],
  exports: [BannerUIComponent],
})
export class BannerUIModule {}
