import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ProductActions, ProductActionsUnion} from 'src/store/actions/products.actions';
import {UIActions} from 'src/store/actions/ui.actions';
import {productSetParams} from 'src/store/models/products.models';

@Injectable()
export class ProductsEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProducts.type),
      mergeMap(action => {
        return this.integrationsService
          .products({
            data: productSetParams[action.productSet],
          })
          .pipe(
            map(products =>
              ProductActions.loadProductsSuccess({productSet: action.productSet, products}),
            ),
            catchError(error => {
              console.error(
                `Unable to load products for set ${action.productSet} with paths`,
                productSetParams[action.productSet],
                error,
              );
              return of(UIActions.showFatalError({message: 'Nelze načíst produkty z číselníku.'}));
            }),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions<ProductActionsUnion>,
    private integrationsService: IntegrationsService,
  ) {}
}
