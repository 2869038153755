import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'kpt-confirm-field',
  templateUrl: './confirm-field.component.html',
  styleUrls: ['./confirm-field.component.scss'],
})
export class ConfirmFieldComponent {
  @Input() label: string;
  @Input() controlForm: UntypedFormControl;

  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  keyup(event: KeyboardEvent) {
    if (event.key === 'Escape') this.cancel.emit();
    if (event.key === 'Enter') this.confirm.emit();
  }
}
