import {OnDestroy, OnInit, Directive} from '@angular/core';

import {UntypedFormGroup} from '@angular/forms';
import {DialogService} from 'src/shared/services/modal.service';

@Directive()
export abstract class AbstractFormTemplateComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({});

  constructor(private dialogService: DialogService) {}

  dismiss = () => this.dialogService.dismiss(void 0);

  ngOnInit() {}
  ngOnDestroy() {}
}
