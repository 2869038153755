import {IStepperProgressItem} from '@shared/ui';
import {createStepperProgressConfig} from '@shared/ui/stepper-progress/stepper-progress.utils';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';

export enum InvestmentPage {
  Family = 'family',
  Investment = 'investment',
  Requirements = 'requirements',
  Finances = 'finances',
  Properties = 'properties',
  CurrentSituation = 'current-situation',
  InvestmentSurvey = 'investment-survey',
  AnalysisSummary = 'analysis-summary',
  AdvisorProposed = 'advisor-proposed',
  ClientProposed = 'client-proposed',
  PlanSummary = 'plan-summary',
}

export const investmentPageNames = {
  [InvestmentPage.Family]: 'Rodina',
  [InvestmentPage.Investment]: 'Investice',
  [InvestmentPage.Requirements]: 'Požadavky',
  [InvestmentPage.Finances]: 'Hospodaření',
  [InvestmentPage.Properties]: 'Majetek',
  [InvestmentPage.CurrentSituation]: 'Současné řešení',
  [InvestmentPage.InvestmentSurvey]: 'Dotazník',
  [InvestmentPage.AnalysisSummary]: 'Shrnutí',
  [InvestmentPage.AdvisorProposed]: 'Návrh řešení',
  [InvestmentPage.ClientProposed]: 'Volba klienta',
  [InvestmentPage.PlanSummary]: 'Shrnutí',
};

export function investmentStepperProgressConfig(
  businessCaseStatus: BusinessCaseStatus,
): IStepperProgressItem[] {
  if (!businessCaseStatus) return [];

  const steps: Record<BusinessCaseStatus, InvestmentPage[]> = {
    [BusinessCaseStatus.FinancialAnalysisPreparation]: [
      InvestmentPage.Family,
      InvestmentPage.Investment,
      InvestmentPage.Requirements,
      InvestmentPage.Finances,
      InvestmentPage.Properties,
      InvestmentPage.CurrentSituation,
      InvestmentPage.InvestmentSurvey,
      InvestmentPage.AnalysisSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPreparation]: [
      InvestmentPage.AdvisorProposed,
      InvestmentPage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPresentation]: [
      InvestmentPage.Requirements,
      InvestmentPage.ClientProposed,
      InvestmentPage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanFinalisation]: [],
    [BusinessCaseStatus.FinancialPlanTermination]: [],
  };

  const stepperItems: IStepperProgressItem[] = steps[businessCaseStatus]?.map(url => ({
    url,
    label: investmentPageNames[url],
  }));

  return createStepperProgressConfig(stepperItems, SecuredPage.Client, ClientPage.Investment);
}
