<div [formGroup]="formGroup.get(person.id)">
  <span *ngIf="!readonly">Vyberte rizika, která chcete zajistit:</span>
  <div>
    <table class="data-table">
      <thead>
        <tr>
          <th></th>
          <ng-container *ngIf="!person.child">
            <th>Zajištění příjmů</th>
            <th>Zajištění výdajů</th>
          </ng-container>
          <ng-container *ngIf="person.child">
            <th>Standardní zajištění</th>
            <th>Minimální zajištění</th>
          </ng-container>
          <th>Poznámky</th>
        </tr>
      </thead>
      <ng-container
        *ngFor="
          let risk of person.child
            ? filteredChildrenRisksDefinitions
            : filteredAdultRisksDefinitions
        "
        [formGroupName]="risk.id"
      >
        <tr>
          <td [innerHTML]="risk.label"></td>
          <td>
            <div class="checkbox-cell">
              <span class="icon-wrapper"></span>
              <kpt-checkbox
                *ngIf="formGroup.get([person.id, risk.id, 'income']).enabled; else disabledCheckBox"
                [label]="'&nbsp;'"
                class="risk-checkbox"
                [disabledField]="readonly"
                formControlName="income"
              >
              </kpt-checkbox>
              <span class="icon-wrapper">
                <span
                  *ngIf="showWarnings && getRiskWarning(person.id, risk.id, 'income')"
                  class="icon icon--warning icon--large"
                  [tooltip]="getRiskWarning(person.id, risk.id, 'income')"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <div class="checkbox-cell">
              <span class="icon-wrapper"></span>
              <kpt-checkbox
                *ngIf="
                  formGroup.get([person.id, risk.id, 'expenses']).enabled;
                  else disabledCheckBox
                "
                [label]="'&nbsp;'"
                class="risk-checkbox"
                [disabledField]="readonly"
                formControlName="expenses"
              >
              </kpt-checkbox>
              <span class="icon-wrapper">
                <span
                  *ngIf="showWarnings && getRiskWarning(person.id, risk.id, 'expenses')"
                  class="icon icon--warning icon--large"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <kpt-input formControlName="note" [disabledField]="readonly"></kpt-input>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="!person.child" class="profession-row">
        <td>Máte rizikové povolání?</td>
        <td colspan="2">
          <kpt-radio-group
            formControlName="riskProfession"
            [disabledField]="readonly"
            [options]="[
              {label: 'Ano', key: 'yes'},
              {label: 'Ne', key: 'no'}
            ]"
            [itemClass]="'d-inline-block w-50'"
          >
          </kpt-radio-group>
        </td>
        <td>
          <kpt-input formControlName="riskProfessionNote" [disabledField]="readonly"></kpt-input>
        </td>
      </tr>
    </table>
  </div>
  <kpt-accordion-container customClass="p-0 d-flex align-items-center" *ngIf="!person.child">
    <kpt-reserves-accordion-old
      [formGroup]="formGroup.get(person.id)"
      [personId]="person.id"
      [readonly]="readonly"
    >
    </kpt-reserves-accordion-old>

    <kpt-pension-ensurement-accordion-old
      [formGroup]="formGroup.get(person.id)"
      [person]="person"
      [readonly]="readonly"
    >
    </kpt-pension-ensurement-accordion-old>
  </kpt-accordion-container>
</div>

<ng-template #disabledCheckBox>
  <kpt-checkbox [label]="'&nbsp;'" [disabledField]="true" class="risk-checkbox"></kpt-checkbox>
</ng-template>
