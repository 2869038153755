import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[kptTemplate]',
})
export class PartTemplateDirective {
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('kptTemplate') name: string;
  constructor(public template: TemplateRef<any>) {}
}
