<form [formGroup]="form" *ngIf="form">
  <div class="survey-participants" *ngIf="showMultipleParticipants">
    <kpt-survey-participants [participantsForm]="participantsForm" [participants]="participants">
    </kpt-survey-participants>
  </div>
  <kpt-empty-state class="d-flex align-items-center" *ngIf="getActiveParticipants().length === 0">
    Vyberte členy rodiny, pro které chcete vyplnit dotazník
  </kpt-empty-state>

  <ng-container *ngIf="getActiveParticipants().length > 0">
    <kpt-carousel-stepper
      finishButtonLabel="Vyhodnotit"
      [finishedSteps]="finishedSteps"
      (finishAction)="resolve()"
      [allStepsMustBeFinished]="true"
      [disableSkippingSteps]="true"
      [showFinishButton]="showFinishButton"
      (activeStepChange)="activeStep = $event"
      (beforeActiveStepChange)="checkConsistency($event)"
      [activeStep]="activeStep"
      [class.question-full-width]="getActiveParticipants().length < 2"
      [showSteps]="showSteps"
      [wrapLabels]="true"
    >
      <ng-container *ngFor="let category of questionCategories">
        <kpt-carousel-step
          *ngFor="let question of category.questions"
          [stepGroup]="category.name"
          [stepName]="question.id"
        >
          <h2 *ngIf="!showSteps">{{ category.name }}</h2>
          <kpt-survey-step *ngIf="!question.graph" [question]="question">
            <div
              class="survey-container"
              [ngStyle]="{
                'grid-template-rows': questionRowsStyle(question),
                'grid-template-columns': questionColumnsStyle()
              }"
            >
              <ng-container [ngSwitch]="question.type">
                <ng-container *ngSwitchCase="QuestionType.TABLE">
                  <div></div>
                  <div class="table-header-row"></div>
                  <ng-container *ngFor="let row of question.rows">
                    <div class="table-header-row">
                      {{ row.text }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="QuestionType.CHECKBOX">
                  <div class="survey-header"></div>
                  <ng-container *ngFor="let answer of question.answers">
                    <div class="row table-header-row">
                      {{ answer.text }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="QuestionType.RADIO">
                  <div class="survey-header"></div>
                  <ng-container *ngFor="let option of radioOptions[question.id]">
                    <div class="row table-header-row">
                      {{ option.label }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container
                *ngFor="let person of getActiveParticipants()"
                [formGroup]="form.get(person.sugarUuid)"
              >
                <div class="survey-header">
                  <h3 class="participant-name" *ngIf="person">{{ person.name }}</h3>
                </div>
                <ng-template
                  [ngTemplateOutlet]="questionBody"
                  [ngTemplateOutletContext]="{question: question, person: person, form: form}"
                >
                </ng-template>
              </ng-container>
            </div>
          </kpt-survey-step>
          <kpt-survey-step *ngIf="question.graph" [question]="question">
            <ng-container
              *ngFor="let person of getActiveParticipants()"
              [formGroup]="form.get(person.sugarUuid)"
            >
              <div class="survey-participant-header">
                <h3 class="participant-name" *ngIf="person">{{ person.name }}</h3>
              </div>
              <div
                class="step-img-container title-body"
                *ngIf="question.graph && personalizedGraphQuestions"
              >
                <div class="step-img">
                  <ng-template
                    [ngTemplateOutlet]="personalizedGraph"
                    [ngTemplateOutletContext]="{
                      question: personalizedGraphQuestions[person.sugarUuid]
                    }"
                  >
                  </ng-template>
                </div>
              </div>
              <ng-container *ngFor="let option of radioOptions[question.id]">
                <ng-container *ngFor="let answer of question.answers">
                  <ng-container *ngIf="answer.id === option.key">
                    <div class="row">
                      <kpt-single-radio-button
                        [value]="answer.id"
                        [label]="answer.text"
                        [controlForm]="form.get([person.sugarUuid, question.id, 'answer'])"
                      >
                      </kpt-single-radio-button>
                      <div [class.multi-answer]="answer.additionalAnswerParts">
                        <ng-template
                          [ngTemplateOutlet]="additionalAnswerContent"
                          [ngTemplateOutletContext]="{
                            question: question,
                            answers: answer.additionalAnswerParts,
                            person: person,
                            form: form
                          }"
                        >
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </kpt-survey-step>
        </kpt-carousel-step>
      </ng-container>
    </kpt-carousel-stepper>
  </ng-container>
</form>

<ng-template #questionBody let-question="question" let-person="person" let-form="form">
  <ng-container [formGroup]="form.get([person.sugarUuid, question.id])">
    <ng-container [ngSwitch]="question.type">
      <ng-container *ngSwitchCase="QuestionType.RADIO">
        <ng-container *ngFor="let option of radioOptions[question.id]">
          <ng-container *ngFor="let answer of question.answers">
            <ng-container *ngIf="answer.id === option.key">
              <div class="row survey-row" [class]="answer.customClass">
                <kpt-single-radio-button
                  [value]="answer.id"
                  [controlForm]="form.get([person.sugarUuid, question.id, 'answer'])"
                >
                </kpt-single-radio-button>
                <div [class.multi-answer]="answer.additionalAnswerParts">
                  <ng-template
                    [ngTemplateOutlet]="additionalAnswerContent"
                    [ngTemplateOutletContext]="{
                      question: question,
                      answers: answer.additionalAnswerParts,
                      person: person,
                      form: form
                    }"
                  >
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="QuestionType.CHECKBOX">
        <ng-container *ngFor="let answer of question.answers">
          <div
            [class.multi-answer]="answer.additionalAnswerParts"
            class="row survey-row"
            [class]="answer.customClass"
          >
            <kpt-checkbox [formControl]="form.get([person.sugarUuid, question.id, answer.id])">
            </kpt-checkbox>
            <ng-template
              [ngTemplateOutlet]="additionalAnswerContent"
              [ngTemplateOutletContext]="{
                question: question,
                answers: answer.additionalAnswerParts,
                person: person,
                form: form
              }"
            >
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="QuestionType.TABLE">
        <div class="row table-container">
          <div class="col table-header row" *ngFor="let column of question.answers">
            <label *ngIf="!column.shortText">{{ column.text }}</label>
            <div
              *ngIf="column.shortText"
              [tooltip]="column.text"
              class="custom-tooltip__toggle answer-tooltip"
              type="div"
              container="body"
            >
              {{ column.shortText }}
            </div>
          </div>
        </div>
        <div class="row table-container" *ngFor="let row of question.rows">
          <div
            class="col"
            *ngFor="let column of question.answers"
            [formGroup]="form.get([person.sugarUuid, question.id, row.id])"
            [formGroupName]="row.id"
          >
            <kpt-checkbox
              [formControl]="form.get([person.sugarUuid, question.id, row.id, column.id])"
              label="&nbsp;"
            ></kpt-checkbox>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #additionalAnswerContent
  let-question="question"
  let-answers="answers"
  let-person="person"
  let-form="form"
>
  <ng-container *ngFor="let additionalAnswer of answers" [ngSwitch]="additionalAnswer.type">
    <span
      *ngSwitchCase="AdditionalAnswerType.TEXT"
      [innerHTML]="additionalAnswer.content.text"
    ></span>
    <kpt-input
      *ngSwitchCase="AdditionalAnswerType.INPUT_TEXT"
      [label]=""
      [formControl]="form.get([person.sugarUuid, question.id, additionalAnswer.id])"
    >
    </kpt-input>
    <kpt-input
      *ngSwitchCase="AdditionalAnswerType.INPUT_NUMBER"
      type="NUMBER"
      [label]="additionalAnswer.content.label"
      [showErrorsOn]="false"
      min="1"
      [formControl]="form.get([person.sugarUuid, question.id, additionalAnswer.id])"
    >
    </kpt-input>
    <kpt-select
      *ngSwitchCase="AdditionalAnswerType.INPUT_SELECT"
      [label]="additionalAnswer.content.label"
      [showErrorsOn]="false"
      [options]="additionalAnswer.content.options"
      [formControl]="form.get([person.sugarUuid, question.id, additionalAnswer.id])"
    >
    </kpt-select>
    <kpt-help
      *ngIf="additionalAnswer.content.help"
      [text]="additionalAnswer.content.help"
    ></kpt-help>
  </ng-container>
</ng-template>

<ng-template #personalizedGraph let-question="question">
  <kpt-simple-graph
    *ngIf="question"
    [datasets]="question.graph.datasets"
    [options]="question.graph.options"
    [chartType]="question.graph.type"
    [labels]="question.graph.labels"
  >
  </kpt-simple-graph>
</ng-template>
