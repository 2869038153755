import {formatNumber} from '@angular/common';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {FieldLabel} from '@shared/analysis/field-label';
import {
  Asset,
  AssetType,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
} from '@shared/analysis/models/asset';
import {
  frequencyNames,
  investmentStrategyNames,
  leasingTypeNames,
  liabilityInsuranceRegionNames,
  liabilityInsuranceRiskGroupNames,
  liabilityInsuranceScopeNames,
  participationTypeNames,
  propertyLoanPurposeNames,
} from '@shared/analysis/models/assets.enums';
import {
  BuildingSavingsLoanAsset,
  CommonCreditAsset,
  ConsumerLoanAsset,
  CreditCardAsset,
  InsuredRisksByDebtor,
  LeasingAsset,
  MortgageAsset,
  OtherIndividualLoanAsset,
  OverdraftAsset,
  PropertyLoanAsset,
} from '@shared/analysis/models/credit-products';
import {PaymentInformationAsset} from '@shared/analysis/models/financial-products';
import {
  BondsAsset,
  QualifiedInvestorFundsAsset,
  OtherFinancialPropertyAsset,
  TermDepositAsset,
} from '@shared/analysis/models/financial-properties';
import {
  BusinessInsuranceAsset,
  CapitalLifeInsuranceAsset,
  CommonPropertyInsuranceParticipation,
  InsuredPerson,
  InvestmentLifeInsuranceAsset,
  LiabilityInsuranceAsset,
  LifeInsuranceAsset,
  OtherPropertyInsuranceParticipation,
  OtherRisk,
  PropertyInsuranceAsset,
  PropertyInsuranceParticipation,
  RiskLifeInsuranceAsset,
  TravelInsuranceAsset,
  VehicleInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {
  BuildingSavingsAsset,
  CertificatesAsset,
  CombinedDepositsAsset,
  CommodityAsset,
  CommonInvestmentAsset,
  ExchangeTradedFundsAsset,
  PensionInsuranceAsset,
  RealEstateFundAsset,
  SavingsAccountAsset,
  SupplementaryPensionSavingsAsset,
  UnitTrustAsset,
} from '@shared/analysis/models/investment-products';
import {selectContracts as selectContractAssets} from '@shared/analysis/store';
import {getYearsPluralString, isNilOrEmpty} from '@shared/lib';
import {
  CreditProductInsuranceRisk,
  creditProductInsuranceRisks,
} from '@shared/ui/formly/insurance-of-credit-product-formly/insurance-of-credit-product-formly.models';
import {get} from 'lodash';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
  formatPercent,
  getCheckboxTextValue,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {Category, CategoryType, Item, ItemsGroup, Section} from './summary.models';

export const selectContracts = createSelector(
  selectContractAssets,
  getFamilyMembers,
  (assets, familyMembers) =>
    createSection(
      assets.insuranceProducts,
      assets.investmentProducts,
      assets.creditProducts,
      familyMembers,
    ),
);

function createSection(
  insuranceAssets: Asset[],
  investmentAssets: Asset[],
  creditAssets: Asset[],
  familyMembers: FamilyMember[],
): Section {
  return {
    label: 'Smlouvy',
    categories: [
      createCategory(insuranceAssets, 'Pojistné produkty', familyMembers),
      createCategory(investmentAssets, 'Spořící a investiční produkty', familyMembers),
      createCategory(creditAssets, 'Úvěrové produkty', familyMembers),
    ],
  };
}

function createCategory(assets: Asset[], label: string, familyMembers: FamilyMember[]): Category {
  return {
    label,
    categoryTypes: [createCategoryType(assets, familyMembers)],
  };
}

function createCategoryType(assets: Asset[], familyMembers: FamilyMember[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset, familyMembers)),
  };
}

function createItemsGroup(asset: Asset, familyMembers: FamilyMember[]): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    items: [...createItemFunctions[asset.type](asset, familyMembers)].filter(Boolean),
  };
}

const createItemFunctions: Partial<
  Record<AssetType, (asset: Asset, familyMembers: FamilyMember[]) => Item[]>
> = {
  [AssetType.InvestmentLifeInsurance]: createInvestmentLifeInsuranceItems,
  [AssetType.RiskLifeInsurance]: createRiskLifeInsuranceItems,
  [AssetType.CapitalLifeInsurance]: createCapitalLifeInsuranceItems,
  [AssetType.PropertyInsurance]: createPropertyInsuranceItems,
  [AssetType.LiabilityInsurance]: createLiabilityInsuranceItems,
  [AssetType.VehicleInsurance]: createVehicleInsuranceItems,
  [AssetType.TravelInsurance]: createTravelInsuranceItems,
  [AssetType.BusinessInsurance]: createBusinessInsuranceItems,

  [AssetType.UnitTrust]: createUnitTrustItems,
  [AssetType.RealEstateFund]: createRealEstateFundItems,
  [AssetType.Commodity]: createCommodityItems,
  [AssetType.BuildingSavings]: createBuildingSavingsItems,
  [AssetType.SavingsAccount]: createSavingsAccountItems,
  [AssetType.ExchangeTradedFunds]: createExchangeTradedFundsItems,
  [AssetType.Certificates]: createCertificatesItems,
  [AssetType.CombinedDeposits]: createCombinedDepositsItems,
  [AssetType.TermDeposit]: createTermDepositItems,
  [AssetType.Bonds]: createBondsItems,
  [AssetType.QualifiedInvestorFunds]: createQualifiedInvestorFundsItems,
  [AssetType.SupplementaryPensionSavings]: createSupplementaryPensionSavingsItems,
  [AssetType.PensionInsurance]: createPensionInsuranceItems,
  [AssetType.OtherFinancialProperty]: createOtherFinancialPropertyItems,

  [AssetType.Mortgage]: createMortgageItems,
  [AssetType.BuildingSavingsLoan]: createBuildingSavingsLoanItems,
  [AssetType.Leasing]: createLeasingItems,
  [AssetType.CreditCard]: createCreditCardItems,
  [AssetType.Overdraft]: createOverdraftItems,
  [AssetType.ConsumerLoan]: createConsumerLoanItems,
  [AssetType.OtherIndividualLoan]: createOtherIndividualLoanItems,
};

function createInvestmentLifeInsuranceItems(
  asset: InvestmentLifeInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  const items = [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.FUTURE_VALUE, formatCZKNotNull(asset.futureValue)),
    createItem(FieldLabel.FUTURE_VALUE_DATE, formatDate(asset.futureValueDate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.EMPLOYER_CONTRIBUTION, formatCZKNotNull(asset.employerContribution)),
    createItem(FieldLabel.EMPLOYER_NAME, asset.employerName),
    createItem(
      FieldLabel.EMPLOYER_ID,
      asset.employerID ? formatNumber(asset.employerID, 'cs-CZ') : null,
    ),
    createItem(FieldLabel.EMPLOYER_CONTACT, asset.employerContact),
  ];
  return [
    ...items,
    ...fillRemainingRowSpace(items.filter(i => i).length),
    ...createLifeInsuranceInsuredPersonItems(asset, familyMembers),
    ...getPaymentInformationItems(asset),
  ];
}

function createRiskLifeInsuranceItems(
  asset: RiskLifeInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  const items = [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
  ];

  return [
    ...items,
    ...fillRemainingRowSpace(items.filter(i => i).length),
    ...createLifeInsuranceInsuredPersonItems(asset, familyMembers),
    ...getPaymentInformationItems(asset),
  ];
}

function createCapitalLifeInsuranceItems(
  asset: CapitalLifeInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  const items = [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.FUTURE_VALUE, formatCZKNotNull(asset.futureValue)),
    createItem(FieldLabel.FUTURE_VALUE_DATE, formatDate(asset.futureValueDate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.EMPLOYER_CONTRIBUTION, formatCZKNotNull(asset.employerContribution)),
  ];

  return [
    ...items,
    ...fillRemainingRowSpace(items.filter(i => i).length),
    ...createLifeInsuranceInsuredPersonItems(asset, familyMembers),
    ...getPaymentInformationItems(asset),
  ];
}

function createPropertyInsuranceItems(
  asset: PropertyInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  const otherParticipation: Item[] = [];
  if (asset.otherInsuranceParticipations) {
    otherParticipation.push(
      ...asset.otherInsuranceParticipations
        .map(p => createOtherParticipationItems(p))
        .reduce((items, i) => [...items, ...i], []),
    );
  }
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.RELATED_PROPERTY, null, asset.relatedPropertyUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.REQUIRED_INSURED_AMOUNT, formatCZKNotNull(asset.requiredInsuredAmount)),
    createItem(FieldLabel.HOUSEHOLD_VALUE, formatCZKNotNull(asset.household)),
    createItem(FieldLabel.CIVIL_LIABILITY, formatCZKNotNull(asset.civilLiability)),
    createItem(FieldLabel.HOLDING_INSURANCE, formatCZKNotNull(asset.holdingInsurance)),
    ...createOtherRiskItems(asset.otherRisks),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...createParticipationItems(asset),
    ...otherParticipation,
    ...createOtherRiskItems(asset.otherRisks),
    ...getPaymentInformationItems(asset),
  ];
}

function createLiabilityInsuranceItems(
  asset: LiabilityInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.PROFESSION, asset.profession),
    createItem(
      FieldLabel.RISK_GROUP,
      asset.riskGroup && liabilityInsuranceRiskGroupNames[asset.riskGroup],
    ),
    createItem(
      FieldLabel.INSURANCE_SCOPE,
      asset.scope && liabilityInsuranceScopeNames[asset.scope],
    ),
    createItem(FieldLabel.REGION, asset.region && liabilityInsuranceRegionNames[asset.region]),
    createItem(FieldLabel.INSURANCE_LIMIT, formatCZKNotNull(asset.limit)),
    ...createCommonParticipationItems(asset),
    ...getPaymentInformationItems(asset),
  ];
}

function createVehicleInsuranceItems(
  asset: VehicleInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  const formatCZKInMil = (value: number): string => {
    if (isNilOrEmpty(value)) return;
    return `${formatNumber(asset.compulsoryInsuranceHealthInMil, 'cs-CZ', '1.0-0')} mil. Kč`;
  };
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.RELATED_PROPERTY, null, asset.relatedPropertyUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(
      FieldLabel.COMPULSORY_INSURANCE_HEALTH,
      formatCZKInMil(asset.compulsoryInsuranceHealthInMil),
    ),
    createItem(
      FieldLabel.COMPULSORY_INSURANCE_PROPERTY,
      formatCZKInMil(asset.compulsoryInsurancePropertyInMil),
    ),
    createItem(FieldLabel.ACCIDENT_INSURANCE_TOTAL, formatCZKNotNull(asset.accidentInsuranceTotal)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.POLICYHOLDER, getCheckboxTextValue(asset.policyholder)),
    createItem(FieldLabel.HOLDER, getCheckboxTextValue(asset.holder)),
    createItem(FieldLabel.OWNER, getCheckboxTextValue(asset.owner)),
    createItem(
      FieldLabel.WINDSHIELD,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.windshield),
    ),
    createItem(
      FieldLabel.ALL_WINDOWS,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.allWindows),
    ),
    createItem(FieldLabel.BAGGAGE, formatCZKNotNull(asset.vehicleSupplementaryInsurance.baggage)),
    createItem(
      FieldLabel.ACCIDENT_ASSISTANCE,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.accidentAssistance),
    ),
    createItem(
      FieldLabel.LEGAL_PROTECTION,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.legalProtection),
    ),
    createItem(
      FieldLabel.ANIMAL_DAMAGE,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.animalDamage),
    ),
    createItem(
      FieldLabel.VANDALISM,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.vandalism),
    ),
    createItem(
      FieldLabel.NATURAL_DISASTERS,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.naturalDisasters),
    ),
    createItem(
      FieldLabel.UNINTENTIONAL_ACCIDENT_INSURANCE,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.unintentionalAccidentInsurance),
    ),
    createItem(
      FieldLabel.REPLACEMENT_VEHICLE,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.replacementVehicle),
    ),
    createItem(
      FieldLabel.NON_STANDARD_EQUIPMENT,
      formatCZKNotNull(asset.vehicleSupplementaryInsurance.nonStandardEquipment),
    ),
    createItem(FieldLabel.ALARM, getCheckboxTextValue(asset.vehicleAccidentInsurance.alarm)),
    createItem(
      FieldLabel.IMMOBILIZER,
      getCheckboxTextValue(asset.vehicleAccidentInsurance.immobilizer),
    ),
    createItem(
      FieldLabel.MECHANICAL_SECURITY,
      getCheckboxTextValue(asset.vehicleAccidentInsurance.mechanicalSecurity),
    ),
    createItem(
      FieldLabel.ACTIVE_SEARCH_SYSTEM,
      getCheckboxTextValue(asset.vehicleAccidentInsurance.activeSearchSystem),
    ),
    createItem(
      FieldLabel.VIN_ON_GLASS,
      getCheckboxTextValue(asset.vehicleAccidentInsurance.vinOnGlass),
    ),
    ...createCommonParticipationItems(asset),
    ...createOtherRiskItems(asset.otherRisks),
    ...getPaymentInformationItems(asset),
  ];
}

function createTravelInsuranceItems(
  asset: TravelInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(
      FieldLabel.INSURED_PERSONS,
      getFamilyMembersNames(asset.insuredPersonUuids, familyMembers),
    ),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(
      FieldLabel.MEDICAL_EXPENSES_INSURANCE,
      formatCZKNotNull(asset.medicalExpensesInsurance),
    ),
    createItem(FieldLabel.INSURANCE_UZO, formatCZKNotNull(asset.insuranceUzo)),
    createItem(FieldLabel.TERRITORIAL_SCOPE, asset.territorialScope),
    createItem(FieldLabel.RISK_SPORTS, getCheckboxTextValue(asset.riskSports)),
    createItem(FieldLabel.RISK_SPORTS_SPECIFICATION, asset.riskSportsSpecification),
    ...createOtherRiskItems(asset.otherRisks),
    ...getPaymentInformationItems(asset),
  ];
}

function createBusinessInsuranceItems(
  asset: BusinessInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...createCommonParticipationItems(asset),
    ...createOtherRiskItems(asset.otherRisks),
    ...getPaymentInformationItems(asset),
  ];
}

function createUnitTrustItems(asset: UnitTrustAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.STRATEGY, investmentStrategyNames[asset.strategy]),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.TARGET_AMOUNT, formatCZKNotNull(asset.targetAmount)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createRealEstateFundItems(
  asset: RealEstateFundAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createCommodityItems(asset: CommodityAsset, familyMembers: FamilyMember[]): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createExchangeTradedFundsItems(
  asset: ExchangeTradedFundsAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createCertificatesItems(asset: CertificatesAsset, familyMembers: FamilyMember[]): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createCombinedDepositsItems(
  asset: CombinedDepositsAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createBuildingSavingsItems(
  asset: BuildingSavingsAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createBasicInvestmentItems(asset, familyMembers);
}

function createSavingsAccountItems(
  asset: SavingsAccountAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createBasicInvestmentItems(asset, familyMembers, FieldLabel.REGULAR_DEPOSIT);
}

// TODO unify createTermDepositItems, createBondsItems and createQualifiedInvestorFundsItems
function createTermDepositItems(asset: TermDepositAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createBondsItems(asset: BondsAsset, familyMembers: FamilyMember[]): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createQualifiedInvestorFundsItems(
  asset: QualifiedInvestorFundsAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createSupplementaryPensionSavingsItems(
  asset: SupplementaryPensionSavingsAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.EMPLOYER_CONTRIBUTION, formatCZKNotNull(asset.employerContribution)),
    createItem(
      FieldLabel.CONSERVATION_POSSIBILITY,
      getCheckboxTextValue(asset.conservationPossibility),
    ),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.STRATEGY, investmentStrategyNames[asset.strategy]),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.TARGET_AMOUNT, formatCZKNotNull(asset.targetAmount)),
    ...getPaymentInformationItems(asset),
  ];
}

function createPensionInsuranceItems(
  asset: PensionInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.TARGET_AMOUNT, formatCZKNotNull(asset.targetAmount)),
    ...getPaymentInformationItems(asset),
  ];
}

function createMortgageItems(asset: MortgageAsset, familyMembers: FamilyMember[]): Item[] {
  return createPropertyLoanItems(asset, familyMembers, FieldLabel.ORIGINAL_MORTGAGE_VALUE);
}

function createBuildingSavingsLoanItems(
  asset: BuildingSavingsLoanAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return createPropertyLoanItems(asset, familyMembers, FieldLabel.ORIGINAL_LOAN_VALUE);
}

function createLeasingItems(asset: LeasingAsset, familyMembers: FamilyMember[]): Item[] {
  return createCommonCreditItems(
    asset,
    familyMembers,
    createItem(FieldLabel.LEASING_TYPE, asset.leasingType && leasingTypeNames[asset.leasingType]),
    false,
    true,
  );
}

function createCreditCardItems(asset: CreditCardAsset, familyMembers: FamilyMember[]): Item[] {
  return createCommonCreditItems(
    asset,
    familyMembers,
    createItem(FieldLabel.CREDIT_LIMIT, formatCZKNotNull(asset.creditLimit)),
  );
}

function createOverdraftItems(asset: OverdraftAsset, familyMembers: FamilyMember[]): Item[] {
  return createCommonCreditItems(
    asset,
    familyMembers,
    createItem(FieldLabel.CREDIT_LIMIT, formatCZKNotNull(asset.creditLimit)),
  );
}

function createConsumerLoanItems(asset: ConsumerLoanAsset, familyMembers: FamilyMember[]): Item[] {
  return createCommonCreditItems(
    asset,
    familyMembers,
    createItem(FieldLabel.ORIGINAL_BANK, asset.originalBank),
    false,
    true,
  );
}

function createOtherIndividualLoanItems(
  asset: OtherIndividualLoanAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.RELATED_PROPERTY, null, asset.relatedPropertyUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.OUTSTANDING_VALUE, formatCZKNotNull(asset.outstandingValue)),
    createItem(FieldLabel.OUTSTANDING_VALUE_DATE, formatDate(asset.outstandingValueDate)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.PURPOSE, asset.purpose),
    createItem(FieldLabel.ORIGINAL_VALUE, formatCZKNotNull(asset.originalValue)),
    createItem(FieldLabel.REINSURANCE, getCheckboxTextValue(asset.reinsurance)),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createOtherRiskItems(otherRisks: OtherRisk[]): Item[] {
  return otherRisks.map(risk => ({label: risk.label, value: formatCZKNotNull(risk.value)}));
}

function createCommonParticipationItems(
  participation: CommonPropertyInsuranceParticipation,
): Item[] {
  const items: Item[] = [];
  items.push(
    createItem(FieldLabel.PARTICIPATION, formatPercent(participation.percentParticipation)),
  );
  items.push(
    createItem(
      FieldLabel.PARTICIPATION_TYPE,
      participationTypeNames[participation.participationType],
    ),
  );
  items.push(
    createItem(FieldLabel.PARTICIPATION_VALUE, formatCZKNotNull(participation.participationValue)),
  );
  return items;
}

function createParticipationItems(participation: PropertyInsuranceParticipation): Item[] {
  const items: Item[] = [];
  items.push(createItem(FieldLabel.PLACE_OF_INSURANCE, participation.placeOfInsurance));
  items.push(createItem(FieldLabel.INSURANCE_TYPE, participation.insuranceType));
  items.push(...createCommonParticipationItems(participation));
  return items;
}

function createOtherParticipationItems(participation: OtherPropertyInsuranceParticipation): Item[] {
  const items: Item[] = [];
  items.push(createItem(FieldLabel.PARTICIPATION_NAME, participation.label));
  items.push(...createParticipationItems(participation));
  return items;
}

function getFamilyMembersNames(personIds: string[], familyMembers: FamilyMember[]): string {
  if (!personIds) return;
  return familyMembers
    .filter(member => personIds.includes(member.sugarUuid))
    .map(member => member.firstName)
    .join(', ');
}

function getFamilyMemberName(personId: string, familyMembers: FamilyMember[]): string {
  return familyMembers.find(member => member.sugarUuid === personId)?.firstName;
}

function getPaymentInformationItems(asset: PaymentInformationAsset): Item[] {
  return [
    createItem(FieldLabel.BANK_ACCOUNT_NUMBER, asset.bankAccountNumber),
    createItem(FieldLabel.VARIABLE_SYMBOL, asset.variableSymbol),
    createItem(FieldLabel.CONTRACT_PAYMENT_START, formatDate(asset.paymentStart)),
    createItem(FieldLabel.CONTRACT_PAYMENT_END, formatDate(asset.paymentEnd)),
    createItem(FieldLabel.CONTRACT_PAYMENT_DAY, asset.paymentDay && `${asset.paymentDay}.`),
    createItem(FieldLabel.CONTRACT_PAYMENT_NOTE, asset.paymentNote),
  ];
}

function createLifeInsuranceInsuredPersonItems(
  asset: LifeInsuranceAsset,
  familyMembers: FamilyMember[],
): Item[] {
  return asset.insuredPersons
    .map(p => createLifeInsuranceRiskItems(p, familyMembers))
    .reduce((items, i) => [...items, ...i], []);
}

function createLifeInsuranceRiskItems(
  insuredPerson: InsuredPerson,
  familyMembers: FamilyMember[],
): Item[] {
  const items = [
    createItem(FieldLabel.DEATH_DEBT_REPAYMENT, formatCZKNotNull(insuredPerson.deathDebtRepayment)),
    createItem(
      FieldLabel.DEATH_THRICE_ANNUAL_INCOME,
      formatCZKNotNull(insuredPerson.deathThriceAnnualIncome),
    ),
    createItem(
      FieldLabel.DEATH_CHILDREN_ENSUREMENT,
      formatCZKNotNull(insuredPerson.deathChildrenEnsurement),
    ),
    createItem(FieldLabel.SERIOUS_DISEASE, formatCZKNotNull(insuredPerson.seriousDisease)),
    createItem(FieldLabel.INVALIDITY, formatCZKNotNull(insuredPerson.invalidity)),
    createItem(
      FieldLabel.INVALIDITY_DEBT_REPAYMENT,
      formatCZKNotNull(insuredPerson.invalidityDebtRepayment),
    ),
    createItem(FieldLabel.HOSPITALIZATION, formatCZKNotNull(insuredPerson.hospitalization)),
    createItem(FieldLabel.INCAPACITY, formatCZKNotNull(insuredPerson.incapacity)),
    createItem(
      FieldLabel.PERMANENT_DISABILITY,
      formatCZKNotNull(insuredPerson.permanentDisability),
    ),
    createItem(FieldLabel.DAILY_COMPENSATION, formatCZKNotNull(insuredPerson.dailyCompensation)),
    createItem(FieldLabel.PHYSICAL_DAMAGE, formatCZKNotNull(insuredPerson.physicalDamage)),
    ...createOtherRiskItems(insuredPerson.otherRisks),
  ];
  if (items.filter(i => i).length === 0) return [];
  return [
    createItem(
      FieldLabel.INSURED_PERSON,
      getFamilyMemberName(insuredPerson.insuredPersonUuid, familyMembers),
    ),
    {label: '', value: ''},
    {label: '', value: ''},
    ...items,
    ...fillRemainingRowSpace(items.filter(i => i).length),
  ];
}

function createBasicInvestmentItems(
  asset: CommonInvestmentAsset,
  familyMembers: FamilyMember[],
  valueLabel: string = FieldLabel.CONTRACT_PAYMENT,
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(FieldLabel.PRESENT_VALUE, formatCZKNotNull(asset.presentValue)),
    createItem(FieldLabel.PRESENT_VALUE_DATE, formatDate(asset.presentValueDate)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(valueLabel, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(FieldLabel.TARGET_AMOUNT, formatCZKNotNull(asset.targetAmount)),
    ...getPaymentInformationItems(asset),
  ];
}

function createPropertyLoanItems(
  asset: PropertyLoanAsset,
  familyMembers: FamilyMember[],
  originalValueLabel: string,
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    createItem(originalValueLabel, formatCZKNotNull(asset.originalValue)),
    createItem(FieldLabel.RELATED_OBJECTIVE, null, asset.relatedObjectiveUuid),
    createItem(FieldLabel.RELATED_PROPERTY, null, asset.relatedPropertyUuid),
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.OUTSTANDING_VALUE, formatCZKNotNull(asset.outstandingValue)),
    createItem(FieldLabel.OUTSTANDING_VALUE_DATE, formatDate(asset.outstandingValueDate)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    createItem(FieldLabel.NEAREST_FIXATION_DATE, formatDate(asset.nearestFixationDate)),
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    createItem(
      FieldLabel.CONTRACT_PURPOSE,
      asset.purpose && propertyLoanPurposeNames[asset.purpose],
    ),
    createItem(
      FieldLabel.FIXATION_IN_YEARS,
      asset.fixationInYears && getYearsPluralString(asset.fixationInYears),
    ),
    createItem(FieldLabel.ORIGINAL_BANK, asset.originalBank),
    createItem(FieldLabel.ORIGINAL_LTV, asset.originalLtv && `${asset.originalLtv} %`),
    createItem(FieldLabel.PROPERTY_ESTIMATED_VALUE, formatCZKNotNull(asset.propertyEstimatedValue)),
    createItem(
      FieldLabel.HAS_REAL_ESTATE_INSURANCE,
      getCheckboxTextValue(asset.hasRealEstateInsurance),
    ),
    createItem(FieldLabel.REAL_ESTATE_INSURANCE, asset.realEstateInsurance),
    createItem(FieldLabel.DEBTORS, getFamilyMembersNames(asset.debtorUuids, familyMembers)),
    createItem(FieldLabel.OTHER_DEBTORS, asset.otherDebtors),
    ...getPaymentProtectionInsuranceItems(asset),
    ...getPaymentInformationItems(asset),
  ];
}

function getPaymentProtectionInsuranceItems(asset: PropertyLoanAsset): Item[] {
  function riskGroup(
    risks: InsuredRisksByDebtor,
    type: CreditProductInsuranceRisk['type'],
  ): string {
    const group = creditProductInsuranceRisks.find(r => r.type === type && r.group);
    if (get(risks, group.key)) {
      const names = riskNames(risks, type).join(', ');
      return `${group.label}${names ? ' (' + names + ')' : ''}`;
    } else {
      return '';
    }
  }

  function riskNames(
    risks: InsuredRisksByDebtor,
    type: CreditProductInsuranceRisk['type'],
  ): string[] {
    return creditProductInsuranceRisks
      .filter(r => get(risks, r.key) && r.type === type && !r.group)
      .map(r => r.label);
  }

  return asset.insuredRisksByDebtors.map(risks => {
    const value = [riskGroup(risks, 'protection'), riskGroup(risks, 'package')]
      .filter(Boolean)
      .join(', ');
    return createItem(FieldLabel.INSURANCE_OF_CREDIT_PRODUCT, value);
  });
}

function createCommonCreditItems(
  asset: CommonCreditAsset,
  familyMembers: FamilyMember[],
  extraItem: Item,
  hasRelatedObjectiveAsset = false,
  hasRelatedPropertyAsset = false,
): Item[] {
  return [
    createItem(FieldLabel.CONTRACT_NUMBER, asset.contractNumber),
    hasRelatedObjectiveAsset
      ? createItem(
          FieldLabel.RELATED_OBJECTIVE,
          null,
          (asset as unknown as RelatedObjectiveAsset).relatedObjectiveUuid,
        )
      : null,
    hasRelatedPropertyAsset
      ? createItem(
          FieldLabel.RELATED_PROPERTY,
          null,
          (asset as unknown as RelatedPropertyAsset).relatedPropertyUuid,
        )
      : null,
    createItem(FieldLabel.STAKEHOLDER, getFamilyMemberName(asset.stakeholderUuid, familyMembers)),
    createItem(FieldLabel.CONTRACT_PAYMENT, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FREQUENCY, frequencyNames[asset.frequency]),
    createItem(FieldLabel.OUTSTANDING_VALUE, formatCZKNotNull(asset.outstandingValue)),
    createItem(FieldLabel.OUTSTANDING_VALUE_DATE, formatDate(asset.outstandingValueDate)),
    createItem(FieldLabel.YEARLY_RATE, formatPercent(asset.yearlyRate)),
    extraItem,
    createItem(FieldLabel.CONTRACT_START, formatDate(asset.start)),
    createItem(FieldLabel.CONTRACT_END, formatDate(asset.end as string)),
    createItem(FieldLabel.GOAL, asset.goal),
    createItem(FieldLabel.NOTES, asset.note),
    ...getPaymentInformationItems(asset),
  ];
}

function createOtherFinancialPropertyItems(asset: OtherFinancialPropertyAsset): Item[] {
  return [
    createItem(FieldLabel.FINANCIAL_PROPERTY_VALUE, formatCZKNotNull(asset.value)),
    createItem(FieldLabel.FINANCIAL_PROPERTY_START, formatDate(asset.start)),
    createItem(FieldLabel.INSTITUTION_NAME, asset.institutionName),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function fillRemainingRowSpace(arrayLength: number): Item[] {
  const remainder = arrayLength % 3;
  if (remainder === 0) return [];
  return Array(3 - remainder).fill({label: '', value: ''});
}
