import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DeleteFilesModule} from '@generated/store/integrations/deleteFiles/deleteFiles.module';
import {TooltipModule} from '@lib/components';
import {CommonBaseModule, ContentBoxModule} from '@shared/lib';
import {AttachmentsModule} from '@shared/lib/components/attachments/attachments.module';
import {ButtonModule, FormModule, HelpModule, SortModule} from '@shared/ui';
import {ClickOutsideModule} from 'ng-click-outside';
import {FileUploadModule} from 'ng2-file-upload';
import {AnalysisChangedAlertModule} from '../shared/components/analysis-changed-alert/analysis-changed-alert.module';
import {HeaderColumnComponent} from './header-column/header-column.component';
import {AttachedFileComponent} from './insurance-group/attached-file/attached-file.component';
import {AttachedFilesComponent} from './insurance-group/attached-files/attached-files.component';
import {InsuranceGroupComponent} from './insurance-group/insurance-group.component';
import {PrioritiesResultsComponent} from './priorities-results/priorities-results.component';
import {ProductHeaderComponent} from './product-header/product-header.component';
import {ProposalsComponent} from './proposals.component';
import {SumColumnComponent} from './sum-column/sum-column.component';

@NgModule({
  declarations: [
    ProposalsComponent,
    InsuranceGroupComponent,
    AttachedFilesComponent,
    AttachedFileComponent,
    HeaderColumnComponent,
    PrioritiesResultsComponent,
    SumColumnComponent,
    ProductHeaderComponent,
  ],
  imports: [
    CommonModule,
    CommonBaseModule,
    ContentBoxModule,
    FormModule,
    HelpModule,
    ReactiveFormsModule,
    ButtonModule,
    ClickOutsideModule,
    TooltipModule,
    FileUploadModule,
    DeleteFilesModule,
    SortModule,
    AttachmentsModule,
    AnalysisChangedAlertModule,
  ],
  exports: [PrioritiesResultsComponent],
})
export class ProposalsModule {}
