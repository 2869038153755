import {createSelector} from '@ngrx/store';
import {
  AdvisorProposalState,
  Asset,
  AssetType,
  ClientProposalState,
  RelatedObjectiveAsset,
  RelatedPropertyAsset,
} from '@shared/analysis/models/asset';
import {StakeholderAsset} from '@shared/analysis/models/financial-products';
import {getObjectiveProductAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {selectAllAssets, selectCurrentAssets} from '@shared/analysis/store';
import {isNil} from 'lodash';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';

export const selectUnassignedAssets = (
  objectiveType: ObjectiveType,
  relatedPropertyUuid: string,
  stakeholderUuid: string,
) => {
  return createSelector(selectCurrentAssets, (currentAssets): Asset[] => {
    const products = getObjectiveProductAssets(objectiveType);
    const productAssets = currentAssets.filter(asset => products.includes(asset.type));

    if (objectiveType === ObjectiveType.Family) {
      return productAssets.filter(
        asset =>
          (isNil((asset as RelatedObjectiveAsset).relatedObjectiveUuid) &&
            (asset as StakeholderAsset).stakeholderUuid === stakeholderUuid) ||
          isNil((asset as StakeholderAsset).stakeholderUuid),
      );
    }

    if (objectiveType === ObjectiveType.Property || objectiveType === ObjectiveType.Vehicle) {
      return productAssets.filter(
        asset =>
          (isNil((asset as RelatedObjectiveAsset).relatedObjectiveUuid) &&
            (asset as RelatedPropertyAsset).relatedPropertyUuid === relatedPropertyUuid) ||
          isNil((asset as RelatedPropertyAsset).relatedPropertyUuid),
      );
    }

    return productAssets.filter(asset =>
      isNil((asset as RelatedObjectiveAsset).relatedObjectiveUuid),
    );
  });
};

export const selectAssignedAssets = (
  objectiveType: ObjectiveType,
  relatedObjectiveUuid: string,
  situation: Situation,
) => {
  return createSelector(
    selectCurrentAssets,
    selectAllAssets,
    (currentAssets, allAssets): Asset[] => {
      const products =
        [ObjectiveType.Reserve].includes(objectiveType) && situation === Situation.Current
          ? getObjectiveProductAssets(objectiveType).filter(
              type =>
                type !== AssetType.InvestmentLifeInsurance &&
                type !== AssetType.CapitalLifeInsurance,
            )
          : getObjectiveProductAssets(objectiveType);

      return currentAssets.filter(
        asset =>
          products.includes(asset.type) &&
          (asset as RelatedObjectiveAsset).relatedObjectiveUuid === relatedObjectiveUuid &&
          (situation === Situation.Current ? !asset.proposal : asset.proposal) &&
          !isAssetAlreadyAssigned(asset.assetUuid, allAssets),
      );
    },
  );
};

function isAssetAlreadyAssigned(assetUuid: string, assets: Asset[]): boolean {
  const currentAsset = assets.find(a => a.assetUuid === assetUuid);
  if (!currentAsset) return false;
  if (currentAsset.advisorProposalState === AdvisorProposalState.Unchanged) return true;
  const proposedAsset = assets.find(a => a.originalAssetUuid === currentAsset.assetUuid);
  if (!proposedAsset) return false;
  return proposedAsset.advisorProposalState === AdvisorProposalState.Updated;
}

export const isProposalForbidden = (assetUuid: string, objectiveType: ObjectiveType) =>
  createSelector(selectAllAssets, (assets): boolean => {
    const asset = assets.find(a => a.assetUuid === assetUuid);
    if (!asset) return false;
    const assetType = asset.type;
    return (
      (assetType === AssetType.InvestmentLifeInsurance ||
        assetType === AssetType.CapitalLifeInsurance) &&
      objectiveType === ObjectiveType.Reserve
    );
  });

export const selectAdvisorOrClientProposedAsset = (assetUuid: string) =>
  createSelector(
    selectAllAssets,
    (assets): Asset => assets.find(a => a.originalAssetUuid === assetUuid),
  );

export const selectAdvisorProposedAsset = (assetUuid: string) =>
  createSelector(
    selectAllAssets,
    (assets): Asset =>
      assets.find(
        a =>
          a.originalAssetUuid === assetUuid &&
          a.advisorProposalState === AdvisorProposalState.Updated,
      ),
  );

export const selectIsDecisionMadeByAdvisor = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isDecisionMadeByAdvisor(assetUuid, assets));

export function isDecisionMadeByAdvisor(assetUuid: string, assets: Asset[]): boolean {
  return (
    isAssetAcceptedByAdvisor(assetUuid, assets) ||
    isAssetUpdatedByAdvisor(assetUuid, assets) ||
    isAssetTerminatedByAdvisor(assetUuid, assets)
  );
}

export const selectIsAssetAcceptedByAdvisor = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetAcceptedByAdvisor(assetUuid, assets));

export function isAssetAcceptedByAdvisor(assetUuid: string, assets: Asset[]): boolean {
  const asset = assets.find(a => a.assetUuid === assetUuid);
  if (!asset) return false;

  return asset.advisorProposalState === AdvisorProposalState.Unchanged;
}

export const selectIsAssetUpdatedByAdvisor = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetUpdatedByAdvisor(assetUuid, assets));

export function isAssetUpdatedByAdvisor(assetUuid: string, assets: Asset[]): boolean {
  const proposedAsset = assets.find(a => a.originalAssetUuid === assetUuid);
  if (!proposedAsset) return false;

  return proposedAsset.advisorProposalState === AdvisorProposalState.Updated;
}

export const selectIsAssetTerminatedByAdvisor = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetTerminatedByAdvisor(assetUuid, assets));

export function isAssetTerminatedByAdvisor(assetUuid: string, assets: Asset[]): boolean {
  const asset = assets.find(a => a.assetUuid === assetUuid);
  if (!asset) return false;

  return asset.advisorProposalState === AdvisorProposalState.Terminated;
}

export const selectIsDecisionMadeByClient = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isDecisionMadeByClient(assetUuid, assets));

export function isDecisionMadeByClient(assetUuid: string, assets: Asset[]): boolean {
  return (
    isAssetAcceptedByClient(assetUuid, assets) ||
    isAssetUpdatedByClient(assetUuid, assets) ||
    isAssetRejectedByClient(assetUuid, assets) ||
    isAssetTerminatedByClient(assetUuid, assets)
  );
}

export const selectIsAssetAcceptedByClient = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetAcceptedByClient(assetUuid, assets));

export function isAssetAcceptedByClient(assetUuid: string, assets: Asset[]): boolean {
  const asset = assets.find(a => a.assetUuid === assetUuid);
  if (!asset) return false;

  const newAccepted =
    asset.advisorProposalState === AdvisorProposalState.New &&
    asset.clientProposalState === ClientProposalState.NewClientApproved;
  const unchangedAccepted =
    asset.advisorProposalState === AdvisorProposalState.Unchanged &&
    asset.clientProposalState === ClientProposalState.Unchanged;
  const updatedAccepted =
    asset.advisorProposalState === AdvisorProposalState.Updated &&
    asset.clientProposalState === ClientProposalState.AdvisorUpdated;
  const terminatedAccepted =
    asset.advisorProposalState === AdvisorProposalState.Terminated &&
    asset.clientProposalState === ClientProposalState.AdvisorTerminated;

  return newAccepted || unchangedAccepted || updatedAccepted || terminatedAccepted;
}

export const selectIsAssetUpdatedByClient = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetUpdatedByClient(assetUuid, assets));

export function isAssetUpdatedByClient(assetUuid: string, assets: Asset[]): boolean {
  const proposedAsset = assets.find(a => a.originalAssetUuid === assetUuid);
  if (!proposedAsset) return false;

  return (
    proposedAsset.clientProposalState === ClientProposalState.ClientUpdated ||
    proposedAsset.clientProposalState === ClientProposalState.NewClientUpdated
  );
}

export const selectIsAssetTerminatedByClient = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetTerminatedByClient(assetUuid, assets));

export function isAssetTerminatedByClient(assetUuid: string, assets: Asset[]): boolean {
  const asset = assets.find(a => a.assetUuid === assetUuid);
  if (!asset) return false;

  const currentAsset = assets.find(a => a.assetUuid === asset.originalAssetUuid);

  const unchangedTerminated =
    asset.advisorProposalState === AdvisorProposalState.Unchanged &&
    asset.clientProposalState === ClientProposalState.ClientTerminated;
  const updatedTerminated =
    asset.advisorProposalState === AdvisorProposalState.Updated &&
    currentAsset &&
    currentAsset.clientProposalState === ClientProposalState.ClientTerminated;
  const newRejected =
    asset.advisorProposalState === AdvisorProposalState.New &&
    asset.clientProposalState === ClientProposalState.NewClientRejected;

  return unchangedTerminated || updatedTerminated || newRejected;
}

export const selectIsAssetRejectedByClient = (assetUuid: string) =>
  createSelector(selectAllAssets, assets => isAssetRejectedByClient(assetUuid, assets));

export function isAssetRejectedByClient(assetUuid: string, assets: Asset[]): boolean {
  const asset = assets.find(a => a.assetUuid === assetUuid);
  if (!asset) return false;

  const currentAsset = assets.find(a => a.assetUuid === asset.originalAssetUuid);

  const updatedRejected =
    asset.advisorProposalState === AdvisorProposalState.Updated &&
    currentAsset &&
    currentAsset.clientProposalState === ClientProposalState.Unchanged;
  const terminatedRejected =
    asset.advisorProposalState === AdvisorProposalState.Terminated &&
    asset.clientProposalState === ClientProposalState.Unchanged;

  return updatedRejected || terminatedRejected;
}
