<div class="message-wrapper" [ngClass]="message.type">
  <div class="message-text-wrapper">
    <span class="icon icon--online" *ngIf="message.type === MessageType.ONLINE"></span>
    <span class="icon icon--offline" *ngIf="message.type === MessageType.OFFLINE"></span>
    <div class="message-text" [innerHTML]="message.message"></div>
  </div>
  <div class="message-suffix-wrapper message-action">
    <ng-content select="[messageAction]"></ng-content>
  </div>
  <div class="message-suffix-wrapper" *ngIf="showClose" (click)="onClose()">
    <span class="icon icon--close message-icon"></span>
  </div>
</div>
