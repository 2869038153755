<div class="error-page">
  <div class="error-page__status-illustration">
    <img
      class="error-page__status-illustration-picture"
      src="/assets/images/layout/404.png"
      title="Error page"
      alt="Error page"
    />
  </div>
  <div class="error-page__status-text">
    <p class="text-muted mb-0">
      Omlováme se, ale tato stránka neexistuje. Prosím vraťte se na homepage a zkuste to znovu.
    </p>
  </div>
  <div class="error-page__next-steps">
    <ng-content></ng-content>
  </div>
</div>
