import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {DocumentTypeLifeInsuranceFileEnum} from '@generated/defs/LifeInsuranceFile';
import {Start as GetPriorityQuestionnaire} from '@generated/store/lifeinsurance/getPriorityQuestionnaire/states/actions';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FAMILY_ID} from '@shared/constants';
import {LifeInsuranceSummaryService} from '@shared/investment-survey-old/component/summary/life-insurance/life-insurance-summary.service';
import {StoreFile, StoreFileUpdate} from '@shared/models/file.models';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DataValidationService} from 'src/app/modules/life-insurance-old/6-summary/data-validation.service';
import {LifeInsuranceActions} from 'src/app/modules/life-insurance-old/store';
import {LifeInsuranceFormGroup} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {State} from 'src/store';
import {UIActions} from 'src/store/actions/ui.actions';
import {
  PersonalInsuranceProposalVM,
  selectInsuranceProposalsVM,
} from '../5-proposals/proposals.selectors';
import {LifeInsurancePriorityProducts} from '../store/life-insurance.models';
import {
  selectGeneralFilesOfPerson,
  selectPriorityProducts,
} from '../store/life-insurance.selectors';

@UntilDestroy()
@Component({
  selector: 'kpt-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalsComponent implements OnInit, OnDestroy {
  insuranceProposals$ = this.store.pipe(select(selectInsuranceProposalsVM));
  dragOverPage = false;
  showSuggestions: {[key: string]: boolean} = {};
  priorityProducts$: Observable<LifeInsurancePriorityProducts> = this.store.pipe(
    select(selectPriorityProducts),
    untilDestroyed(this),
  );
  @ViewChild('scrollView') scrollView: ElementRef;

  constructor(
    private store: Store<State>,
    private investmentSurveyService: LifeInsuranceSummaryService,
    private dataValidatorService: DataValidationService,
    @Inject(FAMILY_ID) private familyId: string,
  ) {
    this.store.dispatch(UIActions.setWideContainer());
  }

  get formGroups(): LifeInsuranceFormGroup[] {
    return Object.values(LifeInsuranceFormGroup);
  }

  ngOnInit() {
    this.dataValidatorService.setFamilyId(this.familyId);

    this.investmentSurveyService
      .loadSurveyParticipants()
      .pipe(
        filter(familyMembers => familyMembers.length > 0),
        untilDestroyed(this),
      )
      .subscribe(familyMembers => {
        const participants = familyMembers.map(m => ({...m, participate: true}));
        this.store.dispatch(
          new GetPriorityQuestionnaire({
            family_uuid: this.familyId,
            data: {
              clients: participants.map(p => ({sugarUuid: p.sugarUuid})),
            },
          }),
        );
      });
  }

  ngOnDestroy() {
    this.store.dispatch(UIActions.unsetWideContainer());
  }

  trackByPersonId(_: number, item: PersonalInsuranceProposalVM) {
    return item.person.id;
  }

  // for mark drop zones
  onDragOver(event: Event) {
    this.dragOverPage = true;
    event.preventDefault();
  }

  // clear marked drop zones
  onDragLeave(event: Event) {
    this.dragOverPage = false;
    event.preventDefault();
  }

  onToggleSuggestions(personId: string) {
    this.showSuggestions[personId] = !this.showSuggestions[personId];
  }

  scrollInto(element: ElementRef) {
    setTimeout(() => {
      element.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    });
  }

  getGeneralFilesOfPerson$(personId: string) {
    return this.store.pipe(select(selectGeneralFilesOfPerson, personId));
  }

  onGeneralFileUploaded(file: StoreFile<DocumentTypeLifeInsuranceFileEnum>, personId: string) {
    this.store.dispatch(LifeInsuranceActions.addGeneralFile({personId, file: {...file, personId}}));
  }

  onGeneralFileUpdated({dmsUuid, name}: StoreFileUpdate) {
    this.store.dispatch(LifeInsuranceActions.updateGeneralFileName({dmsUuid, name}));
  }

  onGeneralFileRemoved(dmsUuid: string) {
    this.store.dispatch(LifeInsuranceActions.removeGeneralFile({dmsUuid}));
  }
}
