import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AlertModule, ButtonModule, ModalModule} from '@shared/ui';
import {GuardComponent} from './guard.component';

@NgModule({
  declarations: [GuardComponent],
  exports: [GuardComponent],
  imports: [CommonModule, ModalModule, ButtonModule, AlertModule],
  providers: [],
})
export class GuardModule {}
