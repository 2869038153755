<form [formGroup]="form">
  <kpt-input [formControl]="form.get('firstName')" label="Jméno"> </kpt-input>

  <kpt-input [formControl]="form.get('lastName')" label="Příjmení"> </kpt-input>

  <kpt-phone-number [control]="form.get('phoneNumber')" label="Telefon"> </kpt-phone-number>

  <kpt-input [formControl]="form.get('email')" label="E-mail"> </kpt-input>

  <kpt-input [formControl]="form.get('birthNumber')" label="Rodné číslo"> </kpt-input>

  <kpt-radio-group
    id="sex"
    label="Pohlaví"
    formControlName="sex"
    [itemClass]="'d-inline-block mr-6'"
    [options]="sexOptions"
    [success]="form.get('sex').valid && form.get('sex').touched"
  >
  </kpt-radio-group>

  <kpt-datepicker
    [formControl]="form.get('birthDate')"
    minDate="19000101"
    [warningText]="birthDateTooOldWarning"
    label="Datum narození"
  >
  </kpt-datepicker>

  <div *ngIf="getErrorMsg() as msg" class="col-10 offset-1 invalid-feedback mb-4 d-block">
    {{ msg }}
  </div>

  <div class="button-group">
    <kpt-button
      [isLoading]="findClientLoading$ | async"
      [isDisabled]="
        duplicateClientCheck || !form.valid || (createClientLoading$ | async) || enableCreation
      "
      [isSubmitButton]="primaryButton === 'search'"
      type="primary"
      (action)="onSearch()"
    >
      Vyhledat klienta
    </kpt-button>

    <kpt-button
      type="primary"
      [isSubmitButton]="primaryButton === 'create'"
      [isLoading]="createClientLoading$ | async"
      [isDisabled]="
        !form.valid ||
        !duplicateClientCheck ||
        (foundByBirthNumber && !isForeignerBirthNumber) ||
        (!enableCreation && clientFound) ||
        (findClientLoading$ | async)
      "
      (action)="onCreate()"
    >
      Založit klienta
    </kpt-button>
  </div>
</form>
