import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MessageType, NotificationMessage} from '@lib/models';
import {enterAnimation} from '../notification-center/notification-center.component';

@Component({
  selector: 'kpt-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  animations: [enterAnimation],
})
export class NotificationMessageComponent implements OnChanges {
  @Input() message: NotificationMessage;

  @Input() canHide: boolean;

  @Output() dismiss = new EventEmitter<any>();

  shouldHide = false;

  MessageType = MessageType;

  get showClose(): boolean {
    return ![
      MessageType.QUEUE,
      MessageType.ONLINE,
      MessageType.OFFLINE,
      MessageType.NEW_VERSION,
    ].includes(this.message.type);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.canHide.currentValue &&
      this.message &&
      this.message.duration &&
      this.message.duration !== -1
    ) {
      setTimeout(() => this.onClose(), this.message.duration);
    }
  }

  onClose() {
    this.shouldHide = true;
    setTimeout(() => this.dismiss.emit(), 500);
  }
}
