<ng-template #selectActionCol let-row="row" let-rowIndex="rowIndex">
  <ng-container *ngIf="showClientSelection">
    <a
      *ngIf="row.access === 'visible'"
      (click)="selectClient(row, rowIndex)"
      [class.is-loading]="rowIndex === clientNavigating"
      [class.is-disabled]="clientNavigating !== -1 && rowIndex !== clientNavigating"
      class="icon__link"
    >
      <span
        *ngIf="!duplicates"
        class="icon icon--arrow-link icon--large icon--wider-click-area"
      ></span>
      <span
        *ngIf="duplicates"
        class="icon icon--external-link icon--large icon--wider-click-area"
      ></span>
    </a>
    <a class="assign-text" *ngIf="row.access === 'permitted'" (click)="assign(row)">Získat</a>
    <div class="assign-container" *ngIf="row.access === 'unpermitted'">
      <a class="assign-text" (click)="reassign(row)">Požádat</a>
      <kpt-help
        class="pl-3 hint"
        text="Kliknutím na Požádat můžete doplnit rodné číslo a získat přístup ke klientovi.
                <br>Další možností je kontaktovat pečovatele a domluvit se na sdílení."
        direction="top"
      >
      </kpt-help>
    </div>
  </ng-container>
  <div *ngIf="!showClientSelection" class="px-4">
    <a class="assign-text" *ngIf="row.access === 'visible'" (click)="addClientToFamily(row)">
      <span *ngIf="!inCurrentFamily(row)">Přidat do rodiny</span>
    </a>
    <div *ngIf="inCurrentFamily(row)">
      <kpt-help class="pl-3 hint" text="Tento klient již je členem rodiny." direction="top">
      </kpt-help>
    </div>
    <a class="assign-text" *ngIf="row.access === 'permitted'" (click)="assign(row, true)">Získat</a>
    <div class="assign-container" *ngIf="row.access === 'unpermitted'">
      <a class="assign-text" (click)="reassign(row)">Požádat</a>
      <kpt-help
        class="pl-3 hint"
        text="Kliknutím na Požádat můžete doplnit rodné číslo a získat přístup ke klientovi.
                <br>Další možností je kontaktovat pečovatele a domluvit se na sdílení."
        direction="top"
      >
      </kpt-help>
    </div>
  </div>
</ng-template>

<ng-template #advisorTemplate let-row="row">
  <div class="d-flex align-items-center justify-content-between">
    <div>{{ row.assignedUserName }}</div>
    <kpt-help
      class="pl-3 hint"
      [text]="
        'Pečovatel ' +
        row.assignedUserName +
        '<br/>' +
        '<strong>E-mail: </strong>' +
        row.assignedUserEmail +
        '<br/>' +
        '<strong>Telefon: </strong>' +
        row.assignedUserPhone
      "
      direction="top"
    >
    </kpt-help>
  </div>
</ng-template>

<div *ngIf="showResultsFor('Not found')" class="mt-6">
  <div class="row">
    <div class="col-md-10 offset-md-1 text-center">Nebyl nalezen žádný odpovídající klient.</div>
  </div>
</div>
<div *ngIf="showResultsFor('Found')" class="mt-6">
  <div class="row">
    <div class="col-md-12">
      <kpt-table
        [cols]="tableCols"
        [rows]="foundClientsState.clients"
        [showHead]="true"
        [tableClass]="['table-bordered', 'table--lower-cells']"
        [actionColClass]="'text-center'"
        [actionColTemplate]="selectActionCol"
        [columnTemplates]="{advisor: advisorTemplate}"
        [highlightRowCondition]="foundByBirthNumber()"
      >
      </kpt-table>
    </div>
  </div>
</div>
