<ng-container *ngTemplateOutlet="startTemplate; context: {data: data}"></ng-container>

<div class="asset-name" [class.clickable]="clickable" (click)="clickItem.emit()">
  {{ data.name }}
  <kpt-help
    *ngIf="data.tooltip"
    class="pl-3"
    [text]="data.tooltip"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  ></kpt-help>
  <ng-container *ngTemplateOutlet="afterLabelTemplate; context: {data: data}"></ng-container>
</div>

<div class="actions">
  <shd-tooltip
    *ngIf="requiresAttention(data)"
    icon="warning"
    [hover]="true"
    class="requires-attention"
  >
    Ke smlouvě je potřeba doplnit důležité údaje pro tvorbu finančního plánu.
  </shd-tooltip>
  <ng-container *ngTemplateOutlet="beforeInputTemplate; context: {data: data}"></ng-container>
  <span>
    <kpt-simple-asset-value-input
      [assetValueFormControl]="data.valueFormControl"
      customFormGroupClass="form-group--hidden-label"
      appendText="Kč"
      min="0"
      max="2147483647"
      [class.readonly]="isInputReadOnly()"
      [readonly]="isInputReadOnly() || (persisting(data.type) | async)"
      (valueChange)="valueChange.emit($event)"
    >
    </kpt-simple-asset-value-input>
  </span>

  <ng-container *ngTemplateOutlet="endTemplate; context: {data: data}"></ng-container>
</div>
