import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {FinancialOutlookType} from '@shared/analysis/models/income-attributes';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {GuardModalMessages} from '../../client/models/guard.model';
import {GuardService} from '../../client/services/guard.service';

export const INVESTMENT_BALANCE_LIMIT = 500;

@Injectable()
export class CanLoadInvestment implements CanActivate {
  constructor(
    private analysisDataService: AnalysisDataService,
    private router: Router,
    private guardService: GuardService,
  ) {}

  // https://github.com/angular/angular/issues/28306
  // has to be canActivate
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const familyId = route.paramMap.get('familyId');
    this.guardService.setTargetUrl(state.url);

    return this.analysisDataService.getInvestmentRequiredData$(familyId).pipe(
      map(({familyBalance, familyHeadOutlook}) => {
        if (
          (familyBalance > INVESTMENT_BALANCE_LIMIT && familyHeadOutlook !== 'negative') ||
          // force queryParameter allows user enter the module regardless the other conditions
          // after redirecting, this parameter is removed right away
          route.queryParams.force === 'true'
        ) {
          return true;
        }

        // show guard modal
        this.guardService.setModalData({
          module: 'investment',
          open: true,
          restrictType: this.getRestrictType(familyBalance, familyHeadOutlook),
          messages: this.getMessages(familyBalance, familyHeadOutlook),
        });

        return this.router.createUrlTree(['/secured/client/', familyId], {
          queryParams: {
            tab: 'subjects',
          },
        });
      }),
    );
  }

  private getRestrictType(
    balance: number,
    financialOutlook: FinancialOutlookType,
  ): 'loose' | 'full' {
    return 0 <= balance && balance <= INVESTMENT_BALANCE_LIMIT && financialOutlook !== 'negative'
      ? 'loose'
      : 'full';
  }

  private getMessages(balance: number, financialOutlook: FinancialOutlookType): GuardModalMessages {
    const messages: GuardModalMessages = {error: [], warning: []};

    if (financialOutlook === 'negative') {
      messages.error.push('Negativní výhled finanční situace, pro klienta není vhodné investovat.');
    }

    if (balance < 0) {
      messages.error.push('Výdaje jsou větší než příjmy, pro klienta není vhodné investovat.');
    } else if (0 <= balance && balance <= INVESTMENT_BALANCE_LIMIT) {
      messages.warning.push('Rozdíl mezi příjmy a výdaji není dostatečný pro investování.');
    }

    return messages;
  }
}
