import {formatCZK} from '@lib/utils';
import {createSelector} from '@ngrx/store';
import {futureValue} from '@shared/analysis/formulas';
import {getAssetName} from '@shared/analysis/store';
import {selectBusinessCaseMode} from '@shared/business-case/store';
import * as moment from 'moment';
import {
  BusinessCaseMode,
  Situation,
} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {selectPaymentCalendar} from 'src/app/modules/financial-plan/payment-overview/payment-overview.selectors';
import {computeAverageAbsoluteFulfillment} from 'src/app/modules/financial-plan/store/financial-plan-graphs.selectors';
import {
  PaymentsOverview,
  PriorityPlan,
  PriorityPlanItem,
} from 'src/app/modules/financial-plan/store/financial-plan.models';
import {ObjectiveType} from 'src/shared/analysis/objectives.helpers';
import {
  VehicleObjective,
  FamilyObjective,
  FinancialPlanObjectives,
  Fulfillment,
  FulfillmentType,
  Objective,
  PropertyObjective,
} from './objectives.models';
import {selectFinancialPlanObjectives} from './objectives.selectors';

export const selectPriorityPlan = ({showNewDashboard}: {showNewDashboard: boolean}) =>
  createSelector(
    selectFinancialPlanObjectives({showNewDashboard}),
    selectBusinessCaseMode,
    (objectives, businessCaseMode): PriorityPlan => {
      const familyObjectives = [...objectives[ObjectiveType.Family]];
      const propertyObjectives = [...objectives[ObjectiveType.Property]];
      const vehicleObjectives = [...objectives[ObjectiveType.Vehicle]];

      const otherObjectives = [
        ...objectives[ObjectiveType.Pension],
        ...objectives[ObjectiveType.FinancialResources],
        ...objectives[ObjectiveType.Independence],
        ...objectives[ObjectiveType.Housing],
        ...objectives[ObjectiveType.Reserve],
      ];
      return {
        items: [
          ...(familyObjectives.length
            ? [createPlanItemFromFamilyOrPropertyObjective(familyObjectives)]
            : []),
          ...(propertyObjectives.length
            ? [createPlanItemFromFamilyOrPropertyObjective(propertyObjectives)]
            : []),
          ...(vehicleObjectives.length
            ? [createPlanItemFromFamilyOrPropertyObjective(vehicleObjectives)]
            : []),
          ...otherObjectives.map((objective): PriorityPlanItem => {
            return {
              objectiveUuid: objective.objectiveAsset.assetUuid,
              objectiveType: objective.type,
              objectiveAsset: objective.objectiveAsset,
              name: getAssetName(objective.objectiveAsset),
              when: -moment().diff(objective.objectiveAsset.start, 'years'),
              value: objective.objectiveAsset.value ?? 0,
              originalFulfillment: getOriginalFulfillment(objective),
              proposedFulfillment: getProposedFulfillment(objective, businessCaseMode),
            };
          }),
          ...(hasSomeAdditionalContracts(objectives)
            ? [
                {
                  objectiveUuid: null,
                  objectiveType: ObjectiveType.AdditionalContracts,
                  objectiveAsset: null,
                  value: null,
                  originalFulfillment: null,
                  proposedFulfillment: null,
                  name: 'Další smlouvy',
                  when: null,
                },
              ]
            : []),
        ],
      };
    },
  );

export const selectPaymentsOverview = createSelector(
  selectPaymentCalendar,
  (paymentCalendar): PaymentsOverview => {
    const currentValue = paymentCalendar.comparison.totalCurrentPayment;
    const newValue = paymentCalendar.comparison.totalProposedPayment;
    const savings = currentValue - newValue;
    const investmentFutureValue = futureValue(0.04, 5, -savings, 0, 1);

    const positiveSavingsHtml =
      `<p>Nově měsíčně ušetříte <strong>${formatCZK(savings)}</strong></p><p>` +
      `TIP: Investujte tuto částku a za&nbsp;5&nbsp;let můžete mít ` +
      `<strong>${formatCZK(investmentFutureValue)}</strong></p>`;

    const negativeSavingsHtml =
      `<p>Ke&nbsp;splnění vašich zadaných priorit a cílů nyní platíte pouze ` +
      `o&nbsp;<strong>${formatCZK(-savings)}</strong> více.</p>`;

    const differenceHtml =
      savings > 0 ? positiveSavingsHtml : savings < 0 ? negativeSavingsHtml : '';

    return {currentValue, newValue, savings, investmentFutureValue, differenceHtml};
  },
);

function computeAvgAbsoluteFulfillment(objectives: Objective[], proposed: boolean): Fulfillment {
  let situation: Situation;
  if (!proposed) {
    situation = Situation.Current;
  } else {
    situation = objectives.some(obj => obj.table.clientProposed.rows.length > 0)
      ? Situation.ClientProposed
      : Situation.AdvisorProposed;
  }
  return computeAverageAbsoluteFulfillment(objectives, situation);
}

function getOriginalFulfillment(objective: Objective): Fulfillment {
  return {
    type: FulfillmentType.Percent,
    percent: objective.table.current.fulfillment.percent ?? 0,
  };
}

function getProposedFulfillment(
  objective: Objective,
  businessCaseMode: BusinessCaseMode,
): Fulfillment {
  return {
    type: FulfillmentType.Percent,
    percent: getProposedPercent(businessCaseMode, objective),
  };
}

function getProposedPercent(businessCaseMode: BusinessCaseMode, objective: Objective): number {
  if (businessCaseMode === BusinessCaseMode.Design) {
    return objective.table.advisorProposed.fulfillment.percent ?? 0;
  }
  if (objective.table.clientProposed.rows.length > 0) {
    return objective.table.clientProposed.fulfillment.percent ?? 0;
  }
  return objective.table.advisorProposed.fulfillment.percent ?? 0;
}

function hasSomeAdditionalContracts(objectives: FinancialPlanObjectives): boolean {
  return (
    objectives[ObjectiveType.AdditionalContracts][0]?.table.current.rows.length > 0 ||
    objectives[ObjectiveType.AdditionalContracts][0]?.table.advisorProposed.rows.length > 0
  );
}

function createPlanItemFromFamilyOrPropertyObjective(
  objectives: FamilyObjective[] | PropertyObjective[] | VehicleObjective[],
): PriorityPlanItem {
  return {
    objectiveUuid: objectives[0].objectiveAsset.assetUuid,
    objectiveType: objectives[0].type,
    objectiveAsset: objectives[0].objectiveAsset,
    name: getAssetName(objectives[0].objectiveAsset),
    when: -moment().diff(objectives[0].objectiveAsset.start, 'years'),
    value: objectives[0].objectiveAsset.value ?? 0,
    originalFulfillment: computeAvgAbsoluteFulfillment(objectives, false),
    proposedFulfillment: computeAvgAbsoluteFulfillment(objectives, true),
  };
}
