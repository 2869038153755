import {Component, EventEmitter, Input, Output} from '@angular/core';

import {EventSituationButtonConfig} from '@shared/ui';

@Component({
  selector: 'kpt-event-situation',
  templateUrl: './event-situation.component.html',
  styleUrls: ['./event-situation.component.scss'],
})
export class EventSituationComponent {
  @Input() config: EventSituationButtonConfig[];
  @Input() dataTransferData: string;
  @Input() buttonOffset = true;
  @Output() eventAction: EventEmitter<EventSituationButtonConfig> =
    new EventEmitter<EventSituationButtonConfig>();
  @Output() dragActive: EventEmitter<boolean> = new EventEmitter<boolean>();
}
