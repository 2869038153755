import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {mapTo, take, tap} from 'rxjs/operators';
import {AnalysisDataService} from 'src/app/services/analysis-data.service';
import {setErrorLoggerUserContext} from 'src/app/services/error-handler.helpers';

// https://github.com/angular/angular/issues/15670
// cannot be the implementation of Resolver
@Injectable()
export class ClientResolve implements CanActivate {
  constructor(private analysisDataService: AnalysisDataService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const familyId = route.paramMap.get('familyId');
    return this.loadClientData$(familyId).pipe(mapTo(true));
  }

  loadClientData$(familyId: string) {
    return this.analysisDataService.loadClientDataAndSaveAssetChanges$(familyId).pipe(
      take(1),
      tap(([family, _]) => setErrorLoggerUserContext(family, familyId)),
    );
  }
}
