import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CreateAssignmentModule} from '@generated/store/integrations/createAssignment/createAssignment.module';
import {ButtonModule, HelpModule, TableModule} from '@shared/ui';
import {ClientSearchComponent} from './client-search.component';

@NgModule({
  declarations: [ClientSearchComponent],
  imports: [ButtonModule, CommonModule, TableModule, CreateAssignmentModule, HelpModule],
  exports: [ClientSearchComponent],
})
export class ClientSearchModule {}
