import {Asset} from '@shared/analysis/models/asset';
import {Frequency} from '@shared/analysis/models/unit';

export interface CalculationInput {
  start: string;
  end: string;
  frequency: Frequency;
  assets: Asset[];
}

export enum ResultView {
  Equity,
  Cashflow,
}
