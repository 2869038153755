import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export type MenuTabs = 'client' | 'advisor' | 'articles' | 'notes';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  menuOpened$ = new BehaviorSubject(false);
  activeTab$ = new BehaviorSubject<MenuTabs>('client');

  toggle() {
    this.menuOpened$.next(!this.menuOpened$.value);
  }

  close() {
    this.setOpened(false);
  }

  setActiveTab(tab: MenuTabs) {
    this.activeTab$.next(tab);
  }

  private setOpened(state: boolean) {
    this.menuOpened$.next(state);
  }
}
