/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {CreateOrUpdateAdvisorUiSettingsModule} from '@generated/store/ui/createOrUpdateAdvisorUiSettings/createOrUpdateAdvisorUiSettings.module';
import {GetAdvisorUiSettingsModule} from '@generated/store/ui/getAdvisorUiSettings/getAdvisorUiSettings.module';
import {StoreModule} from '@ngrx/store';
import {AdvisorUiSettingsDataService} from './advisor-ui-settings-data.service';
import {reducer, storeFeatureName} from './store';
/* eslint-enable max-len */

@NgModule({
  imports: [
    StoreModule.forFeature(storeFeatureName, reducer),
    GetAdvisorUiSettingsModule,
    CreateOrUpdateAdvisorUiSettingsModule,
  ],
  providers: [AdvisorUiSettingsDataService],
})
export class AdvisorUiSettingsModule {}
