import {Component, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ResultSet} from '@shared/analysis/components/investment-survey-summary/investment-survey-summary.component';
import {InvestmentSurveySummaryService} from '@shared/analysis/components/investment-survey-summary/investment-survey-summary.service';
import {selectAdultFamilyMembers} from '@shared/analysis/operators';
import {FAMILY_ID} from '@shared/constants';
import {InvestmentSurveyDataService} from '@shared/investment-survey-old/investment-survey-data.service';
import {selectAllSurveyAnswers} from '@shared/investment-survey-old/store';
import {combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {State} from 'src/store';

@Component({
  selector: 'kpt-investment-survey-summary',
  templateUrl: './investment-survey-summary.component.html',
  styleUrls: ['./investment-survey-summary.component.scss'],
})
export class InvestmentSurveySummaryComponent implements OnInit {
  results: ResultSet[] = [];

  constructor(
    private surveyService: InvestmentSurveySummaryService,
    private store: Store<State>,
    private investmentSurveyDataService: InvestmentSurveyDataService,
    @Inject(FAMILY_ID) private familyUuid: string,
  ) {}

  ngOnInit() {
    this.investmentSurveyDataService.loadData(this.familyUuid).finally(() => this.init());
  }

  private init() {
    combineLatest([
      this.store.pipe(select(selectAllSurveyAnswers)),
      this.store.pipe(selectAdultFamilyMembers()),
    ])
      .pipe(take(1))
      .subscribe(([surveyAnswers, familyMembers]) => {
        this.results = this.surveyService.resolve(surveyAnswers, familyMembers).results;
      });
  }
}
