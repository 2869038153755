<div class="event-situation-wrapper">
  <div
    #eventSituation
    class="event-situation"
    [class.event-situation--disabled]="!config.isActive || (config.onlyOne && config.isUsed)"
    [class.event-situation--used]="config.isUsed"
    [ngClass]="config.buttonType ? 'event-situation--' + config.buttonType : ''"
    [class.interaction--move]="config.isDraggable"
    [class.interaction--click]="!config.isDraggable"
    (click)="eventAction.emit(config)"
    [draggable]="config.isDraggable && !(config.onlyOne && config.isUsed)"
    (dragstart)="dragStart($event, config)"
    (dragend)="dragEnd($event)"
  >
    <span class="event-situation__holder">
      <span *ngIf="config.icon && config.iconPosition !== 'right'" class="event-situation__icon">
        <span class="icon" [ngClass]="'icon--' + config.icon"> </span>
      </span>
      <span class="event-situation__label">
        {{ config.labelFormatted || config.label }}
      </span>
      <span
        *ngIf="config.icon && config.iconPosition === 'right'"
        class="event-situation__icon pl-2 pr-0"
      >
        <span class="icon" [ngClass]="'icon--' + config.icon"> </span>
      </span>
    </span>
  </div>
  <div *ngIf="config.tooltip" class="event-situation-tooltip">
    <span class="icon" [ngClass]="'icon--' + config.tooltip"> </span>
  </div>
</div>
