import {Injectable} from '@angular/core';
import {FinancialOutlookType} from '@shared/analysis/models/income-attributes';
import {getSelectField} from 'src/shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {AssetDefinition} from './definitions.models';

export const financialOutlookI18n = {
  [FinancialOutlookType.Positive]: 'Pozitivní',
  [FinancialOutlookType.Stable]: 'Stabilní',
  [FinancialOutlookType.Negative]: 'Negativní',
};

@Injectable()
export class FinancialOutlook {
  financialOutlook: AssetDefinition = {
    name: assetNames[AssetType.FinancialOutlook],
    type: AssetType.FinancialOutlook,
    fields: [
      getSelectField('outlook', 'Výhled finanční situace', [
        {
          label: financialOutlookI18n[FinancialOutlookType.Positive],
          key: FinancialOutlookType.Positive,
        },
        {
          label: financialOutlookI18n[FinancialOutlookType.Stable],
          key: FinancialOutlookType.Stable,
        },
        {
          label: financialOutlookI18n[FinancialOutlookType.Negative],
          key: FinancialOutlookType.Negative,
        },
      ]),
    ],
    model: {
      outlook: FinancialOutlookType.Positive,
    },
  };

  getAll() {
    return [this.financialOutlook];
  }
}
