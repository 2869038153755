import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FieldType} from '@ngx-formly/core';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {AssetType, RelatedObjectiveAsset} from '@shared/analysis/models/asset';
import {objectiveSourceAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {FinancialAnalysisActions, selectObjectivesAssets} from '@shared/analysis/store';
import {cloneDeep, flatten} from 'lodash';
import {combineLatest} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {formatFulfillment} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {
  FinancialPlanObjectives,
  Fulfillment,
  Objective,
  selectFinancialPlanObjectives,
} from 'src/app/modules/financial-plan/store';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';

const allowedObjectiveAssets: AssetType[] = [
  ...objectiveSourceAssets[ObjectiveType.Property],
  ...objectiveSourceAssets[ObjectiveType.Family],
  ...objectiveSourceAssets[ObjectiveType.Vehicle],
  ...objectiveSourceAssets[ObjectiveType.Reserve],
  ...objectiveSourceAssets[ObjectiveType.Housing],
  ...objectiveSourceAssets[ObjectiveType.Pension],
  ...objectiveSourceAssets[ObjectiveType.Independence],
  ...objectiveSourceAssets[ObjectiveType.FinancialResources],
];

@UntilDestroy()
@Component({
  selector: 'kpt-fulfillment-formly',
  templateUrl: './fulfillment-formly.component.html',
  styleUrls: ['./fulfillment-formly.component.scss'],
})
export class FulfillmentFormlyComponent extends FieldType implements OnInit, OnDestroy {
  fulfillment: Fulfillment;
  formatFulfillment = formatFulfillment;
  private allowedAssetType = false;
  private allowedRoute = false;

  constructor(
    private store: Store<State>,
    private router: Router,
    private assetsHandlerService: AssetsHandlerService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    super();
  }

  get fulfillmentVisible(): boolean {
    return this.allowedAssetType && this.allowedRoute;
  }

  ngOnDestroy() {
    this.store.dispatch(FinancialAnalysisActions.FA_SetCurrentlyEditedAsset(null));
  }

  ngOnInit() {
    if (!(this.router.url.includes('financial-plan') && this.router.url.includes('priorities'))) {
      return;
    }

    this.allowedRoute = true;

    this.store
      .pipe(
        select(
          selectFinancialPlanObjectives({
            showNewDashboard: this.featureFlagsService.showNewDashboard,
          }),
        ),
        map((objectives: FinancialPlanObjectives) => {
          const objective = flatten<Objective>(Object.values(objectives)).find(
            obj => obj.objectiveAsset?.assetUuid === this.model.relatedObjectiveUuid,
          );
          if (!objective) return null;

          const situation = this.assetsHandlerService.openAssetSituation;
          return objective.table[situation]?.fulfillment ?? null;
        }),
        untilDestroyed(this),
      )
      .subscribe(fulfillment => (this.fulfillment = fulfillment));

    setTimeout(
      () =>
        this.store.dispatch(
          FinancialAnalysisActions.FA_SetCurrentlyEditedAsset(cloneDeep(this.model)),
        ),
      0,
    );

    this.field.form.valueChanges.pipe(debounceTime(250), untilDestroyed(this)).subscribe(model => {
      this.store.dispatch(
        FinancialAnalysisActions.FA_SetCurrentlyEditedAsset({
          ...cloneDeep(this.model),
          ...cloneDeep(model),
        }),
      );
    });

    combineLatest([
      this.field.form.valueChanges.pipe(startWith(this.field.form.value as object)),
      this.store.pipe(select(selectObjectivesAssets)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([model, objectiveAssets]) => {
        const currentAsset = {...this.model, ...model} as RelatedObjectiveAsset;
        const objectiveAsset = objectiveAssets.find(
          a => a.assetUuid === currentAsset.relatedObjectiveUuid,
        );
        this.allowedAssetType =
          objectiveAsset &&
          allowedObjectiveAssets.some(assetType => assetType === objectiveAsset.type);
      });
  }
}
