import {Injectable} from '@angular/core';
import {assetNames, AssetType} from '../models/asset';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition} from './definitions.models';

@Injectable()
export class OtherIncomes {
  maternity: AssetDefinition = {
    name: assetNames[AssetType.Maternity],
    type: AssetType.Maternity,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.Maternity),
    model: {},
  };

  parental: AssetDefinition = {
    name: assetNames[AssetType.Parental],
    type: AssetType.Parental,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.Parental),
    model: {},
  };

  unemploymentBenefits: AssetDefinition = {
    name: assetNames[AssetType.UnemploymentBenefits],
    type: AssetType.UnemploymentBenefits,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.UnemploymentBenefits),
    model: {},
  };

  pension: AssetDefinition = {
    name: assetNames[AssetType.PensionIncome],
    type: AssetType.PensionIncome,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.PensionIncome),
    model: {},
  };

  otherPension: AssetDefinition = {
    name: assetNames[AssetType.OtherPensionIncome],
    type: AssetType.OtherPensionIncome,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.OtherPensionIncome),
    model: {},
  };

  rentalIncome: AssetDefinition = {
    name: assetNames[AssetType.RentalIncome],
    type: AssetType.RentalIncome,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.RentalIncome),
    model: {},
  };

  dividends: AssetDefinition = {
    name: assetNames[AssetType.Dividends],
    type: AssetType.Dividends,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.Dividends),
    model: {},
  };

  otherPassiveIncome: AssetDefinition = {
    name: assetNames[AssetType.OtherPassiveIncome],
    type: AssetType.OtherPassiveIncome,
    fields: this.abstractAssets.getCommonIncomeField(),
    validators: getAssetValidators(AssetType.OtherPassiveIncome),
    model: {},
  };

  constructor(private abstractAssets: AbstractAssetsDefinitions) {}

  getAll() {
    return [
      this.maternity,
      this.parental,
      this.unemploymentBenefits,
      this.pension,
      this.otherPension,
      this.rentalIncome,
      this.dividends,
      this.otherPassiveIncome,
    ];
  }
}
