/* eslint-disable max-len */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {calculateMonthlySum} from '@shared/analysis/evaluation';
import {MainIncomesAsset} from '@shared/analysis/models/main-incomes';
import {
  selectCommonExpensesSum,
  selectCommonIndispensableExpensesSum,
  selectCreditProductsSum,
  selectCreditPropertiesSum,
  selectCurrentAssets,
  selectFinancialProductsSum,
  selectFinancialPropertiesSum,
  selectImmovablePropertiesSum,
  selectInsuranceProductsSum,
  selectInsurancePropertiesSum,
  selectInvestmentProductsSum,
  selectInvestmentPropertiesSum,
  selectMonthlyBalance,
  selectMovablePropertiesSum,
  selectPropertiesSum,
} from '@shared/analysis/store';
import {
  ExpensesChart,
  IncomesChart,
  LiabilitiesChart,
  PotentialPropertyChart,
  PropertyStructureChart,
} from 'src/app/modules/financial-plan/store/financial-plan.models';
import {mainIncomesAssets, otherIncomesAssets} from 'src/shared/analysis/models/asset';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {FinancialPlanState} from './financial-plan.state';
/* eslint-enable max-len */

export const storeFeatureName = 'financialPlan';

export const selectFinancialPlanState = createFeatureSelector<FinancialPlanState>(storeFeatureName);

export const selectFinancialPlanData = createSelector(
  selectFinancialPlanState,
  state => state.financialPlanData,
);

export const selectAdditionalContracts = createSelector(
  selectFinancialPlanState,
  state => state.financialPlanData.additionalContracts,
);

export const selectObjectivesData = createSelector(
  selectFinancialPlanData,
  financialPlanData => financialPlanData.objectives,
);

export const selectPropertiesOverview = createSelector(
  selectPropertiesSum,
  selectCreditPropertiesSum,
  (propertiesSum, creditPropertiesSum): LiabilitiesChart => ({
    totalProperties: propertiesSum,
    liabilities: creditPropertiesSum,
  }),
);

export const selectPropertyStructureSum = createSelector(
  selectImmovablePropertiesSum,
  selectMovablePropertiesSum,
  selectInsurancePropertiesSum,
  selectInvestmentPropertiesSum,
  selectFinancialPropertiesSum,
  (
    immovablePropertiesSum,
    movablePropertiesSum,
    insurancePropertiesSum,
    investmentPropertiesSum,
    financialPropertiesSum,
  ): PropertyStructureChart => ({
    items: [
      {
        label: 'Movitý majetek',
        amount: movablePropertiesSum,
      },
      {
        label: 'Nemovitý majetek',
        amount: immovablePropertiesSum,
      },
      {
        label: 'Pojistné produkty',
        amount: insurancePropertiesSum,
      },
      {
        label: 'Běžný účet a hotovost',
        amount: financialPropertiesSum,
      },
      {
        label: 'Spoření a investice',
        amount: investmentPropertiesSum,
      },
    ],
  }),
);

export const selectProperties = createSelector(
  selectMonthlyBalance,
  selectInsuranceProductsSum,
  selectInvestmentProductsSum,
  selectCreditProductsSum,
  (
    monthlyBalance,
    insuranceProductsSum,
    investmentProductsSum,
    creditProductsSum,
  ): PotentialPropertyChart => ({
    items: [
      {
        label: 'Měsíční zůstatek',
        amount: monthlyBalance,
      },
      {
        label: 'Pojistné produkty',
        amount: insuranceProductsSum,
      },
      {
        label: 'Investiční produkty',
        amount: investmentProductsSum,
      },
      {
        label: 'Úvěrové produkty',
        amount: creditProductsSum,
      },
    ],
  }),
);

export const selectExpenses = createSelector(
  selectCommonExpensesSum,
  selectFinancialProductsSum,
  selectCommonIndispensableExpensesSum,
  (commonExpensesSum, financialProductsSum, commonIndispensableExpensesSum): ExpensesChart => ({
    financialProducts: financialProductsSum,
    dispensable: commonExpensesSum - commonIndispensableExpensesSum,
    indispensable: commonIndispensableExpensesSum,
  }),
);

export const selectIncomesByPerson = createSelector(
  selectCurrentAssets,
  getFamilyMembers,
  (assets, familyMembers): IncomesChart => {
    const others = {label: 'Ostatní', amount: 0};
    const mainIncomeAssets = assets.filter(asset => mainIncomesAssets.includes(asset.type));
    const otherIncomeAssets = assets.filter(asset => otherIncomesAssets.includes(asset.type));

    return {
      persons: [
        ...familyMembers.map(fm => {
          const income = mainIncomeAssets.filter(
            (asset: MainIncomesAsset) => asset.familyMemberUuid === fm.sugarUuid,
          );
          const other = otherIncomeAssets.filter(
            (asset: MainIncomesAsset) => asset.familyMemberUuid === fm.sugarUuid,
          );

          others.amount += calculateMonthlySum(other);
          return {
            label: fm.firstName ? fm.firstName : fm.lastName,
            amount: calculateMonthlySum(income),
          };
        }),
        others,
      ],
    };
  },
);
