import {ModuleWithProviders, NgModule} from '@angular/core';

import {CollapseDirective} from './collapse.directive';

/**
 * Source code taken from ngx-bootstrap library:
 * https://github.com/valor-software/ngx-bootstrap/blob/development/src/collapse/collapse.module.ts
 */
@NgModule({
  declarations: [CollapseDirective],
  exports: [CollapseDirective],
})
export class CollapseModule {
  static forRoot(): ModuleWithProviders<CollapseModule> {
    return {ngModule: CollapseModule, providers: []};
  }
}
