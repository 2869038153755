/* eslint-disable no-console */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {Observable, OperatorFunction, Subject} from 'rxjs';
import {bufferTime, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';
import {v4 as uuid} from 'uuid';

export interface RemoteLog {
  severity: 'info' | 'warn' | 'error';
  topic: string;
  data: unknown[];
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RemoteLoggerService {
  referenceId = uuid();

  private logs = new Subject<RemoteLog>();

  constructor(private http: HttpClient, private store: Store<State>) {
    // TODO(pz) needs to investigate, bufferTime causes Protractor hanging
    const bundler: OperatorFunction<RemoteLog, RemoteLog[]> = environment.remoteLogBufferTime
      ? bufferTime(environment.remoteLogBufferTime)
      : map(log => [log]);

    this.logs
      .pipe(
        filter(log => Boolean(log)),

        // log locally
        tap(log => {
          if (!environment.logLocally) return;

          const logMethod = console[log.severity];
          if (!logMethod) return;

          const {topic, data} = log;
          logMethod(`${topic}:`, ...data);
        }),

        filter(_ => environment.logRemotelly),

        // add IDs
        mergeMap(log =>
          this.store
            .select(getFamilyUuid)
            .pipe(map(familyUuid => ({referenceId: this.referenceId, familyUuid, ...log}))),
        ),

        bundler,
        filter(logs => logs.length > 0),

        // remote logging
        switchMap(logs => this.sendLogs(logs)),

        untilDestroyed(this),
      )
      .subscribe();
  }

  info(topic: string, ...data: unknown[]) {
    this.logs.next({severity: 'info', topic, data});
  }

  warn(topic: string, ...data: unknown[]) {
    this.logs.next({severity: 'warn', topic, data});
  }

  error(topic: string, ...data: unknown[]) {
    this.logs.next({severity: 'error', topic, data});
  }

  private sendLogs(records: RemoteLog[]): Observable<void> {
    if (!records) return;
    return this.http.post<void>(`/api/integrations/remote-log/`, records);
  }
}
