import {
  AssetType,
  BaseAsset,
  DateAsset,
  FamilyMemberAsset,
  NameAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';

export interface CommonExpensesSumAsset extends BaseAsset, ValueAsset, DateAsset {
  type: AssetType.CommonExpensesSum;
}

export interface CommonExpenseAsset extends BaseAsset, ValueAsset, DateAsset {
  /** Nezbytnost; percents, from 0 to 1 */
  indispensablePart: number;
}

/** Detailní rozpad ANO/NE */
export enum InputMode {
  Simple = 'simple',
  Detail = 'detail',
}

export interface InputModeAsset {
  /** Detailní rozpad */
  inputMode: InputMode;
}

export interface FoodAsset extends CommonExpenseAsset, InputModeAsset {
  type: AssetType.Food;
  /** Nákupy potravin */
  food: number;
  /** Obědy */
  lunch: number;
  /** Večeře */
  dinner: number;
  /** Ostatní */
  other: number;
}

export interface HousingExpensesAsset extends CommonExpenseAsset, InputModeAsset, NameAsset {
  type: AssetType.HousingExpenses;
  /** Nájem/splátky hypotéky */
  rent: number;
  /** Voda */
  water: number;
  /** Plyn */
  gas: number;
  /** Elektřina */
  electricity: number;
  /** Teplo */
  heating: number;
  /** Fond oprav */
  repairsFund: number;
  /** Ostatní */
  other: number;
}

export interface TransportAsset extends CommonExpenseAsset, InputModeAsset {
  type: AssetType.Transport;
  /** Pohonné hmoty */
  fuel: number;
  /** Pravidelná údržba vozidla */
  carService: number;
  /** MHD/Veřejná doprava */
  publicTransport: number;
  /** Taxi */
  taxi: number;
  /** Ostatní */
  other: number;
}

export interface DrugstoreAsset extends CommonExpenseAsset, InputModeAsset {
  type: AssetType.Drugstore;
  /** Základní drogerie */
  basicDrugstore: number;
  /** Hygienické potřeby pro děti */
  childrenHygiene: number;
  /** Léky */
  drugs: number;
  /** Ostatní */
  other: number;
}

export interface ClothesAsset extends CommonExpenseAsset {
  type: AssetType.Clothes;
}

export interface CommunicationAsset extends CommonExpenseAsset, InputModeAsset {
  type: AssetType.Communication;
  /** Internet */
  internet: number;
  /** Televize */
  television: number;
  /** Telefon */
  phone: number;
}

export interface HobbiesAsset extends CommonExpenseAsset {
  type: AssetType.Hobbies;
}

export interface VacationsAsset extends CommonExpenseAsset {
  type: AssetType.Vacations;
  /** Celkové roční výdaje */
  yearValue: number;
}

export interface ChildrenExpensesAsset extends CommonExpenseAsset, FamilyMemberAsset {
  type: AssetType.ChildrenExpenses;
}

export interface OtherCommonExpensesAsset extends CommonExpenseAsset, InputModeAsset {
  type: AssetType.OtherCommonExpenses;
  /** Zábava */
  entertainment: number;
  /** Výživné */
  alimony: number;
  /** Cigarety */
  cigarettes: number;
  /** Alkohol */
  alcohol: number;
  /** Dárky */
  presents: number;
  /** Ostatní */
  other: number;
}

export type CommonExpensesAsset =
  | FoodAsset
  | HousingExpensesAsset
  | TransportAsset
  | DrugstoreAsset
  | ClothesAsset
  | CommunicationAsset
  | HobbiesAsset
  | VacationsAsset
  | ChildrenExpensesAsset
  | OtherCommonExpensesAsset;
