<kpt-select
  [formControl]="formControl"
  [label]="to.label"
  [options]="options$ | async"
  [required]="to.required"
  [showErrorsOn]="true"
  [value]="to.value"
  [clearable]="to.clearable"
  (selected)="to.change ? to.change(field, $event) : noop()"
></kpt-select>
