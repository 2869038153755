import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {filter, tap} from 'rxjs/operators';
import {selectDisabledReserves} from 'src/app/modules/life-insurance-old/store/life-insurance.selectors';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-reserves-accordion-old',
  templateUrl: './reserves-accordion-old.component.html',
  styleUrls: ['./reserves-accordion-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservesAccordionOldComponent {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  isChild = false;

  @Input()
  personId: string;

  @Input()
  readonly: boolean;

  disableReserves$ = this.store.pipe(
    select(selectDisabledReserves),
    filter(state => state !== undefined),
    tap(state => {
      if (state[this.personId]) {
        this.formGroup.get('createReserves').setValue(false);
        this.formGroup.get('taxRelief').setValue(false);
        this.formGroup.get('employeeContribution').setValue(false);
      }
    }),
    untilDestroyed(this),
  );

  taxReliefHelp = `<div class="text-left">
      Podmínky pro daňové zvýhodnění soukromého životního pojištění podle zákona o daních z příjmů:
      <ul class="m-4">
        <li>pojistník=pojištěný</li>
        <li>pojištění sjednáno minimálně do 60&nbsp;let věku</li>
        <li>pojistná doba minimálně 5&nbsp;let</li>
        <li>pojistná smlouva neumožňuje výběry finančních prostředků během trvání pojištění</li>
      </ul>
      Proto je třeba zvážit a zvolit, zda chci využívat výhody daňového odpočtu zaplaceného pojistného a případně
      příspěvků zaměstnavatele, ale bez možnosti vybírat finanční prostředky ze smlouvy, nebo možnost výběrů finančních
      prostředků ze smlouvy, ale bez daňového zvýhodnění pro mě a mého zaměstnavatele.
  </div>`;

  constructor(private store: Store<State>) {}
}
