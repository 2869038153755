import {Injectable} from '@angular/core';
import {LoginService} from '@lib/services';
import {CustomDimensionsService, UserManagementService} from '@piwikpro/ngx-piwik-pro';

const DIVISION_DIMENSION_ID = '1';
const ROLE_DIMENSION_ID = '2';

@Injectable({
  providedIn: 'root',
})
export class PiwikService {
  constructor(
    private piwikUserManagementService: UserManagementService,
    private piwikCustomDimensionService: CustomDimensionsService,
    private loginService: LoginService,
  ) {
    window._paq = window._paq || [];
  }

  initUserIdAndDimensions(): void {
    if (this.loginService.isLogged()) {
      this.piwikUserManagementService.setUserId(this.loginService.getUserId());
      this.piwikCustomDimensionService.setCustomDimensionValue(
        ROLE_DIMENSION_ID,
        this.loginService.getKfBpmRoles()[0],
      );
    } else {
      this.piwikUserManagementService.resetUserId();
      this.piwikCustomDimensionService.deleteCustomDimension(ROLE_DIMENSION_ID);
    }

    if (this.loginService.advisorDivision) {
      this.piwikCustomDimensionService.setCustomDimensionValue(
        DIVISION_DIMENSION_ID,
        this.loginService.advisorDivision,
      );
    } else {
      this.piwikCustomDimensionService.deleteCustomDimension(DIVISION_DIMENSION_ID);
    }
  }
}
