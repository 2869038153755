/* eslint-disable max-len */
import {WrapperOption} from '@ngx-formly/core/lib/services/formly.config';
import {AssetValueFormlyWrapperComponent} from '@shared/ui/formly/wrappers/asset-value-formly-wrapper/asset-value-formly-wrapper.component';
import {CaptionedInputFormlyWrapperComponent} from '@shared/ui/formly/wrappers/captioned-input-formly-wrapper/captioned-input-formly-wrapper.component';
import {CoreValueFormlyWrapperComponent} from '@shared/ui/formly/wrappers/core-value-formly-wrapper/core-value-formly-wrapper.component';
import {ImportantValueFormlyWrapperComponent} from '@shared/ui/formly/wrappers/important-value-formly-wrapper/important-value-formly-wrapper.component';
import {InsuranceValueFormlyWrapperComponent} from '@shared/ui/formly/wrappers/insurance-value-formly-wrapper/insurance-value-formly-wrapper.component';
import {FormlyWrappers} from './formly.enums';
import {NamedGroupFormlyComponent} from './wrappers/named-group-formly/named-group-formly.component';
import {TabFormlyComponent} from './wrappers/tab-formly/tab-formly.component';
import {TabsFormlyComponent} from './wrappers/tabs-formly/tabs-formly.component';
/* eslint-enable max-len */

export const formlyWrappersDefinition: WrapperOption[] = [
  {
    name: FormlyWrappers.Tabs,
    component: TabsFormlyComponent,
  },
  {
    name: FormlyWrappers.Tab,
    component: TabFormlyComponent,
  },
  {
    name: FormlyWrappers.NamedGroup,
    component: NamedGroupFormlyComponent,
  },
  {
    name: FormlyWrappers.AssetValue,
    component: AssetValueFormlyWrapperComponent,
  },
  {
    name: FormlyWrappers.CoreValue,
    component: CoreValueFormlyWrapperComponent,
  },
  {
    name: FormlyWrappers.ImportantValue,
    component: ImportantValueFormlyWrapperComponent,
  },
  {
    name: FormlyWrappers.InsuranceValue,
    component: InsuranceValueFormlyWrapperComponent,
  },
  {
    name: FormlyWrappers.CaptionedInput,
    component: CaptionedInputFormlyWrapperComponent,
  },
];
