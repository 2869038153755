import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'kpt-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() name: string;
  @Input() date: string;
  @Output() remove = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
