import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DirectivesModule} from '@shared/directives/directives.module';
import {DropdownComponent} from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [DropdownComponent],
})
export class DropdownModule {}
