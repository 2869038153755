import {createSelector} from '@ngrx/store';
import {selectCurrentAssets, selectProposedAssets} from '@shared/analysis/store';
import {pull} from 'lodash';
import {selectClientProposedAssets} from 'src/app/modules/financial-plan/store/client-proposed-assets.selectors';
import {AdvisorProposalState, Asset} from 'src/shared/analysis/models/asset';

/**
 * Select assets which represent the whole proposed state for the client.
 * Same as {@link selectClientProposedAssets} but advisor decision is not ignored, i.e.
 * select assets which represent the whole proposed state which is made
 * 1. only by the advisor,
 * 2. or partially by the advisor and partially by the client,
 * 3. or only by the client.
 */
export const selectClientOrAdvisorProposedAssets = createSelector(
  selectClientProposedAssets,
  selectCurrentAssets,
  selectProposedAssets,
  (clientProposedOnlyAssets, currentAssets, proposedAssets): Asset[] => {
    const clientOrAdvisorProposedAssets = [...clientProposedOnlyAssets];
    pushNewAdvisorOnlyAssets(clientOrAdvisorProposedAssets, proposedAssets);
    pushAdvisorUpdateOnlyAssets(clientOrAdvisorProposedAssets, currentAssets, proposedAssets);
    pullAdvisorTerminatedOnlyAssets(clientOrAdvisorProposedAssets, proposedAssets);
    return clientOrAdvisorProposedAssets;
  },
);

function pushNewAdvisorOnlyAssets(clientOrAdvisorProposedAssets: Asset[], proposedAssets: Asset[]) {
  const newAssets = proposedAssets.filter(asset => {
    const clientUpdatedAsset = proposedAssets.find(a => a.originalAssetUuid === asset.assetUuid);

    const noActionByClient = !asset.clientProposalState;
    const notUpdatedByClient = !clientUpdatedAsset;

    return (
      asset.advisorProposalState === AdvisorProposalState.New &&
      noActionByClient &&
      notUpdatedByClient
    );
  });
  clientOrAdvisorProposedAssets.push(...newAssets);
}

function pushAdvisorUpdateOnlyAssets(
  clientOrAdvisorProposedAssets: Asset[],
  currentAssets: Asset[],
  proposedAssets: Asset[],
) {
  const advisorUpdatedOnlyAssets = proposedAssets.filter(asset => {
    const currentAsset = currentAssets.find(a => a.assetUuid === asset.originalAssetUuid);
    const clientUpdatedAsset = proposedAssets.find(a => a.originalAssetUuid === asset.assetUuid);

    const notApprovedByClient = !asset.clientProposalState;
    const notRejectedOrTerminatedByClient = currentAsset && !currentAsset.clientProposalState;
    const notUpdatedByClient = !clientUpdatedAsset;

    return (
      asset.advisorProposalState === AdvisorProposalState.Updated &&
      notApprovedByClient &&
      notRejectedOrTerminatedByClient &&
      notUpdatedByClient
    );
  });
  clientOrAdvisorProposedAssets.push(...advisorUpdatedOnlyAssets);

  const currentAssetsOfAdvisorUpdatedAssets = advisorUpdatedOnlyAssets.map(asset =>
    currentAssets.find(a => a.assetUuid === asset.originalAssetUuid),
  );
  pull(clientOrAdvisorProposedAssets, ...currentAssetsOfAdvisorUpdatedAssets);
}

function pullAdvisorTerminatedOnlyAssets(
  clientOrAdvisorProposedAssets: Asset[],
  proposedAssets: Asset[],
) {
  const advisorTerminatedOnlyAssets = clientOrAdvisorProposedAssets.filter(asset => {
    const clientUpdatedAsset = proposedAssets.find(a => a.originalAssetUuid === asset.assetUuid);

    const notApprovedOrRejectedByClient = !asset.clientProposalState;
    const notUpdatedByClient = !clientUpdatedAsset;

    return (
      asset.advisorProposalState === AdvisorProposalState.Terminated &&
      notApprovedOrRejectedByClient &&
      notUpdatedByClient
    );
  });

  pull(clientOrAdvisorProposedAssets, ...advisorTerminatedOnlyAssets);
}
