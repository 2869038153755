import {Options} from '@angular-slider/ngx-slider';

export interface SliderOptions extends Options {
  bottomLabel?: SliderBottomLabel | SliderBottomLabelFunction;
  fillBar?: boolean;
  warningRange?: [number, number];
  dangerRange?: [number, number];
}

export type SliderBottomLabel = 'percentage';

export type SliderBottomLabelFunction = (value: any, percentage: number) => string;
