<kpt-accordion-group
  *ngIf="selectedPerson$ | async as person"
  [headingTemplate]="createReservesHeading"
  [preventDefault]="true"
  [isOpen]="!person.child && personData.createReserves"
>
  <ng-template #createReservesHeading>
    <kpt-switch
      [disabledField]="disabled$ | async"
      name="createReserves"
      customFormGroupClass="form-group--full-width-switch"
      [(ngModel)]="personData.createReserves"
      label="Chci mít možnost investovat volné finanční prostředky, případně vytvořit finanční rezervy"
    >
    </kpt-switch>
  </ng-template>

  <ng-container *ngIf="!person.child">
    <div>Chci využít:</div>
    <div>
      <kpt-checkbox
        name="taxRelief"
        [appendTemplate]="taxReliefTmplRef"
        label="Daňové úlevy"
        [(ngModel)]="personData.taxRelief"
      >
      </kpt-checkbox>

      <ng-template #taxReliefTmplRef>
        <kpt-help class="pl-3 hidden-print" [text]="taxReliefHelp" direction="right"></kpt-help>
      </ng-template>
    </div>
    <div>
      <kpt-checkbox
        name="employeeContribution"
        label="Příspěvek zaměstnavatele"
        [(ngModel)]="personData.employeeContribution"
      >
      </kpt-checkbox>
    </div>
  </ng-container>
</kpt-accordion-group>
