import {isNil} from 'lodash';
import * as moment from 'moment';

export const getPersonAge = (birthDate: string, defaultAge: number = null): number | null => {
  if (moment(birthDate).isValid()) {
    return moment().diff(birthDate, 'years');
  } else {
    return !isNil(defaultAge) ? defaultAge : null;
  }
};
