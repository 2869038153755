<div *ngIf="loaded">
  <kpt-tabs [alternativeColors]="true">
    <kpt-tab *ngFor="let person of familyMembers; let index = index" [tabTitle]="person.name">
      <kpt-risks-selection
        [formGroup]="form.get('membersRisks')"
        [person]="person"
        [showWarnings]="false"
      >
      </kpt-risks-selection>
    </kpt-tab>
  </kpt-tabs>
</div>
