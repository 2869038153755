import {Environment} from './environment.model';

export const base: Environment = {
  name: 'base',
  production: false,
  apiBaseUrl: '',
  loginUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/auth',
  logoutUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/logout',
  tokenUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/token',
  clientId: 'newMax',
  clientSecret: '66a17df5-2d89-4950-839f-a599f944d9e5',
  resourceAccess: 'newMax',
  tokenUserIdField: 'sub',
  gtmId: 'GTM-TPWMRX4',
  piwikContainerId: '04b2cd11-f532-42c1-8390-54e6ad928a03',
  piwikContainerUrl: 'https://kapitol.containers.piwik.pro',
  smartformClientId: 'MW5CGCNllS',
  sugarUrl: 'https://tsugar.kapitol.cz/',
  comparatorUrl: 'https://dsrovnavacuveru.kapitol.cz/',
  eRecordUrl: 'https://te-zaznam.kapitol.cz/',
  portalUrl: 'https://tportal.kapitol.cz/',
  poradceUrl: 'https://tporadce.kapitol.cz/',
  poradce1Url: 'https://tporadce1.kapitol.cz/',

  logLocally: true,
  logRemotelly: true,
  remoteLogBufferTime: 500,

  showFeatureFlagsConfig: true,
  showNewDashboard: true,
};
