import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@shared/pipes';
import {DatepickerComponent} from '@shared/ui/forms/datepicker/datepicker.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {CommonFieldModule} from '../common-field/common-field.module';
import {FieldErrorModule} from '../field-error/field-error.module';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    PipesModule,
    ReactiveFormsModule,
    CommonFieldModule,
    FormsModule,
    FieldErrorModule,
  ],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
