import {mapValues} from 'lodash';
import * as moment from 'moment';

export function trimBirthDateWithinObject(values: any) {
  return typeof values.birthDate !== 'string'
    ? {...values, birthDate: moment(values.birthDate).format('YYYY-MM-DD')}
    : values;
}

export function trimSingleDate(date: any) {
  return moment(date).format('YYYY-MM-DD');
}

export function trimDatesWithinObject<T extends object>(
  obj: T,
  dateProps: (keyof T)[],
): Record<keyof T, any> {
  return mapValues(obj, (value, key) =>
    dateProps.includes(key as any) && value && typeof value !== 'string'
      ? trimSingleDate(value)
      : value,
  );
}

export function parseDatesWithinObject<T extends object>(
  obj: T,
  dateProps: (keyof T)[],
): Record<keyof T, any> {
  return mapValues(obj, (value, key) =>
    dateProps.includes(key as any) && value && typeof value === 'string'
      ? moment(value).toDate()
      : value,
  );
}
