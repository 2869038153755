import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from 'src/shared/ui/formly/formly.enums';
import {createTab} from 'src/shared/ui/formly/formly.utils';
import {BaseAsset} from '../models/asset';
import {FinancialProductTab, tabNames} from './definitions.models';

export const createProductTab = (id: FinancialProductTab, ...fields: FormlyFieldConfig[]) =>
  createTab(id, tabNames[id], ...fields);

export const sumValue = (field: FormlyFieldConfig) => {
  const sum = field.parent.fieldGroup.reduce((acc: number, sourceField: FormlyFieldConfig) => {
    if (
      sourceField.type === 'number' &&
      sourceField.key !== 'value' &&
      !sourceField.templateOptions.excludeFromSum
    ) {
      return acc + (isNaN(sourceField.formControl.value) ? 0 : sourceField.formControl.value);
    }
    return acc;
  }, 0);

  field.formControl.parent.get('value').patchValue(sum);
};

export const sumByPaths =
  (targetPath: string, sourcePaths: string[]) => (field: FormlyFieldConfig) => {
    const sum = sourcePaths.reduce((acc: number, sourcePath: string) => {
      return acc + field.form.get(sourcePath).value;
    }, 0);

    field.form.get(targetPath).setValue(sum);
  };

export const updateNetIncome = (field: FormlyFieldConfig) => {
  const netIncome = field.form.get('value').value - field.form.get('otherIncome').value;
  field.form.get('netIncome').setValue(netIncome);
};

// implements BaseAsset
export const getBaseAssetFields = (
  noteTemplateOptions: {[key: string]: any} = {},
): {
  [key in keyof BaseAsset]?: FormlyFieldConfig;
} => {
  return {
    assetUuid: {
      key: 'assetUuid',
      hide: true,
    },
    proposal: {
      key: 'proposal',
    },
    advisorProposalState: {
      key: 'advisorProposalState',
    },
    clientProposalState: {
      key: 'clientProposalState',
    },
    originalAssetUuid: {
      key: 'originalAssetUuid',
    },
    note: {
      key: 'note',
      type: FormlyTypes.Textarea,
      templateOptions: {
        rows: 3,
        ...noteTemplateOptions,
        label: noteTemplateOptions.label ? noteTemplateOptions.label : FieldLabel.NOTE,
      },
    },
    excludedFromAnalysis: {
      key: 'excludedFromAnalysis',
    },
  };
};

export const sliderPercentTranslate = (value: number): string => {
  return value + ' %';
};
