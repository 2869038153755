<kpt-modal-wrapper (close)="dismiss()" [headingTemplate]="headingTemplate">
  <form [formGroup]="form">
    <kpt-radio-group formControlName="category" [options]="categoriesOptions"></kpt-radio-group>
    <div class="text-center">
      <kpt-button (action)="close()" [isSubmitButton]="true" label="Uložit" type="primary">
      </kpt-button>
    </div>
  </form>
</kpt-modal-wrapper>

<ng-template #headingTemplate>
  <h3 class="modal-title">
    Kategorie klienta
    <kpt-help
      class="help-with-link"
      tooltipCustomClass="align-left"
      [text]="
        'Vymezení kategorií klientů při poskytování služeb dle zákona č 256/2004 sb. o podnikání' +
        ' na kapitálovém trhu ' +
        '<a href=\'/assets/files/vymezeni_kategorii_zakazniku.pdf\' target=\'_blank\'>najdete zde</a>'
      "
    >
    </kpt-help>
  </h3>
</ng-template>
