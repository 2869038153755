import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/model';
import {Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {LifeSituation} from '@shared/business-case/store';
import {MenuItem} from '@shared/lib';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BoardSection, CardBoard, ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';
import {ClientPage} from './client-container.config';

export function getModuleCards(boardSection: BoardSection): ModuleCard[] {
  return [boardSection.mainCard, ...boardSection.cards]
    .filter(Boolean)
    .filter(card => !card.disabled && !card.showInAdvisorMenu);
}

export function getComplexPlanMenuItems(boardSection: BoardSection): MenuItem[] {
  return getMenuItems('Řešíme komplexní finanční plán', getModuleCards(boardSection), true);
}

export function getLifeSituationMenuItems(boardSection: BoardSection): MenuItem[] {
  return getMenuItems('Řešíme konkrétní životní situaci', getModuleCards(boardSection), true);
}

export function getLifeSituationMenuItems2(boardSection: BoardSection): MenuItem[] {
  return getMenuItems('Co budeme řešit', getModuleCards(boardSection), true);
}

export function getOtherToolsMenuItems(boardSection: BoardSection): MenuItem[] {
  return getMenuItems('Ostatní nástroje', getModuleCards(boardSection));
}

export function getMenuItems(
  label: string,
  cards: ModuleCard[],
  toggledAtInit?: boolean,
): MenuItem[] {
  return [
    {
      target: null,
      label,
      items: cards,
      toggledAtInit,
    },
  ];
}

@Injectable({
  providedIn: 'root',
})
export class ClientPageConfig {
  constructor(private store: Store<State>, private featureFlagsService: FeatureFlagsService) {}

  getCardBoard(): Observable<CardBoard> {
    return this.store.pipe(
      selectFamilyHead(),
      map(familyMember =>
        this.featureFlagsService.showNewDashboard
          ? this.getCardsV2(familyMember)
          : this.getCardsV1(familyMember),
      ),
    );
  }

  private getCardsV1(familyMember: FamilyMember): CardBoard {
    return {
      complexPlan: {
        mainCard: this.getFinancialAnalysis(),
        cards: [this.getFinancialPlan()],
      },
      lifeSituation: {
        mainCard: this.getSimpleFinancialAnalysis(),
        cards: [
          {
            target: ClientPage.LifeInsuranceOld,
            type: 'primary',
            isEditable: true,
            disabled: false,
            icon: 'life-insurance2',
            label: 'Životní pojištění',
          },
          {
            target: ClientPage.Housing,
            type: 'primary',
            isEditable: true,
            disabled: false,
            icon: 'housing2',
            label: 'Bydlení',
          },
          {
            target: ClientPage.ConsumerLoan,
            type: 'primary',
            isEditable: true,
            disabled: false,
            icon: 'loan',
            label: 'Spotřebitelské úvěry',
          },
          {
            target: ClientPage.PensionOld,
            type: 'primary',
            isEditable: true,
            disabled: false,
            icon: 'pension',
            label: 'Penze',
          },
          {
            target: ClientPage.InvestmentOld,
            type: 'primary',
            isEditable: true,
            disabled: false,
            icon: 'investment',
            label: 'Investice',
          },
          this.getGoldCard(),
        ],
      },
      otherTools: this.getOtherTools(familyMember),
      gold: {
        mainCard: this.getSimpleFinancialAnalysis(),
        cards: [this.getGoldCard()],
      },
    };
  }

  private getCardsV2(familyMember: FamilyMember): CardBoard {
    return {
      lifeSituation: {
        mainCard: {
          target: '',
          type: 'primary',
          icon: 'financial-plan',
          label: 'Finanční plán',
          value: LifeSituation.All,
        },
        cards: [
          {
            target: ClientPage.LifeInsurance,
            type: 'primary',
            icon: 'life-insurance2',
            label: 'Životní pojištění',
            value: LifeSituation.LifeInsurance,
          },
          {
            target: ClientPage.Housing,
            type: 'primary',
            icon: 'housing2',
            label: 'Bydlení',
            value: 'housingLegacy',
          },
          {
            target: ClientPage.ConsumerLoan,
            type: 'primary',
            icon: 'loan',
            label: 'Spotřebitelské úvěry',
            value: 'consumerLoanLegacy',
          },
          {
            target: ClientPage.VehicleInsurance,
            type: 'primary',
            icon: 'car',
            label: 'Pojištění vozidel',
            value: LifeSituation.VehicleInsurance,
          },
          {
            target: ClientPage.Pension,
            type: 'primary',
            icon: 'pension',
            label: 'Penze',
            value: LifeSituation.Pension,
          },
          {
            target: ClientPage.Investment,
            type: 'primary',
            icon: 'investment',
            label: 'Investice',
            value: LifeSituation.Investment,
          },
          {
            target: ClientPage.Gold,
            type: 'primary',
            icon: 'gold2',
            label: 'Zlato',
            value: 'goldLegacy',
          },
          {
            target: ClientPage.PropertyInsurance,
            type: 'primary',
            icon: 'property',
            label: 'Pojištění majetku',
            value: LifeSituation.PropertyInsurance,
          },
        ],
      },
      otherTools: this.getOtherTools(familyMember),
      gold: {
        mainCard: this.getSimpleFinancialAnalysis(),
        cards: [this.getGoldCard()],
      },
    };
  }

  private getOtherTools(familyMember: FamilyMember): {cards: ModuleCard[]} {
    return {
      cards: [
        {
          target: 'https://srovnavacaut.kapitol.cz/',
          type: 'other',
          disabled: false,
          icon: 'car',
          label: 'Pojištění vozidel',
          header: 'Srovnávač',
          isExternalLink: true,
        },
        {
          target: 'https://kapitol.zeteortm.creasoft.cz/',
          type: 'other',
          disabled: false,
          icon: 'property',
          label: 'Pojištění majetku',
          header: 'Srovnávač',
          isExternalLink: true,
        },
        {
          target: `${environment.poradceUrl}portal-poradce/kalendar`,
          type: 'other',
          disabled: false,
          icon: 'plan-meeting',
          label: 'Naplánovat schůzku',
          header: 'Kalendář',
          isExternalLink: true,
        },
        {
          target: `${environment.poradce1Url}klient/${familyMember.sugarUuid}`,
          type: 'other',
          disabled: false,
          icon: 'person2',
          label: 'Data klienta v\u00a0Portále',
          header: 'Portál',
          isExternalLink: true,
        },
        {
          target: `${environment.portalUrl}kalkulacky/`,
          type: 'other',
          disabled: false,
          icon: 'calculator',
          label: 'Kalkulačky',
          header: 'Nástroje',
          isExternalLink: true,
        },
        {
          target: '',
          type: 'other',
          disabled: false,
          icon: 'erecord',
          label: 'Sjednat smlouvu',
          header: 'eZáznam',
          isExternalLink: true,
        },
        {
          target:
            `${environment.poradceUrl}portal-poradce/zadosti/nova-zadost/` +
            `tipy-klienta?recommenderClientId=${familyMember.sugarUuid}`,
          type: 'other',
          disabled: false,
          icon: 'krystufek',
          label: 'Kryštůfek',
          header: 'Doporučení',
          isExternalLink: true,
        },
      ],
    };
  }

  private getFinancialAnalysis(): ModuleCard {
    return {
      target: ClientPage.FinancialAnalysis,
      type: 'primary',
      isEditable: true,
      icon: 'financial-analyse',
      label: 'Finanční analýza',
    };
  }

  private getSimpleFinancialAnalysis(): ModuleCard {
    return {
      target: ClientPage.SimpleFinancialAnalysis,
      type: 'primary',
      isEditable: true,
      icon: 'simplified-fa',
      label: 'Zjednodušená finanční analýza',
    };
  }

  private getFinancialPlan(): ModuleCard {
    return {
      target: ClientPage.FinancialPlan,
      type: 'primary',
      isEditable: true,
      icon: 'financial-plan',
      label: 'Finanční plán',
    };
  }

  private getGoldCard(): ModuleCard {
    return {
      target: ClientPage.Gold,
      type: 'primary',
      isEditable: true,
      icon: 'gold2',
      label: 'Zlato',
    };
  }
}
