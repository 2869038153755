<div id="top" class="body-inner">
  <kpt-sidebar></kpt-sidebar>
  <kpt-header
    [familyId]="familyUuid$ | async"
    [pageName]="title$ | async"
    [clientName]="clientName$ | async"
    [hasStepper]="!!(stepperProgressConfig$ | async) && !!(stepperProgressConfig$ | async).length"
    [advisorPersonalInfo]="personalInfo"
    [advisorDetail]="advisorDetail$ | async"
  >
  </kpt-header>
  <div
    [class.container-content--without-stepper]="
      !(stepperProgressConfig$ | async) ||
      (!!(stepperProgressConfig$ | async) && !(stepperProgressConfig$ | async).length)
    "
    [class.container-content--wide]="wideContainer$ | async"
    class="container-content bg-gradient bg-gradient--var-1"
  >
    <div class="container-fluid">
      <kpt-header-info-row [pageName]="title$ | async"></kpt-header-info-row>
      <kpt-stepper-progress
        class="stepper-progress"
        *ngIf="!!(stepperProgressConfig$ | async) && !!(stepperProgressConfig$ | async).length"
        [config]="stepperProgressConfig$ | async"
      >
      </kpt-stepper-progress>
      <div class="row">
        <div class="col-md-12">
          <main class="main">
            <router-outlet></router-outlet>
            <kpt-modal-dynamic *ngIf="!!(modalService.activeModal$ | async)"></kpt-modal-dynamic>
          </main>
        </div>
      </div>
    </div>
  </div>
  <kpt-notification-center
    [messages]="notificationService.messages"
    [connectionInfo]="notificationService.connectionInfo"
  >
  </kpt-notification-center>
</div>
