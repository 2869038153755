<div class="page-shadow" *ngIf="show$ | async" (click)="close()"></div>
<div [class.show]="show$ | async" class="hamburger-menu">
  <div class="menu-container">
    <kpt-card-action>
      <ng-template let-cardAction="cardAction">
        <div class="menu-header">
          <div class="menu-header-top">
            <div class="header__controls--left">
              <img
                src="assets/images/logo-kappka-white.svg"
                class="menu-logo"
                alt="logo"
                [title]="'Kappka'"
              />
            </div>
            <div class="close-icon-container" (click)="close()">
              <a class="control menu-icon"><span class="icon icon--close"></span></a>
              <div class="icon-close-label">Zavřít</div>
            </div>
          </div>
          <div class="menu-tabs">
            <div
              *ngIf="!!familyUuid"
              [class.menu-tab-active]="(activeTab$ | async) === 'client'"
              class="menu-tab"
              (click)="setActiveTab('client')"
            >
              Klient
              <br />{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}
            </div>
            <div
              [class.menu-tab-active]="(activeTab$ | async) === 'advisor'"
              class="menu-tab"
              (click)="setActiveTab('advisor')"
            >
              Poradce
              <br />{{ (advisor$ | async)?.personalInfo.firstName }}
              {{ (advisor$ | async)?.personalInfo.lastName }}
            </div>
            <!--<div [class.menu-tab-active]="(activeTab$ | async) === 'articles'"
                 class="menu-tab" (click)="setActiveTab('articles')">Články
            </div>
            <div [class.menu-tab-active]="(activeTab$ | async) === 'notes'"
                 class="menu-tab" (click)="setActiveTab('notes')">Poznámky
            </div>-->
          </div>
        </div>
        <div class="menu-body" [ngSwitch]="activeTab$ | async">
          <kpt-client-section
            *ngSwitchCase="'client'"
            [user]="user$ | async"
            (navigate)="cardAction($event) && close()"
          >
          </kpt-client-section>
          <kpt-advisor-section
            *ngSwitchCase="'advisor'"
            [advisor$]="advisor$"
            [hasClientSelected]="!!familyUuid"
            (navigate)="cardAction($event) && close()"
          >
          </kpt-advisor-section>
          <div *ngSwitchCase="'articles'">Články</div>
          <div *ngSwitchCase="'notes'">Poznámky</div>
        </div>
        <a
          *ngIf="featureFlagsService.showFeatureFlagsConfig"
          class="feature-flags"
          (click)="openFeatureFlagsModal()"
          >Feature flags</a
        >
      </ng-template>
    </kpt-card-action>
  </div>
</div>
