import {col, row} from '@lib/utils';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FieldLabel} from '@shared/analysis/field-label';
import {getBaseAssetFields} from '@shared/analysis/forms/forms.helpers';
import {isNilOrEmpty} from '@shared/lib';
import {SliderOptions} from '@shared/ui';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {getBasicField} from '@shared/ui/formly/formly.utils';
import {round} from 'lodash';

const calcOwnPartValue = () => (field: FormlyFieldConfig) => {
  const fieldForm = field.form;

  if (!fieldForm) return;

  const totalValueFormControl = fieldForm.get('totalValue');
  const ratioFormControl = fieldForm.get('ownValueRatio');

  if ([totalValueFormControl.errors, ratioFormControl.errors].some(Boolean)) return;

  const totalValue = totalValueFormControl.value;
  const ratio = ratioFormControl.value;

  if ([totalValue, ratio].some(isNilOrEmpty)) return;
  fieldForm.get('value').setValue(round(totalValue * ratio));
};

const calcOwnPartRatio = () => (field: FormlyFieldConfig) => {
  const fieldForm = field.form;

  if (!fieldForm) return;

  const totalValueFormControl = fieldForm.get('totalValue');
  const ownValueFormControl = fieldForm.get('value');

  if ([totalValueFormControl.errors, ownValueFormControl.errors].some(Boolean)) return;

  const totalValue = totalValueFormControl.value;
  const ownValue = ownValueFormControl.value;

  if ([totalValue, ownValue].some(value => isNilOrEmpty(value) || value === 0)) {
    fieldForm.get('ownValueRatio').reset();
    return;
  }
  fieldForm.get('ownValueRatio').setValue(ownValue / totalValue);
};

const calcValue = {change: calcOwnPartValue()};

const calcPercentage = {change: calcOwnPartRatio()};

export const defaultObjectiveMaxValue = 20_000_000;

export const getValueSliderField = (
  valueLabel: string,
  ceil: number,
  hideExpression?: string,
  stepRatio = 0.01,
  floor = 0,
): FormlyFieldConfig => ({
  key: 'value',
  type: FormlyTypes.SliderInput,
  templateOptions: {
    label: valueLabel,
    appendText: 'Kč',
    sliderOption: {
      floor,
      step: ceil * stepRatio,
      ceil,
      translate: value => `${value} Kč`,
    } as SliderOptions,
  },
  hideExpression,
});

export const getObjectivesField = (): FormlyFieldConfig[] => {
  const baseAsset = getBaseAssetFields();

  return [
    baseAsset.assetUuid,
    baseAsset.note,
    {
      key: 'description',
      type: FormlyTypes.Textarea,
      templateOptions: {
        rows: 2,
        label: FieldLabel.OBJECTIVE_DESCRIPTION,
      },
    },
    {key: 'selected'},
  ];
};

export const getStartValueField = (
  startLabel: string,
  valueLabel: string,
  startOfYear = false,
  valueExpressionProperties = {},
  maxValue = defaultObjectiveMaxValue,
  templateOptions: any = {},
): FormlyFieldConfig => ({
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      ...getBasicField(FormlyTypes.Year, 'start', startLabel, {startOfYear}),
      className: 'col-6',
    },
    {
      ...getBasicField(FormlyTypes.Currency, 'value', valueLabel, {
        max: maxValue,
        ...templateOptions,
      }),
      className: 'col-6',
      expressionProperties: valueExpressionProperties,
    },
  ],
});

export const getHousingFields = (startLabel: string): FormlyFieldConfig[] => [
  row([
    col(getBasicField(FormlyTypes.Year, 'start', startLabel)),
    col(
      getBasicField(FormlyTypes.Currency, 'totalValue', FieldLabel.TOTAL_VALUE, {
        max: defaultObjectiveMaxValue,
        ...calcPercentage,
      }),
    ),
  ]),
  row([
    col(
      getBasicField(FormlyTypes.Percentage, 'ownValueRatio', FieldLabel.OWN_VALUE_RATIO, {
        min: 0,
        max: 1,
        precision: 2,
        ...calcValue,
      }),
    ),
    col(
      getBasicField(FormlyTypes.Currency, 'value', FieldLabel.OBJECTIVE_VALUE, {
        max: defaultObjectiveMaxValue,
        ...calcPercentage,
      }),
    ),
  ]),
];
