import {Component, Input} from '@angular/core';
import {GetBusinessCaseResponse} from '@generated/defs/GetBusinessCaseResponse';
import {DialogService} from '@shared/services/modal.service';
import * as moment from 'moment/moment';
import {PreviousPlanDetailModalComponent} from './previous-plan-detail-modal/previous-plan-detail-modal.component';

@Component({
  selector: 'kpt-previous-plans-card',
  templateUrl: './previous-plans-card.component.html',
  styleUrls: ['./previous-plans-card.component.scss'],
})
export class PreviousPlansCardComponent {
  @Input() finalizedBusinessCases: GetBusinessCaseResponse[] = [];

  constructor(private dialogService: DialogService) {}

  getTimeAgo(businessCase: GetBusinessCaseResponse): string {
    const timeAgo = moment(businessCase.timestamp).fromNow();
    return `Dokončen, ${timeAgo}`;
  }

  getYearLabel(businessCase: GetBusinessCaseResponse): string {
    return `Finanční plán ${moment(businessCase.timestamp).year()}`;
  }

  openDetailModal(businessCase: GetBusinessCaseResponse) {
    this.dialogService.load(
      PreviousPlanDetailModalComponent,
      {
        header: this.getYearLabel(businessCase),
        fpDocument: businessCase.documentDownloadUrl,
        fpAttachment: businessCase.fpAttachment?.downloadUrl,
        faResults: businessCase.faResults?.downloadUrl,
      },
      {size: 'sm'},
    );
  }

  scrollToFourthPlan() {
    const parent = document.getElementsByClassName('previous-plan-card-wrapper').item(0);
    const child = document.getElementsByClassName('previous-plan-card').item(4);
    if (!parent || !child) {
      return;
    }

    const parentRect = parent.getBoundingClientRect();

    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    const childRect = child.getBoundingClientRect();
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.bottom <= parentRect.top + parentViewableArea.height;

    if (!isViewable) {
      const scrollTop = childRect.top - parentRect.top;
      const scrollBot = childRect.bottom - parentRect.bottom;
      if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        parent.scrollTop += scrollTop;
      } else {
        parent.scrollTop += scrollBot;
      }
    }
  }
}
