<kpt-asset-form-ng-model
  *ngIf="useNgModel$ | async"
  [value]="value"
  (valueChange)="valueChange.emit($event)"
  [disabled]="disabled"
  [showRelatedAssetHeader]="showRelatedAssetHeader"
  [mode]="mode"
  [selectedPersonId]="selectedPersonId"
  [showPersonSelector]="showPersonSelector"
></kpt-asset-form-ng-model>

<kpt-asset-form-formly
  *ngIf="!(useNgModel$ | async)"
  [value]="value"
  (valueChange)="valueChange.emit($event)"
></kpt-asset-form-formly>
