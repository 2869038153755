export enum FieldLabel {
  // Family
  FIRST_NAME = 'Jméno',
  LAST_NAME = 'Příjmení',
  BIRTH_DATE = 'Datum narození',
  BIRTH_NUMBER = 'Rodné číslo',
  SEX = 'Pohlaví',
  PHONE_NUMBER = 'Telefon',
  EMAIL = 'E-mail',
  MARTIAL_STATUS = 'Rodinný stav',
  STREET = 'Bydliště (ulice, č. popisné)',
  CITY = 'Město',
  ZIP_CODE = 'PSČ',
  PLACE_OF_BIRTH = 'Místo narození',
  NATIONALITY = 'Státní příslušnost',
  DOCUMENT_TYPE = 'Druh dokladu',
  DOCUMENT_NUMBER = 'Číslo dokladu',
  AUTHORITY = 'Doklad vydal',
  STATE_AUTHORITY = 'Stát vydání dokladu',
  DATE_OF_EXPIRY = 'Platnost dokladu do',
  FAMILY_HEAD = 'Hlavní klient (pro modelaci)',

  // Objective assets
  TOTAL_VALUE = 'Celková výše investice',
  OWN_VALUE_RATIO = 'Podíl vlastních prostředků',
  OBJECTIVE_VALUE = 'Chci si našetřit částku',
  OBJECTIVE_DESCRIPTION = 'Stávající stav',
  OWN_HOUSING_START = 'Chci si pořídit vlastní bydlení ve věku',
  CHILDREN_FUTURE_START = 'Chci svému dítěti dát našetřené peníze v mém věku',
  RETIREMENT_FAMILY_MEMBER_RADIO_LABEL = 'Do penze půjde',
  RETIREMENT_INPUT_MODE = 'Zadat měsíční rentu',
  RETIREMENT_START = 'Chci odejít do penze ve věku',
  RETIREMENT_MONTHLY_RENT = 'Částka, kterou chci v penzi měsíčně',
  RETIREMENT_PENSION_DURATION = 'Počet let v penzi',
  INDEPENDENCE_INPUT_MODE = 'Zadat měsíční rentu',
  INDEPENDENCE_START = 'Chci dosáhnout finanční nezávislosti ve věku',
  INDEPENDENCE_MONTHLY_RENT = 'Částka, kterou chci měsíčně',
  INDEPENDENCE_LIFE_EXPECTANCY = 'Očekávám, že se dožiji',
  SHORT_RESERVE_START = 'Chci si našetřit krátkodobou rezervu ve věku',
  SHORT_RESERVE_VALUE = 'Chci mít rezervu ve výši',
  LONG_TERM_RESERVE_START = 'Chci si našetřit prostředky ve věku',
  LONG_TERM_RESERVE_VALUE = 'Chci mít prostředky ve výši',
  BAD_DEBTS_REPAYMENT_START = 'Chci splatit svoje špatné úvěry ve věku',
  BAD_DEBTS_REPAYMENT_VALUE = 'Výše špatných úvěrů',
  PROVISION_START = 'Chci mít zajištění ve věku',
  CHILD_BIRTH_START = 'Plánujeme dítě ve věku',
  CHILD_BIRTH_MATERNITY = 'Na mateřskou dovolenou půjde',
  CHILD_BIRTH_PARENTAL = 'Na rodičovskou dovolenou půjde',
  CHILD_BIRTH_PARENTAL_DURATION = 'Délka pobírání rodičovského příspěvku',
  NEW_HOUSING_START = 'Chci si pořídit nové bydlení ve věku',
  RECONSTRUCTION_START = 'Chci rekonstruovat bydlení ve věku',
  FURNISHING_START = 'Chci si pořídit nové vybavení ve věku',
  EXPECTED_VALUE = 'Očekávaná částka',
  NEW_CAR_START = 'Chci nové auto ve věku',
  VACATION_START = 'Chci jet na pořádnou dovolenou ve věku',
  OBJECTIVE_ONE_TIME_PAYMENT = 'jednorázová částka',
  OBJECTIVE_MONTHLY_PAYMENT = 'měsíční částka',
  OTHER_OBJECTIVE_ONE_TIME_START = 'Chci si to splnit ve věku',
  OTHER_OBJECTIVE_MONTHLY_START = 'Platit budu',
  OTHER_OBJECTIVE_MONTHLY_VALUE = 'Očekávaná měsíční částka',

  // Common labels
  NOTES = 'Poznámky',
  NOTE = 'Poznámka',
  NAME = 'Název',
  MONTHLY_PAYMENT = 'Měsíční částka',
  VALUE = 'Částka',
  YEARLY_RATE = 'Úroková sazba',
  LIMIT = 'Limit',

  // Finances assets
  COMMON_MONTHLY_EXPENSES = 'Běžné měsíční výdaje',
  INSURANCE_PRODUCT_MONTHLY_EXPENSES = 'Měsíční výdaje na pojistné produkty',
  INVESTMENT_PRODUCT_MONTHLY_EXPENSES = 'Měsíční výdaje na spořící a investiční produkty',
  CREDIT_PRODUCT_MONTHLY_EXPENSES = 'Měsíční výdaje na úvěrové produkty',
  MAIN_FAMILY_INCOMES = 'Hlavní měsíční příjmy',
  OTHER_INCOMES = 'Další měsíční příjmy',
  EXPENSE_START = 'Kdy výdaj začne?',
  EXPENSE_END = 'Kdy výdaj skončí?',
  INCOME_START = 'Kdy příjem začne?',
  INCOME_END = 'Kdy příjem skončí?',
  INDISPENSABLE_PART = 'Jaká část výdaje je nezbytná?',

  RENT = 'Nájem/splátky hypotéky',
  WATER = 'Voda',
  GAS = 'Plyn',
  ELECTRICITY = 'Elektřina',
  HEATING = 'Teplo',
  REPAIRS_FUND = 'Fond oprav',
  OTHER = 'Ostatní',

  FOOD = 'Jídlo',
  LUNCH = 'Oběd',
  DINNER = 'Večeře',

  INTERNET = 'Internet',
  PHONE = 'Telefon',
  TELEVISION = 'Televize',

  BASIC_DRUGSTORE = 'Základní drogerie',
  CHILDREN_HYGIENE = 'Hygienické potřeby pro děti',

  FUEL = 'Pohonné hmoty',
  CAR_SERVICE = 'Pravidelná údržba vozidla',
  PUBLIC_TRANSPORT = 'MHD / Veřejná doprava',
  TAXI = 'Taxi',

  YEARLY_VALUE = 'Celkové roční výdaje',

  DRUGS = 'Léky',
  ENTERTAINMENT = 'Zábava',
  ALIMONY = 'Výživné',
  CIGARETTES = 'Cigarety',
  ALCOHOL = 'Alkohol',
  PRESENTS = 'Dárky',

  HEALTH_INSURANCE = 'Zdravotní pojištění',
  SOCIAL_INSURANCE = 'Sociální pojištění',
  SICKNESS_INSURANCE = 'Výše nemocenského pojištění',

  NET_INCOME = 'Čistý příjem',
  OTHER_INCOME = 'Ostatní příjem',
  MONTHLY_INCOME = 'Celkový měsíční čistý příjem',
  PROFESSION = 'Profese',

  // Properties assets
  TANGIBLE_START = 'Od kdy je majetek k dispozici?',
  TANGIBLE_VALUE = 'Současná tržní hodnota',
  PURPOSE = 'Účel',
  OWNERSHIP_TYPE = 'Druh vlastnictví?',
  TOTAL_AREA = 'Celková plocha',
  IMMOVABLE_PROPERTY_AREA = 'Plocha nemovitosti',
  HOUSEHOLD_AREA = 'Plocha domácnosti',

  FINANCIAL_PROPERTY_START = 'Výše majetku platná ke dni',
  FINANCIAL_PROPERTY_VALUE = 'Objem vložených prostředků',
  INSTITUTION_NAME = 'Název instituce',

  // Contracts
  CONTRACT_START = 'Sjednáno od',
  CONTRACT_END = 'Sjednáno do',
  CONTRACT_NUMBER = 'Číslo smlouvy',
  CONTRACT_PAYMENT = 'Platba',
  CONTRACT_PARTNER = 'Partner',
  CONTRACT_PRODUCT = 'Produkt',
  REGULAR_DEPOSIT = 'Pravidelný vklad',
  FREQUENCY = 'Frekvence',
  STAKEHOLDER = 'Koho je smlouva?',
  GOAL = 'Cíl',
  BANK_ACCOUNT_NUMBER = 'Číslo účtu',
  VARIABLE_SYMBOL = 'Variabilní symbol',
  CONTRACT_PAYMENT_START = 'Datum první platby',
  CONTRACT_PAYMENT_END = 'Datum poslední platby',
  CONTRACT_PAYMENT_DAY = 'Den platby v rámci období',
  CONTRACT_PAYMENT_NOTE = 'Poznámka k platbě',
  RELATED_OBJECTIVE = 'K jaké prioritě se vztahuje?',
  RELATED_PROPERTY = 'K jakému majetku se vztahuje?',
  INSURED_PERSONS = 'Pojištěné osoby',
  INSURED_PERSON = 'Pojištěná osoba',
  INCAPACITY = 'Pracovní neschopnost',
  DAILY_COMPENSATION = 'Denní odškodné',
  PHYSICAL_DAMAGE = 'Tělesné poškození',
  HOSPITALIZATION = 'Hospitalizace',
  SERIOUS_DISEASE = 'Vážná onemocnění',
  PERMANENT_DISABILITY = 'Trvalé následky úrazu',
  INVALIDITY = 'Invalidita',
  INVALIDITY_DEBT_REPAYMENT = 'Invalidita – splacení úvěru',
  DEATH_DEBT_REPAYMENT = 'Smrt – splacení úvěru',
  DEATH_THRICE_ANNUAL_INCOME = 'Smrt – zajištění rodiny 3 roční příjmy',
  DEATH_CHILDREN_ENSUREMENT = 'Smrt – zajištění dětí',
  CHILD_DAILY_COMPENSATION = 'Denní odškodné',
  CHILD_PHYSICAL_DAMAGE = 'Tělesné poškození',
  CHILD_HOSPITALIZATION = 'Hospitalizace',
  CHILD_SERIOUS_DISEASE = 'Vážná onemocnění',
  CHILD_PERMANENT_DISABILITY = 'Trvalé následky úrazu',
  CHILD_SURVIVAL = 'Dožití',

  FUTURE_VALUE = 'Předpokládaná hodnota účtu',
  FUTURE_VALUE_DATE = 'Předpokládaná hodnota účtu ke dni',

  OUTSTANDING_VALUE = 'Zbývá splatit',
  OUTSTANDING_VALUE_DATE = 'Zbývá splatit platné ke dni',

  ORIGINAL_MORTGAGE_VALUE = 'Původní výše hypotéky',
  ORIGINAL_LOAN_VALUE = 'Původní výše úvěru',
  ORIGINAL_VALUE = 'Původní výše',
  CONTRACT_PURPOSE = 'Účel smlouvy',
  FIXATION_IN_YEARS = 'Počet let fixace',
  NEAREST_FIXATION_DATE = 'Datum konce fixace',
  ORIGINAL_BANK = 'Původní banka',
  ORIGINAL_LTV = 'Původní LTV',
  PROPERTY_ESTIMATED_VALUE = 'Předpokládaná aktuální hodnota zastavované nemovitosti',
  HAS_REAL_ESTATE_INSURANCE = 'Je sjednáno pojištění nemovitosti',
  REAL_ESTATE_INSURANCE = 'Jaké pojištění nemovitosti',
  DEBTORS = 'Dlužníci',
  OTHER_DEBTORS = 'Jiní dlužníci',
  PRESENT_VALUE = 'Současná hodnota investovaných prostředků',
  PRESENT_VALUE_DATE = 'Současná hodnota platná ke dni',
  TARGET_AMOUNT = 'Cílová částka',
  INSURANCE_OF_CREDIT_PRODUCT = 'Je sjednaný produkt',

  EMPLOYER_CONTRIBUTION = 'Příspěvek zaměstnavatele',
  EMPLOYER_NAME = 'Název zaměstnavatele',
  EMPLOYER_ID = 'IČO zaměstnavatele',
  EMPLOYER_CONTACT = 'Kontaktní osoby zaměstnavatele',

  PROPERTY = 'Nemovitost',
  REQUIRED_INSURED_AMOUNT = 'Nemovitost \u2013 pojistná částka',
  HOUSEHOLD = 'Domácnost',
  HOUSEHOLD_VALUE = 'Domácnost \u2013 pojistná částka',
  HOLDING_INSURANCE = 'Odpovědnost z vlastnictví nebo držby nemovitosti',
  CIVIL_LIABILITY = 'Odpovědnost v běžném občanském životě',

  PARTICIPATION_NAME = 'Název spoluúčasti',
  PARTICIPATION_VALUE = 'Částka spoluúčasti',
  PARTICIPATION_TYPE = 'Typ spoluúčasti',
  PARTICIPATION = 'Spoluúčast',
  PLACE_OF_INSURANCE = 'Místo pojištění',
  INSURANCE_TYPE = 'Varianta produktu/balíček',

  RISK_GROUP = 'Riziková skupina',
  INSURANCE_SCOPE = 'Rozsah pojištění',
  REGION = 'Územní platnost',
  INSURANCE_LIMIT = 'Limit pojistného plnění',
  COMPULSORY_INSURANCE = 'POV',
  COMPULSORY_INSURANCE_LIMIT = 'Limit POV',
  COMPULSORY_INSURANCE_HEALTH = 'Limit POV zdraví',
  COMPULSORY_INSURANCE_PROPERTY = 'Limit POV majetek',
  ACCIDENT_INSURANCE = 'HAV',
  ACCIDENT_INSURANCE_TOTAL = 'Pojistná částka HAV',

  COMPULSORY_INSURANCE_LONGER = 'POV (povinné ručení)',
  ACCIDENT_INSURANCE_LONGER = 'HAV (havarijní pojištění)',
  THEFT = 'Odcizení',
  WINDSHIELD = 'Čelní sklo',
  ALL_WINDOWS = 'Všechna výhledová skla',
  BAGGAGE = 'Zavazadla',
  ACCIDENT_ASSISTANCE = 'Pomoc při nehodě',
  LEGAL_PROTECTION = 'Právní ochrana',
  ANIMAL_DAMAGE = 'Poškození vozidla zvířetem',
  VANDALISM = 'Vandalismus',
  NATURAL_DISASTERS = 'Živelné pohromy',
  OTHER_RISK = 'Jiné',
  UNINTENTIONAL_ACCIDENT_INSURANCE = 'Pojištění nezaviněné nehody',
  REPLACEMENT_VEHICLE = 'Náhradní vozidlo',
  NON_STANDARD_EQUIPMENT = 'Nestandardní výbava',

  MANUFACTURER = 'Tovární značka',
  MODEL = 'Model',
  CATEGORY = 'Kategorie',
  FUEL_TYPE = 'Palivo',
  REGISTRATION_PLATE = 'RZ',
  VEHICLE_IDENTIFICATION_NUMBER = 'VIN',
  VEHICLE_REGISTRATION_CERTIFICATE = 'Číslo VTP',
  YEAR_BEGINNING_USE = 'Rok uvedení do provozu',
  MILEAGE = 'Stav tachometru',
  ANNUAL_MILEAGE = 'Roční nájezd',
  LEASING = 'Leasing',
  SEATS = 'Počet sedadel',
  ENGINE_CAPACITY = 'Objem motoru',
  ENGINE_POWER = 'Výkon',
  WEIGHT = 'Váha',

  ALARM = 'Alarm',
  IMMOBILIZER = 'Imobilizér',
  MECHANICAL_SECURITY = 'Mechanické zabezpečení',
  ACTIVE_SEARCH_SYSTEM = 'Aktivní vyhledávací systém',
  VIN_ON_GLASS = 'VIN na skle',
  POLICYHOLDER = 'Pojistník',
  HOLDER = 'Držitel',
  OWNER = 'Vlastník',

  STRATEGY = 'Strategie',
  CONSERVATION_POSSIBILITY = 'Možnost zkonzervativnění',
  LEASING_TYPE = 'Smlouva pro',
  CREDIT_LIMIT = 'Úvěrový limit',

  REINSURANCE = 'Zajištění',

  MEDICAL_EXPENSES_INSURANCE = 'Pojištění léčebných výloh',
  INSURANCE_UZO = 'Pojištění ÚZO (úraz, zavazadla, odpovědnost)',
  TERRITORIAL_SCOPE = 'Územní rozsah',
  RISK_SPORTS = 'Rizikové sporty',
  RISK_SPORTS_SPECIFICATION = 'Upřesnění rizikových sportů',
}
