import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IDotsDirection} from './models/dots-direction.model';
import {IIconPosition} from './models/icon-position.model';
// own models
import {IButtonSize} from './models/size.model';
import {IButtonType} from './models/type.model';
import {IButtonVariant} from './models/variant.model';

@Component({
  selector: 'kpt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() customClass?: string;
  @Input() customIconClass?: string;
  @Input() dotsDirection?: IDotsDirection;
  @Input() hasOutline = false;
  @Input() icon?: string;
  @Input() iconPosition?: IIconPosition;
  @Input() isDisabled = false;
  @Input() isSubmitButton = false;
  @Input() isLoading = false;
  @Input() label?: string;
  @Input() size?: IButtonSize;
  @Input() type?: IButtonType;
  @Input() variant?: IButtonVariant;
  @Input() removeFocus?: boolean;

  @ViewChild('button')
  buttonElement: ElementRef<HTMLButtonElement>;

  constructor() {
    this.dotsDirection = Object.values(IDotsDirection).includes(this.dotsDirection)
      ? this.dotsDirection
      : null;
    this.iconPosition = Object.values(IIconPosition).includes(this.iconPosition)
      ? this.iconPosition
      : IIconPosition.LEFT;
    this.size = Object.values(IButtonSize).includes(this.size) ? this.size : null;
    this.type = Object.values(IButtonType).includes(this.type) ? this.type : null;
    this.variant = Object.values(IButtonVariant).includes(this.variant) ? this.variant : null;
  }

  click(event: Event) {
    this.action.emit(event);
    if (this.removeFocus) {
      this.buttonElement.nativeElement.blur();
    }
  }
}
