export const GRAPH_CONST = {
  COL_PADDING: 8,
  COL_COUNT: 12,
  MIN_AGE: 18,
  MAX_AGE: 80,
  HALFLIFE_AGE: 50,
  PENSION_AGE: 65,
  ICONS: {
    plus: '\ue908',
    bulb: '\ue90a',
  },
  PICTURES: {
    MALE: '/assets/images/illustrations/silhouette_man.svg',
    FEMALE: '/assets/images/illustrations/silhouette_woman.svg',
  },
  PICTURES_GREEN: {
    MALE: '/assets/images/illustrations/silhouette_man_green.svg',
    FEMALE: '/assets/images/illustrations/silhouette_woman_green.svg',
  },
  MAX_STAGE_HEIGHT: 250,
  LINE: {
    VERTICAL_DASHED: 30,
  },
  SILHOUETTE: {
    WIDTH: 100,
    HEIGHT: 170,
  },
  SILHOUETTE_SMALL: {
    WIDTH: 88,
    HEIGHT: 150,
  },
  LIFE_LINE: {
    OFFSET_Y: 200,
    OFFSET_Y_LOW: 60,
    OFFSET_RECOMMENDATION_Y: 450,
    TEXT_OFFSET_X: 5,
    TEXT_OFFSET_Y: 15,
  },
  MONEY_LINE: {
    OFFSET_Y: 10,
  },
  TOOLTIP_SPACE: 30,
};
