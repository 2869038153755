<div class="sorting-wrapper" (click)="onToggleSort()">
  <ng-container *ngIf="direction === directions.NONE">
    <span class="icon icon--arrow-up"></span>
    <span class="icon icon--arrow-down"></span>
  </ng-container>
  <span
    *ngIf="direction !== directions.NONE"
    class="icon"
    [ngClass]="[direction === directions.ASC ? 'icon--arrow-up' : 'icon--arrow-down']"
  >
  </span>
</div>
