import {Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FieldWrapper} from '@ngx-formly/core';
import {coreContractToAsset} from '@shared/analysis/core-sync.models';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {selectCoreContractByUuid} from '@shared/analysis/store/core-contracts.selectors';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {State} from 'src/store';

@UntilDestroy()
@Component({
  selector: 'kpt-core-value-formly-wrapper',
  templateUrl: './core-value-formly-wrapper.component.html',
})
export class CoreValueFormlyWrapperComponent extends FieldWrapper implements OnInit {
  coreContract$: Observable<Partial<CommonFinancialProductAsset>>;
  coreValue$: Observable<any>;

  constructor(private store: Store<State>) {
    super();
  }

  isEqualToCurrentValue(value: any): boolean {
    return value === this.field.formControl.value;
  }

  ngOnInit() {
    const sugarUuid = this.form.get('sugarUuid')?.value;
    if (!sugarUuid) return;

    this.coreContract$ = this.store.pipe(
      select(selectCoreContractByUuid(sugarUuid)),
      filter(contract => !!contract),
      map(contract => coreContractToAsset(contract)),
    );

    const fieldName = String(this.field.key);
    this.coreValue$ = this.coreContract$.pipe(map(contract => (contract as any)[fieldName]));
  }

  setValue(value: any) {
    const fieldName = String(this.field.key);
    this.form.get(fieldName).setValue(value);
  }

  // TODO ngOnInit check field, subscribe needed sources and create formatting method
  formatValue(value: any) {
    const {type, templateOptions} = this.field;

    if (type === 'text') return value;

    if (type === 'select') {
      const options = templateOptions?.options;
      if (!Array.isArray(options)) return '';
      const label = options.find(option => option.key === value)?.label;
      return label || '';
    }

    if (type === 'datepicker') {
      const date = new Date(value);
      return date.toLocaleDateString();
    }

    return '';
  }
}
