import {Pipe, PipeTransform} from '@angular/core';
import {getYearsPluralString} from '@shared/lib/utils/utils';
import {isNumber} from 'lodash';

@Pipe({
  name: 'years',
})
export class YearsPipe implements PipeTransform {
  transform(value: number): any {
    if (!isNumber(value)) return value;
    return getYearsPluralString(value);
  }
}
