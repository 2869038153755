<ng-container *ngFor="let result of results; let index = index">
  <div [class.more-participants]="index < results.length - 1">
    <h2 class="evaluation-title">Vyhodnocení {{ result.lifeInsuranceResult.name }}</h2>

    <h2 class="section-title">Životní pojištění</h2>
    <kpt-investment-survey-life-insurance-summary [results]="result.lifeInsuranceResult">
    </kpt-investment-survey-life-insurance-summary>

    <h2 class="section-title">Investice</h2>
    <kpt-investment-survey-investment-summary
      [resultForm]="result.investmentResult"
      [familyMemberUuid]="result.sugarUuid"
      [loadInvestmentSurveyData]="index === 0"
      [readonly]="readonly"
    >
    </kpt-investment-survey-investment-summary>

    <h2 class="section-title">Doplňkové penzijní spoření</h2>
    <kpt-investment-survey-pension-summary [result]="result.supplementaryPensionSavingsResult">
    </kpt-investment-survey-pension-summary>

    <kpt-summary-agreement></kpt-summary-agreement>
  </div>
</ng-container>
