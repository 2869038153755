import {DecimalPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

import {PrecisionPipe} from '../precision/precision.pipe';

const defaultDigitsInfo = '1.2-2';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  constructor(
    private precisionPipe: PrecisionPipe,
    private decimalPipe: DecimalPipe,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  transform(
    amount: any,
    digitsInfo: string = defaultDigitsInfo,
    locale: string = this.locale,
  ): any {
    const number = +this.precisionPipe.transform(amount.value, amount.precision);
    const formattedNumber = this.decimalPipe.transform(number, digitsInfo, locale);
    return `${formattedNumber} ${amount.currency}`;
  }
}
