import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {TranslateFunction} from '@angular-slider/ngx-slider/options';

/**
 * A version 2 of slider component. Replaces kpt-slider. Compatible with
 * template-driven forms.
 */
@Component({
  standalone: true,
  selector: 'kpt-slider2',
  templateUrl: './slider2.component.html',
  styleUrls: ['./slider.component.scss'],
  imports: [NgxSliderModule],
})
export class Slider2Component {
  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();
  @Input() highValue: number;
  @Output() highValueChange = new EventEmitter<number>();

  @Input() label: string;
  @Input() floor: number;
  @Input() ceil: number;
  @Input() step = 1;
  @Input() translate: TranslateFunction;
  @Input() disabled = false;
}
