import {Component, ContentChild, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {select, Store} from '@ngrx/store';
import {selectFamilyHead} from '@shared/analysis/operators';
import {DialogService} from '@shared/services/modal.service';
import {isString} from 'lodash';
import {ERecordModalComponent} from 'src/app/modules/client/card-board/e-record-modal/e-record-modal.component';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';
import {environment} from 'src/environments/environment';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';

@Component({
  selector: 'kpt-card-action',
  template: `
    <ng-template
      [ngTemplateOutletContext]="{
        cardAction: cardAction.bind(this)
      }"
      [ngTemplateOutlet]="template"
    >
    </ng-template>
  `,
})
export class CardBoardActionComponent {
  @ContentChild(TemplateRef)
  template: TemplateRef<unknown>;

  private familyHead: FamilyMember;
  private familyUuid: string;

  constructor(
    private store: Store<State>,
    private router: Router,
    private dialogService: DialogService,
  ) {
    this.store.pipe(selectFamilyHead()).subscribe(head => {
      this.familyHead = head;
    });
    this.store.pipe(select(getFamilyUuid)).subscribe(familyUuid => {
      this.familyUuid = familyUuid;
    });
  }

  cardAction(card: ModuleCard): boolean {
    if (card.icon === 'erecord') {
      this.openERecordModal();
      return true;
    }

    if (card.icon === 'plan-meeting') {
      const portalUrl = `${environment.poradceUrl}portal-poradce/kalendar?newEvent=true&newEventClientId=${this.familyHead.sugarUuid}`;
      window.open(portalUrl, '_blank');
      return true;
    }

    if (!card.disabled && isString(card.target)) {
      if (card.target.startsWith('http') || card.target.endsWith('.pdf')) {
        window.open(card.target, '_blank');
      } else {
        if (card.target.startsWith('/')) {
          this.router.navigateByUrl(card.target);
        } else {
          const baseUrl = `/secured/client/${this.familyUuid}`;
          this.router.navigateByUrl(`${baseUrl}/${card.target}`.replace(/\/$/, ''));
        }
      }
      return true;
    }

    return false;
  }

  private openERecordModal() {
    this.dialogService.load(
      ERecordModalComponent,
      {
        familyMember: this.familyHead,
      },
      {
        size: 'sm',
      },
    );
  }
}
