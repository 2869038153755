<div class="custom-control custom-radio">
  <input
    type="radio"
    [attr.disabled]="disabled ? '' : null"
    class="custom-control-input"
    [class.form-control--not-empty]="notEmpty"
    [class.is-invalid]="!!error"
    (change)="change.emit()"
    [checked]="controlForm.value === value"
    [formControl]="controlForm"
    [value]="value"
  />

  <label class="custom-control-label" (click)="controlForm.setValue(value); change.emit()">
    {{ label }}
    <ng-content></ng-content>
  </label>

  <div *ngIf="!!error" [innerHtml]="error" [class.d-block]="!!error" class="invalid-feedback"></div>
</div>
