<form #form="ngForm" [kptBackendValidation]="model?.assetUuid">
  <ng-container *ngIf="model" [ngSwitch]="model?.type">
    <ng-container *ngSwitchCase="AssetType.Vehicle">
      <kpt-vehicle-form [asset]="model"></kpt-vehicle-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.VehicleProvision">
      <kpt-vehicle-provision-form
        [asset]="model"
        [disabled]="disabled"
      ></kpt-vehicle-provision-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.VehicleRequirements">
      <kpt-vehicle-requirements-form
        [asset]="model"
        [showRelatedAssetHeader]="showRelatedAssetHeader"
        [disabled]="disabled"
      ></kpt-vehicle-requirements-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.PropertyProvision">
      <kpt-property-provision-form
        [asset]="model"
        [disabled]="disabled"
      ></kpt-property-provision-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.PropertyRequirements">
      <kpt-property-requirements-form
        [asset]="model"
        [showRelatedAssetHeader]="showRelatedAssetHeader"
        [disabled]="disabled"
      ></kpt-property-requirements-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.BadDebtsRepayment">
      <kpt-bad-debts-repayment-form [asset]="model"></kpt-bad-debts-repayment-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.OtherObjective">
      <kpt-other-objective-form [asset]="model"></kpt-other-objective-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.Car">
      <kpt-car-form [asset]="model"></kpt-car-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.ChildBirth">
      <kpt-child-birth-form [asset]="model"></kpt-child-birth-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.ChildrenFuture">
      <kpt-children-future-form [asset]="model"></kpt-children-future-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.FinancialIndependence">
      <kpt-financial-independence-form [asset]="model"></kpt-financial-independence-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.Furnishings">
      <kpt-furnishings-form [asset]="model"></kpt-furnishings-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.LongTermReserve">
      <kpt-long-term-reserve-form [asset]="model"></kpt-long-term-reserve-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.NewHousing">
      <kpt-new-housing-form [asset]="model"></kpt-new-housing-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.OwnHousing">
      <kpt-own-housing-form [asset]="model"></kpt-own-housing-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.Reconstruction">
      <kpt-reconstruction-form [asset]="model"></kpt-reconstruction-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.ShortTermReserve">
      <kpt-short-term-reserve-form [asset]="model"></kpt-short-term-reserve-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.Vacation">
      <kpt-vacation-form [asset]="model"></kpt-vacation-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.Retirement">
      <kpt-retirement-form
        [asset]="model"
        [showPersonSelector]="showPersonSelector"
      ></kpt-retirement-form>
    </ng-container>
    <ng-container *ngSwitchCase="AssetType.FamilyProvision">
      <kpt-family-provision-form
        [asset]="model"
        [disabled]="disabled"
        [mode]="mode"
        [selectedPersonId]="selectedPersonId"
      ></kpt-family-provision-form>
    </ng-container>
    <div *ngSwitchDefault>
      No form for asset type {{ model?.type }}, please add one to asset-form-ng-model component.
    </div>
  </ng-container>
</form>
