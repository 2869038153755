import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getFamilyMembersAdultsThenChildren} from '@shared/lib';
import {FamilyMemberState} from '../reducers/family-members.reducer';

export const getFamilyMembersState = createFeatureSelector<FamilyMemberState>('familyMembers');

export const getFamilyMembers = createSelector(getFamilyMembersState, state =>
  getFamilyMembersAdultsThenChildren(state.familyMembers),
);

export const getFamilyUuid = createSelector(getFamilyMembersState, state => state.familyUuid);

export const getFamilyHead = createSelector(getFamilyMembers, familyMembers =>
  familyMembers.find(member => member.familyHead),
);
