import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'kpt-repeatable-formly',
  templateUrl: './repeatable-formly.component.html',
  styleUrls: ['./repeatable-formly.component.scss'],
})
export class RepeatableFormlyComponent extends FieldArrayType {
  // TODO: add explicit constructor
  constructor() {
    super();
  }

  get removeButtonClass(): string {
    if (!this.to.deleteButtonPosition) return '';
    return `remove-button-${this.to.deleteButtonPosition}`;
  }

  get canAddRemove() {
    return this.to.canAddRemove ?? true;
  }
}
