<div class="graph-container" *ngIf="showGraph">
  <canvas
    baseChart
    [datasets]="datasets"
    [labels]="labels"
    [options]="options"
    [plugins]="plugins"
    [legend]="legend"
    [chartType]="chartType"
  >
  </canvas>
</div>
