import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

export enum FinancialAnalysisPage {
  Family = 'family',
  Objectives = 'objectives',
  Priorities = 'priorities',
  Finances = 'finances',
  Properties = 'properties',
  ClientPreferences = 'client-preferences',
  Summary = 'summary',
}

export enum FinancialAnalysisClientPreferencesTabs {
  Investments = 'investments',
  Priorities = 'priorities',
}

const financialAnalysisSteps: IStepperProgressItem[] = [
  {
    url: FinancialAnalysisPage.Family,
    done: false,
    label: 'Rodina',
  },
  {
    url: FinancialAnalysisPage.Objectives,
    done: false,
    label: 'Plány',
  },
  {
    url: FinancialAnalysisPage.Priorities,
    done: false,
    label: 'Priority',
  },
  {
    url: FinancialAnalysisPage.Finances,
    done: false,
    label: 'Hospodaření',
  },
  {
    url: FinancialAnalysisPage.Properties,
    done: false,
    label: 'Majetek',
  },
  {
    url: FinancialAnalysisPage.ClientPreferences,
    done: false,
    label: 'Preference klienta',
  },
  {
    url: FinancialAnalysisPage.Summary,
    done: false,
    label: 'Shrnutí',
  },
];

export const financialAnalysisStepperProgressConfig: IStepperProgressItem[] =
  createStepperProgressConfig(
    financialAnalysisSteps,
    SecuredPage.Client,
    ClientPage.FinancialAnalysis,
  );

const allowedSimpleFinancialAnalysisSteps: string[] = [
  FinancialAnalysisPage.Family,
  FinancialAnalysisPage.Finances,
  FinancialAnalysisPage.Properties,
];

export const simpleFinancialAnalysisStepperProgressConfig: IStepperProgressItem[] =
  createStepperProgressConfig(
    financialAnalysisSteps.filter(item => allowedSimpleFinancialAnalysisSteps.includes(item.url)),
    SecuredPage.Client,
    ClientPage.SimpleFinancialAnalysis,
  );
