<ng-container *ngIf="asset">
  <kpt-objective-description [assetType]="asset.type"></kpt-objective-description>

  <kpt-input label="Název" type="text" name="name" [(ngModel)]="asset.name"></kpt-input>

  <kpt-family-members
    *ngIf="showPersonSelector"
    type="radio"
    label="Do penze půjde"
    [onlyAdults]="false"
    [(value)]="asset.familyMemberUuid"
  ></kpt-family-members>

  <kpt-row>
    <kpt-objective-age-input
      grow
      label="Chci odejít do penze ve věku"
      name="start"
      [personId]="asset.familyMemberUuid"
      [(start)]="this.asset.start"
    ></kpt-objective-age-input>
    <kpt-input
      grow
      type="number"
      label="Chci si našetřit částku"
      appendText="Kč"
      name="value"
      [(ngModel)]="asset.value"
      (ngModelChange)="onValueChange()"
      required
    ></kpt-input>
  </kpt-row>

  <kpt-row>
    <kpt-input
      grow
      type="number"
      label="Počet let v penzi"
      name="pensionDuration"
      [(ngModel)]="asset.pensionDuration"
      (ngModelChange)="onPensionDurationChange()"
    ></kpt-input>
    <kpt-input
      grow
      type="number"
      label="Částka, kterou chci v penzi měsíčně"
      appendText="Kč"
      name="monthlyRent"
      [(ngModel)]="asset.monthlyRent"
      (ngModelChange)="onMonthlyRentChange()"
      required
    ></kpt-input>
  </kpt-row>

  <kpt-input
    label="Poznámka"
    type="textarea"
    rows="3"
    name="note"
    [(ngModel)]="asset.note"
  ></kpt-input>

  <kpt-input
    label="Stávající stav"
    type="textarea"
    rows="2"
    name="description"
    [(ngModel)]="asset.description"
  ></kpt-input>
</ng-container>
