import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ConfirmDialogService} from '@shared/building-blocks/confirm-dialog/confirm-dialog.service';
import {CommonBaseModule} from '@shared/lib';
import {ButtonModule, ModalModule} from '@shared/ui';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, ButtonModule, ModalModule, CommonBaseModule],
  providers: [ConfirmDialogService],
})
export class ConfirmDialogModule {}
