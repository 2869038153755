import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

export const goldConfig: IStepperProgressItem[] = createStepperProgressConfig(
  [
    {
      url: 'investment',
      done: false,
      label: 'Investice',
    },
    {
      url: 'summary',
      done: false,
      label: 'Shrnutí/průvodka',
    },
  ],
  SecuredPage.Client,
  ClientPage.Gold,
);
