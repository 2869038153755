import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AssetType} from '@shared/analysis/models/asset';
import {
  getRisksVM,
  PersonalInsuranceSelectionVM,
} from '@shared/analysis/models/life-insurance-risks-vm';
import {FamilyProvisionAsset} from '@shared/analysis/models/objectives';
import {selectCurrentAssets} from '@shared/analysis/store';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {selectFamilyObjectives} from 'src/app/modules/financial-plan/store';
import {selectLifeInsurancePersons} from 'src/app/modules/life-insurance/life-insurance.selectors';
import {State} from 'src/store';

@Injectable({providedIn: 'root'})
export class LifeInsuranceService {
  persons$ = this.store.pipe(select(selectLifeInsurancePersons));

  selections$: Observable<PersonalInsuranceSelectionVM[]> = combineLatest([
    this.persons$,
    this.store.pipe(select(selectCurrentAssets)),
  ]).pipe(
    map(([persons, assets]) => {
      const familyProvision = assets.find(
        a => a.type === AssetType.FamilyProvision,
      ) as FamilyProvisionAsset;

      return getRisksVM(familyProvision, persons, assets);
    }),
  );

  objectives$ = this.store.pipe(select(selectFamilyObjectives));

  /**
   * True if the user has selected any option that involves investing
   */
  wantsInvestment$ = this.persons$.pipe(map(persons => persons.some(p => p.data.createReserves)));

  constructor(private store: Store<State>) {}
}
