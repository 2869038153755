import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isNilOrEmpty} from '@shared/lib/utils/utils';
import {filter, isEqual} from 'lodash';
import * as moment from 'moment';
import {birthNumberRegExp, birthNumberValidation, getSexAndBirthDate} from './birth-number.fnc';

export class CustomValidators {
  static phoneNumber = (
    regExp = '^\\+42[0|1] ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$',
  ): ValidatorFn => {
    const expresion = new RegExp(regExp);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        phoneNumber: true,
      };
    };
  };

  static postalCode = (regExp: string): ValidatorFn => {
    const expresion = new RegExp(regExp);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        postalCode: true,
      };
    };
  };

  static customEmail = (regExp: string): ValidatorFn => {
    const expresion = new RegExp(regExp);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        customEmail: true,
      };
    };
  };

  static birthNumber = (regExp = birthNumberRegExp): ValidatorFn => {
    const expression = new RegExp(regExp);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || birthNumberValidation(control.value, expression)) {
        return null;
      }

      return {
        birthNumber: true,
      };
    };
  };

  static isDecimal = (): ValidatorFn => {
    const expresion = new RegExp(/^(\d*([.,]\d+)?)$/);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        decimal: true,
      };
    };
  };

  static isNumber = (): ValidatorFn => {
    const expresion = new RegExp(/^-?(0|[1-9]\d*)?$/);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        number: true,
      };
    };
  };

  static matchesBirthNumber = (
    field: 'sex' | 'birthDate',
    birthNumberName = 'birthNumber',
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (isNilOrEmpty(control.value)) return null;

      const birthNumberControl = control.parent && control.parent.get(birthNumberName);
      if (!birthNumberControl || isNilOrEmpty(birthNumberControl.value)) return null;
      if (birthNumberControl.pristine && control.pristine) return null;

      control.markAsTouched();

      const sexAndBirthDate = getSexAndBirthDate(birthNumberControl.value);
      const derived = sexAndBirthDate ? sexAndBirthDate[field] : null;

      const matches =
        field === 'birthDate' && typeof control.value === 'string'
          ? moment(control.value).isSame(derived)
          : isEqual(control.value, derived);
      if (matches) return null;

      return {[`${field}MatchesBirthNumber`]: true};
    };
  };

  static oneOf = (fields: string[]): ValidatorFn => {
    return (form: UntypedFormGroup): ValidationErrors => {
      const validFields = filter(fields, field => {
        const formField = form.get(field);
        if (!formField) {
          return false;
        }
        return !!formField.value && formField.valid;
      });

      if (validFields.length > 0) {
        return null;
      }

      return {
        oneOf: {...fields},
      };
    };
  };

  static documentNumber = (regExp = '^[a-zA-Z0-9]*$'): ValidatorFn => {
    const expresion = new RegExp(regExp);
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || expresion.test(control.value)) {
        return null;
      }

      return {
        documentNumber: true,
      };
    };
  };

  static birthDate = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
      if (control.pristine) {
        return null;
      }

      control.markAsTouched();
      if (isNilOrEmpty(control.value) || moment(control.value).year() >= 1900) {
        return null;
      }

      return {
        birthDate: true,
      };
    };
  };
}
