import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BusinessToolModalComponent} from '@shared/lib/components/modal/modals/business-tool-modal/business-tool-modal.component';
import {CanLoadInvestment} from 'src/app/modules/investment-old/services/investment.can-activate';
import {ClientContainerComponent} from './client-container.component';
import {ClientPage} from './client-container.config';
import {ClientPageComponent} from './client-page.component';
import {AssetDetailProxyComponent} from './pages/asset-detail/asset-detail-proxy.component';
import {ClientResolve} from './services/client.resolve';

const routes: Routes = [
  {
    path: ':familyId',
    component: ClientContainerComponent,
    data: {title: 'Klient'},
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: ClientPageComponent,
            data: {title: 'Klient \u2013 Analýza a plánování'},
          },
          {
            path: 'summary',
            component: ClientPageComponent,
            data: {title: 'Klient \u2013 Výsledky'},
          },
          {
            path: ClientPage.FinancialAnalysis,
            loadChildren: () =>
              import('../financial-analysis/financial-analysis.module').then(
                m => m.FinancialAnalysisModule,
              ),
          },
          {
            path: ClientPage.SimpleFinancialAnalysis,
            loadChildren: () =>
              import('../financial-analysis/financial-analysis.module').then(
                m => m.FinancialAnalysisModule,
              ),
          },
          {
            path: ClientPage.FinancialPlan,
            loadChildren: () =>
              import('../financial-plan/financial-plan.module').then(m => m.FinancialPlanModule),
          },
          {
            path: ClientPage.Gold,
            loadChildren: () => import('../gold/gold-page.module').then(m => m.GoldPageModule),
          },
          {
            path: ClientPage.Housing,
            loadChildren: () =>
              import('../housing/housing-page.module').then(m => m.HousingPageModule),
          },
          {
            path: ClientPage.LifeInsuranceOld,
            loadChildren: () =>
              import('src/app/modules/life-insurance-old/life-insurance-page.module').then(
                m => m.LifeInsurancePageModule,
              ),
          },
          {
            path: ClientPage.ConsumerLoan,
            loadChildren: () =>
              import('../consumer-loans/consumer-loans-page.module').then(
                m => m.ConsumerLoansPageModule,
              ),
          },
          {
            path: ClientPage.PensionOld,
            loadChildren: () =>
              import('src/app/modules/pension-old/pension-page.module').then(
                m => m.PensionPageModule,
              ),
          },
          {
            path: ClientPage.InvestmentOld,
            loadChildren: () =>
              import('src/app/modules/investment-old/investment.module').then(
                m => m.InvestmentModule,
              ),
            canActivate: [CanLoadInvestment],
          },
          {
            path: ClientPage.Housing,
            component: BusinessToolModalComponent,
            loadChildren: () =>
              import('../housing/housing-page.module').then(m => m.HousingPageModule),
            outlet: 'modal',
          },
          {
            path: ClientPage.LifeInsuranceOld,
            component: BusinessToolModalComponent,
            loadChildren: () =>
              import('src/app/modules/life-insurance-old/life-insurance-page.module').then(
                m => m.LifeInsurancePageModule,
              ),
            outlet: 'modal',
            data: {hideContinueButton: true},
          },
          {
            path: ClientPage.LifeInsurance,
            loadChildren: () =>
              import('../life-insurance/life-insurance.module').then(m => m.LifeInsuranceModule),
          },
          {
            path: ClientPage.ConsumerLoan,
            component: BusinessToolModalComponent,
            loadChildren: () =>
              import('../consumer-loans/consumer-loans-page.module').then(
                m => m.ConsumerLoansPageModule,
              ),
            outlet: 'modal',
          },
          {
            path: ClientPage.PensionOld,
            component: BusinessToolModalComponent,
            loadChildren: () =>
              import('src/app/modules/pension-old/pension-page.module').then(
                m => m.PensionPageModule,
              ),
            outlet: 'modal',
          },
          {
            path: ClientPage.Pension,
            loadChildren: () =>
              import('src/app/modules/pension/pension.module').then(m => m.PensionModule),
          },
          {
            path: ClientPage.InvestmentOld,
            component: BusinessToolModalComponent,
            loadChildren: () =>
              import('src/app/modules/investment-old/investment.module').then(
                m => m.InvestmentModule,
              ),
            canActivate: [CanLoadInvestment],
            outlet: 'modal',
          },
          {
            path: ClientPage.Investment,
            loadChildren: () =>
              import('../investment/investment.module').then(m => m.InvestmentModule),
          },
          {
            path: ClientPage.VehicleInsurance,
            loadChildren: () =>
              import('../vehicle-insurance/vehicle-insurance.module').then(
                m => m.VehicleInsuranceModule,
              ),
          },
          {
            path: ClientPage.PropertyInsurance,
            loadChildren: () =>
              import('../property-insurance/property-insurance.module').then(
                m => m.PropertyInsuranceModule,
              ),
          },
        ],
        canActivate: [ClientResolve],
      },
      {
        path: 'asset',
        outlet: 'assetDetail',
        component: AssetDetailProxyComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/asset-detail/asset-detail.module').then(m => m.AssetDetailModule),
          },
        ],
      },

      {
        path: '',
        redirectTo: '/secured/consultant',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientContainerRoutingModule {}
