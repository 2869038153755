import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TipComponent} from '@shared/ui/tip/tip.component';

@NgModule({
  declarations: [TipComponent],
  imports: [CommonModule],
  exports: [TipComponent],
})
export class TipModule {}
