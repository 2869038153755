<div class="container horizontal-scrollable">
  <div class="row">
    <ng-container *ngFor="let member of members$ | async">
      <div *ngIf="isChecked(member.sugarUuid)" class="col-5">
        <strong>{{ member.firstName }}</strong>
        <formly-field [field]="getDebtorFieldGroup(member.sugarUuid).fieldGroup[0]"></formly-field>
      </div>
    </ng-container>
  </div>
</div>
