<div *ngIf="show" class="stepper-container">
  <div class="progress-bar" #stepperContainer>
    <ng-container *ngIf="showSteps">
      <div
        class="progress-bar__item-wrapper"
        *ngFor="let step of stepTitles; let i = index; trackBy: trackByItem"
        [class.progress-bar__item-wrapper--active]="steps[activeStep]?.stepGroup === step"
        [class.progress-bar__item-wrapper--done]="
          stepGroupFinished(step) && steps[activeStep]?.stepGroup !== step
        "
        [class.progress-bar__item-wrapper--disabled]="
          disableSkippingSteps && !stepGroupFinished(step) && !stepGroupInProgress(step)
        "
        (click)="stepClick(step)"
      >
        <div class="progress-bar__item">
          <span class="progress-bar__bullet"></span>
          <label [class.wrap-label]="wrapLabels" class="progress-bar__label">{{ step }}</label>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="stepper-body">
    <div class="position-relative">
      <ng-container *ngFor="let step of steps; let i = index; trackBy: trackByProperty('stepName')">
        <div
          class="step"
          [class.active-step]="i === activeStep"
          [class.left-step]="i === activeStep - 1"
          [class.right-step]="i === activeStep + 1"
        >
          <ng-container *ngTemplateOutlet="getTemplate(i)"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showStepperFooter" class="stepper-footer">
    <div>
      <kpt-button
        *ngIf="activeStep !== 0 || showBackButtonOnFirstPage"
        type="primary"
        customClass="mr-3"
        [hasOutline]="true"
        (action)="proceed(false)"
      >
        {{ backButtonLabel }}
      </kpt-button>
    </div>
    <div class="step-counter">{{ stepLabel }} {{ activeStep + 1 }} z {{ steps.length }}</div>

    <div>
      <kpt-button
        *ngIf="activeStep !== steps.length - 1"
        [isDisabled]="disableAllSteps || (disableNextWhenInvalid && !canContinue())"
        type="primary"
        (action)="proceed(true)"
      >
        Další
      </kpt-button>

      <kpt-button
        *ngIf="showFinishButton && activeStep === steps.length - 1"
        type="primary"
        [isDisabled]="disableAllSteps || disableFinishingStep || !canFinish()"
        (action)="finishAction.emit()"
      >
        {{ finishButtonLabel }}
      </kpt-button>
    </div>
  </div>
</div>
