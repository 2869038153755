import {InterestRate} from '@shared/analysis/models/interest-rate';

export const interestRates: Record<string, InterestRate> = {
  currentAccount: {min: 0, max: 0.2},
  capitalLifeInsurance: {min: 0, max: 0.2},
  investmentLifeInsurance: {min: 0, max: 0.2},
  supplementaryPensionSavings: {min: 0, max: 0.2},
  pensionInsurance: {min: 0, max: 0.2},
  buildingSavings: {min: 0, max: 0.2},
  savingsAccount: {min: 0, max: 0.2},
  termDeposit: {min: 0, max: 0.2},
  unitTrustStrategyConservative: {min: 0, max: 0.2},
  unitTrustStrategyBalanced: {min: 0, max: 0.2},
  unitTrustStrategyDynamic: {min: 0, max: 0.2},
};
