import {regularPaymentTypeToFrequency} from '@shared/analysis/helpers';
import {Asset, AssetType, DescriptionAsset} from '@shared/analysis/models/asset';
import {CreditAsset, PropertyLoanAsset} from '@shared/analysis/models/credit-products';
import {
  BondsAsset,
  FinancialPropertyAsset,
  QualifiedInvestorFundsAsset,
  TermDepositAsset,
} from '@shared/analysis/models/financial-properties';
import {InvestmentLifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {
  BuildingSavingsAsset,
  CommonInvestmentAsset,
  InvestmentAsset,
  PensionInsuranceAsset,
  SupplementaryPensionSavingsAsset,
} from '@shared/analysis/models/investment-products';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {
  Frequency,
  FunctionType,
  Liquidity,
  Unit,
  UnitFunction,
  UnitType,
} from '@shared/analysis/models/unit';

function toAsset(asset: Asset, functions: UnitFunction[]): Unit {
  return {
    id: asset.assetUuid,
    name: (asset as DescriptionAsset).description,
    functions,
  };
}

const lifeInsuranceExpense = (asset: InvestmentLifeInsuranceAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.presentValueDate,
      params: {value: (!asset.futureValue || !asset.futureValueDate) && asset.presentValue},
    },
    {
      type: FunctionType.LifeInsurance,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.Low,
      start: asset.presentValueDate,
      params: {
        futureValue: asset.futureValue,
        yearRate: asset.yearlyRate,
        presentValue: asset.presentValue,
        futureValueDate: asset.futureValueDate,
      },
    },
  ]);
};

const investment = (asset: InvestmentAsset): Unit => {
  const investmentAsset = asset as CommonInvestmentAsset;

  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: investmentAsset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: investmentAsset.yearlyRate,
        presentValue: investmentAsset.presentValue,
        periodicPayment: asset.value,
      },
    },
  ]);
};

const currentBalance = (asset: FinancialPropertyAsset): Unit => {
  return {
    ...toAsset(asset, [
      {
        type: FunctionType.Constant,
        liquidity: Liquidity.High,
        start: asset.start,
        params: {value: asset.value},
      },
    ]),
    type: UnitType.CurrentBalance,
  };
};

const propertyLoan = (asset: CreditAsset): Unit => {
  return toAsset(asset, [
    {
      // Original loan value
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      params: {value: (asset as PropertyLoanAsset).originalValue},
    },
    {
      // Subtract outstanding value from the original loan value (only when the original value is defined)
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      params: {value: (asset as PropertyLoanAsset).originalValue ? -asset.outstandingValue : 0},
    },
    {
      // The loan is amortized
      type: FunctionType.Amortization,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      end: asset.end,
      params: {loan: asset.outstandingValue, yearRate: asset.yearlyRate},
    },
  ]);
};

const credit = (asset: CreditAsset): Unit => {
  return toAsset(asset, [
    {
      // The loan is amortized
      type: FunctionType.Amortization,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      liquidity: Liquidity.Low,
      start: asset.outstandingValueDate,
      end: asset.end,
      params: {loan: asset.outstandingValue, yearRate: asset.yearlyRate},
    },
  ]);
};

const creditPropertiesSum = (asset: CreditAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: -asset.value},
    },
  ]);
};

const property = (asset: PropertyAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.Constant,
      liquidity: Liquidity.Low,
      start: asset.start,
      params: {value: asset.value},
    },
  ]);
};

const termDeposit = (asset: TermDepositAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const bonds = (asset: BondsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const qualifiedInvestorFunds = (asset: QualifiedInvestorFundsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.FutureValue,
      liquidity: Liquidity.Low,
      frequency: Frequency.Month,
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        presentValue: asset.presentValue,
        periodicPayment: 0,
        yearRate: asset.yearlyRate,
      },
    },
  ]);
};

const buildingSavings = (asset: BuildingSavingsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.BuildingSavings,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        periodicPayment: asset.value,
      },
    },
  ]);
};

const supplementaryPensionSavings = (asset: SupplementaryPensionSavingsAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.SupplementaryPensionSavings,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        ownContribution: asset.value,
        employerContribution: asset.employerContribution,
      },
    },
  ]);
};

const pensionInsurance = (asset: PensionInsuranceAsset): Unit => {
  return toAsset(asset, [
    {
      type: FunctionType.PensionInsurance,
      liquidity: Liquidity.Low,
      frequency: regularPaymentTypeToFrequency(asset.frequency),
      start: asset.presentValueDate,
      end: asset.end,
      params: {
        yearRate: asset.yearlyRate,
        oneTimePayment: asset.presentValue,
        ownContribution: asset.value,
        employerContribution: asset.employerContribution,
      },
    },
  ]);
};

const noop = (asset: Asset): Unit => {
  return toAsset(asset, []);
};

const assetsToUnits: Record<AssetType, (asset: Asset) => Unit> = {
  // Sandbox Assets
  [AssetType.SandboxCurrentBalance]: noop,
  [AssetType.SandboxIncome]: noop,
  [AssetType.SandboxExpenses]: noop,
  [AssetType.SandboxUnitTrust]: noop,
  [AssetType.SandboxHousePurchase]: noop,
  [AssetType.SandboxMortgage]: noop,
  [AssetType.SandboxCarPurchase]: noop,
  [AssetType.SandboxConsumerLoan]: noop,
  [AssetType.SandboxLossOfWork]: noop,
  [AssetType.SandboxInheritance]: noop,

  // Objectives
  [AssetType.OwnHousing]: noop,
  [AssetType.ChildrenFuture]: noop,
  [AssetType.Retirement]: noop,
  [AssetType.FinancialIndependence]: noop,
  [AssetType.ShortTermReserve]: noop,
  [AssetType.LongTermReserve]: noop,
  [AssetType.BadDebtsRepayment]: noop,
  [AssetType.FamilyProvision]: noop,
  [AssetType.PropertyProvision]: noop,
  [AssetType.PropertyRequirements]: noop,
  [AssetType.VehicleProvision]: noop,
  [AssetType.VehicleRequirements]: noop,
  [AssetType.ChildBirth]: noop,
  [AssetType.NewHousing]: noop,
  [AssetType.Reconstruction]: noop,
  [AssetType.Furnishings]: noop,
  [AssetType.Car]: noop,
  [AssetType.Vacation]: noop,
  [AssetType.OtherObjective]: noop,

  // Common Expenses
  [AssetType.CommonExpensesSum]: noop,
  [AssetType.Food]: noop,
  [AssetType.HousingExpenses]: noop,
  [AssetType.Drugstore]: noop,
  [AssetType.Transport]: noop,
  [AssetType.Communication]: noop,
  [AssetType.Clothes]: noop,
  [AssetType.Hobbies]: noop,
  [AssetType.Vacations]: noop,
  [AssetType.ChildrenExpenses]: noop,
  [AssetType.OtherCommonExpenses]: noop,
  [AssetType.ContractorInsurance]: noop,

  // Main Incomes
  [AssetType.Employment]: noop,
  [AssetType.Contractor]: noop,
  [AssetType.FinancialOutlook]: noop,
  [AssetType.ChildrenTaxAdvantage]: noop,

  // Other Incomes
  [AssetType.Maternity]: noop,
  [AssetType.Parental]: noop,
  [AssetType.UnemploymentBenefits]: noop,
  [AssetType.PensionIncome]: noop,
  [AssetType.OtherPensionIncome]: noop,
  [AssetType.RentalIncome]: noop,
  [AssetType.Dividends]: noop,
  [AssetType.OtherPassiveIncome]: noop,

  // Financial Products - Insurance
  [AssetType.InsuranceProductsSum]: noop,
  [AssetType.InvestmentLifeInsurance]: lifeInsuranceExpense,
  [AssetType.RiskLifeInsurance]: noop,
  [AssetType.CapitalLifeInsurance]: lifeInsuranceExpense,
  [AssetType.PropertyInsurance]: noop,
  [AssetType.LiabilityInsurance]: noop,
  [AssetType.VehicleInsurance]: noop,
  [AssetType.TravelInsurance]: noop,
  [AssetType.BusinessInsurance]: noop,

  // Financial Products - Investment
  [AssetType.InvestmentProductsSum]: noop,
  [AssetType.UnitTrust]: investment,
  [AssetType.RealEstateFund]: investment,
  [AssetType.Commodity]: investment,
  [AssetType.BuildingSavings]: buildingSavings,
  [AssetType.SupplementaryPensionSavings]: supplementaryPensionSavings,
  [AssetType.PensionInsurance]: pensionInsurance,
  [AssetType.SavingsAccount]: investment,
  [AssetType.ExchangeTradedFunds]: investment,
  [AssetType.Certificates]: investment,
  [AssetType.CombinedDeposits]: investment,

  // Financial Products - Credit
  [AssetType.CreditProductsSum]: noop,
  [AssetType.Mortgage]: propertyLoan,
  [AssetType.BuildingSavingsLoan]: propertyLoan,
  [AssetType.Leasing]: credit,
  [AssetType.CreditCard]: credit,
  [AssetType.Overdraft]: credit,
  [AssetType.ConsumerLoan]: credit,
  [AssetType.OtherIndividualLoan]: credit,

  // Immovable Properties
  [AssetType.ImmovablePropertiesSum]: property,
  [AssetType.Flat]: property,
  [AssetType.House]: property,
  [AssetType.Household]: property,
  [AssetType.HolidayProperty]: property,
  [AssetType.ApartmentBuilding]: property,
  [AssetType.Garage]: property,
  [AssetType.Land]: property,
  [AssetType.OtherImmovableProperty]: property,

  // Movable Properties
  [AssetType.MovablePropertiesSum]: property,
  [AssetType.Vehicle]: property,
  [AssetType.Valuables]: property,
  [AssetType.SpecialValuables]: property,

  // Financial Properties
  [AssetType.FinancialPropertiesSum]: currentBalance,
  [AssetType.CurrentAccount]: currentBalance,
  [AssetType.TermDeposit]: termDeposit,
  [AssetType.Bonds]: bonds,
  [AssetType.QualifiedInvestorFunds]: qualifiedInvestorFunds,
  [AssetType.OtherFinancialProperty]: property,

  // Other Assets
  [AssetType.CurrentAccountUsage]: noop, // no calculations are performed

  // Properties Sums
  [AssetType.InvestmentPropertiesSum]: noop, // asset is not used
  [AssetType.CreditPropertiesSum]: creditPropertiesSum,
  [AssetType.InsurancePropertiesSum]: lifeInsuranceExpense,

  // Virtual Assets
  [AssetType.VirtualMonthlyBalance]: noop,
};

export function transformAssetsToUnitsForFP(assets: Asset[]): Unit[] {
  return assets.map(asset => assetsToUnits[asset.type](asset));
}
