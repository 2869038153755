import {createSelector} from '@ngrx/store';
import {AssetPageStateValue} from '@shared/analysis/assets-page.models';
import {FieldLabel} from '@shared/analysis/field-label';
import {Asset, AssetType, ValueAsset} from '@shared/analysis/models/asset';
import {
  ChildrenExpensesAsset,
  ClothesAsset,
  CommunicationAsset,
  DrugstoreAsset,
  FoodAsset,
  HobbiesAsset,
  HousingExpensesAsset,
  InputMode,
  OtherCommonExpensesAsset,
  TransportAsset,
  VacationsAsset,
} from '@shared/analysis/models/common-expenses';
import {ContractorAsset} from '@shared/analysis/models/main-incomes';
import {selectFinances as selectFinancesAnalysis} from '@shared/analysis/store';
import {
  createItem,
  formatCZKNotNull,
  formatDate,
} from 'src/app/modules/client/summary/store/summary.helpers';
import {Category, CategoryType, Item, ItemsGroup} from './../summary.models';

export const selectExpensesCategory = createSelector(selectFinancesAnalysis, finances =>
  createCategory(finances.expenses),
);

function createCategory(expenses: AssetPageStateValue): Category {
  return {
    label: expenses.title,
    value: formatCZKNotNull(expenses.sum),
    categoryTypes: [createCategoryType(expenses.assets)],
  };
}

function createCategoryType(assets: Asset[]): CategoryType {
  return {
    itemsGroups: assets.map(asset => createItemsGroup(asset)),
  };
}

function createItemsGroup(asset: Asset): ItemsGroup {
  return {
    assetUuid: asset.assetUuid,
    value: formatCZKNotNull(
      asset.type === AssetType.Contractor
        ? getContractorInsuranceSum(asset as ContractorAsset)
        : (asset as ValueAsset).value,
    ),
    items: [...createItemFunctions[asset.type](asset)].filter(Boolean),
  };
}

function getContractorInsuranceSum(asset: ContractorAsset): number {
  return (
    asset.healthInsuranceValue +
    asset.socialInsuranceValue +
    (asset.sicknessInsurancePayer ? asset.sicknessInsuranceValue : 0)
  );
}

const createItemFunctions: Partial<Record<AssetType, (asset: Asset) => Item[]>> = {
  [AssetType.Food]: createFoodItems,
  [AssetType.HousingExpenses]: createHousingExpenseItems,
  [AssetType.Drugstore]: createDrugstoreItems,
  [AssetType.Transport]: createTransportItems,
  [AssetType.Communication]: createCommunicationItems,
  [AssetType.Clothes]: createClothesItems,
  [AssetType.Hobbies]: createHobbiesItems,
  [AssetType.Vacations]: createVacationsItems,
  [AssetType.ChildrenExpenses]: createChildrenExpensesItems,
  [AssetType.OtherCommonExpenses]: createOtherCommonExpensesItems,
  [AssetType.Contractor]: createContractorInsuranceItems,
};

function createHousingExpenseItems(asset: HousingExpensesAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.RENT, formatCZKNotNull(asset.rent)));
    detail.push(createItem(FieldLabel.WATER, formatCZKNotNull(asset.water)));
    detail.push(createItem(FieldLabel.GAS, formatCZKNotNull(asset.gas)));
    detail.push(createItem(FieldLabel.ELECTRICITY, formatCZKNotNull(asset.electricity)));
    detail.push(createItem(FieldLabel.HEATING, formatCZKNotNull(asset.heating)));
    detail.push(createItem(FieldLabel.REPAIRS_FUND, formatCZKNotNull(asset.repairsFund)));
    detail.push(createItem(FieldLabel.OTHER, formatCZKNotNull(asset.other)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createFoodItems(asset: FoodAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.FOOD, formatCZKNotNull(asset.food)));
    detail.push(createItem(FieldLabel.LUNCH, formatCZKNotNull(asset.lunch)));
    detail.push(createItem(FieldLabel.DINNER, formatCZKNotNull(asset.dinner)));
    detail.push(createItem(FieldLabel.OTHER, formatCZKNotNull(asset.other)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createDrugstoreItems(asset: DrugstoreAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.BASIC_DRUGSTORE, formatCZKNotNull(asset.basicDrugstore)));
    detail.push(createItem(FieldLabel.CHILDREN_HYGIENE, formatCZKNotNull(asset.childrenHygiene)));
    detail.push(createItem(FieldLabel.DRUGS, formatCZKNotNull(asset.drugs)));
    detail.push(createItem(FieldLabel.OTHER, formatCZKNotNull(asset.other)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createTransportItems(asset: TransportAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.FUEL, formatCZKNotNull(asset.fuel)));
    detail.push(createItem(FieldLabel.CAR_SERVICE, formatCZKNotNull(asset.carService)));
    detail.push(createItem(FieldLabel.PUBLIC_TRANSPORT, formatCZKNotNull(asset.publicTransport)));
    detail.push(createItem(FieldLabel.TAXI, formatCZKNotNull(asset.taxi)));
    detail.push(createItem(FieldLabel.OTHER, formatCZKNotNull(asset.other)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createCommunicationItems(asset: CommunicationAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.INTERNET, formatCZKNotNull(asset.internet)));
    detail.push(createItem(FieldLabel.TELEVISION, formatCZKNotNull(asset.television)));
    detail.push(createItem(FieldLabel.PHONE, formatCZKNotNull(asset.phone)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createClothesItems(asset: ClothesAsset): Item[] {
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createHobbiesItems(asset: HobbiesAsset): Item[] {
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createVacationsItems(asset: VacationsAsset): Item[] {
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    asset.yearValue && createItem(FieldLabel.YEARLY_VALUE, formatCZKNotNull(asset.yearValue)),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createChildrenExpensesItems(asset: ChildrenExpensesAsset): Item[] {
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createOtherCommonExpensesItems(asset: OtherCommonExpensesAsset): Item[] {
  const detail: Item[] = [];
  if (asset.inputMode === InputMode.Detail) {
    detail.push(createItem(FieldLabel.ENTERTAINMENT, formatCZKNotNull(asset.entertainment)));
    detail.push(createItem(FieldLabel.ALIMONY, formatCZKNotNull(asset.alimony)));
    detail.push(createItem(FieldLabel.CIGARETTES, formatCZKNotNull(asset.cigarettes)));
    detail.push(createItem(FieldLabel.ALCOHOL, formatCZKNotNull(asset.alcohol)));
    detail.push(createItem(FieldLabel.PRESENTS, formatCZKNotNull(asset.presents)));
    detail.push(createItem(FieldLabel.OTHER, formatCZKNotNull(asset.other)));
  }
  return [
    createItem(FieldLabel.EXPENSE_START, formatDate(asset.start)),
    createItem(
      FieldLabel.INDISPENSABLE_PART,
      formatCZKNotNull(asset.value * asset.indispensablePart),
    ),
    ...detail,
    createItem(FieldLabel.NOTE, asset.note),
  ];
}

function createContractorInsuranceItems(asset: ContractorAsset): Item[] {
  return [
    createItem(FieldLabel.HEALTH_INSURANCE, formatCZKNotNull(asset.healthInsuranceValue)),
    createItem(FieldLabel.SOCIAL_INSURANCE, formatCZKNotNull(asset.socialInsuranceValue)),
    asset.sicknessInsurancePayer &&
      createItem(FieldLabel.SICKNESS_INSURANCE, formatCZKNotNull(asset.sicknessInsuranceValue)),
  ];
}
