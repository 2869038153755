import {Injectable} from '@angular/core';
import {AnalysisService} from '@generated/controllers/Analysis';
import {Asset as GeneratedAsset} from '@generated/model';
import {select, Store} from '@ngrx/store';
import {Asset} from '@shared/analysis/models/asset';
import {FinancialAnalysisActions, selectAssetsForBE} from '@shared/analysis/store';
import {BackendValidationService} from '@shared/backend-validation/backend-validation.service';
import {isEqual} from 'lodash';
import {Observable, of, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {State} from 'src/store';

const DEBOUNCE_TIME = 500;

@Injectable()
export class FinancialAnalysisDataService {
  private cancel$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private analysisService: AnalysisService,
    private backendValidationService: BackendValidationService,
  ) {}

  async loadDataAndSaveChanges(familyId: string): Promise<Asset[]> {
    this.stop();
    const assets = await this.loadData(familyId);
    this.listenToChanges(familyId);
    return assets;
  }

  stop() {
    this.cancel$.next();
  }

  private async loadData(familyId: string): Promise<Asset[]> {
    const assets = await (
      this.analysisService.analysisAssetsRead({family_uuid: familyId}) as unknown as Observable<
        Asset[]
      >
    ).toPromise();
    this.store.dispatch(FinancialAnalysisActions.FA_SetData({assets}));
    return assets;
  }

  private listenToChanges(familyId: string) {
    this.store
      .pipe(
        select(selectAssetsForBE),
        skip(1), // skip once subscribed to store
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged<Asset[]>(isEqual),
        takeUntil(this.cancel$),
        switchMap(assets => {
          return this.analysisService
            .analysisAssetsCreate({
              family_uuid: familyId,
              data: assets as unknown as GeneratedAsset[],
            })
            .pipe(
              this.backendValidationService.catchValidations(),
              catchError(error => {
                console.error('Failed to save financial analysis assets:', error);
                return of();
              }),
            );
        }),
      )
      .subscribe();
  }
}
