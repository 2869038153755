import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, TemplateRef, Output} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ModuleCard} from 'src/app/modules/client/models/client-tabs.model';

@Component({
  standalone: true,
  selector: 'kpt-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  imports: [CommonModule, RouterModule, AngularSvgIconModule],
})
export class CardComponent {
  @Input() config: ModuleCard;
  @Input() imageTemplate?: TemplateRef<any>;
  @Input() label?: string;
  @Input() selected = false;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
}
