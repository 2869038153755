import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {FAMILY_ID} from '@shared/constants';
import {InvestmentSurveyDataService} from '@shared/investment-survey-old/investment-survey-data.service';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {InvestmentSurveyActions, selectAllSurveyAnswers} from '@shared/investment-survey-old/store';
import {DialogService} from '@shared/services/modal.service';
import {cloneDeep, isEqual} from 'lodash';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {
  categoriesOptions,
  knowledgeExperienceI18n,
} from 'src/app/modules/investment-old/investment-survey/investment-survey.models';
import {State} from 'src/store';
import {pointsI18n} from '../summary.utils';
import {ClientCategorySelectionComponent} from './client-category-selection/client-category-selection.component';

const investorLevelTooltips = [
  {
    label: 'méně než 0 bodů – klient neakceptující ztrátu',
    range: (points: number) => points < 0,
  },
  {
    label:
      '0 až 3 body – základní investor – klient vzhledem ke svým znalostem a zkušenostem by měl do svého' +
      ' portfolia zařadit produkty odpovídající pouze konzervativnímu profilu,' +
      ' tj. spojené s nízkým rizikem ztráty (SRI max. 2)',
    range: (points: number) => points >= 0 && points <= 3,
  },
  {
    label:
      '4 až 19 bodů – informovaný investor – klient má znalosti a zkušenosti umožňující zařadit mu do svého' +
      ' portfolia zařadit produkty odpovídající konzervativnímu až vyváženému  profilu, tj. spojené s vyšším' +
      ' rizikem ztráty investice (SRI max. 5)',
    range: (points: number) => points >= 4 && points <= 19,
  },
  {
    label:
      '20 až 41 bodů – pokročilý investor – klient s touto úrovní může do svého portfolia zařadit produkty' +
      ' odpovídající všem investičním profilům (i s vysokým rizikem ztráty – SRI max. 7)',
    range: (points: number) => points >= 20,
  },
];

@UntilDestroy()
@Component({
  selector: 'kpt-investment-survey-investment-summary',
  templateUrl: './investment-summary.component.html',
  styleUrls: ['./investment-summary.component.scss'],
})
export class InvestmentSummaryComponent implements OnInit, OnDestroy {
  @Input() familyMemberUuid: string;

  @Input() resultForm: UntypedFormGroup;
  resultPoints = 0;

  @Input()
  loadInvestmentSurveyData = true;

  @Input()
  readonly = false;

  get clientCategory(): string {
    return this.categoriesOptions.find(category => category.key === this.clientCategoryKey)
      ?.label as string;
  }

  private categoriesOptions = categoriesOptions;
  private clientCategoryKey: string;

  private surveyAnswers: SurveyAnswers[];

  constructor(
    @Inject(FAMILY_ID) private familyId: string,
    private investmentDataService: InvestmentSurveyDataService,
    private store: Store<State>,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    if (this.loadInvestmentSurveyData) {
      this.investmentDataService.loadDataAndSaveChanges(this.familyId).finally(() => {
        this.init();
      });
    } else {
      this.init();
    }
  }

  ngOnDestroy() {
    this.investmentDataService.stop();
  }

  openClientCategoryModal() {
    this.dialogService
      .load(
        ClientCategorySelectionComponent,
        {
          clientCategoryKey: this.clientCategoryKey,
        },
        {
          size: 'sm',
        },
      )
      .then((category: string) => {
        if (category) {
          this.updateSurveyAnswers(category);
        }
      })
      .catch(() => {});
  }

  get resultPointsLabel(): string {
    let investorLevel = '';

    if (this.resultPoints >= 0 && this.resultPoints <= 3) {
      investorLevel = ' - Základní investor';
    } else if (this.resultPoints >= 4 && this.resultPoints <= 19) {
      investorLevel = ' - Informovaný investor';
    } else if (this.resultPoints >= 20) {
      investorLevel = ' - Pokročilý investor';
    }

    return `${this.resultPoints} ${pointsI18n(this.resultPoints)}${investorLevel}`;
  }

  get investorLevelTooltip(): string {
    return investorLevelTooltips.map(tooltip => tooltip.label).join('<br><br>');
  }

  getKnowledgeAndExperienceI18n(): string {
    return knowledgeExperienceI18n.find(a => a.range(this.resultPoints)).label;
  }

  private init() {
    this.loadData();
    this.resultPoints = this.resultForm.get('knowledgeExperiencePoints').value;
  }

  private loadData() {
    this.store
      .pipe(
        select(selectAllSurveyAnswers),
        distinctUntilChanged<SurveyAnswers[]>(isEqual),
        tap(surveyAnswers => (this.surveyAnswers = surveyAnswers)),
        map(surveyAnswers =>
          surveyAnswers.find(survey => survey.sugarUuid === this.familyMemberUuid),
        ),
        untilDestroyed(this),
      )
      .subscribe(survey => {
        const category = survey.answers.investmentClientEligibility.answer as string;
        if (category) {
          this.clientCategoryKey = category;
        } else this.updateSurveyAnswers('non-professional');
      });
  }

  private updateSurveyAnswers(category: string) {
    const newSurvey = cloneDeep(
      this.surveyAnswers.find(s => s.sugarUuid === this.familyMemberUuid),
    );
    newSurvey.answers.investmentClientEligibility.answer = category;
    const surveyAnswers: SurveyAnswers[] = [
      ...this.surveyAnswers.filter(s => s.sugarUuid !== this.familyMemberUuid),
      newSurvey,
    ];
    this.store.dispatch(InvestmentSurveyActions.IS_UpdateData({surveyAnswers}));
  }
}
