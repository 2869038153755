import {Injectable, NgModuleRef} from '@angular/core';
import {AnalysisService} from '@generated/controllers/Analysis';
import {Asset as ModelAsset} from '@generated/defs/Asset';
import {select, Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {isCommonFinancialProductAsset, isForeignContract} from '@shared/analysis/asset.utils';
import {CoreSyncCommonService} from '@shared/analysis/core-sync-common.service';
import {terminatedCoreSecondaryStatus} from '@shared/analysis/core-sync.data';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {findAssetById, selectAssets} from '@shared/analysis/store';
import {FinancialAnalysisActions} from '@shared/analysis/store/financial-analysis.actions';
import {SynchronizationService} from '@shared/analysis/synchronization.service';
import {BackendValidationService} from '@shared/backend-validation/backend-validation.service';
import {cloneDeep} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AssetDetailCreator} from 'src/app/modules/client/pages/asset-detail/asset-detail-creator.service';
import {AssetDetailModule} from 'src/app/modules/client/pages/asset-detail/asset-detail.module';
import {AssetModalSize} from 'src/app/modules/client/pages/asset-detail/modal-wrapper/asset-detail-modal-wrapper.component';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {Asset, AssetType} from 'src/shared/analysis/models/asset';
import {State} from 'src/store';
import {getFamilyUuid} from 'src/store/selectors/family-member.selectors';
import {v4 as uuid} from 'uuid';

@Injectable()
export class AssetsHandlerService {
  persistingAsset$ = new BehaviorSubject<AssetType>(null);
  openAssetSituation: Situation;

  constructor(
    private store: Store<State>,
    private assetDetailCreator: AssetDetailCreator,
    private assetTypeService: AssetTypeService,
    private syncService: SynchronizationService,
    private assetHelperService: AssetHelperService,
    private analysisService: AnalysisService,
    private coreSyncCommonService: CoreSyncCommonService,
    private backendValidationService: BackendValidationService,
  ) {}

  openEditModal(
    assetUuid: string,
    ngModuleRef: NgModuleRef<AssetDetailModule> = null,
    modalSize = AssetModalSize.Large,
    showDeleteButton = false,
    defaults: any,
  ) {
    const assetInfo = {
      assetUuid,
    };

    this.assetDetailCreator
      .insert(ngModuleRef, assetInfo, modalSize, showDeleteButton, defaults)
      .subscribe();
  }

  async updateAsset(asset: Asset) {
    if (isCommonFinancialProductAsset(asset)) {
      this.coreSyncCommonService.enqueueUpdateCoreContractForAsset(asset);
    }

    this.store.dispatch(
      FinancialAnalysisActions.FA_UpdateAsset(this.assetHelperService.prepareAsset(asset)),
    );
    this.syncService.syncPersonsFromFinancialAnalysisToLifeInsurance();
  }

  async createAsset(type: AssetType, value = 0, start: string = null, defaults: any = {}) {
    const defaultValue = await this.assetTypeService.create(type);
    defaults = {...defaultValue, ...defaults};

    await this.addAsset(
      this.assetHelperService.prepareAsset(
        {
          ...defaults,
          type,
          ...(start ? {start} : {}),
        } as Asset,
        value,
      ),
    );
  }

  async addAsset(asset: Asset) {
    const defaultValue = await this.assetTypeService.create(asset.type);
    const newAsset: Asset = {...defaultValue, ...asset, assetUuid: uuid()};

    this.store.dispatch(
      FinancialAnalysisActions.FA_AddAsset(this.assetHelperService.prepareAsset(newAsset)),
    );

    this.saveAssetToCore(newAsset);

    this.syncService.syncPersonsFromFinancialAnalysisToLifeInsurance();
  }

  async saveAsset(asset: Asset, saveToCore = false): Promise<Asset> {
    asset = this.assetHelperService.prepareAsset({...asset});

    const familyUuid = await this.store.pipe(select(getFamilyUuid), take(1)).toPromise();
    const saveAsset$ = this.analysisService
      .asset({
        family_uuid: familyUuid,
        data: asset as ModelAsset,
      })
      .pipe(this.backendValidationService.catchValidations());

    const savedAsset = (await saveAsset$.toPromise()) as Asset;

    this.store.dispatch(FinancialAnalysisActions.FA_SaveAsset(savedAsset));

    if (saveToCore) {
      this.saveAssetToCore(asset);
    }

    this.syncService.syncPersonsFromFinancialAnalysisToLifeInsurance();

    return savedAsset;
  }

  async saveAssetToCore(asset: Asset): Promise<void> {
    if (isCommonFinancialProductAsset(asset)) {
      if (!isForeignContract(asset)) {
        await this.coreSyncCommonService.updateCoreContractForAsset(asset);
      } else {
        await this.coreSyncCommonService.saveForeignAssetToCore(asset);
      }
    }
  }

  addObjectiveRelation(assetUuid: string, relatedObjectiveUuid: string) {
    this.store.dispatch(
      FinancialAnalysisActions.FA_AddObjectiveRelation({assetUuid, relatedObjectiveUuid}),
    );
  }

  addPropertyRelation(assetUuid: string, relatedPropertyUuid: string) {
    this.store.dispatch(
      FinancialAnalysisActions.FA_AddPropertyRelation({assetUuid, relatedPropertyUuid}),
    );
  }

  addStakeholderRelation(assetUuid: string, stakeholderUuid: string) {
    this.store.dispatch(
      FinancialAnalysisActions.FA_AddStakeholderRelation({assetUuid, stakeholderUuid}),
    );
  }

  removeObjectiveRelation(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_RemoveObjectiveRelation({assetUuid}));
  }

  removePropertyRelation(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_RemovePropertyRelation({assetUuid}));
  }

  async openAddModal(
    type: AssetType,
    ngModuleRef: NgModuleRef<AssetDetailModule> = null,
    modalSize = AssetModalSize.Large,
    showDeleteButton = false,
    clone: boolean,
    defaults: any = {},
  ): Promise<Asset> {
    const assetInfo = {
      assetDefinition: this.assetTypeService.getAssetDefinition(type),
    };

    const defaultValue = await this.assetTypeService.create(type, defaults);
    defaults = {...defaultValue, ...defaults, type};

    return this.assetDetailCreator
      .insert(ngModuleRef, assetInfo, modalSize, showDeleteButton, defaults, clone)
      .toPromise();
  }

  async removeAsset(assetUuid: string) {
    const asset = await this.getAssetById(assetUuid);
    if (isCommonFinancialProductAsset(asset) && isForeignContract(asset) && asset.sugarUuid) {
      // no need to wait (await ommited)
      this.coreSyncCommonService.deleteForeignCoreContractForAsset(asset);
    }
    this.store.dispatch(FinancialAnalysisActions.FA_RemoveAsset({assetUuid}));
    this.syncService.syncPersonsFromFinancialAnalysisToLifeInsurance();
  }

  excludeAssetFromAnalysis(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_ExcludeAssetFromAnalysis({assetUuid}));
  }

  includeAssetInAnalysis(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_IncludeAssetInAnalysis({assetUuid}));
  }

  proposeCurrentAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_ProposeCurrentAsset({assetUuid}));
  }

  terminateAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_TerminateAsset({assetUuid}));
  }

  acceptNewAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_AcceptNewAsset({assetUuid}));
  }

  rejectNewAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_RejectNewAsset({assetUuid}));
  }

  acceptUnchangedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_AcceptUnchangedAsset({assetUuid}));
  }

  terminateUnchangedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_TerminateUnchangedAsset({assetUuid}));
  }

  acceptTerminatedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_AcceptTerminatedAsset({assetUuid}));
  }

  rejectTerminatedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_RejectTerminatedAsset({assetUuid}));
  }

  acceptUpdatedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_AcceptUpdatedAsset({assetUuid}));
  }

  rejectUpdatedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_RejectUpdatedAsset({assetUuid}));
  }

  terminateUpdatedAsset(assetUuid: string) {
    this.store.dispatch(FinancialAnalysisActions.FA_TerminateUpdatedAsset({assetUuid}));
  }

  async markAsTerminatedSecondaryStatus(assetUuid: string) {
    await this.updateSecondaryStatus(assetUuid, terminatedCoreSecondaryStatus.code);
  }

  async markAsLiveSecondaryStatus(assetUuid: string) {
    await this.updateSecondaryStatus(assetUuid, null);
  }

  async getAssetById(assetUuid: string): Promise<Asset> {
    return await this.store
      .pipe(
        select(selectAssets),
        map(assets => findAssetById(assets, assetUuid)),
        take(1),
      )
      .toPromise();
  }

  private async updateSecondaryStatus(assetUuid: string, status: string) {
    let asset = await this.getAssetById(assetUuid);
    asset = cloneDeep(asset);
    (asset as CommonFinancialProductAsset).coreSecondaryStatus = status;
    await this.updateAsset(asset);
  }
}
