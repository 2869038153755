import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[kptCarouselTemplate]',
})
export class CarouselTemplateDirective {
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('kptCarouselTemplate') stepGroup: string;
  @Input('stepName') stepName: string;
  @Input('skip') skip: boolean;
  constructor(public content: TemplateRef<any>) {}
}
