<div
  class="row item-title-row"
  *ngIf="itemsGroup.label || itemsGroup.assetUuid || itemsGroup.value"
>
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h5 class="font-weight-medium my-3" [innerHTML]="label | async"></h5>
      <h5 class="font-weight-medium my-3" [innerHTML]="itemsGroup.value"></h5>
    </div>
  </div>
</div>
<div class="row mb-3 avoid-page-break">
  <div *ngFor="let item of itemsGroup.items" class="col-4 mb-4">
    <kpt-item [item]="item"></kpt-item>
  </div>
</div>
