import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/model';
import {Store} from '@ngrx/store';
import {CommonCreditAsset, PropertyLoanAsset} from '@shared/analysis/models/credit-products';
import {
  CommonFinancialProductAsset,
  isStakeholderAsset,
} from '@shared/analysis/models/financial-products';
import {LifeInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {CommonInvestmentAsset} from '@shared/analysis/models/investment-products';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {getYearsPluralString} from '@shared/lib';
import {getPersonAge} from '@shared/utils';
import {compact, has, isNil} from 'lodash';
import {Moment} from 'moment';
import * as moment from 'moment';
import {
  Asset,
  assetNames,
  AssetType,
  DateAsset,
  isFamilyMemberAsset,
  isNameAsset,
  ValueAsset,
} from 'src/shared/analysis/models/asset';
import {State} from 'src/store';

@Injectable()
export class AssetHelperService {
  private _familyMembers: FamilyMember[];
  get familyMembers() {
    return this._familyMembers;
  }
  get familyMemberCount() {
    return this._familyMembers.length;
  }

  private _familyMembersBySugarUuid: Record<string, FamilyMember>;

  constructor(private store: Store<State>) {
    this.store.pipe(selectFamilyMembers()).subscribe(members => {
      this._familyMembers = members;
      this._familyMembersBySugarUuid = {};
      members.forEach(member => {
        this._familyMembersBySugarUuid[member.sugarUuid] = member;
      });
    });
  }

  getName(asset: Asset): string {
    const assetType = asset.type === AssetType.OtherObjective ? null : assetNames[asset.type];
    const assetName = compact([assetType, isNameAsset(asset) && asset.name]).join(' \u2013 ');

    const parts = [assetName];

    if (this.familyMemberCount > 1) {
      const familyMemberCaption = this.getFamilyMemberCaption(asset);
      if (familyMemberCaption) parts.push(`(${familyMemberCaption})`);
    }

    return parts.join(' ');
  }

  getFamilyMemberCaption(asset: Asset) {
    const member = this.getFamilyMember(asset) || this.getStakeholder(asset);
    return this.formatFamilyMemberCaption(member);
  }

  formatFamilyMemberCaption(member: FamilyMember) {
    if (!member) return null;

    return compact([
      member.firstName || member.lastName,
      getYearsPluralString(getPersonAge(member.birthDate)),
    ]).join(', ');
  }

  getFamilyMemberBySugarUuid(sugarUuid: string): FamilyMember {
    if (!sugarUuid) return null;
    return this._familyMembersBySugarUuid[sugarUuid];
  }

  getFamilyMember(asset: Asset) {
    if (!isFamilyMemberAsset(asset)) return null;
    return this.getFamilyMemberBySugarUuid(asset.familyMemberUuid);
  }

  getStakeholder(asset: Asset) {
    if (!isStakeholderAsset(asset)) return null;
    return this.getFamilyMemberBySugarUuid(asset.stakeholderUuid);
  }

  prepareAsset(asset: Asset, value: number = null): Asset {
    const dateAsset = asset as DateAsset;
    if (has(dateAsset, 'start') && !isNil(dateAsset.start)) {
      const startDate = moment(dateAsset.start);
      dateAsset.start = this.formatDate(startDate);
    }

    if (has(dateAsset, 'end') && !isNil(dateAsset.end)) {
      const endDate = moment(dateAsset.end);
      dateAsset.end = this.formatDate(endDate);
    }

    const creditAsset = asset as CommonCreditAsset;
    if (has(creditAsset, 'outstandingValueDate') && !isNil(creditAsset.outstandingValueDate)) {
      const endDate = moment(creditAsset.outstandingValueDate);
      creditAsset.outstandingValueDate = this.formatDate(endDate);
    }

    const propertyLoanAsset = asset as PropertyLoanAsset;
    if (
      has(propertyLoanAsset, 'nearestFixationDate') &&
      !isNil(propertyLoanAsset.nearestFixationDate)
    ) {
      const endDate = moment(propertyLoanAsset.nearestFixationDate);
      propertyLoanAsset.nearestFixationDate = this.formatDate(endDate);
    }

    const investmentAsset = asset as CommonInvestmentAsset;
    if (has(investmentAsset, 'presentValueDate') && !isNil(investmentAsset.presentValueDate)) {
      const endDate = moment(investmentAsset.presentValueDate);
      investmentAsset.presentValueDate = this.formatDate(endDate);
    }

    const lifeInsuranceAsset = asset as LifeInsuranceAsset;
    if (has(lifeInsuranceAsset, 'futureValueDate') && !isNil(lifeInsuranceAsset.futureValueDate)) {
      const endDate = moment(lifeInsuranceAsset.futureValueDate);
      lifeInsuranceAsset.futureValueDate = this.formatDate(endDate);
    }

    if (!isNil(value) && has(asset, 'value')) {
      (asset as ValueAsset).value = value;
    }

    const commonFinancialProductAsset = asset as CommonFinancialProductAsset;
    if (
      has(commonFinancialProductAsset, 'paymentStart') &&
      !isNil(commonFinancialProductAsset.paymentStart)
    ) {
      const paymentStartDate = moment(commonFinancialProductAsset.paymentStart);
      commonFinancialProductAsset.paymentStart = this.formatDate(paymentStartDate);
    }

    if (
      has(commonFinancialProductAsset, 'paymentEnd') &&
      !isNil(commonFinancialProductAsset.paymentEnd)
    ) {
      const paymentEndDate = moment(commonFinancialProductAsset.paymentEnd);
      commonFinancialProductAsset.paymentEnd = this.formatDate(paymentEndDate);
    }

    return asset;
  }

  private formatDate(date: Moment): string {
    return date.format('YYYY-MM-DD');
  }
}
