import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@shared/pipes';
import {FamilyMemberComponent} from './family-member.component';

@NgModule({
  declarations: [FamilyMemberComponent],
  imports: [CommonModule, PipesModule],
  exports: [FamilyMemberComponent],
})
export class FamilyMemberUIModule {}
