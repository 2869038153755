import {EventType} from 'src/app/modules/life-in-time/life-in-time.model';

export interface IEventSituationButtonConfig {
  label: string;
  labelFormatted?: string;
  type: EventType;
  icon?: string;
  tooltip?: string;
  buttonType?: string;
  isActive?: boolean;
  isDraggable?: boolean;
  [key: string]: any;
}
