import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

const createStyleNode = (content: string): Node => {
  const styleEl = document.createElement('style');
  styleEl.textContent = content;
  return styleEl;
};

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private stylesMap: Map<string, Node> = new Map();
  private host = this.document.head;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  addStyle(key: string, style: string) {
    this.removeStyle(key);
    const styleEl = createStyleNode(style);
    this.stylesMap.set(key, styleEl);
    this.host.appendChild(styleEl);
  }

  removeStyle(key: string) {
    const styleEl = this.stylesMap.get(key);
    if (styleEl) {
      this.stylesMap.delete(key);
      this.host.removeChild(styleEl);
    }
  }
}
