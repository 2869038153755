import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  TemplateRef,
} from '@angular/core';
import {NavigationEnd, Params, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AssetLinkService} from '@shared/lib/components/asset-link/asset-link.service';
import {filter, map} from 'rxjs/operators';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {FinancialProductTab} from 'src/shared/analysis/forms/definitions.models';
import {AssetType} from 'src/shared/analysis/models/asset';

@UntilDestroy()
@Component({
  selector: 'kpt-asset-link',
  template: `
    <ng-template
      [ngTemplateOutletContext]="{
        queryParams: queryParameters,
        routerLink: routerLink
      }"
      [ngTemplateOutlet]="tmpl"
    >
    </ng-template>
  `,
  styleUrls: ['./asset-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetLinkComponent implements OnChanges {
  @Input()
  assetUuid: string;

  @Input()
  assetType: AssetType;

  @Input()
  clone: boolean;

  @Input()
  tab: FinancialProductTab;

  @Input()
  situation: Situation;

  @Input()
  queryParams: Params = {};

  @ContentChild(TemplateRef)
  tmpl: TemplateRef<any>;

  queryParameters: Params;
  routerLink: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private assetLinkService: AssetLinkService,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        untilDestroyed(this),
      )
      .subscribe(url => {
        this.updateQueryParameters(url);
        this.cd.detectChanges();
      });
  }

  ngOnChanges() {
    if (this.assetUuid && this.clone) {
      this.routerLink = ['../../', this.assetLinkService.getCloneLink(this.assetUuid)];
    } else if (this.assetUuid) {
      this.routerLink = ['../../', this.assetLinkService.getEditLink(this.assetUuid)];
    } else if (this.assetType) {
      this.routerLink = ['../../', this.assetLinkService.getAddLink(this.assetType)];
    } else {
      this.routerLink = [];
    }
    this.updateQueryParameters(this.router.url);
  }

  private updateQueryParameters(returnUrl: string) {
    this.queryParameters = {returnUrl, ...this.queryParams};
    if (this.tab) this.queryParameters.tab = this.tab.toString();
    if (this.situation) this.queryParameters.situation = this.situation.toString();
  }
}
