import {createSelector} from '@ngrx/store';
import {selectCurrentAssets, selectProposedAssets} from '@shared/analysis/store';
import {pull} from 'lodash';
import {Asset, ClientProposalState} from 'src/shared/analysis/models/asset';

/**
 * Select assets which represent the whole proposed state for the client.
 * If client hasn't made a decision about an asset, the current asset is selected.
 * Advisor proposition is ignored, ie. if there is an asset with advisor decision only,
 * the current asset is selected. */
export const selectClientProposedAssets = createSelector(
  selectCurrentAssets,
  selectProposedAssets,
  (currentAssets, proposedAssets): Asset[] => {
    const clientProposedAssets = [...currentAssets];

    pullTerminatedAssets(clientProposedAssets);
    pushAdvisorUpdatedAssets(clientProposedAssets, currentAssets, proposedAssets);
    pushClientUpdatedAssets(clientProposedAssets, currentAssets, proposedAssets);
    pushNewAssets(clientProposedAssets, proposedAssets);

    return clientProposedAssets;
  },
);

function pullTerminatedAssets(clientProposedAssets: Asset[]) {
  const terminatedAssets = clientProposedAssets.filter(
    asset =>
      asset.clientProposalState === ClientProposalState.AdvisorTerminated ||
      asset.clientProposalState === ClientProposalState.ClientTerminated,
  );

  pull(clientProposedAssets, ...terminatedAssets);
}

function pushAdvisorUpdatedAssets(
  clientProposedAssets: Asset[],
  currentAssets: Asset[],
  proposedAssets: Asset[],
) {
  const advisorUpdatedAssets = proposedAssets.filter(
    asset => asset.clientProposalState === ClientProposalState.AdvisorUpdated,
  );
  clientProposedAssets.push(...advisorUpdatedAssets);

  const currentAssetsOfAdvisorUpdatedAssets = advisorUpdatedAssets.map(asset =>
    currentAssets.find(a => a.assetUuid === asset.originalAssetUuid),
  );
  pull(clientProposedAssets, ...currentAssetsOfAdvisorUpdatedAssets);
}

function pushClientUpdatedAssets(
  clientProposedAssets: Asset[],
  currentAssets: Asset[],
  proposedAssets: Asset[],
) {
  const clientUpdatedAssets = proposedAssets.filter(
    asset => asset.clientProposalState === ClientProposalState.ClientUpdated,
  );
  clientProposedAssets.push(...clientUpdatedAssets);

  const currentAssetsOfClientUpdatedAssets = clientUpdatedAssets.map(asset => {
    const advisorProposedAsset = proposedAssets.find(a => a.assetUuid === asset.originalAssetUuid);
    if (advisorProposedAsset) {
      return currentAssets.find(a => a.assetUuid === advisorProposedAsset.originalAssetUuid);
    } else {
      return currentAssets.find(a => a.assetUuid === asset.originalAssetUuid);
    }
  });
  pull(clientProposedAssets, ...currentAssetsOfClientUpdatedAssets);
}

function pushNewAssets(clientProposedAssets: Asset[], proposedAssets: Asset[]) {
  const newAssets = proposedAssets.filter(
    asset =>
      asset.clientProposalState === ClientProposalState.NewClientApproved ||
      asset.clientProposalState === ClientProposalState.NewClientUpdated,
  );
  clientProposedAssets.push(...newAssets);
}
