import {Component, Input} from '@angular/core';

@Component({
  selector: 'kpt-survey-question',
  template: ` <h3 class="participant-name" *ngIf="participantName">{{ participantName }}</h3>
    <div class="divider" *ngIf="participantName"></div>
    <div class="step-body">
      <ng-content></ng-content>
    </div>`,
  styleUrls: ['./survey-step-question.component.scss'],
})
export class SurveyStepQuestionComponent {
  @Input()
  participantName: string;
}
