import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {ButtonModule, MenuItemModule, SelectModule} from '@shared/ui';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {RelatedAssetSelectFormlyComponent} from './related-asset-select-formly.component';

@NgModule({
  declarations: [RelatedAssetSelectFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
    ButtonModule,
    MenuItemModule,
  ],
  exports: [RelatedAssetSelectFormlyComponent],
})
export class RelatedAssetSelectFormlyModule {}
