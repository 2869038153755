import {convertToMonthly} from '@shared/analysis/asset.utils';
import {calculateCurrentEquity, calculateEquity} from '@shared/analysis/evaluation';
import {
  AdvisorProposalState,
  Asset,
  assetNames,
  AssetType,
  ClientProposalState,
  RelatedObjectiveAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {InsuranceAsset} from '@shared/analysis/models/insurance-products';
import {InvestmentAsset} from '@shared/analysis/models/investment-products';
import {
  VehicleProvisionAsset,
  PropertyProvisionAsset,
  PropertyRequirementsAsset,
  VehicleRequirementsAsset,
} from '@shared/analysis/models/objectives';
import {PropertyAsset} from '@shared/analysis/models/properties';
import {getRiskList} from '@shared/analysis/models/risks';
import {getObjectiveProductAssets, ObjectiveType} from '@shared/analysis/objectives.helpers';
import {findAssetsByRelatedPropertyUuid} from '@shared/analysis/store';
import {keyBy, mapValues} from 'lodash';
import * as moment from 'moment';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {sumRows} from 'src/app/modules/financial-plan/store';
import {
  AssetRisk,
  Fulfillment,
  FulfillmentType,
  InputRisk,
  InsuranceRisk,
  Row,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';

export function getCategory(asset: Asset): string {
  return asset.type === AssetType.CurrentAccountUsage ? 'Běžný účet' : assetNames[asset.type];
}

export function getPresentValue(asset: Asset): number {
  return asset.type === AssetType.CurrentAccountUsage
    ? asset.value
    : calculateCurrentEquity([asset]);
}

export function getFutureValue(finalDate: string, asset: Asset): number {
  return asset.type === AssetType.CurrentAccountUsage
    ? asset.value
    : calculateEquity(finalDate, [asset]);
}

export function getFutureValueInYears(years: number, asset: Asset): number {
  return asset.type === AssetType.CurrentAccountUsage
    ? asset.value
    : calculateEquityInYears(years, [asset]);
}

export function getMonthlyValue(asset: Asset): number {
  return asset.type === AssetType.CurrentAccountUsage
    ? null
    : convertToMonthly((asset as ValueAsset).value, (asset as InvestmentAsset).frequency);
}

export function getSkipSum(asset: Asset, situation: Situation): boolean {
  return (
    (situation === Situation.AdvisorProposed &&
      asset.advisorProposalState === AdvisorProposalState.Terminated) ||
    (situation === Situation.ClientProposed &&
      (asset.clientProposalState === ClientProposalState.AdvisorTerminated ||
        asset.clientProposalState === ClientProposalState.ClientTerminated ||
        asset.clientProposalState === ClientProposalState.NewClientRejected))
  );
}

export function numberOrNull(value: number): number | null {
  return typeof value === 'number' && !isNaN(value) && Number.isFinite(value) ? value : null;
}

export function calculateEquityInYears(years: number, assets: Asset[]): number {
  return Number(
    calculateEquity(moment().add(years, 'years').format('YYYY-MM-DD'), assets).toFixed(0),
  );
}

export function formatFulfillment(fulfillment: Fulfillment): string {
  if (fulfillment.type === FulfillmentType.Percent && fulfillment.percent !== null) {
    return `${Math.round(fulfillment.percent * 100)}\u00A0%`;
  }
  if (fulfillment.type === FulfillmentType.Absolute) {
    return `${fulfillment.absoluteSelected}/${fulfillment.absoluteTotal}`;
  }
  return '\u2013';
}

export const introTexts: Partial<Record<ObjectiveType, string>> = {
  [ObjectiveType.Property]:
    'Důležité je zvážit všechny okolnosti, které Vám mohou znemožnit vydělávat peníze. ' +
    'Zajištění příjmů pokryje jejich možný výpadek.',
  [ObjectiveType.Vehicle]:
    'Důležité je zvážit všechny okolnosti, které Vám mohou znemožnit vydělávat peníze. ' +
    'Zajištění příjmů pokryje jejich možný výpadek.',
  [ObjectiveType.Family]:
    'Důležité je zvážit všechny okolnosti, které Vám mohou znemožnit vydělávat peníze. ' +
    'Zajištění příjmů pokryje jejich možný výpadek.',
  [ObjectiveType.Pension]:
    'Kvalitním zajištěním penze si naši klienti vytvoří dostatečnou rezervu, ' +
    'která jim umožní užít si svou penzi podle svých představ.',
  [ObjectiveType.Housing]:
    'Určení potřené částky v potřebném čase nám umožňuje využít dostupných příležitostí' +
    ' a zdrojů pro splnění Vámi stanoveného cíle.',
  [ObjectiveType.Independence]:
    'Pečlivou analýzou Vašeho hospodaření a majetku nastavujeme nejlepší možnou cestu' +
    ' k dosažení Vaši finanční nezávislosti.',
  [ObjectiveType.FinancialResources]:
    'Určení potřené částky v potřebném čase nám umožňuje využít dostupných příležitostí' +
    ' a zdrojů pro splnění Vámi stanoveného cíle.',
  [ObjectiveType.Reserve]:
    'Určení potřené částky v potřebném čase nám umožňuje využít dostupných příležitostí' +
    ' a zdrojů pro splnění Vámi stanoveného cíle.',
  [ObjectiveType.AdditionalContracts]:
    'V rámci této sekce se přiřazují zbývající smlouvy, které nejsou vázané k žádné prioritě.',
};

export function getPropertyTable(
  situation: Situation,
  assets: Asset[],
  propertyAsset: PropertyAsset,
  objectiveAsset: PropertyProvisionAsset | VehicleProvisionAsset,
  objectiveType: ObjectiveType,
): Table {
  const relatedAssets = getObjectiveProductAssets(objectiveType);
  const rows = findAssetsByRelatedPropertyUuid(assets, propertyAsset.assetUuid)
    .filter(
      asset => (asset as RelatedObjectiveAsset).relatedObjectiveUuid === objectiveAsset.assetUuid,
    )
    .filter(asset => relatedAssets.includes(asset.type))
    .map((asset): Row => {
      const insuranceAsset = asset as InsuranceAsset;
      return {
        assetUuid: insuranceAsset.assetUuid,
        advisorProposalState: insuranceAsset.advisorProposalState,
        clientProposalState: insuranceAsset.clientProposalState,
        asset,
        name: insuranceAsset.name ? insuranceAsset.name : assetNames[insuranceAsset.type],
        category: getCategory(asset),
        monthlyValue: getMonthlyValue(asset),
        skipSum: getSkipSum(asset, situation),
      };
    });

  return {
    rows,
    sum: sumRows(rows),
    fulfillment: null,
    rating: null,
  };
}

export function safeNumber(fn: () => number): number {
  try {
    const value = fn();
    return isFinite(value) ? value : 0;
  } catch (e) {
    return 0;
  }
}

export function mergePropertyRisks(
  currentPropertyRisks: AssetRisk[],
  advisorProposedPropertyRisks: AssetRisk[],
  clientProposedPropertyRisks: AssetRisk[],
  inputRisks: InputRisk[],
): InsuranceRisk[] {
  const currentRisksMap = mapValues(keyBy(currentPropertyRisks, 'key'), 'value');
  const advisorProposedRisksMap = mapValues(keyBy(advisorProposedPropertyRisks, 'key'), 'value');
  const clientProposedRisksMap = mapValues(keyBy(clientProposedPropertyRisks, 'key'), 'value');

  return inputRisks.map(inputRisk => ({
    key: inputRisk.key,
    heading: inputRisk.label,
    currentValue: currentRisksMap[inputRisk.key],
    advisorProposedValue: advisorProposedRisksMap[inputRisk.key],
    clientProposedValue: clientProposedRisksMap[inputRisk.key],
  }));
}

export function getPropertyInputRisks(
  requirementsAsset: PropertyRequirementsAsset | VehicleRequirementsAsset,
): InputRisk[] {
  if (!requirementsAsset) return [];
  return getRiskList(requirementsAsset)
    .filter(r => r.data.active)
    .map((r): InputRisk => {
      return {
        key: r.data.key,
        label: r.def.label,
        limit: r.data.limit,
      };
    });
}
