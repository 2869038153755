import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedService} from '@generated/controllers/Shared';
import {FormsSharedModule} from '@generated/store/forms-shared.module';
import {StoreModule} from '@ngrx/store';
import {InvestmentSummaryService} from '@shared/investment-survey-old/component/summary/investment/investment-summary.service';
import {PensionSummaryService} from '@shared/investment-survey-old/component/summary/pension/pension-summary.service';
import {InvestmentSurveyDataService} from '@shared/investment-survey-old/investment-survey-data.service';
import {InvestmentSurveyUtilsService} from '@shared/investment-survey-old/investment-survey-utils.service';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {reducer, storeFeatureName} from '@shared/investment-survey-old/store';
import {EmptyStateModule} from '@shared/lib/components/empty-state/empty-state.module';
import {
  ButtonModule,
  FormModule,
  HelpModule,
  ModalModule,
  SimpleGraphModule,
  SliderModule,
} from '@shared/ui';
import {CarouselStepperModule} from '@shared/ui/carousel-stepper/carousel-stepper.module';
import {SurveyModule} from '@shared/ui/survey/survey.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {InconsistencyWarningModalComponent} from './component/inconsistency-warning/inconsistency-warning-modal.component';
import {InvestmentSurveyComponent} from './component/investment-survey.component';
import {ClientCategorySelectionComponent} from './component/summary/investment/client-category-selection/client-category-selection.component';
import {InvestmentSummaryComponent} from './component/summary/investment/investment-summary.component';
import {LifeInsuranceSummaryComponent} from './component/summary/life-insurance/life-insurance-summary.component';
import {LifeInsuranceSummaryService} from './component/summary/life-insurance/life-insurance-summary.service';
import {LifeInsuranceSummaryOldComponent} from './component/summary/life-insurance-old/life-insurance-summary-old.component';
import {PensionSummaryComponent} from './component/summary/pension/pension-summary.component';
import {SummaryAgreementComponent} from './component/summary/summary-agreement/summary-agreement.component';

@NgModule({
  declarations: [
    InvestmentSurveyComponent,
    LifeInsuranceSummaryComponent,
    LifeInsuranceSummaryOldComponent,
    InvestmentSummaryComponent,
    PensionSummaryComponent,
    ClientCategorySelectionComponent,
    SummaryAgreementComponent,
    InconsistencyWarningModalComponent,
  ],
  exports: [
    InvestmentSurveyComponent,
    LifeInsuranceSummaryComponent,
    InvestmentSummaryComponent,
    PensionSummaryComponent,
    SummaryAgreementComponent,
    LifeInsuranceSummaryOldComponent,
  ],
  providers: [
    SharedService,
    Questions,
    InvestmentSurveyDataService,
    LifeInsuranceSummaryService,
    InvestmentSummaryService,
    PensionSummaryService,
    InvestmentSurveyUtilsService,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(storeFeatureName, reducer),
    FormsSharedModule,
    FormModule,
    CarouselStepperModule,
    SimpleGraphModule,
    SliderModule,
    EmptyStateModule,
    SurveyModule,
    HelpModule,
    ModalModule,
    ButtonModule,
    TooltipModule,
  ],
})
export class InvestmentSurveyModule {}
