<kpt-accordion-group
  *ngIf="formGroup"
  [headingTemplate]="createReservesHeading"
  [preventDefault]="true"
  [isOpen]="!isChild && formGroup.get('createReserves').value"
  [formGroup]="formGroup"
>
  <ng-template #createReservesHeading>
    <kpt-switch
      [disabledField]="(disableReserves$ | async)[personId] || readonly"
      formControlName="createReserves"
      customFormGroupClass="form-group--full-width-switch"
      [checked]="formGroup.get('createReserves').value"
      label="Chci mít možnost investovat volné finanční prostředky, případně vytvořit finanční rezervy"
    >
    </kpt-switch>
  </ng-template>

  <ng-container *ngIf="!isChild">
    <div>Chci využít:</div>
    <div>
      <kpt-checkbox
        formControlName="taxRelief"
        [appendTemplate]="taxReliefTmplRef"
        label="Daňové úlevy"
        [disabledField]="readonly"
      >
      </kpt-checkbox>

      <ng-template #taxReliefTmplRef>
        <kpt-help class="pl-3 hidden-print" [text]="taxReliefHelp" direction="right"></kpt-help>
      </ng-template>
    </div>
    <div>
      <kpt-checkbox
        formControlName="employeeContribution"
        label="Příspěvek zaměstnavatele"
        [disabledField]="readonly"
      >
      </kpt-checkbox>
    </div>
  </ng-container>
</kpt-accordion-group>
