import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {SexFamilyMemberEnum} from '@shared/models/family-member';
import {ChildAgeType, childrenTypes} from '@shared/ui/family-member/family-member.model';

const memberClass = (type: string, sex: SexFamilyMemberEnum | string, age: number): string => {
  const suffix: string[] = [];
  if (type === 'CHILD') {
    sex === 'MALE' ? suffix.push('son') : suffix.push('daughter');
    age < childrenTypes.preschool.to
      ? suffix.push(`-${ChildAgeType.PRE_SCHOOL}`)
      : age < childrenTypes.middleChildhood.to
      ? suffix.push(`-${ChildAgeType.MIDDLE_CHILDHOOD}`)
      : suffix.push(`-${ChildAgeType.TEENAGER}`);
  } else sex === 'MALE' ? suffix.push('father') : suffix.push('mother');
  return suffix.join('');
};

@Component({
  selector: 'kpt-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss'],
})
export class FamilyMemberComponent {
  @HostBinding('attr.class') get hostClasses(): string {
    return `family-page--${memberClass(this.type, this.sex, this.data.age)}`;
  }

  @Input() data: FamilyMember;
  @Input() icon?: string;
  @Input() sex: SexFamilyMemberEnum | string;
  @Input() type: string;
  @Input() isFirst: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  get familyMemberClass(): string {
    return `family-member--${memberClass(this.type, this.sex, this.data.age)}`;
  }

  get isPreschool(): boolean {
    return this.data.age < childrenTypes.preschool.to;
  }

  get isMiddleChildhood(): boolean {
    return (
      this.data.age >= childrenTypes.middleChildhood.from &&
      this.data.age < childrenTypes.middleChildhood.to
    );
  }

  get isTeenager(): boolean {
    return this.data.age >= childrenTypes.teenager.from;
  }
}
