import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BackendValidationModule} from '@shared/backend-validation/backend-validation.module';
import {CommonFieldStatusesModule} from '../../forms/common-field/common-field-statuses.module';
import {SelectModule} from '../../forms/select';
import {ProductSelectFormlyComponent} from './product-select-formly.component';

@NgModule({
  declarations: [ProductSelectFormlyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    CommonFieldStatusesModule,
    BackendValidationModule,
  ],
  exports: [ProductSelectFormlyComponent],
})
export class ProductSelectFormlyModule {}
