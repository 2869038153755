<div *ngIf="result">
  <div class="result">
    <div class="result-title">
      Znalosti a zkušenosti
      <kpt-help class="pl-3" tooltipCustomClass="align-left" [text]="investorLevelTooltip">
      </kpt-help>
    </div>
    <div class="result-value">{{ result.knowledgeExperience }}</div>
  </div>
  <div class="result">
    <div class="result-title">Investiční horizont</div>
    <div class="result-value">{{ result.horizon }}</div>
  </div>
  <div class="result">
    <div class="result-title">Rizikový profil klienta</div>
    <div class="result-value">{{ result.riskProfile }}</div>
  </div>
  <div class="result">
    <div class="result-title">Investiční strategie s maximálním možným rizikem</div>
    <div class="result-value">{{ result.strategy }}</div>
  </div>
</div>
