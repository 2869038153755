import {SixMonthReserve} from '@generated/defs/SixMonthReserve';
import {invert} from 'lodash';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';

/**
 * @deprecated
 */
export enum RiskId {
  Incapacity = 'incapacity',
  DailyCompensation = 'daily-compensation',
  PhysicalDamage = 'physical-damage',
  Hospitalization = 'hospitalization',
  SeriousDisease = 'serious-disease',
  PermanentDisability = 'permanent-disability',
  Invalidity = 'invalidity',
  InvalidityDebtRepayment = 'invalidity-debt-repayment',
  DeathDebtRepayment = 'death-debt-repayment',
  DeathThriceAnnualIncome = 'death-thrice-annual-income',
  Survival = 'survival',
  DeathChildrenEnsurement = 'death-children-ensurement',
  ChildDailyCompensation = 'child-daily-compensation',
  ChildPhysicalDamage = 'child-physical-damage',
  ChildHospitalization = 'child-hospitalization',
  ChildSeriousDisease = 'child-serious-disease',
  ChildPermanentDisability = 'child-permanent-disability',
  ChildSurvival = 'child-survival',
}

/**
 * @deprecated
 */
export enum RiskIdCamelCased {
  Incapacity = 'incapacity',
  DailyCompensation = 'dailyCompensation',
  PhysicalDamage = 'physicalDamage',
  Hospitalization = 'hospitalization',
  SeriousDisease = 'seriousDisease',
  PermanentDisability = 'permanentDisability',
  Invalidity = 'invalidity',
  InvalidityDebtRepayment = 'invalidityDebtRepayment',
  DeathDebtRepayment = 'deathDebtRepayment',
  DeathThriceAnnualIncome = 'deathThriceAnnualIncome',
  Survival = 'survival',
  DeathChildrenEnsurement = 'deathChildrenEnsurement',
  ChildDailyCompensation = 'childDailyCompensation',
  ChildPhysicalDamage = 'childPhysicalDamage',
  ChildHospitalization = 'childHospitalization',
  ChildSeriousDisease = 'childSeriousDisease',
  ChildPermanentDisability = 'childPermanentDisability',
  ChildSurvival = 'childSurvival',
}

/**
 * @deprecated
 */
export const camelCasedRiskIds: Record<RiskId, RiskIdCamelCased> = {
  [RiskId.Incapacity]: RiskIdCamelCased.Incapacity,
  [RiskId.DailyCompensation]: RiskIdCamelCased.DailyCompensation,
  [RiskId.PhysicalDamage]: RiskIdCamelCased.PhysicalDamage,
  [RiskId.Hospitalization]: RiskIdCamelCased.Hospitalization,
  [RiskId.SeriousDisease]: RiskIdCamelCased.SeriousDisease,
  [RiskId.PermanentDisability]: RiskIdCamelCased.PermanentDisability,
  [RiskId.Invalidity]: RiskIdCamelCased.Invalidity,
  [RiskId.InvalidityDebtRepayment]: RiskIdCamelCased.InvalidityDebtRepayment,
  [RiskId.DeathDebtRepayment]: RiskIdCamelCased.DeathDebtRepayment,
  [RiskId.DeathThriceAnnualIncome]: RiskIdCamelCased.DeathThriceAnnualIncome,
  [RiskId.Survival]: RiskIdCamelCased.Survival,
  [RiskId.DeathChildrenEnsurement]: RiskIdCamelCased.DeathChildrenEnsurement,
  [RiskId.ChildDailyCompensation]: RiskIdCamelCased.ChildDailyCompensation,
  [RiskId.ChildPhysicalDamage]: RiskIdCamelCased.ChildPhysicalDamage,
  [RiskId.ChildHospitalization]: RiskIdCamelCased.ChildHospitalization,
  [RiskId.ChildSeriousDisease]: RiskIdCamelCased.ChildSeriousDisease,
  [RiskId.ChildPermanentDisability]: RiskIdCamelCased.ChildPermanentDisability,
  [RiskId.ChildSurvival]: RiskIdCamelCased.ChildSurvival,
};

/**
 * @deprecated
 */
export const reverseCamelCasedRiskIds = invert(camelCasedRiskIds) as Record<
  RiskIdCamelCased,
  RiskId
>;

/**
 * @deprecated
 */
export interface RiskDefinition {
  onlyProposals?: boolean;
  child?: boolean;
  help?: HelpTextRisk;
  id: RiskId;
  label: string;
  description: HelpTextRisk;
  outlook: Outlook;
  provision: Provision;
  calculate: CalculateRisk;
}

/**
 * @deprecated
 */
export interface RiskInput {
  monthlyIncomeEmployee?: number;
  monthlyIncomeContractor?: number;
  otherEmployee?: number;
  otherContractor?: number;
  insuranceContractor?: number;
  netMonthlyExpense?: number;
  debt?: number;
  age?: number;
  childrenAges?: number[];
  childrenTaxAdvantage?: number;
  provision: Provision;
  persons?: LifeInsurancePerson[];
  otherIncome?: number;
  mortgageRepaymentByRisk?: MortgageRepaymentByRisk;
  sixMonthReserve?: SixMonthReserve;
}

/**
 * @deprecated
 */
export enum Outlook {
  Same,
  Decreasing,
  Min,
  Daily,
}

/**
 * @deprecated
 */
export enum Provision {
  Income = 'income',
  Expenses = 'expenses',
  Standard = 'standard',
  Minimal = 'minimal',
  Custom = 'custom',
}

/**
 * @deprecated
 */
export type CalculateRisk = (input: RiskInput) => number;

/**
 * @deprecated
 */
export type HelpTextRisk = (input: RiskInput) => string;

/**
 * @deprecated
 */
export type MortgageRepaymentByRisk = Partial<Record<RiskId, number>>;
