import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UIFile} from '@shared/models/file.models';
import {IOption} from '@shared/ui/forms';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {BehaviorSubject} from 'rxjs';
import {AttachmentsModalData, UploadFileForm} from './attachments-modal.models';

@UntilDestroy()
@Component({
  selector: 'kpt-attachments-modal',
  templateUrl: './attachments-modal.component.html',
  styleUrls: ['./attachments-modal.component.scss'],
})
export class AttachmentsModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() files: UIFile[] = [];
  @Input() documentTypeOptions: IOption[];

  @Output() onSubmit: EventEmitter<UIFile[]>;

  uploadInProgress$ = new BehaviorSubject<boolean>(false);

  form = new UntypedFormGroup({
    files: this.fb.array([]),
  });

  constructor(private fb: UntypedFormBuilder) {}

  /** Set by modal container. */
  set data({files, documentTypeOptions, onSubmit, uploadInProgress}: AttachmentsModalData) {
    this.files = files;
    this.documentTypeOptions = documentTypeOptions;
    this.onSubmit = onSubmit;
    this.uploadInProgress$ = uploadInProgress;
  }

  get showDocumentType() {
    return this.documentTypeOptions && this.documentTypeOptions.length > 0;
  }

  get formFiles() {
    return this.form.get('files') as UntypedFormArray;
  }

  set formFiles(formFiles: UntypedFormArray) {
    this.form.setControl('files', formFiles);
  }

  ngOnInit() {
    this.uploadInProgress$.pipe(untilDestroyed(this)).subscribe(uploaded => {
      if (uploaded) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });

    this.formFiles = this.fb.array(
      this.files.map(
        file =>
          new UntypedFormGroup({
            name: new UntypedFormControl(file.name, Validators.required),
            ...(this.showDocumentType
              ? {documentType: new UntypedFormControl(null, Validators.required)}
              : {}),
          }),
      ),
    );
  }

  ngOnDestroy() {}

  submit() {
    if (!this.form.valid) {
      // TODO show validation errors
      markFormGroupTouched(this.form);
      return;
    }

    const formFilesValue: UploadFileForm[] = this.formFiles.value;
    const files: UIFile[] = formFilesValue.map((formFile, index) => ({
      ...this.files[index],
      ...formFile,
    }));
    this.onSubmit.emit(files);
  }
}
