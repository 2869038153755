<div [ngSwitch]="status">
  <div *ngSwitchCase="statuses.Uploading" class="file-item file-uploading">
    <span class="icon icon--file"></span>
    <span class="file-name" [title]="file.name">{{ file.name }}</span>
    <!-- TODO uncomment when backend is ready -->
    <!--<div class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': file?.item?.progress + '%' }"></div>
    </div>-->
    <div class="action-buttons">
      <div class="lds-dual-ring"></div>
      <span class="icon icon--close" (click)="onCancelUpload()"></span>
    </div>
  </div>

  <div *ngSwitchCase="statuses.Canceled" class="file-item file-canceled">
    <span class="icon icon--file"></span>
    <span class="file-name" [title]="file.name">{{ file.name }}</span>
    <div class="action-buttons">
      <span class="icon icon--refresh" (click)="onRepeatUpload()"></span>&nbsp;
      <span class="icon icon--bin" (click)="onRemoveFromQueue()"></span>
    </div>
  </div>

  <div *ngSwitchCase="statuses.Ready" class="file-item file-ready">
    <span class="icon icon--file"></span>
    <span class="file-name" [title]="file.name" (click)="onPreview()">{{ file.name }}</span>
    <div *ngIf="!shortcut" class="action-buttons">
      <span class="icon icon--edit" (click)="toggleEditName()"></span>&nbsp;
      <span class="icon icon--bin" (click)="onRemove()"></span>
    </div>
  </div>

  <div *ngSwitchCase="statuses.Edit" class="file-item file-edit">
    <input #newNameInput type="text" value="{{ file.name }}" />
    <div class="action-buttons">
      <span class="icon icon--check" (click)="onSaveNewName(newNameInput)"></span>&nbsp;
      <span class="icon icon--close" (click)="toggleEditName()"></span>
    </div>
  </div>
</div>
