import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BubblesComponent} from './bubbles.component';

@NgModule({
  declarations: [BubblesComponent],
  imports: [CommonModule],
  exports: [BubblesComponent],
})
export class BubblesModule {}
