import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LifeInsuranceSharedModule} from '@shared/life-insurance/life-insurance-shared.module';
import {AssetsHandlerService} from 'src/shared/analysis/assets-handler.service';
import {AssetsDefinitionsModule} from 'src/shared/analysis/forms/definitions.module';
import {AssetDetailModalHandlerService} from './asset-detail-modal-handler.service';
import {AssetDetailPageComponent, AssetDetailType} from './asset-detail.component';
import {CanActivateAssetDetail, CanDeactivateAssetDetail} from './asset-detail.guards';
import {AssetDetailModalWrapperModule} from './modal-wrapper/asset-detail-modal-wrapper.module';

const ROUTES: Routes = [
  {
    path: 'edit/:uuid',
    data: {
      type: AssetDetailType.Edit,
    },
    component: AssetDetailPageComponent,
    canActivate: [CanActivateAssetDetail],
    canDeactivate: [CanDeactivateAssetDetail],
  },
  {
    path: 'new/:type',
    data: {
      type: AssetDetailType.New,
    },
    component: AssetDetailPageComponent,
    canActivate: [CanActivateAssetDetail],
    canDeactivate: [CanDeactivateAssetDetail],
  },
  {
    path: 'clone/:uuid',
    data: {
      type: AssetDetailType.Clone,
    },
    component: AssetDetailPageComponent,
    canActivate: [CanActivateAssetDetail],
    canDeactivate: [CanDeactivateAssetDetail],
  },
];

@NgModule({
  declarations: [AssetDetailPageComponent],
  exports: [AssetDetailPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    AssetsDefinitionsModule,
    AssetDetailModalWrapperModule,
    LifeInsuranceSharedModule,
  ],
  providers: [
    AssetsHandlerService,
    CanActivateAssetDetail,
    CanDeactivateAssetDetail,
    AssetDetailModalHandlerService,
  ],
})
export class AssetDetailModule {}
