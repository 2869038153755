import {GetBusinessCaseResponse} from '@generated/defs/GetBusinessCaseResponse';
import {LifeSituation} from '@shared/business-case/store/business-case.state';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {ofType, unionize, UnionOf} from 'unionize';

export const BusinessCaseActions = unionize(
  {
    BC_SetData: ofType<{
      businessCases: GetBusinessCaseResponse[];
      situation: LifeSituation;
    }>(),
    BC_SetFinalizedBusinessCases: ofType<GetBusinessCaseResponse[]>(),
    BC_ResetState: ofType<{}>(),
    BC_UpdateStatusAndTimestamp: ofType<{
      situation: LifeSituation;
      status: BusinessCaseStatus;
      timestamp: string;
    }>(),
  },
  {tag: 'type', value: 'payload'},
);

export type BusinessCaseAction = UnionOf<typeof BusinessCaseActions>;
