import {Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {
  GtmService,
  LoginService,
  setErrorLoggerAdvisor,
  VersionCheckerService,
  YBugService,
} from '@lib/services';
import * as moment from 'moment';
import {filter} from 'rxjs/operators';
import {ConnectionInfoService} from 'src/app/services/connection-info.service';
import {PiwikService} from 'src/app/services/piwik-service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'kpt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  @ViewChild('assetDetailRef', {read: ViewContainerRef, static: true})
  assetDetailRef: ViewContainerRef;

  constructor(
    private gtmService: GtmService,
    private loginService: LoginService,
    private versionChecker: VersionCheckerService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private piwikService: PiwikService,
    connectionsInfoService: ConnectionInfoService,
    yBugService: YBugService,
  ) {
    moment.locale('cs');
    moment.defineLocale('cs-months', {
      parentLocale: 'cs',
      months: [
        'ledna',
        'února',
        'března',
        'dubna',
        'května',
        'června',
        'července',
        'srpna',
        'září',
        'října',
        'listopadu',
        'prosince',
      ],
    });

    this.gtmService.setUser(this.loginService.getUserId());
    setErrorLoggerAdvisor(this.loginService.getUserBasicInformation());
    connectionsInfoService.init();

    yBugService.initialize(environment.YBugProjectId, environment.YBugTitle);

    if (environment.production || environment.name === 'dev') this.versionChecker.init();
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.piwikService.initUserIdAndDimensions();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      let child = this.route.snapshot.firstChild;
      while (child.firstChild) child = child.firstChild;
      this.titleService.setTitle(`${child.data.title ?? 'Jiné'} \u2013 Kappka`);
    });
  }
}
