<div class="participants" [formGroup]="participantsForm">
  <h2>Vyplňuji dotazník pro:</h2>
  <div class="participant" *ngFor="let person of participants">
    <kpt-checkbox
      [formControlName]="person.sugarUuid"
      [label]="person.name"
      [disabledField]="person.disabled"
    >
    </kpt-checkbox>
  </div>
</div>
