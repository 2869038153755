<div class="row mb-5" *ngIf="category.label || category.value">
  <div class="col-12">
    <div class="d-flex justify-content-between title-row">
      <h3 class="mb-1">{{ category.label }}</h3>
      <h3 class="mb-1">{{ category.value }}</h3>
    </div>
  </div>
</div>
<div class="row d-print-block">
  <div class="col-12">
    <kpt-category-type
      *ngFor="let categoryType of category.categoryTypes"
      [categoryType]="categoryType"
    >
    </kpt-category-type>
  </div>
</div>
