import {createSelector} from '@ngrx/store';
import {
  Asset,
  assetNames,
  financialProductAssets,
  RelatedObjectiveAsset,
} from '@shared/analysis/models/asset';
import {CommonInvestmentAsset} from '@shared/analysis/models/investment-products';
import {ObjectiveType} from '@shared/analysis/objectives.helpers';
import {
  selectAdditionalAdvisorProposedAssets,
  selectAdditionalClientProposedAssets,
  selectCurrentAssets,
} from '@shared/analysis/store';
import {
  getCategory,
  getMonthlyValue,
  getSkipSum,
  introTexts,
} from 'src/app/modules/financial-plan/objectives/objectives.helpers';
import {Situation} from 'src/app/modules/financial-plan/objectives/objectives.models';
import {selectAdditionalContracts} from 'src/app/modules/financial-plan/store/financial-plan.selectors';
import {sumRows} from 'src/app/modules/financial-plan/store/objectives-common.selectors';
import {
  AdditionalContractsObjective,
  FulfillmentType,
  Row,
  Table,
} from 'src/app/modules/financial-plan/store/objectives.models';

export const selectAdditionalContractsObjective = createSelector(
  selectCurrentAssets,
  selectAdditionalAdvisorProposedAssets,
  selectAdditionalClientProposedAssets,
  selectAdditionalContracts,
  (
    currentAssets,
    advisorProposedAssets,
    clientProposedAssets,
    additionalContracts,
  ): AdditionalContractsObjective[] => {
    const currentTable = getTable(Situation.Current, currentAssets);
    const advisorProposedTable = getTable(Situation.AdvisorProposed, advisorProposedAssets);
    const clientProposedTable = getTable(Situation.ClientProposed, clientProposedAssets);

    if (
      currentTable.rows.length === 0 &&
      advisorProposedTable.rows.length === 0 &&
      clientProposedTable.rows.length === 0
    ) {
      return [];
    }

    return [
      {
        type: ObjectiveType.AdditionalContracts,
        objectiveAsset: null,
        objectiveValue: null,
        name: 'Ostatní smlouvy',
        introText: introTexts[ObjectiveType.AdditionalContracts],
        table: {
          current: currentTable,
          advisorProposed: advisorProposedTable,
          clientProposed: clientProposedTable,
        },
        input: ['Optimalizace ostatních smluv, které se nevážou k\u00a0žádné prioritě.'],
        advisorProposal: additionalContracts?.advisorSolution ?? '',
      },
    ];
  },
);

function getTable(situation: Situation, assets: Asset[]): Table {
  const contractTypes = financialProductAssets;
  const contractAssets = assets.filter(asset => contractTypes.includes(asset.type));
  const rows = contractAssets
    .filter(asset => !(asset as RelatedObjectiveAsset).relatedObjectiveUuid)
    .map((asset): Row => {
      const investmentAsset = asset as CommonInvestmentAsset;
      return {
        assetUuid: investmentAsset.assetUuid,
        advisorProposalState: investmentAsset.advisorProposalState,
        clientProposalState: investmentAsset.clientProposalState,
        asset,
        name: investmentAsset.name ? investmentAsset.name : assetNames[investmentAsset.type],
        category: getCategory(asset),
        monthlyValue: getMonthlyValue(asset),
        skipSum: getSkipSum(asset, situation),
      };
    });

  return {
    rows,
    sum: sumRows(rows),
    fulfillment: {
      type: FulfillmentType.Percent,
      percent: null,
    },
    rating: null,
  };
}
