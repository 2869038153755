import {IStepperProgressItem} from '@shared/ui';
import {SecuredPage} from 'src/app/layouts/secured/secured-layout.config';
import {ClientPage} from 'src/app/modules/client/client-container.config';
import {createStepperProgressConfig} from 'src/shared/ui/stepper-progress/stepper-progress.utils';

const whatLoan = {
  url: 'what-loan',
  done: false,
  label: 'Jaký úvěr',
};
const mortgage = {
  url: 'mortgage',
  done: false,
  label: 'Hypotéka',
};
const buildingSavings = {
  url: 'building-savings',
  done: false,
  label: 'Stavební spoření',
};
const whatToThink = {
  url: 'what-to-think',
  done: false,
  label: 'Na co myslet',
};

export const housingStepperProgressConfig: IStepperProgressItem[] = createStepperProgressConfig(
  [whatLoan, mortgage, buildingSavings, whatToThink],
  SecuredPage.Client,
  ClientPage.Housing,
);

export const housingStepperProgressConfigInModal: IStepperProgressItem[] = [
  whatLoan,
  mortgage,
  buildingSavings,
  whatToThink,
];
