import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Category} from 'src/app/modules/client/summary/store/summary.models';

@Component({
  selector: 'kpt-section-category',
  templateUrl: './section-category.component.html',
  styleUrls: ['./section-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCategoryComponent implements OnInit {
  @Input() category: Category;

  constructor() {}

  ngOnInit(): void {}
}
