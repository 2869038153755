import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'kpt-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input()
  icon: string;

  @Input()
  iconSrc: string;

  @Input()
  @HostBinding('class.menu-item--is-disabled')
  isDisabled = false;

  @HostBinding('class') get className() {
    return 'menu-item';
  }
}
