import {AssetValidatorsRules} from './forms/definitions.models';
import {AssetType} from './models/asset';

export const investmentAssetValidators: AssetValidatorsRules = {
  presentValueDate: [{name: 'required'}],
  presentValue: [{name: 'required'}],
  frequency: [{name: 'required'}],
  start: [{name: 'required'}],
  value: [{name: 'required'}, {name: 'min', comparator: 0}],
};

const savingsAccountAssetValidators: AssetValidatorsRules = {
  frequency: [{name: 'required'}],
  start: [{name: 'required'}],
  value: [{name: 'required'}, {name: 'min', comparator: 0}],
};

const regularExpenseAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}, {name: 'min', comparator: 0}],
};

export const creditAssetValidators: AssetValidatorsRules = {
  outstandingValueDate: [{name: 'required'}],
  outstandingValue: [{name: 'required'}],
};

const propertyAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

const regularInsuranceExpenseAssetValidators: AssetValidatorsRules = {
  frequency: [{name: 'required'}],
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

const insuranceWithFutureValueAssetValidators: AssetValidatorsRules = {
  frequency: [{name: 'required'}],
  start: [{name: 'required'}],
  value: [{name: 'required'}],
  presentValueDate: [{name: 'required'}],
  presentValue: [{name: 'required'}],
};

const oneTimeExpenseAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

const housingAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
};

const otherObjectiveExpenseAssetValidators: AssetValidatorsRules = {
  payment: [{name: 'required'}],
};

const currentBalanceAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

const regularIncomeAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

const contractorAssetValidators: AssetValidatorsRules = {
  start: [{name: 'required'}],
  value: [{name: 'required'}],
};

export const assetValidators: Record<AssetType, AssetValidatorsRules> = {
  // Sandbox Assets
  [AssetType.SandboxCurrentBalance]: null,
  [AssetType.SandboxIncome]: null,
  [AssetType.SandboxExpenses]: null,
  [AssetType.SandboxUnitTrust]: null,
  [AssetType.SandboxHousePurchase]: null,
  [AssetType.SandboxMortgage]: null,
  [AssetType.SandboxCarPurchase]: null,
  [AssetType.SandboxConsumerLoan]: null,
  [AssetType.SandboxLossOfWork]: null,
  [AssetType.SandboxInheritance]: null,

  // Objectives
  [AssetType.OwnHousing]: housingAssetValidators,
  [AssetType.ChildrenFuture]: oneTimeExpenseAssetValidators,
  [AssetType.Retirement]: oneTimeExpenseAssetValidators,
  [AssetType.FinancialIndependence]: oneTimeExpenseAssetValidators,
  [AssetType.ShortTermReserve]: oneTimeExpenseAssetValidators,
  [AssetType.LongTermReserve]: oneTimeExpenseAssetValidators,
  [AssetType.BadDebtsRepayment]: oneTimeExpenseAssetValidators,
  [AssetType.FamilyProvision]: null,
  [AssetType.PropertyProvision]: null,
  [AssetType.PropertyRequirements]: null,
  [AssetType.VehicleProvision]: null,
  [AssetType.VehicleRequirements]: null,
  [AssetType.ChildBirth]: regularExpenseAssetValidators,
  [AssetType.NewHousing]: housingAssetValidators,
  [AssetType.Reconstruction]: housingAssetValidators,
  [AssetType.Furnishings]: oneTimeExpenseAssetValidators,
  [AssetType.Car]: oneTimeExpenseAssetValidators,
  [AssetType.Vacation]: oneTimeExpenseAssetValidators,
  [AssetType.OtherObjective]: otherObjectiveExpenseAssetValidators,

  // Common Expenses
  [AssetType.CommonExpensesSum]: regularExpenseAssetValidators,
  [AssetType.Food]: regularExpenseAssetValidators,
  [AssetType.HousingExpenses]: regularExpenseAssetValidators,
  [AssetType.Drugstore]: regularExpenseAssetValidators,
  [AssetType.Transport]: regularExpenseAssetValidators,
  [AssetType.Clothes]: regularExpenseAssetValidators,
  [AssetType.Communication]: regularExpenseAssetValidators,
  [AssetType.Hobbies]: regularExpenseAssetValidators,
  [AssetType.Vacations]: regularExpenseAssetValidators,
  [AssetType.ChildrenExpenses]: regularExpenseAssetValidators,
  [AssetType.OtherCommonExpenses]: regularExpenseAssetValidators,
  [AssetType.ContractorInsurance]: contractorAssetValidators,

  // Main Incomes
  [AssetType.Employment]: regularIncomeAssetValidators,
  [AssetType.Contractor]: contractorAssetValidators,
  [AssetType.FinancialOutlook]: null,
  [AssetType.ChildrenTaxAdvantage]: null,

  // Other Incomes
  [AssetType.Maternity]: regularIncomeAssetValidators,
  [AssetType.Parental]: regularIncomeAssetValidators,
  [AssetType.UnemploymentBenefits]: regularIncomeAssetValidators,
  [AssetType.PensionIncome]: regularIncomeAssetValidators,
  [AssetType.OtherPensionIncome]: regularIncomeAssetValidators,
  [AssetType.RentalIncome]: regularIncomeAssetValidators,
  [AssetType.Dividends]: regularIncomeAssetValidators,
  [AssetType.OtherPassiveIncome]: regularIncomeAssetValidators,

  // Financial Products - Insurance
  [AssetType.InsuranceProductsSum]: regularExpenseAssetValidators,
  [AssetType.InvestmentLifeInsurance]: insuranceWithFutureValueAssetValidators,
  [AssetType.RiskLifeInsurance]: regularInsuranceExpenseAssetValidators,
  [AssetType.CapitalLifeInsurance]: insuranceWithFutureValueAssetValidators,
  [AssetType.PropertyInsurance]: regularInsuranceExpenseAssetValidators,
  [AssetType.LiabilityInsurance]: regularInsuranceExpenseAssetValidators,
  [AssetType.VehicleInsurance]: regularInsuranceExpenseAssetValidators,
  [AssetType.TravelInsurance]: regularInsuranceExpenseAssetValidators,
  [AssetType.BusinessInsurance]: regularInsuranceExpenseAssetValidators,

  // Financial Products - Investment
  [AssetType.InvestmentProductsSum]: regularExpenseAssetValidators,
  [AssetType.UnitTrust]: investmentAssetValidators,
  [AssetType.RealEstateFund]: investmentAssetValidators,
  [AssetType.Commodity]: investmentAssetValidators,
  [AssetType.ExchangeTradedFunds]: savingsAccountAssetValidators,
  [AssetType.Certificates]: savingsAccountAssetValidators,
  [AssetType.CombinedDeposits]: savingsAccountAssetValidators,
  [AssetType.BuildingSavings]: investmentAssetValidators,
  [AssetType.SupplementaryPensionSavings]: investmentAssetValidators,
  [AssetType.PensionInsurance]: investmentAssetValidators,
  [AssetType.SavingsAccount]: savingsAccountAssetValidators,

  // Financial Products - Credit
  [AssetType.CreditProductsSum]: regularExpenseAssetValidators,
  [AssetType.Mortgage]: creditAssetValidators,
  [AssetType.BuildingSavingsLoan]: creditAssetValidators,
  [AssetType.Leasing]: creditAssetValidators,
  [AssetType.CreditCard]: creditAssetValidators,
  [AssetType.Overdraft]: creditAssetValidators,
  [AssetType.ConsumerLoan]: creditAssetValidators,
  [AssetType.OtherIndividualLoan]: creditAssetValidators,

  // Immovable Properties
  [AssetType.ImmovablePropertiesSum]: propertyAssetValidators,
  [AssetType.Flat]: propertyAssetValidators,
  [AssetType.House]: propertyAssetValidators,
  [AssetType.Household]: propertyAssetValidators,
  [AssetType.HolidayProperty]: propertyAssetValidators,
  [AssetType.ApartmentBuilding]: propertyAssetValidators,
  [AssetType.Garage]: propertyAssetValidators,
  [AssetType.Land]: propertyAssetValidators,
  [AssetType.OtherImmovableProperty]: propertyAssetValidators,

  // Movable Properties
  [AssetType.MovablePropertiesSum]: propertyAssetValidators,
  [AssetType.Vehicle]: propertyAssetValidators,
  [AssetType.Valuables]: propertyAssetValidators,
  [AssetType.SpecialValuables]: propertyAssetValidators,

  // Financial Properties
  [AssetType.FinancialPropertiesSum]: currentBalanceAssetValidators,
  [AssetType.CurrentAccount]: currentBalanceAssetValidators,
  [AssetType.CurrentAccountUsage]: null,
  [AssetType.TermDeposit]: currentBalanceAssetValidators,
  [AssetType.Bonds]: currentBalanceAssetValidators,
  [AssetType.QualifiedInvestorFunds]: currentBalanceAssetValidators,
  [AssetType.OtherFinancialProperty]: currentBalanceAssetValidators,

  // Properties Sums
  [AssetType.InvestmentPropertiesSum]: null, // asset is not used
  [AssetType.CreditPropertiesSum]: null, // asset is not used
  [AssetType.InsurancePropertiesSum]: propertyAssetValidators,

  // Virtual Assets
  [AssetType.VirtualMonthlyBalance]: null,
};
