<div class="form-container" *ngIf="asset">
  <kpt-related-asset-header
    *ngIf="showRelatedAssetHeader"
    [relatedPropertyUuid]="asset.relatedPropertyUuid"
  ></kpt-related-asset-header>

  <kpt-vehicle-requirements-common
    [asset]="asset"
    [disabled]="disabled"
  ></kpt-vehicle-requirements-common>
</div>
