<div class="accordion">
  <div class="accordion__header" [ngClass]="customClass ? customClass : ''">
    <h3 class="accordion__header-title" *ngIf="heading">
      {{ heading }}
    </h3>
    <ng-content select="[accordion-heading]"></ng-content>
    <span *ngIf="subheading" class="accordion__header-subtitle">
      {{ subheading }}
    </span>
    <ng-content select="[accordion-subheading]"></ng-content>
  </div>
  <div class="accordion__body">
    <ng-content></ng-content>
  </div>
</div>
