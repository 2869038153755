import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BorderGroupItemDirective} from './border-group-item.directive';
import {FlexItemDirective} from './flex-item.directive';
import {ColComponent} from './row/col.component';
import {RowComponent} from './row/row.component';

// prettier-ignore
const DECLARATIONS = [
  BorderGroupItemDirective,
  FlexItemDirective,
  ColComponent,
  RowComponent
];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
  imports: [CommonModule],
})
export class FlexboxModule {}
