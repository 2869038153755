<div class="info-row-wrapper">
  <div class="flex-grow-1 breadcrumbs">
    <ng-container *ngIf="showBreadcrumbs">
      <a [routerLink]="getConsultantLink()">Představení</a>
      <span *ngIf="familyUuid">
        <ng-container *ngIf="!pageName">&nbsp;/&nbsp;Karta klienta</ng-container>
        <ng-container *ngIf="pageName"
          >&nbsp;/&nbsp;<a [routerLink]="getClientLink()">Karta klienta</a></ng-container
        >
        <ng-container *ngIf="pageName">&nbsp;/&nbsp;{{ pageName }}</ng-container>
      </span>
    </ng-container>
  </div>
  <div>
    <div *ngIf="phoneNumber" class="contact">
      <span class="icon icon--phone2"></span>
      <a [href]="'tel:' + phoneNumber">{{ phoneNumber | phoneNumber }}</a>
    </div>
    <div *ngIf="email" class="contact">
      <span class="icon icon--email"></span>
      <a [href]="'mailto:' + email">{{ email }}</a>
    </div>
  </div>
</div>
