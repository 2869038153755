import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {PipesModule} from '@lib/pipes';
import {formatCZK} from '@lib/utils';
import {LifeInsurancePersonData} from '@shared/analysis/models/life-insurance-risks';
import {AccordionModule, getPensionByIncome} from '@shared/lib';
import {HelpModule, IOption} from '@shared/ui';
import {RadioGroupModule} from '@shared/ui/forms/radio-group/radio-group.module';
import {SwitchModule} from '@shared/ui/forms/switch';
import {AVERAGE_PENSION} from 'src/app/modules/life-insurance-old/constants';
import {LifeInsurancePerson} from 'src/app/modules/life-insurance-old/store/life-insurance.models';

@Component({
  standalone: true,
  selector: 'kpt-pension-ensurement-accordion',
  templateUrl: './pension-ensurement-accordion.component.html',
  styleUrls: ['./pension-ensurement-accordion.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    SwitchModule,
    PipesModule,
    HelpModule,
    RadioGroupModule,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PensionEnsurementAccordionComponent {
  @Input()
  person: LifeInsurancePerson;

  @Input()
  personData: LifeInsurancePersonData;

  @Input()
  readonly: boolean;

  averagePension = AVERAGE_PENSION;

  pensionEnsurementHelp = `Měsíční výdaje průměrného důchodce:
    <table class="mt-4 mx-auto">
      <tr><td class="text-left">Jídlo:</td><td class="text-right">${formatCZK(2_790)}</td></tr>
      <tr><td class="text-left">Bydlení:</td><td class="text-right">${formatCZK(347)}</td></tr>
      <tr><td class="text-left">Cíle a potřeby:</td><td class="text-right">${formatCZK(
        2_275,
      )}</td></tr>
      <tr><td class="text-left">Spoření a pojištění:</td><td class="text-right">${formatCZK(
        237,
      )}</td></tr>
      <tr><td class="text-left">Ostatní výdaje:</td><td class="text-right">${formatCZK(
        2_804,
      )}</td></tr>
      <tr><td class="text-left">Úvěry:</td><td class="text-right">${formatCZK(271)}</td></tr>
      <tr><th class="text-left pt-4">Celkem:</th><th class="text-right pt-4">${formatCZK(
        this.averagePension,
      )}</th></tr>
    </table>
  `;

  get pension() {
    return getPensionByIncome(this.person.income);
  }

  get ensurementTypes(): IOption[] {
    const income = formatCZK(this.person.income);
    const expenses = formatCZK(this.person.expenses);
    const average = formatCZK(this.averagePension);
    return [
      {key: 'income', label: `aby moje příjmy byly v důchodu stejné jako dnes (${income})`},
      {key: 'expense', label: `aby moje výdaje byly v důchodu pokryté jako dnes (${expenses})`},
      {
        key: 'average',
        label: `aby u mě byly v důchodu pokryty průměrné výdaje důchodce v ČR (${average})`,
      },
    ];
  }

  constructor() {}
}
