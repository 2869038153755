import {FamilyMember} from '@generated/defs/FamilyMember';
import {createSelector} from '@ngrx/store';
import {
  Asset,
  ContractState,
  financialProductAssets,
  RegularPaymentType,
} from '@shared/analysis/models/asset';
import {CommonFinancialProductAsset} from '@shared/analysis/models/financial-products';
import {selectAllAssets, selectCurrentAssets} from '@shared/analysis/store';
import {flatten, groupBy, pull, sortBy, sum} from 'lodash';
import {getContractName} from 'src/app/modules/financial-plan/financial-plan.utils';
import {selectClientOrAdvisorProposedAssets} from 'src/app/modules/financial-plan/store/client-or-advisor-proposed-assets.selectors';
import {convertToMonthly} from 'src/shared/analysis/asset.utils';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';

export interface PaymentCalendar {
  comparison: Comparison;
  paymentsByContractState: PaymentsByContractState[];
  paymentsByPerson: PaymentsByPerson[];
}

export interface Comparison {
  persons: ComparisonPerson[];
  totalCurrentPayment: number;
  totalProposedPayment: number;
}

export interface ComparisonPerson {
  familyMember: FamilyMember;
  contractsByContractState: ContractsByContractState[];
}

export interface ComparisonPersonContract {
  personUuid: string;
  name: string;
  contractState: ContractState;
  currentPayment: number;
  proposedPayment: number;
}

export interface ContractsByContractState {
  contractState: ContractState;
  contracts: ComparisonPersonContract[];
}

export interface PaymentsByContractState {
  contractState: ContractState;
  paymentDetails: PaymentDetail[];
}

export interface PaymentsByPerson {
  familyMember: FamilyMember;
  paymentsByContractState: PaymentsByContractState[];
  totalCurrentPayment: number;
  totalProposedPayment: number;
}

export interface PaymentDetail {
  personUuid: string;
  assetUuid: string;
  name: string;
  contractState: ContractState;
  payment: number;
  frequency: RegularPaymentType;
  paymentDay: number; // relates to frequency
  paymentStart: string;
  paymentEnd: string;
  contractNumber: string;
  start: string;
  end: string;
  bankAccountNumber: string;
  variableSymbol: string;
  paymentNote: string;
  category: string;
}

interface ContractPair {
  currentAsset: CommonFinancialProductAsset;
  proposedAsset: CommonFinancialProductAsset;
  contractState: ContractState;
}

const contractStateOrder: Record<ContractState, number> = {
  [ContractState.New]: 1,
  [ContractState.Updated]: 2,
  [ContractState.Terminated]: 3,
  [ContractState.Unchanged]: 4,
};

export const selectContractPairs = createSelector(
  selectCurrentAssets,
  selectClientOrAdvisorProposedAssets,
  selectAllAssets,
  (currentAssets, proposedAssets, allAssets): ContractPair[] => {
    const contractTypes = financialProductAssets;
    const currentContractAssets = currentAssets.filter(asset =>
      contractTypes.includes(asset.type),
    ) as CommonFinancialProductAsset[];
    const proposedContractAssets = proposedAssets.filter(asset =>
      contractTypes.includes(asset.type),
    ) as CommonFinancialProductAsset[];

    const contractPairs: ContractPair[] = proposedContractAssets.map(proposedAsset => {
      // unchanged
      let currentAsset = currentContractAssets.find(a => a.assetUuid === proposedAsset.assetUuid);
      if (currentAsset) {
        pull(currentContractAssets, currentAsset);
        return {currentAsset, proposedAsset, contractState: ContractState.Unchanged};
      }

      // updated
      currentAsset = currentContractAssets.find(a => {
        const advisorUpdatedAsset = allAssets.find(
          a2 => a2.assetUuid === proposedAsset.originalAssetUuid,
        );
        return (
          a.assetUuid === proposedAsset.originalAssetUuid ||
          a.assetUuid === advisorUpdatedAsset?.originalAssetUuid
        );
      });
      if (currentAsset) {
        pull(currentContractAssets, currentAsset);
        return {currentAsset, proposedAsset, contractState: ContractState.Updated};
      } else {
        // new
        return {currentAsset: null, proposedAsset, contractState: ContractState.New};
      }
    });

    //terminated
    const terminatedAssets = currentContractAssets.map(
      (currentAsset): ContractPair => ({
        currentAsset,
        proposedAsset: null,
        contractState: ContractState.Terminated,
      }),
    );
    contractPairs.push(...terminatedAssets);

    return contractPairs;
  },
);

export const selectPaymentCalendar = createSelector(
  selectContractPairs,
  getFamilyMembers,
  (contractPairs, familyMembers): PaymentCalendar => {
    const comparison = getComparison(contractPairs, familyMembers);

    const paymentDetails = getPaymentDetails(contractPairs, familyMembers);
    const paymentsByContractState = groupPaymentsByContractState(paymentDetails);
    const paymentsByPerson = groupPaymentsByPerson(paymentDetails, comparison, familyMembers);

    return {
      comparison,
      paymentsByContractState,
      paymentsByPerson,
    };
  },
);

function getComparison(contractPairs: ContractPair[], familyMembers: FamilyMember[]): Comparison {
  let contracts: ComparisonPersonContract[] = contractPairs.map(
    ({currentAsset, proposedAsset, contractState}) => {
      const currentPayment = currentAsset
        ? convertToMonthly(currentAsset.value, currentAsset.frequency)
        : null;
      const proposedPayment = proposedAsset
        ? convertToMonthly(proposedAsset.value, proposedAsset.frequency)
        : null;
      const assetType = currentAsset ? currentAsset.type : proposedAsset.type;

      let personUuid = proposedAsset ? proposedAsset.stakeholderUuid : currentAsset.stakeholderUuid;
      if (!personUuid || !familyMembers.find(member => member.sugarUuid === personUuid)) {
        personUuid = familyMembers.find(member => member.familyHead).sugarUuid;
      }

      return {
        personUuid,
        name: getContractName(currentAsset as Asset, proposedAsset as Asset, assetType),
        contractState,
        currentPayment,
        proposedPayment,
      };
    },
  );

  contracts = sortBy(
    contracts,
    contract => contractStateOrder[contract.contractState] + contract.name,
  );

  const groupedContracts = groupBy(contracts, contract => contract.personUuid);
  const persons: ComparisonPerson[] = familyMembers.map(familyMember => ({
    familyMember,
    contractsByContractState:
      groupContractsByContractState(groupedContracts[familyMember.sugarUuid]) || [],
  }));

  const totalCurrentPayment = sum(contracts.map(contract => contract.currentPayment || 0));
  const totalProposedPayment = sum(contracts.map(contract => contract.proposedPayment || 0));

  return {
    persons,
    totalCurrentPayment,
    totalProposedPayment,
  };
}

function getPaymentDetails(
  contractPairs: ContractPair[],
  familyMembers: FamilyMember[],
): PaymentDetail[] {
  const paymentDetails = contractPairs.map((pair): PaymentDetail => {
    const asset = pair.proposedAsset ?? pair.currentAsset;

    let personUuid = asset.stakeholderUuid;
    if (!familyMembers.find(member => member.sugarUuid === personUuid)) {
      personUuid = familyMembers.find(member => member.familyHead).sugarUuid;
    }

    return {
      personUuid,
      assetUuid: asset.assetUuid,
      name: getContractName(pair.currentAsset as Asset, pair.proposedAsset as Asset, asset.type),
      contractState: pair.contractState,
      category: 'TODO',
      start: asset.start,
      end: asset.end as string,
      payment: asset.value,
      frequency: asset.frequency,
      contractNumber: asset.contractNumber,
      paymentNote: asset.paymentNote,
      bankAccountNumber: asset.bankAccountNumber,
      paymentDay: asset.paymentDay,
      paymentStart: asset.paymentStart,
      paymentEnd: asset.paymentEnd,
      variableSymbol: asset.variableSymbol,
    };
  });

  return sortBy(paymentDetails, detail => contractStateOrder[detail.contractState] + detail.name);
}

function groupContractsByContractState(
  contractsToGroup: ComparisonPersonContract[],
): ContractsByContractState[] {
  return Object.entries(groupBy(contractsToGroup, item => item.contractState)).map(
    ([contractState, contracts]: [ContractState, ComparisonPersonContract[]]) => ({
      contractState,
      contracts,
    }),
  );
}

function groupPaymentsByContractState(payments: PaymentDetail[]): PaymentsByContractState[] {
  return Object.entries(groupBy(payments, item => item.contractState)).map(
    ([contractState, paymentDetails]: [ContractState, PaymentDetail[]]) => ({
      contractState,
      paymentDetails,
    }),
  );
}

function groupPaymentsByPerson(
  payments: PaymentDetail[],
  comparison: Comparison,
  familyMembers: FamilyMember[],
): PaymentsByPerson[] {
  return Object.entries(groupBy(payments, item => item.personUuid))
    .map(([personUuid, paymentDetails]: [string, PaymentDetail[]]): PaymentsByPerson => {
      const {currentPayment, proposedPayment} = computePaymentsForPerson(personUuid, comparison);
      const familyMember = familyMembers.find(member => member.sugarUuid === personUuid);
      return {
        familyMember,
        paymentsByContractState: groupPaymentsByContractState(paymentDetails),
        totalCurrentPayment: currentPayment,
        totalProposedPayment: proposedPayment,
      };
    })
    .sort((a, b) => familyMembers.indexOf(a.familyMember) - familyMembers.indexOf(b.familyMember));
}

function computePaymentsForPerson(
  personUuid: string,
  comparison: Comparison,
): {currentPayment: number; proposedPayment: number} {
  const person = comparison.persons.find(p => p.familyMember.sugarUuid === personUuid);
  if (!person) return {currentPayment: 0, proposedPayment: 0};

  const contracts = flatten(Object.values(person.contractsByContractState).map(c => c.contracts));
  const currentPayment = sum(contracts.map(contract => contract.currentPayment || 0));
  const proposedPayment = sum(contracts.map(contract => contract.proposedPayment || 0));

  return {currentPayment, proposedPayment};
}
