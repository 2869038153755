<div class="card-wrapper previous-plan-card-wrapper">
  <a *ngIf="finalizedBusinessCases.length > 3" (click)="scrollToFourthPlan()" class="other-plans"
    >ostatní Finanční plány</a
  >
  <a
    *ngFor="let businessCase of finalizedBusinessCases"
    class="card previous-plan-card"
    (click)="openDetailModal(businessCase)"
  >
    <span class="card__holder">
      <img src="/assets/images/homepage/plan-finished.svg" />
      <div class="card__header">
        <span class="card__label">
          <div>
            {{ getYearLabel(businessCase) }}
          </div>
        </span>
        <div class="plan-finished-date">
          <div class="text-left">{{ getTimeAgo(businessCase) }}</div>
          <div class="text-left">({{ businessCase.timestamp | date }})</div>
        </div>
      </div>
    </span>
  </a>
</div>
