import {FamilyMember} from '@generated/defs/FamilyMember';

export interface InsurablePerson {
  sugarUuid: string;
  name: string;
}

export enum InsuranceType {
  Risk = 'risk',
  Investment = 'investment',
}

export type StakeholderField = keyof (FamilyMember & {
  usPerson: boolean;
});
