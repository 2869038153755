import {Injectable} from '@angular/core';
import {RiskAdditionalData} from '@generated/defs/RiskAdditionalData';
import {Start as CreateOrUpdateRisksAdditionalData} from '@generated/store/analysis/createUpdateRisks/states/actions';
import {Start as GetRisksAdditionalData} from '@generated/store/analysis/getRisks/states/actions';
import {Store} from '@ngrx/store';
import {State} from 'src/store';

@Injectable()
export class RisksSelectionDataService {
  constructor(private store: Store<State>) {}

  getRisksAdditionalData(familyId: string) {
    this.store.dispatch(new GetRisksAdditionalData({family_uuid: familyId}));
  }
  saveRisksAdditionalData(familyId: string, additionalData: RiskAdditionalData) {
    this.store.dispatch(
      new CreateOrUpdateRisksAdditionalData({family_uuid: familyId, data: additionalData}),
    );
  }
}
