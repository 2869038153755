export const horizonsI18n: Record<Horizon, string> = {
  'five-years': '5 let',
  'ten-years': '10 let',
  'fifteen-years': '15 let',
  'twenty-years': '20 let',
  'twenty-five-years': '25 let',
};

export const riskProfileI18n: Record<RiskProfile, string> = {
  conservative: 'Konzervativní profil (SRI max. 2)',
  balanced: 'Vyvážený profil (SRI max. 4)',
  dynamic: 'Dynamický profil (SRI max. 7)',
};

export const knowledgeExperience = (
  analysisResult: boolean,
): {key: KnowledgeExperience; range: (points: number) => boolean}[] => {
  return [
    {
      key: 'a',
      range: (points: number) => points <= -100,
    },
    {
      key: 'b',
      range: (points: number) =>
        analysisResult ? points >= -8 && points <= 2 : points >= -4 && points <= 5,
    },
    {
      key: 'c',
      range: (points: number) =>
        analysisResult ? points >= 3 && points <= 16 : points >= 6 && points <= 14,
    },
    {
      key: 'd',
      range: (points: number) => (analysisResult ? points >= 17 : points >= 15),
    },
  ];
};

export const knowledgeExperienceI18n: Record<KnowledgeExperience, string> = {
  a: 'Klient neakceptující ztrátu',
  b: 'Základní investor',
  c: 'Informovaný investor',
  d: 'Pokročilý investor',
};

export const investmentStrategyI18n: Record<InvestmentStrategy, string> = {
  'guaranteed-fund': 'Lze sjednat pouze garantovaný fond',
  conservative: 'Konzervativní strategie',
  balanced: 'Vyvážená strategie',
  dynamic: 'Dynamická strategie',
};

export type KnowledgeExperience = 'a' | 'b' | 'c' | 'd';
export type Horizon =
  | 'five-years'
  | 'ten-years'
  | 'fifteen-years'
  | 'twenty-years'
  | 'twenty-five-years';
export type RiskProfile = 'conservative' | 'balanced' | 'dynamic';
export type InvestmentStrategy = 'guaranteed-fund' | 'conservative' | 'balanced' | 'dynamic';

export interface PensionInvestmentSurveyResult {
  knowledgeExperience: string;
  horizon: string;
  riskProfile: string;
  strategy: string;
}
