import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {CreatePortalClient} from '@generated/defs/CreatePortalClient';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {markFormGroupTouched} from '@shared/utils/form/mark-form-group-as-touched.fnc';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SmartformService} from 'src/app/services/smartform.service';
import {DialogService} from 'src/shared/services/modal.service';
import {State} from 'src/store';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {FamilyMemberDefinition, FamilyMemberDefinitionsService} from './family-member.definitions';

interface FamilyMemberDetail extends FamilyMember {
  originalFamilyHead: boolean;
}

@UntilDestroy()
@Component({
  selector: 'kpt-member-detail-formly',
  templateUrl: './family-member-detail.component.html',
})
export class FamilyMemberDetailComponent implements OnInit, OnDestroy {
  data$: Observable<FamilyMemberDefinition>;
  form = new UntypedFormGroup({});
  showDeleteButton = false;

  sugarUuid: string = null;
  createClient: CreatePortalClient = null;

  constructor(
    public modalService: DialogService,
    private store: Store<State>,
    private smartformService: SmartformService,
    private familyMemberDefinitionsService: FamilyMemberDefinitionsService,
  ) {
    this.smartformService.insertSmartformScript();
  }

  ngOnInit() {
    this.data$ = this.store.pipe(
      select(getFamilyMembers),
      map(members => {
        const member = members.find(value => value.sugarUuid === this.sugarUuid);
        const definition =
          members.filter(m => m.type === 'ADULT').length > 1 && !member
            ? this.familyMemberDefinitionsService.getChildDefinition()
            : this.familyMemberDefinitionsService.getFamilyMemberDefinition();
        return {
          ...definition,
          model: member
            ? {...member, originalFamilyHead: member.familyHead}
            : ({...this.createClient, familyHead: false} as FamilyMemberDetail),
          showRemoveButton: member && !member.familyHead && this.showDeleteButton,
        };
      }),
      tap(definition => this.initSmartformInstance(definition)),
    );
  }

  save() {
    markFormGroupTouched(this.form);

    if (this.form.valid) {
      this.modalService.close({model: this.form.getRawValue(), type: 'save'});
    }
  }

  remove() {
    this.form.markAsUntouched();
    this.modalService.close({type: 'remove'});
  }

  dismiss() {
    this.form.markAsUntouched();
    this.modalService.dismiss(void 0);
  }

  ngOnDestroy(): void {}

  private initSmartformInstance(definition: FamilyMemberDefinition) {
    if (definition.smartformInstance) {
      this.smartformService
        .scriptReady()
        .pipe(untilDestroyed(this))
        .subscribe(_ => {
          this.smartformService.initSmartformInstance(this.form, definition.smartformInstance);
        });
    }
  }
}
