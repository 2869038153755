import {PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import csLocale from '@angular/common/locales/cs';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {SharedService} from '@generated/controllers/Shared';
import {BuildingModule} from '@generated/store/building/building/building.module';
import {ConsumerModule} from '@generated/store/consumer/consumer/consumer.module';
import {GetAdvisorModule} from '@generated/store/integrations/getAdvisor/getAdvisor.module';
import {CommonEnvironment, environmentToken} from '@lib/models';
import {GtmService, SentryErrorHandler, VersionCheckerService} from '@lib/services';
import {SentryInterceptorModule} from '@lib/utils';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NgxPiwikProModule, NgxPiwikProRouterModule} from '@piwikpro/ngx-piwik-pro';
import {FinancialAnalysisSharedModule} from '@shared/analysis/financial-analysis-shared.module';
import {BusinessToolModule} from '@shared/lib/components/modal/modals/business-tool-modal/business-tool.module';
import {PipesModule} from '@shared/pipes';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {PrintInterceptor} from 'src/app/services/print-interceptor.service';
import {SmartformService} from 'src/app/services/smartform.service';
import {environment} from '../environments/environment';
import {effects, metaReducers, reducers} from '../store';
import {AppComponent} from './app.component';
import {AppRoutingModule, CustomSerializer} from './app.routing';
import {ApiInterceptor} from './services/api-interceptor.service';

registerLocaleData(csLocale);

export const sentryErrorHandlerFactory = (injectionToken: CommonEnvironment) => {
  return environment.sentryDataSourceName
    ? new SentryErrorHandler(injectionToken)
    : new ErrorHandler();
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25}) : [],
    AngularSvgIconModule.forRoot(),
    BuildingModule,
    ConsumerModule,
    PipesModule,
    RouterModule,
    GetAdvisorModule,
    SentryInterceptorModule.forRoot(),
    FinancialAnalysisSharedModule,
    BrowserModule,
    BusinessToolModule,
    NgxPiwikProModule.forRoot(environment.piwikContainerId, environment.piwikContainerUrl),
    NgxPiwikProRouterModule.forRoot({exclude: [/.*\/auth\/.*/]}),
  ],
  providers: [
    IntegrationsService,
    PathLocationStrategy,
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: PrintInterceptor, multi: true},
    {provide: RouterStateSerializer, useClass: CustomSerializer},
    {provide: LOCALE_ID, useValue: 'cs'},
    {provide: 'windowObject', useValue: window},
    {provide: environmentToken, useValue: environment},
    GtmService,
    VersionCheckerService,
    {provide: 'authSettings', useValue: environment},
    {provide: 'gtmId', useValue: environment.gtmId},
    SmartformService,
    SharedService,
    {provide: ErrorHandler, useFactory: sentryErrorHandlerFactory, deps: [environmentToken]},
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
