// eslint-disable max-len
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GetClientModule} from '@generated/store/integrations/getClient/getClient.module';
import {GetPortalFamilyMembersModule} from '@generated/store/integrations/getPortalFamilyMembers/getPortalFamilyMembers.module';
import {AdvisorUiSettingsModule} from '@shared/advisor-ui-settings/advisor-ui-settings.module';
import {BusinessCaseModule} from '@shared/business-case/business-case.module';
import {ContentBoxModule} from '@shared/lib';
import {CardBoardActionModule} from '@shared/lib/components/card-board-action/card-board-action.module';
import {PipesModule} from '@shared/pipes';
import {ButtonModule, CardComponent, IntroJsModule, ModalModule, TabsModule} from '@shared/ui';
import {CardBoard2Component} from 'src/app/modules/client/card-board/card-board2.component';
import {ERecordModalComponent} from 'src/app/modules/client/card-board/e-record-modal/e-record-modal.component';
import {SummaryModule} from 'src/app/modules/client/summary/summary.module';
import {CanLoadInvestment} from 'src/app/modules/investment-old/services/investment.can-activate';
import {CanLoadLifeInsurance} from 'src/app/modules/life-insurance-old/services/life-insurance.can-activate';
import {CardBoard1Component} from './card-board/card-board1.component';
import {NewPlanModalComponent} from './card-board/new-plan-modal/new-plan-modal.component';
import {PreviousPlanDetailModalComponent} from './card-board/previous-plans-card/previous-plan-detail-modal/previous-plan-detail-modal.component';
import {PreviousPlansCardComponent} from './card-board/previous-plans-card/previous-plans-card.component';
import {ClientContainerComponent} from './client-container.component';
import {ClientContainerRoutingModule} from './client-container.routing';
import {ClientPageComponent} from './client-page.component';
import {GuardModule} from './guard/guard.module';
import {AssetDetailCreator} from './pages/asset-detail/asset-detail-creator.service';
import {AssetDetailProxyComponent} from './pages/asset-detail/asset-detail-proxy.component';
import {AssetDetailModule} from './pages/asset-detail/asset-detail.module';
import {ClientResolve} from './services/client.resolve';
import {GuardService} from './services/guard.service';
// eslint-enable max-len

@NgModule({
  declarations: [
    ClientContainerComponent,
    ClientPageComponent,
    AssetDetailProxyComponent,
    CardBoard1Component,
    CardBoard2Component,
    ERecordModalComponent,
    NewPlanModalComponent,
    PreviousPlansCardComponent,
    PreviousPlanDetailModalComponent,
  ],
  exports: [ClientContainerComponent, ClientPageComponent, AssetDetailProxyComponent],
  imports: [
    ClientContainerRoutingModule,
    CommonModule,
    PipesModule,
    TabsModule,
    GetClientModule,
    GetPortalFamilyMembersModule,
    GuardModule,
    AssetDetailModule,
    SummaryModule,
    ContentBoxModule,
    IntroJsModule,
    AdvisorUiSettingsModule,
    BusinessCaseModule,
    ModalModule,
    ButtonModule,
    CardBoardActionModule,
    CardComponent,
  ],
  providers: [
    ClientResolve,
    CanLoadLifeInsurance,
    CanLoadInvestment,
    GuardService,
    AssetDetailCreator,
  ],
})
export class ClientContainerModule {}
