import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

enum LongTermCostCoverageEnum {
  sufficient = 'Dostatečné',
  insufficient = 'Nedostatečné',
}

export interface SurveyParticipant {
  sugarUuid: string;
  name: string;
  lastName?: string;
  longTermCostCoverage: LongTermCostCoverageEnum;
  participate?: boolean;
  disabled: boolean;
  familyHead?: boolean;
}

@Component({
  selector: 'kpt-survey-participants',
  templateUrl: './survey-participants.component.html',
  styleUrls: ['./survey-participants.component.scss'],
})
export class SurveyParticipantsComponent implements OnInit {
  @Input()
  participants: SurveyParticipant[] = [];

  @Input()
  participantsForm: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}
}
