import {Injectable} from '@angular/core';
import {MessageType, NotificationMessage} from '@lib/models';
import {
  connectionEstablished,
  connectionInterrupted,
  connectionTimeout,
  genericValidationLocalization,
} from '@shared/models/message-localization.models';
import {pull} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  messages: NotificationMessage[] = [];

  connectionInfo: NotificationMessage;

  private skippedMessages: string[] = [];

  dispatchConnectionChange(online: boolean) {
    online ? this.dispatchOnlineMessage() : this.dispatchOfflineMessage();
    this.messages = [];
  }

  dispatchError(message: string, duration = 4_000) {
    this.checkAndDispatch({message, type: MessageType.ERROR, duration});
  }

  dispatchWarning(message: string, duration = 4_000) {
    this.checkAndDispatch({message, type: MessageType.WARNING, duration});
  }

  dispatchNewVersion(message: string) {
    this.checkAndDispatch({message, type: MessageType.NEW_VERSION});
  }

  dispatchTimeout() {
    this.checkAndDispatch({message: connectionTimeout, type: MessageType.TIMEOUT});
  }

  dispatchMessage(message: string, duration = 4_000, type: MessageType = MessageType.INFO) {
    this.checkAndDispatch({message, type, duration});
  }

  dispatchInfo(message: string, duration = 4_000) {
    this.checkAndDispatch({message, type: MessageType.INFO, duration});
  }

  dispatchSuccess(message: string, duration = 4_000) {
    this.checkAndDispatch({message, type: MessageType.INFO, duration});
  }

  skipGenericValidationMessage() {
    this.skippedMessages.push(genericValidationLocalization);
  }

  unskipGenericValidationMessage() {
    pull(this.skippedMessages, genericValidationLocalization);
  }

  private dispatchOnlineMessage() {
    this.connectionInfo = {
      message: connectionEstablished,
      type: MessageType.ONLINE,
      duration: 4_000,
    };
  }

  private dispatchOfflineMessage() {
    this.connectionInfo = {
      message: connectionInterrupted,
      type: MessageType.OFFLINE,
    };
  }

  private checkAndDispatch(message: NotificationMessage) {
    if (
      this.isDifferentFromLast(message) &&
      (!this.connectionInfo || this.connectionInfo.type !== MessageType.OFFLINE) &&
      !this.skippedMessages.includes(message.message)
    ) {
      this.messages.push(message);
    }
  }

  private isDifferentFromLast(message: NotificationMessage) {
    if (this.messages.length === 0) {
      return true;
    }
    return message.message !== this.messages[this.messages.length - 1].message;
  }
}
