import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LifeInsuranceDataService} from '@shared/life-insurance/life-insurance-data.service';
import {UIFile, StoreFileUpdate} from '@shared/models/file.models';

import {FileItem} from 'ng2-file-upload';

export enum Status {
  Uploading,
  Canceled,
  Ready,
  Edit,
}

@Component({
  selector: 'kpt-attached-file',
  templateUrl: './attached-file.component.html',
  styleUrls: ['./attached-file.component.scss'],
})
export class AttachedFileComponent implements OnInit {
  @Input() file: UIFile;
  @Input() shortcut: boolean;
  @Output() update = new EventEmitter<StoreFileUpdate>();
  @Output() removeFromQueue = new EventEmitter<FileItem>();
  @Output() showDeleteConfirmation = new EventEmitter<UIFile>();

  status: Status;
  statuses = Status;

  constructor(private lifeInsuranceData: LifeInsuranceDataService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.file.item) {
      this.status = Status.Ready;
      return;
    }
    const {item} = this.file;
    item.onError = () => {
      this.status = Status.Canceled;
      this.cd.markForCheck();
    };
    if (item.isCancel || item.isError) {
      this.status = Status.Canceled;
      return;
    }
    if (item.isUploading) {
      this.status = Status.Uploading;
    }
  }

  onCancelUpload() {
    this.file.item.cancel();
    this.status = Status.Canceled;
  }

  onRemoveFromQueue() {
    this.removeFromQueue.emit(this.file.item);
  }

  onRepeatUpload() {
    this.file.item.upload();
    this.status = Status.Uploading;
  }

  toggleEditName() {
    this.status = this.status === Status.Ready ? Status.Edit : Status.Ready;
  }

  onSaveNewName(element: HTMLInputElement) {
    this.file.name = element.value;
    this.status = Status.Ready;
    this.update.emit({dmsUuid: this.file.dmsUuid, name: element.value});
  }

  onRemove() {
    this.showDeleteConfirmation.emit(this.file);
  }

  onPreview() {
    this.lifeInsuranceData.getFile(this.file.dmsUuid).subscribe(previewFileBlob => {
      if (this.file.mimeType === 'application/octet-stream') {
        this.openOrSaveFile(previewFileBlob);
      } else {
        const fileUrl = window.URL.createObjectURL(previewFileBlob);
        window.open(fileUrl);
      }
    });
  }

  private openOrSaveFile(blob: Blob) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.file.name;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}
