export enum FieldTypes {
  'FILE' = 'FILE',
  'SLIDER' = 'SLIDER',
  'INPUT' = 'INPUT',
  'LABEL' = 'LABEL',
  'DATEPICKER' = 'DATEPICKER',
  'IMAGECROPPER' = 'IMAGECROPPER',
  'NUMBER' = 'NUMBER',
  'NSELECT' = 'NSELECT', // old kpt-form-field implementation using native select element
  'SELECT' = 'SELECT',
  'RADIOGROUP' = 'RADIOGROUP',
  'TEXTAREA' = 'TEXTAREA',
  'SWITCH' = 'SWITCH',
  'TOGGLE' = 'TOGGLE',
  'CHECKBOX' = 'CHECKBOX',
  'PHONE' = 'PHONE',
  'CONTENT' = 'CONTENT',
}
