import {Injectable} from '@angular/core';
import {Answers, emptyAnswers} from '@shared/investment-survey-old/models/answer';
import {QuestionCategory, QuestionType} from '@shared/investment-survey-old/models/question';
import {SurveyAnswers} from '@shared/investment-survey-old/models/survey';
import {Questions} from '@shared/investment-survey-old/questions.definitions';
import {isFunction} from 'lodash';

@Injectable()
export class InvestmentSurveyUtilsService {
  constructor(private questions: Questions) {}

  answersValid(
    surveyAnswers: SurveyAnswers[],
    sugarUuid: string,
    questionCategories: QuestionCategory[],
  ): boolean {
    const answers = surveyAnswers.find(a => a.sugarUuid === sugarUuid);
    if (!answers) return false;

    let valid = true;
    const surveyData = {...emptyAnswers(), ...answers.answers};
    questionCategories.forEach(category => {
      category.questions
        .filter(question => (isFunction(question.hidden) ? !question.hidden(surveyAnswers) : true))
        .forEach(question => {
          if (question.type === QuestionType.CHECKBOX) {
            if (!this.isCheckboxGroupSet(surveyData[question.id], question.id)) valid = false;
          } else if (question.type === QuestionType.TABLE) {
            Object.values(surveyData[question.id]).forEach((row: {[key: string]: boolean}) => {
              if (!Object.values(row).some(Boolean)) valid = false;
            });
          } else if (question.type === QuestionType.SLIDER) {
            const sliderValue = Number(surveyData[question.id].answer);
            if (question.sliderOptions.minLimit && sliderValue < question.sliderOptions.minLimit) {
              valid = false;
            }
            if (question.sliderOptions.maxLimit && sliderValue > question.sliderOptions.minLimit) {
              valid = false;
            }
          } else {
            if (!question.answers.some(a => surveyData[question.id].answer === a.id)) valid = false;
          }
        });
    });

    return valid;
  }

  isCheckboxGroupSet(checkboxGroup: Answers['key'], questionId: string): boolean {
    const question = this.questions.questionByQuestionId(questionId);
    return Object.entries(checkboxGroup)
      .filter(([answerId]) => question.answers.find(answer => answer.id === answerId))
      .map(([_, answerValue]) => answerValue)
      .some(Boolean);
  }
}
