import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AddAssetButtonComponent} from '@shared/lib/components/add-asset-button/add-asset-button.component';
import {AssetLinkModule} from '@shared/lib/components/asset-link/asset-link.module';
import {ButtonModule} from '@shared/ui';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule, AssetLinkModule],
  declarations: [AddAssetButtonComponent],
  exports: [AddAssetButtonComponent],
})
export class AddAssetButtonModule {}
