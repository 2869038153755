import {Choice, LifeInsuranceFile, PriorityQuestionnaireSerializerAnswer} from '@generated/model';
import {getGetPriorityQuestionnaireStateSelector} from '@generated/store/lifeinsurance/getPriorityQuestionnaire/states/reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {selectCurrentAssets} from '@shared/analysis/store/index';
import {reject} from 'lodash';
import {
  calculateRisk,
  computeRecommendedInsuranceAmount,
} from 'src/app/modules/life-insurance-old/life-insurance.utils';
import {
  LifeInsuranceCustomRiskDefinition,
  LifeInsuranceForm,
  LifeInsurancePerson,
  LifeInsuranceRisk,
} from 'src/app/modules/life-insurance-old/store/life-insurance.models';
import {getRiskDefinition, orderedRiskIds} from 'src/app/services/risk-definitions';
import {RiskId} from 'src/store/models/risk.models';
import {getFamilyMembers} from 'src/store/selectors/family-member.selectors';
import {notLoadingNorError} from 'src/store/store-helpers';
import {LifeInsurancePriorityProducts} from './life-insurance.models';
import * as fromLifeInsurance from './life-insurance.state';

export interface LifeInsuranceForBE {
  persons: LifeInsurancePerson[];
  forms: LifeInsuranceForm[];
  risks: LifeInsuranceRisk[];
  choice: Choice;
  files: LifeInsuranceFile[];
  definitions: LifeInsuranceCustomRiskDefinition[];
}

export const storeFeatureName = 'lifeInsurance';

export const selectState =
  createFeatureSelector<fromLifeInsurance.LifeInsuranceState>(storeFeatureName);

export const selectPersons = createSelector(
  selectState,
  getFamilyMembers,
  (state, familyMembers) => {
    return state.persons.map(p => {
      const familyMember = familyMembers.find(fm => fm.sugarUuid === p.id);
      return {
        ...p,
        lastName: familyMember?.lastName,
        familyHead: familyMember ? familyMember.familyHead : false,
      };
    });
  },
);

export const selectOrderedPersons = createSelector(selectPersons, persons =>
  [...persons].sort((p1, p2) => (p1.order < p2.order ? -1 : 1)),
);

export const selectAdultPersons = createSelector(selectOrderedPersons, persons =>
  persons.filter(p => !p.child),
);

export const selectAllRisks = createSelector(selectState, state => state.risks);

export const selectRisks = createSelector(
  selectAllRisks,
  selectPersons,
  selectCurrentAssets,
  (allRisks, persons, assets): LifeInsuranceRisk[] =>
    reject(allRisks, risk => {
      const person = persons.find(p => p.id === risk.personId);
      if (!person) return true;
      if (!person.individualSelection) return false;
      const riskDef = getRiskDefinition(risk.riskId, risk.provision);
      const calculatedRisk = riskDef
        ? calculateRisk(riskDef, risk.provision, person, persons, assets)
        : 0;
      return risk.myChoice && riskDef && calculatedRisk === 0;
    }),
);

export const selectCustomRiskDefinitions = createSelector(
  selectState,
  state => state.customRiskDefinitions,
);

export const selectRisksOfMyChoice = createSelector(selectRisks, risks =>
  risks.filter(risk => risk.myChoice),
);

export const selectEnabledRisksOfMyChoice = createSelector(
  selectRisksOfMyChoice,
  selectPersons,
  selectCurrentAssets,
  (risks, persons, assets) =>
    reject(risks, risk => {
      const person = persons.find(p => p.id === risk.personId);
      const riskDef = getRiskDefinition(risk.riskId, risk.provision);
      const calculatedRisk = riskDef
        ? calculateRisk(riskDef, risk.provision, person, persons, assets)
        : 0;
      return risk.myChoice && riskDef && calculatedRisk === 0;
    }).sort((r1, r2) =>
      orderedRiskIds.indexOf(r1.riskId as RiskId) < orderedRiskIds.indexOf(r2.riskId as RiskId)
        ? -1
        : 1,
    ),
);

export const selectForms = createSelector(selectState, state => state.forms);

export const selectStakeholderIds = createSelector(selectForms, forms =>
  forms.map(form => form.stakeholderId).filter(stakeholderId => stakeholderId !== null),
);

export const selectProductProposedPersonIds = createSelector(selectForms, forms =>
  forms.filter(form => form.stakeholderId !== null).map(form => form.personId),
);

export const selectChoice = createSelector(selectState, state => state.choice);

export const selectFiles = createSelector(selectState, state => state.files);

export const selectGeneralFilesOfPerson = createSelector(
  selectFiles,
  (files: LifeInsuranceFile[], personId: string) =>
    files.filter(file => file.general === true && file.personId === personId),
);

export const selectRecommendedInsuranceAmount = createSelector(selectOrderedPersons, persons =>
  computeRecommendedInsuranceAmount(persons),
);

export const selectLifeInsuranceForBE = createSelector(
  selectPersons,
  selectForms,
  selectAllRisks,
  selectChoice,
  selectFiles,
  selectCustomRiskDefinitions,
  (persons, forms, risks, choice, files, definitions) =>
    ({persons, forms, risks, choice, files, definitions} as LifeInsuranceForBE),
);

export const selectUI = createSelector(selectState, state => state.ui);

export const selectInsuranceCalculations = createSelector(
  selectUI,
  state => state.showInsuranceCalculations,
);

export const selectFinancialAnalysisDataChanged = createSelector(
  selectUI,
  state => state.financialAnalysisChanged,
);

export const selectAdditionalAnalysisData = createSelector(
  selectState,
  state => state.additionalAnalysisData,
);

export const selectInvestmentSurveyHiddenState = createSelector(selectPersons, persons => {
  const hiddenState: {[key: string]: boolean} = {};
  persons
    .filter(person => !person.child)
    .forEach(adult => {
      hiddenState[adult.id] = isFinancialStateBad(adult) || !adult.createReserves;
    });
  return hiddenState;
});

export const selectDisabledReserves = createSelector(selectPersons, persons => {
  const disabledReserves: {[key: string]: boolean} = {};
  persons
    .filter(person => !person.child)
    .forEach(adult => {
      disabledReserves[adult.id] = isFinancialStateBad(adult);
    });
  return disabledReserves;
});

export function isFinancialStateBad(person: LifeInsurancePerson) {
  const incomeThreshold = 500;
  const negativeOutlook = person.financialOutlook === 'negative';
  const noIncome = !person.income && !person.incomeContractor && !person.passiveIncome;
  const lowDifference =
    person.expenses !== undefined &&
    person.expenses !== 0 &&
    person.passiveIncome + person.incomeContractor + person.income - person.expenses <
      incomeThreshold;

  return negativeOutlook || noIncome || lowDifference;
}
export const selectSummaries = createSelector(selectState, state => state.summaries);

export const selectUnfinishedParticipantIds = createSelector(
  selectState,
  state => state.ui.summariesProgress.unfinishedParticipantIds,
);

export const selectSelectedParticipantId = createSelector(
  selectState,
  state => state.ui.summariesProgress.selectedParticipantId,
);

export const selectPriorityProducts = createSelector(
  getGetPriorityQuestionnaireStateSelector,
  priorityQuestions => {
    if (notLoadingNorError(priorityQuestions) && priorityQuestions.data !== null) {
      const priorityProducts: {[key: string]: PriorityQuestionnaireSerializerAnswer} = {};
      for (const priorityQuestion of priorityQuestions.data) {
        if (priorityQuestion.scoredProducts && priorityQuestion.scoredProducts.length > 0) {
          priorityProducts[priorityQuestion.clientUuid] = priorityQuestion;
        }
      }
      return priorityProducts as LifeInsurancePriorityProducts;
    }
    return {};
  },
);

export const selectPriorityQuestionnaire = createSelector(
  getGetPriorityQuestionnaireStateSelector,
  priorityQuestions => {
    if (notLoadingNorError(priorityQuestions) && priorityQuestions.data !== null) {
      return priorityQuestions.data;
    }
    return [];
  },
);
