import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'jsonSafe',
})
export class JsonSafePipe<T> implements PipeTransform {
  transform(obj: T): string {
    const cache: object[] = [];
    return JSON.stringify(
      obj,
      function (_, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) return `>>circular>>`; // Circular reference found, discard key
          cache.push(value);
        }
        return value;
      },
      2,
    );
  }
}
