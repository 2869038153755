export enum IClientTabs {
  SUBJECTS = 'subjects',
  SUMMARY = 'summary',
}

export interface CardBoard {
  complexPlan?: BoardSection;
  lifeSituation: BoardSection;
  otherTools: BoardSection;
  gold?: BoardSection;
}

export interface BoardSection {
  mainCard?: ModuleCard;
  cards: ModuleCard[];
}

export interface ModuleCard {
  target?: string;
  type: 'primary' | 'secondary' | 'other';
  isEditable?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  showInAdvisorMenu?: boolean;
  icon: string;
  iconMarginTop?: string;
  label: string;
  value?: string;
  imagePath?: string;
  header?: string;
  isExternalLink?: boolean;
  statusIcon?: string;
  statusColor?: string;
  statusTooltip?: string;
}
