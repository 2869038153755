import {Component, OnDestroy} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {InputComponentType} from '@shared/ui/forms/input/input.component';

@UntilDestroy()
@Component({
  selector: 'kpt-input-formly',
  templateUrl: './input-formly.component.html',
  styleUrls: ['./input-formly.component.scss'],
})
export class InputFormlyComponent extends FieldType implements OnDestroy {
  get type(): InputComponentType {
    if (this.field.type === FormlyTypes.Currency) return 'number';
    return this.field.type !== 'input' ? (this.field.type as InputComponentType) : 'text';
  }

  ngOnDestroy() {}

  onChange(field: FormlyFieldConfig, $event: any) {
    if (this.to.change) this.to.change(field, $event);
  }
}
