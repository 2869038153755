import produce from 'immer';
import {UIActions, UIActionsUnion} from 'src/store/actions/ui.actions';

export interface UIState {
  wideContainer: number;
}

export const initialState: UIState = {
  wideContainer: 0,
};

const uiReducer = produce((state: UIState, action: UIActionsUnion) => {
  switch (action.type) {
    case UIActions.setWideContainer.type: {
      state.wideContainer++;
      break;
    }

    case UIActions.unsetWideContainer.type: {
      state.wideContainer--;
      break;
    }
  }
});

export function reducer(state = initialState, action: UIActionsUnion): UIState {
  return uiReducer(state, action);
}
