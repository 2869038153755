import {Injectable} from '@angular/core';
import {BadDebtsRepaymentAssetType} from '@shared/analysis/asset-types/bad-debts-repayment/bad-debts-repayment-asset-type';
import {CarAssetType} from '@shared/analysis/asset-types/car/car-asset-type';
import {ChildBirthAssetType} from '@shared/analysis/asset-types/child-birth/child-birth-asset-type';
import {ChildrenFutureAssetType} from '@shared/analysis/asset-types/children-future/children-future-asset-type';
import {FamilyProvisionAssetType} from '@shared/analysis/asset-types/family-provision/family-provision-asset-type';
import {FinancialIndependenceAssetType} from '@shared/analysis/asset-types/financial-independence/financial-independence-asset-type';
import {FurnishingsAssetType} from '@shared/analysis/asset-types/furnishings/furnishings-asset-type';
import {LongTermReserveAssetType} from '@shared/analysis/asset-types/long-term-reserve/long-term-reserve-asset-type';
import {NewHousingAssetType} from '@shared/analysis/asset-types/new-housing/new-housing-asset-type';
import {OtherObjectiveAssetType} from '@shared/analysis/asset-types/other-objective/other-objective-asset-type';
import {OwnHousingAssetType} from '@shared/analysis/asset-types/own-housing/own-housing-asset-type';
import {ReconstructionAssetType} from '@shared/analysis/asset-types/reconstruction/reconstruction-asset-type';
import {RetirementAssetType} from '@shared/analysis/asset-types/retirement/retirement-asset-type';
import {ShortTermReserveAssetType} from '@shared/analysis/asset-types/short-term-reserve/short-term-reserve-asset-type';
import {VacationAssetType} from '@shared/analysis/asset-types/vacation/vacation-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';

@Injectable()
export class ObjectivesDefinitions {
  constructor(
    private ownHousing: OwnHousingAssetType,
    private childrenFuture: ChildrenFutureAssetType,
    private financialIndependence: FinancialIndependenceAssetType,
    private shortTermReserve: ShortTermReserveAssetType,
    private longTermReserve: LongTermReserveAssetType,
    private badDebtsRepayment: BadDebtsRepaymentAssetType,
    private childBirth: ChildBirthAssetType,
    private newHousing: NewHousingAssetType,
    private reconstruction: ReconstructionAssetType,
    private furnishings: FurnishingsAssetType,
    private car: CarAssetType,
    private vacation: VacationAssetType,
    private otherObjective: OtherObjectiveAssetType,
    private retirement: RetirementAssetType,
    private familyProvision: FamilyProvisionAssetType,
  ) {}

  getAll(): AssetDefinition[] {
    return [
      this.ownHousing.getAssetDefinition(),
      this.childrenFuture.getAssetDefinition(),
      this.retirement.getAssetDefinition(),
      this.financialIndependence.getAssetDefinition(),
      this.shortTermReserve.getAssetDefinition(),
      this.longTermReserve.getAssetDefinition(),
      this.badDebtsRepayment.getAssetDefinition(),
      this.familyProvision.getAssetDefinition(),
      this.childBirth.getAssetDefinition(),
      this.newHousing.getAssetDefinition(),
      this.reconstruction.getAssetDefinition(),
      this.furnishings.getAssetDefinition(),
      this.car.getAssetDefinition(),
      this.vacation.getAssetDefinition(),
      this.otherObjective.getAssetDefinition(),
    ];
  }
}
