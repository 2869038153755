import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

export const defaultDateFormat = 'DD.MM.YYYY';
export const monthDateFormat = 'MM.YYYY';

export const defaultDatepickerConfig: Partial<BsDatepickerConfig> = {
  containerClass: 'theme-default',
  showWeekNumbers: false,
  dateInputFormat: defaultDateFormat,
  customTodayClass: 'today',
};

export const defaultMonthpickerConfig: Partial<BsDatepickerConfig> = {
  containerClass: 'theme-default',
  dateInputFormat: monthDateFormat,
};
