import {createAction, props, union} from '@ngrx/store';

export const setWideContainer = createAction('[UI] Set Wide Container');

export const unsetWideContainer = createAction('[UI] Unset Wide Container');

export const showFatalError = createAction('[UI] showFatalError', props<{message: string}>());

export const UIActions = {
  setWideContainer,
  unsetWideContainer,
  showFatalError,
};

const allActions = union(UIActions);

export type UIActionsUnion = typeof allActions;
