import {Asset, AssetType} from '@shared/analysis/models/asset';

export interface GraphEvent {
  label: string;
  labelFormatted?: string;
  type: AssetType;
  icon?: string;
  isDraggable: boolean;
  startYear: number;
  buttonType?: string;
  asset: Asset;
  typeIndex: number;
  tooltip?: boolean;
  highlight?: boolean;
  recommended?: boolean;
}
